// cSpell:disable
export const messages = {
  es_ES: {
    translation: {
      module: {
        air: 'Aire',
        dialysis: 'Diálisis',
        infusion: 'Infusión',
        monitor: 'Monitor',
      },
      menu: {
        alarms: 'Alarmas',
        configERP: 'Frecuencia PEP',
        dashboard: 'Cuadro de mando',
        history: 'Historial',
        sendERP: 'Enviar PEP',
        deployment: 'Implantación de hospitales',
        ecg: 'ECG',
      },
      configMenu: {
        userManagement: 'Gestión de usuario',
        assistentialManagement: 'Administración de cuidados',
        generalSettings: 'Configuración general',
      },
      title: {
        addUser: 'Orchestra | Registro de Usuario',
        air: 'Orchestra | Ventilador Pulmonar',
        alarms: 'Orchestra | Nivel de alarma',
        alarmsConfig: 'Orchestra | Configuración de alarmas',
        changePassword: 'Orchestra | Cambiar/alterar contraseña',
        dashboard: 'Orchestra | Cuadro de mando',
        erpConfig: 'Orchestra | Parámetros de envío al ERP',
        erpSend: 'Orchestra | Validación y envío al ERP',
        history: 'Orchestra | Historial del monitor',
        home: 'Orchestra | Camas',
        infusion: 'Orchestra | Bomba de infusión',
        login: 'Orchestra | Acceso',
        permissionDenied: 'Orchestra | ¡Permiso negado!',
        resetPassword: 'Orchestra | Restablecer la contraseña',
        users: 'Orchestra | Usuarios',
        usersLogs: 'Orchestra | Acceso de usuarios',
      },
      ssvv: {
        arterialPressure: 'Presión arterial',
        invasivePressure: 'Presión invasiva',
        diastolicArterialPressure: 'Presión arterial diastólica',
        heartRate: 'Frec. cardíaca',
        meanArterialPressure: 'Presión arterial media',
        oxygenSaturation: 'Sat. de oxígeno',
        respiratoryRate: 'Frec. respiratoria',
        systolicArterialPressure: 'Presión arterial sistólica',
        temperature: 'Temperatura',
        carbonDioxide: 'Dióxido de carbono',
        initials: {
          arterialPressure: 'PNI',
          diastolicArterialPressure: 'PAD',
          heartRate: 'FC',
          meanArterialPressure: 'PAM',
          oxygenSaturation: 'SpO₂',
          respiratoryRate: 'FR',
          systolicArterialPressure: 'PAS',
          temperature: 'Temp.',
          carbonDioxide: 'CO₂',
          invasivePressure: 'PAI',
        },
        units: {
          arterialPressure: 'mmHg',
          invasivePressure: 'mmHg',
          diastolicArterialPressure: 'mmHg',
          heartRate: 'bpm',
          meanArterialPressure: 'mmHg',
          oxygenSaturation: '%',
          respiratoryRate: 'rpm',
          systolicArterialPressure: 'mmHg',
          temperature: '°C',
          carbonDioxide: 'mmHg',
        },
        noData: 'NO HAY DATOS DEL MONITOR',
      },
      infusion: {
        volumeBasalInfused: 'Volumen basal infundido',
        volumeTotalInfused: 'Volumen total de líquido infundido',
        chooseMedication: 'Elegir medicación',
        noSubstancesFound: 'NO SE ENCONTRARON SUSTANCIAS',
        contactTheAdministrator: 'CONTACTAR AL ADMINISTRADOR',
        medication: 'Medicamento',
        dosage: 'Dosis:',
        wait: 'Esperar...',
        noData: 'NO HAY DATOS DE INFUSION',
      },
      air: {
        noData: 'NO HAY DATOS DE AIR',
        configIndicators: 'Configuración de indicadores',
        mode: 'modalidad',
        opMode: 'modo de operación',
        incidence: 'incidencia',
        complacency: 'Complacencia EST',
        resistance: 'resistencia',
        VENT_MODE: 'MODALIDAD',
        NIV: 'Ventilación no invasiva',
        BIVENT_APRV: 'Ventilación con liberación de presión en la vía aérea (APRV)',
        NAVA: 'Ventilación asistida ajustada neuronalmente (NAVA)',
        NAVA_NIV: 'Ventilación asistida ajustada neuronalmente / Ventilación no invasiva',
        NASAL_CPAP: 'Presión positiva continua en las vías respiratorias / Nasal ',
        CPAP: 'Presión positiva continua en las vías respiratorias  (CPAP)',
        PS_CPAP: 'Presión positiva continua en las vías respiratorias  / Presión asistida',
        DUAL_PAP: 'Ventilación con presión positiva en dos niveles',
        SIMV_PS: 'Ventilación mandatoria intermitente sincronizada (SIMV) con presión controlada',
        SIMV_PRVC_VS: 'Ventilación mandatoria intermitente sincronizada con volumen controlado',
        VCV: 'Ventilación mandatoria con volumen controlado',
        SIMV_PRVC_PS: 'Ventilación mandatoria intermitente sincronizada / control de volumen regulado por presión + soporte de presión',
        VS: 'Volumen asistido',
        PRVC: 'Control de volumen regulado a presión',
        VS_PRVS: 'Control de volumen regulado a presión / Volumen asistido',
        VC: 'Volumen controlado',
        VC_VS: 'Volumen controlado / Volumen asistido',
        PS_PC: 'Presión controlada / Presión asistida',
        PC: 'Presión controlada',
        NIV_PS: 'Ventilación no invasiva / Presión asistida',
        HIGH_FLOW: 'Cánula nasal de alto flujo',
        MMV: 'Ventilación espontánea con volumen minuto mandatorio',
        PLV: 'Ventilación por presión limitada y flujo continuo (neonatal)',
        FLOW_TRIGGER: 'MODO DE OPERACIÓN',
        CONTROLLED: 'Controlada',
        ASSISTED: 'Asistida',
        COMBINED: 'Combinada',
        FLOW_CONTROL: 'INCIDENCIA ',
        PRESSURE: 'Presión ',
        VOLUME: 'Volumen',
        FLOW: 'Flujo',
        CSTAT: 'Cest',
        PMEAN: 'P Media',
        PEEP: 'PEEP',
        RRATE: 'FR',
        PLAT: 'Pausa Insp',
        VMI: 'VM Insp',
        VME: 'VM Exp',
        PPEAK: 'Ppico',
        PPLAT: 'Pplat (Plateau)',
        VTE: 'VC Exp',
        VTI: 'VC Insp',
        SENS: 'SENS',
        PINS: 'Pinsp',
        CDYN: 'Cdin',
        RI: 'RVA Insp',
        RE: 'RVA Exp',
        PRSENS: 'SENS',
        FLSENS: 'SENS',
        SPO2: 'FiO₂',
        RATIO_IE: 'I:E',
        units: {
          CSTAT: 'ml/cmH₂O',
          PMEAN: 'cmH₂O',
          PEEP: 'cmH₂O',
          RRATE: 'rpm',
          PLAT: 's',
          VMI: 'l/min',
          VME: 'l/min',
          PPEAK: 'cmH₂O',
          PPLAT: 'cmH₂O',
          VTE: 'ml/kg',
          VTI: 'ml/kg',
          PINS: 'cmH₂O',
          CDYN: 'ml/cmH₂O',
          RI: 'cmH₂O/l/s',
          RE: 'cmH₂O/l/s',
          PRSENS: 'cmH₂O',
          FLSENS: 'l/min',
          SPO2: '%',
          RATIO_IE: '',
        },
      },
      abbreviation: {
        diastolicArterialPressure: 'PAD',
        diastolicArterialPressure_measureUnit: 'PAD (mmHg)',
        heartRate: 'FC',
        heartRate_measureUnit: 'FC (bpm)',
        meanArterialPressure: 'PAM',
        meanArterialPressure_measureUnit: 'PAM (mmHg)',
        oxygenSaturation: 'SAT',
        oxygenSaturation_measureUnit: 'SAT (%)',
        respiratoryRate: 'FR',
        respiratoryRate_measureUnit: 'FR (rpm)',
        systolicArterialPressure: 'PAS',
        systolicArterialPressure_measureUnit: 'PAS (mmHg)',
        temperature: 'TEMP',
        temperature_measureUnit: 'TEMP (ºC)',
        carbonDioxide: 'CO2 (mmHg)',
      },
      pageHome: {
        modal: {
          title: 'Atención',
          one: 'El preset "{{presetName}}" no está asociado y en breve será excluido.',
          many: 'Los siguientes presets no están asociados y en breve serán excluidos.',
        },
        reorderError: 'Se produjo un error al reordenar las camas, inténtelo nuevamente.',
      },
      historyMonitorSelect: {
        detailedSight: 'visualización detallada (1min)',
        mediumSight: 'visualización intermedia (15min)',
        wideSight: 'visualización general (30min)',
      },
      monitorHistory: {
        noData: 'NO HAY DATOS EN EL HISTORIAL',
      },
      sendERP: {
        sendValidData: 'Enviar datos ya validados',
        sendNow: 'Recopilar ahora',
        selectAll: 'Seleccionar todos',
        select: 'Seleccionar',
        date: 'Fecha',
        hour: 'Hora',
        noData: 'SIN DATOS PEP',
        sentData: '¡Datos enviados con éxito!',
        updatedData: '¡Datos actualizados con éxito!',
        errorSentData: 'Datos no enviados. Intente nuevamente.',
        totalItems: 'Total {{count}} registros',
        modalTitle: '¡Atención!',
        modalMsg: 'Datos para historia clínica no enviados hace más de un día. Favor verificar.',
        disabled: 'DESACTIVADO',
      },
      frequencyERP: {
        hospital: 'Hospital',
        ward: 'Sector',
        timer: 'Tiempo de recogida',
        autoSend: 'Envío automático para el MV',
        toastSuccess: '¡Valores alterados con éxito!',
        toastFail: 'Hubo un error, intente nuevamente.',
      },
      alarmsConfig: {
        availHospitals: 'Hospitales disponibles [sector]',
        selectedHospitals: 'Hospitales seleccionados [sector]',
        resultCount: 'Exhibiendo {{count}} de {{total}} resultados',
        presetName: 'Nombre del preset',
        selectedPreset: 'Preset seleccionado: ',
        expertise: 'Especialidad',
        whoCreated: 'Creado por{{name}}',
        titleCreator: 'Creado por',
        createdAt: 'Fecha/Hora de creación',
        actions: 'Acciones',
        copyof: 'Copia{{count}} de - ',
        modal: {
          titleDuplicated: 'Preset duplicado',
          textDuplicated: 'Este preset ha sido duplicado. ¿Quieres editar ahora?',
          titleFail: 'Error',
          textFailDuplicated: 'Hubo un error, intente nuevamente.',
          titleRemove: 'Excluir',
          textRemove: '¿Desea excluir el preset "{{name}}"?',
          textFailRemove: 'Hubo un error al excluir el preset, intente nuevamente.',
        },
      },
      alarmsControl: {
        link: 'Vincular hospital',
        duplicate: 'Duplicar',
        edit: 'Editar',
        erase: 'Borrar',
        publish: 'Publicar',
        unpublish: 'Despublicar',
        addAll: 'Añadir todos >>',
        addSelected: 'Añadir seleccionado >',
        removeSelected: '< Remover seleccionado',
        removeAll: '<< Remover todos',
        noData: 'NO HAY DATOS EN ALARMAS',
      },
      presetConfig: {
        pageTitle: 'Preset de alarmas',
        presetName: 'Nombre del preset',
        specialty: 'Especialidad',
        createdBy: 'Creado por {{name}}',
        identification: 'Identificación ',
        seeMore: 'Ver más',
        seeLess: 'Ver menos',
        vitalSign: 'Signos vitales',
        success: {
          title: 'ÉXITO',
          text: 'Fue creado un nuevo preset',
        },
        fail: {
          title: 'ERROR',
          text: 'Hubo un error, intente nuevamente.',
        },
      },
      presetEdit: {
        success: {
          title: 'ÉXITO',
          text: 'Las alteraciones fueron guardadas',
        },
        fail: {
          title: 'ERROR',
          text: 'Hubo un error al actualizar el preset, intente nuevamente.',
        },
      },
      patientRecord: {
        pageTitle: 'Registro del paciente',
        dataSentToPep: 'Informaciones del paciente',
        byEquipment: 'Equipo',
        indicator: 'Indicador',
        condition: 'Condición',
        value: 'Valor',
        byDate: 'Fecha',
        addFilter: 'Añadir filtro',
        moreThen: 'Mayor que',
        lessThen: 'Menor que',
        equal: 'Igual',
        selectEquipment: 'Selecione um equipamento',
        search: {
          title: '¡Atención!',
          text: 'No se encontró información para esta búsqueda.',
        },
      },
      multiRange: {
        low: 'Bajo',
        high: 'Alto',
        tooLow: 'Muy bajo',
        tooHigh: 'Muy alto',
        normal: 'Normal',
      },
      specialties: {
        neonatal: 'Neonatal',
        pediatric: 'Pediátrica',
        mixedPediatric: 'Pediátrica mixta',
        pediatricNeonatal: 'Pediátrica y neonatal',
        adult: 'Adulto',
        cardiac: 'Cardiopata',
        coronary: 'Coronaria',
        surgical: 'Quirúrgica',
        neurological: 'Neurológica',
      },
      assistentialManagement: {
        titleFrequency: 'Defina la frecuencia de envío del muestreo de datos a la historia clínica.',
        rulerTitle: 'Frecuencia de envío',
      },
      levels: {
        fail: 'Sin datos',
        high: 'alto',
        low: 'bajo',
        normal: 'normal',
        veryHigh: 'muy alto',
        veryLow: 'muy bajo',
      },
      text: {
        accessDenied: 'Su autorización no es suficiente para acceder a esta página.',
        commercialMessage: 'Si desea convertirse en una Tele-UCI completa activando nuestros módulos adicionales para el monitoreo remoto de bombas de infusión y de ventiladores mecánicos, haga <0>clic aquí</0> o comuníquese con nuestro equipo de "Atención al Cliente"',
        criteriaForCreatingPassword: 'Criterios para crear una contraseña:',
        criteriaForCreatingPasswordDifferentPassword: 'Contraseña diferente a la nueva contraseña, verifíquela.',
        criteriaForCreatingPasswordNumberOfChars: 'La contraseña debe contener entre {{minCharacteres}} y {{maxCharacteres}} caracteres.',
        criteriaForCreatingPasswordTypesOfChars: 'La contraseña debe contener letras mayúsculas, minúsculas y números.',
        doNotHaveEmail: 'No tiene correo electrónico',
        doYouWantRemoveUser: '¿Realmente desea remover?',
        expiredSession: 'Su sesión expiró por inactividad. Es necesario ingresar nuevamente.',
        firstAccessText: 'Para continuar usando el sistema, por seguridad necesitamos que cree una nueva contraseña.',
        forgotPassword: 'Olvidé mi contraseña',
        goToInitialPage: 'Ir a la página inicial',
        informID: 'Favor informar su ID de profesional',
        informPassword: 'Favor informar su contraseña',
        loginLabel: 'Introduzca sus datos de acceso',
        logoutConfirmation: '¿Está seguro de que desea salir de Orchestra?',
        noChangesToSave: 'No hay cambios para guardar.',
        noDataFound: 'No se encontraron datos.',
        noLogsRegistred: 'No hay registros por el momento.',
        noRegistreFoundErp: 'No hay registros para validación y envío al ERP',
        noUserRegistred: 'No hay usuarios registrados',
        preset: 'Defina la frecuencia de envío del muestreo de datos a la historia clínica. Entre 15, 30 y 60 minutos (deslizar hasta la opción deseada)',
        searchResults: 'Mostrando {{amount}} de {{total}} resultados',
        setRange: 'Defina los valores de intervalo para el indicador:',
        typeAName: 'Favor digitar un nombre',
        typeAnEmail: 'Correo electrónico donde se enviará la contraseña',
        typeARole: 'Seleccione el nivel de permiso',
        typeCodeErp: 'Código del PEP',
        typePermissionLevel: 'Seleccione el nivel de permiso',
        typePhoneNumber: 'Código de área + Teléfono (opcional)',
        typeProfessionalID: 'Favor digitar el ID de profesional',
        typeProfessionalIDToRecoverPassword: 'Ingresa tu ID Profesional para recibir el código de acceso.',
        typeValidProfessionalID: 'Favor digitar un ID de profesional válido',
        typeXdigits: 'Por favor, su contraseña debe contener como mínimo {{count}} dígitos',
        typeYourPassword: 'Escribe tu contraseña',
        typeYourPasswordConfirmation: 'Ingrese la confirmación de su contraseña',
        typeYourProfessionalIDAndCode: 'Introduce tu ID Profesional y código recibido por email.',
        uniquepepIdForAllHospitals: 'Código PEP único para todos los hospitales',
        userLogsTitle: 'Administrador: consulte abajo los LOG del sistema relacionados al uso de todos los usuarios registrados.',
      },
      conectionStatus: {
        conected: 'Conectividad ON',
        desconected: 'Conectividad OFF',
      },
      button: {
        back: 'Volver',
        cancel: 'Cancelar',
        logOut: 'Salir',
        save: 'Guardar',
        sendValidatedData: 'Enviar datos ya validados',
        yes: 'Si',
        no: 'No',
        uploadAgain: 'Subir de nuevo',
        close: 'Salir',
        confirm: 'Confirmar',
        everyOne: 'Todos',
        search: 'Buscar',
        clear: 'Limpiar',
        exclude: 'Excluir',
        enableIt: 'Activar',
        disableIt: 'Inactivar',
        create: 'Crear',
        freezeMoment: 'Momento de congelación',
        captureMoment: 'Capturar el momento',
      },
      placeholder: {
        typeHere: 'Digitar aquí',
      },
      bedStatus: {
        DISABLED: 'Desactivado',
        errorPID: 'Por favor verifique los datos del PID',
        FREE: 'Libre',
        ON_HOLD: 'En espera',
        ACTIVE_UNPLUGGED: 'Desconectado',
        ON_HOLD_UNPLUGGED: 'Desconectado',
        FREE_UNPLUGGED: 'Desconectado',
        WAITING_ADMISSION: 'Anónimo',
        anonyPid: 'Esperando admisión',
      },
      alarmConfig: {
        cantBeEmpty: 'Este espacio no puede estar vacío',
        tooLowNotLowerThan: 'Valor muy bajo, no puede ser menor que',
        tooLowNotbiggerThan: 'Valor muy bajo, no puede ser más alto que el bajo',
        lowNotlowerThanTooLow: 'El valor bajo no puede ser menor o igual al bajo ',
        hightNotlowerThanLow: 'El valor alto no puede ser menor o igual al bajo',
        tooHightNotlowerThanHight: 'Valor muy alto, no puede ser menor o igual al alto',
        tooHightNotHigherThan: 'Valor muy alto, no puede ser mayor que',
      },
      confirmation: {
        keep: 'Continúe en la página',
        leave: 'Salir sin guardar',
        changesNotSaved: 'No se guardaron las modificaciones',
        inactivate: '¿Quieres desactivar?',
      },
      feedback: {
        allowedFileExtensions: 'Extensión de archivo permitida .Xlsx.',
        configSaved: 'Configuraciones guardadas',
        errorDisassociatingPatient: 'Error al disociar al paciente.',
        errorSavingConfig: 'Error al guardar la configuración',
        errorSavingPatient: 'Error al salvar al paciente.',
        errorUnknown: 'error inesperado, inténtalo de nuevo',
        errorWhenChangingPresets: 'Error al cambiar los ajustes preestablecidos',
        errorWhenCreatingUser: 'Error al crear el usuario',
        errorWhenLoadingConfig: 'Error al cargar la configuración',
        errorWhenLoadingPresets: 'Error al cargar ajustes preestablecidos',
        errorWhenLoadingSickbedConfig: 'Error al cargar la información de la cama',
        errorWhenLoadingUserList: 'No se pudo cargar la lista de usuarios',
        errorWhileLoading: 'Error del servidor al intentar cargar vitals, inténtelo de nuevo.',
        errorWhileValidating: 'Error del servidor al intentar validar. Inténtalo de nuevo.',
        exitWithoutSave: '¿Quieres salir sin guardar?',
        exitWithoutSavingUser: '¿Quieres salir sin crear el usuario?',
        exitWithoutSavingEditUser: '¿Quiere salir sin guardar los cambios?',
        exitWithoutSavePreset: 'As modificações não foram salvas Deseja sair sem salvar?',
        includeAHospital: 'Incluir al menos un hospital de la lista',
        invalidPhoneNumber: 'Teléfono inválido',
        noChangesToSave: '¡UPS! no hay cambios para guardar',
        NotAuthorizedException: 'Consulta tu ID Profesional y contraseña.',
        noVpn: 'Sin VPN',
        noGateway: 'Sin Gateway',
        patientDisassociatedSuccessfully: '¡El paciente fue desasociado con éxito.!',
        patientSuccessfullySaved: '¡Los datos del paciente se guardaron correctamente!',
        pleaseSelectAtLeastOneItem: 'Seleccione al menos un elemento de la lista.',
        pleaseSelectPermissionLevel: 'Seleccione un nivel de permiso',
        pleaseSelectSomeDataToSend: 'Seleccione algunos datos para enviar.',
        pleaseTypeAName: 'Por favor ingrese un nombre',
        pleaseTypeAnEmail: 'Por favor ingrese un correo electrónico',
        pleaseTypeAnValidEmail: 'Por favor, ingrese un correo electrónico válido',
        pleaseTypeAValidName: 'Por favor ingrese un nombre valido',
        pleaseTypeERPCode: 'Ingrese el código ERP',
        pleaseTypeProfessionalId: 'Ingrese la identificación profesional',
        pleaseTypeSurname: 'Por favor ingrese un apellido',
        professionalIdNotExist: 'La identificación profesional ingresada no está registrada en el sistema',
        redirectingToLogin: 'Redirigiendo para iniciar sesión...',
        requiredField: 'Campo requerido',
        serverErrorTryAgain: 'El sistema no está disponible, inténtalo de nuevo en unos momentos.',
        successfullyCreatedPassword: 'Contraseña registrada con éxito.',
        successfullyCreatedUser: 'Usuario creado con éxito, hemos enviado su contraseña por correo electrónico, verifique.',
        successfullyCreatedUsers: 'Usuarios registrados con éxito',
        successfullyDeletedUser: 'Usuario eliminado',
        successfullyUpdatedUser: 'Usuario cambiado con éxito',
        successfullyUpdatedUsers: 'Usuarios cambiados con éxito',
        successfullyValidated: 'Datos validados con éxito',
        theFollowingErrorsHasBeenFound: 'Se detectaron los siguientes errores:',
        updatedParams: 'Parámetros actualizados',
        usernameTaken: 'El ID profesional ya existe, por favor ingrese otro',
        weSentAnEmailToRecoverYourPassword: 'Hemos enviado el código de acceso a su correo electrónico, por favor verifíquelo.',
        weSentAnEmailToRecoverYourPasswordTo: 'Hemos enviado el código de acceso a su correo electrónico {{email}}, por favor verifíquelo.',
        whenTraingToUpdateSessionTime: 'Al intentar actualizar la hora de la sesión.',
        forbidden: 'Perfil no autorizado, comuníquese con su gerente.',
        badUserInput: 'Función actualmente no disponible, póngase en contacto con el soporte de Carenet.',
        internalServerError: 'Función no disponible actualmente, inténtelo de nuevo más tarde.',
      },
      selector: {
        selectHospital: 'Seleccionar hospital',
        selectIcu: 'Seleccione una UCI',
        selectPreset: 'Preset selecionado:',
      },
      pickList: {
        leftListTitle: 'Hospitales disponibles',
        rightListTitle: 'Hospitales seleccionados',
        addAll: 'Añadir todo >>',
        addOne: 'Añadir hospital >',
        removeAll: '<< Eliminar todo',
        removeOne: '< Retirar hospital',
      },
      floatingMenu: {
        createMultiple: 'Crear en lote',
        createUser: 'Crear usuario',
        editMultiple: 'Edición por lotes',
        excludeSelection: 'Excluir selección',
        export: 'Exportar',
        createAlarm: 'Crear preset de alarme',
        createHospital: 'Crear hospital',
        networks: 'Crear rede',
      },
      usersTable: {
        actions: 'Acciones',
        fullName: 'Nombre de usuario',
        userName: 'ID Profesional',
        userPosition: 'Posición',
        role: 'Permiso',
        pepId: 'Codigo PEP',
        loadMore: 'Ver más',
      },
      useRoles: {
        S_Admin: 'S_Admin',
        G_Admin: 'G_Admin',
        H_Admin: 'H_Admin',
        User: 'Usuario',
        User_TV: 'Usuario de televisión',
        User_Deploy: 'Usuario de implementación',
        Orchestra_Support: 'Soporte Orchestra',
      },
      templateFile: {
        professionalId: 'Identificación profesional',
        pepId: 'Cod. de PEP',
        fullName: 'Nombre y apellido',
        email: 'E-mail',
        phone: 'Teléfono',
        role: 'Nivel de permiso',
        userPosition: 'Puesto',
        hospital: 'Hospital vinculado',
        status: 'Situación',
      },
      delpoymentTabs: {
        createHospital: 'Crear Hospital',
        configVPN: 'Configurar VPN',
      },
      tabConfigVPN: {
        gatewayClient: 'IP gateway del client',
        prefixIP: 'Prefijo IP estático*',
        algorithmCriptografy: 'Algoritmo de cifrado de fase 1',
        algorithmIntegrity: 'Algoritmo de integridad de fase 1',
        numberOfGroup: 'Números de grupo de fase 1 DH',
        vpnCreated: 'La VPN ya ha sido creada',
      },
      tabAir: {
        equipmentIP: 'Equipo | Puerta IP',
        unitBed: 'Unidad de hospitalización | Cama',
        converter: 'Convertidor',
        maker: 'Fabricante',
        model: 'Modelo',
        protocol: 'Protocolo',
        ipDoor: 'Puerta IP',
        intReq: 'Int. Sol.',
        intervalRequest: 'Intervalo solicitud',
        addEquipments: 'Añadir equipo',
        linkEquipmentsBed: 'Vincule el equipo a la unidad de pacientes hospitalizados | Camas',
        equipment: 'Equipo',
      },
      tabMonitor: {
        unitExhibition: 'Uni. de Hospitalización | Exhibición',
        unitTechnical: 'Uni. de Hospitalización | Técnico',
        equipment: 'Equipo',
        bedExhibition: 'Cama | Exhibición',
        bedTechnical: 'Cama | Técnico',
        hardware: 'Hardware',
        name: 'Nombre',
        ipDoor: 'Puerta IP',
        maker: 'Fabricante',
        familyModel: 'Familia/Modelo',
        firmware: 'Firmware',
        intReq: 'Int. Sol.',
        intervalRequest: 'Intervalo solicitud',
        infoOrchestra: 'Información Orchestra',
        dnsIP: 'DNS o IP',
        door: 'Puerta',
        addEquipments: 'Añadir equipo',
        hardwareType: 'Tipo de hardware',
        equipmentName: 'Nombre del equipo',
        serialNumber: 'Numero de serie',
        firmwareVersion: 'Versión de firmware (opcional)',
        linkEquipmentBed: 'Vincule equipo a la Unidad de Pacientes Internos/Camas',
        unitNameExhibition: 'Unidad de hospitalización | nombre de exibición',
        unitNameTechnical: 'Unidad de hospitalización | Nombre técnico',
        bedNameExhibition: 'Cama | Nombre de exibición',
        bedNameTechnical: 'Cama | Nombre técnico',
        plusICU: '+UTI',
        configWarnings: 'Configurar alertas para camas',
        bedFree: 'Cama libre no disponible por',
        bedOccupied: 'Cama ocupada no disponible por',
        bedStandby: 'Cama supletoria no disponible por',
        minutes: 'Min',
        gatewayClient: 'IP gateway del client*',
        prefixIP: 'IP estático*',
        algorithmCriptografy: 'Algoritmo de cifrado de fase {{phase}}',
        algorithmIntegrity: 'Algoritmo de integridad de fase {{phase}}',
        numberOfGroup: 'Números de grupo de fase {{phase}} DH',
        attention: 'Atención',
        uploadStarted: 'La operación ha comenzado, espere {{time}} min.',
        wipeVPN: 'Eliminar VPN',
      },
      logs: {
        logHistory: 'Historia - Registros',
        logUser: 'Información del Usuario',
      },
      logActions: {
        userLogin: 'Conectado',
        userLogout: 'Cierre de sesión realizado',
        userChangePassword: 'Cambio de contraseña realizado',
        userChangeIcu: 'Cambio de UCI, de {{0}} a {{1}}',
        userChangeHospital: 'Cambio de hospital de {{0}} a {{1}}',
        userExclusion: 'Eliminación de usuario {{0}}',
        userActivated: 'Usuario activado {{0}}',
        userDeactivated: 'Usuario desactivado {{0}}',
        userActivatedBy: 'Activado por el usuario {{0}}',
        userDeactivatedBy: 'Desactivado por el usuario {{0}}',
        userBatchCreateDownload: 'Plantilla descargada para crear en lote',
        userBatchCreate: 'Archivo subido para crear en lote',
        userBatchEditDownload: 'Plantilla de edición por lotes descargada',
        userBatchEdit: 'Archivo de edición por lotes subido',
        userCreate: 'Usuario registrado {{0}}',
        userUpdate: 'Actualización de registro',
        userUpdateName: 'Nombre actualizado: {{0}} a {{1}}',
        userUpdateUsername: 'ID profesional actualizado: {{0}} a {{1}}',
        userUpdatePosition: 'Posición actualizada: {{0}} a {{1}}',
        userUpdatePhone: 'Teléfono actualizado: {{0}} a {{1}}',
        userUpdateEmail: 'E-mail actualizado: {{0}} a {{1}}',
        userUpdateRole: 'Nivel de permiso actualizado: {{0}} a {{1}}',
        userAddHospital: 'Enlace del hospital agregado: {{0}} para el usuario: {{1}}',
        userRemoveHospital: 'Se eliminó el enlace del hospital: {{0}} del usuario {{1}}',
        userUpdatePepCode: 'Código PEP actualizado {{0}} a Código PEP {{1}} {{2}}',
        pepSentDataToPep: 'Datos enviados del {{1}} a la historia clínica electrónica del {{0}}',
        pepUpdatedVitalSign: 'Signo vital actualizado [{{1}}], valor de {{2}} a {{3}} el {{0}}',
        pepIncludingVitalSign: 'Incluido para signo vital [{{1}}], valor {{2}} en la fecha {{3}}',
        presetCreated: 'Registrado preset {{0}}',
        presetDuplicated: 'Duplicado preset {{0}}',
        presetExcluded: 'Exclusión o preset {{0}}',
        presetPublished: 'Publicado preset {{0}}',
        presetUnpublished: 'Despublicado preset {{0}}',
        presetLinked: 'Vinculado preset {{0}}',
        presetUnlinked: 'Desvinculado preset {{0}}',
        presetTempCreated: 'Registrado preset temporal {{0}}',
        presetUpdatedTitle: 'Cambió el título del preset {{0}} para {{1}}',
        presetUpdatedSpecialty: 'Cambió la especialidad de preset {{0}} para {{1}}',
        presetUpdatedVitalSign: 'Cambió el parámetro de alarma de {{0}} {{1}} de {{2}} para {{3}}',
        deviceLinked: 'Equipo {{0}} "{{1}}" asociado a la cama {{2}}',
        hospitalCreated: '{{0}} registrado',
        vpnCreate: 'VPN creada',
        pepFrequencyUpdated: 'Se actualizó la configuración de frecuencia de {{0}} de {{1}} {{2}} minutos a {{3}} minutos.',
        custom_arterial_pressure: 'PA',
        custom_diastolic_arterial_pressure: 'PAD',
        custom_heart_rate: 'FC',
        custom_mean_arterial_pressure: 'PAM',
        custom_oxygen_saturation: 'SAT O₂',
        custom_respiratory_rate: 'FR',
        custom_systolic_arterial_pressure: 'PAS',
        custom_temperature: 'Temp',
        temperatureDisabled: 'Temperatura desactivada',
        temperatureEnabled: 'Temperatura activada',
        carbonDioxideDisabled: 'CO₂ desactivado',
        carbonDioxideEnabled: 'CO₂ activado',
      },
      deployment: {
        pageTitle: 'Implantación',
        updatedAt: 'Actualizado',
        hospitalName: 'Nombre del hospital',
        network: 'Red',
        networks: 'Redes',
        createNetwork: 'Crear Red',
        editNetwork: 'Editar Red',
        networkName: 'Nombre de red',
        city: 'Ciudad',
        state: 'CEI',
        actions: 'Acciones',
        unit: 'Unidad',
        modalExcludeBed: {
          title: 'Atención',
          text: '¿Quieres eliminar "{{bedName}}"?',
          text2: 'La UCI dispone de cama activa, ¡compruébalo!',
        },
        modalExcludeEquip: {
          title: 'Atención',
          text: '¿Quieres eliminar "{{equipName}}"?',
        },
        repeatedDevice: '¡Ya existe un dispositivo con este nombre!',
        repeatedHost: '¡Este host ya existe!',
        repeatedSerial: 'Este número de serie ya existe',
        repeatedBed: '"{{bed}}" en uso, ¡compruébalo!',
      },
      labelPage: {
        productName: 'Nombre comercial del producto – Código del producto (Versión software)',
        descriptionProduct: 'Descripción del producto registrado en ANVISA',
        productDesc: 'Sistema de interoperabilidad y visualización de informaciones de terapia intensiva hospitalaria',
        vendorName: 'Nombre del fabricante y dirección:',
        storageIndication: 'Recomendaciones de almacenamiento, conservación y/o manipulación del producto, advertencias y/o precauciones:',
        version: 'Versión:',
        personTechnical: 'Nombre del responsable técnico:',
        shelfLife: 'Fecha de vencimiento: Indeterminado',
        composition: 'Composición: No se aplica',
        sterile: 'Estéril: No se aplica',
        registerClass: 'Nº de registro del Consejo de Clase:',
        quantity: 'Cantidad en el empaque: No se aplica',
        sterileMethod: 'Método de esterilización: No se aplica',
        registerNumberProduct: 'Número de registro del producto médico ANVISA:',
        storageCondition: 'Condiciones de almacenamiento: No se aplica',
        sac: 'SAC: ',
        whastapp: 'WhatsApp: ',
      },
      logCheck: {
        enable: 'Activar',
        disable: 'Desactivar',
      },
      section: {
        seeLess: 'Ver menos',
        seeMore: 'Ver más',
      },
      secondsUnit: 's',
      accessDenied: '¡Acceso negado!',
      action_plural: 'acciones',
      action: 'Acción',
      addUser: 'Registro de usuario',
      all: 'Todos',
      attention: 'Atención',
      bed_plural: 'Camas',
      bed: 'Cama',
      changeICU: 'Cambio de UCI',
      code: 'Código',
      codErp: 'Cód. del ERP',
      complacency: 'Complacencia',
      comingSoon: 'Próximamente paciente watcher',
      config: 'Config',
      configurationFile: 'Ajustes',
      createPassword: 'Crear contraseña',
      date: 'fecha',
      justDate: 'Fecha',
      dateTime: 'Fecha y Hora',
      delete: 'Excluir',
      disassociatingPatient: 'Disociar paciente',
      downloadFile: 'Descargar plantilla',
      edit: 'Editar',
      email: 'Correo electrónico',
      empty: 'vacio',
      error: 'error',
      expiredSession: 'LA SESIÓN HA EXPIRADO',
      fullName: 'Nombre y apellido',
      generateFile: 'Exportar VPN',
      hospital: 'Hospital',
      hour: 'hora',
      incidence: 'incidencia',
      indicators: 'indicadores',
      indicatorsConfiguration: 'Configuración de indicadores',
      line: 'línea',
      linkHospitals: 'Enlazar hospitales',
      linkedHospitals: 'Hospitales enlazados',
      loading: 'Cargando...',
      loadingSSVV: 'Cargando signos vitales...',
      log: 'Log',
      logOut: 'Salir del sistema',
      label: 'Etiqueta',
      measureUnit: 'Unidad de medida',
      medication: 'Medicamento',
      modality: 'modalidad',
      mozartDownloadHere: 'Descarga Mozart aquí',
      newPassword: 'Nueva contraseña',
      operationsMode: 'Modo de operación',
      qrCodeMozard: 'Escanea el código para descargar Mozart',
      downloadMozardHere: 'Descarga Mozard aquí',
      orchestrationSystem: 'Sistema de orquestación',
      password: 'Contraseña',
      passwordConfirmation: 'Confirmación de contraseña',
      pepId: 'Código PEP',
      permission: 'Permiso',
      permissionLevel: 'Nível de autorización',
      phone: 'Teléfono',
      professionalData: 'Datos profesionales',
      professionalFullName: 'Nombre y apellido del profesional',
      professionalId: 'ID profesional',
      professionalIdSugestion: 'Sugerencia: nombre.apellido',
      professionalPosition: 'Puesto del profesional',
      resistence: 'resistencia',
      role: 'función',
      roleOpt: 'Cargo',
      qrCodeMozart: 'Escanea el código para descargar la aplicación Mozart',
      search: 'Buscar',
      searchForPreset: 'Buscar preconfiguración',
      sector: 'Sector',
      seeProfile: 'Ver perfil',
      select: 'Seleccionar',
      selectAll: 'seleccionar todos',
      sendFile: 'Enviar archivo',
      sendValidatedData: 'Enviar datos validados',
      settings: 'Ajustes',
      standard: 'Estándar',
      success: 'Éxito',
      time: 'tiempo',
      uploadFile: 'Importar VPN',
      user_plural: 'usuarios',
      user: 'usuario',
      userLogs: 'registros de usuarios',
      value: 'Valor',
      version: 'Versión',
      welcomeToOrchestra: '{{name}}, bienvenido al Orchestra.',
      noData: 'Sin datos',
      dataSaved: 'Datos guardados',
      wentWrong: 'Se produjo un error, inténtalo de nuevo',
      now: 'Ahora',
      wait: 'Espere...',
      alarmEquipment: {
        monitor: {
          hrTooHigh: {
            trans: 'TAQUICARDIA',
            keyword: 'TAQUICARDIA',
          },
          hrTooLow: {
            trans: 'BRADICARDIA',
            keyword: 'BRADICARDIA',
          },
          st1TooHigh: {
            trans: 'ST1 MUY ALTA',
            keyword: 'ST1',
          },
          st1TooLow: {
            trans: 'ST1 MUY BAJA',
            keyword: 'ST1',
          },
          st2TooHigh: {
            trans: 'ST2 MUY ALTA',
            keyword: 'ST2',
          },
          st2TooLow: {
            trans: 'ST2 MUY BAJA',
            keyword: 'ST2',
          },
          stITooHigh: {
            trans: 'ST-I MUY ALTA',
            keyword: 'ST-I',
          },
          stITooLow: {
            trans: 'ST-I MUY BAJA',
            keyword: 'ST-I',
          },
          stIiTooHigh: {
            trans: 'ST-II MUY ALTA',
            keyword: 'ST-II',
          },
          stIiTooLow: {
            trans: 'ST-II MUY BAJA',
            keyword: 'ST-II',
          },
          stIiiTooHigh: {
            trans: 'ST-III MUY ALTA',
            keyword: 'ST-III',
          },
          stIiiTooLow: {
            trans: 'ST-III MUY BAJA',
            keyword: 'ST-III',
          },
          stAvrTooHigh: {
            trans: 'ST-aVR MUY ALTA',
            keyword: 'ST-aVR',
          },
          stAvrTooLow: {
            trans: 'ST-aVR MUY BAJA',
            keyword: 'ST-aVR',
          },
          stAvlTooHigh: {
            trans: 'ST-aVL MUY ALTA',
            keyword: 'ST-aVL',
          },
          nibpDiaTooHigh: {
            trans: 'NIBP-Dia MUY ALTA',
            keyword: 'NIBP-DIAST',
          },
          nibpDiaTooLow: {
            trans: 'NIBP-Dia MUY BAJA',
            keyword: 'NIBP-DIAST',
          },
          rrTooHigh: {
            trans: 'TAQUIPNEA',
            keyword: 'TAQUIPNEA',
          },
          rrTooLow: {
            trans: 'BRADIPNEA',
            keyword: 'BRADIPNEA',
          },
          ibp1SysTooHigh: {
            trans: 'IBP1-Sist MUY ALTA',
            keyword: 'IBP1-SIST',
          },
          ibp1SysTooLow: {
            trans: 'IBP1-Sist MUY BAJA',
            keyword: 'IBP1-SIST',
          },
          ibp1MeanTooHigh: {
            trans: 'IBP1-Media MUY ALTA',
            keyword: 'IBP1-MEDIA',
          },
          ibp1MeanTooLow: {
            trans: 'IBP1-Media MUY BAJA',
            keyword: 'IBP1-MEDIA',
          },
          ibp1DiaTooHigh: {
            trans: 'IBP1-Diast MUY ALTA',
            keyword: 'IBP1-DIAST',
          },
          ibp1DiaTooLow: {
            trans: 'IBP1-Diast MUY BAJA',
            keyword: 'IBP1-DIAST',
          },
          ibp2SysTooHigh: {
            trans: 'IBP2-Sist MUY ALTA',
            keyword: 'IBP2-SIST',
          },
          ibp2SysTooLow: {
            trans: 'IBP2-Sist MUY BAJA',
            keyword: 'IBP2-SIST',
          },
          ibp2MeanTooHigh: {
            trans: 'IBP2-Media MUY ALTA',
            keyword: 'IBP2-MEDIA',
          },
          ibp2MeanTooLow: {
            trans: 'IBP2-Media MUY BAJA',
            keyword: 'IBP2-MEDIA',
          },
          ibp2DiaTooHigh: {
            trans: 'IBP2-Diast MUY ALTA',
            keyword: 'IBP2-DIAST',
          },
          ibp2DiaTooLow: {
            trans: 'IBP2-Diast MUY BAJA',
            keyword: 'IBP2-DIAST',
          },
          ibp3SysTooHigh: {
            trans: 'IBP3-Sist MUY ALTA',
            keyword: 'IBP3-SIST',
          },
          ibp3SysTooLow: {
            trans: 'IBP3-Sist MUY BAJA',
            keyword: 'IBP3-SIST',
          },
          ibp3MeanTooHigh: {
            trans: 'IBP3-Media MUY ALTA',
            keyword: 'IBP3-MEDIA',
          },
          ibp3MeanTooLow: {
            trans: 'IBP3-Media MUY BAJA',
            keyword: 'IBP3-MEDIA',
          },
          ibp3DiaTooHigh: {
            trans: 'IBP3-Diast MUY ALTA',
            keyword: 'IBP3-DIAST',
          },
          ibp3DiaTooLow: {
            trans: 'IBP3-Diast MUY BAJA',
            keyword: 'IBP3-DIAST',
          },
          ibp4SysTooHigh: {
            trans: 'IBP4-Sist MUY ALTA',
            keyword: 'IBP4-SIST',
          },
          ibp4SysTooLow: {
            trans: 'IBP4-Sist MUY BAJA',
            keyword: 'IBP4-SIST',
          },
          ibp4MeanTooLow: {
            trans: 'IBP4-Media MUY BAJA',
            keyword: 'IBP4-MEDIA',
          },
          ibp4DiaTooLow: {
            trans: 'IBP4-Diast MUY BAJA',
            keyword: 'IBP4-DIAST',
          },
          fico2TooLow: {
            trans: 'FiCO2 MUY BAJA',
            keyword: 'FiCO2',
          },
          etco2TooLow: {
            trans: 'EtCO2 MUY BAJO',
            keyword: 'EtCO2',
          },
          awrrTooLow: {
            trans: 'awRR MUY BAJO',
            keyword: 'AwRR',
          },
          t1TooLow: {
            trans: 'T1 MUY BAJO',
            keyword: 'T1',
          },
          t2TooLow: {
            trans: 'T2 MUY BAJO',
            keyword: 'T2',
          },
          tdTooLow: {
            trans: 'Td MUY BAJO',
            keyword: 'Td',
          },
          tbTooLow: {
            trans: 'TB MUY BAJO',
            keyword: 'TB',
          },
          eto2TooLow: {
            trans: 'EtO2 MUY BAJO',
            keyword: 'EtO2',
          },
          fio2TooLow: {
            trans: 'FiO2 MUY BAJA',
            keyword: 'FiO2',
          },
          etn2oTooLow: {
            trans: 'EtN2O MUY BAJO',
            keyword: 'EtN2O',
          },
          fin2oTooLow: {
            trans: 'FiN2O MUY BAJA',
            keyword: 'FiN2O',
          },
          etaaTooLow: {
            trans: 'EtAA MUY BAJO',
            keyword: 'EtAA',
          },
          fiaaTooLow: {
            trans: 'FiAA MUY BAJA',
            keyword: 'FiAA',
          },
          ethalTooLow: {
            trans: 'EtHAL MUY BAJO',
            keyword: 'EtHAL',
          },
          fihalTooLow: {
            trans: 'FiHAL MUY BAJA',
            keyword: 'FiHAL',
          },
          etenfTooLow: {
            trans: 'EtENF MUY BAJO',
            keyword: 'EtENF',
          },
          fienfTooLow: {
            trans: 'FiENF MUY BAJA',
            keyword: 'FiENF',
          },
          etisoTooLow: {
            trans: 'EtISO MUY BAJO',
            keyword: 'EtISO',
          },
          fiisoTooLow: {
            trans: 'FiISO MUY BAJA',
            keyword: 'FiISO',
          },
          etsevTooLow: {
            trans: 'EtSEV MUY BAJO',
            keyword: 'EtSEV',
          },
          fisevTooLow: {
            trans: 'FiSEV MUY BAJA',
            keyword: 'FiSEV',
          },
          etdesTooLow: {
            trans: 'EtDES MUY BAJO',
            keyword: 'EtDES',
          },
          fidesTooLow: {
            trans: 'FiDES MUY BAJA',
            keyword: 'FiDES',
          },
          agawrrTooLow: {
            trans: 'AgAwRR MUY BAJA',
            keyword: 'AgAwRR',
          },
          gasApnea: {
            trans: 'APNEA',
            keyword: 'APNEA',
          },
          ecgLost: {
            trans: 'DESCONECTADO ECG',
            keyword: 'ECG',
          },
          noPulse: {
            trans: 'SIN PULSO',
            keyword: 'SIN PULSO',
          },
          apnea: {
            trans: 'APNEA',
            keyword: 'APNEA',
          },
          asystole: {
            trans: 'ASISTOLIA',
            keyword: 'ASISTOLIA',
          },
          fio2TooHigh: {
            trans: 'FiO2 MUY ALTA',
            keyword: 'FiO2',
          },
          peepTooHigh: {
            trans: 'PEEP MUY ALTO',
            keyword: 'PEEP',
          },
          peepTooLow: {
            trans: 'PEEP MUY BAJO',
            keyword: 'PEEP',
          },
          pipTooHigh: {
            trans: 'PIP MUY ALTO',
            keyword: 'PIP',
          },
          pipTooLow: {
            trans: 'PIP MUY BAJO',
            keyword: 'PIP',
          },
          bisTooHigh: {
            trans: 'BIS MUY ALTO',
            keyword: 'BIS',
          },
          bisTooLow: {
            trans: 'BIS MUY BAJO',
            keyword: 'BIS',
          },
          paSysTooHigh: {
            trans: 'PAS MUY ALTA',
            keyword: 'PAS',
          },
          st4OutOfRange: {
            trans: 'ST4 - NO IDENTIFICADA',
            keyword: 'ST4',
          },
          st5OutOfRange: {
            trans: 'ST5 - NO IDENTIFICADA',
            keyword: 'ST5',
          },
          st6OutOfRange: {
            trans: 'ST6 - NO IDENTIFICADA',
            keyword: 'ST6',
          },
          st7OutOfRange: {
            trans: 'ST7 - NO IDENTIFICADA',
            keyword: 'ST7',
          },
          rrOutOfRange: {
            trans: 'FR - NO IDENTIFICADA',
            keyword: 'FR',
          },
          spo2SensorOff: {
            trans: 'SpO2 - SENSOR APAGADO',
            keyword: 'SpO2',
          },
          spo2SearchingForPulse: {
            trans: 'SpO2 - BUSCANDO PULSO',
            keyword: 'SpO2',
          },
          spo2InitializationError: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError1: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError2: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError3: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError4: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError5: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError6: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError7: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2InitializationError8: {
            trans: 'SpO2 - ERROR DE INICIALIZACIÓN',
            keyword: 'SpO2',
          },
          spo2CommunicationStopped: {
            trans: 'SpO2 - COMUNICACIÓN INTERROMPIDA',
            keyword: 'SpO2',
          },
          spo2CommunicationError: {
            trans: 'SpO2 - ERROR DE COMUNICACIÓN',
            keyword: 'SpO2',
          },
          spo2OutOfRange: {
            trans: 'SpO2 - NO IDENTIFICADA',
            keyword: 'SpO2',
          },
          spo2WeakPulse: {
            trans: 'SpO2 - PULSO DÉBIL',
            keyword: 'SpO2',
          },
          spo2WeakSignal: {
            trans: 'SpO2 - SEÑAL DÉBIL',
            keyword: 'SpO2',
          },
          spo2CheckSensor: {
            trans: 'SpO2 - VERIFICAR SENSOR',
            keyword: 'SpO2',
          },
          spo2SensorInMotion: {
            trans: 'SpO2 - SENSOR EN MOVIMIENTO',
            keyword: 'SpO2',
          },
          spo2Interference: {
            trans: 'SpO2 - INTERFERENCIA',
            keyword: 'SpO2',
          },
          spo2LowPerfusion: {
            trans: 'SpO2 - BAJA PERFUSIÓN',
            keyword: 'SpO2',
          },
          spo2TooMuchLight: {
            trans: 'SpO2 - EXCESO DE LUZ',
            keyword: 'SpO2',
          },
          spo2UnrecognizedSensor: {
            trans: 'SpO2 - SENSOR NO RECONOCIDO',
            keyword: 'SpO2',
          },
          spo2SensorFault: {
            trans: 'SpO2 - FALLA EN SENSOR',
            keyword: 'SpO2',
          },
          spo2NoSensor: {
            trans: 'SpO2 - AUSENCIA DE SENSOR',
            keyword: 'SpO2',
          },
          spo2LowSignal: {
            trans: 'SpO2 - SEÑAL BAJA',
            keyword: 'SpO2',
          },
          spo2IncompatibleSensor: {
            trans: 'SpO2 - SENSOR INCOMPATIBLE',
            keyword: 'SpO2',
          },
          tempInitializationError: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError1: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError2: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError3: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError4: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError5: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError6: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError7: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempInitializationError8: {
            trans: 'TEMP - ERROR DE INICIALIZACIÓN',
            keyword: 'TEMPERATURA',
          },
          t1OutOfRange: {
            trans: 'T1 - NO IDENTIFICADA',
            keyword: 'T1',
          },
          t2OutOfRange: {
            trans: 'T2 - NO IDENTIFICADA',
            keyword: 'T2',
          },
          tdOutOfRange: {
            trans: 'TD - NO IDENTIFICADA',
            keyword: 'TD',
          },
          tempCommunicationError: {
            trans: 'TEMP - ERROR DE COMUNICACIÓN',
            keyword: 'TEMPERATURA',
          },
          tempCommunicationStopped: {
            trans: 'TEMP - COMUNICACIÓN INTERROMPIDA',
            keyword: 'TEMPERATURA',
          },
          tempSelftestError: {
            trans: 'TEMP - FALLA EN AUTOTEST',
            keyword: 'TEMPERATURA',
          },
          tempCalibrationError: {
            trans: 'TEMP - ERROR DE CALIBRACIÓN',
            keyword: 'TEMPERATURA',
          },
        },
        infusion: {
          endOfLimitTime: {
            trans: 'FIN DEL TIEMPO LÍMITE DE INFUSIÓN',
            keyword: 'FIN INFUSIÓN',
          },
          endOfVolumeTime: {
            trans: 'FIN VOLUMEN/TIEMPO DE INFUSIÓN',
            keyword: 'FIN INFUSIÓN',
          },
          battery: {
            trans: 'BATERÍA',
            keyword: 'BATERÍA',
          },
          occlusion: {
            trans: 'OBSTRUCCIÓN',
            keyword: 'OBSTRUCCIÓN',
          },
          syringeClamp: {
            trans: 'FALLA EN CLAMP DE JERINGA',
            keyword: 'CLAMP',
          },
          flange: {
            trans: 'FALLA EN ENCAJE DE BRIDA DE JERINGA',
            keyword: 'FLANGE',
          },
          plungerHead: {
            trans: 'FALLA EN ENCAJE DEL ÉMBOLO DE JERINGA',
            keyword: 'ÉMBOLO',
          },
          disengagement: {
            trans: 'DESCONEXIÓN DEL CIRCUITO',
            keyword: 'CIRCUITO',
          },
          forceSensor: {
            trans: 'SENSOR DE FUERZA',
            keyword: 'SENSOR FUERZA',
          },
          pcaProtectiveCoverMissing: {
            trans: 'PCA - AUSENCIA DE TAPA DE PROTECCIÓN',
            keyword: 'PCA',
          },
          pcaProtectiveCoverNotLocked: {
            trans: 'PCA - TAPA DE PROTECCIÓN ABIERTA',
            keyword: 'PCA',
          },
          pcaMaxVolumeInfused: {
            trans: 'PCA - VOLUMEN MÁXIMO INFUNDIDO',
            keyword: 'PCA',
          },
          pcaMaxDoseInfused: {
            trans: 'PCA - DOSIS MÁXIMA INFUNDIDA',
            keyword: 'PCA',
          },
          clampMissing: {
            trans: 'AUSENCIA DE CLAMP',
            keyword: 'CLAMP',
          },
          endOfInfusion: {
            trans: 'FIN DE INFUSIÓN',
            keyword: 'FIN INFUSIÓN',
          },
          endOfSecondaryInfusion: {
            trans: 'FIN DE INFUSIÓN SECUNDARIA',
            keyword: 'FIN INFUSIÓN',
          },
          downstreamOcclusion: {
            trans: 'OBSTRUCCIÓN DE FLUJO FINAL',
            keyword: 'OBSTRUCCIÓN',
          },
          doorOpened: {
            trans: 'PUERTA ABIERTA',
            keyword: 'PUERTA ABIERTA',
          },
          setNotDetectedByPressureDownstreamSensor: {
            trans: 'SISTEMA NO DETECTADO POR SENSOR DE PRESIÓN FINAL',
            keyword: 'SISTEMA',
          },
          setNotDetectedByUpstreamPressureSensor: {
            trans: 'SISTEMA NO DETECTADO POR SENSOR DE PRESIÓN INICIAL',
            keyword: 'SISTEMA',
          },
          setNotInstalledCorrectly: {
            trans: 'SISTEMA NO INSTALADO CORRECTAMENTE',
            keyword: 'SISTEMA',
          },
          upstreamOcclusion: {
            trans: 'OBSTRUCCIÓN DE FLUJO INICIAL',
            keyword: 'OBSTRUCCIÓN',
          },
          emptyBagUpstreamPressure: {
            trans: 'BOLSA VACÍA IDENTIFICADA DURANTE PRESIÓN INICIAL',
            keyword: 'BOLSA VACÍA',
          },
          uncontrolledFlow: {
            trans: 'FLUJO DESCONTROLADO',
            keyword: 'FLUJO',
          },
          noDrop: {
            trans: 'SIN GOTEO',
            keyword: 'GOTEO',
          },
          underflowDetectedByDropSensor: {
            trans: 'FLUJO POR DEBAJO DEL ESPERADO',
            keyword: 'FLUJO',
          },
          overflowDetectedByDropSensor: {
            trans: 'FLUJO POR ENCIMA DEL ESPERADO',
            keyword: 'FLUJO',
          },
          absenceOfDropSensor: {
            trans: 'AUSENCIA DE SENSOR DE GOTEO',
            keyword: 'GOTEO',
          },
          dropSensorHasBeenConnectedDuringInfusion: {
            trans: 'SENSOR DE GOTEO CONECTADO DURANTE INFUSIÓN',
            keyword: 'GOTEO',
          },
          airDetection: {
            trans: 'AIRE EN CIRCUITO',
            keyword: 'PRESENCIA AIRE',
          },
          airVolumeExceededLimit: {
            trans: 'EXCESO DE AIRE EN CIRCUITO',
            keyword: 'PRESENCIA AIRE',
          },
          airSensorDefective: {
            trans: 'FALLA EN SENSOR DE AIRE',
            keyword: 'SENSOR AIRE',
          },
          endOfLimitVolume: {
            trans: 'FIN DEL VOLUMEN LÍMITE DE INFUSIÓN',
            keyword: 'FIN INFUSIÓN',
          },
          pcaPatientHandsetMissing: {
            trans: 'PCA - MÓDULO DE CONTROL DEL PACIENTE NO LOCALIZADO',
            keyword: 'PCA',
          },
          pcaPresenceOfProtectiveCover: {
            trans: 'PCA - PRESENCIA DE TAPA DE SEGURIDAD',
            keyword: 'PCA',
          },
          pcaMaxNumberOfPatientBoluses: {
            trans: 'PCA - CANTIDAD MÁXIMA DE BOLUS ACCIONADOS POR PACIENTE',
            keyword: 'PCA',
          },
          endOfVolumeLimitKvo: {
            trans: 'KVO - FIN DEL VOLUMEN LÍMITE',
            keyword: 'KVO',
          },
          endOfVolumeTimeKvo: {
            trans: 'KVO - FIN DE VOLUMEN / TIEMPO',
            keyword: 'KVO',
          },
          emptySyringeMode: {
            trans: 'MODO JERINGA VACÍA',
            keyword: 'JERINGA',
          },
          mainUnplugged: {
            trans: 'SISTEMA DESCONECTADO',
            keyword: 'SISTEMA',
          },
          pressureRise: {
            trans: 'PRESIÓN AUMENTADA',
            keyword: 'PRESIÓN',
          },
          pressureDown: {
            trans: 'PRESIÓN DISMINUIDA',
            keyword: 'PRESIÓN',
          },
          keyboardLocked: {
            trans: 'TECLADO BLOQUEADO',
            keyword: 'TECLADO',
          },
          keyboardLockedAvailableInIdleMode: {
            trans: 'TECLADO BLOQUEADO DISPONIBLE EN MODO INACTIVO',
            keyword: 'TECLADO',
          },
          flowRateLow: {
            trans: 'FLUJO BAJO',
            keyword: 'FLUJO BAJO',
          },
          flowRateHigh: {
            trans: 'FLUJO ALTO',
            keyword: 'FLUJO ALTO',
          },
          loadingDoseLow: {
            trans: 'DOSIS INICIAL BAJA',
            keyword: 'DOSIS INICIAL',
          },
          loadingDoseHigh: {
            trans: 'DOSIS INICIAL ALTA',
            keyword: 'DOSIS INICIAL',
          },
          infusionStartDelayedAfterEndOfPause: {
            trans: 'INFUSIÓN RETRASADA DESPUÉS DE PAUSA',
            keyword: 'INFUSIÓN',
          },
          weakBattery: {
            trans: 'BATERÍA DÉBIL',
            keyword: 'BATERÍA',
          },
          tciConcentrationLow: {
            trans: 'TCI - CONCENTRACIÓN BAJA',
            keyword: 'TCI',
          },
          tciConcentrationHigh: {
            trans: 'TCI - CONCENTRACIÓN ALTA',
            keyword: 'TCI',
          },
          nonValidationWhenNoAction: {
            trans: 'AUSENCIA DE VALIDACIÓN MIENTRAS NO HAY ACCIÓN',
            keyword: 'SISTEMA',
          },
          nonValidationWhileWaiting: {
            trans: 'AUSENCIA DE VALIDACIÓN DURANTE ESPERA',
            keyword: 'SISTEMA',
          },
          nonValidationWhenChoosingValue: {
            trans: 'AUSENCIA DE VALIDACIÓN DURANTE AJUSTE DE VALORES',
            keyword: 'SISTEMA',
          },
          deviceTemperatureHigh: {
            trans: 'TEMPERATURA DEL EQUIPO ELEVADA',
            keyword: 'EQUIPAMIENTO',
          },
          programmedBolusDoseLow: {
            trans: 'DOSIS BOLUS BAJA',
            keyword: 'DOSIS BOLUS',
          },
          programmedBolusDoseHigh: {
            trans: 'DOSIS BOLUS ALTA',
            keyword: 'DOSIS BOLUS',
          },
          keyboardLockedScreen: {
            trans: 'PANTALLA DEL TECLADO BLOQUEADA',
            keyword: 'TECLADO',
          },
          mainsFluctuation: {
            trans: 'FLUCTUACIÓN EN SISTEMA PRINCIPAL',
            keyword: 'SISTEMA',
          },
          pcaEndOfDoctorBolusWithMaxDoseInfused: {
            trans: 'PCA - FIN DE APLICACIÓN DE DOSIS MÁXIMA INFUNDIDA POR BOLUS',
            keyword: 'PCA',
          },
          clinicalInformation: {
            trans: 'INFORMACIÓN CLÍNICA',
            keyword: 'SISTEMA',
          },
          occlusivityControlNotCompliant: {
            trans: 'CONTROL DE OCLUSIÓN INADECUADO',
            keyword: 'OBSTRUCCIÓN',
          },
          endOfInfusionKvo: {
            trans: 'KVO - FIN DE INFUSIÓN',
            keyword: 'KVO',
          },
          mainsSupplyOscillation: {
            trans: 'RED ELÉCTRICA INESTABLE',
            keyword: 'EQUIPAMIENTO',
          },
          dropSensorPresent: {
            trans: 'SENSOR DE GOTEO PRESENTE',
            keyword: 'GOTEO',
          },
          airAlarm: {
            trans: 'AIRE EN CIRCUITO',
            keyword: 'PRESENCIA AIRE',
          },
          airAlarmAirBubbleTooLarge: {
            trans: 'BURBUJAS DE AIRE MUY GRANDES EN CIRCUITO',
            keyword: 'PRESENCIA AIRE',
          },
          airAlarmAirRateExceeded: {
            trans: 'VOLUMEN DE AIRE EN CIRCUITO EXCEDIDO',
            keyword: 'PRESENCIA AIRE',
          },
          airAlarmSensorTestFaulty: {
            trans: 'FALLA EN PRUEBA DE SENSOR DE AIRE',
            keyword: 'SISTEMA',
          },
          batteryAlarm: {
            trans: 'ALARMA DE BATERÍA',
            keyword: 'BATERÍA',
          },
          batteryCoverOpen: {
            trans: 'TAPA DE BATERÍA ABIERTA',
            keyword: 'BATERÍA',
          },
          batteryDefective: {
            trans: 'BATERÍA DEFECTUOSA',
            keyword: 'BATERÍA',
          },
          batteryNearFlat: {
            trans: 'BATERÍA CASI DESCARGADA',
            keyword: 'BATERÍA',
          },
          batteryNotInPump: {
            trans: 'AUSENCIA DE BATERÍA EN BOMBA',
            keyword: 'BATERÍA',
          },
          batteryVoltageTooLow: {
            trans: 'VOLTAJE DE BATERÍA MUY BAJO',
            keyword: 'BATERÍA',
          },
          calibrationDataCorrupt: {
            trans: 'DATOS DE CALIBRACIÓN CORRUPTOS',
            keyword: 'EQUIPAMIENTO',
          },
          clawMalfunction: {
            trans: 'MAL FUNCIONAMIENTO DE LA GARRA',
            keyword: 'EQUIPAMIENTO',
          },
          dangerOfFreeFlow: {
            trans: 'PELIGRO DE FLUJO LIBRE',
            keyword: 'FLUJO LIBRE',
          },
          dataLockUnauthorizedManipulation: {
            trans: 'MANIPULACIÓN NO PERMITIDA',
            keyword: 'SISTEMA',
          },
          doorWasOpenedDuringTheInfusion: {
            trans: 'PUERTA ABIERTA DURANTE LA INFUSIÓN',
            keyword: 'PUERTA ABIERTA',
          },
          dropAlarmFreeFlow: {
            trans: 'FLUJO LIBRE',
            keyword: 'FLUJO LIBRE',
          },
          dropAlarmNoDropSensor: {
            trans: 'AUSENCIA DE SENSOR DE GOTEO',
            keyword: 'EQUIPAMIENTO',
          },
          dropAlarmNoDrops: {
            trans: 'SIN GOTEO',
            keyword: 'GOTEO',
          },
          dropAlarmTooLessDrops: {
            trans: 'GOTEO MUY LENTO',
            keyword: 'GOTEO',
          },
          dropAlarmTooManyDrops: {
            trans: 'GOTEO MUY RÁPIDO',
            keyword: 'GOTEO',
          },
          infusionTimeExpired: {
            trans: 'TIEMPO DE INFUSIÓN FINALIZADO',
            keyword: 'FIN INFUSIÓN',
          },
          kvoEnd: {
            trans: 'FIN DEL KVO',
            keyword: 'KVO',
          },
          kvoOperation: {
            trans: 'KVO',
            keyword: 'KVO',
          },
          occlusionTestEncountersAnError: {
            trans: 'ERROR IDENTIFICADO POR LA PRUEBA DE OCLUSIÓN',
            keyword: 'SISTEMA',
          },
          pressureSensorDefect: {
            trans: 'DEFECTO EN SENSOR DE PRESIÓN',
            keyword: 'EQUIPAMIENTO',
          },
          pressureTooHigh: {
            trans: 'PRESIÓN MUY ALTA',
            keyword: 'PRESIÓN',
          },
          pressureTooHighDriveBlocked: {
            trans: 'INFUSIÓN BLOQUEADA DEBIDO A ALTA PRESIÓN',
            keyword: 'PRESIÓN',
          },
          standbyTimeExpired: {
            trans: 'TIEMPO DE ESPERA FINALIZADO',
            keyword: 'ESPERA',
          },
          syringeEmpty: {
            trans: 'JERINGA VACÍA',
            keyword: 'JERINGA',
          },
          syringeNotInsertedCorrect: {
            trans: 'JERINGA NO INSERTADA CORRECTAMENTE',
            keyword: 'JERINGA',
          },
          syringeNotInsertedSecurely: {
            trans: 'JERINGA NO INSERTADA DE MODO SEGURO',
            keyword: 'JERINGA',
          },
          timeNearEnd: {
            trans: 'TIEMPO DE INFUSIÓN CERCANO AL FIN',
            keyword: 'TIEMPO INFUSIÓN',
          },
          volumeInfused: {
            trans: 'VOLUMEN INFUNDIDO',
            keyword: 'INFUSIÓN',
          },
          volumeToBeDeliveredNearEnd: {
            trans: 'VOLUMEN DE INFUSIÓN PREVISTO CERCANO AL FIN',
            keyword: 'INFUSIÓN',
          },
          oclusO: {
            trans: 'OBSTRUCCIÓN',
            keyword: 'OBSTRUCCIÓN',
          },
          vazOLivre: {
            trans: 'FLUJO LIBRE',
            keyword: 'FLUJO LIBRE',
          },
          bateriaCrTica: {
            trans: 'BATERÍA CRÍTICA',
            keyword: 'BATERÍA',
          },
          infusOConcluDa: {
            trans: 'INFUSIÓN CONCLUIDA',
            keyword: 'FIN INFUSIÓN',
          },
          alarmeDeTravamento: {
            trans: 'BLOQUEO',
            keyword: 'SISTEMA',
          },
          alarmeDeDesengateMotor: {
            trans: 'DESENGANCHE DE JERINGA',
            keyword: 'JERINGA',
          },
          alarmeIdentificaODeSeringa: {
            trans: 'IDENTIFICACIÓN DE JERINGA',
            keyword: 'JERINGA',
          },
          alarmePosiODeSeringa: {
            trans: 'POSICIÓN DE JERINGA',
            keyword: 'JERINGA',
          },
          tampaPcaAberta: {
            trans: 'PCA - TAPA ABIERTA',
            keyword: 'PCA',
          },
          erroSensorDePosiO: {
            trans: 'ERROR EN SENSOR DE POSICIÓN',
            keyword: 'EQUIPAMIENTO',
          },
          erroSensorDaSeringa: {
            trans: 'ERROR EN SENSOR DE JERINGA',
            keyword: 'EQUIPAMIENTO',
          },
          erroNoAcionadorPca: {
            trans: 'ERROR EN ACCIONADOR DEL PCA',
            keyword: 'EQUIPAMIENTO',
          },
          portaAberta: {
            trans: 'PUERTA ABIERTA',
            keyword: 'SISTEMA',
          },
          erroNoMecanismo: {
            trans: 'ERROR EN MECANISMO DE LA BOMBA',
            keyword: 'EQUIPAMIENTO',
          },
          arNaLinha: {
            trans: 'AIRE EN LA LÍNEA',
            keyword: 'PRESENCIA AIRE',
          },
          frascoVazio: {
            trans: 'FRASCO VACÍO',
            keyword: 'FRASCO VACÍO',
          },
          erroNoSensorGotas: {
            trans: 'ERROR EN SENSOR DE GOTEO',
            keyword: 'GOTEO',
          },
          erroCortaFluxo: {
            trans: 'ERROR EN CORTA-FLUJO',
            keyword: 'EQUIPAMIENTO',
          },
          sistemaReiniciado: {
            trans: 'SISTEMA REINICIADO',
            keyword: 'SISTEMA',
          },
          oclusOSuperior: {
            trans: 'OBSTRUCCIÓN SUPERIOR',
            keyword: 'OBSTRUCCIÓN',
          },
          soluOConcluDa: {
            trans: 'INFUSIÓN CONCLUIDA',
            keyword: 'FIN INFUSIÓN',
          },
        },
        air: {
          o2ConcentrationTooHigh: {
            trans: 'CONCENTRACIÓN DE O2 MUY ALTA',
            keyword: 'CONC. O2',
          },
          o2ConcentrationTooLow: {
            trans: 'CONCENTRACIÓN DE O2 MUY BAJA',
            keyword: 'CONC. O2',
          },
          upperPressureExceeded: {
            trans: 'PRESIÓN SUPERIOR EXCEDIDA',
            keyword: 'PRESIÓN',
          },
          expMinuteVolume: {
            trans: 'VOLUMEN/MIN EXPIRATORIO',
            keyword: 'EXPIRACIÓN',
          },
          apneaAlarmBackupVentilation: {
            trans: 'APNEA',
            keyword: 'APNEA',
          },
          gasSupplyAlarm: {
            trans: 'ALARMA DE SUMINISTRO DE GASES',
            keyword: 'GASES',
          },
          battery: {
            trans: 'BATERÍA',
            keyword: 'BATERÍA',
          },
          powerFailure: {
            trans: 'FALLA EN LA ALIMENTACIÓN DE ENERGÍA',
            keyword: 'ENERGÍA',
          },
          mainsFailure: {
            trans: 'FALLA EN EL FUNCIONAMIENTO DEL VENTILADOR',
            keyword: 'VENTILADOR',
          },
          barometerError: {
            trans: 'ERROR EN EL BARÓMETRO',
            keyword: 'BARÓMETRO',
          },
          highContinuousPressure: {
            trans: 'PRESIÓN CONTINUA ELEVADA',
            keyword: 'PRESIÓN',
          },
          o2CellDisconnect: {
            trans: 'CÉLULA DE O2 DESCONECTADA',
            keyword: 'CÉLULA O2',
          },
          timeInWaitingPositionExceeded2Min: {
            trans: 'TIEMPO EN POSICIÓN DE ESPERA EXCEDIDO 2 MIN',
            keyword: 'ESPERA',
          },
          noPatientEffort: {
            trans: 'ESFUERZO DEL PACIENTE NO DETECTADO',
            keyword: 'ESFUERZO',
          },
          leakageOutOfRange: {
            trans: 'FUGA FUERA DE LO USUAL',
            keyword: 'FUGA',
          },
          checkTubing: {
            trans: 'VERIFICAR LA TUBERÍA',
            keyword: 'TUBERÍA',
          },
          regulationPressureLimited: {
            trans: 'REGULACIÓN DE PRESIÓN LIMITADA',
            keyword: 'PRESIÓN',
          },
          breathFrequencyHigh: {
            trans: 'FR ALTA',
            keyword: 'FR ALTA',
          },
          breathFrequencyLow: {
            trans: 'FR BAJA',
            keyword: 'FR BAJA',
          },
          peepLow: {
            trans: 'PEEP BAJA',
            keyword: 'PEEP BAJA',
          },
          peepHigh: {
            trans: 'PEEP ALTA',
            keyword: 'PEEP ALTA',
          },
          expMinuteVolumeTooHigh: {
            trans: 'VOLUMEN/MIN EXPIRATORIO ALTO',
            keyword: 'EXPIRACIÓN',
          },
          expMinuteVolumeTooLow: {
            trans: 'VOLUMEN/MIN EXPIRATORIO BAJO',
            keyword: 'EXPIRACIÓN',
          },
          noConsistentPatientEffort: {
            trans: 'AUSENCIA DE ESFUERZO CONSISTENTE DEL PACIENTE',
            keyword: 'ESFUERZO',
          },
          highPriorityAlarm: {
            trans: 'ALARMA DE ALTA PRIORIDAD',
            keyword: 'VENTILADOR',
          },
        },
        bed: {},
      },
    },
  },
};
