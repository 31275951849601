import Section from 'components/core/Section';
import React from 'react';
import { List } from './styles';

export default function CompositeLog({ log, formatLog }) {
  return (
    <div>
      <span>{formatLog(log)}</span>
      <Section isFolded>
        <List>
          {
            log.children.map(
              (l) => <li>{formatLog(l)}</li>,
            )
          }
        </List>
      </Section>
    </div>
  );
}
