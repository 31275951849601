export const paths = {
  root: '/',
  login: '/login',
  recoverPassword: '/login/recover-password',
  newPassword: '/login/reset-password',
  settings: '/settings',
  userManagement: '/settings/user-management/',
  user: '/settings/user-management/user/',
  userLogs: '/settings/user-management/user-logs/',
  frequencyERP: '/settings/assistentialManagement/frequency-erp/',
  alarmsConfig: '/settings/assistentialManagement/alarms-config/',
  createPreset: '/settings/assistentialManagement/create-preset/',
  editPreset: '/settings/assistentialManagement/edit-preset/',
  assistentialManagement: '/settings/assistentialManagement',
  generalSettings: '/settings/generalSettings',
  deployment: '/settings/generalSettings/deployment',
  bed: '/bed/',
  monitor: 'monitor/*',
  infusion: 'infusion/*',
  patientRecord: '/patientRecord/',
  downloadMozart: '/mozart-app',
  label: '/label',
};
