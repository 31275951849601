import styled from 'styled-components';

import { breakpoints, fontSize } from 'config/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (min-width: ${breakpoints.xs}) {
    width: 360px;
  }
`;

export const Title = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text.w000};
  display: flex;
  font-size: ${fontSize.xs};
  gap: 12px;
  margin: 0 auto;
  justify-content: space-between;
  width: fit-content;

  h2 {
    font-size: ${fontSize.lg};
    padding: 12px;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${fontSize.xs};
  text-align: center;
`;
