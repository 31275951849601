import styled, { css } from 'styled-components';

export const Container = styled.div`
  left: calc(50% - 40px);
  position: absolute;
  top: calc(50% - 40px);
  width: 80px;
  z-index: 99;
  ${({ fixed }) => fixed && css`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;
    & > div {
      width: 80px;
      height: 80px;
    }
  `}
`;
