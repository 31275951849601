import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { zIndex } from 'config/constants';

import Modal from 'components/layout/Modal';
import Button, { models } from 'components/core/Button';

import logUserAction, { USER_LOGOUT } from 'services/apollo/log/logUserAction';
import { useApolloClient } from '@apollo/client';
import useUser from 'services/cognito/user';
import {
  Container, Title, Text, ButtonWrapper,
} from './styles';
import { toggleExitModal } from './actions';

export default function ExitModal() {
  const { showExitModal } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const { user, refresh: refreshUser } = useUser();

  function closeModal() {
    dispatch(toggleExitModal());
  }

  return (
    <Modal
      onCloseModal={closeModal}
      zIndex={zIndex.lv4}
      blurBackground
      show={showExitModal}
    >
      <Container>
        <Title>
          {t('logOut')}
        </Title>
        <Text>
          {t('text.logoutConfirmation')}
        </Text>
        <ButtonWrapper>
          <Button
            type="button"
            margin="0 auto"
            onClick={() => {
              closeModal();
              logUserAction(apolloClient, USER_LOGOUT);
              user.logout();
              refreshUser();
            }}
            text={t('button.logOut')}
            model={models.modelB}
            width="120px"
          />
          <Button
            type="button"
            margin="0 auto"
            onClick={closeModal}
            text={t('button.back')}
            model={models.modelBSecondary}
            width="120px"
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
