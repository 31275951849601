import styled from 'styled-components';
import { fontSize, zIndex } from 'config/constants';
import { states } from './constants';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => width};
  input::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`;

const dropShadow = (theme) => `drop-shadow(0 0 6px ${theme.colors.shadow})`;

const getBorderColor = (theme, state) => {
  switch (state) {
    case states.disabled:
      return theme.colors.secondary.w100;
    case states.error:
      return theme.colors.feedback.alert;
    default:
      return theme.colors.secondary.w050;
  }
};

export const StyledInput = styled.input`
  background: ${({ theme, state }) => (state === states.disabled ? theme.colors.input.w200 : theme.colors.input.w100)};
  color: ${({ theme, state }) => (state === states.disabled ? theme.colors.text.w000 : theme.colors.secondary.w500)};
  border-radius: 50px;
  border: 1px solid ;
  border-color: ${({ theme, state }) => getBorderColor(theme, state)};
  font-size: ${fontSize.sm};
  font-weight: 400;
  height: ${({ height }) => height};
  outline: none;
  padding: 0 15px;
  padding-right: ${({ isSearch }) => (isSearch ? '40px' : '15px')};
  text-align: ${({ textAlign }) => (textAlign || 'center')};
  width: 100%;

  &::-ms-reveal {
    display: none;
  }

  &:focus {
    border-color: ${({ theme, state }) => (state === states.error ? theme.colors.feedback.alert : theme.colors.primary.w100)};
    filter: ${({ theme }) => dropShadow(theme)};
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin: -32px;
  z-index: ${zIndex.lv0};
`;

export const Feedback = styled.p`
  bottom: -22px;
  color: ${({ theme }) => theme.colors.feedback.alert};
  font-size: ${fontSize.xs};
  font-weight: 200;
  position: absolute;
  right: 20px;
`;

export const Label = styled.p`
  color: ${({ theme, contrast }) => (contrast ? theme.colors.text.w400 : theme.colors.text.w000)};
  font-size: ${fontSize.sm};
  font-weight: 200;
  margin: 0 auto 4px 14px;

  @media (max-width: 992px) {
    font-size: ${fontSize.xs};
  }
`;

export const WrapperIconAndInputToggether = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;
