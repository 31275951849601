import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from 'components/core/Checkbox';
import Radio from 'components/core/Radio';

import { Container, Item } from './styles';

export default function CheckBoxList({
  items, callback, id, canSelectMultipleItems, defaultSelectedItems,
}) {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (defaultSelectedItems?.length > 0) {
      const defaultSelectedItemsValues = defaultSelectedItems.map((item) => item.value);
      setSelectedItems(defaultSelectedItemsValues);
    }
  }, [defaultSelectedItems]);

  useEffect(() => {
    const result = items.filter((item) => selectedItems.includes(item.value));
    callback(result);
  }, [callback, items, selectedItems]);

  const { t } = useTranslation();

  function handleSelectAllChange() {
    const itemsValue = items.map((item) => item.value);
    const isAllSelected = selectedItems.length === items.length;

    if (isAllSelected) {
      setSelectedItems([]);
      return;
    }
    setSelectedItems(itemsValue);
  }

  function handleChange(item) {
    const shouldRemoveItem = selectedItems.includes(item.value);
    if (shouldRemoveItem) {
      const result = selectedItems.filter((i) => i !== item.value);
      setSelectedItems(result);
      return;
    }
    setSelectedItems((prev) => ([...prev, item.value]));
  }
  function handleRadioClick(item) {
    setSelectedItems(item.value);
  }

  if (!items) return null;
  return (
    <Container id={id}>
      {
        canSelectMultipleItems && (
          <Item key="select_all">
            <Checkbox
              text={t('selectAll')}
              onChange={handleSelectAllChange}
              value="select_all"
              id="select_all"
              isControlled
              readOnly
              checked={selectedItems.length === items.length}
              cy="noEmail"
            />
          </Item>
        )
      }
      {
        items.map((item) => (
          <Item key={item.value}>
            {
              canSelectMultipleItems ? (
                <Checkbox
                  className="checkbox_list_item"
                  text={item.label}
                  onChange={() => handleChange(item)}
                  value={item.value}
                  isControlled
                  readOnly
                  checked={selectedItems.includes(item.value)}
                />
              ) : (
                <Radio
                  name="hospital"
                  className="checkbox_list_item"
                  text={item.label}
                  onChange={() => handleRadioClick(item)}
                  value={item.value}
                />
              )
            }
          </Item>
        ))
      }
    </Container>
  );
}

CheckBoxList.defaultProps = {
  items: [],
  callback: () => { /** */ },
  canSelectMultipleItems: true,
};
