import React, { useEffect, useState } from 'react';

import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { models } from './constants';

import { Container, Feedback, Label } from './styles';

export default function Select({
  options,
  placeholder,
  model,
  label,
  onChange,
  feedbackMessage: externalFeedbackMessage,
  required,
  menuPlacement,
  isLoading,
  defaultValue,
  value,
  readonly,
  isMulti,
  inline,
  textAlign,
  id,
  cy,
}) {
  const [feedbackMessage, setFeedbackMessage] = useState(externalFeedbackMessage);
  const tempTheme = useSelector((state) => state.theme);
  const { t } = useTranslation();

  function handleChange(event) {
    const shouldShowRequiredFieldMessage = (event.value === '' && required);

    onChange(event);

    if (shouldShowRequiredFieldMessage) {
      setFeedbackMessage(t('feedback.requiredField'));
      return;
    }
    setFeedbackMessage(null);
  }

  useEffect(() => {
    setFeedbackMessage(externalFeedbackMessage);
  }, [externalFeedbackMessage]);

  if (!defaultValue && model === models.dark) return null;

  return (
    <Container model={model} inline={inline} textAlign={textAlign} data-cy={cy}>
      {label && <Label>{label}</Label>}
      <ReactSelect
        id={id}
        value={value}
        isLoading={isLoading}
        className="react-select"
        classNamePrefix="react-select"
        blurInputOnSelect
        placeholder={placeholder}
        defaultValue={defaultValue}
        menuPlacement={menuPlacement}
        onChange={(event) => handleChange(event)}
        options={options}
        isMulti={isMulti}
        isDisabled={readonly}
        isSearchable={!readonly}
        menuIsOpen={readonly ? false : undefined}
        theme={(theme) => ({
          ...theme,
          colors: {
            primary: tempTheme.colors.primary.w100,
            primary75: tempTheme.colors.primary.w200,
            primary50: tempTheme.colors.primary.w200,
            primary25: tempTheme.colors.primary.w200,
            neutral50: '#ffffffd3',
          },
        })}
      />
      <Feedback>{feedbackMessage}</Feedback>
    </Container>
  );
}

Select.defaultProps = {
  onChange: () => { /* */ },
  model: models.light,
  options: [],
  placeholder: '',
  required: false,
  label: '',
  menuPlacement: 'auto',
  isLoading: false,
  defaultValue: null,
  isMulti: false,
  textAlign: 'center',
};

export { models };
