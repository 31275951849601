import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { validityEmail } from 'components/forms/AddUser/helpers/validityEmail';

import Icon, { names } from 'components/core/Icon';
import { isMobile } from 'utils/isMobile';
import { states } from './constants';

import {
  Container, IconContainer, StyledInput, Feedback, WrapperIconAndInputToggether, Label,
} from './styles';

export default function Input({
  autoComplete,
  contrast,
  cy,
  feedbackMessage: externalFeedbackMessage,
  height,
  id,
  isSearch,
  label,
  max,
  maxLength,
  name,
  onBlur,
  onChange,
  onSearchIconClick,
  placeholder,
  readonly,
  required,
  showPasswordToggle,
  state,
  textAlign,
  type,
  value,
  width,
  list,
}) {
  const [inputType, setInputType] = useState(type);
  const [feedbackMessage, setFeedbackMessage] = useState(externalFeedbackMessage);
  const [inputState, setInputState] = useState(state);

  const { t } = useTranslation();

  function handleClick(e) {
    e.preventDefault();
    if (inputType === 'password') setInputType('text');
    if (inputType === 'text') setInputType('password');
  }

  const eye = (inputType === 'password') ? names.visibilityOff : names.visibilityOn;

  function validateEmailField(email) {
    if (type !== 'email') return true;

    if (validityEmail(email)) {
      setFeedbackMessage(null);
      setInputState(states.default);
      return true;
    }

    setFeedbackMessage('E-mail inválido');
    setInputState(states.error);
    return false;
  }

  function validateRequiredField(text) {
    const shouldShowMessage = (text === '' && required);
    if (!shouldShowMessage) return true;

    setFeedbackMessage(t('feedback.requiredField'));
    setInputState(states.error);
    return false;
  }

  function handleOnBlur(event) {
    onBlur(event);

    const isRequiredFieldValid = validateRequiredField(event.target.value);
    const isEmailValid = validateEmailField(event.target.value);

    if (isRequiredFieldValid && isEmailValid && state !== states.error) {
      setFeedbackMessage(null);
      setInputState(states.default);
    }
  }

  useEffect(() => {
    setFeedbackMessage(externalFeedbackMessage);
    setInputState(state);
  }, [externalFeedbackMessage, state]);

  return (
    <Container width={width}>
      {label && <Label contrast={contrast}>{label}</Label>}
      <WrapperIconAndInputToggether>
        <StyledInput
          list={list}
          autoComplete={autoComplete}
          data-cy={cy}
          disabled={state === states.disabled || readonly}
          height={height}
          id={id}
          isSearch={isSearch}
          max={max}
          maxLength={maxLength}
          name={name}
          onBlur={handleOnBlur}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readonly}
          state={inputState}
          textAlign={textAlign}
          type={inputType}
          value={value}
        />
        {
          showPasswordToggle && (type === 'password') && !readonly && (
            <IconContainer onClick={(event) => handleClick(event)}>
              <Icon name={eye} width="20" />
            </IconContainer>
          )
        }
        {
          isSearch && !isMobile() ? (
            <IconContainer onClick={(event) => onSearchIconClick(event)}>
              <Icon name={names.search} />
            </IconContainer>
          ) : null
        }
      </WrapperIconAndInputToggether>
      {feedbackMessage ? <Feedback>{feedbackMessage}</Feedback> : null}
    </Container>
  );
}

export { states };

Input.defaultProps = {
  autoComplete: 'on',
  contrast: false,
  height: '45px',
  id: '',
  isSearch: false,
  label: null,
  maxLength: '',
  name: '',
  onBlur: () => { /** */ },
  onChange: () => { /** */ },
  onSearchIconClick: () => { /** */ },
  placeholder: '',
  required: false,
  state: states.default,
  showPasswordToggle: true,
  type: 'text',
  width: 'auto',
  readonly: false,
};
