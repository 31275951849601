import { breakpoints, fontSize } from 'config/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: none;
  
  @media (min-width: ${breakpoints.md}) {
    display: block;
    font-size: ${fontSize.md};
    grid-column: main;
    margin-bottom: 60px;
    overflow: hidden;
  }
`;
export const Thead = styled.thead`
  width: 100%;
  ${({ scrollBar }) => scrollBar && css`
    display: inline-table;
  `}
`;
export const Tbody = styled.tbody`
  &.scrollbar {
    max-height: 40vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    &::-webkit-scrollbar {
      width: 8px;
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.primary.w200};
      box-shadow: none;
    }
    &::-webkit-scrollbar-track {
      background-color: #000;
      box-shadow: none;
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0;
  overflow: hidden;
  width: 100%;
  ${({ scrollBar }) => scrollBar && css`
    display: block;
  `}
`;

export const Tr = styled.tr`
  color: ${({ theme }) => theme.colors.text.w000};
  height: 50px;
  ${({ scrollBar }) => scrollBar && css`
      width: 100%;
      display: inline-table;
  `}

  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.secondary.w200};
  }
  &:nth-child(even) {
    background: ${({ theme }) => theme.colors.secondary.w300};
  }
`;

export const HeaderText = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: fit-content;
`;

export const Th = styled.th`
  font-weight: 400;
  font-size: ${fontSize.sm};
  background: ${({ theme }) => theme.colors.secondary.w400};
  color: ${({ theme }) => theme.colors.primary.w200};
  padding: 0 20px;
  text-align: left;
  user-select: none;
  ${({ scrollBar }) => scrollBar && css`
    width: ${({ width }) => (width || 'auto')};
    display: inline-table;
  `}

  & > div {
    cursor: pointer;
  }
  
  &:nth-child(1) {
    width: 0;
  }
`;

export const Td = styled.td`
  font-weight: 400;
  font-size: ${fontSize.sm};
  padding: 0 20px;
  width: ${({ width }) => (width || 'auto')};
  oveflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LoadMore = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  cursor: pointer;
  display: none;
  font-size: ${fontSize.md};
  font-weight: 300;
  grid-column: main;
  margin-left: auto;
  transform: translateY(-62px);
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.w100};
  }

  @media (min-width: ${breakpoints.md}) {
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  }
`;

export const Arrow = styled.div`
  border-bottom-color: ${({ theme }) => theme.colors.primary.w100};
  border-bottom: 10px solid;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  display: ${({ isSorted }) => (isSorted ? 'block' : 'none')};
  height: 0px;
  position: absolute;
  right: -20px;
  top: 12px;
  transition: 200ms ease;  
  width: 0px;

  ${({ isSortedDesc }) => isSortedDesc && css`
    margin-left: 4px;
    transform: rotate(180deg);
  `}
`;
