import styled, { css } from 'styled-components';

import { zIndex } from 'config/constants';

const overlayIsShowing = css`
  background: #000000b8;
  display: block;
`;

export const Container = styled.div`
  background: rgba(0,0,0,0.9);
  bottom: 0;
  display: none;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0%;
  transition: all ease-in-out 200ms;
  width: 100%;
  z-index: ${zIndex.lv3};

  ${({ isVisible }) => isVisible && overlayIsShowing}
`;
