const dark = {
  name: 'dark',
  colors: {
    primary: {
      w100: '#4ECABF',
      w200: '#5EB6AF',
      gradient: 'linear-gradient(160deg, #029292 0%, #04B4B4 30%, #4ECABF 100%)',
    },
    secondary: {
      w000: '#FFFFFF',
      w050: '#B7B7B7',
      w100: '#676767',
      w200: '#555555',
      w200blur: '#555555f0',
      w300: '#464646',
      w350: '#313232',
      w400: '#252625',
      w400blur: '#252625f0',
      w500: '#1E1E1D',
      gradient: 'linear-gradient(160deg, #3B3B3B 10%, #292929 30%, #1f1f1f 70%)',
      gradientLeft: 'linear-gradient( to left, rgba(80,78,78,1) 0%, rgba(80,78,78,1) 30%, rgba(48,48,48,1) 67%, rgba(48,48,48,1) 100% )',
      gradientRight: 'linear-gradient( to right, rgba(80,78,78,1) 0%, rgba(80,78,78,1) 30%, rgba(48,48,48,1) 67%, rgba(48,48,48,1) 100% )',
      bedGradient: 'linear-gradient(to right, #333333 0%,#0f0e0e 51%)',
    },
    disabled: '#95B1AE80',
    shadow: '#9ec5be8f',
    feedback: {
      danger: '#C00000', // red
      alert: '#ffd200', // yellow
      fail: '#636363', // gray
    },
    text: {
      w000: '#ffffff',
      w100: '#a1a1a1',
      w200: '#898989',
      w400: '#707070',
      w500: '#252625',
      w600: '#1E1E1D',
    },
    input: {
      w100: '#F3F0EF',
    },
    levels: {
      veryLow: '#FFF700',
      lower: '#42C14E',
      veryHigher: '#FF0000',
    },
  },
};

export default dark;
