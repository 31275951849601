import { fontSize } from 'config/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

const Folded = css`
  max-height: 0;
  opacity: 0;
`;

const Unfolded = css`
  max-height: 5000px;
  opacity: 1;
`;

export const Foldable = styled.div`
  transition: 500ms;
  ${({ folded }) => (folded ? Folded : Unfolded)}
`;

export const FoldButton = styled.div`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.w000};
  cursor: pointer;
  font-size: ${fontSize.md};
  font-weight: 300;
  position: absolute;
  right: 10px;
  top: -30px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.w200}
  }
`;
