import React from 'react';
import { Container, List, ListItem } from './styles';

export default function LogList({ logs }) {
  return (
    <Container>
      <List>
        {
        logs.map(({ timestamp, action }) => (
          <ListItem key={timestamp}>
            <p>{timestamp}</p>
            <p>{action}</p>
          </ListItem>
        ))
      }
      </List>
    </Container>
  );
}

LogList.defaultProps = {
  logs: [],
};
