const VITAL_SIGN_INTERVAL = 60000;

function subscriptionFactory(id, callback) {
  let observationFunction = () => { /* */ };

  const interval = setInterval(() => {
    callback(id, observationFunction);
  }, VITAL_SIGN_INTERVAL);

  const subscription = {
    onData: (dataCallback) => {
      observationFunction = dataCallback;
    },
    unsubscribe: () => {
      clearInterval(interval);
    },
  };

  return subscription;
}

export const isInvasive = {
  IsInvasive: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    onData({
      isInvasive: {
        value: 'false',
        id,
      },
    });
  }),
};
