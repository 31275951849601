export const userManagement = {
  SET_ERRORS: 'USER_MANAGEMENT/SET_ERRORS',
  TOGGLE_CREATE_USERS_MODAL: 'USER_MANAGEMENT/TOGGLE_CREATE_USERS_MODAL',
  TOGGLE_EDIT_USERS_MODAL: 'USER_MANAGEMENT/TOGGLE_EDIT_USERS_MODAL',
  TOGGLE_SUCCESS_MODAL: 'USER_MANAGEMENT/TOGGLE_SUCCESS_MODAL',
  SET_SELECTED_USERS: 'USER_MANAGEMENT/SET_SELECTED_USERS',
};

export const modes = {
  create: 'create',
  edit: 'edit',
};
