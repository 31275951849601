import React from 'react';
import toSlug from 'utils/toSlug';
import { names } from './constants';

export default function Icon({
  name, width, height, alt, loading, margin, onClick, title, cy,
}) {
  return (
    <img
      title={title}
      role="presentation"
      onClick={onClick}
      width={width}
      height={height}
      alt={alt || name}
      loading={loading}
      src={`/assets/icons/${name}`}
      style={{ margin }}
      data-cy={cy ? (`icon-${toSlug(cy)}`) : ''}
    />
  );
}

Icon.defaultProps = {
  alt: 'icone',
  title: '',
  height: 'auto',
  loading: 'lazy',
  margin: '',
  onClick: () => { /** */ },
  width: 'auto',
};

export { names };
