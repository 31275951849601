import CognitoUser from './cognitoUser';
import { KEY_TO_STORAGE_DEMO_USER } from './settings';
import StorageHelper from './storageHelper';

export default class MockableCognitoUserPool {
  constructor(data, wrapRefreshSessionCallback) {
    const { UserPoolId, ClientId } = data || {};
    this.UserPoolId = UserPoolId;
    this.clientId = ClientId;

    this.storage = data.storage || new StorageHelper().getStorage();

    if (wrapRefreshSessionCallback) {
      this.wrapRefreshSessionCallback = wrapRefreshSessionCallback;
    }
  }

  getCurrentUser() {
    const lastAuthUser = this.storage.get(`${KEY_TO_STORAGE_DEMO_USER}`);
    if (lastAuthUser) {
      const cognitoUser = {
        Username: lastAuthUser,
        Pool: this,
        Storage: this.storage,
      };
      return new CognitoUser(cognitoUser);
    }
    return null;
  }
}
