export const ssvvWaves = [
  {
    name: 'heartRate',
    value: [
      {
        timeStamp: '2024-10-10T16:10:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:11:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:12:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:13:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:14:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:15:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:16:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:17:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:18:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:19:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:20:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:21:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:22:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:23:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:24:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:25:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:26:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:27:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:28:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:29:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:30:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:31:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:32:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:33:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:34:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:35:00.000Z',
        value: '60.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:36:00.000Z',
        value: '60.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:37:00.000Z',
        value: '60.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:38:00.000Z',
        value: '60.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:39:00.000Z',
        value: '60.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
    ],
    __typename: 'MonitorDashboardItem',
  },
  {
    name: 'respiratoryRate',
    value: [
      {
        timeStamp: '2024-10-10T16:10:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:11:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:12:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:13:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:14:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:15:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:16:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:17:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:18:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:19:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:20:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:21:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:22:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:23:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:24:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:25:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:26:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:27:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:28:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:29:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:30:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:31:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:32:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:33:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:34:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:35:00.000Z',
        value: '20.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:36:00.000Z',
        value: '20.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:37:00.000Z',
        value: '20.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:38:00.000Z',
        value: '20.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:39:00.000Z',
        value: '20.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
    ],
    __typename: 'MonitorDashboardItem',
  },
  {
    name: 'oxygenSaturation',
    value: [
      {
        timeStamp: '2024-10-10T16:10:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:11:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:12:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:13:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:14:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:15:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:16:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:17:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:18:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:19:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:20:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:21:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:22:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:23:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:24:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:25:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:26:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:27:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:28:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:29:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:30:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:31:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:32:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:33:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:34:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:35:00.000Z',
        value: '97.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:36:00.000Z',
        value: '98.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:37:00.000Z',
        value: '97.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:38:00.000Z',
        value: '96.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:39:00.000Z',
        value: '97.00',
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
    ],
    __typename: 'MonitorDashboardItem',
  },
  {
    name: 'carbonDioxide',
    value: [
      {
        timeStamp: '2024-10-10T16:10:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:11:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:12:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:13:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:14:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:15:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:16:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:17:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:18:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:19:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:20:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:21:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:22:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:23:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:24:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:25:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:26:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:27:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:28:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:29:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:30:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:31:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:32:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:33:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:34:00.000Z',
        value: '0',
        alarmStatus: null,
        isTemperatureEnabled: null,
        isCarbonDioxideEnabled: null,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:35:00.000Z',
        value: null,
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:36:00.000Z',
        value: null,
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:37:00.000Z',
        value: null,
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:38:00.000Z',
        value: null,
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
      {
        timeStamp: '2024-10-10T16:39:00.000Z',
        value: null,
        alarmStatus: null,
        isTemperatureEnabled: true,
        isCarbonDioxideEnabled: true,
        __typename: 'VitalSign',
      },
    ],
    __typename: 'MonitorDashboardItem',
  },
];
