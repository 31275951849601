import styled, { css } from 'styled-components';
import { breakpoints, fontSize, zIndex } from 'config/constants';
import { models } from './constants';

const dark = css`
  display: flex;
  min-width: 100px;
  width: min-content;

  .react-select {
    text-align: ${({ textAlign }) => textAlign};
    min-width: 160px;

    &__control {
      background: transparent;
      border-radius: 50px;
      border: none;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.secondary.w100};
      cursor: pointer;
      font-size: ${fontSize.sm};
      font-weight: 200;

      &--is-focused {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.w200};
      }

      @media (min-width: ${breakpoints.sm}) {
        min-height: 28px;
      }
    }

    &__option {
      background: ${({ theme }) => theme.colors.secondary.w200};
      color: ${({ theme }) => theme.colors.text.w000};
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.colors.secondary.w300};
      }

      @media (min-width: ${breakpoints.sm}) {
        background: ${({ theme }) => theme.colors.secondary.w400};
      }
    }

    &__single-value {
      color: ${({ theme }) => theme.colors.text.w000};
    }

    &__menu {
      background: ${({ theme }) => theme.colors.secondary.w200};
      border-radius: 20px;
      overflow: hidden;

      @media (min-width: ${breakpoints.sm}) {
        background: ${({ theme }) => theme.colors.secondary.w400};
      }
    }

    &__input-container {
      color: ${({ theme }) => theme.colors.text.w000};
      margin: 0;
      padding: 0;
    }

    &__placeholder {
      overflow: hidden;
      text-align: ${({ textAlign }) => textAlign};
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__indicator {
      color: ${({ theme }) => theme.colors.text.w000};
      padding-top: 0;
      padding-bottom: 0;
    }

    @media (min-width: ${breakpoints.xs}) {
      min-width: 200px;
    }

    @media (min-width: ${breakpoints.md}) {
      min-width: 200px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    width: auto;
  }
`;

const dropShadow = (theme) => `drop-shadow(0 0 6px ${theme.colors.shadow})`;

const light = css`
  .react-select {
    text-align: ${({ textAlign }) => textAlign};
    width: 100%;
    z-index: ${zIndex.lv1};

    &__control {
      background: ${({ theme }) => theme.colors.input.w100};
      border-radius: 50px;
      border: 1px solid ;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.secondary.w100};
      color: ${({ theme }) => theme.colors.text.w200};
      cursor: pointer;
      font-size: ${fontSize.sm};
      height: 45px;

      &--is-focused {
        border-color:${({ theme }) => theme.colors.primary.w200};
        filter: ${({ theme }) => dropShadow(theme)};
      }
    }

    &__option {
      background: ${({ theme }) => theme.colors.secondary.w000};
      color: ${({ theme }) => theme.colors.text.w600};
      cursor: pointer;

      &--is-focused {
        background: ${({ theme }) => theme.colors.primary.w100};
        color: ${({ theme }) => theme.colors.text.w000};
      }

      &--is-selected {
        background: ${({ theme }) => theme.colors.primary.w200};
        color: ${({ theme }) => theme.colors.text.w000};
      }
    }
    
    &__single-value {
      color: ${({ theme }) => theme.colors.text.w600};
    }
    
    &__menu {
      background: ${({ theme }) => theme.colors.secondary.w000};
      border-radius: 18px;
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.w200};
      overflow: hidden;
    }
    
    &__input-container {
      color: ${({ theme }) => theme.colors.text.w600};
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.text.w200};
      overflow: hidden;
      text-align: ${({ textAlign }) => textAlign};
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    &__indicator {
      color: ${({ theme }) => theme.colors.primary.w200};
    }
  }
`;

export const inlineContainer = css`
  align-items: center;
  display: flex;
  gap: 20px;
  width: 100%;

  &>p {
      white-space: nowrap;
  }
`;

export const Container = styled.div`
  ${({ model }) => (model === models.dark && dark)}
  ${({ model }) => (model === models.light && light)}
  ${({ inline }) => (inline && inlineContainer)}
  position: relative;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${fontSize.sm};
  font-weight: 200;
  margin: 0 auto 4px 14px;
`;

export const Feedback = styled.p`
  bottom: -22px;
  color: ${({ theme }) => theme.colors.feedback.alert};
  font-size: ${fontSize.xs};
  font-weight: 200;
  position: absolute;
  right: 20px;
`;
