const data = {
  users: [
    {
      isDisabled: false,
      pepId: [
        '1',
      ],
      role: {
        label: 'G_Admin',
      },
      userPosition: 'Médica ',
      fullName: 'Marcy Temperini Prucho',
      username: 'marcy_temperini',
    },
    {
      isDisabled: false,
      pepId: [
        '42',
      ],
      role: {
        label: 'H_Admin',
      },
      userPosition: 'Enfermeiro',
      fullName: 'Diogo Marcello Felizardo',
      username: 'diogo_marcello',
    },
    {
      isDisabled: true,
      pepId: [
        '1212',
      ],
      role: {
        label: 'H_Admin',
      },
      userPosition: 'Fisioterapeuta',
      fullName: 'Lizete Santana Sousa',
      username: 'lizete_santana',
    },
    {
      isDisabled: false,
      pepId: [
        '321',
      ],
      role: {
        label: 'Usuário',
      },
      userPosition: 'Técnico de enfermagem',
      fullName: 'Pietro Leal Donato',
      username: 'pietro_leal',
    },
    {
      isDisabled: false,
      pepId: [
        '20',
      ],
      role: {
        label: 'Usuário TV',
      },
      userPosition: 'TV',
      fullName: 'Usuário TV',
      username: 'usuario_tv',
    },
  ],
};
export const user = {
  Users: () => data,
  SetUserEnabled: ({ onCompleted }) => [() => onCompleted({
    setUserEnabled: {
      isDisabled: false,
      pepId: [
        '1',
      ],
      role: {
        label: 'G_Admin',
      },
      userPosition: 'Médica',
      fullName: 'Marcy Temperini Prucho',
      username: 'marcy_temperini',
    },
  })],
  upsertUser: () => {
    const upsertUser = ({ variables: { user } }) => {
      if (user) {
        return [upsertUser, { data: true, loading: false, error: undefined }];
      }
      return [upsertUser, { data: undefined, loading: false, error: undefined }];
    };
    return [upsertUser, { data: undefined, loading: false, error: undefined }];
  },
  UserRegistrationPage: () => ({
    adminEmails: [
      {
        label: 'hamilton.gouvea@carenet.com.br',
        value: 'hamilton.gouvea@carenet.com.br',
        username: 'hamilton.ribeiro',
        __typename: 'Email',
      },
      {
        label: 'noreply-dev@carenet.com.br',
        value: 'noreply-dev@carenet.com.br',
        username: 'rafaelcm',
        __typename: 'Email',
      },
      {
        label: 'hugo.rodrigues@carenet.com.br',
        value: 'hugo.rodrigues@carenet.com.br',
        username: 'eli.test',
        __typename: 'Email',
      },
      {
        label: 'rcmedeiros@gmail.com',
        value: 'rcmedeiros@gmail.com',
        username: 'testedoteste',
        __typename: 'Email',
      },
      {
        label: 'rcmedeiros@gmail.com',
        value: 'rcmedeiros@gmail.com',
        username: 'jeanluc',
        __typename: 'Email',
      },
      {
        label: 'eli.chicarelli@carenet.com.br',
        value: 'eli.chicarelli@carenet.com.br',
        username: 'test',
        __typename: 'Email',
      },
      {
        label: 'rafael.medeiros@carenet.com.br',
        value: 'rafael.medeiros@carenet.com.br',
        username: 'hadmin',
        __typename: 'Email',
      },
      {
        label: 'tv@carenet.com.br',
        value: 'tv@carenet.com.br',
        username: 'usuario_tv',
        __typename: 'Email',
      },
    ],
    availableHospitals: [
      {
        label: 'Hospital São Márcio',
        value: 'rede_carenet/hospital_sao_marcio',
        address: 'Alameda dos Maracatins, 426 - Sala 610 - Moema, São Paulo - SP, 04089-000',
        __typename: 'Hospital',
      },
      {
        label: 'Hospital Elizabeth Blackwell',
        value: 'rede_carenet/hospital_elizabeth_blackwell',
        address: 'R. Venda da Esperança, 162 - Socorro, São Paulo - SP, 04763-040',
        __typename: 'Hospital',
      },
    ],
    roles: [
      {
        label: 'H_Admin',
        value: 'H_Admin',
        canHaveMultipleHospitals: true,
        __typename: 'Role',
      },
      {
        label: 'Usuário',
        value: 'Usuário',
        canHaveMultipleHospitals: true,
        __typename: 'Role',
      },
      {
        label: 'Usuário TV',
        value: 'Usuário TV',
        canHaveMultipleHospitals: false,
        __typename: 'Role',
      },
    ],
    user: {
      fullName: 'Marcy Temperini Prucho',
      username: 'marcy_temperini',
      userPosition: 'Médica',
      role: {
        label: 'H_Admin',
        value: 'H_Admin',
        canHaveMultipleHospitals: true,
        __typename: 'Role',
      },
      phoneNumber: '',
      email: 'marcy.prucho@geranv.com.br',
      proxyEmailAdmin: null,
      userHospitals: [
        {
          value: 'rede_carenet/hospital_sao_marcio',
          pepId: '1212',
          __typename: 'UserHospitalFF',
        },
        {
          value: 'rede_carenet/hospital_elizabeth_blackwell',
          pepId: '1212',
          __typename: 'UserHospitalFF',
        },
      ],
      __typename: 'User',
    },
  }),
  DeleteUser: () => {
    const deleteUser = (_, setIsLoading = () => {}) => {
      setIsLoading(false);
      return true;
    };
    return [deleteUser];
  },
  doesIdExist: () => [() => true, { data: undefined }],
  GetLogs: () => ({
    getLogs: [
      {
        username: 'diego_marcello',
        timestamp: '2023-01-30T15:08:57.346Z',
        action: 'USER_ACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-26T20:56:21.061Z',
        action: 'USER_DEACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-26T20:56:14.405Z',
        action: 'USER_ACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-26T20:54:26.368Z',
        action: 'USER_DEACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-26T20:53:57.821Z',
        action: 'USER_ACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-26T19:02:15.762Z',
        action: 'USER_DEACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-23T21:28:44.154Z',
        action: 'USER_ACTIVATED_BY',
        parameters: [
          'jeanluc',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-23T21:27:54.046Z',
        action: 'USER_DEACTIVATED_BY',
        parameters: [
          'jeanluc',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-20T15:12:21.496Z',
        action: 'USER_UPDATE',
        parameters: [],
        children: [
          {
            username: 'diego_marcello',
            timestamp: '2023-01-20T15:12:21.502Z',
            action: 'USER_UPDATE_PHONE',
            parameters: [
              '89 6554-88822', '90 7445-99933',
            ],
            __typename: 'Log',
          },
        ],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-12T14:15:33.134Z',
        action: 'USER_ACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-12T14:15:19.516Z',
        action: 'USER_DEACTIVATED_BY',
        parameters: [
          'marcy_temperini',
        ],
        children: [],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2023-01-06T11:39:19.256Z',
        action: 'USER_UPDATE',
        parameters: [],
        children: [
          {
            username: 'diego_marcello',
            timestamp: '2023-01-06T11:39:19.347Z',
            action: 'USER_UPDATE_NAME',
            parameters: [
              'Diogo Felizardo',
              'Diogo Marcello',
            ],
            __typename: 'Log',
          },
        ],
        __typename: 'Log',
      },
      {
        username: 'diego_marcello',
        timestamp: '2022-02-11T02:45:00.000Z',
        action: 'USER_LOGIN',
        parameters: [],
        children: [],
        __typename: 'Log',
      },
    ],
  }),
  User: () => ({
    user: {
      username: 'diogo_marcello',
      fullName: 'Diogo Marcello Felizardo',
      userPosition: 'Enfermeiro',
      phoneNumber: null,
      email: 'diogo.felizardo@geranv.com.br',
      proxyEmailAdmin: null,
      userHospitals: [
        {
          label: 'Hospital São Márcio',
          value: 'rede_carenet/hospital_sao_marcio',
          pepId: '42',
          address: 'Alameda dos Maracatins, 426 - Sala 610 - Moema, São Paulo - SP, 04089-000',
          __typename: 'UserHospitalFF',
        },
      ],
      role: {
        label: 'H_Admin',
        value: 'H_Admin',
        canHaveMultipleHospitals: true,
        __typename: 'Role',
      },
      pepId: [
        '42',
      ],
      isDisabled: false,
      __typename: 'User',
    },
  }),
};
