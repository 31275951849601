import { pageHome } from './pageHome';
import { getPep } from './getPep';
import { user } from './user';
import { alarmsBed } from './alarmsBed';
import { monitor } from './monitor';
import { historyMonitor } from './getHistoryByBed';
import { tableAlarms } from './tableAlarms';
import { patientRecord } from './patientRecord';
import { bed } from './bed';
import { air } from './air';
import { infusion } from './infusion';
import { getAllHospitalsGroup } from './deployment';
import { WAVES } from './ecg';
import { toggleTemperature } from './toggleTemperature';
import { toggleCarbonDioxide } from './toggleCarbonDioxide';
import { ecgIi } from './ecgWave2';
import { realTimeWaves } from './realTimeWaves';
import { equipmentAlarms } from './equipmentAlarms';
import { isInvasive } from './isInvasive';

export default {
  ...pageHome,
  ...getPep,
  ...alarmsBed,
  ...monitor,
  ...user,
  ...historyMonitor,
  ...bed,
  ...tableAlarms,
  ...air,
  ...patientRecord,
  ...infusion,
  ...getAllHospitalsGroup,
  ...WAVES,
  ...ecgIi,
  ...toggleTemperature,
  ...toggleCarbonDioxide,
  ...realTimeWaves,
  ...equipmentAlarms,
  ...isInvasive,
};
