import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loginStatus } from 'settings';
import client from 'services/apollo';
import useUser from 'services/cognito/user';
import logUserAction, { USER_LOGIN } from '../log/logUserAction';

export default function useApolloClient() {
  const { loginStatus: status } = useSelector((state) => state.login);
  const [apolloClient, setApolloClient] = useState({});
  const { user } = useUser();

  useEffect(() => {
    setApolloClient(user ? client() : {});
  }, [user]);

  useEffect(() => {
    const apolloClientExists = !!apolloClient.mutate;
    if (apolloClientExists && status === loginStatus.success) {
      logUserAction(apolloClient, USER_LOGIN);
    }
  }, [apolloClient, status]);

  return apolloClient;
}
