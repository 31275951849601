/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import EmailSelector from 'components/layout/EmailSelector';
import Input, { states } from 'components/core/Input';
import LinkHospitalsToUser from 'components/layout/LinkHospitalsToUser';
import LinkHospitalToPep from 'components/layout/LinkHospitalToPep';
import SectionTitle from 'components/core/SectionTitle';
import Select, { models as selectModels } from 'components/core/Select';
import { GET_USERS_QUERY } from 'pages/UserManagement/queries';

import maskPhoneNumber from 'utils/maskPhoneNumber';

import useMockableMutation from 'services/apollo/hooks/useMockableMutation';
import useMockableLazyQuery from 'services/apollo/hooks/useMockableLazyQuery';
import { formValidation } from './helpers/formValidation';
import getProfessionalIdFeedbackMessage from './helpers/getProfessionalIdFeedbackMessage';
import { REGISTER_USER_MUTATION, DOES_ID_EXIST } from './queries';
import useHandleOneHospital from './hooks/useHandleOneHospital';
import useGetSubmittingState from './hooks/useGetSubmittingState';
import clearHospitals from './helpers/clearHospitals';

import { Form, HospitalInfo, InputContainer } from './styles';

export default function AddUser({
  emails, hospitals, roles, onSuccess, onSubmittingStateChange, user, readonly,
}) {
  const [fullName, setFullName] = useState({ value: '', isValid: true });
  const [userName, setUserName] = useState({ value: '', isValid: true });
  const [userPosition, setUserPosition] = useState({ value: '', isValid: true });
  const [phoneNumber, setPhoneNumber] = useState({ value: '', isValid: true });
  const [email, setEmail] = useState({ value: '', isValid: true });
  const [proxyEmailAdmin, setProxyEmailAdmin] = useState(null);
  const [role, setRole] = useState({ value: '', isValid: true });
  const [userHospitals, setUserHospitals] = useState({ value: [], isValid: readonly === false });
  const [isPicklistValid, setIsPicklistValid] = useState(true);

  const { t } = useTranslation();

  const [sendForm, { data, loading, error }] = useMockableMutation(REGISTER_USER_MUTATION);
  const [doesIdExist, { data: doesIdExistData }] = useMockableLazyQuery(DOES_ID_EXIST);
  const isUpdate = !!user;

  if (data) {
    onSuccess(isUpdate);
  }
  const handleEmailChange = useCallback((value) => {
    setEmail((prev) => ({ ...prev, value: value.value }));
    setProxyEmailAdmin(value.proxyEmailAdmin);
  }, []);

  const memoizedSetUserHospitals = useCallback((value) => {
    setUserHospitals((prev) => ({ ...prev, value }));
  }, []);

  const handleInputpepId = useCallback((event) => {
    const cleanHospitals = clearHospitals(hospitals);
    const value = [
      {
        ...cleanHospitals[0],
        pepId: event.target.value,
      },
    ];
    setUserHospitals((prev) => ({ ...prev, value }));
  }, [hospitals]);

  useGetSubmittingState(onSubmittingStateChange, { loading, error, data });
  const { hasOnlyOneHospital } = useHandleOneHospital(hospitals, memoizedSetUserHospitals);

  function handleSubmit(event) {
    event.preventDefault();

    if (hasOnlyOneHospital) {
      userHospitals.value = userHospitals.value.map((el) => ({ value: el.value, pepId: el.pepId, label: '' }));
    }

    const hospitals = userHospitals.value.map((el) => (
      { value: el.value, label: el.label, pepId: el.pepId }
    ));
    const payload = {
      fullName: fullName.value,
      username: userName.value,
      userPosition: userPosition.value,
      phoneNumber: phoneNumber.value || '',
      email: email.value,
      role: role.value.value,
      proxyEmailAdmin,
      userHospitals: hospitals,
    };

    setIsPicklistValid(userHospitals.value.length > 0);

    const { isValid, missingFields } = formValidation(payload, hasOnlyOneHospital);

    if (!isValid) {
      const set = {
        fullName: setFullName,
        userName: setUserName,
        userPosition: setUserPosition,
        phoneNumber: setPhoneNumber,
        email: setEmail,
        userHospitals: setUserHospitals,
        role: setRole,
      };
      missingFields.forEach((missingField) => {
        set[missingField]((prev) => ({ ...prev, isValid: false }));
      });
      return;
    }

    sendForm({
      variables: { user: payload, isUpdate },
      refetchQueries: [
        { query: GET_USERS_QUERY },
      ],
    });
  }

  function setProfessionalId({ target: { value } }) {
    setUserName({ isValid: true, value: value.toLowerCase() });
  }

  function handleProfessionalIdOnBlur({ target: { value } }) {
    if (!value) return;
    doesIdExist({ variables: { id: value } })
      .then(({ data: d }) => setUserName((prev) => ({ ...prev, isValid: !d?.doesIdExist })));
  }

  const defaultSelectedHospitals = useMemo(() => user?.userHospitals, [user]);

  useEffect(() => {
    if (user) {
      setFullName((prev) => ({ ...prev, value: user?.fullName }));
      setUserName((prev) => ({ ...prev, value: user?.username }));
      setUserPosition((prev) => ({ ...prev, value: user?.userPosition }));
      setPhoneNumber((prev) => ({ ...prev, value: user?.phoneNumber || '' }));
      setEmail((prev) => ({ ...prev, value: user?.email }));
      setProxyEmailAdmin(user?.proxyEmailAdmin);
      setRole((prev) => ({ ...prev, value: user?.role }));
      setUserHospitals((prev) => ({ ...prev, value: user?.userHospitals }));
    }
  }, [user]);

  function hospitalsInfo() {
    return readonly ? (
      <>
        <SectionTitle text={t('linkedHospitals')} />
        { userHospitals.value.map((hospital) => (
          <HospitalInfo key={hospital.value}>
            <span>{`${hospital.label} (${hospital.hospitalGroup})`}</span>
            <span>{hospital.address}</span>
          </HospitalInfo>
        )) }
      </>
    ) : (
      <>
        <SectionTitle text={t('linkHospitals')} />
        <LinkHospitalsToUser
          defaultSelectedItems={defaultSelectedHospitals}
          canHaveMultipleHospitals={role.value.canHaveMultipleHospitals}
          hospitals={hospitals}
          callback={memoizedSetUserHospitals}
          feedbackMessage={!isPicklistValid && t('feedback.includeAHospital')}
        />
      </>
    );
  }
  return (
    <Form onSubmit={handleSubmit} id="addUser">
      <SectionTitle text={t('professionalData')} />
      <InputContainer>
        <Input
          value={fullName.value}
          onChange={(event) => setFullName((prev) => ({ ...prev, value: event.target.value }))}
          label={t('professionalFullName')}
          placeholder={t('fullName')}
          textAlign="left"
          readonly={readonly}
          required
          state={fullName.isValid ? states.default : states.error}
          feedbackMessage={!fullName.isValid && t('feedback.requiredField')}
          cy="fullName"
        />
        <Input
          value={userName.value}
          onChange={setProfessionalId}
          onBlur={handleProfessionalIdOnBlur}
          label={t('professionalId')}
          placeholder={t('professionalIdSugestion')}
          textAlign="left"
          disabled={isUpdate}
          readonly={readonly || isUpdate}
          required
          state={
            userName.isValid
              ? states.default
              : states.error
          }
          feedbackMessage={
            t(getProfessionalIdFeedbackMessage(doesIdExistData?.doesIdExist, userName))
          }
          cy="professionalId"
        />
        <Input
          value={userPosition.value}
          onChange={(event) => setUserPosition((prev) => ({ ...prev, value: event.target.value }))}
          label={t('roleOpt')}
          placeholder={t('professionalPosition')}
          textAlign="left"
          readonly={readonly}
          required
          state={userPosition.isValid ? states.default : states.error}
          feedbackMessage={!userPosition.isValid && t('feedback.requiredField')}
          cy="professionalPosition"
        />
        <Input
          value={maskPhoneNumber(phoneNumber.value)}
          onChange={(event) => setPhoneNumber((prev) => ({ ...prev, value: event.target.value }))}
          label={t('text.typePhoneNumber')}
          placeholder="00 00000-0000"
          textAlign="left"
          type="tel"
          maxLength={13}
          readonly={readonly}
          state={phoneNumber.isValid ? states.default : states.error}
          feedbackMessage={!phoneNumber.isValid && t('feedback.invalidPhoneNumber')}
          cy="phoneProfessional"
        />
        <EmailSelector
          value={email.value}
          proxyEmailAdmin={proxyEmailAdmin}
          callback={handleEmailChange}
          options={emails}
          readonly={readonly}
          feedbackMessage={!email.isValid && t('feedback.requiredField')}
        />
        <Select
          value={role?.value}
          label={t('permissionLevel')}
          placeholder={t('text.typePermissionLevel')}
          textAlign="left"
          model={selectModels.light}
          options={roles.map((r) => ({
            ...r,
            label: t(`useRoles.${r.label}`),
          }))}
          onChange={(value) => setRole((prev) => (
            {
              ...prev,
              value,
            }
          ))}
          required
          readonly={readonly}
          feedbackMessage={!role.isValid && t('feedback.requiredField')}
          cy="select-permission-level"
        />
        {
          hasOnlyOneHospital && (
            <Input
              label={t('pepId')}
              placeholder={t('pepId')}
              textAlign="left"
              value={userHospitals.value[0] ? userHospitals.value[0].pepId : ''}
              onChange={handleInputpepId}
              required
              feedbackMessage={!userHospitals.isValid && t('feedback.requiredField')}
              cy="pep-id-one-hospital"
            />
          )
        }
      </InputContainer>
      {
        hasOnlyOneHospital ? (
          <>
            <SectionTitle text={t('hospital')} />
            <HospitalInfo>
              <span>{`${hospitals[0].label} (${hospitals[0].hospitalGroup})`}</span>
              <span>{hospitals[0].address}</span>
            </HospitalInfo>
          </>
        ) : (
          <>
            { hospitalsInfo() }
            <SectionTitle text={t('pepId')} />
            <LinkHospitalToPep
              readonly={readonly}
              hospitals={userHospitals.value}
              callback={(memoizedSetUserHospitals)}
              isValid={userHospitals.isValid}
            />
          </>
        )
      }
    </Form>
  );
}

AddUser.defaultProps = {
  emails: [],
  hospitals: [],
  roles: [],
  onSuccess: () => { /* */ },
  onSubmittingStateChange: () => { /* */ },
  readonly: false,
};
