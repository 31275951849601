/* eslint-disable no-loss-of-precision */
export const I = [
  {
    x: 0,
    y: 0.09512710571289062,
  },
  {
    x: 472.65625,
    y: 0.09512710571289062,
  },
  {
    x: 476.5625,
    y: 0.10010910034179688,
  },
  {
    x: 480.46875,
    y: 0.13001251220703125,
  },
  {
    x: 484.375,
    y: 0.159912109375,
  },
  {
    x: 488.28125,
    y: 0.18981552124023438,
  },
  {
    x: 492.1875,
    y: 0.21971511840820312,
  },
  {
    x: 496.09375,
    y: 0.24462890625,
  },
  {
    x: 500,
    y: 0.14496231079101562,
  },
  {
    x: 503.90625,
    y: 0.14496231079101562,
  },
  {
    x: 515.625,
    y: 0.14496231079101562,
  },
  {
    x: 519.53125,
    y: 0.1349945068359375,
  },
  {
    x: 523.4375,
    y: 0.110076904296875,
  },
  {
    x: 527.34375,
    y: 0.0851593017578125,
  },
  {
    x: 531.25,
    y: 0.0751953125,
  },
  {
    x: 535.15625,
    y: 0.07020950317382812,
  },
  {
    x: 539.0625,
    y: 0.06522369384765625,
  },
  {
    x: 542.96875,
    y: 0.06024169921875,
  },
  {
    x: 546.875,
    y: 0.06024169921875,
  },
  {
    x: 550.78125,
    y: 0.06024169921875,
  },
  {
    x: 554.6875,
    y: 0.05525970458984375,
  },
  {
    x: 558.59375,
    y: 0.050273895263671875,
  },
  {
    x: 562.5,
    y: 0.050273895263671875,
  },
  {
    x: 566.40625,
    y: 0.045291900634765625,
  },
  {
    x: 570.3125,
    y: 0.040309906005859375,
  },
  {
    x: 574.21875,
    y: 0.040309906005859375,
  },
  {
    x: 578.125,
    y: 0.040309906005859375,
  },
  {
    x: 582.03125,
    y: 0.0353240966796875,
  },
  {
    x: 585.9375,
    y: 0.0353240966796875,
  },
  {
    x: 593.75,
    y: 0.0353240966796875,
  },
  {
    x: 597.65625,
    y: 0.03034210205078125,
  },
  {
    x: 601.5625,
    y: 0.025356292724609375,
  },
  {
    x: 605.46875,
    y: 0.03034210205078125,
  },
  {
    x: 609.375,
    y: 0.0353240966796875,
  },
  {
    x: 613.28125,
    y: 0.040309906005859375,
  },
  {
    x: 617.1875,
    y: 0.040309906005859375,
  },
  {
    x: 621.09375,
    y: 0.045291900634765625,
  },
  {
    x: 625,
    y: 0.050273895263671875,
  },
  {
    x: 628.90625,
    y: 0.05525970458984375,
  },
  {
    x: 632.8125,
    y: 0.06024169921875,
  },
  {
    x: 636.71875,
    y: 0.06024169921875,
  },
  {
    x: 640.625,
    y: 0.06522369384765625,
  },
  {
    x: 644.53125,
    y: 0.07020950317382812,
  },
  {
    x: 648.4375,
    y: 0.0751953125,
  },
  {
    x: 652.34375,
    y: 0.0751953125,
  },
  {
    x: 656.25,
    y: 0.08017730712890625,
  },
  {
    x: 660.15625,
    y: 0.0851593017578125,
  },
  {
    x: 664.0625,
    y: 0.09014511108398438,
  },
  {
    x: 667.96875,
    y: 0.09014511108398438,
  },
  {
    x: 671.875,
    y: 0.09512710571289062,
  },
  {
    x: 675.78125,
    y: 0.09512710571289062,
  },
  {
    x: 1199.21875,
    y: 0.09512710571289062,
  },
  {
    x: 1203.125,
    y: 0.10010910034179688,
  },
  {
    x: 1207.03125,
    y: 0.13001251220703125,
  },
  {
    x: 1210.9375,
    y: 0.159912109375,
  },
  {
    x: 1214.84375,
    y: 0.18981552124023438,
  },
  {
    x: 1218.75,
    y: 0.21971511840820312,
  },
  {
    x: 1222.65625,
    y: 0.24462890625,
  },
  {
    x: 1226.5625,
    y: 0.2695503234863281,
  },
  {
    x: 1230.46875,
    y: 0.2994499206542969,
  },
  {
    x: 1234.375,
    y: 0.3243675231933594,
  },
  {
    x: 1238.28125,
    y: 0.35427093505859375,
  },
  {
    x: 1242.1875,
    y: 0.3692207336425781,
  },
  {
    x: 1246.09375,
    y: 0.3592529296875,
  },
  {
    x: 1250,
    y: 0.3393211364746094,
  },
  {
    x: 1253.90625,
    y: 0.31439971923828125,
  },
  {
    x: 1257.8125,
    y: 0.2894859313964844,
  },
  {
    x: 1261.71875,
    y: 0.2695503234863281,
  },
  {
    x: 1265.625,
    y: 0.24462890625,
  },
  {
    x: 1269.53125,
    y: 0.21971511840820312,
  },
  {
    x: 1273.4375,
    y: 0.19479751586914062,
  },
  {
    x: 1277.34375,
    y: 0.1698760986328125,
  },
  {
    x: 1281.25,
    y: 0.159912109375,
  },
  {
    x: 1285.15625,
    y: 0.14994430541992188,
  },
  {
    x: 1289.0625,
    y: 0.14496231079101562,
  },
  {
    x: 1292.96875,
    y: 0.14496231079101562,
  },
  {
    x: 1492.1875,
    y: 0.14496231079101562,
  },
  {
    x: 1496.09375,
    y: 0.1349945068359375,
  },
  {
    x: 1500,
    y: 0.110076904296875,
  },
  {
    x: 1503.90625,
    y: 0.0851593017578125,
  },
  {
    x: 1507.8125,
    y: 0.0751953125,
  },
  {
    x: 1511.71875,
    y: 0.07020950317382812,
  },
  {
    x: 1515.625,
    y: 0.06522369384765625,
  },
  {
    x: 1519.53125,
    y: 0.06024169921875,
  },
  {
    x: 1523.4375,
    y: 0.06024169921875,
  },
  {
    x: 1527.34375,
    y: 0.06024169921875,
  },
  {
    x: 1531.25,
    y: 0.05525970458984375,
  },
  {
    x: 1535.15625,
    y: 0.050273895263671875,
  },
  {
    x: 1539.0625,
    y: 0.050273895263671875,
  },
  {
    x: 1542.96875,
    y: 0.045291900634765625,
  },
  {
    x: 1546.875,
    y: 0.040309906005859375,
  },
  {
    x: 1550.78125,
    y: 0.040309906005859375,
  },
  {
    x: 1554.6875,
    y: 0.040309906005859375,
  },
  {
    x: 1558.59375,
    y: 0.0353240966796875,
  },
  {
    x: 1562.5,
    y: 0.0353240966796875,
  },
  {
    x: 1570.3125,
    y: 0.0353240966796875,
  },
  {
    x: 1574.21875,
    y: 0.03034210205078125,
  },
  {
    x: 1578.125,
    y: 0.025356292724609375,
  },
  {
    x: 1582.03125,
    y: 0.03034210205078125,
  },
  {
    x: 1585.9375,
    y: 0.0353240966796875,
  },
  {
    x: 1589.84375,
    y: 0.040309906005859375,
  },
  {
    x: 1593.75,
    y: 0.040309906005859375,
  },
  {
    x: 1597.65625,
    y: 0.045291900634765625,
  },
  {
    x: 1601.5625,
    y: 0.050273895263671875,
  },
  {
    x: 1605.46875,
    y: 0.05525970458984375,
  },
  {
    x: 1609.375,
    y: 0.06024169921875,
  },
  {
    x: 1613.28125,
    y: 0.06024169921875,
  },
  {
    x: 1617.1875,
    y: 0.06522369384765625,
  },
  {
    x: 1621.09375,
    y: 0.07020950317382812,
  },
  {
    x: 1625,
    y: 0.0751953125,
  },
  {
    x: 1628.90625,
    y: 0.0751953125,
  },
  {
    x: 1632.8125,
    y: 0.08017730712890625,
  },
  {
    x: 1636.71875,
    y: 0.0851593017578125,
  },
  {
    x: 1640.625,
    y: 0.09014511108398438,
  },
  {
    x: 1644.53125,
    y: 0.09014511108398438,
  },
  {
    x: 1648.4375,
    y: 0.09512710571289062,
  },
  {
    x: 1652.34375,
    y: 0.09512710571289062,
  },
  {
    x: 2175.78125,
    y: 0.09512710571289062,
  },
  {
    x: 2179.6875,
    y: 0.10010910034179688,
  },
  {
    x: 2183.59375,
    y: 0.13001251220703125,
  },
  {
    x: 2187.5,
    y: 0.159912109375,
  },
  {
    x: 2191.40625,
    y: 0.18981552124023438,
  },
  {
    x: 2195.3125,
    y: 0.21971511840820312,
  },
  {
    x: 2199.21875,
    y: 0.24462890625,
  },
  {
    x: 2203.125,
    y: 0.2695503234863281,
  },
  {
    x: 2207.03125,
    y: 0.2994499206542969,
  },
  {
    x: 2210.9375,
    y: 0.3243675231933594,
  },
  {
    x: 2214.84375,
    y: 0.35427093505859375,
  },
  {
    x: 2218.75,
    y: 0.3692207336425781,
  },
  {
    x: 2222.65625,
    y: 0.3592529296875,
  },
  {
    x: 2226.5625,
    y: 0.3393211364746094,
  },
  {
    x: 2230.46875,
    y: 0.31439971923828125,
  },
  {
    x: 2234.375,
    y: 0.2894859313964844,
  },
  {
    x: 2238.28125,
    y: 0.2695503234863281,
  },
  {
    x: 2242.1875,
    y: 0.24462890625,
  },
  {
    x: 2246.09375,
    y: 0.21971511840820312,
  },
  {
    x: 2250,
    y: 0.19479751586914062,
  },
  {
    x: 2253.90625,
    y: 0.1698760986328125,
  },
  {
    x: 2257.8125,
    y: 0.159912109375,
  },
  {
    x: 2261.71875,
    y: 0.14994430541992188,
  },
  {
    x: 2265.625,
    y: 0.14496231079101562,
  },
  {
    x: 2269.53125,
    y: 0.14496231079101562,
  },
  {
    x: 2468.75,
    y: 0.14496231079101562,
  },
  {
    x: 2472.65625,
    y: 0.1349945068359375,
  },
  {
    x: 2476.5625,
    y: 0.110076904296875,
  },
  {
    x: 2480.46875,
    y: 0.0851593017578125,
  },
  {
    x: 2484.375,
    y: 0.0751953125,
  },
  {
    x: 2488.28125,
    y: 0.07020950317382812,
  },
  {
    x: 2492.1875,
    y: 0.06522369384765625,
  },
  {
    x: 2496.09375,
    y: 0.06024169921875,
  },
  {
    x: 2500,
    y: 0.06024169921875,
  },
  {
    x: 2503.90625,
    y: 0.06024169921875,
  },
  {
    x: 2507.8125,
    y: 0.05525970458984375,
  },
  {
    x: 2511.71875,
    y: 0.050273895263671875,
  },
  {
    x: 2515.625,
    y: 0.050273895263671875,
  },
  {
    x: 2519.53125,
    y: 0.045291900634765625,
  },
  {
    x: 2523.4375,
    y: 0.040309906005859375,
  },
  {
    x: 2527.34375,
    y: 0.040309906005859375,
  },
  {
    x: 2531.25,
    y: 0.040309906005859375,
  },
  {
    x: 2535.15625,
    y: 0.0353240966796875,
  },
  {
    x: 2539.0625,
    y: 0.0353240966796875,
  },
  {
    x: 2546.875,
    y: 0.0353240966796875,
  },
  {
    x: 2550.78125,
    y: 0.03034210205078125,
  },
  {
    x: 2554.6875,
    y: 0.025356292724609375,
  },
  {
    x: 2558.59375,
    y: 0.03034210205078125,
  },
  {
    x: 2562.5,
    y: 0.0353240966796875,
  },
  {
    x: 2566.40625,
    y: 0.040309906005859375,
  },
  {
    x: 2570.3125,
    y: 0.040309906005859375,
  },
  {
    x: 2574.21875,
    y: 0.045291900634765625,
  },
  {
    x: 2578.125,
    y: 0.050273895263671875,
  },
  {
    x: 2582.03125,
    y: 0.05525970458984375,
  },
  {
    x: 2585.9375,
    y: 0.06024169921875,
  },
  {
    x: 2589.84375,
    y: 0.06024169921875,
  },
  {
    x: 2593.75,
    y: 0.06522369384765625,
  },
  {
    x: 2597.65625,
    y: 0.07020950317382812,
  },
  {
    x: 2601.5625,
    y: 0.0751953125,
  },
  {
    x: 2605.46875,
    y: 0.0751953125,
  },
  {
    x: 2609.375,
    y: 0.08017730712890625,
  },
  {
    x: 2613.28125,
    y: 0.0851593017578125,
  },
  {
    x: 2617.1875,
    y: 0.09014511108398438,
  },
  {
    x: 2621.09375,
    y: 0.09014511108398438,
  },
  {
    x: 2625,
    y: 0.09512710571289062,
  },
  {
    x: 2628.90625,
    y: 0.09512710571289062,
  },
  {
    x: 3152.34375,
    y: 0.09512710571289062,
  },
  {
    x: 3156.25,
    y: 0.10010910034179688,
  },
  {
    x: 3160.15625,
    y: 0.13001251220703125,
  },
  {
    x: 3164.0625,
    y: 0.159912109375,
  },
  {
    x: 3167.96875,
    y: 0.18981552124023438,
  },
  {
    x: 3171.875,
    y: 0.21971511840820312,
  },
  {
    x: 3175.78125,
    y: 0.24462890625,
  },
  {
    x: 3179.6875,
    y: 0.2695503234863281,
  },
  {
    x: 3183.59375,
    y: 0.2994499206542969,
  },
  {
    x: 3187.5,
    y: 0.3243675231933594,
  },
  {
    x: 3191.40625,
    y: 0.35427093505859375,
  },
  {
    x: 3195.3125,
    y: 0.3692207336425781,
  },
  {
    x: 3199.21875,
    y: 0.3592529296875,
  },
  {
    x: 3203.125,
    y: 0.3393211364746094,
  },
  {
    x: 3207.03125,
    y: 0.31439971923828125,
  },
  {
    x: 3210.9375,
    y: 0.2894859313964844,
  },
  {
    x: 3214.84375,
    y: 0.2695503234863281,
  },
  {
    x: 3218.75,
    y: 0.24462890625,
  },
  {
    x: 3222.65625,
    y: 0.21971511840820312,
  },
  {
    x: 3226.5625,
    y: 0.19479751586914062,
  },
  {
    x: 3230.46875,
    y: 0.1698760986328125,
  },
  {
    x: 3234.375,
    y: 0.159912109375,
  },
  {
    x: 3238.28125,
    y: 0.14994430541992188,
  },
  {
    x: 3242.1875,
    y: 0.14496231079101562,
  },
  {
    x: 3246.09375,
    y: 0.14496231079101562,
  },
  {
    x: 3445.3125,
    y: 0.14496231079101562,
  },
  {
    x: 3449.21875,
    y: 0.1349945068359375,
  },
  {
    x: 3453.125,
    y: 0.110076904296875,
  },
  {
    x: 3457.03125,
    y: 0.0851593017578125,
  },
  {
    x: 3460.9375,
    y: 0.0751953125,
  },
  {
    x: 3464.84375,
    y: 0.07020950317382812,
  },
  {
    x: 3468.75,
    y: 0.06522369384765625,
  },
  {
    x: 3472.65625,
    y: 0.06024169921875,
  },
  {
    x: 3476.5625,
    y: 0.06024169921875,
  },
  {
    x: 3480.46875,
    y: 0.06024169921875,
  },
  {
    x: 3484.375,
    y: 0.05525970458984375,
  },
  {
    x: 3488.28125,
    y: 0.050273895263671875,
  },
  {
    x: 3492.1875,
    y: 0.050273895263671875,
  },
  {
    x: 3496.09375,
    y: 0.045291900634765625,
  },
  {
    x: 3500,
    y: 0.040309906005859375,
  },
  {
    x: 3503.90625,
    y: 0.040309906005859375,
  },
  {
    x: 3511.71875,
    y: 0.040309906005859375,
  },
  {
    x: 3515.625,
    y: 0.0353240966796875,
  },
  {
    x: 3519.53125,
    y: 0.0353240966796875,
  },
  {
    x: 3523.4375,
    y: 0.0353240966796875,
  },
  {
    x: 3527.34375,
    y: 0.03034210205078125,
  },
  {
    x: 3531.25,
    y: 0.025356292724609375,
  },
  {
    x: 3535.15625,
    y: 0.03034210205078125,
  },
  {
    x: 3539.0625,
    y: 0.0353240966796875,
  },
  {
    x: 3542.96875,
    y: 0.040309906005859375,
  },
  {
    x: 3546.875,
    y: 0.040309906005859375,
  },
  {
    x: 3550.78125,
    y: 0.045291900634765625,
  },
  {
    x: 3554.6875,
    y: 0.050273895263671875,
  },
  {
    x: 3558.59375,
    y: 0.05525970458984375,
  },
  {
    x: 3562.5,
    y: 0.06024169921875,
  },
  {
    x: 3566.40625,
    y: 0.06024169921875,
  },
  {
    x: 3570.3125,
    y: 0.06522369384765625,
  },
  {
    x: 3574.21875,
    y: 0.07020950317382812,
  },
  {
    x: 3578.125,
    y: 0.0751953125,
  },
  {
    x: 3582.03125,
    y: 0.0751953125,
  },
  {
    x: 3585.9375,
    y: 0.08017730712890625,
  },
  {
    x: 3589.84375,
    y: 0.0851593017578125,
  },
  {
    x: 3593.75,
    y: 0.09014511108398438,
  },
  {
    x: 3597.65625,
    y: 0.09014511108398438,
  },
  {
    x: 3601.5625,
    y: 0.09512710571289062,
  },
  {
    x: 3605.46875,
    y: 0.09512710571289062,
  },
  {
    x: 4128.90625,
    y: 0.09512710571289062,
  },
  {
    x: 4132.8125,
    y: 0.10010910034179688,
  },
  {
    x: 4136.71875,
    y: 0.13001251220703125,
  },
  {
    x: 4140.625,
    y: 0.159912109375,
  },
  {
    x: 4144.53125,
    y: 0.18981552124023438,
  },
  {
    x: 4148.4375,
    y: 0.21971511840820312,
  },
  {
    x: 4152.34375,
    y: 0.24462890625,
  },
  {
    x: 4156.25,
    y: 0.2695503234863281,
  },
  {
    x: 4160.15625,
    y: 0.2994499206542969,
  },
  {
    x: 4164.0625,
    y: 0.3243675231933594,
  },
  {
    x: 4167.96875,
    y: 0.35427093505859375,
  },
  {
    x: 4171.875,
    y: 0.3692207336425781,
  },
  {
    x: 4175.78125,
    y: 0.3592529296875,
  },
  {
    x: 4179.6875,
    y: 0.3393211364746094,
  },
  {
    x: 4183.59375,
    y: 0.31439971923828125,
  },
  {
    x: 4187.5,
    y: 0.2894859313964844,
  },
  {
    x: 4191.40625,
    y: 0.2695503234863281,
  },
  {
    x: 4195.3125,
    y: 0.24462890625,
  },
  {
    x: 4199.21875,
    y: 0.21971511840820312,
  },
  {
    x: 4203.125,
    y: 0.19479751586914062,
  },
  {
    x: 4207.03125,
    y: 0.1698760986328125,
  },
  {
    x: 4210.9375,
    y: 0.159912109375,
  },
  {
    x: 4214.84375,
    y: 0.14994430541992188,
  },
  {
    x: 4218.75,
    y: 0.14496231079101562,
  },
  {
    x: 4222.65625,
    y: 0.14496231079101562,
  },
  {
    x: 4421.875,
    y: 0.14496231079101562,
  },
  {
    x: 4425.78125,
    y: 0.1349945068359375,
  },
  {
    x: 4429.6875,
    y: 0.110076904296875,
  },
  {
    x: 4433.59375,
    y: 0.0851593017578125,
  },
  {
    x: 4437.5,
    y: 0.0751953125,
  },
  {
    x: 4441.40625,
    y: 0.07020950317382812,
  },
  {
    x: 4445.3125,
    y: 0.06522369384765625,
  },
  {
    x: 4449.21875,
    y: 0.06024169921875,
  },
  {
    x: 4453.125,
    y: 0.06024169921875,
  },
  {
    x: 4457.03125,
    y: 0.06024169921875,
  },
  {
    x: 4460.9375,
    y: 0.05525970458984375,
  },
  {
    x: 4464.84375,
    y: 0.050273895263671875,
  },
  {
    x: 4468.75,
    y: 0.050273895263671875,
  },
  {
    x: 4472.65625,
    y: 0.045291900634765625,
  },
  {
    x: 4476.5625,
    y: 0.040309906005859375,
  },
  {
    x: 4480.46875,
    y: 0.040309906005859375,
  },
  {
    x: 4484.375,
    y: 0.040309906005859375,
  },
  {
    x: 4488.28125,
    y: 0.0353240966796875,
  },
  {
    x: 4492.1875,
    y: 0.0353240966796875,
  },
  {
    x: 4500,
    y: 0.0353240966796875,
  },
  {
    x: 4503.90625,
    y: 0.03034210205078125,
  },
  {
    x: 4507.8125,
    y: 0.025356292724609375,
  },
  {
    x: 4511.71875,
    y: 0.03034210205078125,
  },
  {
    x: 4515.625,
    y: 0.0353240966796875,
  },
  {
    x: 4519.53125,
    y: 0.040309906005859375,
  },
  {
    x: 4523.4375,
    y: 0.040309906005859375,
  },
  {
    x: 4527.34375,
    y: 0.045291900634765625,
  },
  {
    x: 4531.25,
    y: 0.050273895263671875,
  },
  {
    x: 4535.15625,
    y: 0.05525970458984375,
  },
  {
    x: 4539.0625,
    y: 0.06024169921875,
  },
  {
    x: 4542.96875,
    y: 0.06024169921875,
  },
  {
    x: 4546.875,
    y: 0.06522369384765625,
  },
  {
    x: 4550.78125,
    y: 0.07020950317382812,
  },
  {
    x: 4554.6875,
    y: 0.0751953125,
  },
  {
    x: 4558.59375,
    y: 0.0751953125,
  },
  {
    x: 4562.5,
    y: 0.08017730712890625,
  },
  {
    x: 4566.40625,
    y: 0.0851593017578125,
  },
  {
    x: 4570.3125,
    y: 0.09014511108398438,
  },
  {
    x: 4574.21875,
    y: 0.09014511108398438,
  },
  {
    x: 4578.125,
    y: 0.09512710571289062,
  },
  {
    x: 4582.03125,
    y: 0.09512710571289062,
  },
  {
    x: 5105.46875,
    y: 0.09512710571289062,
  },
  {
    x: 5109.375,
    y: 0.10010910034179688,
  },
  {
    x: 5113.28125,
    y: 0.13001251220703125,
  },
  {
    x: 5117.1875,
    y: 0.159912109375,
  },
  {
    x: 5121.09375,
    y: 0.18981552124023438,
  },
  {
    x: 5125,
    y: 0.21971511840820312,
  },
  {
    x: 5128.90625,
    y: 0.24462890625,
  },
  {
    x: 5132.8125,
    y: 0.2695503234863281,
  },
  {
    x: 5136.71875,
    y: 0.2994499206542969,
  },
  {
    x: 5140.625,
    y: 0.3243675231933594,
  },
  {
    x: 5144.53125,
    y: 0.35427093505859375,
  },
  {
    x: 5148.4375,
    y: 0.3692207336425781,
  },
  {
    x: 5152.34375,
    y: 0.3592529296875,
  },
  {
    x: 5156.25,
    y: 0.3393211364746094,
  },
  {
    x: 5160.15625,
    y: 0.31439971923828125,
  },
  {
    x: 5164.0625,
    y: 0.2894859313964844,
  },
  {
    x: 5167.96875,
    y: 0.2695503234863281,
  },
  {
    x: 5171.875,
    y: 0.24462890625,
  },
  {
    x: 5175.78125,
    y: 0.21971511840820312,
  },
  {
    x: 5179.6875,
    y: 0.19479751586914062,
  },
  {
    x: 5183.59375,
    y: 0.1698760986328125,
  },
  {
    x: 5187.5,
    y: 0.159912109375,
  },
  {
    x: 5191.40625,
    y: 0.14994430541992188,
  },
  {
    x: 5195.3125,
    y: 0.14496231079101562,
  },
  {
    x: 5199.21875,
    y: 0.14496231079101562,
  },
  {
    x: 5398.4375,
    y: 0.14496231079101562,
  },
  {
    x: 5402.34375,
    y: 0.1349945068359375,
  },
  {
    x: 5406.25,
    y: 0.110076904296875,
  },
  {
    x: 5410.15625,
    y: 0.0851593017578125,
  },
  {
    x: 5414.0625,
    y: 0.0751953125,
  },
  {
    x: 5417.96875,
    y: 0.07020950317382812,
  },
  {
    x: 5421.875,
    y: 0.06522369384765625,
  },
  {
    x: 5425.78125,
    y: 0.06024169921875,
  },
  {
    x: 5429.6875,
    y: 0.06024169921875,
  },
  {
    x: 5433.59375,
    y: 0.06024169921875,
  },
  {
    x: 5437.5,
    y: 0.05525970458984375,
  },
  {
    x: 5441.40625,
    y: 0.050273895263671875,
  },
  {
    x: 5445.3125,
    y: 0.050273895263671875,
  },
  {
    x: 5449.21875,
    y: 0.045291900634765625,
  },
  {
    x: 5453.125,
    y: 0.040309906005859375,
  },
  {
    x: 5457.03125,
    y: 0.040309906005859375,
  },
  {
    x: 5460.9375,
    y: 0.040309906005859375,
  },
  {
    x: 5464.84375,
    y: 0.0353240966796875,
  },
  {
    x: 5468.75,
    y: 0.0353240966796875,
  },
  {
    x: 5476.5625,
    y: 0.0353240966796875,
  },
  {
    x: 5480.46875,
    y: 0.03034210205078125,
  },
  {
    x: 5484.375,
    y: 0.025356292724609375,
  },
  {
    x: 5488.28125,
    y: 0.03034210205078125,
  },
  {
    x: 5492.1875,
    y: 0.0353240966796875,
  },
  {
    x: 5496.09375,
    y: 0.040309906005859375,
  },
  {
    x: 5500,
    y: 0.09512710571289062,
  },
  {
    x: 5503.90625,
    y: 0.09512710571289062,
  },
  {
    x: 5582.03125,
    y: 0.09512710571289062,
  },
  {
    x: 5585.9375,
    y: 0.10010910034179688,
  },
  {
    x: 5589.84375,
    y: 0.13001251220703125,
  },
  {
    x: 5593.75,
    y: 0.159912109375,
  },
  {
    x: 5597.65625,
    y: 0.18981552124023438,
  },
  {
    x: 5601.5625,
    y: 0.21971511840820312,
  },
  {
    x: 5605.46875,
    y: 0.24462890625,
  },
  {
    x: 5609.375,
    y: 0.2695503234863281,
  },
  {
    x: 5613.28125,
    y: 0.2994499206542969,
  },
  {
    x: 5617.1875,
    y: 0.3243675231933594,
  },
  {
    x: 5621.09375,
    y: 0.35427093505859375,
  },
  {
    x: 5625,
    y: 0.3692207336425781,
  },
  {
    x: 5628.90625,
    y: 0.3592529296875,
  },
  {
    x: 5632.8125,
    y: 0.3393211364746094,
  },
  {
    x: 5636.71875,
    y: 0.31439971923828125,
  },
  {
    x: 5640.625,
    y: 0.2894859313964844,
  },
  {
    x: 5644.53125,
    y: 0.2645683288574219,
  },
  {
    x: 5648.4375,
    y: 0.23964691162109375,
  },
  {
    x: 5652.34375,
    y: 0.21473312377929688,
  },
  {
    x: 5656.25,
    y: 0.18981552124023438,
  },
  {
    x: 5660.15625,
    y: 0.1698760986328125,
  },
  {
    x: 5664.0625,
    y: 0.15492630004882812,
  },
  {
    x: 5667.96875,
    y: 0.14496231079101562,
  },
  {
    x: 5671.875,
    y: 0.13997650146484375,
  },
  {
    x: 5675.78125,
    y: 0.13997650146484375,
  },
  {
    x: 5875,
    y: 0.13997650146484375,
  },
  {
    x: 5878.90625,
    y: 0.13001251220703125,
  },
  {
    x: 5882.8125,
    y: 0.10509490966796875,
  },
  {
    x: 5886.71875,
    y: 0.0851593017578125,
  },
  {
    x: 5890.625,
    y: 0.0751953125,
  },
  {
    x: 5894.53125,
    y: 0.06522369384765625,
  },
  {
    x: 5898.4375,
    y: 0.06522369384765625,
  },
  {
    x: 5902.34375,
    y: 0.06024169921875,
  },
  {
    x: 5906.25,
    y: 0.06024169921875,
  },
  {
    x: 5910.15625,
    y: 0.06024169921875,
  },
  {
    x: 5914.0625,
    y: 0.05525970458984375,
  },
  {
    x: 5917.96875,
    y: 0.050273895263671875,
  },
  {
    x: 5921.875,
    y: 0.050273895263671875,
  },
  {
    x: 5925.78125,
    y: 0.045291900634765625,
  },
  {
    x: 5929.6875,
    y: 0.040309906005859375,
  },
  {
    x: 5933.59375,
    y: 0.040309906005859375,
  },
  {
    x: 5937.5,
    y: 0.040309906005859375,
  },
  {
    x: 5941.40625,
    y: 0.0353240966796875,
  },
  {
    x: 5945.3125,
    y: 0.0353240966796875,
  },
  {
    x: 5953.125,
    y: 0.0353240966796875,
  },
  {
    x: 5957.03125,
    y: 0.03034210205078125,
  },
  {
    x: 5960.9375,
    y: 0.025356292724609375,
  },
  {
    x: 5964.84375,
    y: 0.03034210205078125,
  },
  {
    x: 5968.75,
    y: 0.0353240966796875,
  },
  {
    x: 5972.65625,
    y: 0.040309906005859375,
  },
  {
    x: 5976.5625,
    y: 0.040309906005859375,
  },
  {
    x: 5980.46875,
    y: 0.045291900634765625,
  },
  {
    x: 5984.375,
    y: 0.050273895263671875,
  },
  {
    x: 5988.28125,
    y: 0.05525970458984375,
  },
  {
    x: 5992.1875,
    y: 0.06024169921875,
  },
  {
    x: 5996.09375,
    y: 0.06024169921875,
  },
  {
    x: 6000,
    y: 0.06024169921875,
  },
];
export const Ii = [
  {
    x: 0,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 226.5625,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 230.46875,
    y: 2.0685997009277344,
    __typename: 'Points',
  },
  {
    x: 234.375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 238.28125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 269.53125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 273.4375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 277.34375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 281.25,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 285.15625,
    y: 0.1997833251953125,
    __typename: 'Points',
  },
  {
    x: 289.0625,
    y: 0.2745361328125,
    __typename: 'Points',
  },
  {
    x: 292.96875,
    y: 0.3592529296875,
    __typename: 'Points',
  },
  {
    x: 296.875,
    y: 0.45394134521484375,
    __typename: 'Points',
  },
  {
    x: 300.78125,
    y: 0.5486259460449219,
    __typename: 'Points',
  },
  {
    x: 304.6875,
    y: 0.643310546875,
    __typename: 'Points',
  },
  {
    x: 308.59375,
    y: 0.7330207824707031,
    __typename: 'Points',
  },
  {
    x: 312.5,
    y: 0.8177375793457031,
    __typename: 'Points',
  },
  {
    x: 316.40625,
    y: 0.8875045776367188,
    __typename: 'Points',
  },
  {
    x: 320.3125,
    y: 0.9223899841308594,
    __typename: 'Points',
  },
  {
    x: 324.21875,
    y: 0.9174079895019531,
    __typename: 'Points',
  },
  {
    x: 328.125,
    y: 0.8675727844238281,
    __typename: 'Points',
  },
  {
    x: 332.03125,
    y: 0.7928199768066406,
    __typename: 'Points',
  },
  {
    x: 335.9375,
    y: 0.708099365234375,
    __typename: 'Points',
  },
  {
    x: 339.84375,
    y: 0.6183967590332031,
    __typename: 'Points',
  },
  {
    x: 343.75,
    y: 0.523712158203125,
    __typename: 'Points',
  },
  {
    x: 347.65625,
    y: 0.4340057373046875,
    __typename: 'Points',
  },
  {
    x: 351.5625,
    y: 0.3443031311035156,
    __typename: 'Points',
  },
  {
    x: 355.46875,
    y: 0.2645683288574219,
    __typename: 'Points',
  },
  {
    x: 359.375,
    y: 0.20476531982421875,
    __typename: 'Points',
  },
  {
    x: 363.28125,
    y: 0.159912109375,
    __typename: 'Points',
  },
  {
    x: 367.1875,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 371.09375,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 378.90625,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 589.84375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 593.75,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 597.65625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 601.5625,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 605.46875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 609.375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 613.28125,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 617.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 628.90625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 632.8125,
    y: -0.054378509521484375,
    __typename: 'Points',
  },
  {
    x: 636.71875,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 640.625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 644.53125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 648.4375,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 656.25,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 660.15625,
    y: -0.1141815185546875,
    __typename: 'Points',
  },
  {
    x: 664.0625,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 667.96875,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 671.875,
    y: -0.13909912109375,
    __typename: 'Points',
  },
  {
    x: 675.78125,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 679.6875,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 683.59375,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 687.5,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 691.40625,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 695.3125,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 699.21875,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 703.125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 710.9375,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 714.84375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 718.75,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 722.65625,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 726.5625,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 730.46875,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 734.375,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 738.28125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 742.1875,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 746.09375,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 750,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 753.90625,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 757.8125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 769.53125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 773.4375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 777.34375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 804.6875,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 808.59375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 812.5,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 835.9375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 839.84375,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 843.75,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 867.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 871.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 894.53125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 898.4375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 902.34375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1226.5625,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1230.46875,
    y: 2.0685997009277344,
    __typename: 'Points',
  },
  {
    x: 1234.375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1238.28125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1269.53125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1273.4375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1277.34375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1281.25,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 1285.15625,
    y: 0.1997833251953125,
    __typename: 'Points',
  },
  {
    x: 1289.0625,
    y: 0.2745361328125,
    __typename: 'Points',
  },
  {
    x: 1292.96875,
    y: 0.3592529296875,
    __typename: 'Points',
  },
  {
    x: 1296.875,
    y: 0.45394134521484375,
    __typename: 'Points',
  },
  {
    x: 1300.78125,
    y: 0.5486259460449219,
    __typename: 'Points',
  },
  {
    x: 1304.6875,
    y: 0.643310546875,
    __typename: 'Points',
  },
  {
    x: 1308.59375,
    y: 0.7330207824707031,
    __typename: 'Points',
  },
  {
    x: 1312.5,
    y: 0.8177375793457031,
    __typename: 'Points',
  },
  {
    x: 1316.40625,
    y: 0.8875045776367188,
    __typename: 'Points',
  },
  {
    x: 1320.3125,
    y: 0.9223899841308594,
    __typename: 'Points',
  },
  {
    x: 1324.21875,
    y: 0.9174079895019531,
    __typename: 'Points',
  },
  {
    x: 1328.125,
    y: 0.8675727844238281,
    __typename: 'Points',
  },
  {
    x: 1332.03125,
    y: 0.7928199768066406,
    __typename: 'Points',
  },
  {
    x: 1335.9375,
    y: 0.708099365234375,
    __typename: 'Points',
  },
  {
    x: 1339.84375,
    y: 0.6183967590332031,
    __typename: 'Points',
  },
  {
    x: 1343.75,
    y: 0.523712158203125,
    __typename: 'Points',
  },
  {
    x: 1347.65625,
    y: 0.4340057373046875,
    __typename: 'Points',
  },
  {
    x: 1351.5625,
    y: 0.3443031311035156,
    __typename: 'Points',
  },
  {
    x: 1355.46875,
    y: 0.2645683288574219,
    __typename: 'Points',
  },
  {
    x: 1359.375,
    y: 0.20476531982421875,
    __typename: 'Points',
  },
  {
    x: 1363.28125,
    y: 0.159912109375,
    __typename: 'Points',
  },
  {
    x: 1367.1875,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 1371.09375,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 1375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1378.90625,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1589.84375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1593.75,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 1597.65625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1601.5625,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 1605.46875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1609.375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1613.28125,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 1617.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 1621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 1628.90625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 1632.8125,
    y: -0.054378509521484375,
    __typename: 'Points',
  },
  {
    x: 1636.71875,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 1640.625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 1644.53125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 1648.4375,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 1652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 1656.25,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 1660.15625,
    y: -0.1141815185546875,
    __typename: 'Points',
  },
  {
    x: 1664.0625,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 1667.96875,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 1671.875,
    y: -0.13909912109375,
    __typename: 'Points',
  },
  {
    x: 1675.78125,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 1679.6875,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 1683.59375,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 1687.5,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 1691.40625,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 1695.3125,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 1699.21875,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 1703.125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 1710.9375,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 1714.84375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1718.75,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 1722.65625,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 1726.5625,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 1730.46875,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 1734.375,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1738.28125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1742.1875,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 1746.09375,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1750,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 1753.90625,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1757.8125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1769.53125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1773.4375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 1777.34375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 1804.6875,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 1808.59375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1812.5,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1835.9375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1839.84375,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1843.75,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1867.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1871.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1894.53125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1898.4375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1902.34375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2226.5625,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2230.46875,
    y: 2.0685997009277344,
    __typename: 'Points',
  },
  {
    x: 2234.375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2238.28125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2269.53125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2273.4375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2277.34375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2281.25,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 2285.15625,
    y: 0.1997833251953125,
    __typename: 'Points',
  },
  {
    x: 2289.0625,
    y: 0.2745361328125,
    __typename: 'Points',
  },
  {
    x: 2292.96875,
    y: 0.3592529296875,
    __typename: 'Points',
  },
  {
    x: 2296.875,
    y: 0.45394134521484375,
    __typename: 'Points',
  },
  {
    x: 2300.78125,
    y: 0.5486259460449219,
    __typename: 'Points',
  },
  {
    x: 2304.6875,
    y: 0.643310546875,
    __typename: 'Points',
  },
  {
    x: 2308.59375,
    y: 0.7330207824707031,
    __typename: 'Points',
  },
  {
    x: 2312.5,
    y: 0.8177375793457031,
    __typename: 'Points',
  },
  {
    x: 2316.40625,
    y: 0.8875045776367188,
    __typename: 'Points',
  },
  {
    x: 2320.3125,
    y: 0.9223899841308594,
    __typename: 'Points',
  },
  {
    x: 2324.21875,
    y: 0.9174079895019531,
    __typename: 'Points',
  },
  {
    x: 2328.125,
    y: 0.8675727844238281,
    __typename: 'Points',
  },
  {
    x: 2332.03125,
    y: 0.7928199768066406,
    __typename: 'Points',
  },
  {
    x: 2335.9375,
    y: 0.708099365234375,
    __typename: 'Points',
  },
  {
    x: 2339.84375,
    y: 0.6183967590332031,
    __typename: 'Points',
  },
  {
    x: 2343.75,
    y: 0.523712158203125,
    __typename: 'Points',
  },
  {
    x: 2347.65625,
    y: 0.4340057373046875,
    __typename: 'Points',
  },
  {
    x: 2351.5625,
    y: 0.3443031311035156,
    __typename: 'Points',
  },
  {
    x: 2355.46875,
    y: 0.2645683288574219,
    __typename: 'Points',
  },
  {
    x: 2359.375,
    y: 0.20476531982421875,
    __typename: 'Points',
  },
  {
    x: 2363.28125,
    y: 0.159912109375,
    __typename: 'Points',
  },
  {
    x: 2367.1875,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 2371.09375,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 2375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2378.90625,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2589.84375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2593.75,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 2597.65625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2601.5625,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 2605.46875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2609.375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2613.28125,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 2617.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 2621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 2628.90625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 2632.8125,
    y: -0.054378509521484375,
    __typename: 'Points',
  },
  {
    x: 2636.71875,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 2640.625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 2644.53125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 2648.4375,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 2652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 2656.25,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 2660.15625,
    y: -0.1141815185546875,
    __typename: 'Points',
  },
  {
    x: 2664.0625,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 2667.96875,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 2671.875,
    y: -0.13909912109375,
    __typename: 'Points',
  },
  {
    x: 2675.78125,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 2679.6875,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 2683.59375,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 2687.5,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 2691.40625,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 2695.3125,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 2699.21875,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 2703.125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 2710.9375,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 2714.84375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2718.75,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 2722.65625,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 2726.5625,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 2730.46875,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 2734.375,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2738.28125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2742.1875,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 2746.09375,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2750,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 2753.90625,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2757.8125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2769.53125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2773.4375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 2777.34375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 2804.6875,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 2808.59375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2812.5,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2835.9375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2839.84375,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2843.75,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2867.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2871.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2894.53125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2898.4375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2902.34375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3226.5625,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3230.46875,
    y: 2.0685997009277344,
    __typename: 'Points',
  },
  {
    x: 3234.375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3238.28125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3269.53125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3273.4375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3277.34375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3281.25,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 3285.15625,
    y: 0.1997833251953125,
    __typename: 'Points',
  },
  {
    x: 3289.0625,
    y: 0.2745361328125,
    __typename: 'Points',
  },
  {
    x: 3292.96875,
    y: 0.3592529296875,
    __typename: 'Points',
  },
  {
    x: 3296.875,
    y: 0.45394134521484375,
    __typename: 'Points',
  },
  {
    x: 3300.78125,
    y: 0.5486259460449219,
    __typename: 'Points',
  },
  {
    x: 3304.6875,
    y: 0.643310546875,
    __typename: 'Points',
  },
  {
    x: 3308.59375,
    y: 0.7330207824707031,
    __typename: 'Points',
  },
  {
    x: 3312.5,
    y: 0.8177375793457031,
    __typename: 'Points',
  },
  {
    x: 3316.40625,
    y: 0.8875045776367188,
    __typename: 'Points',
  },
  {
    x: 3320.3125,
    y: 0.9223899841308594,
    __typename: 'Points',
  },
  {
    x: 3324.21875,
    y: 0.9174079895019531,
    __typename: 'Points',
  },
  {
    x: 3328.125,
    y: 0.8675727844238281,
    __typename: 'Points',
  },
  {
    x: 3332.03125,
    y: 0.7928199768066406,
    __typename: 'Points',
  },
  {
    x: 3335.9375,
    y: 0.708099365234375,
    __typename: 'Points',
  },
  {
    x: 3339.84375,
    y: 0.6183967590332031,
    __typename: 'Points',
  },
  {
    x: 3343.75,
    y: 0.523712158203125,
    __typename: 'Points',
  },
  {
    x: 3347.65625,
    y: 0.4340057373046875,
    __typename: 'Points',
  },
  {
    x: 3351.5625,
    y: 0.3443031311035156,
    __typename: 'Points',
  },
  {
    x: 3355.46875,
    y: 0.2645683288574219,
    __typename: 'Points',
  },
  {
    x: 3359.375,
    y: 0.20476531982421875,
    __typename: 'Points',
  },
  {
    x: 3363.28125,
    y: 0.159912109375,
    __typename: 'Points',
  },
  {
    x: 3367.1875,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 3371.09375,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 3375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3378.90625,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3589.84375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3593.75,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 3597.65625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3601.5625,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 3605.46875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3609.375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3613.28125,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 3617.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 3621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 3628.90625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 3632.8125,
    y: -0.054378509521484375,
    __typename: 'Points',
  },
  {
    x: 3636.71875,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 3640.625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 3644.53125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 3648.4375,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 3652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 3656.25,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 3660.15625,
    y: -0.1141815185546875,
    __typename: 'Points',
  },
  {
    x: 3664.0625,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 3667.96875,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 3671.875,
    y: -0.13909912109375,
    __typename: 'Points',
  },
  {
    x: 3675.78125,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 3679.6875,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 3683.59375,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 3687.5,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 3691.40625,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 3695.3125,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 3699.21875,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 3703.125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 3710.9375,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 3714.84375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3718.75,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 3722.65625,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 3726.5625,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 3730.46875,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 3734.375,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3738.28125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3742.1875,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 3746.09375,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3750,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 3753.90625,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3757.8125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3769.53125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3773.4375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 3777.34375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 3804.6875,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 3808.59375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3812.5,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3835.9375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3839.84375,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3843.75,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3867.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3871.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3894.53125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3898.4375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3902.34375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4226.5625,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4230.46875,
    y: 2.0685997009277344,
    __typename: 'Points',
  },
  {
    x: 4234.375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4238.28125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4269.53125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4273.4375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4277.34375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4281.25,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 4285.15625,
    y: 0.1997833251953125,
    __typename: 'Points',
  },
  {
    x: 4289.0625,
    y: 0.2745361328125,
    __typename: 'Points',
  },
  {
    x: 4292.96875,
    y: 0.3592529296875,
    __typename: 'Points',
  },
  {
    x: 4296.875,
    y: 0.45394134521484375,
    __typename: 'Points',
  },
  {
    x: 4300.78125,
    y: 0.5486259460449219,
    __typename: 'Points',
  },
  {
    x: 4304.6875,
    y: 0.643310546875,
    __typename: 'Points',
  },
  {
    x: 4308.59375,
    y: 0.7330207824707031,
    __typename: 'Points',
  },
  {
    x: 4312.5,
    y: 0.8177375793457031,
    __typename: 'Points',
  },
  {
    x: 4316.40625,
    y: 0.8875045776367188,
    __typename: 'Points',
  },
  {
    x: 4320.3125,
    y: 0.9223899841308594,
    __typename: 'Points',
  },
  {
    x: 4324.21875,
    y: 0.9174079895019531,
    __typename: 'Points',
  },
  {
    x: 4328.125,
    y: 0.8675727844238281,
    __typename: 'Points',
  },
  {
    x: 4332.03125,
    y: 0.7928199768066406,
    __typename: 'Points',
  },
  {
    x: 4335.9375,
    y: 0.708099365234375,
    __typename: 'Points',
  },
  {
    x: 4339.84375,
    y: 0.6183967590332031,
    __typename: 'Points',
  },
  {
    x: 4343.75,
    y: 0.523712158203125,
    __typename: 'Points',
  },
  {
    x: 4347.65625,
    y: 0.4340057373046875,
    __typename: 'Points',
  },
  {
    x: 4351.5625,
    y: 0.3443031311035156,
    __typename: 'Points',
  },
  {
    x: 4355.46875,
    y: 0.2645683288574219,
    __typename: 'Points',
  },
  {
    x: 4359.375,
    y: 0.20476531982421875,
    __typename: 'Points',
  },
  {
    x: 4363.28125,
    y: 0.159912109375,
    __typename: 'Points',
  },
  {
    x: 4367.1875,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 4371.09375,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 4375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4378.90625,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4589.84375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4593.75,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 4597.65625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4601.5625,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 4605.46875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4609.375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4613.28125,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 4617.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 4621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 4628.90625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 4632.8125,
    y: -0.054378509521484375,
    __typename: 'Points',
  },
  {
    x: 4636.71875,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 4640.625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 4644.53125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 4648.4375,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 4652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 4656.25,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 4660.15625,
    y: -0.1141815185546875,
    __typename: 'Points',
  },
  {
    x: 4664.0625,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 4667.96875,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 4671.875,
    y: -0.13909912109375,
    __typename: 'Points',
  },
  {
    x: 4675.78125,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 4679.6875,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 4683.59375,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 4687.5,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 4691.40625,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 4695.3125,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 4699.21875,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 4703.125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 4710.9375,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 4714.84375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4718.75,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 4722.65625,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 4726.5625,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 4730.46875,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 4734.375,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4738.28125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4742.1875,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 4746.09375,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4750,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 4753.90625,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4757.8125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4769.53125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4773.4375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 4777.34375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 4804.6875,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 4808.59375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4812.5,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4835.9375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4839.84375,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4843.75,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4867.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4871.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4894.53125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4898.4375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4902.34375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5226.5625,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5230.46875,
    y: 2.0685997009277344,
    __typename: 'Points',
  },
  {
    x: 5234.375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5238.28125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5269.53125,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5273.4375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5277.34375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5281.25,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 5285.15625,
    y: 0.1997833251953125,
    __typename: 'Points',
  },
  {
    x: 5289.0625,
    y: 0.2745361328125,
    __typename: 'Points',
  },
  {
    x: 5292.96875,
    y: 0.3592529296875,
    __typename: 'Points',
  },
  {
    x: 5296.875,
    y: 0.45394134521484375,
    __typename: 'Points',
  },
  {
    x: 5300.78125,
    y: 0.5486259460449219,
    __typename: 'Points',
  },
  {
    x: 5304.6875,
    y: 0.643310546875,
    __typename: 'Points',
  },
  {
    x: 5308.59375,
    y: 0.7330207824707031,
    __typename: 'Points',
  },
  {
    x: 5312.5,
    y: 0.8177375793457031,
    __typename: 'Points',
  },
  {
    x: 5316.40625,
    y: 0.8875045776367188,
    __typename: 'Points',
  },
  {
    x: 5320.3125,
    y: 0.9223899841308594,
    __typename: 'Points',
  },
  {
    x: 5324.21875,
    y: 0.9174079895019531,
    __typename: 'Points',
  },
  {
    x: 5328.125,
    y: 0.8675727844238281,
    __typename: 'Points',
  },
  {
    x: 5332.03125,
    y: 0.7928199768066406,
    __typename: 'Points',
  },
  {
    x: 5335.9375,
    y: 0.708099365234375,
    __typename: 'Points',
  },
  {
    x: 5339.84375,
    y: 0.6183967590332031,
    __typename: 'Points',
  },
  {
    x: 5343.75,
    y: 0.523712158203125,
    __typename: 'Points',
  },
  {
    x: 5347.65625,
    y: 0.4340057373046875,
    __typename: 'Points',
  },
  {
    x: 5351.5625,
    y: 0.3443031311035156,
    __typename: 'Points',
  },
  {
    x: 5355.46875,
    y: 0.2645683288574219,
    __typename: 'Points',
  },
  {
    x: 5359.375,
    y: 0.20476531982421875,
    __typename: 'Points',
  },
  {
    x: 5363.28125,
    y: 0.159912109375,
    __typename: 'Points',
  },
  {
    x: 5367.1875,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 5371.09375,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 5375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5378.90625,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5589.84375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5593.75,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 5597.65625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5601.5625,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 5605.46875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5609.375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5613.28125,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 5617.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 5621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 5628.90625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 5632.8125,
    y: -0.054378509521484375,
    __typename: 'Points',
  },
  {
    x: 5636.71875,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 5640.625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 5644.53125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 5648.4375,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 5652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 5656.25,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 5660.15625,
    y: -0.1141815185546875,
    __typename: 'Points',
  },
  {
    x: 5664.0625,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 5667.96875,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 5671.875,
    y: -0.13909912109375,
    __typename: 'Points',
  },
  {
    x: 5675.78125,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 5679.6875,
    y: -0.14408111572265625,
    __typename: 'Points',
  },
  {
    x: 5683.59375,
    y: -0.13411712646484375,
    __typename: 'Points',
  },
  {
    x: 5687.5,
    y: -0.1241455078125,
    __typename: 'Points',
  },
  {
    x: 5691.40625,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 5695.3125,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 5699.21875,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 5703.125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 5710.9375,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 5714.84375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5718.75,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 5722.65625,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 5726.5625,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 5730.46875,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 5734.375,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5738.28125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5742.1875,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 5746.09375,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5750,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 5753.90625,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5757.8125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5769.53125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5773.4375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 5777.34375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 5804.6875,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 5808.59375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5812.5,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5835.9375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5839.84375,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5843.75,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5867.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5871.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5894.53125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5898.4375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5902.34375,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 6000,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
];
export const Iii = [
  {
    x: 0,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 46.875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 50.78125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 54.6875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 109.375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 113.28125,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 117.1875,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 246.09375,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 250,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 253.90625,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 500,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 503.90625,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 507.8125,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 511.71875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 515.625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 519.53125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 523.4375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 527.34375,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 531.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 535.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 539.0625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 542.96875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 546.875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 550.78125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 554.6875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 558.59375,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 566.40625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 570.3125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 574.21875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 578.125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 582.03125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 585.9375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 589.84375,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 593.75,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 597.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 601.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 609.375,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 613.28125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 617.1875,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 625,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 636.71875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 640.625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 644.53125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 648.4375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 652.34375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 656.25,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 660.15625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 664.0625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 671.875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 675.78125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 679.6875,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 683.59375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 687.5,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 691.40625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 695.3125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 699.21875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 703.125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 707.03125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 710.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 714.84375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 718.75,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 722.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 726.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 730.46875,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 734.375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 738.28125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 742.1875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 746.09375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 750,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 753.90625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 757.8125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 761.71875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 765.625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 769.53125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 777.34375,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 781.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 785.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 789.0625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 792.96875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 796.875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 800.78125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 804.6875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 808.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 812.5,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 816.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 820.3125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 824.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 828.125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 859.375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 863.28125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 867.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 878.90625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 882.8125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 886.71875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 925.78125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 929.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 933.59375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 941.40625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 945.3125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 949.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 988.28125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 992.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 996.09375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1046.875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1050.78125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1054.6875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1109.375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1113.28125,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 1117.1875,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 1246.09375,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 1250,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 1253.90625,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 1500,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 1503.90625,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 1507.8125,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 1511.71875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1515.625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1519.53125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1523.4375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1527.34375,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1531.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1535.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1539.0625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1542.96875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1546.875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1550.78125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1554.6875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1558.59375,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 1566.40625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 1570.3125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1574.21875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1578.125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1582.03125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1585.9375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1589.84375,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 1593.75,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 1597.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 1601.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 1609.375,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 1613.28125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 1617.1875,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 1621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1625,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1636.71875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1640.625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 1644.53125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1648.4375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1652.34375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1656.25,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1660.15625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1664.0625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1671.875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1675.78125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 1679.6875,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 1683.59375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1687.5,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1691.40625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1695.3125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1699.21875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1703.125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1707.03125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1710.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1714.84375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 1718.75,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 1722.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 1726.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 1730.46875,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 1734.375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1738.28125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1742.1875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1746.09375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1750,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1753.90625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 1757.8125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1761.71875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1765.625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1769.53125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1777.34375,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1781.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1785.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1789.0625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1792.96875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1796.875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1800.78125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 1804.6875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1808.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1812.5,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1816.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1820.3125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1824.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1828.125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1859.375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1863.28125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1867.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1878.90625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1882.8125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1886.71875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1925.78125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1929.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1933.59375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1941.40625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1945.3125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1949.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1988.28125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1992.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1996.09375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2046.875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2050.78125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2054.6875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2109.375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2113.28125,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 2117.1875,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 2246.09375,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 2250,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 2253.90625,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 2500,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 2503.90625,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 2507.8125,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 2511.71875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2515.625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2519.53125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2523.4375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2527.34375,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2531.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2535.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2539.0625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2542.96875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2546.875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2550.78125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2554.6875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2558.59375,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 2566.40625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 2570.3125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2574.21875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2578.125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2582.03125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2585.9375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2589.84375,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 2593.75,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 2597.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 2601.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 2609.375,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 2613.28125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 2617.1875,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 2621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2625,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2636.71875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2640.625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 2644.53125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2648.4375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2652.34375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2656.25,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2660.15625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2664.0625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2671.875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2675.78125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 2679.6875,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 2683.59375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2687.5,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2691.40625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2695.3125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2699.21875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2703.125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2707.03125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2710.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2714.84375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 2718.75,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 2722.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 2726.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 2730.46875,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 2734.375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2738.28125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2742.1875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2746.09375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2750,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2753.90625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 2757.8125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2761.71875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2765.625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2769.53125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2777.34375,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2781.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2785.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2789.0625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2792.96875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2796.875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2800.78125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 2804.6875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2808.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2812.5,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2816.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2820.3125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2824.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2828.125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2859.375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2863.28125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2867.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2878.90625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2882.8125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2886.71875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2925.78125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2929.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2933.59375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2941.40625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2945.3125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2949.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2988.28125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2992.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2996.09375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3046.875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3050.78125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3054.6875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3109.375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3113.28125,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 3117.1875,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 3246.09375,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 3250,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 3253.90625,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 3500,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 3503.90625,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 3507.8125,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 3511.71875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3515.625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3519.53125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3523.4375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3527.34375,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3531.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3535.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3539.0625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3542.96875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3546.875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3550.78125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3554.6875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3558.59375,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 3566.40625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 3570.3125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3574.21875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3578.125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3582.03125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3585.9375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3589.84375,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 3593.75,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 3597.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 3601.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 3609.375,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 3613.28125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 3617.1875,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 3621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3625,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3636.71875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3640.625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 3644.53125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3648.4375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3652.34375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3656.25,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3660.15625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3664.0625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3671.875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3675.78125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 3679.6875,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 3683.59375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3687.5,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3691.40625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3695.3125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3699.21875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3703.125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3707.03125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3710.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3714.84375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 3718.75,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 3722.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 3726.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 3730.46875,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 3734.375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3738.28125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3742.1875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3746.09375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3750,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3753.90625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 3757.8125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3761.71875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3765.625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3769.53125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3777.34375,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3781.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3785.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3789.0625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3792.96875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3796.875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3800.78125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 3804.6875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3808.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3812.5,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3816.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3820.3125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3824.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3828.125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3859.375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3863.28125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3867.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3878.90625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3882.8125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3886.71875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3925.78125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3929.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3933.59375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3941.40625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3945.3125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3949.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3988.28125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3992.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3996.09375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4046.875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4050.78125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4054.6875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4109.375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4113.28125,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 4117.1875,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 4246.09375,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 4250,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 4253.90625,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 4500,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 4503.90625,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 4507.8125,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 4511.71875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4515.625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4519.53125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4523.4375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4527.34375,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4531.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4535.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4539.0625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4542.96875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4546.875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4550.78125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4554.6875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4558.59375,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 4566.40625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 4570.3125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4574.21875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4578.125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4582.03125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4585.9375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4589.84375,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 4593.75,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 4597.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 4601.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 4609.375,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 4613.28125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 4617.1875,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 4621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4625,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4636.71875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4640.625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 4644.53125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4648.4375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4652.34375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4656.25,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4660.15625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4664.0625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4671.875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4675.78125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 4679.6875,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 4683.59375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4687.5,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4691.40625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4695.3125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4699.21875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4703.125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4707.03125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4710.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4714.84375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 4718.75,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 4722.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 4726.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 4730.46875,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 4734.375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4738.28125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4742.1875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4746.09375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4750,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4753.90625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 4757.8125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4761.71875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4765.625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4769.53125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4777.34375,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4781.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4785.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4789.0625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4792.96875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4796.875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4800.78125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 4804.6875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4808.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4812.5,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4816.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4820.3125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4824.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4828.125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4859.375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4863.28125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4867.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4878.90625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4882.8125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4886.71875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4925.78125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4929.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4933.59375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4941.40625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4945.3125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4949.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4988.28125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4992.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4996.09375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5046.875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5050.78125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5054.6875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5109.375,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5113.28125,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 5117.1875,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 5246.09375,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 5250,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 5253.90625,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 5500,
    y: 0.14994430541992188,
    __typename: 'Points',
  },
  {
    x: 5503.90625,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 5507.8125,
    y: 0.13997650146484375,
    __typename: 'Points',
  },
  {
    x: 5511.71875,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5515.625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5519.53125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5523.4375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5527.34375,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5531.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5535.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5539.0625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5542.96875,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5546.875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5550.78125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5554.6875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5558.59375,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 5566.40625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 5570.3125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5574.21875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5578.125,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5582.03125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5585.9375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5589.84375,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 5593.75,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 5597.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 5601.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 5609.375,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 5613.28125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 5617.1875,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 5621.09375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5625,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5636.71875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5640.625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 5644.53125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5648.4375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5652.34375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5656.25,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5660.15625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5664.0625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5671.875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5675.78125,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 5679.6875,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 5683.59375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5687.5,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5691.40625,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5695.3125,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5699.21875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5703.125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5707.03125,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5710.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5714.84375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 5718.75,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 5722.65625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 5726.5625,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 5730.46875,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 5734.375,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5738.28125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5742.1875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5746.09375,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5750,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5753.90625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 5757.8125,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5761.71875,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5765.625,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5769.53125,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5777.34375,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5781.25,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5785.15625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5789.0625,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5792.96875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5796.875,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5800.78125,
    y: 0.08017730712890625,
    __typename: 'Points',
  },
  {
    x: 5804.6875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5808.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5812.5,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5816.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5820.3125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5824.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5828.125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5859.375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5863.28125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5867.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5878.90625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5882.8125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5886.71875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5925.78125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5929.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5933.59375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5941.40625,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5945.3125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5949.21875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5988.28125,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5992.1875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5996.09375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 6000,
    y: 0.110076904296875,
    __typename: 'Points',
  },
];
export const Avr = [
  {
    x: 0,
    y: 0.31439971923828125,
  },
  {
    x: 7.8125,
    y: 0.31439971923828125,
  },
  {
    x: 11.71875,
    y: 0.30443572998046875,
  },
  {
    x: 15.625,
    y: 0.2944679260253906,
  },
  {
    x: 19.53125,
    y: 0.2845001220703125,
  },
  {
    x: 23.4375,
    y: 0.27951812744140625,
  },
  {
    x: 27.34375,
    y: 0.2695503234863281,
  },
  {
    x: 31.25,
    y: 0.2595863342285156,
  },
  {
    x: 35.15625,
    y: 0.24961471557617188,
  },
  {
    x: 39.0625,
    y: 0.24462890625,
  },
  {
    x: 42.96875,
    y: 0.2346649169921875,
  },
  {
    x: 46.875,
    y: 0.22469711303710938,
  },
  {
    x: 50.78125,
    y: 0.21971511840820312,
  },
  {
    x: 54.6875,
    y: 0.209747314453125,
  },
  {
    x: 58.59375,
    y: 0.1997833251953125,
  },
  {
    x: 62.5,
    y: 0.19479751586914062,
  },
  {
    x: 66.40625,
    y: 0.18483352661132812,
  },
  {
    x: 70.3125,
    y: 0.17486572265625,
  },
  {
    x: 74.21875,
    y: 0.17486572265625,
  },
  {
    x: 78.125,
    y: 0.1698760986328125,
  },
  {
    x: 82.03125,
    y: 0.1698760986328125,
  },
  {
    x: 89.84375,
    y: 0.1698760986328125,
  },
  {
    x: 93.75,
    y: 0.16489410400390625,
  },
  {
    x: 97.65625,
    y: 0.16489410400390625,
  },
  {
    x: 121.09375,
    y: 0.16489410400390625,
  },
  {
    x: 125,
    y: 0.159912109375,
  },
  {
    x: 128.90625,
    y: 0.159912109375,
  },
  {
    x: 152.34375,
    y: 0.159912109375,
  },
  {
    x: 156.25,
    y: 0.15492630004882812,
  },
  {
    x: 160.15625,
    y: 0.15492630004882812,
  },
  {
    x: 355.46875,
    y: 0.15492630004882812,
  },
  {
    x: 359.375,
    y: 0.13997650146484375,
  },
  {
    x: 363.28125,
    y: 0.09014511108398438,
  },
  {
    x: 367.1875,
    y: 0.03034210205078125,
  },
  {
    x: 382.8125,
    y: -0.20886993408203125,
  },
  {
    x: 386.71875,
    y: -0.26866912841796875,
  },
  {
    x: 390.625,
    y: -0.3284721374511719,
  },
  {
    x: 394.53125,
    y: -0.388275146484375,
  },
  {
    x: 398.4375,
    y: -0.4231605529785156,
  },
  {
    x: 402.34375,
    y: -0.4032249450683594,
  },
  {
    x: 406.25,
    y: -0.3533897399902344,
  },
  {
    x: 410.15625,
    y: -0.2985725402832031,
  },
  {
    x: 414.0625,
    y: -0.24375152587890625,
  },
  {
    x: 417.96875,
    y: -0.18394851684570312,
  },
  {
    x: 421.875,
    y: -0.12913131713867188,
  },
  {
    x: 425.78125,
    y: -0.074310302734375,
  },
  {
    x: 429.6875,
    y: -0.01949310302734375,
  },
  {
    x: 433.59375,
    y: 0.040309906005859375,
  },
  {
    x: 437.5,
    y: 0.0851593017578125,
  },
  {
    x: 441.40625,
    y: 0.10010910034179688,
  },
  {
    x: 445.3125,
    y: 0.10509490966796875,
  },
  {
    x: 449.21875,
    y: 0.110076904296875,
  },
  {
    x: 453.125,
    y: 0.110076904296875,
  },
  {
    x: 496.09375,
    y: 0.110076904296875,
  },
  {
    x: 500,
    y: 0.27951812744140625,
  },
  {
    x: 503.90625,
    y: 0.2695503234863281,
  },
  {
    x: 507.8125,
    y: 0.2595863342285156,
  },
  {
    x: 511.71875,
    y: 0.24961471557617188,
  },
  {
    x: 515.625,
    y: 0.24462890625,
  },
  {
    x: 519.53125,
    y: 0.2346649169921875,
  },
  {
    x: 523.4375,
    y: 0.22469711303710938,
  },
  {
    x: 527.34375,
    y: 0.21971511840820312,
  },
  {
    x: 531.25,
    y: 0.209747314453125,
  },
  {
    x: 535.15625,
    y: 0.1997833251953125,
  },
  {
    x: 539.0625,
    y: 0.19479751586914062,
  },
  {
    x: 542.96875,
    y: 0.18483352661132812,
  },
  {
    x: 546.875,
    y: 0.17486572265625,
  },
  {
    x: 550.78125,
    y: 0.17486572265625,
  },
  {
    x: 554.6875,
    y: 0.1698760986328125,
  },
  {
    x: 558.59375,
    y: 0.1698760986328125,
  },
  {
    x: 566.40625,
    y: 0.1698760986328125,
  },
  {
    x: 570.3125,
    y: 0.16489410400390625,
  },
  {
    x: 574.21875,
    y: 0.16489410400390625,
  },
  {
    x: 597.65625,
    y: 0.16489410400390625,
  },
  {
    x: 601.5625,
    y: 0.159912109375,
  },
  {
    x: 605.46875,
    y: 0.159912109375,
  },
  {
    x: 628.90625,
    y: 0.159912109375,
  },
  {
    x: 632.8125,
    y: 0.15492630004882812,
  },
  {
    x: 636.71875,
    y: 0.15492630004882812,
  },
  {
    x: 1082.03125,
    y: 0.15492630004882812,
  },
  {
    x: 1085.9375,
    y: 0.13997650146484375,
  },
  {
    x: 1089.84375,
    y: 0.09014511108398438,
  },
  {
    x: 1093.75,
    y: 0.03034210205078125,
  },
  {
    x: 1109.375,
    y: -0.20886993408203125,
  },
  {
    x: 1113.28125,
    y: -0.26866912841796875,
  },
  {
    x: 1117.1875,
    y: -0.3284721374511719,
  },
  {
    x: 1121.09375,
    y: -0.388275146484375,
  },
  {
    x: 1125,
    y: -0.4231605529785156,
  },
  {
    x: 1128.90625,
    y: -0.4032249450683594,
  },
  {
    x: 1132.8125,
    y: -0.3533897399902344,
  },
  {
    x: 1136.71875,
    y: -0.2985725402832031,
  },
  {
    x: 1140.625,
    y: -0.24375152587890625,
  },
  {
    x: 1144.53125,
    y: -0.18394851684570312,
  },
  {
    x: 1148.4375,
    y: -0.12913131713867188,
  },
  {
    x: 1152.34375,
    y: -0.074310302734375,
  },
  {
    x: 1156.25,
    y: -0.01949310302734375,
  },
  {
    x: 1160.15625,
    y: 0.040309906005859375,
  },
  {
    x: 1164.0625,
    y: 0.0851593017578125,
  },
  {
    x: 1167.96875,
    y: 0.10010910034179688,
  },
  {
    x: 1171.875,
    y: 0.10509490966796875,
  },
  {
    x: 1175.78125,
    y: 0.110076904296875,
  },
  {
    x: 1179.6875,
    y: 0.110076904296875,
  },
  {
    x: 1375,
    y: 0.110076904296875,
  },
  {
    x: 1378.90625,
    y: 0.125030517578125,
  },
  {
    x: 1382.8125,
    y: 0.15492630004882812,
  },
  {
    x: 1386.71875,
    y: 0.19479751586914062,
  },
  {
    x: 1390.625,
    y: 0.21971511840820312,
  },
  {
    x: 1394.53125,
    y: 0.2346649169921875,
  },
  {
    x: 1398.4375,
    y: 0.23964691162109375,
  },
  {
    x: 1402.34375,
    y: 0.24462890625,
  },
  {
    x: 1406.25,
    y: 0.25460052490234375,
  },
  {
    x: 1410.15625,
    y: 0.2595863342285156,
  },
  {
    x: 1414.0625,
    y: 0.2645683288574219,
  },
  {
    x: 1417.96875,
    y: 0.2695503234863281,
  },
  {
    x: 1421.875,
    y: 0.2745361328125,
  },
  {
    x: 1425.78125,
    y: 0.27951812744140625,
  },
  {
    x: 1429.6875,
    y: 0.2845001220703125,
  },
  {
    x: 1433.59375,
    y: 0.2894859313964844,
  },
  {
    x: 1437.5,
    y: 0.2944679260253906,
  },
  {
    x: 1441.40625,
    y: 0.2994499206542969,
  },
  {
    x: 1445.3125,
    y: 0.30443572998046875,
  },
  {
    x: 1449.21875,
    y: 0.309417724609375,
  },
  {
    x: 1453.125,
    y: 0.31439971923828125,
  },
  {
    x: 1457.03125,
    y: 0.31439971923828125,
  },
  {
    x: 1460.9375,
    y: 0.31439971923828125,
  },
  {
    x: 1464.84375,
    y: 0.30443572998046875,
  },
  {
    x: 1468.75,
    y: 0.2944679260253906,
  },
  {
    x: 1472.65625,
    y: 0.2845001220703125,
  },
  {
    x: 1476.5625,
    y: 0.27951812744140625,
  },
  {
    x: 1480.46875,
    y: 0.2695503234863281,
  },
  {
    x: 1484.375,
    y: 0.2595863342285156,
  },
  {
    x: 1488.28125,
    y: 0.24961471557617188,
  },
  {
    x: 1492.1875,
    y: 0.24462890625,
  },
  {
    x: 1496.09375,
    y: 0.2346649169921875,
  },
  {
    x: 1500,
    y: 0.22469711303710938,
  },
  {
    x: 1503.90625,
    y: 0.21971511840820312,
  },
  {
    x: 1507.8125,
    y: 0.209747314453125,
  },
  {
    x: 1511.71875,
    y: 0.1997833251953125,
  },
  {
    x: 1515.625,
    y: 0.19479751586914062,
  },
  {
    x: 1519.53125,
    y: 0.18483352661132812,
  },
  {
    x: 1523.4375,
    y: 0.17486572265625,
  },
  {
    x: 1527.34375,
    y: 0.17486572265625,
  },
  {
    x: 1531.25,
    y: 0.1698760986328125,
  },
  {
    x: 1535.15625,
    y: 0.1698760986328125,
  },
  {
    x: 1542.96875,
    y: 0.1698760986328125,
  },
  {
    x: 1546.875,
    y: 0.16489410400390625,
  },
  {
    x: 1550.78125,
    y: 0.16489410400390625,
  },
  {
    x: 1574.21875,
    y: 0.16489410400390625,
  },
  {
    x: 1578.125,
    y: 0.159912109375,
  },
  {
    x: 1582.03125,
    y: 0.159912109375,
  },
  {
    x: 1605.46875,
    y: 0.159912109375,
  },
  {
    x: 1609.375,
    y: 0.15492630004882812,
  },
  {
    x: 1613.28125,
    y: 0.15492630004882812,
  },
  {
    x: 2058.59375,
    y: 0.15492630004882812,
  },
  {
    x: 2062.5,
    y: 0.13997650146484375,
  },
  {
    x: 2066.40625,
    y: 0.09014511108398438,
  },
  {
    x: 2070.3125,
    y: 0.03034210205078125,
  },
  {
    x: 2085.9375,
    y: -0.20886993408203125,
  },
  {
    x: 2089.84375,
    y: -0.26866912841796875,
  },
  {
    x: 2093.75,
    y: -0.3284721374511719,
  },
  {
    x: 2097.65625,
    y: -0.388275146484375,
  },
  {
    x: 2101.5625,
    y: -0.4231605529785156,
  },
  {
    x: 2105.46875,
    y: -0.4032249450683594,
  },
  {
    x: 2109.375,
    y: -0.3533897399902344,
  },
  {
    x: 2113.28125,
    y: -0.2985725402832031,
  },
  {
    x: 2117.1875,
    y: -0.24375152587890625,
  },
  {
    x: 2121.09375,
    y: -0.18394851684570312,
  },
  {
    x: 2125,
    y: -0.12913131713867188,
  },
  {
    x: 2128.90625,
    y: -0.074310302734375,
  },
  {
    x: 2132.8125,
    y: -0.01949310302734375,
  },
  {
    x: 2136.71875,
    y: 0.040309906005859375,
  },
  {
    x: 2140.625,
    y: 0.0851593017578125,
  },
  {
    x: 2144.53125,
    y: 0.10010910034179688,
  },
  {
    x: 2148.4375,
    y: 0.10509490966796875,
  },
  {
    x: 2152.34375,
    y: 0.110076904296875,
  },
  {
    x: 2156.25,
    y: 0.110076904296875,
  },
  {
    x: 2351.5625,
    y: 0.110076904296875,
  },
  {
    x: 2355.46875,
    y: 0.125030517578125,
  },
  {
    x: 2359.375,
    y: 0.15492630004882812,
  },
  {
    x: 2363.28125,
    y: 0.19479751586914062,
  },
  {
    x: 2367.1875,
    y: 0.21971511840820312,
  },
  {
    x: 2371.09375,
    y: 0.2346649169921875,
  },
  {
    x: 2375,
    y: 0.23964691162109375,
  },
  {
    x: 2378.90625,
    y: 0.24462890625,
  },
  {
    x: 2382.8125,
    y: 0.25460052490234375,
  },
  {
    x: 2386.71875,
    y: 0.2595863342285156,
  },
  {
    x: 2390.625,
    y: 0.2645683288574219,
  },
  {
    x: 2394.53125,
    y: 0.2695503234863281,
  },
  {
    x: 2398.4375,
    y: 0.2745361328125,
  },
  {
    x: 2402.34375,
    y: 0.27951812744140625,
  },
  {
    x: 2406.25,
    y: 0.2845001220703125,
  },
  {
    x: 2410.15625,
    y: 0.2894859313964844,
  },
  {
    x: 2414.0625,
    y: 0.2944679260253906,
  },
  {
    x: 2417.96875,
    y: 0.2994499206542969,
  },
  {
    x: 2421.875,
    y: 0.30443572998046875,
  },
  {
    x: 2425.78125,
    y: 0.309417724609375,
  },
  {
    x: 2429.6875,
    y: 0.31439971923828125,
  },
  {
    x: 2433.59375,
    y: 0.31439971923828125,
  },
  {
    x: 2437.5,
    y: 0.31439971923828125,
  },
  {
    x: 2441.40625,
    y: 0.30443572998046875,
  },
  {
    x: 2445.3125,
    y: 0.2944679260253906,
  },
  {
    x: 2449.21875,
    y: 0.2845001220703125,
  },
  {
    x: 2453.125,
    y: 0.27951812744140625,
  },
  {
    x: 2457.03125,
    y: 0.2695503234863281,
  },
  {
    x: 2460.9375,
    y: 0.2595863342285156,
  },
  {
    x: 2464.84375,
    y: 0.24961471557617188,
  },
  {
    x: 2468.75,
    y: 0.24462890625,
  },
  {
    x: 2472.65625,
    y: 0.2346649169921875,
  },
  {
    x: 2476.5625,
    y: 0.22469711303710938,
  },
  {
    x: 2480.46875,
    y: 0.21971511840820312,
  },
  {
    x: 2484.375,
    y: 0.209747314453125,
  },
  {
    x: 2488.28125,
    y: 0.1997833251953125,
  },
  {
    x: 2492.1875,
    y: 0.19479751586914062,
  },
  {
    x: 2496.09375,
    y: 0.18483352661132812,
  },
  {
    x: 2500,
    y: 0.17486572265625,
  },
  {
    x: 2503.90625,
    y: 0.17486572265625,
  },
  {
    x: 2507.8125,
    y: 0.1698760986328125,
  },
  {
    x: 2511.71875,
    y: 0.1698760986328125,
  },
  {
    x: 2519.53125,
    y: 0.1698760986328125,
  },
  {
    x: 2523.4375,
    y: 0.16489410400390625,
  },
  {
    x: 2527.34375,
    y: 0.16489410400390625,
  },
  {
    x: 2550.78125,
    y: 0.16489410400390625,
  },
  {
    x: 2554.6875,
    y: 0.159912109375,
  },
  {
    x: 2558.59375,
    y: 0.159912109375,
  },
  {
    x: 2582.03125,
    y: 0.159912109375,
  },
  {
    x: 2585.9375,
    y: 0.15492630004882812,
  },
  {
    x: 2589.84375,
    y: 0.15492630004882812,
  },
  {
    x: 3035.15625,
    y: 0.15492630004882812,
  },
  {
    x: 3039.0625,
    y: 0.13997650146484375,
  },
  {
    x: 3042.96875,
    y: 0.09014511108398438,
  },
  {
    x: 3046.875,
    y: 0.03034210205078125,
  },
  {
    x: 3062.5,
    y: -0.20886993408203125,
  },
  {
    x: 3066.40625,
    y: -0.26866912841796875,
  },
  {
    x: 3070.3125,
    y: -0.3284721374511719,
  },
  {
    x: 3074.21875,
    y: -0.388275146484375,
  },
  {
    x: 3078.125,
    y: -0.4231605529785156,
  },
  {
    x: 3082.03125,
    y: -0.4032249450683594,
  },
  {
    x: 3085.9375,
    y: -0.3533897399902344,
  },
  {
    x: 3089.84375,
    y: -0.2985725402832031,
  },
  {
    x: 3093.75,
    y: -0.24375152587890625,
  },
  {
    x: 3097.65625,
    y: -0.18394851684570312,
  },
  {
    x: 3101.5625,
    y: -0.12913131713867188,
  },
  {
    x: 3105.46875,
    y: -0.074310302734375,
  },
  {
    x: 3109.375,
    y: -0.01949310302734375,
  },
  {
    x: 3113.28125,
    y: 0.040309906005859375,
  },
  {
    x: 3117.1875,
    y: 0.0851593017578125,
  },
  {
    x: 3121.09375,
    y: 0.10010910034179688,
  },
  {
    x: 3125,
    y: 0.10509490966796875,
  },
  {
    x: 3128.90625,
    y: 0.110076904296875,
  },
  {
    x: 3132.8125,
    y: 0.110076904296875,
  },
  {
    x: 3328.125,
    y: 0.110076904296875,
  },
  {
    x: 3332.03125,
    y: 0.125030517578125,
  },
  {
    x: 3335.9375,
    y: 0.15492630004882812,
  },
  {
    x: 3339.84375,
    y: 0.19479751586914062,
  },
  {
    x: 3343.75,
    y: 0.21971511840820312,
  },
  {
    x: 3347.65625,
    y: 0.2346649169921875,
  },
  {
    x: 3351.5625,
    y: 0.23964691162109375,
  },
  {
    x: 3355.46875,
    y: 0.24462890625,
  },
  {
    x: 3359.375,
    y: 0.25460052490234375,
  },
  {
    x: 3363.28125,
    y: 0.2595863342285156,
  },
  {
    x: 3367.1875,
    y: 0.2645683288574219,
  },
  {
    x: 3371.09375,
    y: 0.2695503234863281,
  },
  {
    x: 3375,
    y: 0.2745361328125,
  },
  {
    x: 3378.90625,
    y: 0.27951812744140625,
  },
  {
    x: 3382.8125,
    y: 0.2845001220703125,
  },
  {
    x: 3386.71875,
    y: 0.2894859313964844,
  },
  {
    x: 3390.625,
    y: 0.2944679260253906,
  },
  {
    x: 3394.53125,
    y: 0.2994499206542969,
  },
  {
    x: 3398.4375,
    y: 0.30443572998046875,
  },
  {
    x: 3402.34375,
    y: 0.309417724609375,
  },
  {
    x: 3406.25,
    y: 0.31439971923828125,
  },
  {
    x: 3410.15625,
    y: 0.31439971923828125,
  },
  {
    x: 3414.0625,
    y: 0.31439971923828125,
  },
  {
    x: 3417.96875,
    y: 0.30443572998046875,
  },
  {
    x: 3421.875,
    y: 0.2944679260253906,
  },
  {
    x: 3425.78125,
    y: 0.2845001220703125,
  },
  {
    x: 3429.6875,
    y: 0.27951812744140625,
  },
  {
    x: 3433.59375,
    y: 0.2695503234863281,
  },
  {
    x: 3437.5,
    y: 0.2595863342285156,
  },
  {
    x: 3441.40625,
    y: 0.24961471557617188,
  },
  {
    x: 3445.3125,
    y: 0.24462890625,
  },
  {
    x: 3449.21875,
    y: 0.2346649169921875,
  },
  {
    x: 3453.125,
    y: 0.22469711303710938,
  },
  {
    x: 3457.03125,
    y: 0.21971511840820312,
  },
  {
    x: 3460.9375,
    y: 0.209747314453125,
  },
  {
    x: 3464.84375,
    y: 0.1997833251953125,
  },
  {
    x: 3468.75,
    y: 0.19479751586914062,
  },
  {
    x: 3472.65625,
    y: 0.18483352661132812,
  },
  {
    x: 3476.5625,
    y: 0.17486572265625,
  },
  {
    x: 3480.46875,
    y: 0.17486572265625,
  },
  {
    x: 3484.375,
    y: 0.1698760986328125,
  },
  {
    x: 3488.28125,
    y: 0.1698760986328125,
  },
  {
    x: 3496.09375,
    y: 0.1698760986328125,
  },
  {
    x: 3500,
    y: 0.16489410400390625,
  },
  {
    x: 3503.90625,
    y: 0.16489410400390625,
  },
  {
    x: 3527.34375,
    y: 0.16489410400390625,
  },
  {
    x: 3531.25,
    y: 0.159912109375,
  },
  {
    x: 3535.15625,
    y: 0.159912109375,
  },
  {
    x: 3558.59375,
    y: 0.159912109375,
  },
  {
    x: 3562.5,
    y: 0.15492630004882812,
  },
  {
    x: 3566.40625,
    y: 0.15492630004882812,
  },
  {
    x: 4011.71875,
    y: 0.15492630004882812,
  },
  {
    x: 4015.625,
    y: 0.13997650146484375,
  },
  {
    x: 4019.53125,
    y: 0.09014511108398438,
  },
  {
    x: 4023.4375,
    y: 0.03034210205078125,
  },
  {
    x: 4039.0625,
    y: -0.20886993408203125,
  },
  {
    x: 4042.96875,
    y: -0.26866912841796875,
  },
  {
    x: 4046.875,
    y: -0.3284721374511719,
  },
  {
    x: 4050.78125,
    y: -0.388275146484375,
  },
  {
    x: 4054.6875,
    y: -0.4231605529785156,
  },
  {
    x: 4058.59375,
    y: -0.4032249450683594,
  },
  {
    x: 4062.5,
    y: -0.3533897399902344,
  },
  {
    x: 4066.40625,
    y: -0.2985725402832031,
  },
  {
    x: 4070.3125,
    y: -0.24375152587890625,
  },
  {
    x: 4074.21875,
    y: -0.18394851684570312,
  },
  {
    x: 4078.125,
    y: -0.12913131713867188,
  },
  {
    x: 4082.03125,
    y: -0.074310302734375,
  },
  {
    x: 4085.9375,
    y: -0.01949310302734375,
  },
  {
    x: 4089.84375,
    y: 0.040309906005859375,
  },
  {
    x: 4093.75,
    y: 0.0851593017578125,
  },
  {
    x: 4097.65625,
    y: 0.10010910034179688,
  },
  {
    x: 4101.5625,
    y: 0.10509490966796875,
  },
  {
    x: 4105.46875,
    y: 0.110076904296875,
  },
  {
    x: 4109.375,
    y: 0.110076904296875,
  },
  {
    x: 4304.6875,
    y: 0.110076904296875,
  },
  {
    x: 4308.59375,
    y: 0.125030517578125,
  },
  {
    x: 4312.5,
    y: 0.15492630004882812,
  },
  {
    x: 4316.40625,
    y: 0.19479751586914062,
  },
  {
    x: 4320.3125,
    y: 0.21971511840820312,
  },
  {
    x: 4324.21875,
    y: 0.2346649169921875,
  },
  {
    x: 4328.125,
    y: 0.23964691162109375,
  },
  {
    x: 4332.03125,
    y: 0.24462890625,
  },
  {
    x: 4335.9375,
    y: 0.25460052490234375,
  },
  {
    x: 4339.84375,
    y: 0.2595863342285156,
  },
  {
    x: 4343.75,
    y: 0.2645683288574219,
  },
  {
    x: 4347.65625,
    y: 0.2695503234863281,
  },
  {
    x: 4351.5625,
    y: 0.2745361328125,
  },
  {
    x: 4355.46875,
    y: 0.27951812744140625,
  },
  {
    x: 4359.375,
    y: 0.2845001220703125,
  },
  {
    x: 4363.28125,
    y: 0.2894859313964844,
  },
  {
    x: 4367.1875,
    y: 0.2944679260253906,
  },
  {
    x: 4371.09375,
    y: 0.2994499206542969,
  },
  {
    x: 4375,
    y: 0.30443572998046875,
  },
  {
    x: 4378.90625,
    y: 0.309417724609375,
  },
  {
    x: 4382.8125,
    y: 0.31439971923828125,
  },
  {
    x: 4386.71875,
    y: 0.3193855285644531,
  },
  {
    x: 4390.625,
    y: 0.31439971923828125,
  },
  {
    x: 4394.53125,
    y: 0.30443572998046875,
  },
  {
    x: 4398.4375,
    y: 0.2944679260253906,
  },
  {
    x: 4402.34375,
    y: 0.2845001220703125,
  },
  {
    x: 4406.25,
    y: 0.27951812744140625,
  },
  {
    x: 4410.15625,
    y: 0.2695503234863281,
  },
  {
    x: 4414.0625,
    y: 0.2595863342285156,
  },
  {
    x: 4417.96875,
    y: 0.24961471557617188,
  },
  {
    x: 4421.875,
    y: 0.24462890625,
  },
  {
    x: 4425.78125,
    y: 0.2346649169921875,
  },
  {
    x: 4429.6875,
    y: 0.22469711303710938,
  },
  {
    x: 4433.59375,
    y: 0.21971511840820312,
  },
  {
    x: 4437.5,
    y: 0.209747314453125,
  },
  {
    x: 4441.40625,
    y: 0.1997833251953125,
  },
  {
    x: 4445.3125,
    y: 0.19479751586914062,
  },
  {
    x: 4449.21875,
    y: 0.18483352661132812,
  },
  {
    x: 4453.125,
    y: 0.17486572265625,
  },
  {
    x: 4457.03125,
    y: 0.17486572265625,
  },
  {
    x: 4460.9375,
    y: 0.1698760986328125,
  },
  {
    x: 4464.84375,
    y: 0.1698760986328125,
  },
  {
    x: 4472.65625,
    y: 0.1698760986328125,
  },
  {
    x: 4476.5625,
    y: 0.16489410400390625,
  },
  {
    x: 4480.46875,
    y: 0.16489410400390625,
  },
  {
    x: 4503.90625,
    y: 0.16489410400390625,
  },
  {
    x: 4507.8125,
    y: 0.159912109375,
  },
  {
    x: 4511.71875,
    y: 0.159912109375,
  },
  {
    x: 4535.15625,
    y: 0.159912109375,
  },
  {
    x: 4539.0625,
    y: 0.15492630004882812,
  },
  {
    x: 4542.96875,
    y: 0.15492630004882812,
  },
  {
    x: 4988.28125,
    y: 0.15492630004882812,
  },
  {
    x: 4992.1875,
    y: 0.13997650146484375,
  },
  {
    x: 4996.09375,
    y: 0.09014511108398438,
  },
  {
    x: 5000,
    y: 0.03034210205078125,
  },
  {
    x: 5015.625,
    y: -0.20886993408203125,
  },
  {
    x: 5019.53125,
    y: -0.26866912841796875,
  },
  {
    x: 5023.4375,
    y: -0.3284721374511719,
  },
  {
    x: 5027.34375,
    y: -0.388275146484375,
  },
  {
    x: 5031.25,
    y: -0.4231605529785156,
  },
  {
    x: 5035.15625,
    y: -0.4032249450683594,
  },
  {
    x: 5039.0625,
    y: -0.3533897399902344,
  },
  {
    x: 5042.96875,
    y: -0.2985725402832031,
  },
  {
    x: 5046.875,
    y: -0.24375152587890625,
  },
  {
    x: 5050.78125,
    y: -0.18394851684570312,
  },
  {
    x: 5054.6875,
    y: -0.12913131713867188,
  },
  {
    x: 5058.59375,
    y: -0.06932830810546875,
  },
  {
    x: 5062.5,
    y: -0.0145111083984375,
  },
  {
    x: 5066.40625,
    y: 0.040309906005859375,
  },
  {
    x: 5070.3125,
    y: 0.09014511108398438,
  },
  {
    x: 5074.21875,
    y: 0.10509490966796875,
  },
  {
    x: 5078.125,
    y: 0.110076904296875,
  },
  {
    x: 5082.03125,
    y: 0.11506271362304688,
  },
  {
    x: 5085.9375,
    y: 0.11506271362304688,
  },
  {
    x: 5281.25,
    y: 0.11506271362304688,
  },
  {
    x: 5285.15625,
    y: 0.13001251220703125,
  },
  {
    x: 5289.0625,
    y: 0.159912109375,
  },
  {
    x: 5292.96875,
    y: 0.19479751586914062,
  },
  {
    x: 5296.875,
    y: 0.21971511840820312,
  },
  {
    x: 5300.78125,
    y: 0.2346649169921875,
  },
  {
    x: 5304.6875,
    y: 0.23964691162109375,
  },
  {
    x: 5308.59375,
    y: 0.24462890625,
  },
  {
    x: 5312.5,
    y: 0.25460052490234375,
  },
  {
    x: 5316.40625,
    y: 0.2595863342285156,
  },
  {
    x: 5320.3125,
    y: 0.2645683288574219,
  },
  {
    x: 5324.21875,
    y: 0.2695503234863281,
  },
  {
    x: 5328.125,
    y: 0.2745361328125,
  },
  {
    x: 5332.03125,
    y: 0.27951812744140625,
  },
  {
    x: 5335.9375,
    y: 0.2845001220703125,
  },
  {
    x: 5339.84375,
    y: 0.2894859313964844,
  },
  {
    x: 5343.75,
    y: 0.2944679260253906,
  },
  {
    x: 5347.65625,
    y: 0.2994499206542969,
  },
  {
    x: 5351.5625,
    y: 0.30443572998046875,
  },
  {
    x: 5355.46875,
    y: 0.309417724609375,
  },
  {
    x: 5359.375,
    y: 0.31439971923828125,
  },
  {
    x: 5363.28125,
    y: 0.31439971923828125,
  },
  {
    x: 5367.1875,
    y: 0.31439971923828125,
  },
  {
    x: 5371.09375,
    y: 0.30443572998046875,
  },
  {
    x: 5375,
    y: 0.2944679260253906,
  },
  {
    x: 5378.90625,
    y: 0.2845001220703125,
  },
  {
    x: 5382.8125,
    y: 0.27951812744140625,
  },
  {
    x: 5386.71875,
    y: 0.2695503234863281,
  },
  {
    x: 5390.625,
    y: 0.2595863342285156,
  },
  {
    x: 5394.53125,
    y: 0.24961471557617188,
  },
  {
    x: 5398.4375,
    y: 0.24462890625,
  },
  {
    x: 5402.34375,
    y: 0.2346649169921875,
  },
  {
    x: 5406.25,
    y: 0.22469711303710938,
  },
  {
    x: 5410.15625,
    y: 0.21971511840820312,
  },
  {
    x: 5414.0625,
    y: 0.209747314453125,
  },
  {
    x: 5417.96875,
    y: 0.1997833251953125,
  },
  {
    x: 5421.875,
    y: 0.19479751586914062,
  },
  {
    x: 5425.78125,
    y: 0.18483352661132812,
  },
  {
    x: 5429.6875,
    y: 0.17486572265625,
  },
  {
    x: 5433.59375,
    y: 0.17486572265625,
  },
  {
    x: 5437.5,
    y: 0.1698760986328125,
  },
  {
    x: 5441.40625,
    y: 0.1698760986328125,
  },
  {
    x: 5449.21875,
    y: 0.1698760986328125,
  },
  {
    x: 5453.125,
    y: 0.16489410400390625,
  },
  {
    x: 5457.03125,
    y: 0.16489410400390625,
  },
  {
    x: 5480.46875,
    y: 0.16489410400390625,
  },
  {
    x: 5484.375,
    y: 0.159912109375,
  },
  {
    x: 5488.28125,
    y: 0.159912109375,
  },
  {
    x: 5511.71875,
    y: 0.159912109375,
  },
  {
    x: 5515.625,
    y: 0.15492630004882812,
  },
  {
    x: 5519.53125,
    y: 0.15492630004882812,
  },
  {
    x: 5746.09375,
    y: 0.15492630004882812,
  },
  {
    x: 5750,
    y: -0.3284721374511719,
  },
  {
    x: 5753.90625,
    y: -0.388275146484375,
  },
  {
    x: 5757.8125,
    y: -0.4231605529785156,
  },
  {
    x: 5761.71875,
    y: -0.4032249450683594,
  },
  {
    x: 5765.625,
    y: -0.3533897399902344,
  },
  {
    x: 5769.53125,
    y: -0.2985725402832031,
  },
  {
    x: 5773.4375,
    y: -0.24375152587890625,
  },
  {
    x: 5777.34375,
    y: -0.18394851684570312,
  },
  {
    x: 5781.25,
    y: -0.12913131713867188,
  },
  {
    x: 5785.15625,
    y: -0.06932830810546875,
  },
  {
    x: 5789.0625,
    y: -0.0145111083984375,
  },
  {
    x: 5792.96875,
    y: 0.040309906005859375,
  },
  {
    x: 5796.875,
    y: 0.09014511108398438,
  },
  {
    x: 5800.78125,
    y: 0.10509490966796875,
  },
  {
    x: 5804.6875,
    y: 0.110076904296875,
  },
  {
    x: 5808.59375,
    y: 0.11506271362304688,
  },
  {
    x: 5812.5,
    y: 0.11506271362304688,
  },
  {
    x: 6000,
    y: 0.11506271362304688,
  },
];
export const Avl = [
  {
    x: 15.625,
    y: 0.1599121093751,
  },
  {
    x: 19.53125,
    y: 0.15492630004882812,
  },
  {
    x: 23.4375,
    y: 0.15492630004882812,
  },
  {
    x: 152.34375,
    y: 0.15492630004882812,
  },
  {
    x: 156.25,
    y: 0.14994430541992188,
  },
  {
    x: 160.15625,
    y: 0.14496231079101562,
  },
  {
    x: 164.0625,
    y: 0.13997650146484375,
  },
  {
    x: 167.96875,
    y: 0.13997650146484375,
  },
  {
    x: 171.875,
    y: 0.13997650146484375,
  },
  {
    x: 175.78125,
    y: 0.14496231079101562,
  },
  {
    x: 179.6875,
    y: 0.14496231079101562,
  },
  {
    x: 183.59375,
    y: 0.14496231079101562,
  },
  {
    x: 187.5,
    y: 0.14994430541992188,
  },
  {
    x: 191.40625,
    y: 0.14994430541992188,
  },
  {
    x: 195.3125,
    y: 0.15492630004882812,
  },
  {
    x: 199.21875,
    y: 0.15492630004882812,
  },
  {
    x: 203.125,
    y: 0.15492630004882812,
  },
  {
    x: 207.03125,
    y: 0.159912109375,
  },
  {
    x: 210.9375,
    y: 0.159912109375,
  },
  {
    x: 214.84375,
    y: 0.16489410400390625,
  },
  {
    x: 218.75,
    y: 0.16489410400390625,
  },
  {
    x: 222.65625,
    y: 0.159912109375,
  },
  {
    x: 226.5625,
    y: 0.16489410400390625,
  },
  {
    x: 230.46875,
    y: 0.16489410400390625,
  },
  {
    x: 234.375,
    y: 0.16489410400390625,
  },
  {
    x: 238.28125,
    y: 0.159912109375,
  },
  {
    x: 242.1875,
    y: 0.159912109375,
  },
  {
    x: 246.09375,
    y: 0.159912109375,
  },
  {
    x: 250,
    y: 0.15492630004882812,
  },
  {
    x: 253.90625,
    y: 0.15492630004882812,
  },
  {
    x: 257.8125,
    y: 0.14994430541992188,
  },
  {
    x: 261.71875,
    y: 0.14994430541992188,
  },
  {
    x: 265.625,
    y: 0.14994430541992188,
  },
  {
    x: 269.53125,
    y: 0.14496231079101562,
  },
  {
    x: 273.4375,
    y: 0.14496231079101562,
  },
  {
    x: 277.34375,
    y: 0.13997650146484375,
  },
  {
    x: 281.25,
    y: 0.13997650146484375,
  },
  {
    x: 285.15625,
    y: 0.13997650146484375,
  },
  {
    x: 289.0625,
    y: 0.1349945068359375,
  },
  {
    x: 296.875,
    y: 0.125030517578125,
  },
  {
    x: 300.78125,
    y: 0.12004470825195312,
  },
  {
    x: 304.6875,
    y: 0.12004470825195312,
  },
  {
    x: 585.9375,
    y: 0.12004470825195312,
  },
  {
    x: 589.84375,
    y: 0.10509490966796875,
  },
  {
    x: 593.75,
    y: 0.09014511108398438,
  },
  {
    x: 597.65625,
    y: 0.07020950317382812,
  },
  {
    x: 601.5625,
    y: 0.050273895263671875,
  },
  {
    x: 605.46875,
    y: 0.0353240966796875,
  },
  {
    x: 609.375,
    y: 0.015392303466796875,
  },
  {
    x: 613.28125,
    y: 0.0004425048828125,
  },
  {
    x: 617.1875,
    y: -0.01949310302734375,
  },
  {
    x: 621.09375,
    y: -0.0394287109375,
  },
  {
    x: 625,
    y: -0.049396514892578125,
  },
  {
    x: 628.90625,
    y: -0.04441070556640625,
  },
  {
    x: 632.8125,
    y: -0.024478912353515625,
  },
  {
    x: 636.71875,
    y: -0.004543304443359375,
  },
  {
    x: 640.625,
    y: 0.020374298095703125,
  },
  {
    x: 644.53125,
    y: 0.040309906005859375,
  },
  {
    x: 648.4375,
    y: 0.06522369384765625,
  },
  {
    x: 652.34375,
    y: 0.0851593017578125,
  },
  {
    x: 656.25,
    y: 0.110076904296875,
  },
  {
    x: 660.15625,
    y: 0.13997650146484375,
  },
  {
    x: 664.0625,
    y: 0.159912109375,
  },
  {
    x: 667.96875,
    y: 0.16489410400390625,
  },
  {
    x: 671.875,
    y: 0.1698760986328125,
  },
  {
    x: 675.78125,
    y: 0.1698760986328125,
  },
  {
    x: 679.6875,
    y: 0.16489410400390625,
  },
  {
    x: 683.59375,
    y: 0.16489410400390625,
  },
  {
    x: 710.9375,
    y: 0.16489410400390625,
  },
  {
    x: 714.84375,
    y: 0.159912109375,
  },
  {
    x: 718.75,
    y: 0.159912109375,
  },
  {
    x: 742.1875,
    y: 0.159912109375,
  },
  {
    x: 746.09375,
    y: 0.15492630004882812,
  },
  {
    x: 750,
    y: 0.15492630004882812,
  },
  {
    x: 878.90625,
    y: 0.15492630004882812,
  },
  {
    x: 882.8125,
    y: 0.14994430541992188,
  },
  {
    x: 886.71875,
    y: 0.14496231079101562,
  },
  {
    x: 890.625,
    y: 0.13997650146484375,
  },
  {
    x: 894.53125,
    y: 0.13997650146484375,
  },
  {
    x: 898.4375,
    y: 0.13997650146484375,
  },
  {
    x: 902.34375,
    y: 0.14496231079101562,
  },
  {
    x: 906.25,
    y: 0.14496231079101562,
  },
  {
    x: 910.15625,
    y: 0.14496231079101562,
  },
  {
    x: 914.0625,
    y: 0.14994430541992188,
  },
  {
    x: 917.96875,
    y: 0.14994430541992188,
  },
  {
    x: 921.875,
    y: 0.15492630004882812,
  },
  {
    x: 925.78125,
    y: 0.15492630004882812,
  },
  {
    x: 929.6875,
    y: 0.15492630004882812,
  },
  {
    x: 933.59375,
    y: 0.159912109375,
  },
  {
    x: 937.5,
    y: 0.159912109375,
  },
  {
    x: 941.40625,
    y: 0.16489410400390625,
  },
  {
    x: 945.3125,
    y: 0.16489410400390625,
  },
  {
    x: 949.21875,
    y: 0.159912109375,
  },
  {
    x: 953.125,
    y: 0.16489410400390625,
  },
  {
    x: 957.03125,
    y: 0.16489410400390625,
  },
  {
    x: 960.9375,
    y: 0.16489410400390625,
  },
  {
    x: 964.84375,
    y: 0.159912109375,
  },
  {
    x: 968.75,
    y: 0.159912109375,
  },
  {
    x: 972.65625,
    y: 0.159912109375,
  },
  {
    x: 976.5625,
    y: 0.15492630004882812,
  },
  {
    x: 980.46875,
    y: 0.15492630004882812,
  },
  {
    x: 984.375,
    y: 0.14994430541992188,
  },
  {
    x: 988.28125,
    y: 0.14994430541992188,
  },
  {
    x: 992.1875,
    y: 0.14994430541992188,
  },
  {
    x: 996.09375,
    y: 0.14496231079101562,
  },
  {
    x: 1000,
    y: 0.14496231079101562,
  },
  {
    x: 1003.90625,
    y: 0.13997650146484375,
  },
  {
    x: 1007.8125,
    y: 0.13997650146484375,
  },
  {
    x: 1011.71875,
    y: 0.13997650146484375,
  },
  {
    x: 1015.625,
    y: 0.1349945068359375,
  },
  {
    x: 1023.4375,
    y: 0.125030517578125,
  },
  {
    x: 1027.34375,
    y: 0.12004470825195312,
  },
  {
    x: 1031.25,
    y: 0.12004470825195312,
  },
  {
    x: 1562.5,
    y: 0.12004470825195312,
  },
  {
    x: 1566.40625,
    y: 0.10509490966796875,
  },
  {
    x: 1570.3125,
    y: 0.09014511108398438,
  },
  {
    x: 1574.21875,
    y: 0.07020950317382812,
  },
  {
    x: 1578.125,
    y: 0.050273895263671875,
  },
  {
    x: 1582.03125,
    y: 0.0353240966796875,
  },
  {
    x: 1585.9375,
    y: 0.015392303466796875,
  },
  {
    x: 1589.84375,
    y: 0.0004425048828125,
  },
  {
    x: 1593.75,
    y: -0.01949310302734375,
  },
  {
    x: 1597.65625,
    y: -0.0394287109375,
  },
  {
    x: 1601.5625,
    y: -0.049396514892578125,
  },
  {
    x: 1605.46875,
    y: -0.04441070556640625,
  },
  {
    x: 1609.375,
    y: -0.024478912353515625,
  },
  {
    x: 1613.28125,
    y: -0.004543304443359375,
  },
  {
    x: 1617.1875,
    y: 0.020374298095703125,
  },
  {
    x: 1621.09375,
    y: 0.040309906005859375,
  },
  {
    x: 1625,
    y: 0.06522369384765625,
  },
  {
    x: 1628.90625,
    y: 0.0851593017578125,
  },
  {
    x: 1632.8125,
    y: 0.110076904296875,
  },
  {
    x: 1636.71875,
    y: 0.13997650146484375,
  },
  {
    x: 1640.625,
    y: 0.159912109375,
  },
  {
    x: 1644.53125,
    y: 0.16489410400390625,
  },
  {
    x: 1648.4375,
    y: 0.1698760986328125,
  },
  {
    x: 1652.34375,
    y: 0.1698760986328125,
  },
  {
    x: 1656.25,
    y: 0.16489410400390625,
  },
  {
    x: 1660.15625,
    y: 0.16489410400390625,
  },
  {
    x: 1687.5,
    y: 0.16489410400390625,
  },
  {
    x: 1691.40625,
    y: 0.159912109375,
  },
  {
    x: 1695.3125,
    y: 0.159912109375,
  },
  {
    x: 1718.75,
    y: 0.159912109375,
  },
  {
    x: 1722.65625,
    y: 0.15492630004882812,
  },
  {
    x: 1726.5625,
    y: 0.15492630004882812,
  },
  {
    x: 1746.09375,
    y: 0.15492630004882812,
  },
  {
    x: 1750,
    y: 0.125030517578125,
  },
  {
    x: 1753.90625,
    y: 0.12004470825195312,
  },
  {
    x: 1757.8125,
    y: 0.12004470825195312,
  },
  {
    x: 2246.09375,
    y: 0.12004470825195312,
  },
  {
    x: 2250,
    y: 0.15492630004882812,
  },
  {
    x: 2253.90625,
    y: 0.15492630004882812,
  },
  {
    x: 2355.46875,
    y: 0.15492630004882812,
  },
  {
    x: 2359.375,
    y: 0.14994430541992188,
  },
  {
    x: 2363.28125,
    y: 0.14496231079101562,
  },
  {
    x: 2367.1875,
    y: 0.13997650146484375,
  },
  {
    x: 2371.09375,
    y: 0.13997650146484375,
  },
  {
    x: 2375,
    y: 0.13997650146484375,
  },
  {
    x: 2378.90625,
    y: 0.14496231079101562,
  },
  {
    x: 2382.8125,
    y: 0.14496231079101562,
  },
  {
    x: 2386.71875,
    y: 0.14496231079101562,
  },
  {
    x: 2390.625,
    y: 0.14994430541992188,
  },
  {
    x: 2394.53125,
    y: 0.14994430541992188,
  },
  {
    x: 2398.4375,
    y: 0.15492630004882812,
  },
  {
    x: 2402.34375,
    y: 0.15492630004882812,
  },
  {
    x: 2406.25,
    y: 0.15492630004882812,
  },
  {
    x: 2410.15625,
    y: 0.159912109375,
  },
  {
    x: 2414.0625,
    y: 0.159912109375,
  },
  {
    x: 2417.96875,
    y: 0.16489410400390625,
  },
  {
    x: 2421.875,
    y: 0.16489410400390625,
  },
  {
    x: 2425.78125,
    y: 0.159912109375,
  },
  {
    x: 2429.6875,
    y: 0.16489410400390625,
  },
  {
    x: 2433.59375,
    y: 0.16489410400390625,
  },
  {
    x: 2437.5,
    y: 0.16489410400390625,
  },
  {
    x: 2441.40625,
    y: 0.159912109375,
  },
  {
    x: 2445.3125,
    y: 0.159912109375,
  },
  {
    x: 2449.21875,
    y: 0.159912109375,
  },
  {
    x: 2453.125,
    y: 0.15492630004882812,
  },
  {
    x: 2457.03125,
    y: 0.15492630004882812,
  },
  {
    x: 2460.9375,
    y: 0.14994430541992188,
  },
  {
    x: 2464.84375,
    y: 0.14994430541992188,
  },
  {
    x: 2468.75,
    y: 0.14994430541992188,
  },
  {
    x: 2472.65625,
    y: 0.14496231079101562,
  },
  {
    x: 2476.5625,
    y: 0.14496231079101562,
  },
  {
    x: 2480.46875,
    y: 0.13997650146484375,
  },
  {
    x: 2484.375,
    y: 0.13997650146484375,
  },
  {
    x: 2488.28125,
    y: 0.13997650146484375,
  },
  {
    x: 2492.1875,
    y: 0.1349945068359375,
  },
  {
    x: 2496.09375,
    y: 0.13001251220703125,
  },
  {
    x: 2500,
    y: 0.12004470825195312,
  },
  {
    x: 2503.90625,
    y: 0.12004470825195312,
  },
  {
    x: 2539.0625,
    y: 0.12004470825195312,
  },
  {
    x: 2542.96875,
    y: 0.10509490966796875,
  },
  {
    x: 2546.875,
    y: 0.09014511108398438,
  },
  {
    x: 2550.78125,
    y: 0.07020950317382812,
  },
  {
    x: 2554.6875,
    y: 0.050273895263671875,
  },
  {
    x: 2558.59375,
    y: 0.0353240966796875,
  },
  {
    x: 2562.5,
    y: 0.015392303466796875,
  },
  {
    x: 2566.40625,
    y: 0.0004425048828125,
  },
  {
    x: 2570.3125,
    y: -0.01949310302734375,
  },
  {
    x: 2574.21875,
    y: -0.0394287109375,
  },
  {
    x: 2578.125,
    y: -0.049396514892578125,
  },
  {
    x: 2582.03125,
    y: -0.04441070556640625,
  },
  {
    x: 2585.9375,
    y: -0.024478912353515625,
  },
  {
    x: 2589.84375,
    y: -0.004543304443359375,
  },
  {
    x: 2593.75,
    y: 0.020374298095703125,
  },
  {
    x: 2597.65625,
    y: 0.040309906005859375,
  },
  {
    x: 2601.5625,
    y: 0.06522369384765625,
  },
  {
    x: 2605.46875,
    y: 0.0851593017578125,
  },
  {
    x: 2609.375,
    y: 0.110076904296875,
  },
  {
    x: 2613.28125,
    y: 0.13997650146484375,
  },
  {
    x: 2617.1875,
    y: 0.159912109375,
  },
  {
    x: 2621.09375,
    y: 0.16489410400390625,
  },
  {
    x: 2625,
    y: 0.1698760986328125,
  },
  {
    x: 2628.90625,
    y: 0.1698760986328125,
  },
  {
    x: 2632.8125,
    y: 0.16489410400390625,
  },
  {
    x: 2636.71875,
    y: 0.16489410400390625,
  },
  {
    x: 2664.0625,
    y: 0.16489410400390625,
  },
  {
    x: 2667.96875,
    y: 0.159912109375,
  },
  {
    x: 2671.875,
    y: 0.159912109375,
  },
  {
    x: 2695.3125,
    y: 0.159912109375,
  },
  {
    x: 2699.21875,
    y: 0.15492630004882812,
  },
  {
    x: 2703.125,
    y: 0.15492630004882812,
  },
  {
    x: 2832.03125,
    y: 0.15492630004882812,
  },
  {
    x: 2835.9375,
    y: 0.14994430541992188,
  },
  {
    x: 2839.84375,
    y: 0.14496231079101562,
  },
  {
    x: 2843.75,
    y: 0.13997650146484375,
  },
  {
    x: 2847.65625,
    y: 0.13997650146484375,
  },
  {
    x: 2851.5625,
    y: 0.13997650146484375,
  },
  {
    x: 2855.46875,
    y: 0.14496231079101562,
  },
  {
    x: 2859.375,
    y: 0.14496231079101562,
  },
  {
    x: 2863.28125,
    y: 0.14496231079101562,
  },
  {
    x: 2867.1875,
    y: 0.14994430541992188,
  },
  {
    x: 2871.09375,
    y: 0.14994430541992188,
  },
  {
    x: 2875,
    y: 0.15492630004882812,
  },
  {
    x: 2878.90625,
    y: 0.15492630004882812,
  },
  {
    x: 2882.8125,
    y: 0.15492630004882812,
  },
  {
    x: 2886.71875,
    y: 0.159912109375,
  },
  {
    x: 2890.625,
    y: 0.159912109375,
  },
  {
    x: 2894.53125,
    y: 0.16489410400390625,
  },
  {
    x: 2898.4375,
    y: 0.16489410400390625,
  },
  {
    x: 2902.34375,
    y: 0.159912109375,
  },
  {
    x: 2906.25,
    y: 0.16489410400390625,
  },
  {
    x: 2910.15625,
    y: 0.16489410400390625,
  },
  {
    x: 2914.0625,
    y: 0.16489410400390625,
  },
  {
    x: 2917.96875,
    y: 0.159912109375,
  },
  {
    x: 2921.875,
    y: 0.159912109375,
  },
  {
    x: 2925.78125,
    y: 0.159912109375,
  },
  {
    x: 2929.6875,
    y: 0.15492630004882812,
  },
  {
    x: 2933.59375,
    y: 0.15492630004882812,
  },
  {
    x: 2937.5,
    y: 0.14994430541992188,
  },
  {
    x: 2941.40625,
    y: 0.14994430541992188,
  },
  {
    x: 2945.3125,
    y: 0.14994430541992188,
  },
  {
    x: 2949.21875,
    y: 0.14496231079101562,
  },
  {
    x: 2953.125,
    y: 0.14496231079101562,
  },
  {
    x: 2957.03125,
    y: 0.13997650146484375,
  },
  {
    x: 2960.9375,
    y: 0.13997650146484375,
  },
  {
    x: 2964.84375,
    y: 0.13997650146484375,
  },
  {
    x: 2968.75,
    y: 0.1349945068359375,
  },
  {
    x: 2976.5625,
    y: 0.125030517578125,
  },
  {
    x: 2980.46875,
    y: 0.12004470825195312,
  },
  {
    x: 2984.375,
    y: 0.12004470825195312,
  },
  {
    x: 3515.625,
    y: 0.12004470825195312,
  },
  {
    x: 3519.53125,
    y: 0.10509490966796875,
  },
  {
    x: 3523.4375,
    y: 0.09014511108398438,
  },
  {
    x: 3527.34375,
    y: 0.07020950317382812,
  },
  {
    x: 3531.25,
    y: 0.050273895263671875,
  },
  {
    x: 3535.15625,
    y: 0.0353240966796875,
  },
  {
    x: 3539.0625,
    y: 0.015392303466796875,
  },
  {
    x: 3542.96875,
    y: 0.0004425048828125,
  },
  {
    x: 3546.875,
    y: -0.01949310302734375,
  },
  {
    x: 3550.78125,
    y: -0.0394287109375,
  },
  {
    x: 3554.6875,
    y: -0.049396514892578125,
  },
  {
    x: 3558.59375,
    y: -0.04441070556640625,
  },
  {
    x: 3562.5,
    y: -0.024478912353515625,
  },
  {
    x: 3566.40625,
    y: -0.004543304443359375,
  },
  {
    x: 3570.3125,
    y: 0.020374298095703125,
  },
  {
    x: 3574.21875,
    y: 0.040309906005859375,
  },
  {
    x: 3578.125,
    y: 0.06522369384765625,
  },
  {
    x: 3582.03125,
    y: 0.0851593017578125,
  },
  {
    x: 3585.9375,
    y: 0.110076904296875,
  },
  {
    x: 3589.84375,
    y: 0.13997650146484375,
  },
  {
    x: 3593.75,
    y: 0.159912109375,
  },
  {
    x: 3597.65625,
    y: 0.16489410400390625,
  },
  {
    x: 3601.5625,
    y: 0.1698760986328125,
  },
  {
    x: 3605.46875,
    y: 0.1698760986328125,
  },
  {
    x: 3609.375,
    y: 0.16489410400390625,
  },
  {
    x: 3613.28125,
    y: 0.16489410400390625,
  },
  {
    x: 3640.625,
    y: 0.16489410400390625,
  },
  {
    x: 3644.53125,
    y: 0.159912109375,
  },
  {
    x: 3648.4375,
    y: 0.159912109375,
  },
  {
    x: 3671.875,
    y: 0.159912109375,
  },
  {
    x: 3675.78125,
    y: 0.15492630004882812,
  },
  {
    x: 3679.6875,
    y: 0.15492630004882812,
  },
  {
    x: 3808.59375,
    y: 0.15492630004882812,
  },
  {
    x: 3812.5,
    y: 0.14994430541992188,
  },
  {
    x: 3816.40625,
    y: 0.14496231079101562,
  },
  {
    x: 3820.3125,
    y: 0.13997650146484375,
  },
  {
    x: 3824.21875,
    y: 0.13997650146484375,
  },
  {
    x: 3828.125,
    y: 0.13997650146484375,
  },
  {
    x: 3832.03125,
    y: 0.14496231079101562,
  },
  {
    x: 3835.9375,
    y: 0.14496231079101562,
  },
  {
    x: 3839.84375,
    y: 0.14496231079101562,
  },
  {
    x: 3843.75,
    y: 0.14994430541992188,
  },
  {
    x: 3847.65625,
    y: 0.14994430541992188,
  },
  {
    x: 3851.5625,
    y: 0.15492630004882812,
  },
  {
    x: 3855.46875,
    y: 0.15492630004882812,
  },
  {
    x: 3859.375,
    y: 0.15492630004882812,
  },
  {
    x: 3863.28125,
    y: 0.159912109375,
  },
  {
    x: 3867.1875,
    y: 0.159912109375,
  },
  {
    x: 3871.09375,
    y: 0.16489410400390625,
  },
  {
    x: 3875,
    y: 0.16489410400390625,
  },
  {
    x: 3878.90625,
    y: 0.159912109375,
  },
  {
    x: 3882.8125,
    y: 0.16489410400390625,
  },
  {
    x: 3886.71875,
    y: 0.16489410400390625,
  },
  {
    x: 3890.625,
    y: 0.16489410400390625,
  },
  {
    x: 3894.53125,
    y: 0.159912109375,
  },
  {
    x: 3898.4375,
    y: 0.159912109375,
  },
  {
    x: 3902.34375,
    y: 0.159912109375,
  },
  {
    x: 3906.25,
    y: 0.15492630004882812,
  },
  {
    x: 3910.15625,
    y: 0.15492630004882812,
  },
  {
    x: 3914.0625,
    y: 0.14994430541992188,
  },
  {
    x: 3917.96875,
    y: 0.14994430541992188,
  },
  {
    x: 3921.875,
    y: 0.14994430541992188,
  },
  {
    x: 3925.78125,
    y: 0.14496231079101562,
  },
  {
    x: 3929.6875,
    y: 0.14496231079101562,
  },
  {
    x: 3933.59375,
    y: 0.13997650146484375,
  },
  {
    x: 3937.5,
    y: 0.13997650146484375,
  },
  {
    x: 3941.40625,
    y: 0.13997650146484375,
  },
  {
    x: 3945.3125,
    y: 0.1349945068359375,
  },
  {
    x: 3953.125,
    y: 0.125030517578125,
  },
  {
    x: 3957.03125,
    y: 0.12004470825195312,
  },
  {
    x: 3960.9375,
    y: 0.12004470825195312,
  },
  {
    x: 4492.1875,
    y: 0.12004470825195312,
  },
  {
    x: 4496.09375,
    y: 0.10509490966796875,
  },
  {
    x: 4500,
    y: 0.09014511108398438,
  },
  {
    x: 4503.90625,
    y: 0.07020950317382812,
  },
  {
    x: 4507.8125,
    y: 0.050273895263671875,
  },
  {
    x: 4511.71875,
    y: 0.0353240966796875,
  },
  {
    x: 4515.625,
    y: 0.015392303466796875,
  },
  {
    x: 4519.53125,
    y: 0.0004425048828125,
  },
  {
    x: 4523.4375,
    y: -0.01949310302734375,
  },
  {
    x: 4527.34375,
    y: -0.0394287109375,
  },
  {
    x: 4531.25,
    y: -0.049396514892578125,
  },
  {
    x: 4535.15625,
    y: -0.04441070556640625,
  },
  {
    x: 4539.0625,
    y: -0.024478912353515625,
  },
  {
    x: 4542.96875,
    y: -0.004543304443359375,
  },
  {
    x: 4546.875,
    y: 0.020374298095703125,
  },
  {
    x: 4550.78125,
    y: 0.040309906005859375,
  },
  {
    x: 4554.6875,
    y: 0.06522369384765625,
  },
  {
    x: 4558.59375,
    y: 0.0851593017578125,
  },
  {
    x: 4562.5,
    y: 0.110076904296875,
  },
  {
    x: 4566.40625,
    y: 0.13997650146484375,
  },
  {
    x: 4570.3125,
    y: 0.159912109375,
  },
  {
    x: 4574.21875,
    y: 0.16489410400390625,
  },
  {
    x: 4578.125,
    y: 0.1698760986328125,
  },
  {
    x: 4582.03125,
    y: 0.1698760986328125,
  },
  {
    x: 4585.9375,
    y: 0.16489410400390625,
  },
  {
    x: 4589.84375,
    y: 0.16489410400390625,
  },
  {
    x: 4617.1875,
    y: 0.16489410400390625,
  },
  {
    x: 4621.09375,
    y: 0.159912109375,
  },
  {
    x: 4625,
    y: 0.159912109375,
  },
  {
    x: 4648.4375,
    y: 0.159912109375,
  },
  {
    x: 4652.34375,
    y: 0.15492630004882812,
  },
  {
    x: 4656.25,
    y: 0.15492630004882812,
  },
  {
    x: 4785.15625,
    y: 0.15492630004882812,
  },
  {
    x: 4789.0625,
    y: 0.14994430541992188,
  },
  {
    x: 4792.96875,
    y: 0.14496231079101562,
  },
  {
    x: 4796.875,
    y: 0.13997650146484375,
  },
  {
    x: 4800.78125,
    y: 0.13997650146484375,
  },
  {
    x: 4804.6875,
    y: 0.13997650146484375,
  },
  {
    x: 4808.59375,
    y: 0.14496231079101562,
  },
  {
    x: 4812.5,
    y: 0.14496231079101562,
  },
  {
    x: 4816.40625,
    y: 0.14496231079101562,
  },
  {
    x: 4820.3125,
    y: 0.14994430541992188,
  },
  {
    x: 4824.21875,
    y: 0.14994430541992188,
  },
  {
    x: 4828.125,
    y: 0.15492630004882812,
  },
  {
    x: 4832.03125,
    y: 0.15492630004882812,
  },
  {
    x: 4835.9375,
    y: 0.15492630004882812,
  },
  {
    x: 4839.84375,
    y: 0.159912109375,
  },
  {
    x: 4843.75,
    y: 0.159912109375,
  },
  {
    x: 4847.65625,
    y: 0.16489410400390625,
  },
  {
    x: 4851.5625,
    y: 0.16489410400390625,
  },
  {
    x: 4855.46875,
    y: 0.159912109375,
  },
  {
    x: 4859.375,
    y: 0.16489410400390625,
  },
  {
    x: 4863.28125,
    y: 0.16489410400390625,
  },
  {
    x: 4867.1875,
    y: 0.16489410400390625,
  },
  {
    x: 4871.09375,
    y: 0.159912109375,
  },
  {
    x: 4875,
    y: 0.159912109375,
  },
  {
    x: 4878.90625,
    y: 0.159912109375,
  },
  {
    x: 4882.8125,
    y: 0.15492630004882812,
  },
  {
    x: 4886.71875,
    y: 0.15492630004882812,
  },
  {
    x: 4890.625,
    y: 0.14994430541992188,
  },
  {
    x: 4894.53125,
    y: 0.14994430541992188,
  },
  {
    x: 4898.4375,
    y: 0.14994430541992188,
  },
  {
    x: 4902.34375,
    y: 0.14496231079101562,
  },
  {
    x: 4906.25,
    y: 0.14496231079101562,
  },
  {
    x: 4910.15625,
    y: 0.13997650146484375,
  },
  {
    x: 4914.0625,
    y: 0.13997650146484375,
  },
  {
    x: 4917.96875,
    y: 0.13997650146484375,
  },
  {
    x: 4921.875,
    y: 0.1349945068359375,
  },
  {
    x: 4929.6875,
    y: 0.125030517578125,
  },
  {
    x: 4933.59375,
    y: 0.12004470825195312,
  },
  {
    x: 4937.5,
    y: 0.12004470825195312,
  },
  {
    x: 5468.75,
    y: 0.12004470825195312,
  },
  {
    x: 5472.65625,
    y: 0.10509490966796875,
  },
  {
    x: 5476.5625,
    y: 0.09014511108398438,
  },
  {
    x: 5480.46875,
    y: 0.07020950317382812,
  },
  {
    x: 5484.375,
    y: 0.050273895263671875,
  },
  {
    x: 5488.28125,
    y: 0.0353240966796875,
  },
  {
    x: 5492.1875,
    y: 0.015392303466796875,
  },
  {
    x: 5496.09375,
    y: 0.0004425048828125,
  },
  {
    x: 5500,
    y: -0.01949310302734375,
  },
  {
    x: 5503.90625,
    y: -0.0394287109375,
  },
  {
    x: 5507.8125,
    y: -0.049396514892578125,
  },
  {
    x: 5511.71875,
    y: -0.04441070556640625,
  },
  {
    x: 5515.625,
    y: -0.024478912353515625,
  },
  {
    x: 5519.53125,
    y: -0.004543304443359375,
  },
  {
    x: 5523.4375,
    y: 0.020374298095703125,
  },
  {
    x: 5527.34375,
    y: 0.040309906005859375,
  },
  {
    x: 5531.25,
    y: 0.06522369384765625,
  },
  {
    x: 5535.15625,
    y: 0.0851593017578125,
  },
  {
    x: 5539.0625,
    y: 0.110076904296875,
  },
  {
    x: 5542.96875,
    y: 0.13997650146484375,
  },
  {
    x: 5546.875,
    y: 0.159912109375,
  },
  {
    x: 5550.78125,
    y: 0.16489410400390625,
  },
  {
    x: 5554.6875,
    y: 0.1698760986328125,
  },
  {
    x: 5558.59375,
    y: 0.1698760986328125,
  },
  {
    x: 5562.5,
    y: 0.16489410400390625,
  },
  {
    x: 5566.40625,
    y: 0.16489410400390625,
  },
  {
    x: 5593.75,
    y: 0.16489410400390625,
  },
  {
    x: 5597.65625,
    y: 0.159912109375,
  },
  {
    x: 5601.5625,
    y: 0.159912109375,
  },
  {
    x: 5625,
    y: 0.159912109375,
  },
  {
    x: 5628.90625,
    y: 0.15492630004882812,
  },
  {
    x: 5632.8125,
    y: 0.15492630004882812,
  },
  {
    x: 5761.71875,
    y: 0.15492630004882812,
  },
  {
    x: 5765.625,
    y: 0.14994430541992188,
  },
  {
    x: 5769.53125,
    y: 0.14496231079101562,
  },
  {
    x: 5773.4375,
    y: 0.13997650146484375,
  },
  {
    x: 5777.34375,
    y: 0.13997650146484375,
  },
  {
    x: 5781.25,
    y: 0.13997650146484375,
  },
  {
    x: 5785.15625,
    y: 0.14496231079101562,
  },
  {
    x: 5789.0625,
    y: 0.14496231079101562,
  },
  {
    x: 5792.96875,
    y: 0.14496231079101562,
  },
  {
    x: 5796.875,
    y: 0.14994430541992188,
  },
  {
    x: 5800.78125,
    y: 0.14994430541992188,
  },
  {
    x: 5804.6875,
    y: 0.15492630004882812,
  },
  {
    x: 5808.59375,
    y: 0.15492630004882812,
  },
  {
    x: 5812.5,
    y: 0.15492630004882812,
  },
  {
    x: 5816.40625,
    y: 0.159912109375,
  },
  {
    x: 5820.3125,
    y: 0.159912109375,
  },
  {
    x: 5824.21875,
    y: 0.16489410400390625,
  },
  {
    x: 5828.125,
    y: 0.16489410400390625,
  },
  {
    x: 5832.03125,
    y: 0.159912109375,
  },
  {
    x: 5835.9375,
    y: 0.16489410400390625,
  },
  {
    x: 5839.84375,
    y: 0.16489410400390625,
  },
  {
    x: 5843.75,
    y: 0.16489410400390625,
  },
  {
    x: 5847.65625,
    y: 0.159912109375,
  },
  {
    x: 5851.5625,
    y: 0.159912109375,
  },
  {
    x: 5855.46875,
    y: 0.159912109375,
  },
  {
    x: 5859.375,
    y: 0.15492630004882812,
  },
  {
    x: 5863.28125,
    y: 0.15492630004882812,
  },
  {
    x: 5867.1875,
    y: 0.14994430541992188,
  },
  {
    x: 5871.09375,
    y: 0.14994430541992188,
  },
  {
    x: 5875,
    y: 0.14994430541992188,
  },
  {
    x: 5878.90625,
    y: 0.14496231079101562,
  },
  {
    x: 5882.8125,
    y: 0.14496231079101562,
  },
  {
    x: 5886.71875,
    y: 0.13997650146484375,
  },
  {
    x: 5890.625,
    y: 0.13997650146484375,
  },
  {
    x: 5894.53125,
    y: 0.13997650146484375,
  },
  {
    x: 5898.4375,
    y: 0.1349945068359375,
  },
  {
    x: 5906.25,
    y: 0.125030517578125,
  },
  {
    x: 5910.15625,
    y: 0.12004470825195312,
  },
  {
    x: 5914.0625,
    y: 0.12004470825195312,
  },
  {
    x: 6000,
    y: 0.12004470825195312,
  },
];
export const Avf = [
  {
    x: 0,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 46.875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 50.78125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 54.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 109.375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 113.28125,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 117.1875,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 246.09375,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 250,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 253.90625,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 496.09375,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 500,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 503.90625,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 507.8125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 511.71875,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 515.625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 519.53125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 523.4375,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 527.34375,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 531.25,
    y: 0.045291900634765625,
    __typename: 'Points',
  },
  {
    x: 535.15625,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 539.0625,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 542.96875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 546.875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 550.78125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 554.6875,
    y: 0.00542449951171875,
    __typename: 'Points',
  },
  {
    x: 558.59375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 562.5,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 566.40625,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 570.3125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 574.21875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 578.125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 582.03125,
    y: -0.024478912353515625,
    __typename: 'Points',
  },
  {
    x: 585.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 589.84375,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 593.75,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 597.65625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 601.5625,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 605.46875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 609.375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 613.28125,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 617.1875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 621.09375,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 625,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 628.90625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 632.8125,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 636.71875,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 640.625,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 644.53125,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 648.4375,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 656.25,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 660.15625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 664.0625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 671.875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 675.78125,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 679.6875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 683.59375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 687.5,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 691.40625,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 695.3125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 699.21875,
    y: -0.07929611206054688,
    __typename: 'Points',
  },
  {
    x: 703.125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 707.03125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 710.9375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 714.84375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 718.75,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 722.65625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 726.5625,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 730.46875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 734.375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 738.28125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 742.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 746.09375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 750,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 753.90625,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 757.8125,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 761.71875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 765.625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 769.53125,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 773.4375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 777.34375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 781.25,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 785.15625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 789.0625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 792.96875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 796.875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 800.78125,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 804.6875,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 808.59375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 812.5,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 816.40625,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 820.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 824.21875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 828.125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 859.375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 867.1875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 878.90625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 882.8125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 886.71875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 925.78125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 929.6875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 933.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 941.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 945.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 949.21875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 988.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 992.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 996.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1046.875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1050.78125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1054.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1109.375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 1113.28125,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 1117.1875,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 1246.09375,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 1250,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 1253.90625,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 1496.09375,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 1500,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 1503.90625,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 1507.8125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 1511.71875,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 1515.625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1519.53125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1523.4375,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 1527.34375,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 1531.25,
    y: 0.045291900634765625,
    __typename: 'Points',
  },
  {
    x: 1535.15625,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 1539.0625,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 1542.96875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1546.875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1550.78125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1554.6875,
    y: 0.00542449951171875,
    __typename: 'Points',
  },
  {
    x: 1558.59375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 1562.5,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 1566.40625,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 1570.3125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 1574.21875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 1578.125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 1582.03125,
    y: -0.024478912353515625,
    __typename: 'Points',
  },
  {
    x: 1585.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1589.84375,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 1593.75,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 1597.65625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 1601.5625,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1605.46875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1609.375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1613.28125,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1617.1875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1621.09375,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 1625,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 1628.90625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 1632.8125,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 1636.71875,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 1640.625,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 1644.53125,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 1648.4375,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 1652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 1656.25,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 1660.15625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 1664.0625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 1671.875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 1675.78125,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 1679.6875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 1683.59375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 1687.5,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 1691.40625,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 1695.3125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 1699.21875,
    y: -0.07929611206054688,
    __typename: 'Points',
  },
  {
    x: 1703.125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 1707.03125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 1710.9375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 1714.84375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1718.75,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 1722.65625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 1726.5625,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 1730.46875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 1734.375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 1738.28125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 1742.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 1746.09375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 1750,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 1753.90625,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 1757.8125,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 1761.71875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 1765.625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 1769.53125,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 1773.4375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 1777.34375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 1781.25,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 1785.15625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 1789.0625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 1792.96875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 1796.875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 1800.78125,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 1804.6875,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 1808.59375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 1812.5,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 1816.40625,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 1820.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1824.21875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1828.125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1859.375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1867.1875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1878.90625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1882.8125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1886.71875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1925.78125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 1929.6875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1933.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1941.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1945.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1949.21875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1988.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 1992.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 1996.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2046.875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2050.78125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2054.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2109.375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 2113.28125,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 2117.1875,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 2246.09375,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 2250,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 2253.90625,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 2496.09375,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 2500,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 2503.90625,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 2507.8125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 2511.71875,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 2515.625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2519.53125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2523.4375,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 2527.34375,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 2531.25,
    y: 0.045291900634765625,
    __typename: 'Points',
  },
  {
    x: 2535.15625,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 2539.0625,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 2542.96875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2546.875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2550.78125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2554.6875,
    y: 0.00542449951171875,
    __typename: 'Points',
  },
  {
    x: 2558.59375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 2562.5,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 2566.40625,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 2570.3125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 2574.21875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 2578.125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 2582.03125,
    y: -0.024478912353515625,
    __typename: 'Points',
  },
  {
    x: 2585.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2589.84375,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 2593.75,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 2597.65625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 2601.5625,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2605.46875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2609.375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2613.28125,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2617.1875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2621.09375,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 2625,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 2628.90625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 2632.8125,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 2636.71875,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 2640.625,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 2644.53125,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 2648.4375,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 2652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 2656.25,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 2660.15625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 2664.0625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 2671.875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 2675.78125,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 2679.6875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 2683.59375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 2687.5,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 2691.40625,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 2695.3125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 2699.21875,
    y: -0.07929611206054688,
    __typename: 'Points',
  },
  {
    x: 2703.125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 2707.03125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 2710.9375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 2714.84375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2718.75,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 2722.65625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 2726.5625,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 2730.46875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 2734.375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 2738.28125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 2742.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 2746.09375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 2750,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 2753.90625,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 2757.8125,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 2761.71875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 2765.625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 2769.53125,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 2773.4375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 2777.34375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 2781.25,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 2785.15625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 2789.0625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 2792.96875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 2796.875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 2800.78125,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 2804.6875,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 2808.59375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 2812.5,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 2816.40625,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 2820.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2824.21875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2828.125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2859.375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2867.1875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2878.90625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2882.8125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2886.71875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2925.78125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 2929.6875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2933.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2941.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2945.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2949.21875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2988.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 2992.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 2996.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3046.875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3050.78125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3054.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3109.375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 3113.28125,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 3117.1875,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 3246.09375,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 3250,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 3253.90625,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 3496.09375,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 3500,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 3503.90625,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 3507.8125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 3511.71875,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 3515.625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3519.53125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3523.4375,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 3527.34375,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 3531.25,
    y: 0.045291900634765625,
    __typename: 'Points',
  },
  {
    x: 3535.15625,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 3539.0625,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 3542.96875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3546.875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3550.78125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3554.6875,
    y: 0.00542449951171875,
    __typename: 'Points',
  },
  {
    x: 3558.59375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 3562.5,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 3566.40625,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 3570.3125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 3574.21875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 3578.125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 3582.03125,
    y: -0.024478912353515625,
    __typename: 'Points',
  },
  {
    x: 3585.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3589.84375,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 3593.75,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 3597.65625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 3601.5625,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3605.46875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3609.375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3613.28125,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3617.1875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3621.09375,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 3625,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 3628.90625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 3632.8125,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 3636.71875,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 3640.625,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 3644.53125,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 3648.4375,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 3652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 3656.25,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 3660.15625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 3664.0625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 3671.875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 3675.78125,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 3679.6875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 3683.59375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 3687.5,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 3691.40625,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 3695.3125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 3699.21875,
    y: -0.07929611206054688,
    __typename: 'Points',
  },
  {
    x: 3703.125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 3707.03125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 3710.9375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 3714.84375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3718.75,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 3722.65625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 3726.5625,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 3730.46875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 3734.375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 3738.28125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 3742.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 3746.09375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 3750,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 3753.90625,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 3757.8125,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 3761.71875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 3765.625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 3769.53125,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 3773.4375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 3777.34375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 3781.25,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 3785.15625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 3789.0625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 3792.96875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 3796.875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 3800.78125,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 3804.6875,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 3808.59375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 3812.5,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 3816.40625,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 3820.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3824.21875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3828.125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3859.375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3867.1875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3878.90625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3882.8125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3886.71875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3925.78125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 3929.6875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3933.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3941.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3945.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3949.21875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3988.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 3992.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 3996.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4046.875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4050.78125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4054.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4109.375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 4113.28125,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 4117.1875,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 4246.09375,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 4250,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 4253.90625,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 4496.09375,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 4500,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 4503.90625,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 4507.8125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 4511.71875,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 4515.625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4519.53125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4523.4375,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 4527.34375,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 4531.25,
    y: 0.045291900634765625,
    __typename: 'Points',
  },
  {
    x: 4535.15625,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 4539.0625,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 4542.96875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4546.875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4550.78125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4554.6875,
    y: 0.00542449951171875,
    __typename: 'Points',
  },
  {
    x: 4558.59375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 4562.5,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 4566.40625,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 4570.3125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 4574.21875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 4578.125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 4582.03125,
    y: -0.024478912353515625,
    __typename: 'Points',
  },
  {
    x: 4585.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4589.84375,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 4593.75,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 4597.65625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 4601.5625,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4605.46875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4609.375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4613.28125,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4617.1875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4621.09375,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 4625,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 4628.90625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 4632.8125,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 4636.71875,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 4640.625,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 4644.53125,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 4648.4375,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 4652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 4656.25,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 4660.15625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 4664.0625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 4671.875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 4675.78125,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 4679.6875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 4683.59375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 4687.5,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 4691.40625,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 4695.3125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 4699.21875,
    y: -0.07929611206054688,
    __typename: 'Points',
  },
  {
    x: 4703.125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 4707.03125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 4710.9375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 4714.84375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4718.75,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 4722.65625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 4726.5625,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 4730.46875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 4734.375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 4738.28125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 4742.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 4746.09375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 4750,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 4753.90625,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 4757.8125,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 4761.71875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 4765.625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 4769.53125,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 4773.4375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 4777.34375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 4781.25,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 4785.15625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 4789.0625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 4792.96875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 4796.875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 4800.78125,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 4804.6875,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 4808.59375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 4812.5,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 4816.40625,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 4820.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4824.21875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4828.125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4859.375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4867.1875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4878.90625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4882.8125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4886.71875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4925.78125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 4929.6875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4933.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4941.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4945.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4949.21875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4988.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 4992.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 4996.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5046.875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5050.78125,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5054.6875,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5109.375,
    y: 0.110076904296875,
    __typename: 'Points',
  },
  {
    x: 5113.28125,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 5117.1875,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 5246.09375,
    y: 0.11506271362304688,
    __typename: 'Points',
  },
  {
    x: 5250,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 5253.90625,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 5496.09375,
    y: 0.1349945068359375,
    __typename: 'Points',
  },
  {
    x: 5500,
    y: 0.13001251220703125,
    __typename: 'Points',
  },
  {
    x: 5503.90625,
    y: 0.125030517578125,
    __typename: 'Points',
  },
  {
    x: 5507.8125,
    y: 0.12004470825195312,
    __typename: 'Points',
  },
  {
    x: 5511.71875,
    y: 0.10509490966796875,
    __typename: 'Points',
  },
  {
    x: 5515.625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5519.53125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5523.4375,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 5527.34375,
    y: 0.06024169921875,
    __typename: 'Points',
  },
  {
    x: 5531.25,
    y: 0.045291900634765625,
    __typename: 'Points',
  },
  {
    x: 5535.15625,
    y: 0.040309906005859375,
    __typename: 'Points',
  },
  {
    x: 5539.0625,
    y: 0.025356292724609375,
    __typename: 'Points',
  },
  {
    x: 5542.96875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5546.875,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5550.78125,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5554.6875,
    y: 0.00542449951171875,
    __typename: 'Points',
  },
  {
    x: 5558.59375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 5562.5,
    y: -0.00952911376953125,
    __typename: 'Points',
  },
  {
    x: 5566.40625,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 5570.3125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 5574.21875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 5578.125,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 5582.03125,
    y: -0.024478912353515625,
    __typename: 'Points',
  },
  {
    x: 5585.9375,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5589.84375,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 5593.75,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 5597.65625,
    y: -0.04441070556640625,
    __typename: 'Points',
  },
  {
    x: 5601.5625,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5605.46875,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5609.375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5613.28125,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5617.1875,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5621.09375,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 5625,
    y: -0.06932830810546875,
    __typename: 'Points',
  },
  {
    x: 5628.90625,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 5632.8125,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 5636.71875,
    y: -0.074310302734375,
    __typename: 'Points',
  },
  {
    x: 5640.625,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 5644.53125,
    y: -0.089263916015625,
    __typename: 'Points',
  },
  {
    x: 5648.4375,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 5652.34375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 5656.25,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 5660.15625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 5664.0625,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 5671.875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 5675.78125,
    y: -0.10919570922851562,
    __typename: 'Points',
  },
  {
    x: 5679.6875,
    y: -0.10421371459960938,
    __typename: 'Points',
  },
  {
    x: 5683.59375,
    y: -0.09923171997070312,
    __typename: 'Points',
  },
  {
    x: 5687.5,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 5691.40625,
    y: -0.09424591064453125,
    __typename: 'Points',
  },
  {
    x: 5695.3125,
    y: -0.08427810668945312,
    __typename: 'Points',
  },
  {
    x: 5699.21875,
    y: -0.07929611206054688,
    __typename: 'Points',
  },
  {
    x: 5703.125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 5707.03125,
    y: -0.0643463134765625,
    __typename: 'Points',
  },
  {
    x: 5710.9375,
    y: -0.05936431884765625,
    __typename: 'Points',
  },
  {
    x: 5714.84375,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5718.75,
    y: -0.049396514892578125,
    __typename: 'Points',
  },
  {
    x: 5722.65625,
    y: -0.0394287109375,
    __typename: 'Points',
  },
  {
    x: 5726.5625,
    y: -0.034442901611328125,
    __typename: 'Points',
  },
  {
    x: 5730.46875,
    y: -0.029460906982421875,
    __typename: 'Points',
  },
  {
    x: 5734.375,
    y: -0.01949310302734375,
    __typename: 'Points',
  },
  {
    x: 5738.28125,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 5742.1875,
    y: -0.0145111083984375,
    __typename: 'Points',
  },
  {
    x: 5746.09375,
    y: -0.004543304443359375,
    __typename: 'Points',
  },
  {
    x: 5750,
    y: 0.0004425048828125,
    __typename: 'Points',
  },
  {
    x: 5753.90625,
    y: 0.010406494140625,
    __typename: 'Points',
  },
  {
    x: 5757.8125,
    y: 0.015392303466796875,
    __typename: 'Points',
  },
  {
    x: 5761.71875,
    y: 0.020374298095703125,
    __typename: 'Points',
  },
  {
    x: 5765.625,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 5769.53125,
    y: 0.03034210205078125,
    __typename: 'Points',
  },
  {
    x: 5773.4375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 5777.34375,
    y: 0.0353240966796875,
    __typename: 'Points',
  },
  {
    x: 5781.25,
    y: 0.050273895263671875,
    __typename: 'Points',
  },
  {
    x: 5785.15625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 5789.0625,
    y: 0.05525970458984375,
    __typename: 'Points',
  },
  {
    x: 5792.96875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 5796.875,
    y: 0.06522369384765625,
    __typename: 'Points',
  },
  {
    x: 5800.78125,
    y: 0.07020950317382812,
    __typename: 'Points',
  },
  {
    x: 5804.6875,
    y: 0.0751953125,
    __typename: 'Points',
  },
  {
    x: 5808.59375,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 5812.5,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 5816.40625,
    y: 0.0851593017578125,
    __typename: 'Points',
  },
  {
    x: 5820.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5824.21875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5828.125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5859.375,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5863.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5867.1875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5878.90625,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5882.8125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5886.71875,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5925.78125,
    y: 0.09014511108398438,
    __typename: 'Points',
  },
  {
    x: 5929.6875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5933.59375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5941.40625,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5945.3125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5949.21875,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5988.28125,
    y: 0.09512710571289062,
    __typename: 'Points',
  },
  {
    x: 5992.1875,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 5996.09375,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
  {
    x: 6000,
    y: 0.10010910034179688,
    __typename: 'Points',
  },
];
export const V1 = [
  {
    x: 27.34375,
    y: 0.1698760986328125,
  },
  {
    x: 31.25,
    y: 0.1997833251953125,
  },
  {
    x: 35.15625,
    y: 0.2346649169921875,
  },
  {
    x: 39.0625,
    y: 0.2695503234863281,
  },
  {
    x: 46.875,
    y: 0.3393211364746094,
  },
  {
    x: 50.78125,
    y: 0.3742027282714844,
  },
  {
    x: 54.6875,
    y: 0.409088134765625,
  },
  {
    x: 58.59375,
    y: 0.4439735412597656,
  },
  {
    x: 62.5,
    y: 0.45892333984375,
  },
  {
    x: 66.40625,
    y: 0.4389915466308594,
  },
  {
    x: 70.3125,
    y: 0.4041023254394531,
  },
  {
    x: 74.21875,
    y: 0.3642387390136719,
  },
  {
    x: 78.125,
    y: 0.3293495178222656,
  },
  {
    x: 82.03125,
    y: 0.2894859313964844,
  },
  {
    x: 85.9375,
    y: 0.24961471557617188,
  },
  {
    x: 89.84375,
    y: 0.209747314453125,
  },
  {
    x: 93.75,
    y: 0.1698760986328125,
  },
  {
    x: 97.65625,
    y: 0.13001251220703125,
  },
  {
    x: 101.5625,
    y: 0.09512710571289062,
  },
  {
    x: 105.46875,
    y: 0.0851593017578125,
  },
  {
    x: 109.375,
    y: 0.0751953125,
  },
  {
    x: 113.28125,
    y: 0.0751953125,
  },
  {
    x: 121.09375,
    y: 0.0751953125,
  },
  {
    x: 125,
    y: 0.08017730712890625,
  },
  {
    x: 128.90625,
    y: 0.08017730712890625,
  },
  {
    x: 152.34375,
    y: 0.08017730712890625,
  },
  {
    x: 156.25,
    y: 0.0851593017578125,
  },
  {
    x: 160.15625,
    y: 0.0851593017578125,
  },
  {
    x: 183.59375,
    y: 0.0851593017578125,
  },
  {
    x: 187.5,
    y: 0.09014511108398438,
  },
  {
    x: 191.40625,
    y: 0.09014511108398438,
  },
  {
    x: 214.84375,
    y: 0.09014511108398438,
  },
  {
    x: 218.75,
    y: 0.09512710571289062,
  },
  {
    x: 222.65625,
    y: 0.09512710571289062,
  },
  {
    x: 324.21875,
    y: 0.09512710571289062,
  },
  {
    x: 328.125,
    y: 0.10010910034179688,
  },
  {
    x: 332.03125,
    y: 0.10010910034179688,
  },
  {
    x: 335.9375,
    y: 0.09512710571289062,
  },
  {
    x: 339.84375,
    y: 0.09014511108398438,
  },
  {
    x: 343.75,
    y: 0.0851593017578125,
  },
  {
    x: 347.65625,
    y: 0.0851593017578125,
  },
  {
    x: 351.5625,
    y: 0.08017730712890625,
  },
  {
    x: 355.46875,
    y: 0.08017730712890625,
  },
  {
    x: 359.375,
    y: 0.0751953125,
  },
  {
    x: 363.28125,
    y: 0.07020950317382812,
  },
  {
    x: 367.1875,
    y: 0.06522369384765625,
  },
  {
    x: 371.09375,
    y: 0.06024169921875,
  },
  {
    x: 375,
    y: 0.06024169921875,
  },
  {
    x: 378.90625,
    y: 0.05525970458984375,
  },
  {
    x: 382.8125,
    y: 0.05525970458984375,
  },
  {
    x: 386.71875,
    y: 0.05525970458984375,
  },
  {
    x: 390.625,
    y: 0.045291900634765625,
  },
  {
    x: 394.53125,
    y: 0.040309906005859375,
  },
  {
    x: 398.4375,
    y: 0.0353240966796875,
  },
  {
    x: 402.34375,
    y: 0.045291900634765625,
  },
  {
    x: 406.25,
    y: 0.045291900634765625,
  },
  {
    x: 410.15625,
    y: 0.050273895263671875,
  },
  {
    x: 414.0625,
    y: 0.05525970458984375,
  },
  {
    x: 417.96875,
    y: 0.06024169921875,
  },
  {
    x: 421.875,
    y: 0.06522369384765625,
  },
  {
    x: 425.78125,
    y: 0.07020950317382812,
  },
  {
    x: 429.6875,
    y: 0.08017730712890625,
  },
  {
    x: 433.59375,
    y: 0.08017730712890625,
  },
  {
    x: 437.5,
    y: 0.0851593017578125,
  },
  {
    x: 441.40625,
    y: 0.09014511108398438,
  },
  {
    x: 445.3125,
    y: 0.09512710571289062,
  },
  {
    x: 449.21875,
    y: 0.10010910034179688,
  },
  {
    x: 453.125,
    y: 0.10509490966796875,
  },
  {
    x: 457.03125,
    y: 0.110076904296875,
  },
  {
    x: 460.9375,
    y: 0.11506271362304688,
  },
  {
    x: 464.84375,
    y: 0.12004470825195312,
  },
  {
    x: 468.75,
    y: 0.125030517578125,
  },
  {
    x: 472.65625,
    y: 0.125030517578125,
  },
  {
    x: 746.09375,
    y: 0.125030517578125,
  },
  {
    x: 750,
    y: 0.13997650146484375,
  },
  {
    x: 753.90625,
    y: 0.1698760986328125,
  },
  {
    x: 757.8125,
    y: 0.1997833251953125,
  },
  {
    x: 761.71875,
    y: 0.2346649169921875,
  },
  {
    x: 765.625,
    y: 0.2695503234863281,
  },
  {
    x: 773.4375,
    y: 0.3393211364746094,
  },
  {
    x: 777.34375,
    y: 0.3742027282714844,
  },
  {
    x: 781.25,
    y: 0.409088134765625,
  },
  {
    x: 785.15625,
    y: 0.4439735412597656,
  },
  {
    x: 789.0625,
    y: 0.45892333984375,
  },
  {
    x: 792.96875,
    y: 0.4389915466308594,
  },
  {
    x: 796.875,
    y: 0.4041023254394531,
  },
  {
    x: 800.78125,
    y: 0.3642387390136719,
  },
  {
    x: 804.6875,
    y: 0.3293495178222656,
  },
  {
    x: 808.59375,
    y: 0.2894859313964844,
  },
  {
    x: 812.5,
    y: 0.24961471557617188,
  },
  {
    x: 816.40625,
    y: 0.209747314453125,
  },
  {
    x: 820.3125,
    y: 0.1698760986328125,
  },
  {
    x: 824.21875,
    y: 0.13001251220703125,
  },
  {
    x: 828.125,
    y: 0.09512710571289062,
  },
  {
    x: 832.03125,
    y: 0.0851593017578125,
  },
  {
    x: 835.9375,
    y: 0.0751953125,
  },
  {
    x: 839.84375,
    y: 0.0751953125,
  },
  {
    x: 847.65625,
    y: 0.0751953125,
  },
  {
    x: 851.5625,
    y: 0.08017730712890625,
  },
  {
    x: 855.46875,
    y: 0.08017730712890625,
  },
  {
    x: 878.90625,
    y: 0.08017730712890625,
  },
  {
    x: 882.8125,
    y: 0.0851593017578125,
  },
  {
    x: 886.71875,
    y: 0.0851593017578125,
  },
  {
    x: 910.15625,
    y: 0.0851593017578125,
  },
  {
    x: 914.0625,
    y: 0.09014511108398438,
  },
  {
    x: 917.96875,
    y: 0.09014511108398438,
  },
  {
    x: 941.40625,
    y: 0.09014511108398438,
  },
  {
    x: 945.3125,
    y: 0.09512710571289062,
  },
  {
    x: 949.21875,
    y: 0.09512710571289062,
  },
  {
    x: 1050.78125,
    y: 0.09512710571289062,
  },
  {
    x: 1054.6875,
    y: 0.10010910034179688,
  },
  {
    x: 1058.59375,
    y: 0.10010910034179688,
  },
  {
    x: 1062.5,
    y: 0.09512710571289062,
  },
  {
    x: 1066.40625,
    y: 0.09014511108398438,
  },
  {
    x: 1070.3125,
    y: 0.0851593017578125,
  },
  {
    x: 1074.21875,
    y: 0.0851593017578125,
  },
  {
    x: 1078.125,
    y: 0.08017730712890625,
  },
  {
    x: 1082.03125,
    y: 0.08017730712890625,
  },
  {
    x: 1085.9375,
    y: 0.0751953125,
  },
  {
    x: 1089.84375,
    y: 0.07020950317382812,
  },
  {
    x: 1093.75,
    y: 0.06522369384765625,
  },
  {
    x: 1097.65625,
    y: 0.06024169921875,
  },
  {
    x: 1101.5625,
    y: 0.06024169921875,
  },
  {
    x: 1105.46875,
    y: 0.05525970458984375,
  },
  {
    x: 1109.375,
    y: 0.05525970458984375,
  },
  {
    x: 1113.28125,
    y: 0.050273895263671875,
  },
  {
    x: 1117.1875,
    y: 0.045291900634765625,
  },
  {
    x: 1121.09375,
    y: 0.040309906005859375,
  },
  {
    x: 1125,
    y: 0.0353240966796875,
  },
  {
    x: 1128.90625,
    y: 0.045291900634765625,
  },
  {
    x: 1132.8125,
    y: 0.045291900634765625,
  },
  {
    x: 1136.71875,
    y: 0.050273895263671875,
  },
  {
    x: 1140.625,
    y: 0.05525970458984375,
  },
  {
    x: 1144.53125,
    y: 0.06024169921875,
  },
  {
    x: 1148.4375,
    y: 0.06522369384765625,
  },
  {
    x: 1152.34375,
    y: 0.07020950317382812,
  },
  {
    x: 1156.25,
    y: 0.08017730712890625,
  },
  {
    x: 1160.15625,
    y: 0.08017730712890625,
  },
  {
    x: 1164.0625,
    y: 0.0851593017578125,
  },
  {
    x: 1167.96875,
    y: 0.09014511108398438,
  },
  {
    x: 1171.875,
    y: 0.09512710571289062,
  },
  {
    x: 1175.78125,
    y: 0.10010910034179688,
  },
  {
    x: 1179.6875,
    y: 0.10509490966796875,
  },
  {
    x: 1183.59375,
    y: 0.110076904296875,
  },
  {
    x: 1187.5,
    y: 0.11506271362304688,
  },
  {
    x: 1191.40625,
    y: 0.12004470825195312,
  },
  {
    x: 1195.3125,
    y: 0.125030517578125,
  },
  {
    x: 1199.21875,
    y: 0.125030517578125,
  },
  {
    x: 1722.65625,
    y: 0.125030517578125,
  },
  {
    x: 1726.5625,
    y: 0.13997650146484375,
  },
  {
    x: 1730.46875,
    y: 0.1698760986328125,
  },
  {
    x: 1734.375,
    y: 0.1997833251953125,
  },
  {
    x: 1738.28125,
    y: 0.2346649169921875,
  },
  {
    x: 1742.1875,
    y: 0.2695503234863281,
  },
  {
    x: 1750,
    y: 0.3393211364746094,
  },
  {
    x: 1753.90625,
    y: 0.3742027282714844,
  },
  {
    x: 1757.8125,
    y: 0.409088134765625,
  },
  {
    x: 1761.71875,
    y: 0.4439735412597656,
  },
  {
    x: 1765.625,
    y: 0.45892333984375,
  },
  {
    x: 1769.53125,
    y: 0.4389915466308594,
  },
  {
    x: 1773.4375,
    y: 0.4041023254394531,
  },
  {
    x: 1777.34375,
    y: 0.3642387390136719,
  },
  {
    x: 1781.25,
    y: 0.3293495178222656,
  },
  {
    x: 1785.15625,
    y: 0.2894859313964844,
  },
  {
    x: 1789.0625,
    y: 0.24961471557617188,
  },
  {
    x: 1792.96875,
    y: 0.209747314453125,
  },
  {
    x: 1796.875,
    y: 0.1698760986328125,
  },
  {
    x: 1800.78125,
    y: 0.13001251220703125,
  },
  {
    x: 1804.6875,
    y: 0.09512710571289062,
  },
  {
    x: 1808.59375,
    y: 0.0851593017578125,
  },
  {
    x: 1812.5,
    y: 0.0751953125,
  },
  {
    x: 1816.40625,
    y: 0.0751953125,
  },
  {
    x: 1824.21875,
    y: 0.0751953125,
  },
  {
    x: 1828.125,
    y: 0.08017730712890625,
  },
  {
    x: 1832.03125,
    y: 0.08017730712890625,
  },
  {
    x: 1855.46875,
    y: 0.08017730712890625,
  },
  {
    x: 1859.375,
    y: 0.0851593017578125,
  },
  {
    x: 1863.28125,
    y: 0.0851593017578125,
  },
  {
    x: 1886.71875,
    y: 0.0851593017578125,
  },
  {
    x: 1890.625,
    y: 0.09014511108398438,
  },
  {
    x: 1894.53125,
    y: 0.09014511108398438,
  },
  {
    x: 1917.96875,
    y: 0.09014511108398438,
  },
  {
    x: 1921.875,
    y: 0.09512710571289062,
  },
  {
    x: 1925.78125,
    y: 0.09512710571289062,
  },
  {
    x: 2027.34375,
    y: 0.09512710571289062,
  },
  {
    x: 2031.25,
    y: 0.10010910034179688,
  },
  {
    x: 2035.15625,
    y: 0.10010910034179688,
  },
  {
    x: 2039.0625,
    y: 0.09512710571289062,
  },
  {
    x: 2042.96875,
    y: 0.09014511108398438,
  },
  {
    x: 2046.875,
    y: 0.0851593017578125,
  },
  {
    x: 2050.78125,
    y: 0.0851593017578125,
  },
  {
    x: 2054.6875,
    y: 0.08017730712890625,
  },
  {
    x: 2058.59375,
    y: 0.08017730712890625,
  },
  {
    x: 2062.5,
    y: 0.0751953125,
  },
  {
    x: 2066.40625,
    y: 0.07020950317382812,
  },
  {
    x: 2070.3125,
    y: 0.06522369384765625,
  },
  {
    x: 2074.21875,
    y: 0.06024169921875,
  },
  {
    x: 2078.125,
    y: 0.06024169921875,
  },
  {
    x: 2082.03125,
    y: 0.05525970458984375,
  },
  {
    x: 2085.9375,
    y: 0.050273895263671875,
  },
  {
    x: 2089.84375,
    y: 0.050273895263671875,
  },
  {
    x: 2093.75,
    y: 0.045291900634765625,
  },
  {
    x: 2097.65625,
    y: 0.040309906005859375,
  },
  {
    x: 2101.5625,
    y: 0.0353240966796875,
  },
  {
    x: 2105.46875,
    y: 0.045291900634765625,
  },
  {
    x: 2109.375,
    y: 0.045291900634765625,
  },
  {
    x: 2113.28125,
    y: 0.050273895263671875,
  },
  {
    x: 2117.1875,
    y: 0.05525970458984375,
  },
  {
    x: 2121.09375,
    y: 0.06024169921875,
  },
  {
    x: 2125,
    y: 0.06522369384765625,
  },
  {
    x: 2128.90625,
    y: 0.07020950317382812,
  },
  {
    x: 2132.8125,
    y: 0.08017730712890625,
  },
  {
    x: 2136.71875,
    y: 0.08017730712890625,
  },
  {
    x: 2140.625,
    y: 0.0851593017578125,
  },
  {
    x: 2144.53125,
    y: 0.09014511108398438,
  },
  {
    x: 2148.4375,
    y: 0.09512710571289062,
  },
  {
    x: 2152.34375,
    y: 0.10010910034179688,
  },
  {
    x: 2156.25,
    y: 0.10509490966796875,
  },
  {
    x: 2160.15625,
    y: 0.110076904296875,
  },
  {
    x: 2164.0625,
    y: 0.11506271362304688,
  },
  {
    x: 2167.96875,
    y: 0.12004470825195312,
  },
  {
    x: 2171.875,
    y: 0.125030517578125,
  },
  {
    x: 2175.78125,
    y: 0.125030517578125,
  },
  {
    x: 2699.21875,
    y: 0.125030517578125,
  },
  {
    x: 2703.125,
    y: 0.13997650146484375,
  },
  {
    x: 2707.03125,
    y: 0.1698760986328125,
  },
  {
    x: 2710.9375,
    y: 0.1997833251953125,
  },
  {
    x: 2714.84375,
    y: 0.2346649169921875,
  },
  {
    x: 2718.75,
    y: 0.2695503234863281,
  },
  {
    x: 2726.5625,
    y: 0.3393211364746094,
  },
  {
    x: 2730.46875,
    y: 0.3742027282714844,
  },
  {
    x: 2734.375,
    y: 0.409088134765625,
  },
  {
    x: 2738.28125,
    y: 0.4439735412597656,
  },
  {
    x: 2742.1875,
    y: 0.45892333984375,
  },
  {
    x: 2746.09375,
    y: 0.4389915466308594,
  },
  {
    x: 2750,
    y: 0.4041023254394531,
  },
  {
    x: 2753.90625,
    y: 0.3642387390136719,
  },
  {
    x: 2757.8125,
    y: 0.3293495178222656,
  },
  {
    x: 2761.71875,
    y: 0.2894859313964844,
  },
  {
    x: 2765.625,
    y: 0.24961471557617188,
  },
  {
    x: 2769.53125,
    y: 0.209747314453125,
  },
  {
    x: 2773.4375,
    y: 0.1698760986328125,
  },
  {
    x: 2777.34375,
    y: 0.13001251220703125,
  },
  {
    x: 2781.25,
    y: 0.09512710571289062,
  },
  {
    x: 2785.15625,
    y: 0.0851593017578125,
  },
  {
    x: 2789.0625,
    y: 0.0751953125,
  },
  {
    x: 2792.96875,
    y: 0.0751953125,
  },
  {
    x: 2800.78125,
    y: 0.0751953125,
  },
  {
    x: 2804.6875,
    y: 0.08017730712890625,
  },
  {
    x: 2808.59375,
    y: 0.08017730712890625,
  },
  {
    x: 2832.03125,
    y: 0.08017730712890625,
  },
  {
    x: 2835.9375,
    y: 0.0851593017578125,
  },
  {
    x: 2839.84375,
    y: 0.0851593017578125,
  },
  {
    x: 2863.28125,
    y: 0.0851593017578125,
  },
  {
    x: 2867.1875,
    y: 0.09014511108398438,
  },
  {
    x: 2871.09375,
    y: 0.09014511108398438,
  },
  {
    x: 2894.53125,
    y: 0.09014511108398438,
  },
  {
    x: 2898.4375,
    y: 0.09512710571289062,
  },
  {
    x: 2902.34375,
    y: 0.09512710571289062,
  },
  {
    x: 3003.90625,
    y: 0.09512710571289062,
  },
  {
    x: 3007.8125,
    y: 0.10010910034179688,
  },
  {
    x: 3011.71875,
    y: 0.10010910034179688,
  },
  {
    x: 3015.625,
    y: 0.09512710571289062,
  },
  {
    x: 3019.53125,
    y: 0.09014511108398438,
  },
  {
    x: 3023.4375,
    y: 0.0851593017578125,
  },
  {
    x: 3027.34375,
    y: 0.0851593017578125,
  },
  {
    x: 3031.25,
    y: 0.08017730712890625,
  },
  {
    x: 3035.15625,
    y: 0.08017730712890625,
  },
  {
    x: 3039.0625,
    y: 0.0751953125,
  },
  {
    x: 3042.96875,
    y: 0.07020950317382812,
  },
  {
    x: 3046.875,
    y: 0.06522369384765625,
  },
  {
    x: 3050.78125,
    y: 0.06024169921875,
  },
  {
    x: 3054.6875,
    y: 0.06024169921875,
  },
  {
    x: 3058.59375,
    y: 0.05525970458984375,
  },
  {
    x: 3062.5,
    y: 0.050273895263671875,
  },
  {
    x: 3066.40625,
    y: 0.050273895263671875,
  },
  {
    x: 3070.3125,
    y: 0.045291900634765625,
  },
  {
    x: 3074.21875,
    y: 0.040309906005859375,
  },
  {
    x: 3078.125,
    y: 0.040309906005859375,
  },
  {
    x: 3082.03125,
    y: 0.045291900634765625,
  },
  {
    x: 3085.9375,
    y: 0.045291900634765625,
  },
  {
    x: 3089.84375,
    y: 0.050273895263671875,
  },
  {
    x: 3093.75,
    y: 0.05525970458984375,
  },
  {
    x: 3097.65625,
    y: 0.06024169921875,
  },
  {
    x: 3101.5625,
    y: 0.06522369384765625,
  },
  {
    x: 3105.46875,
    y: 0.07020950317382812,
  },
  {
    x: 3109.375,
    y: 0.08017730712890625,
  },
  {
    x: 3113.28125,
    y: 0.08017730712890625,
  },
  {
    x: 3117.1875,
    y: 0.0851593017578125,
  },
  {
    x: 3121.09375,
    y: 0.09014511108398438,
  },
  {
    x: 3125,
    y: 0.09512710571289062,
  },
  {
    x: 3128.90625,
    y: 0.10010910034179688,
  },
  {
    x: 3132.8125,
    y: 0.10509490966796875,
  },
  {
    x: 3136.71875,
    y: 0.110076904296875,
  },
  {
    x: 3140.625,
    y: 0.11506271362304688,
  },
  {
    x: 3144.53125,
    y: 0.12004470825195312,
  },
  {
    x: 3148.4375,
    y: 0.125030517578125,
  },
  {
    x: 3152.34375,
    y: 0.125030517578125,
  },
  {
    x: 3675.78125,
    y: 0.125030517578125,
  },
  {
    x: 3679.6875,
    y: 0.13997650146484375,
  },
  {
    x: 3683.59375,
    y: 0.1698760986328125,
  },
  {
    x: 3687.5,
    y: 0.1997833251953125,
  },
  {
    x: 3691.40625,
    y: 0.2346649169921875,
  },
  {
    x: 3695.3125,
    y: 0.2695503234863281,
  },
  {
    x: 3703.125,
    y: 0.3393211364746094,
  },
  {
    x: 3707.03125,
    y: 0.3742027282714844,
  },
  {
    x: 3710.9375,
    y: 0.409088134765625,
  },
  {
    x: 3714.84375,
    y: 0.4439735412597656,
  },
  {
    x: 3718.75,
    y: 0.45892333984375,
  },
  {
    x: 3722.65625,
    y: 0.4389915466308594,
  },
  {
    x: 3726.5625,
    y: 0.4041023254394531,
  },
  {
    x: 3730.46875,
    y: 0.3642387390136719,
  },
  {
    x: 3734.375,
    y: 0.3293495178222656,
  },
  {
    x: 3738.28125,
    y: 0.2894859313964844,
  },
  {
    x: 3742.1875,
    y: 0.24961471557617188,
  },
  {
    x: 3746.09375,
    y: 0.209747314453125,
  },
  {
    x: 3750,
    y: 0.1698760986328125,
  },
  {
    x: 3753.90625,
    y: 0.13001251220703125,
  },
  {
    x: 3757.8125,
    y: 0.09512710571289062,
  },
  {
    x: 3761.71875,
    y: 0.0851593017578125,
  },
  {
    x: 3765.625,
    y: 0.0751953125,
  },
  {
    x: 3769.53125,
    y: 0.0751953125,
  },
  {
    x: 3777.34375,
    y: 0.0751953125,
  },
  {
    x: 3781.25,
    y: 0.08017730712890625,
  },
  {
    x: 3785.15625,
    y: 0.08017730712890625,
  },
  {
    x: 3808.59375,
    y: 0.08017730712890625,
  },
  {
    x: 3812.5,
    y: 0.0851593017578125,
  },
  {
    x: 3816.40625,
    y: 0.0851593017578125,
  },
  {
    x: 3839.84375,
    y: 0.0851593017578125,
  },
  {
    x: 3843.75,
    y: 0.09014511108398438,
  },
  {
    x: 3847.65625,
    y: 0.09014511108398438,
  },
  {
    x: 3871.09375,
    y: 0.09014511108398438,
  },
  {
    x: 3875,
    y: 0.09512710571289062,
  },
  {
    x: 3878.90625,
    y: 0.09512710571289062,
  },
  {
    x: 3980.46875,
    y: 0.09512710571289062,
  },
  {
    x: 3984.375,
    y: 0.10010910034179688,
  },
  {
    x: 3988.28125,
    y: 0.10010910034179688,
  },
  {
    x: 3992.1875,
    y: 0.09512710571289062,
  },
  {
    x: 3996.09375,
    y: 0.09014511108398438,
  },
  {
    x: 4000,
    y: 0.0851593017578125,
  },
  {
    x: 4003.90625,
    y: 0.0851593017578125,
  },
  {
    x: 4007.8125,
    y: 0.08017730712890625,
  },
  {
    x: 4011.71875,
    y: 0.08017730712890625,
  },
  {
    x: 4015.625,
    y: 0.0751953125,
  },
  {
    x: 4019.53125,
    y: 0.07020950317382812,
  },
  {
    x: 4023.4375,
    y: 0.06522369384765625,
  },
  {
    x: 4027.34375,
    y: 0.06024169921875,
  },
  {
    x: 4031.25,
    y: 0.06024169921875,
  },
  {
    x: 4035.15625,
    y: 0.05525970458984375,
  },
  {
    x: 4039.0625,
    y: 0.05525970458984375,
  },
  {
    x: 4042.96875,
    y: 0.05525970458984375,
  },
  {
    x: 4046.875,
    y: 0.045291900634765625,
  },
  {
    x: 4050.78125,
    y: 0.040309906005859375,
  },
  {
    x: 4054.6875,
    y: 0.0353240966796875,
  },
  {
    x: 4058.59375,
    y: 0.045291900634765625,
  },
  {
    x: 4062.5,
    y: 0.045291900634765625,
  },
  {
    x: 4066.40625,
    y: 0.050273895263671875,
  },
  {
    x: 4070.3125,
    y: 0.05525970458984375,
  },
  {
    x: 4074.21875,
    y: 0.06024169921875,
  },
  {
    x: 4078.125,
    y: 0.06522369384765625,
  },
  {
    x: 4082.03125,
    y: 0.07020950317382812,
  },
  {
    x: 4085.9375,
    y: 0.08017730712890625,
  },
  {
    x: 4089.84375,
    y: 0.08017730712890625,
  },
  {
    x: 4093.75,
    y: 0.0851593017578125,
  },
  {
    x: 4097.65625,
    y: 0.09014511108398438,
  },
  {
    x: 4101.5625,
    y: 0.09512710571289062,
  },
  {
    x: 4105.46875,
    y: 0.10010910034179688,
  },
  {
    x: 4109.375,
    y: 0.10509490966796875,
  },
  {
    x: 4113.28125,
    y: 0.110076904296875,
  },
  {
    x: 4117.1875,
    y: 0.11506271362304688,
  },
  {
    x: 4121.09375,
    y: 0.12004470825195312,
  },
  {
    x: 4125,
    y: 0.125030517578125,
  },
  {
    x: 4128.90625,
    y: 0.125030517578125,
  },
  {
    x: 4652.34375,
    y: 0.125030517578125,
  },
  {
    x: 4656.25,
    y: 0.13997650146484375,
  },
  {
    x: 4660.15625,
    y: 0.1698760986328125,
  },
  {
    x: 4664.0625,
    y: 0.1997833251953125,
  },
  {
    x: 4667.96875,
    y: 0.2346649169921875,
  },
  {
    x: 4671.875,
    y: 0.2695503234863281,
  },
  {
    x: 4679.6875,
    y: 0.3393211364746094,
  },
  {
    x: 4683.59375,
    y: 0.3742027282714844,
  },
  {
    x: 4687.5,
    y: 0.409088134765625,
  },
  {
    x: 4691.40625,
    y: 0.4439735412597656,
  },
  {
    x: 4695.3125,
    y: 0.45892333984375,
  },
  {
    x: 4699.21875,
    y: 0.4389915466308594,
  },
  {
    x: 4703.125,
    y: 0.4041023254394531,
  },
  {
    x: 4707.03125,
    y: 0.3642387390136719,
  },
  {
    x: 4710.9375,
    y: 0.3293495178222656,
  },
  {
    x: 4714.84375,
    y: 0.2894859313964844,
  },
  {
    x: 4718.75,
    y: 0.24961471557617188,
  },
  {
    x: 4722.65625,
    y: 0.209747314453125,
  },
  {
    x: 4726.5625,
    y: 0.1698760986328125,
  },
  {
    x: 4730.46875,
    y: 0.13001251220703125,
  },
  {
    x: 4734.375,
    y: 0.09512710571289062,
  },
  {
    x: 4738.28125,
    y: 0.0851593017578125,
  },
  {
    x: 4742.1875,
    y: 0.0751953125,
  },
  {
    x: 4746.09375,
    y: 0.0751953125,
  },
  {
    x: 4750,
    y: 0.06522369384765625,
  },
  {
    x: 4753.90625,
    y: 0.06024169921875,
  },
  {
    x: 4757.8125,
    y: 0.06024169921875,
  },
  {
    x: 4761.71875,
    y: 0.05525970458984375,
  },
  {
    x: 4765.625,
    y: 0.05525970458984375,
  },
  {
    x: 4769.53125,
    y: 0.050273895263671875,
  },
  {
    x: 4773.4375,
    y: 0.045291900634765625,
  },
  {
    x: 4777.34375,
    y: 0.040309906005859375,
  },
  {
    x: 4781.25,
    y: 0.0353240966796875,
  },
  {
    x: 4785.15625,
    y: 0.045291900634765625,
  },
  {
    x: 4789.0625,
    y: 0.045291900634765625,
  },
  {
    x: 4792.96875,
    y: 0.050273895263671875,
  },
  {
    x: 4796.875,
    y: 0.05525970458984375,
  },
  {
    x: 4800.78125,
    y: 0.06024169921875,
  },
  {
    x: 4804.6875,
    y: 0.06522369384765625,
  },
  {
    x: 4808.59375,
    y: 0.07020950317382812,
  },
  {
    x: 4812.5,
    y: 0.08017730712890625,
  },
  {
    x: 4816.40625,
    y: 0.08017730712890625,
  },
  {
    x: 4820.3125,
    y: 0.0851593017578125,
  },
  {
    x: 4824.21875,
    y: 0.09014511108398438,
  },
  {
    x: 4828.125,
    y: 0.09512710571289062,
  },
  {
    x: 4832.03125,
    y: 0.10010910034179688,
  },
  {
    x: 4835.9375,
    y: 0.10509490966796875,
  },
  {
    x: 4839.84375,
    y: 0.110076904296875,
  },
  {
    x: 4843.75,
    y: 0.11506271362304688,
  },
  {
    x: 4847.65625,
    y: 0.12004470825195312,
  },
  {
    x: 4851.5625,
    y: 0.125030517578125,
  },
  {
    x: 4855.46875,
    y: 0.125030517578125,
  },
  {
    x: 5378.90625,
    y: 0.125030517578125,
  },
  {
    x: 5382.8125,
    y: 0.13997650146484375,
  },
  {
    x: 5386.71875,
    y: 0.1698760986328125,
  },
  {
    x: 5390.625,
    y: 0.1997833251953125,
  },
  {
    x: 5394.53125,
    y: 0.2346649169921875,
  },
  {
    x: 5398.4375,
    y: 0.2695503234863281,
  },
  {
    x: 5406.25,
    y: 0.3393211364746094,
  },
  {
    x: 5410.15625,
    y: 0.3742027282714844,
  },
  {
    x: 5414.0625,
    y: 0.409088134765625,
  },
  {
    x: 5417.96875,
    y: 0.4439735412597656,
  },
  {
    x: 5421.875,
    y: 0.45892333984375,
  },
  {
    x: 5425.78125,
    y: 0.4389915466308594,
  },
  {
    x: 5429.6875,
    y: 0.4041023254394531,
  },
  {
    x: 5433.59375,
    y: 0.3642387390136719,
  },
  {
    x: 5437.5,
    y: 0.3293495178222656,
  },
  {
    x: 5441.40625,
    y: 0.2894859313964844,
  },
  {
    x: 5445.3125,
    y: 0.24961471557617188,
  },
  {
    x: 5449.21875,
    y: 0.209747314453125,
  },
  {
    x: 5453.125,
    y: 0.1698760986328125,
  },
  {
    x: 5457.03125,
    y: 0.13001251220703125,
  },
  {
    x: 5460.9375,
    y: 0.09512710571289062,
  },
  {
    x: 5464.84375,
    y: 0.0851593017578125,
  },
  {
    x: 5468.75,
    y: 0.0751953125,
  },
  {
    x: 5472.65625,
    y: 0.0751953125,
  },
  {
    x: 5480.46875,
    y: 0.0751953125,
  },
  {
    x: 5484.375,
    y: 0.08017730712890625,
  },
  {
    x: 5488.28125,
    y: 0.08017730712890625,
  },
  {
    x: 5496.09375,
    y: 0.08017730712890625,
  },
  {
    x: 5500,
    y: 0.045291900634765625,
  },
  {
    x: 5503.90625,
    y: 0.040309906005859375,
  },
  {
    x: 5507.8125,
    y: 0.0353240966796875,
  },
  {
    x: 5511.71875,
    y: 0.045291900634765625,
  },
  {
    x: 5515.625,
    y: 0.045291900634765625,
  },
  {
    x: 5519.53125,
    y: 0.050273895263671875,
  },
  {
    x: 5523.4375,
    y: 0.05525970458984375,
  },
  {
    x: 5527.34375,
    y: 0.06024169921875,
  },
  {
    x: 5531.25,
    y: 0.06522369384765625,
  },
  {
    x: 5535.15625,
    y: 0.07020950317382812,
  },
  {
    x: 5539.0625,
    y: 0.08017730712890625,
  },
  {
    x: 5542.96875,
    y: 0.08017730712890625,
  },
  {
    x: 5546.875,
    y: 0.0851593017578125,
  },
  {
    x: 5550.78125,
    y: 0.09014511108398438,
  },
  {
    x: 5554.6875,
    y: 0.09512710571289062,
  },
  {
    x: 5558.59375,
    y: 0.10010910034179688,
  },
  {
    x: 5562.5,
    y: 0.10509490966796875,
  },
  {
    x: 5566.40625,
    y: 0.110076904296875,
  },
  {
    x: 5570.3125,
    y: 0.11506271362304688,
  },
  {
    x: 5574.21875,
    y: 0.12004470825195312,
  },
  {
    x: 5578.125,
    y: 0.125030517578125,
  },
  {
    x: 5582.03125,
    y: 0.125030517578125,
  },
  {
    x: 5855.46875,
    y: 0.125030517578125,
  },
  {
    x: 5859.375,
    y: 0.13997650146484375,
  },
  {
    x: 5863.28125,
    y: 0.1698760986328125,
  },
  {
    x: 5867.1875,
    y: 0.1997833251953125,
  },
  {
    x: 5871.09375,
    y: 0.2346649169921875,
  },
  {
    x: 5875,
    y: 0.2695503234863281,
  },
  {
    x: 5882.8125,
    y: 0.3393211364746094,
  },
  {
    x: 5886.71875,
    y: 0.3742027282714844,
  },
  {
    x: 5890.625,
    y: 0.409088134765625,
  },
  {
    x: 5894.53125,
    y: 0.4439735412597656,
  },
  {
    x: 5898.4375,
    y: 0.45892333984375,
  },
  {
    x: 5902.34375,
    y: 0.4389915466308594,
  },
  {
    x: 5906.25,
    y: 0.4041023254394531,
  },
  {
    x: 5910.15625,
    y: 0.3642387390136719,
  },
  {
    x: 5914.0625,
    y: 0.3293495178222656,
  },
  {
    x: 5917.96875,
    y: 0.2894859313964844,
  },
  {
    x: 5921.875,
    y: 0.24961471557617188,
  },
  {
    x: 5925.78125,
    y: 0.209747314453125,
  },
  {
    x: 5929.6875,
    y: 0.1698760986328125,
  },
  {
    x: 5933.59375,
    y: 0.13001251220703125,
  },
  {
    x: 5937.5,
    y: 0.09512710571289062,
  },
  {
    x: 5941.40625,
    y: 0.0851593017578125,
  },
  {
    x: 5945.3125,
    y: 0.0751953125,
  },
  {
    x: 5949.21875,
    y: 0.0751953125,
  },
  {
    x: 5957.03125,
    y: 0.0751953125,
  },
  {
    x: 5960.9375,
    y: 0.08017730712890625,
  },
  {
    x: 5964.84375,
    y: 0.08017730712890625,
  },
  {
    x: 5988.28125,
    y: 0.08017730712890625,
  },
  {
    x: 5992.1875,
    y: 0.0851593017578125,
  },
  {
    x: 5996.09375,
    y: 0.0851593017578125,
  },
  {
    x: 6000,
    y: 0.0851593017578125,
  },
];
export const V2 = [
  {
    x: 0,
    y: 0.08017730712890625,
  },
  {
    x: 11.71875,
    y: 0.050273895263671875,
  },
  {
    x: 15.625,
    y: 0.045291900634765625,
  },
  {
    x: 19.53125,
    y: 0.0353240966796875,
  },
  {
    x: 23.4375,
    y: 0.03034210205078125,
  },
  {
    x: 27.34375,
    y: 0.020374298095703125,
  },
  {
    x: 31.25,
    y: 0.010406494140625,
  },
  {
    x: 35.15625,
    y: 0.00542449951171875,
  },
  {
    x: 39.0625,
    y: -0.004543304443359375,
  },
  {
    x: 42.96875,
    y: -0.0145111083984375,
  },
  {
    x: 46.875,
    y: -0.00952911376953125,
  },
  {
    x: 50.78125,
    y: -0.004543304443359375,
  },
  {
    x: 54.6875,
    y: 0.00542449951171875,
  },
  {
    x: 58.59375,
    y: 0.020374298095703125,
  },
  {
    x: 62.5,
    y: 0.03034210205078125,
  },
  {
    x: 66.40625,
    y: 0.040309906005859375,
  },
  {
    x: 70.3125,
    y: 0.050273895263671875,
  },
  {
    x: 74.21875,
    y: 0.06024169921875,
  },
  {
    x: 85.9375,
    y: 0.09014511108398438,
  },
  {
    x: 89.84375,
    y: 0.10010910034179688,
  },
  {
    x: 93.75,
    y: 0.110076904296875,
  },
  {
    x: 97.65625,
    y: 0.12004470825195312,
  },
  {
    x: 101.5625,
    y: 0.13997650146484375,
  },
  {
    x: 105.46875,
    y: 0.14994430541992188,
  },
  {
    x: 109.375,
    y: 0.159912109375,
  },
  {
    x: 113.28125,
    y: 0.16489410400390625,
  },
  {
    x: 117.1875,
    y: 0.1698760986328125,
  },
  {
    x: 121.09375,
    y: 0.1698760986328125,
  },
  {
    x: 125,
    y: 0.16489410400390625,
  },
  {
    x: 128.90625,
    y: 0.16489410400390625,
  },
  {
    x: 156.25,
    y: 0.16489410400390625,
  },
  {
    x: 160.15625,
    y: 0.159912109375,
  },
  {
    x: 164.0625,
    y: 0.159912109375,
  },
  {
    x: 187.5,
    y: 0.159912109375,
  },
  {
    x: 191.40625,
    y: 0.15492630004882812,
  },
  {
    x: 195.3125,
    y: 0.15492630004882812,
  },
  {
    x: 246.09375,
    y: 0.15492630004882812,
  },
  {
    x: 250,
    y: -0.034442901611328125,
  },
  {
    x: 253.90625,
    y: -0.034442901611328125,
  },
  {
    x: 257.8125,
    y: -0.029460906982421875,
  },
  {
    x: 261.71875,
    y: -0.029460906982421875,
  },
  {
    x: 265.625,
    y: -0.029460906982421875,
  },
  {
    x: 269.53125,
    y: -0.024478912353515625,
  },
  {
    x: 273.4375,
    y: -0.024478912353515625,
  },
  {
    x: 277.34375,
    y: -0.01949310302734375,
  },
  {
    x: 281.25,
    y: -0.01949310302734375,
  },
  {
    x: 285.15625,
    y: -0.01949310302734375,
  },
  {
    x: 289.0625,
    y: -0.0145111083984375,
  },
  {
    x: 292.96875,
    y: -0.0145111083984375,
  },
  {
    x: 296.875,
    y: -0.0145111083984375,
  },
  {
    x: 300.78125,
    y: -0.00952911376953125,
  },
  {
    x: 304.6875,
    y: -0.00952911376953125,
  },
  {
    x: 308.59375,
    y: -0.004543304443359375,
  },
  {
    x: 312.5,
    y: -0.004543304443359375,
  },
  {
    x: 316.40625,
    y: -0.004543304443359375,
  },
  {
    x: 320.3125,
    y: 0.0004425048828125,
  },
  {
    x: 324.21875,
    y: 0.0004425048828125,
  },
  {
    x: 332.03125,
    y: 0.0004425048828125,
  },
  {
    x: 335.9375,
    y: 0.00542449951171875,
  },
  {
    x: 339.84375,
    y: 0.00542449951171875,
  },
  {
    x: 347.65625,
    y: 0.00542449951171875,
  },
  {
    x: 351.5625,
    y: 0.010406494140625,
  },
  {
    x: 355.46875,
    y: 0.010406494140625,
  },
  {
    x: 363.28125,
    y: 0.010406494140625,
  },
  {
    x: 367.1875,
    y: 0.015392303466796875,
  },
  {
    x: 371.09375,
    y: 0.015392303466796875,
  },
  {
    x: 378.90625,
    y: 0.015392303466796875,
  },
  {
    x: 382.8125,
    y: 0.020374298095703125,
  },
  {
    x: 386.71875,
    y: 0.020374298095703125,
  },
  {
    x: 394.53125,
    y: 0.020374298095703125,
  },
  {
    x: 398.4375,
    y: 0.025356292724609375,
  },
  {
    x: 402.34375,
    y: 0.025356292724609375,
  },
  {
    x: 410.15625,
    y: 0.025356292724609375,
  },
  {
    x: 414.0625,
    y: 0.03034210205078125,
  },
  {
    x: 417.96875,
    y: 0.03034210205078125,
  },
  {
    x: 425.78125,
    y: 0.03034210205078125,
  },
  {
    x: 429.6875,
    y: 0.0353240966796875,
  },
  {
    x: 433.59375,
    y: 0.0353240966796875,
  },
  {
    x: 437.5,
    y: 0.0353240966796875,
  },
  {
    x: 441.40625,
    y: 0.040309906005859375,
  },
  {
    x: 445.3125,
    y: 0.07020950317382812,
  },
  {
    x: 449.21875,
    y: 0.10509490966796875,
  },
  {
    x: 453.125,
    y: 0.13001251220703125,
  },
  {
    x: 457.03125,
    y: 0.125030517578125,
  },
  {
    x: 460.9375,
    y: 0.12004470825195312,
  },
  {
    x: 464.84375,
    y: 0.110076904296875,
  },
  {
    x: 468.75,
    y: 0.10010910034179688,
  },
  {
    x: 472.65625,
    y: 0.09014511108398438,
  },
  {
    x: 476.5625,
    y: 0.08017730712890625,
  },
  {
    x: 488.28125,
    y: 0.050273895263671875,
  },
  {
    x: 492.1875,
    y: 0.045291900634765625,
  },
  {
    x: 496.09375,
    y: 0.0353240966796875,
  },
  {
    x: 500,
    y: 0.03034210205078125,
  },
  {
    x: 503.90625,
    y: 0.020374298095703125,
  },
  {
    x: 507.8125,
    y: 0.010406494140625,
  },
  {
    x: 511.71875,
    y: 0.00542449951171875,
  },
  {
    x: 515.625,
    y: -0.004543304443359375,
  },
  {
    x: 519.53125,
    y: -0.0145111083984375,
  },
  {
    x: 523.4375,
    y: -0.00952911376953125,
  },
  {
    x: 527.34375,
    y: -0.004543304443359375,
  },
  {
    x: 531.25,
    y: 0.00542449951171875,
  },
  {
    x: 535.15625,
    y: 0.020374298095703125,
  },
  {
    x: 539.0625,
    y: 0.03034210205078125,
  },
  {
    x: 542.96875,
    y: 0.040309906005859375,
  },
  {
    x: 546.875,
    y: 0.050273895263671875,
  },
  {
    x: 550.78125,
    y: 0.06024169921875,
  },
  {
    x: 562.5,
    y: 0.09014511108398438,
  },
  {
    x: 566.40625,
    y: 0.10010910034179688,
  },
  {
    x: 570.3125,
    y: 0.110076904296875,
  },
  {
    x: 574.21875,
    y: 0.12004470825195312,
  },
  {
    x: 578.125,
    y: 0.13997650146484375,
  },
  {
    x: 582.03125,
    y: 0.14994430541992188,
  },
  {
    x: 585.9375,
    y: 0.159912109375,
  },
  {
    x: 589.84375,
    y: 0.16489410400390625,
  },
  {
    x: 593.75,
    y: 0.1698760986328125,
  },
  {
    x: 597.65625,
    y: 0.1698760986328125,
  },
  {
    x: 601.5625,
    y: 0.16489410400390625,
  },
  {
    x: 605.46875,
    y: 0.16489410400390625,
  },
  {
    x: 632.8125,
    y: 0.16489410400390625,
  },
  {
    x: 636.71875,
    y: 0.159912109375,
  },
  {
    x: 640.625,
    y: 0.159912109375,
  },
  {
    x: 664.0625,
    y: 0.159912109375,
  },
  {
    x: 667.96875,
    y: 0.15492630004882812,
  },
  {
    x: 671.875,
    y: 0.15492630004882812,
  },
  {
    x: 1121.09375,
    y: 0.15492630004882812,
  },
  {
    x: 1125,
    y: 0.17486572265625,
  },
  {
    x: 1128.90625,
    y: 0.23964691162109375,
  },
  {
    x: 1132.8125,
    y: 0.309417724609375,
  },
  {
    x: 1136.71875,
    y: 0.3941383361816406,
  },
  {
    x: 1140.625,
    y: 0.4738731384277344,
  },
  {
    x: 1144.53125,
    y: 0.5536079406738281,
  },
  {
    x: 1148.4375,
    y: 0.6333465576171875,
  },
  {
    x: 1152.34375,
    y: 0.7130813598632812,
  },
  {
    x: 1156.25,
    y: 0.7928199768066406,
  },
  {
    x: 1160.15625,
    y: 0.8675727844238281,
  },
  {
    x: 1164.0625,
    y: 0.9024581909179688,
  },
  {
    x: 1167.96875,
    y: 0.8526229858398438,
  },
  {
    x: 1171.875,
    y: 0.7679023742675781,
  },
  {
    x: 1175.78125,
    y: 0.6732177734375,
  },
  {
    x: 1179.6875,
    y: 0.57354736328125,
  },
  {
    x: 1183.59375,
    y: 0.4688911437988281,
  },
  {
    x: 1187.5,
    y: 0.3742027282714844,
  },
  {
    x: 1191.40625,
    y: 0.2745361328125,
  },
  {
    x: 1195.3125,
    y: 0.17984771728515625,
  },
  {
    x: 1199.21875,
    y: 0.0751953125,
  },
  {
    x: 1203.125,
    y: 0.0004425048828125,
  },
  {
    x: 1207.03125,
    y: -0.029460906982421875,
  },
  {
    x: 1210.9375,
    y: -0.034442901611328125,
  },
  {
    x: 1214.84375,
    y: -0.0394287109375,
  },
  {
    x: 1218.75,
    y: -0.0394287109375,
  },
  {
    x: 1222.65625,
    y: -0.034442901611328125,
  },
  {
    x: 1226.5625,
    y: -0.034442901611328125,
  },
  {
    x: 1230.46875,
    y: -0.034442901611328125,
  },
  {
    x: 1234.375,
    y: -0.029460906982421875,
  },
  {
    x: 1238.28125,
    y: -0.029460906982421875,
  },
  {
    x: 1242.1875,
    y: -0.029460906982421875,
  },
  {
    x: 1246.09375,
    y: -0.024478912353515625,
  },
  {
    x: 1250,
    y: -0.024478912353515625,
  },
  {
    x: 1253.90625,
    y: -0.01949310302734375,
  },
  {
    x: 1257.8125,
    y: -0.01949310302734375,
  },
  {
    x: 1261.71875,
    y: -0.01949310302734375,
  },
  {
    x: 1265.625,
    y: -0.0145111083984375,
  },
  {
    x: 1269.53125,
    y: -0.0145111083984375,
  },
  {
    x: 1273.4375,
    y: -0.0145111083984375,
  },
  {
    x: 1277.34375,
    y: -0.00952911376953125,
  },
  {
    x: 1281.25,
    y: -0.00952911376953125,
  },
  {
    x: 1285.15625,
    y: -0.004543304443359375,
  },
  {
    x: 1289.0625,
    y: -0.004543304443359375,
  },
  {
    x: 1292.96875,
    y: -0.004543304443359375,
  },
  {
    x: 1296.875,
    y: 0.0004425048828125,
  },
  {
    x: 1300.78125,
    y: 0.0004425048828125,
  },
  {
    x: 1308.59375,
    y: 0.0004425048828125,
  },
  {
    x: 1312.5,
    y: 0.00542449951171875,
  },
  {
    x: 1316.40625,
    y: 0.00542449951171875,
  },
  {
    x: 1324.21875,
    y: 0.00542449951171875,
  },
  {
    x: 1328.125,
    y: 0.010406494140625,
  },
  {
    x: 1332.03125,
    y: 0.010406494140625,
  },
  {
    x: 1339.84375,
    y: 0.010406494140625,
  },
  {
    x: 1343.75,
    y: 0.015392303466796875,
  },
  {
    x: 1347.65625,
    y: 0.015392303466796875,
  },
  {
    x: 1355.46875,
    y: 0.015392303466796875,
  },
  {
    x: 1359.375,
    y: 0.020374298095703125,
  },
  {
    x: 1363.28125,
    y: 0.020374298095703125,
  },
  {
    x: 1371.09375,
    y: 0.020374298095703125,
  },
  {
    x: 1375,
    y: 0.025356292724609375,
  },
  {
    x: 1378.90625,
    y: 0.025356292724609375,
  },
  {
    x: 1386.71875,
    y: 0.025356292724609375,
  },
  {
    x: 1390.625,
    y: 0.03034210205078125,
  },
  {
    x: 1394.53125,
    y: 0.03034210205078125,
  },
  {
    x: 1402.34375,
    y: 0.03034210205078125,
  },
  {
    x: 1406.25,
    y: 0.0353240966796875,
  },
  {
    x: 1410.15625,
    y: 0.0353240966796875,
  },
  {
    x: 1414.0625,
    y: 0.0353240966796875,
  },
  {
    x: 1417.96875,
    y: 0.040309906005859375,
  },
  {
    x: 1421.875,
    y: 0.07020950317382812,
  },
  {
    x: 1425.78125,
    y: 0.10509490966796875,
  },
  {
    x: 1429.6875,
    y: 0.13001251220703125,
  },
  {
    x: 1433.59375,
    y: 0.125030517578125,
  },
  {
    x: 1437.5,
    y: 0.12004470825195312,
  },
  {
    x: 1441.40625,
    y: 0.110076904296875,
  },
  {
    x: 1445.3125,
    y: 0.10010910034179688,
  },
  {
    x: 1449.21875,
    y: 0.09014511108398438,
  },
  {
    x: 1453.125,
    y: 0.08017730712890625,
  },
  {
    x: 1464.84375,
    y: 0.050273895263671875,
  },
  {
    x: 1468.75,
    y: 0.045291900634765625,
  },
  {
    x: 1472.65625,
    y: 0.0353240966796875,
  },
  {
    x: 1476.5625,
    y: 0.03034210205078125,
  },
  {
    x: 1480.46875,
    y: 0.020374298095703125,
  },
  {
    x: 1484.375,
    y: 0.010406494140625,
  },
  {
    x: 1488.28125,
    y: 0.00542449951171875,
  },
  {
    x: 1492.1875,
    y: -0.004543304443359375,
  },
  {
    x: 1496.09375,
    y: -0.0145111083984375,
  },
  {
    x: 1500,
    y: -0.00952911376953125,
  },
  {
    x: 1503.90625,
    y: -0.004543304443359375,
  },
  {
    x: 1507.8125,
    y: 0.00542449951171875,
  },
  {
    x: 1511.71875,
    y: 0.020374298095703125,
  },
  {
    x: 1515.625,
    y: 0.03034210205078125,
  },
  {
    x: 1519.53125,
    y: 0.040309906005859375,
  },
  {
    x: 1523.4375,
    y: 0.050273895263671875,
  },
  {
    x: 1527.34375,
    y: 0.06024169921875,
  },
  {
    x: 1539.0625,
    y: 0.09014511108398438,
  },
  {
    x: 1542.96875,
    y: 0.10010910034179688,
  },
  {
    x: 1546.875,
    y: 0.110076904296875,
  },
  {
    x: 1550.78125,
    y: 0.12004470825195312,
  },
  {
    x: 1554.6875,
    y: 0.13997650146484375,
  },
  {
    x: 1558.59375,
    y: 0.14994430541992188,
  },
  {
    x: 1562.5,
    y: 0.159912109375,
  },
  {
    x: 1566.40625,
    y: 0.16489410400390625,
  },
  {
    x: 1570.3125,
    y: 0.1698760986328125,
  },
  {
    x: 1574.21875,
    y: 0.1698760986328125,
  },
  {
    x: 1578.125,
    y: 0.16489410400390625,
  },
  {
    x: 1582.03125,
    y: 0.16489410400390625,
  },
  {
    x: 1609.375,
    y: 0.16489410400390625,
  },
  {
    x: 1613.28125,
    y: 0.159912109375,
  },
  {
    x: 1617.1875,
    y: 0.159912109375,
  },
  {
    x: 1640.625,
    y: 0.159912109375,
  },
  {
    x: 1644.53125,
    y: 0.15492630004882812,
  },
  {
    x: 1648.4375,
    y: 0.15492630004882812,
  },
  {
    x: 2097.65625,
    y: 0.15492630004882812,
  },
  {
    x: 2101.5625,
    y: 0.17486572265625,
  },
  {
    x: 2105.46875,
    y: 0.23964691162109375,
  },
  {
    x: 2109.375,
    y: 0.309417724609375,
  },
  {
    x: 2113.28125,
    y: 0.3941383361816406,
  },
  {
    x: 2117.1875,
    y: 0.4738731384277344,
  },
  {
    x: 2121.09375,
    y: 0.5536079406738281,
  },
  {
    x: 2125,
    y: 0.6333465576171875,
  },
  {
    x: 2128.90625,
    y: 0.7130813598632812,
  },
  {
    x: 2132.8125,
    y: 0.7928199768066406,
  },
  {
    x: 2136.71875,
    y: 0.8675727844238281,
  },
  {
    x: 2140.625,
    y: 0.9024581909179688,
  },
  {
    x: 2144.53125,
    y: 0.8526229858398438,
  },
  {
    x: 2148.4375,
    y: 0.7679023742675781,
  },
  {
    x: 2152.34375,
    y: 0.6732177734375,
  },
  {
    x: 2156.25,
    y: 0.57354736328125,
  },
  {
    x: 2160.15625,
    y: 0.4688911437988281,
  },
  {
    x: 2164.0625,
    y: 0.3742027282714844,
  },
  {
    x: 2167.96875,
    y: 0.2745361328125,
  },
  {
    x: 2171.875,
    y: 0.17984771728515625,
  },
  {
    x: 2175.78125,
    y: 0.0751953125,
  },
  {
    x: 2179.6875,
    y: 0.0004425048828125,
  },
  {
    x: 2183.59375,
    y: -0.029460906982421875,
  },
  {
    x: 2187.5,
    y: -0.034442901611328125,
  },
  {
    x: 2191.40625,
    y: -0.0394287109375,
  },
  {
    x: 2195.3125,
    y: -0.0394287109375,
  },
  {
    x: 2199.21875,
    y: -0.034442901611328125,
  },
  {
    x: 2203.125,
    y: -0.034442901611328125,
  },
  {
    x: 2207.03125,
    y: -0.034442901611328125,
  },
  {
    x: 2210.9375,
    y: -0.029460906982421875,
  },
  {
    x: 2214.84375,
    y: -0.029460906982421875,
  },
  {
    x: 2218.75,
    y: -0.029460906982421875,
  },
  {
    x: 2222.65625,
    y: -0.024478912353515625,
  },
  {
    x: 2226.5625,
    y: -0.024478912353515625,
  },
  {
    x: 2230.46875,
    y: -0.01949310302734375,
  },
  {
    x: 2234.375,
    y: -0.01949310302734375,
  },
  {
    x: 2238.28125,
    y: -0.01949310302734375,
  },
  {
    x: 2242.1875,
    y: -0.0145111083984375,
  },
  {
    x: 2246.09375,
    y: -0.0145111083984375,
  },
  {
    x: 2250,
    y: -0.0145111083984375,
  },
  {
    x: 2253.90625,
    y: -0.00952911376953125,
  },
  {
    x: 2257.8125,
    y: -0.00952911376953125,
  },
  {
    x: 2261.71875,
    y: -0.004543304443359375,
  },
  {
    x: 2265.625,
    y: -0.004543304443359375,
  },
  {
    x: 2269.53125,
    y: -0.004543304443359375,
  },
  {
    x: 2273.4375,
    y: 0.0004425048828125,
  },
  {
    x: 2277.34375,
    y: 0.0004425048828125,
  },
  {
    x: 2285.15625,
    y: 0.0004425048828125,
  },
  {
    x: 2289.0625,
    y: 0.00542449951171875,
  },
  {
    x: 2292.96875,
    y: 0.00542449951171875,
  },
  {
    x: 2300.78125,
    y: 0.00542449951171875,
  },
  {
    x: 2304.6875,
    y: 0.010406494140625,
  },
  {
    x: 2308.59375,
    y: 0.010406494140625,
  },
  {
    x: 2316.40625,
    y: 0.010406494140625,
  },
  {
    x: 2320.3125,
    y: 0.015392303466796875,
  },
  {
    x: 2324.21875,
    y: 0.015392303466796875,
  },
  {
    x: 2332.03125,
    y: 0.015392303466796875,
  },
  {
    x: 2335.9375,
    y: 0.020374298095703125,
  },
  {
    x: 2339.84375,
    y: 0.020374298095703125,
  },
  {
    x: 2347.65625,
    y: 0.020374298095703125,
  },
  {
    x: 2351.5625,
    y: 0.025356292724609375,
  },
  {
    x: 2355.46875,
    y: 0.025356292724609375,
  },
  {
    x: 2363.28125,
    y: 0.025356292724609375,
  },
  {
    x: 2367.1875,
    y: 0.03034210205078125,
  },
  {
    x: 2371.09375,
    y: 0.03034210205078125,
  },
  {
    x: 2378.90625,
    y: 0.03034210205078125,
  },
  {
    x: 2382.8125,
    y: 0.0353240966796875,
  },
  {
    x: 2386.71875,
    y: 0.0353240966796875,
  },
  {
    x: 2390.625,
    y: 0.0353240966796875,
  },
  {
    x: 2394.53125,
    y: 0.040309906005859375,
  },
  {
    x: 2398.4375,
    y: 0.07020950317382812,
  },
  {
    x: 2402.34375,
    y: 0.10509490966796875,
  },
  {
    x: 2406.25,
    y: 0.13001251220703125,
  },
  {
    x: 2410.15625,
    y: 0.125030517578125,
  },
  {
    x: 2414.0625,
    y: 0.12004470825195312,
  },
  {
    x: 2417.96875,
    y: 0.110076904296875,
  },
  {
    x: 2421.875,
    y: 0.10010910034179688,
  },
  {
    x: 2425.78125,
    y: 0.09014511108398438,
  },
  {
    x: 2429.6875,
    y: 0.08017730712890625,
  },
  {
    x: 2441.40625,
    y: 0.050273895263671875,
  },
  {
    x: 2445.3125,
    y: 0.045291900634765625,
  },
  {
    x: 2449.21875,
    y: 0.0353240966796875,
  },
  {
    x: 2453.125,
    y: 0.03034210205078125,
  },
  {
    x: 2457.03125,
    y: 0.020374298095703125,
  },
  {
    x: 2460.9375,
    y: 0.010406494140625,
  },
  {
    x: 2464.84375,
    y: 0.00542449951171875,
  },
  {
    x: 2468.75,
    y: -0.004543304443359375,
  },
  {
    x: 2472.65625,
    y: -0.0145111083984375,
  },
  {
    x: 2476.5625,
    y: -0.00952911376953125,
  },
  {
    x: 2480.46875,
    y: -0.004543304443359375,
  },
  {
    x: 2484.375,
    y: 0.00542449951171875,
  },
  {
    x: 2488.28125,
    y: 0.020374298095703125,
  },
  {
    x: 2492.1875,
    y: 0.03034210205078125,
  },
  {
    x: 2496.09375,
    y: 0.040309906005859375,
  },
  {
    x: 2500,
    y: 0.050273895263671875,
  },
  {
    x: 2503.90625,
    y: 0.06024169921875,
  },
  {
    x: 2515.625,
    y: 0.09014511108398438,
  },
  {
    x: 2519.53125,
    y: 0.10010910034179688,
  },
  {
    x: 2523.4375,
    y: 0.110076904296875,
  },
  {
    x: 2527.34375,
    y: 0.12004470825195312,
  },
  {
    x: 2531.25,
    y: 0.13997650146484375,
  },
  {
    x: 2535.15625,
    y: 0.14994430541992188,
  },
  {
    x: 2539.0625,
    y: 0.159912109375,
  },
  {
    x: 2542.96875,
    y: 0.16489410400390625,
  },
  {
    x: 2546.875,
    y: 0.1698760986328125,
  },
  {
    x: 2550.78125,
    y: 0.1698760986328125,
  },
  {
    x: 2554.6875,
    y: 0.16489410400390625,
  },
  {
    x: 2558.59375,
    y: 0.16489410400390625,
  },
  {
    x: 2585.9375,
    y: 0.16489410400390625,
  },
  {
    x: 2589.84375,
    y: 0.159912109375,
  },
  {
    x: 2593.75,
    y: 0.159912109375,
  },
  {
    x: 2617.1875,
    y: 0.159912109375,
  },
  {
    x: 2621.09375,
    y: 0.15492630004882812,
  },
  {
    x: 2625,
    y: 0.15492630004882812,
  },
  {
    x: 3074.21875,
    y: 0.15492630004882812,
  },
  {
    x: 3078.125,
    y: 0.17486572265625,
  },
  {
    x: 3082.03125,
    y: 0.23964691162109375,
  },
  {
    x: 3085.9375,
    y: 0.309417724609375,
  },
  {
    x: 3089.84375,
    y: 0.3941383361816406,
  },
  {
    x: 3093.75,
    y: 0.4738731384277344,
  },
  {
    x: 3097.65625,
    y: 0.5536079406738281,
  },
  {
    x: 3101.5625,
    y: 0.6333465576171875,
  },
  {
    x: 3105.46875,
    y: 0.7130813598632812,
  },
  {
    x: 3109.375,
    y: 0.7928199768066406,
  },
  {
    x: 3113.28125,
    y: 0.8675727844238281,
  },
  {
    x: 3117.1875,
    y: 0.9024581909179688,
  },
  {
    x: 3121.09375,
    y: 0.8526229858398438,
  },
  {
    x: 3125,
    y: 0.7679023742675781,
  },
  {
    x: 3128.90625,
    y: 0.6732177734375,
  },
  {
    x: 3132.8125,
    y: 0.57354736328125,
  },
  {
    x: 3136.71875,
    y: 0.4688911437988281,
  },
  {
    x: 3140.625,
    y: 0.3742027282714844,
  },
  {
    x: 3144.53125,
    y: 0.2745361328125,
  },
  {
    x: 3148.4375,
    y: 0.17984771728515625,
  },
  {
    x: 3152.34375,
    y: 0.0751953125,
  },
  {
    x: 3156.25,
    y: 0.0004425048828125,
  },
  {
    x: 3160.15625,
    y: -0.029460906982421875,
  },
  {
    x: 3164.0625,
    y: -0.034442901611328125,
  },
  {
    x: 3167.96875,
    y: -0.0394287109375,
  },
  {
    x: 3171.875,
    y: -0.0394287109375,
  },
  {
    x: 3175.78125,
    y: -0.034442901611328125,
  },
  {
    x: 3179.6875,
    y: -0.034442901611328125,
  },
  {
    x: 3183.59375,
    y: -0.034442901611328125,
  },
  {
    x: 3187.5,
    y: -0.029460906982421875,
  },
  {
    x: 3191.40625,
    y: -0.029460906982421875,
  },
  {
    x: 3195.3125,
    y: -0.029460906982421875,
  },
  {
    x: 3199.21875,
    y: -0.024478912353515625,
  },
  {
    x: 3203.125,
    y: -0.024478912353515625,
  },
  {
    x: 3207.03125,
    y: -0.01949310302734375,
  },
  {
    x: 3210.9375,
    y: -0.01949310302734375,
  },
  {
    x: 3214.84375,
    y: -0.01949310302734375,
  },
  {
    x: 3218.75,
    y: -0.0145111083984375,
  },
  {
    x: 3222.65625,
    y: -0.0145111083984375,
  },
  {
    x: 3226.5625,
    y: -0.0145111083984375,
  },
  {
    x: 3230.46875,
    y: -0.00952911376953125,
  },
  {
    x: 3234.375,
    y: -0.00952911376953125,
  },
  {
    x: 3238.28125,
    y: -0.004543304443359375,
  },
  {
    x: 3242.1875,
    y: -0.004543304443359375,
  },
  {
    x: 3246.09375,
    y: -0.004543304443359375,
  },
  {
    x: 3250,
    y: 0.0004425048828125,
  },
  {
    x: 3253.90625,
    y: 0.0004425048828125,
  },
  {
    x: 3261.71875,
    y: 0.0004425048828125,
  },
  {
    x: 3265.625,
    y: 0.00542449951171875,
  },
  {
    x: 3269.53125,
    y: 0.00542449951171875,
  },
  {
    x: 3277.34375,
    y: 0.00542449951171875,
  },
  {
    x: 3281.25,
    y: 0.010406494140625,
  },
  {
    x: 3285.15625,
    y: 0.010406494140625,
  },
  {
    x: 3292.96875,
    y: 0.010406494140625,
  },
  {
    x: 3296.875,
    y: 0.015392303466796875,
  },
  {
    x: 3300.78125,
    y: 0.015392303466796875,
  },
  {
    x: 3308.59375,
    y: 0.015392303466796875,
  },
  {
    x: 3312.5,
    y: 0.020374298095703125,
  },
  {
    x: 3316.40625,
    y: 0.020374298095703125,
  },
  {
    x: 3324.21875,
    y: 0.020374298095703125,
  },
  {
    x: 3328.125,
    y: 0.025356292724609375,
  },
  {
    x: 3332.03125,
    y: 0.025356292724609375,
  },
  {
    x: 3339.84375,
    y: 0.025356292724609375,
  },
  {
    x: 3343.75,
    y: 0.03034210205078125,
  },
  {
    x: 3347.65625,
    y: 0.03034210205078125,
  },
  {
    x: 3355.46875,
    y: 0.03034210205078125,
  },
  {
    x: 3359.375,
    y: 0.0353240966796875,
  },
  {
    x: 3363.28125,
    y: 0.0353240966796875,
  },
  {
    x: 3367.1875,
    y: 0.0353240966796875,
  },
  {
    x: 3371.09375,
    y: 0.040309906005859375,
  },
  {
    x: 3375,
    y: 0.07020950317382812,
  },
  {
    x: 3378.90625,
    y: 0.10509490966796875,
  },
  {
    x: 3382.8125,
    y: 0.13001251220703125,
  },
  {
    x: 3386.71875,
    y: 0.125030517578125,
  },
  {
    x: 3390.625,
    y: 0.12004470825195312,
  },
  {
    x: 3394.53125,
    y: 0.110076904296875,
  },
  {
    x: 3398.4375,
    y: 0.10010910034179688,
  },
  {
    x: 3402.34375,
    y: 0.09014511108398438,
  },
  {
    x: 3406.25,
    y: 0.08017730712890625,
  },
  {
    x: 3417.96875,
    y: 0.050273895263671875,
  },
  {
    x: 3421.875,
    y: 0.045291900634765625,
  },
  {
    x: 3425.78125,
    y: 0.0353240966796875,
  },
  {
    x: 3429.6875,
    y: 0.03034210205078125,
  },
  {
    x: 3433.59375,
    y: 0.020374298095703125,
  },
  {
    x: 3437.5,
    y: 0.010406494140625,
  },
  {
    x: 3441.40625,
    y: 0.00542449951171875,
  },
  {
    x: 3445.3125,
    y: -0.004543304443359375,
  },
  {
    x: 3449.21875,
    y: -0.0145111083984375,
  },
  {
    x: 3453.125,
    y: -0.00952911376953125,
  },
  {
    x: 3457.03125,
    y: -0.004543304443359375,
  },
  {
    x: 3460.9375,
    y: 0.00542449951171875,
  },
  {
    x: 3464.84375,
    y: 0.020374298095703125,
  },
  {
    x: 3468.75,
    y: 0.03034210205078125,
  },
  {
    x: 3472.65625,
    y: 0.040309906005859375,
  },
  {
    x: 3476.5625,
    y: 0.050273895263671875,
  },
  {
    x: 3480.46875,
    y: 0.06024169921875,
  },
  {
    x: 3492.1875,
    y: 0.09014511108398438,
  },
  {
    x: 3496.09375,
    y: 0.10010910034179688,
  },
  {
    x: 3500,
    y: 0.110076904296875,
  },
  {
    x: 3503.90625,
    y: 0.12004470825195312,
  },
  {
    x: 3507.8125,
    y: 0.13997650146484375,
  },
  {
    x: 3511.71875,
    y: 0.14994430541992188,
  },
  {
    x: 3515.625,
    y: 0.159912109375,
  },
  {
    x: 3519.53125,
    y: 0.16489410400390625,
  },
  {
    x: 3523.4375,
    y: 0.1698760986328125,
  },
  {
    x: 3527.34375,
    y: 0.1698760986328125,
  },
  {
    x: 3531.25,
    y: 0.16489410400390625,
  },
  {
    x: 3535.15625,
    y: 0.16489410400390625,
  },
  {
    x: 3562.5,
    y: 0.16489410400390625,
  },
  {
    x: 3566.40625,
    y: 0.159912109375,
  },
  {
    x: 3570.3125,
    y: 0.159912109375,
  },
  {
    x: 3593.75,
    y: 0.159912109375,
  },
  {
    x: 3597.65625,
    y: 0.15492630004882812,
  },
  {
    x: 3601.5625,
    y: 0.15492630004882812,
  },
  {
    x: 4050.78125,
    y: 0.15492630004882812,
  },
  {
    x: 4054.6875,
    y: 0.17486572265625,
  },
  {
    x: 4058.59375,
    y: 0.23964691162109375,
  },
  {
    x: 4062.5,
    y: 0.309417724609375,
  },
  {
    x: 4066.40625,
    y: 0.3941383361816406,
  },
  {
    x: 4070.3125,
    y: 0.4738731384277344,
  },
  {
    x: 4074.21875,
    y: 0.5536079406738281,
  },
  {
    x: 4078.125,
    y: 0.6333465576171875,
  },
  {
    x: 4082.03125,
    y: 0.7130813598632812,
  },
  {
    x: 4085.9375,
    y: 0.7928199768066406,
  },
  {
    x: 4089.84375,
    y: 0.8675727844238281,
  },
  {
    x: 4093.75,
    y: 0.9024581909179688,
  },
  {
    x: 4097.65625,
    y: 0.8526229858398438,
  },
  {
    x: 4101.5625,
    y: 0.7679023742675781,
  },
  {
    x: 4105.46875,
    y: 0.6682319641113281,
  },
  {
    x: 4109.375,
    y: 0.5685577392578125,
  },
  {
    x: 4113.28125,
    y: 0.4688911437988281,
  },
  {
    x: 4117.1875,
    y: 0.3692207336425781,
  },
  {
    x: 4121.09375,
    y: 0.2745361328125,
  },
  {
    x: 4125,
    y: 0.17486572265625,
  },
  {
    x: 4128.90625,
    y: 0.07020950317382812,
  },
  {
    x: 4132.8125,
    y: -0.004543304443359375,
  },
  {
    x: 4136.71875,
    y: -0.034442901611328125,
  },
  {
    x: 4140.625,
    y: -0.0394287109375,
  },
  {
    x: 4144.53125,
    y: -0.04441070556640625,
  },
  {
    x: 4148.4375,
    y: -0.04441070556640625,
  },
  {
    x: 4152.34375,
    y: -0.0394287109375,
  },
  {
    x: 4156.25,
    y: -0.0394287109375,
  },
  {
    x: 4160.15625,
    y: -0.0394287109375,
  },
  {
    x: 4164.0625,
    y: -0.034442901611328125,
  },
  {
    x: 4167.96875,
    y: -0.034442901611328125,
  },
  {
    x: 4171.875,
    y: -0.029460906982421875,
  },
  {
    x: 4175.78125,
    y: -0.029460906982421875,
  },
  {
    x: 4179.6875,
    y: -0.029460906982421875,
  },
  {
    x: 4183.59375,
    y: -0.024478912353515625,
  },
  {
    x: 4187.5,
    y: -0.024478912353515625,
  },
  {
    x: 4191.40625,
    y: -0.024478912353515625,
  },
  {
    x: 4195.3125,
    y: -0.01949310302734375,
  },
  {
    x: 4199.21875,
    y: -0.01949310302734375,
  },
  {
    x: 4203.125,
    y: -0.0145111083984375,
  },
  {
    x: 4207.03125,
    y: -0.0145111083984375,
  },
  {
    x: 4210.9375,
    y: -0.0145111083984375,
  },
  {
    x: 4214.84375,
    y: -0.00952911376953125,
  },
  {
    x: 4218.75,
    y: -0.00952911376953125,
  },
  {
    x: 4222.65625,
    y: -0.00952911376953125,
  },
  {
    x: 4226.5625,
    y: -0.004543304443359375,
  },
  {
    x: 4230.46875,
    y: -0.004543304443359375,
  },
  {
    x: 4234.375,
    y: -0.004543304443359375,
  },
  {
    x: 4238.28125,
    y: 0.0004425048828125,
  },
  {
    x: 4242.1875,
    y: 0.0004425048828125,
  },
  {
    x: 4250,
    y: 0.0004425048828125,
  },
  {
    x: 4253.90625,
    y: 0.00542449951171875,
  },
  {
    x: 4257.8125,
    y: 0.00542449951171875,
  },
  {
    x: 4265.625,
    y: 0.00542449951171875,
  },
  {
    x: 4269.53125,
    y: 0.010406494140625,
  },
  {
    x: 4273.4375,
    y: 0.010406494140625,
  },
  {
    x: 4281.25,
    y: 0.010406494140625,
  },
  {
    x: 4285.15625,
    y: 0.015392303466796875,
  },
  {
    x: 4289.0625,
    y: 0.015392303466796875,
  },
  {
    x: 4296.875,
    y: 0.015392303466796875,
  },
  {
    x: 4300.78125,
    y: 0.020374298095703125,
  },
  {
    x: 4304.6875,
    y: 0.020374298095703125,
  },
  {
    x: 4312.5,
    y: 0.020374298095703125,
  },
  {
    x: 4316.40625,
    y: 0.025356292724609375,
  },
  {
    x: 4320.3125,
    y: 0.025356292724609375,
  },
  {
    x: 4328.125,
    y: 0.025356292724609375,
  },
  {
    x: 4332.03125,
    y: 0.03034210205078125,
  },
  {
    x: 4335.9375,
    y: 0.03034210205078125,
  },
  {
    x: 4343.75,
    y: 0.03034210205078125,
  },
  {
    x: 4347.65625,
    y: 0.040309906005859375,
  },
  {
    x: 4351.5625,
    y: 0.07020950317382812,
  },
  {
    x: 4355.46875,
    y: 0.10509490966796875,
  },
  {
    x: 4359.375,
    y: 0.125030517578125,
  },
  {
    x: 4363.28125,
    y: 0.125030517578125,
  },
  {
    x: 4367.1875,
    y: 0.12004470825195312,
  },
  {
    x: 4371.09375,
    y: 0.110076904296875,
  },
  {
    x: 4375,
    y: 0.10010910034179688,
  },
  {
    x: 4378.90625,
    y: 0.09014511108398438,
  },
  {
    x: 4382.8125,
    y: 0.08017730712890625,
  },
  {
    x: 4390.625,
    y: 0.06024169921875,
  },
  {
    x: 4394.53125,
    y: 0.05525970458984375,
  },
  {
    x: 4398.4375,
    y: 0.050273895263671875,
  },
  {
    x: 4402.34375,
    y: 0.040309906005859375,
  },
  {
    x: 4406.25,
    y: 0.03034210205078125,
  },
  {
    x: 4410.15625,
    y: 0.020374298095703125,
  },
  {
    x: 4414.0625,
    y: 0.015392303466796875,
  },
  {
    x: 4417.96875,
    y: 0.00542449951171875,
  },
  {
    x: 4425.78125,
    y: -0.0145111083984375,
  },
  {
    x: 4429.6875,
    y: -0.00952911376953125,
  },
  {
    x: 4433.59375,
    y: 0.0004425048828125,
  },
  {
    x: 4437.5,
    y: 0.010406494140625,
  },
  {
    x: 4441.40625,
    y: 0.020374298095703125,
  },
  {
    x: 4449.21875,
    y: 0.040309906005859375,
  },
  {
    x: 4453.125,
    y: 0.05525970458984375,
  },
  {
    x: 4457.03125,
    y: 0.06522369384765625,
  },
  {
    x: 4460.9375,
    y: 0.07020950317382812,
  },
  {
    x: 4464.84375,
    y: 0.08017730712890625,
  },
  {
    x: 4468.75,
    y: 0.09014511108398438,
  },
  {
    x: 4472.65625,
    y: 0.10010910034179688,
  },
  {
    x: 4476.5625,
    y: 0.110076904296875,
  },
  {
    x: 4480.46875,
    y: 0.12004470825195312,
  },
  {
    x: 4484.375,
    y: 0.13997650146484375,
  },
  {
    x: 4488.28125,
    y: 0.14994430541992188,
  },
  {
    x: 4492.1875,
    y: 0.159912109375,
  },
  {
    x: 4496.09375,
    y: 0.16489410400390625,
  },
  {
    x: 4500,
    y: 0.1698760986328125,
  },
  {
    x: 4503.90625,
    y: 0.1698760986328125,
  },
  {
    x: 4511.71875,
    y: 0.1698760986328125,
  },
  {
    x: 4515.625,
    y: 0.16489410400390625,
  },
  {
    x: 4519.53125,
    y: 0.16489410400390625,
  },
  {
    x: 4542.96875,
    y: 0.16489410400390625,
  },
  {
    x: 4546.875,
    y: 0.159912109375,
  },
  {
    x: 4550.78125,
    y: 0.159912109375,
  },
  {
    x: 4574.21875,
    y: 0.159912109375,
  },
  {
    x: 4578.125,
    y: 0.15492630004882812,
  },
  {
    x: 4582.03125,
    y: 0.15492630004882812,
  },
  {
    x: 5027.34375,
    y: 0.15492630004882812,
  },
  {
    x: 5031.25,
    y: 0.17486572265625,
  },
  {
    x: 5035.15625,
    y: 0.23964691162109375,
  },
  {
    x: 5039.0625,
    y: 0.309417724609375,
  },
  {
    x: 5042.96875,
    y: 0.3941383361816406,
  },
  {
    x: 5046.875,
    y: 0.4738731384277344,
  },
  {
    x: 5050.78125,
    y: 0.5536079406738281,
  },
  {
    x: 5054.6875,
    y: 0.6333465576171875,
  },
  {
    x: 5058.59375,
    y: 0.7130813598632812,
  },
  {
    x: 5062.5,
    y: 0.7928199768066406,
  },
  {
    x: 5066.40625,
    y: 0.8675727844238281,
  },
  {
    x: 5070.3125,
    y: 0.9024581909179688,
  },
  {
    x: 5074.21875,
    y: 0.8526229858398438,
  },
  {
    x: 5078.125,
    y: 0.7679023742675781,
  },
  {
    x: 5082.03125,
    y: 0.6682319641113281,
  },
  {
    x: 5085.9375,
    y: 0.5685577392578125,
  },
  {
    x: 5089.84375,
    y: 0.4688911437988281,
  },
  {
    x: 5093.75,
    y: 0.3692207336425781,
  },
  {
    x: 5097.65625,
    y: 0.2745361328125,
  },
  {
    x: 5101.5625,
    y: 0.17486572265625,
  },
  {
    x: 5105.46875,
    y: 0.07020950317382812,
  },
  {
    x: 5109.375,
    y: -0.004543304443359375,
  },
  {
    x: 5113.28125,
    y: -0.034442901611328125,
  },
  {
    x: 5117.1875,
    y: -0.0394287109375,
  },
  {
    x: 5121.09375,
    y: -0.04441070556640625,
  },
  {
    x: 5125,
    y: -0.04441070556640625,
  },
  {
    x: 5128.90625,
    y: -0.0394287109375,
  },
  {
    x: 5132.8125,
    y: -0.0394287109375,
  },
  {
    x: 5136.71875,
    y: -0.0394287109375,
  },
  {
    x: 5140.625,
    y: -0.034442901611328125,
  },
  {
    x: 5144.53125,
    y: -0.034442901611328125,
  },
  {
    x: 5148.4375,
    y: -0.029460906982421875,
  },
  {
    x: 5152.34375,
    y: -0.029460906982421875,
  },
  {
    x: 5156.25,
    y: -0.029460906982421875,
  },
  {
    x: 5160.15625,
    y: -0.024478912353515625,
  },
  {
    x: 5164.0625,
    y: -0.024478912353515625,
  },
  {
    x: 5167.96875,
    y: -0.024478912353515625,
  },
  {
    x: 5171.875,
    y: -0.01949310302734375,
  },
  {
    x: 5175.78125,
    y: -0.01949310302734375,
  },
  {
    x: 5179.6875,
    y: -0.0145111083984375,
  },
  {
    x: 5183.59375,
    y: -0.0145111083984375,
  },
  {
    x: 5187.5,
    y: -0.0145111083984375,
  },
  {
    x: 5191.40625,
    y: -0.00952911376953125,
  },
  {
    x: 5195.3125,
    y: -0.00952911376953125,
  },
  {
    x: 5199.21875,
    y: -0.00952911376953125,
  },
  {
    x: 5203.125,
    y: -0.004543304443359375,
  },
  {
    x: 5207.03125,
    y: -0.004543304443359375,
  },
  {
    x: 5210.9375,
    y: -0.004543304443359375,
  },
  {
    x: 5214.84375,
    y: 0.0004425048828125,
  },
  {
    x: 5218.75,
    y: 0.0004425048828125,
  },
  {
    x: 5226.5625,
    y: 0.0004425048828125,
  },
  {
    x: 5230.46875,
    y: 0.00542449951171875,
  },
  {
    x: 5234.375,
    y: 0.00542449951171875,
  },
  {
    x: 5242.1875,
    y: 0.00542449951171875,
  },
  {
    x: 5246.09375,
    y: 0.010406494140625,
  },
  {
    x: 5250,
    y: 0.010406494140625,
  },
  {
    x: 5257.8125,
    y: 0.010406494140625,
  },
  {
    x: 5261.71875,
    y: 0.015392303466796875,
  },
  {
    x: 5265.625,
    y: 0.015392303466796875,
  },
  {
    x: 5273.4375,
    y: 0.015392303466796875,
  },
  {
    x: 5277.34375,
    y: 0.020374298095703125,
  },
  {
    x: 5281.25,
    y: 0.020374298095703125,
  },
  {
    x: 5289.0625,
    y: 0.020374298095703125,
  },
  {
    x: 5292.96875,
    y: 0.025356292724609375,
  },
  {
    x: 5296.875,
    y: 0.025356292724609375,
  },
  {
    x: 5304.6875,
    y: 0.025356292724609375,
  },
  {
    x: 5308.59375,
    y: 0.03034210205078125,
  },
  {
    x: 5312.5,
    y: 0.03034210205078125,
  },
  {
    x: 5320.3125,
    y: 0.03034210205078125,
  },
  {
    x: 5324.21875,
    y: 0.040309906005859375,
  },
  {
    x: 5328.125,
    y: 0.07020950317382812,
  },
  {
    x: 5332.03125,
    y: 0.10509490966796875,
  },
  {
    x: 5335.9375,
    y: 0.125030517578125,
  },
  {
    x: 5339.84375,
    y: 0.125030517578125,
  },
  {
    x: 5343.75,
    y: 0.12004470825195312,
  },
  {
    x: 5347.65625,
    y: 0.110076904296875,
  },
  {
    x: 5351.5625,
    y: 0.10010910034179688,
  },
  {
    x: 5355.46875,
    y: 0.09014511108398438,
  },
  {
    x: 5359.375,
    y: 0.08017730712890625,
  },
  {
    x: 5367.1875,
    y: 0.06024169921875,
  },
  {
    x: 5371.09375,
    y: 0.05525970458984375,
  },
  {
    x: 5375,
    y: 0.050273895263671875,
  },
  {
    x: 5378.90625,
    y: 0.040309906005859375,
  },
  {
    x: 5382.8125,
    y: 0.03034210205078125,
  },
  {
    x: 5386.71875,
    y: 0.020374298095703125,
  },
  {
    x: 5390.625,
    y: 0.015392303466796875,
  },
  {
    x: 5394.53125,
    y: 0.00542449951171875,
  },
  {
    x: 5402.34375,
    y: -0.0145111083984375,
  },
  {
    x: 5406.25,
    y: -0.00952911376953125,
  },
  {
    x: 5410.15625,
    y: 0.0004425048828125,
  },
  {
    x: 5414.0625,
    y: 0.010406494140625,
  },
  {
    x: 5417.96875,
    y: 0.020374298095703125,
  },
  {
    x: 5425.78125,
    y: 0.040309906005859375,
  },
  {
    x: 5429.6875,
    y: 0.05525970458984375,
  },
  {
    x: 5433.59375,
    y: 0.06522369384765625,
  },
  {
    x: 5437.5,
    y: 0.07020950317382812,
  },
  {
    x: 5441.40625,
    y: 0.08017730712890625,
  },
  {
    x: 5445.3125,
    y: 0.09014511108398438,
  },
  {
    x: 5449.21875,
    y: 0.10010910034179688,
  },
  {
    x: 5453.125,
    y: 0.110076904296875,
  },
  {
    x: 5457.03125,
    y: 0.12004470825195312,
  },
  {
    x: 5460.9375,
    y: 0.13997650146484375,
  },
  {
    x: 5464.84375,
    y: 0.14994430541992188,
  },
  {
    x: 5468.75,
    y: 0.159912109375,
  },
  {
    x: 5472.65625,
    y: 0.16489410400390625,
  },
  {
    x: 5476.5625,
    y: 0.1698760986328125,
  },
  {
    x: 5480.46875,
    y: 0.1698760986328125,
  },
  {
    x: 5488.28125,
    y: 0.1698760986328125,
  },
  {
    x: 5492.1875,
    y: 0.16489410400390625,
  },
  {
    x: 5496.09375,
    y: 0.16489410400390625,
  },
  {
    x: 5500,
    y: 0.15492630004882812,
  },
  {
    x: 5503.90625,
    y: 0.15492630004882812,
  },
  {
    x: 5746.09375,
    y: 0.15492630004882812,
  },
  {
    x: 5750,
    y: 0.16489410400390625,
  },
  {
    x: 5753.90625,
    y: 0.16489410400390625,
  },
  {
    x: 5769.53125,
    y: 0.16489410400390625,
  },
  {
    x: 5773.4375,
    y: 0.159912109375,
  },
  {
    x: 5777.34375,
    y: 0.159912109375,
  },
  {
    x: 5800.78125,
    y: 0.159912109375,
  },
  {
    x: 5804.6875,
    y: 0.15492630004882812,
  },
  {
    x: 5808.59375,
    y: 0.15492630004882812,
  },
  {
    x: 6000,
    y: 0.15492630004882812,
  },
];
export const V3 = [
  {
    x: 0,
    y: -0.0643463134765625,
  },
  {
    x: 3.90625,
    y: -0.049396514892578125,
  },
  {
    x: 7.8125,
    y: -0.029460906982421875,
  },
  {
    x: 11.71875,
    y: -0.0145111083984375,
  },
  {
    x: 15.625,
    y: 0.00542449951171875,
  },
  {
    x: 19.53125,
    y: 0.020374298095703125,
  },
  {
    x: 23.4375,
    y: 0.040309906005859375,
  },
  {
    x: 27.34375,
    y: 0.05525970458984375,
  },
  {
    x: 35.15625,
    y: 0.0851593017578125,
  },
  {
    x: 39.0625,
    y: 0.09014511108398438,
  },
  {
    x: 42.96875,
    y: 0.09014511108398438,
  },
  {
    x: 58.59375,
    y: 0.09014511108398438,
  },
  {
    x: 62.5,
    y: 0.09512710571289062,
  },
  {
    x: 66.40625,
    y: 0.09512710571289062,
  },
  {
    x: 566.40625,
    y: 0.09512710571289062,
  },
  {
    x: 570.3125,
    y: 0.1349945068359375,
  },
  {
    x: 574.21875,
    y: 0.2346649169921875,
  },
  {
    x: 578.125,
    y: 0.35427093505859375,
  },
  {
    x: 582.03125,
    y: 0.4838409423828125,
  },
  {
    x: 585.9375,
    y: 0.6134147644042969,
  },
  {
    x: 589.84375,
    y: 0.7479705810546875,
  },
  {
    x: 593.75,
    y: 0.8775367736816406,
  },
  {
    x: 597.65625,
    y: 1.0021286010742188,
  },
  {
    x: 601.5625,
    y: 1.1267127990722656,
  },
  {
    x: 605.46875,
    y: 1.2513046264648438,
  },
  {
    x: 609.375,
    y: 1.3111076354980469,
  },
  {
    x: 613.28125,
    y: 1.2463188171386719,
  },
  {
    x: 617.1875,
    y: 1.1317024230957031,
  },
  {
    x: 621.09375,
    y: 1.0021286010742188,
  },
  {
    x: 625,
    y: 0.8625869750976562,
  },
  {
    x: 628.90625,
    y: 0.7280311584472656,
  },
  {
    x: 632.8125,
    y: 0.5884971618652344,
  },
  {
    x: 636.71875,
    y: 0.45394134521484375,
  },
  {
    x: 640.625,
    y: 0.3243675231933594,
  },
  {
    x: 644.53125,
    y: 0.18981552124023438,
  },
  {
    x: 648.4375,
    y: 0.08017730712890625,
  },
  {
    x: 652.34375,
    y: 0.040309906005859375,
  },
  {
    x: 656.25,
    y: 0.03034210205078125,
  },
  {
    x: 660.15625,
    y: 0.025356292724609375,
  },
  {
    x: 664.0625,
    y: 0.025356292724609375,
  },
  {
    x: 675.78125,
    y: 0.025356292724609375,
  },
  {
    x: 679.6875,
    y: 0.03034210205078125,
  },
  {
    x: 683.59375,
    y: 0.03034210205078125,
  },
  {
    x: 691.40625,
    y: 0.03034210205078125,
  },
  {
    x: 695.3125,
    y: 0.0353240966796875,
  },
  {
    x: 699.21875,
    y: 0.0353240966796875,
  },
  {
    x: 707.03125,
    y: 0.0353240966796875,
  },
  {
    x: 710.9375,
    y: 0.040309906005859375,
  },
  {
    x: 714.84375,
    y: 0.040309906005859375,
  },
  {
    x: 722.65625,
    y: 0.040309906005859375,
  },
  {
    x: 726.5625,
    y: 0.045291900634765625,
  },
  {
    x: 730.46875,
    y: 0.045291900634765625,
  },
  {
    x: 746.09375,
    y: 0.045291900634765625,
  },
  {
    x: 750,
    y: 0.050273895263671875,
  },
  {
    x: 753.90625,
    y: 0.050273895263671875,
  },
  {
    x: 777.34375,
    y: 0.050273895263671875,
  },
  {
    x: 781.25,
    y: 0.05525970458984375,
  },
  {
    x: 785.15625,
    y: 0.05525970458984375,
  },
  {
    x: 808.59375,
    y: 0.05525970458984375,
  },
  {
    x: 812.5,
    y: 0.06024169921875,
  },
  {
    x: 816.40625,
    y: 0.06024169921875,
  },
  {
    x: 839.84375,
    y: 0.06024169921875,
  },
  {
    x: 843.75,
    y: 0.06522369384765625,
  },
  {
    x: 847.65625,
    y: 0.06522369384765625,
  },
  {
    x: 859.375,
    y: 0.06522369384765625,
  },
  {
    x: 863.28125,
    y: 0.06024169921875,
  },
  {
    x: 867.1875,
    y: 0.040309906005859375,
  },
  {
    x: 871.09375,
    y: 0.015392303466796875,
  },
  {
    x: 875,
    y: -0.004543304443359375,
  },
  {
    x: 878.90625,
    y: -0.01949310302734375,
  },
  {
    x: 882.8125,
    y: -0.029460906982421875,
  },
  {
    x: 886.71875,
    y: -0.04441070556640625,
  },
  {
    x: 890.625,
    y: -0.05936431884765625,
  },
  {
    x: 894.53125,
    y: -0.074310302734375,
  },
  {
    x: 898.4375,
    y: -0.08427810668945312,
  },
  {
    x: 902.34375,
    y: -0.09923171997070312,
  },
  {
    x: 906.25,
    y: -0.10919570922851562,
  },
  {
    x: 910.15625,
    y: -0.1241455078125,
  },
  {
    x: 914.0625,
    y: -0.13411712646484375,
  },
  {
    x: 917.96875,
    y: -0.14906692504882812,
  },
  {
    x: 921.875,
    y: -0.15903091430664062,
  },
  {
    x: 925.78125,
    y: -0.17398452758789062,
  },
  {
    x: 929.6875,
    y: -0.18394851684570312,
  },
  {
    x: 933.59375,
    y: -0.19391632080078125,
  },
  {
    x: 937.5,
    y: -0.20886993408203125,
  },
  {
    x: 941.40625,
    y: -0.21883392333984375,
  },
  {
    x: 945.3125,
    y: -0.20886993408203125,
  },
  {
    x: 949.21875,
    y: -0.19391632080078125,
  },
  {
    x: 953.125,
    y: -0.17896652221679688,
  },
  {
    x: 957.03125,
    y: -0.15903091430664062,
  },
  {
    x: 960.9375,
    y: -0.13909912109375,
  },
  {
    x: 964.84375,
    y: -0.11916351318359375,
  },
  {
    x: 968.75,
    y: -0.10421371459960938,
  },
  {
    x: 972.65625,
    y: -0.08427810668945312,
  },
  {
    x: 976.5625,
    y: -0.0643463134765625,
  },
  {
    x: 980.46875,
    y: -0.049396514892578125,
  },
  {
    x: 984.375,
    y: -0.029460906982421875,
  },
  {
    x: 988.28125,
    y: -0.0145111083984375,
  },
  {
    x: 992.1875,
    y: 0.00542449951171875,
  },
  {
    x: 996.09375,
    y: 0.020374298095703125,
  },
  {
    x: 1000,
    y: 0.040309906005859375,
  },
  {
    x: 1003.90625,
    y: 0.05525970458984375,
  },
  {
    x: 1011.71875,
    y: 0.0851593017578125,
  },
  {
    x: 1015.625,
    y: 0.09014511108398438,
  },
  {
    x: 1019.53125,
    y: 0.09014511108398438,
  },
  {
    x: 1035.15625,
    y: 0.09014511108398438,
  },
  {
    x: 1039.0625,
    y: 0.09512710571289062,
  },
  {
    x: 1042.96875,
    y: 0.09512710571289062,
  },
  {
    x: 1542.96875,
    y: 0.09512710571289062,
  },
  {
    x: 1546.875,
    y: 0.1349945068359375,
  },
  {
    x: 1550.78125,
    y: 0.2346649169921875,
  },
  {
    x: 1554.6875,
    y: 0.35427093505859375,
  },
  {
    x: 1558.59375,
    y: 0.4838409423828125,
  },
  {
    x: 1562.5,
    y: 0.6134147644042969,
  },
  {
    x: 1566.40625,
    y: 0.7479705810546875,
  },
  {
    x: 1570.3125,
    y: 0.8775367736816406,
  },
  {
    x: 1574.21875,
    y: 1.0021286010742188,
  },
  {
    x: 1578.125,
    y: 1.1267127990722656,
  },
  {
    x: 1582.03125,
    y: 1.2513046264648438,
  },
  {
    x: 1585.9375,
    y: 1.3111076354980469,
  },
  {
    x: 1589.84375,
    y: 1.2463188171386719,
  },
  {
    x: 1593.75,
    y: 1.1317024230957031,
  },
  {
    x: 1597.65625,
    y: 1.0021286010742188,
  },
  {
    x: 1601.5625,
    y: 0.8625869750976562,
  },
  {
    x: 1605.46875,
    y: 0.7280311584472656,
  },
  {
    x: 1609.375,
    y: 0.5884971618652344,
  },
  {
    x: 1613.28125,
    y: 0.45394134521484375,
  },
  {
    x: 1617.1875,
    y: 0.3243675231933594,
  },
  {
    x: 1621.09375,
    y: 0.18981552124023438,
  },
  {
    x: 1625,
    y: 0.08017730712890625,
  },
  {
    x: 1628.90625,
    y: 0.040309906005859375,
  },
  {
    x: 1632.8125,
    y: 0.03034210205078125,
  },
  {
    x: 1636.71875,
    y: 0.025356292724609375,
  },
  {
    x: 1640.625,
    y: 0.025356292724609375,
  },
  {
    x: 1652.34375,
    y: 0.025356292724609375,
  },
  {
    x: 1656.25,
    y: 0.03034210205078125,
  },
  {
    x: 1660.15625,
    y: 0.03034210205078125,
  },
  {
    x: 1667.96875,
    y: 0.03034210205078125,
  },
  {
    x: 1671.875,
    y: 0.0353240966796875,
  },
  {
    x: 1675.78125,
    y: 0.0353240966796875,
  },
  {
    x: 1683.59375,
    y: 0.0353240966796875,
  },
  {
    x: 1687.5,
    y: 0.040309906005859375,
  },
  {
    x: 1691.40625,
    y: 0.040309906005859375,
  },
  {
    x: 1699.21875,
    y: 0.040309906005859375,
  },
  {
    x: 1703.125,
    y: 0.045291900634765625,
  },
  {
    x: 1707.03125,
    y: 0.045291900634765625,
  },
  {
    x: 1722.65625,
    y: 0.045291900634765625,
  },
  {
    x: 1726.5625,
    y: 0.050273895263671875,
  },
  {
    x: 1730.46875,
    y: 0.050273895263671875,
  },
  {
    x: 1753.90625,
    y: 0.050273895263671875,
  },
  {
    x: 1757.8125,
    y: 0.05525970458984375,
  },
  {
    x: 1761.71875,
    y: 0.05525970458984375,
  },
  {
    x: 1785.15625,
    y: 0.05525970458984375,
  },
  {
    x: 1789.0625,
    y: 0.06024169921875,
  },
  {
    x: 1792.96875,
    y: 0.06024169921875,
  },
  {
    x: 1816.40625,
    y: 0.06024169921875,
  },
  {
    x: 1820.3125,
    y: 0.06522369384765625,
  },
  {
    x: 1824.21875,
    y: 0.06522369384765625,
  },
  {
    x: 1835.9375,
    y: 0.06522369384765625,
  },
  {
    x: 1839.84375,
    y: 0.06024169921875,
  },
  {
    x: 1843.75,
    y: 0.040309906005859375,
  },
  {
    x: 1847.65625,
    y: 0.015392303466796875,
  },
  {
    x: 1851.5625,
    y: -0.004543304443359375,
  },
  {
    x: 1855.46875,
    y: -0.01949310302734375,
  },
  {
    x: 1859.375,
    y: -0.029460906982421875,
  },
  {
    x: 1863.28125,
    y: -0.04441070556640625,
  },
  {
    x: 1867.1875,
    y: -0.05936431884765625,
  },
  {
    x: 1871.09375,
    y: -0.074310302734375,
  },
  {
    x: 1875,
    y: -0.08427810668945312,
  },
  {
    x: 1878.90625,
    y: -0.09923171997070312,
  },
  {
    x: 1882.8125,
    y: -0.10919570922851562,
  },
  {
    x: 1886.71875,
    y: -0.1241455078125,
  },
  {
    x: 1890.625,
    y: -0.13411712646484375,
  },
  {
    x: 1894.53125,
    y: -0.14906692504882812,
  },
  {
    x: 1898.4375,
    y: -0.15903091430664062,
  },
  {
    x: 1902.34375,
    y: -0.17398452758789062,
  },
  {
    x: 1906.25,
    y: -0.18394851684570312,
  },
  {
    x: 1910.15625,
    y: -0.19391632080078125,
  },
  {
    x: 1914.0625,
    y: -0.20886993408203125,
  },
  {
    x: 1917.96875,
    y: -0.2138519287109375,
  },
  {
    x: 1921.875,
    y: -0.20886993408203125,
  },
  {
    x: 1925.78125,
    y: -0.19391632080078125,
  },
  {
    x: 1929.6875,
    y: -0.17896652221679688,
  },
  {
    x: 1933.59375,
    y: -0.15903091430664062,
  },
  {
    x: 1937.5,
    y: -0.13909912109375,
  },
  {
    x: 1941.40625,
    y: -0.11916351318359375,
  },
  {
    x: 1945.3125,
    y: -0.10421371459960938,
  },
  {
    x: 1949.21875,
    y: -0.08427810668945312,
  },
  {
    x: 1953.125,
    y: -0.0643463134765625,
  },
  {
    x: 1957.03125,
    y: -0.049396514892578125,
  },
  {
    x: 1960.9375,
    y: -0.029460906982421875,
  },
  {
    x: 1964.84375,
    y: -0.0145111083984375,
  },
  {
    x: 1968.75,
    y: 0.00542449951171875,
  },
  {
    x: 1972.65625,
    y: 0.020374298095703125,
  },
  {
    x: 1976.5625,
    y: 0.040309906005859375,
  },
  {
    x: 1980.46875,
    y: 0.05525970458984375,
  },
  {
    x: 1988.28125,
    y: 0.0851593017578125,
  },
  {
    x: 1992.1875,
    y: 0.09014511108398438,
  },
  {
    x: 1996.09375,
    y: 0.09014511108398438,
  },
  {
    x: 2011.71875,
    y: 0.09014511108398438,
  },
  {
    x: 2015.625,
    y: 0.09512710571289062,
  },
  {
    x: 2019.53125,
    y: 0.09512710571289062,
  },
  {
    x: 2519.53125,
    y: 0.09512710571289062,
  },
  {
    x: 2523.4375,
    y: 0.1349945068359375,
  },
  {
    x: 2527.34375,
    y: 0.2346649169921875,
  },
  {
    x: 2531.25,
    y: 0.35427093505859375,
  },
  {
    x: 2535.15625,
    y: 0.4838409423828125,
  },
  {
    x: 2539.0625,
    y: 0.6134147644042969,
  },
  {
    x: 2542.96875,
    y: 0.7479705810546875,
  },
  {
    x: 2546.875,
    y: 0.8775367736816406,
  },
  {
    x: 2550.78125,
    y: 1.0021286010742188,
  },
  {
    x: 2554.6875,
    y: 1.1267127990722656,
  },
  {
    x: 2558.59375,
    y: 1.2513046264648438,
  },
  {
    x: 2562.5,
    y: 1.3111076354980469,
  },
  {
    x: 2566.40625,
    y: 1.2463188171386719,
  },
  {
    x: 2570.3125,
    y: 1.1317024230957031,
  },
  {
    x: 2574.21875,
    y: 1.0021286010742188,
  },
  {
    x: 2578.125,
    y: 0.8625869750976562,
  },
  {
    x: 2582.03125,
    y: 0.7280311584472656,
  },
  {
    x: 2585.9375,
    y: 0.5884971618652344,
  },
  {
    x: 2589.84375,
    y: 0.45394134521484375,
  },
  {
    x: 2593.75,
    y: 0.3243675231933594,
  },
  {
    x: 2597.65625,
    y: 0.18981552124023438,
  },
  {
    x: 2601.5625,
    y: 0.08017730712890625,
  },
  {
    x: 2605.46875,
    y: 0.040309906005859375,
  },
  {
    x: 2609.375,
    y: 0.03034210205078125,
  },
  {
    x: 2613.28125,
    y: 0.025356292724609375,
  },
  {
    x: 2617.1875,
    y: 0.025356292724609375,
  },
  {
    x: 2628.90625,
    y: 0.025356292724609375,
  },
  {
    x: 2632.8125,
    y: 0.03034210205078125,
  },
  {
    x: 2636.71875,
    y: 0.03034210205078125,
  },
  {
    x: 2644.53125,
    y: 0.03034210205078125,
  },
  {
    x: 2648.4375,
    y: 0.0353240966796875,
  },
  {
    x: 2652.34375,
    y: 0.0353240966796875,
  },
  {
    x: 2660.15625,
    y: 0.0353240966796875,
  },
  {
    x: 2664.0625,
    y: 0.040309906005859375,
  },
  {
    x: 2667.96875,
    y: 0.040309906005859375,
  },
  {
    x: 2675.78125,
    y: 0.040309906005859375,
  },
  {
    x: 2679.6875,
    y: 0.045291900634765625,
  },
  {
    x: 2683.59375,
    y: 0.045291900634765625,
  },
  {
    x: 2699.21875,
    y: 0.045291900634765625,
  },
  {
    x: 2703.125,
    y: 0.050273895263671875,
  },
  {
    x: 2707.03125,
    y: 0.050273895263671875,
  },
  {
    x: 2730.46875,
    y: 0.050273895263671875,
  },
  {
    x: 2734.375,
    y: 0.05525970458984375,
  },
  {
    x: 2738.28125,
    y: 0.05525970458984375,
  },
  {
    x: 2761.71875,
    y: 0.05525970458984375,
  },
  {
    x: 2765.625,
    y: 0.06024169921875,
  },
  {
    x: 2769.53125,
    y: 0.06024169921875,
  },
  {
    x: 2792.96875,
    y: 0.06024169921875,
  },
  {
    x: 2796.875,
    y: 0.06522369384765625,
  },
  {
    x: 2800.78125,
    y: 0.06522369384765625,
  },
  {
    x: 2812.5,
    y: 0.06522369384765625,
  },
  {
    x: 2816.40625,
    y: 0.06024169921875,
  },
  {
    x: 2820.3125,
    y: 0.040309906005859375,
  },
  {
    x: 2824.21875,
    y: 0.015392303466796875,
  },
  {
    x: 2828.125,
    y: -0.004543304443359375,
  },
  {
    x: 2832.03125,
    y: -0.01949310302734375,
  },
  {
    x: 2835.9375,
    y: -0.029460906982421875,
  },
  {
    x: 2839.84375,
    y: -0.04441070556640625,
  },
  {
    x: 2843.75,
    y: -0.05936431884765625,
  },
  {
    x: 2847.65625,
    y: -0.074310302734375,
  },
  {
    x: 2851.5625,
    y: -0.08427810668945312,
  },
  {
    x: 2855.46875,
    y: -0.09923171997070312,
  },
  {
    x: 2859.375,
    y: -0.10919570922851562,
  },
  {
    x: 2863.28125,
    y: -0.1241455078125,
  },
  {
    x: 2867.1875,
    y: -0.13411712646484375,
  },
  {
    x: 2871.09375,
    y: -0.14906692504882812,
  },
  {
    x: 2875,
    y: -0.15903091430664062,
  },
  {
    x: 2878.90625,
    y: -0.17398452758789062,
  },
  {
    x: 2882.8125,
    y: -0.18394851684570312,
  },
  {
    x: 2886.71875,
    y: -0.19391632080078125,
  },
  {
    x: 2890.625,
    y: -0.20886993408203125,
  },
  {
    x: 2894.53125,
    y: -0.21883392333984375,
  },
  {
    x: 2898.4375,
    y: -0.20886993408203125,
  },
  {
    x: 2902.34375,
    y: -0.19391632080078125,
  },
  {
    x: 2906.25,
    y: -0.17896652221679688,
  },
  {
    x: 2910.15625,
    y: -0.15903091430664062,
  },
  {
    x: 2914.0625,
    y: -0.13909912109375,
  },
  {
    x: 2917.96875,
    y: -0.11916351318359375,
  },
  {
    x: 2921.875,
    y: -0.10421371459960938,
  },
  {
    x: 2925.78125,
    y: -0.08427810668945312,
  },
  {
    x: 2929.6875,
    y: -0.0643463134765625,
  },
  {
    x: 2933.59375,
    y: -0.049396514892578125,
  },
  {
    x: 2937.5,
    y: -0.029460906982421875,
  },
  {
    x: 2941.40625,
    y: -0.0145111083984375,
  },
  {
    x: 2945.3125,
    y: 0.00542449951171875,
  },
  {
    x: 2949.21875,
    y: 0.020374298095703125,
  },
  {
    x: 2953.125,
    y: 0.040309906005859375,
  },
  {
    x: 2957.03125,
    y: 0.05525970458984375,
  },
  {
    x: 2964.84375,
    y: 0.0851593017578125,
  },
  {
    x: 2968.75,
    y: 0.09014511108398438,
  },
  {
    x: 2972.65625,
    y: 0.09014511108398438,
  },
  {
    x: 2988.28125,
    y: 0.09014511108398438,
  },
  {
    x: 2992.1875,
    y: 0.09512710571289062,
  },
  {
    x: 2996.09375,
    y: 0.09512710571289062,
  },
  {
    x: 3496.09375,
    y: 0.09512710571289062,
  },
  {
    x: 3500,
    y: 0.1349945068359375,
  },
  {
    x: 3503.90625,
    y: 0.2346649169921875,
  },
  {
    x: 3507.8125,
    y: 0.35427093505859375,
  },
  {
    x: 3511.71875,
    y: 0.4838409423828125,
  },
  {
    x: 3515.625,
    y: 0.6134147644042969,
  },
  {
    x: 3519.53125,
    y: 0.7479705810546875,
  },
  {
    x: 3523.4375,
    y: 0.8775367736816406,
  },
  {
    x: 3527.34375,
    y: 1.0021286010742188,
  },
  {
    x: 3531.25,
    y: 1.1267127990722656,
  },
  {
    x: 3535.15625,
    y: 1.2513046264648438,
  },
  {
    x: 3539.0625,
    y: 1.3111076354980469,
  },
  {
    x: 3542.96875,
    y: 1.2463188171386719,
  },
  {
    x: 3546.875,
    y: 1.1317024230957031,
  },
  {
    x: 3550.78125,
    y: 1.0021286010742188,
  },
  {
    x: 3554.6875,
    y: 0.8625869750976562,
  },
  {
    x: 3558.59375,
    y: 0.7280311584472656,
  },
  {
    x: 3562.5,
    y: 0.5884971618652344,
  },
  {
    x: 3566.40625,
    y: 0.45394134521484375,
  },
  {
    x: 3570.3125,
    y: 0.3243675231933594,
  },
  {
    x: 3574.21875,
    y: 0.18981552124023438,
  },
  {
    x: 3578.125,
    y: 0.08017730712890625,
  },
  {
    x: 3582.03125,
    y: 0.040309906005859375,
  },
  {
    x: 3585.9375,
    y: 0.03034210205078125,
  },
  {
    x: 3589.84375,
    y: 0.025356292724609375,
  },
  {
    x: 3593.75,
    y: 0.025356292724609375,
  },
  {
    x: 3605.46875,
    y: 0.025356292724609375,
  },
  {
    x: 3609.375,
    y: 0.03034210205078125,
  },
  {
    x: 3613.28125,
    y: 0.03034210205078125,
  },
  {
    x: 3621.09375,
    y: 0.03034210205078125,
  },
  {
    x: 3625,
    y: 0.0353240966796875,
  },
  {
    x: 3628.90625,
    y: 0.0353240966796875,
  },
  {
    x: 3636.71875,
    y: 0.0353240966796875,
  },
  {
    x: 3640.625,
    y: 0.040309906005859375,
  },
  {
    x: 3644.53125,
    y: 0.040309906005859375,
  },
  {
    x: 3652.34375,
    y: 0.040309906005859375,
  },
  {
    x: 3656.25,
    y: 0.045291900634765625,
  },
  {
    x: 3660.15625,
    y: 0.045291900634765625,
  },
  {
    x: 3675.78125,
    y: 0.045291900634765625,
  },
  {
    x: 3679.6875,
    y: 0.050273895263671875,
  },
  {
    x: 3683.59375,
    y: 0.050273895263671875,
  },
  {
    x: 3707.03125,
    y: 0.050273895263671875,
  },
  {
    x: 3710.9375,
    y: 0.05525970458984375,
  },
  {
    x: 3714.84375,
    y: 0.05525970458984375,
  },
  {
    x: 3738.28125,
    y: 0.05525970458984375,
  },
  {
    x: 3742.1875,
    y: 0.06024169921875,
  },
  {
    x: 3746.09375,
    y: 0.06024169921875,
  },
  {
    x: 3769.53125,
    y: 0.06024169921875,
  },
  {
    x: 3773.4375,
    y: 0.06522369384765625,
  },
  {
    x: 3777.34375,
    y: 0.06522369384765625,
  },
  {
    x: 3789.0625,
    y: 0.06522369384765625,
  },
  {
    x: 3792.96875,
    y: 0.06024169921875,
  },
  {
    x: 3796.875,
    y: 0.040309906005859375,
  },
  {
    x: 3800.78125,
    y: 0.015392303466796875,
  },
  {
    x: 3804.6875,
    y: -0.004543304443359375,
  },
  {
    x: 3808.59375,
    y: -0.01949310302734375,
  },
  {
    x: 3812.5,
    y: -0.029460906982421875,
  },
  {
    x: 3816.40625,
    y: -0.04441070556640625,
  },
  {
    x: 3820.3125,
    y: -0.05936431884765625,
  },
  {
    x: 3824.21875,
    y: -0.074310302734375,
  },
  {
    x: 3828.125,
    y: -0.08427810668945312,
  },
  {
    x: 3832.03125,
    y: -0.09923171997070312,
  },
  {
    x: 3835.9375,
    y: -0.10919570922851562,
  },
  {
    x: 3839.84375,
    y: -0.1241455078125,
  },
  {
    x: 3843.75,
    y: -0.13411712646484375,
  },
  {
    x: 3847.65625,
    y: -0.14906692504882812,
  },
  {
    x: 3851.5625,
    y: -0.15903091430664062,
  },
  {
    x: 3855.46875,
    y: -0.17398452758789062,
  },
  {
    x: 3859.375,
    y: -0.18394851684570312,
  },
  {
    x: 3863.28125,
    y: -0.1988983154296875,
  },
  {
    x: 3867.1875,
    y: -0.20886993408203125,
  },
  {
    x: 3871.09375,
    y: -0.21883392333984375,
  },
  {
    x: 3875,
    y: -0.20886993408203125,
  },
  {
    x: 3878.90625,
    y: -0.19391632080078125,
  },
  {
    x: 3882.8125,
    y: -0.17896652221679688,
  },
  {
    x: 3886.71875,
    y: -0.15903091430664062,
  },
  {
    x: 3890.625,
    y: -0.13909912109375,
  },
  {
    x: 3894.53125,
    y: -0.11916351318359375,
  },
  {
    x: 3898.4375,
    y: -0.10421371459960938,
  },
  {
    x: 3902.34375,
    y: -0.08427810668945312,
  },
  {
    x: 3906.25,
    y: -0.0643463134765625,
  },
  {
    x: 3910.15625,
    y: -0.049396514892578125,
  },
  {
    x: 3914.0625,
    y: -0.029460906982421875,
  },
  {
    x: 3917.96875,
    y: -0.0145111083984375,
  },
  {
    x: 3921.875,
    y: 0.00542449951171875,
  },
  {
    x: 3925.78125,
    y: 0.020374298095703125,
  },
  {
    x: 3929.6875,
    y: 0.040309906005859375,
  },
  {
    x: 3933.59375,
    y: 0.05525970458984375,
  },
  {
    x: 3941.40625,
    y: 0.0851593017578125,
  },
  {
    x: 3945.3125,
    y: 0.09014511108398438,
  },
  {
    x: 3949.21875,
    y: 0.09014511108398438,
  },
  {
    x: 3964.84375,
    y: 0.09014511108398438,
  },
  {
    x: 3968.75,
    y: 0.09512710571289062,
  },
  {
    x: 3972.65625,
    y: 0.09512710571289062,
  },
  {
    x: 4472.65625,
    y: 0.09512710571289062,
  },
  {
    x: 4476.5625,
    y: 0.1349945068359375,
  },
  {
    x: 4480.46875,
    y: 0.2346649169921875,
  },
  {
    x: 4484.375,
    y: 0.35427093505859375,
  },
  {
    x: 4488.28125,
    y: 0.4838409423828125,
  },
  {
    x: 4492.1875,
    y: 0.6134147644042969,
  },
  {
    x: 4496.09375,
    y: 0.7479705810546875,
  },
  {
    x: 4500,
    y: 0.8775367736816406,
  },
  {
    x: 4503.90625,
    y: 1.0021286010742188,
  },
  {
    x: 4507.8125,
    y: 1.1267127990722656,
  },
  {
    x: 4511.71875,
    y: 1.2513046264648438,
  },
  {
    x: 4515.625,
    y: 1.3111076354980469,
  },
  {
    x: 4519.53125,
    y: 1.2463188171386719,
  },
  {
    x: 4523.4375,
    y: 1.1317024230957031,
  },
  {
    x: 4527.34375,
    y: 1.0021286010742188,
  },
  {
    x: 4531.25,
    y: 0.8625869750976562,
  },
  {
    x: 4535.15625,
    y: 0.7280311584472656,
  },
  {
    x: 4539.0625,
    y: 0.5884971618652344,
  },
  {
    x: 4542.96875,
    y: 0.45394134521484375,
  },
  {
    x: 4546.875,
    y: 0.3243675231933594,
  },
  {
    x: 4550.78125,
    y: 0.18981552124023438,
  },
  {
    x: 4554.6875,
    y: 0.08017730712890625,
  },
  {
    x: 4558.59375,
    y: 0.040309906005859375,
  },
  {
    x: 4562.5,
    y: 0.03034210205078125,
  },
  {
    x: 4566.40625,
    y: 0.025356292724609375,
  },
  {
    x: 4570.3125,
    y: 0.025356292724609375,
  },
  {
    x: 4582.03125,
    y: 0.025356292724609375,
  },
  {
    x: 4585.9375,
    y: 0.03034210205078125,
  },
  {
    x: 4589.84375,
    y: 0.03034210205078125,
  },
  {
    x: 4597.65625,
    y: 0.03034210205078125,
  },
  {
    x: 4601.5625,
    y: 0.0353240966796875,
  },
  {
    x: 4605.46875,
    y: 0.0353240966796875,
  },
  {
    x: 4613.28125,
    y: 0.0353240966796875,
  },
  {
    x: 4617.1875,
    y: 0.040309906005859375,
  },
  {
    x: 4621.09375,
    y: 0.040309906005859375,
  },
  {
    x: 4628.90625,
    y: 0.040309906005859375,
  },
  {
    x: 4632.8125,
    y: 0.045291900634765625,
  },
  {
    x: 4636.71875,
    y: 0.045291900634765625,
  },
  {
    x: 4652.34375,
    y: 0.045291900634765625,
  },
  {
    x: 4656.25,
    y: 0.050273895263671875,
  },
  {
    x: 4660.15625,
    y: 0.050273895263671875,
  },
  {
    x: 4683.59375,
    y: 0.050273895263671875,
  },
  {
    x: 4687.5,
    y: 0.05525970458984375,
  },
  {
    x: 4691.40625,
    y: 0.05525970458984375,
  },
  {
    x: 4714.84375,
    y: 0.05525970458984375,
  },
  {
    x: 4718.75,
    y: 0.06024169921875,
  },
  {
    x: 4722.65625,
    y: 0.06024169921875,
  },
  {
    x: 4746.09375,
    y: 0.06024169921875,
  },
  {
    x: 4750,
    y: 0.06522369384765625,
  },
  {
    x: 4753.90625,
    y: 0.06522369384765625,
  },
  {
    x: 4765.625,
    y: 0.06522369384765625,
  },
  {
    x: 4769.53125,
    y: 0.06024169921875,
  },
  {
    x: 4773.4375,
    y: 0.040309906005859375,
  },
  {
    x: 4777.34375,
    y: 0.015392303466796875,
  },
  {
    x: 4781.25,
    y: -0.004543304443359375,
  },
  {
    x: 4785.15625,
    y: -0.01949310302734375,
  },
  {
    x: 4789.0625,
    y: -0.029460906982421875,
  },
  {
    x: 4792.96875,
    y: -0.04441070556640625,
  },
  {
    x: 4796.875,
    y: -0.05936431884765625,
  },
  {
    x: 4800.78125,
    y: -0.074310302734375,
  },
  {
    x: 4804.6875,
    y: -0.08427810668945312,
  },
  {
    x: 4808.59375,
    y: -0.09923171997070312,
  },
  {
    x: 4812.5,
    y: -0.10919570922851562,
  },
  {
    x: 4816.40625,
    y: -0.1241455078125,
  },
  {
    x: 4820.3125,
    y: -0.13411712646484375,
  },
  {
    x: 4824.21875,
    y: -0.14906692504882812,
  },
  {
    x: 4828.125,
    y: -0.15903091430664062,
  },
  {
    x: 4832.03125,
    y: -0.17398452758789062,
  },
  {
    x: 4835.9375,
    y: -0.18394851684570312,
  },
  {
    x: 4839.84375,
    y: -0.19391632080078125,
  },
  {
    x: 4843.75,
    y: -0.20886993408203125,
  },
  {
    x: 4847.65625,
    y: -0.21883392333984375,
  },
  {
    x: 4851.5625,
    y: -0.20886993408203125,
  },
  {
    x: 4855.46875,
    y: -0.19391632080078125,
  },
  {
    x: 4859.375,
    y: -0.17896652221679688,
  },
  {
    x: 4863.28125,
    y: -0.15903091430664062,
  },
  {
    x: 4867.1875,
    y: -0.13909912109375,
  },
  {
    x: 4871.09375,
    y: -0.11916351318359375,
  },
  {
    x: 4875,
    y: -0.10421371459960938,
  },
  {
    x: 4878.90625,
    y: -0.08427810668945312,
  },
  {
    x: 4882.8125,
    y: -0.0643463134765625,
  },
  {
    x: 4886.71875,
    y: -0.049396514892578125,
  },
  {
    x: 4890.625,
    y: -0.029460906982421875,
  },
  {
    x: 4894.53125,
    y: -0.0145111083984375,
  },
  {
    x: 4898.4375,
    y: 0.00542449951171875,
  },
  {
    x: 4902.34375,
    y: 0.020374298095703125,
  },
  {
    x: 4906.25,
    y: 0.040309906005859375,
  },
  {
    x: 4910.15625,
    y: 0.05525970458984375,
  },
  {
    x: 4917.96875,
    y: 0.0851593017578125,
  },
  {
    x: 4921.875,
    y: 0.09014511108398438,
  },
  {
    x: 4925.78125,
    y: 0.09014511108398438,
  },
  {
    x: 4941.40625,
    y: 0.09014511108398438,
  },
  {
    x: 4945.3125,
    y: 0.09512710571289062,
  },
  {
    x: 4949.21875,
    y: 0.09512710571289062,
  },
  {
    x: 5449.21875,
    y: 0.09512710571289062,
  },
  {
    x: 5453.125,
    y: 0.1349945068359375,
  },
  {
    x: 5457.03125,
    y: 0.2346649169921875,
  },
  {
    x: 5460.9375,
    y: 0.35427093505859375,
  },
  {
    x: 5464.84375,
    y: 0.4838409423828125,
  },
  {
    x: 5468.75,
    y: 0.6134147644042969,
  },
  {
    x: 5472.65625,
    y: 0.7479705810546875,
  },
  {
    x: 5476.5625,
    y: 0.8775367736816406,
  },
  {
    x: 5480.46875,
    y: 1.0021286010742188,
  },
  {
    x: 5484.375,
    y: 1.1267127990722656,
  },
  {
    x: 5488.28125,
    y: 1.2513046264648438,
  },
  {
    x: 5492.1875,
    y: 1.3111076354980469,
  },
  {
    x: 5496.09375,
    y: 1.2463188171386719,
  },
  {
    x: 5500,
    y: 1.1317024230957031,
  },
  {
    x: 5503.90625,
    y: 1.0021286010742188,
  },
  {
    x: 5507.8125,
    y: 0.8625869750976562,
  },
  {
    x: 5511.71875,
    y: 0.7280311584472656,
  },
  {
    x: 5515.625,
    y: 0.5884971618652344,
  },
  {
    x: 5519.53125,
    y: 0.45394134521484375,
  },
  {
    x: 5523.4375,
    y: 0.3243675231933594,
  },
  {
    x: 5527.34375,
    y: 0.18981552124023438,
  },
  {
    x: 5531.25,
    y: 0.08017730712890625,
  },
  {
    x: 5535.15625,
    y: 0.040309906005859375,
  },
  {
    x: 5539.0625,
    y: 0.03034210205078125,
  },
  {
    x: 5542.96875,
    y: 0.025356292724609375,
  },
  {
    x: 5546.875,
    y: 0.025356292724609375,
  },
  {
    x: 5558.59375,
    y: 0.025356292724609375,
  },
  {
    x: 5562.5,
    y: 0.03034210205078125,
  },
  {
    x: 5566.40625,
    y: 0.03034210205078125,
  },
  {
    x: 5574.21875,
    y: 0.03034210205078125,
  },
  {
    x: 5578.125,
    y: 0.0353240966796875,
  },
  {
    x: 5582.03125,
    y: 0.0353240966796875,
  },
  {
    x: 5589.84375,
    y: 0.0353240966796875,
  },
  {
    x: 5593.75,
    y: 0.040309906005859375,
  },
  {
    x: 5597.65625,
    y: 0.040309906005859375,
  },
  {
    x: 5605.46875,
    y: 0.040309906005859375,
  },
  {
    x: 5609.375,
    y: 0.045291900634765625,
  },
  {
    x: 5613.28125,
    y: 0.045291900634765625,
  },
  {
    x: 5628.90625,
    y: 0.045291900634765625,
  },
  {
    x: 5632.8125,
    y: 0.050273895263671875,
  },
  {
    x: 5636.71875,
    y: 0.050273895263671875,
  },
  {
    x: 5660.15625,
    y: 0.050273895263671875,
  },
  {
    x: 5664.0625,
    y: 0.05525970458984375,
  },
  {
    x: 5667.96875,
    y: 0.05525970458984375,
  },
  {
    x: 5691.40625,
    y: 0.05525970458984375,
  },
  {
    x: 5695.3125,
    y: 0.06024169921875,
  },
  {
    x: 5699.21875,
    y: 0.06024169921875,
  },
  {
    x: 5722.65625,
    y: 0.06024169921875,
  },
  {
    x: 5726.5625,
    y: 0.06522369384765625,
  },
  {
    x: 5730.46875,
    y: 0.06522369384765625,
  },
  {
    x: 5742.1875,
    y: 0.06522369384765625,
  },
  {
    x: 5746.09375,
    y: 0.06024169921875,
  },
  {
    x: 5750,
    y: 0.09512710571289062,
  },
  {
    x: 5753.90625,
    y: 0.09512710571289062,
  },
  {
    x: 6000,
    y: 0.09512710571289062,
  },
];
export const V4 = [
  {
    x: 0,
    y: 0.050273895263671875,
  },
  {
    x: 27.34375,
    y: 0.050273895263671875,
  },
  {
    x: 31.25,
    y: 0.05525970458984375,
  },
  {
    x: 35.15625,
    y: 0.05525970458984375,
  },
  {
    x: 58.59375,
    y: 0.05525970458984375,
  },
  {
    x: 62.5,
    y: 0.06024169921875,
  },
  {
    x: 66.40625,
    y: 0.06024169921875,
  },
  {
    x: 89.84375,
    y: 0.06024169921875,
  },
  {
    x: 93.75,
    y: 0.06522369384765625,
  },
  {
    x: 97.65625,
    y: 0.06522369384765625,
  },
  {
    x: 121.09375,
    y: 0.06522369384765625,
  },
  {
    x: 125,
    y: 0.07020950317382812,
  },
  {
    x: 128.90625,
    y: 0.07020950317382812,
  },
  {
    x: 152.34375,
    y: 0.07020950317382812,
  },
  {
    x: 156.25,
    y: 0.0751953125,
  },
  {
    x: 160.15625,
    y: 0.0751953125,
  },
  {
    x: 171.875,
    y: 0.0751953125,
  },
  {
    x: 175.78125,
    y: 0.06522369384765625,
  },
  {
    x: 179.6875,
    y: 0.045291900634765625,
  },
  {
    x: 183.59375,
    y: 0.015392303466796875,
  },
  {
    x: 187.5,
    y: 0.0004425048828125,
  },
  {
    x: 191.40625,
    y: -0.0145111083984375,
  },
  {
    x: 195.3125,
    y: -0.029460906982421875,
  },
  {
    x: 199.21875,
    y: -0.04441070556640625,
  },
  {
    x: 203.125,
    y: -0.054378509521484375,
  },
  {
    x: 207.03125,
    y: -0.06932830810546875,
  },
  {
    x: 210.9375,
    y: -0.07929611206054688,
  },
  {
    x: 218.75,
    y: -0.09923171997070312,
  },
  {
    x: 222.65625,
    y: -0.1141815185546875,
  },
  {
    x: 226.5625,
    y: -0.1241455078125,
  },
  {
    x: 230.46875,
    y: -0.13411712646484375,
  },
  {
    x: 234.375,
    y: -0.14906692504882812,
  },
  {
    x: 238.28125,
    y: -0.15903091430664062,
  },
  {
    x: 242.1875,
    y: -0.16899871826171875,
  },
  {
    x: 246.09375,
    y: -0.17896652221679688,
  },
  {
    x: 250,
    y: -0.19391632080078125,
  },
  {
    x: 253.90625,
    y: -0.20388412475585938,
  },
  {
    x: 257.8125,
    y: -0.19391632080078125,
  },
  {
    x: 261.71875,
    y: -0.17896652221679688,
  },
  {
    x: 265.625,
    y: -0.1640167236328125,
  },
  {
    x: 269.53125,
    y: -0.14408111572265625,
  },
  {
    x: 273.4375,
    y: -0.12913131713867188,
  },
  {
    x: 277.34375,
    y: -0.10919570922851562,
  },
  {
    x: 281.25,
    y: -0.09424591064453125,
  },
  {
    x: 285.15625,
    y: -0.074310302734375,
  },
  {
    x: 289.0625,
    y: -0.05936431884765625,
  },
  {
    x: 292.96875,
    y: -0.04441070556640625,
  },
  {
    x: 296.875,
    y: -0.024478912353515625,
  },
  {
    x: 300.78125,
    y: -0.00952911376953125,
  },
  {
    x: 304.6875,
    y: 0.00542449951171875,
  },
  {
    x: 308.59375,
    y: 0.020374298095703125,
  },
  {
    x: 312.5,
    y: 0.040309906005859375,
  },
  {
    x: 316.40625,
    y: 0.05525970458984375,
  },
  {
    x: 320.3125,
    y: 0.07020950317382812,
  },
  {
    x: 324.21875,
    y: 0.08017730712890625,
  },
  {
    x: 328.125,
    y: 0.0851593017578125,
  },
  {
    x: 332.03125,
    y: 0.0851593017578125,
  },
  {
    x: 339.84375,
    y: 0.0851593017578125,
  },
  {
    x: 343.75,
    y: 0.09014511108398438,
  },
  {
    x: 347.65625,
    y: 0.09014511108398438,
  },
  {
    x: 371.09375,
    y: 0.09014511108398438,
  },
  {
    x: 375,
    y: 0.09512710571289062,
  },
  {
    x: 378.90625,
    y: 0.09512710571289062,
  },
  {
    x: 855.46875,
    y: 0.09512710571289062,
  },
  {
    x: 859.375,
    y: 0.13001251220703125,
  },
  {
    x: 863.28125,
    y: 0.22469711303710938,
  },
  {
    x: 867.1875,
    y: 0.3393211364746094,
  },
  {
    x: 871.09375,
    y: 0.45892333984375,
  },
  {
    x: 875,
    y: 0.5785293579101562,
  },
  {
    x: 878.90625,
    y: 0.7031173706054688,
  },
  {
    x: 882.8125,
    y: 0.822723388671875,
  },
  {
    x: 886.71875,
    y: 0.9423255920410156,
  },
  {
    x: 890.625,
    y: 1.0569496154785156,
  },
  {
    x: 894.53125,
    y: 1.1765518188476562,
  },
  {
    x: 898.4375,
    y: 1.2313690185546875,
  },
  {
    x: 902.34375,
    y: 1.1765518188476562,
  },
  {
    x: 906.25,
    y: 1.0669136047363281,
  },
  {
    x: 910.15625,
    y: 0.9423255920410156,
  },
  {
    x: 917.96875,
    y: 0.6931495666503906,
  },
  {
    x: 921.875,
    y: 0.5685577392578125,
  },
  {
    x: 925.78125,
    y: 0.4389915466308594,
  },
  {
    x: 929.6875,
    y: 0.3193855285644531,
  },
  {
    x: 933.59375,
    y: 0.19479751586914062,
  },
  {
    x: 937.5,
    y: 0.09512710571289062,
  },
  {
    x: 941.40625,
    y: 0.06024169921875,
  },
  {
    x: 945.3125,
    y: 0.050273895263671875,
  },
  {
    x: 949.21875,
    y: 0.040309906005859375,
  },
  {
    x: 953.125,
    y: 0.045291900634765625,
  },
  {
    x: 957.03125,
    y: 0.045291900634765625,
  },
  {
    x: 972.65625,
    y: 0.045291900634765625,
  },
  {
    x: 976.5625,
    y: 0.050273895263671875,
  },
  {
    x: 980.46875,
    y: 0.050273895263671875,
  },
  {
    x: 1003.90625,
    y: 0.050273895263671875,
  },
  {
    x: 1007.8125,
    y: 0.05525970458984375,
  },
  {
    x: 1011.71875,
    y: 0.05525970458984375,
  },
  {
    x: 1035.15625,
    y: 0.05525970458984375,
  },
  {
    x: 1039.0625,
    y: 0.06024169921875,
  },
  {
    x: 1042.96875,
    y: 0.06024169921875,
  },
  {
    x: 1066.40625,
    y: 0.06024169921875,
  },
  {
    x: 1070.3125,
    y: 0.06522369384765625,
  },
  {
    x: 1074.21875,
    y: 0.06522369384765625,
  },
  {
    x: 1097.65625,
    y: 0.06522369384765625,
  },
  {
    x: 1101.5625,
    y: 0.07020950317382812,
  },
  {
    x: 1105.46875,
    y: 0.07020950317382812,
  },
  {
    x: 1128.90625,
    y: 0.07020950317382812,
  },
  {
    x: 1132.8125,
    y: 0.0751953125,
  },
  {
    x: 1136.71875,
    y: 0.0751953125,
  },
  {
    x: 1148.4375,
    y: 0.0751953125,
  },
  {
    x: 1152.34375,
    y: 0.06522369384765625,
  },
  {
    x: 1156.25,
    y: 0.045291900634765625,
  },
  {
    x: 1160.15625,
    y: 0.015392303466796875,
  },
  {
    x: 1164.0625,
    y: 0.0004425048828125,
  },
  {
    x: 1167.96875,
    y: -0.0145111083984375,
  },
  {
    x: 1171.875,
    y: -0.029460906982421875,
  },
  {
    x: 1175.78125,
    y: -0.04441070556640625,
  },
  {
    x: 1179.6875,
    y: -0.054378509521484375,
  },
  {
    x: 1183.59375,
    y: -0.06932830810546875,
  },
  {
    x: 1187.5,
    y: -0.07929611206054688,
  },
  {
    x: 1195.3125,
    y: -0.09923171997070312,
  },
  {
    x: 1199.21875,
    y: -0.1141815185546875,
  },
  {
    x: 1203.125,
    y: -0.1241455078125,
  },
  {
    x: 1207.03125,
    y: -0.13411712646484375,
  },
  {
    x: 1210.9375,
    y: -0.14906692504882812,
  },
  {
    x: 1214.84375,
    y: -0.15903091430664062,
  },
  {
    x: 1218.75,
    y: -0.16899871826171875,
  },
  {
    x: 1222.65625,
    y: -0.17896652221679688,
  },
  {
    x: 1226.5625,
    y: -0.19391632080078125,
  },
  {
    x: 1230.46875,
    y: -0.1988983154296875,
  },
  {
    x: 1234.375,
    y: -0.19391632080078125,
  },
  {
    x: 1238.28125,
    y: -0.17896652221679688,
  },
  {
    x: 1242.1875,
    y: -0.1640167236328125,
  },
  {
    x: 1246.09375,
    y: -0.14408111572265625,
  },
  {
    x: 1250,
    y: -0.12913131713867188,
  },
  {
    x: 1253.90625,
    y: -0.10919570922851562,
  },
  {
    x: 1257.8125,
    y: -0.09424591064453125,
  },
  {
    x: 1261.71875,
    y: -0.074310302734375,
  },
  {
    x: 1265.625,
    y: -0.05936431884765625,
  },
  {
    x: 1269.53125,
    y: -0.04441070556640625,
  },
  {
    x: 1273.4375,
    y: -0.024478912353515625,
  },
  {
    x: 1277.34375,
    y: -0.00952911376953125,
  },
  {
    x: 1281.25,
    y: 0.00542449951171875,
  },
  {
    x: 1285.15625,
    y: 0.020374298095703125,
  },
  {
    x: 1289.0625,
    y: 0.040309906005859375,
  },
  {
    x: 1292.96875,
    y: 0.05525970458984375,
  },
  {
    x: 1296.875,
    y: 0.07020950317382812,
  },
  {
    x: 1300.78125,
    y: 0.08017730712890625,
  },
  {
    x: 1304.6875,
    y: 0.0851593017578125,
  },
  {
    x: 1308.59375,
    y: 0.0851593017578125,
  },
  {
    x: 1316.40625,
    y: 0.0851593017578125,
  },
  {
    x: 1320.3125,
    y: 0.09014511108398438,
  },
  {
    x: 1324.21875,
    y: 0.09014511108398438,
  },
  {
    x: 1347.65625,
    y: 0.09014511108398438,
  },
  {
    x: 1351.5625,
    y: 0.09512710571289062,
  },
  {
    x: 1355.46875,
    y: 0.09512710571289062,
  },
  {
    x: 1832.03125,
    y: 0.09512710571289062,
  },
  {
    x: 1835.9375,
    y: 0.13001251220703125,
  },
  {
    x: 1839.84375,
    y: 0.22469711303710938,
  },
  {
    x: 1843.75,
    y: 0.3393211364746094,
  },
  {
    x: 1847.65625,
    y: 0.45892333984375,
  },
  {
    x: 1851.5625,
    y: 0.5785293579101562,
  },
  {
    x: 1855.46875,
    y: 0.7031173706054688,
  },
  {
    x: 1859.375,
    y: 0.822723388671875,
  },
  {
    x: 1863.28125,
    y: 0.9423255920410156,
  },
  {
    x: 1867.1875,
    y: 1.0569496154785156,
  },
  {
    x: 1871.09375,
    y: 1.1765518188476562,
  },
  {
    x: 1875,
    y: 1.2313690185546875,
  },
  {
    x: 1878.90625,
    y: 1.1765518188476562,
  },
  {
    x: 1882.8125,
    y: 1.0669136047363281,
  },
  {
    x: 1886.71875,
    y: 0.9423255920410156,
  },
  {
    x: 1894.53125,
    y: 0.6931495666503906,
  },
  {
    x: 1898.4375,
    y: 0.5685577392578125,
  },
  {
    x: 1902.34375,
    y: 0.4389915466308594,
  },
  {
    x: 1906.25,
    y: 0.3193855285644531,
  },
  {
    x: 1910.15625,
    y: 0.19479751586914062,
  },
  {
    x: 1914.0625,
    y: 0.09512710571289062,
  },
  {
    x: 1917.96875,
    y: 0.06024169921875,
  },
  {
    x: 1921.875,
    y: 0.050273895263671875,
  },
  {
    x: 1925.78125,
    y: 0.040309906005859375,
  },
  {
    x: 1929.6875,
    y: 0.045291900634765625,
  },
  {
    x: 1933.59375,
    y: 0.045291900634765625,
  },
  {
    x: 1949.21875,
    y: 0.045291900634765625,
  },
  {
    x: 1953.125,
    y: 0.050273895263671875,
  },
  {
    x: 1957.03125,
    y: 0.050273895263671875,
  },
  {
    x: 1980.46875,
    y: 0.050273895263671875,
  },
  {
    x: 1984.375,
    y: 0.05525970458984375,
  },
  {
    x: 1988.28125,
    y: 0.05525970458984375,
  },
  {
    x: 2011.71875,
    y: 0.05525970458984375,
  },
  {
    x: 2015.625,
    y: 0.06024169921875,
  },
  {
    x: 2019.53125,
    y: 0.06024169921875,
  },
  {
    x: 2042.96875,
    y: 0.06024169921875,
  },
  {
    x: 2046.875,
    y: 0.06522369384765625,
  },
  {
    x: 2050.78125,
    y: 0.06522369384765625,
  },
  {
    x: 2074.21875,
    y: 0.06522369384765625,
  },
  {
    x: 2078.125,
    y: 0.07020950317382812,
  },
  {
    x: 2082.03125,
    y: 0.07020950317382812,
  },
  {
    x: 2105.46875,
    y: 0.07020950317382812,
  },
  {
    x: 2109.375,
    y: 0.0751953125,
  },
  {
    x: 2113.28125,
    y: 0.0751953125,
  },
  {
    x: 2125,
    y: 0.0751953125,
  },
  {
    x: 2128.90625,
    y: 0.06522369384765625,
  },
  {
    x: 2132.8125,
    y: 0.045291900634765625,
  },
  {
    x: 2136.71875,
    y: 0.015392303466796875,
  },
  {
    x: 2140.625,
    y: 0.0004425048828125,
  },
  {
    x: 2144.53125,
    y: -0.0145111083984375,
  },
  {
    x: 2148.4375,
    y: -0.029460906982421875,
  },
  {
    x: 2152.34375,
    y: -0.04441070556640625,
  },
  {
    x: 2156.25,
    y: -0.054378509521484375,
  },
  {
    x: 2160.15625,
    y: -0.06932830810546875,
  },
  {
    x: 2164.0625,
    y: -0.07929611206054688,
  },
  {
    x: 2171.875,
    y: -0.09923171997070312,
  },
  {
    x: 2175.78125,
    y: -0.1141815185546875,
  },
  {
    x: 2179.6875,
    y: -0.1241455078125,
  },
  {
    x: 2183.59375,
    y: -0.13411712646484375,
  },
  {
    x: 2187.5,
    y: -0.14906692504882812,
  },
  {
    x: 2191.40625,
    y: -0.15903091430664062,
  },
  {
    x: 2195.3125,
    y: -0.16899871826171875,
  },
  {
    x: 2199.21875,
    y: -0.17896652221679688,
  },
  {
    x: 2203.125,
    y: -0.19391632080078125,
  },
  {
    x: 2207.03125,
    y: -0.20388412475585938,
  },
  {
    x: 2210.9375,
    y: -0.19391632080078125,
  },
  {
    x: 2214.84375,
    y: -0.17896652221679688,
  },
  {
    x: 2218.75,
    y: -0.1640167236328125,
  },
  {
    x: 2222.65625,
    y: -0.14408111572265625,
  },
  {
    x: 2226.5625,
    y: -0.12913131713867188,
  },
  {
    x: 2230.46875,
    y: -0.10919570922851562,
  },
  {
    x: 2234.375,
    y: -0.09424591064453125,
  },
  {
    x: 2238.28125,
    y: -0.074310302734375,
  },
  {
    x: 2242.1875,
    y: -0.05936431884765625,
  },
  {
    x: 2246.09375,
    y: -0.04441070556640625,
  },
  {
    x: 2250,
    y: -0.024478912353515625,
  },
  {
    x: 2253.90625,
    y: -0.00952911376953125,
  },
  {
    x: 2257.8125,
    y: 0.00542449951171875,
  },
  {
    x: 2261.71875,
    y: 0.020374298095703125,
  },
  {
    x: 2265.625,
    y: 0.040309906005859375,
  },
  {
    x: 2269.53125,
    y: 0.05525970458984375,
  },
  {
    x: 2273.4375,
    y: 0.07020950317382812,
  },
  {
    x: 2277.34375,
    y: 0.08017730712890625,
  },
  {
    x: 2281.25,
    y: 0.0851593017578125,
  },
  {
    x: 2285.15625,
    y: 0.0851593017578125,
  },
  {
    x: 2292.96875,
    y: 0.0851593017578125,
  },
  {
    x: 2296.875,
    y: 0.09014511108398438,
  },
  {
    x: 2300.78125,
    y: 0.09014511108398438,
  },
  {
    x: 2324.21875,
    y: 0.09014511108398438,
  },
  {
    x: 2328.125,
    y: 0.09512710571289062,
  },
  {
    x: 2332.03125,
    y: 0.09512710571289062,
  },
  {
    x: 2808.59375,
    y: 0.09512710571289062,
  },
  {
    x: 2812.5,
    y: 0.13001251220703125,
  },
  {
    x: 2816.40625,
    y: 0.22469711303710938,
  },
  {
    x: 2820.3125,
    y: 0.3393211364746094,
  },
  {
    x: 2824.21875,
    y: 0.45892333984375,
  },
  {
    x: 2828.125,
    y: 0.5785293579101562,
  },
  {
    x: 2832.03125,
    y: 0.7031173706054688,
  },
  {
    x: 2835.9375,
    y: 0.822723388671875,
  },
  {
    x: 2839.84375,
    y: 0.9423255920410156,
  },
  {
    x: 2843.75,
    y: 1.0569496154785156,
  },
  {
    x: 2847.65625,
    y: 1.1765518188476562,
  },
  {
    x: 2851.5625,
    y: 1.2313690185546875,
  },
  {
    x: 2855.46875,
    y: 1.1765518188476562,
  },
  {
    x: 2859.375,
    y: 1.0669136047363281,
  },
  {
    x: 2863.28125,
    y: 0.9423255920410156,
  },
  {
    x: 2871.09375,
    y: 0.6931495666503906,
  },
  {
    x: 2875,
    y: 0.5685577392578125,
  },
  {
    x: 2878.90625,
    y: 0.4389915466308594,
  },
  {
    x: 2882.8125,
    y: 0.3193855285644531,
  },
  {
    x: 2886.71875,
    y: 0.19479751586914062,
  },
  {
    x: 2890.625,
    y: 0.09512710571289062,
  },
  {
    x: 2894.53125,
    y: 0.06024169921875,
  },
  {
    x: 2898.4375,
    y: 0.050273895263671875,
  },
  {
    x: 2902.34375,
    y: 0.040309906005859375,
  },
  {
    x: 2906.25,
    y: 0.045291900634765625,
  },
  {
    x: 2910.15625,
    y: 0.045291900634765625,
  },
  {
    x: 2925.78125,
    y: 0.045291900634765625,
  },
  {
    x: 2929.6875,
    y: 0.050273895263671875,
  },
  {
    x: 2933.59375,
    y: 0.050273895263671875,
  },
  {
    x: 2957.03125,
    y: 0.050273895263671875,
  },
  {
    x: 2960.9375,
    y: 0.05525970458984375,
  },
  {
    x: 2964.84375,
    y: 0.05525970458984375,
  },
  {
    x: 2988.28125,
    y: 0.05525970458984375,
  },
  {
    x: 2992.1875,
    y: 0.06024169921875,
  },
  {
    x: 2996.09375,
    y: 0.06024169921875,
  },
  {
    x: 3019.53125,
    y: 0.06024169921875,
  },
  {
    x: 3023.4375,
    y: 0.06522369384765625,
  },
  {
    x: 3027.34375,
    y: 0.06522369384765625,
  },
  {
    x: 3050.78125,
    y: 0.06522369384765625,
  },
  {
    x: 3054.6875,
    y: 0.07020950317382812,
  },
  {
    x: 3058.59375,
    y: 0.07020950317382812,
  },
  {
    x: 3082.03125,
    y: 0.07020950317382812,
  },
  {
    x: 3085.9375,
    y: 0.0751953125,
  },
  {
    x: 3089.84375,
    y: 0.0751953125,
  },
  {
    x: 3101.5625,
    y: 0.0751953125,
  },
  {
    x: 3105.46875,
    y: 0.06522369384765625,
  },
  {
    x: 3109.375,
    y: 0.045291900634765625,
  },
  {
    x: 3113.28125,
    y: 0.015392303466796875,
  },
  {
    x: 3117.1875,
    y: 0.0004425048828125,
  },
  {
    x: 3121.09375,
    y: -0.0145111083984375,
  },
  {
    x: 3125,
    y: -0.029460906982421875,
  },
  {
    x: 3128.90625,
    y: -0.04441070556640625,
  },
  {
    x: 3132.8125,
    y: -0.054378509521484375,
  },
  {
    x: 3136.71875,
    y: -0.06932830810546875,
  },
  {
    x: 3140.625,
    y: -0.07929611206054688,
  },
  {
    x: 3148.4375,
    y: -0.09923171997070312,
  },
  {
    x: 3152.34375,
    y: -0.1141815185546875,
  },
  {
    x: 3156.25,
    y: -0.1241455078125,
  },
  {
    x: 3160.15625,
    y: -0.13411712646484375,
  },
  {
    x: 3164.0625,
    y: -0.14906692504882812,
  },
  {
    x: 3167.96875,
    y: -0.15903091430664062,
  },
  {
    x: 3171.875,
    y: -0.16899871826171875,
  },
  {
    x: 3175.78125,
    y: -0.17896652221679688,
  },
  {
    x: 3179.6875,
    y: -0.19391632080078125,
  },
  {
    x: 3183.59375,
    y: -0.20388412475585938,
  },
  {
    x: 3187.5,
    y: -0.19391632080078125,
  },
  {
    x: 3191.40625,
    y: -0.17896652221679688,
  },
  {
    x: 3195.3125,
    y: -0.1640167236328125,
  },
  {
    x: 3199.21875,
    y: -0.14408111572265625,
  },
  {
    x: 3203.125,
    y: -0.12913131713867188,
  },
  {
    x: 3207.03125,
    y: -0.10919570922851562,
  },
  {
    x: 3210.9375,
    y: -0.09424591064453125,
  },
  {
    x: 3214.84375,
    y: -0.074310302734375,
  },
  {
    x: 3218.75,
    y: -0.05936431884765625,
  },
  {
    x: 3222.65625,
    y: -0.04441070556640625,
  },
  {
    x: 3226.5625,
    y: -0.024478912353515625,
  },
  {
    x: 3230.46875,
    y: -0.00952911376953125,
  },
  {
    x: 3234.375,
    y: 0.00542449951171875,
  },
  {
    x: 3238.28125,
    y: 0.020374298095703125,
  },
  {
    x: 3242.1875,
    y: 0.040309906005859375,
  },
  {
    x: 3246.09375,
    y: 0.05525970458984375,
  },
  {
    x: 3250,
    y: 0.07020950317382812,
  },
  {
    x: 3253.90625,
    y: 0.08017730712890625,
  },
  {
    x: 3257.8125,
    y: 0.0851593017578125,
  },
  {
    x: 3261.71875,
    y: 0.0851593017578125,
  },
  {
    x: 3269.53125,
    y: 0.0851593017578125,
  },
  {
    x: 3273.4375,
    y: 0.09014511108398438,
  },
  {
    x: 3277.34375,
    y: 0.09014511108398438,
  },
  {
    x: 3300.78125,
    y: 0.09014511108398438,
  },
  {
    x: 3304.6875,
    y: 0.09512710571289062,
  },
  {
    x: 3308.59375,
    y: 0.09512710571289062,
  },
  {
    x: 3785.15625,
    y: 0.09512710571289062,
  },
  {
    x: 3789.0625,
    y: 0.13001251220703125,
  },
  {
    x: 3792.96875,
    y: 0.22469711303710938,
  },
  {
    x: 3796.875,
    y: 0.3393211364746094,
  },
  {
    x: 3800.78125,
    y: 0.45892333984375,
  },
  {
    x: 3804.6875,
    y: 0.5785293579101562,
  },
  {
    x: 3808.59375,
    y: 0.7031173706054688,
  },
  {
    x: 3812.5,
    y: 0.822723388671875,
  },
  {
    x: 3816.40625,
    y: 0.9423255920410156,
  },
  {
    x: 3820.3125,
    y: 1.0569496154785156,
  },
  {
    x: 3824.21875,
    y: 1.1765518188476562,
  },
  {
    x: 3828.125,
    y: 1.2313690185546875,
  },
  {
    x: 3832.03125,
    y: 1.1765518188476562,
  },
  {
    x: 3835.9375,
    y: 1.0669136047363281,
  },
  {
    x: 3839.84375,
    y: 0.9423255920410156,
  },
  {
    x: 3847.65625,
    y: 0.6931495666503906,
  },
  {
    x: 3851.5625,
    y: 0.5685577392578125,
  },
  {
    x: 3855.46875,
    y: 0.4439735412597656,
  },
  {
    x: 3859.375,
    y: 0.3243675231933594,
  },
  {
    x: 3863.28125,
    y: 0.1997833251953125,
  },
  {
    x: 3867.1875,
    y: 0.10010910034179688,
  },
  {
    x: 3871.09375,
    y: 0.06522369384765625,
  },
  {
    x: 3875,
    y: 0.050273895263671875,
  },
  {
    x: 3878.90625,
    y: 0.045291900634765625,
  },
  {
    x: 3882.8125,
    y: 0.045291900634765625,
  },
  {
    x: 3890.625,
    y: 0.045291900634765625,
  },
  {
    x: 3894.53125,
    y: 0.050273895263671875,
  },
  {
    x: 3898.4375,
    y: 0.050273895263671875,
  },
  {
    x: 3921.875,
    y: 0.050273895263671875,
  },
  {
    x: 3925.78125,
    y: 0.05525970458984375,
  },
  {
    x: 3929.6875,
    y: 0.05525970458984375,
  },
  {
    x: 3953.125,
    y: 0.05525970458984375,
  },
  {
    x: 3957.03125,
    y: 0.06024169921875,
  },
  {
    x: 3960.9375,
    y: 0.06024169921875,
  },
  {
    x: 3984.375,
    y: 0.06024169921875,
  },
  {
    x: 3988.28125,
    y: 0.06522369384765625,
  },
  {
    x: 3992.1875,
    y: 0.06522369384765625,
  },
  {
    x: 4015.625,
    y: 0.06522369384765625,
  },
  {
    x: 4019.53125,
    y: 0.07020950317382812,
  },
  {
    x: 4023.4375,
    y: 0.07020950317382812,
  },
  {
    x: 4046.875,
    y: 0.07020950317382812,
  },
  {
    x: 4050.78125,
    y: 0.0751953125,
  },
  {
    x: 4054.6875,
    y: 0.0751953125,
  },
  {
    x: 4078.125,
    y: 0.0751953125,
  },
  {
    x: 4082.03125,
    y: 0.07020950317382812,
  },
  {
    x: 4085.9375,
    y: 0.045291900634765625,
  },
  {
    x: 4089.84375,
    y: 0.015392303466796875,
  },
  {
    x: 4093.75,
    y: -0.004543304443359375,
  },
  {
    x: 4097.65625,
    y: -0.01949310302734375,
  },
  {
    x: 4101.5625,
    y: -0.029460906982421875,
  },
  {
    x: 4105.46875,
    y: -0.04441070556640625,
  },
  {
    x: 4109.375,
    y: -0.05936431884765625,
  },
  {
    x: 4113.28125,
    y: -0.06932830810546875,
  },
  {
    x: 4117.1875,
    y: -0.07929611206054688,
  },
  {
    x: 4121.09375,
    y: -0.09424591064453125,
  },
  {
    x: 4125,
    y: -0.10421371459960938,
  },
  {
    x: 4128.90625,
    y: -0.1141815185546875,
  },
  {
    x: 4132.8125,
    y: -0.12913131713867188,
  },
  {
    x: 4136.71875,
    y: -0.13909912109375,
  },
  {
    x: 4140.625,
    y: -0.14906692504882812,
  },
  {
    x: 4144.53125,
    y: -0.15903091430664062,
  },
  {
    x: 4148.4375,
    y: -0.17398452758789062,
  },
  {
    x: 4152.34375,
    y: -0.18394851684570312,
  },
  {
    x: 4156.25,
    y: -0.19391632080078125,
  },
  {
    x: 4160.15625,
    y: -0.20388412475585938,
  },
  {
    x: 4164.0625,
    y: -0.1988983154296875,
  },
  {
    x: 4167.96875,
    y: -0.18394851684570312,
  },
  {
    x: 4171.875,
    y: -0.1640167236328125,
  },
  {
    x: 4175.78125,
    y: -0.14906692504882812,
  },
  {
    x: 4179.6875,
    y: -0.12913131713867188,
  },
  {
    x: 4183.59375,
    y: -0.10919570922851562,
  },
  {
    x: 4187.5,
    y: -0.09424591064453125,
  },
  {
    x: 4195.3125,
    y: -0.0643463134765625,
  },
  {
    x: 4199.21875,
    y: -0.04441070556640625,
  },
  {
    x: 4203.125,
    y: -0.029460906982421875,
  },
  {
    x: 4207.03125,
    y: -0.0145111083984375,
  },
  {
    x: 4210.9375,
    y: 0.00542449951171875,
  },
  {
    x: 4214.84375,
    y: 0.020374298095703125,
  },
  {
    x: 4226.5625,
    y: 0.06522369384765625,
  },
  {
    x: 4230.46875,
    y: 0.08017730712890625,
  },
  {
    x: 4234.375,
    y: 0.08017730712890625,
  },
  {
    x: 4238.28125,
    y: 0.0851593017578125,
  },
  {
    x: 4242.1875,
    y: 0.0851593017578125,
  },
  {
    x: 4257.8125,
    y: 0.0851593017578125,
  },
  {
    x: 4261.71875,
    y: 0.09014511108398438,
  },
  {
    x: 4265.625,
    y: 0.09014511108398438,
  },
  {
    x: 4289.0625,
    y: 0.09014511108398438,
  },
  {
    x: 4292.96875,
    y: 0.09512710571289062,
  },
  {
    x: 4296.875,
    y: 0.09512710571289062,
  },
  {
    x: 4761.71875,
    y: 0.09512710571289062,
  },
  {
    x: 4765.625,
    y: 0.13001251220703125,
  },
  {
    x: 4769.53125,
    y: 0.22469711303710938,
  },
  {
    x: 4773.4375,
    y: 0.3393211364746094,
  },
  {
    x: 4777.34375,
    y: 0.45892333984375,
  },
  {
    x: 4781.25,
    y: 0.5785293579101562,
  },
  {
    x: 4785.15625,
    y: 0.7031173706054688,
  },
  {
    x: 4789.0625,
    y: 0.822723388671875,
  },
  {
    x: 4792.96875,
    y: 0.9423255920410156,
  },
  {
    x: 4796.875,
    y: 1.0569496154785156,
  },
  {
    x: 4800.78125,
    y: 1.1765518188476562,
  },
  {
    x: 4804.6875,
    y: 1.2313690185546875,
  },
  {
    x: 4808.59375,
    y: 1.1765518188476562,
  },
  {
    x: 4812.5,
    y: 1.0669136047363281,
  },
  {
    x: 4816.40625,
    y: 0.9423255920410156,
  },
  {
    x: 4824.21875,
    y: 0.6931495666503906,
  },
  {
    x: 4828.125,
    y: 0.5685577392578125,
  },
  {
    x: 4832.03125,
    y: 0.4439735412597656,
  },
  {
    x: 4835.9375,
    y: 0.3243675231933594,
  },
  {
    x: 4839.84375,
    y: 0.1997833251953125,
  },
  {
    x: 4843.75,
    y: 0.10010910034179688,
  },
  {
    x: 4847.65625,
    y: 0.06522369384765625,
  },
  {
    x: 4851.5625,
    y: 0.050273895263671875,
  },
  {
    x: 4855.46875,
    y: 0.045291900634765625,
  },
  {
    x: 4859.375,
    y: 0.045291900634765625,
  },
  {
    x: 4867.1875,
    y: 0.045291900634765625,
  },
  {
    x: 4871.09375,
    y: 0.050273895263671875,
  },
  {
    x: 4875,
    y: 0.050273895263671875,
  },
  {
    x: 4898.4375,
    y: 0.050273895263671875,
  },
  {
    x: 4902.34375,
    y: 0.05525970458984375,
  },
  {
    x: 4906.25,
    y: 0.05525970458984375,
  },
  {
    x: 4929.6875,
    y: 0.05525970458984375,
  },
  {
    x: 4933.59375,
    y: 0.06024169921875,
  },
  {
    x: 4937.5,
    y: 0.06024169921875,
  },
  {
    x: 4960.9375,
    y: 0.06024169921875,
  },
  {
    x: 4964.84375,
    y: 0.06522369384765625,
  },
  {
    x: 4968.75,
    y: 0.06522369384765625,
  },
  {
    x: 4992.1875,
    y: 0.06522369384765625,
  },
  {
    x: 4996.09375,
    y: 0.07020950317382812,
  },
  {
    x: 5000,
    y: 0.07020950317382812,
  },
  {
    x: 5023.4375,
    y: 0.07020950317382812,
  },
  {
    x: 5027.34375,
    y: 0.0751953125,
  },
  {
    x: 5031.25,
    y: 0.0751953125,
  },
  {
    x: 5054.6875,
    y: 0.0751953125,
  },
  {
    x: 5058.59375,
    y: 0.07020950317382812,
  },
  {
    x: 5062.5,
    y: 0.045291900634765625,
  },
  {
    x: 5066.40625,
    y: 0.015392303466796875,
  },
  {
    x: 5070.3125,
    y: -0.004543304443359375,
  },
  {
    x: 5074.21875,
    y: -0.01949310302734375,
  },
  {
    x: 5078.125,
    y: -0.029460906982421875,
  },
  {
    x: 5082.03125,
    y: -0.04441070556640625,
  },
  {
    x: 5085.9375,
    y: -0.05936431884765625,
  },
  {
    x: 5089.84375,
    y: -0.06932830810546875,
  },
  {
    x: 5093.75,
    y: -0.07929611206054688,
  },
  {
    x: 5097.65625,
    y: -0.09424591064453125,
  },
  {
    x: 5101.5625,
    y: -0.10421371459960938,
  },
  {
    x: 5105.46875,
    y: -0.1141815185546875,
  },
  {
    x: 5109.375,
    y: -0.12913131713867188,
  },
  {
    x: 5113.28125,
    y: -0.13909912109375,
  },
  {
    x: 5117.1875,
    y: -0.14906692504882812,
  },
  {
    x: 5121.09375,
    y: -0.15903091430664062,
  },
  {
    x: 5125,
    y: -0.17398452758789062,
  },
  {
    x: 5128.90625,
    y: -0.18394851684570312,
  },
  {
    x: 5132.8125,
    y: -0.19391632080078125,
  },
  {
    x: 5140.625,
    y: -0.19391632080078125,
  },
  {
    x: 5144.53125,
    y: -0.18394851684570312,
  },
  {
    x: 5148.4375,
    y: -0.1640167236328125,
  },
  {
    x: 5152.34375,
    y: -0.14906692504882812,
  },
  {
    x: 5156.25,
    y: -0.12913131713867188,
  },
  {
    x: 5160.15625,
    y: -0.10919570922851562,
  },
  {
    x: 5164.0625,
    y: -0.09424591064453125,
  },
  {
    x: 5171.875,
    y: -0.0643463134765625,
  },
  {
    x: 5175.78125,
    y: -0.04441070556640625,
  },
  {
    x: 5179.6875,
    y: -0.029460906982421875,
  },
  {
    x: 5183.59375,
    y: -0.0145111083984375,
  },
  {
    x: 5187.5,
    y: 0.00542449951171875,
  },
  {
    x: 5191.40625,
    y: 0.020374298095703125,
  },
  {
    x: 5203.125,
    y: 0.06522369384765625,
  },
  {
    x: 5207.03125,
    y: 0.08017730712890625,
  },
  {
    x: 5210.9375,
    y: 0.08017730712890625,
  },
  {
    x: 5214.84375,
    y: 0.0851593017578125,
  },
  {
    x: 5218.75,
    y: 0.0851593017578125,
  },
  {
    x: 5234.375,
    y: 0.0851593017578125,
  },
  {
    x: 5238.28125,
    y: 0.09014511108398438,
  },
  {
    x: 5242.1875,
    y: 0.09014511108398438,
  },
  {
    x: 5265.625,
    y: 0.09014511108398438,
  },
  {
    x: 5269.53125,
    y: 0.09512710571289062,
  },
  {
    x: 5273.4375,
    y: 0.09512710571289062,
  },
  {
    x: 5738.28125,
    y: 0.09512710571289062,
  },
  {
    x: 5742.1875,
    y: 0.13001251220703125,
  },
  {
    x: 5746.09375,
    y: 0.22469711303710938,
  },
  {
    x: 5750,
    y: 0.3393211364746094,
  },
  {
    x: 5753.90625,
    y: 0.45892333984375,
  },
  {
    x: 5757.8125,
    y: 0.5785293579101562,
  },
  {
    x: 5761.71875,
    y: 0.7031173706054688,
  },
  {
    x: 5765.625,
    y: 0.822723388671875,
  },
  {
    x: 5769.53125,
    y: 0.9423255920410156,
  },
  {
    x: 5773.4375,
    y: 1.0569496154785156,
  },
  {
    x: 5777.34375,
    y: 1.1765518188476562,
  },
  {
    x: 5781.25,
    y: 1.2313690185546875,
  },
  {
    x: 5785.15625,
    y: 1.1765518188476562,
  },
  {
    x: 5789.0625,
    y: 1.0669136047363281,
  },
  {
    x: 5792.96875,
    y: 0.9423255920410156,
  },
  {
    x: 5800.78125,
    y: 0.6931495666503906,
  },
  {
    x: 5804.6875,
    y: 0.5685577392578125,
  },
  {
    x: 5808.59375,
    y: 0.4439735412597656,
  },
  {
    x: 5812.5,
    y: 0.3243675231933594,
  },
  {
    x: 5816.40625,
    y: 0.1997833251953125,
  },
  {
    x: 5820.3125,
    y: 0.10010910034179688,
  },
  {
    x: 5824.21875,
    y: 0.06522369384765625,
  },
  {
    x: 5828.125,
    y: 0.050273895263671875,
  },
  {
    x: 5832.03125,
    y: 0.045291900634765625,
  },
  {
    x: 5835.9375,
    y: 0.045291900634765625,
  },
  {
    x: 5843.75,
    y: 0.045291900634765625,
  },
  {
    x: 5847.65625,
    y: 0.050273895263671875,
  },
  {
    x: 5851.5625,
    y: 0.050273895263671875,
  },
  {
    x: 5875,
    y: 0.050273895263671875,
  },
  {
    x: 5878.90625,
    y: 0.05525970458984375,
  },
  {
    x: 5882.8125,
    y: 0.05525970458984375,
  },
  {
    x: 5906.25,
    y: 0.05525970458984375,
  },
  {
    x: 5910.15625,
    y: 0.06024169921875,
  },
  {
    x: 5914.0625,
    y: 0.06024169921875,
  },
  {
    x: 5937.5,
    y: 0.06024169921875,
  },
  {
    x: 5941.40625,
    y: 0.06522369384765625,
  },
  {
    x: 5945.3125,
    y: 0.06522369384765625,
  },
  {
    x: 5968.75,
    y: 0.06522369384765625,
  },
  {
    x: 5972.65625,
    y: 0.07020950317382812,
  },
  {
    x: 5976.5625,
    y: 0.07020950317382812,
  },
  {
    x: 6000,
    y: 0.07020950317382812,
  },
];
export const V5 = [
  {
    x: 0,
    y: 0.09512710571289062,
  },
  {
    x: 27.34375,
    y: 0.09512710571289062,
  },
  {
    x: 31.25,
    y: 0.125030517578125,
  },
  {
    x: 35.15625,
    y: 0.21473312377929688,
  },
  {
    x: 39.0625,
    y: 0.31439971923828125,
  },
  {
    x: 42.96875,
    y: 0.424041748046875,
  },
  {
    x: 46.875,
    y: 0.5336761474609375,
  },
  {
    x: 50.78125,
    y: 0.643310546875,
  },
  {
    x: 54.6875,
    y: 0.7529525756835938,
  },
  {
    x: 58.59375,
    y: 0.85760498046875,
  },
  {
    x: 62.5,
    y: 0.9622573852539062,
  },
  {
    x: 66.40625,
    y: 1.0669136047363281,
  },
  {
    x: 70.3125,
    y: 1.1167488098144531,
  },
  {
    x: 74.21875,
    y: 1.0669136047363281,
  },
  {
    x: 78.125,
    y: 0.9772109985351562,
  },
  {
    x: 82.03125,
    y: 0.8675727844238281,
  },
  {
    x: 93.75,
    y: 0.5386581420898438,
  },
  {
    x: 97.65625,
    y: 0.42902374267578125,
  },
  {
    x: 101.5625,
    y: 0.3193855285644531,
  },
  {
    x: 105.46875,
    y: 0.209747314453125,
  },
  {
    x: 109.375,
    y: 0.125030517578125,
  },
  {
    x: 113.28125,
    y: 0.09014511108398438,
  },
  {
    x: 117.1875,
    y: 0.08017730712890625,
  },
  {
    x: 121.09375,
    y: 0.0751953125,
  },
  {
    x: 125,
    y: 0.0751953125,
  },
  {
    x: 144.53125,
    y: 0.0751953125,
  },
  {
    x: 148.4375,
    y: 0.08017730712890625,
  },
  {
    x: 152.34375,
    y: 0.08017730712890625,
  },
  {
    x: 175.78125,
    y: 0.08017730712890625,
  },
  {
    x: 179.6875,
    y: 0.0851593017578125,
  },
  {
    x: 183.59375,
    y: 0.0851593017578125,
  },
  {
    x: 207.03125,
    y: 0.0851593017578125,
  },
  {
    x: 210.9375,
    y: 0.09014511108398438,
  },
  {
    x: 214.84375,
    y: 0.09014511108398438,
  },
  {
    x: 238.28125,
    y: 0.09014511108398438,
  },
  {
    x: 242.1875,
    y: 0.09512710571289062,
  },
  {
    x: 246.09375,
    y: 0.09512710571289062,
  },
  {
    x: 320.3125,
    y: 0.09512710571289062,
  },
  {
    x: 324.21875,
    y: 0.0851593017578125,
  },
  {
    x: 328.125,
    y: 0.05525970458984375,
  },
  {
    x: 332.03125,
    y: 0.020374298095703125,
  },
  {
    x: 335.9375,
    y: 0.0004425048828125,
  },
  {
    x: 339.84375,
    y: -0.0145111083984375,
  },
  {
    x: 343.75,
    y: -0.024478912353515625,
  },
  {
    x: 347.65625,
    y: -0.0394287109375,
  },
  {
    x: 351.5625,
    y: -0.049396514892578125,
  },
  {
    x: 355.46875,
    y: -0.05936431884765625,
  },
  {
    x: 359.375,
    y: -0.06932830810546875,
  },
  {
    x: 363.28125,
    y: -0.07929611206054688,
  },
  {
    x: 371.09375,
    y: -0.09923171997070312,
  },
  {
    x: 375,
    y: -0.1141815185546875,
  },
  {
    x: 378.90625,
    y: -0.1241455078125,
  },
  {
    x: 382.8125,
    y: -0.12913131713867188,
  },
  {
    x: 386.71875,
    y: -0.14408111572265625,
  },
  {
    x: 390.625,
    y: -0.14906692504882812,
  },
  {
    x: 394.53125,
    y: -0.15903091430664062,
  },
  {
    x: 398.4375,
    y: -0.16899871826171875,
  },
  {
    x: 402.34375,
    y: -0.18394851684570312,
  },
  {
    x: 406.25,
    y: -0.17398452758789062,
  },
  {
    x: 410.15625,
    y: -0.15903091430664062,
  },
  {
    x: 414.0625,
    y: -0.14408111572265625,
  },
  {
    x: 425.78125,
    y: -0.09923171997070312,
  },
  {
    x: 429.6875,
    y: -0.08427810668945312,
  },
  {
    x: 433.59375,
    y: -0.06932830810546875,
  },
  {
    x: 437.5,
    y: -0.049396514892578125,
  },
  {
    x: 441.40625,
    y: -0.034442901611328125,
  },
  {
    x: 445.3125,
    y: -0.01949310302734375,
  },
  {
    x: 457.03125,
    y: 0.025356292724609375,
  },
  {
    x: 460.9375,
    y: 0.0353240966796875,
  },
  {
    x: 464.84375,
    y: 0.050273895263671875,
  },
  {
    x: 468.75,
    y: 0.06522369384765625,
  },
  {
    x: 472.65625,
    y: 0.0751953125,
  },
  {
    x: 476.5625,
    y: 0.08017730712890625,
  },
  {
    x: 480.46875,
    y: 0.08017730712890625,
  },
  {
    x: 488.28125,
    y: 0.08017730712890625,
  },
  {
    x: 492.1875,
    y: 0.0851593017578125,
  },
  {
    x: 496.09375,
    y: 0.0851593017578125,
  },
  {
    x: 519.53125,
    y: 0.0851593017578125,
  },
  {
    x: 523.4375,
    y: 0.09014511108398438,
  },
  {
    x: 527.34375,
    y: 0.09014511108398438,
  },
  {
    x: 550.78125,
    y: 0.09014511108398438,
  },
  {
    x: 554.6875,
    y: 0.09512710571289062,
  },
  {
    x: 558.59375,
    y: 0.09512710571289062,
  },
  {
    x: 753.90625,
    y: 0.09512710571289062,
  },
  {
    x: 757.8125,
    y: 0.125030517578125,
  },
  {
    x: 761.71875,
    y: 0.21473312377929688,
  },
  {
    x: 765.625,
    y: 0.31439971923828125,
  },
  {
    x: 769.53125,
    y: 0.424041748046875,
  },
  {
    x: 773.4375,
    y: 0.5336761474609375,
  },
  {
    x: 777.34375,
    y: 0.643310546875,
  },
  {
    x: 781.25,
    y: 0.7529525756835938,
  },
  {
    x: 785.15625,
    y: 0.85760498046875,
  },
  {
    x: 789.0625,
    y: 0.9622573852539062,
  },
  {
    x: 792.96875,
    y: 1.0669136047363281,
  },
  {
    x: 796.875,
    y: 1.1167488098144531,
  },
  {
    x: 800.78125,
    y: 1.0669136047363281,
  },
  {
    x: 804.6875,
    y: 0.9772109985351562,
  },
  {
    x: 808.59375,
    y: 0.8675727844238281,
  },
  {
    x: 812.5,
    y: 0.7579345703125,
  },
  {
    x: 816.40625,
    y: 0.643310546875,
  },
  {
    x: 820.3125,
    y: 0.5336761474609375,
  },
  {
    x: 824.21875,
    y: 0.424041748046875,
  },
  {
    x: 828.125,
    y: 0.31439971923828125,
  },
  {
    x: 832.03125,
    y: 0.209747314453125,
  },
  {
    x: 835.9375,
    y: 0.12004470825195312,
  },
  {
    x: 839.84375,
    y: 0.0851593017578125,
  },
  {
    x: 843.75,
    y: 0.0751953125,
  },
  {
    x: 847.65625,
    y: 0.07020950317382812,
  },
  {
    x: 851.5625,
    y: 0.07020950317382812,
  },
  {
    x: 871.09375,
    y: 0.07020950317382812,
  },
  {
    x: 875,
    y: 0.0751953125,
  },
  {
    x: 878.90625,
    y: 0.0751953125,
  },
  {
    x: 902.34375,
    y: 0.0751953125,
  },
  {
    x: 906.25,
    y: 0.08017730712890625,
  },
  {
    x: 910.15625,
    y: 0.08017730712890625,
  },
  {
    x: 933.59375,
    y: 0.08017730712890625,
  },
  {
    x: 937.5,
    y: 0.0851593017578125,
  },
  {
    x: 941.40625,
    y: 0.0851593017578125,
  },
  {
    x: 964.84375,
    y: 0.0851593017578125,
  },
  {
    x: 968.75,
    y: 0.09014511108398438,
  },
  {
    x: 972.65625,
    y: 0.09014511108398438,
  },
  {
    x: 996.09375,
    y: 0.09014511108398438,
  },
  {
    x: 1000,
    y: 0.09512710571289062,
  },
  {
    x: 1003.90625,
    y: 0.09512710571289062,
  },
  {
    x: 1046.875,
    y: 0.09512710571289062,
  },
  {
    x: 1050.78125,
    y: 0.0851593017578125,
  },
  {
    x: 1054.6875,
    y: 0.06024169921875,
  },
  {
    x: 1058.59375,
    y: 0.025356292724609375,
  },
  {
    x: 1062.5,
    y: 0.00542449951171875,
  },
  {
    x: 1066.40625,
    y: -0.00952911376953125,
  },
  {
    x: 1070.3125,
    y: -0.024478912353515625,
  },
  {
    x: 1074.21875,
    y: -0.034442901611328125,
  },
  {
    x: 1078.125,
    y: -0.04441070556640625,
  },
  {
    x: 1085.9375,
    y: -0.0643463134765625,
  },
  {
    x: 1089.84375,
    y: -0.074310302734375,
  },
  {
    x: 1093.75,
    y: -0.089263916015625,
  },
  {
    x: 1097.65625,
    y: -0.09923171997070312,
  },
  {
    x: 1101.5625,
    y: -0.10919570922851562,
  },
  {
    x: 1105.46875,
    y: -0.11916351318359375,
  },
  {
    x: 1117.1875,
    y: -0.14906692504882812,
  },
  {
    x: 1121.09375,
    y: -0.15903091430664062,
  },
  {
    x: 1125,
    y: -0.16899871826171875,
  },
  {
    x: 1132.8125,
    y: -0.16899871826171875,
  },
  {
    x: 1136.71875,
    y: -0.15903091430664062,
  },
  {
    x: 1140.625,
    y: -0.14408111572265625,
  },
  {
    x: 1144.53125,
    y: -0.1241455078125,
  },
  {
    x: 1148.4375,
    y: -0.10919570922851562,
  },
  {
    x: 1164.0625,
    y: -0.049396514892578125,
  },
  {
    x: 1167.96875,
    y: -0.034442901611328125,
  },
  {
    x: 1171.875,
    y: -0.01949310302734375,
  },
  {
    x: 1183.59375,
    y: 0.025356292724609375,
  },
  {
    x: 1187.5,
    y: 0.040309906005859375,
  },
  {
    x: 1191.40625,
    y: 0.05525970458984375,
  },
  {
    x: 1195.3125,
    y: 0.06522369384765625,
  },
  {
    x: 1199.21875,
    y: 0.08017730712890625,
  },
  {
    x: 1203.125,
    y: 0.08017730712890625,
  },
  {
    x: 1207.03125,
    y: 0.0851593017578125,
  },
  {
    x: 1210.9375,
    y: 0.0851593017578125,
  },
  {
    x: 1226.5625,
    y: 0.0851593017578125,
  },
  {
    x: 1230.46875,
    y: 0.09014511108398438,
  },
  {
    x: 1234.375,
    y: 0.09014511108398438,
  },
  {
    x: 1257.8125,
    y: 0.09014511108398438,
  },
  {
    x: 1261.71875,
    y: 0.09512710571289062,
  },
  {
    x: 1265.625,
    y: 0.09512710571289062,
  },
  {
    x: 1730.46875,
    y: 0.09512710571289062,
  },
  {
    x: 1734.375,
    y: 0.125030517578125,
  },
  {
    x: 1738.28125,
    y: 0.21473312377929688,
  },
  {
    x: 1742.1875,
    y: 0.31439971923828125,
  },
  {
    x: 1746.09375,
    y: 0.424041748046875,
  },
  {
    x: 1750,
    y: 0.5336761474609375,
  },
  {
    x: 1753.90625,
    y: 0.643310546875,
  },
  {
    x: 1757.8125,
    y: 0.7529525756835938,
  },
  {
    x: 1761.71875,
    y: 0.85760498046875,
  },
  {
    x: 1765.625,
    y: 0.9622573852539062,
  },
  {
    x: 1769.53125,
    y: 1.0669136047363281,
  },
  {
    x: 1773.4375,
    y: 1.1167488098144531,
  },
  {
    x: 1777.34375,
    y: 1.0669136047363281,
  },
  {
    x: 1781.25,
    y: 0.9772109985351562,
  },
  {
    x: 1785.15625,
    y: 0.8675727844238281,
  },
  {
    x: 1789.0625,
    y: 0.7579345703125,
  },
  {
    x: 1792.96875,
    y: 0.643310546875,
  },
  {
    x: 1796.875,
    y: 0.5336761474609375,
  },
  {
    x: 1800.78125,
    y: 0.424041748046875,
  },
  {
    x: 1804.6875,
    y: 0.31439971923828125,
  },
  {
    x: 1808.59375,
    y: 0.209747314453125,
  },
  {
    x: 1812.5,
    y: 0.12004470825195312,
  },
  {
    x: 1816.40625,
    y: 0.0851593017578125,
  },
  {
    x: 1820.3125,
    y: 0.0751953125,
  },
  {
    x: 1824.21875,
    y: 0.07020950317382812,
  },
  {
    x: 1828.125,
    y: 0.07020950317382812,
  },
  {
    x: 1847.65625,
    y: 0.07020950317382812,
  },
  {
    x: 1851.5625,
    y: 0.0751953125,
  },
  {
    x: 1855.46875,
    y: 0.0751953125,
  },
  {
    x: 1878.90625,
    y: 0.0751953125,
  },
  {
    x: 1882.8125,
    y: 0.08017730712890625,
  },
  {
    x: 1886.71875,
    y: 0.08017730712890625,
  },
  {
    x: 1910.15625,
    y: 0.08017730712890625,
  },
  {
    x: 1914.0625,
    y: 0.0851593017578125,
  },
  {
    x: 1917.96875,
    y: 0.0851593017578125,
  },
  {
    x: 1941.40625,
    y: 0.0851593017578125,
  },
  {
    x: 1945.3125,
    y: 0.09014511108398438,
  },
  {
    x: 1949.21875,
    y: 0.09014511108398438,
  },
  {
    x: 1972.65625,
    y: 0.09014511108398438,
  },
  {
    x: 1976.5625,
    y: 0.09512710571289062,
  },
  {
    x: 1980.46875,
    y: 0.09512710571289062,
  },
  {
    x: 2023.4375,
    y: 0.09512710571289062,
  },
  {
    x: 2027.34375,
    y: 0.0851593017578125,
  },
  {
    x: 2031.25,
    y: 0.06024169921875,
  },
  {
    x: 2035.15625,
    y: 0.025356292724609375,
  },
  {
    x: 2039.0625,
    y: 0.00542449951171875,
  },
  {
    x: 2042.96875,
    y: -0.00952911376953125,
  },
  {
    x: 2046.875,
    y: -0.024478912353515625,
  },
  {
    x: 2050.78125,
    y: -0.034442901611328125,
  },
  {
    x: 2054.6875,
    y: -0.04441070556640625,
  },
  {
    x: 2062.5,
    y: -0.0643463134765625,
  },
  {
    x: 2066.40625,
    y: -0.074310302734375,
  },
  {
    x: 2070.3125,
    y: -0.089263916015625,
  },
  {
    x: 2074.21875,
    y: -0.09923171997070312,
  },
  {
    x: 2078.125,
    y: -0.10919570922851562,
  },
  {
    x: 2082.03125,
    y: -0.11916351318359375,
  },
  {
    x: 2093.75,
    y: -0.14906692504882812,
  },
  {
    x: 2097.65625,
    y: -0.15903091430664062,
  },
  {
    x: 2101.5625,
    y: -0.16899871826171875,
  },
  {
    x: 2109.375,
    y: -0.16899871826171875,
  },
  {
    x: 2113.28125,
    y: -0.15903091430664062,
  },
  {
    x: 2117.1875,
    y: -0.14408111572265625,
  },
  {
    x: 2121.09375,
    y: -0.1241455078125,
  },
  {
    x: 2125,
    y: -0.10919570922851562,
  },
  {
    x: 2140.625,
    y: -0.049396514892578125,
  },
  {
    x: 2144.53125,
    y: -0.034442901611328125,
  },
  {
    x: 2148.4375,
    y: -0.01949310302734375,
  },
  {
    x: 2160.15625,
    y: 0.025356292724609375,
  },
  {
    x: 2164.0625,
    y: 0.040309906005859375,
  },
  {
    x: 2167.96875,
    y: 0.05525970458984375,
  },
  {
    x: 2171.875,
    y: 0.06522369384765625,
  },
  {
    x: 2175.78125,
    y: 0.08017730712890625,
  },
  {
    x: 2179.6875,
    y: 0.08017730712890625,
  },
  {
    x: 2183.59375,
    y: 0.0851593017578125,
  },
  {
    x: 2187.5,
    y: 0.0851593017578125,
  },
  {
    x: 2203.125,
    y: 0.0851593017578125,
  },
  {
    x: 2207.03125,
    y: 0.09014511108398438,
  },
  {
    x: 2210.9375,
    y: 0.09014511108398438,
  },
  {
    x: 2234.375,
    y: 0.09014511108398438,
  },
  {
    x: 2238.28125,
    y: 0.09512710571289062,
  },
  {
    x: 2242.1875,
    y: 0.09512710571289062,
  },
  {
    x: 2707.03125,
    y: 0.09512710571289062,
  },
  {
    x: 2710.9375,
    y: 0.125030517578125,
  },
  {
    x: 2714.84375,
    y: 0.21473312377929688,
  },
  {
    x: 2718.75,
    y: 0.31439971923828125,
  },
  {
    x: 2722.65625,
    y: 0.424041748046875,
  },
  {
    x: 2726.5625,
    y: 0.5336761474609375,
  },
  {
    x: 2730.46875,
    y: 0.643310546875,
  },
  {
    x: 2734.375,
    y: 0.7529525756835938,
  },
  {
    x: 2738.28125,
    y: 0.85760498046875,
  },
  {
    x: 2742.1875,
    y: 0.9622573852539062,
  },
  {
    x: 2746.09375,
    y: 1.0669136047363281,
  },
  {
    x: 2750,
    y: 1.1167488098144531,
  },
  {
    x: 2753.90625,
    y: 1.0669136047363281,
  },
  {
    x: 2757.8125,
    y: 0.9772109985351562,
  },
  {
    x: 2761.71875,
    y: 0.8675727844238281,
  },
  {
    x: 2765.625,
    y: 0.7579345703125,
  },
  {
    x: 2769.53125,
    y: 0.643310546875,
  },
  {
    x: 2773.4375,
    y: 0.5336761474609375,
  },
  {
    x: 2777.34375,
    y: 0.424041748046875,
  },
  {
    x: 2781.25,
    y: 0.31439971923828125,
  },
  {
    x: 2785.15625,
    y: 0.209747314453125,
  },
  {
    x: 2789.0625,
    y: 0.12004470825195312,
  },
  {
    x: 2792.96875,
    y: 0.0851593017578125,
  },
  {
    x: 2796.875,
    y: 0.0751953125,
  },
  {
    x: 2800.78125,
    y: 0.07020950317382812,
  },
  {
    x: 2804.6875,
    y: 0.07020950317382812,
  },
  {
    x: 2824.21875,
    y: 0.07020950317382812,
  },
  {
    x: 2828.125,
    y: 0.0751953125,
  },
  {
    x: 2832.03125,
    y: 0.0751953125,
  },
  {
    x: 2855.46875,
    y: 0.0751953125,
  },
  {
    x: 2859.375,
    y: 0.08017730712890625,
  },
  {
    x: 2863.28125,
    y: 0.08017730712890625,
  },
  {
    x: 2886.71875,
    y: 0.08017730712890625,
  },
  {
    x: 2890.625,
    y: 0.0851593017578125,
  },
  {
    x: 2894.53125,
    y: 0.0851593017578125,
  },
  {
    x: 2917.96875,
    y: 0.0851593017578125,
  },
  {
    x: 2921.875,
    y: 0.09014511108398438,
  },
  {
    x: 2925.78125,
    y: 0.09014511108398438,
  },
  {
    x: 2949.21875,
    y: 0.09014511108398438,
  },
  {
    x: 2953.125,
    y: 0.09512710571289062,
  },
  {
    x: 2957.03125,
    y: 0.09512710571289062,
  },
  {
    x: 3000,
    y: 0.09512710571289062,
  },
  {
    x: 3003.90625,
    y: 0.0851593017578125,
  },
  {
    x: 3007.8125,
    y: 0.06024169921875,
  },
  {
    x: 3011.71875,
    y: 0.025356292724609375,
  },
  {
    x: 3015.625,
    y: 0.00542449951171875,
  },
  {
    x: 3019.53125,
    y: -0.00952911376953125,
  },
  {
    x: 3023.4375,
    y: -0.024478912353515625,
  },
  {
    x: 3027.34375,
    y: -0.034442901611328125,
  },
  {
    x: 3031.25,
    y: -0.04441070556640625,
  },
  {
    x: 3039.0625,
    y: -0.0643463134765625,
  },
  {
    x: 3042.96875,
    y: -0.074310302734375,
  },
  {
    x: 3046.875,
    y: -0.089263916015625,
  },
  {
    x: 3050.78125,
    y: -0.09923171997070312,
  },
  {
    x: 3054.6875,
    y: -0.10919570922851562,
  },
  {
    x: 3058.59375,
    y: -0.11916351318359375,
  },
  {
    x: 3070.3125,
    y: -0.14906692504882812,
  },
  {
    x: 3074.21875,
    y: -0.15404891967773438,
  },
  {
    x: 3078.125,
    y: -0.16899871826171875,
  },
  {
    x: 3082.03125,
    y: -0.17896652221679688,
  },
  {
    x: 3085.9375,
    y: -0.16899871826171875,
  },
  {
    x: 3089.84375,
    y: -0.15903091430664062,
  },
  {
    x: 3093.75,
    y: -0.14408111572265625,
  },
  {
    x: 3097.65625,
    y: -0.1241455078125,
  },
  {
    x: 3101.5625,
    y: -0.10919570922851562,
  },
  {
    x: 3117.1875,
    y: -0.049396514892578125,
  },
  {
    x: 3121.09375,
    y: -0.034442901611328125,
  },
  {
    x: 3125,
    y: -0.01949310302734375,
  },
  {
    x: 3136.71875,
    y: 0.025356292724609375,
  },
  {
    x: 3140.625,
    y: 0.040309906005859375,
  },
  {
    x: 3144.53125,
    y: 0.05525970458984375,
  },
  {
    x: 3148.4375,
    y: 0.06522369384765625,
  },
  {
    x: 3152.34375,
    y: 0.08017730712890625,
  },
  {
    x: 3156.25,
    y: 0.08017730712890625,
  },
  {
    x: 3160.15625,
    y: 0.0851593017578125,
  },
  {
    x: 3164.0625,
    y: 0.0851593017578125,
  },
  {
    x: 3179.6875,
    y: 0.0851593017578125,
  },
  {
    x: 3183.59375,
    y: 0.09014511108398438,
  },
  {
    x: 3187.5,
    y: 0.09014511108398438,
  },
  {
    x: 3210.9375,
    y: 0.09014511108398438,
  },
  {
    x: 3214.84375,
    y: 0.09512710571289062,
  },
  {
    x: 3218.75,
    y: 0.09512710571289062,
  },
  {
    x: 3683.59375,
    y: 0.09512710571289062,
  },
  {
    x: 3687.5,
    y: 0.125030517578125,
  },
  {
    x: 3691.40625,
    y: 0.21473312377929688,
  },
  {
    x: 3695.3125,
    y: 0.31439971923828125,
  },
  {
    x: 3699.21875,
    y: 0.424041748046875,
  },
  {
    x: 3703.125,
    y: 0.5336761474609375,
  },
  {
    x: 3707.03125,
    y: 0.643310546875,
  },
  {
    x: 3710.9375,
    y: 0.7529525756835938,
  },
  {
    x: 3714.84375,
    y: 0.85760498046875,
  },
  {
    x: 3718.75,
    y: 0.9622573852539062,
  },
  {
    x: 3722.65625,
    y: 1.0669136047363281,
  },
  {
    x: 3726.5625,
    y: 1.1167488098144531,
  },
  {
    x: 3730.46875,
    y: 1.0669136047363281,
  },
  {
    x: 3734.375,
    y: 0.9772109985351562,
  },
  {
    x: 3738.28125,
    y: 0.8675727844238281,
  },
  {
    x: 3742.1875,
    y: 0.7579345703125,
  },
  {
    x: 3746.09375,
    y: 0.643310546875,
  },
  {
    x: 3750,
    y: 0.5336761474609375,
  },
  {
    x: 3753.90625,
    y: 0.424041748046875,
  },
  {
    x: 3757.8125,
    y: 0.31439971923828125,
  },
  {
    x: 3761.71875,
    y: 0.209747314453125,
  },
  {
    x: 3765.625,
    y: 0.12004470825195312,
  },
  {
    x: 3769.53125,
    y: 0.0851593017578125,
  },
  {
    x: 3773.4375,
    y: 0.0751953125,
  },
  {
    x: 3777.34375,
    y: 0.07020950317382812,
  },
  {
    x: 3781.25,
    y: 0.07020950317382812,
  },
  {
    x: 3800.78125,
    y: 0.07020950317382812,
  },
  {
    x: 3804.6875,
    y: 0.0751953125,
  },
  {
    x: 3808.59375,
    y: 0.0751953125,
  },
  {
    x: 3832.03125,
    y: 0.0751953125,
  },
  {
    x: 3835.9375,
    y: 0.08017730712890625,
  },
  {
    x: 3839.84375,
    y: 0.08017730712890625,
  },
  {
    x: 3863.28125,
    y: 0.08017730712890625,
  },
  {
    x: 3867.1875,
    y: 0.0851593017578125,
  },
  {
    x: 3871.09375,
    y: 0.0851593017578125,
  },
  {
    x: 3894.53125,
    y: 0.0851593017578125,
  },
  {
    x: 3898.4375,
    y: 0.09014511108398438,
  },
  {
    x: 3902.34375,
    y: 0.09014511108398438,
  },
  {
    x: 3925.78125,
    y: 0.09014511108398438,
  },
  {
    x: 3929.6875,
    y: 0.09512710571289062,
  },
  {
    x: 3933.59375,
    y: 0.09512710571289062,
  },
  {
    x: 3976.5625,
    y: 0.09512710571289062,
  },
  {
    x: 3980.46875,
    y: 0.0851593017578125,
  },
  {
    x: 3984.375,
    y: 0.06024169921875,
  },
  {
    x: 3988.28125,
    y: 0.025356292724609375,
  },
  {
    x: 3992.1875,
    y: 0.00542449951171875,
  },
  {
    x: 3996.09375,
    y: -0.00952911376953125,
  },
  {
    x: 4000,
    y: -0.024478912353515625,
  },
  {
    x: 4003.90625,
    y: -0.034442901611328125,
  },
  {
    x: 4007.8125,
    y: -0.04441070556640625,
  },
  {
    x: 4015.625,
    y: -0.0643463134765625,
  },
  {
    x: 4019.53125,
    y: -0.074310302734375,
  },
  {
    x: 4023.4375,
    y: -0.089263916015625,
  },
  {
    x: 4027.34375,
    y: -0.09923171997070312,
  },
  {
    x: 4031.25,
    y: -0.10919570922851562,
  },
  {
    x: 4035.15625,
    y: -0.11916351318359375,
  },
  {
    x: 4046.875,
    y: -0.14906692504882812,
  },
  {
    x: 4050.78125,
    y: -0.15903091430664062,
  },
  {
    x: 4054.6875,
    y: -0.16899871826171875,
  },
  {
    x: 4062.5,
    y: -0.16899871826171875,
  },
  {
    x: 4066.40625,
    y: -0.15903091430664062,
  },
  {
    x: 4070.3125,
    y: -0.14408111572265625,
  },
  {
    x: 4074.21875,
    y: -0.1241455078125,
  },
  {
    x: 4078.125,
    y: -0.10919570922851562,
  },
  {
    x: 4093.75,
    y: -0.049396514892578125,
  },
  {
    x: 4097.65625,
    y: -0.034442901611328125,
  },
  {
    x: 4101.5625,
    y: -0.01949310302734375,
  },
  {
    x: 4113.28125,
    y: 0.025356292724609375,
  },
  {
    x: 4117.1875,
    y: 0.040309906005859375,
  },
  {
    x: 4121.09375,
    y: 0.05525970458984375,
  },
  {
    x: 4125,
    y: 0.06522369384765625,
  },
  {
    x: 4128.90625,
    y: 0.08017730712890625,
  },
  {
    x: 4132.8125,
    y: 0.08017730712890625,
  },
  {
    x: 4136.71875,
    y: 0.0851593017578125,
  },
  {
    x: 4140.625,
    y: 0.0851593017578125,
  },
  {
    x: 4156.25,
    y: 0.0851593017578125,
  },
  {
    x: 4160.15625,
    y: 0.09014511108398438,
  },
  {
    x: 4164.0625,
    y: 0.09014511108398438,
  },
  {
    x: 4187.5,
    y: 0.09014511108398438,
  },
  {
    x: 4191.40625,
    y: 0.09512710571289062,
  },
  {
    x: 4195.3125,
    y: 0.09512710571289062,
  },
  {
    x: 4660.15625,
    y: 0.09512710571289062,
  },
  {
    x: 4664.0625,
    y: 0.125030517578125,
  },
  {
    x: 4667.96875,
    y: 0.21473312377929688,
  },
  {
    x: 4671.875,
    y: 0.31439971923828125,
  },
  {
    x: 4675.78125,
    y: 0.424041748046875,
  },
  {
    x: 4679.6875,
    y: 0.5336761474609375,
  },
  {
    x: 4683.59375,
    y: 0.643310546875,
  },
  {
    x: 4687.5,
    y: 0.7529525756835938,
  },
  {
    x: 4691.40625,
    y: 0.85760498046875,
  },
  {
    x: 4695.3125,
    y: 0.9622573852539062,
  },
  {
    x: 4699.21875,
    y: 1.0669136047363281,
  },
  {
    x: 4703.125,
    y: 1.1167488098144531,
  },
  {
    x: 4707.03125,
    y: 1.0669136047363281,
  },
  {
    x: 4710.9375,
    y: 0.9772109985351562,
  },
  {
    x: 4714.84375,
    y: 0.8675727844238281,
  },
  {
    x: 4718.75,
    y: 0.7579345703125,
  },
  {
    x: 4722.65625,
    y: 0.643310546875,
  },
  {
    x: 4726.5625,
    y: 0.5336761474609375,
  },
  {
    x: 4730.46875,
    y: 0.424041748046875,
  },
  {
    x: 4734.375,
    y: 0.31439971923828125,
  },
  {
    x: 4738.28125,
    y: 0.209747314453125,
  },
  {
    x: 4742.1875,
    y: 0.12004470825195312,
  },
  {
    x: 4746.09375,
    y: 0.0851593017578125,
  },
  {
    x: 4750,
    y: 0.0751953125,
  },
  {
    x: 4753.90625,
    y: 0.07020950317382812,
  },
  {
    x: 4757.8125,
    y: 0.07020950317382812,
  },
  {
    x: 4777.34375,
    y: 0.07020950317382812,
  },
  {
    x: 4781.25,
    y: 0.0751953125,
  },
  {
    x: 4785.15625,
    y: 0.0751953125,
  },
  {
    x: 4808.59375,
    y: 0.0751953125,
  },
  {
    x: 4812.5,
    y: 0.08017730712890625,
  },
  {
    x: 4816.40625,
    y: 0.08017730712890625,
  },
  {
    x: 4839.84375,
    y: 0.08017730712890625,
  },
  {
    x: 4843.75,
    y: 0.0851593017578125,
  },
  {
    x: 4847.65625,
    y: 0.0851593017578125,
  },
  {
    x: 4871.09375,
    y: 0.0851593017578125,
  },
  {
    x: 4875,
    y: 0.09014511108398438,
  },
  {
    x: 4878.90625,
    y: 0.09014511108398438,
  },
  {
    x: 4902.34375,
    y: 0.09014511108398438,
  },
  {
    x: 4906.25,
    y: 0.09512710571289062,
  },
  {
    x: 4910.15625,
    y: 0.09512710571289062,
  },
  {
    x: 4953.125,
    y: 0.09512710571289062,
  },
  {
    x: 4957.03125,
    y: 0.0851593017578125,
  },
  {
    x: 4960.9375,
    y: 0.06024169921875,
  },
  {
    x: 4964.84375,
    y: 0.025356292724609375,
  },
  {
    x: 4968.75,
    y: 0.00542449951171875,
  },
  {
    x: 4972.65625,
    y: -0.00952911376953125,
  },
  {
    x: 4976.5625,
    y: -0.024478912353515625,
  },
  {
    x: 4980.46875,
    y: -0.034442901611328125,
  },
  {
    x: 4984.375,
    y: -0.04441070556640625,
  },
  {
    x: 4992.1875,
    y: -0.0643463134765625,
  },
  {
    x: 4996.09375,
    y: -0.074310302734375,
  },
  {
    x: 5000,
    y: -0.089263916015625,
  },
  {
    x: 5003.90625,
    y: -0.09923171997070312,
  },
  {
    x: 5007.8125,
    y: -0.10919570922851562,
  },
  {
    x: 5011.71875,
    y: -0.11916351318359375,
  },
  {
    x: 5023.4375,
    y: -0.14906692504882812,
  },
  {
    x: 5027.34375,
    y: -0.15903091430664062,
  },
  {
    x: 5031.25,
    y: -0.16899871826171875,
  },
  {
    x: 5039.0625,
    y: -0.16899871826171875,
  },
  {
    x: 5042.96875,
    y: -0.15903091430664062,
  },
  {
    x: 5046.875,
    y: -0.14408111572265625,
  },
  {
    x: 5050.78125,
    y: -0.1241455078125,
  },
  {
    x: 5054.6875,
    y: -0.10919570922851562,
  },
  {
    x: 5070.3125,
    y: -0.049396514892578125,
  },
  {
    x: 5074.21875,
    y: -0.034442901611328125,
  },
  {
    x: 5078.125,
    y: -0.01949310302734375,
  },
  {
    x: 5089.84375,
    y: 0.025356292724609375,
  },
  {
    x: 5093.75,
    y: 0.040309906005859375,
  },
  {
    x: 5097.65625,
    y: 0.05525970458984375,
  },
  {
    x: 5101.5625,
    y: 0.06522369384765625,
  },
  {
    x: 5105.46875,
    y: 0.08017730712890625,
  },
  {
    x: 5109.375,
    y: 0.08017730712890625,
  },
  {
    x: 5113.28125,
    y: 0.0851593017578125,
  },
  {
    x: 5117.1875,
    y: 0.0851593017578125,
  },
  {
    x: 5132.8125,
    y: 0.0851593017578125,
  },
  {
    x: 5136.71875,
    y: 0.09014511108398438,
  },
  {
    x: 5140.625,
    y: 0.09014511108398438,
  },
  {
    x: 5164.0625,
    y: 0.09014511108398438,
  },
  {
    x: 5167.96875,
    y: 0.09512710571289062,
  },
  {
    x: 5171.875,
    y: 0.09512710571289062,
  },
  {
    x: 5636.71875,
    y: 0.09512710571289062,
  },
  {
    x: 5640.625,
    y: 0.125030517578125,
  },
  {
    x: 5644.53125,
    y: 0.21473312377929688,
  },
  {
    x: 5648.4375,
    y: 0.31439971923828125,
  },
  {
    x: 5652.34375,
    y: 0.424041748046875,
  },
  {
    x: 5656.25,
    y: 0.5336761474609375,
  },
  {
    x: 5660.15625,
    y: 0.643310546875,
  },
  {
    x: 5664.0625,
    y: 0.7529525756835938,
  },
  {
    x: 5667.96875,
    y: 0.85760498046875,
  },
  {
    x: 5671.875,
    y: 0.9622573852539062,
  },
  {
    x: 5675.78125,
    y: 1.0669136047363281,
  },
  {
    x: 5679.6875,
    y: 1.1167488098144531,
  },
  {
    x: 5683.59375,
    y: 1.0669136047363281,
  },
  {
    x: 5687.5,
    y: 0.9772109985351562,
  },
  {
    x: 5691.40625,
    y: 0.8675727844238281,
  },
  {
    x: 5695.3125,
    y: 0.7579345703125,
  },
  {
    x: 5699.21875,
    y: 0.643310546875,
  },
  {
    x: 5703.125,
    y: 0.5336761474609375,
  },
  {
    x: 5707.03125,
    y: 0.424041748046875,
  },
  {
    x: 5710.9375,
    y: 0.31439971923828125,
  },
  {
    x: 5714.84375,
    y: 0.209747314453125,
  },
  {
    x: 5718.75,
    y: 0.12004470825195312,
  },
  {
    x: 5722.65625,
    y: 0.0851593017578125,
  },
  {
    x: 5726.5625,
    y: 0.0751953125,
  },
  {
    x: 5730.46875,
    y: 0.07020950317382812,
  },
  {
    x: 5734.375,
    y: 0.07020950317382812,
  },
  {
    x: 5746.09375,
    y: 0.07020950317382812,
  },
  {
    x: 5750,
    y: 0.09512710571289062,
  },
  {
    x: 5753.90625,
    y: 0.09512710571289062,
  },
  {
    x: 6000,
    y: 0.09512710571289062,
  },
];
export const V6 = [
  {
    x: 0,
    y: 0.110076904296875,
  },
  {
    x: 175.78125,
    y: 0.110076904296875,
  },
  {
    x: 179.6875,
    y: 0.1349945068359375,
  },
  {
    x: 183.59375,
    y: 0.18981552124023438,
  },
  {
    x: 187.5,
    y: 0.2595863342285156,
  },
  {
    x: 191.40625,
    y: 0.3343391418457031,
  },
  {
    x: 195.3125,
    y: 0.409088134765625,
  },
  {
    x: 199.21875,
    y: 0.4838409423828125,
  },
  {
    x: 203.125,
    y: 0.55859375,
  },
  {
    x: 207.03125,
    y: 0.6283645629882812,
  },
  {
    x: 210.9375,
    y: 0.7031173706054688,
  },
  {
    x: 214.84375,
    y: 0.7728843688964844,
  },
  {
    x: 218.75,
    y: 0.807769775390625,
  },
  {
    x: 222.65625,
    y: 0.7679023742675781,
  },
  {
    x: 226.5625,
    y: 0.6981315612792969,
  },
  {
    x: 230.46875,
    y: 0.6233787536621094,
  },
  {
    x: 234.375,
    y: 0.5436439514160156,
  },
  {
    x: 238.28125,
    y: 0.46390533447265625,
  },
  {
    x: 242.1875,
    y: 0.3841705322265625,
  },
  {
    x: 246.09375,
    y: 0.30443572998046875,
  },
  {
    x: 250,
    y: 0.22469711303710938,
  },
  {
    x: 253.90625,
    y: 0.14994430541992188,
  },
  {
    x: 257.8125,
    y: 0.08017730712890625,
  },
  {
    x: 261.71875,
    y: 0.06024169921875,
  },
  {
    x: 265.625,
    y: 0.05525970458984375,
  },
  {
    x: 269.53125,
    y: 0.050273895263671875,
  },
  {
    x: 273.4375,
    y: 0.050273895263671875,
  },
  {
    x: 292.96875,
    y: 0.050273895263671875,
  },
  {
    x: 296.875,
    y: 0.05525970458984375,
  },
  {
    x: 300.78125,
    y: 0.05525970458984375,
  },
  {
    x: 324.21875,
    y: 0.05525970458984375,
  },
  {
    x: 328.125,
    y: 0.06024169921875,
  },
  {
    x: 332.03125,
    y: 0.06024169921875,
  },
  {
    x: 355.46875,
    y: 0.06024169921875,
  },
  {
    x: 359.375,
    y: 0.06522369384765625,
  },
  {
    x: 363.28125,
    y: 0.06522369384765625,
  },
  {
    x: 386.71875,
    y: 0.06522369384765625,
  },
  {
    x: 390.625,
    y: 0.07020950317382812,
  },
  {
    x: 394.53125,
    y: 0.07020950317382812,
  },
  {
    x: 417.96875,
    y: 0.07020950317382812,
  },
  {
    x: 421.875,
    y: 0.0751953125,
  },
  {
    x: 425.78125,
    y: 0.0751953125,
  },
  {
    x: 449.21875,
    y: 0.0751953125,
  },
  {
    x: 453.125,
    y: 0.08017730712890625,
  },
  {
    x: 457.03125,
    y: 0.08017730712890625,
  },
  {
    x: 472.65625,
    y: 0.08017730712890625,
  },
  {
    x: 476.5625,
    y: 0.07020950317382812,
  },
  {
    x: 480.46875,
    y: 0.06522369384765625,
  },
  {
    x: 484.375,
    y: 0.06024169921875,
  },
  {
    x: 488.28125,
    y: 0.050273895263671875,
  },
  {
    x: 492.1875,
    y: 0.045291900634765625,
  },
  {
    x: 496.09375,
    y: 0.040309906005859375,
  },
  {
    x: 500,
    y: 0.03034210205078125,
  },
  {
    x: 503.90625,
    y: 0.020374298095703125,
  },
  {
    x: 507.8125,
    y: 0.015392303466796875,
  },
  {
    x: 511.71875,
    y: 0.00542449951171875,
  },
  {
    x: 515.625,
    y: 0.0004425048828125,
  },
  {
    x: 519.53125,
    y: -0.00952911376953125,
  },
  {
    x: 523.4375,
    y: -0.0145111083984375,
  },
  {
    x: 527.34375,
    y: -0.024478912353515625,
  },
  {
    x: 531.25,
    y: -0.029460906982421875,
  },
  {
    x: 535.15625,
    y: -0.034442901611328125,
  },
  {
    x: 539.0625,
    y: -0.04441070556640625,
  },
  {
    x: 542.96875,
    y: -0.049396514892578125,
  },
  {
    x: 546.875,
    y: -0.054378509521484375,
  },
  {
    x: 550.78125,
    y: -0.0643463134765625,
  },
  {
    x: 554.6875,
    y: -0.05936431884765625,
  },
  {
    x: 558.59375,
    y: -0.054378509521484375,
  },
  {
    x: 562.5,
    y: -0.04441070556640625,
  },
  {
    x: 566.40625,
    y: -0.029460906982421875,
  },
  {
    x: 570.3125,
    y: -0.01949310302734375,
  },
  {
    x: 574.21875,
    y: -0.00952911376953125,
  },
  {
    x: 578.125,
    y: 0.0004425048828125,
  },
  {
    x: 582.03125,
    y: 0.010406494140625,
  },
  {
    x: 585.9375,
    y: 0.020374298095703125,
  },
  {
    x: 593.75,
    y: 0.040309906005859375,
  },
  {
    x: 597.65625,
    y: 0.050273895263671875,
  },
  {
    x: 601.5625,
    y: 0.06024169921875,
  },
  {
    x: 605.46875,
    y: 0.07020950317382812,
  },
  {
    x: 609.375,
    y: 0.0751953125,
  },
  {
    x: 613.28125,
    y: 0.0851593017578125,
  },
  {
    x: 617.1875,
    y: 0.10010910034179688,
  },
  {
    x: 621.09375,
    y: 0.10509490966796875,
  },
  {
    x: 625,
    y: 0.110076904296875,
  },
  {
    x: 628.90625,
    y: 0.110076904296875,
  },
  {
    x: 1152.34375,
    y: 0.110076904296875,
  },
  {
    x: 1156.25,
    y: 0.1349945068359375,
  },
  {
    x: 1160.15625,
    y: 0.18981552124023438,
  },
  {
    x: 1164.0625,
    y: 0.2595863342285156,
  },
  {
    x: 1167.96875,
    y: 0.3343391418457031,
  },
  {
    x: 1171.875,
    y: 0.409088134765625,
  },
  {
    x: 1175.78125,
    y: 0.4838409423828125,
  },
  {
    x: 1179.6875,
    y: 0.55859375,
  },
  {
    x: 1183.59375,
    y: 0.6283645629882812,
  },
  {
    x: 1187.5,
    y: 0.7031173706054688,
  },
  {
    x: 1191.40625,
    y: 0.7728843688964844,
  },
  {
    x: 1195.3125,
    y: 0.807769775390625,
  },
  {
    x: 1199.21875,
    y: 0.7679023742675781,
  },
  {
    x: 1203.125,
    y: 0.6981315612792969,
  },
  {
    x: 1207.03125,
    y: 0.6233787536621094,
  },
  {
    x: 1210.9375,
    y: 0.5436439514160156,
  },
  {
    x: 1214.84375,
    y: 0.46390533447265625,
  },
  {
    x: 1218.75,
    y: 0.3841705322265625,
  },
  {
    x: 1222.65625,
    y: 0.30443572998046875,
  },
  {
    x: 1226.5625,
    y: 0.22469711303710938,
  },
  {
    x: 1230.46875,
    y: 0.14994430541992188,
  },
  {
    x: 1234.375,
    y: 0.08017730712890625,
  },
  {
    x: 1238.28125,
    y: 0.06024169921875,
  },
  {
    x: 1242.1875,
    y: 0.05525970458984375,
  },
  {
    x: 1246.09375,
    y: 0.050273895263671875,
  },
  {
    x: 1250,
    y: 0.050273895263671875,
  },
  {
    x: 1269.53125,
    y: 0.050273895263671875,
  },
  {
    x: 1273.4375,
    y: 0.05525970458984375,
  },
  {
    x: 1277.34375,
    y: 0.05525970458984375,
  },
  {
    x: 1300.78125,
    y: 0.05525970458984375,
  },
  {
    x: 1304.6875,
    y: 0.06024169921875,
  },
  {
    x: 1308.59375,
    y: 0.06024169921875,
  },
  {
    x: 1332.03125,
    y: 0.06024169921875,
  },
  {
    x: 1335.9375,
    y: 0.06522369384765625,
  },
  {
    x: 1339.84375,
    y: 0.06522369384765625,
  },
  {
    x: 1363.28125,
    y: 0.06522369384765625,
  },
  {
    x: 1367.1875,
    y: 0.07020950317382812,
  },
  {
    x: 1371.09375,
    y: 0.07020950317382812,
  },
  {
    x: 1394.53125,
    y: 0.07020950317382812,
  },
  {
    x: 1398.4375,
    y: 0.0751953125,
  },
  {
    x: 1402.34375,
    y: 0.0751953125,
  },
  {
    x: 1425.78125,
    y: 0.0751953125,
  },
  {
    x: 1429.6875,
    y: 0.08017730712890625,
  },
  {
    x: 1433.59375,
    y: 0.08017730712890625,
  },
  {
    x: 1449.21875,
    y: 0.08017730712890625,
  },
  {
    x: 1453.125,
    y: 0.07020950317382812,
  },
  {
    x: 1457.03125,
    y: 0.06522369384765625,
  },
  {
    x: 1460.9375,
    y: 0.06024169921875,
  },
  {
    x: 1464.84375,
    y: 0.050273895263671875,
  },
  {
    x: 1468.75,
    y: 0.045291900634765625,
  },
  {
    x: 1472.65625,
    y: 0.040309906005859375,
  },
  {
    x: 1476.5625,
    y: 0.03034210205078125,
  },
  {
    x: 1480.46875,
    y: 0.020374298095703125,
  },
  {
    x: 1484.375,
    y: 0.015392303466796875,
  },
  {
    x: 1488.28125,
    y: 0.00542449951171875,
  },
  {
    x: 1492.1875,
    y: 0.0004425048828125,
  },
  {
    x: 1496.09375,
    y: -0.00952911376953125,
  },
  {
    x: 1500,
    y: -0.0145111083984375,
  },
  {
    x: 1503.90625,
    y: -0.024478912353515625,
  },
  {
    x: 1507.8125,
    y: -0.029460906982421875,
  },
  {
    x: 1511.71875,
    y: -0.034442901611328125,
  },
  {
    x: 1515.625,
    y: -0.04441070556640625,
  },
  {
    x: 1519.53125,
    y: -0.049396514892578125,
  },
  {
    x: 1523.4375,
    y: -0.054378509521484375,
  },
  {
    x: 1527.34375,
    y: -0.0643463134765625,
  },
  {
    x: 1531.25,
    y: -0.05936431884765625,
  },
  {
    x: 1535.15625,
    y: -0.054378509521484375,
  },
  {
    x: 1539.0625,
    y: -0.04441070556640625,
  },
  {
    x: 1542.96875,
    y: -0.029460906982421875,
  },
  {
    x: 1546.875,
    y: -0.01949310302734375,
  },
  {
    x: 1550.78125,
    y: -0.00952911376953125,
  },
  {
    x: 1554.6875,
    y: 0.0004425048828125,
  },
  {
    x: 1558.59375,
    y: 0.010406494140625,
  },
  {
    x: 1562.5,
    y: 0.020374298095703125,
  },
  {
    x: 1570.3125,
    y: 0.040309906005859375,
  },
  {
    x: 1574.21875,
    y: 0.050273895263671875,
  },
  {
    x: 1578.125,
    y: 0.06024169921875,
  },
  {
    x: 1582.03125,
    y: 0.07020950317382812,
  },
  {
    x: 1585.9375,
    y: 0.0751953125,
  },
  {
    x: 1589.84375,
    y: 0.0851593017578125,
  },
  {
    x: 1593.75,
    y: 0.10010910034179688,
  },
  {
    x: 1597.65625,
    y: 0.10509490966796875,
  },
  {
    x: 1601.5625,
    y: 0.110076904296875,
  },
  {
    x: 1605.46875,
    y: 0.110076904296875,
  },
  {
    x: 2128.90625,
    y: 0.110076904296875,
  },
  {
    x: 2132.8125,
    y: 0.1349945068359375,
  },
  {
    x: 2136.71875,
    y: 0.18981552124023438,
  },
  {
    x: 2140.625,
    y: 0.2595863342285156,
  },
  {
    x: 2144.53125,
    y: 0.3343391418457031,
  },
  {
    x: 2148.4375,
    y: 0.409088134765625,
  },
  {
    x: 2152.34375,
    y: 0.4838409423828125,
  },
  {
    x: 2156.25,
    y: 0.55859375,
  },
  {
    x: 2160.15625,
    y: 0.6283645629882812,
  },
  {
    x: 2164.0625,
    y: 0.7031173706054688,
  },
  {
    x: 2167.96875,
    y: 0.7728843688964844,
  },
  {
    x: 2171.875,
    y: 0.807769775390625,
  },
  {
    x: 2175.78125,
    y: 0.7679023742675781,
  },
  {
    x: 2179.6875,
    y: 0.6981315612792969,
  },
  {
    x: 2183.59375,
    y: 0.6233787536621094,
  },
  {
    x: 2187.5,
    y: 0.5436439514160156,
  },
  {
    x: 2191.40625,
    y: 0.46390533447265625,
  },
  {
    x: 2195.3125,
    y: 0.3841705322265625,
  },
  {
    x: 2199.21875,
    y: 0.30443572998046875,
  },
  {
    x: 2203.125,
    y: 0.22469711303710938,
  },
  {
    x: 2207.03125,
    y: 0.14994430541992188,
  },
  {
    x: 2210.9375,
    y: 0.08017730712890625,
  },
  {
    x: 2214.84375,
    y: 0.06024169921875,
  },
  {
    x: 2218.75,
    y: 0.05525970458984375,
  },
  {
    x: 2222.65625,
    y: 0.050273895263671875,
  },
  {
    x: 2226.5625,
    y: 0.050273895263671875,
  },
  {
    x: 2246.09375,
    y: 0.050273895263671875,
  },
  {
    x: 2250,
    y: 0.05525970458984375,
  },
  {
    x: 2253.90625,
    y: 0.05525970458984375,
  },
  {
    x: 2277.34375,
    y: 0.05525970458984375,
  },
  {
    x: 2281.25,
    y: 0.06024169921875,
  },
  {
    x: 2285.15625,
    y: 0.06024169921875,
  },
  {
    x: 2308.59375,
    y: 0.06024169921875,
  },
  {
    x: 2312.5,
    y: 0.06522369384765625,
  },
  {
    x: 2316.40625,
    y: 0.06522369384765625,
  },
  {
    x: 2339.84375,
    y: 0.06522369384765625,
  },
  {
    x: 2343.75,
    y: 0.07020950317382812,
  },
  {
    x: 2347.65625,
    y: 0.07020950317382812,
  },
  {
    x: 2371.09375,
    y: 0.07020950317382812,
  },
  {
    x: 2375,
    y: 0.0751953125,
  },
  {
    x: 2378.90625,
    y: 0.0751953125,
  },
  {
    x: 2402.34375,
    y: 0.0751953125,
  },
  {
    x: 2406.25,
    y: 0.08017730712890625,
  },
  {
    x: 2410.15625,
    y: 0.08017730712890625,
  },
  {
    x: 2425.78125,
    y: 0.08017730712890625,
  },
  {
    x: 2429.6875,
    y: 0.07020950317382812,
  },
  {
    x: 2433.59375,
    y: 0.06522369384765625,
  },
  {
    x: 2437.5,
    y: 0.06024169921875,
  },
  {
    x: 2441.40625,
    y: 0.050273895263671875,
  },
  {
    x: 2445.3125,
    y: 0.045291900634765625,
  },
  {
    x: 2449.21875,
    y: 0.040309906005859375,
  },
  {
    x: 2453.125,
    y: 0.03034210205078125,
  },
  {
    x: 2457.03125,
    y: 0.020374298095703125,
  },
  {
    x: 2460.9375,
    y: 0.015392303466796875,
  },
  {
    x: 2464.84375,
    y: 0.00542449951171875,
  },
  {
    x: 2468.75,
    y: 0.0004425048828125,
  },
  {
    x: 2472.65625,
    y: -0.00952911376953125,
  },
  {
    x: 2476.5625,
    y: -0.0145111083984375,
  },
  {
    x: 2480.46875,
    y: -0.024478912353515625,
  },
  {
    x: 2484.375,
    y: -0.029460906982421875,
  },
  {
    x: 2488.28125,
    y: -0.034442901611328125,
  },
  {
    x: 2492.1875,
    y: -0.04441070556640625,
  },
  {
    x: 2496.09375,
    y: -0.049396514892578125,
  },
  {
    x: 2500,
    y: -0.054378509521484375,
  },
  {
    x: 2503.90625,
    y: -0.0643463134765625,
  },
  {
    x: 2507.8125,
    y: -0.05936431884765625,
  },
  {
    x: 2511.71875,
    y: -0.049396514892578125,
  },
  {
    x: 2515.625,
    y: -0.04441070556640625,
  },
  {
    x: 2519.53125,
    y: -0.029460906982421875,
  },
  {
    x: 2523.4375,
    y: -0.01949310302734375,
  },
  {
    x: 2527.34375,
    y: -0.00952911376953125,
  },
  {
    x: 2531.25,
    y: 0.0004425048828125,
  },
  {
    x: 2535.15625,
    y: 0.010406494140625,
  },
  {
    x: 2539.0625,
    y: 0.020374298095703125,
  },
  {
    x: 2546.875,
    y: 0.040309906005859375,
  },
  {
    x: 2550.78125,
    y: 0.050273895263671875,
  },
  {
    x: 2554.6875,
    y: 0.06024169921875,
  },
  {
    x: 2558.59375,
    y: 0.07020950317382812,
  },
  {
    x: 2562.5,
    y: 0.0751953125,
  },
  {
    x: 2566.40625,
    y: 0.0851593017578125,
  },
  {
    x: 2570.3125,
    y: 0.10010910034179688,
  },
  {
    x: 2574.21875,
    y: 0.10509490966796875,
  },
  {
    x: 2578.125,
    y: 0.110076904296875,
  },
  {
    x: 2582.03125,
    y: 0.110076904296875,
  },
  {
    x: 3105.46875,
    y: 0.110076904296875,
  },
  {
    x: 3109.375,
    y: 0.1349945068359375,
  },
  {
    x: 3113.28125,
    y: 0.18981552124023438,
  },
  {
    x: 3117.1875,
    y: 0.2595863342285156,
  },
  {
    x: 3121.09375,
    y: 0.3343391418457031,
  },
  {
    x: 3125,
    y: 0.409088134765625,
  },
  {
    x: 3128.90625,
    y: 0.4838409423828125,
  },
  {
    x: 3132.8125,
    y: 0.55859375,
  },
  {
    x: 3136.71875,
    y: 0.6283645629882812,
  },
  {
    x: 3140.625,
    y: 0.7031173706054688,
  },
  {
    x: 3144.53125,
    y: 0.7728843688964844,
  },
  {
    x: 3148.4375,
    y: 0.807769775390625,
  },
  {
    x: 3152.34375,
    y: 0.7679023742675781,
  },
  {
    x: 3156.25,
    y: 0.6981315612792969,
  },
  {
    x: 3160.15625,
    y: 0.6233787536621094,
  },
  {
    x: 3164.0625,
    y: 0.5436439514160156,
  },
  {
    x: 3167.96875,
    y: 0.46390533447265625,
  },
  {
    x: 3171.875,
    y: 0.3841705322265625,
  },
  {
    x: 3175.78125,
    y: 0.30443572998046875,
  },
  {
    x: 3179.6875,
    y: 0.22469711303710938,
  },
  {
    x: 3183.59375,
    y: 0.14994430541992188,
  },
  {
    x: 3187.5,
    y: 0.08017730712890625,
  },
  {
    x: 3191.40625,
    y: 0.06024169921875,
  },
  {
    x: 3195.3125,
    y: 0.05525970458984375,
  },
  {
    x: 3199.21875,
    y: 0.050273895263671875,
  },
  {
    x: 3203.125,
    y: 0.050273895263671875,
  },
  {
    x: 3222.65625,
    y: 0.050273895263671875,
  },
  {
    x: 3226.5625,
    y: 0.05525970458984375,
  },
  {
    x: 3230.46875,
    y: 0.05525970458984375,
  },
  {
    x: 3253.90625,
    y: 0.05525970458984375,
  },
  {
    x: 3257.8125,
    y: 0.06024169921875,
  },
  {
    x: 3261.71875,
    y: 0.06024169921875,
  },
  {
    x: 3285.15625,
    y: 0.06024169921875,
  },
  {
    x: 3289.0625,
    y: 0.06522369384765625,
  },
  {
    x: 3292.96875,
    y: 0.06522369384765625,
  },
  {
    x: 3316.40625,
    y: 0.06522369384765625,
  },
  {
    x: 3320.3125,
    y: 0.07020950317382812,
  },
  {
    x: 3324.21875,
    y: 0.07020950317382812,
  },
  {
    x: 3347.65625,
    y: 0.07020950317382812,
  },
  {
    x: 3351.5625,
    y: 0.0751953125,
  },
  {
    x: 3355.46875,
    y: 0.0751953125,
  },
  {
    x: 3378.90625,
    y: 0.0751953125,
  },
  {
    x: 3382.8125,
    y: 0.08017730712890625,
  },
  {
    x: 3386.71875,
    y: 0.08017730712890625,
  },
  {
    x: 3402.34375,
    y: 0.08017730712890625,
  },
  {
    x: 3406.25,
    y: 0.07020950317382812,
  },
  {
    x: 3410.15625,
    y: 0.06522369384765625,
  },
  {
    x: 3414.0625,
    y: 0.06024169921875,
  },
  {
    x: 3417.96875,
    y: 0.050273895263671875,
  },
  {
    x: 3421.875,
    y: 0.045291900634765625,
  },
  {
    x: 3425.78125,
    y: 0.040309906005859375,
  },
  {
    x: 3429.6875,
    y: 0.03034210205078125,
  },
  {
    x: 3433.59375,
    y: 0.020374298095703125,
  },
  {
    x: 3437.5,
    y: 0.015392303466796875,
  },
  {
    x: 3441.40625,
    y: 0.00542449951171875,
  },
  {
    x: 3445.3125,
    y: 0.0004425048828125,
  },
  {
    x: 3449.21875,
    y: -0.00952911376953125,
  },
  {
    x: 3453.125,
    y: -0.0145111083984375,
  },
  {
    x: 3457.03125,
    y: -0.024478912353515625,
  },
  {
    x: 3460.9375,
    y: -0.029460906982421875,
  },
  {
    x: 3464.84375,
    y: -0.034442901611328125,
  },
  {
    x: 3468.75,
    y: -0.04441070556640625,
  },
  {
    x: 3472.65625,
    y: -0.049396514892578125,
  },
  {
    x: 3476.5625,
    y: -0.054378509521484375,
  },
  {
    x: 3480.46875,
    y: -0.0643463134765625,
  },
  {
    x: 3484.375,
    y: -0.05936431884765625,
  },
  {
    x: 3488.28125,
    y: -0.049396514892578125,
  },
  {
    x: 3492.1875,
    y: -0.04441070556640625,
  },
  {
    x: 3496.09375,
    y: -0.029460906982421875,
  },
  {
    x: 3500,
    y: -0.01949310302734375,
  },
  {
    x: 3503.90625,
    y: -0.00952911376953125,
  },
  {
    x: 3507.8125,
    y: 0.0004425048828125,
  },
  {
    x: 3511.71875,
    y: 0.010406494140625,
  },
  {
    x: 3515.625,
    y: 0.020374298095703125,
  },
  {
    x: 3523.4375,
    y: 0.040309906005859375,
  },
  {
    x: 3527.34375,
    y: 0.050273895263671875,
  },
  {
    x: 3531.25,
    y: 0.06024169921875,
  },
  {
    x: 3535.15625,
    y: 0.07020950317382812,
  },
  {
    x: 3539.0625,
    y: 0.0751953125,
  },
  {
    x: 3542.96875,
    y: 0.0851593017578125,
  },
  {
    x: 3546.875,
    y: 0.10010910034179688,
  },
  {
    x: 3550.78125,
    y: 0.10509490966796875,
  },
  {
    x: 3554.6875,
    y: 0.110076904296875,
  },
  {
    x: 3558.59375,
    y: 0.110076904296875,
  },
  {
    x: 4082.03125,
    y: 0.110076904296875,
  },
  {
    x: 4085.9375,
    y: 0.1349945068359375,
  },
  {
    x: 4089.84375,
    y: 0.18981552124023438,
  },
  {
    x: 4093.75,
    y: 0.2595863342285156,
  },
  {
    x: 4097.65625,
    y: 0.3343391418457031,
  },
  {
    x: 4101.5625,
    y: 0.409088134765625,
  },
  {
    x: 4105.46875,
    y: 0.4838409423828125,
  },
  {
    x: 4109.375,
    y: 0.55859375,
  },
  {
    x: 4113.28125,
    y: 0.6283645629882812,
  },
  {
    x: 4117.1875,
    y: 0.7031173706054688,
  },
  {
    x: 4121.09375,
    y: 0.7728843688964844,
  },
  {
    x: 4125,
    y: 0.807769775390625,
  },
  {
    x: 4128.90625,
    y: 0.7679023742675781,
  },
  {
    x: 4132.8125,
    y: 0.6981315612792969,
  },
  {
    x: 4136.71875,
    y: 0.6233787536621094,
  },
  {
    x: 4140.625,
    y: 0.5436439514160156,
  },
  {
    x: 4144.53125,
    y: 0.46390533447265625,
  },
  {
    x: 4148.4375,
    y: 0.3841705322265625,
  },
  {
    x: 4152.34375,
    y: 0.30443572998046875,
  },
  {
    x: 4156.25,
    y: 0.22469711303710938,
  },
  {
    x: 4160.15625,
    y: 0.14994430541992188,
  },
  {
    x: 4164.0625,
    y: 0.08017730712890625,
  },
  {
    x: 4167.96875,
    y: 0.06024169921875,
  },
  {
    x: 4171.875,
    y: 0.05525970458984375,
  },
  {
    x: 4175.78125,
    y: 0.050273895263671875,
  },
  {
    x: 4179.6875,
    y: 0.050273895263671875,
  },
  {
    x: 4199.21875,
    y: 0.050273895263671875,
  },
  {
    x: 4203.125,
    y: 0.05525970458984375,
  },
  {
    x: 4207.03125,
    y: 0.05525970458984375,
  },
  {
    x: 4230.46875,
    y: 0.05525970458984375,
  },
  {
    x: 4234.375,
    y: 0.06024169921875,
  },
  {
    x: 4238.28125,
    y: 0.06024169921875,
  },
  {
    x: 4261.71875,
    y: 0.06024169921875,
  },
  {
    x: 4265.625,
    y: 0.06522369384765625,
  },
  {
    x: 4269.53125,
    y: 0.06522369384765625,
  },
  {
    x: 4292.96875,
    y: 0.06522369384765625,
  },
  {
    x: 4296.875,
    y: 0.07020950317382812,
  },
  {
    x: 4300.78125,
    y: 0.07020950317382812,
  },
  {
    x: 4324.21875,
    y: 0.07020950317382812,
  },
  {
    x: 4328.125,
    y: 0.0751953125,
  },
  {
    x: 4332.03125,
    y: 0.0751953125,
  },
  {
    x: 4355.46875,
    y: 0.0751953125,
  },
  {
    x: 4359.375,
    y: 0.08017730712890625,
  },
  {
    x: 4363.28125,
    y: 0.08017730712890625,
  },
  {
    x: 4378.90625,
    y: 0.08017730712890625,
  },
  {
    x: 4382.8125,
    y: 0.07020950317382812,
  },
  {
    x: 4386.71875,
    y: 0.06522369384765625,
  },
  {
    x: 4390.625,
    y: 0.06024169921875,
  },
  {
    x: 4394.53125,
    y: 0.050273895263671875,
  },
  {
    x: 4398.4375,
    y: 0.045291900634765625,
  },
  {
    x: 4402.34375,
    y: 0.040309906005859375,
  },
  {
    x: 4406.25,
    y: 0.03034210205078125,
  },
  {
    x: 4410.15625,
    y: 0.020374298095703125,
  },
  {
    x: 4414.0625,
    y: 0.015392303466796875,
  },
  {
    x: 4417.96875,
    y: 0.00542449951171875,
  },
  {
    x: 4421.875,
    y: 0.0004425048828125,
  },
  {
    x: 4425.78125,
    y: -0.00952911376953125,
  },
  {
    x: 4429.6875,
    y: -0.0145111083984375,
  },
  {
    x: 4433.59375,
    y: -0.024478912353515625,
  },
  {
    x: 4437.5,
    y: -0.029460906982421875,
  },
  {
    x: 4441.40625,
    y: -0.034442901611328125,
  },
  {
    x: 4445.3125,
    y: -0.04441070556640625,
  },
  {
    x: 4449.21875,
    y: -0.049396514892578125,
  },
  {
    x: 4453.125,
    y: -0.054378509521484375,
  },
  {
    x: 4457.03125,
    y: -0.0643463134765625,
  },
  {
    x: 4460.9375,
    y: -0.05936431884765625,
  },
  {
    x: 4464.84375,
    y: -0.054378509521484375,
  },
  {
    x: 4468.75,
    y: -0.04441070556640625,
  },
  {
    x: 4472.65625,
    y: -0.029460906982421875,
  },
  {
    x: 4476.5625,
    y: -0.01949310302734375,
  },
  {
    x: 4480.46875,
    y: -0.00952911376953125,
  },
  {
    x: 4484.375,
    y: 0.0004425048828125,
  },
  {
    x: 4488.28125,
    y: 0.010406494140625,
  },
  {
    x: 4492.1875,
    y: 0.020374298095703125,
  },
  {
    x: 4500,
    y: 0.040309906005859375,
  },
  {
    x: 4503.90625,
    y: 0.050273895263671875,
  },
  {
    x: 4507.8125,
    y: 0.06024169921875,
  },
  {
    x: 4511.71875,
    y: 0.07020950317382812,
  },
  {
    x: 4515.625,
    y: 0.0751953125,
  },
  {
    x: 4519.53125,
    y: 0.0851593017578125,
  },
  {
    x: 4523.4375,
    y: 0.10010910034179688,
  },
  {
    x: 4527.34375,
    y: 0.10509490966796875,
  },
  {
    x: 4531.25,
    y: 0.110076904296875,
  },
  {
    x: 4535.15625,
    y: 0.110076904296875,
  },
  {
    x: 5058.59375,
    y: 0.110076904296875,
  },
  {
    x: 5062.5,
    y: 0.1349945068359375,
  },
  {
    x: 5066.40625,
    y: 0.18981552124023438,
  },
  {
    x: 5070.3125,
    y: 0.2595863342285156,
  },
  {
    x: 5074.21875,
    y: 0.3343391418457031,
  },
  {
    x: 5078.125,
    y: 0.409088134765625,
  },
  {
    x: 5082.03125,
    y: 0.4838409423828125,
  },
  {
    x: 5085.9375,
    y: 0.55859375,
  },
  {
    x: 5089.84375,
    y: 0.6283645629882812,
  },
  {
    x: 5093.75,
    y: 0.7031173706054688,
  },
  {
    x: 5097.65625,
    y: 0.7728843688964844,
  },
  {
    x: 5101.5625,
    y: 0.807769775390625,
  },
  {
    x: 5105.46875,
    y: 0.7679023742675781,
  },
  {
    x: 5109.375,
    y: 0.6981315612792969,
  },
  {
    x: 5113.28125,
    y: 0.6233787536621094,
  },
  {
    x: 5117.1875,
    y: 0.5436439514160156,
  },
  {
    x: 5121.09375,
    y: 0.46390533447265625,
  },
  {
    x: 5125,
    y: 0.3841705322265625,
  },
  {
    x: 5128.90625,
    y: 0.30443572998046875,
  },
  {
    x: 5132.8125,
    y: 0.22469711303710938,
  },
  {
    x: 5136.71875,
    y: 0.14994430541992188,
  },
  {
    x: 5140.625,
    y: 0.08017730712890625,
  },
  {
    x: 5144.53125,
    y: 0.06024169921875,
  },
  {
    x: 5148.4375,
    y: 0.05525970458984375,
  },
  {
    x: 5152.34375,
    y: 0.050273895263671875,
  },
  {
    x: 5156.25,
    y: 0.050273895263671875,
  },
  {
    x: 5175.78125,
    y: 0.050273895263671875,
  },
  {
    x: 5179.6875,
    y: 0.05525970458984375,
  },
  {
    x: 5183.59375,
    y: 0.05525970458984375,
  },
  {
    x: 5207.03125,
    y: 0.05525970458984375,
  },
  {
    x: 5210.9375,
    y: 0.06024169921875,
  },
  {
    x: 5214.84375,
    y: 0.06024169921875,
  },
  {
    x: 5238.28125,
    y: 0.06024169921875,
  },
  {
    x: 5242.1875,
    y: 0.06522369384765625,
  },
  {
    x: 5246.09375,
    y: 0.06522369384765625,
  },
  {
    x: 5269.53125,
    y: 0.06522369384765625,
  },
  {
    x: 5273.4375,
    y: 0.07020950317382812,
  },
  {
    x: 5277.34375,
    y: 0.07020950317382812,
  },
  {
    x: 5300.78125,
    y: 0.07020950317382812,
  },
  {
    x: 5304.6875,
    y: 0.0751953125,
  },
  {
    x: 5308.59375,
    y: 0.0751953125,
  },
  {
    x: 5332.03125,
    y: 0.0751953125,
  },
  {
    x: 5335.9375,
    y: 0.08017730712890625,
  },
  {
    x: 5339.84375,
    y: 0.08017730712890625,
  },
  {
    x: 5355.46875,
    y: 0.08017730712890625,
  },
  {
    x: 5359.375,
    y: 0.07020950317382812,
  },
  {
    x: 5363.28125,
    y: 0.06522369384765625,
  },
  {
    x: 5367.1875,
    y: 0.06024169921875,
  },
  {
    x: 5371.09375,
    y: 0.050273895263671875,
  },
  {
    x: 5375,
    y: 0.045291900634765625,
  },
  {
    x: 5378.90625,
    y: 0.040309906005859375,
  },
  {
    x: 5382.8125,
    y: 0.03034210205078125,
  },
  {
    x: 5386.71875,
    y: 0.020374298095703125,
  },
  {
    x: 5390.625,
    y: 0.015392303466796875,
  },
  {
    x: 5394.53125,
    y: 0.00542449951171875,
  },
  {
    x: 5398.4375,
    y: 0.0004425048828125,
  },
  {
    x: 5402.34375,
    y: -0.00952911376953125,
  },
  {
    x: 5406.25,
    y: -0.0145111083984375,
  },
  {
    x: 5410.15625,
    y: -0.024478912353515625,
  },
  {
    x: 5414.0625,
    y: -0.029460906982421875,
  },
  {
    x: 5417.96875,
    y: -0.034442901611328125,
  },
  {
    x: 5421.875,
    y: -0.04441070556640625,
  },
  {
    x: 5425.78125,
    y: -0.049396514892578125,
  },
  {
    x: 5429.6875,
    y: -0.054378509521484375,
  },
  {
    x: 5433.59375,
    y: -0.0643463134765625,
  },
  {
    x: 5437.5,
    y: -0.05936431884765625,
  },
  {
    x: 5441.40625,
    y: -0.054378509521484375,
  },
  {
    x: 5445.3125,
    y: -0.04441070556640625,
  },
  {
    x: 5449.21875,
    y: -0.029460906982421875,
  },
  {
    x: 5453.125,
    y: -0.01949310302734375,
  },
  {
    x: 5457.03125,
    y: -0.00952911376953125,
  },
  {
    x: 5460.9375,
    y: 0.0004425048828125,
  },
  {
    x: 5464.84375,
    y: 0.010406494140625,
  },
  {
    x: 5468.75,
    y: 0.020374298095703125,
  },
  {
    x: 5476.5625,
    y: 0.040309906005859375,
  },
  {
    x: 5480.46875,
    y: 0.050273895263671875,
  },
  {
    x: 5484.375,
    y: 0.06024169921875,
  },
  {
    x: 5488.28125,
    y: 0.07020950317382812,
  },
  {
    x: 5492.1875,
    y: 0.0751953125,
  },
  {
    x: 5496.09375,
    y: 0.0851593017578125,
  },
  {
    x: 5500,
    y: 0.10010910034179688,
  },
  {
    x: 5503.90625,
    y: 0.10509490966796875,
  },
  {
    x: 5507.8125,
    y: 0.110076904296875,
  },
  {
    x: 5511.71875,
    y: 0.110076904296875,
  },
  {
    x: 6000,
    y: 0.110076904296875,
  },
];
export const waves24 = {
  i: [
    {
      x: 0,
      y: 0.10010910034179688,
    },
    {
      x: 191.57608695652175,
      y: 0.10010910034179688,
    },
    {
      x: 195.6521739130435,
      y: 0.09014511108398438,
    },
    {
      x: 199.72826086956522,
      y: 0.08017730712890625,
    },
    {
      x: 203.80434782608697,
      y: 0.0751953125,
    },
    {
      x: 207.8804347826087,
      y: 0.07020950317382812,
    },
    {
      x: 211.95652173913044,
      y: 0.06522369384765625,
    },
    {
      x: 216.0326086956522,
      y: 0.06522369384765625,
    },
    {
      x: 224.18478260869566,
      y: 0.06522369384765625,
    },
    {
      x: 228.2608695652174,
      y: 0.06024169921875,
    },
    {
      x: 232.33695652173913,
      y: 0.05525970458984375,
    },
    {
      x: 236.41304347826087,
      y: 0.05525970458984375,
    },
    {
      x: 240.48913043478262,
      y: 0.050273895263671875,
    },
    {
      x: 244.56521739130434,
      y: 0.045291900634765625,
    },
    {
      x: 248.6413043478261,
      y: 0.045291900634765625,
    },
    {
      x: 252.71739130434784,
      y: 0.045291900634765625,
    },
    {
      x: 256.79347826086956,
      y: 0.040309906005859375,
    },
    {
      x: 260.8695652173913,
      y: 0.10010910034179688,
    },
    {
      x: 264.94565217391306,
      y: 0.10010910034179688,
    },
    {
      x: 403.5326086956522,
      y: 0.10010910034179688,
    },
    {
      x: 407.60869565217394,
      y: 0.10509490966796875,
    },
    {
      x: 411.6847826086957,
      y: 0.1349945068359375,
    },
    {
      x: 415.7608695652174,
      y: 0.16489410400390625,
    },
    {
      x: 419.8369565217391,
      y: 0.19479751586914062,
    },
    {
      x: 423.9130434782609,
      y: 0.21971511840820312,
    },
    {
      x: 427.9891304347826,
      y: 0.24961471557617188,
    },
    {
      x: 432.0652173913044,
      y: 0.2745361328125,
    },
    {
      x: 436.1413043478261,
      y: 0.2994499206542969,
    },
    {
      x: 440.2173913043478,
      y: 0.3293495178222656,
    },
    {
      x: 444.29347826086956,
      y: 0.35427093505859375,
    },
    {
      x: 448.3695652173913,
      y: 0.3692207336425781,
    },
    {
      x: 452.44565217391306,
      y: 0.35427093505859375,
    },
    {
      x: 456.5217391304348,
      y: 0.3343391418457031,
    },
    {
      x: 460.59782608695656,
      y: 0.309417724609375,
    },
    {
      x: 464.67391304347825,
      y: 0.27951812744140625,
    },
    {
      x: 468.75,
      y: 0.24961471557617188,
    },
    {
      x: 472.82608695652175,
      y: 0.22469711303710938,
    },
    {
      x: 476.9021739130435,
      y: 0.1997833251953125,
    },
    {
      x: 480.97826086956525,
      y: 0.1698760986328125,
    },
    {
      x: 485.054347826087,
      y: 0.14496231079101562,
    },
    {
      x: 489.1304347826087,
      y: 0.11506271362304688,
    },
    {
      x: 493.20652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 497.2826086956522,
      y: 0.10010910034179688,
    },
    {
      x: 501.35869565217394,
      y: 0.10010910034179688,
    },
    {
      x: 778.5326086956522,
      y: 0.10010910034179688,
    },
    {
      x: 782.608695652174,
      y: 0.05525970458984375,
    },
    {
      x: 786.6847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 790.7608695652174,
      y: 0.045291900634765625,
    },
    {
      x: 794.8369565217391,
      y: 0.045291900634765625,
    },
    {
      x: 798.9130434782609,
      y: 0.045291900634765625,
    },
    {
      x: 802.9891304347826,
      y: 0.040309906005859375,
    },
    {
      x: 807.0652173913044,
      y: 0.040309906005859375,
    },
    {
      x: 811.1413043478261,
      y: 0.0353240966796875,
    },
    {
      x: 815.2173913043479,
      y: 0.0353240966796875,
    },
    {
      x: 819.2934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 823.3695652173914,
      y: 0.03034210205078125,
    },
    {
      x: 827.4456521739131,
      y: 0.0353240966796875,
    },
    {
      x: 831.5217391304348,
      y: 0.040309906005859375,
    },
    {
      x: 835.5978260869565,
      y: 0.040309906005859375,
    },
    {
      x: 839.6739130434783,
      y: 0.045291900634765625,
    },
    {
      x: 843.75,
      y: 0.050273895263671875,
    },
    {
      x: 847.8260869565217,
      y: 0.050273895263671875,
    },
    {
      x: 851.9021739130435,
      y: 0.05525970458984375,
    },
    {
      x: 855.9782608695652,
      y: 0.06024169921875,
    },
    {
      x: 860.054347826087,
      y: 0.06522369384765625,
    },
    {
      x: 864.1304347826087,
      y: 0.07020950317382812,
    },
    {
      x: 868.2065217391305,
      y: 0.0751953125,
    },
    {
      x: 872.2826086956522,
      y: 0.0751953125,
    },
    {
      x: 876.358695652174,
      y: 0.08017730712890625,
    },
    {
      x: 880.4347826086956,
      y: 0.08017730712890625,
    },
    {
      x: 884.5108695652174,
      y: 0.0851593017578125,
    },
    {
      x: 888.5869565217391,
      y: 0.09512710571289062,
    },
    {
      x: 892.6630434782609,
      y: 0.09512710571289062,
    },
    {
      x: 896.7391304347826,
      y: 0.10010910034179688,
    },
    {
      x: 900.8152173913044,
      y: 0.10010910034179688,
    },
    {
      x: 1259.5108695652175,
      y: 0.10010910034179688,
    },
    {
      x: 1263.5869565217392,
      y: 0.09014511108398438,
    },
    {
      x: 1267.663043478261,
      y: 0.08017730712890625,
    },
    {
      x: 1271.7391304347827,
      y: 0.0751953125,
    },
    {
      x: 1275.8152173913045,
      y: 0.07020950317382812,
    },
    {
      x: 1279.8913043478262,
      y: 0.06522369384765625,
    },
    {
      x: 1283.9673913043478,
      y: 0.06522369384765625,
    },
    {
      x: 1292.1195652173913,
      y: 0.06522369384765625,
    },
    {
      x: 1296.195652173913,
      y: 0.06024169921875,
    },
    {
      x: 1300.2717391304348,
      y: 0.05525970458984375,
    },
    {
      x: 1304.3478260869565,
      y: 0.10010910034179688,
    },
    {
      x: 1308.4239130434783,
      y: 0.10010910034179688,
    },
    {
      x: 1471.4673913043478,
      y: 0.10010910034179688,
    },
    {
      x: 1475.5434782608695,
      y: 0.10509490966796875,
    },
    {
      x: 1479.6195652173913,
      y: 0.1349945068359375,
    },
    {
      x: 1483.695652173913,
      y: 0.16489410400390625,
    },
    {
      x: 1487.7717391304348,
      y: 0.19479751586914062,
    },
    {
      x: 1491.8478260869565,
      y: 0.21971511840820312,
    },
    {
      x: 1495.9239130434783,
      y: 0.24961471557617188,
    },
    {
      x: 1500,
      y: 0.2745361328125,
    },
    {
      x: 1504.0760869565217,
      y: 0.2994499206542969,
    },
    {
      x: 1508.1521739130435,
      y: 0.3293495178222656,
    },
    {
      x: 1512.2282608695652,
      y: 0.35427093505859375,
    },
    {
      x: 1516.304347826087,
      y: 0.3692207336425781,
    },
    {
      x: 1520.3804347826087,
      y: 0.35427093505859375,
    },
    {
      x: 1524.4565217391305,
      y: 0.3343391418457031,
    },
    {
      x: 1528.5326086956522,
      y: 0.309417724609375,
    },
    {
      x: 1532.608695652174,
      y: 0.27951812744140625,
    },
    {
      x: 1536.6847826086957,
      y: 0.24961471557617188,
    },
    {
      x: 1540.7608695652175,
      y: 0.22469711303710938,
    },
    {
      x: 1544.8369565217392,
      y: 0.1997833251953125,
    },
    {
      x: 1548.913043478261,
      y: 0.1698760986328125,
    },
    {
      x: 1552.9891304347827,
      y: 0.14496231079101562,
    },
    {
      x: 1557.0652173913045,
      y: 0.11506271362304688,
    },
    {
      x: 1561.1413043478262,
      y: 0.10509490966796875,
    },
    {
      x: 1565.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 1569.2934782608695,
      y: 0.10010910034179688,
    },
    {
      x: 1822.0108695652175,
      y: 0.10010910034179688,
    },
    {
      x: 1826.0869565217392,
      y: 0.06522369384765625,
    },
    {
      x: 1830.163043478261,
      y: 0.06522369384765625,
    },
    {
      x: 1838.3152173913045,
      y: 0.06522369384765625,
    },
    {
      x: 1842.3913043478262,
      y: 0.06024169921875,
    },
    {
      x: 1846.467391304348,
      y: 0.05525970458984375,
    },
    {
      x: 1850.5434782608695,
      y: 0.05525970458984375,
    },
    {
      x: 1854.6195652173913,
      y: 0.050273895263671875,
    },
    {
      x: 1858.695652173913,
      y: 0.045291900634765625,
    },
    {
      x: 1862.7717391304348,
      y: 0.045291900634765625,
    },
    {
      x: 1870.9239130434783,
      y: 0.045291900634765625,
    },
    {
      x: 1875,
      y: 0.040309906005859375,
    },
    {
      x: 1879.0760869565217,
      y: 0.0353240966796875,
    },
    {
      x: 1883.1521739130435,
      y: 0.0353240966796875,
    },
    {
      x: 1887.2282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 1891.304347826087,
      y: 0.03034210205078125,
    },
    {
      x: 1895.3804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 1899.4565217391305,
      y: 0.0353240966796875,
    },
    {
      x: 1903.5326086956522,
      y: 0.040309906005859375,
    },
    {
      x: 1907.608695652174,
      y: 0.045291900634765625,
    },
    {
      x: 1911.6847826086957,
      y: 0.050273895263671875,
    },
    {
      x: 1915.7608695652175,
      y: 0.050273895263671875,
    },
    {
      x: 1919.8369565217392,
      y: 0.05525970458984375,
    },
    {
      x: 1923.913043478261,
      y: 0.06024169921875,
    },
    {
      x: 1927.9891304347827,
      y: 0.06522369384765625,
    },
    {
      x: 1932.0652173913045,
      y: 0.07020950317382812,
    },
    {
      x: 1936.1413043478262,
      y: 0.0751953125,
    },
    {
      x: 1940.217391304348,
      y: 0.0751953125,
    },
    {
      x: 1944.2934782608697,
      y: 0.08017730712890625,
    },
    {
      x: 1948.3695652173913,
      y: 0.08017730712890625,
    },
    {
      x: 1952.445652173913,
      y: 0.0851593017578125,
    },
    {
      x: 1956.5217391304348,
      y: 0.09512710571289062,
    },
    {
      x: 1960.5978260869565,
      y: 0.09512710571289062,
    },
    {
      x: 1964.6739130434783,
      y: 0.10010910034179688,
    },
    {
      x: 1968.75,
      y: 0.10010910034179688,
    },
    {
      x: 2082.880434782609,
      y: 0.10010910034179688,
    },
    {
      x: 2086.9565217391305,
      y: 0.22469711303710938,
    },
    {
      x: 2091.032608695652,
      y: 0.1997833251953125,
    },
    {
      x: 2095.108695652174,
      y: 0.1698760986328125,
    },
    {
      x: 2099.1847826086955,
      y: 0.14496231079101562,
    },
    {
      x: 2103.2608695652175,
      y: 0.11506271362304688,
    },
    {
      x: 2107.336956521739,
      y: 0.10509490966796875,
    },
    {
      x: 2111.413043478261,
      y: 0.10010910034179688,
    },
    {
      x: 2115.4891304347825,
      y: 0.10010910034179688,
    },
    {
      x: 2327.445652173913,
      y: 0.10010910034179688,
    },
    {
      x: 2331.521739130435,
      y: 0.09014511108398438,
    },
    {
      x: 2335.5978260869565,
      y: 0.08017730712890625,
    },
    {
      x: 2339.6739130434785,
      y: 0.0751953125,
    },
    {
      x: 2343.75,
      y: 0.07020950317382812,
    },
    {
      x: 2347.826086956522,
      y: 0.10010910034179688,
    },
    {
      x: 2351.9021739130435,
      y: 0.10010910034179688,
    },
    {
      x: 2539.4021739130435,
      y: 0.10010910034179688,
    },
    {
      x: 2543.4782608695655,
      y: 0.10509490966796875,
    },
    {
      x: 2547.554347826087,
      y: 0.1349945068359375,
    },
    {
      x: 2551.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 2555.7065217391305,
      y: 0.19479751586914062,
    },
    {
      x: 2559.7826086956525,
      y: 0.21971511840820312,
    },
    {
      x: 2563.858695652174,
      y: 0.24961471557617188,
    },
    {
      x: 2567.9347826086955,
      y: 0.2745361328125,
    },
    {
      x: 2572.0108695652175,
      y: 0.2994499206542969,
    },
    {
      x: 2576.086956521739,
      y: 0.3293495178222656,
    },
    {
      x: 2580.163043478261,
      y: 0.35427093505859375,
    },
    {
      x: 2584.2391304347825,
      y: 0.3692207336425781,
    },
    {
      x: 2588.3152173913045,
      y: 0.35427093505859375,
    },
    {
      x: 2592.391304347826,
      y: 0.3343391418457031,
    },
    {
      x: 2596.467391304348,
      y: 0.309417724609375,
    },
    {
      x: 2600.5434782608695,
      y: 0.27951812744140625,
    },
    {
      x: 2604.6195652173915,
      y: 0.24961471557617188,
    },
    {
      x: 2608.695652173913,
      y: 0.10010910034179688,
    },
    {
      x: 2612.771739130435,
      y: 0.10010910034179688,
    },
    {
      x: 2873.641304347826,
      y: 0.10010910034179688,
    },
    {
      x: 2877.717391304348,
      y: 0.09014511108398438,
    },
    {
      x: 2881.7934782608695,
      y: 0.08017730712890625,
    },
    {
      x: 2885.8695652173915,
      y: 0.0751953125,
    },
    {
      x: 2889.945652173913,
      y: 0.07020950317382812,
    },
    {
      x: 2894.021739130435,
      y: 0.06522369384765625,
    },
    {
      x: 2898.0978260869565,
      y: 0.06522369384765625,
    },
    {
      x: 2906.25,
      y: 0.06522369384765625,
    },
    {
      x: 2910.326086956522,
      y: 0.06024169921875,
    },
    {
      x: 2914.4021739130435,
      y: 0.05525970458984375,
    },
    {
      x: 2918.4782608695655,
      y: 0.05525970458984375,
    },
    {
      x: 2922.554347826087,
      y: 0.050273895263671875,
    },
    {
      x: 2926.630434782609,
      y: 0.045291900634765625,
    },
    {
      x: 2930.7065217391305,
      y: 0.045291900634765625,
    },
    {
      x: 2934.7826086956525,
      y: 0.045291900634765625,
    },
    {
      x: 2938.858695652174,
      y: 0.040309906005859375,
    },
    {
      x: 2942.9347826086955,
      y: 0.040309906005859375,
    },
    {
      x: 2947.0108695652175,
      y: 0.0353240966796875,
    },
    {
      x: 2951.086956521739,
      y: 0.0353240966796875,
    },
    {
      x: 2955.163043478261,
      y: 0.03034210205078125,
    },
    {
      x: 2959.2391304347825,
      y: 0.03034210205078125,
    },
    {
      x: 2963.3152173913045,
      y: 0.0353240966796875,
    },
    {
      x: 2967.391304347826,
      y: 0.040309906005859375,
    },
    {
      x: 2971.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 2975.5434782608695,
      y: 0.045291900634765625,
    },
    {
      x: 2979.6195652173915,
      y: 0.050273895263671875,
    },
    {
      x: 2983.695652173913,
      y: 0.050273895263671875,
    },
    {
      x: 2987.771739130435,
      y: 0.05525970458984375,
    },
    {
      x: 2991.8478260869565,
      y: 0.06024169921875,
    },
    {
      x: 2995.9239130434785,
      y: 0.06522369384765625,
    },
    {
      x: 3000,
      y: 0.07020950317382812,
    },
    {
      x: 3004.076086956522,
      y: 0.0751953125,
    },
    {
      x: 3008.1521739130435,
      y: 0.0751953125,
    },
    {
      x: 3012.2282608695655,
      y: 0.08017730712890625,
    },
    {
      x: 3016.304347826087,
      y: 0.08017730712890625,
    },
    {
      x: 3020.380434782609,
      y: 0.0851593017578125,
    },
    {
      x: 3024.4565217391305,
      y: 0.09512710571289062,
    },
    {
      x: 3028.5326086956525,
      y: 0.09512710571289062,
    },
    {
      x: 3032.608695652174,
      y: 0.10010910034179688,
    },
    {
      x: 3036.6847826086955,
      y: 0.10010910034179688,
    },
    {
      x: 3126.358695652174,
      y: 0.10010910034179688,
    },
    {
      x: 3130.434782608696,
      y: 0.3692207336425781,
    },
    {
      x: 3134.5108695652175,
      y: 0.35427093505859375,
    },
    {
      x: 3138.586956521739,
      y: 0.3343391418457031,
    },
    {
      x: 3142.663043478261,
      y: 0.309417724609375,
    },
    {
      x: 3146.7391304347825,
      y: 0.27951812744140625,
    },
    {
      x: 3150.8152173913045,
      y: 0.24961471557617188,
    },
    {
      x: 3154.891304347826,
      y: 0.22469711303710938,
    },
    {
      x: 3158.967391304348,
      y: 0.1997833251953125,
    },
    {
      x: 3163.0434782608695,
      y: 0.1698760986328125,
    },
    {
      x: 3167.1195652173915,
      y: 0.14496231079101562,
    },
    {
      x: 3171.195652173913,
      y: 0.11506271362304688,
    },
    {
      x: 3175.271739130435,
      y: 0.10509490966796875,
    },
    {
      x: 3179.3478260869565,
      y: 0.10010910034179688,
    },
    {
      x: 3183.4239130434785,
      y: 0.10010910034179688,
    },
    {
      x: 3868.2065217391305,
      y: 0.10010910034179688,
    },
    {
      x: 3872.2826086956525,
      y: 0.10509490966796875,
    },
    {
      x: 3876.358695652174,
      y: 0.1349945068359375,
    },
    {
      x: 3880.434782608696,
      y: 0.16489410400390625,
    },
    {
      x: 3884.5108695652175,
      y: 0.19479751586914062,
    },
    {
      x: 3888.5869565217395,
      y: 0.21971511840820312,
    },
    {
      x: 3892.663043478261,
      y: 0.24961471557617188,
    },
    {
      x: 3896.7391304347825,
      y: 0.2745361328125,
    },
    {
      x: 3900.8152173913045,
      y: 0.2994499206542969,
    },
    {
      x: 3904.891304347826,
      y: 0.3293495178222656,
    },
    {
      x: 3908.967391304348,
      y: 0.35427093505859375,
    },
    {
      x: 3913.0434782608695,
      y: 0.10010910034179688,
    },
    {
      x: 3917.1195652173915,
      y: 0.10010910034179688,
    },
    {
      x: 3941.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 3945.6521739130435,
      y: 0.09014511108398438,
    },
    {
      x: 3949.7282608695655,
      y: 0.08017730712890625,
    },
    {
      x: 3953.804347826087,
      y: 0.0751953125,
    },
    {
      x: 3957.880434782609,
      y: 0.07020950317382812,
    },
    {
      x: 3961.9565217391305,
      y: 0.06522369384765625,
    },
    {
      x: 3966.0326086956525,
      y: 0.06522369384765625,
    },
    {
      x: 3974.184782608696,
      y: 0.06522369384765625,
    },
    {
      x: 3978.2608695652175,
      y: 0.06024169921875,
    },
    {
      x: 3982.3369565217395,
      y: 0.05525970458984375,
    },
    {
      x: 3986.413043478261,
      y: 0.05525970458984375,
    },
    {
      x: 3990.4891304347825,
      y: 0.050273895263671875,
    },
    {
      x: 3994.5652173913045,
      y: 0.045291900634765625,
    },
    {
      x: 3998.641304347826,
      y: 0.045291900634765625,
    },
    {
      x: 4002.717391304348,
      y: 0.045291900634765625,
    },
    {
      x: 4006.7934782608695,
      y: 0.040309906005859375,
    },
    {
      x: 4010.8695652173915,
      y: 0.040309906005859375,
    },
    {
      x: 4014.945652173913,
      y: 0.0353240966796875,
    },
    {
      x: 4019.021739130435,
      y: 0.0353240966796875,
    },
    {
      x: 4023.0978260869565,
      y: 0.0353240966796875,
    },
    {
      x: 4027.1739130434785,
      y: 0.03034210205078125,
    },
    {
      x: 4031.25,
      y: 0.0353240966796875,
    },
    {
      x: 4035.326086956522,
      y: 0.040309906005859375,
    },
    {
      x: 4039.4021739130435,
      y: 0.040309906005859375,
    },
    {
      x: 4043.4782608695655,
      y: 0.045291900634765625,
    },
    {
      x: 4047.554347826087,
      y: 0.050273895263671875,
    },
    {
      x: 4051.630434782609,
      y: 0.050273895263671875,
    },
    {
      x: 4055.7065217391305,
      y: 0.05525970458984375,
    },
    {
      x: 4059.7826086956525,
      y: 0.06024169921875,
    },
    {
      x: 4063.858695652174,
      y: 0.06522369384765625,
    },
    {
      x: 4067.934782608696,
      y: 0.07020950317382812,
    },
    {
      x: 4072.0108695652175,
      y: 0.0751953125,
    },
    {
      x: 4076.0869565217395,
      y: 0.0751953125,
    },
    {
      x: 4080.163043478261,
      y: 0.08017730712890625,
    },
    {
      x: 4084.2391304347825,
      y: 0.08017730712890625,
    },
    {
      x: 4088.3152173913045,
      y: 0.0851593017578125,
    },
    {
      x: 4092.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 4096.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 4100.54347826087,
      y: 0.10010910034179688,
    },
    {
      x: 4104.619565217391,
      y: 0.10010910034179688,
    },
    {
      x: 4675.271739130435,
      y: 0.10010910034179688,
    },
    {
      x: 4679.347826086957,
      y: 0.10509490966796875,
    },
    {
      x: 4683.423913043478,
      y: 0.1349945068359375,
    },
    {
      x: 4687.5,
      y: 0.16489410400390625,
    },
    {
      x: 4691.576086956522,
      y: 0.19479751586914062,
    },
    {
      x: 4695.652173913044,
      y: 0.21971511840820312,
    },
    {
      x: 4699.728260869565,
      y: 0.24961471557617188,
    },
    {
      x: 4703.804347826087,
      y: 0.2745361328125,
    },
    {
      x: 4707.880434782609,
      y: 0.2994499206542969,
    },
    {
      x: 4711.956521739131,
      y: 0.3293495178222656,
    },
    {
      x: 4716.032608695652,
      y: 0.35427093505859375,
    },
    {
      x: 4720.108695652174,
      y: 0.3692207336425781,
    },
    {
      x: 4724.184782608696,
      y: 0.35427093505859375,
    },
    {
      x: 4728.260869565218,
      y: 0.3343391418457031,
    },
    {
      x: 4732.336956521739,
      y: 0.309417724609375,
    },
    {
      x: 4736.413043478261,
      y: 0.27951812744140625,
    },
    {
      x: 4740.489130434783,
      y: 0.24961471557617188,
    },
    {
      x: 4744.565217391304,
      y: 0.22469711303710938,
    },
    {
      x: 4748.641304347826,
      y: 0.1997833251953125,
    },
    {
      x: 4752.717391304348,
      y: 0.1698760986328125,
    },
    {
      x: 4756.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 4760.869565217391,
      y: 0.11506271362304688,
    },
    {
      x: 4764.945652173913,
      y: 0.10509490966796875,
    },
    {
      x: 4769.021739130435,
      y: 0.10010910034179688,
    },
    {
      x: 4773.097826086957,
      y: 0.10010910034179688,
    },
    {
      x: 5009.510869565218,
      y: 0.10010910034179688,
    },
    {
      x: 5013.586956521739,
      y: 0.09014511108398438,
    },
    {
      x: 5017.663043478261,
      y: 0.08017730712890625,
    },
    {
      x: 5021.739130434783,
      y: 0.0751953125,
    },
    {
      x: 5025.815217391305,
      y: 0.07020950317382812,
    },
    {
      x: 5029.891304347826,
      y: 0.06522369384765625,
    },
    {
      x: 5033.967391304348,
      y: 0.06522369384765625,
    },
    {
      x: 5042.119565217391,
      y: 0.06522369384765625,
    },
    {
      x: 5046.195652173913,
      y: 0.06024169921875,
    },
    {
      x: 5050.271739130435,
      y: 0.05525970458984375,
    },
    {
      x: 5054.347826086957,
      y: 0.05525970458984375,
    },
    {
      x: 5058.423913043478,
      y: 0.050273895263671875,
    },
    {
      x: 5062.5,
      y: 0.045291900634765625,
    },
    {
      x: 5066.576086956522,
      y: 0.045291900634765625,
    },
    {
      x: 5070.652173913044,
      y: 0.045291900634765625,
    },
    {
      x: 5074.728260869565,
      y: 0.040309906005859375,
    },
    {
      x: 5078.804347826087,
      y: 0.040309906005859375,
    },
    {
      x: 5082.880434782609,
      y: 0.0353240966796875,
    },
    {
      x: 5086.956521739131,
      y: 0.0353240966796875,
    },
    {
      x: 5091.032608695652,
      y: 0.0353240966796875,
    },
    {
      x: 5095.108695652174,
      y: 0.03034210205078125,
    },
    {
      x: 5099.184782608696,
      y: 0.0353240966796875,
    },
    {
      x: 5103.260869565218,
      y: 0.040309906005859375,
    },
    {
      x: 5107.336956521739,
      y: 0.040309906005859375,
    },
    {
      x: 5111.413043478261,
      y: 0.045291900634765625,
    },
    {
      x: 5115.489130434783,
      y: 0.050273895263671875,
    },
    {
      x: 5119.565217391305,
      y: 0.050273895263671875,
    },
    {
      x: 5123.641304347826,
      y: 0.05525970458984375,
    },
    {
      x: 5127.717391304348,
      y: 0.06024169921875,
    },
    {
      x: 5131.79347826087,
      y: 0.06522369384765625,
    },
    {
      x: 5135.869565217391,
      y: 0.07020950317382812,
    },
    {
      x: 5139.945652173913,
      y: 0.0751953125,
    },
    {
      x: 5144.021739130435,
      y: 0.0751953125,
    },
    {
      x: 5148.097826086957,
      y: 0.08017730712890625,
    },
    {
      x: 5152.173913043478,
      y: 0.08017730712890625,
    },
    {
      x: 5156.25,
      y: 0.0851593017578125,
    },
    {
      x: 5160.326086956522,
      y: 0.09512710571289062,
    },
    {
      x: 5164.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 5168.478260869565,
      y: 0.10010910034179688,
    },
    {
      x: 5172.554347826087,
      y: 0.10010910034179688,
    },
    {
      x: 5221.467391304348,
      y: 0.10010910034179688,
    },
    {
      x: 5225.54347826087,
      y: 0.10509490966796875,
    },
    {
      x: 5229.619565217391,
      y: 0.1349945068359375,
    },
    {
      x: 5233.695652173913,
      y: 0.16489410400390625,
    },
    {
      x: 5237.771739130435,
      y: 0.19479751586914062,
    },
    {
      x: 5241.847826086957,
      y: 0.21971511840820312,
    },
    {
      x: 5245.923913043478,
      y: 0.24961471557617188,
    },
    {
      x: 5250,
      y: 0.2745361328125,
    },
    {
      x: 5254.076086956522,
      y: 0.2994499206542969,
    },
    {
      x: 5258.152173913044,
      y: 0.3293495178222656,
    },
    {
      x: 5262.228260869565,
      y: 0.35427093505859375,
    },
    {
      x: 5266.304347826087,
      y: 0.3692207336425781,
    },
    {
      x: 5270.380434782609,
      y: 0.35427093505859375,
    },
    {
      x: 5274.456521739131,
      y: 0.3343391418457031,
    },
    {
      x: 5278.532608695652,
      y: 0.309417724609375,
    },
    {
      x: 5282.608695652174,
      y: 0.27951812744140625,
    },
    {
      x: 5286.684782608696,
      y: 0.24961471557617188,
    },
    {
      x: 5290.760869565218,
      y: 0.22469711303710938,
    },
    {
      x: 5294.836956521739,
      y: 0.1997833251953125,
    },
    {
      x: 5298.913043478261,
      y: 0.1698760986328125,
    },
    {
      x: 5302.989130434783,
      y: 0.14496231079101562,
    },
    {
      x: 5307.065217391305,
      y: 0.11506271362304688,
    },
    {
      x: 5311.141304347826,
      y: 0.10509490966796875,
    },
    {
      x: 5315.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 5319.29347826087,
      y: 0.10010910034179688,
    },
    {
      x: 5816.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 5820.652173913044,
      y: 0.09014511108398438,
    },
    {
      x: 5824.728260869565,
      y: 0.08017730712890625,
    },
    {
      x: 5828.804347826087,
      y: 0.0751953125,
    },
    {
      x: 5832.880434782609,
      y: 0.07020950317382812,
    },
    {
      x: 5836.956521739131,
      y: 0.06522369384765625,
    },
    {
      x: 5841.032608695652,
      y: 0.06522369384765625,
    },
    {
      x: 5849.184782608696,
      y: 0.06522369384765625,
    },
    {
      x: 5853.260869565218,
      y: 0.06024169921875,
    },
    {
      x: 5857.336956521739,
      y: 0.05525970458984375,
    },
    {
      x: 5861.413043478261,
      y: 0.05525970458984375,
    },
    {
      x: 5865.489130434783,
      y: 0.050273895263671875,
    },
    {
      x: 5869.565217391305,
      y: 0.045291900634765625,
    },
    {
      x: 5873.641304347826,
      y: 0.045291900634765625,
    },
    {
      x: 5881.79347826087,
      y: 0.045291900634765625,
    },
    {
      x: 5885.869565217391,
      y: 0.040309906005859375,
    },
    {
      x: 5889.945652173913,
      y: 0.0353240966796875,
    },
    {
      x: 5894.021739130435,
      y: 0.0353240966796875,
    },
    {
      x: 5898.097826086957,
      y: 0.03034210205078125,
    },
    {
      x: 5902.173913043478,
      y: 0.03034210205078125,
    },
    {
      x: 5906.25,
      y: 0.03034210205078125,
    },
    {
      x: 5910.326086956522,
      y: 0.0353240966796875,
    },
    {
      x: 5914.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 5918.478260869565,
      y: 0.045291900634765625,
    },
    {
      x: 5922.554347826087,
      y: 0.050273895263671875,
    },
    {
      x: 5926.630434782609,
      y: 0.050273895263671875,
    },
    {
      x: 5930.706521739131,
      y: 0.05525970458984375,
    },
    {
      x: 5934.782608695652,
      y: 0.06024169921875,
    },
    {
      x: 5938.858695652174,
      y: 0.06522369384765625,
    },
    {
      x: 5942.934782608696,
      y: 0.07020950317382812,
    },
    {
      x: 5947.010869565218,
      y: 0.0751953125,
    },
    {
      x: 5951.086956521739,
      y: 0.0751953125,
    },
    {
      x: 5955.163043478261,
      y: 0.08017730712890625,
    },
    {
      x: 5959.239130434783,
      y: 0.08017730712890625,
    },
    {
      x: 5963.315217391305,
      y: 0.0851593017578125,
    },
    {
      x: 5967.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 5971.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 5975.54347826087,
      y: 0.10010910034179688,
    },
    {
      x: 5979.619565217391,
      y: 0.10010910034179688,
    },
    {
      x: 6289.402173913044,
      y: 0.10010910034179688,
    },
    {
      x: 6293.478260869565,
      y: 0.10509490966796875,
    },
    {
      x: 6297.554347826087,
      y: 0.1349945068359375,
    },
    {
      x: 6301.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 6305.706521739131,
      y: 0.19479751586914062,
    },
    {
      x: 6309.782608695652,
      y: 0.21971511840820312,
    },
    {
      x: 6313.858695652174,
      y: 0.24961471557617188,
    },
    {
      x: 6317.934782608696,
      y: 0.2745361328125,
    },
    {
      x: 6322.010869565218,
      y: 0.2994499206542969,
    },
    {
      x: 6326.086956521739,
      y: 0.3293495178222656,
    },
    {
      x: 6330.163043478261,
      y: 0.35427093505859375,
    },
    {
      x: 6334.239130434783,
      y: 0.3692207336425781,
    },
    {
      x: 6338.315217391305,
      y: 0.35427093505859375,
    },
    {
      x: 6342.391304347826,
      y: 0.3343391418457031,
    },
    {
      x: 6346.467391304348,
      y: 0.309417724609375,
    },
    {
      x: 6350.54347826087,
      y: 0.27951812744140625,
    },
    {
      x: 6354.619565217392,
      y: 0.24961471557617188,
    },
    {
      x: 6358.695652173913,
      y: 0.22469711303710938,
    },
    {
      x: 6362.771739130435,
      y: 0.1997833251953125,
    },
    {
      x: 6366.847826086957,
      y: 0.1698760986328125,
    },
    {
      x: 6370.923913043478,
      y: 0.14496231079101562,
    },
    {
      x: 6375,
      y: 0.11506271362304688,
    },
    {
      x: 6379.076086956522,
      y: 0.10509490966796875,
    },
    {
      x: 6383.152173913044,
      y: 0.10010910034179688,
    },
    {
      x: 6387.228260869565,
      y: 0.10010910034179688,
    },
    {
      x: 7145.380434782609,
      y: 0.10010910034179688,
    },
    {
      x: 7149.456521739131,
      y: 0.09014511108398438,
    },
    {
      x: 7153.532608695652,
      y: 0.08017730712890625,
    },
    {
      x: 7157.608695652174,
      y: 0.0751953125,
    },
    {
      x: 7161.684782608696,
      y: 0.07020950317382812,
    },
    {
      x: 7165.760869565218,
      y: 0.06522369384765625,
    },
    {
      x: 7169.836956521739,
      y: 0.06522369384765625,
    },
    {
      x: 7177.989130434783,
      y: 0.06522369384765625,
    },
    {
      x: 7182.065217391305,
      y: 0.06024169921875,
    },
    {
      x: 7186.141304347826,
      y: 0.05525970458984375,
    },
    {
      x: 7190.217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 7194.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 7198.369565217392,
      y: 0.045291900634765625,
    },
    {
      x: 7202.445652173913,
      y: 0.045291900634765625,
    },
    {
      x: 7206.521739130435,
      y: 0.045291900634765625,
    },
    {
      x: 7210.597826086957,
      y: 0.040309906005859375,
    },
    {
      x: 7214.673913043478,
      y: 0.040309906005859375,
    },
    {
      x: 7218.75,
      y: 0.0353240966796875,
    },
    {
      x: 7222.826086956522,
      y: 0.0353240966796875,
    },
    {
      x: 7226.902173913044,
      y: 0.03034210205078125,
    },
    {
      x: 7230.978260869565,
      y: 0.03034210205078125,
    },
    {
      x: 7235.054347826087,
      y: 0.0353240966796875,
    },
    {
      x: 7239.130434782609,
      y: 0.040309906005859375,
    },
    {
      x: 7243.206521739131,
      y: 0.040309906005859375,
    },
    {
      x: 7247.282608695652,
      y: 0.045291900634765625,
    },
    {
      x: 7251.358695652174,
      y: 0.050273895263671875,
    },
    {
      x: 7255.434782608696,
      y: 0.050273895263671875,
    },
    {
      x: 7259.510869565218,
      y: 0.05525970458984375,
    },
    {
      x: 7263.586956521739,
      y: 0.06024169921875,
    },
    {
      x: 7267.663043478261,
      y: 0.06522369384765625,
    },
    {
      x: 7271.739130434783,
      y: 0.07020950317382812,
    },
    {
      x: 7275.815217391305,
      y: 0.0751953125,
    },
    {
      x: 7279.891304347826,
      y: 0.0751953125,
    },
    {
      x: 7283.967391304348,
      y: 0.08017730712890625,
    },
    {
      x: 7288.04347826087,
      y: 0.08017730712890625,
    },
    {
      x: 7292.119565217392,
      y: 0.0851593017578125,
    },
    {
      x: 7296.195652173913,
      y: 0.09512710571289062,
    },
    {
      x: 7300.271739130435,
      y: 0.09512710571289062,
    },
    {
      x: 7304.347826086957,
      y: 0.10010910034179688,
    },
    {
      x: 7308.423913043478,
      y: 0.10010910034179688,
    },
    {
      x: 7357.336956521739,
      y: 0.10010910034179688,
    },
    {
      x: 7361.413043478261,
      y: 0.10509490966796875,
    },
    {
      x: 7365.489130434783,
      y: 0.1349945068359375,
    },
    {
      x: 7369.565217391305,
      y: 0.16489410400390625,
    },
    {
      x: 7373.641304347826,
      y: 0.19479751586914062,
    },
    {
      x: 7377.717391304348,
      y: 0.21971511840820312,
    },
    {
      x: 7381.79347826087,
      y: 0.24961471557617188,
    },
    {
      x: 7385.869565217392,
      y: 0.2745361328125,
    },
    {
      x: 7389.945652173913,
      y: 0.2994499206542969,
    },
    {
      x: 7394.021739130435,
      y: 0.3293495178222656,
    },
    {
      x: 7398.097826086957,
      y: 0.35427093505859375,
    },
    {
      x: 7402.173913043478,
      y: 0.3692207336425781,
    },
    {
      x: 7406.25,
      y: 0.35427093505859375,
    },
    {
      x: 7410.326086956522,
      y: 0.3343391418457031,
    },
    {
      x: 7414.402173913044,
      y: 0.309417724609375,
    },
    {
      x: 7418.478260869565,
      y: 0.27951812744140625,
    },
    {
      x: 7422.554347826087,
      y: 0.24961471557617188,
    },
    {
      x: 7426.630434782609,
      y: 0.22469711303710938,
    },
    {
      x: 7430.706521739131,
      y: 0.1997833251953125,
    },
    {
      x: 7434.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 7438.858695652174,
      y: 0.14496231079101562,
    },
    {
      x: 7442.934782608696,
      y: 0.11506271362304688,
    },
    {
      x: 7447.010869565218,
      y: 0.10509490966796875,
    },
    {
      x: 7451.086956521739,
      y: 0.10010910034179688,
    },
    {
      x: 7455.163043478261,
      y: 0.10010910034179688,
    },
    {
      x: 7822.010869565218,
      y: 0.10010910034179688,
    },
    {
      x: 7826.086956521739,
      y: 0.0751953125,
    },
    {
      x: 7830.163043478261,
      y: 0.08017730712890625,
    },
    {
      x: 7834.239130434783,
      y: 0.08017730712890625,
    },
    {
      x: 7838.315217391305,
      y: 0.0851593017578125,
    },
    {
      x: 7842.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 7846.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 7850.54347826087,
      y: 0.10010910034179688,
    },
    {
      x: 7854.619565217392,
      y: 0.10010910034179688,
    },
    {
      x: 8082.880434782609,
      y: 0.10010910034179688,
    },
    {
      x: 8086.956521739131,
      y: 0.10509490966796875,
    },
    {
      x: 8091.032608695652,
      y: 0.10509490966796875,
    },
    {
      x: 8213.315217391304,
      y: 0.10509490966796875,
    },
    {
      x: 8217.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 8221.467391304348,
      y: 0.08017730712890625,
    },
    {
      x: 8225.54347826087,
      y: 0.0751953125,
    },
    {
      x: 8229.619565217392,
      y: 0.07020950317382812,
    },
    {
      x: 8233.695652173914,
      y: 0.06522369384765625,
    },
    {
      x: 8237.771739130436,
      y: 0.06522369384765625,
    },
    {
      x: 8241.847826086956,
      y: 0.06024169921875,
    },
    {
      x: 8245.923913043478,
      y: 0.06024169921875,
    },
    {
      x: 8250,
      y: 0.06024169921875,
    },
    {
      x: 8254.076086956522,
      y: 0.05525970458984375,
    },
    {
      x: 8258.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 8262.228260869566,
      y: 0.050273895263671875,
    },
    {
      x: 8266.304347826088,
      y: 0.045291900634765625,
    },
    {
      x: 8270.380434782608,
      y: 0.045291900634765625,
    },
    {
      x: 8274.45652173913,
      y: 0.040309906005859375,
    },
    {
      x: 8278.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 8282.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 8286.684782608696,
      y: 0.0353240966796875,
    },
    {
      x: 8290.760869565218,
      y: 0.0353240966796875,
    },
    {
      x: 8294.83695652174,
      y: 0.0353240966796875,
    },
    {
      x: 8298.913043478262,
      y: 0.03034210205078125,
    },
    {
      x: 8302.989130434782,
      y: 0.03034210205078125,
    },
    {
      x: 8307.065217391304,
      y: 0.0353240966796875,
    },
    {
      x: 8311.141304347826,
      y: 0.040309906005859375,
    },
    {
      x: 8315.217391304348,
      y: 0.045291900634765625,
    },
    {
      x: 8319.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 8323.369565217392,
      y: 0.050273895263671875,
    },
    {
      x: 8327.445652173914,
      y: 0.05525970458984375,
    },
    {
      x: 8331.521739130436,
      y: 0.06024169921875,
    },
    {
      x: 8335.597826086956,
      y: 0.06522369384765625,
    },
    {
      x: 8339.673913043478,
      y: 0.07020950317382812,
    },
    {
      x: 8343.75,
      y: 0.0751953125,
    },
    {
      x: 8347.826086956522,
      y: 0.10509490966796875,
    },
    {
      x: 8351.902173913044,
      y: 0.10509490966796875,
    },
    {
      x: 8498.641304347826,
      y: 0.10509490966796875,
    },
    {
      x: 8502.717391304348,
      y: 0.09512710571289062,
    },
    {
      x: 8506.79347826087,
      y: 0.08017730712890625,
    },
    {
      x: 8510.869565217392,
      y: 0.0751953125,
    },
    {
      x: 8514.945652173914,
      y: 0.07020950317382812,
    },
    {
      x: 8519.021739130436,
      y: 0.06522369384765625,
    },
    {
      x: 8523.097826086956,
      y: 0.06522369384765625,
    },
    {
      x: 8527.173913043478,
      y: 0.06024169921875,
    },
    {
      x: 8531.25,
      y: 0.06024169921875,
    },
    {
      x: 8535.326086956522,
      y: 0.06024169921875,
    },
    {
      x: 8539.402173913044,
      y: 0.05525970458984375,
    },
    {
      x: 8543.478260869566,
      y: 0.05525970458984375,
    },
    {
      x: 8547.554347826088,
      y: 0.050273895263671875,
    },
    {
      x: 8551.630434782608,
      y: 0.045291900634765625,
    },
    {
      x: 8555.70652173913,
      y: 0.045291900634765625,
    },
    {
      x: 8559.782608695652,
      y: 0.040309906005859375,
    },
    {
      x: 8563.858695652174,
      y: 0.040309906005859375,
    },
    {
      x: 8567.934782608696,
      y: 0.040309906005859375,
    },
    {
      x: 8572.010869565218,
      y: 0.0353240966796875,
    },
    {
      x: 8576.08695652174,
      y: 0.0353240966796875,
    },
    {
      x: 8580.163043478262,
      y: 0.03034210205078125,
    },
    {
      x: 8584.239130434782,
      y: 0.03034210205078125,
    },
    {
      x: 8588.315217391304,
      y: 0.03034210205078125,
    },
    {
      x: 8592.391304347826,
      y: 0.0353240966796875,
    },
    {
      x: 8596.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 8600.54347826087,
      y: 0.045291900634765625,
    },
    {
      x: 8604.619565217392,
      y: 0.050273895263671875,
    },
    {
      x: 8608.695652173914,
      y: 0.10010910034179688,
    },
    {
      x: 8612.771739130436,
      y: 0.10010910034179688,
    },
    {
      x: 8947.010869565218,
      y: 0.10010910034179688,
    },
    {
      x: 8951.08695652174,
      y: 0.10509490966796875,
    },
    {
      x: 8955.163043478262,
      y: 0.1349945068359375,
    },
    {
      x: 8959.239130434782,
      y: 0.16489410400390625,
    },
    {
      x: 8963.315217391304,
      y: 0.19479751586914062,
    },
    {
      x: 8967.391304347826,
      y: 0.21971511840820312,
    },
    {
      x: 8971.467391304348,
      y: 0.24961471557617188,
    },
    {
      x: 8975.54347826087,
      y: 0.2745361328125,
    },
    {
      x: 8979.619565217392,
      y: 0.2994499206542969,
    },
    {
      x: 8983.695652173914,
      y: 0.3293495178222656,
    },
    {
      x: 8987.771739130436,
      y: 0.35427093505859375,
    },
    {
      x: 8991.847826086956,
      y: 0.3692207336425781,
    },
    {
      x: 8995.923913043478,
      y: 0.3592529296875,
    },
    {
      x: 9000,
      y: 0.3343391418457031,
    },
    {
      x: 9004.076086956522,
      y: 0.309417724609375,
    },
    {
      x: 9008.152173913044,
      y: 0.2845001220703125,
    },
    {
      x: 9012.228260869566,
      y: 0.25460052490234375,
    },
    {
      x: 9016.304347826088,
      y: 0.22469711303710938,
    },
    {
      x: 9020.380434782608,
      y: 0.1997833251953125,
    },
    {
      x: 9024.45652173913,
      y: 0.17486572265625,
    },
    {
      x: 9028.532608695652,
      y: 0.14994430541992188,
    },
    {
      x: 9032.608695652174,
      y: 0.12004470825195312,
    },
    {
      x: 9036.684782608696,
      y: 0.110076904296875,
    },
    {
      x: 9040.760869565218,
      y: 0.10509490966796875,
    },
    {
      x: 9044.83695652174,
      y: 0.10509490966796875,
    },
    {
      x: 9126.358695652174,
      y: 0.10509490966796875,
    },
    {
      x: 9130.434782608696,
      y: 0.050273895263671875,
    },
    {
      x: 9134.510869565218,
      y: 0.05525970458984375,
    },
    {
      x: 9138.58695652174,
      y: 0.06024169921875,
    },
    {
      x: 9142.663043478262,
      y: 0.06522369384765625,
    },
    {
      x: 9146.739130434782,
      y: 0.07020950317382812,
    },
    {
      x: 9150.815217391304,
      y: 0.0751953125,
    },
    {
      x: 9154.891304347826,
      y: 0.0751953125,
    },
    {
      x: 9158.967391304348,
      y: 0.08017730712890625,
    },
    {
      x: 9163.04347826087,
      y: 0.08017730712890625,
    },
    {
      x: 9167.119565217392,
      y: 0.0851593017578125,
    },
    {
      x: 9171.195652173914,
      y: 0.09512710571289062,
    },
    {
      x: 9175.271739130436,
      y: 0.09512710571289062,
    },
    {
      x: 9179.347826086956,
      y: 0.10010910034179688,
    },
    {
      x: 9183.423913043478,
      y: 0.10010910034179688,
    },
    {
      x: 9493.20652173913,
      y: 0.10010910034179688,
    },
    {
      x: 9497.282608695652,
      y: 0.10509490966796875,
    },
    {
      x: 9501.358695652174,
      y: 0.1349945068359375,
    },
    {
      x: 9505.434782608696,
      y: 0.16489410400390625,
    },
    {
      x: 9509.510869565218,
      y: 0.19479751586914062,
    },
    {
      x: 9513.58695652174,
      y: 0.21971511840820312,
    },
    {
      x: 9517.663043478262,
      y: 0.24961471557617188,
    },
    {
      x: 9521.739130434782,
      y: 0.2745361328125,
    },
    {
      x: 9525.815217391304,
      y: 0.2994499206542969,
    },
    {
      x: 9529.891304347826,
      y: 0.3293495178222656,
    },
    {
      x: 9533.967391304348,
      y: 0.35427093505859375,
    },
    {
      x: 9538.04347826087,
      y: 0.3692207336425781,
    },
    {
      x: 9542.119565217392,
      y: 0.3592529296875,
    },
    {
      x: 9546.195652173914,
      y: 0.3343391418457031,
    },
    {
      x: 9550.271739130436,
      y: 0.309417724609375,
    },
    {
      x: 9554.347826086956,
      y: 0.2845001220703125,
    },
    {
      x: 9558.423913043478,
      y: 0.25460052490234375,
    },
    {
      x: 9562.5,
      y: 0.22469711303710938,
    },
    {
      x: 9566.576086956522,
      y: 0.1997833251953125,
    },
    {
      x: 9570.652173913044,
      y: 0.17486572265625,
    },
    {
      x: 9574.728260869566,
      y: 0.14994430541992188,
    },
    {
      x: 9578.804347826088,
      y: 0.12004470825195312,
    },
    {
      x: 9582.880434782608,
      y: 0.110076904296875,
    },
    {
      x: 9586.95652173913,
      y: 0.10509490966796875,
    },
    {
      x: 9591.032608695652,
      y: 0.10509490966796875,
    },
    {
      x: 9648.097826086956,
      y: 0.10509490966796875,
    },
    {
      x: 9652.173913043478,
      y: 0.03034210205078125,
    },
    {
      x: 9656.25,
      y: 0.03034210205078125,
    },
    {
      x: 9660.326086956522,
      y: 0.0353240966796875,
    },
    {
      x: 9664.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 9668.478260869566,
      y: 0.045291900634765625,
    },
    {
      x: 9672.554347826088,
      y: 0.050273895263671875,
    },
    {
      x: 9676.630434782608,
      y: 0.050273895263671875,
    },
    {
      x: 9680.70652173913,
      y: 0.05525970458984375,
    },
    {
      x: 9684.782608695652,
      y: 0.06024169921875,
    },
    {
      x: 9688.858695652174,
      y: 0.06522369384765625,
    },
    {
      x: 9692.934782608696,
      y: 0.07020950317382812,
    },
    {
      x: 9697.010869565218,
      y: 0.0751953125,
    },
    {
      x: 9701.08695652174,
      y: 0.0751953125,
    },
    {
      x: 9705.163043478262,
      y: 0.08017730712890625,
    },
    {
      x: 9709.239130434782,
      y: 0.08017730712890625,
    },
    {
      x: 9713.315217391304,
      y: 0.0851593017578125,
    },
    {
      x: 9717.391304347826,
      y: 0.09014511108398438,
    },
    {
      x: 9721.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 9725.54347826087,
      y: 0.10010910034179688,
    },
    {
      x: 9729.619565217392,
      y: 0.10010910034179688,
    },
    {
      x: 10169.83695652174,
      y: 0.10010910034179688,
    },
    {
      x: 10173.913043478262,
      y: 0.10509490966796875,
    },
    {
      x: 10177.989130434782,
      y: 0.10509490966796875,
    },
    {
      x: 10349.184782608696,
      y: 0.10509490966796875,
    },
    {
      x: 10353.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 10357.33695652174,
      y: 0.08017730712890625,
    },
    {
      x: 10361.413043478262,
      y: 0.0751953125,
    },
    {
      x: 10365.489130434782,
      y: 0.07020950317382812,
    },
    {
      x: 10369.565217391304,
      y: 0.06522369384765625,
    },
    {
      x: 10373.641304347826,
      y: 0.06522369384765625,
    },
    {
      x: 10377.717391304348,
      y: 0.06024169921875,
    },
    {
      x: 10381.79347826087,
      y: 0.06024169921875,
    },
    {
      x: 10385.869565217392,
      y: 0.06024169921875,
    },
    {
      x: 10389.945652173914,
      y: 0.05525970458984375,
    },
    {
      x: 10394.021739130436,
      y: 0.05525970458984375,
    },
    {
      x: 10398.097826086956,
      y: 0.050273895263671875,
    },
    {
      x: 10402.173913043478,
      y: 0.045291900634765625,
    },
    {
      x: 10406.25,
      y: 0.045291900634765625,
    },
    {
      x: 10414.402173913044,
      y: 0.045291900634765625,
    },
    {
      x: 10418.478260869566,
      y: 0.040309906005859375,
    },
    {
      x: 10422.554347826088,
      y: 0.0353240966796875,
    },
    {
      x: 10426.63043478261,
      y: 0.0353240966796875,
    },
    {
      x: 10430.70652173913,
      y: 0.03034210205078125,
    },
    {
      x: 10434.782608695652,
      y: 0.10010910034179688,
    },
    {
      x: 10438.858695652174,
      y: 0.10010910034179688,
    },
    {
      x: 10822.010869565218,
      y: 0.10010910034179688,
    },
    {
      x: 10826.08695652174,
      y: 0.10509490966796875,
    },
    {
      x: 10830.163043478262,
      y: 0.1349945068359375,
    },
    {
      x: 10834.239130434782,
      y: 0.16489410400390625,
    },
    {
      x: 10838.315217391304,
      y: 0.19479751586914062,
    },
    {
      x: 10842.391304347826,
      y: 0.21971511840820312,
    },
    {
      x: 10846.467391304348,
      y: 0.24961471557617188,
    },
    {
      x: 10850.54347826087,
      y: 0.2745361328125,
    },
    {
      x: 10854.619565217392,
      y: 0.2994499206542969,
    },
    {
      x: 10858.695652173914,
      y: 0.3293495178222656,
    },
    {
      x: 10862.771739130436,
      y: 0.35427093505859375,
    },
    {
      x: 10866.847826086956,
      y: 0.3692207336425781,
    },
    {
      x: 10870.923913043478,
      y: 0.3592529296875,
    },
    {
      x: 10875,
      y: 0.3343391418457031,
    },
    {
      x: 10879.076086956522,
      y: 0.309417724609375,
    },
    {
      x: 10883.152173913044,
      y: 0.2845001220703125,
    },
    {
      x: 10887.228260869566,
      y: 0.25460052490234375,
    },
    {
      x: 10891.304347826088,
      y: 0.22469711303710938,
    },
    {
      x: 10895.38043478261,
      y: 0.1997833251953125,
    },
    {
      x: 10899.45652173913,
      y: 0.17486572265625,
    },
    {
      x: 10903.532608695652,
      y: 0.14994430541992188,
    },
    {
      x: 10907.608695652174,
      y: 0.12004470825195312,
    },
    {
      x: 10911.684782608696,
      y: 0.110076904296875,
    },
    {
      x: 10915.760869565218,
      y: 0.10509490966796875,
    },
    {
      x: 10919.83695652174,
      y: 0.10509490966796875,
    },
    {
      x: 10952.445652173914,
      y: 0.10509490966796875,
    },
    {
      x: 10956.521739130436,
      y: 0.040309906005859375,
    },
    {
      x: 10960.597826086956,
      y: 0.040309906005859375,
    },
    {
      x: 10964.673913043478,
      y: 0.040309906005859375,
    },
    {
      x: 10968.75,
      y: 0.0353240966796875,
    },
    {
      x: 10972.826086956522,
      y: 0.0353240966796875,
    },
    {
      x: 10976.902173913044,
      y: 0.03034210205078125,
    },
    {
      x: 10980.978260869566,
      y: 0.03034210205078125,
    },
    {
      x: 10985.054347826088,
      y: 0.03034210205078125,
    },
    {
      x: 10989.13043478261,
      y: 0.0353240966796875,
    },
    {
      x: 10993.20652173913,
      y: 0.040309906005859375,
    },
    {
      x: 10997.282608695652,
      y: 0.045291900634765625,
    },
    {
      x: 11001.358695652174,
      y: 0.050273895263671875,
    },
    {
      x: 11005.434782608696,
      y: 0.050273895263671875,
    },
    {
      x: 11009.510869565218,
      y: 0.05525970458984375,
    },
    {
      x: 11013.58695652174,
      y: 0.06024169921875,
    },
    {
      x: 11017.663043478262,
      y: 0.06522369384765625,
    },
    {
      x: 11021.739130434782,
      y: 0.07020950317382812,
    },
    {
      x: 11025.815217391304,
      y: 0.0751953125,
    },
    {
      x: 11029.891304347826,
      y: 0.0751953125,
    },
    {
      x: 11033.967391304348,
      y: 0.08017730712890625,
    },
    {
      x: 11038.04347826087,
      y: 0.08017730712890625,
    },
    {
      x: 11042.119565217392,
      y: 0.0851593017578125,
    },
    {
      x: 11046.195652173914,
      y: 0.09512710571289062,
    },
    {
      x: 11050.271739130436,
      y: 0.09512710571289062,
    },
    {
      x: 11054.347826086956,
      y: 0.10010910034179688,
    },
    {
      x: 11058.423913043478,
      y: 0.10010910034179688,
    },
    {
      x: 11213.315217391304,
      y: 0.10010910034179688,
    },
    {
      x: 11217.391304347826,
      y: 0.10509490966796875,
    },
    {
      x: 11221.467391304348,
      y: 0.10509490966796875,
    },
    {
      x: 11417.119565217392,
      y: 0.10509490966796875,
    },
    {
      x: 11421.195652173914,
      y: 0.09512710571289062,
    },
    {
      x: 11425.271739130436,
      y: 0.08017730712890625,
    },
    {
      x: 11429.347826086956,
      y: 0.0751953125,
    },
    {
      x: 11433.423913043478,
      y: 0.07020950317382812,
    },
    {
      x: 11437.5,
      y: 0.06522369384765625,
    },
    {
      x: 11441.576086956522,
      y: 0.06522369384765625,
    },
    {
      x: 11445.652173913044,
      y: 0.06024169921875,
    },
    {
      x: 11449.728260869566,
      y: 0.06024169921875,
    },
    {
      x: 11453.804347826088,
      y: 0.06024169921875,
    },
    {
      x: 11457.88043478261,
      y: 0.05525970458984375,
    },
    {
      x: 11461.95652173913,
      y: 0.05525970458984375,
    },
    {
      x: 11466.032608695652,
      y: 0.050273895263671875,
    },
    {
      x: 11470.108695652174,
      y: 0.045291900634765625,
    },
    {
      x: 11474.184782608696,
      y: 0.045291900634765625,
    },
    {
      x: 11478.260869565218,
      y: 0.10010910034179688,
    },
    {
      x: 11482.33695652174,
      y: 0.10010910034179688,
    },
    {
      x: 11629.076086956522,
      y: 0.10010910034179688,
    },
    {
      x: 11633.152173913044,
      y: 0.10509490966796875,
    },
    {
      x: 11637.228260869566,
      y: 0.1349945068359375,
    },
    {
      x: 11641.304347826088,
      y: 0.16489410400390625,
    },
    {
      x: 11645.38043478261,
      y: 0.19479751586914062,
    },
    {
      x: 11649.45652173913,
      y: 0.21971511840820312,
    },
    {
      x: 11653.532608695652,
      y: 0.24961471557617188,
    },
    {
      x: 11657.608695652174,
      y: 0.2745361328125,
    },
    {
      x: 11661.684782608696,
      y: 0.2994499206542969,
    },
    {
      x: 11665.760869565218,
      y: 0.3293495178222656,
    },
    {
      x: 11669.83695652174,
      y: 0.35427093505859375,
    },
    {
      x: 11673.913043478262,
      y: 0.3692207336425781,
    },
    {
      x: 11677.989130434782,
      y: 0.3592529296875,
    },
    {
      x: 11682.065217391304,
      y: 0.3343391418457031,
    },
    {
      x: 11686.141304347826,
      y: 0.309417724609375,
    },
    {
      x: 11690.217391304348,
      y: 0.2845001220703125,
    },
    {
      x: 11694.29347826087,
      y: 0.25460052490234375,
    },
    {
      x: 11698.369565217392,
      y: 0.22469711303710938,
    },
    {
      x: 11702.445652173914,
      y: 0.1997833251953125,
    },
    {
      x: 11706.521739130436,
      y: 0.17486572265625,
    },
    {
      x: 11710.597826086956,
      y: 0.14994430541992188,
    },
    {
      x: 11714.673913043478,
      y: 0.12004470825195312,
    },
    {
      x: 11718.75,
      y: 0.110076904296875,
    },
    {
      x: 11722.826086956522,
      y: 0.10509490966796875,
    },
    {
      x: 11726.902173913044,
      y: 0.10509490966796875,
    },
    {
      x: 11735.054347826088,
      y: 0.10509490966796875,
    },
    {
      x: 11739.13043478261,
      y: 0.10010910034179688,
    },
    {
      x: 11743.20652173913,
      y: 0.10010910034179688,
    },
    {
      x: 11995.923913043478,
      y: 0.10010910034179688,
    },
    {
      x: 12000,
      y: 0.06024169921875,
    },
    {
      x: 12004.076086956522,
      y: 0.05525970458984375,
    },
    {
      x: 12008.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 12012.228260869566,
      y: 0.050273895263671875,
    },
    {
      x: 12016.304347826088,
      y: 0.045291900634765625,
    },
    {
      x: 12020.38043478261,
      y: 0.045291900634765625,
    },
    {
      x: 12024.45652173913,
      y: 0.040309906005859375,
    },
    {
      x: 12028.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 12032.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 12036.684782608696,
      y: 0.0353240966796875,
    },
    {
      x: 12040.760869565218,
      y: 0.0353240966796875,
    },
    {
      x: 12044.83695652174,
      y: 0.0353240966796875,
    },
    {
      x: 12048.913043478262,
      y: 0.03034210205078125,
    },
    {
      x: 12052.989130434782,
      y: 0.03034210205078125,
    },
    {
      x: 12057.065217391304,
      y: 0.0353240966796875,
    },
    {
      x: 12061.141304347826,
      y: 0.040309906005859375,
    },
    {
      x: 12065.217391304348,
      y: 0.045291900634765625,
    },
    {
      x: 12069.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 12073.369565217392,
      y: 0.050273895263671875,
    },
    {
      x: 12077.445652173914,
      y: 0.05525970458984375,
    },
    {
      x: 12081.521739130436,
      y: 0.06024169921875,
    },
    {
      x: 12085.597826086956,
      y: 0.06522369384765625,
    },
    {
      x: 12089.673913043478,
      y: 0.07020950317382812,
    },
    {
      x: 12093.75,
      y: 0.0751953125,
    },
    {
      x: 12097.826086956522,
      y: 0.0751953125,
    },
    {
      x: 12101.902173913044,
      y: 0.08017730712890625,
    },
    {
      x: 12105.978260869566,
      y: 0.08017730712890625,
    },
    {
      x: 12110.054347826088,
      y: 0.0851593017578125,
    },
    {
      x: 12114.13043478261,
      y: 0.09512710571289062,
    },
    {
      x: 12118.20652173913,
      y: 0.09512710571289062,
    },
    {
      x: 12122.282608695652,
      y: 0.10010910034179688,
    },
    {
      x: 12126.358695652174,
      y: 0.10010910034179688,
    },
    {
      x: 12256.79347826087,
      y: 0.10010910034179688,
    },
    {
      x: 12260.869565217392,
      y: 0.12004470825195312,
    },
    {
      x: 12264.945652173914,
      y: 0.110076904296875,
    },
    {
      x: 12269.021739130436,
      y: 0.10509490966796875,
    },
    {
      x: 12273.097826086956,
      y: 0.10509490966796875,
    },
    {
      x: 12485.054347826088,
      y: 0.10509490966796875,
    },
    {
      x: 12489.13043478261,
      y: 0.09512710571289062,
    },
    {
      x: 12493.20652173913,
      y: 0.08017730712890625,
    },
    {
      x: 12497.282608695652,
      y: 0.0751953125,
    },
    {
      x: 12501.358695652174,
      y: 0.07020950317382812,
    },
    {
      x: 12505.434782608696,
      y: 0.06522369384765625,
    },
    {
      x: 12509.510869565218,
      y: 0.06522369384765625,
    },
    {
      x: 12513.58695652174,
      y: 0.06024169921875,
    },
    {
      x: 12517.663043478262,
      y: 0.06024169921875,
    },
    {
      x: 12521.739130434784,
      y: 0.10010910034179688,
    },
    {
      x: 12525.815217391304,
      y: 0.10010910034179688,
    },
    {
      x: 12697.010869565218,
      y: 0.10010910034179688,
    },
    {
      x: 12701.08695652174,
      y: 0.10509490966796875,
    },
    {
      x: 12705.163043478262,
      y: 0.1349945068359375,
    },
    {
      x: 12709.239130434784,
      y: 0.16489410400390625,
    },
    {
      x: 12713.315217391304,
      y: 0.19479751586914062,
    },
    {
      x: 12717.391304347826,
      y: 0.21971511840820312,
    },
    {
      x: 12721.467391304348,
      y: 0.24961471557617188,
    },
    {
      x: 12725.54347826087,
      y: 0.2745361328125,
    },
    {
      x: 12729.619565217392,
      y: 0.2994499206542969,
    },
    {
      x: 12733.695652173914,
      y: 0.3293495178222656,
    },
    {
      x: 12737.771739130436,
      y: 0.35427093505859375,
    },
    {
      x: 12741.847826086956,
      y: 0.3692207336425781,
    },
    {
      x: 12745.923913043478,
      y: 0.3592529296875,
    },
    {
      x: 12750,
      y: 0.3343391418457031,
    },
    {
      x: 12754.076086956522,
      y: 0.309417724609375,
    },
    {
      x: 12758.152173913044,
      y: 0.2845001220703125,
    },
    {
      x: 12762.228260869566,
      y: 0.25460052490234375,
    },
    {
      x: 12766.304347826088,
      y: 0.22469711303710938,
    },
    {
      x: 12770.38043478261,
      y: 0.1997833251953125,
    },
    {
      x: 12774.45652173913,
      y: 0.17486572265625,
    },
    {
      x: 12778.532608695652,
      y: 0.14994430541992188,
    },
    {
      x: 12782.608695652174,
      y: 0.10010910034179688,
    },
    {
      x: 12786.684782608696,
      y: 0.10010910034179688,
    },
    {
      x: 13039.402173913044,
      y: 0.10010910034179688,
    },
    {
      x: 13043.478260869566,
      y: 0.0751953125,
    },
    {
      x: 13047.554347826088,
      y: 0.07020950317382812,
    },
    {
      x: 13051.63043478261,
      y: 0.06522369384765625,
    },
    {
      x: 13055.70652173913,
      y: 0.06522369384765625,
    },
    {
      x: 13059.782608695652,
      y: 0.06024169921875,
    },
    {
      x: 13063.858695652174,
      y: 0.06024169921875,
    },
    {
      x: 13067.934782608696,
      y: 0.06024169921875,
    },
    {
      x: 13072.010869565218,
      y: 0.05525970458984375,
    },
    {
      x: 13076.08695652174,
      y: 0.05525970458984375,
    },
    {
      x: 13080.163043478262,
      y: 0.050273895263671875,
    },
    {
      x: 13084.239130434784,
      y: 0.045291900634765625,
    },
    {
      x: 13088.315217391304,
      y: 0.045291900634765625,
    },
    {
      x: 13092.391304347826,
      y: 0.040309906005859375,
    },
    {
      x: 13096.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 13100.54347826087,
      y: 0.040309906005859375,
    },
    {
      x: 13104.619565217392,
      y: 0.0353240966796875,
    },
    {
      x: 13108.695652173914,
      y: 0.0353240966796875,
    },
    {
      x: 13112.771739130436,
      y: 0.03034210205078125,
    },
    {
      x: 13116.847826086956,
      y: 0.03034210205078125,
    },
    {
      x: 13120.923913043478,
      y: 0.03034210205078125,
    },
    {
      x: 13125,
      y: 0.0353240966796875,
    },
    {
      x: 13129.076086956522,
      y: 0.040309906005859375,
    },
    {
      x: 13133.152173913044,
      y: 0.045291900634765625,
    },
    {
      x: 13137.228260869566,
      y: 0.050273895263671875,
    },
    {
      x: 13141.304347826088,
      y: 0.050273895263671875,
    },
    {
      x: 13145.38043478261,
      y: 0.05525970458984375,
    },
    {
      x: 13149.45652173913,
      y: 0.06024169921875,
    },
    {
      x: 13153.532608695652,
      y: 0.06522369384765625,
    },
    {
      x: 13157.608695652174,
      y: 0.07020950317382812,
    },
    {
      x: 13161.684782608696,
      y: 0.0751953125,
    },
    {
      x: 13165.760869565218,
      y: 0.0751953125,
    },
    {
      x: 13169.83695652174,
      y: 0.08017730712890625,
    },
    {
      x: 13173.913043478262,
      y: 0.08017730712890625,
    },
    {
      x: 13177.989130434784,
      y: 0.0851593017578125,
    },
    {
      x: 13182.065217391304,
      y: 0.09512710571289062,
    },
    {
      x: 13186.141304347826,
      y: 0.09512710571289062,
    },
    {
      x: 13190.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 13194.29347826087,
      y: 0.10010910034179688,
    },
    {
      x: 13300.271739130436,
      y: 0.10010910034179688,
    },
    {
      x: 13304.347826086956,
      y: 0.2845001220703125,
    },
    {
      x: 13308.423913043478,
      y: 0.25460052490234375,
    },
    {
      x: 13312.5,
      y: 0.22469711303710938,
    },
    {
      x: 13316.576086956522,
      y: 0.1997833251953125,
    },
    {
      x: 13320.652173913044,
      y: 0.17486572265625,
    },
    {
      x: 13324.728260869566,
      y: 0.14994430541992188,
    },
    {
      x: 13328.804347826088,
      y: 0.12004470825195312,
    },
    {
      x: 13332.88043478261,
      y: 0.110076904296875,
    },
    {
      x: 13336.95652173913,
      y: 0.10509490966796875,
    },
    {
      x: 13341.032608695652,
      y: 0.10509490966796875,
    },
    {
      x: 13552.989130434784,
      y: 0.10509490966796875,
    },
    {
      x: 13557.065217391304,
      y: 0.09512710571289062,
    },
    {
      x: 13561.141304347826,
      y: 0.08017730712890625,
    },
    {
      x: 13565.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 13569.29347826087,
      y: 0.10010910034179688,
    },
    {
      x: 13764.945652173914,
      y: 0.10010910034179688,
    },
    {
      x: 13769.021739130436,
      y: 0.10509490966796875,
    },
    {
      x: 13773.097826086956,
      y: 0.1349945068359375,
    },
    {
      x: 13777.173913043478,
      y: 0.16489410400390625,
    },
    {
      x: 13781.25,
      y: 0.19479751586914062,
    },
    {
      x: 13785.326086956522,
      y: 0.21971511840820312,
    },
    {
      x: 13789.402173913044,
      y: 0.24961471557617188,
    },
    {
      x: 13793.478260869566,
      y: 0.2745361328125,
    },
    {
      x: 13797.554347826088,
      y: 0.2994499206542969,
    },
    {
      x: 13801.63043478261,
      y: 0.3293495178222656,
    },
    {
      x: 13805.70652173913,
      y: 0.35427093505859375,
    },
    {
      x: 13809.782608695652,
      y: 0.3692207336425781,
    },
    {
      x: 13813.858695652174,
      y: 0.3592529296875,
    },
    {
      x: 13817.934782608696,
      y: 0.3343391418457031,
    },
    {
      x: 13822.010869565218,
      y: 0.309417724609375,
    },
    {
      x: 13826.08695652174,
      y: 0.10010910034179688,
    },
    {
      x: 13830.163043478262,
      y: 0.10010910034179688,
    },
    {
      x: 14082.88043478261,
      y: 0.10010910034179688,
    },
    {
      x: 14086.95652173913,
      y: 0.10509490966796875,
    },
    {
      x: 14091.032608695652,
      y: 0.10509490966796875,
    },
    {
      x: 14099.184782608696,
      y: 0.10509490966796875,
    },
    {
      x: 14103.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 14107.33695652174,
      y: 0.08017730712890625,
    },
    {
      x: 14111.413043478262,
      y: 0.0751953125,
    },
    {
      x: 14115.489130434784,
      y: 0.07020950317382812,
    },
    {
      x: 14119.565217391304,
      y: 0.06522369384765625,
    },
    {
      x: 14123.641304347826,
      y: 0.06522369384765625,
    },
    {
      x: 14127.717391304348,
      y: 0.06024169921875,
    },
    {
      x: 14131.79347826087,
      y: 0.06024169921875,
    },
    {
      x: 14135.869565217392,
      y: 0.06024169921875,
    },
    {
      x: 14139.945652173914,
      y: 0.05525970458984375,
    },
    {
      x: 14144.021739130436,
      y: 0.05525970458984375,
    },
    {
      x: 14148.097826086956,
      y: 0.050273895263671875,
    },
    {
      x: 14152.173913043478,
      y: 0.045291900634765625,
    },
    {
      x: 14156.25,
      y: 0.045291900634765625,
    },
    {
      x: 14164.402173913044,
      y: 0.045291900634765625,
    },
    {
      x: 14168.478260869566,
      y: 0.040309906005859375,
    },
    {
      x: 14172.554347826088,
      y: 0.0353240966796875,
    },
    {
      x: 14176.63043478261,
      y: 0.0353240966796875,
    },
    {
      x: 14180.70652173913,
      y: 0.03034210205078125,
    },
    {
      x: 14184.782608695652,
      y: 0.03034210205078125,
    },
    {
      x: 14188.858695652174,
      y: 0.03034210205078125,
    },
    {
      x: 14192.934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 14197.010869565218,
      y: 0.040309906005859375,
    },
    {
      x: 14201.08695652174,
      y: 0.045291900634765625,
    },
    {
      x: 14205.163043478262,
      y: 0.050273895263671875,
    },
    {
      x: 14209.239130434784,
      y: 0.050273895263671875,
    },
    {
      x: 14213.315217391304,
      y: 0.05525970458984375,
    },
    {
      x: 14217.391304347826,
      y: 0.06024169921875,
    },
    {
      x: 14221.467391304348,
      y: 0.06522369384765625,
    },
    {
      x: 14225.54347826087,
      y: 0.07020950317382812,
    },
    {
      x: 14229.619565217392,
      y: 0.0751953125,
    },
    {
      x: 14233.695652173914,
      y: 0.0751953125,
    },
    {
      x: 14237.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 14241.847826086956,
      y: 0.08017730712890625,
    },
    {
      x: 14245.923913043478,
      y: 0.0851593017578125,
    },
    {
      x: 14250,
      y: 0.09014511108398438,
    },
    {
      x: 14254.076086956522,
      y: 0.09512710571289062,
    },
    {
      x: 14258.152173913044,
      y: 0.10010910034179688,
    },
    {
      x: 14262.228260869566,
      y: 0.10010910034179688,
    },
    {
      x: 14343.75,
      y: 0.10010910034179688,
    },
    {
      x: 14347.826086956522,
      y: 0.3293495178222656,
    },
    {
      x: 14351.902173913044,
      y: 0.35427093505859375,
    },
    {
      x: 14355.978260869566,
      y: 0.3692207336425781,
    },
    {
      x: 14360.054347826088,
      y: 0.3592529296875,
    },
    {
      x: 14364.13043478261,
      y: 0.3343391418457031,
    },
    {
      x: 14368.20652173913,
      y: 0.309417724609375,
    },
    {
      x: 14372.282608695652,
      y: 0.2845001220703125,
    },
    {
      x: 14376.358695652174,
      y: 0.25460052490234375,
    },
    {
      x: 14380.434782608696,
      y: 0.22469711303710938,
    },
    {
      x: 14384.510869565218,
      y: 0.1997833251953125,
    },
    {
      x: 14388.58695652174,
      y: 0.17486572265625,
    },
    {
      x: 14392.663043478262,
      y: 0.14994430541992188,
    },
    {
      x: 14396.739130434784,
      y: 0.12004470825195312,
    },
    {
      x: 14400.815217391304,
      y: 0.110076904296875,
    },
    {
      x: 14404.891304347826,
      y: 0.10509490966796875,
    },
    {
      x: 14408.967391304348,
      y: 0.10509490966796875,
    },
    {
      x: 14604.619565217392,
      y: 0.10509490966796875,
    },
    {
      x: 14608.695652173914,
      y: 0.10010910034179688,
    },
    {
      x: 14612.771739130436,
      y: 0.10010910034179688,
    },
    {
      x: 14832.88043478261,
      y: 0.10010910034179688,
    },
    {
      x: 14836.95652173913,
      y: 0.10509490966796875,
    },
    {
      x: 14841.032608695652,
      y: 0.1349945068359375,
    },
    {
      x: 14845.108695652174,
      y: 0.16489410400390625,
    },
    {
      x: 14849.184782608696,
      y: 0.19479751586914062,
    },
    {
      x: 14853.260869565218,
      y: 0.21971511840820312,
    },
    {
      x: 14857.33695652174,
      y: 0.24961471557617188,
    },
    {
      x: 14861.413043478262,
      y: 0.2745361328125,
    },
    {
      x: 14865.489130434784,
      y: 0.2994499206542969,
    },
    {
      x: 14869.565217391304,
      y: 0.16489410400390625,
    },
    {
      x: 14873.641304347826,
      y: 0.19479751586914062,
    },
    {
      x: 14877.717391304348,
      y: 0.21971511840820312,
    },
    {
      x: 14881.79347826087,
      y: 0.24961471557617188,
    },
    {
      x: 14885.869565217392,
      y: 0.2745361328125,
    },
    {
      x: 14889.945652173914,
      y: 0.2994499206542969,
    },
    {
      x: 14894.021739130436,
      y: 0.3293495178222656,
    },
    {
      x: 14898.097826086956,
      y: 0.35427093505859375,
    },
    {
      x: 14902.173913043478,
      y: 0.3692207336425781,
    },
    {
      x: 14906.25,
      y: 0.3592529296875,
    },
    {
      x: 14910.326086956522,
      y: 0.3343391418457031,
    },
    {
      x: 14914.402173913044,
      y: 0.309417724609375,
    },
    {
      x: 14918.478260869566,
      y: 0.2845001220703125,
    },
    {
      x: 14922.554347826088,
      y: 0.25460052490234375,
    },
    {
      x: 14926.63043478261,
      y: 0.22469711303710938,
    },
    {
      x: 14930.70652173913,
      y: 0.1997833251953125,
    },
    {
      x: 14934.782608695652,
      y: 0.17486572265625,
    },
    {
      x: 14938.858695652174,
      y: 0.14994430541992188,
    },
    {
      x: 14942.934782608696,
      y: 0.12004470825195312,
    },
    {
      x: 14947.010869565218,
      y: 0.110076904296875,
    },
    {
      x: 14951.08695652174,
      y: 0.10509490966796875,
    },
    {
      x: 14955.163043478262,
      y: 0.10509490966796875,
    },
    {
      x: 15167.119565217392,
      y: 0.10509490966796875,
    },
    {
      x: 15171.195652173914,
      y: 0.09512710571289062,
    },
    {
      x: 15175.271739130436,
      y: 0.08017730712890625,
    },
    {
      x: 15179.347826086956,
      y: 0.0751953125,
    },
    {
      x: 15183.423913043478,
      y: 0.07020950317382812,
    },
    {
      x: 15187.5,
      y: 0.06522369384765625,
    },
    {
      x: 15191.576086956522,
      y: 0.06522369384765625,
    },
    {
      x: 15195.652173913044,
      y: 0.06024169921875,
    },
    {
      x: 15199.728260869566,
      y: 0.06024169921875,
    },
    {
      x: 15203.804347826088,
      y: 0.06024169921875,
    },
    {
      x: 15207.88043478261,
      y: 0.05525970458984375,
    },
    {
      x: 15211.95652173913,
      y: 0.05525970458984375,
    },
    {
      x: 15216.032608695652,
      y: 0.050273895263671875,
    },
    {
      x: 15220.108695652174,
      y: 0.045291900634765625,
    },
    {
      x: 15224.184782608696,
      y: 0.045291900634765625,
    },
    {
      x: 15228.260869565218,
      y: 0.040309906005859375,
    },
    {
      x: 15232.33695652174,
      y: 0.040309906005859375,
    },
    {
      x: 15236.413043478262,
      y: 0.040309906005859375,
    },
    {
      x: 15240.489130434784,
      y: 0.0353240966796875,
    },
    {
      x: 15244.565217391304,
      y: 0.0353240966796875,
    },
    {
      x: 15248.641304347826,
      y: 0.03034210205078125,
    },
    {
      x: 15252.717391304348,
      y: 0.03034210205078125,
    },
    {
      x: 15256.79347826087,
      y: 0.03034210205078125,
    },
    {
      x: 15260.869565217392,
      y: 0.0353240966796875,
    },
    {
      x: 15264.945652173914,
      y: 0.040309906005859375,
    },
    {
      x: 15269.021739130436,
      y: 0.045291900634765625,
    },
    {
      x: 15273.097826086956,
      y: 0.050273895263671875,
    },
    {
      x: 15277.173913043478,
      y: 0.050273895263671875,
    },
    {
      x: 15281.25,
      y: 0.05525970458984375,
    },
    {
      x: 15285.326086956522,
      y: 0.06024169921875,
    },
    {
      x: 15289.402173913044,
      y: 0.06522369384765625,
    },
    {
      x: 15293.478260869566,
      y: 0.07020950317382812,
    },
    {
      x: 15297.554347826088,
      y: 0.0751953125,
    },
    {
      x: 15301.63043478261,
      y: 0.0751953125,
    },
    {
      x: 15305.70652173913,
      y: 0.08017730712890625,
    },
    {
      x: 15309.782608695652,
      y: 0.08017730712890625,
    },
    {
      x: 15313.858695652174,
      y: 0.0851593017578125,
    },
    {
      x: 15317.934782608696,
      y: 0.09512710571289062,
    },
    {
      x: 15322.010869565218,
      y: 0.09512710571289062,
    },
    {
      x: 15326.08695652174,
      y: 0.10010910034179688,
    },
    {
      x: 15330.163043478262,
      y: 0.10010910034179688,
    },
    {
      x: 15648.097826086958,
      y: 0.10010910034179688,
    },
    {
      x: 15652.173913043478,
      y: 0.10509490966796875,
    },
    {
      x: 15656.25,
      y: 0.10509490966796875,
    },
    {
      x: 15713.315217391304,
      y: 0.10509490966796875,
    },
    {
      x: 15717.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 15721.467391304348,
      y: 0.08017730712890625,
    },
    {
      x: 15725.54347826087,
      y: 0.0751953125,
    },
    {
      x: 15729.619565217392,
      y: 0.07020950317382812,
    },
    {
      x: 15733.695652173914,
      y: 0.06522369384765625,
    },
    {
      x: 15737.771739130436,
      y: 0.06522369384765625,
    },
    {
      x: 15741.847826086958,
      y: 0.06024169921875,
    },
    {
      x: 15745.923913043478,
      y: 0.06024169921875,
    },
    {
      x: 15750,
      y: 0.06024169921875,
    },
    {
      x: 15754.076086956522,
      y: 0.05525970458984375,
    },
    {
      x: 15758.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 15762.228260869566,
      y: 0.050273895263671875,
    },
    {
      x: 15766.304347826088,
      y: 0.045291900634765625,
    },
    {
      x: 15770.38043478261,
      y: 0.045291900634765625,
    },
    {
      x: 15774.45652173913,
      y: 0.040309906005859375,
    },
    {
      x: 15778.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 15782.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 15786.684782608696,
      y: 0.0353240966796875,
    },
    {
      x: 15790.760869565218,
      y: 0.0353240966796875,
    },
    {
      x: 15794.83695652174,
      y: 0.0353240966796875,
    },
    {
      x: 15798.913043478262,
      y: 0.03034210205078125,
    },
    {
      x: 15802.989130434784,
      y: 0.03034210205078125,
    },
    {
      x: 15807.065217391304,
      y: 0.0353240966796875,
    },
    {
      x: 15811.141304347826,
      y: 0.040309906005859375,
    },
    {
      x: 15815.217391304348,
      y: 0.045291900634765625,
    },
    {
      x: 15819.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 15823.369565217392,
      y: 0.050273895263671875,
    },
    {
      x: 15827.445652173914,
      y: 0.05525970458984375,
    },
    {
      x: 15831.521739130436,
      y: 0.06024169921875,
    },
    {
      x: 15835.597826086958,
      y: 0.06522369384765625,
    },
    {
      x: 15839.673913043478,
      y: 0.07020950317382812,
    },
    {
      x: 15843.75,
      y: 0.0751953125,
    },
    {
      x: 15847.826086956522,
      y: 0.0751953125,
    },
    {
      x: 15851.902173913044,
      y: 0.08017730712890625,
    },
    {
      x: 15855.978260869566,
      y: 0.08017730712890625,
    },
    {
      x: 15860.054347826088,
      y: 0.0851593017578125,
    },
    {
      x: 15864.13043478261,
      y: 0.09512710571289062,
    },
    {
      x: 15868.20652173913,
      y: 0.09512710571289062,
    },
    {
      x: 15872.282608695652,
      y: 0.10010910034179688,
    },
    {
      x: 15876.358695652174,
      y: 0.10010910034179688,
    },
    {
      x: 16161.684782608696,
      y: 0.10010910034179688,
    },
    {
      x: 16165.760869565218,
      y: 0.10509490966796875,
    },
    {
      x: 16169.83695652174,
      y: 0.1349945068359375,
    },
    {
      x: 16173.913043478262,
      y: 0.10010910034179688,
    },
    {
      x: 16177.989130434784,
      y: 0.10010910034179688,
    },
    {
      x: 16447.010869565216,
      y: 0.10010910034179688,
    },
    {
      x: 16451.08695652174,
      y: 0.10509490966796875,
    },
    {
      x: 16455.16304347826,
      y: 0.1349945068359375,
    },
    {
      x: 16459.239130434784,
      y: 0.16489410400390625,
    },
    {
      x: 16463.315217391304,
      y: 0.19479751586914062,
    },
    {
      x: 16467.391304347828,
      y: 0.21971511840820312,
    },
    {
      x: 16471.467391304348,
      y: 0.24961471557617188,
    },
    {
      x: 16475.54347826087,
      y: 0.2745361328125,
    },
    {
      x: 16479.619565217392,
      y: 0.2994499206542969,
    },
    {
      x: 16483.695652173912,
      y: 0.3293495178222656,
    },
    {
      x: 16487.771739130436,
      y: 0.35427093505859375,
    },
    {
      x: 16491.847826086956,
      y: 0.3692207336425781,
    },
    {
      x: 16495.92391304348,
      y: 0.3592529296875,
    },
    {
      x: 16500,
      y: 0.3343391418457031,
    },
    {
      x: 16504.076086956524,
      y: 0.309417724609375,
    },
    {
      x: 16508.152173913044,
      y: 0.2845001220703125,
    },
    {
      x: 16512.228260869564,
      y: 0.25460052490234375,
    },
    {
      x: 16516.304347826088,
      y: 0.22469711303710938,
    },
    {
      x: 16520.380434782608,
      y: 0.1997833251953125,
    },
    {
      x: 16524.456521739132,
      y: 0.17486572265625,
    },
    {
      x: 16528.532608695652,
      y: 0.14994430541992188,
    },
    {
      x: 16532.608695652176,
      y: 0.12004470825195312,
    },
    {
      x: 16536.684782608696,
      y: 0.110076904296875,
    },
    {
      x: 16540.760869565216,
      y: 0.10509490966796875,
    },
    {
      x: 16544.83695652174,
      y: 0.10509490966796875,
    },
    {
      x: 16691.576086956524,
      y: 0.10509490966796875,
    },
    {
      x: 16695.652173913044,
      y: 0.10010910034179688,
    },
    {
      x: 16699.728260869564,
      y: 0.10010910034179688,
    },
    {
      x: 17254.076086956524,
      y: 0.10010910034179688,
    },
    {
      x: 17258.152173913044,
      y: 0.10509490966796875,
    },
    {
      x: 17262.228260869564,
      y: 0.1349945068359375,
    },
    {
      x: 17266.304347826088,
      y: 0.16489410400390625,
    },
    {
      x: 17270.380434782608,
      y: 0.19479751586914062,
    },
    {
      x: 17274.456521739132,
      y: 0.21971511840820312,
    },
    {
      x: 17278.532608695652,
      y: 0.24961471557617188,
    },
    {
      x: 17282.608695652176,
      y: 0.2745361328125,
    },
    {
      x: 17286.684782608696,
      y: 0.2994499206542969,
    },
    {
      x: 17290.760869565216,
      y: 0.3293495178222656,
    },
    {
      x: 17294.83695652174,
      y: 0.35427093505859375,
    },
    {
      x: 17298.91304347826,
      y: 0.3692207336425781,
    },
    {
      x: 17302.989130434784,
      y: 0.3592529296875,
    },
    {
      x: 17307.065217391304,
      y: 0.3343391418457031,
    },
    {
      x: 17311.141304347828,
      y: 0.309417724609375,
    },
    {
      x: 17315.217391304348,
      y: 0.2845001220703125,
    },
    {
      x: 17319.29347826087,
      y: 0.25460052490234375,
    },
    {
      x: 17323.369565217392,
      y: 0.22469711303710938,
    },
    {
      x: 17327.445652173912,
      y: 0.1997833251953125,
    },
    {
      x: 17331.521739130436,
      y: 0.17486572265625,
    },
    {
      x: 17335.597826086956,
      y: 0.14994430541992188,
    },
    {
      x: 17339.67391304348,
      y: 0.12004470825195312,
    },
    {
      x: 17343.75,
      y: 0.110076904296875,
    },
    {
      x: 17347.826086956524,
      y: 0.10509490966796875,
    },
    {
      x: 17351.902173913044,
      y: 0.10509490966796875,
    },
    {
      x: 17563.858695652176,
      y: 0.10509490966796875,
    },
    {
      x: 17567.934782608696,
      y: 0.09512710571289062,
    },
    {
      x: 17572.010869565216,
      y: 0.08017730712890625,
    },
    {
      x: 17576.08695652174,
      y: 0.0751953125,
    },
    {
      x: 17580.16304347826,
      y: 0.07020950317382812,
    },
    {
      x: 17584.239130434784,
      y: 0.06522369384765625,
    },
    {
      x: 17588.315217391304,
      y: 0.06522369384765625,
    },
    {
      x: 17592.391304347828,
      y: 0.06024169921875,
    },
    {
      x: 17596.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 17600.54347826087,
      y: 0.06024169921875,
    },
    {
      x: 17604.619565217392,
      y: 0.05525970458984375,
    },
    {
      x: 17608.695652173912,
      y: 0.05525970458984375,
    },
    {
      x: 17612.771739130436,
      y: 0.050273895263671875,
    },
    {
      x: 17616.847826086956,
      y: 0.045291900634765625,
    },
    {
      x: 17620.92391304348,
      y: 0.045291900634765625,
    },
    {
      x: 17625,
      y: 0.040309906005859375,
    },
    {
      x: 17629.076086956524,
      y: 0.040309906005859375,
    },
    {
      x: 17633.152173913044,
      y: 0.040309906005859375,
    },
    {
      x: 17637.228260869564,
      y: 0.0353240966796875,
    },
    {
      x: 17641.304347826088,
      y: 0.0353240966796875,
    },
    {
      x: 17645.380434782608,
      y: 0.03034210205078125,
    },
    {
      x: 17649.456521739132,
      y: 0.03034210205078125,
    },
    {
      x: 17653.532608695652,
      y: 0.03034210205078125,
    },
    {
      x: 17657.608695652176,
      y: 0.0353240966796875,
    },
    {
      x: 17661.684782608696,
      y: 0.040309906005859375,
    },
    {
      x: 17665.760869565216,
      y: 0.045291900634765625,
    },
    {
      x: 17669.83695652174,
      y: 0.050273895263671875,
    },
    {
      x: 17673.91304347826,
      y: 0.050273895263671875,
    },
    {
      x: 17677.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 17682.065217391304,
      y: 0.06024169921875,
    },
    {
      x: 17686.141304347828,
      y: 0.06522369384765625,
    },
    {
      x: 17690.217391304348,
      y: 0.07020950317382812,
    },
    {
      x: 17694.29347826087,
      y: 0.0751953125,
    },
    {
      x: 17698.369565217392,
      y: 0.0751953125,
    },
    {
      x: 17702.445652173912,
      y: 0.08017730712890625,
    },
    {
      x: 17706.521739130436,
      y: 0.08017730712890625,
    },
    {
      x: 17710.597826086956,
      y: 0.0851593017578125,
    },
    {
      x: 17714.67391304348,
      y: 0.09512710571289062,
    },
    {
      x: 17718.75,
      y: 0.09512710571289062,
    },
    {
      x: 17722.826086956524,
      y: 0.10010910034179688,
    },
    {
      x: 17726.902173913044,
      y: 0.10010910034179688,
    },
    {
      x: 17735.054347826088,
      y: 0.10010910034179688,
    },
    {
      x: 17739.130434782608,
      y: 0.10509490966796875,
    },
    {
      x: 17743.206521739132,
      y: 0.10509490966796875,
    },
    {
      x: 17849.184782608696,
      y: 0.10509490966796875,
    },
    {
      x: 17853.260869565216,
      y: 0.09512710571289062,
    },
    {
      x: 17857.33695652174,
      y: 0.08017730712890625,
    },
    {
      x: 17861.41304347826,
      y: 0.0751953125,
    },
    {
      x: 17865.489130434784,
      y: 0.07020950317382812,
    },
    {
      x: 17869.565217391304,
      y: 0.06522369384765625,
    },
    {
      x: 17873.641304347828,
      y: 0.06522369384765625,
    },
    {
      x: 17877.717391304348,
      y: 0.06024169921875,
    },
    {
      x: 17881.79347826087,
      y: 0.06024169921875,
    },
    {
      x: 17885.869565217392,
      y: 0.06024169921875,
    },
    {
      x: 17889.945652173912,
      y: 0.05525970458984375,
    },
    {
      x: 17894.021739130436,
      y: 0.05525970458984375,
    },
    {
      x: 17898.097826086956,
      y: 0.050273895263671875,
    },
    {
      x: 17902.17391304348,
      y: 0.045291900634765625,
    },
    {
      x: 17906.25,
      y: 0.045291900634765625,
    },
    {
      x: 17914.402173913044,
      y: 0.045291900634765625,
    },
    {
      x: 17918.478260869564,
      y: 0.040309906005859375,
    },
    {
      x: 17922.554347826088,
      y: 0.0353240966796875,
    },
    {
      x: 17926.630434782608,
      y: 0.0353240966796875,
    },
    {
      x: 17930.706521739132,
      y: 0.03034210205078125,
    },
    {
      x: 17934.782608695652,
      y: 0.03034210205078125,
    },
    {
      x: 17938.858695652176,
      y: 0.03034210205078125,
    },
    {
      x: 17942.934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 17947.010869565216,
      y: 0.040309906005859375,
    },
    {
      x: 17951.08695652174,
      y: 0.045291900634765625,
    },
    {
      x: 17955.16304347826,
      y: 0.050273895263671875,
    },
    {
      x: 17959.239130434784,
      y: 0.050273895263671875,
    },
    {
      x: 17963.315217391304,
      y: 0.05525970458984375,
    },
    {
      x: 17967.391304347828,
      y: 0.06024169921875,
    },
    {
      x: 17971.467391304348,
      y: 0.06522369384765625,
    },
    {
      x: 17975.54347826087,
      y: 0.07020950317382812,
    },
    {
      x: 17979.619565217392,
      y: 0.0751953125,
    },
    {
      x: 17983.695652173912,
      y: 0.0751953125,
    },
    {
      x: 17987.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 17991.847826086956,
      y: 0.08017730712890625,
    },
    {
      x: 17995.92391304348,
      y: 0.0851593017578125,
    },
    {
      x: 18000,
      y: 0.10010910034179688,
    },
    {
      x: 18004.076086956524,
      y: 0.10010910034179688,
    },
    {
      x: 18061.141304347828,
      y: 0.10010910034179688,
    },
    {
      x: 18065.217391304348,
      y: 0.10509490966796875,
    },
    {
      x: 18069.29347826087,
      y: 0.1349945068359375,
    },
    {
      x: 18073.369565217392,
      y: 0.16489410400390625,
    },
    {
      x: 18077.445652173912,
      y: 0.19479751586914062,
    },
    {
      x: 18081.521739130436,
      y: 0.21971511840820312,
    },
    {
      x: 18085.597826086956,
      y: 0.24961471557617188,
    },
    {
      x: 18089.67391304348,
      y: 0.2745361328125,
    },
    {
      x: 18093.75,
      y: 0.2994499206542969,
    },
    {
      x: 18097.826086956524,
      y: 0.3293495178222656,
    },
    {
      x: 18101.902173913044,
      y: 0.35427093505859375,
    },
    {
      x: 18105.978260869564,
      y: 0.3692207336425781,
    },
    {
      x: 18110.054347826088,
      y: 0.3592529296875,
    },
    {
      x: 18114.130434782608,
      y: 0.3343391418457031,
    },
    {
      x: 18118.206521739132,
      y: 0.309417724609375,
    },
    {
      x: 18122.282608695652,
      y: 0.2845001220703125,
    },
    {
      x: 18126.358695652176,
      y: 0.25460052490234375,
    },
    {
      x: 18130.434782608696,
      y: 0.22469711303710938,
    },
    {
      x: 18134.510869565216,
      y: 0.1997833251953125,
    },
    {
      x: 18138.58695652174,
      y: 0.17486572265625,
    },
    {
      x: 18142.66304347826,
      y: 0.14994430541992188,
    },
    {
      x: 18146.739130434784,
      y: 0.12004470825195312,
    },
    {
      x: 18150.815217391304,
      y: 0.110076904296875,
    },
    {
      x: 18154.891304347828,
      y: 0.10509490966796875,
    },
    {
      x: 18158.967391304348,
      y: 0.10509490966796875,
    },
    {
      x: 18256.79347826087,
      y: 0.10509490966796875,
    },
    {
      x: 18260.869565217392,
      y: 0.10010910034179688,
    },
    {
      x: 18264.945652173912,
      y: 0.10010910034179688,
    },
    {
      x: 18517.66304347826,
      y: 0.10010910034179688,
    },
    {
      x: 18521.739130434784,
      y: 0.09014511108398438,
    },
    {
      x: 18525.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 18529.891304347828,
      y: 0.10010910034179688,
    },
    {
      x: 18533.967391304348,
      y: 0.10010910034179688,
    },
    {
      x: 19039.402173913044,
      y: 0.10010910034179688,
    },
    {
      x: 19043.478260869564,
      y: 0.07020950317382812,
    },
    {
      x: 19047.554347826088,
      y: 0.0751953125,
    },
    {
      x: 19051.630434782608,
      y: 0.0751953125,
    },
    {
      x: 19055.706521739132,
      y: 0.08017730712890625,
    },
    {
      x: 19059.782608695652,
      y: 0.08017730712890625,
    },
    {
      x: 19063.858695652176,
      y: 0.0851593017578125,
    },
    {
      x: 19067.934782608696,
      y: 0.09512710571289062,
    },
    {
      x: 19072.010869565216,
      y: 0.09512710571289062,
    },
    {
      x: 19076.08695652174,
      y: 0.10010910034179688,
    },
    {
      x: 19080.16304347826,
      y: 0.10010910034179688,
    },
    {
      x: 19300.271739130436,
      y: 0.10010910034179688,
    },
    {
      x: 19304.347826086956,
      y: 0.10509490966796875,
    },
    {
      x: 19308.42391304348,
      y: 0.10509490966796875,
    },
    {
      x: 19438.858695652176,
      y: 0.10509490966796875,
    },
    {
      x: 19442.934782608696,
      y: 0.09512710571289062,
    },
    {
      x: 19447.010869565216,
      y: 0.08017730712890625,
    },
    {
      x: 19451.08695652174,
      y: 0.0751953125,
    },
    {
      x: 19455.16304347826,
      y: 0.07020950317382812,
    },
    {
      x: 19459.239130434784,
      y: 0.06522369384765625,
    },
    {
      x: 19463.315217391304,
      y: 0.06522369384765625,
    },
    {
      x: 19467.391304347828,
      y: 0.06024169921875,
    },
    {
      x: 19471.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 19475.54347826087,
      y: 0.06024169921875,
    },
    {
      x: 19479.619565217392,
      y: 0.05525970458984375,
    },
    {
      x: 19483.695652173912,
      y: 0.05525970458984375,
    },
    {
      x: 19487.771739130436,
      y: 0.050273895263671875,
    },
    {
      x: 19491.847826086956,
      y: 0.045291900634765625,
    },
    {
      x: 19495.92391304348,
      y: 0.045291900634765625,
    },
    {
      x: 19500,
      y: 0.040309906005859375,
    },
    {
      x: 19504.076086956524,
      y: 0.040309906005859375,
    },
    {
      x: 19508.152173913044,
      y: 0.040309906005859375,
    },
    {
      x: 19512.228260869564,
      y: 0.0353240966796875,
    },
    {
      x: 19516.304347826088,
      y: 0.0353240966796875,
    },
    {
      x: 19520.380434782608,
      y: 0.03034210205078125,
    },
    {
      x: 19524.456521739132,
      y: 0.03034210205078125,
    },
    {
      x: 19528.532608695652,
      y: 0.03034210205078125,
    },
    {
      x: 19532.608695652176,
      y: 0.0353240966796875,
    },
    {
      x: 19536.684782608696,
      y: 0.040309906005859375,
    },
    {
      x: 19540.76086956522,
      y: 0.045291900634765625,
    },
    {
      x: 19544.83695652174,
      y: 0.050273895263671875,
    },
    {
      x: 19548.91304347826,
      y: 0.050273895263671875,
    },
    {
      x: 19552.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 19557.065217391304,
      y: 0.06024169921875,
    },
    {
      x: 19561.141304347828,
      y: 0.06522369384765625,
    },
    {
      x: 19565.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 19569.29347826087,
      y: 0.10010910034179688,
    },
    {
      x: 19650.815217391304,
      y: 0.10010910034179688,
    },
    {
      x: 19654.891304347828,
      y: 0.10509490966796875,
    },
    {
      x: 19658.967391304348,
      y: 0.1349945068359375,
    },
    {
      x: 19663.04347826087,
      y: 0.16489410400390625,
    },
    {
      x: 19667.119565217392,
      y: 0.19479751586914062,
    },
    {
      x: 19671.195652173912,
      y: 0.21971511840820312,
    },
    {
      x: 19675.271739130436,
      y: 0.24961471557617188,
    },
    {
      x: 19679.347826086956,
      y: 0.2745361328125,
    },
    {
      x: 19683.42391304348,
      y: 0.2994499206542969,
    },
    {
      x: 19687.5,
      y: 0.3293495178222656,
    },
    {
      x: 19691.576086956524,
      y: 0.35427093505859375,
    },
    {
      x: 19695.652173913044,
      y: 0.3692207336425781,
    },
    {
      x: 19699.728260869564,
      y: 0.3592529296875,
    },
    {
      x: 19703.804347826088,
      y: 0.3343391418457031,
    },
    {
      x: 19707.880434782608,
      y: 0.309417724609375,
    },
    {
      x: 19711.956521739132,
      y: 0.2845001220703125,
    },
    {
      x: 19716.032608695652,
      y: 0.25460052490234375,
    },
    {
      x: 19720.108695652176,
      y: 0.22469711303710938,
    },
    {
      x: 19724.184782608696,
      y: 0.1997833251953125,
    },
    {
      x: 19728.26086956522,
      y: 0.17486572265625,
    },
    {
      x: 19732.33695652174,
      y: 0.14994430541992188,
    },
    {
      x: 19736.41304347826,
      y: 0.12004470825195312,
    },
    {
      x: 19740.489130434784,
      y: 0.110076904296875,
    },
    {
      x: 19744.565217391304,
      y: 0.10509490966796875,
    },
    {
      x: 19748.641304347828,
      y: 0.10509490966796875,
    },
    {
      x: 19822.01086956522,
      y: 0.10509490966796875,
    },
    {
      x: 19826.08695652174,
      y: 0.10010910034179688,
    },
    {
      x: 19830.16304347826,
      y: 0.10010910034179688,
    },
    {
      x: 20082.880434782608,
      y: 0.10010910034179688,
    },
    {
      x: 20086.956521739132,
      y: 0.045291900634765625,
    },
    {
      x: 20091.032608695652,
      y: 0.050273895263671875,
    },
    {
      x: 20095.108695652176,
      y: 0.050273895263671875,
    },
    {
      x: 20099.184782608696,
      y: 0.05525970458984375,
    },
    {
      x: 20103.26086956522,
      y: 0.06024169921875,
    },
    {
      x: 20107.33695652174,
      y: 0.06522369384765625,
    },
    {
      x: 20111.41304347826,
      y: 0.07020950317382812,
    },
    {
      x: 20115.489130434784,
      y: 0.0751953125,
    },
    {
      x: 20119.565217391304,
      y: 0.0751953125,
    },
    {
      x: 20123.641304347828,
      y: 0.08017730712890625,
    },
    {
      x: 20127.717391304348,
      y: 0.08017730712890625,
    },
    {
      x: 20131.79347826087,
      y: 0.0851593017578125,
    },
    {
      x: 20135.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 20139.945652173912,
      y: 0.09512710571289062,
    },
    {
      x: 20144.021739130436,
      y: 0.10010910034179688,
    },
    {
      x: 20148.097826086956,
      y: 0.10010910034179688,
    },
    {
      x: 20343.75,
      y: 0.10010910034179688,
    },
    {
      x: 20347.826086956524,
      y: 0.10509490966796875,
    },
    {
      x: 20351.902173913044,
      y: 0.10509490966796875,
    },
    {
      x: 20506.79347826087,
      y: 0.10509490966796875,
    },
    {
      x: 20510.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 20514.945652173912,
      y: 0.08017730712890625,
    },
    {
      x: 20519.021739130436,
      y: 0.0751953125,
    },
    {
      x: 20523.097826086956,
      y: 0.07020950317382812,
    },
    {
      x: 20527.17391304348,
      y: 0.06522369384765625,
    },
    {
      x: 20531.25,
      y: 0.06522369384765625,
    },
    {
      x: 20535.326086956524,
      y: 0.06024169921875,
    },
    {
      x: 20539.402173913044,
      y: 0.06024169921875,
    },
    {
      x: 20543.478260869564,
      y: 0.06024169921875,
    },
    {
      x: 20547.554347826088,
      y: 0.05525970458984375,
    },
    {
      x: 20551.630434782608,
      y: 0.05525970458984375,
    },
    {
      x: 20555.706521739132,
      y: 0.050273895263671875,
    },
    {
      x: 20559.782608695652,
      y: 0.045291900634765625,
    },
    {
      x: 20563.858695652176,
      y: 0.045291900634765625,
    },
    {
      x: 20567.934782608696,
      y: 0.040309906005859375,
    },
    {
      x: 20572.01086956522,
      y: 0.040309906005859375,
    },
    {
      x: 20576.08695652174,
      y: 0.040309906005859375,
    },
    {
      x: 20580.16304347826,
      y: 0.0353240966796875,
    },
    {
      x: 20584.239130434784,
      y: 0.0353240966796875,
    },
    {
      x: 20588.315217391304,
      y: 0.0353240966796875,
    },
    {
      x: 20592.391304347828,
      y: 0.03034210205078125,
    },
    {
      x: 20596.467391304348,
      y: 0.03034210205078125,
    },
    {
      x: 20600.54347826087,
      y: 0.0353240966796875,
    },
    {
      x: 20604.619565217392,
      y: 0.040309906005859375,
    },
    {
      x: 20608.695652173912,
      y: 0.10010910034179688,
    },
    {
      x: 20612.771739130436,
      y: 0.10010910034179688,
    },
    {
      x: 20718.75,
      y: 0.10010910034179688,
    },
    {
      x: 20722.826086956524,
      y: 0.10509490966796875,
    },
    {
      x: 20726.902173913044,
      y: 0.1349945068359375,
    },
    {
      x: 20730.978260869564,
      y: 0.16489410400390625,
    },
    {
      x: 20735.054347826088,
      y: 0.19479751586914062,
    },
    {
      x: 20739.130434782608,
      y: 0.21971511840820312,
    },
    {
      x: 20743.206521739132,
      y: 0.24961471557617188,
    },
    {
      x: 20747.282608695652,
      y: 0.2745361328125,
    },
    {
      x: 20751.358695652176,
      y: 0.2994499206542969,
    },
    {
      x: 20755.434782608696,
      y: 0.3293495178222656,
    },
    {
      x: 20759.51086956522,
      y: 0.35427093505859375,
    },
    {
      x: 20763.58695652174,
      y: 0.3692207336425781,
    },
    {
      x: 20767.66304347826,
      y: 0.3592529296875,
    },
    {
      x: 20771.739130434784,
      y: 0.3343391418457031,
    },
    {
      x: 20775.815217391304,
      y: 0.309417724609375,
    },
    {
      x: 20779.891304347828,
      y: 0.2845001220703125,
    },
    {
      x: 20783.967391304348,
      y: 0.25460052490234375,
    },
    {
      x: 20788.04347826087,
      y: 0.22469711303710938,
    },
    {
      x: 20792.119565217392,
      y: 0.1997833251953125,
    },
    {
      x: 20796.195652173912,
      y: 0.17486572265625,
    },
    {
      x: 20800.271739130436,
      y: 0.14994430541992188,
    },
    {
      x: 20804.347826086956,
      y: 0.12004470825195312,
    },
    {
      x: 20808.42391304348,
      y: 0.110076904296875,
    },
    {
      x: 20812.5,
      y: 0.10509490966796875,
    },
    {
      x: 20816.576086956524,
      y: 0.10509490966796875,
    },
    {
      x: 20865.489130434784,
      y: 0.10509490966796875,
    },
    {
      x: 20869.565217391304,
      y: 0.10010910034179688,
    },
    {
      x: 20873.641304347828,
      y: 0.10010910034179688,
    },
    {
      x: 21004.076086956524,
      y: 0.10010910034179688,
    },
    {
      x: 21008.152173913044,
      y: 0.10509490966796875,
    },
    {
      x: 21012.228260869564,
      y: 0.1349945068359375,
    },
    {
      x: 21016.304347826088,
      y: 0.16489410400390625,
    },
    {
      x: 21020.380434782608,
      y: 0.19479751586914062,
    },
    {
      x: 21024.456521739132,
      y: 0.21971511840820312,
    },
    {
      x: 21028.532608695652,
      y: 0.24961471557617188,
    },
    {
      x: 21032.608695652176,
      y: 0.2745361328125,
    },
    {
      x: 21036.684782608696,
      y: 0.2994499206542969,
    },
    {
      x: 21040.76086956522,
      y: 0.3293495178222656,
    },
    {
      x: 21044.83695652174,
      y: 0.35427093505859375,
    },
    {
      x: 21048.91304347826,
      y: 0.3692207336425781,
    },
    {
      x: 21052.989130434784,
      y: 0.3592529296875,
    },
    {
      x: 21057.065217391304,
      y: 0.3343391418457031,
    },
    {
      x: 21061.141304347828,
      y: 0.309417724609375,
    },
    {
      x: 21065.217391304348,
      y: 0.2845001220703125,
    },
    {
      x: 21069.29347826087,
      y: 0.25460052490234375,
    },
    {
      x: 21073.369565217392,
      y: 0.22469711303710938,
    },
    {
      x: 21077.445652173912,
      y: 0.1997833251953125,
    },
    {
      x: 21081.521739130436,
      y: 0.17486572265625,
    },
    {
      x: 21085.597826086956,
      y: 0.14994430541992188,
    },
    {
      x: 21089.67391304348,
      y: 0.12004470825195312,
    },
    {
      x: 21093.75,
      y: 0.110076904296875,
    },
    {
      x: 21097.826086956524,
      y: 0.10509490966796875,
    },
    {
      x: 21101.902173913044,
      y: 0.10509490966796875,
    },
    {
      x: 21126.358695652176,
      y: 0.10509490966796875,
    },
    {
      x: 21130.434782608696,
      y: 0.10010910034179688,
    },
    {
      x: 21134.51086956522,
      y: 0.10010910034179688,
    },
    {
      x: 21387.228260869564,
      y: 0.10010910034179688,
    },
    {
      x: 21391.304347826088,
      y: 0.0353240966796875,
    },
    {
      x: 21395.380434782608,
      y: 0.03034210205078125,
    },
    {
      x: 21399.456521739132,
      y: 0.03034210205078125,
    },
    {
      x: 21403.532608695652,
      y: 0.03034210205078125,
    },
    {
      x: 21407.608695652176,
      y: 0.0353240966796875,
    },
    {
      x: 21411.684782608696,
      y: 0.040309906005859375,
    },
    {
      x: 21415.76086956522,
      y: 0.045291900634765625,
    },
    {
      x: 21419.83695652174,
      y: 0.050273895263671875,
    },
    {
      x: 21423.91304347826,
      y: 0.050273895263671875,
    },
    {
      x: 21427.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 21432.065217391304,
      y: 0.06024169921875,
    },
    {
      x: 21436.141304347828,
      y: 0.06522369384765625,
    },
    {
      x: 21440.217391304348,
      y: 0.07020950317382812,
    },
    {
      x: 21444.29347826087,
      y: 0.0751953125,
    },
    {
      x: 21448.369565217392,
      y: 0.0751953125,
    },
    {
      x: 21452.445652173912,
      y: 0.08017730712890625,
    },
    {
      x: 21456.521739130436,
      y: 0.08017730712890625,
    },
    {
      x: 21460.597826086956,
      y: 0.0851593017578125,
    },
    {
      x: 21464.67391304348,
      y: 0.09512710571289062,
    },
    {
      x: 21468.75,
      y: 0.09512710571289062,
    },
    {
      x: 21472.826086956524,
      y: 0.10010910034179688,
    },
    {
      x: 21476.902173913044,
      y: 0.10010910034179688,
    },
    {
      x: 21648.097826086956,
      y: 0.10010910034179688,
    },
    {
      x: 21652.17391304348,
      y: 0.10509490966796875,
    },
    {
      x: 21656.25,
      y: 0.10509490966796875,
    },
    {
      x: 21835.597826086956,
      y: 0.10509490966796875,
    },
    {
      x: 21839.67391304348,
      y: 0.09512710571289062,
    },
    {
      x: 21843.75,
      y: 0.08017730712890625,
    },
    {
      x: 21847.826086956524,
      y: 0.0751953125,
    },
    {
      x: 21851.902173913044,
      y: 0.07020950317382812,
    },
    {
      x: 21855.978260869564,
      y: 0.06522369384765625,
    },
    {
      x: 21860.054347826088,
      y: 0.06522369384765625,
    },
    {
      x: 21864.130434782608,
      y: 0.06024169921875,
    },
    {
      x: 21868.206521739132,
      y: 0.06024169921875,
    },
    {
      x: 21872.282608695652,
      y: 0.06024169921875,
    },
    {
      x: 21876.358695652176,
      y: 0.05525970458984375,
    },
    {
      x: 21880.434782608696,
      y: 0.05525970458984375,
    },
    {
      x: 21884.51086956522,
      y: 0.050273895263671875,
    },
    {
      x: 21888.58695652174,
      y: 0.045291900634765625,
    },
    {
      x: 21892.66304347826,
      y: 0.045291900634765625,
    },
    {
      x: 21896.739130434784,
      y: 0.040309906005859375,
    },
    {
      x: 21900.815217391304,
      y: 0.040309906005859375,
    },
    {
      x: 21904.891304347828,
      y: 0.040309906005859375,
    },
    {
      x: 21908.967391304348,
      y: 0.0353240966796875,
    },
    {
      x: 21913.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 21917.119565217392,
      y: 0.10509490966796875,
    },
    {
      x: 22120.92391304348,
      y: 0.10509490966796875,
    },
    {
      x: 22125,
      y: 0.09512710571289062,
    },
    {
      x: 22129.076086956524,
      y: 0.08017730712890625,
    },
    {
      x: 22133.152173913044,
      y: 0.0751953125,
    },
    {
      x: 22137.228260869564,
      y: 0.07020950317382812,
    },
    {
      x: 22141.304347826088,
      y: 0.06522369384765625,
    },
    {
      x: 22145.380434782608,
      y: 0.06522369384765625,
    },
    {
      x: 22149.456521739132,
      y: 0.06024169921875,
    },
    {
      x: 22153.532608695652,
      y: 0.06024169921875,
    },
    {
      x: 22157.608695652176,
      y: 0.06024169921875,
    },
    {
      x: 22161.684782608696,
      y: 0.05525970458984375,
    },
    {
      x: 22165.76086956522,
      y: 0.05525970458984375,
    },
    {
      x: 22169.83695652174,
      y: 0.050273895263671875,
    },
    {
      x: 22173.91304347826,
      y: 0.10010910034179688,
    },
    {
      x: 22177.989130434784,
      y: 0.10010910034179688,
    },
    {
      x: 22430.706521739132,
      y: 0.10010910034179688,
    },
    {
      x: 22434.782608695652,
      y: 0.045291900634765625,
    },
    {
      x: 22438.858695652176,
      y: 0.045291900634765625,
    },
    {
      x: 22447.01086956522,
      y: 0.045291900634765625,
    },
    {
      x: 22451.08695652174,
      y: 0.040309906005859375,
    },
    {
      x: 22455.16304347826,
      y: 0.0353240966796875,
    },
    {
      x: 22459.239130434784,
      y: 0.0353240966796875,
    },
    {
      x: 22463.315217391304,
      y: 0.03034210205078125,
    },
    {
      x: 22467.391304347828,
      y: 0.03034210205078125,
    },
    {
      x: 22471.467391304348,
      y: 0.03034210205078125,
    },
    {
      x: 22475.54347826087,
      y: 0.0353240966796875,
    },
    {
      x: 22479.619565217392,
      y: 0.040309906005859375,
    },
    {
      x: 22483.695652173912,
      y: 0.045291900634765625,
    },
    {
      x: 22487.771739130436,
      y: 0.050273895263671875,
    },
    {
      x: 22491.847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 22495.92391304348,
      y: 0.05525970458984375,
    },
    {
      x: 22500,
      y: 0.06024169921875,
    },
    {
      x: 22504.076086956524,
      y: 0.06522369384765625,
    },
    {
      x: 22508.152173913044,
      y: 0.07020950317382812,
    },
    {
      x: 22512.228260869564,
      y: 0.0751953125,
    },
    {
      x: 22516.304347826088,
      y: 0.0751953125,
    },
    {
      x: 22520.380434782608,
      y: 0.08017730712890625,
    },
    {
      x: 22524.456521739132,
      y: 0.08017730712890625,
    },
    {
      x: 22528.532608695652,
      y: 0.0851593017578125,
    },
    {
      x: 22532.608695652176,
      y: 0.09014511108398438,
    },
    {
      x: 22536.684782608696,
      y: 0.09512710571289062,
    },
    {
      x: 22540.76086956522,
      y: 0.10010910034179688,
    },
    {
      x: 22544.83695652174,
      y: 0.10010910034179688,
    },
    {
      x: 22854.619565217392,
      y: 0.10010910034179688,
    },
    {
      x: 22858.695652173912,
      y: 0.10509490966796875,
    },
    {
      x: 22862.771739130436,
      y: 0.1349945068359375,
    },
    {
      x: 22866.847826086956,
      y: 0.16489410400390625,
    },
    {
      x: 22870.92391304348,
      y: 0.19479751586914062,
    },
    {
      x: 22875,
      y: 0.21971511840820312,
    },
    {
      x: 22879.076086956524,
      y: 0.24961471557617188,
    },
    {
      x: 22883.152173913044,
      y: 0.2745361328125,
    },
    {
      x: 22887.228260869564,
      y: 0.2994499206542969,
    },
    {
      x: 22891.304347826088,
      y: 0.3293495178222656,
    },
    {
      x: 22895.380434782608,
      y: 0.35427093505859375,
    },
    {
      x: 22899.456521739132,
      y: 0.3692207336425781,
    },
    {
      x: 22903.532608695652,
      y: 0.3592529296875,
    },
    {
      x: 22907.608695652176,
      y: 0.3343391418457031,
    },
    {
      x: 22911.684782608696,
      y: 0.309417724609375,
    },
    {
      x: 22915.76086956522,
      y: 0.2845001220703125,
    },
    {
      x: 22919.83695652174,
      y: 0.25460052490234375,
    },
    {
      x: 22923.91304347826,
      y: 0.22469711303710938,
    },
    {
      x: 22927.989130434784,
      y: 0.1997833251953125,
    },
    {
      x: 22932.065217391304,
      y: 0.17486572265625,
    },
    {
      x: 22936.141304347828,
      y: 0.14994430541992188,
    },
    {
      x: 22940.217391304348,
      y: 0.12004470825195312,
    },
    {
      x: 22944.29347826087,
      y: 0.110076904296875,
    },
    {
      x: 22948.369565217392,
      y: 0.10509490966796875,
    },
    {
      x: 22952.445652173912,
      y: 0.10509490966796875,
    },
    {
      x: 22956.521739130436,
      y: 0.06024169921875,
    },
    {
      x: 22960.597826086956,
      y: 0.06024169921875,
    },
    {
      x: 22964.67391304348,
      y: 0.06024169921875,
    },
    {
      x: 22968.75,
      y: 0.05525970458984375,
    },
    {
      x: 22972.826086956524,
      y: 0.05525970458984375,
    },
    {
      x: 22976.902173913044,
      y: 0.050273895263671875,
    },
    {
      x: 22980.978260869564,
      y: 0.045291900634765625,
    },
    {
      x: 22985.054347826088,
      y: 0.045291900634765625,
    },
    {
      x: 22989.130434782608,
      y: 0.040309906005859375,
    },
    {
      x: 22993.206521739132,
      y: 0.040309906005859375,
    },
    {
      x: 22997.282608695652,
      y: 0.040309906005859375,
    },
    {
      x: 23001.358695652176,
      y: 0.0353240966796875,
    },
    {
      x: 23005.434782608696,
      y: 0.0353240966796875,
    },
    {
      x: 23009.51086956522,
      y: 0.03034210205078125,
    },
    {
      x: 23013.58695652174,
      y: 0.03034210205078125,
    },
    {
      x: 23017.66304347826,
      y: 0.03034210205078125,
    },
    {
      x: 23021.739130434784,
      y: 0.0353240966796875,
    },
    {
      x: 23025.815217391304,
      y: 0.040309906005859375,
    },
    {
      x: 23029.891304347828,
      y: 0.045291900634765625,
    },
    {
      x: 23033.967391304348,
      y: 0.050273895263671875,
    },
    {
      x: 23038.04347826087,
      y: 0.050273895263671875,
    },
    {
      x: 23042.119565217392,
      y: 0.05525970458984375,
    },
    {
      x: 23046.195652173912,
      y: 0.06024169921875,
    },
    {
      x: 23050.271739130436,
      y: 0.06522369384765625,
    },
    {
      x: 23054.347826086956,
      y: 0.07020950317382812,
    },
    {
      x: 23058.42391304348,
      y: 0.0751953125,
    },
    {
      x: 23062.5,
      y: 0.0751953125,
    },
    {
      x: 23066.576086956524,
      y: 0.08017730712890625,
    },
    {
      x: 23070.652173913044,
      y: 0.08017730712890625,
    },
    {
      x: 23074.728260869564,
      y: 0.0851593017578125,
    },
    {
      x: 23078.804347826088,
      y: 0.09512710571289062,
    },
    {
      x: 23082.880434782608,
      y: 0.09512710571289062,
    },
    {
      x: 23086.956521739132,
      y: 0.10010910034179688,
    },
    {
      x: 23091.032608695652,
      y: 0.10010910034179688,
    },
    {
      x: 23661.684782608696,
      y: 0.10010910034179688,
    },
    {
      x: 23665.76086956522,
      y: 0.10509490966796875,
    },
    {
      x: 23669.83695652174,
      y: 0.1349945068359375,
    },
    {
      x: 23673.91304347826,
      y: 0.16489410400390625,
    },
    {
      x: 23677.989130434784,
      y: 0.19479751586914062,
    },
    {
      x: 23682.065217391304,
      y: 0.21971511840820312,
    },
    {
      x: 23686.141304347828,
      y: 0.24961471557617188,
    },
    {
      x: 23690.217391304348,
      y: 0.2745361328125,
    },
    {
      x: 23694.29347826087,
      y: 0.2994499206542969,
    },
    {
      x: 23698.369565217392,
      y: 0.3293495178222656,
    },
    {
      x: 23702.445652173912,
      y: 0.35427093505859375,
    },
    {
      x: 23706.521739130436,
      y: 0.3692207336425781,
    },
    {
      x: 23710.597826086956,
      y: 0.3592529296875,
    },
    {
      x: 23714.67391304348,
      y: 0.3343391418457031,
    },
    {
      x: 23718.75,
      y: 0.309417724609375,
    },
    {
      x: 23722.826086956524,
      y: 0.2845001220703125,
    },
    {
      x: 23726.902173913044,
      y: 0.25460052490234375,
    },
    {
      x: 23730.978260869564,
      y: 0.22469711303710938,
    },
    {
      x: 23735.054347826088,
      y: 0.1997833251953125,
    },
    {
      x: 23739.130434782608,
      y: 0.17486572265625,
    },
    {
      x: 23743.206521739132,
      y: 0.14994430541992188,
    },
    {
      x: 23747.282608695652,
      y: 0.12004470825195312,
    },
    {
      x: 23751.358695652176,
      y: 0.110076904296875,
    },
    {
      x: 23755.434782608696,
      y: 0.10509490966796875,
    },
    {
      x: 23759.51086956522,
      y: 0.10509490966796875,
    },
    {
      x: 23971.467391304348,
      y: 0.10509490966796875,
    },
    {
      x: 23975.54347826087,
      y: 0.09512710571289062,
    },
    {
      x: 23979.619565217392,
      y: 0.08017730712890625,
    },
    {
      x: 23983.695652173912,
      y: 0.0751953125,
    },
    {
      x: 23987.771739130436,
      y: 0.07020950317382812,
    },
    {
      x: 23991.847826086956,
      y: 0.06522369384765625,
    },
    {
      x: 23995.92391304348,
      y: 0.06522369384765625,
    },
    {
      x: 24000,
      y: 0.06522369384765625,
    },
  ],
  ii: [
    {
      x: 0,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20.604395604395602,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 24.725274725274723,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 28.846153846153843,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 32.967032967032964,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 37.08791208791209,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 41.208791208791204,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 45.32967032967032,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 49.450549450549445,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 53.57142857142857,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 57.692307692307686,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 61.8131868131868,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 65.93406593406593,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 70.05494505494505,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 74.17582417582418,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 78.29670329670328,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 82.41758241758241,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 86.53846153846153,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 90.65934065934064,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 94.78021978021977,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 98.90109890109889,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 103.02197802197801,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 107.14285714285714,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 111.26373626373625,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 115.38461538461537,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 119.5054945054945,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 123.6263736263736,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 127.74725274725273,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 131.86813186813185,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 135.98901098901098,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 259.6153846153846,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 263.7362637362637,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 267.85714285714283,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 502.7472527472527,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 506.8681318681318,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 510.9890109890109,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 515.1098901098901,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 523.3516483516484,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 527.4725274725274,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 531.5934065934065,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 535.7142857142857,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 548.076923076923,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 552.1978021978022,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 556.3186813186812,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 585.1648351648352,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 589.2857142857142,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 593.4065934065934,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 618.131868131868,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 622.2527472527472,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 626.3736263736263,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 646.978021978022,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 651.098901098901,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 655.2197802197802,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 679.9450549450548,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 684.065934065934,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 688.1868131868131,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 787.0879120879121,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 791.2087912087911,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 795.3296703296702,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 885.9890109890109,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 890.10989010989,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 894.2307692307692,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 898.3516483516482,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 902.4725274725274,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 906.5934065934065,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 910.7142857142857,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 914.8351648351647,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 918.9560439560439,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 923.076923076923,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 927.197802197802,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 931.3186813186812,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 935.4395604395603,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 939.5604395604395,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 943.6813186813185,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 947.8021978021977,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 951.9230769230768,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 956.043956043956,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 960.164835164835,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 964.2857142857142,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 968.4065934065933,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 972.5274725274725,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 976.6483516483515,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 980.7692307692307,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 984.8901098901098,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 989.0109890109889,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 993.131868131868,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 997.2527472527471,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 1001.3736263736263,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 1005.4945054945053,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 1013.7362637362636,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 1017.8571428571428,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1021.9780219780218,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 1026.098901098901,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 1030.2197802197802,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 1034.3406593406592,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 1038.4615384615383,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1042.5824175824175,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1046.7032967032967,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 1050.8241758241757,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1054.9450549450548,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1059.065934065934,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1075.5494505494505,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1079.6703296703295,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1083.7912087912086,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1087.9120879120878,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 1092.032967032967,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 1096.153846153846,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 1100.2747252747251,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 1104.3956043956043,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 1108.5164835164835,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 1112.6373626373625,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 1116.7582417582416,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 1120.8791208791208,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 1124.9999999999998,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 1129.120879120879,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 1133.2417582417581,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 1137.3626373626373,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 1141.4835164835163,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 1145.6043956043954,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 1149.7252747252746,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 1153.8461538461538,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 1157.9670329670328,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 1162.087912087912,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 1166.2087912087911,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 1170.3296703296703,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 1174.4505494505493,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 1178.5714285714284,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 1182.6923076923076,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 1186.8131868131868,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1190.9340659340658,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1314.5604395604394,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1318.6813186813185,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1322.8021978021977,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1557.6923076923076,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1561.8131868131866,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 1565.9340659340658,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1570.054945054945,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1578.296703296703,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1582.4175824175823,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 1586.5384615384614,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1590.6593406593404,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1603.021978021978,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1607.1428571428569,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 1611.263736263736,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 1640.1098901098899,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 1644.230769230769,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1648.3516483516482,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1673.0769230769229,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1677.197802197802,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1681.3186813186812,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1701.9230769230767,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1706.0439560439559,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1710.164835164835,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1734.8901098901097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1739.0109890109889,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1743.131868131868,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1842.0329670329668,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1846.153846153846,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1850.2747252747251,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1940.9340659340658,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1945.054945054945,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 1949.175824175824,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1953.296703296703,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 1957.4175824175823,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1961.5384615384614,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1965.6593406593404,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 1969.7802197802196,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 1973.9010989010987,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1978.0219780219777,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 1982.1428571428569,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 1986.263736263736,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 1990.3846153846152,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 1994.5054945054942,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 1998.6263736263734,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 2002.7472527472526,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 2006.8681318681317,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 2010.9890109890107,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 2015.1098901098899,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 2019.230769230769,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 2023.3516483516482,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 2027.4725274725272,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 2031.5934065934064,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 2035.7142857142856,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 2039.8351648351647,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 2043.9560439560437,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 2048.076923076923,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 2052.197802197802,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 2056.3186813186812,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 2060.4395604395604,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 2068.6813186813183,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 2072.8021978021975,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2076.9230769230767,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 2081.043956043956,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 2085.164835164835,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 2089.285714285714,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 2093.4065934065934,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2097.527472527472,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2101.6483516483513,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 2105.7692307692305,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2109.8901098901097,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2114.010989010989,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2130.494505494505,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2134.6153846153843,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2138.7362637362635,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2142.8571428571427,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 2146.978021978022,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 2151.098901098901,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 2155.21978021978,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 2159.340659340659,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 2163.461538461538,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 2167.5824175824173,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 2171.7032967032965,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 2175.8241758241757,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 2179.945054945055,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 2184.065934065934,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 2188.1868131868127,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 2192.307692307692,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 2196.428571428571,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 2200.5494505494503,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 2204.6703296703295,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 2208.7912087912086,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 2212.912087912088,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 2217.032967032967,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 2221.1538461538457,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 2225.274725274725,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 2229.395604395604,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 2233.5164835164833,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 2237.6373626373625,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 2241.7582417582416,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2245.879120879121,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2369.5054945054944,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2373.6263736263736,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2377.7472527472523,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2612.6373626373625,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2616.7582417582416,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 2620.879120879121,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2624.9999999999995,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2633.241758241758,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2637.362637362637,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 2641.4835164835163,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2645.6043956043954,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2657.9670329670325,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2662.0879120879117,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 2666.208791208791,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 2695.0549450549447,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 2699.175824175824,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2703.296703296703,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2728.0219780219777,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2732.142857142857,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2736.263736263736,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2756.8681318681315,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2760.9890109890107,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2765.10989010989,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2789.8351648351645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2793.9560439560437,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2798.076923076923,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2896.978021978022,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2901.0989010989006,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2905.2197802197798,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2995.8791208791204,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2999.9999999999995,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 3004.1208791208787,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3008.241758241758,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 3012.362637362637,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3016.4835164835163,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3020.6043956043954,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 3024.7252747252746,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 3028.8461538461534,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3032.9670329670325,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 3037.0879120879117,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 3041.208791208791,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 3045.32967032967,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 3049.4505494505493,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 3053.5714285714284,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 3057.692307692307,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 3061.8131868131863,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 3065.9340659340655,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 3070.0549450549447,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 3074.175824175824,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 3078.296703296703,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 3082.4175824175823,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 3086.5384615384614,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 3090.65934065934,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 3094.7802197802193,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 3098.9010989010985,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 3103.0219780219777,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 3107.142857142857,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 3111.263736263736,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 3115.3846153846152,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 3123.626373626373,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 3127.7472527472523,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3131.8681318681315,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 3135.9890109890107,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 3140.10989010989,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 3144.230769230769,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 3148.3516483516482,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3152.472527472527,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3156.593406593406,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 3160.7142857142853,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3164.8351648351645,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3168.9560439560437,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3185.43956043956,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3189.560439560439,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3193.6813186813183,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3197.8021978021975,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 3201.9230769230767,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 3206.043956043956,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 3210.164835164835,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 3214.2857142857138,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 3218.406593406593,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 3222.527472527472,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 3226.6483516483513,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 3230.7692307692305,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 3234.8901098901097,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 3239.010989010989,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 3243.1318681318676,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 3247.2527472527468,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 3251.373626373626,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 3255.494505494505,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 3259.6153846153843,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 3263.7362637362635,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 3267.8571428571427,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 3271.978021978022,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 3276.0989010989006,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 3280.2197802197798,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 3284.340659340659,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 3288.461538461538,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 3292.5824175824173,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 3296.7032967032965,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3300.8241758241757,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3424.4505494505493,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3428.5714285714284,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3432.692307692307,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3667.5824175824173,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3671.7032967032965,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 3675.8241758241757,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3679.9450549450544,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3688.1868131868127,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3692.307692307692,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 3696.428571428571,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3700.5494505494503,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3712.9120879120874,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3717.0329670329666,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 3721.1538461538457,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 3749.9999999999995,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 3754.1208791208787,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3758.241758241758,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3782.9670329670325,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3787.0879120879117,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3791.208791208791,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3811.8131868131863,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3815.9340659340655,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3820.0549450549447,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3844.7802197802193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3848.9010989010985,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3853.0219780219777,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3951.9230769230767,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3956.0439560439554,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3960.1648351648346,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4050.824175824175,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4054.9450549450544,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 4059.0659340659336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4063.1868131868127,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 4067.307692307692,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4071.428571428571,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4075.5494505494503,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 4079.6703296703295,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 4083.791208791208,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4087.9120879120874,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 4092.0329670329666,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 4096.153846153846,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 4100.274725274725,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 4104.395604395604,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 4108.516483516483,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 4112.6373626373625,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 4116.758241758242,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 4120.879120879121,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 4125,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 4129.120879120878,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 4133.241758241757,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 4137.362637362637,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 4141.483516483516,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 4145.604395604395,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 4149.725274725274,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 4153.846153846153,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 4157.9670329670325,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 4162.087912087912,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 4166.208791208791,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 4170.32967032967,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 4178.571428571428,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 4182.692307692308,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4186.813186813187,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 4190.934065934065,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 4195.054945054944,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 4199.175824175823,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 4203.296703296703,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4207.417582417582,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4211.538461538461,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 4215.65934065934,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4219.780219780219,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4223.9010989010985,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4240.384615384615,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4244.505494505494,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4248.626373626374,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4252.747252747252,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 4256.868131868131,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 4260.98901098901,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 4265.109890109889,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 4269.230769230769,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 4273.351648351648,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 4277.472527472527,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 4281.593406593406,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 4285.714285714285,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 4289.8351648351645,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 4293.956043956044,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 4298.076923076923,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 4302.197802197802,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 4306.318681318681,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 4310.43956043956,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 4314.560439560439,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 4318.681318681318,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 4322.802197802197,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 4326.923076923076,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 4331.043956043955,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 4335.164835164835,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 4339.285714285714,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 4343.406593406593,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 4347.527472527472,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 4351.648351648351,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4355.7692307692305,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4479.395604395604,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4483.516483516483,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4487.6373626373625,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4722.527472527472,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4726.648351648351,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 4730.7692307692305,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4734.89010989011,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4743.131868131868,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4747.252747252747,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 4751.3736263736255,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4755.494505494505,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4767.857142857142,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4771.978021978021,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 4776.098901098901,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 4804.945054945055,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 4809.065934065934,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4813.186813186812,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4837.912087912087,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4842.032967032967,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4846.153846153846,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4866.758241758242,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4870.879120879121,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4874.999999999999,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4899.725274725274,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4903.846153846153,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4907.9670329670325,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5006.868131868131,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5010.98901098901,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5015.109890109889,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5105.7692307692305,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5109.89010989011,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 5114.010989010989,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5118.131868131868,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 5122.252747252746,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5126.3736263736255,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 5130.494505494505,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 5134.615384615384,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 5138.736263736263,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5142.857142857142,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 5146.978021978021,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 5151.098901098901,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 5155.21978021978,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 5159.340659340659,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 5163.461538461538,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 5167.582417582417,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 5171.7032967032965,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 5175.824175824176,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 5179.945054945055,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 5184.065934065933,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 5188.186813186812,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 5192.3076923076915,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 5196.428571428571,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 5200.54945054945,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 5204.670329670329,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 5208.791208791208,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 5212.912087912087,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 5217.032967032967,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 5221.153846153846,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 5225.274725274725,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 5233.516483516483,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 5237.6373626373625,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5241.758241758242,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 5245.87912087912,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 5249.999999999999,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 5254.120879120878,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 5258.241758241757,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5262.362637362637,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5266.483516483516,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 5270.604395604395,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5274.725274725274,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5278.846153846153,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5295.32967032967,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5299.450549450549,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5303.571428571428,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5307.692307692307,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 5311.813186813186,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 5315.934065934065,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 5320.054945054944,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 5324.175824175823,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 5328.296703296703,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 5332.417582417582,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 5336.538461538461,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 5340.65934065934,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 5344.780219780219,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 5348.9010989010985,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 5353.021978021978,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 5357.142857142857,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 5361.263736263736,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 5365.384615384615,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 5369.5054945054935,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 5373.626373626373,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 5377.747252747252,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 5381.868131868131,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 5385.98901098901,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 5390.109890109889,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 5394.230769230769,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 5398.351648351648,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 5402.472527472527,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 5406.593406593406,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5410.714285714285,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5534.340659340659,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5538.461538461538,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5542.582417582417,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5777.472527472527,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5781.593406593406,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 5785.714285714285,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5789.8351648351645,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5798.076923076923,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5802.197802197801,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 5806.31868131868,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5810.4395604395595,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5822.802197802197,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5826.923076923076,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 5831.043956043955,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 5859.89010989011,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 5864.010989010989,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5868.131868131867,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5892.857142857142,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5896.978021978021,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5901.098901098901,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5921.7032967032965,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5925.824175824176,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5929.945054945054,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5954.670329670329,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5958.791208791208,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5962.912087912087,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6061.813186813186,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6065.934065934065,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6070.054945054944,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6160.714285714285,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6164.8351648351645,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 6168.956043956044,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6173.076923076923,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 6177.197802197801,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 6181.31868131868,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6185.4395604395595,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 6189.560439560439,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 6193.681318681318,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6197.802197802197,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 6201.923076923076,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 6206.043956043955,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 6210.164835164835,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 6214.285714285714,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 6218.406593406593,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 6222.527472527472,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 6226.648351648351,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 6230.7692307692305,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 6234.89010989011,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 6239.010989010988,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 6243.131868131867,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 6247.252747252746,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 6251.3736263736255,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 6255.494505494505,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 6259.615384615384,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 6263.736263736263,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 6267.857142857142,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 6271.978021978021,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 6276.098901098901,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 6280.21978021978,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 6288.461538461538,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 6292.582417582417,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6296.7032967032965,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 6300.824175824175,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 6304.945054945054,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 6309.065934065933,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 6313.186813186812,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 6317.3076923076915,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 6321.428571428571,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 6325.54945054945,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 6329.670329670329,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6333.791208791208,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6350.274725274725,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6354.395604395604,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6358.516483516483,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6362.637362637362,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 6366.758241758241,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 6370.87912087912,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 6374.999999999999,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 6379.120879120878,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 6383.241758241757,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 6387.362637362637,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 6391.483516483516,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 6395.604395604395,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 6399.725274725274,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 6403.846153846153,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 6407.9670329670325,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 6412.087912087912,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 6416.208791208791,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 6420.32967032967,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 6424.450549450548,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 6428.5714285714275,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 6432.692307692307,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 6436.813186813186,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 6440.934065934065,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 6445.054945054944,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 6449.175824175823,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 6453.296703296703,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 6457.417582417582,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 6461.538461538461,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6465.65934065934,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6589.285714285714,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6593.406593406593,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6597.527472527472,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6832.417582417582,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6836.538461538461,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 6840.65934065934,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6844.780219780219,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6853.021978021978,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6857.142857142857,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 6861.263736263735,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 6865.384615384614,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 6877.747252747252,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 6881.868131868131,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 6885.98901098901,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 6914.8351648351645,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 6918.956043956044,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6923.076923076922,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6947.802197802197,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6951.923076923076,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6956.043956043955,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6976.648351648351,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6980.7692307692305,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6984.890109890109,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7009.615384615384,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7013.736263736263,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7017.857142857142,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7116.758241758241,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7120.87912087912,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7124.999999999999,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7215.65934065934,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7219.780219780219,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 7223.9010989010985,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7228.021978021978,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 7232.142857142856,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7236.263736263735,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7240.384615384614,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 7244.5054945054935,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 7248.626373626373,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7252.747252747252,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 7256.868131868131,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 7260.98901098901,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 7265.109890109889,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 7269.230769230769,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 7273.351648351648,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 7277.472527472527,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 7281.593406593406,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 7285.714285714285,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 7289.8351648351645,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 7293.956043956043,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 7298.076923076922,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 7302.197802197801,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 7306.31868131868,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 7310.4395604395595,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 7314.560439560439,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 7318.681318681318,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 7322.802197802197,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 7326.923076923076,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 7331.043956043955,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 7335.164835164835,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 7343.406593406593,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 7347.527472527472,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7351.648351648351,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 7355.76923076923,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 7359.890109890109,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 7364.010989010988,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 7368.131868131867,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 7372.252747252746,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7376.3736263736255,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 7380.494505494505,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7384.615384615384,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7388.736263736263,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7405.21978021978,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7409.340659340659,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7413.461538461538,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7417.582417582416,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 7421.703296703296,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 7425.824175824175,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 7429.945054945054,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 7434.065934065933,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 7438.186813186812,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 7442.3076923076915,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 7446.428571428571,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 7450.54945054945,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 7454.670329670329,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 7458.791208791208,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 7462.912087912087,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 7467.032967032967,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 7471.153846153846,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 7475.274725274725,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 7479.395604395603,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 7483.516483516482,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 7487.637362637362,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 7491.758241758241,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 7495.87912087912,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 7499.999999999999,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 7504.120879120878,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 7508.241758241757,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 7512.362637362637,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 7516.483516483516,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7520.604395604395,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7644.230769230769,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7648.351648351648,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7652.472527472527,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7887.362637362637,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7891.483516483516,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 7895.604395604395,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7899.725274725274,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7907.9670329670325,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 7912.087912087911,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 7916.20879120879,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7920.329670329669,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7932.692307692307,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7936.813186813186,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 7940.934065934065,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 7969.780219780219,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 7973.9010989010985,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7978.021978021977,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8002.747252747252,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8006.868131868131,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8010.98901098901,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8031.593406593406,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8035.714285714285,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8039.835164835164,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8064.560439560439,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8068.681318681318,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8072.802197802197,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8171.703296703296,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8175.824175824175,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8179.945054945054,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8270.604395604394,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8274.725274725273,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 8278.846153846152,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8282.967032967032,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 8287.08791208791,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8291.20879120879,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8295.32967032967,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 8299.450549450548,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 8303.571428571428,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8307.692307692307,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 8311.813186813186,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 8315.934065934065,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 8320.054945054944,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 8324.175824175823,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 8328.296703296703,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 8332.417582417582,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 8336.538461538461,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 8340.65934065934,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 8344.78021978022,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 8348.901098901099,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 8353.021978021978,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 8357.142857142857,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 8361.263736263736,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 8365.384615384615,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 8369.505494505494,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 8373.626373626374,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 8377.747252747251,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 8381.86813186813,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 8385.98901098901,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 8390.109890109889,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 8398.351648351647,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 8402.472527472526,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8406.593406593405,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 8410.714285714284,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 8414.835164835164,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 8418.956043956043,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 8423.076923076922,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8427.197802197801,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8431.31868131868,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 8435.43956043956,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8439.560439560439,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8443.681318681318,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8460.164835164835,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8464.285714285714,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8468.406593406593,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8472.527472527472,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 8476.648351648351,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 8480.76923076923,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 8484.89010989011,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 8489.010989010989,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 8493.131868131868,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 8497.252747252747,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 8501.373626373626,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 8505.494505494504,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 8509.615384615383,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 8513.736263736262,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 8517.857142857141,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 8521.97802197802,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 8526.0989010989,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 8530.219780219779,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 8534.340659340658,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 8538.461538461537,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 8542.582417582416,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 8546.703296703296,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 8550.824175824175,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 8554.945054945054,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 8559.065934065933,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 8563.186813186812,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 8567.307692307691,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 8571.42857142857,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8575.54945054945,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8699.175824175823,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8703.296703296703,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8707.417582417582,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8942.307692307691,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8946.42857142857,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 8950.54945054945,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8954.670329670329,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8962.912087912087,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8967.032967032967,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 8971.153846153846,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8975.274725274725,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8987.637362637362,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8991.758241758242,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 8995.87912087912,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 9024.725274725273,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 9028.846153846152,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9032.967032967032,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9057.692307692307,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9061.813186813186,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9065.934065934065,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9086.538461538461,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9090.65934065934,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9094.78021978022,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9119.505494505494,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9123.626373626372,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9127.747252747251,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9226.648351648351,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9230.76923076923,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9234.89010989011,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9325.54945054945,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9329.670329670329,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 9333.791208791208,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9337.912087912087,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 9342.032967032967,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9346.153846153846,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9350.274725274725,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 9354.395604395604,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 9358.516483516483,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9362.637362637362,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 9366.758241758242,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 9370.879120879119,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 9374.999999999998,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 9379.120879120877,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 9383.241758241757,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 9387.362637362636,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 9391.483516483515,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 9395.604395604394,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 9399.725274725273,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 9403.846153846152,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 9407.967032967032,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 9412.08791208791,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 9416.20879120879,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 9420.32967032967,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 9424.450549450548,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 9428.571428571428,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 9432.692307692307,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 9436.813186813186,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 9440.934065934065,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 9445.054945054944,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 9453.296703296703,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 9457.417582417582,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9461.538461538461,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 9465.65934065934,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 9469.78021978022,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 9473.901098901099,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 9478.021978021978,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9482.142857142857,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9486.263736263736,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 9490.384615384615,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9494.505494505494,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9498.626373626372,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9515.109890109889,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9519.230769230768,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9523.351648351647,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9527.472527472526,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 9531.593406593405,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 9535.714285714284,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 9539.835164835164,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 9543.956043956043,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 9548.076923076922,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 9552.197802197801,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 9556.31868131868,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 9560.43956043956,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 9564.560439560439,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 9568.681318681318,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 9572.802197802197,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 9576.923076923076,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 9581.043956043955,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 9585.164835164835,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 9589.285714285714,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 9593.406593406593,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 9597.527472527472,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 9601.648351648351,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 9605.76923076923,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 9609.89010989011,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 9614.010989010989,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 9618.131868131868,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 9622.252747252745,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 9626.373626373625,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9630.494505494504,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9754.120879120877,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9758.241758241757,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9762.362637362636,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9997.252747252745,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10001.373626373625,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 10005.494505494504,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10009.615384615383,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10017.857142857141,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10021.97802197802,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 10026.0989010989,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10030.219780219779,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10042.582417582416,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10046.703296703296,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 10050.824175824175,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 10079.670329670329,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 10083.791208791208,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10087.912087912087,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10112.637362637362,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10116.75824175824,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10120.879120879119,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10141.483516483515,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10145.604395604394,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10149.725274725273,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10174.450549450548,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10178.571428571428,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10182.692307692307,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10281.593406593405,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10285.714285714284,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10289.835164835164,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10380.494505494504,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10384.615384615383,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 10388.736263736262,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10392.857142857141,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 10396.97802197802,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10401.0989010989,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10405.219780219779,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 10409.340659340658,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 10413.461538461537,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10417.582417582416,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 10421.703296703296,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 10425.824175824175,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 10429.945054945054,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 10434.065934065933,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 10438.186813186812,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 10442.307692307691,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 10446.42857142857,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 10450.54945054945,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 10454.670329670329,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 10458.791208791208,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 10462.912087912087,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 10467.032967032967,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 10471.153846153846,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 10475.274725274725,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 10479.395604395604,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 10483.516483516483,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 10487.63736263736,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 10491.75824175824,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 10495.879120879119,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 10499.999999999998,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 10508.241758241757,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 10512.362637362636,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10516.483516483515,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 10520.604395604394,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 10524.725274725273,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 10528.846153846152,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 10532.967032967032,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10537.08791208791,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10541.20879120879,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 10545.32967032967,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10549.450549450548,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10553.571428571428,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10570.054945054944,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10574.175824175823,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10578.296703296703,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10582.417582417582,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 10586.538461538461,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 10590.65934065934,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 10594.78021978022,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 10598.901098901099,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 10603.021978021978,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 10607.142857142857,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 10611.263736263736,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 10615.384615384613,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 10619.505494505493,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 10623.626373626372,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 10627.747252747251,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 10631.86813186813,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 10635.98901098901,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 10640.109890109889,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 10644.230769230768,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 10648.351648351647,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 10652.472527472526,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 10656.593406593405,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 10660.714285714284,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 10664.835164835164,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 10668.956043956043,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 10673.076923076922,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 10677.197802197801,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 10681.31868131868,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10685.43956043956,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10809.065934065933,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10813.186813186812,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10817.307692307691,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11052.197802197801,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11056.31868131868,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 11060.43956043956,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11064.560439560439,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11072.802197802197,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11076.923076923076,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 11081.043956043955,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11085.164835164835,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11097.527472527472,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11101.648351648351,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 11105.76923076923,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 11134.615384615383,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 11138.736263736262,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11142.857142857141,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11167.582417582416,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11171.703296703296,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11175.824175824175,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11196.42857142857,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11200.54945054945,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11204.670329670329,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11229.395604395604,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11233.516483516481,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11237.63736263736,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11336.538461538461,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11340.65934065934,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11344.78021978022,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11435.43956043956,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11439.560439560439,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 11443.681318681318,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11447.802197802197,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 11451.923076923076,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11456.043956043955,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11460.164835164835,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 11464.285714285714,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 11468.406593406593,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11472.527472527472,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 11476.648351648351,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 11480.769230769229,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 11484.890109890108,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 11489.010989010987,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 11493.131868131866,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 11497.252747252745,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 11501.373626373625,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 11505.494505494504,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 11509.615384615383,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 11513.736263736262,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 11517.857142857141,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 11521.97802197802,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 11526.0989010989,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 11530.219780219779,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 11534.340659340658,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 11538.461538461537,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 11542.582417582416,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 11546.703296703296,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 11550.824175824175,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 11554.945054945054,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 11563.186813186812,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 11567.307692307691,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11571.42857142857,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 11575.54945054945,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 11579.670329670329,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 11583.791208791208,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 11587.912087912087,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11592.032967032967,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11596.153846153846,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 11600.274725274725,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 11604.395604395602,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11608.516483516481,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11624.999999999998,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11629.120879120877,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11633.241758241757,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11637.362637362636,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 11641.483516483515,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 11645.604395604394,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 11649.725274725273,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 11653.846153846152,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 11657.967032967032,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 11662.08791208791,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 11666.20879120879,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 11670.32967032967,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 11674.450549450548,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 11678.571428571428,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 11682.692307692307,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 11686.813186813186,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 11690.934065934065,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 11695.054945054944,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 11699.175824175823,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 11703.296703296703,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 11707.417582417582,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 11711.538461538461,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 11715.65934065934,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 11719.78021978022,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 11723.901098901099,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 11728.021978021978,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 11732.142857142855,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 11736.263736263734,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11740.384615384613,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11864.010989010987,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11868.131868131866,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11872.252747252745,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12107.142857142855,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12111.263736263734,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 12115.384615384613,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12119.505494505493,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12127.747252747251,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12131.86813186813,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 12135.98901098901,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12140.109890109889,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12152.472527472526,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12156.593406593405,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 12160.714285714284,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 12189.560439560439,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 12193.681318681318,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12197.802197802197,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12222.527472527472,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12226.64835164835,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12230.769230769229,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12251.373626373625,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12255.494505494504,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12259.615384615383,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12284.340659340658,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12288.461538461537,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12292.582417582416,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12391.483516483515,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12395.604395604394,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12399.725274725273,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12490.384615384613,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12494.505494505493,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 12498.626373626372,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12502.747252747251,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 12506.86813186813,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12510.98901098901,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12515.109890109889,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 12519.230769230768,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 12523.351648351647,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12527.472527472526,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 12531.593406593405,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 12535.714285714284,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 12539.835164835164,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 12543.956043956043,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 12548.076923076922,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 12552.197802197801,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 12556.31868131868,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 12560.43956043956,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 12564.560439560439,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 12568.681318681318,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 12572.802197802197,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 12576.923076923076,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 12581.043956043955,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 12585.164835164835,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 12589.285714285714,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 12593.406593406593,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 12597.52747252747,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 12601.64835164835,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 12605.769230769229,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 12609.890109890108,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 12618.131868131866,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 12622.252747252745,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12626.373626373625,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 12630.494505494504,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 12634.615384615383,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 12638.736263736262,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 12642.857142857141,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12646.97802197802,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12651.0989010989,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 12655.219780219779,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12659.340659340658,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12663.461538461537,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12679.945054945054,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12684.065934065933,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12688.186813186812,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12692.307692307691,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 12696.42857142857,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 12700.54945054945,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 12704.670329670329,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 12708.791208791208,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 12712.912087912087,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 12717.032967032967,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 12721.153846153846,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 12725.274725274723,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 12729.395604395602,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 12733.516483516481,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 12737.63736263736,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 12741.75824175824,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 12745.879120879119,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 12749.999999999998,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 12754.120879120877,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 12758.241758241757,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 12762.362637362636,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 12766.483516483515,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 12770.604395604394,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 12774.725274725273,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 12778.846153846152,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 12782.967032967032,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 12787.08791208791,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 12791.20879120879,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12795.32967032967,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12918.956043956043,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12923.076923076922,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12927.197802197801,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13162.08791208791,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13166.20879120879,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 13170.32967032967,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13174.450549450548,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13182.692307692307,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13186.813186813186,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 13190.934065934065,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13195.054945054944,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13207.417582417582,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13211.538461538461,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 13215.65934065934,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 13244.505494505493,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 13248.626373626372,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13252.747252747251,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13277.472527472526,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13281.593406593405,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13285.714285714284,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13306.31868131868,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13310.43956043956,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13314.560439560439,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13339.285714285714,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13343.406593406591,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13347.52747252747,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13446.42857142857,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13450.54945054945,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13454.670329670329,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13545.32967032967,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13549.450549450548,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 13553.571428571428,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13557.692307692307,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 13561.813186813186,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13565.934065934065,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13570.054945054944,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 13574.175824175823,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 13578.296703296703,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13582.417582417582,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 13586.538461538461,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 13590.659340659338,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 13594.780219780218,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 13598.901098901097,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 13603.021978021976,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 13607.142857142855,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 13611.263736263734,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 13615.384615384613,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 13619.505494505493,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 13623.626373626372,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 13627.747252747251,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 13631.86813186813,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 13635.98901098901,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 13640.109890109889,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 13644.230769230768,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 13648.351648351647,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 13652.472527472526,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 13656.593406593405,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 13660.714285714284,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 13664.835164835164,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 13673.076923076922,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 13677.197802197801,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13681.31868131868,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 13685.43956043956,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 13689.560439560439,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 13693.681318681318,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 13697.802197802197,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13701.923076923076,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13706.043956043955,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 13710.164835164835,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13714.285714285714,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13718.406593406591,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13734.890109890108,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13739.010989010987,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13743.131868131866,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13747.252747252745,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 13751.373626373625,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 13755.494505494504,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 13759.615384615383,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 13763.736263736262,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 13767.857142857141,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 13771.97802197802,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 13776.0989010989,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 13780.219780219779,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 13784.340659340658,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 13788.461538461537,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 13792.582417582416,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 13796.703296703296,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 13800.824175824175,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 13804.945054945054,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 13809.065934065933,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 13813.186813186812,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 13817.307692307691,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 13821.42857142857,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 13825.54945054945,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 13829.670329670329,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 13833.791208791208,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 13837.912087912087,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 13842.032967032965,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 13846.153846153844,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13850.274725274723,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13973.901098901097,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13978.021978021976,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13982.142857142855,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14217.032967032965,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14221.153846153844,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 14225.274725274723,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14229.395604395602,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14237.63736263736,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14241.75824175824,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 14245.879120879119,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 14249.999999999998,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 14262.362637362636,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 14266.483516483515,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 14270.604395604394,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 14299.450549450548,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 14303.571428571428,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14307.692307692307,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14332.417582417582,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14336.53846153846,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14340.659340659338,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14361.263736263734,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14365.384615384613,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14369.505494505493,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14394.230769230768,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14398.351648351647,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14402.472527472526,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14501.373626373625,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14505.494505494504,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14509.615384615383,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14600.274725274723,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14604.395604395602,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 14608.516483516481,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14612.63736263736,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 14616.75824175824,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14620.879120879119,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14624.999999999998,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 14629.120879120877,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 14633.241758241757,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14637.362637362636,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 14641.483516483515,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 14645.604395604394,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 14649.725274725273,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 14653.846153846152,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 14657.967032967032,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 14662.08791208791,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 14666.20879120879,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 14670.32967032967,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 14674.450549450548,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 14678.571428571428,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 14682.692307692307,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 14686.813186813186,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 14690.934065934065,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 14695.054945054944,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 14699.175824175823,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 14703.296703296703,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 14707.41758241758,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 14711.53846153846,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 14715.659340659338,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 14719.780219780218,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 14728.021978021976,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 14732.142857142855,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14736.263736263734,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 14740.384615384613,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 14744.505494505493,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 14748.626373626372,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 14752.747252747251,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14756.86813186813,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14760.98901098901,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 14765.109890109889,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 14769.230769230768,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14773.351648351647,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14789.835164835164,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14793.956043956043,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14798.076923076922,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14802.197802197801,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 14806.31868131868,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 14810.43956043956,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 14814.560439560439,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 14818.681318681318,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 14822.802197802197,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 14826.923076923076,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 14831.043956043955,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 14835.164835164833,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 14839.285714285712,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 14843.406593406591,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 14847.52747252747,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 14851.64835164835,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 14855.769230769229,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 14859.890109890108,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 14864.010989010987,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 14868.131868131866,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 14872.252747252745,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 14876.373626373625,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 14880.494505494504,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 14884.615384615383,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 14888.736263736262,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 14892.857142857141,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 14896.97802197802,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 14901.0989010989,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14905.219780219779,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15028.846153846152,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15032.967032967032,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15037.08791208791,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15271.97802197802,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15276.0989010989,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 15280.219780219779,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15284.340659340658,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15292.582417582416,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15296.703296703296,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 15300.824175824175,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15304.945054945054,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15317.307692307691,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15321.42857142857,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 15325.54945054945,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 15354.395604395602,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 15358.516483516481,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15362.63736263736,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15387.362637362636,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15391.483516483515,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15395.604395604394,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15416.20879120879,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15420.32967032967,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15424.450549450548,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15449.175824175823,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15453.2967032967,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15457.41758241758,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15556.31868131868,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15560.43956043956,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15564.560439560439,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15655.219780219779,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15659.340659340658,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 15663.461538461537,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15667.582417582416,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 15671.703296703296,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 15675.824175824175,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15679.945054945054,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 15684.065934065933,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 15688.186813186812,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15692.307692307691,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 15696.42857142857,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 15700.549450549448,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 15704.670329670327,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 15708.791208791206,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 15712.912087912086,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 15717.032967032965,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 15721.153846153844,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 15725.274725274723,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 15729.395604395602,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 15733.516483516481,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 15737.63736263736,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 15741.75824175824,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 15745.879120879119,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 15749.999999999998,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 15754.120879120877,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 15758.241758241757,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 15762.362637362636,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 15766.483516483515,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 15770.604395604394,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 15774.725274725273,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 15782.967032967032,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 15787.08791208791,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15791.20879120879,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 15795.32967032967,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 15799.450549450548,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 15803.571428571428,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 15807.692307692307,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 15811.813186813186,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 15815.934065934065,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 15820.054945054944,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15824.175824175822,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15828.2967032967,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15844.780219780218,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15848.901098901097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15853.021978021976,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15857.142857142855,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 15861.263736263734,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 15865.384615384613,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 15869.505494505493,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 15873.626373626372,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 15877.747252747251,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 15881.86813186813,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 15885.98901098901,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 15890.109890109889,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 15894.230769230768,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 15898.351648351647,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 15902.472527472526,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 15906.593406593405,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 15910.714285714284,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 15914.835164835164,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 15918.956043956043,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 15923.076923076922,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 15927.197802197801,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 15931.31868131868,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 15935.43956043956,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 15939.560439560439,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 15943.681318681318,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 15947.802197802197,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 15951.923076923074,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 15956.043956043954,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15960.164835164833,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16083.791208791206,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16087.912087912086,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16092.032967032965,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16326.923076923074,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16331.043956043954,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 16335.164835164833,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16339.285714285712,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16347.52747252747,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16351.64835164835,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 16355.769230769229,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16359.890109890108,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16372.252747252745,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16376.373626373625,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 16380.494505494504,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 16409.340659340658,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 16413.461538461535,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16417.582417582416,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16442.30769230769,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16446.42857142857,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16450.54945054945,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16471.153846153844,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16475.274725274725,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16479.395604395602,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16504.120879120877,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16508.241758241755,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16512.362637362636,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16611.263736263736,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16615.384615384613,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16619.505494505494,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16710.164835164833,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16714.285714285714,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 16718.40659340659,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16722.527472527472,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 16726.64835164835,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16730.76923076923,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16734.890109890108,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 16739.01098901099,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 16743.131868131866,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16747.252747252747,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 16751.373626373625,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 16755.494505494502,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 16759.615384615383,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 16763.73626373626,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 16767.85714285714,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 16771.97802197802,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 16776.0989010989,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 16780.219780219777,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 16784.340659340658,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 16788.461538461535,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 16792.582417582416,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 16796.703296703294,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 16800.824175824175,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 16804.945054945052,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 16809.065934065933,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 16813.18681318681,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 16817.30769230769,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 16821.42857142857,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 16825.54945054945,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 16829.670329670327,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 16837.912087912086,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 16842.032967032967,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16846.153846153844,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 16850.274725274725,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 16854.395604395602,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 16858.516483516483,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 16862.63736263736,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16866.75824175824,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16870.87912087912,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 16875,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16879.120879120877,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16883.241758241755,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16899.72527472527,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16903.846153846152,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16907.96703296703,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16912.08791208791,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 16916.208791208788,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 16920.32967032967,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 16924.450549450547,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 16928.571428571428,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 16932.692307692305,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 16936.813186813186,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 16940.934065934063,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 16945.054945054944,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 16949.17582417582,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 16953.296703296703,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 16957.41758241758,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 16961.53846153846,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 16965.65934065934,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 16969.78021978022,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 16973.901098901097,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 16978.021978021978,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 16982.142857142855,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 16986.263736263736,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 16990.384615384613,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 16994.505494505494,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 16998.62637362637,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 17002.747252747253,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 17006.86813186813,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 17010.989010989008,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17015.10989010989,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17138.73626373626,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17142.85714285714,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17146.97802197802,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17381.86813186813,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17385.989010989008,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 17390.10989010989,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17394.230769230766,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17402.472527472524,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17406.593406593405,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 17410.714285714283,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17414.835164835164,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17427.1978021978,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17431.31868131868,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 17435.439560439558,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 17464.285714285714,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 17468.40659340659,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17472.527472527472,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17497.252747252747,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17501.373626373625,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17505.494505494502,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17526.0989010989,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17530.219780219777,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17534.340659340658,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17559.065934065933,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17563.18681318681,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17567.30769230769,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17666.208791208788,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17670.32967032967,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17674.450549450547,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17765.10989010989,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17769.230769230766,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 17773.351648351647,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17777.472527472524,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 17781.593406593405,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17785.714285714283,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17789.835164835164,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 17793.95604395604,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 17798.076923076922,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17802.1978021978,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 17806.31868131868,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 17810.439560439558,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 17814.56043956044,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 17818.681318681316,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 17822.802197802197,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 17826.923076923074,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 17831.043956043955,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 17835.164835164833,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 17839.285714285714,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 17843.40659340659,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 17847.527472527472,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 17851.64835164835,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 17855.76923076923,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 17859.890109890108,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 17864.01098901099,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 17868.131868131866,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 17872.252747252744,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 17876.373626373625,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 17880.494505494502,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 17884.615384615383,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 17892.85714285714,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 17896.97802197802,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17901.0989010989,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 17905.219780219777,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 17909.340659340658,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 17913.461538461535,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 17917.582417582416,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17921.703296703294,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17925.824175824175,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 17929.945054945052,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17934.065934065933,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17938.18681318681,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17954.670329670327,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17958.79120879121,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17962.912087912086,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17967.032967032967,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 17971.153846153844,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 17975.274725274725,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 17979.395604395602,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 17983.516483516483,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 17987.63736263736,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 17991.75824175824,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 17995.87912087912,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 17999.999999999996,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 18004.120879120877,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 18008.241758241755,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 18012.362637362636,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 18016.483516483513,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 18020.604395604394,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 18024.72527472527,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 18028.846153846152,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 18032.96703296703,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 18037.08791208791,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 18041.208791208788,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 18045.32967032967,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 18049.450549450547,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 18053.571428571428,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 18057.692307692305,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 18061.813186813186,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 18065.934065934063,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18070.054945054944,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18193.681318681316,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18197.802197802197,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18201.923076923074,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18436.813186813186,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18440.934065934063,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 18445.054945054944,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18449.17582417582,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18457.41758241758,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18461.53846153846,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 18465.65934065934,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18469.78021978022,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18482.142857142855,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18486.263736263736,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 18490.384615384613,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 18519.230769230766,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 18523.351648351647,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18527.472527472524,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18552.1978021978,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18556.31868131868,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18560.439560439558,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18581.043956043955,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18585.164835164833,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18589.285714285714,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18614.01098901099,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18618.131868131866,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18622.252747252744,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18721.153846153844,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18725.274725274725,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18729.395604395602,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18820.054945054944,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18824.17582417582,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 18828.296703296703,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18832.41758241758,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 18836.53846153846,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18840.65934065934,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18844.78021978022,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 18848.901098901097,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 18853.021978021978,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18857.142857142855,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 18861.263736263736,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 18865.384615384613,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 18869.50549450549,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 18873.62637362637,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 18877.74725274725,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 18881.86813186813,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 18885.989010989008,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 18890.10989010989,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 18894.230769230766,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 18898.351648351647,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 18902.472527472524,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 18906.593406593405,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 18910.714285714283,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 18914.835164835164,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 18918.95604395604,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 18923.076923076922,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 18927.1978021978,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 18931.31868131868,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 18935.439560439558,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 18939.56043956044,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 18947.802197802197,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 18951.923076923074,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18956.043956043955,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 18960.164835164833,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 18964.285714285714,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 18968.40659340659,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 18972.527472527472,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18976.64835164835,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18980.76923076923,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 18984.890109890108,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18989.01098901099,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18993.131868131866,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19009.615384615383,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19013.73626373626,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19017.85714285714,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19021.97802197802,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 19026.0989010989,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 19030.219780219777,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 19034.340659340658,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 19038.461538461535,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 19042.582417582416,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 19046.703296703294,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 19050.824175824175,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 19054.945054945052,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 19059.065934065933,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 19063.18681318681,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 19067.30769230769,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 19071.42857142857,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 19075.54945054945,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 19079.670329670327,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 19083.79120879121,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 19087.912087912086,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 19092.032967032967,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 19096.153846153844,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 19100.274725274725,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 19104.395604395602,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 19108.516483516483,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 19112.63736263736,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 19116.758241758238,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 19120.87912087912,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19124.999999999996,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19248.62637362637,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19252.74725274725,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19256.86813186813,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19491.758241758238,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19495.87912087912,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 19499.999999999996,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19504.120879120877,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19512.362637362636,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19516.483516483513,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 19520.604395604394,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19524.72527472527,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19537.08791208791,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19541.208791208788,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 19545.32967032967,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 19574.17582417582,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 19578.296703296703,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19582.41758241758,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19607.142857142855,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19611.263736263732,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19615.384615384613,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19635.989010989008,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19640.10989010989,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19644.230769230766,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19668.95604395604,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19673.076923076922,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19677.1978021978,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19776.0989010989,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19780.219780219777,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19784.340659340658,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19874.999999999996,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19879.120879120877,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 19883.241758241755,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19887.362637362636,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 19891.483516483513,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19895.604395604394,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19899.72527472527,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 19903.846153846152,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 19907.96703296703,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19912.08791208791,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 19916.208791208788,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 19920.32967032967,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 19924.450549450547,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 19928.571428571428,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 19932.692307692305,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 19936.813186813186,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 19940.934065934063,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 19945.054945054944,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 19949.17582417582,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 19953.296703296703,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 19957.41758241758,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 19961.53846153846,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 19965.65934065934,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 19969.78021978022,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 19973.901098901097,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 19978.021978021978,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 19982.142857142855,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 19986.263736263732,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 19990.384615384613,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 19994.50549450549,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 20002.74725274725,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 20006.86813186813,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20010.989010989008,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 20015.10989010989,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 20019.230769230766,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 20023.351648351647,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 20027.472527472524,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20031.593406593405,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20035.714285714283,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 20039.835164835164,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20043.95604395604,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20048.076923076922,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20064.56043956044,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20068.681318681316,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20072.802197802197,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20076.923076923074,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 20081.043956043955,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 20085.164835164833,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 20089.285714285714,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 20093.40659340659,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 20097.527472527472,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 20101.64835164835,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 20105.76923076923,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 20109.890109890108,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 20114.010989010985,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 20118.131868131866,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 20122.252747252744,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 20126.373626373625,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 20130.494505494502,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 20134.615384615383,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 20138.73626373626,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 20142.85714285714,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 20146.97802197802,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 20151.0989010989,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 20155.219780219777,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 20159.340659340658,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 20163.461538461535,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 20167.582417582416,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 20171.703296703294,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 20175.824175824175,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20179.945054945052,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20303.571428571428,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20307.692307692305,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20311.813186813186,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20546.703296703294,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20550.824175824175,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 20554.945054945052,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20559.065934065933,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20567.30769230769,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20571.42857142857,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 20575.54945054945,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20579.670329670327,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20592.032967032967,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20596.153846153844,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 20600.274725274725,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 20629.120879120877,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 20633.241758241755,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20637.362637362636,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20662.08791208791,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20666.208791208788,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20670.32967032967,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20690.934065934063,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20695.054945054944,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20699.17582417582,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20723.901098901097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20728.021978021974,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20732.142857142855,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20831.043956043955,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20835.164835164833,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20839.285714285714,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20929.945054945052,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20934.065934065933,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 20938.18681318681,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20942.30769230769,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 20946.42857142857,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20950.54945054945,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20954.670329670327,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 20958.79120879121,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 20962.912087912086,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20967.032967032967,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 20971.153846153844,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 20975.27472527472,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 20979.395604395602,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 20983.51648351648,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 20987.63736263736,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 20991.758241758238,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 20995.87912087912,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 20999.999999999996,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 21004.120879120877,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 21008.241758241755,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 21012.362637362636,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 21016.483516483513,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 21020.604395604394,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 21024.72527472527,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 21028.846153846152,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 21032.96703296703,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 21037.08791208791,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 21041.208791208788,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 21045.32967032967,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 21049.450549450547,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 21057.692307692305,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 21061.813186813186,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21065.934065934063,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 21070.054945054944,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 21074.17582417582,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 21078.296703296703,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 21082.41758241758,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21086.53846153846,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21090.65934065934,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 21094.78021978022,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21098.901098901097,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21103.021978021974,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21119.50549450549,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21123.62637362637,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21127.74725274725,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21131.86813186813,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 21135.989010989008,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 21140.10989010989,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 21144.230769230766,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 21148.351648351647,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 21152.472527472524,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 21156.593406593405,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 21160.714285714283,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 21164.835164835164,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 21168.95604395604,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 21173.076923076922,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 21177.1978021978,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 21181.31868131868,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 21185.439560439558,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 21189.56043956044,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 21193.681318681316,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 21197.802197802197,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 21201.923076923074,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 21206.043956043955,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 21210.164835164833,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 21214.285714285714,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 21218.40659340659,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 21222.527472527472,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 21226.64835164835,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 21230.769230769227,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21234.890109890108,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21358.51648351648,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21362.63736263736,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21366.758241758238,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21601.64835164835,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21605.769230769227,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 21609.890109890108,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21614.010989010985,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21622.252747252744,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21626.373626373625,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 21630.494505494502,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21634.615384615383,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21646.97802197802,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21651.0989010989,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 21655.219780219777,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 21684.065934065933,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 21688.18681318681,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21692.30769230769,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21717.032967032967,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21721.153846153844,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21725.27472527472,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21745.87912087912,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21749.999999999996,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21754.120879120877,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21778.846153846152,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21782.96703296703,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21787.08791208791,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21885.989010989008,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21890.10989010989,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21894.230769230766,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21984.890109890108,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21989.010989010985,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 21993.131868131866,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21997.252747252744,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 22001.373626373625,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22005.494505494502,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22009.615384615383,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 22013.73626373626,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 22017.85714285714,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22021.97802197802,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 22026.0989010989,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 22030.219780219777,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 22034.340659340658,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 22038.461538461535,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 22042.582417582416,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 22046.703296703294,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 22050.824175824175,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 22054.945054945052,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 22059.065934065933,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 22063.18681318681,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 22067.30769230769,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 22071.42857142857,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 22075.54945054945,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 22079.670329670327,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 22083.79120879121,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 22087.912087912086,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 22092.032967032963,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 22096.153846153844,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 22100.27472527472,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 22104.395604395602,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 22112.63736263736,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 22116.758241758238,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22120.87912087912,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 22124.999999999996,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 22129.120879120877,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 22133.241758241755,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 22137.362637362636,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22141.483516483513,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22145.604395604394,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 22149.72527472527,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22153.846153846152,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22157.96703296703,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22174.450549450547,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22178.571428571428,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22182.692307692305,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22186.813186813186,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 22190.934065934063,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 22195.054945054944,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 22199.17582417582,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 22203.296703296703,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 22207.41758241758,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 22211.53846153846,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 22215.65934065934,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 22219.780219780216,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 22223.901098901097,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 22228.021978021974,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 22232.142857142855,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 22236.263736263732,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 22240.384615384613,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 22244.50549450549,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 22248.62637362637,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 22252.74725274725,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 22256.86813186813,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 22260.989010989008,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 22265.10989010989,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 22269.230769230766,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 22273.351648351647,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 22277.472527472524,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 22281.593406593405,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 22285.714285714283,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22289.835164835164,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22413.461538461535,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22417.582417582416,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22421.703296703294,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22656.593406593405,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22660.714285714283,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 22664.835164835164,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22668.95604395604,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22677.1978021978,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22681.31868131868,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 22685.439560439558,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22689.56043956044,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22701.923076923074,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22706.043956043955,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 22710.164835164833,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 22739.010989010985,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 22743.131868131866,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22747.252747252744,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22771.97802197802,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22776.0989010989,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22780.219780219777,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22800.824175824175,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22804.945054945052,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22809.065934065933,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22833.79120879121,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22837.912087912086,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22842.032967032963,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22940.934065934063,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22945.054945054944,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22949.17582417582,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23039.835164835164,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23043.95604395604,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 23048.076923076922,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23052.1978021978,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 23056.31868131868,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23060.439560439558,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23064.56043956044,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 23068.681318681316,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 23072.802197802197,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23076.923076923074,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 23081.043956043955,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 23085.164835164833,
      y: -0.054378509521484375,
      __typename: 'Points',
    },
    {
      x: 23089.28571428571,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 23093.40659340659,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 23097.52747252747,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 23101.64835164835,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 23105.769230769227,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 23109.890109890108,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 23114.010989010985,
      y: -0.1141815185546875,
      __typename: 'Points',
    },
    {
      x: 23118.131868131866,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 23122.252747252744,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 23126.373626373625,
      y: -0.13909912109375,
      __typename: 'Points',
    },
    {
      x: 23130.494505494502,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 23134.615384615383,
      y: -0.14408111572265625,
      __typename: 'Points',
    },
    {
      x: 23138.73626373626,
      y: -0.13411712646484375,
      __typename: 'Points',
    },
    {
      x: 23142.85714285714,
      y: -0.1241455078125,
      __typename: 'Points',
    },
    {
      x: 23146.97802197802,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 23151.0989010989,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 23155.219780219777,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 23159.340659340658,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 23167.582417582416,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 23171.703296703294,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23175.824175824175,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 23179.945054945052,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 23184.065934065933,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 23188.18681318681,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 23192.30769230769,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 23196.42857142857,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23200.54945054945,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 23204.670329670327,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23208.791208791205,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23212.912087912086,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23229.395604395602,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23233.51648351648,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23237.63736263736,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23241.758241758238,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 23245.87912087912,
      y: 0.1997833251953125,
      __typename: 'Points',
    },
    {
      x: 23249.999999999996,
      y: 0.2745361328125,
      __typename: 'Points',
    },
    {
      x: 23254.120879120877,
      y: 0.3592529296875,
      __typename: 'Points',
    },
    {
      x: 23258.241758241755,
      y: 0.45394134521484375,
      __typename: 'Points',
    },
    {
      x: 23262.362637362636,
      y: 0.5486259460449219,
      __typename: 'Points',
    },
    {
      x: 23266.483516483513,
      y: 0.643310546875,
      __typename: 'Points',
    },
    {
      x: 23270.604395604394,
      y: 0.7330207824707031,
      __typename: 'Points',
    },
    {
      x: 23274.72527472527,
      y: 0.8177375793457031,
      __typename: 'Points',
    },
    {
      x: 23278.846153846152,
      y: 0.8875045776367188,
      __typename: 'Points',
    },
    {
      x: 23282.96703296703,
      y: 0.9223899841308594,
      __typename: 'Points',
    },
    {
      x: 23287.08791208791,
      y: 0.9174079895019531,
      __typename: 'Points',
    },
    {
      x: 23291.208791208788,
      y: 0.8675727844238281,
      __typename: 'Points',
    },
    {
      x: 23295.32967032967,
      y: 0.7928199768066406,
      __typename: 'Points',
    },
    {
      x: 23299.450549450547,
      y: 0.708099365234375,
      __typename: 'Points',
    },
    {
      x: 23303.571428571428,
      y: 0.6183967590332031,
      __typename: 'Points',
    },
    {
      x: 23307.692307692305,
      y: 0.523712158203125,
      __typename: 'Points',
    },
    {
      x: 23311.813186813186,
      y: 0.4340057373046875,
      __typename: 'Points',
    },
    {
      x: 23315.934065934063,
      y: 0.3443031311035156,
      __typename: 'Points',
    },
    {
      x: 23320.054945054944,
      y: 0.2645683288574219,
      __typename: 'Points',
    },
    {
      x: 23324.17582417582,
      y: 0.20476531982421875,
      __typename: 'Points',
    },
    {
      x: 23328.296703296703,
      y: 0.159912109375,
      __typename: 'Points',
    },
    {
      x: 23332.41758241758,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 23336.538461538457,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 23340.65934065934,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23344.780219780216,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23468.40659340659,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23472.52747252747,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23476.64835164835,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23711.538461538457,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23715.65934065934,
      y: 2.0685997009277344,
      __typename: 'Points',
    },
    {
      x: 23719.780219780216,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23723.901098901097,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23732.142857142855,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23736.263736263732,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 23740.384615384613,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23744.50549450549,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23756.86813186813,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23760.989010989008,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 23765.10989010989,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 23793.95604395604,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 23798.076923076922,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23802.1978021978,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23826.923076923074,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23831.04395604395,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23835.164835164833,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23855.769230769227,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23859.890109890108,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23864.010989010985,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23888.73626373626,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23892.85714285714,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23896.97802197802,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 23999.999999999996,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
  ],
  iii: [
    {
      x: 0,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 254.03225806451613,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 258.06451612903226,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 262.0967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 306.4516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 310.48387096774195,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 314.51612903225805,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 370.96774193548384,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 375,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 379.0322580645161,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 512.0967741935484,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 516.1290322580645,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 520.1612903225806,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 524.1935483870968,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 528.2258064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 532.258064516129,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 536.2903225806451,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 544.3548387096774,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 548.3870967741935,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 552.4193548387096,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 556.4516129032259,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 560.483870967742,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 564.516129032258,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 568.5483870967741,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 572.5806451612904,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 576.6129032258065,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 580.6451612903226,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 584.6774193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 588.7096774193549,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 592.741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 596.7741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 629.0322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 633.0645161290322,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 637.0967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 649.1935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 653.2258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 657.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 697.5806451612904,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 701.6129032258065,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 705.6451612903226,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 713.7096774193549,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 717.741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 721.7741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 762.0967741935484,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 766.1290322580645,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 770.1612903225806,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 774.1935483870968,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 778.2258064516129,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 782.258064516129,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 786.2903225806451,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 790.3225806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 794.3548387096774,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 798.3870967741935,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 802.4193548387096,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 806.4516129032259,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 810.483870967742,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 814.516129032258,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 818.5483870967741,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 822.5806451612904,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 826.6129032258065,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 830.6451612903226,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 834.6774193548387,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 842.741935483871,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 846.7741935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 850.8064516129032,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 854.8387096774194,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 858.8709677419355,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 862.9032258064516,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 866.9354838709677,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 870.9677419354839,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 875,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 879.0322580645161,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 887.0967741935484,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 891.1290322580645,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 895.1612903225806,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 899.1935483870967,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 903.2258064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 915.3225806451613,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 919.3548387096774,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 923.3870967741935,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 927.4193548387096,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 931.4516129032259,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 935.483870967742,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 939.516129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 943.5483870967741,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 951.6129032258065,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 955.6451612903226,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 959.6774193548387,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 963.7096774193549,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 967.741935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 971.7741935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 975.8064516129032,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 979.8387096774194,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 983.8709677419355,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 987.9032258064516,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 991.9354838709677,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 995.9677419354839,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 1000,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 1004.0322580645161,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 1008.0645161290322,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 1012.0967741935484,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 1016.1290322580645,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1020.1612903225806,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1024.1935483870968,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1028.225806451613,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1032.258064516129,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 1036.2903225806451,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 1286.2903225806451,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 1290.3225806451612,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1294.3548387096773,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1338.7096774193549,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1342.741935483871,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1346.774193548387,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1403.225806451613,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1407.258064516129,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 1411.2903225806451,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 1544.3548387096773,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 1548.3870967741937,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1552.4193548387098,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 1556.4516129032259,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1560.483870967742,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1564.516129032258,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1568.5483870967741,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1576.6129032258063,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1580.6451612903227,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1584.6774193548388,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1588.7096774193549,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1592.741935483871,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1596.774193548387,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1600.8064516129032,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1604.8387096774193,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1608.8709677419354,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1612.9032258064517,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1616.9354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1620.967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1625,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1629.032258064516,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1661.2903225806451,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1665.3225806451612,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1669.3548387096773,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1681.4516129032259,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1685.483870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1689.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1729.8387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1733.8709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1737.9032258064517,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1745.967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1750,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1754.032258064516,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1794.3548387096773,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1798.3870967741934,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1802.4193548387098,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1806.4516129032259,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 1810.483870967742,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 1814.516129032258,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 1818.5483870967741,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1822.5806451612902,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1826.6129032258063,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1830.6451612903227,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1834.6774193548388,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 1838.7096774193549,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1842.741935483871,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1846.774193548387,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1850.8064516129032,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1854.8387096774193,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1858.8709677419354,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1862.9032258064517,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1866.9354838709678,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 1875,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 1879.032258064516,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1883.0645161290322,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1887.0967741935483,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1891.1290322580644,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1895.1612903225807,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1899.1935483870968,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 1903.225806451613,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 1907.258064516129,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 1911.2903225806451,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 1919.3548387096773,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 1923.3870967741934,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 1927.4193548387098,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 1931.4516129032259,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1935.483870967742,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1947.5806451612902,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1951.6129032258063,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 1955.6451612903227,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1959.6774193548388,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1963.7096774193549,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1967.741935483871,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1971.774193548387,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1975.8064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1983.8709677419354,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1987.9032258064517,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 1991.9354838709678,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 1995.967741935484,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 2000,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 2004.032258064516,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 2008.0645161290322,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2012.0967741935483,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2016.1290322580644,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2020.1612903225807,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2024.1935483870968,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2028.225806451613,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 2032.258064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 2036.2903225806451,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 2040.3225806451612,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 2044.3548387096773,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 2048.3870967741937,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2052.4193548387098,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2056.451612903226,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2060.483870967742,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2064.516129032258,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 2068.548387096774,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 2318.548387096774,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 2322.5806451612902,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2326.6129032258063,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2370.967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2375,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2379.032258064516,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2435.483870967742,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2439.516129032258,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 2443.548387096774,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 2576.6129032258063,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 2580.6451612903224,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2584.6774193548385,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 2588.7096774193546,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2592.7419354838707,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2596.7741935483873,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2600.8064516129034,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2608.8709677419356,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2612.9032258064517,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2616.935483870968,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2620.967741935484,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2625,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2629.032258064516,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2633.064516129032,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2637.0967741935483,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2641.1290322580644,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2645.1612903225805,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2649.1935483870966,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2653.2258064516127,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2657.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2661.2903225806454,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2693.548387096774,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2697.5806451612902,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2701.6129032258063,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2713.7096774193546,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2717.7419354838707,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2721.7741935483873,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2762.0967741935483,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2766.1290322580644,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2770.1612903225805,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2778.2258064516127,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2782.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2786.2903225806454,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2826.6129032258063,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2830.6451612903224,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2834.6774193548385,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2838.7096774193546,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 2842.7419354838707,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 2846.7741935483873,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 2850.8064516129034,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2854.8387096774195,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2858.8709677419356,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2862.9032258064517,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2866.935483870968,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 2870.967741935484,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2875,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2879.032258064516,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2883.064516129032,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2887.0967741935483,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2891.1290322580644,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2895.1612903225805,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2899.1935483870966,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 2907.258064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 2911.2903225806454,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2915.3225806451615,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2919.3548387096776,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2923.3870967741937,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2927.4193548387098,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2931.451612903226,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 2935.483870967742,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 2939.516129032258,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 2943.548387096774,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 2951.6129032258063,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 2955.6451612903224,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 2959.6774193548385,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 2963.7096774193546,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2967.7419354838707,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2979.8387096774195,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2983.8709677419356,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 2987.9032258064517,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2991.935483870968,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2995.967741935484,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3000,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3004.032258064516,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3008.064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3016.1290322580644,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3020.1612903225805,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 3024.1935483870966,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 3028.2258064516127,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3032.258064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3036.2903225806454,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3040.3225806451615,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3044.3548387096776,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3048.3870967741937,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3052.4193548387098,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3056.451612903226,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3060.483870967742,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 3064.516129032258,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 3068.548387096774,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 3072.5806451612902,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 3076.6129032258063,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 3080.6451612903224,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3084.6774193548385,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3088.7096774193546,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3092.7419354838707,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3096.7741935483873,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 3100.8064516129034,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 3350.8064516129034,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 3354.8387096774195,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3358.8709677419356,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3403.2258064516127,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3407.258064516129,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3411.2903225806454,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3467.7419354838707,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3471.774193548387,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 3475.8064516129034,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 3608.8709677419356,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 3612.9032258064517,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3616.935483870968,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 3620.967741935484,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3625,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3629.032258064516,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3633.064516129032,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3641.1290322580644,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3645.1612903225805,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3649.1935483870966,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3653.2258064516127,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3657.258064516129,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3661.2903225806454,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3665.3225806451615,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3669.3548387096776,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3673.3870967741937,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3677.4193548387098,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3681.451612903226,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3685.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3689.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3693.548387096774,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3725.8064516129034,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3729.8387096774195,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3733.8709677419356,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3745.967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3750,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3754.032258064516,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3794.3548387096776,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3798.3870967741937,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3802.4193548387098,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3810.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3814.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3818.548387096774,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3858.8709677419356,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3862.9032258064517,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3866.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3870.967741935484,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 3875,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 3879.032258064516,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 3883.064516129032,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3887.0967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3891.1290322580644,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3895.1612903225805,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3899.1935483870966,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 3903.2258064516127,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3907.258064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3911.2903225806454,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3915.3225806451615,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3919.3548387096776,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3923.3870967741937,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3927.4193548387098,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3931.451612903226,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 3939.516129032258,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 3943.548387096774,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3947.5806451612902,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3951.6129032258063,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3955.6451612903224,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3959.6774193548385,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3963.7096774193546,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 3967.7419354838707,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 3971.774193548387,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 3975.8064516129034,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 3983.8709677419356,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 3987.9032258064517,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 3991.935483870968,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 3995.967741935484,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4000,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4012.0967741935483,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4016.1290322580644,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 4020.1612903225805,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4024.1935483870966,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4028.2258064516127,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4032.258064516129,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4036.2903225806454,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4040.3225806451615,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4048.3870967741937,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4052.4193548387098,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 4056.451612903226,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 4060.483870967742,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4064.516129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4068.548387096774,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4072.5806451612902,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4076.6129032258063,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4080.6451612903224,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4084.6774193548385,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4088.7096774193546,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4092.7419354838707,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 4096.774193548387,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 4100.806451612903,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 4104.8387096774195,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 4108.870967741936,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 4112.903225806452,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4116.935483870968,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4120.967741935484,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4125,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4129.032258064516,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 4133.064516129032,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 4383.064516129032,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 4387.096774193548,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4391.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4435.4838709677415,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4439.516129032258,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4443.548387096775,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4500,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4504.032258064516,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 4508.064516129032,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 4641.129032258064,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 4645.1612903225805,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4649.193548387097,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 4653.225806451613,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4657.258064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4661.290322580645,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4665.322580645161,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4673.387096774193,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4677.419354838709,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4681.451612903225,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4685.4838709677415,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4689.516129032258,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4693.548387096775,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4697.580645161291,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4701.612903225807,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4705.645161290323,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4709.677419354839,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4713.709677419355,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4717.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4721.774193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4725.806451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4758.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4762.096774193548,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4766.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4778.225806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4782.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4786.290322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4826.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4830.645161290323,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4834.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4842.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4846.774193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4850.806451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4891.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4895.1612903225805,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4899.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4903.225806451613,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 4907.258064516129,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 4911.290322580645,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 4915.322580645161,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4919.354838709677,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4923.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4927.419354838709,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4931.451612903225,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 4935.4838709677415,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4939.516129032258,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4943.548387096775,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4947.580645161291,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4951.612903225807,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4955.645161290323,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4959.677419354839,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4963.709677419355,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 4971.774193548387,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 4975.806451612903,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4979.8387096774195,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4983.870967741936,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4987.903225806452,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4991.935483870968,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4995.967741935484,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 5000,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 5004.032258064516,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 5008.064516129032,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 5016.129032258064,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 5020.1612903225805,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 5024.193548387097,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 5028.225806451613,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5032.258064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5044.354838709677,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5048.387096774193,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 5052.419354838709,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5056.451612903225,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5060.4838709677415,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5064.516129032258,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5068.548387096775,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5072.580645161291,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5080.645161290323,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5084.677419354839,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 5088.709677419355,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 5092.741935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5096.774193548387,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5100.806451612903,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5104.8387096774195,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5108.870967741936,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5112.903225806452,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5116.935483870968,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5120.967741935484,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5125,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 5129.032258064516,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 5133.064516129032,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 5137.096774193548,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 5141.129032258064,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 5145.1612903225805,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 5149.193548387097,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 5153.225806451613,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 5157.258064516129,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 5161.290322580645,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 5165.322580645161,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 5415.322580645161,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 5419.354838709677,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5423.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5467.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5471.774193548387,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5475.806451612903,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5532.258064516129,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5536.290322580645,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 5540.322580645161,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 5673.387096774193,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 5677.419354838709,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 5681.451612903225,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 5685.4838709677415,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5689.516129032258,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5693.548387096775,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5697.580645161291,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5705.645161290323,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5709.677419354839,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5713.709677419355,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5717.741935483871,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5721.774193548387,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5725.806451612903,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5729.8387096774195,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5733.870967741936,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5737.903225806452,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5741.935483870968,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5745.967741935484,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5750,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5754.032258064516,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5758.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5790.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5794.354838709677,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5798.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5810.4838709677415,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5814.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5818.548387096775,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5858.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5862.903225806452,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5866.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5875,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5879.032258064516,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5883.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5923.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5927.419354838709,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5931.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5935.4838709677415,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 5939.516129032258,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 5943.548387096775,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 5947.580645161291,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5951.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5955.645161290323,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5959.677419354839,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5963.709677419355,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 5967.741935483871,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5971.774193548387,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5975.806451612903,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5979.8387096774195,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5983.870967741936,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5987.903225806452,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5991.935483870968,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5995.967741935484,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 6004.032258064516,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 6008.064516129032,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6012.096774193548,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6016.129032258064,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6020.1612903225805,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6024.193548387097,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6028.225806451613,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 6032.258064516129,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 6036.290322580645,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 6040.322580645161,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 6048.387096774193,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 6052.419354838709,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 6056.451612903225,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 6060.4838709677415,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6064.516129032258,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6076.612903225807,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6080.645161290323,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 6084.677419354839,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6088.709677419355,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6092.741935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6096.774193548387,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6100.806451612903,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6104.8387096774195,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6112.903225806452,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6116.935483870968,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 6120.967741935484,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 6125,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6129.032258064516,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6133.064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6137.096774193548,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6141.129032258064,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6145.1612903225805,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6149.193548387097,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6153.225806451613,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6157.258064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 6161.290322580645,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 6165.322580645161,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 6169.354838709677,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 6173.387096774193,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 6177.419354838709,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6181.451612903225,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6185.4838709677415,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6189.516129032258,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6193.548387096775,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 6197.580645161291,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 6447.580645161291,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 6451.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6455.645161290323,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6500,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6504.032258064516,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6508.064516129032,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6564.516129032258,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6568.548387096774,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 6572.580645161291,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 6705.645161290323,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 6709.677419354839,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6713.709677419355,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 6717.741935483871,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 6721.774193548387,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 6725.806451612903,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 6729.8387096774195,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 6737.903225806452,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 6741.935483870968,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 6745.967741935484,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 6750,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 6754.032258064516,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 6758.064516129032,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 6762.096774193548,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 6766.129032258064,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6770.1612903225805,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6774.193548387097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6778.225806451613,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6782.258064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6786.290322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6790.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6822.580645161291,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6826.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6830.645161290323,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6842.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6846.774193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6850.806451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6891.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6895.1612903225805,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6899.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6907.258064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6911.290322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6915.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6955.645161290323,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6959.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6963.709677419355,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6967.741935483871,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 6971.774193548387,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 6975.806451612903,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 6979.8387096774195,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6983.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6987.903225806452,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6991.935483870968,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6995.967741935484,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7000,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7004.032258064516,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7008.064516129032,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7012.096774193548,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7016.129032258064,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 7020.1612903225805,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 7024.193548387097,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 7028.225806451613,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 7036.290322580645,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 7040.322580645161,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7044.354838709677,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7048.387096774193,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7052.419354838709,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 7056.451612903225,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 7060.4838709677415,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 7064.516129032258,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 7068.548387096774,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 7072.580645161291,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 7080.645161290323,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 7084.677419354839,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 7088.709677419355,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 7092.741935483871,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7096.774193548387,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7108.870967741936,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7112.903225806452,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 7116.935483870968,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7120.967741935484,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7125,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7129.032258064516,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7133.064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7137.096774193548,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7145.1612903225805,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7149.193548387097,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 7153.225806451613,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 7157.258064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7161.290322580645,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7165.322580645161,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7169.354838709677,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7173.387096774193,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7177.419354838709,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7181.451612903225,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7185.4838709677415,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7189.516129032258,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 7193.548387096774,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 7197.580645161291,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 7201.612903225807,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 7205.645161290323,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 7209.677419354839,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 7213.709677419355,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 7217.741935483871,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 7221.774193548387,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7225.806451612903,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 7229.8387096774195,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 7479.8387096774195,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 7483.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7487.903225806452,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7532.258064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7536.290322580645,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7540.322580645161,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7596.774193548387,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 7600.806451612903,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 7604.8387096774195,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 7737.903225806452,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 7741.935483870968,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7745.967741935484,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 7750,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 7754.032258064516,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 7758.064516129032,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7762.096774193548,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7770.1612903225805,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7774.193548387097,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7778.225806451613,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7782.258064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7786.290322580645,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7790.322580645161,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7794.354838709677,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 7798.387096774193,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7802.419354838709,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7806.451612903225,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7810.4838709677415,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7814.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7818.548387096774,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7822.580645161291,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7854.8387096774195,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7858.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7862.903225806452,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7875,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7879.032258064516,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7883.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7923.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7927.419354838709,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7931.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7939.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7943.548387096774,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7947.580645161291,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7987.903225806452,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7991.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7995.967741935484,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8000,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 8004.032258064516,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 8008.064516129032,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 8012.096774193548,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8016.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8020.1612903225805,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8024.193548387097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8028.225806451613,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8032.258064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8036.290322580645,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8040.322580645161,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8044.354838709677,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8048.387096774193,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8052.419354838709,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8056.451612903225,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8060.4838709677415,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 8068.548387096774,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 8072.580645161291,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8076.612903225807,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8080.645161290323,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8084.677419354839,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8088.709677419355,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8092.741935483871,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 8096.774193548387,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 8100.806451612903,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 8104.8387096774195,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 8112.903225806452,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 8116.935483870968,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 8120.967741935484,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 8125,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8129.032258064516,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8141.129032258064,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8145.1612903225805,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 8149.193548387097,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8153.225806451613,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8157.258064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8161.290322580645,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8165.322580645161,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8169.354838709677,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8177.419354838709,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8181.451612903225,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 8185.4838709677415,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 8189.516129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8193.548387096775,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8197.58064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8201.612903225807,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8205.645161290322,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8209.677419354839,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8213.709677419354,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8217.741935483871,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8221.774193548386,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 8225.806451612903,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 8229.838709677419,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 8233.870967741936,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 8237.90322580645,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 8241.935483870968,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8245.967741935483,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8250,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8254.032258064515,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8258.064516129032,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 8262.09677419355,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 8512.09677419355,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 8516.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8520.161290322581,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8564.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8568.548387096775,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8572.58064516129,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8629.032258064515,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8633.064516129032,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 8637.09677419355,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 8770.161290322581,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 8774.193548387097,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8778.225806451614,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 8782.258064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8786.290322580646,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8790.322580645161,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8794.354838709678,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8802.41935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8806.451612903225,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8810.483870967742,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8814.516129032258,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8818.548387096775,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8822.58064516129,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8826.612903225807,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 8830.645161290322,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8834.677419354839,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8838.709677419354,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8842.741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8846.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8850.806451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8854.838709677419,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8887.09677419355,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8891.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8895.161290322581,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8907.258064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8911.290322580646,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8915.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8955.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8959.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8963.709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8971.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8975.806451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8979.838709677419,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9020.161290322581,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9024.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9028.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9032.258064516129,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 9036.290322580646,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 9040.322580645161,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 9044.354838709678,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9048.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9052.41935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9056.451612903225,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9060.483870967742,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 9064.516129032258,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9068.548387096775,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9072.58064516129,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9076.612903225807,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9080.645161290322,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9084.677419354839,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9088.709677419354,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9092.741935483871,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 9100.806451612903,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 9104.838709677419,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9108.870967741936,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9112.90322580645,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9116.935483870968,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9120.967741935483,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9125,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 9129.032258064515,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 9133.064516129032,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 9137.09677419355,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 9145.161290322581,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 9149.193548387097,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 9153.225806451614,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 9157.258064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9161.290322580646,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9173.387096774193,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9177.41935483871,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 9181.451612903225,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9185.483870967742,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9189.516129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9193.548387096775,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9197.58064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9201.612903225807,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9209.677419354839,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9213.709677419354,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 9217.741935483871,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 9221.774193548386,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9225.806451612903,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9229.838709677419,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9233.870967741936,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9237.90322580645,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9241.935483870968,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9245.967741935483,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9250,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9254.032258064515,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 9258.064516129032,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 9262.09677419355,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 9266.129032258064,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 9270.161290322581,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 9274.193548387097,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9278.225806451614,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9282.258064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9286.290322580646,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9290.322580645161,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 9294.354838709678,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 9544.354838709678,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 9548.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9552.41935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9596.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9600.806451612903,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9604.838709677419,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9661.290322580646,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9665.322580645161,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 9669.354838709678,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 9802.41935483871,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 9806.451612903225,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9810.483870967742,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 9814.516129032258,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9818.548387096775,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9822.58064516129,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9826.612903225807,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9834.677419354839,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9838.709677419354,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9842.741935483871,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9846.774193548386,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9850.806451612903,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 9854.838709677419,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 9858.870967741936,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 9862.90322580645,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9866.935483870968,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9870.967741935483,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9875,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9879.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9883.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9887.09677419355,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9919.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9923.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9927.41935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9939.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9943.548387096775,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9947.58064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9987.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9991.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9995.967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10004.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10008.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10012.09677419355,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10052.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10056.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10060.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10064.516129032258,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 10068.548387096775,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 10072.58064516129,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 10076.612903225807,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10080.645161290322,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10084.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10088.709677419354,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10092.741935483871,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10096.774193548386,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10100.806451612903,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10104.838709677419,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10108.870967741936,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10112.90322580645,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10116.935483870968,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10120.967741935483,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10125,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 10133.064516129032,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 10137.09677419355,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10141.129032258064,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10145.161290322581,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10149.193548387097,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10153.225806451614,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10157.258064516129,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 10161.290322580646,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 10165.322580645161,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 10169.354838709678,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 10177.41935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 10181.451612903225,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 10185.483870967742,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 10189.516129032258,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10193.548387096775,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10205.645161290322,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10209.677419354839,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 10213.709677419354,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10217.741935483871,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10221.774193548386,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10225.806451612903,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10229.838709677419,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10233.870967741936,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10241.935483870968,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10245.967741935483,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 10250,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 10254.032258064515,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10258.064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10262.09677419355,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10266.129032258064,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10270.161290322581,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10274.193548387097,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10278.225806451614,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10282.258064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10286.290322580646,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 10290.322580645161,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 10294.354838709678,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 10298.387096774193,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 10302.41935483871,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 10306.451612903225,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10310.483870967742,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10314.516129032258,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10318.548387096775,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10322.58064516129,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 10326.612903225807,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 10576.612903225807,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 10580.645161290322,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10584.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10629.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10633.064516129032,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10637.09677419355,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10693.548387096775,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10697.58064516129,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 10701.612903225807,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 10834.677419354839,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 10838.709677419354,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10842.741935483871,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 10846.774193548386,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10850.806451612903,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10854.838709677419,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10858.870967741936,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10866.935483870968,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10870.967741935483,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10875,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10879.032258064515,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10883.064516129032,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10887.09677419355,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10891.129032258064,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 10895.161290322581,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10899.193548387097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10903.225806451614,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10907.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10911.290322580646,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10915.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10919.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10951.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10955.645161290322,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10959.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10971.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10975.806451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10979.838709677419,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11020.161290322581,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11024.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11028.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11036.290322580646,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11040.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11044.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11084.677419354839,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11088.709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11092.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11096.774193548386,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 11100.806451612903,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 11104.838709677419,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 11108.870967741936,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11112.90322580645,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11116.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11120.967741935483,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11125,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11129.032258064515,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 11133.064516129032,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 11137.09677419355,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11141.129032258064,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11145.161290322581,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11149.193548387097,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11153.225806451614,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11157.258064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 11165.322580645161,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 11169.354838709678,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11173.387096774193,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11177.41935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11181.451612903225,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11185.483870967742,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11189.516129032258,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 11193.548387096775,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 11197.58064516129,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 11201.612903225807,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 11209.677419354839,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 11213.709677419354,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 11217.741935483871,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 11221.774193548386,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11225.806451612903,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11237.90322580645,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11241.935483870968,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 11245.967741935483,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11250,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11254.032258064515,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11258.064516129032,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11262.09677419355,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11266.129032258064,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11274.193548387097,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11278.225806451614,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 11282.258064516129,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 11286.290322580646,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11290.322580645161,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11294.354838709678,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11298.387096774193,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11302.41935483871,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11306.451612903225,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11310.483870967742,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11314.516129032258,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11318.548387096775,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 11322.58064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 11326.612903225807,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 11330.645161290322,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 11334.677419354839,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 11338.709677419354,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11342.741935483871,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11346.774193548386,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11350.806451612903,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11354.838709677419,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 11358.870967741936,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 11608.870967741936,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 11612.90322580645,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11616.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11661.290322580646,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11665.322580645161,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11669.354838709678,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11725.806451612903,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11729.838709677419,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 11733.870967741936,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 11866.935483870968,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 11870.967741935483,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11875,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 11879.032258064515,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11883.064516129032,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11887.09677419355,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11891.129032258064,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11899.193548387097,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 11903.225806451614,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 11907.258064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 11911.290322580646,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 11915.322580645161,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11919.354838709678,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11923.387096774193,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 11927.41935483871,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11931.451612903225,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11935.483870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11939.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11943.548387096775,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11947.58064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11951.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11983.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11987.90322580645,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11991.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12004.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12008.064516129032,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12012.09677419355,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12052.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12056.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12060.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12068.548387096775,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12072.58064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12076.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12116.935483870968,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12120.967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12125,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12129.032258064515,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 12133.064516129032,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 12137.09677419355,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 12141.129032258064,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12145.161290322581,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12149.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12153.225806451614,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12157.258064516129,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12161.290322580646,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12165.322580645161,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12169.354838709678,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12173.387096774193,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12177.41935483871,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12181.451612903225,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12185.483870967742,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12189.516129032258,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 12197.58064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 12201.612903225807,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12205.645161290322,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12209.677419354839,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12213.709677419354,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12217.741935483871,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12221.774193548386,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 12225.806451612903,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 12229.838709677419,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 12233.870967741936,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 12241.935483870968,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 12245.967741935483,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 12250,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 12254.032258064515,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12258.064516129032,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12270.161290322581,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12274.193548387097,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 12278.225806451614,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12282.258064516129,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12286.290322580646,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12290.322580645161,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12294.354838709678,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12298.387096774193,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12306.451612903225,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12310.483870967742,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 12314.516129032258,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 12318.548387096775,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12322.58064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12326.612903225807,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12330.645161290322,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12334.677419354839,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12338.709677419354,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12342.741935483871,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12346.774193548386,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12350.806451612903,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 12354.838709677419,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 12358.870967741936,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 12362.90322580645,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 12366.935483870968,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 12370.967741935483,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12375,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12379.032258064515,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12383.064516129032,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12387.09677419355,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 12391.129032258064,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 12641.129032258064,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 12645.161290322581,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12649.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12693.548387096775,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12697.58064516129,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12701.612903225807,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12758.064516129032,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12762.096774193547,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 12766.129032258064,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 12899.193548387097,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 12903.225806451614,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12907.258064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 12911.290322580646,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12915.322580645161,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12919.354838709678,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12923.387096774193,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12931.451612903225,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12935.483870967742,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12939.516129032258,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12943.548387096775,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12947.58064516129,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12951.612903225807,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12955.645161290322,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 12959.677419354839,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12963.709677419354,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12967.741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12971.774193548386,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12975.806451612903,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12979.838709677419,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12983.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13016.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13020.161290322581,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13024.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13036.290322580646,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13040.322580645161,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13044.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13084.677419354839,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13088.709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13092.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13100.806451612903,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13104.838709677419,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13108.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13149.193548387097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13153.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13157.258064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13161.290322580646,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 13165.322580645161,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 13169.354838709678,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 13173.387096774193,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13177.41935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13181.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13185.483870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13189.516129032258,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13193.548387096775,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13197.58064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13201.612903225807,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13205.645161290322,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13209.677419354839,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13213.709677419354,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13217.741935483871,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13221.774193548386,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 13229.838709677419,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 13233.870967741936,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13237.90322580645,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13241.935483870968,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13245.967741935483,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13250,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13254.032258064515,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 13258.064516129032,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 13262.096774193547,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 13266.129032258064,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 13274.193548387097,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 13278.225806451614,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 13282.258064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 13286.290322580646,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13290.322580645161,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13302.41935483871,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13306.451612903225,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 13310.483870967742,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13314.516129032258,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13318.548387096775,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13322.58064516129,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13326.612903225807,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13330.645161290322,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13338.709677419354,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13342.741935483871,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 13346.774193548386,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 13350.806451612903,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13354.838709677419,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13358.870967741936,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13362.90322580645,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13366.935483870968,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13370.967741935483,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13375,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13379.032258064515,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13383.064516129032,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 13387.096774193547,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 13391.129032258064,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 13395.161290322581,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 13399.193548387097,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 13403.225806451614,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13407.258064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13411.290322580646,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13415.322580645161,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13419.354838709678,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 13423.387096774193,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 13673.387096774193,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 13677.41935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13681.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13725.806451612903,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13729.838709677419,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13733.870967741936,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13790.322580645161,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13794.354838709678,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 13798.387096774193,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 13931.451612903225,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 13935.483870967742,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13939.516129032258,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 13943.548387096775,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13947.58064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13951.612903225807,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13955.645161290322,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13963.709677419354,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13967.741935483871,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13971.774193548386,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13975.806451612903,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13979.838709677419,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13983.870967741936,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13987.90322580645,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 13991.935483870968,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13995.967741935483,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14000,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14004.032258064515,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14008.064516129032,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14012.096774193547,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14016.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14048.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14052.41935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14056.451612903225,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14068.548387096775,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14072.58064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14076.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14116.935483870968,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14120.967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14125,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14133.064516129032,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14137.096774193547,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14141.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14181.451612903225,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14185.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14189.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14193.548387096775,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 14197.58064516129,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 14201.612903225807,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 14205.645161290322,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14209.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14213.709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14217.741935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14221.774193548386,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 14225.806451612903,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 14229.838709677419,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 14233.870967741936,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14237.90322580645,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14241.935483870968,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14245.967741935483,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14250,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14254.032258064515,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 14262.096774193547,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 14266.129032258064,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14270.161290322581,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14274.193548387097,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14278.225806451614,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14282.258064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14286.290322580646,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 14290.322580645161,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 14294.354838709678,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 14298.387096774193,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 14306.451612903225,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 14310.483870967742,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 14314.516129032258,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 14318.548387096775,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14322.58064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14334.677419354839,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14338.709677419354,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 14342.741935483871,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14346.774193548386,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14350.806451612903,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14354.838709677419,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14358.870967741936,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14362.90322580645,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14370.967741935483,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14375,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 14379.032258064515,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 14383.064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14387.096774193547,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14391.129032258064,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14395.161290322581,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14399.193548387097,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14403.225806451614,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14407.258064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14411.290322580646,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14415.322580645161,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 14419.354838709678,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 14423.387096774193,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 14427.41935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 14431.451612903225,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 14435.483870967742,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14439.516129032258,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14443.548387096775,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14447.58064516129,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14451.612903225807,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 14455.645161290322,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 14705.645161290322,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 14709.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14713.709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14758.064516129032,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14762.096774193547,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14766.129032258064,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14822.58064516129,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14826.612903225807,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 14830.645161290322,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 14963.709677419354,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 14967.741935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14971.774193548386,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 14975.806451612903,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14979.838709677419,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14983.870967741936,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14987.90322580645,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14995.967741935483,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 15000,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15004.032258064515,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15008.064516129032,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15012.096774193547,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15016.129032258064,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15020.161290322581,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15024.193548387097,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15028.225806451614,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15032.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15036.290322580646,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15040.322580645161,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15044.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15048.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15080.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15084.677419354839,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15088.709677419354,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15100.806451612903,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15104.838709677419,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15108.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15149.193548387097,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15153.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15157.258064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15165.322580645161,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15169.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15173.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15213.709677419354,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15217.741935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15221.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15225.806451612903,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 15229.838709677419,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 15233.870967741936,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 15237.90322580645,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15241.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15245.967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15250,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15254.032258064515,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 15258.064516129032,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15262.096774193547,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15266.129032258064,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 15270.161290322581,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 15274.193548387097,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 15278.225806451614,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 15282.258064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 15286.290322580646,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 15294.354838709678,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 15298.387096774193,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15302.41935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15306.451612903225,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15310.483870967742,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15314.516129032258,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15318.548387096775,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 15322.58064516129,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 15326.612903225807,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 15330.645161290322,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 15338.709677419354,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 15342.741935483871,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 15346.774193548386,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 15350.806451612903,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15354.838709677419,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15366.935483870968,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15370.967741935483,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 15375,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15379.032258064515,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15383.064516129032,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15387.096774193547,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15391.129032258064,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15395.161290322581,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15403.225806451614,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15407.258064516129,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 15411.290322580646,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 15415.322580645161,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15419.354838709678,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15423.387096774193,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15427.41935483871,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15431.451612903225,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15435.483870967742,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15439.516129032258,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15443.548387096775,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15447.58064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 15451.612903225807,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 15455.645161290322,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 15459.677419354839,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 15463.709677419354,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 15467.741935483871,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15471.774193548386,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15475.806451612903,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15479.838709677419,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15483.870967741936,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 15487.90322580645,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 15737.90322580645,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 15741.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15745.967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15790.322580645161,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15794.354838709678,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15798.387096774193,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15854.838709677419,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15858.870967741936,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 15862.90322580645,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 15995.967741935483,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 16000,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16004.032258064515,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 16008.064516129032,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16012.096774193547,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16016.129032258064,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16020.161290322581,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16028.225806451614,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16032.258064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16036.290322580646,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16040.322580645161,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16044.354838709678,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16048.387096774193,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16052.41935483871,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16056.451612903225,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16060.483870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16064.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16068.548387096775,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16072.58064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16076.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16080.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16112.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16116.935483870968,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16120.967741935483,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16133.064516129032,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16137.096774193547,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16141.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16181.451612903225,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16185.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16189.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16197.58064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16201.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16205.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16245.967741935483,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16250,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16254.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16258.064516129032,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 16262.096774193547,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 16266.129032258064,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 16270.161290322581,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16274.193548387097,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16278.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16282.258064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16286.290322580646,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 16290.322580645161,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16294.354838709678,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16298.387096774193,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16302.41935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16306.451612903225,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16310.483870967742,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16314.516129032258,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16318.548387096775,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 16326.612903225807,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 16330.645161290322,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16334.677419354839,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16338.709677419354,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16342.741935483871,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16346.774193548386,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16350.806451612903,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 16354.838709677419,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 16358.870967741936,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 16362.90322580645,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 16370.967741935483,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 16375,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 16379.032258064515,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 16383.064516129032,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16387.09677419355,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16399.1935483871,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16403.225806451614,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 16407.25806451613,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16411.290322580644,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16415.322580645163,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16419.354838709678,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16423.387096774193,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16427.41935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16435.483870967742,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16439.516129032258,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 16443.548387096773,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 16447.58064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16451.612903225807,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16455.645161290322,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16459.677419354837,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16463.709677419356,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16467.74193548387,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16471.774193548386,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16475.8064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16479.83870967742,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 16483.870967741936,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 16487.90322580645,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 16491.935483870966,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 16495.967741935485,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 16500,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16504.032258064515,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16508.06451612903,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16512.09677419355,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16516.129032258064,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 16520.16129032258,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 16770.16129032258,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 16774.1935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16778.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16822.58064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16826.612903225807,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16830.645161290322,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16887.09677419355,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16891.129032258064,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 16895.16129032258,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 17028.225806451614,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 17032.25806451613,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17036.290322580644,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 17040.322580645163,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17044.354838709678,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17048.387096774193,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17052.41935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17060.483870967742,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17064.516129032258,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17068.548387096773,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17072.58064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17076.612903225807,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17080.645161290322,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17084.677419354837,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17088.709677419356,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17092.74193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17096.774193548386,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17100.8064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17104.83870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17108.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17112.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17145.16129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17149.1935483871,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17153.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17165.322580645163,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17169.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17173.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17213.709677419356,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17217.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17221.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17229.83870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17233.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17237.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17278.225806451614,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17282.25806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17286.290322580644,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17290.322580645163,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 17294.354838709678,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 17298.387096774193,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 17302.41935483871,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17306.451612903227,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17310.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17314.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17318.548387096773,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 17322.58064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17326.612903225807,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17330.645161290322,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17334.677419354837,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17338.709677419356,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17342.74193548387,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17346.774193548386,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17350.8064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 17358.870967741936,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 17362.90322580645,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17366.935483870966,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17370.967741935485,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17375,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17379.032258064515,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17383.06451612903,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 17387.09677419355,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 17391.129032258064,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 17395.16129032258,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 17403.225806451614,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 17407.25806451613,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 17411.290322580644,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 17415.322580645163,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17419.354838709678,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17431.451612903227,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17435.483870967742,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 17439.516129032258,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17443.548387096773,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17447.58064516129,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17451.612903225807,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17455.645161290322,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17459.677419354837,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17467.74193548387,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17471.774193548386,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 17475.8064516129,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 17479.83870967742,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17483.870967741936,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17487.90322580645,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17491.935483870966,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17495.967741935485,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17500,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17504.032258064515,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17508.06451612903,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17512.09677419355,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 17516.129032258064,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 17520.16129032258,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 17524.1935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 17528.225806451614,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 17532.25806451613,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17536.290322580644,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17540.322580645163,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17544.354838709678,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17548.387096774193,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 17552.41935483871,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 17802.41935483871,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 17806.451612903227,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17810.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17854.83870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17858.870967741936,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17862.90322580645,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17919.354838709678,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17923.387096774193,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 17927.41935483871,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 18060.483870967742,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 18064.516129032258,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18068.548387096773,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 18072.58064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18076.612903225807,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18080.645161290322,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18084.677419354837,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18092.74193548387,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18096.774193548386,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18100.8064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18104.83870967742,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18108.870967741936,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18112.90322580645,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18116.935483870966,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18120.967741935485,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18125,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18129.032258064515,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18133.06451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18137.09677419355,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18141.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18145.16129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18177.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18181.451612903227,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18185.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18197.58064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18201.612903225807,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18205.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18245.967741935485,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18250,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18254.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18262.09677419355,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18266.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18270.16129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18310.483870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18314.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18318.548387096773,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18322.58064516129,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 18326.612903225807,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 18330.645161290322,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 18334.677419354837,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18338.709677419356,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18342.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18346.774193548386,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18350.8064516129,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 18354.83870967742,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18358.870967741936,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18362.90322580645,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18366.935483870966,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18370.967741935485,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18375,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18379.032258064515,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18383.06451612903,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 18391.129032258064,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 18395.16129032258,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18399.1935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18403.225806451614,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18407.25806451613,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18411.290322580644,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18415.322580645163,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 18419.354838709678,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 18423.387096774193,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 18427.41935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 18435.483870967742,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 18439.516129032258,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 18443.548387096773,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 18447.58064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18451.612903225807,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18463.709677419356,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18467.74193548387,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 18471.774193548386,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18475.8064516129,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18479.83870967742,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18483.870967741936,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18487.90322580645,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18491.935483870966,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18500,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18504.032258064515,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 18508.06451612903,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 18512.09677419355,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18516.129032258064,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18520.16129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18524.1935483871,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18528.225806451614,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18532.25806451613,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18536.290322580644,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18540.322580645163,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18544.354838709678,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 18548.387096774193,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 18552.41935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 18556.451612903227,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 18560.483870967742,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 18564.516129032258,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18568.548387096773,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18572.58064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18576.612903225807,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18580.645161290322,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 18584.677419354837,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 18834.677419354837,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 18838.709677419356,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18842.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18887.09677419355,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18891.129032258064,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18895.16129032258,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18951.612903225807,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18955.645161290322,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 18959.677419354837,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 19092.74193548387,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 19096.774193548386,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19100.8064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 19104.83870967742,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 19108.870967741936,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 19112.90322580645,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19116.935483870966,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19125,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19129.032258064515,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 19133.06451612903,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 19137.09677419355,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 19141.129032258064,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19145.16129032258,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19149.1935483871,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19153.225806451614,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19157.25806451613,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19161.290322580644,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19165.322580645163,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19169.354838709678,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19173.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19177.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19209.677419354837,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19213.709677419356,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19217.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19229.83870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19233.870967741936,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19237.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19278.225806451614,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19282.25806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19286.290322580644,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19294.354838709678,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19298.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19302.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19342.74193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19346.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19350.8064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19354.83870967742,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 19358.870967741936,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 19362.90322580645,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 19366.935483870966,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19370.967741935485,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19375,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19379.032258064515,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19383.06451612903,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 19387.09677419355,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 19391.129032258064,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 19395.16129032258,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19399.1935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19403.225806451614,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 19407.25806451613,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 19411.290322580644,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 19415.322580645163,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 19423.387096774193,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 19427.41935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19431.451612903227,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19435.483870967742,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19439.516129032258,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19443.548387096773,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19447.58064516129,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 19451.612903225807,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 19455.645161290322,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 19459.677419354837,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 19467.74193548387,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 19471.774193548386,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 19475.8064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 19479.83870967742,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19483.870967741936,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19495.967741935485,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19500,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 19504.032258064515,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19508.06451612903,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19512.09677419355,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19516.129032258064,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19520.16129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19524.1935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19532.25806451613,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19536.290322580644,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 19540.322580645163,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 19544.354838709678,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19548.387096774193,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19552.41935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19556.451612903227,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19560.483870967742,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19564.516129032258,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19568.548387096773,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19572.58064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19576.612903225807,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 19580.645161290322,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 19584.677419354837,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 19588.709677419356,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 19592.74193548387,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 19596.774193548386,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19600.8064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19604.83870967742,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19608.870967741936,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19612.90322580645,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 19616.935483870966,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 19866.935483870966,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 19870.967741935485,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19875,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19919.354838709678,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19923.387096774193,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19927.41935483871,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19983.870967741936,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19987.90322580645,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 19991.935483870966,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 20125,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 20129.032258064515,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20133.06451612903,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 20137.09677419355,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20141.129032258064,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20145.16129032258,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20149.1935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20157.25806451613,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20161.290322580644,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20165.322580645163,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20169.354838709678,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20173.387096774193,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20177.41935483871,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20181.451612903227,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20185.483870967742,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20189.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20193.548387096773,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20197.58064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20201.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20205.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20209.677419354837,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20241.935483870966,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20245.967741935485,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20250,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20262.09677419355,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20266.129032258064,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20270.16129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20310.483870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20314.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20318.548387096773,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20326.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20330.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20334.677419354837,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20375,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20379.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20383.06451612903,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20387.09677419355,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 20391.129032258064,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 20395.16129032258,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 20399.1935483871,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20403.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20407.25806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20411.290322580644,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20415.322580645163,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 20419.354838709678,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20423.387096774193,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20427.41935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20431.451612903227,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20435.483870967742,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20439.516129032258,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20443.548387096773,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20447.58064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 20455.645161290322,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 20459.677419354837,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20463.709677419356,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20467.74193548387,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20471.774193548386,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20475.8064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20479.83870967742,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 20483.870967741936,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 20487.90322580645,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 20491.935483870966,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 20500,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 20504.032258064515,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 20508.06451612903,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 20512.09677419355,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20516.129032258064,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20528.225806451614,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20532.25806451613,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 20536.290322580644,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20540.322580645163,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20544.354838709678,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20548.387096774193,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20552.41935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20556.451612903227,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20564.516129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20568.548387096773,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 20572.58064516129,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 20576.612903225807,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20580.645161290322,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20584.677419354837,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20588.709677419356,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20592.74193548387,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20596.774193548386,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20600.8064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20604.83870967742,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20608.870967741936,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 20612.90322580645,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 20616.935483870966,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 20620.967741935485,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 20625,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 20629.032258064515,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20633.06451612903,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20637.09677419355,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20641.129032258064,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20645.16129032258,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 20649.1935483871,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 20899.1935483871,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 20903.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20907.25806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20951.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20955.645161290322,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20959.677419354837,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21016.129032258064,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21020.16129032258,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 21024.1935483871,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 21157.25806451613,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 21161.290322580644,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21165.322580645163,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 21169.354838709678,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21173.387096774193,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21177.41935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21181.451612903227,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21189.516129032258,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21193.548387096773,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21197.58064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21201.612903225807,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21205.645161290322,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21209.677419354837,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21213.709677419356,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21217.74193548387,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21221.774193548386,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21225.8064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21229.83870967742,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21233.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21237.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21241.935483870966,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21274.1935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21278.225806451614,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21282.25806451613,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21294.354838709678,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21298.387096774193,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21302.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21342.74193548387,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21346.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21350.8064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21358.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21362.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21366.935483870966,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21407.25806451613,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21411.290322580644,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21415.322580645163,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21419.354838709678,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 21423.387096774193,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 21427.41935483871,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 21431.451612903227,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21435.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21439.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21443.548387096773,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21447.58064516129,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 21451.612903225807,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21455.645161290322,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21459.677419354837,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21463.709677419356,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21467.74193548387,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21471.774193548386,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21475.8064516129,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21479.83870967742,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 21487.90322580645,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 21491.935483870966,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21495.967741935485,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21500,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21504.032258064515,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21508.06451612903,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21512.09677419355,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 21516.129032258064,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 21520.16129032258,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 21524.1935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 21532.25806451613,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 21536.290322580644,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 21540.322580645163,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 21544.354838709678,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21548.387096774193,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21560.483870967742,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21564.516129032258,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 21568.548387096773,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21572.58064516129,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21576.612903225807,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21580.645161290322,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21584.677419354837,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21588.709677419356,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21596.774193548386,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21600.8064516129,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 21604.83870967742,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 21608.870967741936,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21612.90322580645,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21616.935483870966,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21620.967741935485,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21625,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21629.032258064515,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21633.06451612903,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21637.09677419355,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21641.129032258064,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 21645.16129032258,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 21649.1935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 21653.225806451614,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 21657.25806451613,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 21661.290322580644,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21665.322580645163,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21669.354838709678,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21673.387096774193,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21677.41935483871,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 21681.451612903227,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 21931.451612903227,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 21935.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21939.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21983.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21987.90322580645,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21991.935483870966,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22048.387096774193,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22052.41935483871,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 22056.451612903227,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 22189.516129032258,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 22193.548387096773,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22197.58064516129,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 22201.612903225807,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22205.645161290322,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22209.677419354837,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22213.709677419356,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22221.774193548386,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22225.8064516129,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22229.83870967742,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22233.870967741936,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22237.90322580645,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22241.935483870966,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22245.967741935485,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22250,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22254.032258064515,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22258.06451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22262.09677419355,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22266.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22270.16129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22274.1935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22306.451612903227,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22310.483870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22314.516129032258,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22326.612903225807,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22330.645161290322,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22334.677419354837,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22375,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22379.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22383.06451612903,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22391.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22395.16129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22399.1935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22439.516129032258,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22443.548387096773,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22447.58064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22451.612903225807,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 22455.645161290322,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 22459.677419354837,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 22463.709677419356,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22467.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22471.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22475.8064516129,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22479.83870967742,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 22483.870967741936,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22487.90322580645,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22491.935483870966,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22495.967741935485,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22500,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22504.032258064515,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22508.06451612903,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22512.09677419355,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 22520.16129032258,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 22524.1935483871,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22528.225806451614,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22532.25806451613,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22536.290322580644,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22540.322580645163,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22544.354838709678,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 22548.387096774193,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 22552.41935483871,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 22556.451612903227,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 22564.516129032258,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 22568.548387096773,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 22572.58064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 22576.612903225807,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22580.645161290322,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22592.74193548387,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22596.774193548386,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 22600.8064516129,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22604.83870967742,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22608.870967741936,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22612.90322580645,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22616.935483870966,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22620.967741935485,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22629.032258064515,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22633.06451612903,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 22637.09677419355,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 22641.129032258064,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22645.16129032258,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22649.1935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22653.225806451614,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22657.25806451613,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22661.290322580644,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22665.322580645163,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22669.354838709678,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22673.387096774193,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 22677.41935483871,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 22681.451612903227,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 22685.483870967742,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 22689.516129032258,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 22693.548387096773,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22697.58064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22701.612903225807,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22705.645161290322,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22709.677419354837,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 22713.709677419356,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 22963.709677419356,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 22967.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22971.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23016.129032258064,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23020.16129032258,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23024.1935483871,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23080.645161290322,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23084.677419354837,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 23088.709677419356,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 23221.774193548386,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 23225.8064516129,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23229.83870967742,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 23233.870967741936,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 23237.90322580645,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 23241.935483870966,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23245.967741935485,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23254.032258064515,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23258.06451612903,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23262.09677419355,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23266.129032258064,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23270.16129032258,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23274.1935483871,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23278.225806451614,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23282.25806451613,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23286.290322580644,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23290.322580645163,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23294.354838709678,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23298.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23302.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23306.451612903227,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23338.709677419356,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23342.74193548387,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23346.774193548386,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23358.870967741936,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23362.90322580645,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23366.935483870966,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23407.25806451613,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23411.290322580644,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23415.322580645163,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23423.387096774193,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23427.41935483871,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23431.451612903227,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23471.774193548386,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23475.8064516129,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23479.83870967742,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23483.870967741936,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 23487.90322580645,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 23491.935483870966,
      y: 0.13997650146484375,
      __typename: 'Points',
    },
    {
      x: 23495.967741935485,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 23500,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23504.032258064515,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23508.06451612903,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23512.09677419355,
      y: 0.08017730712890625,
      __typename: 'Points',
    },
    {
      x: 23516.129032258064,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23520.16129032258,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23524.1935483871,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23528.225806451614,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23532.25806451613,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 23536.290322580644,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 23540.322580645163,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 23544.354838709678,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 23552.41935483871,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 23556.451612903227,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23560.483870967742,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23564.516129032258,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23568.548387096773,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 23572.58064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 23576.612903225807,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 23580.645161290322,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 23584.677419354837,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 23588.709677419356,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 23596.774193548386,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 23600.8064516129,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 23604.83870967742,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 23608.870967741936,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23612.90322580645,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23625,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23629.032258064515,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 23633.06451612903,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23637.09677419355,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23641.129032258064,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23645.16129032258,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23649.1935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23653.225806451614,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23661.290322580644,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23665.322580645163,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 23669.354838709678,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 23673.387096774193,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23677.41935483871,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23681.451612903227,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23685.483870967742,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23689.516129032258,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23693.548387096773,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23697.58064516129,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23701.612903225807,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23705.645161290322,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 23709.677419354837,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 23713.709677419356,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 23717.74193548387,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 23721.774193548386,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 23725.8064516129,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 23729.83870967742,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 23733.870967741936,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 23737.90322580645,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23741.935483870966,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 23745.967741935485,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
    {
      x: 24000,
      y: 0.14994430541992188,
      __typename: 'Points',
    },
  ],
  avf: [
    {
      x: 0,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 50,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 54.16666666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 58.333333333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 116.66666666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 120.83333333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 125.00000000000001,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 262.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 266.6666666666667,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 270.83333333333337,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 275,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 279.1666666666667,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 283.33333333333337,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 287.5,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 291.6666666666667,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 295.83333333333337,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 300,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 304.1666666666667,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 308.33333333333337,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 312.5,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 316.6666666666667,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 320.83333333333337,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 325,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 329.1666666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 333.33333333333337,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 337.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 341.6666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 345.83333333333337,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 350,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 383.33333333333337,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 387.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 391.6666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 404.1666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 408.33333333333337,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 412.50000000000006,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 454.1666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 458.33333333333337,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 462.50000000000006,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 470.83333333333337,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 475.00000000000006,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 479.1666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 520.8333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 525,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 529.1666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 533.3333333333334,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 537.5,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 541.6666666666667,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 545.8333333333334,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 550,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 554.1666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 558.3333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 562.5,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 566.6666666666667,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 570.8333333333334,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 575,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 579.1666666666667,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 583.3333333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 587.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 591.6666666666667,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 595.8333333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 600,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 604.1666666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 608.3333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 612.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 616.6666666666667,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 620.8333333333334,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 625,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 629.1666666666667,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 633.3333333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 637.5,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 641.6666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 645.8333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 650,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 654.1666666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 658.3333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 662.5,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 666.6666666666667,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 670.8333333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 675,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 679.1666666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 683.3333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 687.5,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 691.6666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 695.8333333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 700,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 704.1666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 708.3333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 716.6666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 720.8333333333334,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 725,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 729.1666666666667,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 733.3333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 737.5,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 741.6666666666667,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 745.8333333333334,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 750,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 754.1666666666667,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 758.3333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 762.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 766.6666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 770.8333333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 775,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 779.1666666666667,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 783.3333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 787.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 791.6666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 795.8333333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 800,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 804.1666666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 1062.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 1066.6666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1070.8333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1116.6666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1120.8333333333335,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1125,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1183.3333333333335,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 1187.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 1191.6666666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 1329.1666666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 1333.3333333333335,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 1337.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1341.6666666666667,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 1345.8333333333335,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1350,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 1354.1666666666667,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 1358.3333333333335,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 1362.5,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 1366.6666666666667,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 1370.8333333333335,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 1375,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 1379.1666666666667,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 1383.3333333333335,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 1387.5,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 1391.6666666666667,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 1395.8333333333335,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 1400,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 1404.1666666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 1408.3333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1412.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1416.6666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1450,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1454.1666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1458.3333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1470.8333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1475,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1479.1666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1520.8333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1525,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1529.1666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1537.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1541.6666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1545.8333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1587.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1591.6666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1595.8333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 1600,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 1604.1666666666667,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 1608.3333333333335,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 1612.5000000000002,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 1616.6666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 1620.8333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 1625.0000000000002,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 1629.1666666666667,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 1633.3333333333335,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 1637.5000000000002,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 1641.6666666666667,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 1645.8333333333335,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 1650.0000000000002,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1654.1666666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 1658.3333333333335,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 1662.5000000000002,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 1666.6666666666667,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 1670.8333333333335,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 1675.0000000000002,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 1679.1666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 1683.3333333333335,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 1687.5000000000002,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 1691.6666666666667,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1695.8333333333335,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 1700.0000000000002,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 1704.1666666666667,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 1708.3333333333335,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1712.5000000000002,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1716.6666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1720.8333333333335,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1725.0000000000002,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1729.1666666666667,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 1733.3333333333335,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 1737.5000000000002,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 1741.6666666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 1745.8333333333335,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 1750.0000000000002,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 1754.1666666666667,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 1758.3333333333335,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 1762.5000000000002,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 1766.6666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 1770.8333333333335,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 1775.0000000000002,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 1783.3333333333335,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 1787.5000000000002,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 1791.6666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 1795.8333333333335,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 1800.0000000000002,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 1804.1666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 1808.3333333333335,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 1812.5000000000002,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 1816.6666666666667,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 1820.8333333333335,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 1825.0000000000002,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 1829.1666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1833.3333333333335,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 1837.5000000000002,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 1841.6666666666667,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 1845.8333333333335,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 1850.0000000000002,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 1854.1666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 1858.3333333333335,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 1862.5000000000002,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 1866.6666666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 1870.8333333333335,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 2129.166666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 2133.3333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2137.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2183.3333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2187.5,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2191.666666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2250,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 2254.166666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 2258.3333333333335,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 2395.8333333333335,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 2400,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 2404.166666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2408.3333333333335,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 2412.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2416.666666666667,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 2420.8333333333335,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 2425,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 2429.166666666667,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 2433.3333333333335,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 2437.5,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 2441.666666666667,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 2445.8333333333335,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 2450,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 2454.166666666667,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 2458.3333333333335,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 2462.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 2466.666666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 2470.8333333333335,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 2475,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2479.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2483.3333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2516.666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2520.8333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2525,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2537.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2541.666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2545.8333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2587.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2591.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2595.8333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2604.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2608.3333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2612.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2654.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2658.3333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2662.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 2666.666666666667,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 2670.8333333333335,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 2675,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 2679.166666666667,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 2683.3333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 2687.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 2691.666666666667,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 2695.8333333333335,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 2700,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 2704.166666666667,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 2708.3333333333335,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 2712.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 2716.666666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2720.8333333333335,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 2725,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 2729.166666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 2733.3333333333335,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 2737.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 2741.666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 2745.8333333333335,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 2750,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 2754.166666666667,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 2758.3333333333335,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2762.5,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 2766.666666666667,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 2770.8333333333335,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 2775,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2779.166666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2783.3333333333335,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2787.5,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 2791.666666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 2795.8333333333335,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 2800,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 2804.166666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 2808.3333333333335,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 2812.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 2816.666666666667,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 2820.8333333333335,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 2825,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 2829.166666666667,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 2833.3333333333335,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 2837.5,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 2841.666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 2850,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 2854.166666666667,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 2858.3333333333335,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 2862.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 2866.666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 2870.8333333333335,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 2875,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 2879.166666666667,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 2883.3333333333335,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 2887.5,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 2891.666666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 2895.8333333333335,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2900,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 2904.166666666667,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 2908.3333333333335,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 2912.5,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 2916.666666666667,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 2920.8333333333335,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 2925,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 2929.166666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 2933.3333333333335,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 2937.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 3195.8333333333335,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 3200,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3204.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3250.0000000000005,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3254.166666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3258.3333333333335,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3316.666666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 3320.8333333333335,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 3325.0000000000005,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 3462.5000000000005,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 3466.666666666667,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 3470.8333333333335,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3475.0000000000005,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 3479.166666666667,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3483.3333333333335,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 3487.5000000000005,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 3491.666666666667,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 3495.8333333333335,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 3500.0000000000005,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 3504.166666666667,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 3508.3333333333335,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 3512.5000000000005,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 3516.666666666667,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 3520.8333333333335,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 3525.0000000000005,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 3529.166666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 3533.3333333333335,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 3537.5000000000005,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 3541.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3545.8333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3550.0000000000005,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3583.3333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3587.5000000000005,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3591.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3604.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3608.3333333333335,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3612.5000000000005,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3654.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3658.3333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3662.5000000000005,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3670.8333333333335,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3675.0000000000005,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3679.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3720.8333333333335,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3725.0000000000005,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3729.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 3733.3333333333335,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 3737.5000000000005,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 3741.666666666667,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 3745.8333333333335,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 3750.0000000000005,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 3754.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 3758.3333333333335,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 3762.5000000000005,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 3766.666666666667,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 3770.8333333333335,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 3775.0000000000005,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 3779.166666666667,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 3783.3333333333335,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3787.5000000000005,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 3791.666666666667,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 3795.8333333333335,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 3800.0000000000005,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 3804.166666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 3808.3333333333335,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 3812.5000000000005,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 3816.666666666667,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 3820.8333333333335,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 3825.0000000000005,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3829.166666666667,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 3833.3333333333335,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 3837.5000000000005,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 3841.666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3845.8333333333335,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3850.0000000000005,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3854.166666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3858.3333333333335,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3862.5000000000005,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 3866.666666666667,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 3870.8333333333335,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 3875.0000000000005,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 3879.166666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 3883.3333333333335,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 3887.5000000000005,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 3891.666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 3895.8333333333335,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 3900.0000000000005,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 3904.166666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 3908.3333333333335,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 3916.666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 3920.8333333333335,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 3925.0000000000005,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 3929.166666666667,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 3933.3333333333335,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 3937.5000000000005,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 3941.666666666667,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 3945.8333333333335,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 3950.0000000000005,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 3954.166666666667,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 3958.3333333333335,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 3962.5000000000005,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3966.666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 3970.8333333333335,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 3975.0000000000005,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 3979.166666666667,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 3983.3333333333335,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 3987.5000000000005,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 3991.666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 3995.8333333333335,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 4000.0000000000005,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 4004.166666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 4262.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 4266.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4270.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4316.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4320.833333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4325,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4383.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 4387.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 4391.666666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 4529.166666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 4533.333333333334,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 4537.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4541.666666666667,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 4545.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4550,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 4554.166666666667,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 4558.333333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 4562.5,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 4566.666666666667,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 4570.833333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 4575,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 4579.166666666667,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 4583.333333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 4587.5,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 4591.666666666667,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 4595.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 4600,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 4604.166666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 4608.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4612.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4616.666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4650,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4654.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4658.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4670.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4675,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4679.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4720.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4725,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4729.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4737.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4741.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4745.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4787.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4791.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4795.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 4800,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 4804.166666666667,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 4808.333333333334,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 4812.5,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 4816.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 4820.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 4825,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 4829.166666666667,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 4833.333333333334,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 4837.5,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 4841.666666666667,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 4845.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 4850,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4854.166666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 4858.333333333334,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 4862.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 4866.666666666667,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 4870.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 4875,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 4879.166666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 4883.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 4887.5,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 4891.666666666667,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 4895.833333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 4900,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 4904.166666666667,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 4908.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4912.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4916.666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 4920.833333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4925,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 4929.166666666667,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 4933.333333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 4937.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 4941.666666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 4945.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 4950,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 4954.166666666667,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 4958.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 4962.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 4966.666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 4970.833333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 4975,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 4983.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 4987.5,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 4991.666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 4995.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 5000,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 5004.166666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 5008.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 5012.5,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 5016.666666666667,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 5020.833333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 5025,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5029.166666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5033.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5037.5,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 5041.666666666667,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 5045.833333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5050,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 5054.166666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 5058.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 5062.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 5066.666666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 5070.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 5329.166666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 5333.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5337.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5383.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5387.5,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5391.666666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5450,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 5454.166666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 5458.333333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 5595.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 5600,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 5604.166666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 5608.333333333334,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 5612.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 5616.666666666667,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 5620.833333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 5625,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 5629.166666666667,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 5633.333333333334,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 5637.5,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 5641.666666666667,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 5645.833333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 5650,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 5654.166666666667,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 5658.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 5662.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 5666.666666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 5670.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 5675,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5679.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5683.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5716.666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5720.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5725,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5737.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5741.666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5745.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5787.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5791.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5795.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5804.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5808.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5812.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5854.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5858.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5862.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 5866.666666666667,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 5870.833333333334,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 5875,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 5879.166666666667,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 5883.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 5887.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 5891.666666666667,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 5895.833333333334,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 5900,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 5904.166666666667,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 5908.333333333334,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 5912.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 5916.666666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 5920.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 5925,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 5929.166666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 5933.333333333334,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 5937.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 5941.666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 5945.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 5950,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 5954.166666666667,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 5958.333333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 5962.5,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 5966.666666666667,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 5970.833333333334,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 5975,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5979.166666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5983.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 5987.5,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5991.666666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 5995.833333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 6000,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 6004.166666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 6008.333333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 6012.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 6016.666666666667,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 6020.833333333334,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 6025,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 6029.166666666667,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 6033.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 6037.5,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 6041.666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 6050,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 6054.166666666667,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 6058.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 6062.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 6066.666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 6070.833333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 6075,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 6079.166666666667,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 6083.333333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 6087.5,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 6091.666666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 6095.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6100,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 6104.166666666667,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 6108.333333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 6112.5,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 6116.666666666667,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 6120.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 6125,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 6129.166666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 6133.333333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 6137.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 6395.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 6400,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6404.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6450.000000000001,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6454.166666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6458.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6516.666666666667,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 6520.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 6525.000000000001,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 6662.500000000001,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 6666.666666666667,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 6670.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6675.000000000001,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 6679.166666666667,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6683.333333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 6687.500000000001,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 6691.666666666667,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 6695.833333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 6700.000000000001,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 6704.166666666667,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 6708.333333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 6712.500000000001,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 6716.666666666667,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 6720.833333333334,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 6725.000000000001,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 6729.166666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 6733.333333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 6737.500000000001,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 6741.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6745.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6750.000000000001,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6783.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6787.500000000001,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6791.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6804.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6808.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6812.500000000001,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6854.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6858.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6862.500000000001,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6870.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6875.000000000001,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6879.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6920.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6925.000000000001,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6929.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 6933.333333333334,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 6937.500000000001,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 6941.666666666667,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 6945.833333333334,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 6950.000000000001,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 6954.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 6958.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 6962.500000000001,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 6966.666666666667,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 6970.833333333334,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 6975.000000000001,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 6979.166666666667,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 6983.333333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6987.500000000001,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 6991.666666666667,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 6995.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 7000.000000000001,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 7004.166666666667,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 7008.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 7012.500000000001,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 7016.666666666667,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 7020.833333333334,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 7025.000000000001,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7029.166666666667,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 7033.333333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 7037.500000000001,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 7041.666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7045.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7050.000000000001,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7054.166666666667,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7058.333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7062.500000000001,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 7066.666666666667,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 7070.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 7075.000000000001,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 7079.166666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 7083.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 7087.500000000001,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 7091.666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 7095.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 7100.000000000001,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 7104.166666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 7108.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 7116.666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 7120.833333333334,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 7125.000000000001,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 7129.166666666667,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 7133.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 7137.500000000001,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 7141.666666666667,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 7145.833333333334,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 7150.000000000001,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 7154.166666666667,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 7158.333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 7162.500000000001,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7166.666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 7170.833333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 7175.000000000001,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 7179.166666666667,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 7183.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 7187.500000000001,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 7191.666666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 7195.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 7200.000000000001,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 7204.166666666667,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 7462.500000000001,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 7466.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7470.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7516.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7520.833333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7525.000000000001,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7583.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 7587.500000000001,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 7591.666666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 7729.166666666667,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 7733.333333333334,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 7737.500000000001,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 7741.666666666667,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 7745.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 7750.000000000001,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 7754.166666666667,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 7758.333333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 7762.500000000001,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 7766.666666666667,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 7770.833333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 7775.000000000001,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 7779.166666666667,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 7783.333333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 7787.500000000001,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 7791.666666666667,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 7795.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 7800.000000000001,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 7804.166666666667,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 7808.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7812.500000000001,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7816.666666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7850.000000000001,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7854.166666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7858.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7870.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7875.000000000001,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7879.166666666667,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7920.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 7925.000000000001,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7929.166666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7937.500000000001,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7941.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7945.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7987.500000000001,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 7991.666666666667,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 7995.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8000.000000000001,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 8004.166666666667,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 8008.333333333334,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 8012.500000000001,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 8016.666666666667,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8020.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8025.000000000001,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 8029.166666666667,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 8033.333333333334,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 8037.500000000001,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 8041.666666666667,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 8045.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8050.000000000001,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8054.166666666667,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8058.333333333334,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 8062.500000000001,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 8066.666666666667,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 8070.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 8075.000000000001,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 8079.166666666667,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 8083.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 8087.500000000001,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 8091.666666666667,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8095.833333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 8100.000000000001,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 8104.166666666667,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 8108.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8112.500000000001,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8116.666666666667,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8120.833333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8125.000000000001,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8129.166666666667,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 8133.333333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 8137.500000000001,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 8141.666666666667,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 8145.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 8150.000000000001,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 8154.166666666667,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 8158.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 8162.500000000001,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 8166.666666666667,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 8170.833333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 8175.000000000001,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 8183.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 8187.500000000001,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 8191.666666666667,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 8195.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 8200,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 8204.166666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 8208.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 8212.5,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 8216.666666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 8220.833333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 8225,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 8229.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8233.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 8237.5,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 8241.666666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 8245.833333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 8250,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 8254.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 8258.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 8262.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 8266.666666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 8270.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 8529.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 8533.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8537.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8583.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8587.5,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8591.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8650,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 8654.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 8658.333333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 8795.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 8800,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 8804.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 8808.333333333334,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 8812.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 8816.666666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 8820.833333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 8825,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 8829.166666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 8833.333333333334,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 8837.5,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 8841.666666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 8845.833333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 8850,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 8854.166666666668,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 8858.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 8862.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 8866.666666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 8870.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 8875,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8879.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8883.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8916.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8920.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8925,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8937.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 8941.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8945.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8987.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 8991.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 8995.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9004.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9008.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9012.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9054.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9058.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9062.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9066.666666666668,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 9070.833333333334,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 9075,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 9079.166666666668,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 9083.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9087.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9091.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 9095.833333333334,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 9100,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 9104.166666666668,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 9108.333333333334,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 9112.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9116.666666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9120.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9125,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 9129.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 9133.333333333334,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 9137.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 9141.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 9145.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 9150,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 9154.166666666668,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 9158.333333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9162.5,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 9166.666666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 9170.833333333334,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 9175,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9179.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9183.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9187.5,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9191.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9195.833333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 9200,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 9204.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 9208.333333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 9212.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 9216.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 9220.833333333334,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 9225,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 9229.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 9233.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 9237.5,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 9241.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 9250,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 9254.166666666668,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 9258.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 9262.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 9266.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 9270.833333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 9275,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 9279.166666666668,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 9283.333333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 9287.5,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 9291.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 9295.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9300,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 9304.166666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 9308.333333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 9312.5,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 9316.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 9320.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 9325,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 9329.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 9333.333333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 9337.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 9595.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 9600,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9604.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9650,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 9654.166666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9658.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9716.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 9720.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 9725,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 9862.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 9866.666666666668,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 9870.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 9875,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 9879.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 9883.333333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 9887.5,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 9891.666666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 9895.833333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 9900,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 9904.166666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 9908.333333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 9912.5,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 9916.666666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 9920.833333333334,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 9925,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 9929.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 9933.333333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 9937.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 9941.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9945.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9950,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9983.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 9987.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 9991.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10004.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10008.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10012.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10054.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10058.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10062.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10070.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10075,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10079.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10120.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10125,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10129.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10133.333333333334,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 10137.5,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 10141.666666666668,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 10145.833333333334,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 10150,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 10154.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 10158.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 10162.5,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 10166.666666666668,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 10170.833333333334,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 10175,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 10179.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10183.333333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10187.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10191.666666666668,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 10195.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 10200,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 10204.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 10208.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 10212.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 10216.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 10220.833333333334,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 10225,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10229.166666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 10233.333333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 10237.5,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 10241.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10245.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10250,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10254.166666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10258.333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10262.5,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 10266.666666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 10270.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 10275,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 10279.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 10283.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 10287.5,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 10291.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 10295.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 10300,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 10304.166666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 10308.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 10316.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 10320.833333333334,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 10325,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 10329.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 10333.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 10337.5,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 10341.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 10345.833333333334,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 10350,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 10354.166666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 10358.333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 10362.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10366.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 10370.833333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 10375,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 10379.166666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 10383.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 10387.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 10391.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 10395.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 10400,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 10404.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 10662.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 10666.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10670.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10716.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 10720.833333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10725,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10783.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 10787.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 10791.666666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 10929.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 10933.333333333334,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 10937.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 10941.666666666668,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 10945.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 10950,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 10954.166666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 10958.333333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 10962.5,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 10966.666666666668,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 10970.833333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 10975,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 10979.166666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 10983.333333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 10987.5,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 10991.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 10995.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 11000,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 11004.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 11008.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11012.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11016.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11050,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11054.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11058.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11070.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11075,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11079.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11120.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11125,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11129.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11137.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11141.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11145.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11187.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11191.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11195.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11200,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 11204.166666666668,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 11208.333333333334,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 11212.5,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 11216.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 11220.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 11225,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 11229.166666666668,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 11233.333333333334,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 11237.5,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 11241.666666666668,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 11245.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 11250,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11254.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 11258.333333333334,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 11262.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 11266.666666666668,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 11270.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 11275,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 11279.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 11283.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 11287.5,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 11291.666666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11295.833333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 11300,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 11304.166666666668,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 11308.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11312.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11316.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11320.833333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11325,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11329.166666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 11333.333333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 11337.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 11341.666666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 11345.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 11350,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 11354.166666666668,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 11358.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 11362.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 11366.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 11370.833333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 11375,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 11383.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 11387.5,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 11391.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 11395.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 11400,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 11404.166666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 11408.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 11412.5,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 11416.666666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 11420.833333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 11425,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 11429.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11433.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 11437.5,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 11441.666666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 11445.833333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 11450,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 11454.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 11458.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 11462.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 11466.666666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 11470.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 11729.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 11733.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11737.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11783.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 11787.5,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11791.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11850,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 11854.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 11858.333333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 11995.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 12000,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 12004.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12008.333333333334,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 12012.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12016.666666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 12020.833333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 12025,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 12029.166666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 12033.333333333334,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 12037.5,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 12041.666666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 12045.833333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 12050,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 12054.166666666668,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 12058.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 12062.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 12066.666666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 12070.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 12075,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12079.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12083.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12116.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12120.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12125,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12137.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12141.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12145.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12187.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12191.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12195.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12204.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12208.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12212.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12254.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12258.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12262.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12266.666666666668,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 12270.833333333334,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 12275,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 12279.166666666668,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 12283.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 12287.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 12291.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 12295.833333333334,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 12300,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 12304.166666666668,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 12308.333333333334,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 12312.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 12316.666666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12320.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 12325,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 12329.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 12333.333333333334,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 12337.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 12341.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 12345.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 12350,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 12354.166666666668,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 12358.333333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12362.5,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 12366.666666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 12370.833333333334,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 12375,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12379.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12383.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12387.5,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12391.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12395.833333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 12400,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 12404.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 12408.333333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 12412.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 12416.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 12420.833333333334,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 12425,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 12429.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 12433.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 12437.5,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 12441.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 12450,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 12454.166666666668,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 12458.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 12462.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 12466.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 12470.833333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 12475,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 12479.166666666668,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 12483.333333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 12487.5,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 12491.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 12495.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12500,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 12504.166666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 12508.333333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 12512.5,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 12516.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 12520.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 12525,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 12529.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 12533.333333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 12537.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 12795.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 12800,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12804.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12850.000000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 12854.166666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12858.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12916.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 12920.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 12925.000000000002,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 13062.500000000002,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 13066.666666666668,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 13070.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13075.000000000002,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 13079.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13083.333333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 13087.500000000002,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 13091.666666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 13095.833333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 13100.000000000002,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 13104.166666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 13108.333333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 13112.500000000002,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 13116.666666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 13120.833333333334,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 13125.000000000002,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 13129.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 13133.333333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 13137.500000000002,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 13141.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13145.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13150.000000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13183.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13187.500000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13191.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13204.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13208.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13212.500000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13254.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13258.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13262.500000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13270.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13275.000000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13279.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13320.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13325.000000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13329.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13333.333333333334,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 13337.500000000002,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 13341.666666666668,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 13345.833333333334,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 13350.000000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 13354.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 13358.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 13362.500000000002,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 13366.666666666668,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 13370.833333333334,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 13375.000000000002,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 13379.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 13383.333333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13387.500000000002,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 13391.666666666668,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 13395.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 13400.000000000002,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 13404.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 13408.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 13412.500000000002,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 13416.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 13420.833333333334,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 13425.000000000002,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13429.166666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 13433.333333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 13437.500000000002,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 13441.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13445.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13450.000000000002,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13454.166666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13458.333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13462.500000000002,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 13466.666666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 13470.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 13475.000000000002,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 13479.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 13483.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 13487.500000000002,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 13491.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 13495.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 13500.000000000002,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 13504.166666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 13508.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 13516.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 13520.833333333334,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 13525.000000000002,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 13529.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 13533.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 13537.500000000002,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 13541.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 13545.833333333334,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 13550.000000000002,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 13554.166666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 13558.333333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 13562.500000000002,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13566.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 13570.833333333334,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 13575.000000000002,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 13579.166666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 13583.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 13587.500000000002,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 13591.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 13595.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 13600.000000000002,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 13604.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 13862.500000000002,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 13866.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13870.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13916.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 13920.833333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13925.000000000002,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13983.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 13987.500000000002,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 13991.666666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 14129.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 14133.333333333334,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 14137.500000000002,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14141.666666666668,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 14145.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14150.000000000002,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 14154.166666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 14158.333333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 14162.500000000002,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 14166.666666666668,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 14170.833333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 14175.000000000002,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 14179.166666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 14183.333333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 14187.500000000002,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 14191.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 14195.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 14200.000000000002,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 14204.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 14208.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14212.500000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14216.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14250.000000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14254.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14258.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14270.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14275.000000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14279.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14320.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14325.000000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14329.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14337.500000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14341.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14345.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14387.500000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14391.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14395.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14400.000000000002,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 14404.166666666668,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 14408.333333333334,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 14412.500000000002,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 14416.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 14420.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 14425.000000000002,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 14429.166666666668,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 14433.333333333334,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 14437.500000000002,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 14441.666666666668,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 14445.833333333334,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 14450.000000000002,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14454.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 14458.333333333334,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 14462.500000000002,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 14466.666666666668,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 14470.833333333334,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 14475.000000000002,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 14479.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 14483.333333333334,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 14487.500000000002,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 14491.666666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14495.833333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 14500.000000000002,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 14504.166666666668,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 14508.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14512.500000000002,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14516.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14520.833333333334,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14525.000000000002,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14529.166666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 14533.333333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 14537.500000000002,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 14541.666666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 14545.833333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 14550.000000000002,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 14554.166666666668,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 14558.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 14562.500000000002,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 14566.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 14570.833333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 14575.000000000002,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 14583.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 14587.500000000002,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 14591.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 14595.833333333334,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 14600.000000000002,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 14604.166666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 14608.333333333334,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 14612.500000000002,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 14616.666666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 14620.833333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 14625.000000000002,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 14629.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14633.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 14637.500000000002,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 14641.666666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 14645.833333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 14650.000000000002,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 14654.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 14658.333333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 14662.500000000002,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 14666.666666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 14670.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 14929.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 14933.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14937.500000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14983.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 14987.500000000002,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 14991.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15050.000000000002,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 15054.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 15058.333333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 15195.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 15200.000000000002,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 15204.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15208.333333333334,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 15212.500000000002,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15216.666666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 15220.833333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 15225.000000000002,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 15229.166666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 15233.333333333334,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 15237.500000000002,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 15241.666666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 15245.833333333334,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 15250.000000000002,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 15254.166666666668,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 15258.333333333334,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 15262.500000000002,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 15266.666666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 15270.833333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 15275.000000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15279.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15283.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15316.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15320.833333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15325.000000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15337.500000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15341.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15345.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15387.500000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15391.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15395.833333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15404.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15408.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15412.500000000002,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15454.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15458.333333333334,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15462.500000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 15466.666666666668,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 15470.833333333334,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 15475.000000000002,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 15479.166666666668,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 15483.333333333334,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 15487.500000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 15491.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 15495.833333333334,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 15500.000000000002,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 15504.166666666668,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 15508.333333333334,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 15512.500000000002,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 15516.666666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15520.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 15525.000000000002,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 15529.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 15533.333333333334,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 15537.500000000002,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 15541.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 15545.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 15550.000000000002,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 15554.166666666668,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 15558.333333333334,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15562.500000000002,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 15566.666666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 15570.833333333334,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 15575.000000000002,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15579.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15583.333333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15587.500000000002,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15591.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15595.833333333334,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 15600.000000000002,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 15604.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 15608.333333333334,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 15612.500000000002,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 15616.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 15620.833333333334,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 15625.000000000002,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 15629.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 15633.333333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 15637.500000000002,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 15641.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 15650.000000000002,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 15654.166666666668,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 15658.333333333334,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 15662.500000000002,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 15666.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 15670.833333333334,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 15675.000000000002,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 15679.166666666668,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 15683.333333333334,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 15687.500000000002,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 15691.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 15695.833333333334,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15700.000000000002,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 15704.166666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 15708.333333333334,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 15712.500000000002,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 15716.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 15720.833333333334,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 15725.000000000002,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 15729.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 15733.333333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 15737.500000000002,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 15995.833333333334,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 16000.000000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16004.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16050.000000000002,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16054.166666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16058.333333333334,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16116.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 16120.833333333334,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 16125.000000000002,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 16262.500000000002,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 16266.666666666668,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 16270.833333333334,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16275.000000000002,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 16279.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16283.333333333334,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 16287.500000000002,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 16291.666666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 16295.833333333334,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 16300.000000000002,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 16304.166666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 16308.333333333334,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 16312.500000000002,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 16316.666666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 16320.833333333334,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 16325.000000000002,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 16329.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 16333.333333333334,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 16337.500000000002,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 16341.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16345.833333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16350.000000000002,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16383.333333333334,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16387.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16391.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16404.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16408.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16412.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16454.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16458.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16462.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16470.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16475,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16479.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16520.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16525,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16529.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 16533.333333333336,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 16537.5,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 16541.666666666668,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 16545.833333333336,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 16550,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 16554.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 16558.333333333336,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 16562.5,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 16566.666666666668,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 16570.833333333336,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 16575,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 16579.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 16583.333333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16587.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 16591.666666666668,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 16595.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 16600,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 16604.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 16608.333333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 16612.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 16616.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 16620.833333333336,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 16625,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16629.166666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 16633.333333333336,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 16637.5,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 16641.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16645.833333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16650,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16654.166666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16658.333333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16662.5,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 16666.666666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 16670.833333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 16675,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 16679.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 16683.333333333336,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 16687.5,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 16691.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 16695.833333333336,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 16700,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 16704.166666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 16708.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 16716.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 16720.833333333336,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 16725,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 16729.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 16733.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 16737.5,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 16741.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 16745.833333333336,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 16750,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 16754.166666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 16758.333333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 16762.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16766.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 16770.833333333336,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 16775,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 16779.166666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 16783.333333333336,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 16787.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 16791.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 16795.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 16800,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 16804.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 17062.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 17066.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17070.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17116.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17120.833333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17125,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17183.333333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 17187.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 17191.666666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 17329.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 17333.333333333336,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 17337.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17341.666666666668,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 17345.833333333336,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17350,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 17354.166666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 17358.333333333336,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 17362.5,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 17366.666666666668,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 17370.833333333336,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 17375,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 17379.166666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 17383.333333333336,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 17387.5,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 17391.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 17395.833333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 17400,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 17404.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 17408.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17412.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17416.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17450,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17454.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17458.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17470.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17475,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17479.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17520.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17525,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17529.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17537.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17541.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17545.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17587.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17591.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17595.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 17600,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 17604.166666666668,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 17608.333333333336,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 17612.5,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 17616.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 17620.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 17625,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 17629.166666666668,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 17633.333333333336,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 17637.5,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 17641.666666666668,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 17645.833333333336,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 17650,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17654.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 17658.333333333336,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 17662.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 17666.666666666668,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 17670.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 17675,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 17679.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 17683.333333333336,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 17687.5,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 17691.666666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17695.833333333336,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 17700,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 17704.166666666668,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 17708.333333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17712.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17716.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17720.833333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17725,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17729.166666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 17733.333333333336,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 17737.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 17741.666666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 17745.833333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 17750,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 17754.166666666668,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 17758.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 17762.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 17766.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 17770.833333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 17775,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 17783.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 17787.5,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 17791.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 17795.833333333336,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 17800,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 17804.166666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 17808.333333333336,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 17812.5,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 17816.666666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 17820.833333333336,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 17825,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 17829.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17833.333333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 17837.5,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 17841.666666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 17845.833333333336,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 17850,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 17854.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 17858.333333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 17862.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 17866.666666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 17870.833333333336,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 18129.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 18133.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18137.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18183.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18187.5,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18191.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18250,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 18254.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 18258.333333333336,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 18395.833333333336,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 18400,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 18404.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18408.333333333336,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 18412.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18416.666666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 18420.833333333336,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 18425,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 18429.166666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 18433.333333333336,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 18437.5,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 18441.666666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 18445.833333333336,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 18450,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 18454.166666666668,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 18458.333333333336,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 18462.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 18466.666666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 18470.833333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 18475,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18479.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18483.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18516.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18520.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18525,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18537.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18541.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18545.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18587.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18591.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18595.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18604.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18608.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18612.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18654.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18658.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18662.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 18666.666666666668,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 18670.833333333336,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 18675,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 18679.166666666668,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 18683.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 18687.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 18691.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 18695.833333333336,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 18700,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 18704.166666666668,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 18708.333333333336,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 18712.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 18716.666666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18720.833333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 18725,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 18729.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 18733.333333333336,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 18737.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 18741.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 18745.833333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 18750,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 18754.166666666668,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 18758.333333333336,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18762.5,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 18766.666666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 18770.833333333336,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 18775,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18779.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18783.333333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18787.5,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18791.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18795.833333333336,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 18800,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 18804.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 18808.333333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 18812.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 18816.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 18820.833333333336,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 18825,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 18829.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 18833.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 18837.5,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 18841.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 18850,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 18854.166666666668,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 18858.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 18862.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 18866.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 18870.833333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 18875,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 18879.166666666668,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 18883.333333333336,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 18887.5,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 18891.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 18895.833333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18900,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 18904.166666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 18908.333333333336,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 18912.5,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 18916.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 18920.833333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 18925,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 18929.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 18933.333333333336,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 18937.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 19195.833333333336,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 19200,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19204.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19250,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19254.166666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19258.333333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19316.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 19320.833333333336,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 19325,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 19462.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 19466.666666666668,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 19470.833333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19475,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 19479.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19483.333333333336,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 19487.5,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 19491.666666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 19495.833333333336,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 19500,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 19504.166666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 19508.333333333336,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 19512.5,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 19516.666666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 19520.833333333336,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 19525,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 19529.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 19533.333333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 19537.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 19541.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19545.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19550,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19583.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19587.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19591.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19604.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19608.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19612.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19654.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19658.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19662.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19670.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19675,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19679.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19720.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19725,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19729.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 19733.333333333336,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 19737.5,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 19741.666666666668,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 19745.833333333336,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 19750,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 19754.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 19758.333333333336,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 19762.5,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 19766.666666666668,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 19770.833333333336,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 19775,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 19779.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 19783.333333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19787.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 19791.666666666668,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 19795.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 19800,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 19804.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 19808.333333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 19812.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 19816.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 19820.833333333336,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 19825,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19829.166666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 19833.333333333336,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 19837.5,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 19841.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19845.833333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19850,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19854.166666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19858.333333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19862.5,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 19866.666666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 19870.833333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 19875,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 19879.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 19883.333333333336,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 19887.5,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 19891.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 19895.833333333336,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 19900,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 19904.166666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 19908.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 19916.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 19920.833333333336,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 19925,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 19929.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 19933.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 19937.5,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 19941.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 19945.833333333336,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 19950,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 19954.166666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 19958.333333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 19962.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19966.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 19970.833333333336,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 19975,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 19979.166666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 19983.333333333336,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 19987.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 19991.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 19995.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 20000,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 20004.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 20262.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 20266.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20270.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20316.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20320.833333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20325,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20383.333333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 20387.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 20391.666666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 20529.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 20533.333333333336,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 20537.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20541.666666666668,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 20545.833333333336,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20550,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 20554.166666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 20558.333333333336,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 20562.5,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 20566.666666666668,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 20570.833333333336,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 20575,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 20579.166666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 20583.333333333336,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 20587.5,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 20591.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 20595.833333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 20600,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 20604.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 20608.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20612.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20616.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20650,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20654.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20658.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20670.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20675,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20679.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20720.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20725,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20729.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20737.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20741.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20745.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20787.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20791.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20795.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 20800,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 20804.166666666668,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 20808.333333333336,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 20812.5,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 20816.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 20820.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 20825,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 20829.166666666668,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 20833.333333333336,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 20837.5,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 20841.666666666668,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 20845.833333333336,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 20850,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20854.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 20858.333333333336,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 20862.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 20866.666666666668,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 20870.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 20875,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 20879.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 20883.333333333336,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 20887.5,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 20891.666666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 20895.833333333336,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 20900,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 20904.166666666668,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 20908.333333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20912.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20916.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 20920.833333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20925,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 20929.166666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 20933.333333333336,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 20937.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 20941.666666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 20945.833333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 20950,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 20954.166666666668,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 20958.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 20962.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 20966.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 20970.833333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 20975,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 20983.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 20987.5,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 20991.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 20995.833333333336,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 21000,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 21004.166666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 21008.333333333336,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 21012.5,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 21016.666666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 21020.833333333336,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 21025,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21029.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21033.333333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21037.5,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 21041.666666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 21045.833333333336,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21050,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 21054.166666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 21058.333333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 21062.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 21066.666666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 21070.833333333336,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 21329.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 21333.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21337.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21383.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21387.5,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21391.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21450,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 21454.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 21458.333333333336,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 21595.833333333336,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 21600,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 21604.166666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21608.333333333336,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 21612.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21616.666666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 21620.833333333336,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 21625,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 21629.166666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 21633.333333333336,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 21637.5,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 21641.666666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 21645.833333333336,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 21650,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 21654.166666666668,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 21658.333333333336,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 21662.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 21666.666666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 21670.833333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 21675,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21679.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21683.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21716.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21720.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21725,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21737.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21741.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21745.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21787.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21791.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21795.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21804.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21808.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21812.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21854.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21858.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21862.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 21866.666666666668,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 21870.833333333336,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 21875,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 21879.166666666668,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 21883.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 21887.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 21891.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 21895.833333333336,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 21900,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 21904.166666666668,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 21908.333333333336,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 21912.5,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 21916.666666666668,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21920.833333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 21925,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 21929.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 21933.333333333336,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 21937.5,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 21941.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 21945.833333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 21950,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 21954.166666666668,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 21958.333333333336,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 21962.5,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 21966.666666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 21970.833333333336,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 21975,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21979.166666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21983.333333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 21987.5,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21991.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 21995.833333333336,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 22000,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 22004.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 22008.333333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 22012.5,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 22016.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 22020.833333333336,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 22025,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 22029.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 22033.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 22037.5,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 22041.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 22050,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 22054.166666666668,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 22058.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 22062.5,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 22066.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 22070.833333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 22075,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 22079.166666666668,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 22083.333333333336,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 22087.5,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 22091.666666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 22095.833333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22100,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 22104.166666666668,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 22108.333333333336,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 22112.5,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 22116.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 22120.833333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 22125,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 22129.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 22133.333333333336,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 22137.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 22395.833333333336,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 22400,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22404.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22450,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22454.166666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22458.333333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22516.666666666668,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 22520.833333333336,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 22525,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 22662.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 22666.666666666668,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 22670.833333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22675,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 22679.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22683.333333333336,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 22687.5,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 22691.666666666668,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 22695.833333333336,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 22700,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 22704.166666666668,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 22708.333333333336,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 22712.5,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 22716.666666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 22720.833333333336,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 22725,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 22729.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 22733.333333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 22737.5,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 22741.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22745.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22750,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22783.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22787.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22791.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22804.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22808.333333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22812.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22854.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22858.333333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22862.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22870.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22875,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22879.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22920.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22925,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22929.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 22933.333333333336,
      y: 0.13001251220703125,
      __typename: 'Points',
    },
    {
      x: 22937.5,
      y: 0.125030517578125,
      __typename: 'Points',
    },
    {
      x: 22941.666666666668,
      y: 0.12004470825195312,
      __typename: 'Points',
    },
    {
      x: 22945.833333333336,
      y: 0.10509490966796875,
      __typename: 'Points',
    },
    {
      x: 22950,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 22954.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 22958.333333333336,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 22962.5,
      y: 0.06024169921875,
      __typename: 'Points',
    },
    {
      x: 22966.666666666668,
      y: 0.045291900634765625,
      __typename: 'Points',
    },
    {
      x: 22970.833333333336,
      y: 0.040309906005859375,
      __typename: 'Points',
    },
    {
      x: 22975,
      y: 0.025356292724609375,
      __typename: 'Points',
    },
    {
      x: 22979.166666666668,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 22983.333333333336,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22987.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 22991.666666666668,
      y: 0.00542449951171875,
      __typename: 'Points',
    },
    {
      x: 22995.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 23000,
      y: -0.00952911376953125,
      __typename: 'Points',
    },
    {
      x: 23004.166666666668,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 23008.333333333336,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 23012.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 23016.666666666668,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 23020.833333333336,
      y: -0.024478912353515625,
      __typename: 'Points',
    },
    {
      x: 23025,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23029.166666666668,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 23033.333333333336,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 23037.5,
      y: -0.04441070556640625,
      __typename: 'Points',
    },
    {
      x: 23041.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23045.833333333336,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23050,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23054.166666666668,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23058.333333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23062.5,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 23066.666666666668,
      y: -0.06932830810546875,
      __typename: 'Points',
    },
    {
      x: 23070.833333333336,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 23075,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 23079.166666666668,
      y: -0.074310302734375,
      __typename: 'Points',
    },
    {
      x: 23083.333333333336,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 23087.5,
      y: -0.089263916015625,
      __typename: 'Points',
    },
    {
      x: 23091.666666666668,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 23095.833333333336,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 23100,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 23104.166666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 23108.333333333336,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 23116.666666666668,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 23120.833333333336,
      y: -0.10919570922851562,
      __typename: 'Points',
    },
    {
      x: 23125,
      y: -0.10421371459960938,
      __typename: 'Points',
    },
    {
      x: 23129.166666666668,
      y: -0.09923171997070312,
      __typename: 'Points',
    },
    {
      x: 23133.333333333336,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 23137.5,
      y: -0.09424591064453125,
      __typename: 'Points',
    },
    {
      x: 23141.666666666668,
      y: -0.08427810668945312,
      __typename: 'Points',
    },
    {
      x: 23145.833333333336,
      y: -0.07929611206054688,
      __typename: 'Points',
    },
    {
      x: 23150,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 23154.166666666668,
      y: -0.0643463134765625,
      __typename: 'Points',
    },
    {
      x: 23158.333333333336,
      y: -0.05936431884765625,
      __typename: 'Points',
    },
    {
      x: 23162.5,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23166.666666666668,
      y: -0.049396514892578125,
      __typename: 'Points',
    },
    {
      x: 23170.833333333336,
      y: -0.0394287109375,
      __typename: 'Points',
    },
    {
      x: 23175,
      y: -0.034442901611328125,
      __typename: 'Points',
    },
    {
      x: 23179.166666666668,
      y: -0.029460906982421875,
      __typename: 'Points',
    },
    {
      x: 23183.333333333336,
      y: -0.01949310302734375,
      __typename: 'Points',
    },
    {
      x: 23187.5,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 23191.666666666668,
      y: -0.0145111083984375,
      __typename: 'Points',
    },
    {
      x: 23195.833333333336,
      y: -0.004543304443359375,
      __typename: 'Points',
    },
    {
      x: 23200,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 23204.166666666668,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 23462.5,
      y: 0.1349945068359375,
      __typename: 'Points',
    },
    {
      x: 23466.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23470.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23516.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23520.833333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23525,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23583.333333333336,
      y: 0.110076904296875,
      __typename: 'Points',
    },
    {
      x: 23587.5,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 23591.666666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 23729.166666666668,
      y: 0.11506271362304688,
      __typename: 'Points',
    },
    {
      x: 23733.333333333336,
      y: 0.0004425048828125,
      __typename: 'Points',
    },
    {
      x: 23737.5,
      y: 0.010406494140625,
      __typename: 'Points',
    },
    {
      x: 23741.666666666668,
      y: 0.015392303466796875,
      __typename: 'Points',
    },
    {
      x: 23745.833333333336,
      y: 0.020374298095703125,
      __typename: 'Points',
    },
    {
      x: 23750,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 23754.166666666668,
      y: 0.03034210205078125,
      __typename: 'Points',
    },
    {
      x: 23758.333333333336,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 23762.5,
      y: 0.0353240966796875,
      __typename: 'Points',
    },
    {
      x: 23766.666666666668,
      y: 0.050273895263671875,
      __typename: 'Points',
    },
    {
      x: 23770.833333333336,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 23775,
      y: 0.05525970458984375,
      __typename: 'Points',
    },
    {
      x: 23779.166666666668,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 23783.333333333336,
      y: 0.06522369384765625,
      __typename: 'Points',
    },
    {
      x: 23787.5,
      y: 0.07020950317382812,
      __typename: 'Points',
    },
    {
      x: 23791.666666666668,
      y: 0.0751953125,
      __typename: 'Points',
    },
    {
      x: 23795.833333333336,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 23800,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 23804.166666666668,
      y: 0.0851593017578125,
      __typename: 'Points',
    },
    {
      x: 23808.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23812.5,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23816.666666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23850,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23854.166666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23858.333333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23870.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23875,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23879.166666666668,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23920.833333333336,
      y: 0.09014511108398438,
      __typename: 'Points',
    },
    {
      x: 23925,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23929.166666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23937.5,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23941.666666666668,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23945.833333333336,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23987.5,
      y: 0.09512710571289062,
      __typename: 'Points',
    },
    {
      x: 23991.666666666668,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 23995.833333333336,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
    {
      x: 24000,
      y: 0.10010910034179688,
      __typename: 'Points',
    },
  ],
  avl: [
    {
      x: 0,
      y: 0.13997650146484375,
    },
    {
      x: 20.380434782608695,
      y: 0.13997650146484375,
    },
    {
      x: 24.456521739130437,
      y: 0.14496231079101562,
    },
    {
      x: 28.532608695652176,
      y: 0.15492630004882812,
    },
    {
      x: 32.608695652173914,
      y: 0.16489410400390625,
    },
    {
      x: 36.684782608695656,
      y: 0.1698760986328125,
    },
    {
      x: 40.76086956521739,
      y: 0.1698760986328125,
    },
    {
      x: 52.98913043478261,
      y: 0.1698760986328125,
    },
    {
      x: 57.06521739130435,
      y: 0.17486572265625,
    },
    {
      x: 61.141304347826086,
      y: 0.17486572265625,
    },
    {
      x: 65.21739130434783,
      y: 0.17984771728515625,
    },
    {
      x: 69.29347826086956,
      y: 0.17984771728515625,
    },
    {
      x: 81.52173913043478,
      y: 0.17984771728515625,
    },
    {
      x: 85.59782608695653,
      y: 0.18483352661132812,
    },
    {
      x: 89.67391304347827,
      y: 0.18483352661132812,
    },
    {
      x: 93.75,
      y: 0.18483352661132812,
    },
    {
      x: 97.82608695652175,
      y: 0.18981552124023438,
    },
    {
      x: 101.90217391304348,
      y: 0.18981552124023438,
    },
    {
      x: 105.97826086956522,
      y: 0.18981552124023438,
    },
    {
      x: 110.05434782608695,
      y: 0.18483352661132812,
    },
    {
      x: 114.1304347826087,
      y: 0.17984771728515625,
    },
    {
      x: 118.20652173913044,
      y: 0.17984771728515625,
    },
    {
      x: 122.28260869565217,
      y: 0.17486572265625,
    },
    {
      x: 126.35869565217392,
      y: 0.17486572265625,
    },
    {
      x: 130.43478260869566,
      y: 0.1698760986328125,
    },
    {
      x: 134.5108695652174,
      y: 0.1698760986328125,
    },
    {
      x: 138.58695652173913,
      y: 0.1698760986328125,
    },
    {
      x: 142.66304347826087,
      y: 0.16489410400390625,
    },
    {
      x: 146.73913043478262,
      y: 0.159912109375,
    },
    {
      x: 150.81521739130434,
      y: 0.15492630004882812,
    },
    {
      x: 154.8913043478261,
      y: 0.15492630004882812,
    },
    {
      x: 158.96739130434784,
      y: 0.15492630004882812,
    },
    {
      x: 163.04347826086956,
      y: 0.14994430541992188,
    },
    {
      x: 167.1195652173913,
      y: 0.14994430541992188,
    },
    {
      x: 171.19565217391306,
      y: 0.14496231079101562,
    },
    {
      x: 175.27173913043478,
      y: 0.14496231079101562,
    },
    {
      x: 256.79347826086956,
      y: 0.14496231079101562,
    },
    {
      x: 260.8695652173913,
      y: 0.03034210205078125,
    },
    {
      x: 264.94565217391306,
      y: 0.015392303466796875,
    },
    {
      x: 269.0217391304348,
      y: -0.004543304443359375,
    },
    {
      x: 273.0978260869565,
      y: -0.024478912353515625,
    },
    {
      x: 277.17391304347825,
      y: -0.034442901611328125,
    },
    {
      x: 281.25,
      y: -0.034442901611328125,
    },
    {
      x: 285.32608695652175,
      y: -0.01949310302734375,
    },
    {
      x: 289.4021739130435,
      y: 0.0004425048828125,
    },
    {
      x: 293.47826086956525,
      y: 0.015392303466796875,
    },
    {
      x: 297.55434782608694,
      y: 0.0353240966796875,
    },
    {
      x: 301.6304347826087,
      y: 0.050273895263671875,
    },
    {
      x: 305.70652173913044,
      y: 0.07020950317382812,
    },
    {
      x: 309.7826086956522,
      y: 0.0851593017578125,
    },
    {
      x: 313.85869565217394,
      y: 0.10509490966796875,
    },
    {
      x: 317.9347826086957,
      y: 0.125030517578125,
    },
    {
      x: 322.0108695652174,
      y: 0.1349945068359375,
    },
    {
      x: 326.0869565217391,
      y: 0.13997650146484375,
    },
    {
      x: 330.1630434782609,
      y: 0.13997650146484375,
    },
    {
      x: 517.6630434782609,
      y: 0.13997650146484375,
    },
    {
      x: 521.7391304347826,
      y: 0.14496231079101562,
    },
    {
      x: 525.8152173913044,
      y: 0.14496231079101562,
    },
    {
      x: 758.1521739130435,
      y: 0.14496231079101562,
    },
    {
      x: 762.2282608695652,
      y: 0.125030517578125,
    },
    {
      x: 766.304347826087,
      y: 0.10509490966796875,
    },
    {
      x: 774.4565217391305,
      y: 0.06522369384765625,
    },
    {
      x: 778.5326086956522,
      y: 0.050273895263671875,
    },
    {
      x: 782.608695652174,
      y: 0.14496231079101562,
    },
    {
      x: 786.6847826086956,
      y: 0.14496231079101562,
    },
    {
      x: 1039.4021739130435,
      y: 0.14496231079101562,
    },
    {
      x: 1043.4782608695652,
      y: 0.13997650146484375,
    },
    {
      x: 1047.554347826087,
      y: 0.13997650146484375,
    },
    {
      x: 1088.3152173913045,
      y: 0.13997650146484375,
    },
    {
      x: 1092.391304347826,
      y: 0.14496231079101562,
    },
    {
      x: 1096.4673913043478,
      y: 0.15492630004882812,
    },
    {
      x: 1100.5434782608695,
      y: 0.16489410400390625,
    },
    {
      x: 1104.6195652173913,
      y: 0.1698760986328125,
    },
    {
      x: 1108.695652173913,
      y: 0.1698760986328125,
    },
    {
      x: 1120.9239130434783,
      y: 0.1698760986328125,
    },
    {
      x: 1125,
      y: 0.17486572265625,
    },
    {
      x: 1129.0760869565217,
      y: 0.17486572265625,
    },
    {
      x: 1133.1521739130435,
      y: 0.17984771728515625,
    },
    {
      x: 1137.2282608695652,
      y: 0.17984771728515625,
    },
    {
      x: 1149.4565217391305,
      y: 0.17984771728515625,
    },
    {
      x: 1153.5326086956522,
      y: 0.18483352661132812,
    },
    {
      x: 1157.608695652174,
      y: 0.18483352661132812,
    },
    {
      x: 1161.6847826086957,
      y: 0.18483352661132812,
    },
    {
      x: 1165.7608695652175,
      y: 0.18981552124023438,
    },
    {
      x: 1169.8369565217392,
      y: 0.18981552124023438,
    },
    {
      x: 1173.913043478261,
      y: 0.18981552124023438,
    },
    {
      x: 1177.9891304347827,
      y: 0.18483352661132812,
    },
    {
      x: 1182.0652173913045,
      y: 0.17984771728515625,
    },
    {
      x: 1186.141304347826,
      y: 0.17984771728515625,
    },
    {
      x: 1190.2173913043478,
      y: 0.17486572265625,
    },
    {
      x: 1194.2934782608695,
      y: 0.17486572265625,
    },
    {
      x: 1198.3695652173913,
      y: 0.1698760986328125,
    },
    {
      x: 1202.445652173913,
      y: 0.1698760986328125,
    },
    {
      x: 1206.5217391304348,
      y: 0.1698760986328125,
    },
    {
      x: 1210.5978260869565,
      y: 0.16489410400390625,
    },
    {
      x: 1214.6739130434783,
      y: 0.159912109375,
    },
    {
      x: 1218.75,
      y: 0.15492630004882812,
    },
    {
      x: 1222.8260869565217,
      y: 0.15492630004882812,
    },
    {
      x: 1226.9021739130435,
      y: 0.15492630004882812,
    },
    {
      x: 1230.9782608695652,
      y: 0.14994430541992188,
    },
    {
      x: 1235.054347826087,
      y: 0.14994430541992188,
    },
    {
      x: 1239.1304347826087,
      y: 0.14496231079101562,
    },
    {
      x: 1243.2065217391305,
      y: 0.14496231079101562,
    },
    {
      x: 1304.3478260869565,
      y: 0.14496231079101562,
    },
    {
      x: 1308.4239130434783,
      y: 0.125030517578125,
    },
    {
      x: 1312.5,
      y: 0.10509490966796875,
    },
    {
      x: 1320.6521739130435,
      y: 0.06522369384765625,
    },
    {
      x: 1324.7282608695652,
      y: 0.050273895263671875,
    },
    {
      x: 1328.804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 1332.8804347826087,
      y: 0.015392303466796875,
    },
    {
      x: 1336.9565217391305,
      y: -0.004543304443359375,
    },
    {
      x: 1341.0326086956522,
      y: -0.024478912353515625,
    },
    {
      x: 1345.108695652174,
      y: -0.034442901611328125,
    },
    {
      x: 1349.1847826086957,
      y: -0.034442901611328125,
    },
    {
      x: 1353.2608695652175,
      y: -0.01949310302734375,
    },
    {
      x: 1357.3369565217392,
      y: 0.0004425048828125,
    },
    {
      x: 1361.413043478261,
      y: 0.015392303466796875,
    },
    {
      x: 1365.4891304347827,
      y: 0.0353240966796875,
    },
    {
      x: 1369.5652173913045,
      y: 0.050273895263671875,
    },
    {
      x: 1373.6413043478262,
      y: 0.07020950317382812,
    },
    {
      x: 1377.7173913043478,
      y: 0.0851593017578125,
    },
    {
      x: 1381.7934782608695,
      y: 0.10509490966796875,
    },
    {
      x: 1385.8695652173913,
      y: 0.125030517578125,
    },
    {
      x: 1389.945652173913,
      y: 0.1349945068359375,
    },
    {
      x: 1394.0217391304348,
      y: 0.13997650146484375,
    },
    {
      x: 1398.0978260869565,
      y: 0.13997650146484375,
    },
    {
      x: 1561.1413043478262,
      y: 0.13997650146484375,
    },
    {
      x: 1565.217391304348,
      y: 0.14496231079101562,
    },
    {
      x: 1569.2934782608695,
      y: 0.14496231079101562,
    },
    {
      x: 2082.880434782609,
      y: 0.14496231079101562,
    },
    {
      x: 2086.9565217391305,
      y: 0.13997650146484375,
    },
    {
      x: 2091.032608695652,
      y: 0.13997650146484375,
    },
    {
      x: 2156.25,
      y: 0.13997650146484375,
    },
    {
      x: 2160.326086956522,
      y: 0.14496231079101562,
    },
    {
      x: 2164.4021739130435,
      y: 0.15492630004882812,
    },
    {
      x: 2168.4782608695655,
      y: 0.16489410400390625,
    },
    {
      x: 2172.554347826087,
      y: 0.1698760986328125,
    },
    {
      x: 2176.630434782609,
      y: 0.1698760986328125,
    },
    {
      x: 2188.858695652174,
      y: 0.1698760986328125,
    },
    {
      x: 2192.9347826086955,
      y: 0.17486572265625,
    },
    {
      x: 2197.0108695652175,
      y: 0.17486572265625,
    },
    {
      x: 2201.086956521739,
      y: 0.17984771728515625,
    },
    {
      x: 2205.163043478261,
      y: 0.17984771728515625,
    },
    {
      x: 2217.391304347826,
      y: 0.17984771728515625,
    },
    {
      x: 2221.467391304348,
      y: 0.18483352661132812,
    },
    {
      x: 2225.5434782608695,
      y: 0.18483352661132812,
    },
    {
      x: 2229.6195652173915,
      y: 0.18483352661132812,
    },
    {
      x: 2233.695652173913,
      y: 0.18981552124023438,
    },
    {
      x: 2237.771739130435,
      y: 0.18981552124023438,
    },
    {
      x: 2241.8478260869565,
      y: 0.18981552124023438,
    },
    {
      x: 2245.9239130434785,
      y: 0.18483352661132812,
    },
    {
      x: 2250,
      y: 0.17984771728515625,
    },
    {
      x: 2254.076086956522,
      y: 0.17984771728515625,
    },
    {
      x: 2258.1521739130435,
      y: 0.17486572265625,
    },
    {
      x: 2262.2282608695655,
      y: 0.17486572265625,
    },
    {
      x: 2266.304347826087,
      y: 0.1698760986328125,
    },
    {
      x: 2270.380434782609,
      y: 0.1698760986328125,
    },
    {
      x: 2274.4565217391305,
      y: 0.1698760986328125,
    },
    {
      x: 2278.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 2282.608695652174,
      y: 0.159912109375,
    },
    {
      x: 2286.6847826086955,
      y: 0.15492630004882812,
    },
    {
      x: 2290.7608695652175,
      y: 0.15492630004882812,
    },
    {
      x: 2294.836956521739,
      y: 0.15492630004882812,
    },
    {
      x: 2298.913043478261,
      y: 0.14994430541992188,
    },
    {
      x: 2302.9891304347825,
      y: 0.14994430541992188,
    },
    {
      x: 2307.0652173913045,
      y: 0.14496231079101562,
    },
    {
      x: 2311.141304347826,
      y: 0.14496231079101562,
    },
    {
      x: 2372.282608695652,
      y: 0.14496231079101562,
    },
    {
      x: 2376.358695652174,
      y: 0.125030517578125,
    },
    {
      x: 2380.4347826086955,
      y: 0.10509490966796875,
    },
    {
      x: 2388.586956521739,
      y: 0.06522369384765625,
    },
    {
      x: 2392.663043478261,
      y: 0.050273895263671875,
    },
    {
      x: 2396.7391304347825,
      y: 0.03034210205078125,
    },
    {
      x: 2400.8152173913045,
      y: 0.015392303466796875,
    },
    {
      x: 2404.891304347826,
      y: -0.004543304443359375,
    },
    {
      x: 2408.967391304348,
      y: -0.024478912353515625,
    },
    {
      x: 2413.0434782608695,
      y: -0.034442901611328125,
    },
    {
      x: 2417.1195652173915,
      y: -0.034442901611328125,
    },
    {
      x: 2421.195652173913,
      y: -0.01949310302734375,
    },
    {
      x: 2425.271739130435,
      y: 0.0004425048828125,
    },
    {
      x: 2429.3478260869565,
      y: 0.015392303466796875,
    },
    {
      x: 2433.4239130434785,
      y: 0.0353240966796875,
    },
    {
      x: 2437.5,
      y: 0.050273895263671875,
    },
    {
      x: 2441.576086956522,
      y: 0.07020950317382812,
    },
    {
      x: 2445.6521739130435,
      y: 0.0851593017578125,
    },
    {
      x: 2449.7282608695655,
      y: 0.10509490966796875,
    },
    {
      x: 2453.804347826087,
      y: 0.125030517578125,
    },
    {
      x: 2457.880434782609,
      y: 0.1349945068359375,
    },
    {
      x: 2461.9565217391305,
      y: 0.13997650146484375,
    },
    {
      x: 2466.0326086956525,
      y: 0.13997650146484375,
    },
    {
      x: 2604.6195652173915,
      y: 0.13997650146484375,
    },
    {
      x: 2608.695652173913,
      y: 0.14496231079101562,
    },
    {
      x: 2612.771739130435,
      y: 0.14496231079101562,
    },
    {
      x: 3179.3478260869565,
      y: 0.14496231079101562,
    },
    {
      x: 3183.4239130434785,
      y: 0.125030517578125,
    },
    {
      x: 3187.5,
      y: 0.10509490966796875,
    },
    {
      x: 3195.6521739130435,
      y: 0.06522369384765625,
    },
    {
      x: 3199.7282608695655,
      y: 0.050273895263671875,
    },
    {
      x: 3203.804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 3207.880434782609,
      y: 0.015392303466796875,
    },
    {
      x: 3211.9565217391305,
      y: -0.004543304443359375,
    },
    {
      x: 3216.0326086956525,
      y: -0.024478912353515625,
    },
    {
      x: 3220.108695652174,
      y: -0.034442901611328125,
    },
    {
      x: 3224.184782608696,
      y: -0.034442901611328125,
    },
    {
      x: 3228.2608695652175,
      y: -0.01949310302734375,
    },
    {
      x: 3232.336956521739,
      y: 0.0004425048828125,
    },
    {
      x: 3236.413043478261,
      y: 0.015392303466796875,
    },
    {
      x: 3240.4891304347825,
      y: 0.0353240966796875,
    },
    {
      x: 3244.5652173913045,
      y: 0.050273895263671875,
    },
    {
      x: 3248.641304347826,
      y: 0.07020950317382812,
    },
    {
      x: 3252.717391304348,
      y: 0.0851593017578125,
    },
    {
      x: 3256.7934782608695,
      y: 0.10509490966796875,
    },
    {
      x: 3260.8695652173915,
      y: 0.125030517578125,
    },
    {
      x: 3264.945652173913,
      y: 0.1349945068359375,
    },
    {
      x: 3269.021739130435,
      y: 0.13997650146484375,
    },
    {
      x: 3273.0978260869565,
      y: 0.13997650146484375,
    },
    {
      x: 3485.054347826087,
      y: 0.13997650146484375,
    },
    {
      x: 3489.130434782609,
      y: 0.14496231079101562,
    },
    {
      x: 3493.2065217391305,
      y: 0.15492630004882812,
    },
    {
      x: 3497.2826086956525,
      y: 0.16489410400390625,
    },
    {
      x: 3501.358695652174,
      y: 0.1698760986328125,
    },
    {
      x: 3505.434782608696,
      y: 0.1698760986328125,
    },
    {
      x: 3517.663043478261,
      y: 0.1698760986328125,
    },
    {
      x: 3521.7391304347825,
      y: 0.17486572265625,
    },
    {
      x: 3525.8152173913045,
      y: 0.17486572265625,
    },
    {
      x: 3529.891304347826,
      y: 0.17984771728515625,
    },
    {
      x: 3533.967391304348,
      y: 0.17984771728515625,
    },
    {
      x: 3546.195652173913,
      y: 0.17984771728515625,
    },
    {
      x: 3550.271739130435,
      y: 0.18483352661132812,
    },
    {
      x: 3554.3478260869565,
      y: 0.18483352661132812,
    },
    {
      x: 3558.4239130434785,
      y: 0.18483352661132812,
    },
    {
      x: 3562.5,
      y: 0.18981552124023438,
    },
    {
      x: 3566.576086956522,
      y: 0.18981552124023438,
    },
    {
      x: 3570.6521739130435,
      y: 0.18981552124023438,
    },
    {
      x: 3574.7282608695655,
      y: 0.18483352661132812,
    },
    {
      x: 3578.804347826087,
      y: 0.17984771728515625,
    },
    {
      x: 3582.880434782609,
      y: 0.17984771728515625,
    },
    {
      x: 3586.9565217391305,
      y: 0.17486572265625,
    },
    {
      x: 3591.0326086956525,
      y: 0.17486572265625,
    },
    {
      x: 3595.108695652174,
      y: 0.1698760986328125,
    },
    {
      x: 3599.184782608696,
      y: 0.1698760986328125,
    },
    {
      x: 3603.2608695652175,
      y: 0.1698760986328125,
    },
    {
      x: 3607.336956521739,
      y: 0.16489410400390625,
    },
    {
      x: 3611.413043478261,
      y: 0.159912109375,
    },
    {
      x: 3615.4891304347825,
      y: 0.15492630004882812,
    },
    {
      x: 3619.5652173913045,
      y: 0.15492630004882812,
    },
    {
      x: 3623.641304347826,
      y: 0.15492630004882812,
    },
    {
      x: 3627.717391304348,
      y: 0.14994430541992188,
    },
    {
      x: 3631.7934782608695,
      y: 0.14994430541992188,
    },
    {
      x: 3635.8695652173915,
      y: 0.14496231079101562,
    },
    {
      x: 3639.945652173913,
      y: 0.14496231079101562,
    },
    {
      x: 3648.0978260869565,
      y: 0.14496231079101562,
    },
    {
      x: 3652.1739130434785,
      y: 0.14994430541992188,
    },
    {
      x: 3656.25,
      y: 0.14994430541992188,
    },
    {
      x: 3660.326086956522,
      y: 0.14496231079101562,
    },
    {
      x: 3664.4021739130435,
      y: 0.14496231079101562,
    },
    {
      x: 4169.836956521739,
      y: 0.14496231079101562,
    },
    {
      x: 4173.913043478261,
      y: 0.13997650146484375,
    },
    {
      x: 4177.989130434783,
      y: 0.13997650146484375,
    },
    {
      x: 4292.119565217391,
      y: 0.13997650146484375,
    },
    {
      x: 4296.195652173913,
      y: 0.14496231079101562,
    },
    {
      x: 4300.271739130435,
      y: 0.15492630004882812,
    },
    {
      x: 4304.347826086957,
      y: 0.16489410400390625,
    },
    {
      x: 4308.423913043478,
      y: 0.1698760986328125,
    },
    {
      x: 4312.5,
      y: 0.1698760986328125,
    },
    {
      x: 4324.728260869565,
      y: 0.1698760986328125,
    },
    {
      x: 4328.804347826087,
      y: 0.17486572265625,
    },
    {
      x: 4332.880434782609,
      y: 0.17486572265625,
    },
    {
      x: 4336.956521739131,
      y: 0.17984771728515625,
    },
    {
      x: 4341.032608695652,
      y: 0.17984771728515625,
    },
    {
      x: 4353.260869565218,
      y: 0.17984771728515625,
    },
    {
      x: 4357.336956521739,
      y: 0.18483352661132812,
    },
    {
      x: 4361.413043478261,
      y: 0.18483352661132812,
    },
    {
      x: 4365.489130434783,
      y: 0.18483352661132812,
    },
    {
      x: 4369.565217391304,
      y: 0.18981552124023438,
    },
    {
      x: 4373.641304347826,
      y: 0.18981552124023438,
    },
    {
      x: 4377.717391304348,
      y: 0.18981552124023438,
    },
    {
      x: 4381.79347826087,
      y: 0.18483352661132812,
    },
    {
      x: 4385.869565217391,
      y: 0.17984771728515625,
    },
    {
      x: 4389.945652173913,
      y: 0.17984771728515625,
    },
    {
      x: 4394.021739130435,
      y: 0.17486572265625,
    },
    {
      x: 4398.097826086957,
      y: 0.17486572265625,
    },
    {
      x: 4402.173913043478,
      y: 0.1698760986328125,
    },
    {
      x: 4406.25,
      y: 0.1698760986328125,
    },
    {
      x: 4410.326086956522,
      y: 0.1698760986328125,
    },
    {
      x: 4414.402173913044,
      y: 0.16489410400390625,
    },
    {
      x: 4418.478260869565,
      y: 0.159912109375,
    },
    {
      x: 4422.554347826087,
      y: 0.15492630004882812,
    },
    {
      x: 4426.630434782609,
      y: 0.15492630004882812,
    },
    {
      x: 4430.706521739131,
      y: 0.15492630004882812,
    },
    {
      x: 4434.782608695652,
      y: 0.14496231079101562,
    },
    {
      x: 4438.858695652174,
      y: 0.14496231079101562,
    },
    {
      x: 4508.152173913044,
      y: 0.14496231079101562,
    },
    {
      x: 4512.228260869565,
      y: 0.125030517578125,
    },
    {
      x: 4516.304347826087,
      y: 0.10509490966796875,
    },
    {
      x: 4524.456521739131,
      y: 0.06522369384765625,
    },
    {
      x: 4528.532608695652,
      y: 0.050273895263671875,
    },
    {
      x: 4532.608695652174,
      y: 0.03034210205078125,
    },
    {
      x: 4536.684782608696,
      y: 0.015392303466796875,
    },
    {
      x: 4540.760869565218,
      y: -0.004543304443359375,
    },
    {
      x: 4544.836956521739,
      y: -0.024478912353515625,
    },
    {
      x: 4548.913043478261,
      y: -0.034442901611328125,
    },
    {
      x: 4552.989130434783,
      y: -0.034442901611328125,
    },
    {
      x: 4557.065217391304,
      y: -0.01949310302734375,
    },
    {
      x: 4561.141304347826,
      y: 0.0004425048828125,
    },
    {
      x: 4565.217391304348,
      y: 0.015392303466796875,
    },
    {
      x: 4569.29347826087,
      y: 0.0353240966796875,
    },
    {
      x: 4573.369565217391,
      y: 0.050273895263671875,
    },
    {
      x: 4577.445652173913,
      y: 0.07020950317382812,
    },
    {
      x: 4581.521739130435,
      y: 0.0851593017578125,
    },
    {
      x: 4585.597826086957,
      y: 0.10509490966796875,
    },
    {
      x: 4589.673913043478,
      y: 0.125030517578125,
    },
    {
      x: 4593.75,
      y: 0.1349945068359375,
    },
    {
      x: 4597.826086956522,
      y: 0.13997650146484375,
    },
    {
      x: 4601.902173913044,
      y: 0.13997650146484375,
    },
    {
      x: 4691.576086956522,
      y: 0.13997650146484375,
    },
    {
      x: 4695.652173913044,
      y: 0.14496231079101562,
    },
    {
      x: 4699.728260869565,
      y: 0.14496231079101562,
    },
    {
      x: 4952.445652173913,
      y: 0.14496231079101562,
    },
    {
      x: 4956.521739130435,
      y: 0.1698760986328125,
    },
    {
      x: 4960.597826086957,
      y: 0.16489410400390625,
    },
    {
      x: 4964.673913043478,
      y: 0.159912109375,
    },
    {
      x: 4968.75,
      y: 0.15492630004882812,
    },
    {
      x: 4972.826086956522,
      y: 0.15492630004882812,
    },
    {
      x: 4976.902173913044,
      y: 0.15492630004882812,
    },
    {
      x: 4980.978260869565,
      y: 0.14994430541992188,
    },
    {
      x: 4985.054347826087,
      y: 0.14994430541992188,
    },
    {
      x: 4989.130434782609,
      y: 0.14496231079101562,
    },
    {
      x: 4993.206521739131,
      y: 0.14496231079101562,
    },
    {
      x: 5213.315217391305,
      y: 0.14496231079101562,
    },
    {
      x: 5217.391304347826,
      y: 0.13997650146484375,
    },
    {
      x: 5221.467391304348,
      y: 0.13997650146484375,
    },
    {
      x: 5360.054347826087,
      y: 0.13997650146484375,
    },
    {
      x: 5364.130434782609,
      y: 0.14496231079101562,
    },
    {
      x: 5368.206521739131,
      y: 0.15492630004882812,
    },
    {
      x: 5372.282608695652,
      y: 0.16489410400390625,
    },
    {
      x: 5376.358695652174,
      y: 0.1698760986328125,
    },
    {
      x: 5380.434782608696,
      y: 0.1698760986328125,
    },
    {
      x: 5392.663043478261,
      y: 0.1698760986328125,
    },
    {
      x: 5396.739130434783,
      y: 0.17486572265625,
    },
    {
      x: 5400.815217391305,
      y: 0.17486572265625,
    },
    {
      x: 5404.891304347826,
      y: 0.17984771728515625,
    },
    {
      x: 5408.967391304348,
      y: 0.17984771728515625,
    },
    {
      x: 5421.195652173913,
      y: 0.17984771728515625,
    },
    {
      x: 5425.271739130435,
      y: 0.18483352661132812,
    },
    {
      x: 5429.347826086957,
      y: 0.18483352661132812,
    },
    {
      x: 5433.423913043478,
      y: 0.18483352661132812,
    },
    {
      x: 5437.5,
      y: 0.18981552124023438,
    },
    {
      x: 5441.576086956522,
      y: 0.18981552124023438,
    },
    {
      x: 5445.652173913044,
      y: 0.18981552124023438,
    },
    {
      x: 5449.728260869565,
      y: 0.18483352661132812,
    },
    {
      x: 5453.804347826087,
      y: 0.17984771728515625,
    },
    {
      x: 5457.880434782609,
      y: 0.17984771728515625,
    },
    {
      x: 5461.956521739131,
      y: 0.17486572265625,
    },
    {
      x: 5466.032608695652,
      y: 0.17486572265625,
    },
    {
      x: 5470.108695652174,
      y: 0.1698760986328125,
    },
    {
      x: 5474.184782608696,
      y: 0.1698760986328125,
    },
    {
      x: 5478.260869565218,
      y: 0.14496231079101562,
    },
    {
      x: 5482.336956521739,
      y: 0.14496231079101562,
    },
    {
      x: 5836.956521739131,
      y: 0.14496231079101562,
    },
    {
      x: 5841.032608695652,
      y: 0.125030517578125,
    },
    {
      x: 5845.108695652174,
      y: 0.10509490966796875,
    },
    {
      x: 5853.260869565218,
      y: 0.06522369384765625,
    },
    {
      x: 5857.336956521739,
      y: 0.050273895263671875,
    },
    {
      x: 5861.413043478261,
      y: 0.03034210205078125,
    },
    {
      x: 5865.489130434783,
      y: 0.015392303466796875,
    },
    {
      x: 5869.565217391305,
      y: -0.004543304443359375,
    },
    {
      x: 5873.641304347826,
      y: -0.024478912353515625,
    },
    {
      x: 5877.717391304348,
      y: -0.034442901611328125,
    },
    {
      x: 5881.79347826087,
      y: -0.034442901611328125,
    },
    {
      x: 5885.869565217391,
      y: -0.01949310302734375,
    },
    {
      x: 5889.945652173913,
      y: 0.0004425048828125,
    },
    {
      x: 5894.021739130435,
      y: 0.015392303466796875,
    },
    {
      x: 5898.097826086957,
      y: 0.0353240966796875,
    },
    {
      x: 5902.173913043478,
      y: 0.050273895263671875,
    },
    {
      x: 5906.25,
      y: 0.07020950317382812,
    },
    {
      x: 5910.326086956522,
      y: 0.0851593017578125,
    },
    {
      x: 5914.402173913044,
      y: 0.10509490966796875,
    },
    {
      x: 5918.478260869565,
      y: 0.125030517578125,
    },
    {
      x: 5922.554347826087,
      y: 0.1349945068359375,
    },
    {
      x: 5926.630434782609,
      y: 0.13997650146484375,
    },
    {
      x: 5930.706521739131,
      y: 0.13997650146484375,
    },
    {
      x: 5995.923913043478,
      y: 0.13997650146484375,
    },
    {
      x: 6000,
      y: 0.17984771728515625,
    },
    {
      x: 6004.076086956522,
      y: 0.17984771728515625,
    },
    {
      x: 6008.152173913044,
      y: 0.17486572265625,
    },
    {
      x: 6012.228260869565,
      y: 0.17486572265625,
    },
    {
      x: 6016.304347826087,
      y: 0.1698760986328125,
    },
    {
      x: 6020.380434782609,
      y: 0.1698760986328125,
    },
    {
      x: 6024.456521739131,
      y: 0.1698760986328125,
    },
    {
      x: 6028.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 6032.608695652174,
      y: 0.159912109375,
    },
    {
      x: 6036.684782608696,
      y: 0.15492630004882812,
    },
    {
      x: 6040.760869565218,
      y: 0.15492630004882812,
    },
    {
      x: 6044.836956521739,
      y: 0.15492630004882812,
    },
    {
      x: 6048.913043478261,
      y: 0.14994430541992188,
    },
    {
      x: 6052.989130434783,
      y: 0.14994430541992188,
    },
    {
      x: 6057.065217391305,
      y: 0.14496231079101562,
    },
    {
      x: 6061.141304347826,
      y: 0.14496231079101562,
    },
    {
      x: 6383.152173913044,
      y: 0.14496231079101562,
    },
    {
      x: 6387.228260869565,
      y: 0.125030517578125,
    },
    {
      x: 6391.304347826087,
      y: 0.10509490966796875,
    },
    {
      x: 6399.456521739131,
      y: 0.06522369384765625,
    },
    {
      x: 6403.532608695652,
      y: 0.050273895263671875,
    },
    {
      x: 6407.608695652174,
      y: 0.03034210205078125,
    },
    {
      x: 6411.684782608696,
      y: 0.015392303466796875,
    },
    {
      x: 6415.760869565218,
      y: -0.004543304443359375,
    },
    {
      x: 6419.836956521739,
      y: -0.024478912353515625,
    },
    {
      x: 6423.913043478261,
      y: -0.034442901611328125,
    },
    {
      x: 6427.989130434783,
      y: -0.034442901611328125,
    },
    {
      x: 6432.065217391305,
      y: -0.01949310302734375,
    },
    {
      x: 6436.141304347826,
      y: 0.0004425048828125,
    },
    {
      x: 6440.217391304348,
      y: 0.015392303466796875,
    },
    {
      x: 6444.29347826087,
      y: 0.0353240966796875,
    },
    {
      x: 6448.369565217392,
      y: 0.050273895263671875,
    },
    {
      x: 6452.445652173913,
      y: 0.07020950317382812,
    },
    {
      x: 6456.521739130435,
      y: 0.0851593017578125,
    },
    {
      x: 6460.597826086957,
      y: 0.10509490966796875,
    },
    {
      x: 6464.673913043478,
      y: 0.125030517578125,
    },
    {
      x: 6468.75,
      y: 0.1349945068359375,
    },
    {
      x: 6472.826086956522,
      y: 0.13997650146484375,
    },
    {
      x: 6476.902173913044,
      y: 0.13997650146484375,
    },
    {
      x: 6688.858695652174,
      y: 0.13997650146484375,
    },
    {
      x: 6692.934782608696,
      y: 0.14496231079101562,
    },
    {
      x: 6697.010869565218,
      y: 0.15492630004882812,
    },
    {
      x: 6701.086956521739,
      y: 0.16489410400390625,
    },
    {
      x: 6705.163043478261,
      y: 0.1698760986328125,
    },
    {
      x: 6709.239130434783,
      y: 0.1698760986328125,
    },
    {
      x: 6721.467391304348,
      y: 0.1698760986328125,
    },
    {
      x: 6725.54347826087,
      y: 0.17486572265625,
    },
    {
      x: 6729.619565217392,
      y: 0.17486572265625,
    },
    {
      x: 6733.695652173913,
      y: 0.17984771728515625,
    },
    {
      x: 6737.771739130435,
      y: 0.17984771728515625,
    },
    {
      x: 6750,
      y: 0.17984771728515625,
    },
    {
      x: 6754.076086956522,
      y: 0.18483352661132812,
    },
    {
      x: 6758.152173913044,
      y: 0.18483352661132812,
    },
    {
      x: 6762.228260869565,
      y: 0.18483352661132812,
    },
    {
      x: 6766.304347826087,
      y: 0.18981552124023438,
    },
    {
      x: 6770.380434782609,
      y: 0.18981552124023438,
    },
    {
      x: 6774.456521739131,
      y: 0.18981552124023438,
    },
    {
      x: 6778.532608695652,
      y: 0.18483352661132812,
    },
    {
      x: 6782.608695652174,
      y: 0.14496231079101562,
    },
    {
      x: 6786.684782608696,
      y: 0.14496231079101562,
    },
    {
      x: 7039.402173913044,
      y: 0.14496231079101562,
    },
    {
      x: 7043.478260869565,
      y: 0.18483352661132812,
    },
    {
      x: 7047.554347826087,
      y: 0.18483352661132812,
    },
    {
      x: 7051.630434782609,
      y: 0.18981552124023438,
    },
    {
      x: 7055.706521739131,
      y: 0.18981552124023438,
    },
    {
      x: 7059.782608695652,
      y: 0.18981552124023438,
    },
    {
      x: 7063.858695652174,
      y: 0.18483352661132812,
    },
    {
      x: 7067.934782608696,
      y: 0.17984771728515625,
    },
    {
      x: 7072.010869565218,
      y: 0.17984771728515625,
    },
    {
      x: 7076.086956521739,
      y: 0.17486572265625,
    },
    {
      x: 7080.163043478261,
      y: 0.17486572265625,
    },
    {
      x: 7084.239130434783,
      y: 0.1698760986328125,
    },
    {
      x: 7088.315217391305,
      y: 0.1698760986328125,
    },
    {
      x: 7092.391304347826,
      y: 0.1698760986328125,
    },
    {
      x: 7096.467391304348,
      y: 0.16489410400390625,
    },
    {
      x: 7100.54347826087,
      y: 0.159912109375,
    },
    {
      x: 7104.619565217392,
      y: 0.15492630004882812,
    },
    {
      x: 7108.695652173913,
      y: 0.15492630004882812,
    },
    {
      x: 7112.771739130435,
      y: 0.15492630004882812,
    },
    {
      x: 7116.847826086957,
      y: 0.14994430541992188,
    },
    {
      x: 7120.923913043478,
      y: 0.14994430541992188,
    },
    {
      x: 7125,
      y: 0.14496231079101562,
    },
    {
      x: 7129.076086956522,
      y: 0.14496231079101562,
    },
    {
      x: 7300.271739130435,
      y: 0.14496231079101562,
    },
    {
      x: 7304.347826086957,
      y: 0.13997650146484375,
    },
    {
      x: 7308.423913043478,
      y: 0.13997650146484375,
    },
    {
      x: 7495.923913043478,
      y: 0.13997650146484375,
    },
    {
      x: 7500,
      y: 0.14496231079101562,
    },
    {
      x: 7504.076086956522,
      y: 0.15492630004882812,
    },
    {
      x: 7508.152173913044,
      y: 0.16489410400390625,
    },
    {
      x: 7512.228260869565,
      y: 0.1698760986328125,
    },
    {
      x: 7516.304347826087,
      y: 0.1698760986328125,
    },
    {
      x: 7528.532608695652,
      y: 0.1698760986328125,
    },
    {
      x: 7532.608695652174,
      y: 0.17486572265625,
    },
    {
      x: 7536.684782608696,
      y: 0.17486572265625,
    },
    {
      x: 7540.760869565218,
      y: 0.17984771728515625,
    },
    {
      x: 7544.836956521739,
      y: 0.17984771728515625,
    },
    {
      x: 7557.065217391305,
      y: 0.17984771728515625,
    },
    {
      x: 7561.141304347826,
      y: 0.18483352661132812,
    },
    {
      x: 7565.217391304348,
      y: 0.17984771728515625,
    },
    {
      x: 7569.29347826087,
      y: 0.17984771728515625,
    },
    {
      x: 7581.521739130435,
      y: 0.17984771728515625,
    },
    {
      x: 7585.597826086957,
      y: 0.18483352661132812,
    },
    {
      x: 7589.673913043478,
      y: 0.18483352661132812,
    },
    {
      x: 7593.75,
      y: 0.18483352661132812,
    },
    {
      x: 7597.826086956522,
      y: 0.18981552124023438,
    },
    {
      x: 7601.902173913044,
      y: 0.18981552124023438,
    },
    {
      x: 7605.978260869565,
      y: 0.18981552124023438,
    },
    {
      x: 7610.054347826087,
      y: 0.18483352661132812,
    },
    {
      x: 7614.130434782609,
      y: 0.17984771728515625,
    },
    {
      x: 7618.206521739131,
      y: 0.17984771728515625,
    },
    {
      x: 7622.282608695652,
      y: 0.17486572265625,
    },
    {
      x: 7626.358695652174,
      y: 0.17486572265625,
    },
    {
      x: 7630.434782608696,
      y: 0.1698760986328125,
    },
    {
      x: 7634.510869565218,
      y: 0.1698760986328125,
    },
    {
      x: 7638.586956521739,
      y: 0.1698760986328125,
    },
    {
      x: 7642.663043478261,
      y: 0.16489410400390625,
    },
    {
      x: 7646.739130434783,
      y: 0.159912109375,
    },
    {
      x: 7650.815217391305,
      y: 0.15492630004882812,
    },
    {
      x: 7654.891304347826,
      y: 0.15492630004882812,
    },
    {
      x: 7658.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 7663.04347826087,
      y: 0.14994430541992188,
    },
    {
      x: 7667.119565217392,
      y: 0.14994430541992188,
    },
    {
      x: 7671.195652173913,
      y: 0.14496231079101562,
    },
    {
      x: 7675.271739130435,
      y: 0.14496231079101562,
    },
    {
      x: 7972.826086956522,
      y: 0.14496231079101562,
    },
    {
      x: 7976.902173913044,
      y: 0.125030517578125,
    },
    {
      x: 7980.978260869565,
      y: 0.10509490966796875,
    },
    {
      x: 7989.130434782609,
      y: 0.06522369384765625,
    },
    {
      x: 7993.206521739131,
      y: 0.050273895263671875,
    },
    {
      x: 7997.282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 8001.358695652174,
      y: 0.015392303466796875,
    },
    {
      x: 8005.434782608696,
      y: -0.004543304443359375,
    },
    {
      x: 8009.510869565218,
      y: -0.024478912353515625,
    },
    {
      x: 8013.586956521739,
      y: -0.034442901611328125,
    },
    {
      x: 8017.663043478261,
      y: -0.034442901611328125,
    },
    {
      x: 8021.739130434783,
      y: -0.01949310302734375,
    },
    {
      x: 8025.815217391305,
      y: 0.0004425048828125,
    },
    {
      x: 8029.891304347826,
      y: 0.015392303466796875,
    },
    {
      x: 8033.967391304348,
      y: 0.0353240966796875,
    },
    {
      x: 8038.04347826087,
      y: 0.050273895263671875,
    },
    {
      x: 8042.119565217392,
      y: 0.07020950317382812,
    },
    {
      x: 8046.195652173913,
      y: 0.0851593017578125,
    },
    {
      x: 8050.271739130435,
      y: 0.10509490966796875,
    },
    {
      x: 8054.347826086957,
      y: 0.125030517578125,
    },
    {
      x: 8058.423913043479,
      y: 0.1349945068359375,
    },
    {
      x: 8062.5,
      y: 0.13997650146484375,
    },
    {
      x: 8066.576086956522,
      y: 0.13997650146484375,
    },
    {
      x: 8082.880434782609,
      y: 0.13997650146484375,
    },
    {
      x: 8086.956521739131,
      y: 0.14496231079101562,
    },
    {
      x: 8091.032608695652,
      y: 0.14496231079101562,
    },
    {
      x: 8343.75,
      y: 0.14496231079101562,
    },
    {
      x: 8347.826086956522,
      y: 0.13997650146484375,
    },
    {
      x: 8351.902173913044,
      y: 0.13997650146484375,
    },
    {
      x: 8563.858695652174,
      y: 0.13997650146484375,
    },
    {
      x: 8567.934782608696,
      y: 0.14496231079101562,
    },
    {
      x: 8572.010869565218,
      y: 0.15492630004882812,
    },
    {
      x: 8576.08695652174,
      y: 0.16489410400390625,
    },
    {
      x: 8580.163043478262,
      y: 0.1698760986328125,
    },
    {
      x: 8584.239130434782,
      y: 0.1698760986328125,
    },
    {
      x: 8596.467391304348,
      y: 0.1698760986328125,
    },
    {
      x: 8600.54347826087,
      y: 0.17486572265625,
    },
    {
      x: 8604.619565217392,
      y: 0.17486572265625,
    },
    {
      x: 8608.695652173914,
      y: 0.14496231079101562,
    },
    {
      x: 8612.771739130436,
      y: 0.14496231079101562,
    },
    {
      x: 8779.891304347826,
      y: 0.14496231079101562,
    },
    {
      x: 8783.967391304348,
      y: 0.125030517578125,
    },
    {
      x: 8788.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 8796.195652173914,
      y: 0.06522369384765625,
    },
    {
      x: 8800.271739130436,
      y: 0.050273895263671875,
    },
    {
      x: 8804.347826086956,
      y: 0.03034210205078125,
    },
    {
      x: 8808.423913043478,
      y: 0.015392303466796875,
    },
    {
      x: 8812.5,
      y: -0.004543304443359375,
    },
    {
      x: 8816.576086956522,
      y: -0.024478912353515625,
    },
    {
      x: 8820.652173913044,
      y: -0.034442901611328125,
    },
    {
      x: 8824.728260869566,
      y: -0.034442901611328125,
    },
    {
      x: 8828.804347826088,
      y: -0.01949310302734375,
    },
    {
      x: 8832.880434782608,
      y: 0.0004425048828125,
    },
    {
      x: 8836.95652173913,
      y: 0.015392303466796875,
    },
    {
      x: 8841.032608695652,
      y: 0.0353240966796875,
    },
    {
      x: 8845.108695652174,
      y: 0.050273895263671875,
    },
    {
      x: 8849.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 8853.260869565218,
      y: 0.0851593017578125,
    },
    {
      x: 8857.33695652174,
      y: 0.10509490966796875,
    },
    {
      x: 8861.413043478262,
      y: 0.125030517578125,
    },
    {
      x: 8865.489130434782,
      y: 0.1349945068359375,
    },
    {
      x: 8869.565217391304,
      y: 0.14496231079101562,
    },
    {
      x: 8873.641304347826,
      y: 0.14496231079101562,
    },
    {
      x: 9126.358695652174,
      y: 0.14496231079101562,
    },
    {
      x: 9130.434782608696,
      y: 0.1698760986328125,
    },
    {
      x: 9134.510869565218,
      y: 0.1698760986328125,
    },
    {
      x: 9142.663043478262,
      y: 0.1698760986328125,
    },
    {
      x: 9146.739130434782,
      y: 0.17486572265625,
    },
    {
      x: 9150.815217391304,
      y: 0.17486572265625,
    },
    {
      x: 9154.891304347826,
      y: 0.17984771728515625,
    },
    {
      x: 9158.967391304348,
      y: 0.17984771728515625,
    },
    {
      x: 9171.195652173914,
      y: 0.17984771728515625,
    },
    {
      x: 9175.271739130436,
      y: 0.18483352661132812,
    },
    {
      x: 9179.347826086956,
      y: 0.18483352661132812,
    },
    {
      x: 9183.423913043478,
      y: 0.18483352661132812,
    },
    {
      x: 9187.5,
      y: 0.18981552124023438,
    },
    {
      x: 9191.576086956522,
      y: 0.18981552124023438,
    },
    {
      x: 9195.652173913044,
      y: 0.18981552124023438,
    },
    {
      x: 9199.728260869566,
      y: 0.18483352661132812,
    },
    {
      x: 9203.804347826088,
      y: 0.17984771728515625,
    },
    {
      x: 9207.880434782608,
      y: 0.17984771728515625,
    },
    {
      x: 9211.95652173913,
      y: 0.17486572265625,
    },
    {
      x: 9216.032608695652,
      y: 0.17486572265625,
    },
    {
      x: 9220.108695652174,
      y: 0.1698760986328125,
    },
    {
      x: 9224.184782608696,
      y: 0.1698760986328125,
    },
    {
      x: 9228.260869565218,
      y: 0.1698760986328125,
    },
    {
      x: 9232.33695652174,
      y: 0.16489410400390625,
    },
    {
      x: 9236.413043478262,
      y: 0.159912109375,
    },
    {
      x: 9240.489130434782,
      y: 0.15492630004882812,
    },
    {
      x: 9244.565217391304,
      y: 0.15492630004882812,
    },
    {
      x: 9248.641304347826,
      y: 0.15492630004882812,
    },
    {
      x: 9252.717391304348,
      y: 0.14994430541992188,
    },
    {
      x: 9256.79347826087,
      y: 0.14994430541992188,
    },
    {
      x: 9260.869565217392,
      y: 0.14496231079101562,
    },
    {
      x: 9264.945652173914,
      y: 0.14496231079101562,
    },
    {
      x: 9387.228260869566,
      y: 0.14496231079101562,
    },
    {
      x: 9391.304347826088,
      y: 0.050273895263671875,
    },
    {
      x: 9395.380434782608,
      y: 0.07020950317382812,
    },
    {
      x: 9399.45652173913,
      y: 0.0851593017578125,
    },
    {
      x: 9403.532608695652,
      y: 0.10509490966796875,
    },
    {
      x: 9407.608695652174,
      y: 0.125030517578125,
    },
    {
      x: 9411.684782608696,
      y: 0.1349945068359375,
    },
    {
      x: 9415.760869565218,
      y: 0.13997650146484375,
    },
    {
      x: 9419.83695652174,
      y: 0.13997650146484375,
    },
    {
      x: 9631.79347826087,
      y: 0.13997650146484375,
    },
    {
      x: 9635.869565217392,
      y: 0.14496231079101562,
    },
    {
      x: 9639.945652173914,
      y: 0.15492630004882812,
    },
    {
      x: 9644.021739130436,
      y: 0.16489410400390625,
    },
    {
      x: 9648.097826086956,
      y: 0.1698760986328125,
    },
    {
      x: 9652.173913043478,
      y: 0.14496231079101562,
    },
    {
      x: 9656.25,
      y: 0.14496231079101562,
    },
    {
      x: 9847.826086956522,
      y: 0.14496231079101562,
    },
    {
      x: 9851.902173913044,
      y: 0.125030517578125,
    },
    {
      x: 9855.978260869566,
      y: 0.10509490966796875,
    },
    {
      x: 9864.13043478261,
      y: 0.06522369384765625,
    },
    {
      x: 9868.20652173913,
      y: 0.050273895263671875,
    },
    {
      x: 9872.282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 9876.358695652174,
      y: 0.015392303466796875,
    },
    {
      x: 9880.434782608696,
      y: -0.004543304443359375,
    },
    {
      x: 9884.510869565218,
      y: -0.024478912353515625,
    },
    {
      x: 9888.58695652174,
      y: -0.034442901611328125,
    },
    {
      x: 9892.663043478262,
      y: -0.034442901611328125,
    },
    {
      x: 9896.739130434782,
      y: -0.01949310302734375,
    },
    {
      x: 9900.815217391304,
      y: 0.0004425048828125,
    },
    {
      x: 9904.891304347826,
      y: 0.015392303466796875,
    },
    {
      x: 9908.967391304348,
      y: 0.0353240966796875,
    },
    {
      x: 9913.04347826087,
      y: 0.14496231079101562,
    },
    {
      x: 9917.119565217392,
      y: 0.14496231079101562,
    },
    {
      x: 10169.83695652174,
      y: 0.14496231079101562,
    },
    {
      x: 10173.913043478262,
      y: 0.13997650146484375,
    },
    {
      x: 10177.989130434782,
      y: 0.13997650146484375,
    },
    {
      x: 10182.065217391304,
      y: 0.14496231079101562,
    },
    {
      x: 10186.141304347826,
      y: 0.15492630004882812,
    },
    {
      x: 10190.217391304348,
      y: 0.16489410400390625,
    },
    {
      x: 10194.29347826087,
      y: 0.1698760986328125,
    },
    {
      x: 10198.369565217392,
      y: 0.1698760986328125,
    },
    {
      x: 10210.597826086956,
      y: 0.1698760986328125,
    },
    {
      x: 10214.673913043478,
      y: 0.17486572265625,
    },
    {
      x: 10218.75,
      y: 0.17486572265625,
    },
    {
      x: 10222.826086956522,
      y: 0.17984771728515625,
    },
    {
      x: 10226.902173913044,
      y: 0.17984771728515625,
    },
    {
      x: 10239.13043478261,
      y: 0.17984771728515625,
    },
    {
      x: 10243.20652173913,
      y: 0.18483352661132812,
    },
    {
      x: 10247.282608695652,
      y: 0.18483352661132812,
    },
    {
      x: 10251.358695652174,
      y: 0.18483352661132812,
    },
    {
      x: 10255.434782608696,
      y: 0.18981552124023438,
    },
    {
      x: 10259.510869565218,
      y: 0.18981552124023438,
    },
    {
      x: 10263.58695652174,
      y: 0.18981552124023438,
    },
    {
      x: 10267.663043478262,
      y: 0.18483352661132812,
    },
    {
      x: 10271.739130434782,
      y: 0.17984771728515625,
    },
    {
      x: 10275.815217391304,
      y: 0.17984771728515625,
    },
    {
      x: 10279.891304347826,
      y: 0.17486572265625,
    },
    {
      x: 10283.967391304348,
      y: 0.17486572265625,
    },
    {
      x: 10288.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 10292.119565217392,
      y: 0.1698760986328125,
    },
    {
      x: 10296.195652173914,
      y: 0.1698760986328125,
    },
    {
      x: 10300.271739130436,
      y: 0.16489410400390625,
    },
    {
      x: 10304.347826086956,
      y: 0.159912109375,
    },
    {
      x: 10308.423913043478,
      y: 0.15492630004882812,
    },
    {
      x: 10312.5,
      y: 0.15492630004882812,
    },
    {
      x: 10316.576086956522,
      y: 0.15492630004882812,
    },
    {
      x: 10320.652173913044,
      y: 0.14994430541992188,
    },
    {
      x: 10324.728260869566,
      y: 0.14994430541992188,
    },
    {
      x: 10328.804347826088,
      y: 0.14496231079101562,
    },
    {
      x: 10332.88043478261,
      y: 0.14496231079101562,
    },
    {
      x: 10654.891304347826,
      y: 0.14496231079101562,
    },
    {
      x: 10658.967391304348,
      y: 0.125030517578125,
    },
    {
      x: 10663.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 10671.195652173914,
      y: 0.06522369384765625,
    },
    {
      x: 10675.271739130436,
      y: 0.050273895263671875,
    },
    {
      x: 10679.347826086956,
      y: 0.03034210205078125,
    },
    {
      x: 10683.423913043478,
      y: 0.015392303466796875,
    },
    {
      x: 10687.5,
      y: -0.004543304443359375,
    },
    {
      x: 10691.576086956522,
      y: -0.024478912353515625,
    },
    {
      x: 10695.652173913044,
      y: -0.034442901611328125,
    },
    {
      x: 10699.728260869566,
      y: -0.034442901611328125,
    },
    {
      x: 10703.804347826088,
      y: -0.01949310302734375,
    },
    {
      x: 10707.88043478261,
      y: 0.0004425048828125,
    },
    {
      x: 10711.95652173913,
      y: 0.015392303466796875,
    },
    {
      x: 10716.032608695652,
      y: 0.0353240966796875,
    },
    {
      x: 10720.108695652174,
      y: 0.050273895263671875,
    },
    {
      x: 10724.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 10728.260869565218,
      y: 0.0851593017578125,
    },
    {
      x: 10732.33695652174,
      y: 0.10509490966796875,
    },
    {
      x: 10736.413043478262,
      y: 0.125030517578125,
    },
    {
      x: 10740.489130434782,
      y: 0.1349945068359375,
    },
    {
      x: 10744.565217391304,
      y: 0.13997650146484375,
    },
    {
      x: 10748.641304347826,
      y: 0.13997650146484375,
    },
    {
      x: 10952.445652173914,
      y: 0.13997650146484375,
    },
    {
      x: 10956.521739130436,
      y: 0.14496231079101562,
    },
    {
      x: 10960.597826086956,
      y: 0.14496231079101562,
    },
    {
      x: 11213.315217391304,
      y: 0.14496231079101562,
    },
    {
      x: 11217.391304347826,
      y: 0.13997650146484375,
    },
    {
      x: 11221.467391304348,
      y: 0.13997650146484375,
    },
    {
      x: 11245.923913043478,
      y: 0.13997650146484375,
    },
    {
      x: 11250,
      y: 0.14496231079101562,
    },
    {
      x: 11254.076086956522,
      y: 0.15492630004882812,
    },
    {
      x: 11258.152173913044,
      y: 0.16489410400390625,
    },
    {
      x: 11262.228260869566,
      y: 0.1698760986328125,
    },
    {
      x: 11266.304347826088,
      y: 0.1698760986328125,
    },
    {
      x: 11278.532608695652,
      y: 0.1698760986328125,
    },
    {
      x: 11282.608695652174,
      y: 0.17486572265625,
    },
    {
      x: 11286.684782608696,
      y: 0.17486572265625,
    },
    {
      x: 11290.760869565218,
      y: 0.17984771728515625,
    },
    {
      x: 11294.83695652174,
      y: 0.17984771728515625,
    },
    {
      x: 11307.065217391304,
      y: 0.17984771728515625,
    },
    {
      x: 11311.141304347826,
      y: 0.18483352661132812,
    },
    {
      x: 11315.217391304348,
      y: 0.18483352661132812,
    },
    {
      x: 11319.29347826087,
      y: 0.18483352661132812,
    },
    {
      x: 11323.369565217392,
      y: 0.18981552124023438,
    },
    {
      x: 11327.445652173914,
      y: 0.18981552124023438,
    },
    {
      x: 11331.521739130436,
      y: 0.18981552124023438,
    },
    {
      x: 11335.597826086956,
      y: 0.18483352661132812,
    },
    {
      x: 11339.673913043478,
      y: 0.17984771728515625,
    },
    {
      x: 11343.75,
      y: 0.17984771728515625,
    },
    {
      x: 11347.826086956522,
      y: 0.17486572265625,
    },
    {
      x: 11351.902173913044,
      y: 0.17486572265625,
    },
    {
      x: 11355.978260869566,
      y: 0.1698760986328125,
    },
    {
      x: 11360.054347826088,
      y: 0.1698760986328125,
    },
    {
      x: 11364.13043478261,
      y: 0.1698760986328125,
    },
    {
      x: 11368.20652173913,
      y: 0.16489410400390625,
    },
    {
      x: 11372.282608695652,
      y: 0.159912109375,
    },
    {
      x: 11376.358695652174,
      y: 0.15492630004882812,
    },
    {
      x: 11380.434782608696,
      y: 0.15492630004882812,
    },
    {
      x: 11384.510869565218,
      y: 0.15492630004882812,
    },
    {
      x: 11388.58695652174,
      y: 0.14994430541992188,
    },
    {
      x: 11392.663043478262,
      y: 0.14994430541992188,
    },
    {
      x: 11396.739130434782,
      y: 0.14496231079101562,
    },
    {
      x: 11400.815217391304,
      y: 0.14496231079101562,
    },
    {
      x: 11474.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 11478.260869565218,
      y: 0.06522369384765625,
    },
    {
      x: 11482.33695652174,
      y: 0.050273895263671875,
    },
    {
      x: 11486.413043478262,
      y: 0.03034210205078125,
    },
    {
      x: 11490.489130434782,
      y: 0.015392303466796875,
    },
    {
      x: 11494.565217391304,
      y: -0.004543304443359375,
    },
    {
      x: 11498.641304347826,
      y: -0.024478912353515625,
    },
    {
      x: 11502.717391304348,
      y: -0.034442901611328125,
    },
    {
      x: 11506.79347826087,
      y: -0.034442901611328125,
    },
    {
      x: 11510.869565217392,
      y: -0.01949310302734375,
    },
    {
      x: 11514.945652173914,
      y: 0.0004425048828125,
    },
    {
      x: 11519.021739130436,
      y: 0.015392303466796875,
    },
    {
      x: 11523.097826086956,
      y: 0.0353240966796875,
    },
    {
      x: 11527.173913043478,
      y: 0.050273895263671875,
    },
    {
      x: 11531.25,
      y: 0.07020950317382812,
    },
    {
      x: 11535.326086956522,
      y: 0.0851593017578125,
    },
    {
      x: 11539.402173913044,
      y: 0.10509490966796875,
    },
    {
      x: 11543.478260869566,
      y: 0.125030517578125,
    },
    {
      x: 11547.554347826088,
      y: 0.1349945068359375,
    },
    {
      x: 11551.63043478261,
      y: 0.13997650146484375,
    },
    {
      x: 11555.70652173913,
      y: 0.13997650146484375,
    },
    {
      x: 11735.054347826088,
      y: 0.13997650146484375,
    },
    {
      x: 11739.13043478261,
      y: 0.14496231079101562,
    },
    {
      x: 11743.20652173913,
      y: 0.14496231079101562,
    },
    {
      x: 11983.695652173914,
      y: 0.14496231079101562,
    },
    {
      x: 11987.771739130436,
      y: 0.125030517578125,
    },
    {
      x: 11991.847826086956,
      y: 0.10509490966796875,
    },
    {
      x: 11995.923913043478,
      y: 0.0851593017578125,
    },
    {
      x: 12000,
      y: 0.14496231079101562,
    },
    {
      x: 12004.076086956522,
      y: 0.14496231079101562,
    },
    {
      x: 12256.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 12260.869565217392,
      y: 0.13997650146484375,
    },
    {
      x: 12264.945652173914,
      y: 0.13997650146484375,
    },
    {
      x: 12313.858695652174,
      y: 0.13997650146484375,
    },
    {
      x: 12317.934782608696,
      y: 0.14496231079101562,
    },
    {
      x: 12322.010869565218,
      y: 0.15492630004882812,
    },
    {
      x: 12326.08695652174,
      y: 0.16489410400390625,
    },
    {
      x: 12330.163043478262,
      y: 0.1698760986328125,
    },
    {
      x: 12334.239130434782,
      y: 0.1698760986328125,
    },
    {
      x: 12346.467391304348,
      y: 0.1698760986328125,
    },
    {
      x: 12350.54347826087,
      y: 0.17486572265625,
    },
    {
      x: 12354.619565217392,
      y: 0.17486572265625,
    },
    {
      x: 12358.695652173914,
      y: 0.17984771728515625,
    },
    {
      x: 12362.771739130436,
      y: 0.17984771728515625,
    },
    {
      x: 12375,
      y: 0.17984771728515625,
    },
    {
      x: 12379.076086956522,
      y: 0.18483352661132812,
    },
    {
      x: 12383.152173913044,
      y: 0.18483352661132812,
    },
    {
      x: 12387.228260869566,
      y: 0.18483352661132812,
    },
    {
      x: 12391.304347826088,
      y: 0.18981552124023438,
    },
    {
      x: 12395.38043478261,
      y: 0.18981552124023438,
    },
    {
      x: 12399.45652173913,
      y: 0.18981552124023438,
    },
    {
      x: 12403.532608695652,
      y: 0.18483352661132812,
    },
    {
      x: 12407.608695652174,
      y: 0.17984771728515625,
    },
    {
      x: 12411.684782608696,
      y: 0.17984771728515625,
    },
    {
      x: 12415.760869565218,
      y: 0.17486572265625,
    },
    {
      x: 12419.83695652174,
      y: 0.17486572265625,
    },
    {
      x: 12423.913043478262,
      y: 0.1698760986328125,
    },
    {
      x: 12427.989130434782,
      y: 0.1698760986328125,
    },
    {
      x: 12432.065217391304,
      y: 0.1698760986328125,
    },
    {
      x: 12436.141304347826,
      y: 0.16489410400390625,
    },
    {
      x: 12440.217391304348,
      y: 0.159912109375,
    },
    {
      x: 12444.29347826087,
      y: 0.15492630004882812,
    },
    {
      x: 12448.369565217392,
      y: 0.15492630004882812,
    },
    {
      x: 12452.445652173914,
      y: 0.15492630004882812,
    },
    {
      x: 12456.521739130436,
      y: 0.14994430541992188,
    },
    {
      x: 12460.597826086956,
      y: 0.14994430541992188,
    },
    {
      x: 12464.673913043478,
      y: 0.14496231079101562,
    },
    {
      x: 12468.75,
      y: 0.14496231079101562,
    },
    {
      x: 12529.891304347826,
      y: 0.14496231079101562,
    },
    {
      x: 12533.967391304348,
      y: 0.125030517578125,
    },
    {
      x: 12538.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 12546.195652173914,
      y: 0.06522369384765625,
    },
    {
      x: 12550.271739130436,
      y: 0.050273895263671875,
    },
    {
      x: 12554.347826086956,
      y: 0.03034210205078125,
    },
    {
      x: 12558.423913043478,
      y: 0.015392303466796875,
    },
    {
      x: 12562.5,
      y: -0.004543304443359375,
    },
    {
      x: 12566.576086956522,
      y: -0.024478912353515625,
    },
    {
      x: 12570.652173913044,
      y: -0.034442901611328125,
    },
    {
      x: 12574.728260869566,
      y: -0.034442901611328125,
    },
    {
      x: 12578.804347826088,
      y: -0.01949310302734375,
    },
    {
      x: 12582.88043478261,
      y: 0.0004425048828125,
    },
    {
      x: 12586.95652173913,
      y: 0.015392303466796875,
    },
    {
      x: 12591.032608695652,
      y: 0.0353240966796875,
    },
    {
      x: 12595.108695652174,
      y: 0.050273895263671875,
    },
    {
      x: 12599.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 12603.260869565218,
      y: 0.0851593017578125,
    },
    {
      x: 12607.33695652174,
      y: 0.10509490966796875,
    },
    {
      x: 12611.413043478262,
      y: 0.125030517578125,
    },
    {
      x: 12615.489130434784,
      y: 0.1349945068359375,
    },
    {
      x: 12619.565217391304,
      y: 0.13997650146484375,
    },
    {
      x: 12623.641304347826,
      y: 0.13997650146484375,
    },
    {
      x: 12778.532608695652,
      y: 0.13997650146484375,
    },
    {
      x: 12782.608695652174,
      y: 0.14496231079101562,
    },
    {
      x: 12786.684782608696,
      y: 0.14496231079101562,
    },
    {
      x: 13300.271739130436,
      y: 0.14496231079101562,
    },
    {
      x: 13304.347826086956,
      y: 0.13997650146484375,
    },
    {
      x: 13308.423913043478,
      y: 0.13997650146484375,
    },
    {
      x: 13381.79347826087,
      y: 0.13997650146484375,
    },
    {
      x: 13385.869565217392,
      y: 0.14496231079101562,
    },
    {
      x: 13389.945652173914,
      y: 0.15492630004882812,
    },
    {
      x: 13394.021739130436,
      y: 0.16489410400390625,
    },
    {
      x: 13398.097826086956,
      y: 0.1698760986328125,
    },
    {
      x: 13402.173913043478,
      y: 0.1698760986328125,
    },
    {
      x: 13414.402173913044,
      y: 0.1698760986328125,
    },
    {
      x: 13418.478260869566,
      y: 0.17486572265625,
    },
    {
      x: 13422.554347826088,
      y: 0.17486572265625,
    },
    {
      x: 13426.63043478261,
      y: 0.17984771728515625,
    },
    {
      x: 13430.70652173913,
      y: 0.17984771728515625,
    },
    {
      x: 13442.934782608696,
      y: 0.17984771728515625,
    },
    {
      x: 13447.010869565218,
      y: 0.18483352661132812,
    },
    {
      x: 13451.08695652174,
      y: 0.18483352661132812,
    },
    {
      x: 13455.163043478262,
      y: 0.18483352661132812,
    },
    {
      x: 13459.239130434784,
      y: 0.18981552124023438,
    },
    {
      x: 13463.315217391304,
      y: 0.18981552124023438,
    },
    {
      x: 13467.391304347826,
      y: 0.18981552124023438,
    },
    {
      x: 13471.467391304348,
      y: 0.18483352661132812,
    },
    {
      x: 13475.54347826087,
      y: 0.17984771728515625,
    },
    {
      x: 13479.619565217392,
      y: 0.17984771728515625,
    },
    {
      x: 13483.695652173914,
      y: 0.17486572265625,
    },
    {
      x: 13487.771739130436,
      y: 0.17486572265625,
    },
    {
      x: 13491.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 13495.923913043478,
      y: 0.1698760986328125,
    },
    {
      x: 13500,
      y: 0.1698760986328125,
    },
    {
      x: 13504.076086956522,
      y: 0.16489410400390625,
    },
    {
      x: 13508.152173913044,
      y: 0.159912109375,
    },
    {
      x: 13512.228260869566,
      y: 0.15492630004882812,
    },
    {
      x: 13516.304347826088,
      y: 0.15492630004882812,
    },
    {
      x: 13520.38043478261,
      y: 0.15492630004882812,
    },
    {
      x: 13524.45652173913,
      y: 0.14994430541992188,
    },
    {
      x: 13528.532608695652,
      y: 0.14994430541992188,
    },
    {
      x: 13532.608695652174,
      y: 0.14496231079101562,
    },
    {
      x: 13536.684782608696,
      y: 0.14496231079101562,
    },
    {
      x: 13597.826086956522,
      y: 0.14496231079101562,
    },
    {
      x: 13601.902173913044,
      y: 0.125030517578125,
    },
    {
      x: 13605.978260869566,
      y: 0.10509490966796875,
    },
    {
      x: 13614.13043478261,
      y: 0.06522369384765625,
    },
    {
      x: 13618.20652173913,
      y: 0.050273895263671875,
    },
    {
      x: 13622.282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 13626.358695652174,
      y: 0.015392303466796875,
    },
    {
      x: 13630.434782608696,
      y: -0.004543304443359375,
    },
    {
      x: 13634.510869565218,
      y: -0.024478912353515625,
    },
    {
      x: 13638.58695652174,
      y: -0.034442901611328125,
    },
    {
      x: 13642.663043478262,
      y: -0.034442901611328125,
    },
    {
      x: 13646.739130434784,
      y: -0.01949310302734375,
    },
    {
      x: 13650.815217391304,
      y: 0.0004425048828125,
    },
    {
      x: 13654.891304347826,
      y: 0.015392303466796875,
    },
    {
      x: 13658.967391304348,
      y: 0.0353240966796875,
    },
    {
      x: 13663.04347826087,
      y: 0.050273895263671875,
    },
    {
      x: 13667.119565217392,
      y: 0.07020950317382812,
    },
    {
      x: 13671.195652173914,
      y: 0.0851593017578125,
    },
    {
      x: 13675.271739130436,
      y: 0.10509490966796875,
    },
    {
      x: 13679.347826086956,
      y: 0.125030517578125,
    },
    {
      x: 13683.423913043478,
      y: 0.1349945068359375,
    },
    {
      x: 13687.5,
      y: 0.13997650146484375,
    },
    {
      x: 13691.576086956522,
      y: 0.13997650146484375,
    },
    {
      x: 13822.010869565218,
      y: 0.13997650146484375,
    },
    {
      x: 13826.08695652174,
      y: 0.14496231079101562,
    },
    {
      x: 13830.163043478262,
      y: 0.14496231079101562,
    },
    {
      x: 14343.75,
      y: 0.14496231079101562,
    },
    {
      x: 14347.826086956522,
      y: 0.13997650146484375,
    },
    {
      x: 14351.902173913044,
      y: 0.13997650146484375,
    },
    {
      x: 14449.728260869566,
      y: 0.13997650146484375,
    },
    {
      x: 14453.804347826088,
      y: 0.14496231079101562,
    },
    {
      x: 14457.88043478261,
      y: 0.15492630004882812,
    },
    {
      x: 14461.95652173913,
      y: 0.16489410400390625,
    },
    {
      x: 14466.032608695652,
      y: 0.1698760986328125,
    },
    {
      x: 14470.108695652174,
      y: 0.1698760986328125,
    },
    {
      x: 14482.33695652174,
      y: 0.1698760986328125,
    },
    {
      x: 14486.413043478262,
      y: 0.17486572265625,
    },
    {
      x: 14490.489130434784,
      y: 0.17486572265625,
    },
    {
      x: 14494.565217391304,
      y: 0.17984771728515625,
    },
    {
      x: 14498.641304347826,
      y: 0.17984771728515625,
    },
    {
      x: 14510.869565217392,
      y: 0.17984771728515625,
    },
    {
      x: 14514.945652173914,
      y: 0.18483352661132812,
    },
    {
      x: 14519.021739130436,
      y: 0.18483352661132812,
    },
    {
      x: 14523.097826086956,
      y: 0.18483352661132812,
    },
    {
      x: 14527.173913043478,
      y: 0.18981552124023438,
    },
    {
      x: 14531.25,
      y: 0.18981552124023438,
    },
    {
      x: 14535.326086956522,
      y: 0.18981552124023438,
    },
    {
      x: 14539.402173913044,
      y: 0.18483352661132812,
    },
    {
      x: 14543.478260869566,
      y: 0.17984771728515625,
    },
    {
      x: 14547.554347826088,
      y: 0.17984771728515625,
    },
    {
      x: 14551.63043478261,
      y: 0.17486572265625,
    },
    {
      x: 14555.70652173913,
      y: 0.17486572265625,
    },
    {
      x: 14559.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 14563.858695652174,
      y: 0.1698760986328125,
    },
    {
      x: 14567.934782608696,
      y: 0.1698760986328125,
    },
    {
      x: 14572.010869565218,
      y: 0.16489410400390625,
    },
    {
      x: 14576.08695652174,
      y: 0.159912109375,
    },
    {
      x: 14580.163043478262,
      y: 0.15492630004882812,
    },
    {
      x: 14584.239130434784,
      y: 0.15492630004882812,
    },
    {
      x: 14588.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 14592.391304347826,
      y: 0.14994430541992188,
    },
    {
      x: 14596.467391304348,
      y: 0.14994430541992188,
    },
    {
      x: 14600.54347826087,
      y: 0.14496231079101562,
    },
    {
      x: 14604.619565217392,
      y: 0.14496231079101562,
    },
    {
      x: 14926.63043478261,
      y: 0.14496231079101562,
    },
    {
      x: 14930.70652173913,
      y: 0.125030517578125,
    },
    {
      x: 14934.782608695652,
      y: 0.10509490966796875,
    },
    {
      x: 14942.934782608696,
      y: 0.06522369384765625,
    },
    {
      x: 14947.010869565218,
      y: 0.050273895263671875,
    },
    {
      x: 14951.08695652174,
      y: 0.03034210205078125,
    },
    {
      x: 14955.163043478262,
      y: 0.015392303466796875,
    },
    {
      x: 14959.239130434784,
      y: -0.004543304443359375,
    },
    {
      x: 14963.315217391304,
      y: -0.024478912353515625,
    },
    {
      x: 14967.391304347826,
      y: -0.034442901611328125,
    },
    {
      x: 14971.467391304348,
      y: -0.034442901611328125,
    },
    {
      x: 14975.54347826087,
      y: -0.01949310302734375,
    },
    {
      x: 14979.619565217392,
      y: 0.0004425048828125,
    },
    {
      x: 14983.695652173914,
      y: 0.015392303466796875,
    },
    {
      x: 14987.771739130436,
      y: 0.0353240966796875,
    },
    {
      x: 14991.847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 14995.923913043478,
      y: 0.07020950317382812,
    },
    {
      x: 15000,
      y: 0.0851593017578125,
    },
    {
      x: 15004.076086956522,
      y: 0.10509490966796875,
    },
    {
      x: 15008.152173913044,
      y: 0.125030517578125,
    },
    {
      x: 15012.228260869566,
      y: 0.1349945068359375,
    },
    {
      x: 15016.304347826088,
      y: 0.13997650146484375,
    },
    {
      x: 15020.38043478261,
      y: 0.13997650146484375,
    },
    {
      x: 15126.358695652174,
      y: 0.13997650146484375,
    },
    {
      x: 15130.434782608696,
      y: 0.15492630004882812,
    },
    {
      x: 15134.510869565218,
      y: 0.15492630004882812,
    },
    {
      x: 15138.58695652174,
      y: 0.14994430541992188,
    },
    {
      x: 15142.663043478262,
      y: 0.14994430541992188,
    },
    {
      x: 15146.739130434784,
      y: 0.14496231079101562,
    },
    {
      x: 15150.815217391304,
      y: 0.14496231079101562,
    },
    {
      x: 15517.663043478262,
      y: 0.14496231079101562,
    },
    {
      x: 15521.739130434784,
      y: 0.14994430541992188,
    },
    {
      x: 15525.815217391304,
      y: 0.15492630004882812,
    },
    {
      x: 15529.891304347826,
      y: 0.16489410400390625,
    },
    {
      x: 15533.967391304348,
      y: 0.1698760986328125,
    },
    {
      x: 15538.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 15550.271739130436,
      y: 0.1698760986328125,
    },
    {
      x: 15554.347826086958,
      y: 0.17486572265625,
    },
    {
      x: 15558.423913043478,
      y: 0.17486572265625,
    },
    {
      x: 15562.5,
      y: 0.17984771728515625,
    },
    {
      x: 15566.576086956522,
      y: 0.17984771728515625,
    },
    {
      x: 15578.804347826088,
      y: 0.17984771728515625,
    },
    {
      x: 15582.88043478261,
      y: 0.18483352661132812,
    },
    {
      x: 15586.95652173913,
      y: 0.18483352661132812,
    },
    {
      x: 15591.032608695652,
      y: 0.18483352661132812,
    },
    {
      x: 15595.108695652174,
      y: 0.18981552124023438,
    },
    {
      x: 15599.184782608696,
      y: 0.18981552124023438,
    },
    {
      x: 15603.260869565218,
      y: 0.18981552124023438,
    },
    {
      x: 15607.33695652174,
      y: 0.18483352661132812,
    },
    {
      x: 15611.413043478262,
      y: 0.17984771728515625,
    },
    {
      x: 15615.489130434784,
      y: 0.17984771728515625,
    },
    {
      x: 15619.565217391304,
      y: 0.17486572265625,
    },
    {
      x: 15623.641304347826,
      y: 0.17486572265625,
    },
    {
      x: 15627.717391304348,
      y: 0.1698760986328125,
    },
    {
      x: 15631.79347826087,
      y: 0.1698760986328125,
    },
    {
      x: 15635.869565217392,
      y: 0.1698760986328125,
    },
    {
      x: 15639.945652173914,
      y: 0.16489410400390625,
    },
    {
      x: 15644.021739130436,
      y: 0.159912109375,
    },
    {
      x: 15648.097826086958,
      y: 0.15492630004882812,
    },
    {
      x: 15652.173913043478,
      y: 0.14496231079101562,
    },
    {
      x: 15656.25,
      y: 0.14496231079101562,
    },
    {
      x: 15908.967391304348,
      y: 0.14496231079101562,
    },
    {
      x: 15913.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 15917.119565217392,
      y: 0.1698760986328125,
    },
    {
      x: 15921.195652173914,
      y: 0.1698760986328125,
    },
    {
      x: 15925.271739130436,
      y: 0.16489410400390625,
    },
    {
      x: 15929.347826086958,
      y: 0.159912109375,
    },
    {
      x: 15933.423913043478,
      y: 0.15492630004882812,
    },
    {
      x: 15937.5,
      y: 0.15492630004882812,
    },
    {
      x: 15941.576086956522,
      y: 0.15492630004882812,
    },
    {
      x: 15945.652173913044,
      y: 0.14994430541992188,
    },
    {
      x: 15949.728260869566,
      y: 0.14994430541992188,
    },
    {
      x: 15953.804347826088,
      y: 0.14496231079101562,
    },
    {
      x: 15957.88043478261,
      y: 0.14496231079101562,
    },
    {
      x: 16255.434782608696,
      y: 0.14496231079101562,
    },
    {
      x: 16259.510869565218,
      y: 0.125030517578125,
    },
    {
      x: 16263.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 16271.739130434784,
      y: 0.06522369384765625,
    },
    {
      x: 16275.815217391304,
      y: 0.050273895263671875,
    },
    {
      x: 16279.891304347826,
      y: 0.03034210205078125,
    },
    {
      x: 16283.967391304348,
      y: 0.015392303466796875,
    },
    {
      x: 16288.04347826087,
      y: -0.004543304443359375,
    },
    {
      x: 16292.119565217392,
      y: -0.024478912353515625,
    },
    {
      x: 16296.195652173914,
      y: -0.034442901611328125,
    },
    {
      x: 16300.271739130436,
      y: -0.029460906982421875,
    },
    {
      x: 16304.347826086958,
      y: -0.01949310302734375,
    },
    {
      x: 16308.423913043478,
      y: 0.0004425048828125,
    },
    {
      x: 16312.5,
      y: 0.015392303466796875,
    },
    {
      x: 16316.576086956522,
      y: 0.0353240966796875,
    },
    {
      x: 16320.652173913044,
      y: 0.05525970458984375,
    },
    {
      x: 16324.728260869566,
      y: 0.0751953125,
    },
    {
      x: 16328.804347826088,
      y: 0.09014511108398438,
    },
    {
      x: 16332.88043478261,
      y: 0.110076904296875,
    },
    {
      x: 16336.95652173913,
      y: 0.13001251220703125,
    },
    {
      x: 16341.032608695652,
      y: 0.13997650146484375,
    },
    {
      x: 16345.108695652174,
      y: 0.14496231079101562,
    },
    {
      x: 16349.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 16585.597826086956,
      y: 0.14496231079101562,
    },
    {
      x: 16589.67391304348,
      y: 0.14994430541992188,
    },
    {
      x: 16593.75,
      y: 0.15492630004882812,
    },
    {
      x: 16597.826086956524,
      y: 0.16489410400390625,
    },
    {
      x: 16601.902173913044,
      y: 0.1698760986328125,
    },
    {
      x: 16605.978260869564,
      y: 0.1698760986328125,
    },
    {
      x: 16618.206521739132,
      y: 0.1698760986328125,
    },
    {
      x: 16622.282608695652,
      y: 0.17486572265625,
    },
    {
      x: 16626.358695652176,
      y: 0.17486572265625,
    },
    {
      x: 16630.434782608696,
      y: 0.17984771728515625,
    },
    {
      x: 16634.510869565216,
      y: 0.17984771728515625,
    },
    {
      x: 16646.739130434784,
      y: 0.17984771728515625,
    },
    {
      x: 16650.815217391304,
      y: 0.18483352661132812,
    },
    {
      x: 16654.891304347828,
      y: 0.18483352661132812,
    },
    {
      x: 16658.967391304348,
      y: 0.18483352661132812,
    },
    {
      x: 16663.04347826087,
      y: 0.18981552124023438,
    },
    {
      x: 16667.119565217392,
      y: 0.18981552124023438,
    },
    {
      x: 16671.195652173912,
      y: 0.18981552124023438,
    },
    {
      x: 16675.271739130436,
      y: 0.18483352661132812,
    },
    {
      x: 16679.347826086956,
      y: 0.17984771728515625,
    },
    {
      x: 16683.42391304348,
      y: 0.17984771728515625,
    },
    {
      x: 16687.5,
      y: 0.17486572265625,
    },
    {
      x: 16691.576086956524,
      y: 0.17486572265625,
    },
    {
      x: 16695.652173913044,
      y: 0.14496231079101562,
    },
    {
      x: 16699.728260869564,
      y: 0.14496231079101562,
    },
    {
      x: 16801.630434782608,
      y: 0.14496231079101562,
    },
    {
      x: 16805.706521739132,
      y: 0.125030517578125,
    },
    {
      x: 16809.782608695652,
      y: 0.10509490966796875,
    },
    {
      x: 16817.934782608696,
      y: 0.06522369384765625,
    },
    {
      x: 16822.010869565216,
      y: 0.050273895263671875,
    },
    {
      x: 16826.08695652174,
      y: 0.03034210205078125,
    },
    {
      x: 16830.16304347826,
      y: 0.015392303466796875,
    },
    {
      x: 16834.239130434784,
      y: -0.004543304443359375,
    },
    {
      x: 16838.315217391304,
      y: -0.024478912353515625,
    },
    {
      x: 16842.391304347828,
      y: -0.034442901611328125,
    },
    {
      x: 16846.467391304348,
      y: -0.029460906982421875,
    },
    {
      x: 16850.54347826087,
      y: -0.01949310302734375,
    },
    {
      x: 16854.619565217392,
      y: 0.0004425048828125,
    },
    {
      x: 16858.695652173912,
      y: 0.015392303466796875,
    },
    {
      x: 16862.771739130436,
      y: 0.0353240966796875,
    },
    {
      x: 16866.847826086956,
      y: 0.05525970458984375,
    },
    {
      x: 16870.92391304348,
      y: 0.0751953125,
    },
    {
      x: 16875,
      y: 0.09014511108398438,
    },
    {
      x: 16879.076086956524,
      y: 0.110076904296875,
    },
    {
      x: 16883.152173913044,
      y: 0.13001251220703125,
    },
    {
      x: 16887.228260869564,
      y: 0.13997650146484375,
    },
    {
      x: 16891.304347826088,
      y: 0.14496231079101562,
    },
    {
      x: 16895.380434782608,
      y: 0.14496231079101562,
    },
    {
      x: 16952.445652173912,
      y: 0.14496231079101562,
    },
    {
      x: 16956.521739130436,
      y: 0.18981552124023438,
    },
    {
      x: 16960.597826086956,
      y: 0.18483352661132812,
    },
    {
      x: 16964.67391304348,
      y: 0.17984771728515625,
    },
    {
      x: 16968.75,
      y: 0.17984771728515625,
    },
    {
      x: 16972.826086956524,
      y: 0.17486572265625,
    },
    {
      x: 16976.902173913044,
      y: 0.17486572265625,
    },
    {
      x: 16980.978260869564,
      y: 0.1698760986328125,
    },
    {
      x: 16985.054347826088,
      y: 0.1698760986328125,
    },
    {
      x: 16989.130434782608,
      y: 0.1698760986328125,
    },
    {
      x: 16993.206521739132,
      y: 0.16489410400390625,
    },
    {
      x: 16997.282608695652,
      y: 0.159912109375,
    },
    {
      x: 17001.358695652176,
      y: 0.15492630004882812,
    },
    {
      x: 17005.434782608696,
      y: 0.15492630004882812,
    },
    {
      x: 17009.510869565216,
      y: 0.15492630004882812,
    },
    {
      x: 17013.58695652174,
      y: 0.14994430541992188,
    },
    {
      x: 17017.66304347826,
      y: 0.14994430541992188,
    },
    {
      x: 17021.739130434784,
      y: 0.14496231079101562,
    },
    {
      x: 17025.815217391304,
      y: 0.14496231079101562,
    },
    {
      x: 17653.532608695652,
      y: 0.14496231079101562,
    },
    {
      x: 17657.608695652176,
      y: 0.14994430541992188,
    },
    {
      x: 17661.684782608696,
      y: 0.15492630004882812,
    },
    {
      x: 17665.760869565216,
      y: 0.16489410400390625,
    },
    {
      x: 17669.83695652174,
      y: 0.1698760986328125,
    },
    {
      x: 17673.91304347826,
      y: 0.1698760986328125,
    },
    {
      x: 17686.141304347828,
      y: 0.1698760986328125,
    },
    {
      x: 17690.217391304348,
      y: 0.17486572265625,
    },
    {
      x: 17694.29347826087,
      y: 0.17486572265625,
    },
    {
      x: 17698.369565217392,
      y: 0.17984771728515625,
    },
    {
      x: 17702.445652173912,
      y: 0.17984771728515625,
    },
    {
      x: 17714.67391304348,
      y: 0.17984771728515625,
    },
    {
      x: 17718.75,
      y: 0.18483352661132812,
    },
    {
      x: 17722.826086956524,
      y: 0.18483352661132812,
    },
    {
      x: 17726.902173913044,
      y: 0.18483352661132812,
    },
    {
      x: 17730.978260869564,
      y: 0.18981552124023438,
    },
    {
      x: 17735.054347826088,
      y: 0.18981552124023438,
    },
    {
      x: 17739.130434782608,
      y: 0.14496231079101562,
    },
    {
      x: 17743.206521739132,
      y: 0.14496231079101562,
    },
    {
      x: 17869.565217391304,
      y: 0.14496231079101562,
    },
    {
      x: 17873.641304347828,
      y: 0.125030517578125,
    },
    {
      x: 17877.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 17885.869565217392,
      y: 0.06522369384765625,
    },
    {
      x: 17889.945652173912,
      y: 0.050273895263671875,
    },
    {
      x: 17894.021739130436,
      y: 0.03034210205078125,
    },
    {
      x: 17898.097826086956,
      y: 0.015392303466796875,
    },
    {
      x: 17902.17391304348,
      y: -0.004543304443359375,
    },
    {
      x: 17906.25,
      y: -0.024478912353515625,
    },
    {
      x: 17910.326086956524,
      y: -0.034442901611328125,
    },
    {
      x: 17914.402173913044,
      y: -0.029460906982421875,
    },
    {
      x: 17918.478260869564,
      y: -0.01949310302734375,
    },
    {
      x: 17922.554347826088,
      y: 0.0004425048828125,
    },
    {
      x: 17926.630434782608,
      y: 0.015392303466796875,
    },
    {
      x: 17930.706521739132,
      y: 0.0353240966796875,
    },
    {
      x: 17934.782608695652,
      y: 0.05525970458984375,
    },
    {
      x: 17938.858695652176,
      y: 0.0751953125,
    },
    {
      x: 17942.934782608696,
      y: 0.09014511108398438,
    },
    {
      x: 17947.010869565216,
      y: 0.110076904296875,
    },
    {
      x: 17951.08695652174,
      y: 0.13001251220703125,
    },
    {
      x: 17955.16304347826,
      y: 0.13997650146484375,
    },
    {
      x: 17959.239130434784,
      y: 0.14496231079101562,
    },
    {
      x: 17963.315217391304,
      y: 0.14496231079101562,
    },
    {
      x: 18256.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 18260.869565217392,
      y: 0.17984771728515625,
    },
    {
      x: 18264.945652173912,
      y: 0.18483352661132812,
    },
    {
      x: 18269.021739130436,
      y: 0.18483352661132812,
    },
    {
      x: 18273.097826086956,
      y: 0.18483352661132812,
    },
    {
      x: 18277.17391304348,
      y: 0.18981552124023438,
    },
    {
      x: 18281.25,
      y: 0.18981552124023438,
    },
    {
      x: 18285.326086956524,
      y: 0.18981552124023438,
    },
    {
      x: 18289.402173913044,
      y: 0.18483352661132812,
    },
    {
      x: 18293.478260869564,
      y: 0.17984771728515625,
    },
    {
      x: 18297.554347826088,
      y: 0.17984771728515625,
    },
    {
      x: 18301.630434782608,
      y: 0.17486572265625,
    },
    {
      x: 18305.706521739132,
      y: 0.17486572265625,
    },
    {
      x: 18309.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 18313.858695652176,
      y: 0.1698760986328125,
    },
    {
      x: 18317.934782608696,
      y: 0.1698760986328125,
    },
    {
      x: 18322.010869565216,
      y: 0.16489410400390625,
    },
    {
      x: 18326.08695652174,
      y: 0.159912109375,
    },
    {
      x: 18330.16304347826,
      y: 0.15492630004882812,
    },
    {
      x: 18334.239130434784,
      y: 0.15492630004882812,
    },
    {
      x: 18338.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 18342.391304347828,
      y: 0.14994430541992188,
    },
    {
      x: 18346.467391304348,
      y: 0.14994430541992188,
    },
    {
      x: 18350.54347826087,
      y: 0.14496231079101562,
    },
    {
      x: 18354.619565217392,
      y: 0.14496231079101562,
    },
    {
      x: 18721.467391304348,
      y: 0.14496231079101562,
    },
    {
      x: 18725.54347826087,
      y: 0.14994430541992188,
    },
    {
      x: 18729.619565217392,
      y: 0.15492630004882812,
    },
    {
      x: 18733.695652173912,
      y: 0.16489410400390625,
    },
    {
      x: 18737.771739130436,
      y: 0.1698760986328125,
    },
    {
      x: 18741.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 18754.076086956524,
      y: 0.1698760986328125,
    },
    {
      x: 18758.152173913044,
      y: 0.17486572265625,
    },
    {
      x: 18762.228260869564,
      y: 0.17486572265625,
    },
    {
      x: 18766.304347826088,
      y: 0.17984771728515625,
    },
    {
      x: 18770.380434782608,
      y: 0.17984771728515625,
    },
    {
      x: 18778.532608695652,
      y: 0.17984771728515625,
    },
    {
      x: 18782.608695652176,
      y: 0.14496231079101562,
    },
    {
      x: 18786.684782608696,
      y: 0.14496231079101562,
    },
    {
      x: 18937.5,
      y: 0.14496231079101562,
    },
    {
      x: 18941.576086956524,
      y: 0.125030517578125,
    },
    {
      x: 18945.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 18953.804347826088,
      y: 0.06522369384765625,
    },
    {
      x: 18957.880434782608,
      y: 0.050273895263671875,
    },
    {
      x: 18961.956521739132,
      y: 0.03034210205078125,
    },
    {
      x: 18966.032608695652,
      y: 0.015392303466796875,
    },
    {
      x: 18970.108695652176,
      y: -0.004543304443359375,
    },
    {
      x: 18974.184782608696,
      y: -0.024478912353515625,
    },
    {
      x: 18978.260869565216,
      y: -0.034442901611328125,
    },
    {
      x: 18982.33695652174,
      y: -0.029460906982421875,
    },
    {
      x: 18986.41304347826,
      y: -0.01949310302734375,
    },
    {
      x: 18990.489130434784,
      y: 0.0004425048828125,
    },
    {
      x: 18994.565217391304,
      y: 0.015392303466796875,
    },
    {
      x: 18998.641304347828,
      y: 0.0353240966796875,
    },
    {
      x: 19002.717391304348,
      y: 0.05525970458984375,
    },
    {
      x: 19006.79347826087,
      y: 0.0751953125,
    },
    {
      x: 19010.869565217392,
      y: 0.09014511108398438,
    },
    {
      x: 19014.945652173912,
      y: 0.110076904296875,
    },
    {
      x: 19019.021739130436,
      y: 0.13001251220703125,
    },
    {
      x: 19023.097826086956,
      y: 0.13997650146484375,
    },
    {
      x: 19027.17391304348,
      y: 0.14496231079101562,
    },
    {
      x: 19031.25,
      y: 0.14496231079101562,
    },
    {
      x: 19039.402173913044,
      y: 0.14496231079101562,
    },
    {
      x: 19043.478260869564,
      y: 0.13001251220703125,
    },
    {
      x: 19047.554347826088,
      y: 0.13997650146484375,
    },
    {
      x: 19051.630434782608,
      y: 0.14496231079101562,
    },
    {
      x: 19055.706521739132,
      y: 0.14496231079101562,
    },
    {
      x: 19267.66304347826,
      y: 0.14496231079101562,
    },
    {
      x: 19271.739130434784,
      y: 0.14994430541992188,
    },
    {
      x: 19275.815217391304,
      y: 0.15492630004882812,
    },
    {
      x: 19279.891304347828,
      y: 0.16489410400390625,
    },
    {
      x: 19283.967391304348,
      y: 0.1698760986328125,
    },
    {
      x: 19288.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 19300.271739130436,
      y: 0.1698760986328125,
    },
    {
      x: 19304.347826086956,
      y: 0.14496231079101562,
    },
    {
      x: 19308.42391304348,
      y: 0.14496231079101562,
    },
    {
      x: 19561.141304347828,
      y: 0.14496231079101562,
    },
    {
      x: 19565.217391304348,
      y: 0.17486572265625,
    },
    {
      x: 19569.29347826087,
      y: 0.17486572265625,
    },
    {
      x: 19573.369565217392,
      y: 0.17984771728515625,
    },
    {
      x: 19577.445652173912,
      y: 0.17984771728515625,
    },
    {
      x: 19589.67391304348,
      y: 0.17984771728515625,
    },
    {
      x: 19593.75,
      y: 0.18483352661132812,
    },
    {
      x: 19597.826086956524,
      y: 0.18483352661132812,
    },
    {
      x: 19601.902173913044,
      y: 0.18483352661132812,
    },
    {
      x: 19605.978260869564,
      y: 0.18981552124023438,
    },
    {
      x: 19610.054347826088,
      y: 0.18981552124023438,
    },
    {
      x: 19614.130434782608,
      y: 0.18981552124023438,
    },
    {
      x: 19618.206521739132,
      y: 0.18483352661132812,
    },
    {
      x: 19622.282608695652,
      y: 0.17984771728515625,
    },
    {
      x: 19626.358695652176,
      y: 0.17984771728515625,
    },
    {
      x: 19630.434782608696,
      y: 0.17486572265625,
    },
    {
      x: 19634.51086956522,
      y: 0.17486572265625,
    },
    {
      x: 19638.58695652174,
      y: 0.1698760986328125,
    },
    {
      x: 19642.66304347826,
      y: 0.1698760986328125,
    },
    {
      x: 19646.739130434784,
      y: 0.1698760986328125,
    },
    {
      x: 19650.815217391304,
      y: 0.16489410400390625,
    },
    {
      x: 19654.891304347828,
      y: 0.159912109375,
    },
    {
      x: 19658.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 19663.04347826087,
      y: 0.15492630004882812,
    },
    {
      x: 19667.119565217392,
      y: 0.15492630004882812,
    },
    {
      x: 19671.195652173912,
      y: 0.14994430541992188,
    },
    {
      x: 19675.271739130436,
      y: 0.14994430541992188,
    },
    {
      x: 19679.347826086956,
      y: 0.14496231079101562,
    },
    {
      x: 19683.42391304348,
      y: 0.14496231079101562,
    },
    {
      x: 20005.434782608696,
      y: 0.14496231079101562,
    },
    {
      x: 20009.51086956522,
      y: 0.125030517578125,
    },
    {
      x: 20013.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 20021.739130434784,
      y: 0.06522369384765625,
    },
    {
      x: 20025.815217391304,
      y: 0.050273895263671875,
    },
    {
      x: 20029.891304347828,
      y: 0.03034210205078125,
    },
    {
      x: 20033.967391304348,
      y: 0.015392303466796875,
    },
    {
      x: 20038.04347826087,
      y: -0.004543304443359375,
    },
    {
      x: 20042.119565217392,
      y: -0.024478912353515625,
    },
    {
      x: 20046.195652173912,
      y: -0.034442901611328125,
    },
    {
      x: 20050.271739130436,
      y: -0.029460906982421875,
    },
    {
      x: 20054.347826086956,
      y: -0.01949310302734375,
    },
    {
      x: 20058.42391304348,
      y: 0.0004425048828125,
    },
    {
      x: 20062.5,
      y: 0.015392303466796875,
    },
    {
      x: 20066.576086956524,
      y: 0.0353240966796875,
    },
    {
      x: 20070.652173913044,
      y: 0.05525970458984375,
    },
    {
      x: 20074.728260869564,
      y: 0.0751953125,
    },
    {
      x: 20078.804347826088,
      y: 0.09014511108398438,
    },
    {
      x: 20082.880434782608,
      y: 0.110076904296875,
    },
    {
      x: 20086.956521739132,
      y: 0.16489410400390625,
    },
    {
      x: 20091.032608695652,
      y: 0.1698760986328125,
    },
    {
      x: 20095.108695652176,
      y: 0.1698760986328125,
    },
    {
      x: 20107.33695652174,
      y: 0.1698760986328125,
    },
    {
      x: 20111.41304347826,
      y: 0.17486572265625,
    },
    {
      x: 20115.489130434784,
      y: 0.17486572265625,
    },
    {
      x: 20119.565217391304,
      y: 0.17984771728515625,
    },
    {
      x: 20123.641304347828,
      y: 0.17984771728515625,
    },
    {
      x: 20135.869565217392,
      y: 0.17984771728515625,
    },
    {
      x: 20139.945652173912,
      y: 0.18483352661132812,
    },
    {
      x: 20144.021739130436,
      y: 0.18483352661132812,
    },
    {
      x: 20148.097826086956,
      y: 0.18483352661132812,
    },
    {
      x: 20152.17391304348,
      y: 0.18981552124023438,
    },
    {
      x: 20156.25,
      y: 0.18981552124023438,
    },
    {
      x: 20160.326086956524,
      y: 0.18981552124023438,
    },
    {
      x: 20164.402173913044,
      y: 0.18483352661132812,
    },
    {
      x: 20168.478260869564,
      y: 0.17984771728515625,
    },
    {
      x: 20172.554347826088,
      y: 0.17984771728515625,
    },
    {
      x: 20176.630434782608,
      y: 0.17486572265625,
    },
    {
      x: 20180.706521739132,
      y: 0.17486572265625,
    },
    {
      x: 20184.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 20188.858695652176,
      y: 0.1698760986328125,
    },
    {
      x: 20192.934782608696,
      y: 0.1698760986328125,
    },
    {
      x: 20197.01086956522,
      y: 0.16489410400390625,
    },
    {
      x: 20201.08695652174,
      y: 0.159912109375,
    },
    {
      x: 20205.16304347826,
      y: 0.15492630004882812,
    },
    {
      x: 20209.239130434784,
      y: 0.15492630004882812,
    },
    {
      x: 20213.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 20217.391304347828,
      y: 0.14994430541992188,
    },
    {
      x: 20221.467391304348,
      y: 0.14994430541992188,
    },
    {
      x: 20225.54347826087,
      y: 0.14496231079101562,
    },
    {
      x: 20229.619565217392,
      y: 0.14496231079101562,
    },
    {
      x: 20604.619565217392,
      y: 0.14496231079101562,
    },
    {
      x: 20608.695652173912,
      y: 0.015392303466796875,
    },
    {
      x: 20612.771739130436,
      y: 0.0353240966796875,
    },
    {
      x: 20616.847826086956,
      y: 0.05525970458984375,
    },
    {
      x: 20620.92391304348,
      y: 0.0751953125,
    },
    {
      x: 20625,
      y: 0.09014511108398438,
    },
    {
      x: 20629.076086956524,
      y: 0.110076904296875,
    },
    {
      x: 20633.152173913044,
      y: 0.13001251220703125,
    },
    {
      x: 20637.228260869564,
      y: 0.13997650146484375,
    },
    {
      x: 20641.304347826088,
      y: 0.14496231079101562,
    },
    {
      x: 20645.380434782608,
      y: 0.14496231079101562,
    },
    {
      x: 20857.33695652174,
      y: 0.14496231079101562,
    },
    {
      x: 20861.41304347826,
      y: 0.14994430541992188,
    },
    {
      x: 20865.489130434784,
      y: 0.15492630004882812,
    },
    {
      x: 20869.565217391304,
      y: 0.14496231079101562,
    },
    {
      x: 20873.641304347828,
      y: 0.14496231079101562,
    },
    {
      x: 21334.239130434784,
      y: 0.14496231079101562,
    },
    {
      x: 21338.315217391304,
      y: 0.125030517578125,
    },
    {
      x: 21342.391304347828,
      y: 0.10509490966796875,
    },
    {
      x: 21350.54347826087,
      y: 0.06522369384765625,
    },
    {
      x: 21354.619565217392,
      y: 0.050273895263671875,
    },
    {
      x: 21358.695652173912,
      y: 0.03034210205078125,
    },
    {
      x: 21362.771739130436,
      y: 0.015392303466796875,
    },
    {
      x: 21366.847826086956,
      y: -0.004543304443359375,
    },
    {
      x: 21370.92391304348,
      y: -0.024478912353515625,
    },
    {
      x: 21375,
      y: -0.034442901611328125,
    },
    {
      x: 21379.076086956524,
      y: -0.029460906982421875,
    },
    {
      x: 21383.152173913044,
      y: -0.01949310302734375,
    },
    {
      x: 21387.228260869564,
      y: 0.0004425048828125,
    },
    {
      x: 21391.304347826088,
      y: 0.14496231079101562,
    },
    {
      x: 21395.380434782608,
      y: 0.14496231079101562,
    },
    {
      x: 21403.532608695652,
      y: 0.14496231079101562,
    },
    {
      x: 21407.608695652176,
      y: 0.14994430541992188,
    },
    {
      x: 21411.684782608696,
      y: 0.15492630004882812,
    },
    {
      x: 21415.76086956522,
      y: 0.16489410400390625,
    },
    {
      x: 21419.83695652174,
      y: 0.1698760986328125,
    },
    {
      x: 21423.91304347826,
      y: 0.1698760986328125,
    },
    {
      x: 21436.141304347828,
      y: 0.1698760986328125,
    },
    {
      x: 21440.217391304348,
      y: 0.17486572265625,
    },
    {
      x: 21444.29347826087,
      y: 0.17486572265625,
    },
    {
      x: 21448.369565217392,
      y: 0.17984771728515625,
    },
    {
      x: 21452.445652173912,
      y: 0.17984771728515625,
    },
    {
      x: 21464.67391304348,
      y: 0.17984771728515625,
    },
    {
      x: 21468.75,
      y: 0.18483352661132812,
    },
    {
      x: 21472.826086956524,
      y: 0.18483352661132812,
    },
    {
      x: 21476.902173913044,
      y: 0.18483352661132812,
    },
    {
      x: 21480.978260869564,
      y: 0.18981552124023438,
    },
    {
      x: 21485.054347826088,
      y: 0.18981552124023438,
    },
    {
      x: 21489.130434782608,
      y: 0.18981552124023438,
    },
    {
      x: 21493.206521739132,
      y: 0.18483352661132812,
    },
    {
      x: 21497.282608695652,
      y: 0.17984771728515625,
    },
    {
      x: 21501.358695652176,
      y: 0.17984771728515625,
    },
    {
      x: 21505.434782608696,
      y: 0.17486572265625,
    },
    {
      x: 21509.51086956522,
      y: 0.17486572265625,
    },
    {
      x: 21513.58695652174,
      y: 0.1698760986328125,
    },
    {
      x: 21517.66304347826,
      y: 0.1698760986328125,
    },
    {
      x: 21521.739130434784,
      y: 0.1698760986328125,
    },
    {
      x: 21525.815217391304,
      y: 0.16489410400390625,
    },
    {
      x: 21529.891304347828,
      y: 0.159912109375,
    },
    {
      x: 21533.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 21538.04347826087,
      y: 0.15492630004882812,
    },
    {
      x: 21542.119565217392,
      y: 0.15492630004882812,
    },
    {
      x: 21546.195652173912,
      y: 0.14994430541992188,
    },
    {
      x: 21550.271739130436,
      y: 0.14994430541992188,
    },
    {
      x: 21554.347826086956,
      y: 0.14496231079101562,
    },
    {
      x: 21558.42391304348,
      y: 0.14496231079101562,
    },
    {
      x: 21648.097826086956,
      y: 0.14496231079101562,
    },
    {
      x: 21652.17391304348,
      y: -0.004543304443359375,
    },
    {
      x: 21656.25,
      y: -0.024478912353515625,
    },
    {
      x: 21660.326086956524,
      y: -0.034442901611328125,
    },
    {
      x: 21664.402173913044,
      y: -0.029460906982421875,
    },
    {
      x: 21668.478260869564,
      y: -0.01949310302734375,
    },
    {
      x: 21672.554347826088,
      y: 0.0004425048828125,
    },
    {
      x: 21676.630434782608,
      y: 0.015392303466796875,
    },
    {
      x: 21680.706521739132,
      y: 0.0353240966796875,
    },
    {
      x: 21684.782608695652,
      y: 0.05525970458984375,
    },
    {
      x: 21688.858695652176,
      y: 0.0751953125,
    },
    {
      x: 21692.934782608696,
      y: 0.09014511108398438,
    },
    {
      x: 21697.01086956522,
      y: 0.110076904296875,
    },
    {
      x: 21701.08695652174,
      y: 0.13001251220703125,
    },
    {
      x: 21705.16304347826,
      y: 0.13997650146484375,
    },
    {
      x: 21709.239130434784,
      y: 0.14496231079101562,
    },
    {
      x: 21713.315217391304,
      y: 0.14496231079101562,
    },
    {
      x: 22141.304347826088,
      y: 0.14496231079101562,
    },
    {
      x: 22145.380434782608,
      y: 0.125030517578125,
    },
    {
      x: 22149.456521739132,
      y: 0.10509490966796875,
    },
    {
      x: 22157.608695652176,
      y: 0.06522369384765625,
    },
    {
      x: 22161.684782608696,
      y: 0.050273895263671875,
    },
    {
      x: 22165.76086956522,
      y: 0.03034210205078125,
    },
    {
      x: 22169.83695652174,
      y: 0.015392303466796875,
    },
    {
      x: 22173.91304347826,
      y: 0.14496231079101562,
    },
    {
      x: 22177.989130434784,
      y: 0.14496231079101562,
    },
    {
      x: 22471.467391304348,
      y: 0.14496231079101562,
    },
    {
      x: 22475.54347826087,
      y: 0.14994430541992188,
    },
    {
      x: 22479.619565217392,
      y: 0.15492630004882812,
    },
    {
      x: 22483.695652173912,
      y: 0.16489410400390625,
    },
    {
      x: 22487.771739130436,
      y: 0.1698760986328125,
    },
    {
      x: 22491.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 22504.076086956524,
      y: 0.1698760986328125,
    },
    {
      x: 22508.152173913044,
      y: 0.17486572265625,
    },
    {
      x: 22512.228260869564,
      y: 0.17486572265625,
    },
    {
      x: 22516.304347826088,
      y: 0.17984771728515625,
    },
    {
      x: 22520.380434782608,
      y: 0.17984771728515625,
    },
    {
      x: 22532.608695652176,
      y: 0.17984771728515625,
    },
    {
      x: 22536.684782608696,
      y: 0.18483352661132812,
    },
    {
      x: 22540.76086956522,
      y: 0.18483352661132812,
    },
    {
      x: 22544.83695652174,
      y: 0.18483352661132812,
    },
    {
      x: 22548.91304347826,
      y: 0.18981552124023438,
    },
    {
      x: 22552.989130434784,
      y: 0.18981552124023438,
    },
    {
      x: 22557.065217391304,
      y: 0.18981552124023438,
    },
    {
      x: 22561.141304347828,
      y: 0.18483352661132812,
    },
    {
      x: 22565.217391304348,
      y: 0.17984771728515625,
    },
    {
      x: 22569.29347826087,
      y: 0.17984771728515625,
    },
    {
      x: 22573.369565217392,
      y: 0.17486572265625,
    },
    {
      x: 22577.445652173912,
      y: 0.17486572265625,
    },
    {
      x: 22581.521739130436,
      y: 0.1698760986328125,
    },
    {
      x: 22585.597826086956,
      y: 0.1698760986328125,
    },
    {
      x: 22589.67391304348,
      y: 0.1698760986328125,
    },
    {
      x: 22593.75,
      y: 0.16489410400390625,
    },
    {
      x: 22597.826086956524,
      y: 0.159912109375,
    },
    {
      x: 22601.902173913044,
      y: 0.15492630004882812,
    },
    {
      x: 22605.978260869564,
      y: 0.15492630004882812,
    },
    {
      x: 22610.054347826088,
      y: 0.15492630004882812,
    },
    {
      x: 22614.130434782608,
      y: 0.14994430541992188,
    },
    {
      x: 22618.206521739132,
      y: 0.14994430541992188,
    },
    {
      x: 22622.282608695652,
      y: 0.14496231079101562,
    },
    {
      x: 22626.358695652176,
      y: 0.14496231079101562,
    },
    {
      x: 22691.576086956524,
      y: 0.14496231079101562,
    },
    {
      x: 22695.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 22699.728260869564,
      y: 0.0851593017578125,
    },
    {
      x: 22703.804347826088,
      y: 0.06522369384765625,
    },
    {
      x: 22707.880434782608,
      y: 0.050273895263671875,
    },
    {
      x: 22711.956521739132,
      y: 0.03034210205078125,
    },
    {
      x: 22716.032608695652,
      y: 0.015392303466796875,
    },
    {
      x: 22720.108695652176,
      y: -0.004543304443359375,
    },
    {
      x: 22724.184782608696,
      y: -0.024478912353515625,
    },
    {
      x: 22728.26086956522,
      y: -0.034442901611328125,
    },
    {
      x: 22732.33695652174,
      y: -0.029460906982421875,
    },
    {
      x: 22736.41304347826,
      y: -0.01949310302734375,
    },
    {
      x: 22740.489130434784,
      y: 0.0004425048828125,
    },
    {
      x: 22744.565217391304,
      y: 0.015392303466796875,
    },
    {
      x: 22748.641304347828,
      y: 0.0353240966796875,
    },
    {
      x: 22752.717391304348,
      y: 0.05525970458984375,
    },
    {
      x: 22756.79347826087,
      y: 0.0751953125,
    },
    {
      x: 22760.869565217392,
      y: 0.09014511108398438,
    },
    {
      x: 22764.945652173912,
      y: 0.110076904296875,
    },
    {
      x: 22769.021739130436,
      y: 0.13001251220703125,
    },
    {
      x: 22773.097826086956,
      y: 0.13997650146484375,
    },
    {
      x: 22777.17391304348,
      y: 0.14496231079101562,
    },
    {
      x: 22781.25,
      y: 0.14496231079101562,
    },
    {
      x: 23017.66304347826,
      y: 0.14496231079101562,
    },
    {
      x: 23021.739130434784,
      y: 0.14994430541992188,
    },
    {
      x: 23025.815217391304,
      y: 0.15492630004882812,
    },
    {
      x: 23029.891304347828,
      y: 0.16489410400390625,
    },
    {
      x: 23033.967391304348,
      y: 0.1698760986328125,
    },
    {
      x: 23038.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 23050.271739130436,
      y: 0.1698760986328125,
    },
    {
      x: 23054.347826086956,
      y: 0.17486572265625,
    },
    {
      x: 23058.42391304348,
      y: 0.17486572265625,
    },
    {
      x: 23062.5,
      y: 0.17984771728515625,
    },
    {
      x: 23066.576086956524,
      y: 0.17984771728515625,
    },
    {
      x: 23078.804347826088,
      y: 0.17984771728515625,
    },
    {
      x: 23082.880434782608,
      y: 0.18483352661132812,
    },
    {
      x: 23086.956521739132,
      y: 0.18483352661132812,
    },
    {
      x: 23091.032608695652,
      y: 0.18483352661132812,
    },
    {
      x: 23095.108695652176,
      y: 0.18981552124023438,
    },
    {
      x: 23099.184782608696,
      y: 0.18981552124023438,
    },
    {
      x: 23103.26086956522,
      y: 0.18981552124023438,
    },
    {
      x: 23107.33695652174,
      y: 0.18483352661132812,
    },
    {
      x: 23111.41304347826,
      y: 0.17984771728515625,
    },
    {
      x: 23115.489130434784,
      y: 0.17984771728515625,
    },
    {
      x: 23119.565217391304,
      y: 0.17486572265625,
    },
    {
      x: 23123.641304347828,
      y: 0.17486572265625,
    },
    {
      x: 23127.717391304348,
      y: 0.1698760986328125,
    },
    {
      x: 23131.79347826087,
      y: 0.1698760986328125,
    },
    {
      x: 23135.869565217392,
      y: 0.1698760986328125,
    },
    {
      x: 23139.945652173912,
      y: 0.16489410400390625,
    },
    {
      x: 23144.021739130436,
      y: 0.159912109375,
    },
    {
      x: 23148.097826086956,
      y: 0.15492630004882812,
    },
    {
      x: 23152.17391304348,
      y: 0.15492630004882812,
    },
    {
      x: 23156.25,
      y: 0.15492630004882812,
    },
    {
      x: 23160.326086956524,
      y: 0.14994430541992188,
    },
    {
      x: 23164.402173913044,
      y: 0.14994430541992188,
    },
    {
      x: 23168.478260869564,
      y: 0.14496231079101562,
    },
    {
      x: 23172.554347826088,
      y: 0.14496231079101562,
    },
    {
      x: 23470.108695652176,
      y: 0.14496231079101562,
    },
    {
      x: 23474.184782608696,
      y: 0.125030517578125,
    },
    {
      x: 23478.26086956522,
      y: 0.14496231079101562,
    },
    {
      x: 23482.33695652174,
      y: 0.14496231079101562,
    },
    {
      x: 23755.434782608696,
      y: 0.14496231079101562,
    },
    {
      x: 23759.51086956522,
      y: 0.125030517578125,
    },
    {
      x: 23763.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 23771.739130434784,
      y: 0.06522369384765625,
    },
    {
      x: 23775.815217391304,
      y: 0.050273895263671875,
    },
    {
      x: 23779.891304347828,
      y: 0.03034210205078125,
    },
    {
      x: 23783.967391304348,
      y: 0.015392303466796875,
    },
    {
      x: 23788.04347826087,
      y: -0.004543304443359375,
    },
    {
      x: 23792.119565217392,
      y: -0.024478912353515625,
    },
    {
      x: 23796.195652173912,
      y: -0.034442901611328125,
    },
    {
      x: 23800.271739130436,
      y: -0.029460906982421875,
    },
    {
      x: 23804.347826086956,
      y: -0.01949310302734375,
    },
    {
      x: 23808.42391304348,
      y: 0.0004425048828125,
    },
    {
      x: 23812.5,
      y: 0.015392303466796875,
    },
    {
      x: 23816.576086956524,
      y: 0.0353240966796875,
    },
    {
      x: 23820.652173913044,
      y: 0.05525970458984375,
    },
    {
      x: 23824.728260869564,
      y: 0.0751953125,
    },
    {
      x: 23828.804347826088,
      y: 0.09014511108398438,
    },
    {
      x: 23832.880434782608,
      y: 0.110076904296875,
    },
    {
      x: 23836.956521739132,
      y: 0.13001251220703125,
    },
    {
      x: 23841.032608695652,
      y: 0.13997650146484375,
    },
    {
      x: 23845.108695652176,
      y: 0.14496231079101562,
    },
    {
      x: 23849.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 24000,
      y: 0.14496231079101562,
    },
  ],
  avr: [
    {
      x: 0,
      y: 0.15492630004882812,
    },
    {
      x: 11.71875,
      y: 0.15492630004882812,
    },
    {
      x: 15.625,
      y: 0.13997650146484375,
    },
    {
      x: 19.53125,
      y: 0.09014511108398438,
    },
    {
      x: 23.4375,
      y: 0.03034210205078125,
    },
    {
      x: 39.0625,
      y: -0.20886993408203125,
    },
    {
      x: 42.96875,
      y: -0.26866912841796875,
    },
    {
      x: 46.875,
      y: -0.3284721374511719,
    },
    {
      x: 50.78125,
      y: -0.388275146484375,
    },
    {
      x: 54.6875,
      y: -0.4231605529785156,
    },
    {
      x: 58.59375,
      y: -0.4032249450683594,
    },
    {
      x: 62.5,
      y: -0.3533897399902344,
    },
    {
      x: 66.40625,
      y: -0.2985725402832031,
    },
    {
      x: 70.3125,
      y: -0.24375152587890625,
    },
    {
      x: 74.21875,
      y: -0.18394851684570312,
    },
    {
      x: 78.125,
      y: -0.12913131713867188,
    },
    {
      x: 82.03125,
      y: -0.074310302734375,
    },
    {
      x: 85.9375,
      y: -0.01949310302734375,
    },
    {
      x: 89.84375,
      y: 0.040309906005859375,
    },
    {
      x: 93.75,
      y: 0.0851593017578125,
    },
    {
      x: 97.65625,
      y: 0.10010910034179688,
    },
    {
      x: 101.5625,
      y: 0.10509490966796875,
    },
    {
      x: 105.46875,
      y: 0.110076904296875,
    },
    {
      x: 109.375,
      y: 0.110076904296875,
    },
    {
      x: 304.6875,
      y: 0.110076904296875,
    },
    {
      x: 308.59375,
      y: 0.125030517578125,
    },
    {
      x: 312.5,
      y: 0.15492630004882812,
    },
    {
      x: 316.40625,
      y: 0.19479751586914062,
    },
    {
      x: 320.3125,
      y: 0.21971511840820312,
    },
    {
      x: 324.21875,
      y: 0.2346649169921875,
    },
    {
      x: 328.125,
      y: 0.23964691162109375,
    },
    {
      x: 332.03125,
      y: 0.24462890625,
    },
    {
      x: 335.9375,
      y: 0.25460052490234375,
    },
    {
      x: 339.84375,
      y: 0.2595863342285156,
    },
    {
      x: 343.75,
      y: 0.2645683288574219,
    },
    {
      x: 347.65625,
      y: 0.2695503234863281,
    },
    {
      x: 351.5625,
      y: 0.2745361328125,
    },
    {
      x: 355.46875,
      y: 0.27951812744140625,
    },
    {
      x: 359.375,
      y: 0.2845001220703125,
    },
    {
      x: 363.28125,
      y: 0.2894859313964844,
    },
    {
      x: 367.1875,
      y: 0.2944679260253906,
    },
    {
      x: 371.09375,
      y: 0.2994499206542969,
    },
    {
      x: 375,
      y: 0.30443572998046875,
    },
    {
      x: 378.90625,
      y: 0.309417724609375,
    },
    {
      x: 382.8125,
      y: 0.31439971923828125,
    },
    {
      x: 386.71875,
      y: 0.3193855285644531,
    },
    {
      x: 390.625,
      y: 0.31439971923828125,
    },
    {
      x: 394.53125,
      y: 0.30443572998046875,
    },
    {
      x: 398.4375,
      y: 0.2944679260253906,
    },
    {
      x: 402.34375,
      y: 0.2845001220703125,
    },
    {
      x: 406.25,
      y: 0.27951812744140625,
    },
    {
      x: 410.15625,
      y: 0.2695503234863281,
    },
    {
      x: 414.0625,
      y: 0.2595863342285156,
    },
    {
      x: 417.96875,
      y: 0.24961471557617188,
    },
    {
      x: 421.875,
      y: 0.24462890625,
    },
    {
      x: 425.78125,
      y: 0.2346649169921875,
    },
    {
      x: 429.6875,
      y: 0.22469711303710938,
    },
    {
      x: 433.59375,
      y: 0.21971511840820312,
    },
    {
      x: 437.5,
      y: 0.209747314453125,
    },
    {
      x: 441.40625,
      y: 0.1997833251953125,
    },
    {
      x: 445.3125,
      y: 0.19479751586914062,
    },
    {
      x: 449.21875,
      y: 0.18483352661132812,
    },
    {
      x: 453.125,
      y: 0.17486572265625,
    },
    {
      x: 457.03125,
      y: 0.17486572265625,
    },
    {
      x: 460.9375,
      y: 0.1698760986328125,
    },
    {
      x: 464.84375,
      y: 0.1698760986328125,
    },
    {
      x: 472.65625,
      y: 0.1698760986328125,
    },
    {
      x: 476.5625,
      y: 0.16489410400390625,
    },
    {
      x: 480.46875,
      y: 0.16489410400390625,
    },
    {
      x: 503.90625,
      y: 0.16489410400390625,
    },
    {
      x: 507.8125,
      y: 0.159912109375,
    },
    {
      x: 511.71875,
      y: 0.159912109375,
    },
    {
      x: 535.15625,
      y: 0.159912109375,
    },
    {
      x: 539.0625,
      y: 0.15492630004882812,
    },
    {
      x: 542.96875,
      y: 0.15492630004882812,
    },
    {
      x: 996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 1000,
      y: 0.16489410400390625,
    },
    {
      x: 1003.90625,
      y: 0.16489410400390625,
    },
    {
      x: 1027.34375,
      y: 0.16489410400390625,
    },
    {
      x: 1031.25,
      y: 0.159912109375,
    },
    {
      x: 1035.15625,
      y: 0.159912109375,
    },
    {
      x: 1058.59375,
      y: 0.159912109375,
    },
    {
      x: 1062.5,
      y: 0.15492630004882812,
    },
    {
      x: 1066.40625,
      y: 0.15492630004882812,
    },
    {
      x: 1246.09375,
      y: 0.15492630004882812,
    },
    {
      x: 1250,
      y: 0.110076904296875,
    },
    {
      x: 1253.90625,
      y: 0.110076904296875,
    },
    {
      x: 1328.125,
      y: 0.110076904296875,
    },
    {
      x: 1332.03125,
      y: 0.125030517578125,
    },
    {
      x: 1335.9375,
      y: 0.15492630004882812,
    },
    {
      x: 1339.84375,
      y: 0.19479751586914062,
    },
    {
      x: 1343.75,
      y: 0.21971511840820312,
    },
    {
      x: 1347.65625,
      y: 0.2346649169921875,
    },
    {
      x: 1351.5625,
      y: 0.23964691162109375,
    },
    {
      x: 1355.46875,
      y: 0.24462890625,
    },
    {
      x: 1359.375,
      y: 0.25460052490234375,
    },
    {
      x: 1363.28125,
      y: 0.2595863342285156,
    },
    {
      x: 1367.1875,
      y: 0.2645683288574219,
    },
    {
      x: 1371.09375,
      y: 0.2695503234863281,
    },
    {
      x: 1375,
      y: 0.2745361328125,
    },
    {
      x: 1378.90625,
      y: 0.27951812744140625,
    },
    {
      x: 1382.8125,
      y: 0.2845001220703125,
    },
    {
      x: 1386.71875,
      y: 0.2894859313964844,
    },
    {
      x: 1390.625,
      y: 0.2944679260253906,
    },
    {
      x: 1394.53125,
      y: 0.2994499206542969,
    },
    {
      x: 1398.4375,
      y: 0.30443572998046875,
    },
    {
      x: 1402.34375,
      y: 0.309417724609375,
    },
    {
      x: 1406.25,
      y: 0.31439971923828125,
    },
    {
      x: 1410.15625,
      y: 0.31439971923828125,
    },
    {
      x: 1414.0625,
      y: 0.31439971923828125,
    },
    {
      x: 1417.96875,
      y: 0.30443572998046875,
    },
    {
      x: 1421.875,
      y: 0.2944679260253906,
    },
    {
      x: 1425.78125,
      y: 0.2845001220703125,
    },
    {
      x: 1429.6875,
      y: 0.27951812744140625,
    },
    {
      x: 1433.59375,
      y: 0.2695503234863281,
    },
    {
      x: 1437.5,
      y: 0.2595863342285156,
    },
    {
      x: 1441.40625,
      y: 0.24961471557617188,
    },
    {
      x: 1445.3125,
      y: 0.24462890625,
    },
    {
      x: 1449.21875,
      y: 0.2346649169921875,
    },
    {
      x: 1453.125,
      y: 0.22469711303710938,
    },
    {
      x: 1457.03125,
      y: 0.21971511840820312,
    },
    {
      x: 1460.9375,
      y: 0.209747314453125,
    },
    {
      x: 1464.84375,
      y: 0.1997833251953125,
    },
    {
      x: 1468.75,
      y: 0.19479751586914062,
    },
    {
      x: 1472.65625,
      y: 0.18483352661132812,
    },
    {
      x: 1476.5625,
      y: 0.17486572265625,
    },
    {
      x: 1480.46875,
      y: 0.17486572265625,
    },
    {
      x: 1484.375,
      y: 0.1698760986328125,
    },
    {
      x: 1488.28125,
      y: 0.1698760986328125,
    },
    {
      x: 1496.09375,
      y: 0.1698760986328125,
    },
    {
      x: 1500,
      y: 0.15492630004882812,
    },
    {
      x: 1503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 1535.15625,
      y: 0.15492630004882812,
    },
    {
      x: 1539.0625,
      y: 0.13997650146484375,
    },
    {
      x: 1542.96875,
      y: 0.09014511108398438,
    },
    {
      x: 1546.875,
      y: 0.03034210205078125,
    },
    {
      x: 1562.5,
      y: -0.20886993408203125,
    },
    {
      x: 1566.40625,
      y: -0.26866912841796875,
    },
    {
      x: 1570.3125,
      y: -0.3284721374511719,
    },
    {
      x: 1574.21875,
      y: -0.388275146484375,
    },
    {
      x: 1578.125,
      y: -0.4231605529785156,
    },
    {
      x: 1582.03125,
      y: -0.4032249450683594,
    },
    {
      x: 1585.9375,
      y: -0.3533897399902344,
    },
    {
      x: 1589.84375,
      y: -0.2985725402832031,
    },
    {
      x: 1593.75,
      y: -0.24375152587890625,
    },
    {
      x: 1597.65625,
      y: -0.18394851684570312,
    },
    {
      x: 1601.5625,
      y: -0.12913131713867188,
    },
    {
      x: 1605.46875,
      y: -0.074310302734375,
    },
    {
      x: 1609.375,
      y: -0.01949310302734375,
    },
    {
      x: 1613.28125,
      y: 0.040309906005859375,
    },
    {
      x: 1617.1875,
      y: 0.0851593017578125,
    },
    {
      x: 1621.09375,
      y: 0.10010910034179688,
    },
    {
      x: 1625,
      y: 0.10509490966796875,
    },
    {
      x: 1628.90625,
      y: 0.110076904296875,
    },
    {
      x: 1632.8125,
      y: 0.110076904296875,
    },
    {
      x: 1746.09375,
      y: 0.110076904296875,
    },
    {
      x: 1750,
      y: 0.15492630004882812,
    },
    {
      x: 1753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 1996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 2000,
      y: 0.17486572265625,
    },
    {
      x: 2003.90625,
      y: 0.17486572265625,
    },
    {
      x: 2007.8125,
      y: 0.1698760986328125,
    },
    {
      x: 2011.71875,
      y: 0.1698760986328125,
    },
    {
      x: 2019.53125,
      y: 0.1698760986328125,
    },
    {
      x: 2023.4375,
      y: 0.16489410400390625,
    },
    {
      x: 2027.34375,
      y: 0.16489410400390625,
    },
    {
      x: 2050.78125,
      y: 0.16489410400390625,
    },
    {
      x: 2054.6875,
      y: 0.159912109375,
    },
    {
      x: 2058.59375,
      y: 0.159912109375,
    },
    {
      x: 2082.03125,
      y: 0.159912109375,
    },
    {
      x: 2085.9375,
      y: 0.15492630004882812,
    },
    {
      x: 2089.84375,
      y: 0.15492630004882812,
    },
    {
      x: 2246.09375,
      y: 0.15492630004882812,
    },
    {
      x: 2250,
      y: 0.110076904296875,
    },
    {
      x: 2253.90625,
      y: 0.110076904296875,
    },
    {
      x: 2351.5625,
      y: 0.110076904296875,
    },
    {
      x: 2355.46875,
      y: 0.125030517578125,
    },
    {
      x: 2359.375,
      y: 0.15492630004882812,
    },
    {
      x: 2363.28125,
      y: 0.19479751586914062,
    },
    {
      x: 2367.1875,
      y: 0.21971511840820312,
    },
    {
      x: 2371.09375,
      y: 0.2346649169921875,
    },
    {
      x: 2375,
      y: 0.23964691162109375,
    },
    {
      x: 2378.90625,
      y: 0.24462890625,
    },
    {
      x: 2382.8125,
      y: 0.25460052490234375,
    },
    {
      x: 2386.71875,
      y: 0.2595863342285156,
    },
    {
      x: 2390.625,
      y: 0.2645683288574219,
    },
    {
      x: 2394.53125,
      y: 0.2695503234863281,
    },
    {
      x: 2398.4375,
      y: 0.2745361328125,
    },
    {
      x: 2402.34375,
      y: 0.27951812744140625,
    },
    {
      x: 2406.25,
      y: 0.2845001220703125,
    },
    {
      x: 2410.15625,
      y: 0.2894859313964844,
    },
    {
      x: 2414.0625,
      y: 0.2944679260253906,
    },
    {
      x: 2417.96875,
      y: 0.2994499206542969,
    },
    {
      x: 2421.875,
      y: 0.30443572998046875,
    },
    {
      x: 2425.78125,
      y: 0.309417724609375,
    },
    {
      x: 2429.6875,
      y: 0.31439971923828125,
    },
    {
      x: 2433.59375,
      y: 0.31439971923828125,
    },
    {
      x: 2437.5,
      y: 0.31439971923828125,
    },
    {
      x: 2441.40625,
      y: 0.30443572998046875,
    },
    {
      x: 2445.3125,
      y: 0.2944679260253906,
    },
    {
      x: 2449.21875,
      y: 0.2845001220703125,
    },
    {
      x: 2453.125,
      y: 0.27951812744140625,
    },
    {
      x: 2457.03125,
      y: 0.2695503234863281,
    },
    {
      x: 2460.9375,
      y: 0.2595863342285156,
    },
    {
      x: 2464.84375,
      y: 0.24961471557617188,
    },
    {
      x: 2468.75,
      y: 0.24462890625,
    },
    {
      x: 2472.65625,
      y: 0.2346649169921875,
    },
    {
      x: 2476.5625,
      y: 0.22469711303710938,
    },
    {
      x: 2480.46875,
      y: 0.21971511840820312,
    },
    {
      x: 2484.375,
      y: 0.209747314453125,
    },
    {
      x: 2488.28125,
      y: 0.1997833251953125,
    },
    {
      x: 2492.1875,
      y: 0.19479751586914062,
    },
    {
      x: 2496.09375,
      y: 0.18483352661132812,
    },
    {
      x: 2500,
      y: 0.15492630004882812,
    },
    {
      x: 2503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 2558.59375,
      y: 0.15492630004882812,
    },
    {
      x: 2562.5,
      y: 0.13997650146484375,
    },
    {
      x: 2566.40625,
      y: 0.09014511108398438,
    },
    {
      x: 2570.3125,
      y: 0.03034210205078125,
    },
    {
      x: 2585.9375,
      y: -0.20886993408203125,
    },
    {
      x: 2589.84375,
      y: -0.26866912841796875,
    },
    {
      x: 2593.75,
      y: -0.3284721374511719,
    },
    {
      x: 2597.65625,
      y: -0.388275146484375,
    },
    {
      x: 2601.5625,
      y: -0.4231605529785156,
    },
    {
      x: 2605.46875,
      y: -0.4032249450683594,
    },
    {
      x: 2609.375,
      y: -0.3533897399902344,
    },
    {
      x: 2613.28125,
      y: -0.2985725402832031,
    },
    {
      x: 2617.1875,
      y: -0.24375152587890625,
    },
    {
      x: 2621.09375,
      y: -0.18394851684570312,
    },
    {
      x: 2625,
      y: -0.12913131713867188,
    },
    {
      x: 2628.90625,
      y: -0.074310302734375,
    },
    {
      x: 2632.8125,
      y: -0.01949310302734375,
    },
    {
      x: 2636.71875,
      y: 0.040309906005859375,
    },
    {
      x: 2640.625,
      y: 0.0851593017578125,
    },
    {
      x: 2644.53125,
      y: 0.10010910034179688,
    },
    {
      x: 2648.4375,
      y: 0.10509490966796875,
    },
    {
      x: 2652.34375,
      y: 0.110076904296875,
    },
    {
      x: 2656.25,
      y: 0.110076904296875,
    },
    {
      x: 2746.09375,
      y: 0.110076904296875,
    },
    {
      x: 2750,
      y: 0.15492630004882812,
    },
    {
      x: 2753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 2996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 3000,
      y: 0.110076904296875,
    },
    {
      x: 3003.90625,
      y: 0.110076904296875,
    },
    {
      x: 3125,
      y: 0.110076904296875,
    },
    {
      x: 3128.90625,
      y: 0.125030517578125,
    },
    {
      x: 3132.8125,
      y: 0.15492630004882812,
    },
    {
      x: 3136.71875,
      y: 0.19479751586914062,
    },
    {
      x: 3140.625,
      y: 0.21971511840820312,
    },
    {
      x: 3144.53125,
      y: 0.2346649169921875,
    },
    {
      x: 3148.4375,
      y: 0.23964691162109375,
    },
    {
      x: 3152.34375,
      y: 0.24462890625,
    },
    {
      x: 3156.25,
      y: 0.25460052490234375,
    },
    {
      x: 3160.15625,
      y: 0.2595863342285156,
    },
    {
      x: 3164.0625,
      y: 0.2645683288574219,
    },
    {
      x: 3167.96875,
      y: 0.2695503234863281,
    },
    {
      x: 3171.875,
      y: 0.2745361328125,
    },
    {
      x: 3175.78125,
      y: 0.27951812744140625,
    },
    {
      x: 3179.6875,
      y: 0.2845001220703125,
    },
    {
      x: 3183.59375,
      y: 0.2894859313964844,
    },
    {
      x: 3187.5,
      y: 0.2944679260253906,
    },
    {
      x: 3191.40625,
      y: 0.2994499206542969,
    },
    {
      x: 3195.3125,
      y: 0.30443572998046875,
    },
    {
      x: 3199.21875,
      y: 0.309417724609375,
    },
    {
      x: 3203.125,
      y: 0.31439971923828125,
    },
    {
      x: 3207.03125,
      y: 0.31439971923828125,
    },
    {
      x: 3210.9375,
      y: 0.31439971923828125,
    },
    {
      x: 3214.84375,
      y: 0.30443572998046875,
    },
    {
      x: 3218.75,
      y: 0.2944679260253906,
    },
    {
      x: 3222.65625,
      y: 0.2845001220703125,
    },
    {
      x: 3226.5625,
      y: 0.27951812744140625,
    },
    {
      x: 3230.46875,
      y: 0.2695503234863281,
    },
    {
      x: 3234.375,
      y: 0.2595863342285156,
    },
    {
      x: 3238.28125,
      y: 0.24961471557617188,
    },
    {
      x: 3242.1875,
      y: 0.24462890625,
    },
    {
      x: 3246.09375,
      y: 0.2346649169921875,
    },
    {
      x: 3250,
      y: 0.22469711303710938,
    },
    {
      x: 3253.90625,
      y: 0.21971511840820312,
    },
    {
      x: 3257.8125,
      y: 0.209747314453125,
    },
    {
      x: 3261.71875,
      y: 0.1997833251953125,
    },
    {
      x: 3265.625,
      y: 0.19479751586914062,
    },
    {
      x: 3269.53125,
      y: 0.18483352661132812,
    },
    {
      x: 3273.4375,
      y: 0.17486572265625,
    },
    {
      x: 3277.34375,
      y: 0.17486572265625,
    },
    {
      x: 3281.25,
      y: 0.1698760986328125,
    },
    {
      x: 3285.15625,
      y: 0.1698760986328125,
    },
    {
      x: 3292.96875,
      y: 0.1698760986328125,
    },
    {
      x: 3296.875,
      y: 0.16489410400390625,
    },
    {
      x: 3300.78125,
      y: 0.16489410400390625,
    },
    {
      x: 3324.21875,
      y: 0.16489410400390625,
    },
    {
      x: 3328.125,
      y: 0.159912109375,
    },
    {
      x: 3332.03125,
      y: 0.159912109375,
    },
    {
      x: 3355.46875,
      y: 0.159912109375,
    },
    {
      x: 3359.375,
      y: 0.15492630004882812,
    },
    {
      x: 3363.28125,
      y: 0.15492630004882812,
    },
    {
      x: 3582.03125,
      y: 0.15492630004882812,
    },
    {
      x: 3585.9375,
      y: 0.13997650146484375,
    },
    {
      x: 3589.84375,
      y: 0.09014511108398438,
    },
    {
      x: 3593.75,
      y: 0.03034210205078125,
    },
    {
      x: 3609.375,
      y: -0.20886993408203125,
    },
    {
      x: 3613.28125,
      y: -0.26866912841796875,
    },
    {
      x: 3617.1875,
      y: -0.3284721374511719,
    },
    {
      x: 3621.09375,
      y: -0.388275146484375,
    },
    {
      x: 3625,
      y: -0.4231605529785156,
    },
    {
      x: 3628.90625,
      y: -0.4032249450683594,
    },
    {
      x: 3632.8125,
      y: -0.3533897399902344,
    },
    {
      x: 3636.71875,
      y: -0.2985725402832031,
    },
    {
      x: 3640.625,
      y: -0.24375152587890625,
    },
    {
      x: 3644.53125,
      y: -0.18394851684570312,
    },
    {
      x: 3648.4375,
      y: -0.12913131713867188,
    },
    {
      x: 3652.34375,
      y: -0.074310302734375,
    },
    {
      x: 3656.25,
      y: -0.01949310302734375,
    },
    {
      x: 3660.15625,
      y: 0.040309906005859375,
    },
    {
      x: 3664.0625,
      y: 0.0851593017578125,
    },
    {
      x: 3667.96875,
      y: 0.10010910034179688,
    },
    {
      x: 3671.875,
      y: 0.10509490966796875,
    },
    {
      x: 3675.78125,
      y: 0.110076904296875,
    },
    {
      x: 3679.6875,
      y: 0.110076904296875,
    },
    {
      x: 3746.09375,
      y: 0.110076904296875,
    },
    {
      x: 3750,
      y: 0.15492630004882812,
    },
    {
      x: 3753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 3996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 4000,
      y: 0.27951812744140625,
    },
    {
      x: 4003.90625,
      y: 0.2695503234863281,
    },
    {
      x: 4007.8125,
      y: 0.2595863342285156,
    },
    {
      x: 4011.71875,
      y: 0.24961471557617188,
    },
    {
      x: 4015.625,
      y: 0.24462890625,
    },
    {
      x: 4019.53125,
      y: 0.2346649169921875,
    },
    {
      x: 4023.4375,
      y: 0.22469711303710938,
    },
    {
      x: 4027.34375,
      y: 0.21971511840820312,
    },
    {
      x: 4031.25,
      y: 0.209747314453125,
    },
    {
      x: 4035.15625,
      y: 0.1997833251953125,
    },
    {
      x: 4039.0625,
      y: 0.19479751586914062,
    },
    {
      x: 4042.96875,
      y: 0.18483352661132812,
    },
    {
      x: 4046.875,
      y: 0.17486572265625,
    },
    {
      x: 4050.78125,
      y: 0.17486572265625,
    },
    {
      x: 4054.6875,
      y: 0.1698760986328125,
    },
    {
      x: 4058.59375,
      y: 0.1698760986328125,
    },
    {
      x: 4066.40625,
      y: 0.1698760986328125,
    },
    {
      x: 4070.3125,
      y: 0.16489410400390625,
    },
    {
      x: 4074.21875,
      y: 0.16489410400390625,
    },
    {
      x: 4097.65625,
      y: 0.16489410400390625,
    },
    {
      x: 4101.5625,
      y: 0.159912109375,
    },
    {
      x: 4105.46875,
      y: 0.159912109375,
    },
    {
      x: 4128.90625,
      y: 0.159912109375,
    },
    {
      x: 4132.8125,
      y: 0.15492630004882812,
    },
    {
      x: 4136.71875,
      y: 0.15492630004882812,
    },
    {
      x: 4246.09375,
      y: 0.15492630004882812,
    },
    {
      x: 4250,
      y: 0.110076904296875,
    },
    {
      x: 4253.90625,
      y: 0.110076904296875,
    },
    {
      x: 4398.4375,
      y: 0.110076904296875,
    },
    {
      x: 4402.34375,
      y: 0.125030517578125,
    },
    {
      x: 4406.25,
      y: 0.15492630004882812,
    },
    {
      x: 4410.15625,
      y: 0.19479751586914062,
    },
    {
      x: 4414.0625,
      y: 0.21971511840820312,
    },
    {
      x: 4417.96875,
      y: 0.2346649169921875,
    },
    {
      x: 4421.875,
      y: 0.23964691162109375,
    },
    {
      x: 4425.78125,
      y: 0.24462890625,
    },
    {
      x: 4429.6875,
      y: 0.25460052490234375,
    },
    {
      x: 4433.59375,
      y: 0.2595863342285156,
    },
    {
      x: 4437.5,
      y: 0.2645683288574219,
    },
    {
      x: 4441.40625,
      y: 0.2695503234863281,
    },
    {
      x: 4445.3125,
      y: 0.2745361328125,
    },
    {
      x: 4449.21875,
      y: 0.27951812744140625,
    },
    {
      x: 4453.125,
      y: 0.2845001220703125,
    },
    {
      x: 4457.03125,
      y: 0.2894859313964844,
    },
    {
      x: 4460.9375,
      y: 0.2944679260253906,
    },
    {
      x: 4464.84375,
      y: 0.2994499206542969,
    },
    {
      x: 4468.75,
      y: 0.30443572998046875,
    },
    {
      x: 4472.65625,
      y: 0.309417724609375,
    },
    {
      x: 4476.5625,
      y: 0.31439971923828125,
    },
    {
      x: 4480.46875,
      y: 0.3193855285644531,
    },
    {
      x: 4484.375,
      y: 0.31439971923828125,
    },
    {
      x: 4488.28125,
      y: 0.30443572998046875,
    },
    {
      x: 4492.1875,
      y: 0.2944679260253906,
    },
    {
      x: 4496.09375,
      y: 0.2845001220703125,
    },
    {
      x: 4500,
      y: 0.15492630004882812,
    },
    {
      x: 4503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 4605.46875,
      y: 0.15492630004882812,
    },
    {
      x: 4609.375,
      y: 0.13997650146484375,
    },
    {
      x: 4613.28125,
      y: 0.09014511108398438,
    },
    {
      x: 4617.1875,
      y: 0.03034210205078125,
    },
    {
      x: 4632.8125,
      y: -0.20886993408203125,
    },
    {
      x: 4636.71875,
      y: -0.26866912841796875,
    },
    {
      x: 4640.625,
      y: -0.3284721374511719,
    },
    {
      x: 4644.53125,
      y: -0.388275146484375,
    },
    {
      x: 4648.4375,
      y: -0.4231605529785156,
    },
    {
      x: 4652.34375,
      y: -0.4032249450683594,
    },
    {
      x: 4656.25,
      y: -0.3533897399902344,
    },
    {
      x: 4660.15625,
      y: -0.2985725402832031,
    },
    {
      x: 4664.0625,
      y: -0.24375152587890625,
    },
    {
      x: 4667.96875,
      y: -0.18394851684570312,
    },
    {
      x: 4671.875,
      y: -0.12913131713867188,
    },
    {
      x: 4675.78125,
      y: -0.074310302734375,
    },
    {
      x: 4679.6875,
      y: -0.01949310302734375,
    },
    {
      x: 4683.59375,
      y: 0.040309906005859375,
    },
    {
      x: 4687.5,
      y: 0.0851593017578125,
    },
    {
      x: 4691.40625,
      y: 0.10010910034179688,
    },
    {
      x: 4695.3125,
      y: 0.10509490966796875,
    },
    {
      x: 4699.21875,
      y: 0.110076904296875,
    },
    {
      x: 4703.125,
      y: 0.110076904296875,
    },
    {
      x: 4746.09375,
      y: 0.110076904296875,
    },
    {
      x: 4750,
      y: 0.15492630004882812,
    },
    {
      x: 4753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 4996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 5000,
      y: 0.31439971923828125,
    },
    {
      x: 5003.90625,
      y: 0.31439971923828125,
    },
    {
      x: 5007.8125,
      y: 0.31439971923828125,
    },
    {
      x: 5011.71875,
      y: 0.30443572998046875,
    },
    {
      x: 5015.625,
      y: 0.2944679260253906,
    },
    {
      x: 5019.53125,
      y: 0.2845001220703125,
    },
    {
      x: 5023.4375,
      y: 0.27951812744140625,
    },
    {
      x: 5027.34375,
      y: 0.2695503234863281,
    },
    {
      x: 5031.25,
      y: 0.2595863342285156,
    },
    {
      x: 5035.15625,
      y: 0.24961471557617188,
    },
    {
      x: 5039.0625,
      y: 0.24462890625,
    },
    {
      x: 5042.96875,
      y: 0.2346649169921875,
    },
    {
      x: 5046.875,
      y: 0.22469711303710938,
    },
    {
      x: 5050.78125,
      y: 0.21971511840820312,
    },
    {
      x: 5054.6875,
      y: 0.209747314453125,
    },
    {
      x: 5058.59375,
      y: 0.1997833251953125,
    },
    {
      x: 5062.5,
      y: 0.19479751586914062,
    },
    {
      x: 5066.40625,
      y: 0.18483352661132812,
    },
    {
      x: 5070.3125,
      y: 0.17486572265625,
    },
    {
      x: 5074.21875,
      y: 0.17486572265625,
    },
    {
      x: 5078.125,
      y: 0.1698760986328125,
    },
    {
      x: 5082.03125,
      y: 0.1698760986328125,
    },
    {
      x: 5089.84375,
      y: 0.1698760986328125,
    },
    {
      x: 5093.75,
      y: 0.16489410400390625,
    },
    {
      x: 5097.65625,
      y: 0.16489410400390625,
    },
    {
      x: 5121.09375,
      y: 0.16489410400390625,
    },
    {
      x: 5125,
      y: 0.159912109375,
    },
    {
      x: 5128.90625,
      y: 0.159912109375,
    },
    {
      x: 5152.34375,
      y: 0.159912109375,
    },
    {
      x: 5156.25,
      y: 0.15492630004882812,
    },
    {
      x: 5160.15625,
      y: 0.15492630004882812,
    },
    {
      x: 5246.09375,
      y: 0.15492630004882812,
    },
    {
      x: 5250,
      y: 0.110076904296875,
    },
    {
      x: 5253.90625,
      y: 0.110076904296875,
    },
    {
      x: 5421.875,
      y: 0.110076904296875,
    },
    {
      x: 5425.78125,
      y: 0.125030517578125,
    },
    {
      x: 5429.6875,
      y: 0.15492630004882812,
    },
    {
      x: 5433.59375,
      y: 0.19479751586914062,
    },
    {
      x: 5437.5,
      y: 0.21971511840820312,
    },
    {
      x: 5441.40625,
      y: 0.2346649169921875,
    },
    {
      x: 5445.3125,
      y: 0.23964691162109375,
    },
    {
      x: 5449.21875,
      y: 0.24462890625,
    },
    {
      x: 5453.125,
      y: 0.25460052490234375,
    },
    {
      x: 5457.03125,
      y: 0.2595863342285156,
    },
    {
      x: 5460.9375,
      y: 0.2645683288574219,
    },
    {
      x: 5464.84375,
      y: 0.2695503234863281,
    },
    {
      x: 5468.75,
      y: 0.2745361328125,
    },
    {
      x: 5472.65625,
      y: 0.27951812744140625,
    },
    {
      x: 5476.5625,
      y: 0.2845001220703125,
    },
    {
      x: 5480.46875,
      y: 0.2894859313964844,
    },
    {
      x: 5484.375,
      y: 0.2944679260253906,
    },
    {
      x: 5488.28125,
      y: 0.2994499206542969,
    },
    {
      x: 5492.1875,
      y: 0.30443572998046875,
    },
    {
      x: 5496.09375,
      y: 0.309417724609375,
    },
    {
      x: 5500,
      y: 0.15492630004882812,
    },
    {
      x: 5503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 5628.90625,
      y: 0.15492630004882812,
    },
    {
      x: 5632.8125,
      y: 0.13997650146484375,
    },
    {
      x: 5636.71875,
      y: 0.09014511108398438,
    },
    {
      x: 5640.625,
      y: 0.03034210205078125,
    },
    {
      x: 5656.25,
      y: -0.20886993408203125,
    },
    {
      x: 5660.15625,
      y: -0.26866912841796875,
    },
    {
      x: 5664.0625,
      y: -0.3284721374511719,
    },
    {
      x: 5667.96875,
      y: -0.388275146484375,
    },
    {
      x: 5671.875,
      y: -0.4231605529785156,
    },
    {
      x: 5675.78125,
      y: -0.4032249450683594,
    },
    {
      x: 5679.6875,
      y: -0.3533897399902344,
    },
    {
      x: 5683.59375,
      y: -0.2985725402832031,
    },
    {
      x: 5687.5,
      y: -0.24375152587890625,
    },
    {
      x: 5691.40625,
      y: -0.18394851684570312,
    },
    {
      x: 5695.3125,
      y: -0.12913131713867188,
    },
    {
      x: 5699.21875,
      y: -0.074310302734375,
    },
    {
      x: 5703.125,
      y: -0.01949310302734375,
    },
    {
      x: 5707.03125,
      y: 0.040309906005859375,
    },
    {
      x: 5710.9375,
      y: 0.0851593017578125,
    },
    {
      x: 5714.84375,
      y: 0.10010910034179688,
    },
    {
      x: 5718.75,
      y: 0.10509490966796875,
    },
    {
      x: 5722.65625,
      y: 0.110076904296875,
    },
    {
      x: 5726.5625,
      y: 0.110076904296875,
    },
    {
      x: 5746.09375,
      y: 0.110076904296875,
    },
    {
      x: 5750,
      y: 0.15492630004882812,
    },
    {
      x: 5753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 5996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 6000,
      y: 0.110076904296875,
    },
    {
      x: 6003.90625,
      y: 0.110076904296875,
    },
    {
      x: 6195.3125,
      y: 0.110076904296875,
    },
    {
      x: 6199.21875,
      y: 0.125030517578125,
    },
    {
      x: 6203.125,
      y: 0.15492630004882812,
    },
    {
      x: 6207.03125,
      y: 0.19479751586914062,
    },
    {
      x: 6210.9375,
      y: 0.21971511840820312,
    },
    {
      x: 6214.84375,
      y: 0.2346649169921875,
    },
    {
      x: 6218.75,
      y: 0.23964691162109375,
    },
    {
      x: 6222.65625,
      y: 0.24462890625,
    },
    {
      x: 6226.5625,
      y: 0.25460052490234375,
    },
    {
      x: 6230.46875,
      y: 0.2595863342285156,
    },
    {
      x: 6234.375,
      y: 0.2645683288574219,
    },
    {
      x: 6238.28125,
      y: 0.2695503234863281,
    },
    {
      x: 6242.1875,
      y: 0.2745361328125,
    },
    {
      x: 6246.09375,
      y: 0.27951812744140625,
    },
    {
      x: 6250,
      y: 0.2845001220703125,
    },
    {
      x: 6253.90625,
      y: 0.2894859313964844,
    },
    {
      x: 6257.8125,
      y: 0.2944679260253906,
    },
    {
      x: 6261.71875,
      y: 0.2994499206542969,
    },
    {
      x: 6265.625,
      y: 0.30443572998046875,
    },
    {
      x: 6269.53125,
      y: 0.309417724609375,
    },
    {
      x: 6273.4375,
      y: 0.31439971923828125,
    },
    {
      x: 6277.34375,
      y: 0.31439971923828125,
    },
    {
      x: 6281.25,
      y: 0.31439971923828125,
    },
    {
      x: 6285.15625,
      y: 0.30443572998046875,
    },
    {
      x: 6289.0625,
      y: 0.2944679260253906,
    },
    {
      x: 6292.96875,
      y: 0.2845001220703125,
    },
    {
      x: 6296.875,
      y: 0.27951812744140625,
    },
    {
      x: 6300.78125,
      y: 0.2695503234863281,
    },
    {
      x: 6304.6875,
      y: 0.2595863342285156,
    },
    {
      x: 6308.59375,
      y: 0.24961471557617188,
    },
    {
      x: 6312.5,
      y: 0.24462890625,
    },
    {
      x: 6316.40625,
      y: 0.2346649169921875,
    },
    {
      x: 6320.3125,
      y: 0.22469711303710938,
    },
    {
      x: 6324.21875,
      y: 0.21971511840820312,
    },
    {
      x: 6328.125,
      y: 0.209747314453125,
    },
    {
      x: 6332.03125,
      y: 0.1997833251953125,
    },
    {
      x: 6335.9375,
      y: 0.19479751586914062,
    },
    {
      x: 6339.84375,
      y: 0.18483352661132812,
    },
    {
      x: 6343.75,
      y: 0.17486572265625,
    },
    {
      x: 6347.65625,
      y: 0.17486572265625,
    },
    {
      x: 6351.5625,
      y: 0.1698760986328125,
    },
    {
      x: 6355.46875,
      y: 0.1698760986328125,
    },
    {
      x: 6363.28125,
      y: 0.1698760986328125,
    },
    {
      x: 6367.1875,
      y: 0.16489410400390625,
    },
    {
      x: 6371.09375,
      y: 0.16489410400390625,
    },
    {
      x: 6394.53125,
      y: 0.16489410400390625,
    },
    {
      x: 6398.4375,
      y: 0.159912109375,
    },
    {
      x: 6402.34375,
      y: 0.159912109375,
    },
    {
      x: 6425.78125,
      y: 0.159912109375,
    },
    {
      x: 6429.6875,
      y: 0.15492630004882812,
    },
    {
      x: 6433.59375,
      y: 0.15492630004882812,
    },
    {
      x: 6652.34375,
      y: 0.15492630004882812,
    },
    {
      x: 6656.25,
      y: 0.13997650146484375,
    },
    {
      x: 6660.15625,
      y: 0.09014511108398438,
    },
    {
      x: 6664.0625,
      y: 0.03034210205078125,
    },
    {
      x: 6679.6875,
      y: -0.20886993408203125,
    },
    {
      x: 6683.59375,
      y: -0.26866912841796875,
    },
    {
      x: 6687.5,
      y: -0.3284721374511719,
    },
    {
      x: 6691.40625,
      y: -0.388275146484375,
    },
    {
      x: 6695.3125,
      y: -0.4231605529785156,
    },
    {
      x: 6699.21875,
      y: -0.4032249450683594,
    },
    {
      x: 6703.125,
      y: -0.3533897399902344,
    },
    {
      x: 6707.03125,
      y: -0.2985725402832031,
    },
    {
      x: 6710.9375,
      y: -0.24375152587890625,
    },
    {
      x: 6714.84375,
      y: -0.18394851684570312,
    },
    {
      x: 6718.75,
      y: -0.12913131713867188,
    },
    {
      x: 6722.65625,
      y: -0.074310302734375,
    },
    {
      x: 6726.5625,
      y: -0.01949310302734375,
    },
    {
      x: 6730.46875,
      y: 0.040309906005859375,
    },
    {
      x: 6734.375,
      y: 0.0851593017578125,
    },
    {
      x: 6738.28125,
      y: 0.10010910034179688,
    },
    {
      x: 6742.1875,
      y: 0.10509490966796875,
    },
    {
      x: 6746.09375,
      y: 0.110076904296875,
    },
    {
      x: 6750,
      y: 0.15492630004882812,
    },
    {
      x: 6753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 6996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 7000,
      y: 0.25460052490234375,
    },
    {
      x: 7003.90625,
      y: 0.2595863342285156,
    },
    {
      x: 7007.8125,
      y: 0.2645683288574219,
    },
    {
      x: 7011.71875,
      y: 0.2695503234863281,
    },
    {
      x: 7015.625,
      y: 0.2745361328125,
    },
    {
      x: 7019.53125,
      y: 0.27951812744140625,
    },
    {
      x: 7023.4375,
      y: 0.2845001220703125,
    },
    {
      x: 7027.34375,
      y: 0.2894859313964844,
    },
    {
      x: 7031.25,
      y: 0.2944679260253906,
    },
    {
      x: 7035.15625,
      y: 0.2994499206542969,
    },
    {
      x: 7039.0625,
      y: 0.30443572998046875,
    },
    {
      x: 7042.96875,
      y: 0.309417724609375,
    },
    {
      x: 7046.875,
      y: 0.31439971923828125,
    },
    {
      x: 7050.78125,
      y: 0.31439971923828125,
    },
    {
      x: 7054.6875,
      y: 0.31439971923828125,
    },
    {
      x: 7058.59375,
      y: 0.30443572998046875,
    },
    {
      x: 7062.5,
      y: 0.2944679260253906,
    },
    {
      x: 7066.40625,
      y: 0.2845001220703125,
    },
    {
      x: 7070.3125,
      y: 0.27951812744140625,
    },
    {
      x: 7074.21875,
      y: 0.2695503234863281,
    },
    {
      x: 7078.125,
      y: 0.2595863342285156,
    },
    {
      x: 7082.03125,
      y: 0.24961471557617188,
    },
    {
      x: 7085.9375,
      y: 0.24462890625,
    },
    {
      x: 7089.84375,
      y: 0.2346649169921875,
    },
    {
      x: 7093.75,
      y: 0.22469711303710938,
    },
    {
      x: 7097.65625,
      y: 0.21971511840820312,
    },
    {
      x: 7101.5625,
      y: 0.209747314453125,
    },
    {
      x: 7105.46875,
      y: 0.1997833251953125,
    },
    {
      x: 7109.375,
      y: 0.19479751586914062,
    },
    {
      x: 7113.28125,
      y: 0.18483352661132812,
    },
    {
      x: 7117.1875,
      y: 0.17486572265625,
    },
    {
      x: 7121.09375,
      y: 0.17486572265625,
    },
    {
      x: 7125,
      y: 0.1698760986328125,
    },
    {
      x: 7128.90625,
      y: 0.1698760986328125,
    },
    {
      x: 7136.71875,
      y: 0.1698760986328125,
    },
    {
      x: 7140.625,
      y: 0.16489410400390625,
    },
    {
      x: 7144.53125,
      y: 0.16489410400390625,
    },
    {
      x: 7167.96875,
      y: 0.16489410400390625,
    },
    {
      x: 7171.875,
      y: 0.159912109375,
    },
    {
      x: 7175.78125,
      y: 0.159912109375,
    },
    {
      x: 7199.21875,
      y: 0.159912109375,
    },
    {
      x: 7203.125,
      y: 0.15492630004882812,
    },
    {
      x: 7207.03125,
      y: 0.15492630004882812,
    },
    {
      x: 7246.09375,
      y: 0.15492630004882812,
    },
    {
      x: 7250,
      y: -0.01949310302734375,
    },
    {
      x: 7253.90625,
      y: 0.040309906005859375,
    },
    {
      x: 7257.8125,
      y: 0.0851593017578125,
    },
    {
      x: 7261.71875,
      y: 0.10010910034179688,
    },
    {
      x: 7265.625,
      y: 0.10509490966796875,
    },
    {
      x: 7269.53125,
      y: 0.110076904296875,
    },
    {
      x: 7273.4375,
      y: 0.110076904296875,
    },
    {
      x: 7468.75,
      y: 0.110076904296875,
    },
    {
      x: 7472.65625,
      y: 0.125030517578125,
    },
    {
      x: 7476.5625,
      y: 0.15492630004882812,
    },
    {
      x: 7480.46875,
      y: 0.19479751586914062,
    },
    {
      x: 7484.375,
      y: 0.21971511840820312,
    },
    {
      x: 7488.28125,
      y: 0.2346649169921875,
    },
    {
      x: 7492.1875,
      y: 0.23964691162109375,
    },
    {
      x: 7496.09375,
      y: 0.24462890625,
    },
    {
      x: 7500,
      y: 0.15492630004882812,
    },
    {
      x: 7503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 7675.78125,
      y: 0.15492630004882812,
    },
    {
      x: 7679.6875,
      y: 0.13997650146484375,
    },
    {
      x: 7683.59375,
      y: 0.09014511108398438,
    },
    {
      x: 7687.5,
      y: 0.03034210205078125,
    },
    {
      x: 7703.125,
      y: -0.20886993408203125,
    },
    {
      x: 7707.03125,
      y: -0.26866912841796875,
    },
    {
      x: 7710.9375,
      y: -0.3284721374511719,
    },
    {
      x: 7714.84375,
      y: -0.388275146484375,
    },
    {
      x: 7718.75,
      y: -0.4231605529785156,
    },
    {
      x: 7722.65625,
      y: -0.4032249450683594,
    },
    {
      x: 7726.5625,
      y: -0.3533897399902344,
    },
    {
      x: 7730.46875,
      y: -0.2985725402832031,
    },
    {
      x: 7734.375,
      y: -0.24375152587890625,
    },
    {
      x: 7738.28125,
      y: -0.18394851684570312,
    },
    {
      x: 7742.1875,
      y: -0.12913131713867188,
    },
    {
      x: 7746.09375,
      y: -0.074310302734375,
    },
    {
      x: 7750,
      y: 0.15492630004882812,
    },
    {
      x: 7753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 8199.21875,
      y: 0.15492630004882812,
    },
    {
      x: 8203.125,
      y: 0.13997650146484375,
    },
    {
      x: 8207.03125,
      y: 0.09014511108398438,
    },
    {
      x: 8210.9375,
      y: 0.03034210205078125,
    },
    {
      x: 8226.5625,
      y: -0.20886993408203125,
    },
    {
      x: 8230.46875,
      y: -0.26866912841796875,
    },
    {
      x: 8234.375,
      y: -0.3284721374511719,
    },
    {
      x: 8238.28125,
      y: -0.388275146484375,
    },
    {
      x: 8242.1875,
      y: -0.4231605529785156,
    },
    {
      x: 8246.09375,
      y: -0.4032249450683594,
    },
    {
      x: 8250,
      y: 0.15492630004882812,
    },
    {
      x: 8253.90625,
      y: 0.19479751586914062,
    },
    {
      x: 8257.8125,
      y: 0.21971511840820312,
    },
    {
      x: 8261.71875,
      y: 0.2346649169921875,
    },
    {
      x: 8265.625,
      y: 0.23964691162109375,
    },
    {
      x: 8269.53125,
      y: 0.24462890625,
    },
    {
      x: 8273.4375,
      y: 0.25460052490234375,
    },
    {
      x: 8277.34375,
      y: 0.2595863342285156,
    },
    {
      x: 8281.25,
      y: 0.2645683288574219,
    },
    {
      x: 8285.15625,
      y: 0.2695503234863281,
    },
    {
      x: 8289.0625,
      y: 0.2745361328125,
    },
    {
      x: 8292.96875,
      y: 0.27951812744140625,
    },
    {
      x: 8296.875,
      y: 0.2845001220703125,
    },
    {
      x: 8300.78125,
      y: 0.2894859313964844,
    },
    {
      x: 8304.6875,
      y: 0.2944679260253906,
    },
    {
      x: 8308.59375,
      y: 0.2994499206542969,
    },
    {
      x: 8312.5,
      y: 0.30443572998046875,
    },
    {
      x: 8316.40625,
      y: 0.309417724609375,
    },
    {
      x: 8320.3125,
      y: 0.31439971923828125,
    },
    {
      x: 8324.21875,
      y: 0.3193855285644531,
    },
    {
      x: 8328.125,
      y: 0.31439971923828125,
    },
    {
      x: 8332.03125,
      y: 0.30443572998046875,
    },
    {
      x: 8335.9375,
      y: 0.2944679260253906,
    },
    {
      x: 8339.84375,
      y: 0.2845001220703125,
    },
    {
      x: 8343.75,
      y: 0.27951812744140625,
    },
    {
      x: 8347.65625,
      y: 0.2695503234863281,
    },
    {
      x: 8351.5625,
      y: 0.2595863342285156,
    },
    {
      x: 8355.46875,
      y: 0.24961471557617188,
    },
    {
      x: 8359.375,
      y: 0.24462890625,
    },
    {
      x: 8363.28125,
      y: 0.2346649169921875,
    },
    {
      x: 8367.1875,
      y: 0.22469711303710938,
    },
    {
      x: 8371.09375,
      y: 0.21971511840820312,
    },
    {
      x: 8375,
      y: 0.209747314453125,
    },
    {
      x: 8378.90625,
      y: 0.1997833251953125,
    },
    {
      x: 8382.8125,
      y: 0.19479751586914062,
    },
    {
      x: 8386.71875,
      y: 0.18483352661132812,
    },
    {
      x: 8390.625,
      y: 0.17486572265625,
    },
    {
      x: 8394.53125,
      y: 0.17486572265625,
    },
    {
      x: 8398.4375,
      y: 0.1698760986328125,
    },
    {
      x: 8402.34375,
      y: 0.1698760986328125,
    },
    {
      x: 8410.15625,
      y: 0.1698760986328125,
    },
    {
      x: 8414.0625,
      y: 0.16489410400390625,
    },
    {
      x: 8417.96875,
      y: 0.16489410400390625,
    },
    {
      x: 8441.40625,
      y: 0.16489410400390625,
    },
    {
      x: 8445.3125,
      y: 0.159912109375,
    },
    {
      x: 8449.21875,
      y: 0.159912109375,
    },
    {
      x: 8472.65625,
      y: 0.159912109375,
    },
    {
      x: 8476.5625,
      y: 0.15492630004882812,
    },
    {
      x: 8480.46875,
      y: 0.15492630004882812,
    },
    {
      x: 8496.09375,
      y: 0.15492630004882812,
    },
    {
      x: 8500,
      y: -0.3533897399902344,
    },
    {
      x: 8503.90625,
      y: -0.2985725402832031,
    },
    {
      x: 8507.8125,
      y: -0.24375152587890625,
    },
    {
      x: 8511.71875,
      y: -0.18394851684570312,
    },
    {
      x: 8515.625,
      y: -0.12913131713867188,
    },
    {
      x: 8519.53125,
      y: -0.074310302734375,
    },
    {
      x: 8523.4375,
      y: -0.01949310302734375,
    },
    {
      x: 8527.34375,
      y: 0.040309906005859375,
    },
    {
      x: 8531.25,
      y: 0.0851593017578125,
    },
    {
      x: 8535.15625,
      y: 0.10010910034179688,
    },
    {
      x: 8539.0625,
      y: 0.10509490966796875,
    },
    {
      x: 8542.96875,
      y: 0.110076904296875,
    },
    {
      x: 8546.875,
      y: 0.110076904296875,
    },
    {
      x: 8742.1875,
      y: 0.110076904296875,
    },
    {
      x: 8746.09375,
      y: 0.125030517578125,
    },
    {
      x: 8750,
      y: 0.15492630004882812,
    },
    {
      x: 8753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 8996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 9000,
      y: 0.110076904296875,
    },
    {
      x: 9003.90625,
      y: 0.110076904296875,
    },
    {
      x: 9015.625,
      y: 0.110076904296875,
    },
    {
      x: 9019.53125,
      y: 0.125030517578125,
    },
    {
      x: 9023.4375,
      y: 0.15492630004882812,
    },
    {
      x: 9027.34375,
      y: 0.19479751586914062,
    },
    {
      x: 9031.25,
      y: 0.21971511840820312,
    },
    {
      x: 9035.15625,
      y: 0.2346649169921875,
    },
    {
      x: 9039.0625,
      y: 0.23964691162109375,
    },
    {
      x: 9042.96875,
      y: 0.24462890625,
    },
    {
      x: 9046.875,
      y: 0.25460052490234375,
    },
    {
      x: 9050.78125,
      y: 0.2595863342285156,
    },
    {
      x: 9054.6875,
      y: 0.2645683288574219,
    },
    {
      x: 9058.59375,
      y: 0.2695503234863281,
    },
    {
      x: 9062.5,
      y: 0.2745361328125,
    },
    {
      x: 9066.40625,
      y: 0.27951812744140625,
    },
    {
      x: 9070.3125,
      y: 0.2845001220703125,
    },
    {
      x: 9074.21875,
      y: 0.2894859313964844,
    },
    {
      x: 9078.125,
      y: 0.2944679260253906,
    },
    {
      x: 9082.03125,
      y: 0.2994499206542969,
    },
    {
      x: 9085.9375,
      y: 0.30443572998046875,
    },
    {
      x: 9089.84375,
      y: 0.309417724609375,
    },
    {
      x: 9093.75,
      y: 0.31439971923828125,
    },
    {
      x: 9097.65625,
      y: 0.31439971923828125,
    },
    {
      x: 9101.5625,
      y: 0.31439971923828125,
    },
    {
      x: 9105.46875,
      y: 0.30443572998046875,
    },
    {
      x: 9109.375,
      y: 0.2944679260253906,
    },
    {
      x: 9113.28125,
      y: 0.2845001220703125,
    },
    {
      x: 9117.1875,
      y: 0.27951812744140625,
    },
    {
      x: 9121.09375,
      y: 0.2695503234863281,
    },
    {
      x: 9125,
      y: 0.2595863342285156,
    },
    {
      x: 9128.90625,
      y: 0.24961471557617188,
    },
    {
      x: 9132.8125,
      y: 0.24462890625,
    },
    {
      x: 9136.71875,
      y: 0.2346649169921875,
    },
    {
      x: 9140.625,
      y: 0.22469711303710938,
    },
    {
      x: 9144.53125,
      y: 0.21971511840820312,
    },
    {
      x: 9148.4375,
      y: 0.209747314453125,
    },
    {
      x: 9152.34375,
      y: 0.1997833251953125,
    },
    {
      x: 9156.25,
      y: 0.19479751586914062,
    },
    {
      x: 9160.15625,
      y: 0.18483352661132812,
    },
    {
      x: 9164.0625,
      y: 0.17486572265625,
    },
    {
      x: 9167.96875,
      y: 0.17486572265625,
    },
    {
      x: 9171.875,
      y: 0.1698760986328125,
    },
    {
      x: 9175.78125,
      y: 0.1698760986328125,
    },
    {
      x: 9183.59375,
      y: 0.1698760986328125,
    },
    {
      x: 9187.5,
      y: 0.16489410400390625,
    },
    {
      x: 9191.40625,
      y: 0.16489410400390625,
    },
    {
      x: 9214.84375,
      y: 0.16489410400390625,
    },
    {
      x: 9218.75,
      y: 0.159912109375,
    },
    {
      x: 9222.65625,
      y: 0.159912109375,
    },
    {
      x: 9246.09375,
      y: 0.159912109375,
    },
    {
      x: 9250,
      y: -0.20886993408203125,
    },
    {
      x: 9253.90625,
      y: -0.26866912841796875,
    },
    {
      x: 9257.8125,
      y: -0.3284721374511719,
    },
    {
      x: 9261.71875,
      y: -0.388275146484375,
    },
    {
      x: 9265.625,
      y: -0.4231605529785156,
    },
    {
      x: 9269.53125,
      y: -0.4032249450683594,
    },
    {
      x: 9273.4375,
      y: -0.3533897399902344,
    },
    {
      x: 9277.34375,
      y: -0.2985725402832031,
    },
    {
      x: 9281.25,
      y: -0.24375152587890625,
    },
    {
      x: 9285.15625,
      y: -0.18394851684570312,
    },
    {
      x: 9289.0625,
      y: -0.12913131713867188,
    },
    {
      x: 9292.96875,
      y: -0.074310302734375,
    },
    {
      x: 9296.875,
      y: -0.01949310302734375,
    },
    {
      x: 9300.78125,
      y: 0.040309906005859375,
    },
    {
      x: 9304.6875,
      y: 0.0851593017578125,
    },
    {
      x: 9308.59375,
      y: 0.10010910034179688,
    },
    {
      x: 9312.5,
      y: 0.10509490966796875,
    },
    {
      x: 9316.40625,
      y: 0.110076904296875,
    },
    {
      x: 9320.3125,
      y: 0.110076904296875,
    },
    {
      x: 9496.09375,
      y: 0.110076904296875,
    },
    {
      x: 9500,
      y: 0.15492630004882812,
    },
    {
      x: 9503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 9722.65625,
      y: 0.15492630004882812,
    },
    {
      x: 9726.5625,
      y: 0.13997650146484375,
    },
    {
      x: 9730.46875,
      y: 0.09014511108398438,
    },
    {
      x: 9734.375,
      y: 0.03034210205078125,
    },
    {
      x: 9746.09375,
      y: -0.14906692504882812,
    },
    {
      x: 9750,
      y: 0.159912109375,
    },
    {
      x: 9753.90625,
      y: 0.159912109375,
    },
    {
      x: 9769.53125,
      y: 0.159912109375,
    },
    {
      x: 9773.4375,
      y: 0.15492630004882812,
    },
    {
      x: 9777.34375,
      y: 0.15492630004882812,
    },
    {
      x: 9996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 10000,
      y: 0.13997650146484375,
    },
    {
      x: 10003.90625,
      y: 0.09014511108398438,
    },
    {
      x: 10007.8125,
      y: 0.03034210205078125,
    },
    {
      x: 10023.4375,
      y: -0.20886993408203125,
    },
    {
      x: 10027.34375,
      y: -0.26866912841796875,
    },
    {
      x: 10031.25,
      y: -0.3284721374511719,
    },
    {
      x: 10035.15625,
      y: -0.388275146484375,
    },
    {
      x: 10039.0625,
      y: -0.4231605529785156,
    },
    {
      x: 10042.96875,
      y: -0.4032249450683594,
    },
    {
      x: 10046.875,
      y: -0.3533897399902344,
    },
    {
      x: 10050.78125,
      y: -0.2985725402832031,
    },
    {
      x: 10054.6875,
      y: -0.24375152587890625,
    },
    {
      x: 10058.59375,
      y: -0.18394851684570312,
    },
    {
      x: 10062.5,
      y: -0.12913131713867188,
    },
    {
      x: 10066.40625,
      y: -0.074310302734375,
    },
    {
      x: 10070.3125,
      y: -0.01949310302734375,
    },
    {
      x: 10074.21875,
      y: 0.040309906005859375,
    },
    {
      x: 10078.125,
      y: 0.0851593017578125,
    },
    {
      x: 10082.03125,
      y: 0.10010910034179688,
    },
    {
      x: 10085.9375,
      y: 0.10509490966796875,
    },
    {
      x: 10089.84375,
      y: 0.110076904296875,
    },
    {
      x: 10093.75,
      y: 0.110076904296875,
    },
    {
      x: 10246.09375,
      y: 0.110076904296875,
    },
    {
      x: 10250,
      y: 0.15492630004882812,
    },
    {
      x: 10253.90625,
      y: 0.15492630004882812,
    },
    {
      x: 10496.09375,
      y: 0.15492630004882812,
    },
    {
      x: 10500,
      y: 0.110076904296875,
    },
    {
      x: 10503.90625,
      y: 0.110076904296875,
    },
    {
      x: 10539.0625,
      y: 0.110076904296875,
    },
    {
      x: 10542.96875,
      y: 0.125030517578125,
    },
    {
      x: 10546.875,
      y: 0.15492630004882812,
    },
    {
      x: 10550.78125,
      y: 0.19479751586914062,
    },
    {
      x: 10554.6875,
      y: 0.21971511840820312,
    },
    {
      x: 10558.59375,
      y: 0.2346649169921875,
    },
    {
      x: 10562.5,
      y: 0.23964691162109375,
    },
    {
      x: 10566.40625,
      y: 0.24462890625,
    },
    {
      x: 10570.3125,
      y: 0.25460052490234375,
    },
    {
      x: 10574.21875,
      y: 0.2595863342285156,
    },
    {
      x: 10578.125,
      y: 0.2645683288574219,
    },
    {
      x: 10582.03125,
      y: 0.2695503234863281,
    },
    {
      x: 10585.9375,
      y: 0.2745361328125,
    },
    {
      x: 10589.84375,
      y: 0.27951812744140625,
    },
    {
      x: 10593.75,
      y: 0.2845001220703125,
    },
    {
      x: 10597.65625,
      y: 0.2894859313964844,
    },
    {
      x: 10601.5625,
      y: 0.2944679260253906,
    },
    {
      x: 10605.46875,
      y: 0.2994499206542969,
    },
    {
      x: 10609.375,
      y: 0.30443572998046875,
    },
    {
      x: 10613.28125,
      y: 0.309417724609375,
    },
    {
      x: 10617.1875,
      y: 0.31439971923828125,
    },
    {
      x: 10621.09375,
      y: 0.31439971923828125,
    },
    {
      x: 10625,
      y: 0.31439971923828125,
    },
    {
      x: 10628.90625,
      y: 0.30443572998046875,
    },
    {
      x: 10632.8125,
      y: 0.2944679260253906,
    },
    {
      x: 10636.71875,
      y: 0.2845001220703125,
    },
    {
      x: 10640.625,
      y: 0.27951812744140625,
    },
    {
      x: 10644.53125,
      y: 0.2695503234863281,
    },
    {
      x: 10648.4375,
      y: 0.2595863342285156,
    },
    {
      x: 10652.34375,
      y: 0.24961471557617188,
    },
    {
      x: 10656.25,
      y: 0.24462890625,
    },
    {
      x: 10660.15625,
      y: 0.2346649169921875,
    },
    {
      x: 10664.0625,
      y: 0.22469711303710938,
    },
    {
      x: 10667.96875,
      y: 0.21971511840820312,
    },
    {
      x: 10671.875,
      y: 0.209747314453125,
    },
    {
      x: 10675.78125,
      y: 0.1997833251953125,
    },
    {
      x: 10679.6875,
      y: 0.19479751586914062,
    },
    {
      x: 10683.59375,
      y: 0.18483352661132812,
    },
    {
      x: 10687.5,
      y: 0.17486572265625,
    },
    {
      x: 10691.40625,
      y: 0.17486572265625,
    },
    {
      x: 10695.3125,
      y: 0.1698760986328125,
    },
    {
      x: 10699.21875,
      y: 0.1698760986328125,
    },
    {
      x: 10707.03125,
      y: 0.1698760986328125,
    },
    {
      x: 10710.9375,
      y: 0.16489410400390625,
    },
    {
      x: 10714.84375,
      y: 0.16489410400390625,
    },
    {
      x: 10738.28125,
      y: 0.16489410400390625,
    },
    {
      x: 10742.1875,
      y: 0.159912109375,
    },
    {
      x: 10746.09375,
      y: 0.159912109375,
    },
    {
      x: 10750,
      y: 0.16489410400390625,
    },
    {
      x: 10753.90625,
      y: 0.16489410400390625,
    },
    {
      x: 10761.71875,
      y: 0.16489410400390625,
    },
    {
      x: 10765.625,
      y: 0.159912109375,
    },
    {
      x: 10769.53125,
      y: 0.159912109375,
    },
    {
      x: 10792.96875,
      y: 0.159912109375,
    },
    {
      x: 10796.875,
      y: 0.15492630004882812,
    },
    {
      x: 10800.78125,
      y: 0.15492630004882812,
    },
    {
      x: 10996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 11000,
      y: 0.1698760986328125,
    },
    {
      x: 11003.90625,
      y: 0.1698760986328125,
    },
    {
      x: 11011.71875,
      y: 0.1698760986328125,
    },
    {
      x: 11015.625,
      y: 0.16489410400390625,
    },
    {
      x: 11019.53125,
      y: 0.16489410400390625,
    },
    {
      x: 11042.96875,
      y: 0.16489410400390625,
    },
    {
      x: 11046.875,
      y: 0.159912109375,
    },
    {
      x: 11050.78125,
      y: 0.159912109375,
    },
    {
      x: 11074.21875,
      y: 0.159912109375,
    },
    {
      x: 11078.125,
      y: 0.15492630004882812,
    },
    {
      x: 11082.03125,
      y: 0.15492630004882812,
    },
    {
      x: 11496.09375,
      y: 0.15492630004882812,
    },
    {
      x: 11500,
      y: 0.110076904296875,
    },
    {
      x: 11503.90625,
      y: 0.110076904296875,
    },
    {
      x: 11562.5,
      y: 0.110076904296875,
    },
    {
      x: 11566.40625,
      y: 0.125030517578125,
    },
    {
      x: 11570.3125,
      y: 0.15492630004882812,
    },
    {
      x: 11574.21875,
      y: 0.19479751586914062,
    },
    {
      x: 11578.125,
      y: 0.21971511840820312,
    },
    {
      x: 11582.03125,
      y: 0.2346649169921875,
    },
    {
      x: 11585.9375,
      y: 0.23964691162109375,
    },
    {
      x: 11589.84375,
      y: 0.24462890625,
    },
    {
      x: 11593.75,
      y: 0.25460052490234375,
    },
    {
      x: 11597.65625,
      y: 0.2595863342285156,
    },
    {
      x: 11601.5625,
      y: 0.2645683288574219,
    },
    {
      x: 11605.46875,
      y: 0.2695503234863281,
    },
    {
      x: 11609.375,
      y: 0.2745361328125,
    },
    {
      x: 11613.28125,
      y: 0.27951812744140625,
    },
    {
      x: 11617.1875,
      y: 0.2845001220703125,
    },
    {
      x: 11621.09375,
      y: 0.2894859313964844,
    },
    {
      x: 11625,
      y: 0.2944679260253906,
    },
    {
      x: 11628.90625,
      y: 0.2994499206542969,
    },
    {
      x: 11632.8125,
      y: 0.30443572998046875,
    },
    {
      x: 11636.71875,
      y: 0.309417724609375,
    },
    {
      x: 11640.625,
      y: 0.31439971923828125,
    },
    {
      x: 11644.53125,
      y: 0.31439971923828125,
    },
    {
      x: 11648.4375,
      y: 0.31439971923828125,
    },
    {
      x: 11652.34375,
      y: 0.30443572998046875,
    },
    {
      x: 11656.25,
      y: 0.2944679260253906,
    },
    {
      x: 11660.15625,
      y: 0.2845001220703125,
    },
    {
      x: 11664.0625,
      y: 0.27951812744140625,
    },
    {
      x: 11667.96875,
      y: 0.2695503234863281,
    },
    {
      x: 11671.875,
      y: 0.2595863342285156,
    },
    {
      x: 11675.78125,
      y: 0.24961471557617188,
    },
    {
      x: 11679.6875,
      y: 0.24462890625,
    },
    {
      x: 11683.59375,
      y: 0.2346649169921875,
    },
    {
      x: 11687.5,
      y: 0.22469711303710938,
    },
    {
      x: 11691.40625,
      y: 0.21971511840820312,
    },
    {
      x: 11695.3125,
      y: 0.209747314453125,
    },
    {
      x: 11699.21875,
      y: 0.1997833251953125,
    },
    {
      x: 11703.125,
      y: 0.19479751586914062,
    },
    {
      x: 11707.03125,
      y: 0.18483352661132812,
    },
    {
      x: 11710.9375,
      y: 0.17486572265625,
    },
    {
      x: 11714.84375,
      y: 0.17486572265625,
    },
    {
      x: 11718.75,
      y: 0.1698760986328125,
    },
    {
      x: 11722.65625,
      y: 0.1698760986328125,
    },
    {
      x: 11730.46875,
      y: 0.1698760986328125,
    },
    {
      x: 11734.375,
      y: 0.16489410400390625,
    },
    {
      x: 11738.28125,
      y: 0.16489410400390625,
    },
    {
      x: 11746.09375,
      y: 0.16489410400390625,
    },
    {
      x: 11750,
      y: 0.15492630004882812,
    },
    {
      x: 11753.90625,
      y: 0.15492630004882812,
    },
    {
      x: 11769.53125,
      y: 0.15492630004882812,
    },
    {
      x: 11773.4375,
      y: 0.13997650146484375,
    },
    {
      x: 11777.34375,
      y: 0.09014511108398438,
    },
    {
      x: 11781.25,
      y: 0.03034210205078125,
    },
    {
      x: 11796.875,
      y: -0.20886993408203125,
    },
    {
      x: 11800.78125,
      y: -0.26866912841796875,
    },
    {
      x: 11804.6875,
      y: -0.3284721374511719,
    },
    {
      x: 11808.59375,
      y: -0.388275146484375,
    },
    {
      x: 11812.5,
      y: -0.4231605529785156,
    },
    {
      x: 11816.40625,
      y: -0.4032249450683594,
    },
    {
      x: 11820.3125,
      y: -0.3533897399902344,
    },
    {
      x: 11824.21875,
      y: -0.2985725402832031,
    },
    {
      x: 11828.125,
      y: -0.24375152587890625,
    },
    {
      x: 11832.03125,
      y: -0.18394851684570312,
    },
    {
      x: 11835.9375,
      y: -0.12913131713867188,
    },
    {
      x: 11839.84375,
      y: -0.074310302734375,
    },
    {
      x: 11843.75,
      y: -0.01949310302734375,
    },
    {
      x: 11847.65625,
      y: 0.040309906005859375,
    },
    {
      x: 11851.5625,
      y: 0.0851593017578125,
    },
    {
      x: 11855.46875,
      y: 0.10010910034179688,
    },
    {
      x: 11859.375,
      y: 0.10509490966796875,
    },
    {
      x: 11863.28125,
      y: 0.110076904296875,
    },
    {
      x: 11867.1875,
      y: 0.110076904296875,
    },
    {
      x: 11996.09375,
      y: 0.110076904296875,
    },
    {
      x: 12000,
      y: 0.15492630004882812,
    },
    {
      x: 12003.90625,
      y: 0.15492630004882812,
    },
    {
      x: 12042.96875,
      y: 0.15492630004882812,
    },
    {
      x: 12046.875,
      y: 0.13997650146484375,
    },
    {
      x: 12050.78125,
      y: 0.09014511108398438,
    },
    {
      x: 12054.6875,
      y: 0.03034210205078125,
    },
    {
      x: 12070.3125,
      y: -0.20886993408203125,
    },
    {
      x: 12074.21875,
      y: -0.26866912841796875,
    },
    {
      x: 12078.125,
      y: -0.3284721374511719,
    },
    {
      x: 12082.03125,
      y: -0.388275146484375,
    },
    {
      x: 12085.9375,
      y: -0.4231605529785156,
    },
    {
      x: 12089.84375,
      y: -0.4032249450683594,
    },
    {
      x: 12093.75,
      y: -0.3533897399902344,
    },
    {
      x: 12097.65625,
      y: -0.2985725402832031,
    },
    {
      x: 12101.5625,
      y: -0.24375152587890625,
    },
    {
      x: 12105.46875,
      y: -0.188934326171875,
    },
    {
      x: 12109.375,
      y: -0.13411712646484375,
    },
    {
      x: 12113.28125,
      y: -0.074310302734375,
    },
    {
      x: 12117.1875,
      y: -0.01949310302734375,
    },
    {
      x: 12121.09375,
      y: 0.0353240966796875,
    },
    {
      x: 12125,
      y: 0.08017730712890625,
    },
    {
      x: 12128.90625,
      y: 0.09512710571289062,
    },
    {
      x: 12132.8125,
      y: 0.10010910034179688,
    },
    {
      x: 12136.71875,
      y: 0.10509490966796875,
    },
    {
      x: 12140.625,
      y: 0.10509490966796875,
    },
    {
      x: 12335.9375,
      y: 0.10509490966796875,
    },
    {
      x: 12339.84375,
      y: 0.11506271362304688,
    },
    {
      x: 12343.75,
      y: 0.15492630004882812,
    },
    {
      x: 12347.65625,
      y: 0.19479751586914062,
    },
    {
      x: 12351.5625,
      y: 0.21971511840820312,
    },
    {
      x: 12355.46875,
      y: 0.2346649169921875,
    },
    {
      x: 12359.375,
      y: 0.23964691162109375,
    },
    {
      x: 12363.28125,
      y: 0.24961471557617188,
    },
    {
      x: 12367.1875,
      y: 0.25460052490234375,
    },
    {
      x: 12371.09375,
      y: 0.2595863342285156,
    },
    {
      x: 12375,
      y: 0.2645683288574219,
    },
    {
      x: 12378.90625,
      y: 0.2695503234863281,
    },
    {
      x: 12382.8125,
      y: 0.2745361328125,
    },
    {
      x: 12386.71875,
      y: 0.27951812744140625,
    },
    {
      x: 12390.625,
      y: 0.2845001220703125,
    },
    {
      x: 12394.53125,
      y: 0.2944679260253906,
    },
    {
      x: 12398.4375,
      y: 0.2994499206542969,
    },
    {
      x: 12402.34375,
      y: 0.30443572998046875,
    },
    {
      x: 12406.25,
      y: 0.309417724609375,
    },
    {
      x: 12410.15625,
      y: 0.31439971923828125,
    },
    {
      x: 12414.0625,
      y: 0.3193855285644531,
    },
    {
      x: 12417.96875,
      y: 0.3193855285644531,
    },
    {
      x: 12421.875,
      y: 0.31439971923828125,
    },
    {
      x: 12425.78125,
      y: 0.30443572998046875,
    },
    {
      x: 12429.6875,
      y: 0.2994499206542969,
    },
    {
      x: 12433.59375,
      y: 0.2894859313964844,
    },
    {
      x: 12437.5,
      y: 0.27951812744140625,
    },
    {
      x: 12441.40625,
      y: 0.2695503234863281,
    },
    {
      x: 12445.3125,
      y: 0.2595863342285156,
    },
    {
      x: 12449.21875,
      y: 0.25460052490234375,
    },
    {
      x: 12453.125,
      y: 0.24462890625,
    },
    {
      x: 12457.03125,
      y: 0.2346649169921875,
    },
    {
      x: 12460.9375,
      y: 0.22469711303710938,
    },
    {
      x: 12464.84375,
      y: 0.21971511840820312,
    },
    {
      x: 12468.75,
      y: 0.209747314453125,
    },
    {
      x: 12472.65625,
      y: 0.20476531982421875,
    },
    {
      x: 12476.5625,
      y: 0.19479751586914062,
    },
    {
      x: 12480.46875,
      y: 0.18483352661132812,
    },
    {
      x: 12484.375,
      y: 0.17984771728515625,
    },
    {
      x: 12488.28125,
      y: 0.17486572265625,
    },
    {
      x: 12492.1875,
      y: 0.1698760986328125,
    },
    {
      x: 12496.09375,
      y: 0.1698760986328125,
    },
    {
      x: 12500,
      y: 0.15492630004882812,
    },
    {
      x: 12503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 12746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 12750,
      y: 0.19479751586914062,
    },
    {
      x: 12753.90625,
      y: 0.18483352661132812,
    },
    {
      x: 12757.8125,
      y: 0.17984771728515625,
    },
    {
      x: 12761.71875,
      y: 0.17486572265625,
    },
    {
      x: 12765.625,
      y: 0.1698760986328125,
    },
    {
      x: 12769.53125,
      y: 0.1698760986328125,
    },
    {
      x: 12785.15625,
      y: 0.1698760986328125,
    },
    {
      x: 12789.0625,
      y: 0.16489410400390625,
    },
    {
      x: 12792.96875,
      y: 0.16489410400390625,
    },
    {
      x: 12816.40625,
      y: 0.16489410400390625,
    },
    {
      x: 12820.3125,
      y: 0.159912109375,
    },
    {
      x: 12824.21875,
      y: 0.159912109375,
    },
    {
      x: 12847.65625,
      y: 0.159912109375,
    },
    {
      x: 12851.5625,
      y: 0.15492630004882812,
    },
    {
      x: 12855.46875,
      y: 0.15492630004882812,
    },
    {
      x: 12996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 13000,
      y: 0.10509490966796875,
    },
    {
      x: 13003.90625,
      y: 0.10509490966796875,
    },
    {
      x: 13109.375,
      y: 0.10509490966796875,
    },
    {
      x: 13113.28125,
      y: 0.11506271362304688,
    },
    {
      x: 13117.1875,
      y: 0.15492630004882812,
    },
    {
      x: 13121.09375,
      y: 0.19479751586914062,
    },
    {
      x: 13125,
      y: 0.21971511840820312,
    },
    {
      x: 13128.90625,
      y: 0.2346649169921875,
    },
    {
      x: 13132.8125,
      y: 0.23964691162109375,
    },
    {
      x: 13136.71875,
      y: 0.24961471557617188,
    },
    {
      x: 13140.625,
      y: 0.25460052490234375,
    },
    {
      x: 13144.53125,
      y: 0.2595863342285156,
    },
    {
      x: 13148.4375,
      y: 0.2645683288574219,
    },
    {
      x: 13152.34375,
      y: 0.2695503234863281,
    },
    {
      x: 13156.25,
      y: 0.2745361328125,
    },
    {
      x: 13160.15625,
      y: 0.27951812744140625,
    },
    {
      x: 13164.0625,
      y: 0.2845001220703125,
    },
    {
      x: 13167.96875,
      y: 0.2944679260253906,
    },
    {
      x: 13171.875,
      y: 0.2994499206542969,
    },
    {
      x: 13175.78125,
      y: 0.30443572998046875,
    },
    {
      x: 13179.6875,
      y: 0.309417724609375,
    },
    {
      x: 13183.59375,
      y: 0.31439971923828125,
    },
    {
      x: 13187.5,
      y: 0.3193855285644531,
    },
    {
      x: 13191.40625,
      y: 0.3193855285644531,
    },
    {
      x: 13195.3125,
      y: 0.31439971923828125,
    },
    {
      x: 13199.21875,
      y: 0.30443572998046875,
    },
    {
      x: 13203.125,
      y: 0.2994499206542969,
    },
    {
      x: 13207.03125,
      y: 0.2894859313964844,
    },
    {
      x: 13210.9375,
      y: 0.27951812744140625,
    },
    {
      x: 13214.84375,
      y: 0.2695503234863281,
    },
    {
      x: 13218.75,
      y: 0.2595863342285156,
    },
    {
      x: 13222.65625,
      y: 0.25460052490234375,
    },
    {
      x: 13226.5625,
      y: 0.24462890625,
    },
    {
      x: 13230.46875,
      y: 0.2346649169921875,
    },
    {
      x: 13234.375,
      y: 0.22469711303710938,
    },
    {
      x: 13238.28125,
      y: 0.21971511840820312,
    },
    {
      x: 13242.1875,
      y: 0.209747314453125,
    },
    {
      x: 13246.09375,
      y: 0.20476531982421875,
    },
    {
      x: 13250,
      y: 0.15492630004882812,
    },
    {
      x: 13253.90625,
      y: 0.15492630004882812,
    },
    {
      x: 13316.40625,
      y: 0.15492630004882812,
    },
    {
      x: 13320.3125,
      y: 0.13997650146484375,
    },
    {
      x: 13324.21875,
      y: 0.09014511108398438,
    },
    {
      x: 13328.125,
      y: 0.03034210205078125,
    },
    {
      x: 13343.75,
      y: -0.20886993408203125,
    },
    {
      x: 13347.65625,
      y: -0.26866912841796875,
    },
    {
      x: 13351.5625,
      y: -0.3284721374511719,
    },
    {
      x: 13355.46875,
      y: -0.388275146484375,
    },
    {
      x: 13359.375,
      y: -0.4231605529785156,
    },
    {
      x: 13363.28125,
      y: -0.4032249450683594,
    },
    {
      x: 13367.1875,
      y: -0.3533897399902344,
    },
    {
      x: 13371.09375,
      y: -0.2985725402832031,
    },
    {
      x: 13375,
      y: -0.24375152587890625,
    },
    {
      x: 13378.90625,
      y: -0.188934326171875,
    },
    {
      x: 13382.8125,
      y: -0.13411712646484375,
    },
    {
      x: 13386.71875,
      y: -0.074310302734375,
    },
    {
      x: 13390.625,
      y: -0.01949310302734375,
    },
    {
      x: 13394.53125,
      y: 0.0353240966796875,
    },
    {
      x: 13398.4375,
      y: 0.08017730712890625,
    },
    {
      x: 13402.34375,
      y: 0.09512710571289062,
    },
    {
      x: 13406.25,
      y: 0.10010910034179688,
    },
    {
      x: 13410.15625,
      y: 0.10509490966796875,
    },
    {
      x: 13414.0625,
      y: 0.10509490966796875,
    },
    {
      x: 13496.09375,
      y: 0.10509490966796875,
    },
    {
      x: 13500,
      y: 0.24462890625,
    },
    {
      x: 13503.90625,
      y: 0.2346649169921875,
    },
    {
      x: 13507.8125,
      y: 0.22469711303710938,
    },
    {
      x: 13511.71875,
      y: 0.21971511840820312,
    },
    {
      x: 13515.625,
      y: 0.209747314453125,
    },
    {
      x: 13519.53125,
      y: 0.20476531982421875,
    },
    {
      x: 13523.4375,
      y: 0.19479751586914062,
    },
    {
      x: 13527.34375,
      y: 0.18483352661132812,
    },
    {
      x: 13531.25,
      y: 0.17984771728515625,
    },
    {
      x: 13535.15625,
      y: 0.17486572265625,
    },
    {
      x: 13539.0625,
      y: 0.1698760986328125,
    },
    {
      x: 13542.96875,
      y: 0.1698760986328125,
    },
    {
      x: 13558.59375,
      y: 0.1698760986328125,
    },
    {
      x: 13562.5,
      y: 0.16489410400390625,
    },
    {
      x: 13566.40625,
      y: 0.16489410400390625,
    },
    {
      x: 13589.84375,
      y: 0.16489410400390625,
    },
    {
      x: 13593.75,
      y: 0.159912109375,
    },
    {
      x: 13597.65625,
      y: 0.159912109375,
    },
    {
      x: 13621.09375,
      y: 0.159912109375,
    },
    {
      x: 13625,
      y: 0.15492630004882812,
    },
    {
      x: 13628.90625,
      y: 0.15492630004882812,
    },
    {
      x: 13996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 14000,
      y: 0.10509490966796875,
    },
    {
      x: 14003.90625,
      y: 0.10509490966796875,
    },
    {
      x: 14132.8125,
      y: 0.10509490966796875,
    },
    {
      x: 14136.71875,
      y: 0.11506271362304688,
    },
    {
      x: 14140.625,
      y: 0.15492630004882812,
    },
    {
      x: 14144.53125,
      y: 0.19479751586914062,
    },
    {
      x: 14148.4375,
      y: 0.21971511840820312,
    },
    {
      x: 14152.34375,
      y: 0.2346649169921875,
    },
    {
      x: 14156.25,
      y: 0.23964691162109375,
    },
    {
      x: 14160.15625,
      y: 0.24961471557617188,
    },
    {
      x: 14164.0625,
      y: 0.25460052490234375,
    },
    {
      x: 14167.96875,
      y: 0.2595863342285156,
    },
    {
      x: 14171.875,
      y: 0.2645683288574219,
    },
    {
      x: 14175.78125,
      y: 0.2695503234863281,
    },
    {
      x: 14179.6875,
      y: 0.2745361328125,
    },
    {
      x: 14183.59375,
      y: 0.27951812744140625,
    },
    {
      x: 14187.5,
      y: 0.2845001220703125,
    },
    {
      x: 14191.40625,
      y: 0.2944679260253906,
    },
    {
      x: 14195.3125,
      y: 0.2944679260253906,
    },
    {
      x: 14199.21875,
      y: 0.2994499206542969,
    },
    {
      x: 14203.125,
      y: 0.30443572998046875,
    },
    {
      x: 14207.03125,
      y: 0.31439971923828125,
    },
    {
      x: 14210.9375,
      y: 0.3193855285644531,
    },
    {
      x: 14214.84375,
      y: 0.3193855285644531,
    },
    {
      x: 14218.75,
      y: 0.31439971923828125,
    },
    {
      x: 14222.65625,
      y: 0.30443572998046875,
    },
    {
      x: 14226.5625,
      y: 0.2994499206542969,
    },
    {
      x: 14230.46875,
      y: 0.2894859313964844,
    },
    {
      x: 14234.375,
      y: 0.27951812744140625,
    },
    {
      x: 14238.28125,
      y: 0.2695503234863281,
    },
    {
      x: 14242.1875,
      y: 0.2595863342285156,
    },
    {
      x: 14246.09375,
      y: 0.25460052490234375,
    },
    {
      x: 14250,
      y: 0.15492630004882812,
    },
    {
      x: 14253.90625,
      y: 0.15492630004882812,
    },
    {
      x: 14339.84375,
      y: 0.15492630004882812,
    },
    {
      x: 14343.75,
      y: 0.13997650146484375,
    },
    {
      x: 14347.65625,
      y: 0.09014511108398438,
    },
    {
      x: 14351.5625,
      y: 0.03034210205078125,
    },
    {
      x: 14367.1875,
      y: -0.20886993408203125,
    },
    {
      x: 14371.09375,
      y: -0.26866912841796875,
    },
    {
      x: 14375,
      y: -0.3284721374511719,
    },
    {
      x: 14378.90625,
      y: -0.388275146484375,
    },
    {
      x: 14382.8125,
      y: -0.4231605529785156,
    },
    {
      x: 14386.71875,
      y: -0.4032249450683594,
    },
    {
      x: 14390.625,
      y: -0.3533897399902344,
    },
    {
      x: 14394.53125,
      y: -0.2985725402832031,
    },
    {
      x: 14398.4375,
      y: -0.24375152587890625,
    },
    {
      x: 14402.34375,
      y: -0.188934326171875,
    },
    {
      x: 14406.25,
      y: -0.13411712646484375,
    },
    {
      x: 14410.15625,
      y: -0.074310302734375,
    },
    {
      x: 14414.0625,
      y: -0.01949310302734375,
    },
    {
      x: 14417.96875,
      y: 0.0353240966796875,
    },
    {
      x: 14421.875,
      y: 0.08017730712890625,
    },
    {
      x: 14425.78125,
      y: 0.09512710571289062,
    },
    {
      x: 14429.6875,
      y: 0.10010910034179688,
    },
    {
      x: 14433.59375,
      y: 0.10509490966796875,
    },
    {
      x: 14437.5,
      y: 0.10509490966796875,
    },
    {
      x: 14496.09375,
      y: 0.10509490966796875,
    },
    {
      x: 14500,
      y: 0.15492630004882812,
    },
    {
      x: 14503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 14746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 14750,
      y: 0.2994499206542969,
    },
    {
      x: 14753.90625,
      y: 0.2894859313964844,
    },
    {
      x: 14757.8125,
      y: 0.27951812744140625,
    },
    {
      x: 14761.71875,
      y: 0.2695503234863281,
    },
    {
      x: 14765.625,
      y: 0.2595863342285156,
    },
    {
      x: 14769.53125,
      y: 0.25460052490234375,
    },
    {
      x: 14773.4375,
      y: 0.24462890625,
    },
    {
      x: 14777.34375,
      y: 0.2346649169921875,
    },
    {
      x: 14781.25,
      y: 0.22469711303710938,
    },
    {
      x: 14785.15625,
      y: 0.21971511840820312,
    },
    {
      x: 14789.0625,
      y: 0.209747314453125,
    },
    {
      x: 14792.96875,
      y: 0.20476531982421875,
    },
    {
      x: 14796.875,
      y: 0.19479751586914062,
    },
    {
      x: 14800.78125,
      y: 0.18483352661132812,
    },
    {
      x: 14804.6875,
      y: 0.17984771728515625,
    },
    {
      x: 14808.59375,
      y: 0.17486572265625,
    },
    {
      x: 14812.5,
      y: 0.1698760986328125,
    },
    {
      x: 14816.40625,
      y: 0.1698760986328125,
    },
    {
      x: 14832.03125,
      y: 0.1698760986328125,
    },
    {
      x: 14835.9375,
      y: 0.16489410400390625,
    },
    {
      x: 14839.84375,
      y: 0.16489410400390625,
    },
    {
      x: 14863.28125,
      y: 0.16489410400390625,
    },
    {
      x: 14867.1875,
      y: 0.159912109375,
    },
    {
      x: 14871.09375,
      y: 0.159912109375,
    },
    {
      x: 14894.53125,
      y: 0.159912109375,
    },
    {
      x: 14898.4375,
      y: 0.15492630004882812,
    },
    {
      x: 14902.34375,
      y: 0.15492630004882812,
    },
    {
      x: 14996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 15000,
      y: 0.10509490966796875,
    },
    {
      x: 15003.90625,
      y: 0.10509490966796875,
    },
    {
      x: 15156.25,
      y: 0.10509490966796875,
    },
    {
      x: 15160.15625,
      y: 0.11506271362304688,
    },
    {
      x: 15164.0625,
      y: 0.15492630004882812,
    },
    {
      x: 15167.96875,
      y: 0.19479751586914062,
    },
    {
      x: 15171.875,
      y: 0.21971511840820312,
    },
    {
      x: 15175.78125,
      y: 0.2346649169921875,
    },
    {
      x: 15179.6875,
      y: 0.23964691162109375,
    },
    {
      x: 15183.59375,
      y: 0.24961471557617188,
    },
    {
      x: 15187.5,
      y: 0.25460052490234375,
    },
    {
      x: 15191.40625,
      y: 0.2595863342285156,
    },
    {
      x: 15195.3125,
      y: 0.2645683288574219,
    },
    {
      x: 15199.21875,
      y: 0.2695503234863281,
    },
    {
      x: 15203.125,
      y: 0.2745361328125,
    },
    {
      x: 15207.03125,
      y: 0.27951812744140625,
    },
    {
      x: 15210.9375,
      y: 0.2845001220703125,
    },
    {
      x: 15214.84375,
      y: 0.2944679260253906,
    },
    {
      x: 15218.75,
      y: 0.2994499206542969,
    },
    {
      x: 15222.65625,
      y: 0.30443572998046875,
    },
    {
      x: 15226.5625,
      y: 0.309417724609375,
    },
    {
      x: 15230.46875,
      y: 0.31439971923828125,
    },
    {
      x: 15234.375,
      y: 0.3193855285644531,
    },
    {
      x: 15238.28125,
      y: 0.3193855285644531,
    },
    {
      x: 15242.1875,
      y: 0.31439971923828125,
    },
    {
      x: 15246.09375,
      y: 0.30443572998046875,
    },
    {
      x: 15250,
      y: 0.15492630004882812,
    },
    {
      x: 15253.90625,
      y: 0.15492630004882812,
    },
    {
      x: 15363.28125,
      y: 0.15492630004882812,
    },
    {
      x: 15367.1875,
      y: 0.13997650146484375,
    },
    {
      x: 15371.09375,
      y: 0.09014511108398438,
    },
    {
      x: 15375,
      y: 0.03034210205078125,
    },
    {
      x: 15390.625,
      y: -0.20886993408203125,
    },
    {
      x: 15394.53125,
      y: -0.26866912841796875,
    },
    {
      x: 15398.4375,
      y: -0.3284721374511719,
    },
    {
      x: 15402.34375,
      y: -0.388275146484375,
    },
    {
      x: 15406.25,
      y: -0.4231605529785156,
    },
    {
      x: 15410.15625,
      y: -0.4032249450683594,
    },
    {
      x: 15414.0625,
      y: -0.3533897399902344,
    },
    {
      x: 15417.96875,
      y: -0.2985725402832031,
    },
    {
      x: 15421.875,
      y: -0.24375152587890625,
    },
    {
      x: 15425.78125,
      y: -0.188934326171875,
    },
    {
      x: 15429.6875,
      y: -0.13411712646484375,
    },
    {
      x: 15433.59375,
      y: -0.074310302734375,
    },
    {
      x: 15437.5,
      y: -0.01949310302734375,
    },
    {
      x: 15441.40625,
      y: 0.0353240966796875,
    },
    {
      x: 15445.3125,
      y: 0.08017730712890625,
    },
    {
      x: 15449.21875,
      y: 0.09512710571289062,
    },
    {
      x: 15453.125,
      y: 0.10010910034179688,
    },
    {
      x: 15457.03125,
      y: 0.10509490966796875,
    },
    {
      x: 15460.9375,
      y: 0.10509490966796875,
    },
    {
      x: 15496.09375,
      y: 0.10509490966796875,
    },
    {
      x: 15500,
      y: 0.15492630004882812,
    },
    {
      x: 15503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 15746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 15750,
      y: 0.309417724609375,
    },
    {
      x: 15753.90625,
      y: 0.31439971923828125,
    },
    {
      x: 15757.8125,
      y: 0.3193855285644531,
    },
    {
      x: 15761.71875,
      y: 0.3193855285644531,
    },
    {
      x: 15765.625,
      y: 0.31439971923828125,
    },
    {
      x: 15769.53125,
      y: 0.30443572998046875,
    },
    {
      x: 15773.4375,
      y: 0.2994499206542969,
    },
    {
      x: 15777.34375,
      y: 0.2894859313964844,
    },
    {
      x: 15781.25,
      y: 0.27951812744140625,
    },
    {
      x: 15785.15625,
      y: 0.2695503234863281,
    },
    {
      x: 15789.0625,
      y: 0.2595863342285156,
    },
    {
      x: 15792.96875,
      y: 0.25460052490234375,
    },
    {
      x: 15796.875,
      y: 0.24462890625,
    },
    {
      x: 15800.78125,
      y: 0.2346649169921875,
    },
    {
      x: 15804.6875,
      y: 0.22469711303710938,
    },
    {
      x: 15808.59375,
      y: 0.21971511840820312,
    },
    {
      x: 15812.5,
      y: 0.209747314453125,
    },
    {
      x: 15816.40625,
      y: 0.20476531982421875,
    },
    {
      x: 15820.3125,
      y: 0.19479751586914062,
    },
    {
      x: 15824.21875,
      y: 0.18483352661132812,
    },
    {
      x: 15828.125,
      y: 0.17984771728515625,
    },
    {
      x: 15832.03125,
      y: 0.17486572265625,
    },
    {
      x: 15835.9375,
      y: 0.1698760986328125,
    },
    {
      x: 15839.84375,
      y: 0.1698760986328125,
    },
    {
      x: 15855.46875,
      y: 0.1698760986328125,
    },
    {
      x: 15859.375,
      y: 0.16489410400390625,
    },
    {
      x: 15863.28125,
      y: 0.16489410400390625,
    },
    {
      x: 15886.71875,
      y: 0.16489410400390625,
    },
    {
      x: 15890.625,
      y: 0.159912109375,
    },
    {
      x: 15894.53125,
      y: 0.159912109375,
    },
    {
      x: 15917.96875,
      y: 0.159912109375,
    },
    {
      x: 15921.875,
      y: 0.15492630004882812,
    },
    {
      x: 15925.78125,
      y: 0.15492630004882812,
    },
    {
      x: 16136.71875,
      y: 0.15492630004882812,
    },
    {
      x: 16140.625,
      y: 0.13997650146484375,
    },
    {
      x: 16144.53125,
      y: 0.09014511108398438,
    },
    {
      x: 16148.4375,
      y: 0.03034210205078125,
    },
    {
      x: 16164.0625,
      y: -0.20886993408203125,
    },
    {
      x: 16167.96875,
      y: -0.26866912841796875,
    },
    {
      x: 16171.875,
      y: -0.3284721374511719,
    },
    {
      x: 16175.78125,
      y: -0.388275146484375,
    },
    {
      x: 16179.6875,
      y: -0.4231605529785156,
    },
    {
      x: 16183.59375,
      y: -0.4032249450683594,
    },
    {
      x: 16187.5,
      y: -0.3533897399902344,
    },
    {
      x: 16191.40625,
      y: -0.2985725402832031,
    },
    {
      x: 16195.3125,
      y: -0.24375152587890625,
    },
    {
      x: 16199.21875,
      y: -0.188934326171875,
    },
    {
      x: 16203.125,
      y: -0.13411712646484375,
    },
    {
      x: 16207.03125,
      y: -0.074310302734375,
    },
    {
      x: 16210.9375,
      y: -0.01949310302734375,
    },
    {
      x: 16214.84375,
      y: 0.0353240966796875,
    },
    {
      x: 16218.75,
      y: 0.08017730712890625,
    },
    {
      x: 16222.65625,
      y: 0.09512710571289062,
    },
    {
      x: 16226.5625,
      y: 0.10010910034179688,
    },
    {
      x: 16230.46875,
      y: 0.10509490966796875,
    },
    {
      x: 16234.375,
      y: 0.10509490966796875,
    },
    {
      x: 16429.6875,
      y: 0.10509490966796875,
    },
    {
      x: 16433.59375,
      y: 0.11506271362304688,
    },
    {
      x: 16437.5,
      y: 0.15492630004882812,
    },
    {
      x: 16441.40625,
      y: 0.19479751586914062,
    },
    {
      x: 16445.3125,
      y: 0.21971511840820312,
    },
    {
      x: 16449.21875,
      y: 0.2346649169921875,
    },
    {
      x: 16453.125,
      y: 0.23964691162109375,
    },
    {
      x: 16457.03125,
      y: 0.24961471557617188,
    },
    {
      x: 16460.9375,
      y: 0.25460052490234375,
    },
    {
      x: 16464.84375,
      y: 0.2595863342285156,
    },
    {
      x: 16468.75,
      y: 0.2645683288574219,
    },
    {
      x: 16472.65625,
      y: 0.2695503234863281,
    },
    {
      x: 16476.5625,
      y: 0.2745361328125,
    },
    {
      x: 16480.46875,
      y: 0.27951812744140625,
    },
    {
      x: 16484.375,
      y: 0.2845001220703125,
    },
    {
      x: 16488.28125,
      y: 0.2944679260253906,
    },
    {
      x: 16492.1875,
      y: 0.2994499206542969,
    },
    {
      x: 16496.09375,
      y: 0.30443572998046875,
    },
    {
      x: 16500,
      y: 0.15492630004882812,
    },
    {
      x: 16503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 16746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 16750,
      y: 0.2745361328125,
    },
    {
      x: 16753.90625,
      y: 0.27951812744140625,
    },
    {
      x: 16757.8125,
      y: 0.2845001220703125,
    },
    {
      x: 16761.71875,
      y: 0.2944679260253906,
    },
    {
      x: 16765.625,
      y: 0.2994499206542969,
    },
    {
      x: 16769.53125,
      y: 0.30443572998046875,
    },
    {
      x: 16773.4375,
      y: 0.309417724609375,
    },
    {
      x: 16777.34375,
      y: 0.31439971923828125,
    },
    {
      x: 16781.25,
      y: 0.3193855285644531,
    },
    {
      x: 16785.15625,
      y: 0.3193855285644531,
    },
    {
      x: 16789.0625,
      y: 0.31439971923828125,
    },
    {
      x: 16792.96875,
      y: 0.30443572998046875,
    },
    {
      x: 16796.875,
      y: 0.2994499206542969,
    },
    {
      x: 16800.78125,
      y: 0.2894859313964844,
    },
    {
      x: 16804.6875,
      y: 0.27951812744140625,
    },
    {
      x: 16808.59375,
      y: 0.2695503234863281,
    },
    {
      x: 16812.5,
      y: 0.2595863342285156,
    },
    {
      x: 16816.40625,
      y: 0.25460052490234375,
    },
    {
      x: 16820.3125,
      y: 0.24462890625,
    },
    {
      x: 16824.21875,
      y: 0.2346649169921875,
    },
    {
      x: 16828.125,
      y: 0.22469711303710938,
    },
    {
      x: 16832.03125,
      y: 0.21971511840820312,
    },
    {
      x: 16835.9375,
      y: 0.209747314453125,
    },
    {
      x: 16839.84375,
      y: 0.20476531982421875,
    },
    {
      x: 16843.75,
      y: 0.19479751586914062,
    },
    {
      x: 16847.65625,
      y: 0.18483352661132812,
    },
    {
      x: 16851.5625,
      y: 0.17984771728515625,
    },
    {
      x: 16855.46875,
      y: 0.17486572265625,
    },
    {
      x: 16859.375,
      y: 0.1698760986328125,
    },
    {
      x: 16863.28125,
      y: 0.1698760986328125,
    },
    {
      x: 16878.90625,
      y: 0.1698760986328125,
    },
    {
      x: 16882.8125,
      y: 0.16489410400390625,
    },
    {
      x: 16886.71875,
      y: 0.16489410400390625,
    },
    {
      x: 16910.15625,
      y: 0.16489410400390625,
    },
    {
      x: 16914.0625,
      y: 0.159912109375,
    },
    {
      x: 16917.96875,
      y: 0.159912109375,
    },
    {
      x: 16941.40625,
      y: 0.159912109375,
    },
    {
      x: 16945.3125,
      y: 0.15492630004882812,
    },
    {
      x: 16949.21875,
      y: 0.15492630004882812,
    },
    {
      x: 17160.15625,
      y: 0.15492630004882812,
    },
    {
      x: 17164.0625,
      y: 0.13997650146484375,
    },
    {
      x: 17167.96875,
      y: 0.09014511108398438,
    },
    {
      x: 17171.875,
      y: 0.03034210205078125,
    },
    {
      x: 17187.5,
      y: -0.20886993408203125,
    },
    {
      x: 17191.40625,
      y: -0.26866912841796875,
    },
    {
      x: 17195.3125,
      y: -0.3284721374511719,
    },
    {
      x: 17199.21875,
      y: -0.388275146484375,
    },
    {
      x: 17203.125,
      y: -0.4231605529785156,
    },
    {
      x: 17207.03125,
      y: -0.4032249450683594,
    },
    {
      x: 17210.9375,
      y: -0.3533897399902344,
    },
    {
      x: 17214.84375,
      y: -0.2985725402832031,
    },
    {
      x: 17218.75,
      y: -0.24375152587890625,
    },
    {
      x: 17222.65625,
      y: -0.188934326171875,
    },
    {
      x: 17226.5625,
      y: -0.13411712646484375,
    },
    {
      x: 17230.46875,
      y: -0.074310302734375,
    },
    {
      x: 17234.375,
      y: -0.01949310302734375,
    },
    {
      x: 17238.28125,
      y: 0.0353240966796875,
    },
    {
      x: 17242.1875,
      y: 0.08017730712890625,
    },
    {
      x: 17246.09375,
      y: 0.09512710571289062,
    },
    {
      x: 17250,
      y: 0.10010910034179688,
    },
    {
      x: 17253.90625,
      y: 0.10509490966796875,
    },
    {
      x: 17257.8125,
      y: 0.10509490966796875,
    },
    {
      x: 17453.125,
      y: 0.10509490966796875,
    },
    {
      x: 17457.03125,
      y: 0.11506271362304688,
    },
    {
      x: 17460.9375,
      y: 0.15492630004882812,
    },
    {
      x: 17464.84375,
      y: 0.19479751586914062,
    },
    {
      x: 17468.75,
      y: 0.21971511840820312,
    },
    {
      x: 17472.65625,
      y: 0.2346649169921875,
    },
    {
      x: 17476.5625,
      y: 0.23964691162109375,
    },
    {
      x: 17480.46875,
      y: 0.24961471557617188,
    },
    {
      x: 17484.375,
      y: 0.25460052490234375,
    },
    {
      x: 17488.28125,
      y: 0.2595863342285156,
    },
    {
      x: 17492.1875,
      y: 0.2645683288574219,
    },
    {
      x: 17496.09375,
      y: 0.2695503234863281,
    },
    {
      x: 17500,
      y: 0.15492630004882812,
    },
    {
      x: 17503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 17746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 17750,
      y: 0.23964691162109375,
    },
    {
      x: 17753.90625,
      y: 0.24462890625,
    },
    {
      x: 17757.8125,
      y: 0.25460052490234375,
    },
    {
      x: 17761.71875,
      y: 0.2595863342285156,
    },
    {
      x: 17765.625,
      y: 0.2645683288574219,
    },
    {
      x: 17769.53125,
      y: 0.2695503234863281,
    },
    {
      x: 17773.4375,
      y: 0.2745361328125,
    },
    {
      x: 17777.34375,
      y: 0.27951812744140625,
    },
    {
      x: 17781.25,
      y: 0.2845001220703125,
    },
    {
      x: 17785.15625,
      y: 0.2894859313964844,
    },
    {
      x: 17789.0625,
      y: 0.2944679260253906,
    },
    {
      x: 17792.96875,
      y: 0.2994499206542969,
    },
    {
      x: 17796.875,
      y: 0.30443572998046875,
    },
    {
      x: 17800.78125,
      y: 0.309417724609375,
    },
    {
      x: 17804.6875,
      y: 0.31439971923828125,
    },
    {
      x: 17808.59375,
      y: 0.31439971923828125,
    },
    {
      x: 17812.5,
      y: 0.31439971923828125,
    },
    {
      x: 17816.40625,
      y: 0.30443572998046875,
    },
    {
      x: 17820.3125,
      y: 0.2944679260253906,
    },
    {
      x: 17824.21875,
      y: 0.2845001220703125,
    },
    {
      x: 17828.125,
      y: 0.27951812744140625,
    },
    {
      x: 17832.03125,
      y: 0.2695503234863281,
    },
    {
      x: 17835.9375,
      y: 0.2595863342285156,
    },
    {
      x: 17839.84375,
      y: 0.24961471557617188,
    },
    {
      x: 17843.75,
      y: 0.24462890625,
    },
    {
      x: 17847.65625,
      y: 0.2346649169921875,
    },
    {
      x: 17851.5625,
      y: 0.22469711303710938,
    },
    {
      x: 17855.46875,
      y: 0.21971511840820312,
    },
    {
      x: 17859.375,
      y: 0.209747314453125,
    },
    {
      x: 17863.28125,
      y: 0.1997833251953125,
    },
    {
      x: 17867.1875,
      y: 0.19479751586914062,
    },
    {
      x: 17871.09375,
      y: 0.18483352661132812,
    },
    {
      x: 17875,
      y: 0.17486572265625,
    },
    {
      x: 17878.90625,
      y: 0.17486572265625,
    },
    {
      x: 17882.8125,
      y: 0.1698760986328125,
    },
    {
      x: 17886.71875,
      y: 0.1698760986328125,
    },
    {
      x: 17894.53125,
      y: 0.1698760986328125,
    },
    {
      x: 17898.4375,
      y: 0.16489410400390625,
    },
    {
      x: 17902.34375,
      y: 0.16489410400390625,
    },
    {
      x: 17925.78125,
      y: 0.16489410400390625,
    },
    {
      x: 17929.6875,
      y: 0.159912109375,
    },
    {
      x: 17933.59375,
      y: 0.159912109375,
    },
    {
      x: 17957.03125,
      y: 0.159912109375,
    },
    {
      x: 17960.9375,
      y: 0.15492630004882812,
    },
    {
      x: 17964.84375,
      y: 0.15492630004882812,
    },
    {
      x: 18183.59375,
      y: 0.15492630004882812,
    },
    {
      x: 18187.5,
      y: 0.13997650146484375,
    },
    {
      x: 18191.40625,
      y: 0.09014511108398438,
    },
    {
      x: 18195.3125,
      y: 0.03034210205078125,
    },
    {
      x: 18210.9375,
      y: -0.20886993408203125,
    },
    {
      x: 18214.84375,
      y: -0.26866912841796875,
    },
    {
      x: 18218.75,
      y: -0.3284721374511719,
    },
    {
      x: 18222.65625,
      y: -0.388275146484375,
    },
    {
      x: 18226.5625,
      y: -0.4231605529785156,
    },
    {
      x: 18230.46875,
      y: -0.4032249450683594,
    },
    {
      x: 18234.375,
      y: -0.3533897399902344,
    },
    {
      x: 18238.28125,
      y: -0.2985725402832031,
    },
    {
      x: 18242.1875,
      y: -0.24375152587890625,
    },
    {
      x: 18246.09375,
      y: -0.18394851684570312,
    },
    {
      x: 18250,
      y: -0.12913131713867188,
    },
    {
      x: 18253.90625,
      y: -0.074310302734375,
    },
    {
      x: 18257.8125,
      y: -0.01949310302734375,
    },
    {
      x: 18261.71875,
      y: 0.040309906005859375,
    },
    {
      x: 18265.625,
      y: 0.0851593017578125,
    },
    {
      x: 18269.53125,
      y: 0.10010910034179688,
    },
    {
      x: 18273.4375,
      y: 0.10509490966796875,
    },
    {
      x: 18277.34375,
      y: 0.110076904296875,
    },
    {
      x: 18281.25,
      y: 0.110076904296875,
    },
    {
      x: 18476.5625,
      y: 0.110076904296875,
    },
    {
      x: 18480.46875,
      y: 0.125030517578125,
    },
    {
      x: 18484.375,
      y: 0.15492630004882812,
    },
    {
      x: 18488.28125,
      y: 0.19479751586914062,
    },
    {
      x: 18492.1875,
      y: 0.21971511840820312,
    },
    {
      x: 18496.09375,
      y: 0.2346649169921875,
    },
    {
      x: 18500,
      y: 0.110076904296875,
    },
    {
      x: 18503.90625,
      y: 0.125030517578125,
    },
    {
      x: 18507.8125,
      y: 0.15492630004882812,
    },
    {
      x: 18511.71875,
      y: 0.19479751586914062,
    },
    {
      x: 18515.625,
      y: 0.21971511840820312,
    },
    {
      x: 18519.53125,
      y: 0.2346649169921875,
    },
    {
      x: 18523.4375,
      y: 0.23964691162109375,
    },
    {
      x: 18527.34375,
      y: 0.24462890625,
    },
    {
      x: 18531.25,
      y: 0.25460052490234375,
    },
    {
      x: 18535.15625,
      y: 0.2595863342285156,
    },
    {
      x: 18539.0625,
      y: 0.2645683288574219,
    },
    {
      x: 18542.96875,
      y: 0.2695503234863281,
    },
    {
      x: 18546.875,
      y: 0.2745361328125,
    },
    {
      x: 18550.78125,
      y: 0.27951812744140625,
    },
    {
      x: 18554.6875,
      y: 0.2845001220703125,
    },
    {
      x: 18558.59375,
      y: 0.2894859313964844,
    },
    {
      x: 18562.5,
      y: 0.2944679260253906,
    },
    {
      x: 18566.40625,
      y: 0.2994499206542969,
    },
    {
      x: 18570.3125,
      y: 0.30443572998046875,
    },
    {
      x: 18574.21875,
      y: 0.309417724609375,
    },
    {
      x: 18578.125,
      y: 0.31439971923828125,
    },
    {
      x: 18582.03125,
      y: 0.31439971923828125,
    },
    {
      x: 18585.9375,
      y: 0.31439971923828125,
    },
    {
      x: 18589.84375,
      y: 0.30443572998046875,
    },
    {
      x: 18593.75,
      y: 0.2944679260253906,
    },
    {
      x: 18597.65625,
      y: 0.2845001220703125,
    },
    {
      x: 18601.5625,
      y: 0.27951812744140625,
    },
    {
      x: 18605.46875,
      y: 0.2695503234863281,
    },
    {
      x: 18609.375,
      y: 0.2595863342285156,
    },
    {
      x: 18613.28125,
      y: 0.24961471557617188,
    },
    {
      x: 18617.1875,
      y: 0.24462890625,
    },
    {
      x: 18621.09375,
      y: 0.2346649169921875,
    },
    {
      x: 18625,
      y: 0.22469711303710938,
    },
    {
      x: 18628.90625,
      y: 0.21971511840820312,
    },
    {
      x: 18632.8125,
      y: 0.209747314453125,
    },
    {
      x: 18636.71875,
      y: 0.1997833251953125,
    },
    {
      x: 18640.625,
      y: 0.19479751586914062,
    },
    {
      x: 18644.53125,
      y: 0.18483352661132812,
    },
    {
      x: 18648.4375,
      y: 0.17486572265625,
    },
    {
      x: 18652.34375,
      y: 0.17486572265625,
    },
    {
      x: 18656.25,
      y: 0.1698760986328125,
    },
    {
      x: 18660.15625,
      y: 0.1698760986328125,
    },
    {
      x: 18667.96875,
      y: 0.1698760986328125,
    },
    {
      x: 18671.875,
      y: 0.16489410400390625,
    },
    {
      x: 18675.78125,
      y: 0.16489410400390625,
    },
    {
      x: 18699.21875,
      y: 0.16489410400390625,
    },
    {
      x: 18703.125,
      y: 0.159912109375,
    },
    {
      x: 18707.03125,
      y: 0.159912109375,
    },
    {
      x: 18730.46875,
      y: 0.159912109375,
    },
    {
      x: 18734.375,
      y: 0.15492630004882812,
    },
    {
      x: 18738.28125,
      y: 0.15492630004882812,
    },
    {
      x: 18996.09375,
      y: 0.15492630004882812,
    },
    {
      x: 19000,
      y: -0.4231605529785156,
    },
    {
      x: 19003.90625,
      y: -0.4032249450683594,
    },
    {
      x: 19007.8125,
      y: -0.3533897399902344,
    },
    {
      x: 19011.71875,
      y: -0.2985725402832031,
    },
    {
      x: 19015.625,
      y: -0.24375152587890625,
    },
    {
      x: 19019.53125,
      y: -0.18394851684570312,
    },
    {
      x: 19023.4375,
      y: -0.12913131713867188,
    },
    {
      x: 19027.34375,
      y: -0.074310302734375,
    },
    {
      x: 19031.25,
      y: -0.01949310302734375,
    },
    {
      x: 19035.15625,
      y: 0.040309906005859375,
    },
    {
      x: 19039.0625,
      y: 0.0851593017578125,
    },
    {
      x: 19042.96875,
      y: 0.10010910034179688,
    },
    {
      x: 19046.875,
      y: 0.10509490966796875,
    },
    {
      x: 19050.78125,
      y: 0.110076904296875,
    },
    {
      x: 19054.6875,
      y: 0.110076904296875,
    },
    {
      x: 19246.09375,
      y: 0.110076904296875,
    },
    {
      x: 19250,
      y: 0.15492630004882812,
    },
    {
      x: 19253.90625,
      y: 0.15492630004882812,
    },
    {
      x: 19457.03125,
      y: 0.15492630004882812,
    },
    {
      x: 19460.9375,
      y: 0.13997650146484375,
    },
    {
      x: 19464.84375,
      y: 0.09014511108398438,
    },
    {
      x: 19468.75,
      y: 0.03034210205078125,
    },
    {
      x: 19484.375,
      y: -0.20886993408203125,
    },
    {
      x: 19488.28125,
      y: -0.26866912841796875,
    },
    {
      x: 19492.1875,
      y: -0.3284721374511719,
    },
    {
      x: 19496.09375,
      y: -0.388275146484375,
    },
    {
      x: 19500,
      y: 0.159912109375,
    },
    {
      x: 19503.90625,
      y: 0.159912109375,
    },
    {
      x: 19507.8125,
      y: 0.15492630004882812,
    },
    {
      x: 19511.71875,
      y: 0.15492630004882812,
    },
    {
      x: 19746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 19750,
      y: 0.110076904296875,
    },
    {
      x: 19753.90625,
      y: 0.110076904296875,
    },
    {
      x: 19773.4375,
      y: 0.110076904296875,
    },
    {
      x: 19777.34375,
      y: 0.125030517578125,
    },
    {
      x: 19781.25,
      y: 0.15492630004882812,
    },
    {
      x: 19785.15625,
      y: 0.19479751586914062,
    },
    {
      x: 19789.0625,
      y: 0.21971511840820312,
    },
    {
      x: 19792.96875,
      y: 0.2346649169921875,
    },
    {
      x: 19796.875,
      y: 0.23964691162109375,
    },
    {
      x: 19800.78125,
      y: 0.24462890625,
    },
    {
      x: 19804.6875,
      y: 0.25460052490234375,
    },
    {
      x: 19808.59375,
      y: 0.2595863342285156,
    },
    {
      x: 19812.5,
      y: 0.2645683288574219,
    },
    {
      x: 19816.40625,
      y: 0.2695503234863281,
    },
    {
      x: 19820.3125,
      y: 0.2745361328125,
    },
    {
      x: 19824.21875,
      y: 0.27951812744140625,
    },
    {
      x: 19828.125,
      y: 0.2845001220703125,
    },
    {
      x: 19832.03125,
      y: 0.2894859313964844,
    },
    {
      x: 19835.9375,
      y: 0.2944679260253906,
    },
    {
      x: 19839.84375,
      y: 0.2994499206542969,
    },
    {
      x: 19843.75,
      y: 0.30443572998046875,
    },
    {
      x: 19847.65625,
      y: 0.309417724609375,
    },
    {
      x: 19851.5625,
      y: 0.31439971923828125,
    },
    {
      x: 19855.46875,
      y: 0.3193855285644531,
    },
    {
      x: 19859.375,
      y: 0.31439971923828125,
    },
    {
      x: 19863.28125,
      y: 0.30443572998046875,
    },
    {
      x: 19867.1875,
      y: 0.2944679260253906,
    },
    {
      x: 19871.09375,
      y: 0.2845001220703125,
    },
    {
      x: 19875,
      y: 0.27951812744140625,
    },
    {
      x: 19878.90625,
      y: 0.2695503234863281,
    },
    {
      x: 19882.8125,
      y: 0.2595863342285156,
    },
    {
      x: 19886.71875,
      y: 0.24961471557617188,
    },
    {
      x: 19890.625,
      y: 0.24462890625,
    },
    {
      x: 19894.53125,
      y: 0.2346649169921875,
    },
    {
      x: 19898.4375,
      y: 0.22469711303710938,
    },
    {
      x: 19902.34375,
      y: 0.21971511840820312,
    },
    {
      x: 19906.25,
      y: 0.209747314453125,
    },
    {
      x: 19910.15625,
      y: 0.1997833251953125,
    },
    {
      x: 19914.0625,
      y: 0.19479751586914062,
    },
    {
      x: 19917.96875,
      y: 0.18483352661132812,
    },
    {
      x: 19921.875,
      y: 0.17486572265625,
    },
    {
      x: 19925.78125,
      y: 0.17486572265625,
    },
    {
      x: 19929.6875,
      y: 0.1698760986328125,
    },
    {
      x: 19933.59375,
      y: 0.1698760986328125,
    },
    {
      x: 19941.40625,
      y: 0.1698760986328125,
    },
    {
      x: 19945.3125,
      y: 0.16489410400390625,
    },
    {
      x: 19949.21875,
      y: 0.16489410400390625,
    },
    {
      x: 19972.65625,
      y: 0.16489410400390625,
    },
    {
      x: 19976.5625,
      y: 0.159912109375,
    },
    {
      x: 19980.46875,
      y: 0.159912109375,
    },
    {
      x: 19996.09375,
      y: 0.159912109375,
    },
    {
      x: 20000,
      y: 0.15492630004882812,
    },
    {
      x: 20003.90625,
      y: 0.15492630004882812,
    },
    {
      x: 20230.46875,
      y: 0.15492630004882812,
    },
    {
      x: 20234.375,
      y: 0.13997650146484375,
    },
    {
      x: 20238.28125,
      y: 0.09014511108398438,
    },
    {
      x: 20242.1875,
      y: 0.03034210205078125,
    },
    {
      x: 20257.8125,
      y: -0.20886993408203125,
    },
    {
      x: 20261.71875,
      y: -0.26866912841796875,
    },
    {
      x: 20265.625,
      y: -0.3284721374511719,
    },
    {
      x: 20269.53125,
      y: -0.388275146484375,
    },
    {
      x: 20273.4375,
      y: -0.4231605529785156,
    },
    {
      x: 20277.34375,
      y: -0.4032249450683594,
    },
    {
      x: 20281.25,
      y: -0.3533897399902344,
    },
    {
      x: 20285.15625,
      y: -0.2985725402832031,
    },
    {
      x: 20289.0625,
      y: -0.24375152587890625,
    },
    {
      x: 20292.96875,
      y: -0.18394851684570312,
    },
    {
      x: 20296.875,
      y: -0.12913131713867188,
    },
    {
      x: 20300.78125,
      y: -0.074310302734375,
    },
    {
      x: 20304.6875,
      y: -0.01949310302734375,
    },
    {
      x: 20308.59375,
      y: 0.040309906005859375,
    },
    {
      x: 20312.5,
      y: 0.0851593017578125,
    },
    {
      x: 20316.40625,
      y: 0.10010910034179688,
    },
    {
      x: 20320.3125,
      y: 0.10509490966796875,
    },
    {
      x: 20324.21875,
      y: 0.110076904296875,
    },
    {
      x: 20328.125,
      y: 0.110076904296875,
    },
    {
      x: 20496.09375,
      y: 0.110076904296875,
    },
    {
      x: 20500,
      y: 0.159912109375,
    },
    {
      x: 20503.90625,
      y: 0.159912109375,
    },
    {
      x: 20527.34375,
      y: 0.159912109375,
    },
    {
      x: 20531.25,
      y: 0.15492630004882812,
    },
    {
      x: 20535.15625,
      y: 0.15492630004882812,
    },
    {
      x: 20746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 20750,
      y: 0.110076904296875,
    },
    {
      x: 20753.90625,
      y: 0.110076904296875,
    },
    {
      x: 20796.875,
      y: 0.110076904296875,
    },
    {
      x: 20800.78125,
      y: 0.125030517578125,
    },
    {
      x: 20804.6875,
      y: 0.15492630004882812,
    },
    {
      x: 20808.59375,
      y: 0.19479751586914062,
    },
    {
      x: 20812.5,
      y: 0.21971511840820312,
    },
    {
      x: 20816.40625,
      y: 0.2346649169921875,
    },
    {
      x: 20820.3125,
      y: 0.23964691162109375,
    },
    {
      x: 20824.21875,
      y: 0.24462890625,
    },
    {
      x: 20828.125,
      y: 0.25460052490234375,
    },
    {
      x: 20832.03125,
      y: 0.2595863342285156,
    },
    {
      x: 20835.9375,
      y: 0.2645683288574219,
    },
    {
      x: 20839.84375,
      y: 0.2695503234863281,
    },
    {
      x: 20843.75,
      y: 0.2745361328125,
    },
    {
      x: 20847.65625,
      y: 0.27951812744140625,
    },
    {
      x: 20851.5625,
      y: 0.2845001220703125,
    },
    {
      x: 20855.46875,
      y: 0.2894859313964844,
    },
    {
      x: 20859.375,
      y: 0.2944679260253906,
    },
    {
      x: 20863.28125,
      y: 0.2994499206542969,
    },
    {
      x: 20867.1875,
      y: 0.30443572998046875,
    },
    {
      x: 20871.09375,
      y: 0.309417724609375,
    },
    {
      x: 20875,
      y: 0.31439971923828125,
    },
    {
      x: 20878.90625,
      y: 0.31439971923828125,
    },
    {
      x: 20882.8125,
      y: 0.31439971923828125,
    },
    {
      x: 20886.71875,
      y: 0.30443572998046875,
    },
    {
      x: 20890.625,
      y: 0.2944679260253906,
    },
    {
      x: 20894.53125,
      y: 0.2845001220703125,
    },
    {
      x: 20898.4375,
      y: 0.27951812744140625,
    },
    {
      x: 20902.34375,
      y: 0.2695503234863281,
    },
    {
      x: 20906.25,
      y: 0.2595863342285156,
    },
    {
      x: 20910.15625,
      y: 0.24961471557617188,
    },
    {
      x: 20914.0625,
      y: 0.24462890625,
    },
    {
      x: 20917.96875,
      y: 0.2346649169921875,
    },
    {
      x: 20921.875,
      y: 0.22469711303710938,
    },
    {
      x: 20925.78125,
      y: 0.21971511840820312,
    },
    {
      x: 20929.6875,
      y: 0.209747314453125,
    },
    {
      x: 20933.59375,
      y: 0.1997833251953125,
    },
    {
      x: 20937.5,
      y: 0.19479751586914062,
    },
    {
      x: 20941.40625,
      y: 0.18483352661132812,
    },
    {
      x: 20945.3125,
      y: 0.17486572265625,
    },
    {
      x: 20949.21875,
      y: 0.17486572265625,
    },
    {
      x: 20953.125,
      y: 0.1698760986328125,
    },
    {
      x: 20957.03125,
      y: 0.1698760986328125,
    },
    {
      x: 20964.84375,
      y: 0.1698760986328125,
    },
    {
      x: 20968.75,
      y: 0.16489410400390625,
    },
    {
      x: 20972.65625,
      y: 0.16489410400390625,
    },
    {
      x: 20996.09375,
      y: 0.16489410400390625,
    },
    {
      x: 21000,
      y: 0.15492630004882812,
    },
    {
      x: 21003.90625,
      y: 0.15492630004882812,
    },
    {
      x: 21246.09375,
      y: 0.15492630004882812,
    },
    {
      x: 21250,
      y: 0.16489410400390625,
    },
    {
      x: 21253.90625,
      y: 0.16489410400390625,
    },
    {
      x: 21269.53125,
      y: 0.16489410400390625,
    },
    {
      x: 21273.4375,
      y: 0.159912109375,
    },
    {
      x: 21277.34375,
      y: 0.159912109375,
    },
    {
      x: 21300.78125,
      y: 0.159912109375,
    },
    {
      x: 21304.6875,
      y: 0.15492630004882812,
    },
    {
      x: 21308.59375,
      y: 0.15492630004882812,
    },
    {
      x: 21503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 21507.8125,
      y: 0.13997650146484375,
    },
    {
      x: 21511.71875,
      y: 0.09014511108398438,
    },
    {
      x: 21515.625,
      y: 0.03034210205078125,
    },
    {
      x: 21531.25,
      y: -0.20886993408203125,
    },
    {
      x: 21535.15625,
      y: -0.26866912841796875,
    },
    {
      x: 21539.0625,
      y: -0.3284721374511719,
    },
    {
      x: 21542.96875,
      y: -0.388275146484375,
    },
    {
      x: 21546.875,
      y: -0.4231605529785156,
    },
    {
      x: 21550.78125,
      y: -0.4032249450683594,
    },
    {
      x: 21554.6875,
      y: -0.3533897399902344,
    },
    {
      x: 21558.59375,
      y: -0.2985725402832031,
    },
    {
      x: 21562.5,
      y: -0.24375152587890625,
    },
    {
      x: 21566.40625,
      y: -0.18394851684570312,
    },
    {
      x: 21570.3125,
      y: -0.12913131713867188,
    },
    {
      x: 21574.21875,
      y: -0.074310302734375,
    },
    {
      x: 21578.125,
      y: -0.01949310302734375,
    },
    {
      x: 21582.03125,
      y: 0.040309906005859375,
    },
    {
      x: 21585.9375,
      y: 0.0851593017578125,
    },
    {
      x: 21589.84375,
      y: 0.10010910034179688,
    },
    {
      x: 21593.75,
      y: 0.10509490966796875,
    },
    {
      x: 21597.65625,
      y: 0.110076904296875,
    },
    {
      x: 21601.5625,
      y: 0.110076904296875,
    },
    {
      x: 21820.3125,
      y: 0.110076904296875,
    },
    {
      x: 21824.21875,
      y: 0.125030517578125,
    },
    {
      x: 21828.125,
      y: 0.15492630004882812,
    },
    {
      x: 21832.03125,
      y: 0.19479751586914062,
    },
    {
      x: 21835.9375,
      y: 0.21971511840820312,
    },
    {
      x: 21839.84375,
      y: 0.2346649169921875,
    },
    {
      x: 21843.75,
      y: 0.23964691162109375,
    },
    {
      x: 21847.65625,
      y: 0.24462890625,
    },
    {
      x: 21851.5625,
      y: 0.25460052490234375,
    },
    {
      x: 21855.46875,
      y: 0.2595863342285156,
    },
    {
      x: 21859.375,
      y: 0.2645683288574219,
    },
    {
      x: 21863.28125,
      y: 0.2695503234863281,
    },
    {
      x: 21867.1875,
      y: 0.2745361328125,
    },
    {
      x: 21871.09375,
      y: 0.27951812744140625,
    },
    {
      x: 21875,
      y: 0.2845001220703125,
    },
    {
      x: 21878.90625,
      y: 0.2894859313964844,
    },
    {
      x: 21882.8125,
      y: 0.2944679260253906,
    },
    {
      x: 21886.71875,
      y: 0.2994499206542969,
    },
    {
      x: 21890.625,
      y: 0.30443572998046875,
    },
    {
      x: 21894.53125,
      y: 0.309417724609375,
    },
    {
      x: 21898.4375,
      y: 0.31439971923828125,
    },
    {
      x: 21902.34375,
      y: 0.31439971923828125,
    },
    {
      x: 21906.25,
      y: 0.31439971923828125,
    },
    {
      x: 21910.15625,
      y: 0.30443572998046875,
    },
    {
      x: 21914.0625,
      y: 0.2944679260253906,
    },
    {
      x: 21917.96875,
      y: 0.2845001220703125,
    },
    {
      x: 21921.875,
      y: 0.27951812744140625,
    },
    {
      x: 21925.78125,
      y: 0.2695503234863281,
    },
    {
      x: 21929.6875,
      y: 0.2595863342285156,
    },
    {
      x: 21933.59375,
      y: 0.24961471557617188,
    },
    {
      x: 21937.5,
      y: 0.24462890625,
    },
    {
      x: 21941.40625,
      y: 0.2346649169921875,
    },
    {
      x: 21945.3125,
      y: 0.22469711303710938,
    },
    {
      x: 21949.21875,
      y: 0.21971511840820312,
    },
    {
      x: 21953.125,
      y: 0.209747314453125,
    },
    {
      x: 21957.03125,
      y: 0.1997833251953125,
    },
    {
      x: 21960.9375,
      y: 0.19479751586914062,
    },
    {
      x: 21964.84375,
      y: 0.18483352661132812,
    },
    {
      x: 21968.75,
      y: 0.17486572265625,
    },
    {
      x: 21972.65625,
      y: 0.17486572265625,
    },
    {
      x: 21976.5625,
      y: 0.1698760986328125,
    },
    {
      x: 21980.46875,
      y: 0.1698760986328125,
    },
    {
      x: 21988.28125,
      y: 0.1698760986328125,
    },
    {
      x: 21992.1875,
      y: 0.16489410400390625,
    },
    {
      x: 21996.09375,
      y: 0.16489410400390625,
    },
    {
      x: 22000,
      y: 0.1698760986328125,
    },
    {
      x: 22003.90625,
      y: 0.1698760986328125,
    },
    {
      x: 22011.71875,
      y: 0.1698760986328125,
    },
    {
      x: 22015.625,
      y: 0.16489410400390625,
    },
    {
      x: 22019.53125,
      y: 0.16489410400390625,
    },
    {
      x: 22042.96875,
      y: 0.16489410400390625,
    },
    {
      x: 22046.875,
      y: 0.159912109375,
    },
    {
      x: 22050.78125,
      y: 0.159912109375,
    },
    {
      x: 22074.21875,
      y: 0.159912109375,
    },
    {
      x: 22078.125,
      y: 0.15492630004882812,
    },
    {
      x: 22082.03125,
      y: 0.15492630004882812,
    },
    {
      x: 22527.34375,
      y: 0.15492630004882812,
    },
    {
      x: 22531.25,
      y: 0.13997650146484375,
    },
    {
      x: 22535.15625,
      y: 0.09014511108398438,
    },
    {
      x: 22539.0625,
      y: 0.03034210205078125,
    },
    {
      x: 22554.6875,
      y: -0.20886993408203125,
    },
    {
      x: 22558.59375,
      y: -0.26866912841796875,
    },
    {
      x: 22562.5,
      y: -0.3284721374511719,
    },
    {
      x: 22566.40625,
      y: -0.388275146484375,
    },
    {
      x: 22570.3125,
      y: -0.4231605529785156,
    },
    {
      x: 22574.21875,
      y: -0.4032249450683594,
    },
    {
      x: 22578.125,
      y: -0.3533897399902344,
    },
    {
      x: 22582.03125,
      y: -0.2985725402832031,
    },
    {
      x: 22585.9375,
      y: -0.24375152587890625,
    },
    {
      x: 22589.84375,
      y: -0.18394851684570312,
    },
    {
      x: 22593.75,
      y: -0.12913131713867188,
    },
    {
      x: 22597.65625,
      y: -0.074310302734375,
    },
    {
      x: 22601.5625,
      y: -0.01949310302734375,
    },
    {
      x: 22605.46875,
      y: 0.040309906005859375,
    },
    {
      x: 22609.375,
      y: 0.0851593017578125,
    },
    {
      x: 22613.28125,
      y: 0.10010910034179688,
    },
    {
      x: 22617.1875,
      y: 0.10509490966796875,
    },
    {
      x: 22621.09375,
      y: 0.110076904296875,
    },
    {
      x: 22625,
      y: 0.110076904296875,
    },
    {
      x: 22746.09375,
      y: 0.110076904296875,
    },
    {
      x: 22750,
      y: 0.11506271362304688,
    },
    {
      x: 22753.90625,
      y: 0.11506271362304688,
    },
    {
      x: 22843.75,
      y: 0.11506271362304688,
    },
    {
      x: 22847.65625,
      y: 0.13001251220703125,
    },
    {
      x: 22851.5625,
      y: 0.159912109375,
    },
    {
      x: 22855.46875,
      y: 0.19479751586914062,
    },
    {
      x: 22859.375,
      y: 0.21971511840820312,
    },
    {
      x: 22863.28125,
      y: 0.2346649169921875,
    },
    {
      x: 22867.1875,
      y: 0.23964691162109375,
    },
    {
      x: 22871.09375,
      y: 0.24462890625,
    },
    {
      x: 22875,
      y: 0.25460052490234375,
    },
    {
      x: 22878.90625,
      y: 0.2595863342285156,
    },
    {
      x: 22882.8125,
      y: 0.2645683288574219,
    },
    {
      x: 22886.71875,
      y: 0.2695503234863281,
    },
    {
      x: 22890.625,
      y: 0.2745361328125,
    },
    {
      x: 22894.53125,
      y: 0.27951812744140625,
    },
    {
      x: 22898.4375,
      y: 0.2845001220703125,
    },
    {
      x: 22902.34375,
      y: 0.2894859313964844,
    },
    {
      x: 22906.25,
      y: 0.2944679260253906,
    },
    {
      x: 22910.15625,
      y: 0.2994499206542969,
    },
    {
      x: 22914.0625,
      y: 0.30443572998046875,
    },
    {
      x: 22917.96875,
      y: 0.309417724609375,
    },
    {
      x: 22921.875,
      y: 0.31439971923828125,
    },
    {
      x: 22925.78125,
      y: 0.31439971923828125,
    },
    {
      x: 22929.6875,
      y: 0.31439971923828125,
    },
    {
      x: 22933.59375,
      y: 0.30443572998046875,
    },
    {
      x: 22937.5,
      y: 0.2944679260253906,
    },
    {
      x: 22941.40625,
      y: 0.2845001220703125,
    },
    {
      x: 22945.3125,
      y: 0.27951812744140625,
    },
    {
      x: 22949.21875,
      y: 0.2695503234863281,
    },
    {
      x: 22953.125,
      y: 0.2595863342285156,
    },
    {
      x: 22957.03125,
      y: 0.24961471557617188,
    },
    {
      x: 22960.9375,
      y: 0.24462890625,
    },
    {
      x: 22964.84375,
      y: 0.2346649169921875,
    },
    {
      x: 22968.75,
      y: 0.22469711303710938,
    },
    {
      x: 22972.65625,
      y: 0.21971511840820312,
    },
    {
      x: 22976.5625,
      y: 0.209747314453125,
    },
    {
      x: 22980.46875,
      y: 0.1997833251953125,
    },
    {
      x: 22984.375,
      y: 0.19479751586914062,
    },
    {
      x: 22988.28125,
      y: 0.18483352661132812,
    },
    {
      x: 22992.1875,
      y: 0.17486572265625,
    },
    {
      x: 22996.09375,
      y: 0.17486572265625,
    },
    {
      x: 23000,
      y: 0.15492630004882812,
    },
    {
      x: 23003.90625,
      y: 0.15492630004882812,
    },
    {
      x: 23050.78125,
      y: 0.15492630004882812,
    },
    {
      x: 23054.6875,
      y: 0.13997650146484375,
    },
    {
      x: 23058.59375,
      y: 0.09014511108398438,
    },
    {
      x: 23062.5,
      y: 0.03034210205078125,
    },
    {
      x: 23078.125,
      y: -0.20886993408203125,
    },
    {
      x: 23082.03125,
      y: -0.26866912841796875,
    },
    {
      x: 23085.9375,
      y: -0.3284721374511719,
    },
    {
      x: 23089.84375,
      y: -0.388275146484375,
    },
    {
      x: 23093.75,
      y: -0.4231605529785156,
    },
    {
      x: 23097.65625,
      y: -0.4032249450683594,
    },
    {
      x: 23101.5625,
      y: -0.3533897399902344,
    },
    {
      x: 23105.46875,
      y: -0.2985725402832031,
    },
    {
      x: 23109.375,
      y: -0.24375152587890625,
    },
    {
      x: 23113.28125,
      y: -0.18394851684570312,
    },
    {
      x: 23117.1875,
      y: -0.12913131713867188,
    },
    {
      x: 23121.09375,
      y: -0.06932830810546875,
    },
    {
      x: 23125,
      y: -0.0145111083984375,
    },
    {
      x: 23128.90625,
      y: 0.040309906005859375,
    },
    {
      x: 23132.8125,
      y: 0.09014511108398438,
    },
    {
      x: 23136.71875,
      y: 0.10509490966796875,
    },
    {
      x: 23140.625,
      y: 0.110076904296875,
    },
    {
      x: 23144.53125,
      y: 0.11506271362304688,
    },
    {
      x: 23148.4375,
      y: 0.11506271362304688,
    },
    {
      x: 23367.1875,
      y: 0.11506271362304688,
    },
    {
      x: 23371.09375,
      y: 0.13001251220703125,
    },
    {
      x: 23375,
      y: 0.159912109375,
    },
    {
      x: 23378.90625,
      y: 0.19479751586914062,
    },
    {
      x: 23382.8125,
      y: 0.21971511840820312,
    },
    {
      x: 23386.71875,
      y: 0.2346649169921875,
    },
    {
      x: 23390.625,
      y: 0.23964691162109375,
    },
    {
      x: 23394.53125,
      y: 0.24462890625,
    },
    {
      x: 23398.4375,
      y: 0.25460052490234375,
    },
    {
      x: 23402.34375,
      y: 0.2595863342285156,
    },
    {
      x: 23406.25,
      y: 0.2645683288574219,
    },
    {
      x: 23410.15625,
      y: 0.2695503234863281,
    },
    {
      x: 23414.0625,
      y: 0.2745361328125,
    },
    {
      x: 23417.96875,
      y: 0.27951812744140625,
    },
    {
      x: 23421.875,
      y: 0.2845001220703125,
    },
    {
      x: 23425.78125,
      y: 0.2894859313964844,
    },
    {
      x: 23429.6875,
      y: 0.2944679260253906,
    },
    {
      x: 23433.59375,
      y: 0.2994499206542969,
    },
    {
      x: 23437.5,
      y: 0.30443572998046875,
    },
    {
      x: 23441.40625,
      y: 0.309417724609375,
    },
    {
      x: 23445.3125,
      y: 0.31439971923828125,
    },
    {
      x: 23449.21875,
      y: 0.3193855285644531,
    },
    {
      x: 23453.125,
      y: 0.31439971923828125,
    },
    {
      x: 23457.03125,
      y: 0.30443572998046875,
    },
    {
      x: 23460.9375,
      y: 0.2944679260253906,
    },
    {
      x: 23464.84375,
      y: 0.2845001220703125,
    },
    {
      x: 23468.75,
      y: 0.27951812744140625,
    },
    {
      x: 23472.65625,
      y: 0.2695503234863281,
    },
    {
      x: 23476.5625,
      y: 0.2595863342285156,
    },
    {
      x: 23480.46875,
      y: 0.24961471557617188,
    },
    {
      x: 23484.375,
      y: 0.24462890625,
    },
    {
      x: 23488.28125,
      y: 0.2346649169921875,
    },
    {
      x: 23492.1875,
      y: 0.22469711303710938,
    },
    {
      x: 23496.09375,
      y: 0.21971511840820312,
    },
    {
      x: 23500,
      y: 0.15492630004882812,
    },
    {
      x: 23503.90625,
      y: 0.15492630004882812,
    },
    {
      x: 23746.09375,
      y: 0.15492630004882812,
    },
    {
      x: 23750,
      y: 0.209747314453125,
    },
    {
      x: 23753.90625,
      y: 0.1997833251953125,
    },
    {
      x: 23757.8125,
      y: 0.19479751586914062,
    },
    {
      x: 23761.71875,
      y: 0.18483352661132812,
    },
    {
      x: 23765.625,
      y: 0.17486572265625,
    },
    {
      x: 23769.53125,
      y: 0.17486572265625,
    },
    {
      x: 23773.4375,
      y: 0.1698760986328125,
    },
    {
      x: 23777.34375,
      y: 0.1698760986328125,
    },
    {
      x: 23785.15625,
      y: 0.1698760986328125,
    },
    {
      x: 23789.0625,
      y: 0.16489410400390625,
    },
    {
      x: 23792.96875,
      y: 0.16489410400390625,
    },
    {
      x: 23816.40625,
      y: 0.16489410400390625,
    },
    {
      x: 23820.3125,
      y: 0.159912109375,
    },
    {
      x: 23824.21875,
      y: 0.159912109375,
    },
    {
      x: 23847.65625,
      y: 0.159912109375,
    },
    {
      x: 23851.5625,
      y: 0.15492630004882812,
    },
    {
      x: 23855.46875,
      y: 0.15492630004882812,
    },
    {
      x: 24000,
      y: 0.15492630004882812,
    },
  ],
  v1: [
    {
      x: 0,
      y: 0.15492630004882812,
    },
    {
      x: 44.83695652173913,
      y: 0.15492630004882812,
    },
    {
      x: 48.913043478260875,
      y: 0.16489410400390625,
    },
    {
      x: 52.98913043478261,
      y: 0.19479751586914062,
    },
    {
      x: 57.06521739130435,
      y: 0.22469711303710938,
    },
    {
      x: 61.141304347826086,
      y: 0.2595863342285156,
    },
    {
      x: 65.21739130434783,
      y: 0.2944679260253906,
    },
    {
      x: 69.29347826086956,
      y: 0.3293495178222656,
    },
    {
      x: 73.36956521739131,
      y: 0.3642387390136719,
    },
    {
      x: 77.44565217391305,
      y: 0.3991203308105469,
    },
    {
      x: 81.52173913043478,
      y: 0.4340057373046875,
    },
    {
      x: 85.59782608695653,
      y: 0.46390533447265625,
    },
    {
      x: 89.67391304347827,
      y: 0.4788551330566406,
    },
    {
      x: 93.75,
      y: 0.4489555358886719,
    },
    {
      x: 97.82608695652175,
      y: 0.409088134765625,
    },
    {
      x: 101.90217391304348,
      y: 0.3642387390136719,
    },
    {
      x: 105.97826086956522,
      y: 0.31439971923828125,
    },
    {
      x: 110.05434782608695,
      y: 0.2645683288574219,
    },
    {
      x: 114.1304347826087,
      y: 0.21971511840820312,
    },
    {
      x: 118.20652173913044,
      y: 0.1698760986328125,
    },
    {
      x: 122.28260869565217,
      y: 0.11506271362304688,
    },
    {
      x: 126.35869565217392,
      y: 0.06522369384765625,
    },
    {
      x: 130.43478260869566,
      y: 0.025356292724609375,
    },
    {
      x: 134.5108695652174,
      y: 0.015392303466796875,
    },
    {
      x: 138.58695652173913,
      y: 0.010406494140625,
    },
    {
      x: 142.66304347826087,
      y: 0.010406494140625,
    },
    {
      x: 154.8913043478261,
      y: 0.010406494140625,
    },
    {
      x: 158.96739130434784,
      y: 0.015392303466796875,
    },
    {
      x: 163.04347826086956,
      y: 0.015392303466796875,
    },
    {
      x: 171.19565217391306,
      y: 0.015392303466796875,
    },
    {
      x: 175.27173913043478,
      y: 0.020374298095703125,
    },
    {
      x: 179.34782608695653,
      y: 0.020374298095703125,
    },
    {
      x: 187.5,
      y: 0.020374298095703125,
    },
    {
      x: 191.57608695652175,
      y: 0.025356292724609375,
    },
    {
      x: 195.6521739130435,
      y: 0.025356292724609375,
    },
    {
      x: 203.80434782608697,
      y: 0.025356292724609375,
    },
    {
      x: 207.8804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 211.95652173913044,
      y: 0.03034210205078125,
    },
    {
      x: 220.1086956521739,
      y: 0.03034210205078125,
    },
    {
      x: 224.18478260869566,
      y: 0.0353240966796875,
    },
    {
      x: 228.2608695652174,
      y: 0.0353240966796875,
    },
    {
      x: 236.41304347826087,
      y: 0.0353240966796875,
    },
    {
      x: 240.48913043478262,
      y: 0.040309906005859375,
    },
    {
      x: 244.56521739130434,
      y: 0.040309906005859375,
    },
    {
      x: 252.71739130434784,
      y: 0.040309906005859375,
    },
    {
      x: 256.79347826086956,
      y: 0.045291900634765625,
    },
    {
      x: 260.8695652173913,
      y: 0.15492630004882812,
    },
    {
      x: 264.94565217391306,
      y: 0.159912109375,
    },
    {
      x: 269.0217391304348,
      y: 0.16489410400390625,
    },
    {
      x: 273.0978260869565,
      y: 0.1698760986328125,
    },
    {
      x: 277.17391304347825,
      y: 0.1698760986328125,
    },
    {
      x: 281.25,
      y: 0.16489410400390625,
    },
    {
      x: 285.32608695652175,
      y: 0.16489410400390625,
    },
    {
      x: 313.85869565217394,
      y: 0.16489410400390625,
    },
    {
      x: 317.9347826086957,
      y: 0.159912109375,
    },
    {
      x: 322.0108695652174,
      y: 0.159912109375,
    },
    {
      x: 346.4673913043478,
      y: 0.159912109375,
    },
    {
      x: 350.54347826086956,
      y: 0.15492630004882812,
    },
    {
      x: 354.6195652173913,
      y: 0.15492630004882812,
    },
    {
      x: 778.5326086956522,
      y: 0.15492630004882812,
    },
    {
      x: 782.608695652174,
      y: 0.0353240966796875,
    },
    {
      x: 786.6847826086956,
      y: 0.040309906005859375,
    },
    {
      x: 790.7608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 798.9130434782609,
      y: 0.040309906005859375,
    },
    {
      x: 802.9891304347826,
      y: 0.045291900634765625,
    },
    {
      x: 807.0652173913044,
      y: 0.045291900634765625,
    },
    {
      x: 823.3695652173914,
      y: 0.045291900634765625,
    },
    {
      x: 827.4456521739131,
      y: 0.050273895263671875,
    },
    {
      x: 831.5217391304348,
      y: 0.050273895263671875,
    },
    {
      x: 855.9782608695652,
      y: 0.050273895263671875,
    },
    {
      x: 860.054347826087,
      y: 0.05525970458984375,
    },
    {
      x: 864.1304347826087,
      y: 0.05525970458984375,
    },
    {
      x: 888.5869565217391,
      y: 0.05525970458984375,
    },
    {
      x: 892.6630434782609,
      y: 0.06024169921875,
    },
    {
      x: 896.7391304347826,
      y: 0.06024169921875,
    },
    {
      x: 900.8152173913044,
      y: 0.06522369384765625,
    },
    {
      x: 904.8913043478261,
      y: 0.09512710571289062,
    },
    {
      x: 908.9673913043479,
      y: 0.1349945068359375,
    },
    {
      x: 913.0434782608696,
      y: 0.159912109375,
    },
    {
      x: 917.1195652173914,
      y: 0.16489410400390625,
    },
    {
      x: 921.1956521739131,
      y: 0.16489410400390625,
    },
    {
      x: 925.2717391304348,
      y: 0.159912109375,
    },
    {
      x: 929.3478260869565,
      y: 0.15492630004882812,
    },
    {
      x: 933.4239130434783,
      y: 0.14994430541992188,
    },
    {
      x: 937.5,
      y: 0.14496231079101562,
    },
    {
      x: 941.5760869565217,
      y: 0.13997650146484375,
    },
    {
      x: 945.6521739130435,
      y: 0.1349945068359375,
    },
    {
      x: 949.7282608695652,
      y: 0.125030517578125,
    },
    {
      x: 953.804347826087,
      y: 0.12004470825195312,
    },
    {
      x: 957.8804347826087,
      y: 0.11506271362304688,
    },
    {
      x: 961.9565217391305,
      y: 0.110076904296875,
    },
    {
      x: 966.0326086956522,
      y: 0.10509490966796875,
    },
    {
      x: 970.108695652174,
      y: 0.10010910034179688,
    },
    {
      x: 974.1847826086956,
      y: 0.09512710571289062,
    },
    {
      x: 978.2608695652174,
      y: 0.09512710571289062,
    },
    {
      x: 982.3369565217391,
      y: 0.09014511108398438,
    },
    {
      x: 986.4130434782609,
      y: 0.0851593017578125,
    },
    {
      x: 990.4891304347826,
      y: 0.09014511108398438,
    },
    {
      x: 994.5652173913044,
      y: 0.09512710571289062,
    },
    {
      x: 998.6413043478261,
      y: 0.09512710571289062,
    },
    {
      x: 1002.7173913043479,
      y: 0.10010910034179688,
    },
    {
      x: 1006.7934782608696,
      y: 0.10509490966796875,
    },
    {
      x: 1010.8695652173914,
      y: 0.110076904296875,
    },
    {
      x: 1014.9456521739131,
      y: 0.11506271362304688,
    },
    {
      x: 1019.0217391304349,
      y: 0.12004470825195312,
    },
    {
      x: 1023.0978260869565,
      y: 0.13001251220703125,
    },
    {
      x: 1027.1739130434783,
      y: 0.1349945068359375,
    },
    {
      x: 1031.25,
      y: 0.13997650146484375,
    },
    {
      x: 1035.3260869565217,
      y: 0.14496231079101562,
    },
    {
      x: 1039.4021739130435,
      y: 0.14994430541992188,
    },
    {
      x: 1043.4782608695652,
      y: 0.15492630004882812,
    },
    {
      x: 1047.554347826087,
      y: 0.15492630004882812,
    },
    {
      x: 1373.6413043478262,
      y: 0.15492630004882812,
    },
    {
      x: 1377.7173913043478,
      y: 0.16489410400390625,
    },
    {
      x: 1381.7934782608695,
      y: 0.19479751586914062,
    },
    {
      x: 1385.8695652173913,
      y: 0.22469711303710938,
    },
    {
      x: 1389.945652173913,
      y: 0.2595863342285156,
    },
    {
      x: 1394.0217391304348,
      y: 0.2944679260253906,
    },
    {
      x: 1398.0978260869565,
      y: 0.3293495178222656,
    },
    {
      x: 1402.1739130434783,
      y: 0.3642387390136719,
    },
    {
      x: 1406.25,
      y: 0.3991203308105469,
    },
    {
      x: 1410.3260869565217,
      y: 0.4340057373046875,
    },
    {
      x: 1414.4021739130435,
      y: 0.46390533447265625,
    },
    {
      x: 1418.4782608695652,
      y: 0.4788551330566406,
    },
    {
      x: 1422.554347826087,
      y: 0.4489555358886719,
    },
    {
      x: 1426.6304347826087,
      y: 0.409088134765625,
    },
    {
      x: 1430.7065217391305,
      y: 0.3642387390136719,
    },
    {
      x: 1434.7826086956522,
      y: 0.31439971923828125,
    },
    {
      x: 1438.858695652174,
      y: 0.2645683288574219,
    },
    {
      x: 1442.9347826086957,
      y: 0.21971511840820312,
    },
    {
      x: 1447.0108695652175,
      y: 0.1698760986328125,
    },
    {
      x: 1451.0869565217392,
      y: 0.11506271362304688,
    },
    {
      x: 1455.163043478261,
      y: 0.06522369384765625,
    },
    {
      x: 1459.2391304347827,
      y: 0.025356292724609375,
    },
    {
      x: 1463.3152173913045,
      y: 0.015392303466796875,
    },
    {
      x: 1467.3913043478262,
      y: 0.010406494140625,
    },
    {
      x: 1471.4673913043478,
      y: 0.010406494140625,
    },
    {
      x: 1483.695652173913,
      y: 0.010406494140625,
    },
    {
      x: 1487.7717391304348,
      y: 0.015392303466796875,
    },
    {
      x: 1491.8478260869565,
      y: 0.015392303466796875,
    },
    {
      x: 1500,
      y: 0.015392303466796875,
    },
    {
      x: 1504.0760869565217,
      y: 0.020374298095703125,
    },
    {
      x: 1508.1521739130435,
      y: 0.020374298095703125,
    },
    {
      x: 1516.304347826087,
      y: 0.020374298095703125,
    },
    {
      x: 1520.3804347826087,
      y: 0.025356292724609375,
    },
    {
      x: 1524.4565217391305,
      y: 0.025356292724609375,
    },
    {
      x: 1532.608695652174,
      y: 0.025356292724609375,
    },
    {
      x: 1536.6847826086957,
      y: 0.03034210205078125,
    },
    {
      x: 1540.7608695652175,
      y: 0.03034210205078125,
    },
    {
      x: 1548.913043478261,
      y: 0.03034210205078125,
    },
    {
      x: 1552.9891304347827,
      y: 0.0353240966796875,
    },
    {
      x: 1557.0652173913045,
      y: 0.0353240966796875,
    },
    {
      x: 1561.1413043478262,
      y: 0.0353240966796875,
    },
    {
      x: 1565.217391304348,
      y: 0.12004470825195312,
    },
    {
      x: 1569.2934782608695,
      y: 0.13001251220703125,
    },
    {
      x: 1573.3695652173913,
      y: 0.1349945068359375,
    },
    {
      x: 1577.445652173913,
      y: 0.13997650146484375,
    },
    {
      x: 1581.5217391304348,
      y: 0.14496231079101562,
    },
    {
      x: 1585.5978260869565,
      y: 0.14994430541992188,
    },
    {
      x: 1589.6739130434783,
      y: 0.15492630004882812,
    },
    {
      x: 1593.75,
      y: 0.159912109375,
    },
    {
      x: 1597.8260869565217,
      y: 0.16489410400390625,
    },
    {
      x: 1601.9021739130435,
      y: 0.1698760986328125,
    },
    {
      x: 1605.9782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 1610.054347826087,
      y: 0.16489410400390625,
    },
    {
      x: 1614.1304347826087,
      y: 0.16489410400390625,
    },
    {
      x: 1642.663043478261,
      y: 0.16489410400390625,
    },
    {
      x: 1646.7391304347827,
      y: 0.159912109375,
    },
    {
      x: 1650.8152173913045,
      y: 0.159912109375,
    },
    {
      x: 1675.2717391304348,
      y: 0.159912109375,
    },
    {
      x: 1679.3478260869565,
      y: 0.15492630004882812,
    },
    {
      x: 1683.4239130434783,
      y: 0.15492630004882812,
    },
    {
      x: 1822.0108695652175,
      y: 0.15492630004882812,
    },
    {
      x: 1826.0869565217392,
      y: 0.03034210205078125,
    },
    {
      x: 1830.163043478261,
      y: 0.03034210205078125,
    },
    {
      x: 1834.2391304347827,
      y: 0.03034210205078125,
    },
    {
      x: 1838.3152173913045,
      y: 0.0353240966796875,
    },
    {
      x: 1842.3913043478262,
      y: 0.0353240966796875,
    },
    {
      x: 1850.5434782608695,
      y: 0.0353240966796875,
    },
    {
      x: 1854.6195652173913,
      y: 0.040309906005859375,
    },
    {
      x: 1858.695652173913,
      y: 0.040309906005859375,
    },
    {
      x: 1866.8478260869565,
      y: 0.040309906005859375,
    },
    {
      x: 1870.9239130434783,
      y: 0.045291900634765625,
    },
    {
      x: 1875,
      y: 0.045291900634765625,
    },
    {
      x: 1891.304347826087,
      y: 0.045291900634765625,
    },
    {
      x: 1895.3804347826087,
      y: 0.050273895263671875,
    },
    {
      x: 1899.4565217391305,
      y: 0.050273895263671875,
    },
    {
      x: 1923.913043478261,
      y: 0.050273895263671875,
    },
    {
      x: 1927.9891304347827,
      y: 0.05525970458984375,
    },
    {
      x: 1932.0652173913045,
      y: 0.05525970458984375,
    },
    {
      x: 1956.5217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 1960.5978260869565,
      y: 0.06024169921875,
    },
    {
      x: 1964.6739130434783,
      y: 0.06024169921875,
    },
    {
      x: 1968.75,
      y: 0.06522369384765625,
    },
    {
      x: 1972.8260869565217,
      y: 0.09512710571289062,
    },
    {
      x: 1976.9021739130435,
      y: 0.1349945068359375,
    },
    {
      x: 1980.9782608695652,
      y: 0.159912109375,
    },
    {
      x: 1985.054347826087,
      y: 0.16489410400390625,
    },
    {
      x: 1989.1304347826087,
      y: 0.16489410400390625,
    },
    {
      x: 1993.2065217391305,
      y: 0.159912109375,
    },
    {
      x: 1997.2826086956522,
      y: 0.15492630004882812,
    },
    {
      x: 2001.358695652174,
      y: 0.14994430541992188,
    },
    {
      x: 2005.4347826086957,
      y: 0.14496231079101562,
    },
    {
      x: 2009.5108695652175,
      y: 0.13997650146484375,
    },
    {
      x: 2013.5869565217392,
      y: 0.1349945068359375,
    },
    {
      x: 2017.663043478261,
      y: 0.125030517578125,
    },
    {
      x: 2021.7391304347827,
      y: 0.12004470825195312,
    },
    {
      x: 2025.8152173913045,
      y: 0.11506271362304688,
    },
    {
      x: 2029.8913043478262,
      y: 0.110076904296875,
    },
    {
      x: 2033.967391304348,
      y: 0.10509490966796875,
    },
    {
      x: 2038.0434782608697,
      y: 0.10010910034179688,
    },
    {
      x: 2042.1195652173913,
      y: 0.09512710571289062,
    },
    {
      x: 2046.195652173913,
      y: 0.09512710571289062,
    },
    {
      x: 2050.271739130435,
      y: 0.09014511108398438,
    },
    {
      x: 2054.3478260869565,
      y: 0.0851593017578125,
    },
    {
      x: 2058.4239130434785,
      y: 0.09014511108398438,
    },
    {
      x: 2062.5,
      y: 0.09512710571289062,
    },
    {
      x: 2066.576086956522,
      y: 0.09512710571289062,
    },
    {
      x: 2070.6521739130435,
      y: 0.10010910034179688,
    },
    {
      x: 2074.7282608695655,
      y: 0.10509490966796875,
    },
    {
      x: 2078.804347826087,
      y: 0.110076904296875,
    },
    {
      x: 2082.880434782609,
      y: 0.11506271362304688,
    },
    {
      x: 2086.9565217391305,
      y: 0.020374298095703125,
    },
    {
      x: 2091.032608695652,
      y: 0.025356292724609375,
    },
    {
      x: 2095.108695652174,
      y: 0.025356292724609375,
    },
    {
      x: 2103.2608695652175,
      y: 0.025356292724609375,
    },
    {
      x: 2107.336956521739,
      y: 0.03034210205078125,
    },
    {
      x: 2111.413043478261,
      y: 0.03034210205078125,
    },
    {
      x: 2119.5652173913045,
      y: 0.03034210205078125,
    },
    {
      x: 2123.641304347826,
      y: 0.0353240966796875,
    },
    {
      x: 2127.717391304348,
      y: 0.0353240966796875,
    },
    {
      x: 2135.8695652173915,
      y: 0.0353240966796875,
    },
    {
      x: 2139.945652173913,
      y: 0.040309906005859375,
    },
    {
      x: 2144.021739130435,
      y: 0.040309906005859375,
    },
    {
      x: 2152.1739130434785,
      y: 0.040309906005859375,
    },
    {
      x: 2156.25,
      y: 0.045291900634765625,
    },
    {
      x: 2160.326086956522,
      y: 0.045291900634765625,
    },
    {
      x: 2176.630434782609,
      y: 0.045291900634765625,
    },
    {
      x: 2180.7065217391305,
      y: 0.050273895263671875,
    },
    {
      x: 2184.782608695652,
      y: 0.050273895263671875,
    },
    {
      x: 2209.2391304347825,
      y: 0.050273895263671875,
    },
    {
      x: 2213.3152173913045,
      y: 0.05525970458984375,
    },
    {
      x: 2217.391304347826,
      y: 0.05525970458984375,
    },
    {
      x: 2241.8478260869565,
      y: 0.05525970458984375,
    },
    {
      x: 2245.9239130434785,
      y: 0.06024169921875,
    },
    {
      x: 2250,
      y: 0.06024169921875,
    },
    {
      x: 2254.076086956522,
      y: 0.06522369384765625,
    },
    {
      x: 2258.1521739130435,
      y: 0.09512710571289062,
    },
    {
      x: 2262.2282608695655,
      y: 0.1349945068359375,
    },
    {
      x: 2266.304347826087,
      y: 0.159912109375,
    },
    {
      x: 2270.380434782609,
      y: 0.16489410400390625,
    },
    {
      x: 2274.4565217391305,
      y: 0.16489410400390625,
    },
    {
      x: 2278.532608695652,
      y: 0.159912109375,
    },
    {
      x: 2282.608695652174,
      y: 0.15492630004882812,
    },
    {
      x: 2286.6847826086955,
      y: 0.14994430541992188,
    },
    {
      x: 2290.7608695652175,
      y: 0.14496231079101562,
    },
    {
      x: 2294.836956521739,
      y: 0.13997650146484375,
    },
    {
      x: 2298.913043478261,
      y: 0.1349945068359375,
    },
    {
      x: 2302.9891304347825,
      y: 0.125030517578125,
    },
    {
      x: 2307.0652173913045,
      y: 0.12004470825195312,
    },
    {
      x: 2311.141304347826,
      y: 0.11506271362304688,
    },
    {
      x: 2315.217391304348,
      y: 0.110076904296875,
    },
    {
      x: 2319.2934782608695,
      y: 0.10509490966796875,
    },
    {
      x: 2323.3695652173915,
      y: 0.10010910034179688,
    },
    {
      x: 2327.445652173913,
      y: 0.10010910034179688,
    },
    {
      x: 2331.521739130435,
      y: 0.09512710571289062,
    },
    {
      x: 2335.5978260869565,
      y: 0.09014511108398438,
    },
    {
      x: 2339.6739130434785,
      y: 0.0851593017578125,
    },
    {
      x: 2343.75,
      y: 0.09014511108398438,
    },
    {
      x: 2347.826086956522,
      y: 0.15492630004882812,
    },
    {
      x: 2351.9021739130435,
      y: 0.15492630004882812,
    },
    {
      x: 2702.445652173913,
      y: 0.15492630004882812,
    },
    {
      x: 2706.521739130435,
      y: 0.16489410400390625,
    },
    {
      x: 2710.5978260869565,
      y: 0.19479751586914062,
    },
    {
      x: 2714.6739130434785,
      y: 0.22469711303710938,
    },
    {
      x: 2718.75,
      y: 0.2595863342285156,
    },
    {
      x: 2722.826086956522,
      y: 0.2944679260253906,
    },
    {
      x: 2726.9021739130435,
      y: 0.3293495178222656,
    },
    {
      x: 2730.9782608695655,
      y: 0.3642387390136719,
    },
    {
      x: 2735.054347826087,
      y: 0.3991203308105469,
    },
    {
      x: 2739.130434782609,
      y: 0.4340057373046875,
    },
    {
      x: 2743.2065217391305,
      y: 0.46390533447265625,
    },
    {
      x: 2747.2826086956525,
      y: 0.4788551330566406,
    },
    {
      x: 2751.358695652174,
      y: 0.4489555358886719,
    },
    {
      x: 2755.4347826086955,
      y: 0.409088134765625,
    },
    {
      x: 2759.5108695652175,
      y: 0.3642387390136719,
    },
    {
      x: 2763.586956521739,
      y: 0.31439971923828125,
    },
    {
      x: 2767.663043478261,
      y: 0.2645683288574219,
    },
    {
      x: 2771.7391304347825,
      y: 0.21971511840820312,
    },
    {
      x: 2775.8152173913045,
      y: 0.1698760986328125,
    },
    {
      x: 2779.891304347826,
      y: 0.11506271362304688,
    },
    {
      x: 2783.967391304348,
      y: 0.06522369384765625,
    },
    {
      x: 2788.0434782608695,
      y: 0.025356292724609375,
    },
    {
      x: 2792.1195652173915,
      y: 0.015392303466796875,
    },
    {
      x: 2796.195652173913,
      y: 0.010406494140625,
    },
    {
      x: 2800.271739130435,
      y: 0.010406494140625,
    },
    {
      x: 2812.5,
      y: 0.010406494140625,
    },
    {
      x: 2816.576086956522,
      y: 0.015392303466796875,
    },
    {
      x: 2820.6521739130435,
      y: 0.015392303466796875,
    },
    {
      x: 2828.804347826087,
      y: 0.015392303466796875,
    },
    {
      x: 2832.880434782609,
      y: 0.020374298095703125,
    },
    {
      x: 2836.9565217391305,
      y: 0.020374298095703125,
    },
    {
      x: 2845.108695652174,
      y: 0.020374298095703125,
    },
    {
      x: 2849.1847826086955,
      y: 0.025356292724609375,
    },
    {
      x: 2853.2608695652175,
      y: 0.025356292724609375,
    },
    {
      x: 2861.413043478261,
      y: 0.025356292724609375,
    },
    {
      x: 2865.4891304347825,
      y: 0.03034210205078125,
    },
    {
      x: 2869.5652173913045,
      y: 0.09512710571289062,
    },
    {
      x: 2873.641304347826,
      y: 0.09512710571289062,
    },
    {
      x: 2877.717391304348,
      y: 0.10010910034179688,
    },
    {
      x: 2881.7934782608695,
      y: 0.10509490966796875,
    },
    {
      x: 2885.8695652173915,
      y: 0.110076904296875,
    },
    {
      x: 2889.945652173913,
      y: 0.11506271362304688,
    },
    {
      x: 2894.021739130435,
      y: 0.12004470825195312,
    },
    {
      x: 2898.0978260869565,
      y: 0.13001251220703125,
    },
    {
      x: 2902.1739130434785,
      y: 0.1349945068359375,
    },
    {
      x: 2906.25,
      y: 0.13997650146484375,
    },
    {
      x: 2910.326086956522,
      y: 0.14496231079101562,
    },
    {
      x: 2914.4021739130435,
      y: 0.14994430541992188,
    },
    {
      x: 2918.4782608695655,
      y: 0.15492630004882812,
    },
    {
      x: 2922.554347826087,
      y: 0.159912109375,
    },
    {
      x: 2926.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 2930.7065217391305,
      y: 0.1698760986328125,
    },
    {
      x: 2934.7826086956525,
      y: 0.1698760986328125,
    },
    {
      x: 2938.858695652174,
      y: 0.16489410400390625,
    },
    {
      x: 2942.9347826086955,
      y: 0.16489410400390625,
    },
    {
      x: 2971.467391304348,
      y: 0.16489410400390625,
    },
    {
      x: 2975.5434782608695,
      y: 0.159912109375,
    },
    {
      x: 2979.6195652173915,
      y: 0.159912109375,
    },
    {
      x: 3004.076086956522,
      y: 0.159912109375,
    },
    {
      x: 3008.1521739130435,
      y: 0.15492630004882812,
    },
    {
      x: 3012.2282608695655,
      y: 0.15492630004882812,
    },
    {
      x: 3248.641304347826,
      y: 0.15492630004882812,
    },
    {
      x: 3252.717391304348,
      y: 0.16489410400390625,
    },
    {
      x: 3256.7934782608695,
      y: 0.19479751586914062,
    },
    {
      x: 3260.8695652173915,
      y: 0.22469711303710938,
    },
    {
      x: 3264.945652173913,
      y: 0.2595863342285156,
    },
    {
      x: 3269.021739130435,
      y: 0.2944679260253906,
    },
    {
      x: 3273.0978260869565,
      y: 0.3293495178222656,
    },
    {
      x: 3277.1739130434785,
      y: 0.3642387390136719,
    },
    {
      x: 3281.25,
      y: 0.3991203308105469,
    },
    {
      x: 3285.326086956522,
      y: 0.4340057373046875,
    },
    {
      x: 3289.4021739130435,
      y: 0.46390533447265625,
    },
    {
      x: 3293.4782608695655,
      y: 0.4788551330566406,
    },
    {
      x: 3297.554347826087,
      y: 0.4489555358886719,
    },
    {
      x: 3301.630434782609,
      y: 0.409088134765625,
    },
    {
      x: 3305.7065217391305,
      y: 0.3642387390136719,
    },
    {
      x: 3309.7826086956525,
      y: 0.31439971923828125,
    },
    {
      x: 3313.858695652174,
      y: 0.2645683288574219,
    },
    {
      x: 3317.934782608696,
      y: 0.21971511840820312,
    },
    {
      x: 3322.0108695652175,
      y: 0.1698760986328125,
    },
    {
      x: 3326.086956521739,
      y: 0.11506271362304688,
    },
    {
      x: 3330.163043478261,
      y: 0.06522369384765625,
    },
    {
      x: 3334.2391304347825,
      y: 0.025356292724609375,
    },
    {
      x: 3338.3152173913045,
      y: 0.015392303466796875,
    },
    {
      x: 3342.391304347826,
      y: 0.010406494140625,
    },
    {
      x: 3346.467391304348,
      y: 0.010406494140625,
    },
    {
      x: 3358.695652173913,
      y: 0.010406494140625,
    },
    {
      x: 3362.771739130435,
      y: 0.015392303466796875,
    },
    {
      x: 3366.8478260869565,
      y: 0.015392303466796875,
    },
    {
      x: 3375,
      y: 0.015392303466796875,
    },
    {
      x: 3379.076086956522,
      y: 0.020374298095703125,
    },
    {
      x: 3383.1521739130435,
      y: 0.020374298095703125,
    },
    {
      x: 3387.2282608695655,
      y: 0.020374298095703125,
    },
    {
      x: 3391.304347826087,
      y: 0.15492630004882812,
    },
    {
      x: 3395.380434782609,
      y: 0.15492630004882812,
    },
    {
      x: 3648.0978260869565,
      y: 0.15492630004882812,
    },
    {
      x: 3652.1739130434785,
      y: 0.10010910034179688,
    },
    {
      x: 3656.25,
      y: 0.09512710571289062,
    },
    {
      x: 3660.326086956522,
      y: 0.09512710571289062,
    },
    {
      x: 3664.4021739130435,
      y: 0.09014511108398438,
    },
    {
      x: 3668.4782608695655,
      y: 0.0851593017578125,
    },
    {
      x: 3672.554347826087,
      y: 0.09014511108398438,
    },
    {
      x: 3676.630434782609,
      y: 0.09512710571289062,
    },
    {
      x: 3680.7065217391305,
      y: 0.09512710571289062,
    },
    {
      x: 3684.7826086956525,
      y: 0.10010910034179688,
    },
    {
      x: 3688.858695652174,
      y: 0.10509490966796875,
    },
    {
      x: 3692.934782608696,
      y: 0.110076904296875,
    },
    {
      x: 3697.0108695652175,
      y: 0.11506271362304688,
    },
    {
      x: 3701.086956521739,
      y: 0.12004470825195312,
    },
    {
      x: 3705.163043478261,
      y: 0.13001251220703125,
    },
    {
      x: 3709.2391304347825,
      y: 0.1349945068359375,
    },
    {
      x: 3713.3152173913045,
      y: 0.13997650146484375,
    },
    {
      x: 3717.391304347826,
      y: 0.14496231079101562,
    },
    {
      x: 3721.467391304348,
      y: 0.14994430541992188,
    },
    {
      x: 3725.5434782608695,
      y: 0.15492630004882812,
    },
    {
      x: 3729.6195652173915,
      y: 0.159912109375,
    },
    {
      x: 3733.695652173913,
      y: 0.16489410400390625,
    },
    {
      x: 3737.771739130435,
      y: 0.1698760986328125,
    },
    {
      x: 3741.8478260869565,
      y: 0.1698760986328125,
    },
    {
      x: 3745.9239130434785,
      y: 0.16489410400390625,
    },
    {
      x: 3750,
      y: 0.16489410400390625,
    },
    {
      x: 3778.5326086956525,
      y: 0.16489410400390625,
    },
    {
      x: 3782.608695652174,
      y: 0.159912109375,
    },
    {
      x: 3786.684782608696,
      y: 0.159912109375,
    },
    {
      x: 3811.141304347826,
      y: 0.159912109375,
    },
    {
      x: 3815.217391304348,
      y: 0.15492630004882812,
    },
    {
      x: 3819.2934782608695,
      y: 0.15492630004882812,
    },
    {
      x: 3908.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 3913.0434782608695,
      y: 0.015392303466796875,
    },
    {
      x: 3917.1195652173915,
      y: 0.015392303466796875,
    },
    {
      x: 3921.195652173913,
      y: 0.015392303466796875,
    },
    {
      x: 3925.271739130435,
      y: 0.020374298095703125,
    },
    {
      x: 3929.3478260869565,
      y: 0.020374298095703125,
    },
    {
      x: 3937.5,
      y: 0.020374298095703125,
    },
    {
      x: 3941.576086956522,
      y: 0.025356292724609375,
    },
    {
      x: 3945.6521739130435,
      y: 0.025356292724609375,
    },
    {
      x: 3953.804347826087,
      y: 0.025356292724609375,
    },
    {
      x: 3957.880434782609,
      y: 0.03034210205078125,
    },
    {
      x: 3961.9565217391305,
      y: 0.03034210205078125,
    },
    {
      x: 3970.108695652174,
      y: 0.03034210205078125,
    },
    {
      x: 3974.184782608696,
      y: 0.0353240966796875,
    },
    {
      x: 3978.2608695652175,
      y: 0.0353240966796875,
    },
    {
      x: 3986.413043478261,
      y: 0.0353240966796875,
    },
    {
      x: 3990.4891304347825,
      y: 0.040309906005859375,
    },
    {
      x: 3994.5652173913045,
      y: 0.040309906005859375,
    },
    {
      x: 4002.717391304348,
      y: 0.040309906005859375,
    },
    {
      x: 4006.7934782608695,
      y: 0.045291900634765625,
    },
    {
      x: 4010.8695652173915,
      y: 0.045291900634765625,
    },
    {
      x: 4027.1739130434785,
      y: 0.045291900634765625,
    },
    {
      x: 4031.25,
      y: 0.050273895263671875,
    },
    {
      x: 4035.326086956522,
      y: 0.050273895263671875,
    },
    {
      x: 4059.7826086956525,
      y: 0.050273895263671875,
    },
    {
      x: 4063.858695652174,
      y: 0.05525970458984375,
    },
    {
      x: 4067.934782608696,
      y: 0.05525970458984375,
    },
    {
      x: 4092.391304347826,
      y: 0.05525970458984375,
    },
    {
      x: 4096.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 4100.54347826087,
      y: 0.06024169921875,
    },
    {
      x: 4104.619565217391,
      y: 0.06522369384765625,
    },
    {
      x: 4108.695652173913,
      y: 0.09512710571289062,
    },
    {
      x: 4112.771739130435,
      y: 0.1349945068359375,
    },
    {
      x: 4116.847826086957,
      y: 0.159912109375,
    },
    {
      x: 4120.923913043478,
      y: 0.16489410400390625,
    },
    {
      x: 4125,
      y: 0.16489410400390625,
    },
    {
      x: 4129.076086956522,
      y: 0.159912109375,
    },
    {
      x: 4133.152173913044,
      y: 0.15492630004882812,
    },
    {
      x: 4137.228260869565,
      y: 0.14994430541992188,
    },
    {
      x: 4141.304347826087,
      y: 0.14496231079101562,
    },
    {
      x: 4145.380434782609,
      y: 0.13997650146484375,
    },
    {
      x: 4149.456521739131,
      y: 0.1349945068359375,
    },
    {
      x: 4153.532608695652,
      y: 0.125030517578125,
    },
    {
      x: 4157.608695652174,
      y: 0.12004470825195312,
    },
    {
      x: 4161.684782608696,
      y: 0.11506271362304688,
    },
    {
      x: 4165.760869565218,
      y: 0.110076904296875,
    },
    {
      x: 4169.836956521739,
      y: 0.10509490966796875,
    },
    {
      x: 4173.913043478261,
      y: 0.15492630004882812,
    },
    {
      x: 4177.989130434783,
      y: 0.15492630004882812,
    },
    {
      x: 4316.576086956522,
      y: 0.15492630004882812,
    },
    {
      x: 4320.652173913044,
      y: 0.16489410400390625,
    },
    {
      x: 4324.728260869565,
      y: 0.19479751586914062,
    },
    {
      x: 4328.804347826087,
      y: 0.22469711303710938,
    },
    {
      x: 4332.880434782609,
      y: 0.2595863342285156,
    },
    {
      x: 4336.956521739131,
      y: 0.2944679260253906,
    },
    {
      x: 4341.032608695652,
      y: 0.3293495178222656,
    },
    {
      x: 4345.108695652174,
      y: 0.3642387390136719,
    },
    {
      x: 4349.184782608696,
      y: 0.3991203308105469,
    },
    {
      x: 4353.260869565218,
      y: 0.4340057373046875,
    },
    {
      x: 4357.336956521739,
      y: 0.46390533447265625,
    },
    {
      x: 4361.413043478261,
      y: 0.4788551330566406,
    },
    {
      x: 4365.489130434783,
      y: 0.4489555358886719,
    },
    {
      x: 4369.565217391304,
      y: 0.409088134765625,
    },
    {
      x: 4373.641304347826,
      y: 0.3642387390136719,
    },
    {
      x: 4377.717391304348,
      y: 0.31439971923828125,
    },
    {
      x: 4381.79347826087,
      y: 0.2645683288574219,
    },
    {
      x: 4385.869565217391,
      y: 0.21971511840820312,
    },
    {
      x: 4389.945652173913,
      y: 0.1698760986328125,
    },
    {
      x: 4394.021739130435,
      y: 0.11506271362304688,
    },
    {
      x: 4398.097826086957,
      y: 0.06522369384765625,
    },
    {
      x: 4402.173913043478,
      y: 0.025356292724609375,
    },
    {
      x: 4406.25,
      y: 0.015392303466796875,
    },
    {
      x: 4410.326086956522,
      y: 0.010406494140625,
    },
    {
      x: 4414.402173913044,
      y: 0.010406494140625,
    },
    {
      x: 4426.630434782609,
      y: 0.010406494140625,
    },
    {
      x: 4430.706521739131,
      y: 0.015392303466796875,
    },
    {
      x: 4434.782608695652,
      y: 0.15492630004882812,
    },
    {
      x: 4438.858695652174,
      y: 0.15492630004882812,
    },
    {
      x: 4691.576086956522,
      y: 0.15492630004882812,
    },
    {
      x: 4695.652173913044,
      y: 0.1349945068359375,
    },
    {
      x: 4699.728260869565,
      y: 0.125030517578125,
    },
    {
      x: 4703.804347826087,
      y: 0.12004470825195312,
    },
    {
      x: 4707.880434782609,
      y: 0.11506271362304688,
    },
    {
      x: 4711.956521739131,
      y: 0.110076904296875,
    },
    {
      x: 4716.032608695652,
      y: 0.10509490966796875,
    },
    {
      x: 4720.108695652174,
      y: 0.10010910034179688,
    },
    {
      x: 4724.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 4728.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 4732.336956521739,
      y: 0.09014511108398438,
    },
    {
      x: 4736.413043478261,
      y: 0.0851593017578125,
    },
    {
      x: 4740.489130434783,
      y: 0.09014511108398438,
    },
    {
      x: 4744.565217391304,
      y: 0.09512710571289062,
    },
    {
      x: 4748.641304347826,
      y: 0.09512710571289062,
    },
    {
      x: 4752.717391304348,
      y: 0.10010910034179688,
    },
    {
      x: 4756.79347826087,
      y: 0.10509490966796875,
    },
    {
      x: 4760.869565217391,
      y: 0.110076904296875,
    },
    {
      x: 4764.945652173913,
      y: 0.11506271362304688,
    },
    {
      x: 4769.021739130435,
      y: 0.12004470825195312,
    },
    {
      x: 4773.097826086957,
      y: 0.13001251220703125,
    },
    {
      x: 4777.173913043478,
      y: 0.1349945068359375,
    },
    {
      x: 4781.25,
      y: 0.13997650146484375,
    },
    {
      x: 4785.326086956522,
      y: 0.14496231079101562,
    },
    {
      x: 4789.402173913044,
      y: 0.14994430541992188,
    },
    {
      x: 4793.478260869565,
      y: 0.15492630004882812,
    },
    {
      x: 4797.554347826087,
      y: 0.159912109375,
    },
    {
      x: 4801.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 4805.706521739131,
      y: 0.1698760986328125,
    },
    {
      x: 4809.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 4813.858695652174,
      y: 0.16489410400390625,
    },
    {
      x: 4817.934782608696,
      y: 0.16489410400390625,
    },
    {
      x: 4846.467391304348,
      y: 0.16489410400390625,
    },
    {
      x: 4850.54347826087,
      y: 0.159912109375,
    },
    {
      x: 4854.619565217391,
      y: 0.159912109375,
    },
    {
      x: 4879.076086956522,
      y: 0.159912109375,
    },
    {
      x: 4883.152173913044,
      y: 0.15492630004882812,
    },
    {
      x: 4887.228260869565,
      y: 0.15492630004882812,
    },
    {
      x: 4952.445652173913,
      y: 0.15492630004882812,
    },
    {
      x: 4956.521739130435,
      y: 0.010406494140625,
    },
    {
      x: 4960.597826086957,
      y: 0.010406494140625,
    },
    {
      x: 4972.826086956522,
      y: 0.010406494140625,
    },
    {
      x: 4976.902173913044,
      y: 0.015392303466796875,
    },
    {
      x: 4980.978260869565,
      y: 0.015392303466796875,
    },
    {
      x: 4989.130434782609,
      y: 0.015392303466796875,
    },
    {
      x: 4993.206521739131,
      y: 0.020374298095703125,
    },
    {
      x: 4997.282608695652,
      y: 0.020374298095703125,
    },
    {
      x: 5005.434782608696,
      y: 0.020374298095703125,
    },
    {
      x: 5009.510869565218,
      y: 0.025356292724609375,
    },
    {
      x: 5013.586956521739,
      y: 0.025356292724609375,
    },
    {
      x: 5021.739130434783,
      y: 0.025356292724609375,
    },
    {
      x: 5025.815217391305,
      y: 0.03034210205078125,
    },
    {
      x: 5029.891304347826,
      y: 0.03034210205078125,
    },
    {
      x: 5038.04347826087,
      y: 0.03034210205078125,
    },
    {
      x: 5042.119565217391,
      y: 0.0353240966796875,
    },
    {
      x: 5046.195652173913,
      y: 0.0353240966796875,
    },
    {
      x: 5054.347826086957,
      y: 0.0353240966796875,
    },
    {
      x: 5058.423913043478,
      y: 0.040309906005859375,
    },
    {
      x: 5062.5,
      y: 0.040309906005859375,
    },
    {
      x: 5070.652173913044,
      y: 0.040309906005859375,
    },
    {
      x: 5074.728260869565,
      y: 0.045291900634765625,
    },
    {
      x: 5078.804347826087,
      y: 0.045291900634765625,
    },
    {
      x: 5095.108695652174,
      y: 0.045291900634765625,
    },
    {
      x: 5099.184782608696,
      y: 0.050273895263671875,
    },
    {
      x: 5103.260869565218,
      y: 0.050273895263671875,
    },
    {
      x: 5127.717391304348,
      y: 0.050273895263671875,
    },
    {
      x: 5131.79347826087,
      y: 0.05525970458984375,
    },
    {
      x: 5135.869565217391,
      y: 0.05525970458984375,
    },
    {
      x: 5160.326086956522,
      y: 0.05525970458984375,
    },
    {
      x: 5164.402173913044,
      y: 0.06024169921875,
    },
    {
      x: 5168.478260869565,
      y: 0.06024169921875,
    },
    {
      x: 5172.554347826087,
      y: 0.06522369384765625,
    },
    {
      x: 5176.630434782609,
      y: 0.09512710571289062,
    },
    {
      x: 5180.706521739131,
      y: 0.1349945068359375,
    },
    {
      x: 5184.782608695652,
      y: 0.159912109375,
    },
    {
      x: 5188.858695652174,
      y: 0.16489410400390625,
    },
    {
      x: 5192.934782608696,
      y: 0.16489410400390625,
    },
    {
      x: 5197.010869565218,
      y: 0.159912109375,
    },
    {
      x: 5201.086956521739,
      y: 0.15492630004882812,
    },
    {
      x: 5205.163043478261,
      y: 0.14994430541992188,
    },
    {
      x: 5209.239130434783,
      y: 0.14496231079101562,
    },
    {
      x: 5213.315217391305,
      y: 0.13997650146484375,
    },
    {
      x: 5217.391304347826,
      y: 0.15492630004882812,
    },
    {
      x: 5221.467391304348,
      y: 0.15492630004882812,
    },
    {
      x: 5645.380434782609,
      y: 0.15492630004882812,
    },
    {
      x: 5649.456521739131,
      y: 0.16489410400390625,
    },
    {
      x: 5653.532608695652,
      y: 0.19479751586914062,
    },
    {
      x: 5657.608695652174,
      y: 0.22469711303710938,
    },
    {
      x: 5661.684782608696,
      y: 0.2595863342285156,
    },
    {
      x: 5665.760869565218,
      y: 0.2944679260253906,
    },
    {
      x: 5669.836956521739,
      y: 0.3293495178222656,
    },
    {
      x: 5673.913043478261,
      y: 0.3642387390136719,
    },
    {
      x: 5677.989130434783,
      y: 0.3991203308105469,
    },
    {
      x: 5682.065217391305,
      y: 0.4340057373046875,
    },
    {
      x: 5686.141304347826,
      y: 0.46390533447265625,
    },
    {
      x: 5690.217391304348,
      y: 0.4788551330566406,
    },
    {
      x: 5694.29347826087,
      y: 0.4489555358886719,
    },
    {
      x: 5698.369565217391,
      y: 0.409088134765625,
    },
    {
      x: 5702.445652173913,
      y: 0.3642387390136719,
    },
    {
      x: 5706.521739130435,
      y: 0.31439971923828125,
    },
    {
      x: 5710.597826086957,
      y: 0.2645683288574219,
    },
    {
      x: 5714.673913043478,
      y: 0.21971511840820312,
    },
    {
      x: 5718.75,
      y: 0.1698760986328125,
    },
    {
      x: 5722.826086956522,
      y: 0.11506271362304688,
    },
    {
      x: 5726.902173913044,
      y: 0.06522369384765625,
    },
    {
      x: 5730.978260869565,
      y: 0.025356292724609375,
    },
    {
      x: 5735.054347826087,
      y: 0.015392303466796875,
    },
    {
      x: 5739.130434782609,
      y: 0.16489410400390625,
    },
    {
      x: 5743.206521739131,
      y: 0.159912109375,
    },
    {
      x: 5747.282608695652,
      y: 0.159912109375,
    },
    {
      x: 5751.358695652174,
      y: 0.15492630004882812,
    },
    {
      x: 5755.434782608696,
      y: 0.14994430541992188,
    },
    {
      x: 5759.510869565218,
      y: 0.14496231079101562,
    },
    {
      x: 5763.586956521739,
      y: 0.13997650146484375,
    },
    {
      x: 5767.663043478261,
      y: 0.1349945068359375,
    },
    {
      x: 5771.739130434783,
      y: 0.125030517578125,
    },
    {
      x: 5775.815217391305,
      y: 0.12004470825195312,
    },
    {
      x: 5779.891304347826,
      y: 0.11506271362304688,
    },
    {
      x: 5783.967391304348,
      y: 0.110076904296875,
    },
    {
      x: 5788.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 5792.119565217391,
      y: 0.10010910034179688,
    },
    {
      x: 5796.195652173913,
      y: 0.09512710571289062,
    },
    {
      x: 5800.271739130435,
      y: 0.09014511108398438,
    },
    {
      x: 5804.347826086957,
      y: 0.0851593017578125,
    },
    {
      x: 5808.423913043478,
      y: 0.09014511108398438,
    },
    {
      x: 5812.5,
      y: 0.09512710571289062,
    },
    {
      x: 5816.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 5820.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 5824.728260869565,
      y: 0.110076904296875,
    },
    {
      x: 5828.804347826087,
      y: 0.11506271362304688,
    },
    {
      x: 5832.880434782609,
      y: 0.12004470825195312,
    },
    {
      x: 5836.956521739131,
      y: 0.13001251220703125,
    },
    {
      x: 5841.032608695652,
      y: 0.1349945068359375,
    },
    {
      x: 5845.108695652174,
      y: 0.13997650146484375,
    },
    {
      x: 5849.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 5853.260869565218,
      y: 0.14994430541992188,
    },
    {
      x: 5857.336956521739,
      y: 0.15492630004882812,
    },
    {
      x: 5861.413043478261,
      y: 0.15492630004882812,
    },
    {
      x: 5865.489130434783,
      y: 0.159912109375,
    },
    {
      x: 5869.565217391305,
      y: 0.16489410400390625,
    },
    {
      x: 5873.641304347826,
      y: 0.1698760986328125,
    },
    {
      x: 5877.717391304348,
      y: 0.1698760986328125,
    },
    {
      x: 5894.021739130435,
      y: 0.1698760986328125,
    },
    {
      x: 5898.097826086957,
      y: 0.16489410400390625,
    },
    {
      x: 5902.173913043478,
      y: 0.16489410400390625,
    },
    {
      x: 5926.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 5930.706521739131,
      y: 0.159912109375,
    },
    {
      x: 5934.782608695652,
      y: 0.159912109375,
    },
    {
      x: 5959.239130434783,
      y: 0.159912109375,
    },
    {
      x: 5963.315217391305,
      y: 0.15492630004882812,
    },
    {
      x: 5967.391304347826,
      y: 0.15492630004882812,
    },
    {
      x: 5995.923913043478,
      y: 0.15492630004882812,
    },
    {
      x: 6000,
      y: 0.21473312377929688,
    },
    {
      x: 6004.076086956522,
      y: 0.16489410400390625,
    },
    {
      x: 6008.152173913044,
      y: 0.110076904296875,
    },
    {
      x: 6012.228260869565,
      y: 0.06024169921875,
    },
    {
      x: 6016.304347826087,
      y: 0.025356292724609375,
    },
    {
      x: 6020.380434782609,
      y: 0.010406494140625,
    },
    {
      x: 6024.456521739131,
      y: 0.00542449951171875,
    },
    {
      x: 6028.532608695652,
      y: 0.00542449951171875,
    },
    {
      x: 6040.760869565218,
      y: 0.00542449951171875,
    },
    {
      x: 6044.836956521739,
      y: 0.010406494140625,
    },
    {
      x: 6048.913043478261,
      y: 0.010406494140625,
    },
    {
      x: 6057.065217391305,
      y: 0.010406494140625,
    },
    {
      x: 6061.141304347826,
      y: 0.015392303466796875,
    },
    {
      x: 6065.217391304348,
      y: 0.015392303466796875,
    },
    {
      x: 6073.369565217391,
      y: 0.015392303466796875,
    },
    {
      x: 6077.445652173913,
      y: 0.020374298095703125,
    },
    {
      x: 6081.521739130435,
      y: 0.020374298095703125,
    },
    {
      x: 6089.673913043478,
      y: 0.020374298095703125,
    },
    {
      x: 6093.75,
      y: 0.025356292724609375,
    },
    {
      x: 6097.826086956522,
      y: 0.025356292724609375,
    },
    {
      x: 6105.978260869565,
      y: 0.025356292724609375,
    },
    {
      x: 6110.054347826087,
      y: 0.03034210205078125,
    },
    {
      x: 6114.130434782609,
      y: 0.03034210205078125,
    },
    {
      x: 6122.282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 6126.358695652174,
      y: 0.0353240966796875,
    },
    {
      x: 6130.434782608696,
      y: 0.0353240966796875,
    },
    {
      x: 6138.586956521739,
      y: 0.0353240966796875,
    },
    {
      x: 6142.663043478261,
      y: 0.040309906005859375,
    },
    {
      x: 6146.739130434783,
      y: 0.040309906005859375,
    },
    {
      x: 6154.891304347826,
      y: 0.040309906005859375,
    },
    {
      x: 6158.967391304348,
      y: 0.045291900634765625,
    },
    {
      x: 6163.04347826087,
      y: 0.045291900634765625,
    },
    {
      x: 6175.271739130435,
      y: 0.045291900634765625,
    },
    {
      x: 6179.347826086957,
      y: 0.050273895263671875,
    },
    {
      x: 6183.423913043478,
      y: 0.050273895263671875,
    },
    {
      x: 6207.880434782609,
      y: 0.050273895263671875,
    },
    {
      x: 6211.956521739131,
      y: 0.05525970458984375,
    },
    {
      x: 6216.032608695652,
      y: 0.05525970458984375,
    },
    {
      x: 6236.413043478261,
      y: 0.05525970458984375,
    },
    {
      x: 6240.489130434783,
      y: 0.06522369384765625,
    },
    {
      x: 6244.565217391305,
      y: 0.09512710571289062,
    },
    {
      x: 6248.641304347826,
      y: 0.1349945068359375,
    },
    {
      x: 6252.717391304348,
      y: 0.159912109375,
    },
    {
      x: 6256.79347826087,
      y: 0.16489410400390625,
    },
    {
      x: 6260.869565217392,
      y: 0.15492630004882812,
    },
    {
      x: 6264.945652173913,
      y: 0.15492630004882812,
    },
    {
      x: 6452.445652173913,
      y: 0.15492630004882812,
    },
    {
      x: 6456.521739130435,
      y: 0.16489410400390625,
    },
    {
      x: 6460.597826086957,
      y: 0.19479751586914062,
    },
    {
      x: 6464.673913043478,
      y: 0.22469711303710938,
    },
    {
      x: 6468.75,
      y: 0.2595863342285156,
    },
    {
      x: 6472.826086956522,
      y: 0.2944679260253906,
    },
    {
      x: 6476.902173913044,
      y: 0.3293495178222656,
    },
    {
      x: 6480.978260869565,
      y: 0.3642387390136719,
    },
    {
      x: 6485.054347826087,
      y: 0.3991203308105469,
    },
    {
      x: 6489.130434782609,
      y: 0.4340057373046875,
    },
    {
      x: 6493.206521739131,
      y: 0.46390533447265625,
    },
    {
      x: 6497.282608695652,
      y: 0.4788551330566406,
    },
    {
      x: 6501.358695652174,
      y: 0.4489555358886719,
    },
    {
      x: 6505.434782608696,
      y: 0.409088134765625,
    },
    {
      x: 6509.510869565218,
      y: 0.3642387390136719,
    },
    {
      x: 6513.586956521739,
      y: 0.31439971923828125,
    },
    {
      x: 6517.663043478261,
      y: 0.2645683288574219,
    },
    {
      x: 6521.739130434783,
      y: 0.05525970458984375,
    },
    {
      x: 6525.815217391305,
      y: 0.06522369384765625,
    },
    {
      x: 6529.891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 6533.967391304348,
      y: 0.1349945068359375,
    },
    {
      x: 6538.04347826087,
      y: 0.159912109375,
    },
    {
      x: 6542.119565217392,
      y: 0.16489410400390625,
    },
    {
      x: 6546.195652173913,
      y: 0.16489410400390625,
    },
    {
      x: 6550.271739130435,
      y: 0.159912109375,
    },
    {
      x: 6554.347826086957,
      y: 0.159912109375,
    },
    {
      x: 6558.423913043478,
      y: 0.15492630004882812,
    },
    {
      x: 6562.5,
      y: 0.14994430541992188,
    },
    {
      x: 6566.576086956522,
      y: 0.14496231079101562,
    },
    {
      x: 6570.652173913044,
      y: 0.13997650146484375,
    },
    {
      x: 6574.728260869565,
      y: 0.1349945068359375,
    },
    {
      x: 6578.804347826087,
      y: 0.125030517578125,
    },
    {
      x: 6582.880434782609,
      y: 0.12004470825195312,
    },
    {
      x: 6586.956521739131,
      y: 0.11506271362304688,
    },
    {
      x: 6591.032608695652,
      y: 0.110076904296875,
    },
    {
      x: 6595.108695652174,
      y: 0.10509490966796875,
    },
    {
      x: 6599.184782608696,
      y: 0.10509490966796875,
    },
    {
      x: 6603.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 6607.336956521739,
      y: 0.09014511108398438,
    },
    {
      x: 6611.413043478261,
      y: 0.0851593017578125,
    },
    {
      x: 6615.489130434783,
      y: 0.09014511108398438,
    },
    {
      x: 6619.565217391305,
      y: 0.09512710571289062,
    },
    {
      x: 6623.641304347826,
      y: 0.10010910034179688,
    },
    {
      x: 6627.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 6631.79347826087,
      y: 0.110076904296875,
    },
    {
      x: 6635.869565217392,
      y: 0.11506271362304688,
    },
    {
      x: 6639.945652173913,
      y: 0.12004470825195312,
    },
    {
      x: 6644.021739130435,
      y: 0.13001251220703125,
    },
    {
      x: 6648.097826086957,
      y: 0.1349945068359375,
    },
    {
      x: 6652.173913043478,
      y: 0.13997650146484375,
    },
    {
      x: 6656.25,
      y: 0.14496231079101562,
    },
    {
      x: 6660.326086956522,
      y: 0.14994430541992188,
    },
    {
      x: 6664.402173913044,
      y: 0.15492630004882812,
    },
    {
      x: 6668.478260869565,
      y: 0.15492630004882812,
    },
    {
      x: 6672.554347826087,
      y: 0.159912109375,
    },
    {
      x: 6676.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 6680.706521739131,
      y: 0.1698760986328125,
    },
    {
      x: 6684.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 6701.086956521739,
      y: 0.1698760986328125,
    },
    {
      x: 6705.163043478261,
      y: 0.16489410400390625,
    },
    {
      x: 6709.239130434783,
      y: 0.16489410400390625,
    },
    {
      x: 6733.695652173913,
      y: 0.16489410400390625,
    },
    {
      x: 6737.771739130435,
      y: 0.159912109375,
    },
    {
      x: 6741.847826086957,
      y: 0.159912109375,
    },
    {
      x: 6766.304347826087,
      y: 0.159912109375,
    },
    {
      x: 6770.380434782609,
      y: 0.15492630004882812,
    },
    {
      x: 6774.456521739131,
      y: 0.15492630004882812,
    },
    {
      x: 7039.402173913044,
      y: 0.15492630004882812,
    },
    {
      x: 7043.478260869565,
      y: 0.4788551330566406,
    },
    {
      x: 7047.554347826087,
      y: 0.4489555358886719,
    },
    {
      x: 7051.630434782609,
      y: 0.409088134765625,
    },
    {
      x: 7055.706521739131,
      y: 0.3642387390136719,
    },
    {
      x: 7059.782608695652,
      y: 0.31439971923828125,
    },
    {
      x: 7063.858695652174,
      y: 0.2645683288574219,
    },
    {
      x: 7067.934782608696,
      y: 0.21473312377929688,
    },
    {
      x: 7072.010869565218,
      y: 0.16489410400390625,
    },
    {
      x: 7076.086956521739,
      y: 0.110076904296875,
    },
    {
      x: 7080.163043478261,
      y: 0.06024169921875,
    },
    {
      x: 7084.239130434783,
      y: 0.025356292724609375,
    },
    {
      x: 7088.315217391305,
      y: 0.010406494140625,
    },
    {
      x: 7092.391304347826,
      y: 0.00542449951171875,
    },
    {
      x: 7096.467391304348,
      y: 0.00542449951171875,
    },
    {
      x: 7108.695652173913,
      y: 0.00542449951171875,
    },
    {
      x: 7112.771739130435,
      y: 0.010406494140625,
    },
    {
      x: 7116.847826086957,
      y: 0.010406494140625,
    },
    {
      x: 7125,
      y: 0.010406494140625,
    },
    {
      x: 7129.076086956522,
      y: 0.015392303466796875,
    },
    {
      x: 7133.152173913044,
      y: 0.015392303466796875,
    },
    {
      x: 7141.304347826087,
      y: 0.015392303466796875,
    },
    {
      x: 7145.380434782609,
      y: 0.020374298095703125,
    },
    {
      x: 7149.456521739131,
      y: 0.020374298095703125,
    },
    {
      x: 7157.608695652174,
      y: 0.020374298095703125,
    },
    {
      x: 7161.684782608696,
      y: 0.025356292724609375,
    },
    {
      x: 7165.760869565218,
      y: 0.025356292724609375,
    },
    {
      x: 7173.913043478261,
      y: 0.025356292724609375,
    },
    {
      x: 7177.989130434783,
      y: 0.03034210205078125,
    },
    {
      x: 7182.065217391305,
      y: 0.03034210205078125,
    },
    {
      x: 7190.217391304348,
      y: 0.03034210205078125,
    },
    {
      x: 7194.29347826087,
      y: 0.0353240966796875,
    },
    {
      x: 7198.369565217392,
      y: 0.0353240966796875,
    },
    {
      x: 7206.521739130435,
      y: 0.0353240966796875,
    },
    {
      x: 7210.597826086957,
      y: 0.040309906005859375,
    },
    {
      x: 7214.673913043478,
      y: 0.040309906005859375,
    },
    {
      x: 7222.826086956522,
      y: 0.040309906005859375,
    },
    {
      x: 7226.902173913044,
      y: 0.045291900634765625,
    },
    {
      x: 7230.978260869565,
      y: 0.045291900634765625,
    },
    {
      x: 7243.206521739131,
      y: 0.045291900634765625,
    },
    {
      x: 7247.282608695652,
      y: 0.050273895263671875,
    },
    {
      x: 7251.358695652174,
      y: 0.050273895263671875,
    },
    {
      x: 7275.815217391305,
      y: 0.050273895263671875,
    },
    {
      x: 7279.891304347826,
      y: 0.05525970458984375,
    },
    {
      x: 7283.967391304348,
      y: 0.05525970458984375,
    },
    {
      x: 7300.271739130435,
      y: 0.05525970458984375,
    },
    {
      x: 7304.347826086957,
      y: 0.15492630004882812,
    },
    {
      x: 7308.423913043478,
      y: 0.15492630004882812,
    },
    {
      x: 7520.380434782609,
      y: 0.15492630004882812,
    },
    {
      x: 7524.456521739131,
      y: 0.16489410400390625,
    },
    {
      x: 7528.532608695652,
      y: 0.19479751586914062,
    },
    {
      x: 7532.608695652174,
      y: 0.22469711303710938,
    },
    {
      x: 7536.684782608696,
      y: 0.2595863342285156,
    },
    {
      x: 7540.760869565218,
      y: 0.2944679260253906,
    },
    {
      x: 7544.836956521739,
      y: 0.3293495178222656,
    },
    {
      x: 7548.913043478261,
      y: 0.3642387390136719,
    },
    {
      x: 7552.989130434783,
      y: 0.3991203308105469,
    },
    {
      x: 7557.065217391305,
      y: 0.4340057373046875,
    },
    {
      x: 7561.141304347826,
      y: 0.46390533447265625,
    },
    {
      x: 7565.217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 7569.29347826087,
      y: 0.05525970458984375,
    },
    {
      x: 7589.673913043478,
      y: 0.05525970458984375,
    },
    {
      x: 7593.75,
      y: 0.06522369384765625,
    },
    {
      x: 7597.826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 7601.902173913044,
      y: 0.1349945068359375,
    },
    {
      x: 7605.978260869565,
      y: 0.159912109375,
    },
    {
      x: 7610.054347826087,
      y: 0.16489410400390625,
    },
    {
      x: 7614.130434782609,
      y: 0.16489410400390625,
    },
    {
      x: 7618.206521739131,
      y: 0.159912109375,
    },
    {
      x: 7622.282608695652,
      y: 0.159912109375,
    },
    {
      x: 7626.358695652174,
      y: 0.15492630004882812,
    },
    {
      x: 7630.434782608696,
      y: 0.14994430541992188,
    },
    {
      x: 7634.510869565218,
      y: 0.14496231079101562,
    },
    {
      x: 7638.586956521739,
      y: 0.13997650146484375,
    },
    {
      x: 7642.663043478261,
      y: 0.1349945068359375,
    },
    {
      x: 7646.739130434783,
      y: 0.125030517578125,
    },
    {
      x: 7650.815217391305,
      y: 0.12004470825195312,
    },
    {
      x: 7654.891304347826,
      y: 0.11506271362304688,
    },
    {
      x: 7658.967391304348,
      y: 0.110076904296875,
    },
    {
      x: 7663.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 7667.119565217392,
      y: 0.10010910034179688,
    },
    {
      x: 7671.195652173913,
      y: 0.09512710571289062,
    },
    {
      x: 7675.271739130435,
      y: 0.09014511108398438,
    },
    {
      x: 7679.347826086957,
      y: 0.0851593017578125,
    },
    {
      x: 7683.423913043479,
      y: 0.09014511108398438,
    },
    {
      x: 7687.5,
      y: 0.09512710571289062,
    },
    {
      x: 7691.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 7695.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 7699.728260869565,
      y: 0.110076904296875,
    },
    {
      x: 7703.804347826087,
      y: 0.11506271362304688,
    },
    {
      x: 7707.880434782609,
      y: 0.12004470825195312,
    },
    {
      x: 7711.956521739131,
      y: 0.13001251220703125,
    },
    {
      x: 7716.032608695652,
      y: 0.1349945068359375,
    },
    {
      x: 7720.108695652174,
      y: 0.13997650146484375,
    },
    {
      x: 7724.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 7728.260869565218,
      y: 0.14994430541992188,
    },
    {
      x: 7732.336956521739,
      y: 0.15492630004882812,
    },
    {
      x: 7736.413043478261,
      y: 0.15492630004882812,
    },
    {
      x: 7740.489130434783,
      y: 0.159912109375,
    },
    {
      x: 7744.565217391305,
      y: 0.16489410400390625,
    },
    {
      x: 7748.641304347826,
      y: 0.1698760986328125,
    },
    {
      x: 7752.717391304348,
      y: 0.1698760986328125,
    },
    {
      x: 7769.021739130435,
      y: 0.1698760986328125,
    },
    {
      x: 7773.097826086957,
      y: 0.16489410400390625,
    },
    {
      x: 7777.173913043479,
      y: 0.16489410400390625,
    },
    {
      x: 7801.630434782609,
      y: 0.16489410400390625,
    },
    {
      x: 7805.706521739131,
      y: 0.159912109375,
    },
    {
      x: 7809.782608695652,
      y: 0.159912109375,
    },
    {
      x: 7834.239130434783,
      y: 0.159912109375,
    },
    {
      x: 7838.315217391305,
      y: 0.15492630004882812,
    },
    {
      x: 7842.391304347826,
      y: 0.15492630004882812,
    },
    {
      x: 8082.880434782609,
      y: 0.15492630004882812,
    },
    {
      x: 8086.956521739131,
      y: 0.2944679260253906,
    },
    {
      x: 8091.032608695652,
      y: 0.3293495178222656,
    },
    {
      x: 8095.108695652174,
      y: 0.3642387390136719,
    },
    {
      x: 8099.184782608696,
      y: 0.3991203308105469,
    },
    {
      x: 8103.260869565218,
      y: 0.4340057373046875,
    },
    {
      x: 8107.336956521739,
      y: 0.46390533447265625,
    },
    {
      x: 8111.413043478261,
      y: 0.4788551330566406,
    },
    {
      x: 8115.489130434783,
      y: 0.4489555358886719,
    },
    {
      x: 8119.565217391305,
      y: 0.409088134765625,
    },
    {
      x: 8123.641304347826,
      y: 0.3642387390136719,
    },
    {
      x: 8127.717391304348,
      y: 0.31439971923828125,
    },
    {
      x: 8131.79347826087,
      y: 0.2645683288574219,
    },
    {
      x: 8135.869565217392,
      y: 0.21473312377929688,
    },
    {
      x: 8139.945652173913,
      y: 0.16489410400390625,
    },
    {
      x: 8144.021739130435,
      y: 0.110076904296875,
    },
    {
      x: 8148.097826086957,
      y: 0.06024169921875,
    },
    {
      x: 8152.173913043479,
      y: 0.025356292724609375,
    },
    {
      x: 8156.25,
      y: 0.010406494140625,
    },
    {
      x: 8160.326086956522,
      y: 0.00542449951171875,
    },
    {
      x: 8164.402173913044,
      y: 0.00542449951171875,
    },
    {
      x: 8176.630434782609,
      y: 0.00542449951171875,
    },
    {
      x: 8180.706521739131,
      y: 0.010406494140625,
    },
    {
      x: 8184.782608695652,
      y: 0.010406494140625,
    },
    {
      x: 8192.934782608696,
      y: 0.010406494140625,
    },
    {
      x: 8197.010869565218,
      y: 0.015392303466796875,
    },
    {
      x: 8201.08695652174,
      y: 0.015392303466796875,
    },
    {
      x: 8209.239130434782,
      y: 0.015392303466796875,
    },
    {
      x: 8213.315217391304,
      y: 0.020374298095703125,
    },
    {
      x: 8217.391304347826,
      y: 0.020374298095703125,
    },
    {
      x: 8225.54347826087,
      y: 0.020374298095703125,
    },
    {
      x: 8229.619565217392,
      y: 0.025356292724609375,
    },
    {
      x: 8233.695652173914,
      y: 0.025356292724609375,
    },
    {
      x: 8241.847826086956,
      y: 0.025356292724609375,
    },
    {
      x: 8245.923913043478,
      y: 0.03034210205078125,
    },
    {
      x: 8250,
      y: 0.03034210205078125,
    },
    {
      x: 8258.152173913044,
      y: 0.03034210205078125,
    },
    {
      x: 8262.228260869566,
      y: 0.0353240966796875,
    },
    {
      x: 8266.304347826088,
      y: 0.0353240966796875,
    },
    {
      x: 8274.45652173913,
      y: 0.0353240966796875,
    },
    {
      x: 8278.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 8282.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 8290.760869565218,
      y: 0.040309906005859375,
    },
    {
      x: 8294.83695652174,
      y: 0.045291900634765625,
    },
    {
      x: 8298.913043478262,
      y: 0.045291900634765625,
    },
    {
      x: 8311.141304347826,
      y: 0.045291900634765625,
    },
    {
      x: 8315.217391304348,
      y: 0.050273895263671875,
    },
    {
      x: 8319.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 8368.20652173913,
      y: 0.050273895263671875,
    },
    {
      x: 8372.282608695652,
      y: 0.05525970458984375,
    },
    {
      x: 8376.358695652174,
      y: 0.05525970458984375,
    },
    {
      x: 8396.739130434782,
      y: 0.05525970458984375,
    },
    {
      x: 8400.815217391304,
      y: 0.06522369384765625,
    },
    {
      x: 8404.891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 8408.967391304348,
      y: 0.1349945068359375,
    },
    {
      x: 8413.04347826087,
      y: 0.159912109375,
    },
    {
      x: 8417.119565217392,
      y: 0.16489410400390625,
    },
    {
      x: 8421.195652173914,
      y: 0.16489410400390625,
    },
    {
      x: 8425.271739130436,
      y: 0.159912109375,
    },
    {
      x: 8429.347826086956,
      y: 0.159912109375,
    },
    {
      x: 8433.423913043478,
      y: 0.15492630004882812,
    },
    {
      x: 8437.5,
      y: 0.14994430541992188,
    },
    {
      x: 8441.576086956522,
      y: 0.14496231079101562,
    },
    {
      x: 8445.652173913044,
      y: 0.13997650146484375,
    },
    {
      x: 8449.728260869566,
      y: 0.1349945068359375,
    },
    {
      x: 8453.804347826088,
      y: 0.125030517578125,
    },
    {
      x: 8457.880434782608,
      y: 0.12004470825195312,
    },
    {
      x: 8461.95652173913,
      y: 0.11506271362304688,
    },
    {
      x: 8466.032608695652,
      y: 0.110076904296875,
    },
    {
      x: 8470.108695652174,
      y: 0.10509490966796875,
    },
    {
      x: 8474.184782608696,
      y: 0.10010910034179688,
    },
    {
      x: 8478.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 8482.33695652174,
      y: 0.09014511108398438,
    },
    {
      x: 8486.413043478262,
      y: 0.0851593017578125,
    },
    {
      x: 8490.489130434782,
      y: 0.09014511108398438,
    },
    {
      x: 8494.565217391304,
      y: 0.09512710571289062,
    },
    {
      x: 8498.641304347826,
      y: 0.10010910034179688,
    },
    {
      x: 8502.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 8506.79347826087,
      y: 0.110076904296875,
    },
    {
      x: 8510.869565217392,
      y: 0.11506271362304688,
    },
    {
      x: 8514.945652173914,
      y: 0.12004470825195312,
    },
    {
      x: 8519.021739130436,
      y: 0.13001251220703125,
    },
    {
      x: 8523.097826086956,
      y: 0.1349945068359375,
    },
    {
      x: 8527.173913043478,
      y: 0.13997650146484375,
    },
    {
      x: 8531.25,
      y: 0.14496231079101562,
    },
    {
      x: 8535.326086956522,
      y: 0.14994430541992188,
    },
    {
      x: 8539.402173913044,
      y: 0.15492630004882812,
    },
    {
      x: 8543.478260869566,
      y: 0.15492630004882812,
    },
    {
      x: 8547.554347826088,
      y: 0.159912109375,
    },
    {
      x: 8551.630434782608,
      y: 0.16489410400390625,
    },
    {
      x: 8555.70652173913,
      y: 0.1698760986328125,
    },
    {
      x: 8559.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 8576.08695652174,
      y: 0.1698760986328125,
    },
    {
      x: 8580.163043478262,
      y: 0.16489410400390625,
    },
    {
      x: 8584.239130434782,
      y: 0.16489410400390625,
    },
    {
      x: 8604.619565217392,
      y: 0.16489410400390625,
    },
    {
      x: 8608.695652173914,
      y: 0.15492630004882812,
    },
    {
      x: 8612.771739130436,
      y: 0.15492630004882812,
    },
    {
      x: 8849.184782608696,
      y: 0.15492630004882812,
    },
    {
      x: 8853.260869565218,
      y: 0.16489410400390625,
    },
    {
      x: 8857.33695652174,
      y: 0.19479751586914062,
    },
    {
      x: 8861.413043478262,
      y: 0.22469711303710938,
    },
    {
      x: 8865.489130434782,
      y: 0.2595863342285156,
    },
    {
      x: 8869.565217391304,
      y: 0.16489410400390625,
    },
    {
      x: 8873.641304347826,
      y: 0.159912109375,
    },
    {
      x: 8877.717391304348,
      y: 0.159912109375,
    },
    {
      x: 8902.173913043478,
      y: 0.159912109375,
    },
    {
      x: 8906.25,
      y: 0.15492630004882812,
    },
    {
      x: 8910.326086956522,
      y: 0.15492630004882812,
    },
    {
      x: 9134.510869565218,
      y: 0.15492630004882812,
    },
    {
      x: 9138.58695652174,
      y: 0.16489410400390625,
    },
    {
      x: 9142.663043478262,
      y: 0.19479751586914062,
    },
    {
      x: 9146.739130434782,
      y: 0.22469711303710938,
    },
    {
      x: 9150.815217391304,
      y: 0.2595863342285156,
    },
    {
      x: 9154.891304347826,
      y: 0.2944679260253906,
    },
    {
      x: 9158.967391304348,
      y: 0.3293495178222656,
    },
    {
      x: 9163.04347826087,
      y: 0.3642387390136719,
    },
    {
      x: 9167.119565217392,
      y: 0.3991203308105469,
    },
    {
      x: 9171.195652173914,
      y: 0.4340057373046875,
    },
    {
      x: 9175.271739130436,
      y: 0.46390533447265625,
    },
    {
      x: 9179.347826086956,
      y: 0.4788551330566406,
    },
    {
      x: 9183.423913043478,
      y: 0.4489555358886719,
    },
    {
      x: 9187.5,
      y: 0.409088134765625,
    },
    {
      x: 9191.576086956522,
      y: 0.3642387390136719,
    },
    {
      x: 9195.652173913044,
      y: 0.31439971923828125,
    },
    {
      x: 9199.728260869566,
      y: 0.2645683288574219,
    },
    {
      x: 9203.804347826088,
      y: 0.21473312377929688,
    },
    {
      x: 9207.880434782608,
      y: 0.16489410400390625,
    },
    {
      x: 9211.95652173913,
      y: 0.110076904296875,
    },
    {
      x: 9216.032608695652,
      y: 0.06024169921875,
    },
    {
      x: 9220.108695652174,
      y: 0.025356292724609375,
    },
    {
      x: 9224.184782608696,
      y: 0.010406494140625,
    },
    {
      x: 9228.260869565218,
      y: 0.00542449951171875,
    },
    {
      x: 9232.33695652174,
      y: 0.00542449951171875,
    },
    {
      x: 9244.565217391304,
      y: 0.00542449951171875,
    },
    {
      x: 9248.641304347826,
      y: 0.010406494140625,
    },
    {
      x: 9252.717391304348,
      y: 0.010406494140625,
    },
    {
      x: 9260.869565217392,
      y: 0.010406494140625,
    },
    {
      x: 9264.945652173914,
      y: 0.015392303466796875,
    },
    {
      x: 9269.021739130436,
      y: 0.015392303466796875,
    },
    {
      x: 9277.173913043478,
      y: 0.015392303466796875,
    },
    {
      x: 9281.25,
      y: 0.020374298095703125,
    },
    {
      x: 9285.326086956522,
      y: 0.020374298095703125,
    },
    {
      x: 9293.478260869566,
      y: 0.020374298095703125,
    },
    {
      x: 9297.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 9301.630434782608,
      y: 0.025356292724609375,
    },
    {
      x: 9309.782608695652,
      y: 0.025356292724609375,
    },
    {
      x: 9313.858695652174,
      y: 0.03034210205078125,
    },
    {
      x: 9317.934782608696,
      y: 0.03034210205078125,
    },
    {
      x: 9326.08695652174,
      y: 0.03034210205078125,
    },
    {
      x: 9330.163043478262,
      y: 0.0353240966796875,
    },
    {
      x: 9334.239130434782,
      y: 0.0353240966796875,
    },
    {
      x: 9342.391304347826,
      y: 0.0353240966796875,
    },
    {
      x: 9346.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 9350.54347826087,
      y: 0.040309906005859375,
    },
    {
      x: 9358.695652173914,
      y: 0.040309906005859375,
    },
    {
      x: 9362.771739130436,
      y: 0.045291900634765625,
    },
    {
      x: 9366.847826086956,
      y: 0.045291900634765625,
    },
    {
      x: 9379.076086956522,
      y: 0.045291900634765625,
    },
    {
      x: 9383.152173913044,
      y: 0.050273895263671875,
    },
    {
      x: 9387.228260869566,
      y: 0.050273895263671875,
    },
    {
      x: 9391.304347826088,
      y: 0.15492630004882812,
    },
    {
      x: 9395.380434782608,
      y: 0.15492630004882812,
    },
    {
      x: 9648.097826086956,
      y: 0.15492630004882812,
    },
    {
      x: 9652.173913043478,
      y: 0.16489410400390625,
    },
    {
      x: 9656.25,
      y: 0.16489410400390625,
    },
    {
      x: 9676.630434782608,
      y: 0.16489410400390625,
    },
    {
      x: 9680.70652173913,
      y: 0.159912109375,
    },
    {
      x: 9684.782608695652,
      y: 0.159912109375,
    },
    {
      x: 9709.239130434782,
      y: 0.159912109375,
    },
    {
      x: 9713.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 9717.391304347826,
      y: 0.15492630004882812,
    },
    {
      x: 9941.576086956522,
      y: 0.15492630004882812,
    },
    {
      x: 9945.652173913044,
      y: 0.16489410400390625,
    },
    {
      x: 9949.728260869566,
      y: 0.19479751586914062,
    },
    {
      x: 9953.804347826088,
      y: 0.22469711303710938,
    },
    {
      x: 9957.88043478261,
      y: 0.2595863342285156,
    },
    {
      x: 9961.95652173913,
      y: 0.2944679260253906,
    },
    {
      x: 9966.032608695652,
      y: 0.3293495178222656,
    },
    {
      x: 9970.108695652174,
      y: 0.3642387390136719,
    },
    {
      x: 9974.184782608696,
      y: 0.3991203308105469,
    },
    {
      x: 9978.260869565218,
      y: 0.4340057373046875,
    },
    {
      x: 9982.33695652174,
      y: 0.46390533447265625,
    },
    {
      x: 9986.413043478262,
      y: 0.4788551330566406,
    },
    {
      x: 9990.489130434782,
      y: 0.4489555358886719,
    },
    {
      x: 9994.565217391304,
      y: 0.409088134765625,
    },
    {
      x: 9998.641304347826,
      y: 0.3642387390136719,
    },
    {
      x: 10002.717391304348,
      y: 0.31439971923828125,
    },
    {
      x: 10006.79347826087,
      y: 0.2645683288574219,
    },
    {
      x: 10010.869565217392,
      y: 0.21473312377929688,
    },
    {
      x: 10014.945652173914,
      y: 0.16489410400390625,
    },
    {
      x: 10019.021739130436,
      y: 0.110076904296875,
    },
    {
      x: 10023.097826086956,
      y: 0.06024169921875,
    },
    {
      x: 10027.173913043478,
      y: 0.025356292724609375,
    },
    {
      x: 10031.25,
      y: 0.010406494140625,
    },
    {
      x: 10035.326086956522,
      y: 0.00542449951171875,
    },
    {
      x: 10039.402173913044,
      y: 0.00542449951171875,
    },
    {
      x: 10051.63043478261,
      y: 0.00542449951171875,
    },
    {
      x: 10055.70652173913,
      y: 0.010406494140625,
    },
    {
      x: 10059.782608695652,
      y: 0.010406494140625,
    },
    {
      x: 10067.934782608696,
      y: 0.010406494140625,
    },
    {
      x: 10072.010869565218,
      y: 0.015392303466796875,
    },
    {
      x: 10076.08695652174,
      y: 0.015392303466796875,
    },
    {
      x: 10084.239130434782,
      y: 0.015392303466796875,
    },
    {
      x: 10088.315217391304,
      y: 0.020374298095703125,
    },
    {
      x: 10092.391304347826,
      y: 0.020374298095703125,
    },
    {
      x: 10100.54347826087,
      y: 0.020374298095703125,
    },
    {
      x: 10104.619565217392,
      y: 0.025356292724609375,
    },
    {
      x: 10108.695652173914,
      y: 0.025356292724609375,
    },
    {
      x: 10116.847826086956,
      y: 0.025356292724609375,
    },
    {
      x: 10120.923913043478,
      y: 0.03034210205078125,
    },
    {
      x: 10125,
      y: 0.03034210205078125,
    },
    {
      x: 10133.152173913044,
      y: 0.03034210205078125,
    },
    {
      x: 10137.228260869566,
      y: 0.0353240966796875,
    },
    {
      x: 10141.304347826088,
      y: 0.0353240966796875,
    },
    {
      x: 10149.45652173913,
      y: 0.0353240966796875,
    },
    {
      x: 10153.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 10157.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 10165.760869565218,
      y: 0.040309906005859375,
    },
    {
      x: 10169.83695652174,
      y: 0.045291900634765625,
    },
    {
      x: 10173.913043478262,
      y: 0.045291900634765625,
    },
    {
      x: 10186.141304347826,
      y: 0.045291900634765625,
    },
    {
      x: 10190.217391304348,
      y: 0.050273895263671875,
    },
    {
      x: 10194.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 10218.75,
      y: 0.050273895263671875,
    },
    {
      x: 10222.826086956522,
      y: 0.05525970458984375,
    },
    {
      x: 10226.902173913044,
      y: 0.05525970458984375,
    },
    {
      x: 10247.282608695652,
      y: 0.05525970458984375,
    },
    {
      x: 10251.358695652174,
      y: 0.06522369384765625,
    },
    {
      x: 10255.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 10259.510869565218,
      y: 0.1349945068359375,
    },
    {
      x: 10263.58695652174,
      y: 0.159912109375,
    },
    {
      x: 10267.663043478262,
      y: 0.16489410400390625,
    },
    {
      x: 10271.739130434782,
      y: 0.16489410400390625,
    },
    {
      x: 10275.815217391304,
      y: 0.159912109375,
    },
    {
      x: 10279.891304347826,
      y: 0.159912109375,
    },
    {
      x: 10283.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 10288.04347826087,
      y: 0.14994430541992188,
    },
    {
      x: 10292.119565217392,
      y: 0.14496231079101562,
    },
    {
      x: 10296.195652173914,
      y: 0.13997650146484375,
    },
    {
      x: 10300.271739130436,
      y: 0.1349945068359375,
    },
    {
      x: 10304.347826086956,
      y: 0.125030517578125,
    },
    {
      x: 10308.423913043478,
      y: 0.12004470825195312,
    },
    {
      x: 10312.5,
      y: 0.11506271362304688,
    },
    {
      x: 10316.576086956522,
      y: 0.110076904296875,
    },
    {
      x: 10320.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 10324.728260869566,
      y: 0.10010910034179688,
    },
    {
      x: 10328.804347826088,
      y: 0.09512710571289062,
    },
    {
      x: 10332.88043478261,
      y: 0.09014511108398438,
    },
    {
      x: 10336.95652173913,
      y: 0.0851593017578125,
    },
    {
      x: 10341.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 10345.108695652174,
      y: 0.09512710571289062,
    },
    {
      x: 10349.184782608696,
      y: 0.10010910034179688,
    },
    {
      x: 10353.260869565218,
      y: 0.10509490966796875,
    },
    {
      x: 10357.33695652174,
      y: 0.110076904296875,
    },
    {
      x: 10361.413043478262,
      y: 0.11506271362304688,
    },
    {
      x: 10365.489130434782,
      y: 0.12004470825195312,
    },
    {
      x: 10369.565217391304,
      y: 0.13001251220703125,
    },
    {
      x: 10373.641304347826,
      y: 0.1349945068359375,
    },
    {
      x: 10377.717391304348,
      y: 0.13997650146484375,
    },
    {
      x: 10381.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 10385.869565217392,
      y: 0.14994430541992188,
    },
    {
      x: 10389.945652173914,
      y: 0.15492630004882812,
    },
    {
      x: 10394.021739130436,
      y: 0.15492630004882812,
    },
    {
      x: 10398.097826086956,
      y: 0.159912109375,
    },
    {
      x: 10402.173913043478,
      y: 0.16489410400390625,
    },
    {
      x: 10406.25,
      y: 0.1698760986328125,
    },
    {
      x: 10410.326086956522,
      y: 0.1698760986328125,
    },
    {
      x: 10426.63043478261,
      y: 0.1698760986328125,
    },
    {
      x: 10430.70652173913,
      y: 0.16489410400390625,
    },
    {
      x: 10434.782608695652,
      y: 0.15492630004882812,
    },
    {
      x: 10438.858695652174,
      y: 0.15492630004882812,
    },
    {
      x: 10691.576086956522,
      y: 0.15492630004882812,
    },
    {
      x: 10695.652173913044,
      y: 0.1698760986328125,
    },
    {
      x: 10699.728260869566,
      y: 0.1698760986328125,
    },
    {
      x: 10711.95652173913,
      y: 0.1698760986328125,
    },
    {
      x: 10716.032608695652,
      y: 0.16489410400390625,
    },
    {
      x: 10720.108695652174,
      y: 0.16489410400390625,
    },
    {
      x: 10744.565217391304,
      y: 0.16489410400390625,
    },
    {
      x: 10748.641304347826,
      y: 0.159912109375,
    },
    {
      x: 10752.717391304348,
      y: 0.159912109375,
    },
    {
      x: 10777.173913043478,
      y: 0.159912109375,
    },
    {
      x: 10781.25,
      y: 0.15492630004882812,
    },
    {
      x: 10785.326086956522,
      y: 0.15492630004882812,
    },
    {
      x: 10952.445652173914,
      y: 0.15492630004882812,
    },
    {
      x: 10956.521739130436,
      y: 0.0353240966796875,
    },
    {
      x: 10960.597826086956,
      y: 0.040309906005859375,
    },
    {
      x: 10964.673913043478,
      y: 0.040309906005859375,
    },
    {
      x: 10972.826086956522,
      y: 0.040309906005859375,
    },
    {
      x: 10976.902173913044,
      y: 0.045291900634765625,
    },
    {
      x: 10980.978260869566,
      y: 0.045291900634765625,
    },
    {
      x: 10993.20652173913,
      y: 0.045291900634765625,
    },
    {
      x: 10997.282608695652,
      y: 0.050273895263671875,
    },
    {
      x: 11001.358695652174,
      y: 0.050273895263671875,
    },
    {
      x: 11025.815217391304,
      y: 0.050273895263671875,
    },
    {
      x: 11029.891304347826,
      y: 0.05525970458984375,
    },
    {
      x: 11033.967391304348,
      y: 0.05525970458984375,
    },
    {
      x: 11054.347826086956,
      y: 0.05525970458984375,
    },
    {
      x: 11058.423913043478,
      y: 0.06522369384765625,
    },
    {
      x: 11062.5,
      y: 0.09512710571289062,
    },
    {
      x: 11066.576086956522,
      y: 0.1349945068359375,
    },
    {
      x: 11070.652173913044,
      y: 0.159912109375,
    },
    {
      x: 11074.728260869566,
      y: 0.16489410400390625,
    },
    {
      x: 11078.804347826088,
      y: 0.16489410400390625,
    },
    {
      x: 11082.88043478261,
      y: 0.159912109375,
    },
    {
      x: 11086.95652173913,
      y: 0.159912109375,
    },
    {
      x: 11091.032608695652,
      y: 0.15492630004882812,
    },
    {
      x: 11095.108695652174,
      y: 0.14994430541992188,
    },
    {
      x: 11099.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 11103.260869565218,
      y: 0.13997650146484375,
    },
    {
      x: 11107.33695652174,
      y: 0.1349945068359375,
    },
    {
      x: 11111.413043478262,
      y: 0.125030517578125,
    },
    {
      x: 11115.489130434782,
      y: 0.12004470825195312,
    },
    {
      x: 11119.565217391304,
      y: 0.11506271362304688,
    },
    {
      x: 11123.641304347826,
      y: 0.110076904296875,
    },
    {
      x: 11127.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 11131.79347826087,
      y: 0.10509490966796875,
    },
    {
      x: 11135.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 11139.945652173914,
      y: 0.09014511108398438,
    },
    {
      x: 11144.021739130436,
      y: 0.0851593017578125,
    },
    {
      x: 11148.097826086956,
      y: 0.09014511108398438,
    },
    {
      x: 11152.173913043478,
      y: 0.09512710571289062,
    },
    {
      x: 11156.25,
      y: 0.10010910034179688,
    },
    {
      x: 11160.326086956522,
      y: 0.10509490966796875,
    },
    {
      x: 11164.402173913044,
      y: 0.110076904296875,
    },
    {
      x: 11168.478260869566,
      y: 0.11506271362304688,
    },
    {
      x: 11172.554347826088,
      y: 0.12004470825195312,
    },
    {
      x: 11176.63043478261,
      y: 0.13001251220703125,
    },
    {
      x: 11180.70652173913,
      y: 0.1349945068359375,
    },
    {
      x: 11184.782608695652,
      y: 0.13997650146484375,
    },
    {
      x: 11188.858695652174,
      y: 0.14496231079101562,
    },
    {
      x: 11192.934782608696,
      y: 0.14994430541992188,
    },
    {
      x: 11197.010869565218,
      y: 0.15492630004882812,
    },
    {
      x: 11201.08695652174,
      y: 0.15492630004882812,
    },
    {
      x: 11205.163043478262,
      y: 0.159912109375,
    },
    {
      x: 11209.239130434782,
      y: 0.16489410400390625,
    },
    {
      x: 11213.315217391304,
      y: 0.1698760986328125,
    },
    {
      x: 11217.391304347826,
      y: 0.15492630004882812,
    },
    {
      x: 11221.467391304348,
      y: 0.15492630004882812,
    },
    {
      x: 11270.38043478261,
      y: 0.15492630004882812,
    },
    {
      x: 11274.45652173913,
      y: 0.16489410400390625,
    },
    {
      x: 11278.532608695652,
      y: 0.19479751586914062,
    },
    {
      x: 11282.608695652174,
      y: 0.22469711303710938,
    },
    {
      x: 11286.684782608696,
      y: 0.2595863342285156,
    },
    {
      x: 11290.760869565218,
      y: 0.2944679260253906,
    },
    {
      x: 11294.83695652174,
      y: 0.3293495178222656,
    },
    {
      x: 11298.913043478262,
      y: 0.3642387390136719,
    },
    {
      x: 11302.989130434782,
      y: 0.3991203308105469,
    },
    {
      x: 11307.065217391304,
      y: 0.4340057373046875,
    },
    {
      x: 11311.141304347826,
      y: 0.46390533447265625,
    },
    {
      x: 11315.217391304348,
      y: 0.4788551330566406,
    },
    {
      x: 11319.29347826087,
      y: 0.4489555358886719,
    },
    {
      x: 11323.369565217392,
      y: 0.409088134765625,
    },
    {
      x: 11327.445652173914,
      y: 0.3642387390136719,
    },
    {
      x: 11331.521739130436,
      y: 0.31439971923828125,
    },
    {
      x: 11335.597826086956,
      y: 0.2645683288574219,
    },
    {
      x: 11339.673913043478,
      y: 0.21473312377929688,
    },
    {
      x: 11343.75,
      y: 0.16489410400390625,
    },
    {
      x: 11347.826086956522,
      y: 0.110076904296875,
    },
    {
      x: 11351.902173913044,
      y: 0.06024169921875,
    },
    {
      x: 11355.978260869566,
      y: 0.025356292724609375,
    },
    {
      x: 11360.054347826088,
      y: 0.010406494140625,
    },
    {
      x: 11364.13043478261,
      y: 0.00542449951171875,
    },
    {
      x: 11368.20652173913,
      y: 0.00542449951171875,
    },
    {
      x: 11380.434782608696,
      y: 0.00542449951171875,
    },
    {
      x: 11384.510869565218,
      y: 0.010406494140625,
    },
    {
      x: 11388.58695652174,
      y: 0.010406494140625,
    },
    {
      x: 11396.739130434782,
      y: 0.010406494140625,
    },
    {
      x: 11400.815217391304,
      y: 0.015392303466796875,
    },
    {
      x: 11404.891304347826,
      y: 0.015392303466796875,
    },
    {
      x: 11413.04347826087,
      y: 0.015392303466796875,
    },
    {
      x: 11417.119565217392,
      y: 0.020374298095703125,
    },
    {
      x: 11421.195652173914,
      y: 0.020374298095703125,
    },
    {
      x: 11429.347826086956,
      y: 0.020374298095703125,
    },
    {
      x: 11433.423913043478,
      y: 0.025356292724609375,
    },
    {
      x: 11437.5,
      y: 0.025356292724609375,
    },
    {
      x: 11445.652173913044,
      y: 0.025356292724609375,
    },
    {
      x: 11449.728260869566,
      y: 0.03034210205078125,
    },
    {
      x: 11453.804347826088,
      y: 0.03034210205078125,
    },
    {
      x: 11461.95652173913,
      y: 0.03034210205078125,
    },
    {
      x: 11466.032608695652,
      y: 0.0353240966796875,
    },
    {
      x: 11470.108695652174,
      y: 0.0353240966796875,
    },
    {
      x: 11474.184782608696,
      y: 0.0353240966796875,
    },
    {
      x: 11478.260869565218,
      y: 0.14994430541992188,
    },
    {
      x: 11482.33695652174,
      y: 0.15492630004882812,
    },
    {
      x: 11486.413043478262,
      y: 0.15492630004882812,
    },
    {
      x: 11490.489130434782,
      y: 0.159912109375,
    },
    {
      x: 11494.565217391304,
      y: 0.16489410400390625,
    },
    {
      x: 11498.641304347826,
      y: 0.1698760986328125,
    },
    {
      x: 11502.717391304348,
      y: 0.1698760986328125,
    },
    {
      x: 11519.021739130436,
      y: 0.1698760986328125,
    },
    {
      x: 11523.097826086956,
      y: 0.16489410400390625,
    },
    {
      x: 11527.173913043478,
      y: 0.16489410400390625,
    },
    {
      x: 11551.63043478261,
      y: 0.16489410400390625,
    },
    {
      x: 11555.70652173913,
      y: 0.159912109375,
    },
    {
      x: 11559.782608695652,
      y: 0.159912109375,
    },
    {
      x: 11584.239130434782,
      y: 0.159912109375,
    },
    {
      x: 11588.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 11592.391304347826,
      y: 0.15492630004882812,
    },
    {
      x: 11995.923913043478,
      y: 0.15492630004882812,
    },
    {
      x: 12000,
      y: 0.03034210205078125,
    },
    {
      x: 12004.076086956522,
      y: 0.03034210205078125,
    },
    {
      x: 12008.152173913044,
      y: 0.03034210205078125,
    },
    {
      x: 12012.228260869566,
      y: 0.0353240966796875,
    },
    {
      x: 12016.304347826088,
      y: 0.0353240966796875,
    },
    {
      x: 12024.45652173913,
      y: 0.0353240966796875,
    },
    {
      x: 12028.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 12032.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 12040.760869565218,
      y: 0.040309906005859375,
    },
    {
      x: 12044.83695652174,
      y: 0.045291900634765625,
    },
    {
      x: 12048.913043478262,
      y: 0.045291900634765625,
    },
    {
      x: 12061.141304347826,
      y: 0.045291900634765625,
    },
    {
      x: 12065.217391304348,
      y: 0.050273895263671875,
    },
    {
      x: 12069.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 12093.75,
      y: 0.050273895263671875,
    },
    {
      x: 12097.826086956522,
      y: 0.05525970458984375,
    },
    {
      x: 12101.902173913044,
      y: 0.05525970458984375,
    },
    {
      x: 12122.282608695652,
      y: 0.05525970458984375,
    },
    {
      x: 12126.358695652174,
      y: 0.06522369384765625,
    },
    {
      x: 12130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 12134.510869565218,
      y: 0.1349945068359375,
    },
    {
      x: 12138.58695652174,
      y: 0.159912109375,
    },
    {
      x: 12142.663043478262,
      y: 0.16489410400390625,
    },
    {
      x: 12146.739130434782,
      y: 0.16489410400390625,
    },
    {
      x: 12150.815217391304,
      y: 0.159912109375,
    },
    {
      x: 12154.891304347826,
      y: 0.159912109375,
    },
    {
      x: 12158.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 12163.04347826087,
      y: 0.14994430541992188,
    },
    {
      x: 12167.119565217392,
      y: 0.14496231079101562,
    },
    {
      x: 12171.195652173914,
      y: 0.13997650146484375,
    },
    {
      x: 12175.271739130436,
      y: 0.1349945068359375,
    },
    {
      x: 12179.347826086956,
      y: 0.125030517578125,
    },
    {
      x: 12183.423913043478,
      y: 0.12004470825195312,
    },
    {
      x: 12187.5,
      y: 0.11506271362304688,
    },
    {
      x: 12191.576086956522,
      y: 0.110076904296875,
    },
    {
      x: 12195.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 12199.728260869566,
      y: 0.10010910034179688,
    },
    {
      x: 12203.804347826088,
      y: 0.09512710571289062,
    },
    {
      x: 12207.88043478261,
      y: 0.09014511108398438,
    },
    {
      x: 12211.95652173913,
      y: 0.0851593017578125,
    },
    {
      x: 12216.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 12220.108695652174,
      y: 0.09512710571289062,
    },
    {
      x: 12224.184782608696,
      y: 0.10010910034179688,
    },
    {
      x: 12228.260869565218,
      y: 0.10509490966796875,
    },
    {
      x: 12232.33695652174,
      y: 0.110076904296875,
    },
    {
      x: 12236.413043478262,
      y: 0.11506271362304688,
    },
    {
      x: 12240.489130434782,
      y: 0.12004470825195312,
    },
    {
      x: 12244.565217391304,
      y: 0.13001251220703125,
    },
    {
      x: 12248.641304347826,
      y: 0.1349945068359375,
    },
    {
      x: 12252.717391304348,
      y: 0.13997650146484375,
    },
    {
      x: 12256.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 12260.869565217392,
      y: 0.15492630004882812,
    },
    {
      x: 12264.945652173914,
      y: 0.15492630004882812,
    },
    {
      x: 12338.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 12342.391304347826,
      y: 0.16489410400390625,
    },
    {
      x: 12346.467391304348,
      y: 0.19479751586914062,
    },
    {
      x: 12350.54347826087,
      y: 0.22469711303710938,
    },
    {
      x: 12354.619565217392,
      y: 0.2595863342285156,
    },
    {
      x: 12358.695652173914,
      y: 0.2944679260253906,
    },
    {
      x: 12362.771739130436,
      y: 0.3293495178222656,
    },
    {
      x: 12366.847826086956,
      y: 0.3642387390136719,
    },
    {
      x: 12370.923913043478,
      y: 0.3991203308105469,
    },
    {
      x: 12375,
      y: 0.4340057373046875,
    },
    {
      x: 12379.076086956522,
      y: 0.46390533447265625,
    },
    {
      x: 12383.152173913044,
      y: 0.4788551330566406,
    },
    {
      x: 12387.228260869566,
      y: 0.4489555358886719,
    },
    {
      x: 12391.304347826088,
      y: 0.409088134765625,
    },
    {
      x: 12395.38043478261,
      y: 0.3642387390136719,
    },
    {
      x: 12399.45652173913,
      y: 0.31439971923828125,
    },
    {
      x: 12403.532608695652,
      y: 0.2645683288574219,
    },
    {
      x: 12407.608695652174,
      y: 0.21473312377929688,
    },
    {
      x: 12411.684782608696,
      y: 0.16489410400390625,
    },
    {
      x: 12415.760869565218,
      y: 0.110076904296875,
    },
    {
      x: 12419.83695652174,
      y: 0.06024169921875,
    },
    {
      x: 12423.913043478262,
      y: 0.025356292724609375,
    },
    {
      x: 12427.989130434782,
      y: 0.010406494140625,
    },
    {
      x: 12432.065217391304,
      y: 0.00542449951171875,
    },
    {
      x: 12436.141304347826,
      y: 0.00542449951171875,
    },
    {
      x: 12448.369565217392,
      y: 0.00542449951171875,
    },
    {
      x: 12452.445652173914,
      y: 0.010406494140625,
    },
    {
      x: 12456.521739130436,
      y: 0.010406494140625,
    },
    {
      x: 12464.673913043478,
      y: 0.010406494140625,
    },
    {
      x: 12468.75,
      y: 0.015392303466796875,
    },
    {
      x: 12472.826086956522,
      y: 0.015392303466796875,
    },
    {
      x: 12480.978260869566,
      y: 0.015392303466796875,
    },
    {
      x: 12485.054347826088,
      y: 0.020374298095703125,
    },
    {
      x: 12489.13043478261,
      y: 0.020374298095703125,
    },
    {
      x: 12497.282608695652,
      y: 0.020374298095703125,
    },
    {
      x: 12501.358695652174,
      y: 0.025356292724609375,
    },
    {
      x: 12505.434782608696,
      y: 0.025356292724609375,
    },
    {
      x: 12513.58695652174,
      y: 0.025356292724609375,
    },
    {
      x: 12517.663043478262,
      y: 0.03034210205078125,
    },
    {
      x: 12521.739130434784,
      y: 0.15492630004882812,
    },
    {
      x: 12525.815217391304,
      y: 0.15492630004882812,
    },
    {
      x: 12778.532608695652,
      y: 0.15492630004882812,
    },
    {
      x: 12782.608695652174,
      y: 0.11506271362304688,
    },
    {
      x: 12786.684782608696,
      y: 0.12004470825195312,
    },
    {
      x: 12790.760869565218,
      y: 0.13001251220703125,
    },
    {
      x: 12794.83695652174,
      y: 0.1349945068359375,
    },
    {
      x: 12798.913043478262,
      y: 0.13997650146484375,
    },
    {
      x: 12802.989130434784,
      y: 0.14496231079101562,
    },
    {
      x: 12807.065217391304,
      y: 0.14994430541992188,
    },
    {
      x: 12811.141304347826,
      y: 0.15492630004882812,
    },
    {
      x: 12815.217391304348,
      y: 0.15492630004882812,
    },
    {
      x: 12819.29347826087,
      y: 0.159912109375,
    },
    {
      x: 12823.369565217392,
      y: 0.16489410400390625,
    },
    {
      x: 12827.445652173914,
      y: 0.1698760986328125,
    },
    {
      x: 12831.521739130436,
      y: 0.1698760986328125,
    },
    {
      x: 12847.826086956522,
      y: 0.1698760986328125,
    },
    {
      x: 12851.902173913044,
      y: 0.16489410400390625,
    },
    {
      x: 12855.978260869566,
      y: 0.16489410400390625,
    },
    {
      x: 12880.434782608696,
      y: 0.16489410400390625,
    },
    {
      x: 12884.510869565218,
      y: 0.159912109375,
    },
    {
      x: 12888.58695652174,
      y: 0.159912109375,
    },
    {
      x: 12913.04347826087,
      y: 0.159912109375,
    },
    {
      x: 12917.119565217392,
      y: 0.15492630004882812,
    },
    {
      x: 12921.195652173914,
      y: 0.15492630004882812,
    },
    {
      x: 13039.402173913044,
      y: 0.15492630004882812,
    },
    {
      x: 13043.478260869566,
      y: 0.020374298095703125,
    },
    {
      x: 13047.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 13051.63043478261,
      y: 0.025356292724609375,
    },
    {
      x: 13059.782608695652,
      y: 0.025356292724609375,
    },
    {
      x: 13063.858695652174,
      y: 0.03034210205078125,
    },
    {
      x: 13067.934782608696,
      y: 0.03034210205078125,
    },
    {
      x: 13076.08695652174,
      y: 0.03034210205078125,
    },
    {
      x: 13080.163043478262,
      y: 0.0353240966796875,
    },
    {
      x: 13084.239130434784,
      y: 0.0353240966796875,
    },
    {
      x: 13092.391304347826,
      y: 0.0353240966796875,
    },
    {
      x: 13096.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 13100.54347826087,
      y: 0.040309906005859375,
    },
    {
      x: 13108.695652173914,
      y: 0.040309906005859375,
    },
    {
      x: 13112.771739130436,
      y: 0.045291900634765625,
    },
    {
      x: 13116.847826086956,
      y: 0.045291900634765625,
    },
    {
      x: 13129.076086956522,
      y: 0.045291900634765625,
    },
    {
      x: 13133.152173913044,
      y: 0.050273895263671875,
    },
    {
      x: 13137.228260869566,
      y: 0.050273895263671875,
    },
    {
      x: 13161.684782608696,
      y: 0.050273895263671875,
    },
    {
      x: 13165.760869565218,
      y: 0.05525970458984375,
    },
    {
      x: 13169.83695652174,
      y: 0.05525970458984375,
    },
    {
      x: 13190.217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 13194.29347826087,
      y: 0.06522369384765625,
    },
    {
      x: 13198.369565217392,
      y: 0.09512710571289062,
    },
    {
      x: 13202.445652173914,
      y: 0.1349945068359375,
    },
    {
      x: 13206.521739130436,
      y: 0.159912109375,
    },
    {
      x: 13210.597826086956,
      y: 0.16489410400390625,
    },
    {
      x: 13214.673913043478,
      y: 0.16489410400390625,
    },
    {
      x: 13218.75,
      y: 0.159912109375,
    },
    {
      x: 13222.826086956522,
      y: 0.159912109375,
    },
    {
      x: 13226.902173913044,
      y: 0.15492630004882812,
    },
    {
      x: 13230.978260869566,
      y: 0.14994430541992188,
    },
    {
      x: 13235.054347826088,
      y: 0.14496231079101562,
    },
    {
      x: 13239.13043478261,
      y: 0.13997650146484375,
    },
    {
      x: 13243.20652173913,
      y: 0.1349945068359375,
    },
    {
      x: 13247.282608695652,
      y: 0.125030517578125,
    },
    {
      x: 13251.358695652174,
      y: 0.12004470825195312,
    },
    {
      x: 13255.434782608696,
      y: 0.11506271362304688,
    },
    {
      x: 13259.510869565218,
      y: 0.110076904296875,
    },
    {
      x: 13263.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 13267.663043478262,
      y: 0.10010910034179688,
    },
    {
      x: 13271.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 13275.815217391304,
      y: 0.09014511108398438,
    },
    {
      x: 13279.891304347826,
      y: 0.0851593017578125,
    },
    {
      x: 13283.967391304348,
      y: 0.09014511108398438,
    },
    {
      x: 13288.04347826087,
      y: 0.09512710571289062,
    },
    {
      x: 13292.119565217392,
      y: 0.10010910034179688,
    },
    {
      x: 13296.195652173914,
      y: 0.10509490966796875,
    },
    {
      x: 13300.271739130436,
      y: 0.110076904296875,
    },
    {
      x: 13304.347826086956,
      y: 0.15492630004882812,
    },
    {
      x: 13308.423913043478,
      y: 0.15492630004882812,
    },
    {
      x: 13406.25,
      y: 0.15492630004882812,
    },
    {
      x: 13410.326086956522,
      y: 0.16489410400390625,
    },
    {
      x: 13414.402173913044,
      y: 0.19479751586914062,
    },
    {
      x: 13418.478260869566,
      y: 0.22469711303710938,
    },
    {
      x: 13422.554347826088,
      y: 0.2595863342285156,
    },
    {
      x: 13426.63043478261,
      y: 0.2944679260253906,
    },
    {
      x: 13430.70652173913,
      y: 0.3293495178222656,
    },
    {
      x: 13434.782608695652,
      y: 0.3642387390136719,
    },
    {
      x: 13438.858695652174,
      y: 0.3991203308105469,
    },
    {
      x: 13442.934782608696,
      y: 0.4340057373046875,
    },
    {
      x: 13447.010869565218,
      y: 0.46390533447265625,
    },
    {
      x: 13451.08695652174,
      y: 0.4788551330566406,
    },
    {
      x: 13455.163043478262,
      y: 0.4489555358886719,
    },
    {
      x: 13459.239130434784,
      y: 0.409088134765625,
    },
    {
      x: 13463.315217391304,
      y: 0.3642387390136719,
    },
    {
      x: 13467.391304347826,
      y: 0.31439971923828125,
    },
    {
      x: 13471.467391304348,
      y: 0.2645683288574219,
    },
    {
      x: 13475.54347826087,
      y: 0.21473312377929688,
    },
    {
      x: 13479.619565217392,
      y: 0.16489410400390625,
    },
    {
      x: 13483.695652173914,
      y: 0.110076904296875,
    },
    {
      x: 13487.771739130436,
      y: 0.06024169921875,
    },
    {
      x: 13491.847826086956,
      y: 0.025356292724609375,
    },
    {
      x: 13495.923913043478,
      y: 0.010406494140625,
    },
    {
      x: 13500,
      y: 0.00542449951171875,
    },
    {
      x: 13504.076086956522,
      y: 0.00542449951171875,
    },
    {
      x: 13516.304347826088,
      y: 0.00542449951171875,
    },
    {
      x: 13520.38043478261,
      y: 0.010406494140625,
    },
    {
      x: 13524.45652173913,
      y: 0.010406494140625,
    },
    {
      x: 13532.608695652174,
      y: 0.010406494140625,
    },
    {
      x: 13536.684782608696,
      y: 0.015392303466796875,
    },
    {
      x: 13540.760869565218,
      y: 0.015392303466796875,
    },
    {
      x: 13548.913043478262,
      y: 0.015392303466796875,
    },
    {
      x: 13552.989130434784,
      y: 0.020374298095703125,
    },
    {
      x: 13557.065217391304,
      y: 0.020374298095703125,
    },
    {
      x: 13561.141304347826,
      y: 0.020374298095703125,
    },
    {
      x: 13565.217391304348,
      y: 0.15492630004882812,
    },
    {
      x: 13569.29347826087,
      y: 0.15492630004882812,
    },
    {
      x: 13822.010869565218,
      y: 0.15492630004882812,
    },
    {
      x: 13826.08695652174,
      y: 0.0851593017578125,
    },
    {
      x: 13830.163043478262,
      y: 0.09014511108398438,
    },
    {
      x: 13834.239130434784,
      y: 0.09512710571289062,
    },
    {
      x: 13838.315217391304,
      y: 0.10010910034179688,
    },
    {
      x: 13842.391304347826,
      y: 0.10509490966796875,
    },
    {
      x: 13846.467391304348,
      y: 0.110076904296875,
    },
    {
      x: 13850.54347826087,
      y: 0.11506271362304688,
    },
    {
      x: 13854.619565217392,
      y: 0.12004470825195312,
    },
    {
      x: 13858.695652173914,
      y: 0.13001251220703125,
    },
    {
      x: 13862.771739130436,
      y: 0.1349945068359375,
    },
    {
      x: 13866.847826086956,
      y: 0.13997650146484375,
    },
    {
      x: 13870.923913043478,
      y: 0.14496231079101562,
    },
    {
      x: 13875,
      y: 0.14994430541992188,
    },
    {
      x: 13879.076086956522,
      y: 0.15492630004882812,
    },
    {
      x: 13883.152173913044,
      y: 0.15492630004882812,
    },
    {
      x: 13887.228260869566,
      y: 0.159912109375,
    },
    {
      x: 13891.304347826088,
      y: 0.16489410400390625,
    },
    {
      x: 13895.38043478261,
      y: 0.1698760986328125,
    },
    {
      x: 13899.45652173913,
      y: 0.1698760986328125,
    },
    {
      x: 13915.760869565218,
      y: 0.1698760986328125,
    },
    {
      x: 13919.83695652174,
      y: 0.16489410400390625,
    },
    {
      x: 13923.913043478262,
      y: 0.16489410400390625,
    },
    {
      x: 13948.369565217392,
      y: 0.16489410400390625,
    },
    {
      x: 13952.445652173914,
      y: 0.159912109375,
    },
    {
      x: 13956.521739130436,
      y: 0.159912109375,
    },
    {
      x: 13980.978260869566,
      y: 0.159912109375,
    },
    {
      x: 13985.054347826088,
      y: 0.15492630004882812,
    },
    {
      x: 13989.13043478261,
      y: 0.15492630004882812,
    },
    {
      x: 14082.88043478261,
      y: 0.15492630004882812,
    },
    {
      x: 14086.95652173913,
      y: 0.015392303466796875,
    },
    {
      x: 14091.032608695652,
      y: 0.015392303466796875,
    },
    {
      x: 14095.108695652174,
      y: 0.015392303466796875,
    },
    {
      x: 14099.184782608696,
      y: 0.020374298095703125,
    },
    {
      x: 14103.260869565218,
      y: 0.020374298095703125,
    },
    {
      x: 14111.413043478262,
      y: 0.020374298095703125,
    },
    {
      x: 14115.489130434784,
      y: 0.025356292724609375,
    },
    {
      x: 14119.565217391304,
      y: 0.025356292724609375,
    },
    {
      x: 14127.717391304348,
      y: 0.025356292724609375,
    },
    {
      x: 14131.79347826087,
      y: 0.03034210205078125,
    },
    {
      x: 14135.869565217392,
      y: 0.03034210205078125,
    },
    {
      x: 14144.021739130436,
      y: 0.03034210205078125,
    },
    {
      x: 14148.097826086956,
      y: 0.0353240966796875,
    },
    {
      x: 14152.173913043478,
      y: 0.0353240966796875,
    },
    {
      x: 14160.326086956522,
      y: 0.0353240966796875,
    },
    {
      x: 14164.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 14168.478260869566,
      y: 0.040309906005859375,
    },
    {
      x: 14176.63043478261,
      y: 0.040309906005859375,
    },
    {
      x: 14180.70652173913,
      y: 0.045291900634765625,
    },
    {
      x: 14184.782608695652,
      y: 0.045291900634765625,
    },
    {
      x: 14197.010869565218,
      y: 0.045291900634765625,
    },
    {
      x: 14201.08695652174,
      y: 0.050273895263671875,
    },
    {
      x: 14205.163043478262,
      y: 0.050273895263671875,
    },
    {
      x: 14229.619565217392,
      y: 0.050273895263671875,
    },
    {
      x: 14233.695652173914,
      y: 0.05525970458984375,
    },
    {
      x: 14237.771739130436,
      y: 0.05525970458984375,
    },
    {
      x: 14258.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 14262.228260869566,
      y: 0.06522369384765625,
    },
    {
      x: 14266.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 14270.38043478261,
      y: 0.1349945068359375,
    },
    {
      x: 14274.45652173913,
      y: 0.159912109375,
    },
    {
      x: 14278.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 14282.608695652174,
      y: 0.16489410400390625,
    },
    {
      x: 14286.684782608696,
      y: 0.159912109375,
    },
    {
      x: 14290.760869565218,
      y: 0.159912109375,
    },
    {
      x: 14294.83695652174,
      y: 0.15492630004882812,
    },
    {
      x: 14298.913043478262,
      y: 0.14994430541992188,
    },
    {
      x: 14302.989130434784,
      y: 0.14496231079101562,
    },
    {
      x: 14307.065217391304,
      y: 0.13997650146484375,
    },
    {
      x: 14311.141304347826,
      y: 0.1349945068359375,
    },
    {
      x: 14315.217391304348,
      y: 0.125030517578125,
    },
    {
      x: 14319.29347826087,
      y: 0.12004470825195312,
    },
    {
      x: 14323.369565217392,
      y: 0.11506271362304688,
    },
    {
      x: 14327.445652173914,
      y: 0.110076904296875,
    },
    {
      x: 14331.521739130436,
      y: 0.10509490966796875,
    },
    {
      x: 14335.597826086956,
      y: 0.10010910034179688,
    },
    {
      x: 14339.673913043478,
      y: 0.09512710571289062,
    },
    {
      x: 14343.75,
      y: 0.09014511108398438,
    },
    {
      x: 14347.826086956522,
      y: 0.15492630004882812,
    },
    {
      x: 14351.902173913044,
      y: 0.15492630004882812,
    },
    {
      x: 14474.184782608696,
      y: 0.15492630004882812,
    },
    {
      x: 14478.260869565218,
      y: 0.16489410400390625,
    },
    {
      x: 14482.33695652174,
      y: 0.19479751586914062,
    },
    {
      x: 14486.413043478262,
      y: 0.22469711303710938,
    },
    {
      x: 14490.489130434784,
      y: 0.2595863342285156,
    },
    {
      x: 14494.565217391304,
      y: 0.2944679260253906,
    },
    {
      x: 14498.641304347826,
      y: 0.3293495178222656,
    },
    {
      x: 14502.717391304348,
      y: 0.3642387390136719,
    },
    {
      x: 14506.79347826087,
      y: 0.3991203308105469,
    },
    {
      x: 14510.869565217392,
      y: 0.4340057373046875,
    },
    {
      x: 14514.945652173914,
      y: 0.46390533447265625,
    },
    {
      x: 14519.021739130436,
      y: 0.4788551330566406,
    },
    {
      x: 14523.097826086956,
      y: 0.4489555358886719,
    },
    {
      x: 14527.173913043478,
      y: 0.409088134765625,
    },
    {
      x: 14531.25,
      y: 0.3642387390136719,
    },
    {
      x: 14535.326086956522,
      y: 0.31439971923828125,
    },
    {
      x: 14539.402173913044,
      y: 0.2645683288574219,
    },
    {
      x: 14543.478260869566,
      y: 0.21473312377929688,
    },
    {
      x: 14547.554347826088,
      y: 0.16489410400390625,
    },
    {
      x: 14551.63043478261,
      y: 0.110076904296875,
    },
    {
      x: 14555.70652173913,
      y: 0.06024169921875,
    },
    {
      x: 14559.782608695652,
      y: 0.025356292724609375,
    },
    {
      x: 14563.858695652174,
      y: 0.010406494140625,
    },
    {
      x: 14567.934782608696,
      y: 0.00542449951171875,
    },
    {
      x: 14572.010869565218,
      y: 0.00542449951171875,
    },
    {
      x: 14584.239130434784,
      y: 0.00542449951171875,
    },
    {
      x: 14588.315217391304,
      y: 0.010406494140625,
    },
    {
      x: 14592.391304347826,
      y: 0.010406494140625,
    },
    {
      x: 14600.54347826087,
      y: 0.010406494140625,
    },
    {
      x: 14604.619565217392,
      y: 0.015392303466796875,
    },
    {
      x: 14608.695652173914,
      y: 0.15492630004882812,
    },
    {
      x: 14612.771739130436,
      y: 0.15492630004882812,
    },
    {
      x: 14865.489130434784,
      y: 0.15492630004882812,
    },
    {
      x: 14869.565217391304,
      y: 0.11506271362304688,
    },
    {
      x: 14873.641304347826,
      y: 0.110076904296875,
    },
    {
      x: 14877.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 14881.79347826087,
      y: 0.10509490966796875,
    },
    {
      x: 14885.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 14889.945652173914,
      y: 0.09014511108398438,
    },
    {
      x: 14894.021739130436,
      y: 0.09014511108398438,
    },
    {
      x: 14898.097826086956,
      y: 0.09512710571289062,
    },
    {
      x: 14902.173913043478,
      y: 0.10010910034179688,
    },
    {
      x: 14906.25,
      y: 0.10010910034179688,
    },
    {
      x: 14910.326086956522,
      y: 0.10509490966796875,
    },
    {
      x: 14914.402173913044,
      y: 0.110076904296875,
    },
    {
      x: 14918.478260869566,
      y: 0.11506271362304688,
    },
    {
      x: 14922.554347826088,
      y: 0.12004470825195312,
    },
    {
      x: 14926.63043478261,
      y: 0.13001251220703125,
    },
    {
      x: 14930.70652173913,
      y: 0.1349945068359375,
    },
    {
      x: 14934.782608695652,
      y: 0.13997650146484375,
    },
    {
      x: 14938.858695652174,
      y: 0.14496231079101562,
    },
    {
      x: 14942.934782608696,
      y: 0.14994430541992188,
    },
    {
      x: 14947.010869565218,
      y: 0.15492630004882812,
    },
    {
      x: 14951.08695652174,
      y: 0.159912109375,
    },
    {
      x: 14955.163043478262,
      y: 0.16489410400390625,
    },
    {
      x: 14959.239130434784,
      y: 0.1698760986328125,
    },
    {
      x: 14963.315217391304,
      y: 0.1698760986328125,
    },
    {
      x: 14991.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 14995.923913043478,
      y: 0.16489410400390625,
    },
    {
      x: 15000,
      y: 0.16489410400390625,
    },
    {
      x: 15028.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 15032.608695652174,
      y: 0.159912109375,
    },
    {
      x: 15036.684782608696,
      y: 0.159912109375,
    },
    {
      x: 15061.141304347826,
      y: 0.159912109375,
    },
    {
      x: 15065.217391304348,
      y: 0.15492630004882812,
    },
    {
      x: 15069.29347826087,
      y: 0.15492630004882812,
    },
    {
      x: 15126.358695652174,
      y: 0.15492630004882812,
    },
    {
      x: 15130.434782608696,
      y: 0.00542449951171875,
    },
    {
      x: 15134.510869565218,
      y: 0.00542449951171875,
    },
    {
      x: 15142.663043478262,
      y: 0.00542449951171875,
    },
    {
      x: 15146.739130434784,
      y: 0.010406494140625,
    },
    {
      x: 15150.815217391304,
      y: 0.010406494140625,
    },
    {
      x: 15158.967391304348,
      y: 0.010406494140625,
    },
    {
      x: 15163.04347826087,
      y: 0.015392303466796875,
    },
    {
      x: 15167.119565217392,
      y: 0.015392303466796875,
    },
    {
      x: 15175.271739130436,
      y: 0.015392303466796875,
    },
    {
      x: 15179.347826086956,
      y: 0.020374298095703125,
    },
    {
      x: 15183.423913043478,
      y: 0.020374298095703125,
    },
    {
      x: 15191.576086956522,
      y: 0.020374298095703125,
    },
    {
      x: 15195.652173913044,
      y: 0.025356292724609375,
    },
    {
      x: 15199.728260869566,
      y: 0.025356292724609375,
    },
    {
      x: 15207.88043478261,
      y: 0.025356292724609375,
    },
    {
      x: 15211.95652173913,
      y: 0.03034210205078125,
    },
    {
      x: 15216.032608695652,
      y: 0.03034210205078125,
    },
    {
      x: 15224.184782608696,
      y: 0.03034210205078125,
    },
    {
      x: 15228.260869565218,
      y: 0.0353240966796875,
    },
    {
      x: 15232.33695652174,
      y: 0.0353240966796875,
    },
    {
      x: 15240.489130434784,
      y: 0.0353240966796875,
    },
    {
      x: 15244.565217391304,
      y: 0.040309906005859375,
    },
    {
      x: 15248.641304347826,
      y: 0.040309906005859375,
    },
    {
      x: 15256.79347826087,
      y: 0.040309906005859375,
    },
    {
      x: 15260.869565217392,
      y: 0.045291900634765625,
    },
    {
      x: 15264.945652173914,
      y: 0.045291900634765625,
    },
    {
      x: 15281.25,
      y: 0.045291900634765625,
    },
    {
      x: 15285.326086956522,
      y: 0.050273895263671875,
    },
    {
      x: 15289.402173913044,
      y: 0.050273895263671875,
    },
    {
      x: 15313.858695652174,
      y: 0.050273895263671875,
    },
    {
      x: 15317.934782608696,
      y: 0.05525970458984375,
    },
    {
      x: 15322.010869565218,
      y: 0.05525970458984375,
    },
    {
      x: 15326.08695652174,
      y: 0.05525970458984375,
    },
    {
      x: 15330.163043478262,
      y: 0.06522369384765625,
    },
    {
      x: 15334.239130434784,
      y: 0.09512710571289062,
    },
    {
      x: 15338.315217391304,
      y: 0.1349945068359375,
    },
    {
      x: 15342.391304347826,
      y: 0.16489410400390625,
    },
    {
      x: 15346.467391304348,
      y: 0.1698760986328125,
    },
    {
      x: 15350.54347826087,
      y: 0.1698760986328125,
    },
    {
      x: 15354.619565217392,
      y: 0.16489410400390625,
    },
    {
      x: 15358.695652173914,
      y: 0.159912109375,
    },
    {
      x: 15362.771739130436,
      y: 0.15492630004882812,
    },
    {
      x: 15366.847826086958,
      y: 0.14994430541992188,
    },
    {
      x: 15370.923913043478,
      y: 0.14496231079101562,
    },
    {
      x: 15375,
      y: 0.13997650146484375,
    },
    {
      x: 15379.076086956522,
      y: 0.1349945068359375,
    },
    {
      x: 15383.152173913044,
      y: 0.13001251220703125,
    },
    {
      x: 15387.228260869566,
      y: 0.12004470825195312,
    },
    {
      x: 15391.304347826088,
      y: 0.15492630004882812,
    },
    {
      x: 15395.38043478261,
      y: 0.15492630004882812,
    },
    {
      x: 15542.119565217392,
      y: 0.15492630004882812,
    },
    {
      x: 15546.195652173914,
      y: 0.16489410400390625,
    },
    {
      x: 15550.271739130436,
      y: 0.19479751586914062,
    },
    {
      x: 15554.347826086958,
      y: 0.22469711303710938,
    },
    {
      x: 15558.423913043478,
      y: 0.2595863342285156,
    },
    {
      x: 15562.5,
      y: 0.2944679260253906,
    },
    {
      x: 15566.576086956522,
      y: 0.3293495178222656,
    },
    {
      x: 15570.652173913044,
      y: 0.3642387390136719,
    },
    {
      x: 15574.728260869566,
      y: 0.3991203308105469,
    },
    {
      x: 15578.804347826088,
      y: 0.4340057373046875,
    },
    {
      x: 15582.88043478261,
      y: 0.46390533447265625,
    },
    {
      x: 15586.95652173913,
      y: 0.4788551330566406,
    },
    {
      x: 15591.032608695652,
      y: 0.4489555358886719,
    },
    {
      x: 15595.108695652174,
      y: 0.409088134765625,
    },
    {
      x: 15599.184782608696,
      y: 0.3592529296875,
    },
    {
      x: 15603.260869565218,
      y: 0.309417724609375,
    },
    {
      x: 15607.33695652174,
      y: 0.2595863342285156,
    },
    {
      x: 15611.413043478262,
      y: 0.21473312377929688,
    },
    {
      x: 15615.489130434784,
      y: 0.159912109375,
    },
    {
      x: 15619.565217391304,
      y: 0.10509490966796875,
    },
    {
      x: 15623.641304347826,
      y: 0.06024169921875,
    },
    {
      x: 15627.717391304348,
      y: 0.020374298095703125,
    },
    {
      x: 15631.79347826087,
      y: 0.00542449951171875,
    },
    {
      x: 15635.869565217392,
      y: 0.0004425048828125,
    },
    {
      x: 15639.945652173914,
      y: 0.0004425048828125,
    },
    {
      x: 15648.097826086958,
      y: 0.0004425048828125,
    },
    {
      x: 15652.173913043478,
      y: 0.15492630004882812,
    },
    {
      x: 15656.25,
      y: 0.15492630004882812,
    },
    {
      x: 15908.967391304348,
      y: 0.15492630004882812,
    },
    {
      x: 15913.04347826087,
      y: 0.14994430541992188,
    },
    {
      x: 15917.119565217392,
      y: 0.14496231079101562,
    },
    {
      x: 15921.195652173914,
      y: 0.13997650146484375,
    },
    {
      x: 15925.271739130436,
      y: 0.1349945068359375,
    },
    {
      x: 15929.347826086958,
      y: 0.13001251220703125,
    },
    {
      x: 15933.423913043478,
      y: 0.12004470825195312,
    },
    {
      x: 15937.5,
      y: 0.11506271362304688,
    },
    {
      x: 15941.576086956522,
      y: 0.110076904296875,
    },
    {
      x: 15945.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 15949.728260869566,
      y: 0.10010910034179688,
    },
    {
      x: 15953.804347826088,
      y: 0.09512710571289062,
    },
    {
      x: 15957.88043478261,
      y: 0.09014511108398438,
    },
    {
      x: 15961.95652173913,
      y: 0.09014511108398438,
    },
    {
      x: 15966.032608695652,
      y: 0.09512710571289062,
    },
    {
      x: 15970.108695652174,
      y: 0.10010910034179688,
    },
    {
      x: 15974.184782608696,
      y: 0.10010910034179688,
    },
    {
      x: 15978.260869565218,
      y: 0.10509490966796875,
    },
    {
      x: 15982.33695652174,
      y: 0.110076904296875,
    },
    {
      x: 15986.413043478262,
      y: 0.11506271362304688,
    },
    {
      x: 15990.489130434784,
      y: 0.12004470825195312,
    },
    {
      x: 15994.565217391304,
      y: 0.13001251220703125,
    },
    {
      x: 15998.641304347826,
      y: 0.1349945068359375,
    },
    {
      x: 16002.717391304348,
      y: 0.13997650146484375,
    },
    {
      x: 16006.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 16010.869565217392,
      y: 0.14994430541992188,
    },
    {
      x: 16014.945652173914,
      y: 0.15492630004882812,
    },
    {
      x: 16019.021739130436,
      y: 0.159912109375,
    },
    {
      x: 16023.097826086958,
      y: 0.16489410400390625,
    },
    {
      x: 16027.173913043478,
      y: 0.1698760986328125,
    },
    {
      x: 16031.25,
      y: 0.1698760986328125,
    },
    {
      x: 16059.782608695652,
      y: 0.1698760986328125,
    },
    {
      x: 16063.858695652174,
      y: 0.16489410400390625,
    },
    {
      x: 16067.934782608696,
      y: 0.16489410400390625,
    },
    {
      x: 16096.467391304348,
      y: 0.16489410400390625,
    },
    {
      x: 16100.54347826087,
      y: 0.159912109375,
    },
    {
      x: 16104.619565217392,
      y: 0.159912109375,
    },
    {
      x: 16129.076086956522,
      y: 0.159912109375,
    },
    {
      x: 16133.152173913044,
      y: 0.15492630004882812,
    },
    {
      x: 16137.228260869566,
      y: 0.15492630004882812,
    },
    {
      x: 16169.83695652174,
      y: 0.15492630004882812,
    },
    {
      x: 16173.913043478262,
      y: 0.020374298095703125,
    },
    {
      x: 16177.989130434784,
      y: 0.00542449951171875,
    },
    {
      x: 16182.065217391304,
      y: 0.0004425048828125,
    },
    {
      x: 16186.141304347826,
      y: 0.0004425048828125,
    },
    {
      x: 16194.29347826087,
      y: 0.0004425048828125,
    },
    {
      x: 16198.369565217392,
      y: 0.00542449951171875,
    },
    {
      x: 16202.445652173914,
      y: 0.00542449951171875,
    },
    {
      x: 16210.597826086958,
      y: 0.00542449951171875,
    },
    {
      x: 16214.673913043478,
      y: 0.010406494140625,
    },
    {
      x: 16218.75,
      y: 0.010406494140625,
    },
    {
      x: 16226.902173913044,
      y: 0.010406494140625,
    },
    {
      x: 16230.978260869566,
      y: 0.015392303466796875,
    },
    {
      x: 16235.054347826088,
      y: 0.015392303466796875,
    },
    {
      x: 16243.20652173913,
      y: 0.015392303466796875,
    },
    {
      x: 16247.282608695652,
      y: 0.020374298095703125,
    },
    {
      x: 16251.358695652174,
      y: 0.020374298095703125,
    },
    {
      x: 16259.510869565218,
      y: 0.020374298095703125,
    },
    {
      x: 16263.58695652174,
      y: 0.025356292724609375,
    },
    {
      x: 16267.663043478262,
      y: 0.025356292724609375,
    },
    {
      x: 16275.815217391304,
      y: 0.025356292724609375,
    },
    {
      x: 16279.891304347826,
      y: 0.03034210205078125,
    },
    {
      x: 16283.967391304348,
      y: 0.03034210205078125,
    },
    {
      x: 16292.119565217392,
      y: 0.03034210205078125,
    },
    {
      x: 16296.195652173914,
      y: 0.0353240966796875,
    },
    {
      x: 16300.271739130436,
      y: 0.0353240966796875,
    },
    {
      x: 16308.423913043478,
      y: 0.0353240966796875,
    },
    {
      x: 16312.5,
      y: 0.040309906005859375,
    },
    {
      x: 16316.576086956522,
      y: 0.040309906005859375,
    },
    {
      x: 16324.728260869566,
      y: 0.040309906005859375,
    },
    {
      x: 16328.804347826088,
      y: 0.045291900634765625,
    },
    {
      x: 16332.88043478261,
      y: 0.045291900634765625,
    },
    {
      x: 16349.184782608696,
      y: 0.045291900634765625,
    },
    {
      x: 16353.260869565218,
      y: 0.050273895263671875,
    },
    {
      x: 16357.33695652174,
      y: 0.050273895263671875,
    },
    {
      x: 16381.79347826087,
      y: 0.050273895263671875,
    },
    {
      x: 16385.869565217392,
      y: 0.05525970458984375,
    },
    {
      x: 16389.945652173912,
      y: 0.05525970458984375,
    },
    {
      x: 16394.021739130436,
      y: 0.05525970458984375,
    },
    {
      x: 16398.097826086956,
      y: 0.06522369384765625,
    },
    {
      x: 16402.17391304348,
      y: 0.09512710571289062,
    },
    {
      x: 16406.25,
      y: 0.1349945068359375,
    },
    {
      x: 16410.326086956524,
      y: 0.16489410400390625,
    },
    {
      x: 16414.402173913044,
      y: 0.1698760986328125,
    },
    {
      x: 16418.478260869564,
      y: 0.1698760986328125,
    },
    {
      x: 16422.554347826088,
      y: 0.16489410400390625,
    },
    {
      x: 16426.630434782608,
      y: 0.159912109375,
    },
    {
      x: 16430.706521739132,
      y: 0.15492630004882812,
    },
    {
      x: 16434.782608695652,
      y: 0.15492630004882812,
    },
    {
      x: 16610.054347826088,
      y: 0.15492630004882812,
    },
    {
      x: 16614.130434782608,
      y: 0.16489410400390625,
    },
    {
      x: 16618.206521739132,
      y: 0.19479751586914062,
    },
    {
      x: 16622.282608695652,
      y: 0.22469711303710938,
    },
    {
      x: 16626.358695652176,
      y: 0.2595863342285156,
    },
    {
      x: 16630.434782608696,
      y: 0.2944679260253906,
    },
    {
      x: 16634.510869565216,
      y: 0.3293495178222656,
    },
    {
      x: 16638.58695652174,
      y: 0.3642387390136719,
    },
    {
      x: 16642.66304347826,
      y: 0.3991203308105469,
    },
    {
      x: 16646.739130434784,
      y: 0.4340057373046875,
    },
    {
      x: 16650.815217391304,
      y: 0.46390533447265625,
    },
    {
      x: 16654.891304347828,
      y: 0.4788551330566406,
    },
    {
      x: 16658.967391304348,
      y: 0.4489555358886719,
    },
    {
      x: 16663.04347826087,
      y: 0.409088134765625,
    },
    {
      x: 16667.119565217392,
      y: 0.3592529296875,
    },
    {
      x: 16671.195652173912,
      y: 0.309417724609375,
    },
    {
      x: 16675.271739130436,
      y: 0.2595863342285156,
    },
    {
      x: 16679.347826086956,
      y: 0.21473312377929688,
    },
    {
      x: 16683.42391304348,
      y: 0.159912109375,
    },
    {
      x: 16687.5,
      y: 0.10509490966796875,
    },
    {
      x: 16691.576086956524,
      y: 0.06024169921875,
    },
    {
      x: 16695.652173913044,
      y: 0.16489410400390625,
    },
    {
      x: 16699.728260869564,
      y: 0.1698760986328125,
    },
    {
      x: 16703.804347826088,
      y: 0.1698760986328125,
    },
    {
      x: 16707.880434782608,
      y: 0.16489410400390625,
    },
    {
      x: 16711.956521739132,
      y: 0.159912109375,
    },
    {
      x: 16716.032608695652,
      y: 0.15492630004882812,
    },
    {
      x: 16720.108695652176,
      y: 0.14994430541992188,
    },
    {
      x: 16724.184782608696,
      y: 0.14496231079101562,
    },
    {
      x: 16728.260869565216,
      y: 0.13997650146484375,
    },
    {
      x: 16732.33695652174,
      y: 0.1349945068359375,
    },
    {
      x: 16736.41304347826,
      y: 0.13001251220703125,
    },
    {
      x: 16740.489130434784,
      y: 0.12004470825195312,
    },
    {
      x: 16744.565217391304,
      y: 0.11506271362304688,
    },
    {
      x: 16748.641304347828,
      y: 0.110076904296875,
    },
    {
      x: 16752.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 16756.79347826087,
      y: 0.10010910034179688,
    },
    {
      x: 16760.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 16764.945652173912,
      y: 0.09014511108398438,
    },
    {
      x: 16769.021739130436,
      y: 0.09014511108398438,
    },
    {
      x: 16773.097826086956,
      y: 0.09512710571289062,
    },
    {
      x: 16777.17391304348,
      y: 0.09512710571289062,
    },
    {
      x: 16781.25,
      y: 0.10010910034179688,
    },
    {
      x: 16785.326086956524,
      y: 0.10509490966796875,
    },
    {
      x: 16789.402173913044,
      y: 0.110076904296875,
    },
    {
      x: 16793.478260869564,
      y: 0.11506271362304688,
    },
    {
      x: 16797.554347826088,
      y: 0.12004470825195312,
    },
    {
      x: 16801.630434782608,
      y: 0.13001251220703125,
    },
    {
      x: 16805.706521739132,
      y: 0.1349945068359375,
    },
    {
      x: 16809.782608695652,
      y: 0.13997650146484375,
    },
    {
      x: 16813.858695652176,
      y: 0.14496231079101562,
    },
    {
      x: 16817.934782608696,
      y: 0.14994430541992188,
    },
    {
      x: 16822.010869565216,
      y: 0.15492630004882812,
    },
    {
      x: 16826.08695652174,
      y: 0.159912109375,
    },
    {
      x: 16830.16304347826,
      y: 0.16489410400390625,
    },
    {
      x: 16834.239130434784,
      y: 0.1698760986328125,
    },
    {
      x: 16838.315217391304,
      y: 0.1698760986328125,
    },
    {
      x: 16866.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 16870.92391304348,
      y: 0.16489410400390625,
    },
    {
      x: 16875,
      y: 0.16489410400390625,
    },
    {
      x: 16903.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 16907.608695652176,
      y: 0.159912109375,
    },
    {
      x: 16911.684782608696,
      y: 0.159912109375,
    },
    {
      x: 16936.141304347828,
      y: 0.159912109375,
    },
    {
      x: 16940.217391304348,
      y: 0.15492630004882812,
    },
    {
      x: 16944.29347826087,
      y: 0.15492630004882812,
    },
    {
      x: 16952.445652173912,
      y: 0.15492630004882812,
    },
    {
      x: 16956.521739130436,
      y: 0.309417724609375,
    },
    {
      x: 16960.597826086956,
      y: 0.2595863342285156,
    },
    {
      x: 16964.67391304348,
      y: 0.21473312377929688,
    },
    {
      x: 16968.75,
      y: 0.159912109375,
    },
    {
      x: 16972.826086956524,
      y: 0.10509490966796875,
    },
    {
      x: 16976.902173913044,
      y: 0.06024169921875,
    },
    {
      x: 16980.978260869564,
      y: 0.020374298095703125,
    },
    {
      x: 16985.054347826088,
      y: 0.00542449951171875,
    },
    {
      x: 16989.130434782608,
      y: 0.0004425048828125,
    },
    {
      x: 16993.206521739132,
      y: 0.0004425048828125,
    },
    {
      x: 17001.358695652176,
      y: 0.0004425048828125,
    },
    {
      x: 17005.434782608696,
      y: 0.00542449951171875,
    },
    {
      x: 17009.510869565216,
      y: 0.00542449951171875,
    },
    {
      x: 17017.66304347826,
      y: 0.00542449951171875,
    },
    {
      x: 17021.739130434784,
      y: 0.010406494140625,
    },
    {
      x: 17025.815217391304,
      y: 0.010406494140625,
    },
    {
      x: 17033.967391304348,
      y: 0.010406494140625,
    },
    {
      x: 17038.04347826087,
      y: 0.015392303466796875,
    },
    {
      x: 17042.119565217392,
      y: 0.015392303466796875,
    },
    {
      x: 17050.271739130436,
      y: 0.015392303466796875,
    },
    {
      x: 17054.347826086956,
      y: 0.020374298095703125,
    },
    {
      x: 17058.42391304348,
      y: 0.020374298095703125,
    },
    {
      x: 17066.576086956524,
      y: 0.020374298095703125,
    },
    {
      x: 17070.652173913044,
      y: 0.025356292724609375,
    },
    {
      x: 17074.728260869564,
      y: 0.025356292724609375,
    },
    {
      x: 17082.880434782608,
      y: 0.025356292724609375,
    },
    {
      x: 17086.956521739132,
      y: 0.03034210205078125,
    },
    {
      x: 17091.032608695652,
      y: 0.03034210205078125,
    },
    {
      x: 17099.184782608696,
      y: 0.03034210205078125,
    },
    {
      x: 17103.260869565216,
      y: 0.0353240966796875,
    },
    {
      x: 17107.33695652174,
      y: 0.0353240966796875,
    },
    {
      x: 17115.489130434784,
      y: 0.0353240966796875,
    },
    {
      x: 17119.565217391304,
      y: 0.040309906005859375,
    },
    {
      x: 17123.641304347828,
      y: 0.040309906005859375,
    },
    {
      x: 17131.79347826087,
      y: 0.040309906005859375,
    },
    {
      x: 17135.869565217392,
      y: 0.045291900634765625,
    },
    {
      x: 17139.945652173912,
      y: 0.045291900634765625,
    },
    {
      x: 17156.25,
      y: 0.045291900634765625,
    },
    {
      x: 17160.326086956524,
      y: 0.050273895263671875,
    },
    {
      x: 17164.402173913044,
      y: 0.050273895263671875,
    },
    {
      x: 17188.858695652176,
      y: 0.050273895263671875,
    },
    {
      x: 17192.934782608696,
      y: 0.05525970458984375,
    },
    {
      x: 17197.010869565216,
      y: 0.05525970458984375,
    },
    {
      x: 17201.08695652174,
      y: 0.05525970458984375,
    },
    {
      x: 17205.16304347826,
      y: 0.06522369384765625,
    },
    {
      x: 17209.239130434784,
      y: 0.09512710571289062,
    },
    {
      x: 17213.315217391304,
      y: 0.1349945068359375,
    },
    {
      x: 17217.391304347828,
      y: 0.15492630004882812,
    },
    {
      x: 17221.467391304348,
      y: 0.15492630004882812,
    },
    {
      x: 17677.989130434784,
      y: 0.15492630004882812,
    },
    {
      x: 17682.065217391304,
      y: 0.16489410400390625,
    },
    {
      x: 17686.141304347828,
      y: 0.19479751586914062,
    },
    {
      x: 17690.217391304348,
      y: 0.22469711303710938,
    },
    {
      x: 17694.29347826087,
      y: 0.2595863342285156,
    },
    {
      x: 17698.369565217392,
      y: 0.2944679260253906,
    },
    {
      x: 17702.445652173912,
      y: 0.3293495178222656,
    },
    {
      x: 17706.521739130436,
      y: 0.3642387390136719,
    },
    {
      x: 17710.597826086956,
      y: 0.3991203308105469,
    },
    {
      x: 17714.67391304348,
      y: 0.4340057373046875,
    },
    {
      x: 17718.75,
      y: 0.46390533447265625,
    },
    {
      x: 17722.826086956524,
      y: 0.4788551330566406,
    },
    {
      x: 17726.902173913044,
      y: 0.4489555358886719,
    },
    {
      x: 17730.978260869564,
      y: 0.409088134765625,
    },
    {
      x: 17735.054347826088,
      y: 0.3592529296875,
    },
    {
      x: 17739.130434782608,
      y: 0.159912109375,
    },
    {
      x: 17743.206521739132,
      y: 0.159912109375,
    },
    {
      x: 17747.282608695652,
      y: 0.15492630004882812,
    },
    {
      x: 17751.358695652176,
      y: 0.15492630004882812,
    },
    {
      x: 17995.92391304348,
      y: 0.15492630004882812,
    },
    {
      x: 18000,
      y: 0.05525970458984375,
    },
    {
      x: 18004.076086956524,
      y: 0.05525970458984375,
    },
    {
      x: 18008.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 18012.228260869564,
      y: 0.06522369384765625,
    },
    {
      x: 18016.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 18020.380434782608,
      y: 0.1349945068359375,
    },
    {
      x: 18024.456521739132,
      y: 0.16489410400390625,
    },
    {
      x: 18028.532608695652,
      y: 0.1698760986328125,
    },
    {
      x: 18032.608695652176,
      y: 0.1698760986328125,
    },
    {
      x: 18036.684782608696,
      y: 0.16489410400390625,
    },
    {
      x: 18040.760869565216,
      y: 0.159912109375,
    },
    {
      x: 18044.83695652174,
      y: 0.15492630004882812,
    },
    {
      x: 18048.91304347826,
      y: 0.14994430541992188,
    },
    {
      x: 18052.989130434784,
      y: 0.14496231079101562,
    },
    {
      x: 18057.065217391304,
      y: 0.13997650146484375,
    },
    {
      x: 18061.141304347828,
      y: 0.1349945068359375,
    },
    {
      x: 18065.217391304348,
      y: 0.13001251220703125,
    },
    {
      x: 18069.29347826087,
      y: 0.12004470825195312,
    },
    {
      x: 18073.369565217392,
      y: 0.11506271362304688,
    },
    {
      x: 18077.445652173912,
      y: 0.110076904296875,
    },
    {
      x: 18081.521739130436,
      y: 0.10509490966796875,
    },
    {
      x: 18085.597826086956,
      y: 0.10010910034179688,
    },
    {
      x: 18089.67391304348,
      y: 0.09512710571289062,
    },
    {
      x: 18093.75,
      y: 0.09014511108398438,
    },
    {
      x: 18097.826086956524,
      y: 0.09014511108398438,
    },
    {
      x: 18101.902173913044,
      y: 0.09512710571289062,
    },
    {
      x: 18105.978260869564,
      y: 0.09512710571289062,
    },
    {
      x: 18110.054347826088,
      y: 0.10010910034179688,
    },
    {
      x: 18114.130434782608,
      y: 0.10509490966796875,
    },
    {
      x: 18118.206521739132,
      y: 0.110076904296875,
    },
    {
      x: 18122.282608695652,
      y: 0.11506271362304688,
    },
    {
      x: 18126.358695652176,
      y: 0.12004470825195312,
    },
    {
      x: 18130.434782608696,
      y: 0.13001251220703125,
    },
    {
      x: 18134.510869565216,
      y: 0.1349945068359375,
    },
    {
      x: 18138.58695652174,
      y: 0.13997650146484375,
    },
    {
      x: 18142.66304347826,
      y: 0.14496231079101562,
    },
    {
      x: 18146.739130434784,
      y: 0.14994430541992188,
    },
    {
      x: 18150.815217391304,
      y: 0.15492630004882812,
    },
    {
      x: 18154.891304347828,
      y: 0.159912109375,
    },
    {
      x: 18158.967391304348,
      y: 0.16489410400390625,
    },
    {
      x: 18163.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 18167.119565217392,
      y: 0.1698760986328125,
    },
    {
      x: 18195.652173913044,
      y: 0.1698760986328125,
    },
    {
      x: 18199.728260869564,
      y: 0.16489410400390625,
    },
    {
      x: 18203.804347826088,
      y: 0.16489410400390625,
    },
    {
      x: 18232.33695652174,
      y: 0.16489410400390625,
    },
    {
      x: 18236.41304347826,
      y: 0.159912109375,
    },
    {
      x: 18240.489130434784,
      y: 0.159912109375,
    },
    {
      x: 18256.79347826087,
      y: 0.159912109375,
    },
    {
      x: 18260.869565217392,
      y: 0.4340057373046875,
    },
    {
      x: 18264.945652173912,
      y: 0.46390533447265625,
    },
    {
      x: 18269.021739130436,
      y: 0.4788551330566406,
    },
    {
      x: 18273.097826086956,
      y: 0.4489555358886719,
    },
    {
      x: 18277.17391304348,
      y: 0.409088134765625,
    },
    {
      x: 18281.25,
      y: 0.3592529296875,
    },
    {
      x: 18285.326086956524,
      y: 0.309417724609375,
    },
    {
      x: 18289.402173913044,
      y: 0.2595863342285156,
    },
    {
      x: 18293.478260869564,
      y: 0.21473312377929688,
    },
    {
      x: 18297.554347826088,
      y: 0.159912109375,
    },
    {
      x: 18301.630434782608,
      y: 0.10509490966796875,
    },
    {
      x: 18305.706521739132,
      y: 0.06024169921875,
    },
    {
      x: 18309.782608695652,
      y: 0.020374298095703125,
    },
    {
      x: 18313.858695652176,
      y: 0.00542449951171875,
    },
    {
      x: 18317.934782608696,
      y: 0.0004425048828125,
    },
    {
      x: 18322.010869565216,
      y: 0.0004425048828125,
    },
    {
      x: 18330.16304347826,
      y: 0.0004425048828125,
    },
    {
      x: 18334.239130434784,
      y: 0.00542449951171875,
    },
    {
      x: 18338.315217391304,
      y: 0.00542449951171875,
    },
    {
      x: 18346.467391304348,
      y: 0.00542449951171875,
    },
    {
      x: 18350.54347826087,
      y: 0.010406494140625,
    },
    {
      x: 18354.619565217392,
      y: 0.010406494140625,
    },
    {
      x: 18362.771739130436,
      y: 0.010406494140625,
    },
    {
      x: 18366.847826086956,
      y: 0.015392303466796875,
    },
    {
      x: 18370.92391304348,
      y: 0.015392303466796875,
    },
    {
      x: 18379.076086956524,
      y: 0.015392303466796875,
    },
    {
      x: 18383.152173913044,
      y: 0.020374298095703125,
    },
    {
      x: 18387.228260869564,
      y: 0.020374298095703125,
    },
    {
      x: 18395.380434782608,
      y: 0.020374298095703125,
    },
    {
      x: 18399.456521739132,
      y: 0.025356292724609375,
    },
    {
      x: 18403.532608695652,
      y: 0.025356292724609375,
    },
    {
      x: 18411.684782608696,
      y: 0.025356292724609375,
    },
    {
      x: 18415.760869565216,
      y: 0.03034210205078125,
    },
    {
      x: 18419.83695652174,
      y: 0.03034210205078125,
    },
    {
      x: 18427.989130434784,
      y: 0.03034210205078125,
    },
    {
      x: 18432.065217391304,
      y: 0.0353240966796875,
    },
    {
      x: 18436.141304347828,
      y: 0.0353240966796875,
    },
    {
      x: 18444.29347826087,
      y: 0.0353240966796875,
    },
    {
      x: 18448.369565217392,
      y: 0.040309906005859375,
    },
    {
      x: 18452.445652173912,
      y: 0.040309906005859375,
    },
    {
      x: 18460.597826086956,
      y: 0.040309906005859375,
    },
    {
      x: 18464.67391304348,
      y: 0.045291900634765625,
    },
    {
      x: 18468.75,
      y: 0.045291900634765625,
    },
    {
      x: 18485.054347826088,
      y: 0.045291900634765625,
    },
    {
      x: 18489.130434782608,
      y: 0.050273895263671875,
    },
    {
      x: 18493.206521739132,
      y: 0.050273895263671875,
    },
    {
      x: 18517.66304347826,
      y: 0.050273895263671875,
    },
    {
      x: 18521.739130434784,
      y: 0.15492630004882812,
    },
    {
      x: 18525.815217391304,
      y: 0.15492630004882812,
    },
    {
      x: 18745.92391304348,
      y: 0.15492630004882812,
    },
    {
      x: 18750,
      y: 0.16489410400390625,
    },
    {
      x: 18754.076086956524,
      y: 0.19479751586914062,
    },
    {
      x: 18758.152173913044,
      y: 0.22469711303710938,
    },
    {
      x: 18762.228260869564,
      y: 0.2595863342285156,
    },
    {
      x: 18766.304347826088,
      y: 0.2944679260253906,
    },
    {
      x: 18770.380434782608,
      y: 0.3293495178222656,
    },
    {
      x: 18774.456521739132,
      y: 0.3642387390136719,
    },
    {
      x: 18778.532608695652,
      y: 0.3991203308105469,
    },
    {
      x: 18782.608695652176,
      y: 0.22469711303710938,
    },
    {
      x: 18786.684782608696,
      y: 0.2595863342285156,
    },
    {
      x: 18790.760869565216,
      y: 0.2944679260253906,
    },
    {
      x: 18794.83695652174,
      y: 0.3293495178222656,
    },
    {
      x: 18798.91304347826,
      y: 0.3642387390136719,
    },
    {
      x: 18802.989130434784,
      y: 0.3991203308105469,
    },
    {
      x: 18807.065217391304,
      y: 0.4340057373046875,
    },
    {
      x: 18811.141304347828,
      y: 0.46390533447265625,
    },
    {
      x: 18815.217391304348,
      y: 0.4788551330566406,
    },
    {
      x: 18819.29347826087,
      y: 0.4489555358886719,
    },
    {
      x: 18823.369565217392,
      y: 0.409088134765625,
    },
    {
      x: 18827.445652173912,
      y: 0.3592529296875,
    },
    {
      x: 18831.521739130436,
      y: 0.309417724609375,
    },
    {
      x: 18835.597826086956,
      y: 0.2595863342285156,
    },
    {
      x: 18839.67391304348,
      y: 0.21473312377929688,
    },
    {
      x: 18843.75,
      y: 0.159912109375,
    },
    {
      x: 18847.826086956524,
      y: 0.10509490966796875,
    },
    {
      x: 18851.902173913044,
      y: 0.06024169921875,
    },
    {
      x: 18855.978260869564,
      y: 0.020374298095703125,
    },
    {
      x: 18860.054347826088,
      y: 0.00542449951171875,
    },
    {
      x: 18864.130434782608,
      y: 0.0004425048828125,
    },
    {
      x: 18868.206521739132,
      y: 0.0004425048828125,
    },
    {
      x: 18876.358695652176,
      y: 0.0004425048828125,
    },
    {
      x: 18880.434782608696,
      y: 0.00542449951171875,
    },
    {
      x: 18884.510869565216,
      y: 0.00542449951171875,
    },
    {
      x: 18892.66304347826,
      y: 0.00542449951171875,
    },
    {
      x: 18896.739130434784,
      y: 0.010406494140625,
    },
    {
      x: 18900.815217391304,
      y: 0.010406494140625,
    },
    {
      x: 18908.967391304348,
      y: 0.010406494140625,
    },
    {
      x: 18913.04347826087,
      y: 0.015392303466796875,
    },
    {
      x: 18917.119565217392,
      y: 0.015392303466796875,
    },
    {
      x: 18925.271739130436,
      y: 0.015392303466796875,
    },
    {
      x: 18929.347826086956,
      y: 0.020374298095703125,
    },
    {
      x: 18933.42391304348,
      y: 0.020374298095703125,
    },
    {
      x: 18941.576086956524,
      y: 0.020374298095703125,
    },
    {
      x: 18945.652173913044,
      y: 0.025356292724609375,
    },
    {
      x: 18949.728260869564,
      y: 0.025356292724609375,
    },
    {
      x: 18957.880434782608,
      y: 0.025356292724609375,
    },
    {
      x: 18961.956521739132,
      y: 0.03034210205078125,
    },
    {
      x: 18966.032608695652,
      y: 0.03034210205078125,
    },
    {
      x: 18974.184782608696,
      y: 0.03034210205078125,
    },
    {
      x: 18978.260869565216,
      y: 0.0353240966796875,
    },
    {
      x: 18982.33695652174,
      y: 0.0353240966796875,
    },
    {
      x: 18990.489130434784,
      y: 0.0353240966796875,
    },
    {
      x: 18994.565217391304,
      y: 0.040309906005859375,
    },
    {
      x: 18998.641304347828,
      y: 0.040309906005859375,
    },
    {
      x: 19006.79347826087,
      y: 0.040309906005859375,
    },
    {
      x: 19010.869565217392,
      y: 0.045291900634765625,
    },
    {
      x: 19014.945652173912,
      y: 0.045291900634765625,
    },
    {
      x: 19031.25,
      y: 0.045291900634765625,
    },
    {
      x: 19035.326086956524,
      y: 0.050273895263671875,
    },
    {
      x: 19039.402173913044,
      y: 0.050273895263671875,
    },
    {
      x: 19043.478260869564,
      y: 0.159912109375,
    },
    {
      x: 19047.554347826088,
      y: 0.159912109375,
    },
    {
      x: 19072.010869565216,
      y: 0.159912109375,
    },
    {
      x: 19076.08695652174,
      y: 0.15492630004882812,
    },
    {
      x: 19080.16304347826,
      y: 0.15492630004882812,
    },
    {
      x: 19300.271739130436,
      y: 0.15492630004882812,
    },
    {
      x: 19304.347826086956,
      y: 0.050273895263671875,
    },
    {
      x: 19308.42391304348,
      y: 0.050273895263671875,
    },
    {
      x: 19324.728260869564,
      y: 0.050273895263671875,
    },
    {
      x: 19328.804347826088,
      y: 0.05525970458984375,
    },
    {
      x: 19332.880434782608,
      y: 0.05525970458984375,
    },
    {
      x: 19336.956521739132,
      y: 0.05525970458984375,
    },
    {
      x: 19341.032608695652,
      y: 0.06522369384765625,
    },
    {
      x: 19345.108695652176,
      y: 0.09512710571289062,
    },
    {
      x: 19349.184782608696,
      y: 0.1349945068359375,
    },
    {
      x: 19353.260869565216,
      y: 0.16489410400390625,
    },
    {
      x: 19357.33695652174,
      y: 0.1698760986328125,
    },
    {
      x: 19361.41304347826,
      y: 0.1698760986328125,
    },
    {
      x: 19365.489130434784,
      y: 0.16489410400390625,
    },
    {
      x: 19369.565217391304,
      y: 0.159912109375,
    },
    {
      x: 19373.641304347828,
      y: 0.15492630004882812,
    },
    {
      x: 19377.717391304348,
      y: 0.14994430541992188,
    },
    {
      x: 19381.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 19385.869565217392,
      y: 0.13997650146484375,
    },
    {
      x: 19389.945652173912,
      y: 0.1349945068359375,
    },
    {
      x: 19394.021739130436,
      y: 0.13001251220703125,
    },
    {
      x: 19398.097826086956,
      y: 0.12004470825195312,
    },
    {
      x: 19402.17391304348,
      y: 0.11506271362304688,
    },
    {
      x: 19406.25,
      y: 0.110076904296875,
    },
    {
      x: 19410.326086956524,
      y: 0.10509490966796875,
    },
    {
      x: 19414.402173913044,
      y: 0.10509490966796875,
    },
    {
      x: 19418.478260869564,
      y: 0.09512710571289062,
    },
    {
      x: 19422.554347826088,
      y: 0.09014511108398438,
    },
    {
      x: 19426.630434782608,
      y: 0.09014511108398438,
    },
    {
      x: 19430.706521739132,
      y: 0.09512710571289062,
    },
    {
      x: 19434.782608695652,
      y: 0.10010910034179688,
    },
    {
      x: 19438.858695652176,
      y: 0.10010910034179688,
    },
    {
      x: 19442.934782608696,
      y: 0.10509490966796875,
    },
    {
      x: 19447.010869565216,
      y: 0.110076904296875,
    },
    {
      x: 19451.08695652174,
      y: 0.11506271362304688,
    },
    {
      x: 19455.16304347826,
      y: 0.12004470825195312,
    },
    {
      x: 19459.239130434784,
      y: 0.13001251220703125,
    },
    {
      x: 19463.315217391304,
      y: 0.1349945068359375,
    },
    {
      x: 19467.391304347828,
      y: 0.13997650146484375,
    },
    {
      x: 19471.467391304348,
      y: 0.14496231079101562,
    },
    {
      x: 19475.54347826087,
      y: 0.14994430541992188,
    },
    {
      x: 19479.619565217392,
      y: 0.15492630004882812,
    },
    {
      x: 19483.695652173912,
      y: 0.159912109375,
    },
    {
      x: 19487.771739130436,
      y: 0.16489410400390625,
    },
    {
      x: 19491.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 19495.92391304348,
      y: 0.1698760986328125,
    },
    {
      x: 19524.456521739132,
      y: 0.1698760986328125,
    },
    {
      x: 19528.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 19532.608695652176,
      y: 0.16489410400390625,
    },
    {
      x: 19561.141304347828,
      y: 0.16489410400390625,
    },
    {
      x: 19565.217391304348,
      y: 0.15492630004882812,
    },
    {
      x: 19569.29347826087,
      y: 0.15492630004882812,
    },
    {
      x: 19813.858695652176,
      y: 0.15492630004882812,
    },
    {
      x: 19817.934782608696,
      y: 0.16489410400390625,
    },
    {
      x: 19822.01086956522,
      y: 0.19479751586914062,
    },
    {
      x: 19826.08695652174,
      y: 0.15492630004882812,
    },
    {
      x: 19830.16304347826,
      y: 0.15492630004882812,
    },
    {
      x: 20082.880434782608,
      y: 0.15492630004882812,
    },
    {
      x: 20086.956521739132,
      y: 0.16489410400390625,
    },
    {
      x: 20091.032608695652,
      y: 0.16489410400390625,
    },
    {
      x: 20107.33695652174,
      y: 0.16489410400390625,
    },
    {
      x: 20111.41304347826,
      y: 0.159912109375,
    },
    {
      x: 20115.489130434784,
      y: 0.159912109375,
    },
    {
      x: 20139.945652173912,
      y: 0.159912109375,
    },
    {
      x: 20144.021739130436,
      y: 0.15492630004882812,
    },
    {
      x: 20148.097826086956,
      y: 0.15492630004882812,
    },
    {
      x: 20360.054347826088,
      y: 0.15492630004882812,
    },
    {
      x: 20364.130434782608,
      y: 0.16489410400390625,
    },
    {
      x: 20368.206521739132,
      y: 0.19479751586914062,
    },
    {
      x: 20372.282608695652,
      y: 0.22469711303710938,
    },
    {
      x: 20376.358695652176,
      y: 0.2595863342285156,
    },
    {
      x: 20380.434782608696,
      y: 0.2944679260253906,
    },
    {
      x: 20384.51086956522,
      y: 0.3293495178222656,
    },
    {
      x: 20388.58695652174,
      y: 0.3642387390136719,
    },
    {
      x: 20392.66304347826,
      y: 0.3991203308105469,
    },
    {
      x: 20396.739130434784,
      y: 0.4340057373046875,
    },
    {
      x: 20400.815217391304,
      y: 0.46390533447265625,
    },
    {
      x: 20404.891304347828,
      y: 0.4788551330566406,
    },
    {
      x: 20408.967391304348,
      y: 0.4489555358886719,
    },
    {
      x: 20413.04347826087,
      y: 0.409088134765625,
    },
    {
      x: 20417.119565217392,
      y: 0.3592529296875,
    },
    {
      x: 20421.195652173912,
      y: 0.309417724609375,
    },
    {
      x: 20425.271739130436,
      y: 0.2595863342285156,
    },
    {
      x: 20429.347826086956,
      y: 0.21473312377929688,
    },
    {
      x: 20433.42391304348,
      y: 0.159912109375,
    },
    {
      x: 20437.5,
      y: 0.10509490966796875,
    },
    {
      x: 20441.576086956524,
      y: 0.06024169921875,
    },
    {
      x: 20445.652173913044,
      y: 0.020374298095703125,
    },
    {
      x: 20449.728260869564,
      y: 0.00542449951171875,
    },
    {
      x: 20453.804347826088,
      y: 0.0004425048828125,
    },
    {
      x: 20457.880434782608,
      y: 0.0004425048828125,
    },
    {
      x: 20466.032608695652,
      y: 0.0004425048828125,
    },
    {
      x: 20470.108695652176,
      y: 0.00542449951171875,
    },
    {
      x: 20474.184782608696,
      y: 0.00542449951171875,
    },
    {
      x: 20482.33695652174,
      y: 0.00542449951171875,
    },
    {
      x: 20486.41304347826,
      y: 0.010406494140625,
    },
    {
      x: 20490.489130434784,
      y: 0.010406494140625,
    },
    {
      x: 20498.641304347828,
      y: 0.010406494140625,
    },
    {
      x: 20502.717391304348,
      y: 0.015392303466796875,
    },
    {
      x: 20506.79347826087,
      y: 0.015392303466796875,
    },
    {
      x: 20514.945652173912,
      y: 0.015392303466796875,
    },
    {
      x: 20519.021739130436,
      y: 0.020374298095703125,
    },
    {
      x: 20523.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 20531.25,
      y: 0.020374298095703125,
    },
    {
      x: 20535.326086956524,
      y: 0.025356292724609375,
    },
    {
      x: 20539.402173913044,
      y: 0.025356292724609375,
    },
    {
      x: 20547.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 20551.630434782608,
      y: 0.03034210205078125,
    },
    {
      x: 20555.706521739132,
      y: 0.03034210205078125,
    },
    {
      x: 20563.858695652176,
      y: 0.03034210205078125,
    },
    {
      x: 20567.934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 20572.01086956522,
      y: 0.0353240966796875,
    },
    {
      x: 20580.16304347826,
      y: 0.0353240966796875,
    },
    {
      x: 20584.239130434784,
      y: 0.040309906005859375,
    },
    {
      x: 20588.315217391304,
      y: 0.040309906005859375,
    },
    {
      x: 20596.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 20600.54347826087,
      y: 0.045291900634765625,
    },
    {
      x: 20604.619565217392,
      y: 0.045291900634765625,
    },
    {
      x: 20620.92391304348,
      y: 0.045291900634765625,
    },
    {
      x: 20625,
      y: 0.050273895263671875,
    },
    {
      x: 20629.076086956524,
      y: 0.050273895263671875,
    },
    {
      x: 20653.532608695652,
      y: 0.050273895263671875,
    },
    {
      x: 20657.608695652176,
      y: 0.05525970458984375,
    },
    {
      x: 20661.684782608696,
      y: 0.05525970458984375,
    },
    {
      x: 20665.76086956522,
      y: 0.05525970458984375,
    },
    {
      x: 20669.83695652174,
      y: 0.06522369384765625,
    },
    {
      x: 20673.91304347826,
      y: 0.09512710571289062,
    },
    {
      x: 20677.989130434784,
      y: 0.1349945068359375,
    },
    {
      x: 20682.065217391304,
      y: 0.16489410400390625,
    },
    {
      x: 20686.141304347828,
      y: 0.1698760986328125,
    },
    {
      x: 20690.217391304348,
      y: 0.1698760986328125,
    },
    {
      x: 20694.29347826087,
      y: 0.16489410400390625,
    },
    {
      x: 20698.369565217392,
      y: 0.159912109375,
    },
    {
      x: 20702.445652173912,
      y: 0.15492630004882812,
    },
    {
      x: 20706.521739130436,
      y: 0.14994430541992188,
    },
    {
      x: 20710.597826086956,
      y: 0.14496231079101562,
    },
    {
      x: 20714.67391304348,
      y: 0.13997650146484375,
    },
    {
      x: 20718.75,
      y: 0.1349945068359375,
    },
    {
      x: 20722.826086956524,
      y: 0.13001251220703125,
    },
    {
      x: 20726.902173913044,
      y: 0.12004470825195312,
    },
    {
      x: 20730.978260869564,
      y: 0.11506271362304688,
    },
    {
      x: 20735.054347826088,
      y: 0.110076904296875,
    },
    {
      x: 20739.130434782608,
      y: 0.10509490966796875,
    },
    {
      x: 20743.206521739132,
      y: 0.10010910034179688,
    },
    {
      x: 20747.282608695652,
      y: 0.09512710571289062,
    },
    {
      x: 20751.358695652176,
      y: 0.09014511108398438,
    },
    {
      x: 20755.434782608696,
      y: 0.09014511108398438,
    },
    {
      x: 20759.51086956522,
      y: 0.09512710571289062,
    },
    {
      x: 20763.58695652174,
      y: 0.10010910034179688,
    },
    {
      x: 20767.66304347826,
      y: 0.10010910034179688,
    },
    {
      x: 20771.739130434784,
      y: 0.10509490966796875,
    },
    {
      x: 20775.815217391304,
      y: 0.110076904296875,
    },
    {
      x: 20779.891304347828,
      y: 0.11506271362304688,
    },
    {
      x: 20783.967391304348,
      y: 0.12004470825195312,
    },
    {
      x: 20788.04347826087,
      y: 0.13001251220703125,
    },
    {
      x: 20792.119565217392,
      y: 0.1349945068359375,
    },
    {
      x: 20796.195652173912,
      y: 0.13997650146484375,
    },
    {
      x: 20800.271739130436,
      y: 0.14496231079101562,
    },
    {
      x: 20804.347826086956,
      y: 0.14994430541992188,
    },
    {
      x: 20808.42391304348,
      y: 0.15492630004882812,
    },
    {
      x: 20812.5,
      y: 0.159912109375,
    },
    {
      x: 20816.576086956524,
      y: 0.16489410400390625,
    },
    {
      x: 20820.652173913044,
      y: 0.1698760986328125,
    },
    {
      x: 20824.728260869564,
      y: 0.1698760986328125,
    },
    {
      x: 20853.26086956522,
      y: 0.1698760986328125,
    },
    {
      x: 20857.33695652174,
      y: 0.16489410400390625,
    },
    {
      x: 20861.41304347826,
      y: 0.16489410400390625,
    },
    {
      x: 20865.489130434784,
      y: 0.16489410400390625,
    },
    {
      x: 20869.565217391304,
      y: 0.15492630004882812,
    },
    {
      x: 20873.641304347828,
      y: 0.15492630004882812,
    },
    {
      x: 21126.358695652176,
      y: 0.15492630004882812,
    },
    {
      x: 21130.434782608696,
      y: 0.040309906005859375,
    },
    {
      x: 21134.51086956522,
      y: 0.040309906005859375,
    },
    {
      x: 21142.66304347826,
      y: 0.040309906005859375,
    },
    {
      x: 21146.739130434784,
      y: 0.045291900634765625,
    },
    {
      x: 21150.815217391304,
      y: 0.045291900634765625,
    },
    {
      x: 21167.119565217392,
      y: 0.045291900634765625,
    },
    {
      x: 21171.195652173912,
      y: 0.050273895263671875,
    },
    {
      x: 21175.271739130436,
      y: 0.050273895263671875,
    },
    {
      x: 21199.728260869564,
      y: 0.050273895263671875,
    },
    {
      x: 21203.804347826088,
      y: 0.05525970458984375,
    },
    {
      x: 21207.880434782608,
      y: 0.05525970458984375,
    },
    {
      x: 21211.956521739132,
      y: 0.05525970458984375,
    },
    {
      x: 21216.032608695652,
      y: 0.06522369384765625,
    },
    {
      x: 21220.108695652176,
      y: 0.09512710571289062,
    },
    {
      x: 21224.184782608696,
      y: 0.1349945068359375,
    },
    {
      x: 21228.26086956522,
      y: 0.16489410400390625,
    },
    {
      x: 21232.33695652174,
      y: 0.1698760986328125,
    },
    {
      x: 21236.41304347826,
      y: 0.1698760986328125,
    },
    {
      x: 21240.489130434784,
      y: 0.16489410400390625,
    },
    {
      x: 21244.565217391304,
      y: 0.159912109375,
    },
    {
      x: 21248.641304347828,
      y: 0.15492630004882812,
    },
    {
      x: 21252.717391304348,
      y: 0.14994430541992188,
    },
    {
      x: 21256.79347826087,
      y: 0.14496231079101562,
    },
    {
      x: 21260.869565217392,
      y: 0.13997650146484375,
    },
    {
      x: 21264.945652173912,
      y: 0.1349945068359375,
    },
    {
      x: 21269.021739130436,
      y: 0.13001251220703125,
    },
    {
      x: 21273.097826086956,
      y: 0.12004470825195312,
    },
    {
      x: 21277.17391304348,
      y: 0.11506271362304688,
    },
    {
      x: 21281.25,
      y: 0.110076904296875,
    },
    {
      x: 21285.326086956524,
      y: 0.10509490966796875,
    },
    {
      x: 21289.402173913044,
      y: 0.10010910034179688,
    },
    {
      x: 21293.478260869564,
      y: 0.09512710571289062,
    },
    {
      x: 21297.554347826088,
      y: 0.09014511108398438,
    },
    {
      x: 21301.630434782608,
      y: 0.09014511108398438,
    },
    {
      x: 21305.706521739132,
      y: 0.09512710571289062,
    },
    {
      x: 21309.782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 21313.858695652176,
      y: 0.10010910034179688,
    },
    {
      x: 21317.934782608696,
      y: 0.10509490966796875,
    },
    {
      x: 21322.01086956522,
      y: 0.110076904296875,
    },
    {
      x: 21326.08695652174,
      y: 0.11506271362304688,
    },
    {
      x: 21330.16304347826,
      y: 0.12004470825195312,
    },
    {
      x: 21334.239130434784,
      y: 0.13001251220703125,
    },
    {
      x: 21338.315217391304,
      y: 0.1349945068359375,
    },
    {
      x: 21342.391304347828,
      y: 0.13997650146484375,
    },
    {
      x: 21346.467391304348,
      y: 0.14496231079101562,
    },
    {
      x: 21350.54347826087,
      y: 0.14994430541992188,
    },
    {
      x: 21354.619565217392,
      y: 0.15492630004882812,
    },
    {
      x: 21358.695652173912,
      y: 0.159912109375,
    },
    {
      x: 21362.771739130436,
      y: 0.16489410400390625,
    },
    {
      x: 21366.847826086956,
      y: 0.1698760986328125,
    },
    {
      x: 21370.92391304348,
      y: 0.1698760986328125,
    },
    {
      x: 21399.456521739132,
      y: 0.1698760986328125,
    },
    {
      x: 21403.532608695652,
      y: 0.16489410400390625,
    },
    {
      x: 21407.608695652176,
      y: 0.16489410400390625,
    },
    {
      x: 21436.141304347828,
      y: 0.16489410400390625,
    },
    {
      x: 21440.217391304348,
      y: 0.159912109375,
    },
    {
      x: 21444.29347826087,
      y: 0.159912109375,
    },
    {
      x: 21468.75,
      y: 0.159912109375,
    },
    {
      x: 21472.826086956524,
      y: 0.15492630004882812,
    },
    {
      x: 21476.902173913044,
      y: 0.15492630004882812,
    },
    {
      x: 21688.858695652176,
      y: 0.15492630004882812,
    },
    {
      x: 21692.934782608696,
      y: 0.16489410400390625,
    },
    {
      x: 21697.01086956522,
      y: 0.19479751586914062,
    },
    {
      x: 21701.08695652174,
      y: 0.22469711303710938,
    },
    {
      x: 21705.16304347826,
      y: 0.2595863342285156,
    },
    {
      x: 21709.239130434784,
      y: 0.2944679260253906,
    },
    {
      x: 21713.315217391304,
      y: 0.3293495178222656,
    },
    {
      x: 21717.391304347828,
      y: 0.3642387390136719,
    },
    {
      x: 21721.467391304348,
      y: 0.3991203308105469,
    },
    {
      x: 21725.54347826087,
      y: 0.4340057373046875,
    },
    {
      x: 21729.619565217392,
      y: 0.46390533447265625,
    },
    {
      x: 21733.695652173912,
      y: 0.4788551330566406,
    },
    {
      x: 21737.771739130436,
      y: 0.4489555358886719,
    },
    {
      x: 21741.847826086956,
      y: 0.409088134765625,
    },
    {
      x: 21745.92391304348,
      y: 0.3592529296875,
    },
    {
      x: 21750,
      y: 0.309417724609375,
    },
    {
      x: 21754.076086956524,
      y: 0.2595863342285156,
    },
    {
      x: 21758.152173913044,
      y: 0.21473312377929688,
    },
    {
      x: 21762.228260869564,
      y: 0.159912109375,
    },
    {
      x: 21766.304347826088,
      y: 0.10509490966796875,
    },
    {
      x: 21770.380434782608,
      y: 0.06024169921875,
    },
    {
      x: 21774.456521739132,
      y: 0.020374298095703125,
    },
    {
      x: 21778.532608695652,
      y: 0.00542449951171875,
    },
    {
      x: 21782.608695652176,
      y: 0.0004425048828125,
    },
    {
      x: 21786.684782608696,
      y: 0.0004425048828125,
    },
    {
      x: 21794.83695652174,
      y: 0.0004425048828125,
    },
    {
      x: 21798.91304347826,
      y: 0.00542449951171875,
    },
    {
      x: 21802.989130434784,
      y: 0.00542449951171875,
    },
    {
      x: 21811.141304347828,
      y: 0.00542449951171875,
    },
    {
      x: 21815.217391304348,
      y: 0.010406494140625,
    },
    {
      x: 21819.29347826087,
      y: 0.010406494140625,
    },
    {
      x: 21827.445652173912,
      y: 0.010406494140625,
    },
    {
      x: 21831.521739130436,
      y: 0.015392303466796875,
    },
    {
      x: 21835.597826086956,
      y: 0.015392303466796875,
    },
    {
      x: 21843.75,
      y: 0.015392303466796875,
    },
    {
      x: 21847.826086956524,
      y: 0.020374298095703125,
    },
    {
      x: 21851.902173913044,
      y: 0.020374298095703125,
    },
    {
      x: 21860.054347826088,
      y: 0.020374298095703125,
    },
    {
      x: 21864.130434782608,
      y: 0.025356292724609375,
    },
    {
      x: 21868.206521739132,
      y: 0.025356292724609375,
    },
    {
      x: 21876.358695652176,
      y: 0.025356292724609375,
    },
    {
      x: 21880.434782608696,
      y: 0.03034210205078125,
    },
    {
      x: 21884.51086956522,
      y: 0.03034210205078125,
    },
    {
      x: 21892.66304347826,
      y: 0.03034210205078125,
    },
    {
      x: 21896.739130434784,
      y: 0.0353240966796875,
    },
    {
      x: 21900.815217391304,
      y: 0.0353240966796875,
    },
    {
      x: 21908.967391304348,
      y: 0.0353240966796875,
    },
    {
      x: 21913.04347826087,
      y: 0.1698760986328125,
    },
    {
      x: 21917.119565217392,
      y: 0.1698760986328125,
    },
    {
      x: 21945.652173913044,
      y: 0.1698760986328125,
    },
    {
      x: 21949.728260869564,
      y: 0.16489410400390625,
    },
    {
      x: 21953.804347826088,
      y: 0.16489410400390625,
    },
    {
      x: 21982.33695652174,
      y: 0.16489410400390625,
    },
    {
      x: 21986.41304347826,
      y: 0.159912109375,
    },
    {
      x: 21990.489130434784,
      y: 0.159912109375,
    },
    {
      x: 22014.945652173912,
      y: 0.159912109375,
    },
    {
      x: 22019.021739130436,
      y: 0.15492630004882812,
    },
    {
      x: 22023.097826086956,
      y: 0.15492630004882812,
    },
    {
      x: 22169.83695652174,
      y: 0.15492630004882812,
    },
    {
      x: 22173.91304347826,
      y: 0.03034210205078125,
    },
    {
      x: 22177.989130434784,
      y: 0.03034210205078125,
    },
    {
      x: 22182.065217391304,
      y: 0.0353240966796875,
    },
    {
      x: 22186.141304347828,
      y: 0.0353240966796875,
    },
    {
      x: 22194.29347826087,
      y: 0.0353240966796875,
    },
    {
      x: 22198.369565217392,
      y: 0.040309906005859375,
    },
    {
      x: 22202.445652173912,
      y: 0.040309906005859375,
    },
    {
      x: 22210.597826086956,
      y: 0.040309906005859375,
    },
    {
      x: 22214.67391304348,
      y: 0.045291900634765625,
    },
    {
      x: 22218.75,
      y: 0.045291900634765625,
    },
    {
      x: 22235.054347826088,
      y: 0.045291900634765625,
    },
    {
      x: 22239.130434782608,
      y: 0.050273895263671875,
    },
    {
      x: 22243.206521739132,
      y: 0.050273895263671875,
    },
    {
      x: 22267.66304347826,
      y: 0.050273895263671875,
    },
    {
      x: 22271.739130434784,
      y: 0.05525970458984375,
    },
    {
      x: 22275.815217391304,
      y: 0.05525970458984375,
    },
    {
      x: 22279.891304347828,
      y: 0.05525970458984375,
    },
    {
      x: 22283.967391304348,
      y: 0.06522369384765625,
    },
    {
      x: 22288.04347826087,
      y: 0.09512710571289062,
    },
    {
      x: 22292.119565217392,
      y: 0.1349945068359375,
    },
    {
      x: 22296.195652173912,
      y: 0.16489410400390625,
    },
    {
      x: 22300.271739130436,
      y: 0.1698760986328125,
    },
    {
      x: 22304.347826086956,
      y: 0.1698760986328125,
    },
    {
      x: 22308.42391304348,
      y: 0.16489410400390625,
    },
    {
      x: 22312.5,
      y: 0.159912109375,
    },
    {
      x: 22316.576086956524,
      y: 0.15492630004882812,
    },
    {
      x: 22320.652173913044,
      y: 0.14994430541992188,
    },
    {
      x: 22324.728260869564,
      y: 0.14496231079101562,
    },
    {
      x: 22328.804347826088,
      y: 0.13997650146484375,
    },
    {
      x: 22332.880434782608,
      y: 0.1349945068359375,
    },
    {
      x: 22336.956521739132,
      y: 0.13001251220703125,
    },
    {
      x: 22341.032608695652,
      y: 0.12004470825195312,
    },
    {
      x: 22345.108695652176,
      y: 0.11506271362304688,
    },
    {
      x: 22349.184782608696,
      y: 0.110076904296875,
    },
    {
      x: 22353.26086956522,
      y: 0.10509490966796875,
    },
    {
      x: 22357.33695652174,
      y: 0.10010910034179688,
    },
    {
      x: 22361.41304347826,
      y: 0.09512710571289062,
    },
    {
      x: 22365.489130434784,
      y: 0.09014511108398438,
    },
    {
      x: 22369.565217391304,
      y: 0.09014511108398438,
    },
    {
      x: 22373.641304347828,
      y: 0.09512710571289062,
    },
    {
      x: 22377.717391304348,
      y: 0.09512710571289062,
    },
    {
      x: 22381.79347826087,
      y: 0.10010910034179688,
    },
    {
      x: 22385.869565217392,
      y: 0.10509490966796875,
    },
    {
      x: 22389.945652173912,
      y: 0.110076904296875,
    },
    {
      x: 22394.021739130436,
      y: 0.11506271362304688,
    },
    {
      x: 22398.097826086956,
      y: 0.12004470825195312,
    },
    {
      x: 22402.17391304348,
      y: 0.13001251220703125,
    },
    {
      x: 22406.25,
      y: 0.1349945068359375,
    },
    {
      x: 22410.326086956524,
      y: 0.13997650146484375,
    },
    {
      x: 22414.402173913044,
      y: 0.14496231079101562,
    },
    {
      x: 22418.478260869564,
      y: 0.14994430541992188,
    },
    {
      x: 22422.554347826088,
      y: 0.15492630004882812,
    },
    {
      x: 22426.630434782608,
      y: 0.159912109375,
    },
    {
      x: 22430.706521739132,
      y: 0.16489410400390625,
    },
    {
      x: 22434.782608695652,
      y: 0.15492630004882812,
    },
    {
      x: 22438.858695652176,
      y: 0.15492630004882812,
    },
    {
      x: 22495.92391304348,
      y: 0.15492630004882812,
    },
    {
      x: 22500,
      y: 0.16489410400390625,
    },
    {
      x: 22504.076086956524,
      y: 0.19479751586914062,
    },
    {
      x: 22508.152173913044,
      y: 0.22469711303710938,
    },
    {
      x: 22512.228260869564,
      y: 0.2595863342285156,
    },
    {
      x: 22516.304347826088,
      y: 0.2944679260253906,
    },
    {
      x: 22520.380434782608,
      y: 0.3293495178222656,
    },
    {
      x: 22524.456521739132,
      y: 0.3642387390136719,
    },
    {
      x: 22528.532608695652,
      y: 0.3991203308105469,
    },
    {
      x: 22532.608695652176,
      y: 0.4340057373046875,
    },
    {
      x: 22536.684782608696,
      y: 0.46390533447265625,
    },
    {
      x: 22540.76086956522,
      y: 0.4788551330566406,
    },
    {
      x: 22544.83695652174,
      y: 0.4489555358886719,
    },
    {
      x: 22548.91304347826,
      y: 0.409088134765625,
    },
    {
      x: 22552.989130434784,
      y: 0.3592529296875,
    },
    {
      x: 22557.065217391304,
      y: 0.309417724609375,
    },
    {
      x: 22561.141304347828,
      y: 0.2595863342285156,
    },
    {
      x: 22565.217391304348,
      y: 0.21473312377929688,
    },
    {
      x: 22569.29347826087,
      y: 0.159912109375,
    },
    {
      x: 22573.369565217392,
      y: 0.10509490966796875,
    },
    {
      x: 22577.445652173912,
      y: 0.06024169921875,
    },
    {
      x: 22581.521739130436,
      y: 0.020374298095703125,
    },
    {
      x: 22585.597826086956,
      y: 0.00542449951171875,
    },
    {
      x: 22589.67391304348,
      y: 0.0004425048828125,
    },
    {
      x: 22593.75,
      y: 0.0004425048828125,
    },
    {
      x: 22601.902173913044,
      y: 0.0004425048828125,
    },
    {
      x: 22605.978260869564,
      y: 0.00542449951171875,
    },
    {
      x: 22610.054347826088,
      y: 0.00542449951171875,
    },
    {
      x: 22618.206521739132,
      y: 0.00542449951171875,
    },
    {
      x: 22622.282608695652,
      y: 0.010406494140625,
    },
    {
      x: 22626.358695652176,
      y: 0.010406494140625,
    },
    {
      x: 22634.51086956522,
      y: 0.010406494140625,
    },
    {
      x: 22638.58695652174,
      y: 0.015392303466796875,
    },
    {
      x: 22642.66304347826,
      y: 0.015392303466796875,
    },
    {
      x: 22650.815217391304,
      y: 0.015392303466796875,
    },
    {
      x: 22654.891304347828,
      y: 0.020374298095703125,
    },
    {
      x: 22658.967391304348,
      y: 0.020374298095703125,
    },
    {
      x: 22667.119565217392,
      y: 0.020374298095703125,
    },
    {
      x: 22671.195652173912,
      y: 0.025356292724609375,
    },
    {
      x: 22675.271739130436,
      y: 0.025356292724609375,
    },
    {
      x: 22683.42391304348,
      y: 0.025356292724609375,
    },
    {
      x: 22687.5,
      y: 0.03034210205078125,
    },
    {
      x: 22691.576086956524,
      y: 0.03034210205078125,
    },
    {
      x: 22695.652173913044,
      y: 0.15492630004882812,
    },
    {
      x: 22699.728260869564,
      y: 0.15492630004882812,
    },
    {
      x: 22952.445652173912,
      y: 0.15492630004882812,
    },
    {
      x: 22956.521739130436,
      y: 0.13997650146484375,
    },
    {
      x: 22960.597826086956,
      y: 0.14496231079101562,
    },
    {
      x: 22964.67391304348,
      y: 0.14994430541992188,
    },
    {
      x: 22968.75,
      y: 0.15492630004882812,
    },
    {
      x: 22972.826086956524,
      y: 0.159912109375,
    },
    {
      x: 22976.902173913044,
      y: 0.16489410400390625,
    },
    {
      x: 22980.978260869564,
      y: 0.1698760986328125,
    },
    {
      x: 22985.054347826088,
      y: 0.1698760986328125,
    },
    {
      x: 23013.58695652174,
      y: 0.1698760986328125,
    },
    {
      x: 23017.66304347826,
      y: 0.16489410400390625,
    },
    {
      x: 23021.739130434784,
      y: 0.16489410400390625,
    },
    {
      x: 23050.271739130436,
      y: 0.16489410400390625,
    },
    {
      x: 23054.347826086956,
      y: 0.159912109375,
    },
    {
      x: 23058.42391304348,
      y: 0.159912109375,
    },
    {
      x: 23082.880434782608,
      y: 0.159912109375,
    },
    {
      x: 23086.956521739132,
      y: 0.15492630004882812,
    },
    {
      x: 23091.032608695652,
      y: 0.15492630004882812,
    },
    {
      x: 23213.315217391304,
      y: 0.15492630004882812,
    },
    {
      x: 23217.391304347828,
      y: 0.025356292724609375,
    },
    {
      x: 23221.467391304348,
      y: 0.025356292724609375,
    },
    {
      x: 23229.619565217392,
      y: 0.025356292724609375,
    },
    {
      x: 23233.695652173912,
      y: 0.03034210205078125,
    },
    {
      x: 23237.771739130436,
      y: 0.03034210205078125,
    },
    {
      x: 23245.92391304348,
      y: 0.03034210205078125,
    },
    {
      x: 23250,
      y: 0.0353240966796875,
    },
    {
      x: 23254.076086956524,
      y: 0.0353240966796875,
    },
    {
      x: 23262.228260869564,
      y: 0.0353240966796875,
    },
    {
      x: 23266.304347826088,
      y: 0.040309906005859375,
    },
    {
      x: 23270.380434782608,
      y: 0.040309906005859375,
    },
    {
      x: 23278.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 23282.608695652176,
      y: 0.045291900634765625,
    },
    {
      x: 23286.684782608696,
      y: 0.045291900634765625,
    },
    {
      x: 23302.989130434784,
      y: 0.045291900634765625,
    },
    {
      x: 23307.065217391304,
      y: 0.050273895263671875,
    },
    {
      x: 23311.141304347828,
      y: 0.050273895263671875,
    },
    {
      x: 23335.597826086956,
      y: 0.050273895263671875,
    },
    {
      x: 23339.67391304348,
      y: 0.05525970458984375,
    },
    {
      x: 23343.75,
      y: 0.05525970458984375,
    },
    {
      x: 23347.826086956524,
      y: 0.05525970458984375,
    },
    {
      x: 23351.902173913044,
      y: 0.06522369384765625,
    },
    {
      x: 23355.978260869564,
      y: 0.09512710571289062,
    },
    {
      x: 23360.054347826088,
      y: 0.1349945068359375,
    },
    {
      x: 23364.130434782608,
      y: 0.16489410400390625,
    },
    {
      x: 23368.206521739132,
      y: 0.1698760986328125,
    },
    {
      x: 23372.282608695652,
      y: 0.1698760986328125,
    },
    {
      x: 23376.358695652176,
      y: 0.16489410400390625,
    },
    {
      x: 23380.434782608696,
      y: 0.159912109375,
    },
    {
      x: 23384.51086956522,
      y: 0.15492630004882812,
    },
    {
      x: 23388.58695652174,
      y: 0.14994430541992188,
    },
    {
      x: 23392.66304347826,
      y: 0.14496231079101562,
    },
    {
      x: 23396.739130434784,
      y: 0.13997650146484375,
    },
    {
      x: 23400.815217391304,
      y: 0.1349945068359375,
    },
    {
      x: 23404.891304347828,
      y: 0.13001251220703125,
    },
    {
      x: 23408.967391304348,
      y: 0.12004470825195312,
    },
    {
      x: 23413.04347826087,
      y: 0.11506271362304688,
    },
    {
      x: 23417.119565217392,
      y: 0.110076904296875,
    },
    {
      x: 23421.195652173912,
      y: 0.10509490966796875,
    },
    {
      x: 23425.271739130436,
      y: 0.10509490966796875,
    },
    {
      x: 23429.347826086956,
      y: 0.09512710571289062,
    },
    {
      x: 23433.42391304348,
      y: 0.09014511108398438,
    },
    {
      x: 23437.5,
      y: 0.09014511108398438,
    },
    {
      x: 23441.576086956524,
      y: 0.09512710571289062,
    },
    {
      x: 23445.652173913044,
      y: 0.10010910034179688,
    },
    {
      x: 23449.728260869564,
      y: 0.10010910034179688,
    },
    {
      x: 23453.804347826088,
      y: 0.10509490966796875,
    },
    {
      x: 23457.880434782608,
      y: 0.110076904296875,
    },
    {
      x: 23461.956521739132,
      y: 0.11506271362304688,
    },
    {
      x: 23466.032608695652,
      y: 0.12004470825195312,
    },
    {
      x: 23470.108695652176,
      y: 0.13001251220703125,
    },
    {
      x: 23474.184782608696,
      y: 0.1349945068359375,
    },
    {
      x: 23478.26086956522,
      y: 0.15492630004882812,
    },
    {
      x: 23482.33695652174,
      y: 0.15492630004882812,
    },
    {
      x: 23563.858695652176,
      y: 0.15492630004882812,
    },
    {
      x: 23567.934782608696,
      y: 0.16489410400390625,
    },
    {
      x: 23572.01086956522,
      y: 0.19479751586914062,
    },
    {
      x: 23576.08695652174,
      y: 0.22469711303710938,
    },
    {
      x: 23580.16304347826,
      y: 0.2595863342285156,
    },
    {
      x: 23584.239130434784,
      y: 0.2944679260253906,
    },
    {
      x: 23588.315217391304,
      y: 0.3293495178222656,
    },
    {
      x: 23592.391304347828,
      y: 0.3642387390136719,
    },
    {
      x: 23596.467391304348,
      y: 0.3991203308105469,
    },
    {
      x: 23600.54347826087,
      y: 0.4340057373046875,
    },
    {
      x: 23604.619565217392,
      y: 0.46390533447265625,
    },
    {
      x: 23608.695652173912,
      y: 0.4788551330566406,
    },
    {
      x: 23612.771739130436,
      y: 0.4489555358886719,
    },
    {
      x: 23616.847826086956,
      y: 0.409088134765625,
    },
    {
      x: 23620.92391304348,
      y: 0.3592529296875,
    },
    {
      x: 23625,
      y: 0.309417724609375,
    },
    {
      x: 23629.076086956524,
      y: 0.2595863342285156,
    },
    {
      x: 23633.152173913044,
      y: 0.21473312377929688,
    },
    {
      x: 23637.228260869564,
      y: 0.159912109375,
    },
    {
      x: 23641.304347826088,
      y: 0.10509490966796875,
    },
    {
      x: 23645.380434782608,
      y: 0.06024169921875,
    },
    {
      x: 23649.456521739132,
      y: 0.020374298095703125,
    },
    {
      x: 23653.532608695652,
      y: 0.00542449951171875,
    },
    {
      x: 23657.608695652176,
      y: 0.0004425048828125,
    },
    {
      x: 23661.684782608696,
      y: 0.0004425048828125,
    },
    {
      x: 23669.83695652174,
      y: 0.0004425048828125,
    },
    {
      x: 23673.91304347826,
      y: 0.00542449951171875,
    },
    {
      x: 23677.989130434784,
      y: 0.00542449951171875,
    },
    {
      x: 23686.141304347828,
      y: 0.00542449951171875,
    },
    {
      x: 23690.217391304348,
      y: 0.010406494140625,
    },
    {
      x: 23694.29347826087,
      y: 0.010406494140625,
    },
    {
      x: 23702.445652173912,
      y: 0.010406494140625,
    },
    {
      x: 23706.521739130436,
      y: 0.015392303466796875,
    },
    {
      x: 23710.597826086956,
      y: 0.015392303466796875,
    },
    {
      x: 23718.75,
      y: 0.015392303466796875,
    },
    {
      x: 23722.826086956524,
      y: 0.020374298095703125,
    },
    {
      x: 23726.902173913044,
      y: 0.020374298095703125,
    },
    {
      x: 23735.054347826088,
      y: 0.020374298095703125,
    },
    {
      x: 23739.130434782608,
      y: 0.15492630004882812,
    },
    {
      x: 23743.206521739132,
      y: 0.15492630004882812,
    },
    {
      x: 24000,
      y: 0.15492630004882812,
    },
  ],
  v2: [
    {
      x: 0,
      y: 0.110076904296875,
    },
    {
      x: 541.1931818181819,
      y: 0.110076904296875,
    },
    {
      x: 545.4545454545455,
      y: 0.06024169921875,
    },
    {
      x: 549.7159090909091,
      y: 0.06024169921875,
    },
    {
      x: 553.9772727272727,
      y: 0.06522369384765625,
    },
    {
      x: 558.2386363636364,
      y: 0.06522369384765625,
    },
    {
      x: 583.8068181818182,
      y: 0.06522369384765625,
    },
    {
      x: 588.0681818181819,
      y: 0.07020950317382812,
    },
    {
      x: 592.3295454545455,
      y: 0.07020950317382812,
    },
    {
      x: 617.8977272727274,
      y: 0.07020950317382812,
    },
    {
      x: 622.159090909091,
      y: 0.0751953125,
    },
    {
      x: 626.4204545454546,
      y: 0.0751953125,
    },
    {
      x: 630.6818181818182,
      y: 0.07020950317382812,
    },
    {
      x: 634.9431818181819,
      y: 0.06522369384765625,
    },
    {
      x: 639.2045454545455,
      y: 0.06024169921875,
    },
    {
      x: 643.4659090909091,
      y: 0.05525970458984375,
    },
    {
      x: 647.7272727272727,
      y: 0.045291900634765625,
    },
    {
      x: 651.9886363636364,
      y: 0.0353240966796875,
    },
    {
      x: 656.25,
      y: 0.03034210205078125,
    },
    {
      x: 660.5113636363637,
      y: 0.020374298095703125,
    },
    {
      x: 664.7727272727274,
      y: 0.010406494140625,
    },
    {
      x: 669.034090909091,
      y: 0.0004425048828125,
    },
    {
      x: 673.2954545454546,
      y: -0.004543304443359375,
    },
    {
      x: 677.5568181818182,
      y: -0.0145111083984375,
    },
    {
      x: 681.8181818181819,
      y: -0.01949310302734375,
    },
    {
      x: 686.0795454545455,
      y: -0.024478912353515625,
    },
    {
      x: 690.3409090909091,
      y: -0.034442901611328125,
    },
    {
      x: 694.6022727272727,
      y: -0.04441070556640625,
    },
    {
      x: 698.8636363636364,
      y: -0.049396514892578125,
    },
    {
      x: 703.125,
      y: -0.05936431884765625,
    },
    {
      x: 707.3863636363637,
      y: -0.0643463134765625,
    },
    {
      x: 711.6477272727274,
      y: -0.074310302734375,
    },
    {
      x: 715.909090909091,
      y: -0.06932830810546875,
    },
    {
      x: 720.1704545454546,
      y: -0.05936431884765625,
    },
    {
      x: 724.4318181818182,
      y: -0.049396514892578125,
    },
    {
      x: 728.6931818181819,
      y: -0.0394287109375,
    },
    {
      x: 732.9545454545455,
      y: -0.024478912353515625,
    },
    {
      x: 737.2159090909091,
      y: -0.0145111083984375,
    },
    {
      x: 741.4772727272727,
      y: -0.004543304443359375,
    },
    {
      x: 745.7386363636364,
      y: 0.010406494140625,
    },
    {
      x: 750,
      y: 0.015392303466796875,
    },
    {
      x: 754.2613636363637,
      y: 0.025356292724609375,
    },
    {
      x: 758.5227272727274,
      y: 0.040309906005859375,
    },
    {
      x: 762.784090909091,
      y: 0.050273895263671875,
    },
    {
      x: 767.0454545454546,
      y: 0.06024169921875,
    },
    {
      x: 779.8295454545455,
      y: 0.09014511108398438,
    },
    {
      x: 784.0909090909091,
      y: 0.10010910034179688,
    },
    {
      x: 788.3522727272727,
      y: 0.10509490966796875,
    },
    {
      x: 792.6136363636364,
      y: 0.110076904296875,
    },
    {
      x: 796.8750000000001,
      y: 0.110076904296875,
    },
    {
      x: 848.0113636363637,
      y: 0.110076904296875,
    },
    {
      x: 852.2727272727274,
      y: 0.1349945068359375,
    },
    {
      x: 856.534090909091,
      y: 0.1997833251953125,
    },
    {
      x: 860.7954545454546,
      y: 0.27951812744140625,
    },
    {
      x: 865.0568181818182,
      y: 0.3592529296875,
    },
    {
      x: 869.3181818181819,
      y: 0.4439735412597656,
    },
    {
      x: 873.5795454545455,
      y: 0.523712158203125,
    },
    {
      x: 877.8409090909091,
      y: 0.6034469604492188,
    },
    {
      x: 882.1022727272727,
      y: 0.6831817626953125,
    },
    {
      x: 886.3636363636365,
      y: 0.7629203796386719,
    },
    {
      x: 890.6250000000001,
      y: 0.8426551818847656,
    },
    {
      x: 894.8863636363637,
      y: 0.8775367736816406,
    },
    {
      x: 899.1477272727274,
      y: 0.8376731872558594,
    },
    {
      x: 903.409090909091,
      y: 0.7579345703125,
    },
    {
      x: 907.6704545454546,
      y: 0.6781997680664062,
    },
    {
      x: 911.9318181818182,
      y: 0.5884971618652344,
    },
    {
      x: 916.1931818181819,
      y: 0.4987945556640625,
    },
    {
      x: 920.4545454545455,
      y: 0.409088134765625,
    },
    {
      x: 924.7159090909091,
      y: 0.3193855285644531,
    },
    {
      x: 928.9772727272727,
      y: 0.2346649169921875,
    },
    {
      x: 933.2386363636365,
      y: 0.13997650146484375,
    },
    {
      x: 937.5000000000001,
      y: 0.07020950317382812,
    },
    {
      x: 941.7613636363637,
      y: 0.050273895263671875,
    },
    {
      x: 946.0227272727274,
      y: 0.040309906005859375,
    },
    {
      x: 950.284090909091,
      y: 0.0353240966796875,
    },
    {
      x: 954.5454545454546,
      y: 0.0353240966796875,
    },
    {
      x: 958.8068181818182,
      y: 0.040309906005859375,
    },
    {
      x: 963.0681818181819,
      y: 0.040309906005859375,
    },
    {
      x: 971.5909090909091,
      y: 0.040309906005859375,
    },
    {
      x: 975.8522727272727,
      y: 0.045291900634765625,
    },
    {
      x: 980.1136363636365,
      y: 0.045291900634765625,
    },
    {
      x: 992.8977272727274,
      y: 0.045291900634765625,
    },
    {
      x: 997.159090909091,
      y: 0.050273895263671875,
    },
    {
      x: 1001.4204545454546,
      y: 0.050273895263671875,
    },
    {
      x: 1026.9886363636365,
      y: 0.050273895263671875,
    },
    {
      x: 1031.25,
      y: 0.05525970458984375,
    },
    {
      x: 1035.5113636363637,
      y: 0.05525970458984375,
    },
    {
      x: 1061.0795454545455,
      y: 0.05525970458984375,
    },
    {
      x: 1065.3409090909092,
      y: 0.06024169921875,
    },
    {
      x: 1069.6022727272727,
      y: 0.06024169921875,
    },
    {
      x: 1086.6477272727273,
      y: 0.06024169921875,
    },
    {
      x: 1090.909090909091,
      y: 0.110076904296875,
    },
    {
      x: 1095.1704545454547,
      y: 0.110076904296875,
    },
    {
      x: 1632.1022727272727,
      y: 0.110076904296875,
    },
    {
      x: 1636.3636363636365,
      y: 0.06024169921875,
    },
    {
      x: 1640.6250000000002,
      y: 0.06024169921875,
    },
    {
      x: 1666.193181818182,
      y: 0.06024169921875,
    },
    {
      x: 1670.4545454545455,
      y: 0.06522369384765625,
    },
    {
      x: 1674.7159090909092,
      y: 0.06522369384765625,
    },
    {
      x: 1700.284090909091,
      y: 0.06522369384765625,
    },
    {
      x: 1704.5454545454547,
      y: 0.07020950317382812,
    },
    {
      x: 1708.8068181818182,
      y: 0.07020950317382812,
    },
    {
      x: 1734.3750000000002,
      y: 0.07020950317382812,
    },
    {
      x: 1738.6363636363637,
      y: 0.0751953125,
    },
    {
      x: 1742.8977272727275,
      y: 0.0751953125,
    },
    {
      x: 1747.159090909091,
      y: 0.07020950317382812,
    },
    {
      x: 1751.4204545454547,
      y: 0.06522369384765625,
    },
    {
      x: 1755.6818181818182,
      y: 0.06024169921875,
    },
    {
      x: 1759.943181818182,
      y: 0.05525970458984375,
    },
    {
      x: 1764.2045454545455,
      y: 0.045291900634765625,
    },
    {
      x: 1768.4659090909092,
      y: 0.0353240966796875,
    },
    {
      x: 1772.727272727273,
      y: 0.03034210205078125,
    },
    {
      x: 1776.9886363636365,
      y: 0.020374298095703125,
    },
    {
      x: 1781.2500000000002,
      y: 0.010406494140625,
    },
    {
      x: 1785.5113636363637,
      y: 0.0004425048828125,
    },
    {
      x: 1789.7727272727275,
      y: -0.004543304443359375,
    },
    {
      x: 1794.034090909091,
      y: -0.0145111083984375,
    },
    {
      x: 1798.2954545454547,
      y: -0.01949310302734375,
    },
    {
      x: 1802.5568181818182,
      y: -0.024478912353515625,
    },
    {
      x: 1806.818181818182,
      y: -0.034442901611328125,
    },
    {
      x: 1811.0795454545455,
      y: -0.0394287109375,
    },
    {
      x: 1815.3409090909092,
      y: -0.049396514892578125,
    },
    {
      x: 1819.602272727273,
      y: -0.05936431884765625,
    },
    {
      x: 1823.8636363636365,
      y: -0.0643463134765625,
    },
    {
      x: 1828.1250000000002,
      y: -0.074310302734375,
    },
    {
      x: 1832.3863636363637,
      y: -0.06932830810546875,
    },
    {
      x: 1836.6477272727275,
      y: -0.05936431884765625,
    },
    {
      x: 1840.909090909091,
      y: -0.049396514892578125,
    },
    {
      x: 1845.1704545454547,
      y: -0.0394287109375,
    },
    {
      x: 1849.4318181818182,
      y: -0.024478912353515625,
    },
    {
      x: 1853.693181818182,
      y: -0.0145111083984375,
    },
    {
      x: 1857.9545454545455,
      y: -0.004543304443359375,
    },
    {
      x: 1862.2159090909092,
      y: 0.010406494140625,
    },
    {
      x: 1866.477272727273,
      y: 0.015392303466796875,
    },
    {
      x: 1870.7386363636365,
      y: 0.025356292724609375,
    },
    {
      x: 1875.0000000000002,
      y: 0.040309906005859375,
    },
    {
      x: 1879.2613636363637,
      y: 0.050273895263671875,
    },
    {
      x: 1883.5227272727275,
      y: 0.06024169921875,
    },
    {
      x: 1896.3068181818182,
      y: 0.09014511108398438,
    },
    {
      x: 1900.568181818182,
      y: 0.10010910034179688,
    },
    {
      x: 1904.8295454545455,
      y: 0.10509490966796875,
    },
    {
      x: 1909.0909090909092,
      y: 0.110076904296875,
    },
    {
      x: 1913.352272727273,
      y: 0.110076904296875,
    },
    {
      x: 1964.4886363636365,
      y: 0.110076904296875,
    },
    {
      x: 1968.7500000000002,
      y: 0.1349945068359375,
    },
    {
      x: 1973.0113636363637,
      y: 0.1997833251953125,
    },
    {
      x: 1977.2727272727275,
      y: 0.27951812744140625,
    },
    {
      x: 1981.534090909091,
      y: 0.3592529296875,
    },
    {
      x: 1985.7954545454547,
      y: 0.4439735412597656,
    },
    {
      x: 1990.0568181818182,
      y: 0.523712158203125,
    },
    {
      x: 1994.318181818182,
      y: 0.6034469604492188,
    },
    {
      x: 1998.5795454545455,
      y: 0.6831817626953125,
    },
    {
      x: 2002.8409090909092,
      y: 0.7629203796386719,
    },
    {
      x: 2007.102272727273,
      y: 0.8426551818847656,
    },
    {
      x: 2011.3636363636365,
      y: 0.8775367736816406,
    },
    {
      x: 2015.6250000000002,
      y: 0.8376731872558594,
    },
    {
      x: 2019.8863636363637,
      y: 0.7579345703125,
    },
    {
      x: 2024.1477272727275,
      y: 0.6781997680664062,
    },
    {
      x: 2028.409090909091,
      y: 0.5884971618652344,
    },
    {
      x: 2032.6704545454547,
      y: 0.4987945556640625,
    },
    {
      x: 2036.9318181818182,
      y: 0.409088134765625,
    },
    {
      x: 2041.193181818182,
      y: 0.3193855285644531,
    },
    {
      x: 2045.4545454545455,
      y: 0.2346649169921875,
    },
    {
      x: 2049.7159090909095,
      y: 0.13997650146484375,
    },
    {
      x: 2053.977272727273,
      y: 0.07020950317382812,
    },
    {
      x: 2058.2386363636365,
      y: 0.050273895263671875,
    },
    {
      x: 2062.5,
      y: 0.040309906005859375,
    },
    {
      x: 2066.761363636364,
      y: 0.0353240966796875,
    },
    {
      x: 2071.0227272727275,
      y: 0.0353240966796875,
    },
    {
      x: 2075.284090909091,
      y: 0.040309906005859375,
    },
    {
      x: 2079.5454545454545,
      y: 0.040309906005859375,
    },
    {
      x: 2088.068181818182,
      y: 0.040309906005859375,
    },
    {
      x: 2092.3295454545455,
      y: 0.045291900634765625,
    },
    {
      x: 2096.5909090909095,
      y: 0.045291900634765625,
    },
    {
      x: 2109.375,
      y: 0.045291900634765625,
    },
    {
      x: 2113.636363636364,
      y: 0.050273895263671875,
    },
    {
      x: 2117.8977272727275,
      y: 0.050273895263671875,
    },
    {
      x: 2143.4659090909095,
      y: 0.050273895263671875,
    },
    {
      x: 2147.727272727273,
      y: 0.05525970458984375,
    },
    {
      x: 2151.9886363636365,
      y: 0.05525970458984375,
    },
    {
      x: 2177.5568181818185,
      y: 0.05525970458984375,
    },
    {
      x: 2181.818181818182,
      y: 0.110076904296875,
    },
    {
      x: 2186.0795454545455,
      y: 0.110076904296875,
    },
    {
      x: 2450.284090909091,
      y: 0.110076904296875,
    },
    {
      x: 2454.545454545455,
      y: 0.06024169921875,
    },
    {
      x: 2458.8068181818185,
      y: 0.07020950317382812,
    },
    {
      x: 2467.3295454545455,
      y: 0.09014511108398438,
    },
    {
      x: 2471.5909090909095,
      y: 0.10010910034179688,
    },
    {
      x: 2475.852272727273,
      y: 0.10509490966796875,
    },
    {
      x: 2480.1136363636365,
      y: 0.110076904296875,
    },
    {
      x: 2484.375,
      y: 0.110076904296875,
    },
    {
      x: 2723.011363636364,
      y: 0.110076904296875,
    },
    {
      x: 2727.2727272727275,
      y: 0.05525970458984375,
    },
    {
      x: 2731.534090909091,
      y: 0.05525970458984375,
    },
    {
      x: 2748.5795454545455,
      y: 0.05525970458984375,
    },
    {
      x: 2752.8409090909095,
      y: 0.06024169921875,
    },
    {
      x: 2757.102272727273,
      y: 0.06024169921875,
    },
    {
      x: 2782.670454545455,
      y: 0.06024169921875,
    },
    {
      x: 2786.9318181818185,
      y: 0.06522369384765625,
    },
    {
      x: 2791.193181818182,
      y: 0.06522369384765625,
    },
    {
      x: 2816.761363636364,
      y: 0.06522369384765625,
    },
    {
      x: 2821.0227272727275,
      y: 0.07020950317382812,
    },
    {
      x: 2825.284090909091,
      y: 0.07020950317382812,
    },
    {
      x: 2850.852272727273,
      y: 0.07020950317382812,
    },
    {
      x: 2855.1136363636365,
      y: 0.0751953125,
    },
    {
      x: 2859.375,
      y: 0.0751953125,
    },
    {
      x: 2863.636363636364,
      y: 0.07020950317382812,
    },
    {
      x: 2867.8977272727275,
      y: 0.06522369384765625,
    },
    {
      x: 2872.159090909091,
      y: 0.06024169921875,
    },
    {
      x: 2876.420454545455,
      y: 0.05525970458984375,
    },
    {
      x: 2880.6818181818185,
      y: 0.045291900634765625,
    },
    {
      x: 2884.943181818182,
      y: 0.0353240966796875,
    },
    {
      x: 2889.2045454545455,
      y: 0.03034210205078125,
    },
    {
      x: 2893.4659090909095,
      y: 0.020374298095703125,
    },
    {
      x: 2897.727272727273,
      y: 0.010406494140625,
    },
    {
      x: 2901.9886363636365,
      y: 0.0004425048828125,
    },
    {
      x: 2906.25,
      y: -0.004543304443359375,
    },
    {
      x: 2910.511363636364,
      y: -0.0145111083984375,
    },
    {
      x: 2914.7727272727275,
      y: -0.01949310302734375,
    },
    {
      x: 2919.034090909091,
      y: -0.024478912353515625,
    },
    {
      x: 2923.295454545455,
      y: -0.034442901611328125,
    },
    {
      x: 2927.5568181818185,
      y: -0.0394287109375,
    },
    {
      x: 2931.818181818182,
      y: -0.049396514892578125,
    },
    {
      x: 2936.0795454545455,
      y: -0.05936431884765625,
    },
    {
      x: 2940.3409090909095,
      y: -0.0643463134765625,
    },
    {
      x: 2944.602272727273,
      y: -0.074310302734375,
    },
    {
      x: 2948.8636363636365,
      y: -0.06932830810546875,
    },
    {
      x: 2953.125,
      y: -0.05936431884765625,
    },
    {
      x: 2957.386363636364,
      y: -0.049396514892578125,
    },
    {
      x: 2961.6477272727275,
      y: -0.0394287109375,
    },
    {
      x: 2965.909090909091,
      y: -0.024478912353515625,
    },
    {
      x: 2970.170454545455,
      y: -0.0145111083984375,
    },
    {
      x: 2974.4318181818185,
      y: -0.004543304443359375,
    },
    {
      x: 2978.693181818182,
      y: 0.010406494140625,
    },
    {
      x: 2982.9545454545455,
      y: 0.015392303466796875,
    },
    {
      x: 2987.2159090909095,
      y: 0.025356292724609375,
    },
    {
      x: 2991.477272727273,
      y: 0.040309906005859375,
    },
    {
      x: 2995.7386363636365,
      y: 0.050273895263671875,
    },
    {
      x: 3000,
      y: 0.110076904296875,
    },
    {
      x: 3004.261363636364,
      y: 0.110076904296875,
    },
    {
      x: 3080.9659090909095,
      y: 0.110076904296875,
    },
    {
      x: 3085.227272727273,
      y: 0.1349945068359375,
    },
    {
      x: 3089.4886363636365,
      y: 0.1997833251953125,
    },
    {
      x: 3093.7500000000005,
      y: 0.27951812744140625,
    },
    {
      x: 3098.011363636364,
      y: 0.3592529296875,
    },
    {
      x: 3102.2727272727275,
      y: 0.4439735412597656,
    },
    {
      x: 3106.534090909091,
      y: 0.523712158203125,
    },
    {
      x: 3110.795454545455,
      y: 0.6034469604492188,
    },
    {
      x: 3115.0568181818185,
      y: 0.6831817626953125,
    },
    {
      x: 3119.318181818182,
      y: 0.7629203796386719,
    },
    {
      x: 3123.5795454545455,
      y: 0.8426551818847656,
    },
    {
      x: 3127.8409090909095,
      y: 0.8775367736816406,
    },
    {
      x: 3132.102272727273,
      y: 0.8376731872558594,
    },
    {
      x: 3136.3636363636365,
      y: 0.7579345703125,
    },
    {
      x: 3140.6250000000005,
      y: 0.6781997680664062,
    },
    {
      x: 3144.886363636364,
      y: 0.5884971618652344,
    },
    {
      x: 3149.1477272727275,
      y: 0.4987945556640625,
    },
    {
      x: 3153.409090909091,
      y: 0.409088134765625,
    },
    {
      x: 3157.670454545455,
      y: 0.3193855285644531,
    },
    {
      x: 3161.9318181818185,
      y: 0.2346649169921875,
    },
    {
      x: 3166.193181818182,
      y: 0.13997650146484375,
    },
    {
      x: 3170.4545454545455,
      y: 0.07020950317382812,
    },
    {
      x: 3174.7159090909095,
      y: 0.050273895263671875,
    },
    {
      x: 3178.977272727273,
      y: 0.040309906005859375,
    },
    {
      x: 3183.2386363636365,
      y: 0.0353240966796875,
    },
    {
      x: 3187.5000000000005,
      y: 0.0353240966796875,
    },
    {
      x: 3191.761363636364,
      y: 0.040309906005859375,
    },
    {
      x: 3196.0227272727275,
      y: 0.040309906005859375,
    },
    {
      x: 3204.545454545455,
      y: 0.040309906005859375,
    },
    {
      x: 3208.8068181818185,
      y: 0.045291900634765625,
    },
    {
      x: 3213.068181818182,
      y: 0.045291900634765625,
    },
    {
      x: 3225.852272727273,
      y: 0.045291900634765625,
    },
    {
      x: 3230.1136363636365,
      y: 0.050273895263671875,
    },
    {
      x: 3234.3750000000005,
      y: 0.050273895263671875,
    },
    {
      x: 3259.943181818182,
      y: 0.050273895263671875,
    },
    {
      x: 3264.2045454545455,
      y: 0.05525970458984375,
    },
    {
      x: 3268.4659090909095,
      y: 0.05525970458984375,
    },
    {
      x: 3272.727272727273,
      y: -0.004543304443359375,
    },
    {
      x: 3276.9886363636365,
      y: 0.010406494140625,
    },
    {
      x: 3281.2500000000005,
      y: 0.015392303466796875,
    },
    {
      x: 3285.511363636364,
      y: 0.025356292724609375,
    },
    {
      x: 3289.7727272727275,
      y: 0.040309906005859375,
    },
    {
      x: 3294.034090909091,
      y: 0.050273895263671875,
    },
    {
      x: 3298.295454545455,
      y: 0.06024169921875,
    },
    {
      x: 3311.0795454545455,
      y: 0.09014511108398438,
    },
    {
      x: 3315.3409090909095,
      y: 0.10010910034179688,
    },
    {
      x: 3319.602272727273,
      y: 0.10509490966796875,
    },
    {
      x: 3323.8636363636365,
      y: 0.110076904296875,
    },
    {
      x: 3328.1250000000005,
      y: 0.110076904296875,
    },
    {
      x: 3813.920454545455,
      y: 0.110076904296875,
    },
    {
      x: 3818.1818181818185,
      y: 0.050273895263671875,
    },
    {
      x: 3822.443181818182,
      y: 0.050273895263671875,
    },
    {
      x: 3830.9659090909095,
      y: 0.050273895263671875,
    },
    {
      x: 3835.227272727273,
      y: 0.05525970458984375,
    },
    {
      x: 3839.4886363636365,
      y: 0.05525970458984375,
    },
    {
      x: 3865.0568181818185,
      y: 0.05525970458984375,
    },
    {
      x: 3869.318181818182,
      y: 0.06024169921875,
    },
    {
      x: 3873.579545454546,
      y: 0.06024169921875,
    },
    {
      x: 3899.1477272727275,
      y: 0.06024169921875,
    },
    {
      x: 3903.409090909091,
      y: 0.06522369384765625,
    },
    {
      x: 3907.670454545455,
      y: 0.06522369384765625,
    },
    {
      x: 3933.2386363636365,
      y: 0.06522369384765625,
    },
    {
      x: 3937.5000000000005,
      y: 0.07020950317382812,
    },
    {
      x: 3941.761363636364,
      y: 0.07020950317382812,
    },
    {
      x: 3967.329545454546,
      y: 0.07020950317382812,
    },
    {
      x: 3971.5909090909095,
      y: 0.0751953125,
    },
    {
      x: 3975.852272727273,
      y: 0.0751953125,
    },
    {
      x: 3980.1136363636365,
      y: 0.07020950317382812,
    },
    {
      x: 3984.3750000000005,
      y: 0.06522369384765625,
    },
    {
      x: 3988.636363636364,
      y: 0.06024169921875,
    },
    {
      x: 3992.8977272727275,
      y: 0.05525970458984375,
    },
    {
      x: 3997.159090909091,
      y: 0.045291900634765625,
    },
    {
      x: 4001.420454545455,
      y: 0.0353240966796875,
    },
    {
      x: 4005.6818181818185,
      y: 0.03034210205078125,
    },
    {
      x: 4009.943181818182,
      y: 0.020374298095703125,
    },
    {
      x: 4014.204545454546,
      y: 0.010406494140625,
    },
    {
      x: 4018.4659090909095,
      y: 0.0004425048828125,
    },
    {
      x: 4022.727272727273,
      y: -0.004543304443359375,
    },
    {
      x: 4026.9886363636365,
      y: -0.0145111083984375,
    },
    {
      x: 4031.2500000000005,
      y: -0.01949310302734375,
    },
    {
      x: 4035.511363636364,
      y: -0.024478912353515625,
    },
    {
      x: 4039.7727272727275,
      y: -0.034442901611328125,
    },
    {
      x: 4044.034090909091,
      y: -0.0394287109375,
    },
    {
      x: 4048.295454545455,
      y: -0.049396514892578125,
    },
    {
      x: 4052.5568181818185,
      y: -0.05936431884765625,
    },
    {
      x: 4056.818181818182,
      y: -0.0643463134765625,
    },
    {
      x: 4061.079545454546,
      y: -0.074310302734375,
    },
    {
      x: 4065.3409090909095,
      y: -0.06932830810546875,
    },
    {
      x: 4069.602272727273,
      y: -0.05936431884765625,
    },
    {
      x: 4073.8636363636365,
      y: -0.049396514892578125,
    },
    {
      x: 4078.1250000000005,
      y: -0.0394287109375,
    },
    {
      x: 4082.386363636364,
      y: -0.024478912353515625,
    },
    {
      x: 4086.6477272727275,
      y: -0.0145111083984375,
    },
    {
      x: 4090.909090909091,
      y: 0.110076904296875,
    },
    {
      x: 4095.170454545455,
      y: 0.110076904296875,
    },
    {
      x: 4197.443181818182,
      y: 0.110076904296875,
    },
    {
      x: 4201.704545454546,
      y: 0.1349945068359375,
    },
    {
      x: 4205.965909090909,
      y: 0.1997833251953125,
    },
    {
      x: 4210.227272727273,
      y: 0.27951812744140625,
    },
    {
      x: 4214.488636363637,
      y: 0.3592529296875,
    },
    {
      x: 4218.75,
      y: 0.4439735412597656,
    },
    {
      x: 4223.011363636364,
      y: 0.523712158203125,
    },
    {
      x: 4227.272727272728,
      y: 0.6034469604492188,
    },
    {
      x: 4231.534090909091,
      y: 0.6831817626953125,
    },
    {
      x: 4235.795454545455,
      y: 0.7629203796386719,
    },
    {
      x: 4240.056818181819,
      y: 0.8426551818847656,
    },
    {
      x: 4244.318181818182,
      y: 0.8775367736816406,
    },
    {
      x: 4248.579545454546,
      y: 0.8376731872558594,
    },
    {
      x: 4252.840909090909,
      y: 0.7579345703125,
    },
    {
      x: 4257.102272727273,
      y: 0.6781997680664062,
    },
    {
      x: 4261.363636363637,
      y: 0.5884971618652344,
    },
    {
      x: 4265.625,
      y: 0.4987945556640625,
    },
    {
      x: 4269.886363636364,
      y: 0.409088134765625,
    },
    {
      x: 4274.147727272728,
      y: 0.3193855285644531,
    },
    {
      x: 4278.409090909091,
      y: 0.2346649169921875,
    },
    {
      x: 4282.670454545455,
      y: 0.13997650146484375,
    },
    {
      x: 4286.931818181819,
      y: 0.07020950317382812,
    },
    {
      x: 4291.193181818182,
      y: 0.050273895263671875,
    },
    {
      x: 4295.454545454546,
      y: 0.040309906005859375,
    },
    {
      x: 4299.715909090909,
      y: 0.0353240966796875,
    },
    {
      x: 4303.977272727273,
      y: 0.0353240966796875,
    },
    {
      x: 4308.238636363637,
      y: 0.040309906005859375,
    },
    {
      x: 4312.5,
      y: 0.040309906005859375,
    },
    {
      x: 4321.022727272728,
      y: 0.040309906005859375,
    },
    {
      x: 4325.284090909091,
      y: 0.045291900634765625,
    },
    {
      x: 4329.545454545455,
      y: 0.045291900634765625,
    },
    {
      x: 4342.329545454546,
      y: 0.045291900634765625,
    },
    {
      x: 4346.590909090909,
      y: 0.050273895263671875,
    },
    {
      x: 4350.852272727273,
      y: 0.050273895263671875,
    },
    {
      x: 4359.375,
      y: 0.050273895263671875,
    },
    {
      x: 4363.636363636364,
      y: -0.06932830810546875,
    },
    {
      x: 4367.897727272728,
      y: -0.05936431884765625,
    },
    {
      x: 4372.159090909091,
      y: -0.049396514892578125,
    },
    {
      x: 4376.420454545455,
      y: -0.0394287109375,
    },
    {
      x: 4380.681818181819,
      y: -0.024478912353515625,
    },
    {
      x: 4384.943181818182,
      y: -0.0145111083984375,
    },
    {
      x: 4389.204545454546,
      y: -0.004543304443359375,
    },
    {
      x: 4393.465909090909,
      y: 0.010406494140625,
    },
    {
      x: 4397.727272727273,
      y: 0.015392303466796875,
    },
    {
      x: 4401.988636363637,
      y: 0.025356292724609375,
    },
    {
      x: 4406.25,
      y: 0.040309906005859375,
    },
    {
      x: 4410.511363636364,
      y: 0.050273895263671875,
    },
    {
      x: 4414.772727272728,
      y: 0.06024169921875,
    },
    {
      x: 4427.556818181819,
      y: 0.09014511108398438,
    },
    {
      x: 4431.818181818182,
      y: 0.10010910034179688,
    },
    {
      x: 4436.079545454546,
      y: 0.10509490966796875,
    },
    {
      x: 4440.340909090909,
      y: 0.110076904296875,
    },
    {
      x: 4444.602272727273,
      y: 0.110076904296875,
    },
    {
      x: 4768.465909090909,
      y: 0.110076904296875,
    },
    {
      x: 4772.727272727273,
      y: 0.1349945068359375,
    },
    {
      x: 4776.988636363637,
      y: 0.1997833251953125,
    },
    {
      x: 4781.25,
      y: 0.27951812744140625,
    },
    {
      x: 4785.511363636364,
      y: 0.3592529296875,
    },
    {
      x: 4789.772727272728,
      y: 0.4439735412597656,
    },
    {
      x: 4794.034090909091,
      y: 0.523712158203125,
    },
    {
      x: 4798.295454545455,
      y: 0.6034469604492188,
    },
    {
      x: 4802.556818181819,
      y: 0.6831817626953125,
    },
    {
      x: 4806.818181818182,
      y: 0.7629203796386719,
    },
    {
      x: 4811.079545454546,
      y: 0.8426551818847656,
    },
    {
      x: 4815.340909090909,
      y: 0.8775367736816406,
    },
    {
      x: 4819.602272727273,
      y: 0.8376731872558594,
    },
    {
      x: 4823.863636363637,
      y: 0.7579345703125,
    },
    {
      x: 4828.125,
      y: 0.6781997680664062,
    },
    {
      x: 4832.386363636364,
      y: 0.5884971618652344,
    },
    {
      x: 4836.647727272728,
      y: 0.4987945556640625,
    },
    {
      x: 4840.909090909091,
      y: 0.409088134765625,
    },
    {
      x: 4845.170454545455,
      y: 0.3193855285644531,
    },
    {
      x: 4849.431818181819,
      y: 0.2346649169921875,
    },
    {
      x: 4853.693181818182,
      y: 0.13997650146484375,
    },
    {
      x: 4857.954545454546,
      y: 0.07020950317382812,
    },
    {
      x: 4862.215909090909,
      y: 0.050273895263671875,
    },
    {
      x: 4866.477272727273,
      y: 0.040309906005859375,
    },
    {
      x: 4870.738636363637,
      y: 0.0353240966796875,
    },
    {
      x: 4875,
      y: 0.0353240966796875,
    },
    {
      x: 4879.261363636364,
      y: 0.040309906005859375,
    },
    {
      x: 4883.522727272728,
      y: 0.040309906005859375,
    },
    {
      x: 4892.045454545455,
      y: 0.040309906005859375,
    },
    {
      x: 4896.306818181819,
      y: 0.045291900634765625,
    },
    {
      x: 4900.568181818182,
      y: 0.045291900634765625,
    },
    {
      x: 4913.352272727273,
      y: 0.045291900634765625,
    },
    {
      x: 4917.613636363637,
      y: 0.050273895263671875,
    },
    {
      x: 4921.875,
      y: 0.050273895263671875,
    },
    {
      x: 4947.443181818182,
      y: 0.050273895263671875,
    },
    {
      x: 4951.704545454546,
      y: 0.05525970458984375,
    },
    {
      x: 4955.96590909091,
      y: 0.05525970458984375,
    },
    {
      x: 4981.534090909091,
      y: 0.05525970458984375,
    },
    {
      x: 4985.795454545455,
      y: 0.06024169921875,
    },
    {
      x: 4990.056818181819,
      y: 0.06024169921875,
    },
    {
      x: 5015.625,
      y: 0.06024169921875,
    },
    {
      x: 5019.886363636364,
      y: 0.06522369384765625,
    },
    {
      x: 5024.147727272728,
      y: 0.06522369384765625,
    },
    {
      x: 5049.71590909091,
      y: 0.06522369384765625,
    },
    {
      x: 5053.977272727273,
      y: 0.07020950317382812,
    },
    {
      x: 5058.238636363637,
      y: 0.07020950317382812,
    },
    {
      x: 5083.806818181819,
      y: 0.07020950317382812,
    },
    {
      x: 5088.068181818182,
      y: 0.0751953125,
    },
    {
      x: 5092.329545454546,
      y: 0.0751953125,
    },
    {
      x: 5096.59090909091,
      y: 0.07020950317382812,
    },
    {
      x: 5100.852272727273,
      y: 0.06522369384765625,
    },
    {
      x: 5105.113636363637,
      y: 0.06024169921875,
    },
    {
      x: 5109.375,
      y: 0.05525970458984375,
    },
    {
      x: 5113.636363636364,
      y: 0.045291900634765625,
    },
    {
      x: 5117.897727272728,
      y: 0.0353240966796875,
    },
    {
      x: 5122.159090909091,
      y: 0.03034210205078125,
    },
    {
      x: 5126.420454545455,
      y: 0.020374298095703125,
    },
    {
      x: 5130.681818181819,
      y: 0.010406494140625,
    },
    {
      x: 5134.943181818182,
      y: 0.0004425048828125,
    },
    {
      x: 5139.204545454546,
      y: -0.004543304443359375,
    },
    {
      x: 5143.46590909091,
      y: -0.0145111083984375,
    },
    {
      x: 5147.727272727273,
      y: -0.01949310302734375,
    },
    {
      x: 5151.988636363637,
      y: -0.024478912353515625,
    },
    {
      x: 5156.25,
      y: -0.034442901611328125,
    },
    {
      x: 5160.511363636364,
      y: -0.04441070556640625,
    },
    {
      x: 5164.772727272728,
      y: -0.049396514892578125,
    },
    {
      x: 5169.034090909091,
      y: -0.05936431884765625,
    },
    {
      x: 5173.295454545455,
      y: -0.0643463134765625,
    },
    {
      x: 5177.556818181819,
      y: -0.074310302734375,
    },
    {
      x: 5181.818181818182,
      y: 0.110076904296875,
    },
    {
      x: 5186.079545454546,
      y: 0.110076904296875,
    },
    {
      x: 5612.21590909091,
      y: 0.110076904296875,
    },
    {
      x: 5616.477272727273,
      y: 0.1349945068359375,
    },
    {
      x: 5620.738636363637,
      y: 0.1997833251953125,
    },
    {
      x: 5625,
      y: 0.27951812744140625,
    },
    {
      x: 5629.261363636364,
      y: 0.3592529296875,
    },
    {
      x: 5633.522727272728,
      y: 0.4439735412597656,
    },
    {
      x: 5637.784090909091,
      y: 0.523712158203125,
    },
    {
      x: 5642.045454545455,
      y: 0.6034469604492188,
    },
    {
      x: 5646.306818181819,
      y: 0.6831817626953125,
    },
    {
      x: 5650.568181818182,
      y: 0.7629203796386719,
    },
    {
      x: 5654.829545454546,
      y: 0.8426551818847656,
    },
    {
      x: 5659.09090909091,
      y: 0.8775367736816406,
    },
    {
      x: 5663.352272727273,
      y: 0.8376731872558594,
    },
    {
      x: 5667.613636363637,
      y: 0.7579345703125,
    },
    {
      x: 5671.875,
      y: 0.6781997680664062,
    },
    {
      x: 5676.136363636364,
      y: 0.5884971618652344,
    },
    {
      x: 5680.397727272728,
      y: 0.4987945556640625,
    },
    {
      x: 5684.659090909091,
      y: 0.409088134765625,
    },
    {
      x: 5688.920454545455,
      y: 0.3193855285644531,
    },
    {
      x: 5693.181818181819,
      y: 0.2346649169921875,
    },
    {
      x: 5697.443181818182,
      y: 0.13997650146484375,
    },
    {
      x: 5701.704545454546,
      y: 0.07020950317382812,
    },
    {
      x: 5705.96590909091,
      y: 0.050273895263671875,
    },
    {
      x: 5710.227272727273,
      y: 0.040309906005859375,
    },
    {
      x: 5714.488636363637,
      y: 0.0353240966796875,
    },
    {
      x: 5718.75,
      y: 0.0353240966796875,
    },
    {
      x: 5723.011363636364,
      y: 0.040309906005859375,
    },
    {
      x: 5727.272727272728,
      y: -0.034442901611328125,
    },
    {
      x: 5731.534090909091,
      y: -0.0394287109375,
    },
    {
      x: 5735.795454545455,
      y: -0.049396514892578125,
    },
    {
      x: 5740.056818181819,
      y: -0.05936431884765625,
    },
    {
      x: 5744.318181818182,
      y: -0.0643463134765625,
    },
    {
      x: 5748.579545454546,
      y: -0.074310302734375,
    },
    {
      x: 5752.84090909091,
      y: -0.06932830810546875,
    },
    {
      x: 5757.102272727273,
      y: -0.05936431884765625,
    },
    {
      x: 5761.363636363637,
      y: -0.049396514892578125,
    },
    {
      x: 5765.625,
      y: -0.0394287109375,
    },
    {
      x: 5769.886363636364,
      y: -0.024478912353515625,
    },
    {
      x: 5774.147727272728,
      y: -0.0145111083984375,
    },
    {
      x: 5778.409090909091,
      y: -0.004543304443359375,
    },
    {
      x: 5782.670454545455,
      y: 0.010406494140625,
    },
    {
      x: 5786.931818181819,
      y: 0.015392303466796875,
    },
    {
      x: 5791.193181818182,
      y: 0.025356292724609375,
    },
    {
      x: 5795.454545454546,
      y: 0.040309906005859375,
    },
    {
      x: 5799.71590909091,
      y: 0.050273895263671875,
    },
    {
      x: 5803.977272727273,
      y: 0.06024169921875,
    },
    {
      x: 5816.761363636364,
      y: 0.09014511108398438,
    },
    {
      x: 5821.022727272728,
      y: 0.10010910034179688,
    },
    {
      x: 5825.284090909091,
      y: 0.10509490966796875,
    },
    {
      x: 5829.545454545455,
      y: 0.110076904296875,
    },
    {
      x: 5833.806818181819,
      y: 0.110076904296875,
    },
    {
      x: 6268.46590909091,
      y: 0.110076904296875,
    },
    {
      x: 6272.727272727273,
      y: 0.040309906005859375,
    },
    {
      x: 6276.988636363637,
      y: 0.040309906005859375,
    },
    {
      x: 6281.250000000001,
      y: 0.040309906005859375,
    },
    {
      x: 6285.511363636364,
      y: 0.045291900634765625,
    },
    {
      x: 6289.772727272728,
      y: 0.045291900634765625,
    },
    {
      x: 6302.556818181819,
      y: 0.045291900634765625,
    },
    {
      x: 6306.818181818182,
      y: 0.050273895263671875,
    },
    {
      x: 6311.079545454546,
      y: 0.050273895263671875,
    },
    {
      x: 6336.647727272728,
      y: 0.050273895263671875,
    },
    {
      x: 6340.909090909091,
      y: 0.05525970458984375,
    },
    {
      x: 6345.170454545455,
      y: 0.05525970458984375,
    },
    {
      x: 6370.738636363637,
      y: 0.05525970458984375,
    },
    {
      x: 6375.000000000001,
      y: 0.06024169921875,
    },
    {
      x: 6379.261363636364,
      y: 0.06024169921875,
    },
    {
      x: 6404.829545454546,
      y: 0.06024169921875,
    },
    {
      x: 6409.09090909091,
      y: 0.06522369384765625,
    },
    {
      x: 6413.352272727273,
      y: 0.06522369384765625,
    },
    {
      x: 6438.920454545455,
      y: 0.06522369384765625,
    },
    {
      x: 6443.181818181819,
      y: 0.07020950317382812,
    },
    {
      x: 6447.443181818182,
      y: 0.07020950317382812,
    },
    {
      x: 6473.011363636364,
      y: 0.07020950317382812,
    },
    {
      x: 6477.272727272728,
      y: 0.0751953125,
    },
    {
      x: 6481.534090909091,
      y: 0.0751953125,
    },
    {
      x: 6485.795454545455,
      y: 0.07020950317382812,
    },
    {
      x: 6490.056818181819,
      y: 0.06522369384765625,
    },
    {
      x: 6494.318181818182,
      y: 0.06024169921875,
    },
    {
      x: 6498.579545454546,
      y: 0.05525970458984375,
    },
    {
      x: 6502.84090909091,
      y: 0.045291900634765625,
    },
    {
      x: 6507.102272727273,
      y: 0.0353240966796875,
    },
    {
      x: 6511.363636363637,
      y: 0.03034210205078125,
    },
    {
      x: 6515.625000000001,
      y: 0.020374298095703125,
    },
    {
      x: 6519.886363636364,
      y: 0.010406494140625,
    },
    {
      x: 6524.147727272728,
      y: 0.0004425048828125,
    },
    {
      x: 6528.409090909091,
      y: -0.004543304443359375,
    },
    {
      x: 6532.670454545455,
      y: -0.0145111083984375,
    },
    {
      x: 6536.931818181819,
      y: -0.01949310302734375,
    },
    {
      x: 6541.193181818182,
      y: -0.024478912353515625,
    },
    {
      x: 6545.454545454546,
      y: 0.110076904296875,
    },
    {
      x: 6549.71590909091,
      y: 0.110076904296875,
    },
    {
      x: 6728.693181818182,
      y: 0.110076904296875,
    },
    {
      x: 6732.954545454546,
      y: 0.1349945068359375,
    },
    {
      x: 6737.21590909091,
      y: 0.1997833251953125,
    },
    {
      x: 6741.477272727273,
      y: 0.27951812744140625,
    },
    {
      x: 6745.738636363637,
      y: 0.3592529296875,
    },
    {
      x: 6750.000000000001,
      y: 0.4439735412597656,
    },
    {
      x: 6754.261363636364,
      y: 0.523712158203125,
    },
    {
      x: 6758.522727272728,
      y: 0.6034469604492188,
    },
    {
      x: 6762.784090909091,
      y: 0.6831817626953125,
    },
    {
      x: 6767.045454545455,
      y: 0.7629203796386719,
    },
    {
      x: 6771.306818181819,
      y: 0.8426551818847656,
    },
    {
      x: 6775.568181818182,
      y: 0.8775367736816406,
    },
    {
      x: 6779.829545454546,
      y: 0.8376731872558594,
    },
    {
      x: 6784.09090909091,
      y: 0.7579345703125,
    },
    {
      x: 6788.352272727273,
      y: 0.6781997680664062,
    },
    {
      x: 6792.613636363637,
      y: 0.5884971618652344,
    },
    {
      x: 6796.875000000001,
      y: 0.4987945556640625,
    },
    {
      x: 6801.136363636364,
      y: 0.409088134765625,
    },
    {
      x: 6805.397727272728,
      y: 0.3193855285644531,
    },
    {
      x: 6809.659090909091,
      y: 0.2346649169921875,
    },
    {
      x: 6813.920454545455,
      y: 0.13997650146484375,
    },
    {
      x: 6818.181818181819,
      y: 0.010406494140625,
    },
    {
      x: 6822.443181818182,
      y: 0.0004425048828125,
    },
    {
      x: 6826.704545454546,
      y: -0.004543304443359375,
    },
    {
      x: 6830.96590909091,
      y: -0.0145111083984375,
    },
    {
      x: 6835.227272727273,
      y: -0.01949310302734375,
    },
    {
      x: 6839.488636363637,
      y: -0.024478912353515625,
    },
    {
      x: 6843.750000000001,
      y: -0.034442901611328125,
    },
    {
      x: 6848.011363636364,
      y: -0.0394287109375,
    },
    {
      x: 6852.272727272728,
      y: -0.049396514892578125,
    },
    {
      x: 6856.534090909091,
      y: -0.05936431884765625,
    },
    {
      x: 6860.795454545455,
      y: -0.0643463134765625,
    },
    {
      x: 6865.056818181819,
      y: -0.074310302734375,
    },
    {
      x: 6869.318181818182,
      y: -0.06932830810546875,
    },
    {
      x: 6873.579545454546,
      y: -0.05936431884765625,
    },
    {
      x: 6877.84090909091,
      y: -0.049396514892578125,
    },
    {
      x: 6882.102272727273,
      y: -0.0394287109375,
    },
    {
      x: 6886.363636363637,
      y: -0.024478912353515625,
    },
    {
      x: 6890.625000000001,
      y: -0.0145111083984375,
    },
    {
      x: 6894.886363636364,
      y: -0.004543304443359375,
    },
    {
      x: 6899.147727272728,
      y: 0.010406494140625,
    },
    {
      x: 6903.409090909091,
      y: 0.015392303466796875,
    },
    {
      x: 6907.670454545455,
      y: 0.025356292724609375,
    },
    {
      x: 6911.931818181819,
      y: 0.040309906005859375,
    },
    {
      x: 6916.193181818182,
      y: 0.050273895263671875,
    },
    {
      x: 6920.454545454546,
      y: 0.06024169921875,
    },
    {
      x: 6933.238636363637,
      y: 0.09014511108398438,
    },
    {
      x: 6937.500000000001,
      y: 0.10010910034179688,
    },
    {
      x: 6941.761363636364,
      y: 0.10509490966796875,
    },
    {
      x: 6946.022727272728,
      y: 0.110076904296875,
    },
    {
      x: 6950.284090909091,
      y: 0.110076904296875,
    },
    {
      x: 7086.647727272728,
      y: 0.110076904296875,
    },
    {
      x: 7090.909090909092,
      y: 0.07020950317382812,
    },
    {
      x: 7095.170454545455,
      y: 0.050273895263671875,
    },
    {
      x: 7099.431818181819,
      y: 0.040309906005859375,
    },
    {
      x: 7103.693181818182,
      y: 0.0353240966796875,
    },
    {
      x: 7107.954545454546,
      y: 0.0353240966796875,
    },
    {
      x: 7112.21590909091,
      y: 0.040309906005859375,
    },
    {
      x: 7116.477272727273,
      y: 0.040309906005859375,
    },
    {
      x: 7125.000000000001,
      y: 0.040309906005859375,
    },
    {
      x: 7129.261363636364,
      y: 0.045291900634765625,
    },
    {
      x: 7133.522727272728,
      y: 0.045291900634765625,
    },
    {
      x: 7146.306818181819,
      y: 0.045291900634765625,
    },
    {
      x: 7150.568181818182,
      y: 0.050273895263671875,
    },
    {
      x: 7154.829545454546,
      y: 0.050273895263671875,
    },
    {
      x: 7180.397727272728,
      y: 0.050273895263671875,
    },
    {
      x: 7184.659090909092,
      y: 0.05525970458984375,
    },
    {
      x: 7188.920454545455,
      y: 0.05525970458984375,
    },
    {
      x: 7214.488636363637,
      y: 0.05525970458984375,
    },
    {
      x: 7218.750000000001,
      y: 0.06024169921875,
    },
    {
      x: 7223.011363636364,
      y: 0.06024169921875,
    },
    {
      x: 7248.579545454546,
      y: 0.06024169921875,
    },
    {
      x: 7252.84090909091,
      y: 0.06522369384765625,
    },
    {
      x: 7257.102272727273,
      y: 0.06522369384765625,
    },
    {
      x: 7282.670454545455,
      y: 0.06522369384765625,
    },
    {
      x: 7286.931818181819,
      y: 0.07020950317382812,
    },
    {
      x: 7291.193181818182,
      y: 0.07020950317382812,
    },
    {
      x: 7316.761363636364,
      y: 0.07020950317382812,
    },
    {
      x: 7321.022727272728,
      y: 0.0751953125,
    },
    {
      x: 7325.284090909092,
      y: 0.0751953125,
    },
    {
      x: 7329.545454545455,
      y: 0.07020950317382812,
    },
    {
      x: 7333.806818181819,
      y: 0.06522369384765625,
    },
    {
      x: 7338.068181818182,
      y: 0.06024169921875,
    },
    {
      x: 7342.329545454546,
      y: 0.05525970458984375,
    },
    {
      x: 7346.59090909091,
      y: 0.045291900634765625,
    },
    {
      x: 7350.852272727273,
      y: 0.0353240966796875,
    },
    {
      x: 7355.113636363637,
      y: 0.03034210205078125,
    },
    {
      x: 7359.375000000001,
      y: 0.020374298095703125,
    },
    {
      x: 7363.636363636364,
      y: 0.110076904296875,
    },
    {
      x: 7367.897727272728,
      y: 0.110076904296875,
    },
    {
      x: 7904.829545454546,
      y: 0.110076904296875,
    },
    {
      x: 7909.09090909091,
      y: 0.06024169921875,
    },
    {
      x: 7913.352272727273,
      y: 0.05525970458984375,
    },
    {
      x: 7917.613636363637,
      y: 0.045291900634765625,
    },
    {
      x: 7921.875000000001,
      y: 0.0353240966796875,
    },
    {
      x: 7926.136363636364,
      y: 0.03034210205078125,
    },
    {
      x: 7930.397727272728,
      y: 0.020374298095703125,
    },
    {
      x: 7934.659090909092,
      y: 0.010406494140625,
    },
    {
      x: 7938.920454545455,
      y: 0.0004425048828125,
    },
    {
      x: 7943.181818181819,
      y: -0.004543304443359375,
    },
    {
      x: 7947.443181818182,
      y: -0.0145111083984375,
    },
    {
      x: 7951.704545454546,
      y: -0.01949310302734375,
    },
    {
      x: 7955.96590909091,
      y: -0.024478912353515625,
    },
    {
      x: 7960.227272727273,
      y: -0.034442901611328125,
    },
    {
      x: 7964.488636363637,
      y: -0.0394287109375,
    },
    {
      x: 7968.750000000001,
      y: -0.049396514892578125,
    },
    {
      x: 7973.011363636364,
      y: -0.05936431884765625,
    },
    {
      x: 7977.272727272728,
      y: -0.0643463134765625,
    },
    {
      x: 7981.534090909092,
      y: -0.074310302734375,
    },
    {
      x: 7985.795454545455,
      y: -0.06932830810546875,
    },
    {
      x: 7990.056818181819,
      y: -0.05936431884765625,
    },
    {
      x: 7994.318181818182,
      y: -0.049396514892578125,
    },
    {
      x: 7998.579545454546,
      y: -0.0394287109375,
    },
    {
      x: 8002.84090909091,
      y: -0.024478912353515625,
    },
    {
      x: 8007.102272727273,
      y: -0.0145111083984375,
    },
    {
      x: 8011.363636363637,
      y: -0.004543304443359375,
    },
    {
      x: 8015.625000000001,
      y: 0.010406494140625,
    },
    {
      x: 8019.886363636364,
      y: 0.015392303466796875,
    },
    {
      x: 8024.147727272728,
      y: 0.025356292724609375,
    },
    {
      x: 8028.409090909092,
      y: 0.040309906005859375,
    },
    {
      x: 8032.670454545455,
      y: 0.050273895263671875,
    },
    {
      x: 8036.931818181819,
      y: 0.06024169921875,
    },
    {
      x: 8049.71590909091,
      y: 0.09014511108398438,
    },
    {
      x: 8053.977272727273,
      y: 0.10010910034179688,
    },
    {
      x: 8058.238636363637,
      y: 0.10509490966796875,
    },
    {
      x: 8062.500000000001,
      y: 0.110076904296875,
    },
    {
      x: 8066.761363636364,
      y: 0.110076904296875,
    },
    {
      x: 8177.556818181819,
      y: 0.110076904296875,
    },
    {
      x: 8181.818181818182,
      y: 0.5884971618652344,
    },
    {
      x: 8186.079545454546,
      y: 0.4987945556640625,
    },
    {
      x: 8190.34090909091,
      y: 0.409088134765625,
    },
    {
      x: 8194.602272727274,
      y: 0.3193855285644531,
    },
    {
      x: 8198.863636363638,
      y: 0.2346649169921875,
    },
    {
      x: 8203.125,
      y: 0.13997650146484375,
    },
    {
      x: 8207.386363636364,
      y: 0.07020950317382812,
    },
    {
      x: 8211.647727272728,
      y: 0.050273895263671875,
    },
    {
      x: 8215.909090909092,
      y: 0.040309906005859375,
    },
    {
      x: 8220.170454545456,
      y: 0.0353240966796875,
    },
    {
      x: 8224.431818181818,
      y: 0.0353240966796875,
    },
    {
      x: 8228.693181818182,
      y: 0.040309906005859375,
    },
    {
      x: 8232.954545454546,
      y: 0.040309906005859375,
    },
    {
      x: 8241.477272727274,
      y: 0.040309906005859375,
    },
    {
      x: 8245.738636363638,
      y: 0.045291900634765625,
    },
    {
      x: 8250,
      y: 0.045291900634765625,
    },
    {
      x: 8262.784090909092,
      y: 0.045291900634765625,
    },
    {
      x: 8267.045454545456,
      y: 0.050273895263671875,
    },
    {
      x: 8271.306818181818,
      y: 0.050273895263671875,
    },
    {
      x: 8296.875,
      y: 0.050273895263671875,
    },
    {
      x: 8301.136363636364,
      y: 0.05525970458984375,
    },
    {
      x: 8305.397727272728,
      y: 0.05525970458984375,
    },
    {
      x: 8330.96590909091,
      y: 0.05525970458984375,
    },
    {
      x: 8335.227272727274,
      y: 0.06024169921875,
    },
    {
      x: 8339.488636363638,
      y: 0.06024169921875,
    },
    {
      x: 8365.056818181818,
      y: 0.06024169921875,
    },
    {
      x: 8369.318181818182,
      y: 0.06522369384765625,
    },
    {
      x: 8373.579545454546,
      y: 0.06522369384765625,
    },
    {
      x: 8399.147727272728,
      y: 0.06522369384765625,
    },
    {
      x: 8403.409090909092,
      y: 0.07020950317382812,
    },
    {
      x: 8407.670454545456,
      y: 0.07020950317382812,
    },
    {
      x: 8433.238636363638,
      y: 0.07020950317382812,
    },
    {
      x: 8437.5,
      y: 0.0751953125,
    },
    {
      x: 8441.761363636364,
      y: 0.0751953125,
    },
    {
      x: 8446.022727272728,
      y: 0.07020950317382812,
    },
    {
      x: 8450.284090909092,
      y: 0.06522369384765625,
    },
    {
      x: 8454.545454545456,
      y: 0.110076904296875,
    },
    {
      x: 8458.806818181818,
      y: 0.110076904296875,
    },
    {
      x: 8663.352272727274,
      y: 0.110076904296875,
    },
    {
      x: 8667.613636363638,
      y: 0.1349945068359375,
    },
    {
      x: 8671.875,
      y: 0.1997833251953125,
    },
    {
      x: 8676.136363636364,
      y: 0.27951812744140625,
    },
    {
      x: 8680.397727272728,
      y: 0.3592529296875,
    },
    {
      x: 8684.659090909092,
      y: 0.4439735412597656,
    },
    {
      x: 8688.920454545456,
      y: 0.523712158203125,
    },
    {
      x: 8693.181818181818,
      y: 0.6034469604492188,
    },
    {
      x: 8697.443181818182,
      y: 0.6831817626953125,
    },
    {
      x: 8701.704545454546,
      y: 0.7629203796386719,
    },
    {
      x: 8705.96590909091,
      y: 0.8426551818847656,
    },
    {
      x: 8710.227272727274,
      y: 0.8775367736816406,
    },
    {
      x: 8714.488636363638,
      y: 0.8376731872558594,
    },
    {
      x: 8718.75,
      y: 0.7579345703125,
    },
    {
      x: 8723.011363636364,
      y: 0.6781997680664062,
    },
    {
      x: 8727.272727272728,
      y: 0.110076904296875,
    },
    {
      x: 8731.534090909092,
      y: 0.110076904296875,
    },
    {
      x: 8995.738636363638,
      y: 0.110076904296875,
    },
    {
      x: 9000,
      y: 0.7629203796386719,
    },
    {
      x: 9004.261363636364,
      y: 0.8426551818847656,
    },
    {
      x: 9008.522727272728,
      y: 0.8775367736816406,
    },
    {
      x: 9012.784090909092,
      y: 0.8376731872558594,
    },
    {
      x: 9017.045454545456,
      y: 0.7579345703125,
    },
    {
      x: 9021.306818181818,
      y: 0.6781997680664062,
    },
    {
      x: 9025.568181818182,
      y: 0.5884971618652344,
    },
    {
      x: 9029.829545454546,
      y: 0.4987945556640625,
    },
    {
      x: 9034.09090909091,
      y: 0.409088134765625,
    },
    {
      x: 9038.352272727274,
      y: 0.3193855285644531,
    },
    {
      x: 9042.613636363638,
      y: 0.2346649169921875,
    },
    {
      x: 9046.875,
      y: 0.13997650146484375,
    },
    {
      x: 9051.136363636364,
      y: 0.07020950317382812,
    },
    {
      x: 9055.397727272728,
      y: 0.050273895263671875,
    },
    {
      x: 9059.659090909092,
      y: 0.040309906005859375,
    },
    {
      x: 9063.920454545456,
      y: 0.0353240966796875,
    },
    {
      x: 9068.181818181818,
      y: 0.0353240966796875,
    },
    {
      x: 9072.443181818182,
      y: 0.040309906005859375,
    },
    {
      x: 9076.704545454546,
      y: 0.040309906005859375,
    },
    {
      x: 9085.227272727274,
      y: 0.040309906005859375,
    },
    {
      x: 9089.488636363638,
      y: 0.045291900634765625,
    },
    {
      x: 9093.75,
      y: 0.045291900634765625,
    },
    {
      x: 9106.534090909092,
      y: 0.045291900634765625,
    },
    {
      x: 9110.795454545456,
      y: 0.050273895263671875,
    },
    {
      x: 9115.056818181818,
      y: 0.050273895263671875,
    },
    {
      x: 9140.625,
      y: 0.050273895263671875,
    },
    {
      x: 9144.886363636364,
      y: 0.05525970458984375,
    },
    {
      x: 9149.147727272728,
      y: 0.05525970458984375,
    },
    {
      x: 9174.71590909091,
      y: 0.05525970458984375,
    },
    {
      x: 9178.977272727274,
      y: 0.06024169921875,
    },
    {
      x: 9183.238636363638,
      y: 0.06024169921875,
    },
    {
      x: 9208.806818181818,
      y: 0.06024169921875,
    },
    {
      x: 9213.068181818182,
      y: 0.06522369384765625,
    },
    {
      x: 9217.329545454546,
      y: 0.06522369384765625,
    },
    {
      x: 9242.897727272728,
      y: 0.06522369384765625,
    },
    {
      x: 9247.159090909092,
      y: 0.07020950317382812,
    },
    {
      x: 9251.420454545456,
      y: 0.07020950317382812,
    },
    {
      x: 9276.988636363638,
      y: 0.07020950317382812,
    },
    {
      x: 9281.25,
      y: 0.0751953125,
    },
    {
      x: 9285.511363636364,
      y: 0.0751953125,
    },
    {
      x: 9289.772727272728,
      y: 0.07020950317382812,
    },
    {
      x: 9294.034090909092,
      y: 0.06522369384765625,
    },
    {
      x: 9298.295454545456,
      y: 0.06024169921875,
    },
    {
      x: 9302.556818181818,
      y: 0.05525970458984375,
    },
    {
      x: 9306.818181818182,
      y: 0.045291900634765625,
    },
    {
      x: 9311.079545454546,
      y: 0.0353240966796875,
    },
    {
      x: 9315.34090909091,
      y: 0.03034210205078125,
    },
    {
      x: 9319.602272727274,
      y: 0.020374298095703125,
    },
    {
      x: 9323.863636363638,
      y: 0.010406494140625,
    },
    {
      x: 9328.125,
      y: 0.0004425048828125,
    },
    {
      x: 9332.386363636364,
      y: -0.004543304443359375,
    },
    {
      x: 9336.647727272728,
      y: -0.0145111083984375,
    },
    {
      x: 9340.909090909092,
      y: -0.01949310302734375,
    },
    {
      x: 9345.170454545456,
      y: -0.024478912353515625,
    },
    {
      x: 9349.431818181818,
      y: -0.034442901611328125,
    },
    {
      x: 9353.693181818182,
      y: -0.04441070556640625,
    },
    {
      x: 9357.954545454546,
      y: -0.049396514892578125,
    },
    {
      x: 9362.21590909091,
      y: -0.05936431884765625,
    },
    {
      x: 9366.477272727274,
      y: -0.0643463134765625,
    },
    {
      x: 9370.738636363638,
      y: -0.074310302734375,
    },
    {
      x: 9375,
      y: -0.06932830810546875,
    },
    {
      x: 9379.261363636364,
      y: -0.05936431884765625,
    },
    {
      x: 9383.522727272728,
      y: -0.049396514892578125,
    },
    {
      x: 9387.784090909092,
      y: -0.0394287109375,
    },
    {
      x: 9392.045454545456,
      y: -0.024478912353515625,
    },
    {
      x: 9396.306818181818,
      y: -0.0145111083984375,
    },
    {
      x: 9400.568181818182,
      y: -0.004543304443359375,
    },
    {
      x: 9404.829545454546,
      y: 0.010406494140625,
    },
    {
      x: 9409.09090909091,
      y: 0.015392303466796875,
    },
    {
      x: 9413.352272727274,
      y: 0.025356292724609375,
    },
    {
      x: 9417.613636363638,
      y: 0.040309906005859375,
    },
    {
      x: 9421.875,
      y: 0.050273895263671875,
    },
    {
      x: 9426.136363636364,
      y: 0.06024169921875,
    },
    {
      x: 9438.920454545456,
      y: 0.09014511108398438,
    },
    {
      x: 9443.181818181818,
      y: 0.10010910034179688,
    },
    {
      x: 9447.443181818182,
      y: 0.10509490966796875,
    },
    {
      x: 9451.704545454546,
      y: 0.110076904296875,
    },
    {
      x: 9455.96590909091,
      y: 0.110076904296875,
    },
    {
      x: 9541.193181818182,
      y: 0.110076904296875,
    },
    {
      x: 9545.454545454546,
      y: 0.11506271362304688,
    },
    {
      x: 9549.71590909091,
      y: 0.11506271362304688,
    },
    {
      x: 9779.829545454546,
      y: 0.11506271362304688,
    },
    {
      x: 9784.09090909091,
      y: 0.13997650146484375,
    },
    {
      x: 9788.352272727274,
      y: 0.20476531982421875,
    },
    {
      x: 9792.613636363638,
      y: 0.27951812744140625,
    },
    {
      x: 9796.875,
      y: 0.3592529296875,
    },
    {
      x: 9801.136363636364,
      y: 0.4439735412597656,
    },
    {
      x: 9805.397727272728,
      y: 0.523712158203125,
    },
    {
      x: 9809.659090909092,
      y: 0.6034469604492188,
    },
    {
      x: 9813.920454545456,
      y: 0.6831817626953125,
    },
    {
      x: 9818.18181818182,
      y: 0.07020950317382812,
    },
    {
      x: 9822.443181818182,
      y: 0.07020950317382812,
    },
    {
      x: 9843.75,
      y: 0.07020950317382812,
    },
    {
      x: 9848.011363636364,
      y: 0.0751953125,
    },
    {
      x: 9852.272727272728,
      y: 0.0751953125,
    },
    {
      x: 9856.534090909092,
      y: 0.0751953125,
    },
    {
      x: 9860.795454545456,
      y: 0.07020950317382812,
    },
    {
      x: 9865.05681818182,
      y: 0.06522369384765625,
    },
    {
      x: 9869.318181818182,
      y: 0.06024169921875,
    },
    {
      x: 9873.579545454546,
      y: 0.05525970458984375,
    },
    {
      x: 9877.84090909091,
      y: 0.045291900634765625,
    },
    {
      x: 9882.102272727274,
      y: 0.040309906005859375,
    },
    {
      x: 9886.363636363638,
      y: 0.03034210205078125,
    },
    {
      x: 9894.886363636364,
      y: 0.010406494140625,
    },
    {
      x: 9899.147727272728,
      y: 0.00542449951171875,
    },
    {
      x: 9903.409090909092,
      y: -0.004543304443359375,
    },
    {
      x: 9907.670454545456,
      y: -0.00952911376953125,
    },
    {
      x: 9911.93181818182,
      y: -0.01949310302734375,
    },
    {
      x: 9916.193181818182,
      y: -0.024478912353515625,
    },
    {
      x: 9920.454545454546,
      y: -0.034442901611328125,
    },
    {
      x: 9924.71590909091,
      y: -0.0394287109375,
    },
    {
      x: 9928.977272727274,
      y: -0.049396514892578125,
    },
    {
      x: 9933.238636363638,
      y: -0.05936431884765625,
    },
    {
      x: 9937.5,
      y: -0.0643463134765625,
    },
    {
      x: 9941.761363636364,
      y: -0.074310302734375,
    },
    {
      x: 9946.022727272728,
      y: -0.06932830810546875,
    },
    {
      x: 9950.284090909092,
      y: -0.05936431884765625,
    },
    {
      x: 9954.545454545456,
      y: -0.049396514892578125,
    },
    {
      x: 9958.80681818182,
      y: -0.0394287109375,
    },
    {
      x: 9963.068181818182,
      y: -0.024478912353515625,
    },
    {
      x: 9967.329545454546,
      y: -0.0145111083984375,
    },
    {
      x: 9971.59090909091,
      y: 0.0004425048828125,
    },
    {
      x: 9975.852272727274,
      y: 0.010406494140625,
    },
    {
      x: 9980.113636363638,
      y: 0.015392303466796875,
    },
    {
      x: 9984.375,
      y: 0.025356292724609375,
    },
    {
      x: 9988.636363636364,
      y: 0.040309906005859375,
    },
    {
      x: 9992.897727272728,
      y: 0.050273895263671875,
    },
    {
      x: 9997.159090909092,
      y: 0.06024169921875,
    },
    {
      x: 10009.943181818182,
      y: 0.09014511108398438,
    },
    {
      x: 10014.204545454546,
      y: 0.10509490966796875,
    },
    {
      x: 10018.46590909091,
      y: 0.110076904296875,
    },
    {
      x: 10022.727272727274,
      y: 0.11506271362304688,
    },
    {
      x: 10026.988636363638,
      y: 0.11506271362304688,
    },
    {
      x: 10359.375,
      y: 0.11506271362304688,
    },
    {
      x: 10363.636363636364,
      y: 0.27951812744140625,
    },
    {
      x: 10367.897727272728,
      y: 0.3642387390136719,
    },
    {
      x: 10372.159090909092,
      y: 0.4439735412597656,
    },
    {
      x: 10376.420454545456,
      y: 0.523712158203125,
    },
    {
      x: 10380.68181818182,
      y: 0.6034469604492188,
    },
    {
      x: 10384.943181818182,
      y: 0.6831817626953125,
    },
    {
      x: 10389.204545454546,
      y: 0.7629203796386719,
    },
    {
      x: 10393.46590909091,
      y: 0.8426551818847656,
    },
    {
      x: 10397.727272727274,
      y: 0.8825225830078125,
    },
    {
      x: 10401.988636363638,
      y: 0.8376731872558594,
    },
    {
      x: 10406.25,
      y: 0.7629203796386719,
    },
    {
      x: 10410.511363636364,
      y: 0.6781997680664062,
    },
    {
      x: 10414.772727272728,
      y: 0.5884971618652344,
    },
    {
      x: 10419.034090909092,
      y: 0.4987945556640625,
    },
    {
      x: 10423.295454545456,
      y: 0.409088134765625,
    },
    {
      x: 10427.55681818182,
      y: 0.3193855285644531,
    },
    {
      x: 10431.818181818182,
      y: 0.2346649169921875,
    },
    {
      x: 10436.079545454546,
      y: 0.14496231079101562,
    },
    {
      x: 10440.34090909091,
      y: 0.0751953125,
    },
    {
      x: 10444.602272727274,
      y: 0.050273895263671875,
    },
    {
      x: 10448.863636363638,
      y: 0.040309906005859375,
    },
    {
      x: 10453.125,
      y: 0.0353240966796875,
    },
    {
      x: 10457.386363636364,
      y: 0.040309906005859375,
    },
    {
      x: 10461.647727272728,
      y: 0.040309906005859375,
    },
    {
      x: 10470.170454545456,
      y: 0.040309906005859375,
    },
    {
      x: 10474.43181818182,
      y: 0.045291900634765625,
    },
    {
      x: 10478.693181818182,
      y: 0.045291900634765625,
    },
    {
      x: 10491.477272727274,
      y: 0.045291900634765625,
    },
    {
      x: 10495.738636363638,
      y: 0.050273895263671875,
    },
    {
      x: 10500,
      y: 0.050273895263671875,
    },
    {
      x: 10525.568181818182,
      y: 0.050273895263671875,
    },
    {
      x: 10529.829545454546,
      y: 0.05525970458984375,
    },
    {
      x: 10534.09090909091,
      y: 0.05525970458984375,
    },
    {
      x: 10559.659090909092,
      y: 0.05525970458984375,
    },
    {
      x: 10563.920454545456,
      y: 0.06024169921875,
    },
    {
      x: 10568.18181818182,
      y: 0.06024169921875,
    },
    {
      x: 10593.75,
      y: 0.06024169921875,
    },
    {
      x: 10598.011363636364,
      y: 0.06522369384765625,
    },
    {
      x: 10602.272727272728,
      y: 0.06522369384765625,
    },
    {
      x: 10627.84090909091,
      y: 0.06522369384765625,
    },
    {
      x: 10632.102272727274,
      y: 0.07020950317382812,
    },
    {
      x: 10636.363636363638,
      y: 0.11506271362304688,
    },
    {
      x: 10640.625,
      y: 0.11506271362304688,
    },
    {
      x: 10896.30681818182,
      y: 0.11506271362304688,
    },
    {
      x: 10900.568181818182,
      y: 0.13997650146484375,
    },
    {
      x: 10904.829545454546,
      y: 0.20476531982421875,
    },
    {
      x: 10909.09090909091,
      y: 0.06024169921875,
    },
    {
      x: 10913.352272727274,
      y: 0.06522369384765625,
    },
    {
      x: 10917.613636363638,
      y: 0.06522369384765625,
    },
    {
      x: 10943.18181818182,
      y: 0.06522369384765625,
    },
    {
      x: 10947.443181818182,
      y: 0.07020950317382812,
    },
    {
      x: 10951.704545454546,
      y: 0.07020950317382812,
    },
    {
      x: 10973.011363636364,
      y: 0.07020950317382812,
    },
    {
      x: 10977.272727272728,
      y: 0.06522369384765625,
    },
    {
      x: 10981.534090909092,
      y: 0.06522369384765625,
    },
    {
      x: 10985.795454545456,
      y: 0.06522369384765625,
    },
    {
      x: 10990.05681818182,
      y: 0.05525970458984375,
    },
    {
      x: 10994.318181818182,
      y: 0.050273895263671875,
    },
    {
      x: 10998.579545454546,
      y: 0.040309906005859375,
    },
    {
      x: 11002.84090909091,
      y: 0.03034210205078125,
    },
    {
      x: 11007.102272727274,
      y: 0.025356292724609375,
    },
    {
      x: 11011.363636363638,
      y: 0.015392303466796875,
    },
    {
      x: 11015.625,
      y: 0.00542449951171875,
    },
    {
      x: 11019.886363636364,
      y: -0.004543304443359375,
    },
    {
      x: 11024.147727272728,
      y: -0.00952911376953125,
    },
    {
      x: 11028.409090909092,
      y: -0.0145111083984375,
    },
    {
      x: 11032.670454545456,
      y: -0.024478912353515625,
    },
    {
      x: 11036.93181818182,
      y: -0.029460906982421875,
    },
    {
      x: 11041.193181818182,
      y: -0.0394287109375,
    },
    {
      x: 11045.454545454546,
      y: -0.049396514892578125,
    },
    {
      x: 11049.71590909091,
      y: -0.054378509521484375,
    },
    {
      x: 11053.977272727274,
      y: -0.0643463134765625,
    },
    {
      x: 11058.238636363638,
      y: -0.074310302734375,
    },
    {
      x: 11062.5,
      y: -0.06932830810546875,
    },
    {
      x: 11066.761363636364,
      y: -0.05936431884765625,
    },
    {
      x: 11071.022727272728,
      y: -0.04441070556640625,
    },
    {
      x: 11075.284090909092,
      y: -0.034442901611328125,
    },
    {
      x: 11079.545454545456,
      y: -0.024478912353515625,
    },
    {
      x: 11083.80681818182,
      y: -0.0145111083984375,
    },
    {
      x: 11088.068181818182,
      y: 0.0004425048828125,
    },
    {
      x: 11092.329545454546,
      y: 0.010406494140625,
    },
    {
      x: 11096.59090909091,
      y: 0.020374298095703125,
    },
    {
      x: 11105.113636363638,
      y: 0.040309906005859375,
    },
    {
      x: 11109.375,
      y: 0.050273895263671875,
    },
    {
      x: 11113.636363636364,
      y: 0.06024169921875,
    },
    {
      x: 11117.897727272728,
      y: 0.07020950317382812,
    },
    {
      x: 11122.159090909092,
      y: 0.0851593017578125,
    },
    {
      x: 11126.420454545456,
      y: 0.09512710571289062,
    },
    {
      x: 11130.68181818182,
      y: 0.10509490966796875,
    },
    {
      x: 11134.943181818182,
      y: 0.110076904296875,
    },
    {
      x: 11139.204545454546,
      y: 0.11506271362304688,
    },
    {
      x: 11143.46590909091,
      y: 0.11506271362304688,
    },
    {
      x: 11467.329545454546,
      y: 0.11506271362304688,
    },
    {
      x: 11471.59090909091,
      y: 0.13997650146484375,
    },
    {
      x: 11475.852272727274,
      y: 0.20476531982421875,
    },
    {
      x: 11480.113636363638,
      y: 0.27951812744140625,
    },
    {
      x: 11484.375,
      y: 0.3642387390136719,
    },
    {
      x: 11488.636363636364,
      y: 0.4439735412597656,
    },
    {
      x: 11492.897727272728,
      y: 0.523712158203125,
    },
    {
      x: 11497.159090909092,
      y: 0.6034469604492188,
    },
    {
      x: 11501.420454545456,
      y: 0.6831817626953125,
    },
    {
      x: 11505.68181818182,
      y: 0.7629203796386719,
    },
    {
      x: 11509.943181818182,
      y: 0.8426551818847656,
    },
    {
      x: 11514.204545454546,
      y: 0.8825225830078125,
    },
    {
      x: 11518.46590909091,
      y: 0.8376731872558594,
    },
    {
      x: 11522.727272727274,
      y: 0.7629203796386719,
    },
    {
      x: 11526.988636363638,
      y: 0.6781997680664062,
    },
    {
      x: 11531.25,
      y: 0.5884971618652344,
    },
    {
      x: 11535.511363636364,
      y: 0.49381256103515625,
    },
    {
      x: 11539.772727272728,
      y: 0.409088134765625,
    },
    {
      x: 11544.034090909092,
      y: 0.3193855285644531,
    },
    {
      x: 11548.295454545456,
      y: 0.2346649169921875,
    },
    {
      x: 11552.55681818182,
      y: 0.13997650146484375,
    },
    {
      x: 11556.818181818182,
      y: 0.07020950317382812,
    },
    {
      x: 11561.079545454546,
      y: 0.045291900634765625,
    },
    {
      x: 11565.34090909091,
      y: 0.0353240966796875,
    },
    {
      x: 11569.602272727274,
      y: 0.03034210205078125,
    },
    {
      x: 11573.863636363638,
      y: 0.0353240966796875,
    },
    {
      x: 11578.125,
      y: 0.0353240966796875,
    },
    {
      x: 11586.647727272728,
      y: 0.0353240966796875,
    },
    {
      x: 11590.909090909092,
      y: 0.040309906005859375,
    },
    {
      x: 11595.170454545456,
      y: 0.040309906005859375,
    },
    {
      x: 11603.693181818182,
      y: 0.040309906005859375,
    },
    {
      x: 11607.954545454546,
      y: 0.045291900634765625,
    },
    {
      x: 11612.21590909091,
      y: 0.045291900634765625,
    },
    {
      x: 11625,
      y: 0.045291900634765625,
    },
    {
      x: 11629.261363636364,
      y: 0.050273895263671875,
    },
    {
      x: 11633.522727272728,
      y: 0.050273895263671875,
    },
    {
      x: 11659.09090909091,
      y: 0.050273895263671875,
    },
    {
      x: 11663.352272727274,
      y: 0.05525970458984375,
    },
    {
      x: 11667.613636363638,
      y: 0.05525970458984375,
    },
    {
      x: 11693.18181818182,
      y: 0.05525970458984375,
    },
    {
      x: 11697.443181818182,
      y: 0.06024169921875,
    },
    {
      x: 11701.704545454546,
      y: 0.06024169921875,
    },
    {
      x: 11723.011363636364,
      y: 0.06024169921875,
    },
    {
      x: 11727.272727272728,
      y: 0.11506271362304688,
    },
    {
      x: 11731.534090909092,
      y: 0.11506271362304688,
    },
    {
      x: 12268.46590909091,
      y: 0.11506271362304688,
    },
    {
      x: 12272.727272727274,
      y: 0.06024169921875,
    },
    {
      x: 12276.988636363638,
      y: 0.06024169921875,
    },
    {
      x: 12298.295454545456,
      y: 0.06024169921875,
    },
    {
      x: 12302.55681818182,
      y: 0.06522369384765625,
    },
    {
      x: 12306.818181818182,
      y: 0.06522369384765625,
    },
    {
      x: 12332.386363636364,
      y: 0.06522369384765625,
    },
    {
      x: 12336.647727272728,
      y: 0.07020950317382812,
    },
    {
      x: 12340.909090909092,
      y: 0.07020950317382812,
    },
    {
      x: 12362.21590909091,
      y: 0.07020950317382812,
    },
    {
      x: 12366.477272727274,
      y: 0.06522369384765625,
    },
    {
      x: 12370.738636363638,
      y: 0.06522369384765625,
    },
    {
      x: 12375.000000000002,
      y: 0.06522369384765625,
    },
    {
      x: 12379.261363636364,
      y: 0.05525970458984375,
    },
    {
      x: 12383.522727272728,
      y: 0.050273895263671875,
    },
    {
      x: 12387.784090909092,
      y: 0.040309906005859375,
    },
    {
      x: 12392.045454545456,
      y: 0.03034210205078125,
    },
    {
      x: 12396.30681818182,
      y: 0.025356292724609375,
    },
    {
      x: 12400.568181818182,
      y: 0.015392303466796875,
    },
    {
      x: 12404.829545454546,
      y: 0.00542449951171875,
    },
    {
      x: 12409.09090909091,
      y: -0.004543304443359375,
    },
    {
      x: 12413.352272727274,
      y: -0.00952911376953125,
    },
    {
      x: 12417.613636363638,
      y: -0.0145111083984375,
    },
    {
      x: 12421.875000000002,
      y: -0.024478912353515625,
    },
    {
      x: 12426.136363636364,
      y: -0.029460906982421875,
    },
    {
      x: 12430.397727272728,
      y: -0.0394287109375,
    },
    {
      x: 12434.659090909092,
      y: -0.049396514892578125,
    },
    {
      x: 12438.920454545456,
      y: -0.054378509521484375,
    },
    {
      x: 12443.18181818182,
      y: -0.0643463134765625,
    },
    {
      x: 12447.443181818182,
      y: -0.074310302734375,
    },
    {
      x: 12451.704545454546,
      y: -0.0643463134765625,
    },
    {
      x: 12455.96590909091,
      y: -0.05936431884765625,
    },
    {
      x: 12460.227272727274,
      y: -0.04441070556640625,
    },
    {
      x: 12464.488636363638,
      y: -0.034442901611328125,
    },
    {
      x: 12468.750000000002,
      y: -0.024478912353515625,
    },
    {
      x: 12473.011363636364,
      y: -0.0145111083984375,
    },
    {
      x: 12477.272727272728,
      y: 0.0004425048828125,
    },
    {
      x: 12481.534090909092,
      y: 0.010406494140625,
    },
    {
      x: 12485.795454545456,
      y: 0.020374298095703125,
    },
    {
      x: 12494.318181818182,
      y: 0.040309906005859375,
    },
    {
      x: 12498.579545454546,
      y: 0.050273895263671875,
    },
    {
      x: 12502.84090909091,
      y: 0.06024169921875,
    },
    {
      x: 12507.102272727274,
      y: 0.07020950317382812,
    },
    {
      x: 12511.363636363638,
      y: 0.0851593017578125,
    },
    {
      x: 12515.625000000002,
      y: 0.09512710571289062,
    },
    {
      x: 12519.886363636364,
      y: 0.10509490966796875,
    },
    {
      x: 12524.147727272728,
      y: 0.110076904296875,
    },
    {
      x: 12528.409090909092,
      y: 0.11506271362304688,
    },
    {
      x: 12532.670454545456,
      y: 0.11506271362304688,
    },
    {
      x: 12583.80681818182,
      y: 0.11506271362304688,
    },
    {
      x: 12588.068181818182,
      y: 0.13997650146484375,
    },
    {
      x: 12592.329545454546,
      y: 0.20476531982421875,
    },
    {
      x: 12596.59090909091,
      y: 0.27951812744140625,
    },
    {
      x: 12600.852272727274,
      y: 0.3642387390136719,
    },
    {
      x: 12605.113636363638,
      y: 0.4439735412597656,
    },
    {
      x: 12609.375000000002,
      y: 0.523712158203125,
    },
    {
      x: 12613.636363636364,
      y: 0.6034469604492188,
    },
    {
      x: 12617.897727272728,
      y: 0.6831817626953125,
    },
    {
      x: 12622.159090909092,
      y: 0.7629203796386719,
    },
    {
      x: 12626.420454545456,
      y: 0.8426551818847656,
    },
    {
      x: 12630.68181818182,
      y: 0.8825225830078125,
    },
    {
      x: 12634.943181818182,
      y: 0.8376731872558594,
    },
    {
      x: 12639.204545454546,
      y: 0.7629203796386719,
    },
    {
      x: 12643.46590909091,
      y: 0.6781997680664062,
    },
    {
      x: 12647.727272727274,
      y: 0.5884971618652344,
    },
    {
      x: 12651.988636363638,
      y: 0.49381256103515625,
    },
    {
      x: 12656.250000000002,
      y: 0.409088134765625,
    },
    {
      x: 12660.511363636364,
      y: 0.3193855285644531,
    },
    {
      x: 12664.772727272728,
      y: 0.2346649169921875,
    },
    {
      x: 12669.034090909092,
      y: 0.13997650146484375,
    },
    {
      x: 12673.295454545456,
      y: 0.07020950317382812,
    },
    {
      x: 12677.55681818182,
      y: 0.045291900634765625,
    },
    {
      x: 12681.818181818182,
      y: 0.0353240966796875,
    },
    {
      x: 12686.079545454546,
      y: 0.03034210205078125,
    },
    {
      x: 12690.34090909091,
      y: 0.0353240966796875,
    },
    {
      x: 12694.602272727274,
      y: 0.0353240966796875,
    },
    {
      x: 12703.125000000002,
      y: 0.0353240966796875,
    },
    {
      x: 12707.386363636364,
      y: 0.040309906005859375,
    },
    {
      x: 12711.647727272728,
      y: 0.040309906005859375,
    },
    {
      x: 12720.170454545456,
      y: 0.040309906005859375,
    },
    {
      x: 12724.43181818182,
      y: 0.045291900634765625,
    },
    {
      x: 12728.693181818182,
      y: 0.045291900634765625,
    },
    {
      x: 12741.477272727274,
      y: 0.045291900634765625,
    },
    {
      x: 12745.738636363638,
      y: 0.050273895263671875,
    },
    {
      x: 12750.000000000002,
      y: 0.050273895263671875,
    },
    {
      x: 12775.568181818182,
      y: 0.050273895263671875,
    },
    {
      x: 12779.829545454546,
      y: 0.05525970458984375,
    },
    {
      x: 12784.09090909091,
      y: 0.05525970458984375,
    },
    {
      x: 12809.659090909092,
      y: 0.05525970458984375,
    },
    {
      x: 12813.920454545456,
      y: 0.06024169921875,
    },
    {
      x: 12818.18181818182,
      y: 0.11506271362304688,
    },
    {
      x: 12822.443181818182,
      y: 0.11506271362304688,
    },
    {
      x: 13086.647727272728,
      y: 0.11506271362304688,
    },
    {
      x: 13090.909090909092,
      y: 0.10509490966796875,
    },
    {
      x: 13095.170454545456,
      y: 0.110076904296875,
    },
    {
      x: 13099.43181818182,
      y: 0.11506271362304688,
    },
    {
      x: 13103.693181818182,
      y: 0.11506271362304688,
    },
    {
      x: 13427.55681818182,
      y: 0.11506271362304688,
    },
    {
      x: 13431.818181818182,
      y: 0.13997650146484375,
    },
    {
      x: 13436.079545454546,
      y: 0.20476531982421875,
    },
    {
      x: 13440.34090909091,
      y: 0.27951812744140625,
    },
    {
      x: 13444.602272727274,
      y: 0.3642387390136719,
    },
    {
      x: 13448.863636363638,
      y: 0.4439735412597656,
    },
    {
      x: 13453.125000000002,
      y: 0.523712158203125,
    },
    {
      x: 13457.386363636364,
      y: 0.6034469604492188,
    },
    {
      x: 13461.647727272728,
      y: 0.6831817626953125,
    },
    {
      x: 13465.909090909092,
      y: 0.7629203796386719,
    },
    {
      x: 13470.170454545456,
      y: 0.8426551818847656,
    },
    {
      x: 13474.43181818182,
      y: 0.8825225830078125,
    },
    {
      x: 13478.693181818182,
      y: 0.8376731872558594,
    },
    {
      x: 13482.954545454546,
      y: 0.7629203796386719,
    },
    {
      x: 13487.21590909091,
      y: 0.6781997680664062,
    },
    {
      x: 13491.477272727274,
      y: 0.5884971618652344,
    },
    {
      x: 13495.738636363638,
      y: 0.49381256103515625,
    },
    {
      x: 13500.000000000002,
      y: 0.409088134765625,
    },
    {
      x: 13504.261363636364,
      y: 0.3193855285644531,
    },
    {
      x: 13508.522727272728,
      y: 0.2346649169921875,
    },
    {
      x: 13512.784090909092,
      y: 0.13997650146484375,
    },
    {
      x: 13517.045454545456,
      y: 0.07020950317382812,
    },
    {
      x: 13521.30681818182,
      y: 0.045291900634765625,
    },
    {
      x: 13525.568181818182,
      y: 0.0353240966796875,
    },
    {
      x: 13529.829545454546,
      y: 0.03034210205078125,
    },
    {
      x: 13534.09090909091,
      y: 0.0353240966796875,
    },
    {
      x: 13538.352272727274,
      y: 0.0353240966796875,
    },
    {
      x: 13546.875000000002,
      y: 0.0353240966796875,
    },
    {
      x: 13551.136363636364,
      y: 0.040309906005859375,
    },
    {
      x: 13555.397727272728,
      y: 0.040309906005859375,
    },
    {
      x: 13563.920454545456,
      y: 0.040309906005859375,
    },
    {
      x: 13568.18181818182,
      y: 0.045291900634765625,
    },
    {
      x: 13572.443181818182,
      y: 0.045291900634765625,
    },
    {
      x: 13585.227272727274,
      y: 0.045291900634765625,
    },
    {
      x: 13589.488636363638,
      y: 0.050273895263671875,
    },
    {
      x: 13593.750000000002,
      y: 0.050273895263671875,
    },
    {
      x: 13619.318181818182,
      y: 0.050273895263671875,
    },
    {
      x: 13623.579545454546,
      y: 0.05525970458984375,
    },
    {
      x: 13627.84090909091,
      y: 0.05525970458984375,
    },
    {
      x: 13653.409090909092,
      y: 0.05525970458984375,
    },
    {
      x: 13657.670454545456,
      y: 0.06024169921875,
    },
    {
      x: 13661.93181818182,
      y: 0.06024169921875,
    },
    {
      x: 13687.500000000002,
      y: 0.06024169921875,
    },
    {
      x: 13691.761363636364,
      y: 0.06522369384765625,
    },
    {
      x: 13696.022727272728,
      y: 0.06522369384765625,
    },
    {
      x: 13721.59090909091,
      y: 0.06522369384765625,
    },
    {
      x: 13725.852272727274,
      y: 0.07020950317382812,
    },
    {
      x: 13730.113636363638,
      y: 0.07020950317382812,
    },
    {
      x: 13751.420454545456,
      y: 0.07020950317382812,
    },
    {
      x: 13755.68181818182,
      y: 0.06522369384765625,
    },
    {
      x: 13759.943181818182,
      y: 0.06522369384765625,
    },
    {
      x: 13764.204545454546,
      y: 0.06522369384765625,
    },
    {
      x: 13768.46590909091,
      y: 0.05525970458984375,
    },
    {
      x: 13772.727272727274,
      y: 0.050273895263671875,
    },
    {
      x: 13776.988636363638,
      y: 0.040309906005859375,
    },
    {
      x: 13781.250000000002,
      y: 0.03034210205078125,
    },
    {
      x: 13785.511363636364,
      y: 0.025356292724609375,
    },
    {
      x: 13789.772727272728,
      y: 0.015392303466796875,
    },
    {
      x: 13794.034090909092,
      y: 0.00542449951171875,
    },
    {
      x: 13798.295454545456,
      y: -0.004543304443359375,
    },
    {
      x: 13802.55681818182,
      y: -0.00952911376953125,
    },
    {
      x: 13806.818181818182,
      y: -0.0145111083984375,
    },
    {
      x: 13811.079545454546,
      y: -0.024478912353515625,
    },
    {
      x: 13815.34090909091,
      y: -0.029460906982421875,
    },
    {
      x: 13819.602272727274,
      y: -0.04441070556640625,
    },
    {
      x: 13823.863636363638,
      y: -0.049396514892578125,
    },
    {
      x: 13828.125000000002,
      y: -0.054378509521484375,
    },
    {
      x: 13832.386363636364,
      y: -0.0643463134765625,
    },
    {
      x: 13836.647727272728,
      y: -0.074310302734375,
    },
    {
      x: 13840.909090909092,
      y: -0.06932830810546875,
    },
    {
      x: 13845.170454545456,
      y: -0.05936431884765625,
    },
    {
      x: 13849.43181818182,
      y: -0.04441070556640625,
    },
    {
      x: 13853.693181818182,
      y: -0.034442901611328125,
    },
    {
      x: 13857.954545454546,
      y: -0.024478912353515625,
    },
    {
      x: 13862.21590909091,
      y: -0.0145111083984375,
    },
    {
      x: 13866.477272727274,
      y: 0.0004425048828125,
    },
    {
      x: 13870.738636363638,
      y: 0.010406494140625,
    },
    {
      x: 13875.000000000002,
      y: 0.020374298095703125,
    },
    {
      x: 13883.522727272728,
      y: 0.040309906005859375,
    },
    {
      x: 13887.784090909092,
      y: 0.050273895263671875,
    },
    {
      x: 13892.045454545456,
      y: 0.06024169921875,
    },
    {
      x: 13896.30681818182,
      y: 0.07020950317382812,
    },
    {
      x: 13900.568181818182,
      y: 0.0851593017578125,
    },
    {
      x: 13904.829545454546,
      y: 0.09512710571289062,
    },
    {
      x: 13909.09090909091,
      y: 0.11506271362304688,
    },
    {
      x: 13913.352272727274,
      y: 0.11506271362304688,
    },
    {
      x: 14177.55681818182,
      y: 0.11506271362304688,
    },
    {
      x: 14181.818181818184,
      y: 0.040309906005859375,
    },
    {
      x: 14186.079545454546,
      y: 0.050273895263671875,
    },
    {
      x: 14190.34090909091,
      y: 0.06024169921875,
    },
    {
      x: 14194.602272727274,
      y: 0.07020950317382812,
    },
    {
      x: 14198.863636363638,
      y: 0.0851593017578125,
    },
    {
      x: 14203.125000000002,
      y: 0.09512710571289062,
    },
    {
      x: 14207.386363636364,
      y: 0.10509490966796875,
    },
    {
      x: 14211.647727272728,
      y: 0.110076904296875,
    },
    {
      x: 14215.909090909092,
      y: 0.11506271362304688,
    },
    {
      x: 14220.170454545456,
      y: 0.11506271362304688,
    },
    {
      x: 14450.284090909092,
      y: 0.11506271362304688,
    },
    {
      x: 14454.545454545456,
      y: 0.050273895263671875,
    },
    {
      x: 14458.80681818182,
      y: 0.050273895263671875,
    },
    {
      x: 14463.068181818184,
      y: 0.050273895263671875,
    },
    {
      x: 14467.329545454546,
      y: 0.05525970458984375,
    },
    {
      x: 14471.59090909091,
      y: 0.05525970458984375,
    },
    {
      x: 14497.159090909092,
      y: 0.05525970458984375,
    },
    {
      x: 14501.420454545456,
      y: 0.06024169921875,
    },
    {
      x: 14505.68181818182,
      y: 0.06024169921875,
    },
    {
      x: 14531.250000000002,
      y: 0.06024169921875,
    },
    {
      x: 14535.511363636364,
      y: 0.06522369384765625,
    },
    {
      x: 14539.772727272728,
      y: 0.06522369384765625,
    },
    {
      x: 14565.34090909091,
      y: 0.06522369384765625,
    },
    {
      x: 14569.602272727274,
      y: 0.07020950317382812,
    },
    {
      x: 14573.863636363638,
      y: 0.07020950317382812,
    },
    {
      x: 14595.170454545456,
      y: 0.07020950317382812,
    },
    {
      x: 14599.43181818182,
      y: 0.06522369384765625,
    },
    {
      x: 14603.693181818184,
      y: 0.06522369384765625,
    },
    {
      x: 14607.954545454546,
      y: 0.06522369384765625,
    },
    {
      x: 14612.21590909091,
      y: 0.05525970458984375,
    },
    {
      x: 14616.477272727274,
      y: 0.050273895263671875,
    },
    {
      x: 14620.738636363638,
      y: 0.040309906005859375,
    },
    {
      x: 14625.000000000002,
      y: 0.03034210205078125,
    },
    {
      x: 14629.261363636364,
      y: 0.025356292724609375,
    },
    {
      x: 14633.522727272728,
      y: 0.015392303466796875,
    },
    {
      x: 14637.784090909092,
      y: 0.00542449951171875,
    },
    {
      x: 14642.045454545456,
      y: -0.004543304443359375,
    },
    {
      x: 14646.30681818182,
      y: -0.00952911376953125,
    },
    {
      x: 14650.568181818184,
      y: -0.0145111083984375,
    },
    {
      x: 14654.829545454546,
      y: -0.024478912353515625,
    },
    {
      x: 14659.09090909091,
      y: -0.029460906982421875,
    },
    {
      x: 14663.352272727274,
      y: -0.0394287109375,
    },
    {
      x: 14671.875000000002,
      y: -0.05936431884765625,
    },
    {
      x: 14676.136363636364,
      y: -0.0643463134765625,
    },
    {
      x: 14680.397727272728,
      y: -0.074310302734375,
    },
    {
      x: 14684.659090909092,
      y: -0.06932830810546875,
    },
    {
      x: 14688.920454545456,
      y: -0.05936431884765625,
    },
    {
      x: 14693.18181818182,
      y: -0.04441070556640625,
    },
    {
      x: 14697.443181818184,
      y: -0.034442901611328125,
    },
    {
      x: 14701.704545454546,
      y: -0.024478912353515625,
    },
    {
      x: 14705.96590909091,
      y: -0.0145111083984375,
    },
    {
      x: 14710.227272727274,
      y: 0.0004425048828125,
    },
    {
      x: 14714.488636363638,
      y: 0.010406494140625,
    },
    {
      x: 14718.750000000002,
      y: 0.020374298095703125,
    },
    {
      x: 14723.011363636364,
      y: 0.03034210205078125,
    },
    {
      x: 14727.272727272728,
      y: 0.11506271362304688,
    },
    {
      x: 14731.534090909092,
      y: 0.11506271362304688,
    },
    {
      x: 14816.761363636364,
      y: 0.11506271362304688,
    },
    {
      x: 14821.022727272728,
      y: 0.13997650146484375,
    },
    {
      x: 14825.284090909092,
      y: 0.20476531982421875,
    },
    {
      x: 14829.545454545456,
      y: 0.27951812744140625,
    },
    {
      x: 14833.80681818182,
      y: 0.3642387390136719,
    },
    {
      x: 14838.068181818184,
      y: 0.4439735412597656,
    },
    {
      x: 14842.329545454546,
      y: 0.523712158203125,
    },
    {
      x: 14846.59090909091,
      y: 0.6034469604492188,
    },
    {
      x: 14850.852272727274,
      y: 0.6831817626953125,
    },
    {
      x: 14855.113636363638,
      y: 0.7629203796386719,
    },
    {
      x: 14859.375000000002,
      y: 0.8426551818847656,
    },
    {
      x: 14863.636363636364,
      y: 0.8825225830078125,
    },
    {
      x: 14867.897727272728,
      y: 0.8376731872558594,
    },
    {
      x: 14872.159090909092,
      y: 0.7629203796386719,
    },
    {
      x: 14876.420454545456,
      y: 0.6781997680664062,
    },
    {
      x: 14880.68181818182,
      y: 0.5884971618652344,
    },
    {
      x: 14884.943181818184,
      y: 0.49381256103515625,
    },
    {
      x: 14889.204545454546,
      y: 0.409088134765625,
    },
    {
      x: 14893.46590909091,
      y: 0.3193855285644531,
    },
    {
      x: 14897.727272727274,
      y: 0.2346649169921875,
    },
    {
      x: 14901.988636363638,
      y: 0.13997650146484375,
    },
    {
      x: 14906.250000000002,
      y: 0.07020950317382812,
    },
    {
      x: 14910.511363636364,
      y: 0.045291900634765625,
    },
    {
      x: 14914.772727272728,
      y: 0.0353240966796875,
    },
    {
      x: 14919.034090909092,
      y: 0.03034210205078125,
    },
    {
      x: 14923.295454545456,
      y: 0.0353240966796875,
    },
    {
      x: 14927.55681818182,
      y: 0.0353240966796875,
    },
    {
      x: 14936.079545454546,
      y: 0.0353240966796875,
    },
    {
      x: 14940.34090909091,
      y: 0.040309906005859375,
    },
    {
      x: 14944.602272727274,
      y: 0.040309906005859375,
    },
    {
      x: 14953.125000000002,
      y: 0.040309906005859375,
    },
    {
      x: 14957.386363636364,
      y: 0.045291900634765625,
    },
    {
      x: 14961.647727272728,
      y: 0.045291900634765625,
    },
    {
      x: 14974.43181818182,
      y: 0.045291900634765625,
    },
    {
      x: 14978.693181818184,
      y: 0.050273895263671875,
    },
    {
      x: 14982.954545454546,
      y: 0.050273895263671875,
    },
    {
      x: 14995.738636363638,
      y: 0.050273895263671875,
    },
    {
      x: 15000.000000000002,
      y: 0.11506271362304688,
    },
    {
      x: 15004.261363636364,
      y: 0.11506271362304688,
    },
    {
      x: 15268.46590909091,
      y: 0.11506271362304688,
    },
    {
      x: 15272.727272727274,
      y: -0.024478912353515625,
    },
    {
      x: 15276.988636363638,
      y: -0.0145111083984375,
    },
    {
      x: 15281.250000000002,
      y: 0.0004425048828125,
    },
    {
      x: 15285.511363636364,
      y: 0.010406494140625,
    },
    {
      x: 15289.772727272728,
      y: 0.020374298095703125,
    },
    {
      x: 15298.295454545456,
      y: 0.040309906005859375,
    },
    {
      x: 15302.55681818182,
      y: 0.050273895263671875,
    },
    {
      x: 15306.818181818184,
      y: 0.06024169921875,
    },
    {
      x: 15311.079545454546,
      y: 0.07020950317382812,
    },
    {
      x: 15315.34090909091,
      y: 0.0851593017578125,
    },
    {
      x: 15319.602272727274,
      y: 0.09512710571289062,
    },
    {
      x: 15323.863636363638,
      y: 0.10509490966796875,
    },
    {
      x: 15328.125000000002,
      y: 0.110076904296875,
    },
    {
      x: 15332.386363636364,
      y: 0.11506271362304688,
    },
    {
      x: 15336.647727272728,
      y: 0.11506271362304688,
    },
    {
      x: 15660.511363636364,
      y: 0.11506271362304688,
    },
    {
      x: 15664.772727272728,
      y: 0.13997650146484375,
    },
    {
      x: 15669.034090909092,
      y: 0.20476531982421875,
    },
    {
      x: 15673.295454545456,
      y: 0.27951812744140625,
    },
    {
      x: 15677.55681818182,
      y: 0.3642387390136719,
    },
    {
      x: 15681.818181818184,
      y: 0.4439735412597656,
    },
    {
      x: 15686.079545454546,
      y: 0.523712158203125,
    },
    {
      x: 15690.34090909091,
      y: 0.6034469604492188,
    },
    {
      x: 15694.602272727274,
      y: 0.6831817626953125,
    },
    {
      x: 15698.863636363638,
      y: 0.7629203796386719,
    },
    {
      x: 15703.125000000002,
      y: 0.8426551818847656,
    },
    {
      x: 15707.386363636364,
      y: 0.8825225830078125,
    },
    {
      x: 15711.647727272728,
      y: 0.8376731872558594,
    },
    {
      x: 15715.909090909092,
      y: 0.7629203796386719,
    },
    {
      x: 15720.170454545456,
      y: 0.6781997680664062,
    },
    {
      x: 15724.43181818182,
      y: 0.5884971618652344,
    },
    {
      x: 15728.693181818184,
      y: 0.49381256103515625,
    },
    {
      x: 15732.954545454546,
      y: 0.409088134765625,
    },
    {
      x: 15737.21590909091,
      y: 0.3193855285644531,
    },
    {
      x: 15741.477272727274,
      y: 0.2346649169921875,
    },
    {
      x: 15745.738636363638,
      y: 0.13997650146484375,
    },
    {
      x: 15750.000000000002,
      y: 0.07020950317382812,
    },
    {
      x: 15754.261363636364,
      y: 0.045291900634765625,
    },
    {
      x: 15758.522727272728,
      y: 0.0353240966796875,
    },
    {
      x: 15762.784090909092,
      y: 0.03034210205078125,
    },
    {
      x: 15767.045454545456,
      y: 0.0353240966796875,
    },
    {
      x: 15771.30681818182,
      y: 0.0353240966796875,
    },
    {
      x: 15779.829545454546,
      y: 0.0353240966796875,
    },
    {
      x: 15784.09090909091,
      y: 0.040309906005859375,
    },
    {
      x: 15788.352272727274,
      y: 0.040309906005859375,
    },
    {
      x: 15796.875000000002,
      y: 0.040309906005859375,
    },
    {
      x: 15801.136363636364,
      y: 0.045291900634765625,
    },
    {
      x: 15805.397727272728,
      y: 0.045291900634765625,
    },
    {
      x: 15818.18181818182,
      y: 0.045291900634765625,
    },
    {
      x: 15822.443181818184,
      y: 0.050273895263671875,
    },
    {
      x: 15826.704545454546,
      y: 0.050273895263671875,
    },
    {
      x: 15852.272727272728,
      y: 0.050273895263671875,
    },
    {
      x: 15856.534090909092,
      y: 0.05525970458984375,
    },
    {
      x: 15860.795454545456,
      y: 0.05525970458984375,
    },
    {
      x: 15886.363636363638,
      y: 0.05525970458984375,
    },
    {
      x: 15890.625000000002,
      y: 0.06024169921875,
    },
    {
      x: 15894.886363636364,
      y: 0.06024169921875,
    },
    {
      x: 15920.454545454546,
      y: 0.06024169921875,
    },
    {
      x: 15924.71590909091,
      y: 0.06522369384765625,
    },
    {
      x: 15928.977272727274,
      y: 0.06522369384765625,
    },
    {
      x: 15954.545454545456,
      y: 0.06522369384765625,
    },
    {
      x: 15958.80681818182,
      y: 0.07020950317382812,
    },
    {
      x: 15963.068181818184,
      y: 0.07020950317382812,
    },
    {
      x: 15984.375000000002,
      y: 0.07020950317382812,
    },
    {
      x: 15988.636363636364,
      y: 0.06522369384765625,
    },
    {
      x: 15992.897727272728,
      y: 0.06522369384765625,
    },
    {
      x: 15997.159090909092,
      y: 0.06522369384765625,
    },
    {
      x: 16001.420454545456,
      y: 0.05525970458984375,
    },
    {
      x: 16005.68181818182,
      y: 0.050273895263671875,
    },
    {
      x: 16009.943181818184,
      y: 0.040309906005859375,
    },
    {
      x: 16014.204545454546,
      y: 0.03034210205078125,
    },
    {
      x: 16018.46590909091,
      y: 0.025356292724609375,
    },
    {
      x: 16022.727272727274,
      y: 0.015392303466796875,
    },
    {
      x: 16026.988636363638,
      y: 0.00542449951171875,
    },
    {
      x: 16031.250000000002,
      y: -0.004543304443359375,
    },
    {
      x: 16035.511363636364,
      y: -0.00952911376953125,
    },
    {
      x: 16039.772727272728,
      y: -0.0145111083984375,
    },
    {
      x: 16044.034090909092,
      y: -0.024478912353515625,
    },
    {
      x: 16048.295454545456,
      y: -0.029460906982421875,
    },
    {
      x: 16052.55681818182,
      y: -0.0394287109375,
    },
    {
      x: 16056.818181818184,
      y: -0.049396514892578125,
    },
    {
      x: 16061.079545454546,
      y: -0.054378509521484375,
    },
    {
      x: 16065.34090909091,
      y: -0.0643463134765625,
    },
    {
      x: 16069.602272727274,
      y: -0.074310302734375,
    },
    {
      x: 16073.863636363638,
      y: -0.06932830810546875,
    },
    {
      x: 16078.125000000002,
      y: -0.05936431884765625,
    },
    {
      x: 16082.386363636364,
      y: -0.04441070556640625,
    },
    {
      x: 16086.647727272728,
      y: -0.034442901611328125,
    },
    {
      x: 16090.909090909092,
      y: 0.11506271362304688,
    },
    {
      x: 16095.170454545456,
      y: 0.11506271362304688,
    },
    {
      x: 16359.375000000002,
      y: 0.11506271362304688,
    },
    {
      x: 16363.636363636364,
      y: -0.0643463134765625,
    },
    {
      x: 16367.897727272728,
      y: -0.074310302734375,
    },
    {
      x: 16372.159090909092,
      y: -0.0643463134765625,
    },
    {
      x: 16376.420454545456,
      y: -0.05936431884765625,
    },
    {
      x: 16380.68181818182,
      y: -0.04441070556640625,
    },
    {
      x: 16384.943181818184,
      y: -0.034442901611328125,
    },
    {
      x: 16389.204545454548,
      y: -0.024478912353515625,
    },
    {
      x: 16393.46590909091,
      y: -0.0145111083984375,
    },
    {
      x: 16397.727272727276,
      y: 0.0004425048828125,
    },
    {
      x: 16401.988636363636,
      y: 0.010406494140625,
    },
    {
      x: 16406.25,
      y: 0.020374298095703125,
    },
    {
      x: 16414.772727272728,
      y: 0.040309906005859375,
    },
    {
      x: 16419.034090909092,
      y: 0.050273895263671875,
    },
    {
      x: 16423.295454545456,
      y: 0.06024169921875,
    },
    {
      x: 16427.55681818182,
      y: 0.07020950317382812,
    },
    {
      x: 16431.818181818184,
      y: 0.0851593017578125,
    },
    {
      x: 16436.079545454548,
      y: 0.09512710571289062,
    },
    {
      x: 16440.34090909091,
      y: 0.10509490966796875,
    },
    {
      x: 16444.602272727276,
      y: 0.110076904296875,
    },
    {
      x: 16448.863636363636,
      y: 0.11506271362304688,
    },
    {
      x: 16453.125,
      y: 0.11506271362304688,
    },
    {
      x: 16632.102272727276,
      y: 0.11506271362304688,
    },
    {
      x: 16636.363636363636,
      y: 0.040309906005859375,
    },
    {
      x: 16640.625,
      y: 0.040309906005859375,
    },
    {
      x: 16644.886363636364,
      y: 0.045291900634765625,
    },
    {
      x: 16649.147727272728,
      y: 0.045291900634765625,
    },
    {
      x: 16661.93181818182,
      y: 0.045291900634765625,
    },
    {
      x: 16666.193181818184,
      y: 0.050273895263671875,
    },
    {
      x: 16670.454545454548,
      y: 0.050273895263671875,
    },
    {
      x: 16696.022727272728,
      y: 0.050273895263671875,
    },
    {
      x: 16700.284090909092,
      y: 0.05525970458984375,
    },
    {
      x: 16704.545454545456,
      y: 0.05525970458984375,
    },
    {
      x: 16730.113636363636,
      y: 0.05525970458984375,
    },
    {
      x: 16734.375,
      y: 0.06024169921875,
    },
    {
      x: 16738.636363636364,
      y: 0.06024169921875,
    },
    {
      x: 16764.204545454548,
      y: 0.06024169921875,
    },
    {
      x: 16768.46590909091,
      y: 0.06522369384765625,
    },
    {
      x: 16772.727272727276,
      y: 0.06522369384765625,
    },
    {
      x: 16798.295454545456,
      y: 0.06522369384765625,
    },
    {
      x: 16802.55681818182,
      y: 0.07020950317382812,
    },
    {
      x: 16806.818181818184,
      y: 0.07020950317382812,
    },
    {
      x: 16828.125,
      y: 0.07020950317382812,
    },
    {
      x: 16832.386363636364,
      y: 0.06522369384765625,
    },
    {
      x: 16836.647727272728,
      y: 0.06522369384765625,
    },
    {
      x: 16840.909090909092,
      y: 0.06522369384765625,
    },
    {
      x: 16845.170454545456,
      y: 0.05525970458984375,
    },
    {
      x: 16849.43181818182,
      y: 0.050273895263671875,
    },
    {
      x: 16853.693181818184,
      y: 0.040309906005859375,
    },
    {
      x: 16857.954545454548,
      y: 0.03034210205078125,
    },
    {
      x: 16862.21590909091,
      y: 0.025356292724609375,
    },
    {
      x: 16866.477272727276,
      y: 0.015392303466796875,
    },
    {
      x: 16870.738636363636,
      y: 0.00542449951171875,
    },
    {
      x: 16875,
      y: -0.004543304443359375,
    },
    {
      x: 16879.261363636364,
      y: -0.00952911376953125,
    },
    {
      x: 16883.522727272728,
      y: -0.0145111083984375,
    },
    {
      x: 16887.784090909092,
      y: -0.024478912353515625,
    },
    {
      x: 16892.045454545456,
      y: -0.029460906982421875,
    },
    {
      x: 16896.30681818182,
      y: -0.0394287109375,
    },
    {
      x: 16900.568181818184,
      y: -0.049396514892578125,
    },
    {
      x: 16904.829545454548,
      y: -0.054378509521484375,
    },
    {
      x: 16909.09090909091,
      y: 0.11506271362304688,
    },
    {
      x: 16913.352272727276,
      y: 0.11506271362304688,
    },
    {
      x: 17049.71590909091,
      y: 0.11506271362304688,
    },
    {
      x: 17053.977272727276,
      y: 0.13997650146484375,
    },
    {
      x: 17058.238636363636,
      y: 0.20476531982421875,
    },
    {
      x: 17062.5,
      y: 0.27951812744140625,
    },
    {
      x: 17066.761363636364,
      y: 0.3642387390136719,
    },
    {
      x: 17071.022727272728,
      y: 0.4439735412597656,
    },
    {
      x: 17075.284090909092,
      y: 0.523712158203125,
    },
    {
      x: 17079.545454545456,
      y: 0.6034469604492188,
    },
    {
      x: 17083.80681818182,
      y: 0.6831817626953125,
    },
    {
      x: 17088.068181818184,
      y: 0.7629203796386719,
    },
    {
      x: 17092.329545454548,
      y: 0.8426551818847656,
    },
    {
      x: 17096.59090909091,
      y: 0.8825225830078125,
    },
    {
      x: 17100.852272727276,
      y: 0.8376731872558594,
    },
    {
      x: 17105.113636363636,
      y: 0.7629203796386719,
    },
    {
      x: 17109.375,
      y: 0.6781997680664062,
    },
    {
      x: 17113.636363636364,
      y: 0.5884971618652344,
    },
    {
      x: 17117.897727272728,
      y: 0.49381256103515625,
    },
    {
      x: 17122.159090909092,
      y: 0.409088134765625,
    },
    {
      x: 17126.420454545456,
      y: 0.3193855285644531,
    },
    {
      x: 17130.68181818182,
      y: 0.2346649169921875,
    },
    {
      x: 17134.943181818184,
      y: 0.13997650146484375,
    },
    {
      x: 17139.204545454548,
      y: 0.07020950317382812,
    },
    {
      x: 17143.46590909091,
      y: 0.045291900634765625,
    },
    {
      x: 17147.727272727276,
      y: 0.0353240966796875,
    },
    {
      x: 17151.988636363636,
      y: 0.03034210205078125,
    },
    {
      x: 17156.25,
      y: 0.0353240966796875,
    },
    {
      x: 17160.511363636364,
      y: 0.0353240966796875,
    },
    {
      x: 17169.034090909092,
      y: 0.0353240966796875,
    },
    {
      x: 17173.295454545456,
      y: 0.040309906005859375,
    },
    {
      x: 17177.55681818182,
      y: 0.040309906005859375,
    },
    {
      x: 17181.818181818184,
      y: 0.11506271362304688,
    },
    {
      x: 17186.079545454548,
      y: 0.11506271362304688,
    },
    {
      x: 17450.284090909092,
      y: 0.11506271362304688,
    },
    {
      x: 17454.545454545456,
      y: -0.0145111083984375,
    },
    {
      x: 17458.80681818182,
      y: -0.024478912353515625,
    },
    {
      x: 17463.068181818184,
      y: -0.029460906982421875,
    },
    {
      x: 17467.329545454548,
      y: -0.04441070556640625,
    },
    {
      x: 17471.59090909091,
      y: -0.049396514892578125,
    },
    {
      x: 17475.852272727276,
      y: -0.054378509521484375,
    },
    {
      x: 17480.113636363636,
      y: -0.0643463134765625,
    },
    {
      x: 17484.375,
      y: -0.074310302734375,
    },
    {
      x: 17488.636363636364,
      y: -0.06932830810546875,
    },
    {
      x: 17492.897727272728,
      y: -0.05936431884765625,
    },
    {
      x: 17497.159090909092,
      y: -0.04441070556640625,
    },
    {
      x: 17501.420454545456,
      y: -0.034442901611328125,
    },
    {
      x: 17505.68181818182,
      y: -0.024478912353515625,
    },
    {
      x: 17509.943181818184,
      y: -0.0145111083984375,
    },
    {
      x: 17514.204545454548,
      y: 0.0004425048828125,
    },
    {
      x: 17518.46590909091,
      y: 0.010406494140625,
    },
    {
      x: 17522.727272727276,
      y: 0.020374298095703125,
    },
    {
      x: 17531.25,
      y: 0.040309906005859375,
    },
    {
      x: 17535.511363636364,
      y: 0.050273895263671875,
    },
    {
      x: 17539.772727272728,
      y: 0.06024169921875,
    },
    {
      x: 17544.034090909092,
      y: 0.07020950317382812,
    },
    {
      x: 17548.295454545456,
      y: 0.0851593017578125,
    },
    {
      x: 17552.55681818182,
      y: 0.09512710571289062,
    },
    {
      x: 17556.818181818184,
      y: 0.10509490966796875,
    },
    {
      x: 17561.079545454548,
      y: 0.110076904296875,
    },
    {
      x: 17565.34090909091,
      y: 0.11506271362304688,
    },
    {
      x: 17569.602272727276,
      y: 0.11506271362304688,
    },
    {
      x: 17723.011363636364,
      y: 0.11506271362304688,
    },
    {
      x: 17727.272727272728,
      y: 0.0353240966796875,
    },
    {
      x: 17731.534090909092,
      y: 0.0353240966796875,
    },
    {
      x: 17740.05681818182,
      y: 0.0353240966796875,
    },
    {
      x: 17744.318181818184,
      y: 0.040309906005859375,
    },
    {
      x: 17748.579545454548,
      y: 0.040309906005859375,
    },
    {
      x: 17757.102272727276,
      y: 0.040309906005859375,
    },
    {
      x: 17761.363636363636,
      y: 0.045291900634765625,
    },
    {
      x: 17765.625,
      y: 0.045291900634765625,
    },
    {
      x: 17778.409090909092,
      y: 0.045291900634765625,
    },
    {
      x: 17782.670454545456,
      y: 0.050273895263671875,
    },
    {
      x: 17786.93181818182,
      y: 0.050273895263671875,
    },
    {
      x: 17812.5,
      y: 0.050273895263671875,
    },
    {
      x: 17816.761363636364,
      y: 0.05525970458984375,
    },
    {
      x: 17821.022727272728,
      y: 0.05525970458984375,
    },
    {
      x: 17846.59090909091,
      y: 0.05525970458984375,
    },
    {
      x: 17850.852272727276,
      y: 0.06024169921875,
    },
    {
      x: 17855.113636363636,
      y: 0.06024169921875,
    },
    {
      x: 17880.68181818182,
      y: 0.06024169921875,
    },
    {
      x: 17884.943181818184,
      y: 0.06522369384765625,
    },
    {
      x: 17889.204545454548,
      y: 0.06522369384765625,
    },
    {
      x: 17914.772727272728,
      y: 0.06522369384765625,
    },
    {
      x: 17919.034090909092,
      y: 0.07020950317382812,
    },
    {
      x: 17923.295454545456,
      y: 0.07020950317382812,
    },
    {
      x: 17944.602272727276,
      y: 0.07020950317382812,
    },
    {
      x: 17948.863636363636,
      y: 0.06522369384765625,
    },
    {
      x: 17953.125,
      y: 0.06522369384765625,
    },
    {
      x: 17957.386363636364,
      y: 0.06522369384765625,
    },
    {
      x: 17961.647727272728,
      y: 0.05525970458984375,
    },
    {
      x: 17965.909090909092,
      y: 0.050273895263671875,
    },
    {
      x: 17970.170454545456,
      y: 0.040309906005859375,
    },
    {
      x: 17974.43181818182,
      y: 0.03034210205078125,
    },
    {
      x: 17978.693181818184,
      y: 0.025356292724609375,
    },
    {
      x: 17982.954545454548,
      y: 0.015392303466796875,
    },
    {
      x: 17987.21590909091,
      y: 0.00542449951171875,
    },
    {
      x: 17991.477272727276,
      y: -0.004543304443359375,
    },
    {
      x: 17995.738636363636,
      y: -0.00952911376953125,
    },
    {
      x: 18000,
      y: 0.11506271362304688,
    },
    {
      x: 18004.261363636364,
      y: 0.11506271362304688,
    },
    {
      x: 18166.193181818184,
      y: 0.11506271362304688,
    },
    {
      x: 18170.454545454548,
      y: 0.13997650146484375,
    },
    {
      x: 18174.71590909091,
      y: 0.20476531982421875,
    },
    {
      x: 18178.977272727276,
      y: 0.27951812744140625,
    },
    {
      x: 18183.238636363636,
      y: 0.3642387390136719,
    },
    {
      x: 18187.5,
      y: 0.4439735412597656,
    },
    {
      x: 18191.761363636364,
      y: 0.523712158203125,
    },
    {
      x: 18196.022727272728,
      y: 0.6034469604492188,
    },
    {
      x: 18200.284090909092,
      y: 0.6831817626953125,
    },
    {
      x: 18204.545454545456,
      y: 0.7629203796386719,
    },
    {
      x: 18208.80681818182,
      y: 0.8426551818847656,
    },
    {
      x: 18213.068181818184,
      y: 0.8825225830078125,
    },
    {
      x: 18217.329545454548,
      y: 0.8376731872558594,
    },
    {
      x: 18221.59090909091,
      y: 0.7629203796386719,
    },
    {
      x: 18225.852272727276,
      y: 0.6781997680664062,
    },
    {
      x: 18230.113636363636,
      y: 0.5884971618652344,
    },
    {
      x: 18234.375,
      y: 0.49381256103515625,
    },
    {
      x: 18238.636363636364,
      y: 0.409088134765625,
    },
    {
      x: 18242.897727272728,
      y: 0.3193855285644531,
    },
    {
      x: 18247.159090909092,
      y: 0.2346649169921875,
    },
    {
      x: 18251.420454545456,
      y: 0.13997650146484375,
    },
    {
      x: 18255.68181818182,
      y: 0.07020950317382812,
    },
    {
      x: 18259.943181818184,
      y: 0.045291900634765625,
    },
    {
      x: 18264.204545454548,
      y: 0.0353240966796875,
    },
    {
      x: 18268.46590909091,
      y: 0.03034210205078125,
    },
    {
      x: 18272.727272727276,
      y: 0.11506271362304688,
    },
    {
      x: 18276.988636363636,
      y: 0.11506271362304688,
    },
    {
      x: 18541.193181818184,
      y: 0.11506271362304688,
    },
    {
      x: 18545.454545454548,
      y: 0.2346649169921875,
    },
    {
      x: 18549.71590909091,
      y: 0.13997650146484375,
    },
    {
      x: 18553.977272727276,
      y: 0.07020950317382812,
    },
    {
      x: 18558.238636363636,
      y: 0.045291900634765625,
    },
    {
      x: 18562.5,
      y: 0.0353240966796875,
    },
    {
      x: 18566.761363636364,
      y: 0.03034210205078125,
    },
    {
      x: 18571.022727272728,
      y: 0.0353240966796875,
    },
    {
      x: 18575.284090909092,
      y: 0.0353240966796875,
    },
    {
      x: 18583.80681818182,
      y: 0.0353240966796875,
    },
    {
      x: 18588.068181818184,
      y: 0.040309906005859375,
    },
    {
      x: 18592.329545454548,
      y: 0.040309906005859375,
    },
    {
      x: 18600.852272727276,
      y: 0.040309906005859375,
    },
    {
      x: 18605.113636363636,
      y: 0.045291900634765625,
    },
    {
      x: 18609.375,
      y: 0.045291900634765625,
    },
    {
      x: 18622.159090909092,
      y: 0.045291900634765625,
    },
    {
      x: 18626.420454545456,
      y: 0.050273895263671875,
    },
    {
      x: 18630.68181818182,
      y: 0.050273895263671875,
    },
    {
      x: 18656.25,
      y: 0.050273895263671875,
    },
    {
      x: 18660.511363636364,
      y: 0.05525970458984375,
    },
    {
      x: 18664.772727272728,
      y: 0.05525970458984375,
    },
    {
      x: 18690.34090909091,
      y: 0.05525970458984375,
    },
    {
      x: 18694.602272727276,
      y: 0.06024169921875,
    },
    {
      x: 18698.863636363636,
      y: 0.06024169921875,
    },
    {
      x: 18724.43181818182,
      y: 0.06024169921875,
    },
    {
      x: 18728.693181818184,
      y: 0.06522369384765625,
    },
    {
      x: 18732.954545454548,
      y: 0.06522369384765625,
    },
    {
      x: 18758.522727272728,
      y: 0.06522369384765625,
    },
    {
      x: 18762.784090909092,
      y: 0.07020950317382812,
    },
    {
      x: 18767.045454545456,
      y: 0.07020950317382812,
    },
    {
      x: 18788.352272727276,
      y: 0.07020950317382812,
    },
    {
      x: 18792.613636363636,
      y: 0.06522369384765625,
    },
    {
      x: 18796.875,
      y: 0.06522369384765625,
    },
    {
      x: 18801.136363636364,
      y: 0.06522369384765625,
    },
    {
      x: 18805.397727272728,
      y: 0.05525970458984375,
    },
    {
      x: 18809.659090909092,
      y: 0.050273895263671875,
    },
    {
      x: 18813.920454545456,
      y: 0.040309906005859375,
    },
    {
      x: 18818.18181818182,
      y: 0.03034210205078125,
    },
    {
      x: 18822.443181818184,
      y: 0.025356292724609375,
    },
    {
      x: 18826.704545454548,
      y: 0.015392303466796875,
    },
    {
      x: 18830.96590909091,
      y: 0.00542449951171875,
    },
    {
      x: 18835.227272727276,
      y: -0.004543304443359375,
    },
    {
      x: 18839.488636363636,
      y: -0.00952911376953125,
    },
    {
      x: 18843.75,
      y: -0.0145111083984375,
    },
    {
      x: 18848.011363636364,
      y: -0.024478912353515625,
    },
    {
      x: 18852.272727272728,
      y: -0.029460906982421875,
    },
    {
      x: 18856.534090909092,
      y: -0.0394287109375,
    },
    {
      x: 18865.05681818182,
      y: -0.05936431884765625,
    },
    {
      x: 18869.318181818184,
      y: -0.0643463134765625,
    },
    {
      x: 18873.579545454548,
      y: -0.074310302734375,
    },
    {
      x: 18877.84090909091,
      y: -0.06932830810546875,
    },
    {
      x: 18882.102272727276,
      y: -0.05936431884765625,
    },
    {
      x: 18886.363636363636,
      y: -0.04441070556640625,
    },
    {
      x: 18890.625,
      y: -0.034442901611328125,
    },
    {
      x: 18894.886363636364,
      y: -0.024478912353515625,
    },
    {
      x: 18899.147727272728,
      y: -0.0145111083984375,
    },
    {
      x: 18903.409090909092,
      y: 0.0004425048828125,
    },
    {
      x: 18907.670454545456,
      y: 0.010406494140625,
    },
    {
      x: 18911.93181818182,
      y: 0.020374298095703125,
    },
    {
      x: 18920.454545454548,
      y: 0.040309906005859375,
    },
    {
      x: 18924.71590909091,
      y: 0.050273895263671875,
    },
    {
      x: 18928.977272727276,
      y: 0.06024169921875,
    },
    {
      x: 18933.238636363636,
      y: 0.07020950317382812,
    },
    {
      x: 18937.5,
      y: 0.0851593017578125,
    },
    {
      x: 18941.761363636364,
      y: 0.09512710571289062,
    },
    {
      x: 18946.022727272728,
      y: 0.10509490966796875,
    },
    {
      x: 18950.284090909092,
      y: 0.110076904296875,
    },
    {
      x: 18954.545454545456,
      y: 0.11506271362304688,
    },
    {
      x: 18958.80681818182,
      y: 0.11506271362304688,
    },
    {
      x: 19282.670454545456,
      y: 0.11506271362304688,
    },
    {
      x: 19286.93181818182,
      y: 0.13997650146484375,
    },
    {
      x: 19291.193181818184,
      y: 0.20476531982421875,
    },
    {
      x: 19295.454545454548,
      y: 0.27951812744140625,
    },
    {
      x: 19299.71590909091,
      y: 0.3642387390136719,
    },
    {
      x: 19303.977272727276,
      y: 0.4439735412597656,
    },
    {
      x: 19308.238636363636,
      y: 0.523712158203125,
    },
    {
      x: 19312.5,
      y: 0.6034469604492188,
    },
    {
      x: 19316.761363636364,
      y: 0.6831817626953125,
    },
    {
      x: 19321.022727272728,
      y: 0.7629203796386719,
    },
    {
      x: 19325.284090909092,
      y: 0.8426551818847656,
    },
    {
      x: 19329.545454545456,
      y: 0.8825225830078125,
    },
    {
      x: 19333.80681818182,
      y: 0.8376731872558594,
    },
    {
      x: 19338.068181818184,
      y: 0.7629203796386719,
    },
    {
      x: 19342.329545454548,
      y: 0.6781997680664062,
    },
    {
      x: 19346.59090909091,
      y: 0.5884971618652344,
    },
    {
      x: 19350.852272727276,
      y: 0.49381256103515625,
    },
    {
      x: 19355.113636363636,
      y: 0.409088134765625,
    },
    {
      x: 19359.375,
      y: 0.3193855285644531,
    },
    {
      x: 19363.636363636364,
      y: 0.11506271362304688,
    },
    {
      x: 19367.897727272728,
      y: 0.11506271362304688,
    },
    {
      x: 19853.693181818184,
      y: 0.11506271362304688,
    },
    {
      x: 19857.954545454548,
      y: 0.13997650146484375,
    },
    {
      x: 19862.21590909091,
      y: 0.20476531982421875,
    },
    {
      x: 19866.477272727276,
      y: 0.27951812744140625,
    },
    {
      x: 19870.73863636364,
      y: 0.3642387390136719,
    },
    {
      x: 19875,
      y: 0.4439735412597656,
    },
    {
      x: 19879.261363636364,
      y: 0.523712158203125,
    },
    {
      x: 19883.522727272728,
      y: 0.6034469604492188,
    },
    {
      x: 19887.784090909092,
      y: 0.6831817626953125,
    },
    {
      x: 19892.045454545456,
      y: 0.7629203796386719,
    },
    {
      x: 19896.30681818182,
      y: 0.8426551818847656,
    },
    {
      x: 19900.568181818184,
      y: 0.8825225830078125,
    },
    {
      x: 19904.829545454548,
      y: 0.8376731872558594,
    },
    {
      x: 19909.09090909091,
      y: 0.06522369384765625,
    },
    {
      x: 19913.352272727276,
      y: 0.06522369384765625,
    },
    {
      x: 19917.61363636364,
      y: 0.06522369384765625,
    },
    {
      x: 19921.875,
      y: 0.05525970458984375,
    },
    {
      x: 19926.136363636364,
      y: 0.050273895263671875,
    },
    {
      x: 19930.397727272728,
      y: 0.040309906005859375,
    },
    {
      x: 19934.659090909092,
      y: 0.03034210205078125,
    },
    {
      x: 19938.920454545456,
      y: 0.025356292724609375,
    },
    {
      x: 19943.18181818182,
      y: 0.015392303466796875,
    },
    {
      x: 19947.443181818184,
      y: 0.00542449951171875,
    },
    {
      x: 19951.704545454548,
      y: -0.004543304443359375,
    },
    {
      x: 19955.96590909091,
      y: -0.00952911376953125,
    },
    {
      x: 19960.227272727276,
      y: -0.0145111083984375,
    },
    {
      x: 19964.48863636364,
      y: -0.024478912353515625,
    },
    {
      x: 19968.75,
      y: -0.029460906982421875,
    },
    {
      x: 19973.011363636364,
      y: -0.0394287109375,
    },
    {
      x: 19977.272727272728,
      y: -0.049396514892578125,
    },
    {
      x: 19981.534090909092,
      y: -0.054378509521484375,
    },
    {
      x: 19985.795454545456,
      y: -0.0643463134765625,
    },
    {
      x: 19990.05681818182,
      y: -0.074310302734375,
    },
    {
      x: 19994.318181818184,
      y: -0.06932830810546875,
    },
    {
      x: 19998.579545454548,
      y: -0.05936431884765625,
    },
    {
      x: 20002.84090909091,
      y: -0.04441070556640625,
    },
    {
      x: 20007.102272727276,
      y: -0.034442901611328125,
    },
    {
      x: 20011.36363636364,
      y: -0.024478912353515625,
    },
    {
      x: 20015.625,
      y: -0.0145111083984375,
    },
    {
      x: 20019.886363636364,
      y: 0.0004425048828125,
    },
    {
      x: 20024.147727272728,
      y: 0.010406494140625,
    },
    {
      x: 20028.409090909092,
      y: 0.020374298095703125,
    },
    {
      x: 20036.93181818182,
      y: 0.040309906005859375,
    },
    {
      x: 20041.193181818184,
      y: 0.050273895263671875,
    },
    {
      x: 20045.454545454548,
      y: 0.06024169921875,
    },
    {
      x: 20049.71590909091,
      y: 0.07020950317382812,
    },
    {
      x: 20053.977272727276,
      y: 0.0851593017578125,
    },
    {
      x: 20058.23863636364,
      y: 0.09512710571289062,
    },
    {
      x: 20062.5,
      y: 0.10509490966796875,
    },
    {
      x: 20066.761363636364,
      y: 0.110076904296875,
    },
    {
      x: 20071.022727272728,
      y: 0.11506271362304688,
    },
    {
      x: 20075.284090909092,
      y: 0.11506271362304688,
    },
    {
      x: 20177.55681818182,
      y: 0.11506271362304688,
    },
    {
      x: 20181.818181818184,
      y: 0.7629203796386719,
    },
    {
      x: 20186.079545454548,
      y: 0.6781997680664062,
    },
    {
      x: 20190.34090909091,
      y: 0.5884971618652344,
    },
    {
      x: 20194.602272727276,
      y: 0.4987945556640625,
    },
    {
      x: 20198.86363636364,
      y: 0.409088134765625,
    },
    {
      x: 20203.125,
      y: 0.3193855285644531,
    },
    {
      x: 20207.386363636364,
      y: 0.2346649169921875,
    },
    {
      x: 20211.647727272728,
      y: 0.13997650146484375,
    },
    {
      x: 20215.909090909092,
      y: 0.07020950317382812,
    },
    {
      x: 20220.170454545456,
      y: 0.045291900634765625,
    },
    {
      x: 20224.43181818182,
      y: 0.0353240966796875,
    },
    {
      x: 20228.693181818184,
      y: 0.03034210205078125,
    },
    {
      x: 20232.954545454548,
      y: 0.0353240966796875,
    },
    {
      x: 20237.21590909091,
      y: 0.0353240966796875,
    },
    {
      x: 20245.73863636364,
      y: 0.0353240966796875,
    },
    {
      x: 20250,
      y: 0.040309906005859375,
    },
    {
      x: 20254.261363636364,
      y: 0.040309906005859375,
    },
    {
      x: 20262.784090909092,
      y: 0.040309906005859375,
    },
    {
      x: 20267.045454545456,
      y: 0.045291900634765625,
    },
    {
      x: 20271.30681818182,
      y: 0.045291900634765625,
    },
    {
      x: 20284.09090909091,
      y: 0.045291900634765625,
    },
    {
      x: 20288.352272727276,
      y: 0.050273895263671875,
    },
    {
      x: 20292.61363636364,
      y: 0.050273895263671875,
    },
    {
      x: 20318.18181818182,
      y: 0.050273895263671875,
    },
    {
      x: 20322.443181818184,
      y: 0.05525970458984375,
    },
    {
      x: 20326.704545454548,
      y: 0.05525970458984375,
    },
    {
      x: 20352.272727272728,
      y: 0.05525970458984375,
    },
    {
      x: 20356.534090909092,
      y: 0.06024169921875,
    },
    {
      x: 20360.795454545456,
      y: 0.06024169921875,
    },
    {
      x: 20386.36363636364,
      y: 0.06024169921875,
    },
    {
      x: 20390.625,
      y: 0.06522369384765625,
    },
    {
      x: 20394.886363636364,
      y: 0.06522369384765625,
    },
    {
      x: 20420.454545454548,
      y: 0.06522369384765625,
    },
    {
      x: 20424.71590909091,
      y: 0.07020950317382812,
    },
    {
      x: 20428.977272727276,
      y: 0.07020950317382812,
    },
    {
      x: 20450.284090909092,
      y: 0.07020950317382812,
    },
    {
      x: 20454.545454545456,
      y: 0.11506271362304688,
    },
    {
      x: 20458.80681818182,
      y: 0.11506271362304688,
    },
    {
      x: 20723.011363636364,
      y: 0.11506271362304688,
    },
    {
      x: 20727.272727272728,
      y: 0.07020950317382812,
    },
    {
      x: 20731.534090909092,
      y: 0.07020950317382812,
    },
    {
      x: 20752.84090909091,
      y: 0.07020950317382812,
    },
    {
      x: 20757.102272727276,
      y: 0.06522369384765625,
    },
    {
      x: 20761.36363636364,
      y: 0.06522369384765625,
    },
    {
      x: 20765.625,
      y: 0.05525970458984375,
    },
    {
      x: 20769.886363636364,
      y: 0.050273895263671875,
    },
    {
      x: 20774.147727272728,
      y: 0.040309906005859375,
    },
    {
      x: 20778.409090909092,
      y: 0.03034210205078125,
    },
    {
      x: 20782.670454545456,
      y: 0.025356292724609375,
    },
    {
      x: 20786.93181818182,
      y: 0.015392303466796875,
    },
    {
      x: 20791.193181818184,
      y: 0.00542449951171875,
    },
    {
      x: 20795.454545454548,
      y: -0.004543304443359375,
    },
    {
      x: 20799.71590909091,
      y: -0.00952911376953125,
    },
    {
      x: 20803.977272727276,
      y: -0.0145111083984375,
    },
    {
      x: 20808.23863636364,
      y: -0.024478912353515625,
    },
    {
      x: 20812.5,
      y: -0.029460906982421875,
    },
    {
      x: 20816.761363636364,
      y: -0.0394287109375,
    },
    {
      x: 20821.022727272728,
      y: -0.04441070556640625,
    },
    {
      x: 20825.284090909092,
      y: -0.054378509521484375,
    },
    {
      x: 20829.545454545456,
      y: -0.0643463134765625,
    },
    {
      x: 20833.80681818182,
      y: -0.074310302734375,
    },
    {
      x: 20838.068181818184,
      y: -0.0643463134765625,
    },
    {
      x: 20842.329545454548,
      y: -0.05936431884765625,
    },
    {
      x: 20846.59090909091,
      y: -0.04441070556640625,
    },
    {
      x: 20850.852272727276,
      y: -0.034442901611328125,
    },
    {
      x: 20855.11363636364,
      y: -0.024478912353515625,
    },
    {
      x: 20859.375,
      y: -0.00952911376953125,
    },
    {
      x: 20863.636363636364,
      y: 0.0004425048828125,
    },
    {
      x: 20867.897727272728,
      y: 0.010406494140625,
    },
    {
      x: 20872.159090909092,
      y: 0.020374298095703125,
    },
    {
      x: 20880.68181818182,
      y: 0.040309906005859375,
    },
    {
      x: 20884.943181818184,
      y: 0.050273895263671875,
    },
    {
      x: 20889.204545454548,
      y: 0.06024169921875,
    },
    {
      x: 20893.46590909091,
      y: 0.07020950317382812,
    },
    {
      x: 20897.727272727276,
      y: 0.0851593017578125,
    },
    {
      x: 20901.98863636364,
      y: 0.09512710571289062,
    },
    {
      x: 20906.25,
      y: 0.10509490966796875,
    },
    {
      x: 20910.511363636364,
      y: 0.110076904296875,
    },
    {
      x: 20914.772727272728,
      y: 0.11506271362304688,
    },
    {
      x: 20919.034090909092,
      y: 0.11506271362304688,
    },
    {
      x: 20995.73863636364,
      y: 0.11506271362304688,
    },
    {
      x: 21000,
      y: 0.608428955078125,
    },
    {
      x: 21004.261363636364,
      y: 0.6881675720214844,
    },
    {
      x: 21008.522727272728,
      y: 0.7679023742675781,
    },
    {
      x: 21012.784090909092,
      y: 0.8426551818847656,
    },
    {
      x: 21017.045454545456,
      y: 0.8825225830078125,
    },
    {
      x: 21021.30681818182,
      y: 0.8376731872558594,
    },
    {
      x: 21025.568181818184,
      y: 0.7629203796386719,
    },
    {
      x: 21029.829545454548,
      y: 0.6781997680664062,
    },
    {
      x: 21034.09090909091,
      y: 0.5884971618652344,
    },
    {
      x: 21038.352272727276,
      y: 0.4987945556640625,
    },
    {
      x: 21042.61363636364,
      y: 0.409088134765625,
    },
    {
      x: 21046.875,
      y: 0.3193855285644531,
    },
    {
      x: 21051.136363636364,
      y: 0.2346649169921875,
    },
    {
      x: 21055.397727272728,
      y: 0.13997650146484375,
    },
    {
      x: 21059.659090909092,
      y: 0.07020950317382812,
    },
    {
      x: 21063.920454545456,
      y: 0.045291900634765625,
    },
    {
      x: 21068.18181818182,
      y: 0.0353240966796875,
    },
    {
      x: 21072.443181818184,
      y: 0.0353240966796875,
    },
    {
      x: 21085.227272727276,
      y: 0.0353240966796875,
    },
    {
      x: 21089.48863636364,
      y: 0.040309906005859375,
    },
    {
      x: 21093.75,
      y: 0.040309906005859375,
    },
    {
      x: 21102.272727272728,
      y: 0.040309906005859375,
    },
    {
      x: 21106.534090909092,
      y: 0.045291900634765625,
    },
    {
      x: 21110.795454545456,
      y: 0.045291900634765625,
    },
    {
      x: 21127.84090909091,
      y: 0.045291900634765625,
    },
    {
      x: 21132.102272727276,
      y: 0.050273895263671875,
    },
    {
      x: 21136.36363636364,
      y: 0.050273895263671875,
    },
    {
      x: 21161.93181818182,
      y: 0.050273895263671875,
    },
    {
      x: 21166.193181818184,
      y: 0.05525970458984375,
    },
    {
      x: 21170.454545454548,
      y: 0.05525970458984375,
    },
    {
      x: 21196.022727272728,
      y: 0.05525970458984375,
    },
    {
      x: 21200.284090909092,
      y: 0.06024169921875,
    },
    {
      x: 21204.545454545456,
      y: 0.06024169921875,
    },
    {
      x: 21230.11363636364,
      y: 0.06024169921875,
    },
    {
      x: 21234.375,
      y: 0.06522369384765625,
    },
    {
      x: 21238.636363636364,
      y: 0.06522369384765625,
    },
    {
      x: 21264.204545454548,
      y: 0.06522369384765625,
    },
    {
      x: 21268.46590909091,
      y: 0.07020950317382812,
    },
    {
      x: 21272.727272727276,
      y: 0.11506271362304688,
    },
    {
      x: 21276.98863636364,
      y: 0.11506271362304688,
    },
    {
      x: 21788.352272727276,
      y: 0.11506271362304688,
    },
    {
      x: 21792.61363636364,
      y: 0.13997650146484375,
    },
    {
      x: 21796.875,
      y: 0.20476531982421875,
    },
    {
      x: 21801.136363636364,
      y: 0.2845001220703125,
    },
    {
      x: 21805.397727272728,
      y: 0.3642387390136719,
    },
    {
      x: 21809.659090909092,
      y: 0.4439735412597656,
    },
    {
      x: 21813.920454545456,
      y: 0.5286941528320312,
    },
    {
      x: 21818.18181818182,
      y: 0.06522369384765625,
    },
    {
      x: 21822.443181818184,
      y: 0.06522369384765625,
    },
    {
      x: 21848.011363636364,
      y: 0.06522369384765625,
    },
    {
      x: 21852.272727272728,
      y: 0.07020950317382812,
    },
    {
      x: 21856.534090909092,
      y: 0.07020950317382812,
    },
    {
      x: 21869.318181818184,
      y: 0.07020950317382812,
    },
    {
      x: 21873.579545454548,
      y: 0.06522369384765625,
    },
    {
      x: 21877.84090909091,
      y: 0.06522369384765625,
    },
    {
      x: 21882.102272727276,
      y: 0.05525970458984375,
    },
    {
      x: 21886.36363636364,
      y: 0.050273895263671875,
    },
    {
      x: 21890.625,
      y: 0.045291900634765625,
    },
    {
      x: 21894.886363636364,
      y: 0.0353240966796875,
    },
    {
      x: 21899.147727272728,
      y: 0.025356292724609375,
    },
    {
      x: 21903.409090909092,
      y: 0.015392303466796875,
    },
    {
      x: 21907.670454545456,
      y: 0.010406494140625,
    },
    {
      x: 21911.93181818182,
      y: 0.0004425048828125,
    },
    {
      x: 21916.193181818184,
      y: -0.004543304443359375,
    },
    {
      x: 21920.454545454548,
      y: -0.0145111083984375,
    },
    {
      x: 21924.71590909091,
      y: -0.01949310302734375,
    },
    {
      x: 21928.977272727276,
      y: -0.029460906982421875,
    },
    {
      x: 21933.23863636364,
      y: -0.0394287109375,
    },
    {
      x: 21937.5,
      y: -0.04441070556640625,
    },
    {
      x: 21941.761363636364,
      y: -0.054378509521484375,
    },
    {
      x: 21946.022727272728,
      y: -0.05936431884765625,
    },
    {
      x: 21950.284090909092,
      y: -0.06932830810546875,
    },
    {
      x: 21954.545454545456,
      y: -0.0643463134765625,
    },
    {
      x: 21958.80681818182,
      y: -0.054378509521484375,
    },
    {
      x: 21967.329545454548,
      y: -0.034442901611328125,
    },
    {
      x: 21971.59090909091,
      y: -0.01949310302734375,
    },
    {
      x: 21975.852272727276,
      y: -0.00952911376953125,
    },
    {
      x: 21980.11363636364,
      y: 0.0004425048828125,
    },
    {
      x: 21984.375,
      y: 0.015392303466796875,
    },
    {
      x: 21988.636363636364,
      y: 0.020374298095703125,
    },
    {
      x: 21992.897727272728,
      y: 0.03034210205078125,
    },
    {
      x: 21997.159090909092,
      y: 0.040309906005859375,
    },
    {
      x: 22001.420454545456,
      y: 0.05525970458984375,
    },
    {
      x: 22005.68181818182,
      y: 0.06522369384765625,
    },
    {
      x: 22009.943181818184,
      y: 0.0751953125,
    },
    {
      x: 22014.204545454548,
      y: 0.0851593017578125,
    },
    {
      x: 22018.46590909091,
      y: 0.09512710571289062,
    },
    {
      x: 22022.727272727276,
      y: 0.10509490966796875,
    },
    {
      x: 22026.98863636364,
      y: 0.110076904296875,
    },
    {
      x: 22031.25,
      y: 0.11506271362304688,
    },
    {
      x: 22035.511363636364,
      y: 0.11506271362304688,
    },
    {
      x: 22086.647727272728,
      y: 0.11506271362304688,
    },
    {
      x: 22090.909090909092,
      y: 0.13997650146484375,
    },
    {
      x: 22095.170454545456,
      y: 0.20476531982421875,
    },
    {
      x: 22099.43181818182,
      y: 0.2845001220703125,
    },
    {
      x: 22103.693181818184,
      y: 0.3642387390136719,
    },
    {
      x: 22107.954545454548,
      y: 0.4439735412597656,
    },
    {
      x: 22112.21590909091,
      y: 0.5286941528320312,
    },
    {
      x: 22116.477272727276,
      y: 0.608428955078125,
    },
    {
      x: 22120.73863636364,
      y: 0.6881675720214844,
    },
    {
      x: 22125,
      y: 0.7679023742675781,
    },
    {
      x: 22129.261363636364,
      y: 0.8426551818847656,
    },
    {
      x: 22133.522727272728,
      y: 0.8825225830078125,
    },
    {
      x: 22137.784090909092,
      y: 0.8376731872558594,
    },
    {
      x: 22142.045454545456,
      y: 0.7629203796386719,
    },
    {
      x: 22146.30681818182,
      y: 0.6781997680664062,
    },
    {
      x: 22150.568181818184,
      y: 0.5884971618652344,
    },
    {
      x: 22154.829545454548,
      y: 0.49381256103515625,
    },
    {
      x: 22159.09090909091,
      y: 0.4041023254394531,
    },
    {
      x: 22163.352272727276,
      y: 0.3193855285644531,
    },
    {
      x: 22167.61363636364,
      y: 0.22968292236328125,
    },
    {
      x: 22171.875,
      y: 0.1349945068359375,
    },
    {
      x: 22176.136363636364,
      y: 0.06522369384765625,
    },
    {
      x: 22180.397727272728,
      y: 0.040309906005859375,
    },
    {
      x: 22184.659090909092,
      y: 0.03034210205078125,
    },
    {
      x: 22188.920454545456,
      y: 0.03034210205078125,
    },
    {
      x: 22201.704545454548,
      y: 0.03034210205078125,
    },
    {
      x: 22205.96590909091,
      y: 0.0353240966796875,
    },
    {
      x: 22210.227272727276,
      y: 0.0353240966796875,
    },
    {
      x: 22218.75,
      y: 0.0353240966796875,
    },
    {
      x: 22223.011363636364,
      y: 0.040309906005859375,
    },
    {
      x: 22227.272727272728,
      y: 0.040309906005859375,
    },
    {
      x: 22235.795454545456,
      y: 0.040309906005859375,
    },
    {
      x: 22240.05681818182,
      y: 0.045291900634765625,
    },
    {
      x: 22244.318181818184,
      y: 0.045291900634765625,
    },
    {
      x: 22257.102272727276,
      y: 0.045291900634765625,
    },
    {
      x: 22261.36363636364,
      y: 0.050273895263671875,
    },
    {
      x: 22265.625,
      y: 0.050273895263671875,
    },
    {
      x: 22291.193181818184,
      y: 0.050273895263671875,
    },
    {
      x: 22295.454545454548,
      y: 0.05525970458984375,
    },
    {
      x: 22299.71590909091,
      y: 0.05525970458984375,
    },
    {
      x: 22325.284090909092,
      y: 0.05525970458984375,
    },
    {
      x: 22329.545454545456,
      y: 0.06024169921875,
    },
    {
      x: 22333.80681818182,
      y: 0.06024169921875,
    },
    {
      x: 22359.375,
      y: 0.06024169921875,
    },
    {
      x: 22363.636363636364,
      y: 0.11506271362304688,
    },
    {
      x: 22367.897727272728,
      y: 0.11506271362304688,
    },
    {
      x: 22904.829545454548,
      y: 0.11506271362304688,
    },
    {
      x: 22909.09090909091,
      y: 0.06024169921875,
    },
    {
      x: 22913.352272727276,
      y: 0.06024169921875,
    },
    {
      x: 22930.397727272728,
      y: 0.06024169921875,
    },
    {
      x: 22934.659090909092,
      y: 0.06522369384765625,
    },
    {
      x: 22938.920454545456,
      y: 0.06522369384765625,
    },
    {
      x: 22964.48863636364,
      y: 0.06522369384765625,
    },
    {
      x: 22968.75,
      y: 0.07020950317382812,
    },
    {
      x: 22973.011363636364,
      y: 0.07020950317382812,
    },
    {
      x: 22985.795454545456,
      y: 0.07020950317382812,
    },
    {
      x: 22990.05681818182,
      y: 0.06522369384765625,
    },
    {
      x: 22994.318181818184,
      y: 0.06522369384765625,
    },
    {
      x: 22998.579545454548,
      y: 0.05525970458984375,
    },
    {
      x: 23002.84090909091,
      y: 0.050273895263671875,
    },
    {
      x: 23007.102272727276,
      y: 0.045291900634765625,
    },
    {
      x: 23011.36363636364,
      y: 0.0353240966796875,
    },
    {
      x: 23015.625,
      y: 0.025356292724609375,
    },
    {
      x: 23019.886363636364,
      y: 0.015392303466796875,
    },
    {
      x: 23024.147727272728,
      y: 0.010406494140625,
    },
    {
      x: 23028.409090909092,
      y: 0.0004425048828125,
    },
    {
      x: 23032.670454545456,
      y: -0.004543304443359375,
    },
    {
      x: 23036.93181818182,
      y: -0.0145111083984375,
    },
    {
      x: 23041.193181818184,
      y: -0.01949310302734375,
    },
    {
      x: 23045.454545454548,
      y: -0.029460906982421875,
    },
    {
      x: 23049.71590909091,
      y: -0.034442901611328125,
    },
    {
      x: 23053.977272727276,
      y: -0.04441070556640625,
    },
    {
      x: 23058.23863636364,
      y: -0.054378509521484375,
    },
    {
      x: 23062.5,
      y: -0.05936431884765625,
    },
    {
      x: 23066.761363636364,
      y: -0.06932830810546875,
    },
    {
      x: 23071.022727272728,
      y: -0.0643463134765625,
    },
    {
      x: 23075.284090909092,
      y: -0.054378509521484375,
    },
    {
      x: 23083.80681818182,
      y: -0.034442901611328125,
    },
    {
      x: 23088.068181818184,
      y: -0.01949310302734375,
    },
    {
      x: 23092.329545454548,
      y: -0.00952911376953125,
    },
    {
      x: 23096.59090909091,
      y: 0.0004425048828125,
    },
    {
      x: 23100.852272727276,
      y: 0.015392303466796875,
    },
    {
      x: 23105.11363636364,
      y: 0.020374298095703125,
    },
    {
      x: 23109.375,
      y: 0.03034210205078125,
    },
    {
      x: 23113.636363636364,
      y: 0.040309906005859375,
    },
    {
      x: 23117.897727272728,
      y: 0.05525970458984375,
    },
    {
      x: 23122.159090909092,
      y: 0.06522369384765625,
    },
    {
      x: 23126.420454545456,
      y: 0.0751953125,
    },
    {
      x: 23130.68181818182,
      y: 0.0851593017578125,
    },
    {
      x: 23134.943181818184,
      y: 0.09512710571289062,
    },
    {
      x: 23139.204545454548,
      y: 0.10509490966796875,
    },
    {
      x: 23143.46590909091,
      y: 0.110076904296875,
    },
    {
      x: 23147.727272727276,
      y: 0.11506271362304688,
    },
    {
      x: 23151.98863636364,
      y: 0.11506271362304688,
    },
    {
      x: 23748.579545454548,
      y: 0.11506271362304688,
    },
    {
      x: 23752.84090909091,
      y: 0.13997650146484375,
    },
    {
      x: 23757.102272727276,
      y: 0.20476531982421875,
    },
    {
      x: 23761.36363636364,
      y: 0.2845001220703125,
    },
    {
      x: 23765.625,
      y: 0.3642387390136719,
    },
    {
      x: 23769.886363636364,
      y: 0.4439735412597656,
    },
    {
      x: 23774.147727272728,
      y: 0.5286941528320312,
    },
    {
      x: 23778.409090909092,
      y: 0.608428955078125,
    },
    {
      x: 23782.670454545456,
      y: 0.6881675720214844,
    },
    {
      x: 23786.93181818182,
      y: 0.7679023742675781,
    },
    {
      x: 23791.193181818184,
      y: 0.8426551818847656,
    },
    {
      x: 23795.454545454548,
      y: 0.8825225830078125,
    },
    {
      x: 23799.71590909091,
      y: 0.8376731872558594,
    },
    {
      x: 23803.977272727276,
      y: 0.7629203796386719,
    },
    {
      x: 23808.23863636364,
      y: 0.6781997680664062,
    },
    {
      x: 23812.5,
      y: 0.5884971618652344,
    },
    {
      x: 23816.761363636364,
      y: 0.49381256103515625,
    },
    {
      x: 23821.022727272728,
      y: 0.4041023254394531,
    },
    {
      x: 23825.284090909092,
      y: 0.3193855285644531,
    },
    {
      x: 23829.545454545456,
      y: 0.22968292236328125,
    },
    {
      x: 23833.80681818182,
      y: 0.1349945068359375,
    },
    {
      x: 23838.068181818184,
      y: 0.06522369384765625,
    },
    {
      x: 23842.329545454548,
      y: 0.040309906005859375,
    },
    {
      x: 23846.59090909091,
      y: 0.03034210205078125,
    },
    {
      x: 23850.852272727276,
      y: 0.03034210205078125,
    },
    {
      x: 23863.636363636364,
      y: 0.03034210205078125,
    },
    {
      x: 23867.897727272728,
      y: 0.0353240966796875,
    },
    {
      x: 23872.159090909092,
      y: 0.0353240966796875,
    },
    {
      x: 23880.68181818182,
      y: 0.0353240966796875,
    },
    {
      x: 23884.943181818184,
      y: 0.040309906005859375,
    },
    {
      x: 23889.204545454548,
      y: 0.040309906005859375,
    },
    {
      x: 23897.727272727276,
      y: 0.040309906005859375,
    },
    {
      x: 23901.98863636364,
      y: 0.045291900634765625,
    },
    {
      x: 23906.25,
      y: 0.045291900634765625,
    },
    {
      x: 23919.034090909092,
      y: 0.045291900634765625,
    },
    {
      x: 23923.295454545456,
      y: 0.050273895263671875,
    },
    {
      x: 23927.55681818182,
      y: 0.050273895263671875,
    },
    {
      x: 23953.125,
      y: 0.050273895263671875,
    },
    {
      x: 23957.386363636364,
      y: 0.05525970458984375,
    },
    {
      x: 23961.647727272728,
      y: 0.05525970458984375,
    },
    {
      x: 23987.21590909091,
      y: 0.05525970458984375,
    },
    {
      x: 23991.477272727276,
      y: 0.06024169921875,
    },
    {
      x: 23995.73863636364,
      y: 0.06024169921875,
    },
    {
      x: 24000,
      y: 0.06024169921875,
    },
  ],
  v3: [
    {
      x: 0,
      y: 0.09512710571289062,
    },
    {
      x: 256.79347826086956,
      y: 0.09512710571289062,
    },
    {
      x: 260.8695652173913,
      y: 0.045291900634765625,
    },
    {
      x: 264.94565217391306,
      y: 0.045291900634765625,
    },
    {
      x: 281.25,
      y: 0.045291900634765625,
    },
    {
      x: 285.32608695652175,
      y: 0.050273895263671875,
    },
    {
      x: 289.4021739130435,
      y: 0.050273895263671875,
    },
    {
      x: 313.85869565217394,
      y: 0.050273895263671875,
    },
    {
      x: 317.9347826086957,
      y: 0.05525970458984375,
    },
    {
      x: 322.0108695652174,
      y: 0.05525970458984375,
    },
    {
      x: 346.4673913043478,
      y: 0.05525970458984375,
    },
    {
      x: 350.54347826086956,
      y: 0.06024169921875,
    },
    {
      x: 354.6195652173913,
      y: 0.06024169921875,
    },
    {
      x: 379.07608695652175,
      y: 0.06024169921875,
    },
    {
      x: 383.1521739130435,
      y: 0.06522369384765625,
    },
    {
      x: 387.22826086956525,
      y: 0.06522369384765625,
    },
    {
      x: 411.6847826086957,
      y: 0.06522369384765625,
    },
    {
      x: 415.7608695652174,
      y: 0.07020950317382812,
    },
    {
      x: 419.8369565217391,
      y: 0.06522369384765625,
    },
    {
      x: 423.9130434782609,
      y: 0.040309906005859375,
    },
    {
      x: 427.9891304347826,
      y: 0.015392303466796875,
    },
    {
      x: 432.0652173913044,
      y: -0.004543304443359375,
    },
    {
      x: 436.1413043478261,
      y: -0.01949310302734375,
    },
    {
      x: 440.2173913043478,
      y: -0.034442901611328125,
    },
    {
      x: 444.29347826086956,
      y: -0.049396514892578125,
    },
    {
      x: 448.3695652173913,
      y: -0.05936431884765625,
    },
    {
      x: 452.44565217391306,
      y: -0.074310302734375,
    },
    {
      x: 456.5217391304348,
      y: -0.089263916015625,
    },
    {
      x: 460.59782608695656,
      y: -0.09923171997070312,
    },
    {
      x: 464.67391304347825,
      y: -0.1141815185546875,
    },
    {
      x: 468.75,
      y: -0.1241455078125,
    },
    {
      x: 472.82608695652175,
      y: -0.13909912109375,
    },
    {
      x: 476.9021739130435,
      y: -0.14906692504882812,
    },
    {
      x: 480.97826086956525,
      y: -0.1640167236328125,
    },
    {
      x: 485.054347826087,
      y: -0.17398452758789062,
    },
    {
      x: 489.1304347826087,
      y: -0.188934326171875,
    },
    {
      x: 493.20652173913044,
      y: -0.1988983154296875,
    },
    {
      x: 497.2826086956522,
      y: -0.20886993408203125,
    },
    {
      x: 501.35869565217394,
      y: -0.21883392333984375,
    },
    {
      x: 505.4347826086957,
      y: -0.20886993408203125,
    },
    {
      x: 509.51086956521743,
      y: -0.1988983154296875,
    },
    {
      x: 513.5869565217391,
      y: -0.17896652221679688,
    },
    {
      x: 517.6630434782609,
      y: -0.15903091430664062,
    },
    {
      x: 521.7391304347826,
      y: -0.13909912109375,
    },
    {
      x: 525.8152173913044,
      y: -0.1241455078125,
    },
    {
      x: 529.8913043478261,
      y: -0.10421371459960938,
    },
    {
      x: 533.9673913043479,
      y: -0.08427810668945312,
    },
    {
      x: 538.0434782608696,
      y: -0.06932830810546875,
    },
    {
      x: 542.1195652173914,
      y: -0.049396514892578125,
    },
    {
      x: 546.195652173913,
      y: -0.029460906982421875,
    },
    {
      x: 550.2717391304348,
      y: -0.0145111083984375,
    },
    {
      x: 554.3478260869565,
      y: 0.0004425048828125,
    },
    {
      x: 558.4239130434783,
      y: 0.020374298095703125,
    },
    {
      x: 562.5,
      y: 0.0353240966796875,
    },
    {
      x: 566.5760869565217,
      y: 0.05525970458984375,
    },
    {
      x: 570.6521739130435,
      y: 0.07020950317382812,
    },
    {
      x: 574.7282608695652,
      y: 0.08017730712890625,
    },
    {
      x: 578.804347826087,
      y: 0.0851593017578125,
    },
    {
      x: 582.8804347826087,
      y: 0.09014511108398438,
    },
    {
      x: 586.9565217391305,
      y: 0.09014511108398438,
    },
    {
      x: 607.3369565217391,
      y: 0.09014511108398438,
    },
    {
      x: 611.4130434782609,
      y: 0.09512710571289062,
    },
    {
      x: 615.4891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 892.6630434782609,
      y: 0.09512710571289062,
    },
    {
      x: 896.7391304347826,
      y: 0.1349945068359375,
    },
    {
      x: 900.8152173913044,
      y: 0.2346649169921875,
    },
    {
      x: 904.8913043478261,
      y: 0.35427093505859375,
    },
    {
      x: 908.9673913043479,
      y: 0.4838409423828125,
    },
    {
      x: 913.0434782608696,
      y: 0.6134147644042969,
    },
    {
      x: 917.1195652173914,
      y: 0.7479705810546875,
    },
    {
      x: 921.1956521739131,
      y: 0.8775367736816406,
    },
    {
      x: 925.2717391304348,
      y: 1.0021286010742188,
    },
    {
      x: 929.3478260869565,
      y: 1.1267127990722656,
    },
    {
      x: 933.4239130434783,
      y: 1.2513046264648438,
    },
    {
      x: 937.5,
      y: 1.3111076354980469,
    },
    {
      x: 941.5760869565217,
      y: 1.2463188171386719,
    },
    {
      x: 945.6521739130435,
      y: 1.1317024230957031,
    },
    {
      x: 949.7282608695652,
      y: 1.0021286010742188,
    },
    {
      x: 953.804347826087,
      y: 0.8625869750976562,
    },
    {
      x: 957.8804347826087,
      y: 0.7280311584472656,
    },
    {
      x: 961.9565217391305,
      y: 0.5934791564941406,
    },
    {
      x: 966.0326086956522,
      y: 0.45892333984375,
    },
    {
      x: 970.108695652174,
      y: 0.3293495178222656,
    },
    {
      x: 974.1847826086956,
      y: 0.19479751586914062,
    },
    {
      x: 978.2608695652174,
      y: 0.0851593017578125,
    },
    {
      x: 982.3369565217391,
      y: 0.045291900634765625,
    },
    {
      x: 986.4130434782609,
      y: 0.0353240966796875,
    },
    {
      x: 990.4891304347826,
      y: 0.03034210205078125,
    },
    {
      x: 994.5652173913044,
      y: 0.03034210205078125,
    },
    {
      x: 1006.7934782608696,
      y: 0.03034210205078125,
    },
    {
      x: 1010.8695652173914,
      y: 0.0353240966796875,
    },
    {
      x: 1014.9456521739131,
      y: 0.0353240966796875,
    },
    {
      x: 1023.0978260869565,
      y: 0.0353240966796875,
    },
    {
      x: 1027.1739130434783,
      y: 0.040309906005859375,
    },
    {
      x: 1031.25,
      y: 0.040309906005859375,
    },
    {
      x: 1039.4021739130435,
      y: 0.040309906005859375,
    },
    {
      x: 1043.4782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 1047.554347826087,
      y: 0.09512710571289062,
    },
    {
      x: 1300.2717391304348,
      y: 0.09512710571289062,
    },
    {
      x: 1304.3478260869565,
      y: -0.20886993408203125,
    },
    {
      x: 1308.4239130434783,
      y: -0.21883392333984375,
    },
    {
      x: 1312.5,
      y: -0.20886993408203125,
    },
    {
      x: 1316.5760869565217,
      y: -0.1988983154296875,
    },
    {
      x: 1320.6521739130435,
      y: -0.17896652221679688,
    },
    {
      x: 1324.7282608695652,
      y: -0.15903091430664062,
    },
    {
      x: 1328.804347826087,
      y: -0.13909912109375,
    },
    {
      x: 1332.8804347826087,
      y: -0.1241455078125,
    },
    {
      x: 1336.9565217391305,
      y: -0.10421371459960938,
    },
    {
      x: 1341.0326086956522,
      y: -0.08427810668945312,
    },
    {
      x: 1345.108695652174,
      y: -0.06932830810546875,
    },
    {
      x: 1349.1847826086957,
      y: -0.049396514892578125,
    },
    {
      x: 1353.2608695652175,
      y: -0.029460906982421875,
    },
    {
      x: 1357.3369565217392,
      y: -0.0145111083984375,
    },
    {
      x: 1361.413043478261,
      y: 0.0004425048828125,
    },
    {
      x: 1365.4891304347827,
      y: 0.020374298095703125,
    },
    {
      x: 1369.5652173913045,
      y: 0.0353240966796875,
    },
    {
      x: 1373.6413043478262,
      y: 0.05525970458984375,
    },
    {
      x: 1377.7173913043478,
      y: 0.07020950317382812,
    },
    {
      x: 1381.7934782608695,
      y: 0.08017730712890625,
    },
    {
      x: 1385.8695652173913,
      y: 0.0851593017578125,
    },
    {
      x: 1389.945652173913,
      y: 0.09014511108398438,
    },
    {
      x: 1394.0217391304348,
      y: 0.09014511108398438,
    },
    {
      x: 1414.4021739130435,
      y: 0.09014511108398438,
    },
    {
      x: 1418.4782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 1422.554347826087,
      y: 0.09512710571289062,
    },
    {
      x: 1561.1413043478262,
      y: 0.09512710571289062,
    },
    {
      x: 1565.217391304348,
      y: 0.0353240966796875,
    },
    {
      x: 1569.2934782608695,
      y: 0.0353240966796875,
    },
    {
      x: 1573.3695652173913,
      y: 0.040309906005859375,
    },
    {
      x: 1577.445652173913,
      y: 0.040309906005859375,
    },
    {
      x: 1585.5978260869565,
      y: 0.040309906005859375,
    },
    {
      x: 1589.6739130434783,
      y: 0.045291900634765625,
    },
    {
      x: 1593.75,
      y: 0.045291900634765625,
    },
    {
      x: 1610.054347826087,
      y: 0.045291900634765625,
    },
    {
      x: 1614.1304347826087,
      y: 0.050273895263671875,
    },
    {
      x: 1618.2065217391305,
      y: 0.050273895263671875,
    },
    {
      x: 1642.663043478261,
      y: 0.050273895263671875,
    },
    {
      x: 1646.7391304347827,
      y: 0.05525970458984375,
    },
    {
      x: 1650.8152173913045,
      y: 0.05525970458984375,
    },
    {
      x: 1675.2717391304348,
      y: 0.05525970458984375,
    },
    {
      x: 1679.3478260869565,
      y: 0.06024169921875,
    },
    {
      x: 1683.4239130434783,
      y: 0.06024169921875,
    },
    {
      x: 1707.8804347826087,
      y: 0.06024169921875,
    },
    {
      x: 1711.9565217391305,
      y: 0.06522369384765625,
    },
    {
      x: 1716.0326086956522,
      y: 0.06522369384765625,
    },
    {
      x: 1740.4891304347827,
      y: 0.06522369384765625,
    },
    {
      x: 1744.5652173913045,
      y: 0.07020950317382812,
    },
    {
      x: 1748.6413043478262,
      y: 0.06522369384765625,
    },
    {
      x: 1752.717391304348,
      y: 0.040309906005859375,
    },
    {
      x: 1756.7934782608695,
      y: 0.015392303466796875,
    },
    {
      x: 1760.8695652173913,
      y: -0.004543304443359375,
    },
    {
      x: 1764.945652173913,
      y: -0.01949310302734375,
    },
    {
      x: 1769.0217391304348,
      y: -0.034442901611328125,
    },
    {
      x: 1773.0978260869565,
      y: -0.049396514892578125,
    },
    {
      x: 1777.1739130434783,
      y: -0.05936431884765625,
    },
    {
      x: 1781.25,
      y: -0.074310302734375,
    },
    {
      x: 1785.3260869565217,
      y: -0.089263916015625,
    },
    {
      x: 1789.4021739130435,
      y: -0.09923171997070312,
    },
    {
      x: 1793.4782608695652,
      y: -0.1141815185546875,
    },
    {
      x: 1797.554347826087,
      y: -0.1241455078125,
    },
    {
      x: 1801.6304347826087,
      y: -0.13909912109375,
    },
    {
      x: 1805.7065217391305,
      y: -0.14906692504882812,
    },
    {
      x: 1809.7826086956522,
      y: -0.1640167236328125,
    },
    {
      x: 1813.858695652174,
      y: -0.17398452758789062,
    },
    {
      x: 1817.9347826086957,
      y: -0.188934326171875,
    },
    {
      x: 1822.0108695652175,
      y: -0.1988983154296875,
    },
    {
      x: 1826.0869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 1830.163043478261,
      y: 0.09512710571289062,
    },
    {
      x: 1960.5978260869565,
      y: 0.09512710571289062,
    },
    {
      x: 1964.6739130434783,
      y: 0.1349945068359375,
    },
    {
      x: 1968.75,
      y: 0.2346649169921875,
    },
    {
      x: 1972.8260869565217,
      y: 0.35427093505859375,
    },
    {
      x: 1976.9021739130435,
      y: 0.4838409423828125,
    },
    {
      x: 1980.9782608695652,
      y: 0.6134147644042969,
    },
    {
      x: 1985.054347826087,
      y: 0.7479705810546875,
    },
    {
      x: 1989.1304347826087,
      y: 0.8775367736816406,
    },
    {
      x: 1993.2065217391305,
      y: 1.0021286010742188,
    },
    {
      x: 1997.2826086956522,
      y: 1.1267127990722656,
    },
    {
      x: 2001.358695652174,
      y: 1.2513046264648438,
    },
    {
      x: 2005.4347826086957,
      y: 1.3111076354980469,
    },
    {
      x: 2009.5108695652175,
      y: 1.2463188171386719,
    },
    {
      x: 2013.5869565217392,
      y: 1.1317024230957031,
    },
    {
      x: 2017.663043478261,
      y: 1.0021286010742188,
    },
    {
      x: 2021.7391304347827,
      y: 0.8625869750976562,
    },
    {
      x: 2025.8152173913045,
      y: 0.7280311584472656,
    },
    {
      x: 2029.8913043478262,
      y: 0.5934791564941406,
    },
    {
      x: 2033.967391304348,
      y: 0.45892333984375,
    },
    {
      x: 2038.0434782608697,
      y: 0.3293495178222656,
    },
    {
      x: 2042.1195652173913,
      y: 0.19479751586914062,
    },
    {
      x: 2046.195652173913,
      y: 0.0851593017578125,
    },
    {
      x: 2050.271739130435,
      y: 0.045291900634765625,
    },
    {
      x: 2054.3478260869565,
      y: 0.0353240966796875,
    },
    {
      x: 2058.4239130434785,
      y: 0.03034210205078125,
    },
    {
      x: 2062.5,
      y: 0.03034210205078125,
    },
    {
      x: 2074.7282608695655,
      y: 0.03034210205078125,
    },
    {
      x: 2078.804347826087,
      y: 0.0353240966796875,
    },
    {
      x: 2082.880434782609,
      y: 0.0353240966796875,
    },
    {
      x: 2086.9565217391305,
      y: 0.09512710571289062,
    },
    {
      x: 2091.032608695652,
      y: 0.09512710571289062,
    },
    {
      x: 2343.75,
      y: 0.09512710571289062,
    },
    {
      x: 2347.826086956522,
      y: -0.13411712646484375,
    },
    {
      x: 2351.9021739130435,
      y: -0.14906692504882812,
    },
    {
      x: 2355.9782608695655,
      y: -0.15903091430664062,
    },
    {
      x: 2360.054347826087,
      y: -0.17398452758789062,
    },
    {
      x: 2364.130434782609,
      y: -0.18394851684570312,
    },
    {
      x: 2368.2065217391305,
      y: -0.1988983154296875,
    },
    {
      x: 2372.282608695652,
      y: -0.20886993408203125,
    },
    {
      x: 2376.358695652174,
      y: -0.21883392333984375,
    },
    {
      x: 2380.4347826086955,
      y: -0.20886993408203125,
    },
    {
      x: 2384.5108695652175,
      y: -0.19391632080078125,
    },
    {
      x: 2388.586956521739,
      y: -0.17896652221679688,
    },
    {
      x: 2392.663043478261,
      y: -0.15903091430664062,
    },
    {
      x: 2396.7391304347825,
      y: -0.13909912109375,
    },
    {
      x: 2400.8152173913045,
      y: -0.11916351318359375,
    },
    {
      x: 2404.891304347826,
      y: -0.10421371459960938,
    },
    {
      x: 2408.967391304348,
      y: -0.08427810668945312,
    },
    {
      x: 2413.0434782608695,
      y: -0.0643463134765625,
    },
    {
      x: 2417.1195652173915,
      y: -0.049396514892578125,
    },
    {
      x: 2421.195652173913,
      y: -0.029460906982421875,
    },
    {
      x: 2425.271739130435,
      y: -0.0145111083984375,
    },
    {
      x: 2429.3478260869565,
      y: 0.00542449951171875,
    },
    {
      x: 2433.4239130434785,
      y: 0.020374298095703125,
    },
    {
      x: 2437.5,
      y: 0.040309906005859375,
    },
    {
      x: 2441.576086956522,
      y: 0.05525970458984375,
    },
    {
      x: 2449.7282608695655,
      y: 0.0851593017578125,
    },
    {
      x: 2453.804347826087,
      y: 0.09014511108398438,
    },
    {
      x: 2457.880434782609,
      y: 0.09014511108398438,
    },
    {
      x: 2474.1847826086955,
      y: 0.09014511108398438,
    },
    {
      x: 2478.2608695652175,
      y: 0.09512710571289062,
    },
    {
      x: 2482.336956521739,
      y: 0.09512710571289062,
    },
    {
      x: 2604.6195652173915,
      y: 0.09512710571289062,
    },
    {
      x: 2608.695652173913,
      y: 0.025356292724609375,
    },
    {
      x: 2612.771739130435,
      y: 0.025356292724609375,
    },
    {
      x: 2620.9239130434785,
      y: 0.025356292724609375,
    },
    {
      x: 2625,
      y: 0.03034210205078125,
    },
    {
      x: 2629.076086956522,
      y: 0.03034210205078125,
    },
    {
      x: 2637.2282608695655,
      y: 0.03034210205078125,
    },
    {
      x: 2641.304347826087,
      y: 0.0353240966796875,
    },
    {
      x: 2645.380434782609,
      y: 0.0353240966796875,
    },
    {
      x: 2653.5326086956525,
      y: 0.0353240966796875,
    },
    {
      x: 2657.608695652174,
      y: 0.040309906005859375,
    },
    {
      x: 2661.6847826086955,
      y: 0.040309906005859375,
    },
    {
      x: 2669.836956521739,
      y: 0.040309906005859375,
    },
    {
      x: 2673.913043478261,
      y: 0.045291900634765625,
    },
    {
      x: 2677.9891304347825,
      y: 0.045291900634765625,
    },
    {
      x: 2694.2934782608695,
      y: 0.045291900634765625,
    },
    {
      x: 2698.3695652173915,
      y: 0.050273895263671875,
    },
    {
      x: 2702.445652173913,
      y: 0.050273895263671875,
    },
    {
      x: 2726.9021739130435,
      y: 0.050273895263671875,
    },
    {
      x: 2730.9782608695655,
      y: 0.05525970458984375,
    },
    {
      x: 2735.054347826087,
      y: 0.05525970458984375,
    },
    {
      x: 2759.5108695652175,
      y: 0.05525970458984375,
    },
    {
      x: 2763.586956521739,
      y: 0.06024169921875,
    },
    {
      x: 2767.663043478261,
      y: 0.06024169921875,
    },
    {
      x: 2792.1195652173915,
      y: 0.06024169921875,
    },
    {
      x: 2796.195652173913,
      y: 0.06522369384765625,
    },
    {
      x: 2800.271739130435,
      y: 0.06522369384765625,
    },
    {
      x: 2812.5,
      y: 0.06522369384765625,
    },
    {
      x: 2816.576086956522,
      y: 0.06024169921875,
    },
    {
      x: 2820.6521739130435,
      y: 0.040309906005859375,
    },
    {
      x: 2824.7282608695655,
      y: 0.015392303466796875,
    },
    {
      x: 2828.804347826087,
      y: -0.004543304443359375,
    },
    {
      x: 2832.880434782609,
      y: -0.01949310302734375,
    },
    {
      x: 2836.9565217391305,
      y: -0.029460906982421875,
    },
    {
      x: 2841.0326086956525,
      y: -0.04441070556640625,
    },
    {
      x: 2845.108695652174,
      y: -0.05936431884765625,
    },
    {
      x: 2849.1847826086955,
      y: -0.074310302734375,
    },
    {
      x: 2853.2608695652175,
      y: -0.08427810668945312,
    },
    {
      x: 2857.336956521739,
      y: -0.09923171997070312,
    },
    {
      x: 2861.413043478261,
      y: -0.10919570922851562,
    },
    {
      x: 2865.4891304347825,
      y: -0.1241455078125,
    },
    {
      x: 2869.5652173913045,
      y: 0.09512710571289062,
    },
    {
      x: 2873.641304347826,
      y: 0.09512710571289062,
    },
    {
      x: 3028.5326086956525,
      y: 0.09512710571289062,
    },
    {
      x: 3032.608695652174,
      y: 0.1349945068359375,
    },
    {
      x: 3036.6847826086955,
      y: 0.2346649169921875,
    },
    {
      x: 3040.7608695652175,
      y: 0.35427093505859375,
    },
    {
      x: 3044.836956521739,
      y: 0.4838409423828125,
    },
    {
      x: 3048.913043478261,
      y: 0.6134147644042969,
    },
    {
      x: 3052.9891304347825,
      y: 0.7479705810546875,
    },
    {
      x: 3057.0652173913045,
      y: 0.8775367736816406,
    },
    {
      x: 3061.141304347826,
      y: 1.0021286010742188,
    },
    {
      x: 3065.217391304348,
      y: 1.1267127990722656,
    },
    {
      x: 3069.2934782608695,
      y: 1.2513046264648438,
    },
    {
      x: 3073.3695652173915,
      y: 1.3111076354980469,
    },
    {
      x: 3077.445652173913,
      y: 1.2463188171386719,
    },
    {
      x: 3081.521739130435,
      y: 1.1317024230957031,
    },
    {
      x: 3085.5978260869565,
      y: 1.0021286010742188,
    },
    {
      x: 3089.6739130434785,
      y: 0.8625869750976562,
    },
    {
      x: 3093.75,
      y: 0.7280311584472656,
    },
    {
      x: 3097.826086956522,
      y: 0.5884971618652344,
    },
    {
      x: 3101.9021739130435,
      y: 0.45394134521484375,
    },
    {
      x: 3105.9782608695655,
      y: 0.3243675231933594,
    },
    {
      x: 3110.054347826087,
      y: 0.18981552124023438,
    },
    {
      x: 3114.130434782609,
      y: 0.08017730712890625,
    },
    {
      x: 3118.2065217391305,
      y: 0.040309906005859375,
    },
    {
      x: 3122.2826086956525,
      y: 0.03034210205078125,
    },
    {
      x: 3126.358695652174,
      y: 0.025356292724609375,
    },
    {
      x: 3130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 3134.5108695652175,
      y: 0.09512710571289062,
    },
    {
      x: 3387.2282608695655,
      y: 0.09512710571289062,
    },
    {
      x: 3391.304347826087,
      y: 0.3243675231933594,
    },
    {
      x: 3395.380434782609,
      y: 0.18981552124023438,
    },
    {
      x: 3399.4565217391305,
      y: 0.08017730712890625,
    },
    {
      x: 3403.5326086956525,
      y: 0.040309906005859375,
    },
    {
      x: 3407.608695652174,
      y: 0.03034210205078125,
    },
    {
      x: 3411.684782608696,
      y: 0.025356292724609375,
    },
    {
      x: 3415.7608695652175,
      y: 0.025356292724609375,
    },
    {
      x: 3427.9891304347825,
      y: 0.025356292724609375,
    },
    {
      x: 3432.0652173913045,
      y: 0.03034210205078125,
    },
    {
      x: 3436.141304347826,
      y: 0.03034210205078125,
    },
    {
      x: 3444.2934782608695,
      y: 0.03034210205078125,
    },
    {
      x: 3448.3695652173915,
      y: 0.0353240966796875,
    },
    {
      x: 3452.445652173913,
      y: 0.0353240966796875,
    },
    {
      x: 3460.5978260869565,
      y: 0.0353240966796875,
    },
    {
      x: 3464.6739130434785,
      y: 0.040309906005859375,
    },
    {
      x: 3468.75,
      y: 0.040309906005859375,
    },
    {
      x: 3476.9021739130435,
      y: 0.040309906005859375,
    },
    {
      x: 3480.9782608695655,
      y: 0.045291900634765625,
    },
    {
      x: 3485.054347826087,
      y: 0.045291900634765625,
    },
    {
      x: 3501.358695652174,
      y: 0.045291900634765625,
    },
    {
      x: 3505.434782608696,
      y: 0.050273895263671875,
    },
    {
      x: 3509.5108695652175,
      y: 0.050273895263671875,
    },
    {
      x: 3533.967391304348,
      y: 0.050273895263671875,
    },
    {
      x: 3538.0434782608695,
      y: 0.05525970458984375,
    },
    {
      x: 3542.1195652173915,
      y: 0.05525970458984375,
    },
    {
      x: 3566.576086956522,
      y: 0.05525970458984375,
    },
    {
      x: 3570.6521739130435,
      y: 0.06024169921875,
    },
    {
      x: 3574.7282608695655,
      y: 0.06024169921875,
    },
    {
      x: 3599.184782608696,
      y: 0.06024169921875,
    },
    {
      x: 3603.2608695652175,
      y: 0.06522369384765625,
    },
    {
      x: 3607.336956521739,
      y: 0.06522369384765625,
    },
    {
      x: 3619.5652173913045,
      y: 0.06522369384765625,
    },
    {
      x: 3623.641304347826,
      y: 0.06024169921875,
    },
    {
      x: 3627.717391304348,
      y: 0.040309906005859375,
    },
    {
      x: 3631.7934782608695,
      y: 0.015392303466796875,
    },
    {
      x: 3635.8695652173915,
      y: -0.004543304443359375,
    },
    {
      x: 3639.945652173913,
      y: -0.01949310302734375,
    },
    {
      x: 3644.021739130435,
      y: -0.029460906982421875,
    },
    {
      x: 3648.0978260869565,
      y: -0.04441070556640625,
    },
    {
      x: 3652.1739130434785,
      y: -0.05936431884765625,
    },
    {
      x: 3656.25,
      y: -0.074310302734375,
    },
    {
      x: 3660.326086956522,
      y: -0.08427810668945312,
    },
    {
      x: 3664.4021739130435,
      y: -0.09923171997070312,
    },
    {
      x: 3668.4782608695655,
      y: -0.10919570922851562,
    },
    {
      x: 3672.554347826087,
      y: -0.1241455078125,
    },
    {
      x: 3676.630434782609,
      y: -0.13411712646484375,
    },
    {
      x: 3680.7065217391305,
      y: -0.14906692504882812,
    },
    {
      x: 3684.7826086956525,
      y: -0.15903091430664062,
    },
    {
      x: 3688.858695652174,
      y: -0.17398452758789062,
    },
    {
      x: 3692.934782608696,
      y: -0.18394851684570312,
    },
    {
      x: 3697.0108695652175,
      y: -0.19391632080078125,
    },
    {
      x: 3701.086956521739,
      y: -0.20886993408203125,
    },
    {
      x: 3705.163043478261,
      y: -0.21883392333984375,
    },
    {
      x: 3709.2391304347825,
      y: -0.20886993408203125,
    },
    {
      x: 3713.3152173913045,
      y: -0.19391632080078125,
    },
    {
      x: 3717.391304347826,
      y: -0.17896652221679688,
    },
    {
      x: 3721.467391304348,
      y: -0.15903091430664062,
    },
    {
      x: 3725.5434782608695,
      y: -0.13909912109375,
    },
    {
      x: 3729.6195652173915,
      y: -0.11916351318359375,
    },
    {
      x: 3733.695652173913,
      y: -0.10421371459960938,
    },
    {
      x: 3737.771739130435,
      y: -0.08427810668945312,
    },
    {
      x: 3741.8478260869565,
      y: -0.0643463134765625,
    },
    {
      x: 3745.9239130434785,
      y: -0.049396514892578125,
    },
    {
      x: 3750,
      y: -0.029460906982421875,
    },
    {
      x: 3754.076086956522,
      y: -0.0145111083984375,
    },
    {
      x: 3758.1521739130435,
      y: 0.00542449951171875,
    },
    {
      x: 3762.2282608695655,
      y: 0.020374298095703125,
    },
    {
      x: 3766.304347826087,
      y: 0.040309906005859375,
    },
    {
      x: 3770.380434782609,
      y: 0.05525970458984375,
    },
    {
      x: 3778.5326086956525,
      y: 0.0851593017578125,
    },
    {
      x: 3782.608695652174,
      y: 0.09014511108398438,
    },
    {
      x: 3786.684782608696,
      y: 0.09014511108398438,
    },
    {
      x: 3802.9891304347825,
      y: 0.09014511108398438,
    },
    {
      x: 3807.0652173913045,
      y: 0.09512710571289062,
    },
    {
      x: 3811.141304347826,
      y: 0.09512710571289062,
    },
    {
      x: 4096.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 4100.54347826087,
      y: 0.1349945068359375,
    },
    {
      x: 4104.619565217391,
      y: 0.2346649169921875,
    },
    {
      x: 4108.695652173913,
      y: 0.35427093505859375,
    },
    {
      x: 4112.771739130435,
      y: 0.4838409423828125,
    },
    {
      x: 4116.847826086957,
      y: 0.6134147644042969,
    },
    {
      x: 4120.923913043478,
      y: 0.7479705810546875,
    },
    {
      x: 4125,
      y: 0.8775367736816406,
    },
    {
      x: 4129.076086956522,
      y: 1.0021286010742188,
    },
    {
      x: 4133.152173913044,
      y: 1.1267127990722656,
    },
    {
      x: 4137.228260869565,
      y: 1.2513046264648438,
    },
    {
      x: 4141.304347826087,
      y: 1.3111076354980469,
    },
    {
      x: 4145.380434782609,
      y: 1.2463188171386719,
    },
    {
      x: 4149.456521739131,
      y: 1.1317024230957031,
    },
    {
      x: 4153.532608695652,
      y: 1.0021286010742188,
    },
    {
      x: 4157.608695652174,
      y: 0.8625869750976562,
    },
    {
      x: 4161.684782608696,
      y: 0.7280311584472656,
    },
    {
      x: 4165.760869565218,
      y: 0.5884971618652344,
    },
    {
      x: 4169.836956521739,
      y: 0.45394134521484375,
    },
    {
      x: 4173.913043478261,
      y: 0.09512710571289062,
    },
    {
      x: 4177.989130434783,
      y: 0.09512710571289062,
    },
    {
      x: 4430.706521739131,
      y: 0.09512710571289062,
    },
    {
      x: 4434.782608695652,
      y: 0.040309906005859375,
    },
    {
      x: 4438.858695652174,
      y: 0.015392303466796875,
    },
    {
      x: 4442.934782608696,
      y: -0.004543304443359375,
    },
    {
      x: 4447.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 4451.086956521739,
      y: -0.029460906982421875,
    },
    {
      x: 4455.163043478261,
      y: -0.04441070556640625,
    },
    {
      x: 4459.239130434783,
      y: -0.05936431884765625,
    },
    {
      x: 4463.315217391304,
      y: -0.074310302734375,
    },
    {
      x: 4467.391304347826,
      y: -0.08427810668945312,
    },
    {
      x: 4471.467391304348,
      y: -0.09923171997070312,
    },
    {
      x: 4475.54347826087,
      y: -0.10919570922851562,
    },
    {
      x: 4479.619565217391,
      y: -0.1241455078125,
    },
    {
      x: 4483.695652173913,
      y: -0.13411712646484375,
    },
    {
      x: 4487.771739130435,
      y: -0.14906692504882812,
    },
    {
      x: 4491.847826086957,
      y: -0.15903091430664062,
    },
    {
      x: 4495.923913043478,
      y: -0.17398452758789062,
    },
    {
      x: 4500,
      y: -0.18394851684570312,
    },
    {
      x: 4504.076086956522,
      y: -0.19391632080078125,
    },
    {
      x: 4508.152173913044,
      y: -0.20886993408203125,
    },
    {
      x: 4512.228260869565,
      y: -0.2138519287109375,
    },
    {
      x: 4516.304347826087,
      y: -0.20886993408203125,
    },
    {
      x: 4520.380434782609,
      y: -0.19391632080078125,
    },
    {
      x: 4524.456521739131,
      y: -0.17896652221679688,
    },
    {
      x: 4528.532608695652,
      y: -0.15903091430664062,
    },
    {
      x: 4532.608695652174,
      y: -0.13909912109375,
    },
    {
      x: 4536.684782608696,
      y: -0.11916351318359375,
    },
    {
      x: 4540.760869565218,
      y: -0.10421371459960938,
    },
    {
      x: 4544.836956521739,
      y: -0.08427810668945312,
    },
    {
      x: 4548.913043478261,
      y: -0.0643463134765625,
    },
    {
      x: 4552.989130434783,
      y: -0.049396514892578125,
    },
    {
      x: 4557.065217391304,
      y: -0.029460906982421875,
    },
    {
      x: 4561.141304347826,
      y: -0.0145111083984375,
    },
    {
      x: 4565.217391304348,
      y: 0.00542449951171875,
    },
    {
      x: 4569.29347826087,
      y: 0.020374298095703125,
    },
    {
      x: 4573.369565217391,
      y: 0.040309906005859375,
    },
    {
      x: 4577.445652173913,
      y: 0.05525970458984375,
    },
    {
      x: 4585.597826086957,
      y: 0.0851593017578125,
    },
    {
      x: 4589.673913043478,
      y: 0.09014511108398438,
    },
    {
      x: 4593.75,
      y: 0.09014511108398438,
    },
    {
      x: 4610.054347826087,
      y: 0.09014511108398438,
    },
    {
      x: 4614.130434782609,
      y: 0.09512710571289062,
    },
    {
      x: 4618.206521739131,
      y: 0.09512710571289062,
    },
    {
      x: 4691.576086956522,
      y: 0.09512710571289062,
    },
    {
      x: 4695.652173913044,
      y: 1.1317024230957031,
    },
    {
      x: 4699.728260869565,
      y: 1.0021286010742188,
    },
    {
      x: 4703.804347826087,
      y: 0.8625869750976562,
    },
    {
      x: 4707.880434782609,
      y: 0.7280311584472656,
    },
    {
      x: 4711.956521739131,
      y: 0.5884971618652344,
    },
    {
      x: 4716.032608695652,
      y: 0.45394134521484375,
    },
    {
      x: 4720.108695652174,
      y: 0.3243675231933594,
    },
    {
      x: 4724.184782608696,
      y: 0.18981552124023438,
    },
    {
      x: 4728.260869565218,
      y: 0.08017730712890625,
    },
    {
      x: 4732.336956521739,
      y: 0.040309906005859375,
    },
    {
      x: 4736.413043478261,
      y: 0.03034210205078125,
    },
    {
      x: 4740.489130434783,
      y: 0.025356292724609375,
    },
    {
      x: 4744.565217391304,
      y: 0.025356292724609375,
    },
    {
      x: 4756.79347826087,
      y: 0.025356292724609375,
    },
    {
      x: 4760.869565217391,
      y: 0.03034210205078125,
    },
    {
      x: 4764.945652173913,
      y: 0.03034210205078125,
    },
    {
      x: 4773.097826086957,
      y: 0.03034210205078125,
    },
    {
      x: 4777.173913043478,
      y: 0.0353240966796875,
    },
    {
      x: 4781.25,
      y: 0.0353240966796875,
    },
    {
      x: 4789.402173913044,
      y: 0.0353240966796875,
    },
    {
      x: 4793.478260869565,
      y: 0.040309906005859375,
    },
    {
      x: 4797.554347826087,
      y: 0.040309906005859375,
    },
    {
      x: 4805.706521739131,
      y: 0.040309906005859375,
    },
    {
      x: 4809.782608695652,
      y: 0.045291900634765625,
    },
    {
      x: 4813.858695652174,
      y: 0.045291900634765625,
    },
    {
      x: 4830.163043478261,
      y: 0.045291900634765625,
    },
    {
      x: 4834.239130434783,
      y: 0.050273895263671875,
    },
    {
      x: 4838.315217391304,
      y: 0.050273895263671875,
    },
    {
      x: 4862.771739130435,
      y: 0.050273895263671875,
    },
    {
      x: 4866.847826086957,
      y: 0.05525970458984375,
    },
    {
      x: 4870.923913043478,
      y: 0.05525970458984375,
    },
    {
      x: 4895.380434782609,
      y: 0.05525970458984375,
    },
    {
      x: 4899.456521739131,
      y: 0.06024169921875,
    },
    {
      x: 4903.532608695652,
      y: 0.06024169921875,
    },
    {
      x: 4927.989130434783,
      y: 0.06024169921875,
    },
    {
      x: 4932.065217391305,
      y: 0.06522369384765625,
    },
    {
      x: 4936.141304347826,
      y: 0.06522369384765625,
    },
    {
      x: 4948.369565217391,
      y: 0.06522369384765625,
    },
    {
      x: 4952.445652173913,
      y: 0.06024169921875,
    },
    {
      x: 4956.521739130435,
      y: 0.09512710571289062,
    },
    {
      x: 4960.597826086957,
      y: 0.09512710571289062,
    },
    {
      x: 5164.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 5168.478260869565,
      y: 0.1349945068359375,
    },
    {
      x: 5172.554347826087,
      y: 0.2346649169921875,
    },
    {
      x: 5176.630434782609,
      y: 0.35427093505859375,
    },
    {
      x: 5180.706521739131,
      y: 0.4838409423828125,
    },
    {
      x: 5184.782608695652,
      y: 0.6134147644042969,
    },
    {
      x: 5188.858695652174,
      y: 0.7479705810546875,
    },
    {
      x: 5192.934782608696,
      y: 0.8775367736816406,
    },
    {
      x: 5197.010869565218,
      y: 1.0021286010742188,
    },
    {
      x: 5201.086956521739,
      y: 1.1267127990722656,
    },
    {
      x: 5205.163043478261,
      y: 1.2513046264648438,
    },
    {
      x: 5209.239130434783,
      y: 1.3111076354980469,
    },
    {
      x: 5213.315217391305,
      y: 1.2463188171386719,
    },
    {
      x: 5217.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 5221.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 5449.728260869565,
      y: 0.09512710571289062,
    },
    {
      x: 5453.804347826087,
      y: 0.1349945068359375,
    },
    {
      x: 5457.880434782609,
      y: 0.2346649169921875,
    },
    {
      x: 5461.956521739131,
      y: 0.35427093505859375,
    },
    {
      x: 5466.032608695652,
      y: 0.4838409423828125,
    },
    {
      x: 5470.108695652174,
      y: 0.6134147644042969,
    },
    {
      x: 5474.184782608696,
      y: 0.7479705810546875,
    },
    {
      x: 5478.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 5482.336956521739,
      y: 0.09512710571289062,
    },
    {
      x: 5735.054347826087,
      y: 0.09512710571289062,
    },
    {
      x: 5739.130434782609,
      y: 0.8775367736816406,
    },
    {
      x: 5743.206521739131,
      y: 1.0021286010742188,
    },
    {
      x: 5747.282608695652,
      y: 1.1267127990722656,
    },
    {
      x: 5751.358695652174,
      y: 1.2513046264648438,
    },
    {
      x: 5755.434782608696,
      y: 1.3111076354980469,
    },
    {
      x: 5759.510869565218,
      y: 1.2463188171386719,
    },
    {
      x: 5763.586956521739,
      y: 1.1317024230957031,
    },
    {
      x: 5767.663043478261,
      y: 1.0021286010742188,
    },
    {
      x: 5771.739130434783,
      y: 0.8625869750976562,
    },
    {
      x: 5775.815217391305,
      y: 0.7280311584472656,
    },
    {
      x: 5779.891304347826,
      y: 0.5884971618652344,
    },
    {
      x: 5783.967391304348,
      y: 0.45394134521484375,
    },
    {
      x: 5788.04347826087,
      y: 0.3243675231933594,
    },
    {
      x: 5792.119565217391,
      y: 0.18981552124023438,
    },
    {
      x: 5796.195652173913,
      y: 0.08017730712890625,
    },
    {
      x: 5800.271739130435,
      y: 0.040309906005859375,
    },
    {
      x: 5804.347826086957,
      y: 0.03034210205078125,
    },
    {
      x: 5808.423913043478,
      y: 0.025356292724609375,
    },
    {
      x: 5812.5,
      y: 0.025356292724609375,
    },
    {
      x: 5824.728260869565,
      y: 0.025356292724609375,
    },
    {
      x: 5828.804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 5832.880434782609,
      y: 0.03034210205078125,
    },
    {
      x: 5841.032608695652,
      y: 0.03034210205078125,
    },
    {
      x: 5845.108695652174,
      y: 0.0353240966796875,
    },
    {
      x: 5849.184782608696,
      y: 0.0353240966796875,
    },
    {
      x: 5857.336956521739,
      y: 0.0353240966796875,
    },
    {
      x: 5861.413043478261,
      y: 0.040309906005859375,
    },
    {
      x: 5865.489130434783,
      y: 0.040309906005859375,
    },
    {
      x: 5873.641304347826,
      y: 0.040309906005859375,
    },
    {
      x: 5877.717391304348,
      y: 0.045291900634765625,
    },
    {
      x: 5881.79347826087,
      y: 0.045291900634765625,
    },
    {
      x: 5898.097826086957,
      y: 0.045291900634765625,
    },
    {
      x: 5902.173913043478,
      y: 0.050273895263671875,
    },
    {
      x: 5906.25,
      y: 0.050273895263671875,
    },
    {
      x: 5930.706521739131,
      y: 0.050273895263671875,
    },
    {
      x: 5934.782608695652,
      y: 0.05525970458984375,
    },
    {
      x: 5938.858695652174,
      y: 0.05525970458984375,
    },
    {
      x: 5963.315217391305,
      y: 0.05525970458984375,
    },
    {
      x: 5967.391304347826,
      y: 0.06024169921875,
    },
    {
      x: 5971.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 5995.923913043478,
      y: 0.06024169921875,
    },
    {
      x: 6000,
      y: 0.06522369384765625,
    },
    {
      x: 6004.076086956522,
      y: 0.06522369384765625,
    },
    {
      x: 6016.304347826087,
      y: 0.06522369384765625,
    },
    {
      x: 6020.380434782609,
      y: 0.06024169921875,
    },
    {
      x: 6024.456521739131,
      y: 0.040309906005859375,
    },
    {
      x: 6028.532608695652,
      y: 0.015392303466796875,
    },
    {
      x: 6032.608695652174,
      y: -0.004543304443359375,
    },
    {
      x: 6036.684782608696,
      y: -0.01949310302734375,
    },
    {
      x: 6040.760869565218,
      y: -0.029460906982421875,
    },
    {
      x: 6044.836956521739,
      y: -0.04441070556640625,
    },
    {
      x: 6048.913043478261,
      y: -0.05936431884765625,
    },
    {
      x: 6052.989130434783,
      y: -0.074310302734375,
    },
    {
      x: 6057.065217391305,
      y: -0.08427810668945312,
    },
    {
      x: 6061.141304347826,
      y: -0.09923171997070312,
    },
    {
      x: 6065.217391304348,
      y: -0.10919570922851562,
    },
    {
      x: 6069.29347826087,
      y: -0.1241455078125,
    },
    {
      x: 6073.369565217391,
      y: -0.13411712646484375,
    },
    {
      x: 6077.445652173913,
      y: -0.14906692504882812,
    },
    {
      x: 6081.521739130435,
      y: -0.15903091430664062,
    },
    {
      x: 6085.597826086957,
      y: -0.17398452758789062,
    },
    {
      x: 6089.673913043478,
      y: -0.18394851684570312,
    },
    {
      x: 6093.75,
      y: -0.19391632080078125,
    },
    {
      x: 6097.826086956522,
      y: -0.20886993408203125,
    },
    {
      x: 6101.902173913044,
      y: -0.21883392333984375,
    },
    {
      x: 6105.978260869565,
      y: -0.20886993408203125,
    },
    {
      x: 6110.054347826087,
      y: -0.19391632080078125,
    },
    {
      x: 6114.130434782609,
      y: -0.17896652221679688,
    },
    {
      x: 6118.206521739131,
      y: -0.15903091430664062,
    },
    {
      x: 6122.282608695652,
      y: -0.13909912109375,
    },
    {
      x: 6126.358695652174,
      y: -0.11916351318359375,
    },
    {
      x: 6130.434782608696,
      y: -0.10421371459960938,
    },
    {
      x: 6134.510869565218,
      y: -0.08427810668945312,
    },
    {
      x: 6138.586956521739,
      y: -0.0643463134765625,
    },
    {
      x: 6142.663043478261,
      y: -0.049396514892578125,
    },
    {
      x: 6146.739130434783,
      y: -0.029460906982421875,
    },
    {
      x: 6150.815217391305,
      y: -0.0145111083984375,
    },
    {
      x: 6154.891304347826,
      y: 0.00542449951171875,
    },
    {
      x: 6158.967391304348,
      y: 0.020374298095703125,
    },
    {
      x: 6163.04347826087,
      y: 0.040309906005859375,
    },
    {
      x: 6167.119565217391,
      y: 0.05525970458984375,
    },
    {
      x: 6175.271739130435,
      y: 0.0851593017578125,
    },
    {
      x: 6179.347826086957,
      y: 0.09014511108398438,
    },
    {
      x: 6183.423913043478,
      y: 0.09014511108398438,
    },
    {
      x: 6199.728260869565,
      y: 0.09014511108398438,
    },
    {
      x: 6203.804347826087,
      y: 0.09512710571289062,
    },
    {
      x: 6207.880434782609,
      y: 0.09512710571289062,
    },
    {
      x: 6778.532608695652,
      y: 0.09512710571289062,
    },
    {
      x: 6782.608695652174,
      y: 0.1349945068359375,
    },
    {
      x: 6786.684782608696,
      y: 0.2346649169921875,
    },
    {
      x: 6790.760869565218,
      y: 0.35427093505859375,
    },
    {
      x: 6794.836956521739,
      y: 0.4838409423828125,
    },
    {
      x: 6798.913043478261,
      y: 0.6134147644042969,
    },
    {
      x: 6802.989130434783,
      y: 0.7479705810546875,
    },
    {
      x: 6807.065217391305,
      y: 0.8775367736816406,
    },
    {
      x: 6811.141304347826,
      y: 1.0021286010742188,
    },
    {
      x: 6815.217391304348,
      y: 1.1267127990722656,
    },
    {
      x: 6819.29347826087,
      y: 1.2513046264648438,
    },
    {
      x: 6823.369565217392,
      y: 1.3111076354980469,
    },
    {
      x: 6827.445652173913,
      y: 1.2463188171386719,
    },
    {
      x: 6831.521739130435,
      y: 1.1317024230957031,
    },
    {
      x: 6835.597826086957,
      y: 1.0021286010742188,
    },
    {
      x: 6839.673913043478,
      y: 0.8625869750976562,
    },
    {
      x: 6843.75,
      y: 0.7280311584472656,
    },
    {
      x: 6847.826086956522,
      y: 0.5884971618652344,
    },
    {
      x: 6851.902173913044,
      y: 0.45394134521484375,
    },
    {
      x: 6855.978260869565,
      y: 0.3243675231933594,
    },
    {
      x: 6860.054347826087,
      y: 0.18981552124023438,
    },
    {
      x: 6864.130434782609,
      y: 0.08017730712890625,
    },
    {
      x: 6868.206521739131,
      y: 0.040309906005859375,
    },
    {
      x: 6872.282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 6876.358695652174,
      y: 0.025356292724609375,
    },
    {
      x: 6880.434782608696,
      y: 0.025356292724609375,
    },
    {
      x: 6892.663043478261,
      y: 0.025356292724609375,
    },
    {
      x: 6896.739130434783,
      y: 0.03034210205078125,
    },
    {
      x: 6900.815217391305,
      y: 0.03034210205078125,
    },
    {
      x: 6908.967391304348,
      y: 0.03034210205078125,
    },
    {
      x: 6913.04347826087,
      y: 0.0353240966796875,
    },
    {
      x: 6917.119565217392,
      y: 0.0353240966796875,
    },
    {
      x: 6925.271739130435,
      y: 0.0353240966796875,
    },
    {
      x: 6929.347826086957,
      y: 0.040309906005859375,
    },
    {
      x: 6933.423913043478,
      y: 0.040309906005859375,
    },
    {
      x: 6941.576086956522,
      y: 0.040309906005859375,
    },
    {
      x: 6945.652173913044,
      y: 0.045291900634765625,
    },
    {
      x: 6949.728260869565,
      y: 0.045291900634765625,
    },
    {
      x: 6966.032608695652,
      y: 0.045291900634765625,
    },
    {
      x: 6970.108695652174,
      y: 0.050273895263671875,
    },
    {
      x: 6974.184782608696,
      y: 0.050273895263671875,
    },
    {
      x: 6998.641304347826,
      y: 0.050273895263671875,
    },
    {
      x: 7002.717391304348,
      y: 0.05525970458984375,
    },
    {
      x: 7006.79347826087,
      y: 0.05525970458984375,
    },
    {
      x: 7031.25,
      y: 0.05525970458984375,
    },
    {
      x: 7035.326086956522,
      y: 0.06024169921875,
    },
    {
      x: 7039.402173913044,
      y: 0.06024169921875,
    },
    {
      x: 7063.858695652174,
      y: 0.06024169921875,
    },
    {
      x: 7067.934782608696,
      y: 0.06522369384765625,
    },
    {
      x: 7072.010869565218,
      y: 0.06522369384765625,
    },
    {
      x: 7084.239130434783,
      y: 0.06522369384765625,
    },
    {
      x: 7088.315217391305,
      y: 0.06024169921875,
    },
    {
      x: 7092.391304347826,
      y: 0.040309906005859375,
    },
    {
      x: 7096.467391304348,
      y: 0.015392303466796875,
    },
    {
      x: 7100.54347826087,
      y: -0.004543304443359375,
    },
    {
      x: 7104.619565217392,
      y: -0.01949310302734375,
    },
    {
      x: 7108.695652173913,
      y: -0.029460906982421875,
    },
    {
      x: 7112.771739130435,
      y: -0.04441070556640625,
    },
    {
      x: 7116.847826086957,
      y: -0.05936431884765625,
    },
    {
      x: 7120.923913043478,
      y: -0.074310302734375,
    },
    {
      x: 7125,
      y: -0.08427810668945312,
    },
    {
      x: 7129.076086956522,
      y: -0.09923171997070312,
    },
    {
      x: 7133.152173913044,
      y: -0.10919570922851562,
    },
    {
      x: 7137.228260869565,
      y: -0.1241455078125,
    },
    {
      x: 7141.304347826087,
      y: -0.13411712646484375,
    },
    {
      x: 7145.380434782609,
      y: -0.14906692504882812,
    },
    {
      x: 7149.456521739131,
      y: -0.15903091430664062,
    },
    {
      x: 7153.532608695652,
      y: -0.17398452758789062,
    },
    {
      x: 7157.608695652174,
      y: -0.18394851684570312,
    },
    {
      x: 7161.684782608696,
      y: -0.1988983154296875,
    },
    {
      x: 7165.760869565218,
      y: -0.20886993408203125,
    },
    {
      x: 7169.836956521739,
      y: -0.21883392333984375,
    },
    {
      x: 7173.913043478261,
      y: -0.20886993408203125,
    },
    {
      x: 7177.989130434783,
      y: -0.19391632080078125,
    },
    {
      x: 7182.065217391305,
      y: -0.17896652221679688,
    },
    {
      x: 7186.141304347826,
      y: -0.15903091430664062,
    },
    {
      x: 7190.217391304348,
      y: -0.13909912109375,
    },
    {
      x: 7194.29347826087,
      y: -0.11916351318359375,
    },
    {
      x: 7198.369565217392,
      y: -0.10421371459960938,
    },
    {
      x: 7202.445652173913,
      y: -0.08427810668945312,
    },
    {
      x: 7206.521739130435,
      y: -0.0643463134765625,
    },
    {
      x: 7210.597826086957,
      y: -0.049396514892578125,
    },
    {
      x: 7214.673913043478,
      y: -0.029460906982421875,
    },
    {
      x: 7218.75,
      y: -0.0145111083984375,
    },
    {
      x: 7222.826086956522,
      y: 0.00542449951171875,
    },
    {
      x: 7226.902173913044,
      y: 0.020374298095703125,
    },
    {
      x: 7230.978260869565,
      y: 0.040309906005859375,
    },
    {
      x: 7235.054347826087,
      y: 0.05525970458984375,
    },
    {
      x: 7243.206521739131,
      y: 0.0851593017578125,
    },
    {
      x: 7247.282608695652,
      y: 0.09014511108398438,
    },
    {
      x: 7251.358695652174,
      y: 0.09014511108398438,
    },
    {
      x: 7267.663043478261,
      y: 0.09014511108398438,
    },
    {
      x: 7271.739130434783,
      y: 0.09512710571289062,
    },
    {
      x: 7275.815217391305,
      y: 0.09512710571289062,
    },
    {
      x: 7324.728260869565,
      y: 0.09512710571289062,
    },
    {
      x: 7328.804347826087,
      y: 0.1349945068359375,
    },
    {
      x: 7332.880434782609,
      y: 0.2346649169921875,
    },
    {
      x: 7336.956521739131,
      y: 0.35427093505859375,
    },
    {
      x: 7341.032608695652,
      y: 0.4838409423828125,
    },
    {
      x: 7345.108695652174,
      y: 0.6134147644042969,
    },
    {
      x: 7349.184782608696,
      y: 0.7479705810546875,
    },
    {
      x: 7353.260869565218,
      y: 0.8775367736816406,
    },
    {
      x: 7357.336956521739,
      y: 1.0021286010742188,
    },
    {
      x: 7361.413043478261,
      y: 1.1267127990722656,
    },
    {
      x: 7365.489130434783,
      y: 1.2513046264648438,
    },
    {
      x: 7369.565217391305,
      y: 1.3111076354980469,
    },
    {
      x: 7373.641304347826,
      y: 1.2463188171386719,
    },
    {
      x: 7377.717391304348,
      y: 1.1317024230957031,
    },
    {
      x: 7381.79347826087,
      y: 1.0021286010742188,
    },
    {
      x: 7385.869565217392,
      y: 0.8625869750976562,
    },
    {
      x: 7389.945652173913,
      y: 0.7280311584472656,
    },
    {
      x: 7394.021739130435,
      y: 0.5884971618652344,
    },
    {
      x: 7398.097826086957,
      y: 0.45394134521484375,
    },
    {
      x: 7402.173913043478,
      y: 0.3243675231933594,
    },
    {
      x: 7406.25,
      y: 0.18981552124023438,
    },
    {
      x: 7410.326086956522,
      y: 0.08017730712890625,
    },
    {
      x: 7414.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 7418.478260869565,
      y: 0.03034210205078125,
    },
    {
      x: 7422.554347826087,
      y: 0.025356292724609375,
    },
    {
      x: 7426.630434782609,
      y: 0.025356292724609375,
    },
    {
      x: 7438.858695652174,
      y: 0.025356292724609375,
    },
    {
      x: 7442.934782608696,
      y: 0.03034210205078125,
    },
    {
      x: 7447.010869565218,
      y: 0.03034210205078125,
    },
    {
      x: 7455.163043478261,
      y: 0.03034210205078125,
    },
    {
      x: 7459.239130434783,
      y: 0.0353240966796875,
    },
    {
      x: 7463.315217391305,
      y: 0.0353240966796875,
    },
    {
      x: 7471.467391304348,
      y: 0.0353240966796875,
    },
    {
      x: 7475.54347826087,
      y: 0.040309906005859375,
    },
    {
      x: 7479.619565217392,
      y: 0.040309906005859375,
    },
    {
      x: 7487.771739130435,
      y: 0.040309906005859375,
    },
    {
      x: 7491.847826086957,
      y: 0.045291900634765625,
    },
    {
      x: 7495.923913043478,
      y: 0.045291900634765625,
    },
    {
      x: 7512.228260869565,
      y: 0.045291900634765625,
    },
    {
      x: 7516.304347826087,
      y: 0.050273895263671875,
    },
    {
      x: 7520.380434782609,
      y: 0.050273895263671875,
    },
    {
      x: 7544.836956521739,
      y: 0.050273895263671875,
    },
    {
      x: 7548.913043478261,
      y: 0.05525970458984375,
    },
    {
      x: 7552.989130434783,
      y: 0.05525970458984375,
    },
    {
      x: 7561.141304347826,
      y: 0.05525970458984375,
    },
    {
      x: 7565.217391304348,
      y: 0.09512710571289062,
    },
    {
      x: 7569.29347826087,
      y: 0.09512710571289062,
    },
    {
      x: 7822.010869565218,
      y: 0.09512710571289062,
    },
    {
      x: 7826.086956521739,
      y: 0.05525970458984375,
    },
    {
      x: 7830.163043478261,
      y: 0.05525970458984375,
    },
    {
      x: 7838.315217391305,
      y: 0.05525970458984375,
    },
    {
      x: 7842.391304347826,
      y: 0.06024169921875,
    },
    {
      x: 7846.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 7870.923913043479,
      y: 0.06024169921875,
    },
    {
      x: 7875,
      y: 0.06522369384765625,
    },
    {
      x: 7879.076086956522,
      y: 0.06522369384765625,
    },
    {
      x: 7891.304347826087,
      y: 0.06522369384765625,
    },
    {
      x: 7895.380434782609,
      y: 0.06024169921875,
    },
    {
      x: 7899.456521739131,
      y: 0.040309906005859375,
    },
    {
      x: 7903.532608695652,
      y: 0.015392303466796875,
    },
    {
      x: 7907.608695652174,
      y: -0.004543304443359375,
    },
    {
      x: 7911.684782608696,
      y: -0.01949310302734375,
    },
    {
      x: 7915.760869565218,
      y: -0.029460906982421875,
    },
    {
      x: 7919.836956521739,
      y: -0.04441070556640625,
    },
    {
      x: 7923.913043478261,
      y: -0.05936431884765625,
    },
    {
      x: 7927.989130434783,
      y: -0.074310302734375,
    },
    {
      x: 7932.065217391305,
      y: -0.08427810668945312,
    },
    {
      x: 7936.141304347826,
      y: -0.09923171997070312,
    },
    {
      x: 7940.217391304348,
      y: -0.10919570922851562,
    },
    {
      x: 7944.29347826087,
      y: -0.1241455078125,
    },
    {
      x: 7948.369565217392,
      y: -0.13411712646484375,
    },
    {
      x: 7952.445652173913,
      y: -0.14906692504882812,
    },
    {
      x: 7956.521739130435,
      y: -0.15903091430664062,
    },
    {
      x: 7960.597826086957,
      y: -0.17398452758789062,
    },
    {
      x: 7964.673913043479,
      y: -0.18394851684570312,
    },
    {
      x: 7968.75,
      y: -0.19391632080078125,
    },
    {
      x: 7972.826086956522,
      y: -0.20886993408203125,
    },
    {
      x: 7976.902173913044,
      y: -0.21883392333984375,
    },
    {
      x: 7980.978260869565,
      y: -0.20886993408203125,
    },
    {
      x: 7985.054347826087,
      y: -0.19391632080078125,
    },
    {
      x: 7989.130434782609,
      y: -0.17896652221679688,
    },
    {
      x: 7993.206521739131,
      y: -0.15903091430664062,
    },
    {
      x: 7997.282608695652,
      y: -0.13909912109375,
    },
    {
      x: 8001.358695652174,
      y: -0.11916351318359375,
    },
    {
      x: 8005.434782608696,
      y: -0.10421371459960938,
    },
    {
      x: 8009.510869565218,
      y: -0.08427810668945312,
    },
    {
      x: 8013.586956521739,
      y: -0.0643463134765625,
    },
    {
      x: 8017.663043478261,
      y: -0.049396514892578125,
    },
    {
      x: 8021.739130434783,
      y: -0.029460906982421875,
    },
    {
      x: 8025.815217391305,
      y: -0.0145111083984375,
    },
    {
      x: 8029.891304347826,
      y: 0.00542449951171875,
    },
    {
      x: 8033.967391304348,
      y: 0.020374298095703125,
    },
    {
      x: 8038.04347826087,
      y: 0.040309906005859375,
    },
    {
      x: 8042.119565217392,
      y: 0.05525970458984375,
    },
    {
      x: 8050.271739130435,
      y: 0.0851593017578125,
    },
    {
      x: 8054.347826086957,
      y: 0.09014511108398438,
    },
    {
      x: 8058.423913043479,
      y: 0.09014511108398438,
    },
    {
      x: 8074.728260869565,
      y: 0.09014511108398438,
    },
    {
      x: 8078.804347826087,
      y: 0.09512710571289062,
    },
    {
      x: 8082.880434782609,
      y: 0.09512710571289062,
    },
    {
      x: 8343.75,
      y: 0.09512710571289062,
    },
    {
      x: 8347.826086956522,
      y: 0.09014511108398438,
    },
    {
      x: 8351.902173913044,
      y: 0.09014511108398438,
    },
    {
      x: 8360.054347826088,
      y: 0.09014511108398438,
    },
    {
      x: 8364.130434782608,
      y: 0.09512710571289062,
    },
    {
      x: 8368.20652173913,
      y: 0.09512710571289062,
    },
    {
      x: 8604.619565217392,
      y: 0.09512710571289062,
    },
    {
      x: 8608.695652173914,
      y: 0.050273895263671875,
    },
    {
      x: 8612.771739130436,
      y: 0.050273895263671875,
    },
    {
      x: 8616.847826086956,
      y: 0.05525970458984375,
    },
    {
      x: 8620.923913043478,
      y: 0.05525970458984375,
    },
    {
      x: 8645.380434782608,
      y: 0.05525970458984375,
    },
    {
      x: 8649.45652173913,
      y: 0.06024169921875,
    },
    {
      x: 8653.532608695652,
      y: 0.06024169921875,
    },
    {
      x: 8677.989130434782,
      y: 0.06024169921875,
    },
    {
      x: 8682.065217391304,
      y: 0.06522369384765625,
    },
    {
      x: 8686.141304347826,
      y: 0.06522369384765625,
    },
    {
      x: 8698.369565217392,
      y: 0.06522369384765625,
    },
    {
      x: 8702.445652173914,
      y: 0.06024169921875,
    },
    {
      x: 8706.521739130436,
      y: 0.040309906005859375,
    },
    {
      x: 8710.597826086956,
      y: 0.015392303466796875,
    },
    {
      x: 8714.673913043478,
      y: -0.004543304443359375,
    },
    {
      x: 8718.75,
      y: -0.01949310302734375,
    },
    {
      x: 8722.826086956522,
      y: -0.029460906982421875,
    },
    {
      x: 8726.902173913044,
      y: -0.04441070556640625,
    },
    {
      x: 8730.978260869566,
      y: -0.05936431884765625,
    },
    {
      x: 8735.054347826088,
      y: -0.074310302734375,
    },
    {
      x: 8739.130434782608,
      y: -0.08427810668945312,
    },
    {
      x: 8743.20652173913,
      y: -0.09923171997070312,
    },
    {
      x: 8747.282608695652,
      y: -0.10919570922851562,
    },
    {
      x: 8751.358695652174,
      y: -0.1241455078125,
    },
    {
      x: 8755.434782608696,
      y: -0.13411712646484375,
    },
    {
      x: 8759.510869565218,
      y: -0.14906692504882812,
    },
    {
      x: 8763.58695652174,
      y: -0.15903091430664062,
    },
    {
      x: 8767.663043478262,
      y: -0.17398452758789062,
    },
    {
      x: 8771.739130434782,
      y: -0.18394851684570312,
    },
    {
      x: 8775.815217391304,
      y: -0.19391632080078125,
    },
    {
      x: 8779.891304347826,
      y: -0.20886993408203125,
    },
    {
      x: 8783.967391304348,
      y: -0.2138519287109375,
    },
    {
      x: 8788.04347826087,
      y: -0.20886993408203125,
    },
    {
      x: 8792.119565217392,
      y: -0.19391632080078125,
    },
    {
      x: 8796.195652173914,
      y: -0.17896652221679688,
    },
    {
      x: 8800.271739130436,
      y: -0.15903091430664062,
    },
    {
      x: 8804.347826086956,
      y: -0.13909912109375,
    },
    {
      x: 8808.423913043478,
      y: -0.11916351318359375,
    },
    {
      x: 8812.5,
      y: -0.10421371459960938,
    },
    {
      x: 8816.576086956522,
      y: -0.08427810668945312,
    },
    {
      x: 8820.652173913044,
      y: -0.0643463134765625,
    },
    {
      x: 8824.728260869566,
      y: -0.049396514892578125,
    },
    {
      x: 8828.804347826088,
      y: -0.029460906982421875,
    },
    {
      x: 8832.880434782608,
      y: -0.0145111083984375,
    },
    {
      x: 8836.95652173913,
      y: 0.00542449951171875,
    },
    {
      x: 8841.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 8845.108695652174,
      y: 0.040309906005859375,
    },
    {
      x: 8849.184782608696,
      y: 0.05525970458984375,
    },
    {
      x: 8857.33695652174,
      y: 0.0851593017578125,
    },
    {
      x: 8861.413043478262,
      y: 0.09014511108398438,
    },
    {
      x: 8865.489130434782,
      y: 0.09014511108398438,
    },
    {
      x: 8869.565217391304,
      y: 0.09512710571289062,
    },
    {
      x: 8873.641304347826,
      y: 0.09512710571289062,
    },
    {
      x: 8914.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 8918.478260869566,
      y: 0.1349945068359375,
    },
    {
      x: 8922.554347826088,
      y: 0.2346649169921875,
    },
    {
      x: 8926.630434782608,
      y: 0.35427093505859375,
    },
    {
      x: 8930.70652173913,
      y: 0.4838409423828125,
    },
    {
      x: 8934.782608695652,
      y: 0.6134147644042969,
    },
    {
      x: 8938.858695652174,
      y: 0.7479705810546875,
    },
    {
      x: 8942.934782608696,
      y: 0.8775367736816406,
    },
    {
      x: 8947.010869565218,
      y: 1.0021286010742188,
    },
    {
      x: 8951.08695652174,
      y: 1.1267127990722656,
    },
    {
      x: 8955.163043478262,
      y: 1.2513046264648438,
    },
    {
      x: 8959.239130434782,
      y: 1.3111076354980469,
    },
    {
      x: 8963.315217391304,
      y: 1.2463188171386719,
    },
    {
      x: 8967.391304347826,
      y: 1.1317024230957031,
    },
    {
      x: 8971.467391304348,
      y: 1.0021286010742188,
    },
    {
      x: 8975.54347826087,
      y: 0.8625869750976562,
    },
    {
      x: 8979.619565217392,
      y: 0.7280311584472656,
    },
    {
      x: 8983.695652173914,
      y: 0.5884971618652344,
    },
    {
      x: 8987.771739130436,
      y: 0.45394134521484375,
    },
    {
      x: 8991.847826086956,
      y: 0.3243675231933594,
    },
    {
      x: 8995.923913043478,
      y: 0.18981552124023438,
    },
    {
      x: 9000,
      y: 0.08017730712890625,
    },
    {
      x: 9004.076086956522,
      y: 0.040309906005859375,
    },
    {
      x: 9008.152173913044,
      y: 0.03034210205078125,
    },
    {
      x: 9012.228260869566,
      y: 0.025356292724609375,
    },
    {
      x: 9016.304347826088,
      y: 0.025356292724609375,
    },
    {
      x: 9028.532608695652,
      y: 0.025356292724609375,
    },
    {
      x: 9032.608695652174,
      y: 0.03034210205078125,
    },
    {
      x: 9036.684782608696,
      y: 0.03034210205078125,
    },
    {
      x: 9044.83695652174,
      y: 0.03034210205078125,
    },
    {
      x: 9048.913043478262,
      y: 0.0353240966796875,
    },
    {
      x: 9052.989130434782,
      y: 0.0353240966796875,
    },
    {
      x: 9061.141304347826,
      y: 0.0353240966796875,
    },
    {
      x: 9065.217391304348,
      y: 0.040309906005859375,
    },
    {
      x: 9069.29347826087,
      y: 0.040309906005859375,
    },
    {
      x: 9077.445652173914,
      y: 0.040309906005859375,
    },
    {
      x: 9081.521739130436,
      y: 0.045291900634765625,
    },
    {
      x: 9085.597826086956,
      y: 0.045291900634765625,
    },
    {
      x: 9101.902173913044,
      y: 0.045291900634765625,
    },
    {
      x: 9105.978260869566,
      y: 0.050273895263671875,
    },
    {
      x: 9110.054347826088,
      y: 0.050273895263671875,
    },
    {
      x: 9126.358695652174,
      y: 0.050273895263671875,
    },
    {
      x: 9130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 9134.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 9387.228260869566,
      y: 0.09512710571289062,
    },
    {
      x: 9391.304347826088,
      y: 0.040309906005859375,
    },
    {
      x: 9395.380434782608,
      y: 0.05525970458984375,
    },
    {
      x: 9399.45652173913,
      y: 0.0751953125,
    },
    {
      x: 9403.532608695652,
      y: 0.0851593017578125,
    },
    {
      x: 9407.608695652174,
      y: 0.09014511108398438,
    },
    {
      x: 9411.684782608696,
      y: 0.09014511108398438,
    },
    {
      x: 9415.760869565218,
      y: 0.09014511108398438,
    },
    {
      x: 9419.83695652174,
      y: 0.09512710571289062,
    },
    {
      x: 9423.913043478262,
      y: 0.09512710571289062,
    },
    {
      x: 9721.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 9725.54347826087,
      y: 0.1349945068359375,
    },
    {
      x: 9729.619565217392,
      y: 0.2346649169921875,
    },
    {
      x: 9733.695652173914,
      y: 0.35427093505859375,
    },
    {
      x: 9737.771739130436,
      y: 0.4838409423828125,
    },
    {
      x: 9741.847826086956,
      y: 0.6134147644042969,
    },
    {
      x: 9745.923913043478,
      y: 0.7479705810546875,
    },
    {
      x: 9750,
      y: 0.8775367736816406,
    },
    {
      x: 9754.076086956522,
      y: 1.0021286010742188,
    },
    {
      x: 9758.152173913044,
      y: 1.1267127990722656,
    },
    {
      x: 9762.228260869566,
      y: 1.2513046264648438,
    },
    {
      x: 9766.304347826088,
      y: 1.3111076354980469,
    },
    {
      x: 9770.38043478261,
      y: 1.2463188171386719,
    },
    {
      x: 9774.45652173913,
      y: 1.1317024230957031,
    },
    {
      x: 9778.532608695652,
      y: 1.0021286010742188,
    },
    {
      x: 9782.608695652174,
      y: 0.8625869750976562,
    },
    {
      x: 9786.684782608696,
      y: 0.7230491638183594,
    },
    {
      x: 9790.760869565218,
      y: 0.5835151672363281,
    },
    {
      x: 9794.83695652174,
      y: 0.4489555358886719,
    },
    {
      x: 9798.913043478262,
      y: 0.3193855285644531,
    },
    {
      x: 9802.989130434782,
      y: 0.18483352661132812,
    },
    {
      x: 9807.065217391304,
      y: 0.0751953125,
    },
    {
      x: 9811.141304347826,
      y: 0.0353240966796875,
    },
    {
      x: 9815.217391304348,
      y: 0.025356292724609375,
    },
    {
      x: 9819.29347826087,
      y: 0.020374298095703125,
    },
    {
      x: 9823.369565217392,
      y: 0.020374298095703125,
    },
    {
      x: 9835.597826086956,
      y: 0.020374298095703125,
    },
    {
      x: 9839.673913043478,
      y: 0.025356292724609375,
    },
    {
      x: 9843.75,
      y: 0.025356292724609375,
    },
    {
      x: 9851.902173913044,
      y: 0.025356292724609375,
    },
    {
      x: 9855.978260869566,
      y: 0.03034210205078125,
    },
    {
      x: 9860.054347826088,
      y: 0.03034210205078125,
    },
    {
      x: 9868.20652173913,
      y: 0.03034210205078125,
    },
    {
      x: 9872.282608695652,
      y: 0.0353240966796875,
    },
    {
      x: 9876.358695652174,
      y: 0.0353240966796875,
    },
    {
      x: 9884.510869565218,
      y: 0.0353240966796875,
    },
    {
      x: 9888.58695652174,
      y: 0.040309906005859375,
    },
    {
      x: 9892.663043478262,
      y: 0.040309906005859375,
    },
    {
      x: 9900.815217391304,
      y: 0.040309906005859375,
    },
    {
      x: 9904.891304347826,
      y: 0.045291900634765625,
    },
    {
      x: 9908.967391304348,
      y: 0.045291900634765625,
    },
    {
      x: 9921.195652173914,
      y: 0.045291900634765625,
    },
    {
      x: 9925.271739130436,
      y: 0.050273895263671875,
    },
    {
      x: 9929.347826086956,
      y: 0.050273895263671875,
    },
    {
      x: 9953.804347826088,
      y: 0.050273895263671875,
    },
    {
      x: 9957.88043478261,
      y: 0.05525970458984375,
    },
    {
      x: 9961.95652173913,
      y: 0.05525970458984375,
    },
    {
      x: 9986.413043478262,
      y: 0.05525970458984375,
    },
    {
      x: 9990.489130434782,
      y: 0.06024169921875,
    },
    {
      x: 9994.565217391304,
      y: 0.06024169921875,
    },
    {
      x: 10019.021739130436,
      y: 0.06024169921875,
    },
    {
      x: 10023.097826086956,
      y: 0.06522369384765625,
    },
    {
      x: 10027.173913043478,
      y: 0.06522369384765625,
    },
    {
      x: 10031.25,
      y: 0.06024169921875,
    },
    {
      x: 10035.326086956522,
      y: 0.040309906005859375,
    },
    {
      x: 10039.402173913044,
      y: 0.015392303466796875,
    },
    {
      x: 10043.478260869566,
      y: 0.0004425048828125,
    },
    {
      x: 10047.554347826088,
      y: -0.0145111083984375,
    },
    {
      x: 10051.63043478261,
      y: -0.029460906982421875,
    },
    {
      x: 10055.70652173913,
      y: -0.04441070556640625,
    },
    {
      x: 10059.782608695652,
      y: -0.054378509521484375,
    },
    {
      x: 10063.858695652174,
      y: -0.06932830810546875,
    },
    {
      x: 10067.934782608696,
      y: -0.08427810668945312,
    },
    {
      x: 10072.010869565218,
      y: -0.09424591064453125,
    },
    {
      x: 10076.08695652174,
      y: -0.10919570922851562,
    },
    {
      x: 10080.163043478262,
      y: -0.11916351318359375,
    },
    {
      x: 10084.239130434782,
      y: -0.13411712646484375,
    },
    {
      x: 10088.315217391304,
      y: -0.14408111572265625,
    },
    {
      x: 10092.391304347826,
      y: -0.15903091430664062,
    },
    {
      x: 10096.467391304348,
      y: -0.16899871826171875,
    },
    {
      x: 10100.54347826087,
      y: -0.18394851684570312,
    },
    {
      x: 10104.619565217392,
      y: -0.19391632080078125,
    },
    {
      x: 10112.771739130436,
      y: -0.2138519287109375,
    },
    {
      x: 10116.847826086956,
      y: -0.20886993408203125,
    },
    {
      x: 10120.923913043478,
      y: -0.19391632080078125,
    },
    {
      x: 10125,
      y: -0.17896652221679688,
    },
    {
      x: 10129.076086956522,
      y: -0.15903091430664062,
    },
    {
      x: 10133.152173913044,
      y: -0.13909912109375,
    },
    {
      x: 10137.228260869566,
      y: -0.11916351318359375,
    },
    {
      x: 10141.304347826088,
      y: -0.09923171997070312,
    },
    {
      x: 10145.38043478261,
      y: -0.08427810668945312,
    },
    {
      x: 10149.45652173913,
      y: -0.0643463134765625,
    },
    {
      x: 10153.532608695652,
      y: -0.049396514892578125,
    },
    {
      x: 10157.608695652174,
      y: -0.029460906982421875,
    },
    {
      x: 10161.684782608696,
      y: -0.00952911376953125,
    },
    {
      x: 10165.760869565218,
      y: 0.00542449951171875,
    },
    {
      x: 10169.83695652174,
      y: 0.020374298095703125,
    },
    {
      x: 10173.913043478262,
      y: 0.09512710571289062,
    },
    {
      x: 10177.989130434782,
      y: 0.09512710571289062,
    },
    {
      x: 10430.70652173913,
      y: 0.09512710571289062,
    },
    {
      x: 10434.782608695652,
      y: -0.0643463134765625,
    },
    {
      x: 10438.858695652174,
      y: -0.049396514892578125,
    },
    {
      x: 10442.934782608696,
      y: -0.029460906982421875,
    },
    {
      x: 10447.010869565218,
      y: -0.00952911376953125,
    },
    {
      x: 10451.08695652174,
      y: 0.00542449951171875,
    },
    {
      x: 10455.163043478262,
      y: 0.020374298095703125,
    },
    {
      x: 10459.239130434782,
      y: 0.040309906005859375,
    },
    {
      x: 10463.315217391304,
      y: 0.05525970458984375,
    },
    {
      x: 10467.391304347826,
      y: 0.0751953125,
    },
    {
      x: 10471.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 10475.54347826087,
      y: 0.09014511108398438,
    },
    {
      x: 10479.619565217392,
      y: 0.09014511108398438,
    },
    {
      x: 10483.695652173914,
      y: 0.09014511108398438,
    },
    {
      x: 10487.771739130436,
      y: 0.09512710571289062,
    },
    {
      x: 10491.847826086956,
      y: 0.09512710571289062,
    },
    {
      x: 10691.576086956522,
      y: 0.09512710571289062,
    },
    {
      x: 10695.652173913044,
      y: 0.040309906005859375,
    },
    {
      x: 10699.728260869566,
      y: 0.040309906005859375,
    },
    {
      x: 10707.88043478261,
      y: 0.040309906005859375,
    },
    {
      x: 10711.95652173913,
      y: 0.045291900634765625,
    },
    {
      x: 10716.032608695652,
      y: 0.045291900634765625,
    },
    {
      x: 10728.260869565218,
      y: 0.045291900634765625,
    },
    {
      x: 10732.33695652174,
      y: 0.050273895263671875,
    },
    {
      x: 10736.413043478262,
      y: 0.050273895263671875,
    },
    {
      x: 10760.869565217392,
      y: 0.050273895263671875,
    },
    {
      x: 10764.945652173914,
      y: 0.05525970458984375,
    },
    {
      x: 10769.021739130436,
      y: 0.05525970458984375,
    },
    {
      x: 10793.478260869566,
      y: 0.05525970458984375,
    },
    {
      x: 10797.554347826088,
      y: 0.06024169921875,
    },
    {
      x: 10801.63043478261,
      y: 0.06024169921875,
    },
    {
      x: 10826.08695652174,
      y: 0.06024169921875,
    },
    {
      x: 10830.163043478262,
      y: 0.06522369384765625,
    },
    {
      x: 10834.239130434782,
      y: 0.06522369384765625,
    },
    {
      x: 10838.315217391304,
      y: 0.06024169921875,
    },
    {
      x: 10842.391304347826,
      y: 0.040309906005859375,
    },
    {
      x: 10846.467391304348,
      y: 0.015392303466796875,
    },
    {
      x: 10850.54347826087,
      y: 0.0004425048828125,
    },
    {
      x: 10854.619565217392,
      y: -0.0145111083984375,
    },
    {
      x: 10858.695652173914,
      y: -0.029460906982421875,
    },
    {
      x: 10862.771739130436,
      y: -0.04441070556640625,
    },
    {
      x: 10866.847826086956,
      y: -0.054378509521484375,
    },
    {
      x: 10870.923913043478,
      y: -0.06932830810546875,
    },
    {
      x: 10875,
      y: -0.08427810668945312,
    },
    {
      x: 10879.076086956522,
      y: -0.09424591064453125,
    },
    {
      x: 10883.152173913044,
      y: -0.10919570922851562,
    },
    {
      x: 10887.228260869566,
      y: -0.11916351318359375,
    },
    {
      x: 10891.304347826088,
      y: -0.13411712646484375,
    },
    {
      x: 10895.38043478261,
      y: -0.14408111572265625,
    },
    {
      x: 10899.45652173913,
      y: -0.15903091430664062,
    },
    {
      x: 10903.532608695652,
      y: -0.16899871826171875,
    },
    {
      x: 10907.608695652174,
      y: -0.18394851684570312,
    },
    {
      x: 10911.684782608696,
      y: -0.19391632080078125,
    },
    {
      x: 10919.83695652174,
      y: -0.2138519287109375,
    },
    {
      x: 10923.913043478262,
      y: -0.20886993408203125,
    },
    {
      x: 10927.989130434782,
      y: -0.19391632080078125,
    },
    {
      x: 10932.065217391304,
      y: -0.17896652221679688,
    },
    {
      x: 10936.141304347826,
      y: -0.15903091430664062,
    },
    {
      x: 10940.217391304348,
      y: -0.13909912109375,
    },
    {
      x: 10944.29347826087,
      y: -0.11916351318359375,
    },
    {
      x: 10948.369565217392,
      y: -0.09923171997070312,
    },
    {
      x: 10952.445652173914,
      y: -0.08427810668945312,
    },
    {
      x: 10956.521739130436,
      y: 0.09512710571289062,
    },
    {
      x: 10960.597826086956,
      y: 0.09512710571289062,
    },
    {
      x: 11050.271739130436,
      y: 0.09512710571289062,
    },
    {
      x: 11054.347826086956,
      y: 0.1349945068359375,
    },
    {
      x: 11058.423913043478,
      y: 0.2346649169921875,
    },
    {
      x: 11062.5,
      y: 0.35427093505859375,
    },
    {
      x: 11066.576086956522,
      y: 0.4838409423828125,
    },
    {
      x: 11070.652173913044,
      y: 0.6134147644042969,
    },
    {
      x: 11074.728260869566,
      y: 0.7479705810546875,
    },
    {
      x: 11078.804347826088,
      y: 0.8775367736816406,
    },
    {
      x: 11082.88043478261,
      y: 1.0021286010742188,
    },
    {
      x: 11086.95652173913,
      y: 1.1267127990722656,
    },
    {
      x: 11091.032608695652,
      y: 1.2513046264648438,
    },
    {
      x: 11095.108695652174,
      y: 1.3111076354980469,
    },
    {
      x: 11099.184782608696,
      y: 1.2463188171386719,
    },
    {
      x: 11103.260869565218,
      y: 1.1317024230957031,
    },
    {
      x: 11107.33695652174,
      y: 1.0021286010742188,
    },
    {
      x: 11111.413043478262,
      y: 0.8625869750976562,
    },
    {
      x: 11115.489130434782,
      y: 0.7230491638183594,
    },
    {
      x: 11119.565217391304,
      y: 0.5835151672363281,
    },
    {
      x: 11123.641304347826,
      y: 0.4489555358886719,
    },
    {
      x: 11127.717391304348,
      y: 0.3193855285644531,
    },
    {
      x: 11131.79347826087,
      y: 0.18483352661132812,
    },
    {
      x: 11135.869565217392,
      y: 0.0751953125,
    },
    {
      x: 11139.945652173914,
      y: 0.0353240966796875,
    },
    {
      x: 11144.021739130436,
      y: 0.025356292724609375,
    },
    {
      x: 11148.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 11152.173913043478,
      y: 0.020374298095703125,
    },
    {
      x: 11164.402173913044,
      y: 0.020374298095703125,
    },
    {
      x: 11168.478260869566,
      y: 0.025356292724609375,
    },
    {
      x: 11172.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 11180.70652173913,
      y: 0.025356292724609375,
    },
    {
      x: 11184.782608695652,
      y: 0.03034210205078125,
    },
    {
      x: 11188.858695652174,
      y: 0.03034210205078125,
    },
    {
      x: 11197.010869565218,
      y: 0.03034210205078125,
    },
    {
      x: 11201.08695652174,
      y: 0.0353240966796875,
    },
    {
      x: 11205.163043478262,
      y: 0.0353240966796875,
    },
    {
      x: 11213.315217391304,
      y: 0.0353240966796875,
    },
    {
      x: 11217.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 11221.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 11474.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 11478.260869565218,
      y: -0.17896652221679688,
    },
    {
      x: 11482.33695652174,
      y: -0.15903091430664062,
    },
    {
      x: 11486.413043478262,
      y: -0.13909912109375,
    },
    {
      x: 11490.489130434782,
      y: -0.11916351318359375,
    },
    {
      x: 11494.565217391304,
      y: -0.09923171997070312,
    },
    {
      x: 11498.641304347826,
      y: -0.08427810668945312,
    },
    {
      x: 11502.717391304348,
      y: -0.0643463134765625,
    },
    {
      x: 11506.79347826087,
      y: -0.049396514892578125,
    },
    {
      x: 11510.869565217392,
      y: -0.029460906982421875,
    },
    {
      x: 11514.945652173914,
      y: -0.00952911376953125,
    },
    {
      x: 11519.021739130436,
      y: 0.00542449951171875,
    },
    {
      x: 11523.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 11527.173913043478,
      y: 0.040309906005859375,
    },
    {
      x: 11531.25,
      y: 0.05525970458984375,
    },
    {
      x: 11535.326086956522,
      y: 0.0751953125,
    },
    {
      x: 11539.402173913044,
      y: 0.0851593017578125,
    },
    {
      x: 11543.478260869566,
      y: 0.09014511108398438,
    },
    {
      x: 11547.554347826088,
      y: 0.09014511108398438,
    },
    {
      x: 11551.63043478261,
      y: 0.09014511108398438,
    },
    {
      x: 11555.70652173913,
      y: 0.09512710571289062,
    },
    {
      x: 11559.782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 11857.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 11861.413043478262,
      y: 0.1349945068359375,
    },
    {
      x: 11865.489130434782,
      y: 0.2346649169921875,
    },
    {
      x: 11869.565217391304,
      y: 0.35427093505859375,
    },
    {
      x: 11873.641304347826,
      y: 0.4838409423828125,
    },
    {
      x: 11877.717391304348,
      y: 0.6134147644042969,
    },
    {
      x: 11881.79347826087,
      y: 0.7479705810546875,
    },
    {
      x: 11885.869565217392,
      y: 0.8775367736816406,
    },
    {
      x: 11889.945652173914,
      y: 1.0021286010742188,
    },
    {
      x: 11894.021739130436,
      y: 1.1267127990722656,
    },
    {
      x: 11898.097826086956,
      y: 1.2513046264648438,
    },
    {
      x: 11902.173913043478,
      y: 1.3111076354980469,
    },
    {
      x: 11906.25,
      y: 1.2463188171386719,
    },
    {
      x: 11910.326086956522,
      y: 1.1317024230957031,
    },
    {
      x: 11914.402173913044,
      y: 1.0021286010742188,
    },
    {
      x: 11918.478260869566,
      y: 0.8625869750976562,
    },
    {
      x: 11922.554347826088,
      y: 0.7230491638183594,
    },
    {
      x: 11926.63043478261,
      y: 0.5835151672363281,
    },
    {
      x: 11930.70652173913,
      y: 0.4489555358886719,
    },
    {
      x: 11934.782608695652,
      y: 0.3193855285644531,
    },
    {
      x: 11938.858695652174,
      y: 0.18483352661132812,
    },
    {
      x: 11942.934782608696,
      y: 0.0751953125,
    },
    {
      x: 11947.010869565218,
      y: 0.0353240966796875,
    },
    {
      x: 11951.08695652174,
      y: 0.025356292724609375,
    },
    {
      x: 11955.163043478262,
      y: 0.020374298095703125,
    },
    {
      x: 11959.239130434782,
      y: 0.020374298095703125,
    },
    {
      x: 11971.467391304348,
      y: 0.020374298095703125,
    },
    {
      x: 11975.54347826087,
      y: 0.025356292724609375,
    },
    {
      x: 11979.619565217392,
      y: 0.025356292724609375,
    },
    {
      x: 11987.771739130436,
      y: 0.025356292724609375,
    },
    {
      x: 11991.847826086956,
      y: 0.03034210205078125,
    },
    {
      x: 11995.923913043478,
      y: 0.03034210205078125,
    },
    {
      x: 12004.076086956522,
      y: 0.03034210205078125,
    },
    {
      x: 12008.152173913044,
      y: 0.0353240966796875,
    },
    {
      x: 12012.228260869566,
      y: 0.0353240966796875,
    },
    {
      x: 12020.38043478261,
      y: 0.0353240966796875,
    },
    {
      x: 12024.45652173913,
      y: 0.040309906005859375,
    },
    {
      x: 12028.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 12036.684782608696,
      y: 0.040309906005859375,
    },
    {
      x: 12040.760869565218,
      y: 0.045291900634765625,
    },
    {
      x: 12044.83695652174,
      y: 0.045291900634765625,
    },
    {
      x: 12057.065217391304,
      y: 0.045291900634765625,
    },
    {
      x: 12061.141304347826,
      y: 0.050273895263671875,
    },
    {
      x: 12065.217391304348,
      y: 0.050273895263671875,
    },
    {
      x: 12089.673913043478,
      y: 0.050273895263671875,
    },
    {
      x: 12093.75,
      y: 0.05525970458984375,
    },
    {
      x: 12097.826086956522,
      y: 0.05525970458984375,
    },
    {
      x: 12122.282608695652,
      y: 0.05525970458984375,
    },
    {
      x: 12126.358695652174,
      y: 0.06024169921875,
    },
    {
      x: 12130.434782608696,
      y: 0.06024169921875,
    },
    {
      x: 12154.891304347826,
      y: 0.06024169921875,
    },
    {
      x: 12158.967391304348,
      y: 0.06522369384765625,
    },
    {
      x: 12163.04347826087,
      y: 0.06522369384765625,
    },
    {
      x: 12167.119565217392,
      y: 0.06024169921875,
    },
    {
      x: 12171.195652173914,
      y: 0.040309906005859375,
    },
    {
      x: 12175.271739130436,
      y: 0.015392303466796875,
    },
    {
      x: 12179.347826086956,
      y: 0.0004425048828125,
    },
    {
      x: 12183.423913043478,
      y: -0.0145111083984375,
    },
    {
      x: 12187.5,
      y: -0.029460906982421875,
    },
    {
      x: 12191.576086956522,
      y: -0.04441070556640625,
    },
    {
      x: 12195.652173913044,
      y: -0.054378509521484375,
    },
    {
      x: 12199.728260869566,
      y: -0.06932830810546875,
    },
    {
      x: 12203.804347826088,
      y: -0.08427810668945312,
    },
    {
      x: 12207.88043478261,
      y: -0.09424591064453125,
    },
    {
      x: 12211.95652173913,
      y: -0.10919570922851562,
    },
    {
      x: 12216.032608695652,
      y: -0.11916351318359375,
    },
    {
      x: 12220.108695652174,
      y: -0.13411712646484375,
    },
    {
      x: 12224.184782608696,
      y: -0.14408111572265625,
    },
    {
      x: 12228.260869565218,
      y: -0.15903091430664062,
    },
    {
      x: 12232.33695652174,
      y: -0.16899871826171875,
    },
    {
      x: 12236.413043478262,
      y: -0.18394851684570312,
    },
    {
      x: 12240.489130434782,
      y: -0.19391632080078125,
    },
    {
      x: 12248.641304347826,
      y: -0.2138519287109375,
    },
    {
      x: 12252.717391304348,
      y: -0.20886993408203125,
    },
    {
      x: 12256.79347826087,
      y: -0.19391632080078125,
    },
    {
      x: 12260.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 12264.945652173914,
      y: 0.09512710571289062,
    },
    {
      x: 12517.663043478262,
      y: 0.09512710571289062,
    },
    {
      x: 12521.739130434784,
      y: -0.18394851684570312,
    },
    {
      x: 12525.815217391304,
      y: -0.19391632080078125,
    },
    {
      x: 12533.967391304348,
      y: -0.2138519287109375,
    },
    {
      x: 12538.04347826087,
      y: -0.20886993408203125,
    },
    {
      x: 12542.119565217392,
      y: -0.19391632080078125,
    },
    {
      x: 12546.195652173914,
      y: -0.17896652221679688,
    },
    {
      x: 12550.271739130436,
      y: -0.15903091430664062,
    },
    {
      x: 12554.347826086956,
      y: -0.13909912109375,
    },
    {
      x: 12558.423913043478,
      y: -0.11916351318359375,
    },
    {
      x: 12562.5,
      y: -0.09923171997070312,
    },
    {
      x: 12566.576086956522,
      y: -0.08427810668945312,
    },
    {
      x: 12570.652173913044,
      y: -0.0643463134765625,
    },
    {
      x: 12574.728260869566,
      y: -0.049396514892578125,
    },
    {
      x: 12578.804347826088,
      y: -0.029460906982421875,
    },
    {
      x: 12582.88043478261,
      y: -0.00952911376953125,
    },
    {
      x: 12586.95652173913,
      y: 0.00542449951171875,
    },
    {
      x: 12591.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 12595.108695652174,
      y: 0.040309906005859375,
    },
    {
      x: 12599.184782608696,
      y: 0.05525970458984375,
    },
    {
      x: 12603.260869565218,
      y: 0.0751953125,
    },
    {
      x: 12607.33695652174,
      y: 0.0851593017578125,
    },
    {
      x: 12611.413043478262,
      y: 0.09014511108398438,
    },
    {
      x: 12615.489130434784,
      y: 0.09014511108398438,
    },
    {
      x: 12619.565217391304,
      y: 0.09014511108398438,
    },
    {
      x: 12623.641304347826,
      y: 0.09512710571289062,
    },
    {
      x: 12627.717391304348,
      y: 0.09512710571289062,
    },
    {
      x: 12925.271739130436,
      y: 0.09512710571289062,
    },
    {
      x: 12929.347826086956,
      y: 0.1349945068359375,
    },
    {
      x: 12933.423913043478,
      y: 0.2346649169921875,
    },
    {
      x: 12937.5,
      y: 0.35427093505859375,
    },
    {
      x: 12941.576086956522,
      y: 0.4838409423828125,
    },
    {
      x: 12945.652173913044,
      y: 0.6134147644042969,
    },
    {
      x: 12949.728260869566,
      y: 0.7479705810546875,
    },
    {
      x: 12953.804347826088,
      y: 0.8775367736816406,
    },
    {
      x: 12957.88043478261,
      y: 1.0021286010742188,
    },
    {
      x: 12961.95652173913,
      y: 1.1267127990722656,
    },
    {
      x: 12966.032608695652,
      y: 1.2513046264648438,
    },
    {
      x: 12970.108695652174,
      y: 1.3111076354980469,
    },
    {
      x: 12974.184782608696,
      y: 1.2463188171386719,
    },
    {
      x: 12978.260869565218,
      y: 1.1317024230957031,
    },
    {
      x: 12982.33695652174,
      y: 1.0021286010742188,
    },
    {
      x: 12986.413043478262,
      y: 0.8625869750976562,
    },
    {
      x: 12990.489130434784,
      y: 0.7230491638183594,
    },
    {
      x: 12994.565217391304,
      y: 0.5835151672363281,
    },
    {
      x: 12998.641304347826,
      y: 0.4489555358886719,
    },
    {
      x: 13002.717391304348,
      y: 0.3193855285644531,
    },
    {
      x: 13006.79347826087,
      y: 0.18483352661132812,
    },
    {
      x: 13010.869565217392,
      y: 0.0751953125,
    },
    {
      x: 13014.945652173914,
      y: 0.0353240966796875,
    },
    {
      x: 13019.021739130436,
      y: 0.025356292724609375,
    },
    {
      x: 13023.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 13027.173913043478,
      y: 0.020374298095703125,
    },
    {
      x: 13039.402173913044,
      y: 0.020374298095703125,
    },
    {
      x: 13043.478260869566,
      y: 0.025356292724609375,
    },
    {
      x: 13047.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 13055.70652173913,
      y: 0.025356292724609375,
    },
    {
      x: 13059.782608695652,
      y: 0.03034210205078125,
    },
    {
      x: 13063.858695652174,
      y: 0.03034210205078125,
    },
    {
      x: 13072.010869565218,
      y: 0.03034210205078125,
    },
    {
      x: 13076.08695652174,
      y: 0.0353240966796875,
    },
    {
      x: 13080.163043478262,
      y: 0.0353240966796875,
    },
    {
      x: 13088.315217391304,
      y: 0.0353240966796875,
    },
    {
      x: 13092.391304347826,
      y: 0.040309906005859375,
    },
    {
      x: 13096.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 13104.619565217392,
      y: 0.040309906005859375,
    },
    {
      x: 13108.695652173914,
      y: 0.045291900634765625,
    },
    {
      x: 13112.771739130436,
      y: 0.045291900634765625,
    },
    {
      x: 13125,
      y: 0.045291900634765625,
    },
    {
      x: 13129.076086956522,
      y: 0.050273895263671875,
    },
    {
      x: 13133.152173913044,
      y: 0.050273895263671875,
    },
    {
      x: 13157.608695652174,
      y: 0.050273895263671875,
    },
    {
      x: 13161.684782608696,
      y: 0.05525970458984375,
    },
    {
      x: 13165.760869565218,
      y: 0.05525970458984375,
    },
    {
      x: 13190.217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 13194.29347826087,
      y: 0.06024169921875,
    },
    {
      x: 13198.369565217392,
      y: 0.06024169921875,
    },
    {
      x: 13222.826086956522,
      y: 0.06024169921875,
    },
    {
      x: 13226.902173913044,
      y: 0.06522369384765625,
    },
    {
      x: 13230.978260869566,
      y: 0.06522369384765625,
    },
    {
      x: 13235.054347826088,
      y: 0.06024169921875,
    },
    {
      x: 13239.13043478261,
      y: 0.040309906005859375,
    },
    {
      x: 13243.20652173913,
      y: 0.015392303466796875,
    },
    {
      x: 13247.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 13251.358695652174,
      y: -0.0145111083984375,
    },
    {
      x: 13255.434782608696,
      y: -0.029460906982421875,
    },
    {
      x: 13259.510869565218,
      y: -0.04441070556640625,
    },
    {
      x: 13263.58695652174,
      y: -0.054378509521484375,
    },
    {
      x: 13267.663043478262,
      y: -0.06932830810546875,
    },
    {
      x: 13271.739130434784,
      y: -0.08427810668945312,
    },
    {
      x: 13275.815217391304,
      y: -0.09424591064453125,
    },
    {
      x: 13279.891304347826,
      y: -0.10919570922851562,
    },
    {
      x: 13283.967391304348,
      y: -0.11916351318359375,
    },
    {
      x: 13288.04347826087,
      y: -0.13411712646484375,
    },
    {
      x: 13292.119565217392,
      y: -0.14408111572265625,
    },
    {
      x: 13296.195652173914,
      y: -0.15903091430664062,
    },
    {
      x: 13300.271739130436,
      y: -0.16899871826171875,
    },
    {
      x: 13304.347826086956,
      y: 0.09512710571289062,
    },
    {
      x: 13308.423913043478,
      y: 0.09512710571289062,
    },
    {
      x: 13822.010869565218,
      y: 0.09512710571289062,
    },
    {
      x: 13826.08695652174,
      y: -0.10919570922851562,
    },
    {
      x: 13830.163043478262,
      y: -0.11916351318359375,
    },
    {
      x: 13834.239130434784,
      y: -0.13411712646484375,
    },
    {
      x: 13838.315217391304,
      y: -0.14408111572265625,
    },
    {
      x: 13842.391304347826,
      y: -0.15903091430664062,
    },
    {
      x: 13846.467391304348,
      y: -0.16899871826171875,
    },
    {
      x: 13850.54347826087,
      y: -0.18394851684570312,
    },
    {
      x: 13854.619565217392,
      y: -0.19391632080078125,
    },
    {
      x: 13862.771739130436,
      y: -0.2138519287109375,
    },
    {
      x: 13866.847826086956,
      y: -0.20886993408203125,
    },
    {
      x: 13870.923913043478,
      y: -0.19391632080078125,
    },
    {
      x: 13875,
      y: -0.17896652221679688,
    },
    {
      x: 13879.076086956522,
      y: -0.15903091430664062,
    },
    {
      x: 13883.152173913044,
      y: -0.13909912109375,
    },
    {
      x: 13887.228260869566,
      y: -0.11916351318359375,
    },
    {
      x: 13891.304347826088,
      y: -0.09923171997070312,
    },
    {
      x: 13895.38043478261,
      y: -0.08427810668945312,
    },
    {
      x: 13899.45652173913,
      y: -0.0643463134765625,
    },
    {
      x: 13903.532608695652,
      y: -0.049396514892578125,
    },
    {
      x: 13907.608695652174,
      y: -0.029460906982421875,
    },
    {
      x: 13911.684782608696,
      y: -0.00952911376953125,
    },
    {
      x: 13915.760869565218,
      y: 0.00542449951171875,
    },
    {
      x: 13919.83695652174,
      y: 0.020374298095703125,
    },
    {
      x: 13923.913043478262,
      y: 0.040309906005859375,
    },
    {
      x: 13927.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 13932.065217391304,
      y: 0.0751953125,
    },
    {
      x: 13936.141304347826,
      y: 0.0851593017578125,
    },
    {
      x: 13940.217391304348,
      y: 0.09014511108398438,
    },
    {
      x: 13944.29347826087,
      y: 0.09014511108398438,
    },
    {
      x: 13948.369565217392,
      y: 0.09014511108398438,
    },
    {
      x: 13952.445652173914,
      y: 0.09512710571289062,
    },
    {
      x: 13956.521739130436,
      y: 0.09512710571289062,
    },
    {
      x: 14082.88043478261,
      y: 0.09512710571289062,
    },
    {
      x: 14086.95652173913,
      y: 0.025356292724609375,
    },
    {
      x: 14091.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 14095.108695652174,
      y: 0.020374298095703125,
    },
    {
      x: 14107.33695652174,
      y: 0.020374298095703125,
    },
    {
      x: 14111.413043478262,
      y: 0.025356292724609375,
    },
    {
      x: 14115.489130434784,
      y: 0.025356292724609375,
    },
    {
      x: 14123.641304347826,
      y: 0.025356292724609375,
    },
    {
      x: 14127.717391304348,
      y: 0.03034210205078125,
    },
    {
      x: 14131.79347826087,
      y: 0.03034210205078125,
    },
    {
      x: 14139.945652173914,
      y: 0.03034210205078125,
    },
    {
      x: 14144.021739130436,
      y: 0.0353240966796875,
    },
    {
      x: 14148.097826086956,
      y: 0.0353240966796875,
    },
    {
      x: 14156.25,
      y: 0.0353240966796875,
    },
    {
      x: 14160.326086956522,
      y: 0.040309906005859375,
    },
    {
      x: 14164.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 14172.554347826088,
      y: 0.040309906005859375,
    },
    {
      x: 14176.63043478261,
      y: 0.045291900634765625,
    },
    {
      x: 14180.70652173913,
      y: 0.045291900634765625,
    },
    {
      x: 14192.934782608696,
      y: 0.045291900634765625,
    },
    {
      x: 14197.010869565218,
      y: 0.050273895263671875,
    },
    {
      x: 14201.08695652174,
      y: 0.050273895263671875,
    },
    {
      x: 14225.54347826087,
      y: 0.050273895263671875,
    },
    {
      x: 14229.619565217392,
      y: 0.05525970458984375,
    },
    {
      x: 14233.695652173914,
      y: 0.05525970458984375,
    },
    {
      x: 14258.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 14262.228260869566,
      y: 0.06024169921875,
    },
    {
      x: 14266.304347826088,
      y: 0.06024169921875,
    },
    {
      x: 14290.760869565218,
      y: 0.06024169921875,
    },
    {
      x: 14294.83695652174,
      y: 0.06522369384765625,
    },
    {
      x: 14298.913043478262,
      y: 0.06522369384765625,
    },
    {
      x: 14302.989130434784,
      y: 0.06024169921875,
    },
    {
      x: 14307.065217391304,
      y: 0.040309906005859375,
    },
    {
      x: 14311.141304347826,
      y: 0.015392303466796875,
    },
    {
      x: 14315.217391304348,
      y: 0.0004425048828125,
    },
    {
      x: 14319.29347826087,
      y: -0.0145111083984375,
    },
    {
      x: 14323.369565217392,
      y: -0.029460906982421875,
    },
    {
      x: 14327.445652173914,
      y: -0.04441070556640625,
    },
    {
      x: 14331.521739130436,
      y: -0.054378509521484375,
    },
    {
      x: 14335.597826086956,
      y: -0.06932830810546875,
    },
    {
      x: 14339.673913043478,
      y: -0.08427810668945312,
    },
    {
      x: 14343.75,
      y: -0.09424591064453125,
    },
    {
      x: 14347.826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 14351.902173913044,
      y: 0.09512710571289062,
    },
    {
      x: 14514.945652173914,
      y: 0.09512710571289062,
    },
    {
      x: 14519.021739130436,
      y: 0.1349945068359375,
    },
    {
      x: 14523.097826086956,
      y: 0.2346649169921875,
    },
    {
      x: 14527.173913043478,
      y: 0.35427093505859375,
    },
    {
      x: 14531.25,
      y: 0.4838409423828125,
    },
    {
      x: 14535.326086956522,
      y: 0.6134147644042969,
    },
    {
      x: 14539.402173913044,
      y: 0.7479705810546875,
    },
    {
      x: 14543.478260869566,
      y: 0.8775367736816406,
    },
    {
      x: 14547.554347826088,
      y: 1.0021286010742188,
    },
    {
      x: 14551.63043478261,
      y: 1.1267127990722656,
    },
    {
      x: 14555.70652173913,
      y: 1.2513046264648438,
    },
    {
      x: 14559.782608695652,
      y: 1.3111076354980469,
    },
    {
      x: 14563.858695652174,
      y: 1.2463188171386719,
    },
    {
      x: 14567.934782608696,
      y: 1.1317024230957031,
    },
    {
      x: 14572.010869565218,
      y: 1.0021286010742188,
    },
    {
      x: 14576.08695652174,
      y: 0.8625869750976562,
    },
    {
      x: 14580.163043478262,
      y: 0.7230491638183594,
    },
    {
      x: 14584.239130434784,
      y: 0.5835151672363281,
    },
    {
      x: 14588.315217391304,
      y: 0.4489555358886719,
    },
    {
      x: 14592.391304347826,
      y: 0.3193855285644531,
    },
    {
      x: 14596.467391304348,
      y: 0.18483352661132812,
    },
    {
      x: 14600.54347826087,
      y: 0.0751953125,
    },
    {
      x: 14604.619565217392,
      y: 0.0353240966796875,
    },
    {
      x: 14608.695652173914,
      y: -0.029460906982421875,
    },
    {
      x: 14612.771739130436,
      y: -0.04441070556640625,
    },
    {
      x: 14616.847826086956,
      y: -0.054378509521484375,
    },
    {
      x: 14620.923913043478,
      y: -0.06932830810546875,
    },
    {
      x: 14625,
      y: -0.08427810668945312,
    },
    {
      x: 14629.076086956522,
      y: -0.09424591064453125,
    },
    {
      x: 14633.152173913044,
      y: -0.10919570922851562,
    },
    {
      x: 14637.228260869566,
      y: -0.11916351318359375,
    },
    {
      x: 14641.304347826088,
      y: -0.13411712646484375,
    },
    {
      x: 14645.38043478261,
      y: -0.14408111572265625,
    },
    {
      x: 14649.45652173913,
      y: -0.15903091430664062,
    },
    {
      x: 14653.532608695652,
      y: -0.16899871826171875,
    },
    {
      x: 14657.608695652174,
      y: -0.18394851684570312,
    },
    {
      x: 14661.684782608696,
      y: -0.19391632080078125,
    },
    {
      x: 14669.83695652174,
      y: -0.2138519287109375,
    },
    {
      x: 14673.913043478262,
      y: -0.20886993408203125,
    },
    {
      x: 14677.989130434784,
      y: -0.19391632080078125,
    },
    {
      x: 14682.065217391304,
      y: -0.17896652221679688,
    },
    {
      x: 14686.141304347826,
      y: -0.15903091430664062,
    },
    {
      x: 14690.217391304348,
      y: -0.13909912109375,
    },
    {
      x: 14694.29347826087,
      y: -0.11916351318359375,
    },
    {
      x: 14698.369565217392,
      y: -0.09923171997070312,
    },
    {
      x: 14702.445652173914,
      y: -0.08427810668945312,
    },
    {
      x: 14706.521739130436,
      y: -0.0643463134765625,
    },
    {
      x: 14710.597826086956,
      y: -0.049396514892578125,
    },
    {
      x: 14714.673913043478,
      y: -0.029460906982421875,
    },
    {
      x: 14718.75,
      y: -0.00952911376953125,
    },
    {
      x: 14722.826086956522,
      y: 0.00542449951171875,
    },
    {
      x: 14726.902173913044,
      y: 0.020374298095703125,
    },
    {
      x: 14730.978260869566,
      y: 0.040309906005859375,
    },
    {
      x: 14735.054347826088,
      y: 0.05525970458984375,
    },
    {
      x: 14739.13043478261,
      y: 0.0751953125,
    },
    {
      x: 14743.20652173913,
      y: 0.0851593017578125,
    },
    {
      x: 14747.282608695652,
      y: 0.09014511108398438,
    },
    {
      x: 14751.358695652174,
      y: 0.09014511108398438,
    },
    {
      x: 14755.434782608696,
      y: 0.09014511108398438,
    },
    {
      x: 14759.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 14763.58695652174,
      y: 0.09512710571289062,
    },
    {
      x: 14865.489130434784,
      y: 0.09512710571289062,
    },
    {
      x: 14869.565217391304,
      y: 0.5835151672363281,
    },
    {
      x: 14873.641304347826,
      y: 0.4489555358886719,
    },
    {
      x: 14877.717391304348,
      y: 0.3193855285644531,
    },
    {
      x: 14881.79347826087,
      y: 0.18483352661132812,
    },
    {
      x: 14885.869565217392,
      y: 0.0751953125,
    },
    {
      x: 14889.945652173914,
      y: 0.0353240966796875,
    },
    {
      x: 14894.021739130436,
      y: 0.025356292724609375,
    },
    {
      x: 14898.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 14902.173913043478,
      y: 0.020374298095703125,
    },
    {
      x: 14914.402173913044,
      y: 0.020374298095703125,
    },
    {
      x: 14918.478260869566,
      y: 0.025356292724609375,
    },
    {
      x: 14922.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 14930.70652173913,
      y: 0.025356292724609375,
    },
    {
      x: 14934.782608695652,
      y: 0.03034210205078125,
    },
    {
      x: 14938.858695652174,
      y: 0.03034210205078125,
    },
    {
      x: 14947.010869565218,
      y: 0.03034210205078125,
    },
    {
      x: 14951.08695652174,
      y: 0.0353240966796875,
    },
    {
      x: 14955.163043478262,
      y: 0.0353240966796875,
    },
    {
      x: 14963.315217391304,
      y: 0.0353240966796875,
    },
    {
      x: 14967.391304347826,
      y: 0.040309906005859375,
    },
    {
      x: 14971.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 14979.619565217392,
      y: 0.040309906005859375,
    },
    {
      x: 14983.695652173914,
      y: 0.045291900634765625,
    },
    {
      x: 14987.771739130436,
      y: 0.045291900634765625,
    },
    {
      x: 15000,
      y: 0.045291900634765625,
    },
    {
      x: 15004.076086956522,
      y: 0.050273895263671875,
    },
    {
      x: 15008.152173913044,
      y: 0.050273895263671875,
    },
    {
      x: 15032.608695652174,
      y: 0.050273895263671875,
    },
    {
      x: 15036.684782608696,
      y: 0.05525970458984375,
    },
    {
      x: 15040.760869565218,
      y: 0.05525970458984375,
    },
    {
      x: 15065.217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 15069.29347826087,
      y: 0.06024169921875,
    },
    {
      x: 15073.369565217392,
      y: 0.06024169921875,
    },
    {
      x: 15097.826086956522,
      y: 0.06024169921875,
    },
    {
      x: 15101.902173913044,
      y: 0.06522369384765625,
    },
    {
      x: 15105.978260869566,
      y: 0.06522369384765625,
    },
    {
      x: 15110.054347826088,
      y: 0.06024169921875,
    },
    {
      x: 15114.13043478261,
      y: 0.040309906005859375,
    },
    {
      x: 15118.20652173913,
      y: 0.015392303466796875,
    },
    {
      x: 15122.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 15126.358695652174,
      y: -0.0145111083984375,
    },
    {
      x: 15130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 15134.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 15322.010869565218,
      y: 0.09512710571289062,
    },
    {
      x: 15326.08695652174,
      y: 0.1349945068359375,
    },
    {
      x: 15330.163043478262,
      y: 0.2346649169921875,
    },
    {
      x: 15334.239130434784,
      y: 0.35427093505859375,
    },
    {
      x: 15338.315217391304,
      y: 0.4838409423828125,
    },
    {
      x: 15342.391304347826,
      y: 0.6134147644042969,
    },
    {
      x: 15346.467391304348,
      y: 0.7479705810546875,
    },
    {
      x: 15350.54347826087,
      y: 0.8775367736816406,
    },
    {
      x: 15354.619565217392,
      y: 1.0021286010742188,
    },
    {
      x: 15358.695652173914,
      y: 1.1267127990722656,
    },
    {
      x: 15362.771739130436,
      y: 1.2513046264648438,
    },
    {
      x: 15366.847826086958,
      y: 1.3111076354980469,
    },
    {
      x: 15370.923913043478,
      y: 1.2463188171386719,
    },
    {
      x: 15375,
      y: 1.1317024230957031,
    },
    {
      x: 15379.076086956522,
      y: 1.0021286010742188,
    },
    {
      x: 15383.152173913044,
      y: 0.8625869750976562,
    },
    {
      x: 15387.228260869566,
      y: 0.7230491638183594,
    },
    {
      x: 15391.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 15395.38043478261,
      y: 0.09512710571289062,
    },
    {
      x: 15648.097826086958,
      y: 0.09512710571289062,
    },
    {
      x: 15652.173913043478,
      y: 0.06522369384765625,
    },
    {
      x: 15656.25,
      y: 0.06024169921875,
    },
    {
      x: 15660.326086956522,
      y: 0.040309906005859375,
    },
    {
      x: 15664.402173913044,
      y: 0.015392303466796875,
    },
    {
      x: 15668.478260869566,
      y: 0.0004425048828125,
    },
    {
      x: 15672.554347826088,
      y: -0.0145111083984375,
    },
    {
      x: 15676.63043478261,
      y: -0.029460906982421875,
    },
    {
      x: 15680.70652173913,
      y: -0.04441070556640625,
    },
    {
      x: 15684.782608695652,
      y: -0.054378509521484375,
    },
    {
      x: 15688.858695652174,
      y: -0.06932830810546875,
    },
    {
      x: 15692.934782608696,
      y: -0.08427810668945312,
    },
    {
      x: 15697.010869565218,
      y: -0.09424591064453125,
    },
    {
      x: 15701.08695652174,
      y: -0.10919570922851562,
    },
    {
      x: 15705.163043478262,
      y: -0.11916351318359375,
    },
    {
      x: 15709.239130434784,
      y: -0.13411712646484375,
    },
    {
      x: 15713.315217391304,
      y: -0.14408111572265625,
    },
    {
      x: 15717.391304347826,
      y: -0.15903091430664062,
    },
    {
      x: 15721.467391304348,
      y: -0.16899871826171875,
    },
    {
      x: 15725.54347826087,
      y: -0.18394851684570312,
    },
    {
      x: 15729.619565217392,
      y: -0.19391632080078125,
    },
    {
      x: 15737.771739130436,
      y: -0.2138519287109375,
    },
    {
      x: 15741.847826086958,
      y: -0.20886993408203125,
    },
    {
      x: 15745.923913043478,
      y: -0.19391632080078125,
    },
    {
      x: 15750,
      y: -0.17896652221679688,
    },
    {
      x: 15754.076086956522,
      y: -0.15903091430664062,
    },
    {
      x: 15758.152173913044,
      y: -0.13909912109375,
    },
    {
      x: 15762.228260869566,
      y: -0.11916351318359375,
    },
    {
      x: 15766.304347826088,
      y: -0.09923171997070312,
    },
    {
      x: 15770.38043478261,
      y: -0.08427810668945312,
    },
    {
      x: 15774.45652173913,
      y: -0.0643463134765625,
    },
    {
      x: 15778.532608695652,
      y: -0.049396514892578125,
    },
    {
      x: 15782.608695652174,
      y: -0.029460906982421875,
    },
    {
      x: 15786.684782608696,
      y: -0.00952911376953125,
    },
    {
      x: 15790.760869565218,
      y: 0.00542449951171875,
    },
    {
      x: 15794.83695652174,
      y: 0.020374298095703125,
    },
    {
      x: 15798.913043478262,
      y: 0.040309906005859375,
    },
    {
      x: 15802.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 15807.065217391304,
      y: 0.0751953125,
    },
    {
      x: 15811.141304347826,
      y: 0.0851593017578125,
    },
    {
      x: 15815.217391304348,
      y: 0.09014511108398438,
    },
    {
      x: 15819.29347826087,
      y: 0.09014511108398438,
    },
    {
      x: 15823.369565217392,
      y: 0.09014511108398438,
    },
    {
      x: 15827.445652173914,
      y: 0.09512710571289062,
    },
    {
      x: 15831.521739130436,
      y: 0.09512710571289062,
    },
    {
      x: 15908.967391304348,
      y: 0.09512710571289062,
    },
    {
      x: 15913.04347826087,
      y: 1.3111076354980469,
    },
    {
      x: 15917.119565217392,
      y: 1.2463188171386719,
    },
    {
      x: 15921.195652173914,
      y: 1.1317024230957031,
    },
    {
      x: 15925.271739130436,
      y: 1.0021286010742188,
    },
    {
      x: 15929.347826086958,
      y: 0.8625869750976562,
    },
    {
      x: 15933.423913043478,
      y: 0.7230491638183594,
    },
    {
      x: 15937.5,
      y: 0.5835151672363281,
    },
    {
      x: 15941.576086956522,
      y: 0.4489555358886719,
    },
    {
      x: 15945.652173913044,
      y: 0.3193855285644531,
    },
    {
      x: 15949.728260869566,
      y: 0.18483352661132812,
    },
    {
      x: 15953.804347826088,
      y: 0.0751953125,
    },
    {
      x: 15957.88043478261,
      y: 0.0353240966796875,
    },
    {
      x: 15961.95652173913,
      y: 0.025356292724609375,
    },
    {
      x: 15966.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 15970.108695652174,
      y: 0.020374298095703125,
    },
    {
      x: 15982.33695652174,
      y: 0.020374298095703125,
    },
    {
      x: 15986.413043478262,
      y: 0.025356292724609375,
    },
    {
      x: 15990.489130434784,
      y: 0.025356292724609375,
    },
    {
      x: 15998.641304347826,
      y: 0.025356292724609375,
    },
    {
      x: 16002.717391304348,
      y: 0.03034210205078125,
    },
    {
      x: 16006.79347826087,
      y: 0.03034210205078125,
    },
    {
      x: 16014.945652173914,
      y: 0.03034210205078125,
    },
    {
      x: 16019.021739130436,
      y: 0.0353240966796875,
    },
    {
      x: 16023.097826086958,
      y: 0.0353240966796875,
    },
    {
      x: 16031.25,
      y: 0.0353240966796875,
    },
    {
      x: 16035.326086956522,
      y: 0.040309906005859375,
    },
    {
      x: 16039.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 16047.554347826088,
      y: 0.040309906005859375,
    },
    {
      x: 16051.63043478261,
      y: 0.045291900634765625,
    },
    {
      x: 16055.70652173913,
      y: 0.045291900634765625,
    },
    {
      x: 16067.934782608696,
      y: 0.045291900634765625,
    },
    {
      x: 16072.010869565218,
      y: 0.050273895263671875,
    },
    {
      x: 16076.08695652174,
      y: 0.050273895263671875,
    },
    {
      x: 16100.54347826087,
      y: 0.050273895263671875,
    },
    {
      x: 16104.619565217392,
      y: 0.05525970458984375,
    },
    {
      x: 16108.695652173914,
      y: 0.05525970458984375,
    },
    {
      x: 16133.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 16137.228260869566,
      y: 0.06024169921875,
    },
    {
      x: 16141.304347826088,
      y: 0.06024169921875,
    },
    {
      x: 16165.760869565218,
      y: 0.06024169921875,
    },
    {
      x: 16169.83695652174,
      y: 0.06522369384765625,
    },
    {
      x: 16173.913043478262,
      y: 0.09512710571289062,
    },
    {
      x: 16177.989130434784,
      y: 0.09512710571289062,
    },
    {
      x: 16389.945652173912,
      y: 0.09512710571289062,
    },
    {
      x: 16394.021739130436,
      y: 0.1349945068359375,
    },
    {
      x: 16398.097826086956,
      y: 0.2346649169921875,
    },
    {
      x: 16402.17391304348,
      y: 0.35427093505859375,
    },
    {
      x: 16406.25,
      y: 0.4838409423828125,
    },
    {
      x: 16410.326086956524,
      y: 0.6134147644042969,
    },
    {
      x: 16414.402173913044,
      y: 0.7479705810546875,
    },
    {
      x: 16418.478260869564,
      y: 0.8775367736816406,
    },
    {
      x: 16422.554347826088,
      y: 1.0021286010742188,
    },
    {
      x: 16426.630434782608,
      y: 1.1267127990722656,
    },
    {
      x: 16430.706521739132,
      y: 1.2513046264648438,
    },
    {
      x: 16434.782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 16438.858695652176,
      y: 0.09512710571289062,
    },
    {
      x: 16691.576086956524,
      y: 0.09512710571289062,
    },
    {
      x: 16695.652173913044,
      y: 0.6134147644042969,
    },
    {
      x: 16699.728260869564,
      y: 0.7479705810546875,
    },
    {
      x: 16703.804347826088,
      y: 0.8775367736816406,
    },
    {
      x: 16707.880434782608,
      y: 1.0021286010742188,
    },
    {
      x: 16711.956521739132,
      y: 1.1267127990722656,
    },
    {
      x: 16716.032608695652,
      y: 1.2513046264648438,
    },
    {
      x: 16720.108695652176,
      y: 1.3111076354980469,
    },
    {
      x: 16724.184782608696,
      y: 1.2463188171386719,
    },
    {
      x: 16728.260869565216,
      y: 1.1317024230957031,
    },
    {
      x: 16732.33695652174,
      y: 0.9971466064453125,
    },
    {
      x: 16736.41304347826,
      y: 0.85760498046875,
    },
    {
      x: 16740.489130434784,
      y: 0.7230491638183594,
    },
    {
      x: 16744.565217391304,
      y: 0.5835151672363281,
    },
    {
      x: 16748.641304347828,
      y: 0.4489555358886719,
    },
    {
      x: 16752.717391304348,
      y: 0.31439971923828125,
    },
    {
      x: 16756.79347826087,
      y: 0.17984771728515625,
    },
    {
      x: 16760.869565217392,
      y: 0.07020950317382812,
    },
    {
      x: 16764.945652173912,
      y: 0.03034210205078125,
    },
    {
      x: 16769.021739130436,
      y: 0.020374298095703125,
    },
    {
      x: 16773.097826086956,
      y: 0.015392303466796875,
    },
    {
      x: 16777.17391304348,
      y: 0.015392303466796875,
    },
    {
      x: 16789.402173913044,
      y: 0.015392303466796875,
    },
    {
      x: 16793.478260869564,
      y: 0.020374298095703125,
    },
    {
      x: 16797.554347826088,
      y: 0.020374298095703125,
    },
    {
      x: 16805.706521739132,
      y: 0.020374298095703125,
    },
    {
      x: 16809.782608695652,
      y: 0.025356292724609375,
    },
    {
      x: 16813.858695652176,
      y: 0.025356292724609375,
    },
    {
      x: 16822.010869565216,
      y: 0.025356292724609375,
    },
    {
      x: 16826.08695652174,
      y: 0.03034210205078125,
    },
    {
      x: 16830.16304347826,
      y: 0.03034210205078125,
    },
    {
      x: 16838.315217391304,
      y: 0.03034210205078125,
    },
    {
      x: 16842.391304347828,
      y: 0.0353240966796875,
    },
    {
      x: 16846.467391304348,
      y: 0.0353240966796875,
    },
    {
      x: 16854.619565217392,
      y: 0.0353240966796875,
    },
    {
      x: 16858.695652173912,
      y: 0.040309906005859375,
    },
    {
      x: 16862.771739130436,
      y: 0.040309906005859375,
    },
    {
      x: 16870.92391304348,
      y: 0.040309906005859375,
    },
    {
      x: 16875,
      y: 0.045291900634765625,
    },
    {
      x: 16879.076086956524,
      y: 0.045291900634765625,
    },
    {
      x: 16891.304347826088,
      y: 0.045291900634765625,
    },
    {
      x: 16895.380434782608,
      y: 0.050273895263671875,
    },
    {
      x: 16899.456521739132,
      y: 0.050273895263671875,
    },
    {
      x: 16923.91304347826,
      y: 0.050273895263671875,
    },
    {
      x: 16927.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 16932.065217391304,
      y: 0.05525970458984375,
    },
    {
      x: 16956.521739130436,
      y: 0.05525970458984375,
    },
    {
      x: 16960.597826086956,
      y: 0.06024169921875,
    },
    {
      x: 16964.67391304348,
      y: 0.06024169921875,
    },
    {
      x: 16980.978260869564,
      y: 0.06024169921875,
    },
    {
      x: 16985.054347826088,
      y: 0.05525970458984375,
    },
    {
      x: 16989.130434782608,
      y: 0.0353240966796875,
    },
    {
      x: 16993.206521739132,
      y: 0.015392303466796875,
    },
    {
      x: 16997.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 17001.358695652176,
      y: -0.00952911376953125,
    },
    {
      x: 17005.434782608696,
      y: -0.024478912353515625,
    },
    {
      x: 17017.66304347826,
      y: -0.06932830810546875,
    },
    {
      x: 17021.739130434784,
      y: -0.07929611206054688,
    },
    {
      x: 17025.815217391304,
      y: -0.09424591064453125,
    },
    {
      x: 17029.891304347828,
      y: -0.10421371459960938,
    },
    {
      x: 17033.967391304348,
      y: -0.11916351318359375,
    },
    {
      x: 17038.04347826087,
      y: -0.13411712646484375,
    },
    {
      x: 17042.119565217392,
      y: -0.14408111572265625,
    },
    {
      x: 17046.195652173912,
      y: -0.15404891967773438,
    },
    {
      x: 17050.271739130436,
      y: -0.16899871826171875,
    },
    {
      x: 17054.347826086956,
      y: -0.17896652221679688,
    },
    {
      x: 17058.42391304348,
      y: -0.19391632080078125,
    },
    {
      x: 17062.5,
      y: -0.20388412475585938,
    },
    {
      x: 17070.652173913044,
      y: -0.20388412475585938,
    },
    {
      x: 17074.728260869564,
      y: -0.188934326171875,
    },
    {
      x: 17078.804347826088,
      y: -0.17398452758789062,
    },
    {
      x: 17082.880434782608,
      y: -0.15404891967773438,
    },
    {
      x: 17086.956521739132,
      y: -0.13411712646484375,
    },
    {
      x: 17091.032608695652,
      y: -0.11916351318359375,
    },
    {
      x: 17095.108695652176,
      y: -0.09923171997070312,
    },
    {
      x: 17099.184782608696,
      y: -0.07929611206054688,
    },
    {
      x: 17103.260869565216,
      y: -0.0643463134765625,
    },
    {
      x: 17107.33695652174,
      y: -0.04441070556640625,
    },
    {
      x: 17111.41304347826,
      y: -0.024478912353515625,
    },
    {
      x: 17115.489130434784,
      y: -0.00952911376953125,
    },
    {
      x: 17119.565217391304,
      y: 0.00542449951171875,
    },
    {
      x: 17123.641304347828,
      y: 0.025356292724609375,
    },
    {
      x: 17127.717391304348,
      y: 0.040309906005859375,
    },
    {
      x: 17131.79347826087,
      y: 0.06024169921875,
    },
    {
      x: 17135.869565217392,
      y: 0.0751953125,
    },
    {
      x: 17139.945652173912,
      y: 0.0851593017578125,
    },
    {
      x: 17144.021739130436,
      y: 0.09014511108398438,
    },
    {
      x: 17148.097826086956,
      y: 0.09512710571289062,
    },
    {
      x: 17152.17391304348,
      y: 0.09512710571289062,
    },
    {
      x: 17457.880434782608,
      y: 0.09512710571289062,
    },
    {
      x: 17461.956521739132,
      y: 0.1349945068359375,
    },
    {
      x: 17466.032608695652,
      y: 0.2346649169921875,
    },
    {
      x: 17470.108695652176,
      y: 0.35427093505859375,
    },
    {
      x: 17474.184782608696,
      y: 0.4838409423828125,
    },
    {
      x: 17478.260869565216,
      y: 0.09512710571289062,
    },
    {
      x: 17482.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 17743.206521739132,
      y: 0.09512710571289062,
    },
    {
      x: 17747.282608695652,
      y: 0.1349945068359375,
    },
    {
      x: 17751.358695652176,
      y: 0.2346649169921875,
    },
    {
      x: 17755.434782608696,
      y: 0.35427093505859375,
    },
    {
      x: 17759.510869565216,
      y: 0.4838409423828125,
    },
    {
      x: 17763.58695652174,
      y: 0.6134147644042969,
    },
    {
      x: 17767.66304347826,
      y: 0.7479705810546875,
    },
    {
      x: 17771.739130434784,
      y: 0.8775367736816406,
    },
    {
      x: 17775.815217391304,
      y: 1.0021286010742188,
    },
    {
      x: 17779.891304347828,
      y: 1.1267127990722656,
    },
    {
      x: 17783.967391304348,
      y: 1.2513046264648438,
    },
    {
      x: 17788.04347826087,
      y: 1.3111076354980469,
    },
    {
      x: 17792.119565217392,
      y: 1.2463188171386719,
    },
    {
      x: 17796.195652173912,
      y: 1.1317024230957031,
    },
    {
      x: 17800.271739130436,
      y: 0.9971466064453125,
    },
    {
      x: 17804.347826086956,
      y: 0.85760498046875,
    },
    {
      x: 17808.42391304348,
      y: 0.7230491638183594,
    },
    {
      x: 17812.5,
      y: 0.5835151672363281,
    },
    {
      x: 17816.576086956524,
      y: 0.4489555358886719,
    },
    {
      x: 17820.652173913044,
      y: 0.31439971923828125,
    },
    {
      x: 17824.728260869564,
      y: 0.17984771728515625,
    },
    {
      x: 17828.804347826088,
      y: 0.07020950317382812,
    },
    {
      x: 17832.880434782608,
      y: 0.03034210205078125,
    },
    {
      x: 17836.956521739132,
      y: 0.020374298095703125,
    },
    {
      x: 17841.032608695652,
      y: 0.015392303466796875,
    },
    {
      x: 17845.108695652176,
      y: 0.015392303466796875,
    },
    {
      x: 17857.33695652174,
      y: 0.015392303466796875,
    },
    {
      x: 17861.41304347826,
      y: 0.020374298095703125,
    },
    {
      x: 17865.489130434784,
      y: 0.020374298095703125,
    },
    {
      x: 17873.641304347828,
      y: 0.020374298095703125,
    },
    {
      x: 17877.717391304348,
      y: 0.025356292724609375,
    },
    {
      x: 17881.79347826087,
      y: 0.025356292724609375,
    },
    {
      x: 17889.945652173912,
      y: 0.025356292724609375,
    },
    {
      x: 17894.021739130436,
      y: 0.03034210205078125,
    },
    {
      x: 17898.097826086956,
      y: 0.03034210205078125,
    },
    {
      x: 17906.25,
      y: 0.03034210205078125,
    },
    {
      x: 17910.326086956524,
      y: 0.0353240966796875,
    },
    {
      x: 17914.402173913044,
      y: 0.0353240966796875,
    },
    {
      x: 17922.554347826088,
      y: 0.0353240966796875,
    },
    {
      x: 17926.630434782608,
      y: 0.040309906005859375,
    },
    {
      x: 17930.706521739132,
      y: 0.040309906005859375,
    },
    {
      x: 17938.858695652176,
      y: 0.040309906005859375,
    },
    {
      x: 17942.934782608696,
      y: 0.045291900634765625,
    },
    {
      x: 17947.010869565216,
      y: 0.045291900634765625,
    },
    {
      x: 17959.239130434784,
      y: 0.045291900634765625,
    },
    {
      x: 17963.315217391304,
      y: 0.050273895263671875,
    },
    {
      x: 17967.391304347828,
      y: 0.050273895263671875,
    },
    {
      x: 17991.847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 17995.92391304348,
      y: 0.05525970458984375,
    },
    {
      x: 18000,
      y: 0.05525970458984375,
    },
    {
      x: 18024.456521739132,
      y: 0.05525970458984375,
    },
    {
      x: 18028.532608695652,
      y: 0.06024169921875,
    },
    {
      x: 18032.608695652176,
      y: 0.06024169921875,
    },
    {
      x: 18048.91304347826,
      y: 0.06024169921875,
    },
    {
      x: 18052.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 18057.065217391304,
      y: 0.0353240966796875,
    },
    {
      x: 18061.141304347828,
      y: 0.015392303466796875,
    },
    {
      x: 18065.217391304348,
      y: 0.0004425048828125,
    },
    {
      x: 18069.29347826087,
      y: -0.00952911376953125,
    },
    {
      x: 18073.369565217392,
      y: -0.024478912353515625,
    },
    {
      x: 18085.597826086956,
      y: -0.06932830810546875,
    },
    {
      x: 18089.67391304348,
      y: -0.07929611206054688,
    },
    {
      x: 18093.75,
      y: -0.09424591064453125,
    },
    {
      x: 18097.826086956524,
      y: -0.10421371459960938,
    },
    {
      x: 18101.902173913044,
      y: -0.11916351318359375,
    },
    {
      x: 18105.978260869564,
      y: -0.13411712646484375,
    },
    {
      x: 18110.054347826088,
      y: -0.14408111572265625,
    },
    {
      x: 18114.130434782608,
      y: -0.15404891967773438,
    },
    {
      x: 18118.206521739132,
      y: -0.16899871826171875,
    },
    {
      x: 18122.282608695652,
      y: -0.17896652221679688,
    },
    {
      x: 18126.358695652176,
      y: -0.19391632080078125,
    },
    {
      x: 18130.434782608696,
      y: -0.20388412475585938,
    },
    {
      x: 18138.58695652174,
      y: -0.20388412475585938,
    },
    {
      x: 18142.66304347826,
      y: -0.188934326171875,
    },
    {
      x: 18146.739130434784,
      y: -0.17398452758789062,
    },
    {
      x: 18150.815217391304,
      y: -0.15404891967773438,
    },
    {
      x: 18154.891304347828,
      y: -0.13411712646484375,
    },
    {
      x: 18158.967391304348,
      y: -0.11916351318359375,
    },
    {
      x: 18163.04347826087,
      y: -0.09923171997070312,
    },
    {
      x: 18167.119565217392,
      y: -0.07929611206054688,
    },
    {
      x: 18171.195652173912,
      y: -0.0643463134765625,
    },
    {
      x: 18175.271739130436,
      y: -0.04441070556640625,
    },
    {
      x: 18179.347826086956,
      y: -0.024478912353515625,
    },
    {
      x: 18183.42391304348,
      y: -0.00952911376953125,
    },
    {
      x: 18187.5,
      y: 0.00542449951171875,
    },
    {
      x: 18191.576086956524,
      y: 0.025356292724609375,
    },
    {
      x: 18195.652173913044,
      y: 0.040309906005859375,
    },
    {
      x: 18199.728260869564,
      y: 0.06024169921875,
    },
    {
      x: 18203.804347826088,
      y: 0.0751953125,
    },
    {
      x: 18207.880434782608,
      y: 0.0851593017578125,
    },
    {
      x: 18211.956521739132,
      y: 0.09014511108398438,
    },
    {
      x: 18216.032608695652,
      y: 0.09512710571289062,
    },
    {
      x: 18220.108695652176,
      y: 0.09512710571289062,
    },
    {
      x: 19039.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 19043.478260869564,
      y: 0.050273895263671875,
    },
    {
      x: 19047.554347826088,
      y: 0.050273895263671875,
    },
    {
      x: 19059.782608695652,
      y: 0.050273895263671875,
    },
    {
      x: 19063.858695652176,
      y: 0.05525970458984375,
    },
    {
      x: 19067.934782608696,
      y: 0.05525970458984375,
    },
    {
      x: 19092.391304347828,
      y: 0.05525970458984375,
    },
    {
      x: 19096.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 19100.54347826087,
      y: 0.06024169921875,
    },
    {
      x: 19116.847826086956,
      y: 0.06024169921875,
    },
    {
      x: 19120.92391304348,
      y: 0.05525970458984375,
    },
    {
      x: 19125,
      y: 0.0353240966796875,
    },
    {
      x: 19129.076086956524,
      y: 0.015392303466796875,
    },
    {
      x: 19133.152173913044,
      y: 0.0004425048828125,
    },
    {
      x: 19137.228260869564,
      y: -0.00952911376953125,
    },
    {
      x: 19141.304347826088,
      y: -0.024478912353515625,
    },
    {
      x: 19153.532608695652,
      y: -0.06932830810546875,
    },
    {
      x: 19157.608695652176,
      y: -0.07929611206054688,
    },
    {
      x: 19161.684782608696,
      y: -0.09424591064453125,
    },
    {
      x: 19165.760869565216,
      y: -0.10421371459960938,
    },
    {
      x: 19169.83695652174,
      y: -0.11916351318359375,
    },
    {
      x: 19173.91304347826,
      y: -0.13411712646484375,
    },
    {
      x: 19177.989130434784,
      y: -0.14408111572265625,
    },
    {
      x: 19182.065217391304,
      y: -0.15903091430664062,
    },
    {
      x: 19186.141304347828,
      y: -0.16899871826171875,
    },
    {
      x: 19190.217391304348,
      y: -0.17896652221679688,
    },
    {
      x: 19194.29347826087,
      y: -0.19391632080078125,
    },
    {
      x: 19198.369565217392,
      y: -0.20388412475585938,
    },
    {
      x: 19206.521739130436,
      y: -0.20388412475585938,
    },
    {
      x: 19210.597826086956,
      y: -0.188934326171875,
    },
    {
      x: 19214.67391304348,
      y: -0.17398452758789062,
    },
    {
      x: 19218.75,
      y: -0.15404891967773438,
    },
    {
      x: 19222.826086956524,
      y: -0.13411712646484375,
    },
    {
      x: 19226.902173913044,
      y: -0.11916351318359375,
    },
    {
      x: 19230.978260869564,
      y: -0.09923171997070312,
    },
    {
      x: 19235.054347826088,
      y: -0.07929611206054688,
    },
    {
      x: 19239.130434782608,
      y: -0.0643463134765625,
    },
    {
      x: 19243.206521739132,
      y: -0.04441070556640625,
    },
    {
      x: 19247.282608695652,
      y: -0.024478912353515625,
    },
    {
      x: 19251.358695652176,
      y: -0.00952911376953125,
    },
    {
      x: 19255.434782608696,
      y: 0.00542449951171875,
    },
    {
      x: 19259.510869565216,
      y: 0.025356292724609375,
    },
    {
      x: 19263.58695652174,
      y: 0.040309906005859375,
    },
    {
      x: 19267.66304347826,
      y: 0.06024169921875,
    },
    {
      x: 19271.739130434784,
      y: 0.0751953125,
    },
    {
      x: 19275.815217391304,
      y: 0.0851593017578125,
    },
    {
      x: 19279.891304347828,
      y: 0.09014511108398438,
    },
    {
      x: 19283.967391304348,
      y: 0.09512710571289062,
    },
    {
      x: 19288.04347826087,
      y: 0.09512710571289062,
    },
    {
      x: 19332.880434782608,
      y: 0.09512710571289062,
    },
    {
      x: 19336.956521739132,
      y: 0.1349945068359375,
    },
    {
      x: 19341.032608695652,
      y: 0.2346649169921875,
    },
    {
      x: 19345.108695652176,
      y: 0.35427093505859375,
    },
    {
      x: 19349.184782608696,
      y: 0.4838409423828125,
    },
    {
      x: 19353.260869565216,
      y: 0.6134147644042969,
    },
    {
      x: 19357.33695652174,
      y: 0.7479705810546875,
    },
    {
      x: 19361.41304347826,
      y: 0.8775367736816406,
    },
    {
      x: 19365.489130434784,
      y: 1.0021286010742188,
    },
    {
      x: 19369.565217391304,
      y: 1.1267127990722656,
    },
    {
      x: 19373.641304347828,
      y: 1.2513046264648438,
    },
    {
      x: 19377.717391304348,
      y: 1.3111076354980469,
    },
    {
      x: 19381.79347826087,
      y: 1.2463188171386719,
    },
    {
      x: 19385.869565217392,
      y: 1.1317024230957031,
    },
    {
      x: 19389.945652173912,
      y: 0.9971466064453125,
    },
    {
      x: 19394.021739130436,
      y: 0.85760498046875,
    },
    {
      x: 19398.097826086956,
      y: 0.7230491638183594,
    },
    {
      x: 19402.17391304348,
      y: 0.5835151672363281,
    },
    {
      x: 19406.25,
      y: 0.4489555358886719,
    },
    {
      x: 19410.326086956524,
      y: 0.31439971923828125,
    },
    {
      x: 19414.402173913044,
      y: 0.17984771728515625,
    },
    {
      x: 19418.478260869564,
      y: 0.07020950317382812,
    },
    {
      x: 19422.554347826088,
      y: 0.03034210205078125,
    },
    {
      x: 19426.630434782608,
      y: 0.020374298095703125,
    },
    {
      x: 19430.706521739132,
      y: 0.015392303466796875,
    },
    {
      x: 19434.782608695652,
      y: 0.015392303466796875,
    },
    {
      x: 19447.010869565216,
      y: 0.015392303466796875,
    },
    {
      x: 19451.08695652174,
      y: 0.020374298095703125,
    },
    {
      x: 19455.16304347826,
      y: 0.020374298095703125,
    },
    {
      x: 19463.315217391304,
      y: 0.020374298095703125,
    },
    {
      x: 19467.391304347828,
      y: 0.025356292724609375,
    },
    {
      x: 19471.467391304348,
      y: 0.025356292724609375,
    },
    {
      x: 19479.619565217392,
      y: 0.025356292724609375,
    },
    {
      x: 19483.695652173912,
      y: 0.03034210205078125,
    },
    {
      x: 19487.771739130436,
      y: 0.03034210205078125,
    },
    {
      x: 19495.92391304348,
      y: 0.03034210205078125,
    },
    {
      x: 19500,
      y: 0.0353240966796875,
    },
    {
      x: 19504.076086956524,
      y: 0.0353240966796875,
    },
    {
      x: 19512.228260869564,
      y: 0.0353240966796875,
    },
    {
      x: 19516.304347826088,
      y: 0.040309906005859375,
    },
    {
      x: 19520.380434782608,
      y: 0.040309906005859375,
    },
    {
      x: 19528.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 19532.608695652176,
      y: 0.045291900634765625,
    },
    {
      x: 19536.684782608696,
      y: 0.045291900634765625,
    },
    {
      x: 19548.91304347826,
      y: 0.045291900634765625,
    },
    {
      x: 19552.989130434784,
      y: 0.050273895263671875,
    },
    {
      x: 19557.065217391304,
      y: 0.050273895263671875,
    },
    {
      x: 19561.141304347828,
      y: 0.050273895263671875,
    },
    {
      x: 19565.217391304348,
      y: 0.09014511108398438,
    },
    {
      x: 19569.29347826087,
      y: 0.09512710571289062,
    },
    {
      x: 19573.369565217392,
      y: 0.09512710571289062,
    },
    {
      x: 20082.880434782608,
      y: 0.09512710571289062,
    },
    {
      x: 20086.956521739132,
      y: 0.045291900634765625,
    },
    {
      x: 20091.032608695652,
      y: 0.045291900634765625,
    },
    {
      x: 20095.108695652176,
      y: 0.045291900634765625,
    },
    {
      x: 20099.184782608696,
      y: 0.050273895263671875,
    },
    {
      x: 20103.26086956522,
      y: 0.050273895263671875,
    },
    {
      x: 20127.717391304348,
      y: 0.050273895263671875,
    },
    {
      x: 20131.79347826087,
      y: 0.05525970458984375,
    },
    {
      x: 20135.869565217392,
      y: 0.05525970458984375,
    },
    {
      x: 20160.326086956524,
      y: 0.05525970458984375,
    },
    {
      x: 20164.402173913044,
      y: 0.06024169921875,
    },
    {
      x: 20168.478260869564,
      y: 0.06024169921875,
    },
    {
      x: 20184.782608695652,
      y: 0.06024169921875,
    },
    {
      x: 20188.858695652176,
      y: 0.05525970458984375,
    },
    {
      x: 20192.934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 20197.01086956522,
      y: 0.015392303466796875,
    },
    {
      x: 20201.08695652174,
      y: 0.0004425048828125,
    },
    {
      x: 20205.16304347826,
      y: -0.00952911376953125,
    },
    {
      x: 20209.239130434784,
      y: -0.024478912353515625,
    },
    {
      x: 20221.467391304348,
      y: -0.06932830810546875,
    },
    {
      x: 20225.54347826087,
      y: -0.07929611206054688,
    },
    {
      x: 20229.619565217392,
      y: -0.09424591064453125,
    },
    {
      x: 20233.695652173912,
      y: -0.10421371459960938,
    },
    {
      x: 20237.771739130436,
      y: -0.11916351318359375,
    },
    {
      x: 20241.847826086956,
      y: -0.13411712646484375,
    },
    {
      x: 20245.92391304348,
      y: -0.14408111572265625,
    },
    {
      x: 20250,
      y: -0.15404891967773438,
    },
    {
      x: 20254.076086956524,
      y: -0.16899871826171875,
    },
    {
      x: 20258.152173913044,
      y: -0.17896652221679688,
    },
    {
      x: 20262.228260869564,
      y: -0.188934326171875,
    },
    {
      x: 20266.304347826088,
      y: -0.20388412475585938,
    },
    {
      x: 20270.380434782608,
      y: -0.2138519287109375,
    },
    {
      x: 20274.456521739132,
      y: -0.20388412475585938,
    },
    {
      x: 20278.532608695652,
      y: -0.188934326171875,
    },
    {
      x: 20282.608695652176,
      y: -0.17398452758789062,
    },
    {
      x: 20286.684782608696,
      y: -0.15404891967773438,
    },
    {
      x: 20290.76086956522,
      y: -0.13411712646484375,
    },
    {
      x: 20294.83695652174,
      y: -0.11916351318359375,
    },
    {
      x: 20298.91304347826,
      y: -0.09923171997070312,
    },
    {
      x: 20302.989130434784,
      y: -0.07929611206054688,
    },
    {
      x: 20307.065217391304,
      y: -0.0643463134765625,
    },
    {
      x: 20311.141304347828,
      y: -0.04441070556640625,
    },
    {
      x: 20315.217391304348,
      y: -0.024478912353515625,
    },
    {
      x: 20319.29347826087,
      y: -0.00952911376953125,
    },
    {
      x: 20323.369565217392,
      y: 0.00542449951171875,
    },
    {
      x: 20327.445652173912,
      y: 0.025356292724609375,
    },
    {
      x: 20331.521739130436,
      y: 0.040309906005859375,
    },
    {
      x: 20335.597826086956,
      y: 0.06024169921875,
    },
    {
      x: 20339.67391304348,
      y: 0.0751953125,
    },
    {
      x: 20343.75,
      y: 0.0851593017578125,
    },
    {
      x: 20347.826086956524,
      y: 0.09512710571289062,
    },
    {
      x: 20351.902173913044,
      y: 0.09512710571289062,
    },
    {
      x: 20400.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 20404.891304347828,
      y: 0.1349945068359375,
    },
    {
      x: 20408.967391304348,
      y: 0.2346649169921875,
    },
    {
      x: 20413.04347826087,
      y: 0.35427093505859375,
    },
    {
      x: 20417.119565217392,
      y: 0.4838409423828125,
    },
    {
      x: 20421.195652173912,
      y: 0.6134147644042969,
    },
    {
      x: 20425.271739130436,
      y: 0.7479705810546875,
    },
    {
      x: 20429.347826086956,
      y: 0.8775367736816406,
    },
    {
      x: 20433.42391304348,
      y: 1.0021286010742188,
    },
    {
      x: 20437.5,
      y: 1.1267127990722656,
    },
    {
      x: 20441.576086956524,
      y: 1.2513046264648438,
    },
    {
      x: 20445.652173913044,
      y: 1.3111076354980469,
    },
    {
      x: 20449.728260869564,
      y: 1.2463188171386719,
    },
    {
      x: 20453.804347826088,
      y: 1.1317024230957031,
    },
    {
      x: 20457.880434782608,
      y: 0.9971466064453125,
    },
    {
      x: 20461.956521739132,
      y: 0.85760498046875,
    },
    {
      x: 20466.032608695652,
      y: 0.7230491638183594,
    },
    {
      x: 20470.108695652176,
      y: 0.5835151672363281,
    },
    {
      x: 20474.184782608696,
      y: 0.4489555358886719,
    },
    {
      x: 20478.26086956522,
      y: 0.31439971923828125,
    },
    {
      x: 20482.33695652174,
      y: 0.17984771728515625,
    },
    {
      x: 20486.41304347826,
      y: 0.07020950317382812,
    },
    {
      x: 20490.489130434784,
      y: 0.03034210205078125,
    },
    {
      x: 20494.565217391304,
      y: 0.020374298095703125,
    },
    {
      x: 20498.641304347828,
      y: 0.015392303466796875,
    },
    {
      x: 20502.717391304348,
      y: 0.015392303466796875,
    },
    {
      x: 20514.945652173912,
      y: 0.015392303466796875,
    },
    {
      x: 20519.021739130436,
      y: 0.020374298095703125,
    },
    {
      x: 20523.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 20531.25,
      y: 0.020374298095703125,
    },
    {
      x: 20535.326086956524,
      y: 0.025356292724609375,
    },
    {
      x: 20539.402173913044,
      y: 0.025356292724609375,
    },
    {
      x: 20547.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 20551.630434782608,
      y: 0.03034210205078125,
    },
    {
      x: 20555.706521739132,
      y: 0.03034210205078125,
    },
    {
      x: 20563.858695652176,
      y: 0.03034210205078125,
    },
    {
      x: 20567.934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 20572.01086956522,
      y: 0.0353240966796875,
    },
    {
      x: 20580.16304347826,
      y: 0.0353240966796875,
    },
    {
      x: 20584.239130434784,
      y: 0.040309906005859375,
    },
    {
      x: 20588.315217391304,
      y: 0.040309906005859375,
    },
    {
      x: 20596.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 20600.54347826087,
      y: 0.045291900634765625,
    },
    {
      x: 20604.619565217392,
      y: 0.045291900634765625,
    },
    {
      x: 20608.695652173912,
      y: 0.00542449951171875,
    },
    {
      x: 20612.771739130436,
      y: 0.025356292724609375,
    },
    {
      x: 20616.847826086956,
      y: 0.040309906005859375,
    },
    {
      x: 20620.92391304348,
      y: 0.06024169921875,
    },
    {
      x: 20625,
      y: 0.0751953125,
    },
    {
      x: 20629.076086956524,
      y: 0.0851593017578125,
    },
    {
      x: 20633.152173913044,
      y: 0.09014511108398438,
    },
    {
      x: 20637.228260869564,
      y: 0.09512710571289062,
    },
    {
      x: 20641.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 20865.489130434784,
      y: 0.09512710571289062,
    },
    {
      x: 20869.565217391304,
      y: 0.040309906005859375,
    },
    {
      x: 20873.641304347828,
      y: 0.040309906005859375,
    },
    {
      x: 20881.79347826087,
      y: 0.040309906005859375,
    },
    {
      x: 20885.869565217392,
      y: 0.045291900634765625,
    },
    {
      x: 20889.945652173912,
      y: 0.045291900634765625,
    },
    {
      x: 20902.17391304348,
      y: 0.045291900634765625,
    },
    {
      x: 20906.25,
      y: 0.050273895263671875,
    },
    {
      x: 20910.326086956524,
      y: 0.050273895263671875,
    },
    {
      x: 20934.782608695652,
      y: 0.050273895263671875,
    },
    {
      x: 20938.858695652176,
      y: 0.05525970458984375,
    },
    {
      x: 20942.934782608696,
      y: 0.05525970458984375,
    },
    {
      x: 20967.391304347828,
      y: 0.05525970458984375,
    },
    {
      x: 20971.467391304348,
      y: 0.06024169921875,
    },
    {
      x: 20975.54347826087,
      y: 0.06024169921875,
    },
    {
      x: 20991.847826086956,
      y: 0.06024169921875,
    },
    {
      x: 20995.92391304348,
      y: 0.05525970458984375,
    },
    {
      x: 21000,
      y: 0.0353240966796875,
    },
    {
      x: 21004.076086956524,
      y: 0.015392303466796875,
    },
    {
      x: 21008.152173913044,
      y: 0.0004425048828125,
    },
    {
      x: 21012.228260869564,
      y: -0.00952911376953125,
    },
    {
      x: 21016.304347826088,
      y: -0.024478912353515625,
    },
    {
      x: 21028.532608695652,
      y: -0.06932830810546875,
    },
    {
      x: 21032.608695652176,
      y: -0.07929611206054688,
    },
    {
      x: 21036.684782608696,
      y: -0.09424591064453125,
    },
    {
      x: 21040.76086956522,
      y: -0.10421371459960938,
    },
    {
      x: 21044.83695652174,
      y: -0.11916351318359375,
    },
    {
      x: 21048.91304347826,
      y: -0.13411712646484375,
    },
    {
      x: 21052.989130434784,
      y: -0.14408111572265625,
    },
    {
      x: 21057.065217391304,
      y: -0.15404891967773438,
    },
    {
      x: 21061.141304347828,
      y: -0.16899871826171875,
    },
    {
      x: 21065.217391304348,
      y: -0.17896652221679688,
    },
    {
      x: 21069.29347826087,
      y: -0.19391632080078125,
    },
    {
      x: 21073.369565217392,
      y: -0.20388412475585938,
    },
    {
      x: 21081.521739130436,
      y: -0.20388412475585938,
    },
    {
      x: 21085.597826086956,
      y: -0.188934326171875,
    },
    {
      x: 21089.67391304348,
      y: -0.17398452758789062,
    },
    {
      x: 21093.75,
      y: -0.15404891967773438,
    },
    {
      x: 21097.826086956524,
      y: -0.13411712646484375,
    },
    {
      x: 21101.902173913044,
      y: -0.11916351318359375,
    },
    {
      x: 21105.978260869564,
      y: -0.09923171997070312,
    },
    {
      x: 21110.054347826088,
      y: -0.07929611206054688,
    },
    {
      x: 21114.130434782608,
      y: -0.0643463134765625,
    },
    {
      x: 21118.206521739132,
      y: -0.04441070556640625,
    },
    {
      x: 21122.282608695652,
      y: -0.024478912353515625,
    },
    {
      x: 21126.358695652176,
      y: -0.00952911376953125,
    },
    {
      x: 21130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 21134.51086956522,
      y: 0.09512710571289062,
    },
    {
      x: 21468.75,
      y: 0.09512710571289062,
    },
    {
      x: 21472.826086956524,
      y: 0.1349945068359375,
    },
    {
      x: 21476.902173913044,
      y: 0.2346649169921875,
    },
    {
      x: 21480.978260869564,
      y: 0.35427093505859375,
    },
    {
      x: 21485.054347826088,
      y: 0.4838409423828125,
    },
    {
      x: 21489.130434782608,
      y: 0.6134147644042969,
    },
    {
      x: 21493.206521739132,
      y: 0.7479705810546875,
    },
    {
      x: 21497.282608695652,
      y: 0.8775367736816406,
    },
    {
      x: 21501.358695652176,
      y: 1.0021286010742188,
    },
    {
      x: 21505.434782608696,
      y: 1.1267127990722656,
    },
    {
      x: 21509.51086956522,
      y: 1.2513046264648438,
    },
    {
      x: 21513.58695652174,
      y: 1.3111076354980469,
    },
    {
      x: 21517.66304347826,
      y: 1.2463188171386719,
    },
    {
      x: 21521.739130434784,
      y: 1.1317024230957031,
    },
    {
      x: 21525.815217391304,
      y: 0.9971466064453125,
    },
    {
      x: 21529.891304347828,
      y: 0.85760498046875,
    },
    {
      x: 21533.967391304348,
      y: 0.7230491638183594,
    },
    {
      x: 21538.04347826087,
      y: 0.5835151672363281,
    },
    {
      x: 21542.119565217392,
      y: 0.4489555358886719,
    },
    {
      x: 21546.195652173912,
      y: 0.31439971923828125,
    },
    {
      x: 21550.271739130436,
      y: 0.17984771728515625,
    },
    {
      x: 21554.347826086956,
      y: 0.07020950317382812,
    },
    {
      x: 21558.42391304348,
      y: 0.03034210205078125,
    },
    {
      x: 21562.5,
      y: 0.020374298095703125,
    },
    {
      x: 21566.576086956524,
      y: 0.015392303466796875,
    },
    {
      x: 21570.652173913044,
      y: 0.015392303466796875,
    },
    {
      x: 21582.880434782608,
      y: 0.015392303466796875,
    },
    {
      x: 21586.956521739132,
      y: 0.020374298095703125,
    },
    {
      x: 21591.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 21599.184782608696,
      y: 0.020374298095703125,
    },
    {
      x: 21603.26086956522,
      y: 0.025356292724609375,
    },
    {
      x: 21607.33695652174,
      y: 0.025356292724609375,
    },
    {
      x: 21615.489130434784,
      y: 0.025356292724609375,
    },
    {
      x: 21619.565217391304,
      y: 0.03034210205078125,
    },
    {
      x: 21623.641304347828,
      y: 0.03034210205078125,
    },
    {
      x: 21631.79347826087,
      y: 0.03034210205078125,
    },
    {
      x: 21635.869565217392,
      y: 0.0353240966796875,
    },
    {
      x: 21639.945652173912,
      y: 0.0353240966796875,
    },
    {
      x: 21648.097826086956,
      y: 0.0353240966796875,
    },
    {
      x: 21652.17391304348,
      y: -0.09923171997070312,
    },
    {
      x: 21656.25,
      y: -0.07929611206054688,
    },
    {
      x: 21660.326086956524,
      y: -0.0643463134765625,
    },
    {
      x: 21664.402173913044,
      y: -0.04441070556640625,
    },
    {
      x: 21668.478260869564,
      y: -0.024478912353515625,
    },
    {
      x: 21672.554347826088,
      y: -0.00952911376953125,
    },
    {
      x: 21676.630434782608,
      y: 0.00542449951171875,
    },
    {
      x: 21680.706521739132,
      y: 0.025356292724609375,
    },
    {
      x: 21684.782608695652,
      y: 0.040309906005859375,
    },
    {
      x: 21688.858695652176,
      y: 0.06024169921875,
    },
    {
      x: 21692.934782608696,
      y: 0.0751953125,
    },
    {
      x: 21697.01086956522,
      y: 0.0851593017578125,
    },
    {
      x: 21701.08695652174,
      y: 0.09014511108398438,
    },
    {
      x: 21705.16304347826,
      y: 0.09512710571289062,
    },
    {
      x: 21709.239130434784,
      y: 0.09512710571289062,
    },
    {
      x: 21908.967391304348,
      y: 0.09512710571289062,
    },
    {
      x: 21913.04347826087,
      y: 0.03034210205078125,
    },
    {
      x: 21917.119565217392,
      y: 0.03034210205078125,
    },
    {
      x: 21921.195652173912,
      y: 0.0353240966796875,
    },
    {
      x: 21925.271739130436,
      y: 0.0353240966796875,
    },
    {
      x: 21933.42391304348,
      y: 0.0353240966796875,
    },
    {
      x: 21937.5,
      y: 0.040309906005859375,
    },
    {
      x: 21941.576086956524,
      y: 0.040309906005859375,
    },
    {
      x: 21949.728260869564,
      y: 0.040309906005859375,
    },
    {
      x: 21953.804347826088,
      y: 0.045291900634765625,
    },
    {
      x: 21957.880434782608,
      y: 0.045291900634765625,
    },
    {
      x: 21970.108695652176,
      y: 0.045291900634765625,
    },
    {
      x: 21974.184782608696,
      y: 0.050273895263671875,
    },
    {
      x: 21978.26086956522,
      y: 0.050273895263671875,
    },
    {
      x: 22002.717391304348,
      y: 0.050273895263671875,
    },
    {
      x: 22006.79347826087,
      y: 0.05525970458984375,
    },
    {
      x: 22010.869565217392,
      y: 0.05525970458984375,
    },
    {
      x: 22035.326086956524,
      y: 0.05525970458984375,
    },
    {
      x: 22039.402173913044,
      y: 0.06024169921875,
    },
    {
      x: 22043.478260869564,
      y: 0.06024169921875,
    },
    {
      x: 22059.782608695652,
      y: 0.06024169921875,
    },
    {
      x: 22063.858695652176,
      y: 0.05525970458984375,
    },
    {
      x: 22067.934782608696,
      y: 0.0353240966796875,
    },
    {
      x: 22072.01086956522,
      y: 0.015392303466796875,
    },
    {
      x: 22076.08695652174,
      y: 0.0004425048828125,
    },
    {
      x: 22080.16304347826,
      y: -0.00952911376953125,
    },
    {
      x: 22084.239130434784,
      y: -0.024478912353515625,
    },
    {
      x: 22096.467391304348,
      y: -0.06932830810546875,
    },
    {
      x: 22100.54347826087,
      y: -0.07929611206054688,
    },
    {
      x: 22104.619565217392,
      y: -0.09424591064453125,
    },
    {
      x: 22108.695652173912,
      y: -0.10421371459960938,
    },
    {
      x: 22112.771739130436,
      y: -0.11916351318359375,
    },
    {
      x: 22116.847826086956,
      y: -0.13411712646484375,
    },
    {
      x: 22120.92391304348,
      y: -0.14408111572265625,
    },
    {
      x: 22125,
      y: -0.15404891967773438,
    },
    {
      x: 22129.076086956524,
      y: -0.16899871826171875,
    },
    {
      x: 22133.152173913044,
      y: -0.17896652221679688,
    },
    {
      x: 22137.228260869564,
      y: -0.19391632080078125,
    },
    {
      x: 22141.304347826088,
      y: -0.20388412475585938,
    },
    {
      x: 22149.456521739132,
      y: -0.20388412475585938,
    },
    {
      x: 22153.532608695652,
      y: -0.188934326171875,
    },
    {
      x: 22157.608695652176,
      y: -0.17398452758789062,
    },
    {
      x: 22161.684782608696,
      y: -0.15404891967773438,
    },
    {
      x: 22165.76086956522,
      y: -0.13411712646484375,
    },
    {
      x: 22169.83695652174,
      y: -0.11916351318359375,
    },
    {
      x: 22173.91304347826,
      y: 0.09512710571289062,
    },
    {
      x: 22177.989130434784,
      y: 0.09512710571289062,
    },
    {
      x: 22536.684782608696,
      y: 0.09512710571289062,
    },
    {
      x: 22540.76086956522,
      y: 0.1349945068359375,
    },
    {
      x: 22544.83695652174,
      y: 0.2346649169921875,
    },
    {
      x: 22548.91304347826,
      y: 0.35427093505859375,
    },
    {
      x: 22552.989130434784,
      y: 0.4838409423828125,
    },
    {
      x: 22557.065217391304,
      y: 0.6134147644042969,
    },
    {
      x: 22561.141304347828,
      y: 0.7479705810546875,
    },
    {
      x: 22565.217391304348,
      y: 0.8775367736816406,
    },
    {
      x: 22569.29347826087,
      y: 1.0021286010742188,
    },
    {
      x: 22573.369565217392,
      y: 1.1267127990722656,
    },
    {
      x: 22577.445652173912,
      y: 1.2513046264648438,
    },
    {
      x: 22581.521739130436,
      y: 1.3111076354980469,
    },
    {
      x: 22585.597826086956,
      y: 1.2463188171386719,
    },
    {
      x: 22589.67391304348,
      y: 1.1317024230957031,
    },
    {
      x: 22593.75,
      y: 0.9971466064453125,
    },
    {
      x: 22597.826086956524,
      y: 0.85760498046875,
    },
    {
      x: 22601.902173913044,
      y: 0.7230491638183594,
    },
    {
      x: 22605.978260869564,
      y: 0.5835151672363281,
    },
    {
      x: 22610.054347826088,
      y: 0.4489555358886719,
    },
    {
      x: 22614.130434782608,
      y: 0.31439971923828125,
    },
    {
      x: 22618.206521739132,
      y: 0.17984771728515625,
    },
    {
      x: 22622.282608695652,
      y: 0.07020950317382812,
    },
    {
      x: 22626.358695652176,
      y: 0.03034210205078125,
    },
    {
      x: 22630.434782608696,
      y: 0.020374298095703125,
    },
    {
      x: 22634.51086956522,
      y: 0.015392303466796875,
    },
    {
      x: 22638.58695652174,
      y: 0.015392303466796875,
    },
    {
      x: 22650.815217391304,
      y: 0.015392303466796875,
    },
    {
      x: 22654.891304347828,
      y: 0.020374298095703125,
    },
    {
      x: 22658.967391304348,
      y: 0.020374298095703125,
    },
    {
      x: 22667.119565217392,
      y: 0.020374298095703125,
    },
    {
      x: 22671.195652173912,
      y: 0.025356292724609375,
    },
    {
      x: 22675.271739130436,
      y: 0.025356292724609375,
    },
    {
      x: 22683.42391304348,
      y: 0.025356292724609375,
    },
    {
      x: 22687.5,
      y: 0.03034210205078125,
    },
    {
      x: 22691.576086956524,
      y: 0.03034210205078125,
    },
    {
      x: 22695.652173913044,
      y: -0.20388412475585938,
    },
    {
      x: 22699.728260869564,
      y: -0.188934326171875,
    },
    {
      x: 22703.804347826088,
      y: -0.17398452758789062,
    },
    {
      x: 22707.880434782608,
      y: -0.15404891967773438,
    },
    {
      x: 22711.956521739132,
      y: -0.13411712646484375,
    },
    {
      x: 22716.032608695652,
      y: -0.11916351318359375,
    },
    {
      x: 22720.108695652176,
      y: -0.09923171997070312,
    },
    {
      x: 22724.184782608696,
      y: -0.07929611206054688,
    },
    {
      x: 22728.26086956522,
      y: -0.0643463134765625,
    },
    {
      x: 22732.33695652174,
      y: -0.04441070556640625,
    },
    {
      x: 22736.41304347826,
      y: -0.024478912353515625,
    },
    {
      x: 22740.489130434784,
      y: -0.00952911376953125,
    },
    {
      x: 22744.565217391304,
      y: 0.00542449951171875,
    },
    {
      x: 22748.641304347828,
      y: 0.025356292724609375,
    },
    {
      x: 22752.717391304348,
      y: 0.040309906005859375,
    },
    {
      x: 22756.79347826087,
      y: 0.06024169921875,
    },
    {
      x: 22760.869565217392,
      y: 0.0751953125,
    },
    {
      x: 22764.945652173912,
      y: 0.0851593017578125,
    },
    {
      x: 22769.021739130436,
      y: 0.09014511108398438,
    },
    {
      x: 22773.097826086956,
      y: 0.09512710571289062,
    },
    {
      x: 22777.17391304348,
      y: 0.09512710571289062,
    },
    {
      x: 22952.445652173912,
      y: 0.09512710571289062,
    },
    {
      x: 22956.521739130436,
      y: 0.025356292724609375,
    },
    {
      x: 22960.597826086956,
      y: 0.025356292724609375,
    },
    {
      x: 22968.75,
      y: 0.025356292724609375,
    },
    {
      x: 22972.826086956524,
      y: 0.03034210205078125,
    },
    {
      x: 22976.902173913044,
      y: 0.03034210205078125,
    },
    {
      x: 22985.054347826088,
      y: 0.03034210205078125,
    },
    {
      x: 22989.130434782608,
      y: 0.0353240966796875,
    },
    {
      x: 22993.206521739132,
      y: 0.0353240966796875,
    },
    {
      x: 23001.358695652176,
      y: 0.0353240966796875,
    },
    {
      x: 23005.434782608696,
      y: 0.040309906005859375,
    },
    {
      x: 23009.51086956522,
      y: 0.040309906005859375,
    },
    {
      x: 23017.66304347826,
      y: 0.040309906005859375,
    },
    {
      x: 23021.739130434784,
      y: 0.045291900634765625,
    },
    {
      x: 23025.815217391304,
      y: 0.045291900634765625,
    },
    {
      x: 23038.04347826087,
      y: 0.045291900634765625,
    },
    {
      x: 23042.119565217392,
      y: 0.050273895263671875,
    },
    {
      x: 23046.195652173912,
      y: 0.050273895263671875,
    },
    {
      x: 23070.652173913044,
      y: 0.050273895263671875,
    },
    {
      x: 23074.728260869564,
      y: 0.05525970458984375,
    },
    {
      x: 23078.804347826088,
      y: 0.05525970458984375,
    },
    {
      x: 23103.26086956522,
      y: 0.05525970458984375,
    },
    {
      x: 23107.33695652174,
      y: 0.06024169921875,
    },
    {
      x: 23111.41304347826,
      y: 0.06024169921875,
    },
    {
      x: 23127.717391304348,
      y: 0.06024169921875,
    },
    {
      x: 23131.79347826087,
      y: 0.05525970458984375,
    },
    {
      x: 23135.869565217392,
      y: 0.0353240966796875,
    },
    {
      x: 23139.945652173912,
      y: 0.015392303466796875,
    },
    {
      x: 23144.021739130436,
      y: 0.0004425048828125,
    },
    {
      x: 23148.097826086956,
      y: -0.00952911376953125,
    },
    {
      x: 23152.17391304348,
      y: -0.024478912353515625,
    },
    {
      x: 23164.402173913044,
      y: -0.06932830810546875,
    },
    {
      x: 23168.478260869564,
      y: -0.07929611206054688,
    },
    {
      x: 23172.554347826088,
      y: -0.09424591064453125,
    },
    {
      x: 23176.630434782608,
      y: -0.10421371459960938,
    },
    {
      x: 23180.706521739132,
      y: -0.11916351318359375,
    },
    {
      x: 23184.782608695652,
      y: -0.13411712646484375,
    },
    {
      x: 23188.858695652176,
      y: -0.14408111572265625,
    },
    {
      x: 23192.934782608696,
      y: -0.15903091430664062,
    },
    {
      x: 23197.01086956522,
      y: -0.16899871826171875,
    },
    {
      x: 23201.08695652174,
      y: -0.17896652221679688,
    },
    {
      x: 23205.16304347826,
      y: -0.19391632080078125,
    },
    {
      x: 23209.239130434784,
      y: -0.20388412475585938,
    },
    {
      x: 23213.315217391304,
      y: -0.2138519287109375,
    },
    {
      x: 23217.391304347828,
      y: 0.09512710571289062,
    },
    {
      x: 23221.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 23343.75,
      y: 0.09512710571289062,
    },
    {
      x: 23347.826086956524,
      y: 0.1349945068359375,
    },
    {
      x: 23351.902173913044,
      y: 0.2346649169921875,
    },
    {
      x: 23355.978260869564,
      y: 0.35427093505859375,
    },
    {
      x: 23360.054347826088,
      y: 0.4838409423828125,
    },
    {
      x: 23364.130434782608,
      y: 0.6134147644042969,
    },
    {
      x: 23368.206521739132,
      y: 0.7479705810546875,
    },
    {
      x: 23372.282608695652,
      y: 0.8775367736816406,
    },
    {
      x: 23376.358695652176,
      y: 1.0021286010742188,
    },
    {
      x: 23380.434782608696,
      y: 1.1267127990722656,
    },
    {
      x: 23384.51086956522,
      y: 1.2513046264648438,
    },
    {
      x: 23388.58695652174,
      y: 1.3111076354980469,
    },
    {
      x: 23392.66304347826,
      y: 1.2463188171386719,
    },
    {
      x: 23396.739130434784,
      y: 1.1317024230957031,
    },
    {
      x: 23400.815217391304,
      y: 0.9971466064453125,
    },
    {
      x: 23404.891304347828,
      y: 0.85760498046875,
    },
    {
      x: 23408.967391304348,
      y: 0.7230491638183594,
    },
    {
      x: 23413.04347826087,
      y: 0.5835151672363281,
    },
    {
      x: 23417.119565217392,
      y: 0.4489555358886719,
    },
    {
      x: 23421.195652173912,
      y: 0.31439971923828125,
    },
    {
      x: 23425.271739130436,
      y: 0.17984771728515625,
    },
    {
      x: 23429.347826086956,
      y: 0.07020950317382812,
    },
    {
      x: 23433.42391304348,
      y: 0.03034210205078125,
    },
    {
      x: 23437.5,
      y: 0.020374298095703125,
    },
    {
      x: 23441.576086956524,
      y: 0.015392303466796875,
    },
    {
      x: 23445.652173913044,
      y: 0.015392303466796875,
    },
    {
      x: 23457.880434782608,
      y: 0.015392303466796875,
    },
    {
      x: 23461.956521739132,
      y: 0.020374298095703125,
    },
    {
      x: 23466.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 23474.184782608696,
      y: 0.020374298095703125,
    },
    {
      x: 23478.26086956522,
      y: 0.09512710571289062,
    },
    {
      x: 23482.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 23735.054347826088,
      y: 0.09512710571289062,
    },
    {
      x: 23739.130434782608,
      y: -0.15404891967773438,
    },
    {
      x: 23743.206521739132,
      y: -0.1640167236328125,
    },
    {
      x: 23747.282608695652,
      y: -0.17896652221679688,
    },
    {
      x: 23751.358695652176,
      y: -0.188934326171875,
    },
    {
      x: 23755.434782608696,
      y: -0.20388412475585938,
    },
    {
      x: 23759.51086956522,
      y: -0.2138519287109375,
    },
    {
      x: 23763.58695652174,
      y: -0.20388412475585938,
    },
    {
      x: 23767.66304347826,
      y: -0.188934326171875,
    },
    {
      x: 23771.739130434784,
      y: -0.17398452758789062,
    },
    {
      x: 23775.815217391304,
      y: -0.15404891967773438,
    },
    {
      x: 23779.891304347828,
      y: -0.13411712646484375,
    },
    {
      x: 23783.967391304348,
      y: -0.1141815185546875,
    },
    {
      x: 23788.04347826087,
      y: -0.09923171997070312,
    },
    {
      x: 23792.119565217392,
      y: -0.07929611206054688,
    },
    {
      x: 23796.195652173912,
      y: -0.05936431884765625,
    },
    {
      x: 23800.271739130436,
      y: -0.04441070556640625,
    },
    {
      x: 23804.347826086956,
      y: -0.024478912353515625,
    },
    {
      x: 23808.42391304348,
      y: -0.004543304443359375,
    },
    {
      x: 23812.5,
      y: 0.010406494140625,
    },
    {
      x: 23816.576086956524,
      y: 0.025356292724609375,
    },
    {
      x: 23820.652173913044,
      y: 0.045291900634765625,
    },
    {
      x: 23824.728260869564,
      y: 0.06024169921875,
    },
    {
      x: 23828.804347826088,
      y: 0.0751953125,
    },
    {
      x: 23832.880434782608,
      y: 0.09014511108398438,
    },
    {
      x: 23836.956521739132,
      y: 0.09512710571289062,
    },
    {
      x: 23841.032608695652,
      y: 0.09512710571289062,
    },
    {
      x: 24000,
      y: 0.09512710571289062,
    },
  ],
  v4: [
    {
      x: 0,
      y: 0.5785293579101562,
    },
    {
      x: 4.076086956521739,
      y: 0.45892333984375,
    },
    {
      x: 8.152173913043478,
      y: 0.3343391418457031,
    },
    {
      x: 12.228260869565219,
      y: 0.21473312377929688,
    },
    {
      x: 16.304347826086957,
      y: 0.12004470825195312,
    },
    {
      x: 20.380434782608695,
      y: 0.08017730712890625,
    },
    {
      x: 24.456521739130437,
      y: 0.07020950317382812,
    },
    {
      x: 28.532608695652176,
      y: 0.06522369384765625,
    },
    {
      x: 32.608695652173914,
      y: 0.06522369384765625,
    },
    {
      x: 57.06521739130435,
      y: 0.06522369384765625,
    },
    {
      x: 61.141304347826086,
      y: 0.07020950317382812,
    },
    {
      x: 65.21739130434783,
      y: 0.07020950317382812,
    },
    {
      x: 89.67391304347827,
      y: 0.07020950317382812,
    },
    {
      x: 93.75,
      y: 0.0751953125,
    },
    {
      x: 97.82608695652175,
      y: 0.0751953125,
    },
    {
      x: 122.28260869565217,
      y: 0.0751953125,
    },
    {
      x: 126.35869565217392,
      y: 0.08017730712890625,
    },
    {
      x: 130.43478260869566,
      y: 0.08017730712890625,
    },
    {
      x: 154.8913043478261,
      y: 0.08017730712890625,
    },
    {
      x: 158.96739130434784,
      y: 0.0851593017578125,
    },
    {
      x: 163.04347826086956,
      y: 0.0851593017578125,
    },
    {
      x: 187.5,
      y: 0.0851593017578125,
    },
    {
      x: 191.57608695652175,
      y: 0.09014511108398438,
    },
    {
      x: 195.6521739130435,
      y: 0.09014511108398438,
    },
    {
      x: 220.1086956521739,
      y: 0.09014511108398438,
    },
    {
      x: 224.18478260869566,
      y: 0.09512710571289062,
    },
    {
      x: 228.2608695652174,
      y: 0.09512710571289062,
    },
    {
      x: 236.41304347826087,
      y: 0.09512710571289062,
    },
    {
      x: 240.48913043478262,
      y: 0.0851593017578125,
    },
    {
      x: 244.56521739130434,
      y: 0.05525970458984375,
    },
    {
      x: 248.6413043478261,
      y: 0.020374298095703125,
    },
    {
      x: 252.71739130434784,
      y: -0.004543304443359375,
    },
    {
      x: 256.79347826086956,
      y: -0.01949310302734375,
    },
    {
      x: 260.8695652173913,
      y: 0.09512710571289062,
    },
    {
      x: 264.94565217391306,
      y: 0.09512710571289062,
    },
    {
      x: 427.9891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 432.0652173913044,
      y: 0.13001251220703125,
    },
    {
      x: 436.1413043478261,
      y: 0.22469711303710938,
    },
    {
      x: 440.2173913043478,
      y: 0.3393211364746094,
    },
    {
      x: 444.29347826086956,
      y: 0.45892333984375,
    },
    {
      x: 448.3695652173913,
      y: 0.5785293579101562,
    },
    {
      x: 452.44565217391306,
      y: 0.7031173706054688,
    },
    {
      x: 456.5217391304348,
      y: 0.822723388671875,
    },
    {
      x: 460.59782608695656,
      y: 0.9423255920410156,
    },
    {
      x: 464.67391304347825,
      y: 1.0569496154785156,
    },
    {
      x: 468.75,
      y: 1.1765518188476562,
    },
    {
      x: 472.82608695652175,
      y: 1.2313690185546875,
    },
    {
      x: 476.9021739130435,
      y: 1.1765518188476562,
    },
    {
      x: 480.97826086956525,
      y: 1.0718994140625,
    },
    {
      x: 485.054347826087,
      y: 0.9522895812988281,
    },
    {
      x: 489.1304347826087,
      y: 0.8277053833007812,
    },
    {
      x: 493.20652173913044,
      y: 0.7031173706054688,
    },
    {
      x: 497.2826086956522,
      y: 0.5785293579101562,
    },
    {
      x: 501.35869565217394,
      y: 0.45892333984375,
    },
    {
      x: 505.4347826086957,
      y: 0.3343391418457031,
    },
    {
      x: 509.51086956521743,
      y: 0.21473312377929688,
    },
    {
      x: 513.5869565217391,
      y: 0.12004470825195312,
    },
    {
      x: 517.6630434782609,
      y: 0.08017730712890625,
    },
    {
      x: 521.7391304347826,
      y: -0.034442901611328125,
    },
    {
      x: 525.8152173913044,
      y: -0.049396514892578125,
    },
    {
      x: 529.8913043478261,
      y: -0.05936431884765625,
    },
    {
      x: 533.9673913043479,
      y: -0.074310302734375,
    },
    {
      x: 538.0434782608696,
      y: -0.08427810668945312,
    },
    {
      x: 542.1195652173914,
      y: -0.09424591064453125,
    },
    {
      x: 546.195652173913,
      y: -0.10919570922851562,
    },
    {
      x: 550.2717391304348,
      y: -0.11916351318359375,
    },
    {
      x: 554.3478260869565,
      y: -0.12913131713867188,
    },
    {
      x: 558.4239130434783,
      y: -0.14408111572265625,
    },
    {
      x: 562.5,
      y: -0.15404891967773438,
    },
    {
      x: 570.6521739130435,
      y: -0.17398452758789062,
    },
    {
      x: 574.7282608695652,
      y: -0.18394851684570312,
    },
    {
      x: 578.804347826087,
      y: -0.1988983154296875,
    },
    {
      x: 582.8804347826087,
      y: -0.20886993408203125,
    },
    {
      x: 586.9565217391305,
      y: -0.1988983154296875,
    },
    {
      x: 591.0326086956522,
      y: -0.18394851684570312,
    },
    {
      x: 595.1086956521739,
      y: -0.16899871826171875,
    },
    {
      x: 599.1847826086956,
      y: -0.14906692504882812,
    },
    {
      x: 603.2608695652174,
      y: -0.13411712646484375,
    },
    {
      x: 607.3369565217391,
      y: -0.1141815185546875,
    },
    {
      x: 611.4130434782609,
      y: -0.09923171997070312,
    },
    {
      x: 615.4891304347826,
      y: -0.07929611206054688,
    },
    {
      x: 619.5652173913044,
      y: -0.0643463134765625,
    },
    {
      x: 623.6413043478261,
      y: -0.049396514892578125,
    },
    {
      x: 627.7173913043479,
      y: -0.029460906982421875,
    },
    {
      x: 631.7934782608696,
      y: -0.0145111083984375,
    },
    {
      x: 635.8695652173914,
      y: 0.0004425048828125,
    },
    {
      x: 639.9456521739131,
      y: 0.015392303466796875,
    },
    {
      x: 644.0217391304348,
      y: 0.0353240966796875,
    },
    {
      x: 648.0978260869565,
      y: 0.050273895263671875,
    },
    {
      x: 652.1739130434783,
      y: 0.06522369384765625,
    },
    {
      x: 656.25,
      y: 0.0751953125,
    },
    {
      x: 660.3260869565217,
      y: 0.08017730712890625,
    },
    {
      x: 664.4021739130435,
      y: 0.08017730712890625,
    },
    {
      x: 668.4782608695652,
      y: 0.0851593017578125,
    },
    {
      x: 672.554347826087,
      y: 0.0851593017578125,
    },
    {
      x: 697.0108695652174,
      y: 0.0851593017578125,
    },
    {
      x: 701.0869565217391,
      y: 0.09014511108398438,
    },
    {
      x: 705.1630434782609,
      y: 0.09014511108398438,
    },
    {
      x: 729.6195652173914,
      y: 0.09014511108398438,
    },
    {
      x: 733.6956521739131,
      y: 0.09512710571289062,
    },
    {
      x: 737.7717391304348,
      y: 0.09512710571289062,
    },
    {
      x: 1235.054347826087,
      y: 0.09512710571289062,
    },
    {
      x: 1239.1304347826087,
      y: 0.13001251220703125,
    },
    {
      x: 1243.2065217391305,
      y: 0.22469711303710938,
    },
    {
      x: 1247.2826086956522,
      y: 0.3393211364746094,
    },
    {
      x: 1251.358695652174,
      y: 0.45892333984375,
    },
    {
      x: 1255.4347826086957,
      y: 0.5785293579101562,
    },
    {
      x: 1259.5108695652175,
      y: 0.7031173706054688,
    },
    {
      x: 1263.5869565217392,
      y: 0.822723388671875,
    },
    {
      x: 1267.663043478261,
      y: 0.9423255920410156,
    },
    {
      x: 1271.7391304347827,
      y: 1.0569496154785156,
    },
    {
      x: 1275.8152173913045,
      y: 1.1765518188476562,
    },
    {
      x: 1279.8913043478262,
      y: 1.2313690185546875,
    },
    {
      x: 1283.9673913043478,
      y: 1.1765518188476562,
    },
    {
      x: 1288.0434782608695,
      y: 1.0718994140625,
    },
    {
      x: 1292.1195652173913,
      y: 0.9522895812988281,
    },
    {
      x: 1296.195652173913,
      y: 0.8277053833007812,
    },
    {
      x: 1300.2717391304348,
      y: 0.7031173706054688,
    },
    {
      x: 1304.3478260869565,
      y: 0.09512710571289062,
    },
    {
      x: 1308.4239130434783,
      y: 0.09512710571289062,
    },
    {
      x: 1565.217391304348,
      y: 0.09512710571289062,
    },
    {
      x: 1569.2934782608695,
      y: 0.0851593017578125,
    },
    {
      x: 1573.3695652173913,
      y: 0.05525970458984375,
    },
    {
      x: 1577.445652173913,
      y: 0.020374298095703125,
    },
    {
      x: 1581.5217391304348,
      y: -0.004543304443359375,
    },
    {
      x: 1585.5978260869565,
      y: -0.01949310302734375,
    },
    {
      x: 1589.6739130434783,
      y: -0.034442901611328125,
    },
    {
      x: 1593.75,
      y: -0.049396514892578125,
    },
    {
      x: 1597.8260869565217,
      y: -0.05936431884765625,
    },
    {
      x: 1601.9021739130435,
      y: -0.074310302734375,
    },
    {
      x: 1605.9782608695652,
      y: -0.08427810668945312,
    },
    {
      x: 1610.054347826087,
      y: -0.09424591064453125,
    },
    {
      x: 1614.1304347826087,
      y: -0.10919570922851562,
    },
    {
      x: 1618.2065217391305,
      y: -0.11916351318359375,
    },
    {
      x: 1622.2826086956522,
      y: -0.12913131713867188,
    },
    {
      x: 1626.358695652174,
      y: -0.14408111572265625,
    },
    {
      x: 1630.4347826086957,
      y: -0.15404891967773438,
    },
    {
      x: 1638.5869565217392,
      y: -0.17398452758789062,
    },
    {
      x: 1642.663043478261,
      y: -0.18394851684570312,
    },
    {
      x: 1646.7391304347827,
      y: -0.1988983154296875,
    },
    {
      x: 1650.8152173913045,
      y: -0.20886993408203125,
    },
    {
      x: 1654.8913043478262,
      y: -0.1988983154296875,
    },
    {
      x: 1658.967391304348,
      y: -0.18394851684570312,
    },
    {
      x: 1663.0434782608695,
      y: -0.16899871826171875,
    },
    {
      x: 1667.1195652173913,
      y: -0.14906692504882812,
    },
    {
      x: 1671.195652173913,
      y: -0.13411712646484375,
    },
    {
      x: 1675.2717391304348,
      y: -0.1141815185546875,
    },
    {
      x: 1679.3478260869565,
      y: -0.09923171997070312,
    },
    {
      x: 1683.4239130434783,
      y: -0.07929611206054688,
    },
    {
      x: 1687.5,
      y: -0.0643463134765625,
    },
    {
      x: 1691.5760869565217,
      y: -0.049396514892578125,
    },
    {
      x: 1695.6521739130435,
      y: -0.029460906982421875,
    },
    {
      x: 1699.7282608695652,
      y: -0.0145111083984375,
    },
    {
      x: 1703.804347826087,
      y: 0.0004425048828125,
    },
    {
      x: 1707.8804347826087,
      y: 0.015392303466796875,
    },
    {
      x: 1711.9565217391305,
      y: 0.0353240966796875,
    },
    {
      x: 1716.0326086956522,
      y: 0.050273895263671875,
    },
    {
      x: 1720.108695652174,
      y: 0.06522369384765625,
    },
    {
      x: 1724.1847826086957,
      y: 0.0751953125,
    },
    {
      x: 1728.2608695652175,
      y: 0.08017730712890625,
    },
    {
      x: 1732.3369565217392,
      y: 0.08017730712890625,
    },
    {
      x: 1736.413043478261,
      y: 0.0851593017578125,
    },
    {
      x: 1740.4891304347827,
      y: 0.0851593017578125,
    },
    {
      x: 1764.945652173913,
      y: 0.0851593017578125,
    },
    {
      x: 1769.0217391304348,
      y: 0.09014511108398438,
    },
    {
      x: 1773.0978260869565,
      y: 0.09014511108398438,
    },
    {
      x: 1797.554347826087,
      y: 0.09014511108398438,
    },
    {
      x: 1801.6304347826087,
      y: 0.09512710571289062,
    },
    {
      x: 1805.7065217391305,
      y: 0.09512710571289062,
    },
    {
      x: 1822.0108695652175,
      y: 0.09512710571289062,
    },
    {
      x: 1826.0869565217392,
      y: 1.2313690185546875,
    },
    {
      x: 1830.163043478261,
      y: 1.1765518188476562,
    },
    {
      x: 1834.2391304347827,
      y: 1.0718994140625,
    },
    {
      x: 1838.3152173913045,
      y: 0.9522895812988281,
    },
    {
      x: 1842.3913043478262,
      y: 0.8277053833007812,
    },
    {
      x: 1846.467391304348,
      y: 0.7031173706054688,
    },
    {
      x: 1850.5434782608695,
      y: 0.5785293579101562,
    },
    {
      x: 1854.6195652173913,
      y: 0.45892333984375,
    },
    {
      x: 1858.695652173913,
      y: 0.3343391418457031,
    },
    {
      x: 1862.7717391304348,
      y: 0.21473312377929688,
    },
    {
      x: 1866.8478260869565,
      y: 0.12004470825195312,
    },
    {
      x: 1870.9239130434783,
      y: 0.08017730712890625,
    },
    {
      x: 1875,
      y: 0.07020950317382812,
    },
    {
      x: 1879.0760869565217,
      y: 0.06522369384765625,
    },
    {
      x: 1883.1521739130435,
      y: 0.06522369384765625,
    },
    {
      x: 1907.608695652174,
      y: 0.06522369384765625,
    },
    {
      x: 1911.6847826086957,
      y: 0.07020950317382812,
    },
    {
      x: 1915.7608695652175,
      y: 0.07020950317382812,
    },
    {
      x: 1940.217391304348,
      y: 0.07020950317382812,
    },
    {
      x: 1944.2934782608697,
      y: 0.0751953125,
    },
    {
      x: 1948.3695652173913,
      y: 0.0751953125,
    },
    {
      x: 1972.8260869565217,
      y: 0.0751953125,
    },
    {
      x: 1976.9021739130435,
      y: 0.08017730712890625,
    },
    {
      x: 1980.9782608695652,
      y: 0.08017730712890625,
    },
    {
      x: 2005.4347826086957,
      y: 0.08017730712890625,
    },
    {
      x: 2009.5108695652175,
      y: 0.0851593017578125,
    },
    {
      x: 2013.5869565217392,
      y: 0.0851593017578125,
    },
    {
      x: 2038.0434782608697,
      y: 0.0851593017578125,
    },
    {
      x: 2042.1195652173913,
      y: 0.09014511108398438,
    },
    {
      x: 2046.195652173913,
      y: 0.09014511108398438,
    },
    {
      x: 2070.6521739130435,
      y: 0.09014511108398438,
    },
    {
      x: 2074.7282608695655,
      y: 0.09512710571289062,
    },
    {
      x: 2078.804347826087,
      y: 0.09512710571289062,
    },
    {
      x: 2302.9891304347825,
      y: 0.09512710571289062,
    },
    {
      x: 2307.0652173913045,
      y: 0.13001251220703125,
    },
    {
      x: 2311.141304347826,
      y: 0.22469711303710938,
    },
    {
      x: 2315.217391304348,
      y: 0.3393211364746094,
    },
    {
      x: 2319.2934782608695,
      y: 0.45892333984375,
    },
    {
      x: 2323.3695652173915,
      y: 0.5785293579101562,
    },
    {
      x: 2327.445652173913,
      y: 0.7031173706054688,
    },
    {
      x: 2331.521739130435,
      y: 0.822723388671875,
    },
    {
      x: 2335.5978260869565,
      y: 0.9423255920410156,
    },
    {
      x: 2339.6739130434785,
      y: 1.0569496154785156,
    },
    {
      x: 2343.75,
      y: 1.1765518188476562,
    },
    {
      x: 2347.826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 2351.9021739130435,
      y: 0.09512710571289062,
    },
    {
      x: 2604.6195652173915,
      y: 0.09512710571289062,
    },
    {
      x: 2608.695652173913,
      y: 0.09014511108398438,
    },
    {
      x: 2612.771739130435,
      y: 0.09014511108398438,
    },
    {
      x: 2616.8478260869565,
      y: 0.09014511108398438,
    },
    {
      x: 2620.9239130434785,
      y: 0.09512710571289062,
    },
    {
      x: 2625,
      y: 0.09512710571289062,
    },
    {
      x: 2633.1521739130435,
      y: 0.09512710571289062,
    },
    {
      x: 2637.2282608695655,
      y: 0.0851593017578125,
    },
    {
      x: 2641.304347826087,
      y: 0.05525970458984375,
    },
    {
      x: 2645.380434782609,
      y: 0.020374298095703125,
    },
    {
      x: 2649.4565217391305,
      y: -0.004543304443359375,
    },
    {
      x: 2653.5326086956525,
      y: -0.01949310302734375,
    },
    {
      x: 2657.608695652174,
      y: -0.034442901611328125,
    },
    {
      x: 2661.6847826086955,
      y: -0.049396514892578125,
    },
    {
      x: 2665.7608695652175,
      y: -0.05936431884765625,
    },
    {
      x: 2669.836956521739,
      y: -0.074310302734375,
    },
    {
      x: 2673.913043478261,
      y: -0.08427810668945312,
    },
    {
      x: 2677.9891304347825,
      y: -0.09424591064453125,
    },
    {
      x: 2682.0652173913045,
      y: -0.10919570922851562,
    },
    {
      x: 2686.141304347826,
      y: -0.11916351318359375,
    },
    {
      x: 2690.217391304348,
      y: -0.12913131713867188,
    },
    {
      x: 2694.2934782608695,
      y: -0.14408111572265625,
    },
    {
      x: 2698.3695652173915,
      y: -0.15404891967773438,
    },
    {
      x: 2706.521739130435,
      y: -0.17398452758789062,
    },
    {
      x: 2710.5978260869565,
      y: -0.18394851684570312,
    },
    {
      x: 2714.6739130434785,
      y: -0.1988983154296875,
    },
    {
      x: 2718.75,
      y: -0.20388412475585938,
    },
    {
      x: 2722.826086956522,
      y: -0.1988983154296875,
    },
    {
      x: 2726.9021739130435,
      y: -0.18394851684570312,
    },
    {
      x: 2730.9782608695655,
      y: -0.16899871826171875,
    },
    {
      x: 2735.054347826087,
      y: -0.14906692504882812,
    },
    {
      x: 2739.130434782609,
      y: -0.13411712646484375,
    },
    {
      x: 2743.2065217391305,
      y: -0.1141815185546875,
    },
    {
      x: 2747.2826086956525,
      y: -0.09923171997070312,
    },
    {
      x: 2751.358695652174,
      y: -0.07929611206054688,
    },
    {
      x: 2755.4347826086955,
      y: -0.0643463134765625,
    },
    {
      x: 2759.5108695652175,
      y: -0.049396514892578125,
    },
    {
      x: 2763.586956521739,
      y: -0.029460906982421875,
    },
    {
      x: 2767.663043478261,
      y: -0.0145111083984375,
    },
    {
      x: 2771.7391304347825,
      y: 0.0004425048828125,
    },
    {
      x: 2775.8152173913045,
      y: 0.015392303466796875,
    },
    {
      x: 2779.891304347826,
      y: 0.0353240966796875,
    },
    {
      x: 2783.967391304348,
      y: 0.050273895263671875,
    },
    {
      x: 2788.0434782608695,
      y: 0.06522369384765625,
    },
    {
      x: 2792.1195652173915,
      y: 0.0751953125,
    },
    {
      x: 2796.195652173913,
      y: 0.08017730712890625,
    },
    {
      x: 2800.271739130435,
      y: 0.08017730712890625,
    },
    {
      x: 2804.3478260869565,
      y: 0.0851593017578125,
    },
    {
      x: 2808.4239130434785,
      y: 0.0851593017578125,
    },
    {
      x: 2832.880434782609,
      y: 0.0851593017578125,
    },
    {
      x: 2836.9565217391305,
      y: 0.09014511108398438,
    },
    {
      x: 2841.0326086956525,
      y: 0.09014511108398438,
    },
    {
      x: 2865.4891304347825,
      y: 0.09014511108398438,
    },
    {
      x: 2869.5652173913045,
      y: 0.5785293579101562,
    },
    {
      x: 2873.641304347826,
      y: 0.7031173706054688,
    },
    {
      x: 2877.717391304348,
      y: 0.822723388671875,
    },
    {
      x: 2881.7934782608695,
      y: 0.9423255920410156,
    },
    {
      x: 2885.8695652173915,
      y: 1.0569496154785156,
    },
    {
      x: 2889.945652173913,
      y: 1.1765518188476562,
    },
    {
      x: 2894.021739130435,
      y: 1.2313690185546875,
    },
    {
      x: 2898.0978260869565,
      y: 1.1765518188476562,
    },
    {
      x: 2902.1739130434785,
      y: 1.0718994140625,
    },
    {
      x: 2906.25,
      y: 0.9522895812988281,
    },
    {
      x: 2910.326086956522,
      y: 0.8277053833007812,
    },
    {
      x: 2914.4021739130435,
      y: 0.7031173706054688,
    },
    {
      x: 2918.4782608695655,
      y: 0.5785293579101562,
    },
    {
      x: 2922.554347826087,
      y: 0.45892333984375,
    },
    {
      x: 2926.630434782609,
      y: 0.3343391418457031,
    },
    {
      x: 2930.7065217391305,
      y: 0.21473312377929688,
    },
    {
      x: 2934.7826086956525,
      y: 0.12004470825195312,
    },
    {
      x: 2938.858695652174,
      y: 0.08017730712890625,
    },
    {
      x: 2942.9347826086955,
      y: 0.07020950317382812,
    },
    {
      x: 2947.0108695652175,
      y: 0.06522369384765625,
    },
    {
      x: 2951.086956521739,
      y: 0.06522369384765625,
    },
    {
      x: 2975.5434782608695,
      y: 0.06522369384765625,
    },
    {
      x: 2979.6195652173915,
      y: 0.07020950317382812,
    },
    {
      x: 2983.695652173913,
      y: 0.07020950317382812,
    },
    {
      x: 3008.1521739130435,
      y: 0.07020950317382812,
    },
    {
      x: 3012.2282608695655,
      y: 0.0751953125,
    },
    {
      x: 3016.304347826087,
      y: 0.0751953125,
    },
    {
      x: 3040.7608695652175,
      y: 0.0751953125,
    },
    {
      x: 3044.836956521739,
      y: 0.08017730712890625,
    },
    {
      x: 3048.913043478261,
      y: 0.08017730712890625,
    },
    {
      x: 3073.3695652173915,
      y: 0.08017730712890625,
    },
    {
      x: 3077.445652173913,
      y: 0.0851593017578125,
    },
    {
      x: 3081.521739130435,
      y: 0.0851593017578125,
    },
    {
      x: 3105.9782608695655,
      y: 0.0851593017578125,
    },
    {
      x: 3110.054347826087,
      y: 0.09014511108398438,
    },
    {
      x: 3114.130434782609,
      y: 0.09014511108398438,
    },
    {
      x: 3126.358695652174,
      y: 0.09014511108398438,
    },
    {
      x: 3130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 3134.5108695652175,
      y: 0.09512710571289062,
    },
    {
      x: 3370.9239130434785,
      y: 0.09512710571289062,
    },
    {
      x: 3375,
      y: 0.13001251220703125,
    },
    {
      x: 3379.076086956522,
      y: 0.22469711303710938,
    },
    {
      x: 3383.1521739130435,
      y: 0.3393211364746094,
    },
    {
      x: 3387.2282608695655,
      y: 0.45892333984375,
    },
    {
      x: 3391.304347826087,
      y: 0.09014511108398438,
    },
    {
      x: 3395.380434782609,
      y: 0.09014511108398438,
    },
    {
      x: 3411.684782608696,
      y: 0.09014511108398438,
    },
    {
      x: 3415.7608695652175,
      y: 0.09512710571289062,
    },
    {
      x: 3419.836956521739,
      y: 0.09512710571289062,
    },
    {
      x: 3648.0978260869565,
      y: 0.09512710571289062,
    },
    {
      x: 3652.1739130434785,
      y: 0.0851593017578125,
    },
    {
      x: 3656.25,
      y: 0.09014511108398438,
    },
    {
      x: 3660.326086956522,
      y: 0.09014511108398438,
    },
    {
      x: 3684.7826086956525,
      y: 0.09014511108398438,
    },
    {
      x: 3688.858695652174,
      y: 0.09512710571289062,
    },
    {
      x: 3692.934782608696,
      y: 0.09512710571289062,
    },
    {
      x: 3701.086956521739,
      y: 0.09512710571289062,
    },
    {
      x: 3705.163043478261,
      y: 0.0851593017578125,
    },
    {
      x: 3709.2391304347825,
      y: 0.05525970458984375,
    },
    {
      x: 3713.3152173913045,
      y: 0.020374298095703125,
    },
    {
      x: 3717.391304347826,
      y: -0.004543304443359375,
    },
    {
      x: 3721.467391304348,
      y: -0.01949310302734375,
    },
    {
      x: 3725.5434782608695,
      y: -0.034442901611328125,
    },
    {
      x: 3729.6195652173915,
      y: -0.049396514892578125,
    },
    {
      x: 3733.695652173913,
      y: -0.05936431884765625,
    },
    {
      x: 3737.771739130435,
      y: -0.074310302734375,
    },
    {
      x: 3741.8478260869565,
      y: -0.08427810668945312,
    },
    {
      x: 3745.9239130434785,
      y: -0.09424591064453125,
    },
    {
      x: 3750,
      y: -0.10919570922851562,
    },
    {
      x: 3754.076086956522,
      y: -0.11916351318359375,
    },
    {
      x: 3758.1521739130435,
      y: -0.12913131713867188,
    },
    {
      x: 3762.2282608695655,
      y: -0.14408111572265625,
    },
    {
      x: 3766.304347826087,
      y: -0.15404891967773438,
    },
    {
      x: 3774.4565217391305,
      y: -0.17398452758789062,
    },
    {
      x: 3778.5326086956525,
      y: -0.18394851684570312,
    },
    {
      x: 3782.608695652174,
      y: -0.1988983154296875,
    },
    {
      x: 3786.684782608696,
      y: -0.20886993408203125,
    },
    {
      x: 3790.7608695652175,
      y: -0.1988983154296875,
    },
    {
      x: 3794.836956521739,
      y: -0.18394851684570312,
    },
    {
      x: 3798.913043478261,
      y: -0.16899871826171875,
    },
    {
      x: 3802.9891304347825,
      y: -0.14906692504882812,
    },
    {
      x: 3807.0652173913045,
      y: -0.13411712646484375,
    },
    {
      x: 3811.141304347826,
      y: -0.1141815185546875,
    },
    {
      x: 3815.217391304348,
      y: -0.09923171997070312,
    },
    {
      x: 3819.2934782608695,
      y: -0.07929611206054688,
    },
    {
      x: 3823.3695652173915,
      y: -0.0643463134765625,
    },
    {
      x: 3827.445652173913,
      y: -0.049396514892578125,
    },
    {
      x: 3831.521739130435,
      y: -0.029460906982421875,
    },
    {
      x: 3835.5978260869565,
      y: -0.0145111083984375,
    },
    {
      x: 3839.6739130434785,
      y: 0.0004425048828125,
    },
    {
      x: 3843.75,
      y: 0.015392303466796875,
    },
    {
      x: 3847.826086956522,
      y: 0.0353240966796875,
    },
    {
      x: 3851.9021739130435,
      y: 0.050273895263671875,
    },
    {
      x: 3855.9782608695655,
      y: 0.06522369384765625,
    },
    {
      x: 3860.054347826087,
      y: 0.0751953125,
    },
    {
      x: 3864.130434782609,
      y: 0.08017730712890625,
    },
    {
      x: 3868.2065217391305,
      y: 0.08017730712890625,
    },
    {
      x: 3872.2826086956525,
      y: 0.0851593017578125,
    },
    {
      x: 3876.358695652174,
      y: 0.0851593017578125,
    },
    {
      x: 3900.8152173913045,
      y: 0.0851593017578125,
    },
    {
      x: 3904.891304347826,
      y: 0.09014511108398438,
    },
    {
      x: 3908.967391304348,
      y: 0.09014511108398438,
    },
    {
      x: 3913.0434782608695,
      y: 0.09512710571289062,
    },
    {
      x: 3917.1195652173915,
      y: 0.09512710571289062,
    },
    {
      x: 3921.195652173913,
      y: 0.13001251220703125,
    },
    {
      x: 3925.271739130435,
      y: 0.22469711303710938,
    },
    {
      x: 3929.3478260869565,
      y: 0.3393211364746094,
    },
    {
      x: 3933.4239130434785,
      y: 0.45892333984375,
    },
    {
      x: 3937.5,
      y: 0.5785293579101562,
    },
    {
      x: 3941.576086956522,
      y: 0.7031173706054688,
    },
    {
      x: 3945.6521739130435,
      y: 0.822723388671875,
    },
    {
      x: 3949.7282608695655,
      y: 0.9423255920410156,
    },
    {
      x: 3953.804347826087,
      y: 1.0569496154785156,
    },
    {
      x: 3957.880434782609,
      y: 1.1765518188476562,
    },
    {
      x: 3961.9565217391305,
      y: 1.2313690185546875,
    },
    {
      x: 3966.0326086956525,
      y: 1.1765518188476562,
    },
    {
      x: 3970.108695652174,
      y: 1.0718994140625,
    },
    {
      x: 3974.184782608696,
      y: 0.9522895812988281,
    },
    {
      x: 3978.2608695652175,
      y: 0.8277053833007812,
    },
    {
      x: 3982.3369565217395,
      y: 0.7031173706054688,
    },
    {
      x: 3986.413043478261,
      y: 0.5785293579101562,
    },
    {
      x: 3990.4891304347825,
      y: 0.45892333984375,
    },
    {
      x: 3994.5652173913045,
      y: 0.3343391418457031,
    },
    {
      x: 3998.641304347826,
      y: 0.21473312377929688,
    },
    {
      x: 4002.717391304348,
      y: 0.12004470825195312,
    },
    {
      x: 4006.7934782608695,
      y: 0.08017730712890625,
    },
    {
      x: 4010.8695652173915,
      y: 0.07020950317382812,
    },
    {
      x: 4014.945652173913,
      y: 0.06522369384765625,
    },
    {
      x: 4019.021739130435,
      y: 0.06522369384765625,
    },
    {
      x: 4043.4782608695655,
      y: 0.06522369384765625,
    },
    {
      x: 4047.554347826087,
      y: 0.07020950317382812,
    },
    {
      x: 4051.630434782609,
      y: 0.07020950317382812,
    },
    {
      x: 4076.0869565217395,
      y: 0.07020950317382812,
    },
    {
      x: 4080.163043478261,
      y: 0.0751953125,
    },
    {
      x: 4084.2391304347825,
      y: 0.0751953125,
    },
    {
      x: 4108.695652173913,
      y: 0.0751953125,
    },
    {
      x: 4112.771739130435,
      y: 0.08017730712890625,
    },
    {
      x: 4116.847826086957,
      y: 0.08017730712890625,
    },
    {
      x: 4141.304347826087,
      y: 0.08017730712890625,
    },
    {
      x: 4145.380434782609,
      y: 0.0851593017578125,
    },
    {
      x: 4149.456521739131,
      y: 0.0851593017578125,
    },
    {
      x: 4169.836956521739,
      y: 0.0851593017578125,
    },
    {
      x: 4173.913043478261,
      y: 0.09512710571289062,
    },
    {
      x: 4177.989130434783,
      y: 0.09512710571289062,
    },
    {
      x: 4430.706521739131,
      y: 0.09512710571289062,
    },
    {
      x: 4434.782608695652,
      y: 0.0851593017578125,
    },
    {
      x: 4438.858695652174,
      y: 0.0851593017578125,
    },
    {
      x: 4447.010869565218,
      y: 0.0851593017578125,
    },
    {
      x: 4451.086956521739,
      y: 0.09014511108398438,
    },
    {
      x: 4455.163043478261,
      y: 0.09014511108398438,
    },
    {
      x: 4479.619565217391,
      y: 0.09014511108398438,
    },
    {
      x: 4483.695652173913,
      y: 0.09512710571289062,
    },
    {
      x: 4487.771739130435,
      y: 0.09512710571289062,
    },
    {
      x: 4691.576086956522,
      y: 0.09512710571289062,
    },
    {
      x: 4695.652173913044,
      y: 0.0851593017578125,
    },
    {
      x: 4699.728260869565,
      y: 0.0851593017578125,
    },
    {
      x: 4720.108695652174,
      y: 0.0851593017578125,
    },
    {
      x: 4724.184782608696,
      y: 0.09014511108398438,
    },
    {
      x: 4728.260869565218,
      y: 0.09014511108398438,
    },
    {
      x: 4752.717391304348,
      y: 0.09014511108398438,
    },
    {
      x: 4756.79347826087,
      y: 0.09512710571289062,
    },
    {
      x: 4760.869565217391,
      y: 0.09512710571289062,
    },
    {
      x: 4769.021739130435,
      y: 0.09512710571289062,
    },
    {
      x: 4773.097826086957,
      y: 0.0851593017578125,
    },
    {
      x: 4777.173913043478,
      y: 0.05525970458984375,
    },
    {
      x: 4781.25,
      y: 0.020374298095703125,
    },
    {
      x: 4785.326086956522,
      y: -0.004543304443359375,
    },
    {
      x: 4789.402173913044,
      y: -0.01949310302734375,
    },
    {
      x: 4793.478260869565,
      y: -0.034442901611328125,
    },
    {
      x: 4797.554347826087,
      y: -0.049396514892578125,
    },
    {
      x: 4801.630434782609,
      y: -0.05936431884765625,
    },
    {
      x: 4805.706521739131,
      y: -0.074310302734375,
    },
    {
      x: 4809.782608695652,
      y: -0.08427810668945312,
    },
    {
      x: 4813.858695652174,
      y: -0.09424591064453125,
    },
    {
      x: 4817.934782608696,
      y: -0.10919570922851562,
    },
    {
      x: 4822.010869565218,
      y: -0.11916351318359375,
    },
    {
      x: 4826.086956521739,
      y: -0.12913131713867188,
    },
    {
      x: 4830.163043478261,
      y: -0.14408111572265625,
    },
    {
      x: 4834.239130434783,
      y: -0.15404891967773438,
    },
    {
      x: 4842.391304347826,
      y: -0.17398452758789062,
    },
    {
      x: 4846.467391304348,
      y: -0.18394851684570312,
    },
    {
      x: 4850.54347826087,
      y: -0.1988983154296875,
    },
    {
      x: 4854.619565217391,
      y: -0.20886993408203125,
    },
    {
      x: 4858.695652173913,
      y: -0.1988983154296875,
    },
    {
      x: 4862.771739130435,
      y: -0.18394851684570312,
    },
    {
      x: 4866.847826086957,
      y: -0.16899871826171875,
    },
    {
      x: 4870.923913043478,
      y: -0.14906692504882812,
    },
    {
      x: 4875,
      y: -0.13411712646484375,
    },
    {
      x: 4879.076086956522,
      y: -0.1141815185546875,
    },
    {
      x: 4883.152173913044,
      y: -0.09923171997070312,
    },
    {
      x: 4887.228260869565,
      y: -0.07929611206054688,
    },
    {
      x: 4891.304347826087,
      y: -0.0643463134765625,
    },
    {
      x: 4895.380434782609,
      y: -0.049396514892578125,
    },
    {
      x: 4899.456521739131,
      y: -0.029460906982421875,
    },
    {
      x: 4903.532608695652,
      y: -0.0145111083984375,
    },
    {
      x: 4907.608695652174,
      y: 0.0004425048828125,
    },
    {
      x: 4911.684782608696,
      y: 0.015392303466796875,
    },
    {
      x: 4915.760869565218,
      y: 0.0353240966796875,
    },
    {
      x: 4919.836956521739,
      y: 0.050273895263671875,
    },
    {
      x: 4923.913043478261,
      y: 0.06522369384765625,
    },
    {
      x: 4927.989130434783,
      y: 0.0751953125,
    },
    {
      x: 4932.065217391305,
      y: 0.08017730712890625,
    },
    {
      x: 4936.141304347826,
      y: 0.08017730712890625,
    },
    {
      x: 4940.217391304348,
      y: 0.0851593017578125,
    },
    {
      x: 4944.29347826087,
      y: 0.0851593017578125,
    },
    {
      x: 4952.445652173913,
      y: 0.0851593017578125,
    },
    {
      x: 4956.521739130435,
      y: 0.09512710571289062,
    },
    {
      x: 4960.597826086957,
      y: 0.09512710571289062,
    },
    {
      x: 4985.054347826087,
      y: 0.09512710571289062,
    },
    {
      x: 4989.130434782609,
      y: 0.13001251220703125,
    },
    {
      x: 4993.206521739131,
      y: 0.22469711303710938,
    },
    {
      x: 4997.282608695652,
      y: 0.3393211364746094,
    },
    {
      x: 5001.358695652174,
      y: 0.45892333984375,
    },
    {
      x: 5005.434782608696,
      y: 0.5785293579101562,
    },
    {
      x: 5009.510869565218,
      y: 0.7031173706054688,
    },
    {
      x: 5013.586956521739,
      y: 0.822723388671875,
    },
    {
      x: 5017.663043478261,
      y: 0.9423255920410156,
    },
    {
      x: 5021.739130434783,
      y: 1.0569496154785156,
    },
    {
      x: 5025.815217391305,
      y: 1.1765518188476562,
    },
    {
      x: 5029.891304347826,
      y: 1.2313690185546875,
    },
    {
      x: 5033.967391304348,
      y: 1.1765518188476562,
    },
    {
      x: 5038.04347826087,
      y: 1.0718994140625,
    },
    {
      x: 5042.119565217391,
      y: 0.9522895812988281,
    },
    {
      x: 5046.195652173913,
      y: 0.8277053833007812,
    },
    {
      x: 5050.271739130435,
      y: 0.7031173706054688,
    },
    {
      x: 5054.347826086957,
      y: 0.5785293579101562,
    },
    {
      x: 5058.423913043478,
      y: 0.45892333984375,
    },
    {
      x: 5062.5,
      y: 0.3343391418457031,
    },
    {
      x: 5066.576086956522,
      y: 0.21473312377929688,
    },
    {
      x: 5070.652173913044,
      y: 0.12004470825195312,
    },
    {
      x: 5074.728260869565,
      y: 0.08017730712890625,
    },
    {
      x: 5078.804347826087,
      y: 0.07020950317382812,
    },
    {
      x: 5082.880434782609,
      y: 0.06522369384765625,
    },
    {
      x: 5086.956521739131,
      y: 0.06522369384765625,
    },
    {
      x: 5111.413043478261,
      y: 0.06522369384765625,
    },
    {
      x: 5115.489130434783,
      y: 0.07020950317382812,
    },
    {
      x: 5119.565217391305,
      y: 0.07020950317382812,
    },
    {
      x: 5144.021739130435,
      y: 0.07020950317382812,
    },
    {
      x: 5148.097826086957,
      y: 0.0751953125,
    },
    {
      x: 5152.173913043478,
      y: 0.0751953125,
    },
    {
      x: 5176.630434782609,
      y: 0.0751953125,
    },
    {
      x: 5180.706521739131,
      y: 0.08017730712890625,
    },
    {
      x: 5184.782608695652,
      y: 0.08017730712890625,
    },
    {
      x: 5209.239130434783,
      y: 0.08017730712890625,
    },
    {
      x: 5213.315217391305,
      y: 0.0851593017578125,
    },
    {
      x: 5217.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 5221.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 5270.380434782609,
      y: 0.09512710571289062,
    },
    {
      x: 5274.456521739131,
      y: 0.13001251220703125,
    },
    {
      x: 5278.532608695652,
      y: 0.22469711303710938,
    },
    {
      x: 5282.608695652174,
      y: 0.3393211364746094,
    },
    {
      x: 5286.684782608696,
      y: 0.45892333984375,
    },
    {
      x: 5290.760869565218,
      y: 0.5785293579101562,
    },
    {
      x: 5294.836956521739,
      y: 0.7031173706054688,
    },
    {
      x: 5298.913043478261,
      y: 0.822723388671875,
    },
    {
      x: 5302.989130434783,
      y: 0.9423255920410156,
    },
    {
      x: 5307.065217391305,
      y: 1.0569496154785156,
    },
    {
      x: 5311.141304347826,
      y: 1.1765518188476562,
    },
    {
      x: 5315.217391304348,
      y: 1.2313690185546875,
    },
    {
      x: 5319.29347826087,
      y: 1.1765518188476562,
    },
    {
      x: 5323.369565217391,
      y: 1.0718994140625,
    },
    {
      x: 5327.445652173913,
      y: 0.9522895812988281,
    },
    {
      x: 5331.521739130435,
      y: 0.8277053833007812,
    },
    {
      x: 5335.597826086957,
      y: 0.7031173706054688,
    },
    {
      x: 5339.673913043478,
      y: 0.5785293579101562,
    },
    {
      x: 5343.75,
      y: 0.45892333984375,
    },
    {
      x: 5347.826086956522,
      y: 0.3343391418457031,
    },
    {
      x: 5351.902173913044,
      y: 0.21473312377929688,
    },
    {
      x: 5355.978260869565,
      y: 0.12004470825195312,
    },
    {
      x: 5360.054347826087,
      y: 0.08017730712890625,
    },
    {
      x: 5364.130434782609,
      y: 0.07020950317382812,
    },
    {
      x: 5368.206521739131,
      y: 0.06522369384765625,
    },
    {
      x: 5372.282608695652,
      y: 0.06522369384765625,
    },
    {
      x: 5396.739130434783,
      y: 0.06522369384765625,
    },
    {
      x: 5400.815217391305,
      y: 0.07020950317382812,
    },
    {
      x: 5404.891304347826,
      y: 0.07020950317382812,
    },
    {
      x: 5429.347826086957,
      y: 0.07020950317382812,
    },
    {
      x: 5433.423913043478,
      y: 0.0751953125,
    },
    {
      x: 5437.5,
      y: 0.0751953125,
    },
    {
      x: 5461.956521739131,
      y: 0.0751953125,
    },
    {
      x: 5466.032608695652,
      y: 0.08017730712890625,
    },
    {
      x: 5470.108695652174,
      y: 0.08017730712890625,
    },
    {
      x: 5494.565217391305,
      y: 0.08017730712890625,
    },
    {
      x: 5498.641304347826,
      y: 0.0851593017578125,
    },
    {
      x: 5502.717391304348,
      y: 0.0851593017578125,
    },
    {
      x: 5527.173913043478,
      y: 0.0851593017578125,
    },
    {
      x: 5531.25,
      y: 0.09014511108398438,
    },
    {
      x: 5535.326086956522,
      y: 0.09014511108398438,
    },
    {
      x: 5559.782608695652,
      y: 0.09014511108398438,
    },
    {
      x: 5563.858695652174,
      y: 0.09512710571289062,
    },
    {
      x: 5567.934782608696,
      y: 0.09512710571289062,
    },
    {
      x: 5576.086956521739,
      y: 0.09512710571289062,
    },
    {
      x: 5580.163043478261,
      y: 0.0851593017578125,
    },
    {
      x: 5584.239130434783,
      y: 0.05525970458984375,
    },
    {
      x: 5588.315217391305,
      y: 0.020374298095703125,
    },
    {
      x: 5592.391304347826,
      y: -0.004543304443359375,
    },
    {
      x: 5596.467391304348,
      y: -0.01949310302734375,
    },
    {
      x: 5600.54347826087,
      y: -0.034442901611328125,
    },
    {
      x: 5604.619565217391,
      y: -0.049396514892578125,
    },
    {
      x: 5608.695652173913,
      y: -0.05936431884765625,
    },
    {
      x: 5612.771739130435,
      y: -0.074310302734375,
    },
    {
      x: 5616.847826086957,
      y: -0.08427810668945312,
    },
    {
      x: 5620.923913043478,
      y: -0.09424591064453125,
    },
    {
      x: 5625,
      y: -0.10919570922851562,
    },
    {
      x: 5629.076086956522,
      y: -0.11916351318359375,
    },
    {
      x: 5633.152173913044,
      y: -0.12913131713867188,
    },
    {
      x: 5637.228260869565,
      y: -0.14408111572265625,
    },
    {
      x: 5641.304347826087,
      y: -0.15404891967773438,
    },
    {
      x: 5649.456521739131,
      y: -0.17398452758789062,
    },
    {
      x: 5653.532608695652,
      y: -0.18394851684570312,
    },
    {
      x: 5657.608695652174,
      y: -0.1988983154296875,
    },
    {
      x: 5661.684782608696,
      y: -0.20886993408203125,
    },
    {
      x: 5665.760869565218,
      y: -0.1988983154296875,
    },
    {
      x: 5669.836956521739,
      y: -0.18394851684570312,
    },
    {
      x: 5673.913043478261,
      y: -0.16899871826171875,
    },
    {
      x: 5677.989130434783,
      y: -0.14906692504882812,
    },
    {
      x: 5682.065217391305,
      y: -0.13411712646484375,
    },
    {
      x: 5686.141304347826,
      y: -0.1141815185546875,
    },
    {
      x: 5690.217391304348,
      y: -0.09923171997070312,
    },
    {
      x: 5694.29347826087,
      y: -0.07929611206054688,
    },
    {
      x: 5698.369565217391,
      y: -0.0643463134765625,
    },
    {
      x: 5702.445652173913,
      y: -0.049396514892578125,
    },
    {
      x: 5706.521739130435,
      y: -0.029460906982421875,
    },
    {
      x: 5710.597826086957,
      y: -0.0145111083984375,
    },
    {
      x: 5714.673913043478,
      y: 0.0004425048828125,
    },
    {
      x: 5718.75,
      y: 0.015392303466796875,
    },
    {
      x: 5722.826086956522,
      y: 0.0353240966796875,
    },
    {
      x: 5726.902173913044,
      y: 0.050273895263671875,
    },
    {
      x: 5730.978260869565,
      y: 0.06522369384765625,
    },
    {
      x: 5735.054347826087,
      y: 0.0751953125,
    },
    {
      x: 5739.130434782609,
      y: 0.09512710571289062,
    },
    {
      x: 5743.206521739131,
      y: 0.09512710571289062,
    },
    {
      x: 5995.923913043478,
      y: 0.09512710571289062,
    },
    {
      x: 6000,
      y: 0.08017730712890625,
    },
    {
      x: 6004.076086956522,
      y: 0.08017730712890625,
    },
    {
      x: 6008.152173913044,
      y: 0.0851593017578125,
    },
    {
      x: 6012.228260869565,
      y: 0.0851593017578125,
    },
    {
      x: 6036.684782608696,
      y: 0.0851593017578125,
    },
    {
      x: 6040.760869565218,
      y: 0.09014511108398438,
    },
    {
      x: 6044.836956521739,
      y: 0.09014511108398438,
    },
    {
      x: 6069.29347826087,
      y: 0.09014511108398438,
    },
    {
      x: 6073.369565217391,
      y: 0.09512710571289062,
    },
    {
      x: 6077.445652173913,
      y: 0.09512710571289062,
    },
    {
      x: 6256.79347826087,
      y: 0.09512710571289062,
    },
    {
      x: 6260.869565217392,
      y: 0.0004425048828125,
    },
    {
      x: 6264.945652173913,
      y: 0.015392303466796875,
    },
    {
      x: 6269.021739130435,
      y: 0.0353240966796875,
    },
    {
      x: 6273.097826086957,
      y: 0.050273895263671875,
    },
    {
      x: 6277.173913043478,
      y: 0.06522369384765625,
    },
    {
      x: 6281.25,
      y: 0.0751953125,
    },
    {
      x: 6285.326086956522,
      y: 0.08017730712890625,
    },
    {
      x: 6289.402173913044,
      y: 0.08017730712890625,
    },
    {
      x: 6293.478260869565,
      y: 0.0851593017578125,
    },
    {
      x: 6297.554347826087,
      y: 0.0851593017578125,
    },
    {
      x: 6322.010869565218,
      y: 0.0851593017578125,
    },
    {
      x: 6326.086956521739,
      y: 0.09014511108398438,
    },
    {
      x: 6330.163043478261,
      y: 0.09014511108398438,
    },
    {
      x: 6354.619565217392,
      y: 0.09014511108398438,
    },
    {
      x: 6358.695652173913,
      y: 0.09512710571289062,
    },
    {
      x: 6362.771739130435,
      y: 0.09512710571289062,
    },
    {
      x: 6778.532608695652,
      y: 0.09512710571289062,
    },
    {
      x: 6782.608695652174,
      y: 0.07020950317382812,
    },
    {
      x: 6786.684782608696,
      y: 0.07020950317382812,
    },
    {
      x: 6790.760869565218,
      y: 0.0751953125,
    },
    {
      x: 6794.836956521739,
      y: 0.0751953125,
    },
    {
      x: 6819.29347826087,
      y: 0.0751953125,
    },
    {
      x: 6823.369565217392,
      y: 0.08017730712890625,
    },
    {
      x: 6827.445652173913,
      y: 0.08017730712890625,
    },
    {
      x: 6851.902173913044,
      y: 0.08017730712890625,
    },
    {
      x: 6855.978260869565,
      y: 0.0851593017578125,
    },
    {
      x: 6860.054347826087,
      y: 0.0851593017578125,
    },
    {
      x: 6884.510869565218,
      y: 0.0851593017578125,
    },
    {
      x: 6888.586956521739,
      y: 0.09014511108398438,
    },
    {
      x: 6892.663043478261,
      y: 0.09014511108398438,
    },
    {
      x: 6904.891304347826,
      y: 0.09014511108398438,
    },
    {
      x: 6908.967391304348,
      y: 0.08017730712890625,
    },
    {
      x: 6913.04347826087,
      y: 0.050273895263671875,
    },
    {
      x: 6917.119565217392,
      y: 0.020374298095703125,
    },
    {
      x: 6921.195652173913,
      y: -0.004543304443359375,
    },
    {
      x: 6925.271739130435,
      y: -0.01949310302734375,
    },
    {
      x: 6929.347826086957,
      y: -0.034442901611328125,
    },
    {
      x: 6933.423913043478,
      y: -0.049396514892578125,
    },
    {
      x: 6937.5,
      y: -0.05936431884765625,
    },
    {
      x: 6941.576086956522,
      y: -0.074310302734375,
    },
    {
      x: 6945.652173913044,
      y: -0.08427810668945312,
    },
    {
      x: 6953.804347826087,
      y: -0.10421371459960938,
    },
    {
      x: 6957.880434782609,
      y: -0.11916351318359375,
    },
    {
      x: 6961.956521739131,
      y: -0.12913131713867188,
    },
    {
      x: 6966.032608695652,
      y: -0.13909912109375,
    },
    {
      x: 6970.108695652174,
      y: -0.15404891967773438,
    },
    {
      x: 6974.184782608696,
      y: -0.1640167236328125,
    },
    {
      x: 6978.260869565218,
      y: -0.17398452758789062,
    },
    {
      x: 6982.336956521739,
      y: -0.18394851684570312,
    },
    {
      x: 6986.413043478261,
      y: -0.19391632080078125,
    },
    {
      x: 6990.489130434783,
      y: -0.20388412475585938,
    },
    {
      x: 6994.565217391305,
      y: -0.1988983154296875,
    },
    {
      x: 6998.641304347826,
      y: -0.18394851684570312,
    },
    {
      x: 7002.717391304348,
      y: -0.16899871826171875,
    },
    {
      x: 7006.79347826087,
      y: -0.14906692504882812,
    },
    {
      x: 7010.869565217392,
      y: -0.13411712646484375,
    },
    {
      x: 7014.945652173913,
      y: -0.1141815185546875,
    },
    {
      x: 7019.021739130435,
      y: -0.09923171997070312,
    },
    {
      x: 7023.097826086957,
      y: -0.07929611206054688,
    },
    {
      x: 7027.173913043478,
      y: -0.0643463134765625,
    },
    {
      x: 7031.25,
      y: -0.049396514892578125,
    },
    {
      x: 7035.326086956522,
      y: -0.029460906982421875,
    },
    {
      x: 7039.402173913044,
      y: -0.0145111083984375,
    },
    {
      x: 7043.478260869565,
      y: 0.09512710571289062,
    },
    {
      x: 7047.554347826087,
      y: 0.09512710571289062,
    },
    {
      x: 7120.923913043478,
      y: 0.09512710571289062,
    },
    {
      x: 7125,
      y: 0.13001251220703125,
    },
    {
      x: 7129.076086956522,
      y: 0.22469711303710938,
    },
    {
      x: 7133.152173913044,
      y: 0.3393211364746094,
    },
    {
      x: 7137.228260869565,
      y: 0.45892333984375,
    },
    {
      x: 7141.304347826087,
      y: 0.5785293579101562,
    },
    {
      x: 7145.380434782609,
      y: 0.7031173706054688,
    },
    {
      x: 7149.456521739131,
      y: 0.822723388671875,
    },
    {
      x: 7153.532608695652,
      y: 0.9423255920410156,
    },
    {
      x: 7157.608695652174,
      y: 1.0569496154785156,
    },
    {
      x: 7161.684782608696,
      y: 1.1765518188476562,
    },
    {
      x: 7165.760869565218,
      y: 1.2313690185546875,
    },
    {
      x: 7169.836956521739,
      y: 1.1765518188476562,
    },
    {
      x: 7173.913043478261,
      y: 1.0718994140625,
    },
    {
      x: 7177.989130434783,
      y: 0.9473075866699219,
    },
    {
      x: 7182.065217391305,
      y: 0.8277053833007812,
    },
    {
      x: 7186.141304347826,
      y: 0.7031173706054688,
    },
    {
      x: 7194.29347826087,
      y: 0.45394134521484375,
    },
    {
      x: 7198.369565217392,
      y: 0.3343391418457031,
    },
    {
      x: 7202.445652173913,
      y: 0.209747314453125,
    },
    {
      x: 7206.521739130435,
      y: 0.11506271362304688,
    },
    {
      x: 7210.597826086957,
      y: 0.0751953125,
    },
    {
      x: 7214.673913043478,
      y: 0.06522369384765625,
    },
    {
      x: 7218.75,
      y: 0.06024169921875,
    },
    {
      x: 7222.826086956522,
      y: 0.06024169921875,
    },
    {
      x: 7243.206521739131,
      y: 0.06024169921875,
    },
    {
      x: 7247.282608695652,
      y: 0.06522369384765625,
    },
    {
      x: 7251.358695652174,
      y: 0.06522369384765625,
    },
    {
      x: 7275.815217391305,
      y: 0.06522369384765625,
    },
    {
      x: 7279.891304347826,
      y: 0.07020950317382812,
    },
    {
      x: 7283.967391304348,
      y: 0.07020950317382812,
    },
    {
      x: 7300.271739130435,
      y: 0.07020950317382812,
    },
    {
      x: 7304.347826086957,
      y: -0.09923171997070312,
    },
    {
      x: 7308.423913043478,
      y: -0.07929611206054688,
    },
    {
      x: 7312.5,
      y: -0.0643463134765625,
    },
    {
      x: 7316.576086956522,
      y: -0.049396514892578125,
    },
    {
      x: 7320.652173913044,
      y: -0.029460906982421875,
    },
    {
      x: 7324.728260869565,
      y: -0.0145111083984375,
    },
    {
      x: 7328.804347826087,
      y: 0.0004425048828125,
    },
    {
      x: 7332.880434782609,
      y: 0.015392303466796875,
    },
    {
      x: 7336.956521739131,
      y: 0.0353240966796875,
    },
    {
      x: 7341.032608695652,
      y: 0.050273895263671875,
    },
    {
      x: 7345.108695652174,
      y: 0.06522369384765625,
    },
    {
      x: 7349.184782608696,
      y: 0.0751953125,
    },
    {
      x: 7353.260869565218,
      y: 0.08017730712890625,
    },
    {
      x: 7357.336956521739,
      y: 0.08017730712890625,
    },
    {
      x: 7361.413043478261,
      y: 0.0851593017578125,
    },
    {
      x: 7365.489130434783,
      y: 0.0851593017578125,
    },
    {
      x: 7389.945652173913,
      y: 0.0851593017578125,
    },
    {
      x: 7394.021739130435,
      y: 0.09014511108398438,
    },
    {
      x: 7398.097826086957,
      y: 0.09014511108398438,
    },
    {
      x: 7422.554347826087,
      y: 0.09014511108398438,
    },
    {
      x: 7426.630434782609,
      y: 0.09512710571289062,
    },
    {
      x: 7430.706521739131,
      y: 0.09512710571289062,
    },
    {
      x: 7561.141304347826,
      y: 0.09512710571289062,
    },
    {
      x: 7565.217391304348,
      y: 0.07020950317382812,
    },
    {
      x: 7569.29347826087,
      y: 0.07020950317382812,
    },
    {
      x: 7593.75,
      y: 0.07020950317382812,
    },
    {
      x: 7597.826086956522,
      y: 0.0751953125,
    },
    {
      x: 7601.902173913044,
      y: 0.0751953125,
    },
    {
      x: 7626.358695652174,
      y: 0.0751953125,
    },
    {
      x: 7630.434782608696,
      y: 0.08017730712890625,
    },
    {
      x: 7634.510869565218,
      y: 0.08017730712890625,
    },
    {
      x: 7658.967391304348,
      y: 0.08017730712890625,
    },
    {
      x: 7663.04347826087,
      y: 0.0851593017578125,
    },
    {
      x: 7667.119565217392,
      y: 0.0851593017578125,
    },
    {
      x: 7691.576086956522,
      y: 0.0851593017578125,
    },
    {
      x: 7695.652173913044,
      y: 0.09014511108398438,
    },
    {
      x: 7699.728260869565,
      y: 0.09014511108398438,
    },
    {
      x: 7711.956521739131,
      y: 0.09014511108398438,
    },
    {
      x: 7716.032608695652,
      y: 0.08017730712890625,
    },
    {
      x: 7720.108695652174,
      y: 0.050273895263671875,
    },
    {
      x: 7724.184782608696,
      y: 0.020374298095703125,
    },
    {
      x: 7728.260869565218,
      y: -0.004543304443359375,
    },
    {
      x: 7732.336956521739,
      y: -0.01949310302734375,
    },
    {
      x: 7736.413043478261,
      y: -0.034442901611328125,
    },
    {
      x: 7740.489130434783,
      y: -0.049396514892578125,
    },
    {
      x: 7744.565217391305,
      y: -0.05936431884765625,
    },
    {
      x: 7748.641304347826,
      y: -0.074310302734375,
    },
    {
      x: 7752.717391304348,
      y: -0.08427810668945312,
    },
    {
      x: 7760.869565217392,
      y: -0.10421371459960938,
    },
    {
      x: 7764.945652173913,
      y: -0.11916351318359375,
    },
    {
      x: 7769.021739130435,
      y: -0.12913131713867188,
    },
    {
      x: 7773.097826086957,
      y: -0.13909912109375,
    },
    {
      x: 7777.173913043479,
      y: -0.15404891967773438,
    },
    {
      x: 7781.25,
      y: -0.1640167236328125,
    },
    {
      x: 7785.326086956522,
      y: -0.17398452758789062,
    },
    {
      x: 7789.402173913044,
      y: -0.18394851684570312,
    },
    {
      x: 7793.478260869565,
      y: -0.1988983154296875,
    },
    {
      x: 7797.554347826087,
      y: -0.20886993408203125,
    },
    {
      x: 7801.630434782609,
      y: -0.1988983154296875,
    },
    {
      x: 7805.706521739131,
      y: -0.18394851684570312,
    },
    {
      x: 7809.782608695652,
      y: -0.16899871826171875,
    },
    {
      x: 7813.858695652174,
      y: -0.14906692504882812,
    },
    {
      x: 7817.934782608696,
      y: -0.13411712646484375,
    },
    {
      x: 7822.010869565218,
      y: -0.1141815185546875,
    },
    {
      x: 7826.086956521739,
      y: 0.09512710571289062,
    },
    {
      x: 7830.163043478261,
      y: 0.09512710571289062,
    },
    {
      x: 8188.858695652174,
      y: 0.09512710571289062,
    },
    {
      x: 8192.934782608696,
      y: 0.13001251220703125,
    },
    {
      x: 8197.010869565218,
      y: 0.22469711303710938,
    },
    {
      x: 8201.08695652174,
      y: 0.3393211364746094,
    },
    {
      x: 8205.163043478262,
      y: 0.45892333984375,
    },
    {
      x: 8209.239130434782,
      y: 0.5785293579101562,
    },
    {
      x: 8213.315217391304,
      y: 0.7031173706054688,
    },
    {
      x: 8217.391304347826,
      y: 0.822723388671875,
    },
    {
      x: 8221.467391304348,
      y: 0.9423255920410156,
    },
    {
      x: 8225.54347826087,
      y: 1.0569496154785156,
    },
    {
      x: 8229.619565217392,
      y: 1.1765518188476562,
    },
    {
      x: 8233.695652173914,
      y: 1.2313690185546875,
    },
    {
      x: 8237.771739130436,
      y: 1.1765518188476562,
    },
    {
      x: 8241.847826086956,
      y: 1.0718994140625,
    },
    {
      x: 8245.923913043478,
      y: 0.9473075866699219,
    },
    {
      x: 8250,
      y: 0.8277053833007812,
    },
    {
      x: 8254.076086956522,
      y: 0.7031173706054688,
    },
    {
      x: 8262.228260869566,
      y: 0.45394134521484375,
    },
    {
      x: 8266.304347826088,
      y: 0.3343391418457031,
    },
    {
      x: 8270.380434782608,
      y: 0.209747314453125,
    },
    {
      x: 8274.45652173913,
      y: 0.11506271362304688,
    },
    {
      x: 8278.532608695652,
      y: 0.0751953125,
    },
    {
      x: 8282.608695652174,
      y: 0.06522369384765625,
    },
    {
      x: 8286.684782608696,
      y: 0.06024169921875,
    },
    {
      x: 8290.760869565218,
      y: 0.06024169921875,
    },
    {
      x: 8311.141304347826,
      y: 0.06024169921875,
    },
    {
      x: 8315.217391304348,
      y: 0.06522369384765625,
    },
    {
      x: 8319.29347826087,
      y: 0.06522369384765625,
    },
    {
      x: 8343.75,
      y: 0.06522369384765625,
    },
    {
      x: 8347.826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 8351.902173913044,
      y: 0.09512710571289062,
    },
    {
      x: 8604.619565217392,
      y: 0.09512710571289062,
    },
    {
      x: 8608.695652173914,
      y: -0.1988983154296875,
    },
    {
      x: 8612.771739130436,
      y: -0.18394851684570312,
    },
    {
      x: 8616.847826086956,
      y: -0.16899871826171875,
    },
    {
      x: 8620.923913043478,
      y: -0.14906692504882812,
    },
    {
      x: 8625,
      y: -0.13411712646484375,
    },
    {
      x: 8629.076086956522,
      y: -0.1141815185546875,
    },
    {
      x: 8633.152173913044,
      y: -0.09923171997070312,
    },
    {
      x: 8637.228260869566,
      y: -0.07929611206054688,
    },
    {
      x: 8641.304347826088,
      y: -0.0643463134765625,
    },
    {
      x: 8645.380434782608,
      y: -0.049396514892578125,
    },
    {
      x: 8649.45652173913,
      y: -0.029460906982421875,
    },
    {
      x: 8653.532608695652,
      y: -0.0145111083984375,
    },
    {
      x: 8657.608695652174,
      y: 0.0004425048828125,
    },
    {
      x: 8661.684782608696,
      y: 0.015392303466796875,
    },
    {
      x: 8665.760869565218,
      y: 0.0353240966796875,
    },
    {
      x: 8669.83695652174,
      y: 0.050273895263671875,
    },
    {
      x: 8673.913043478262,
      y: 0.06522369384765625,
    },
    {
      x: 8677.989130434782,
      y: 0.0751953125,
    },
    {
      x: 8682.065217391304,
      y: 0.08017730712890625,
    },
    {
      x: 8686.141304347826,
      y: 0.08017730712890625,
    },
    {
      x: 8690.217391304348,
      y: 0.0851593017578125,
    },
    {
      x: 8694.29347826087,
      y: 0.0851593017578125,
    },
    {
      x: 8718.75,
      y: 0.0851593017578125,
    },
    {
      x: 8722.826086956522,
      y: 0.09014511108398438,
    },
    {
      x: 8726.902173913044,
      y: 0.09014511108398438,
    },
    {
      x: 8751.358695652174,
      y: 0.09014511108398438,
    },
    {
      x: 8755.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 8759.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 8865.489130434782,
      y: 0.09512710571289062,
    },
    {
      x: 8869.565217391304,
      y: 0.06522369384765625,
    },
    {
      x: 8873.641304347826,
      y: 0.06522369384765625,
    },
    {
      x: 8889.945652173914,
      y: 0.06522369384765625,
    },
    {
      x: 8894.021739130436,
      y: 0.07020950317382812,
    },
    {
      x: 8898.097826086956,
      y: 0.07020950317382812,
    },
    {
      x: 8922.554347826088,
      y: 0.07020950317382812,
    },
    {
      x: 8926.630434782608,
      y: 0.0751953125,
    },
    {
      x: 8930.70652173913,
      y: 0.0751953125,
    },
    {
      x: 8955.163043478262,
      y: 0.0751953125,
    },
    {
      x: 8959.239130434782,
      y: 0.08017730712890625,
    },
    {
      x: 8963.315217391304,
      y: 0.08017730712890625,
    },
    {
      x: 8987.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 8991.847826086956,
      y: 0.0851593017578125,
    },
    {
      x: 8995.923913043478,
      y: 0.0851593017578125,
    },
    {
      x: 9020.380434782608,
      y: 0.0851593017578125,
    },
    {
      x: 9024.45652173913,
      y: 0.09014511108398438,
    },
    {
      x: 9028.532608695652,
      y: 0.09014511108398438,
    },
    {
      x: 9040.760869565218,
      y: 0.09014511108398438,
    },
    {
      x: 9044.83695652174,
      y: 0.08017730712890625,
    },
    {
      x: 9048.913043478262,
      y: 0.050273895263671875,
    },
    {
      x: 9052.989130434782,
      y: 0.020374298095703125,
    },
    {
      x: 9057.065217391304,
      y: -0.004543304443359375,
    },
    {
      x: 9061.141304347826,
      y: -0.01949310302734375,
    },
    {
      x: 9065.217391304348,
      y: -0.034442901611328125,
    },
    {
      x: 9069.29347826087,
      y: -0.049396514892578125,
    },
    {
      x: 9073.369565217392,
      y: -0.05936431884765625,
    },
    {
      x: 9077.445652173914,
      y: -0.074310302734375,
    },
    {
      x: 9081.521739130436,
      y: -0.08427810668945312,
    },
    {
      x: 9089.673913043478,
      y: -0.10421371459960938,
    },
    {
      x: 9093.75,
      y: -0.11916351318359375,
    },
    {
      x: 9097.826086956522,
      y: -0.12913131713867188,
    },
    {
      x: 9101.902173913044,
      y: -0.13909912109375,
    },
    {
      x: 9105.978260869566,
      y: -0.15404891967773438,
    },
    {
      x: 9110.054347826088,
      y: -0.1640167236328125,
    },
    {
      x: 9114.130434782608,
      y: -0.17398452758789062,
    },
    {
      x: 9118.20652173913,
      y: -0.18394851684570312,
    },
    {
      x: 9122.282608695652,
      y: -0.1988983154296875,
    },
    {
      x: 9126.358695652174,
      y: -0.20886993408203125,
    },
    {
      x: 9130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 9134.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 9256.79347826087,
      y: 0.09512710571289062,
    },
    {
      x: 9260.869565217392,
      y: 0.13001251220703125,
    },
    {
      x: 9264.945652173914,
      y: 0.22469711303710938,
    },
    {
      x: 9269.021739130436,
      y: 0.3393211364746094,
    },
    {
      x: 9273.097826086956,
      y: 0.45892333984375,
    },
    {
      x: 9277.173913043478,
      y: 0.5785293579101562,
    },
    {
      x: 9281.25,
      y: 0.7031173706054688,
    },
    {
      x: 9285.326086956522,
      y: 0.822723388671875,
    },
    {
      x: 9289.402173913044,
      y: 0.9423255920410156,
    },
    {
      x: 9293.478260869566,
      y: 1.0569496154785156,
    },
    {
      x: 9297.554347826088,
      y: 1.1765518188476562,
    },
    {
      x: 9301.630434782608,
      y: 1.2313690185546875,
    },
    {
      x: 9305.70652173913,
      y: 1.1765518188476562,
    },
    {
      x: 9309.782608695652,
      y: 1.0718994140625,
    },
    {
      x: 9313.858695652174,
      y: 0.9473075866699219,
    },
    {
      x: 9317.934782608696,
      y: 0.8277053833007812,
    },
    {
      x: 9322.010869565218,
      y: 0.7031173706054688,
    },
    {
      x: 9330.163043478262,
      y: 0.45394134521484375,
    },
    {
      x: 9334.239130434782,
      y: 0.3343391418457031,
    },
    {
      x: 9338.315217391304,
      y: 0.209747314453125,
    },
    {
      x: 9342.391304347826,
      y: 0.11506271362304688,
    },
    {
      x: 9346.467391304348,
      y: 0.0751953125,
    },
    {
      x: 9350.54347826087,
      y: 0.06522369384765625,
    },
    {
      x: 9354.619565217392,
      y: 0.06024169921875,
    },
    {
      x: 9358.695652173914,
      y: 0.06024169921875,
    },
    {
      x: 9379.076086956522,
      y: 0.06024169921875,
    },
    {
      x: 9383.152173913044,
      y: 0.06522369384765625,
    },
    {
      x: 9387.228260869566,
      y: 0.06522369384765625,
    },
    {
      x: 9391.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 9395.380434782608,
      y: 0.09512710571289062,
    },
    {
      x: 9648.097826086956,
      y: 0.09512710571289062,
    },
    {
      x: 9652.173913043478,
      y: -0.15404891967773438,
    },
    {
      x: 9656.25,
      y: -0.1640167236328125,
    },
    {
      x: 9660.326086956522,
      y: -0.17398452758789062,
    },
    {
      x: 9664.402173913044,
      y: -0.18394851684570312,
    },
    {
      x: 9668.478260869566,
      y: -0.1988983154296875,
    },
    {
      x: 9672.554347826088,
      y: -0.20886993408203125,
    },
    {
      x: 9676.630434782608,
      y: -0.1988983154296875,
    },
    {
      x: 9680.70652173913,
      y: -0.18394851684570312,
    },
    {
      x: 9684.782608695652,
      y: -0.16899871826171875,
    },
    {
      x: 9688.858695652174,
      y: -0.14906692504882812,
    },
    {
      x: 9692.934782608696,
      y: -0.13411712646484375,
    },
    {
      x: 9697.010869565218,
      y: -0.1141815185546875,
    },
    {
      x: 9701.08695652174,
      y: -0.09923171997070312,
    },
    {
      x: 9705.163043478262,
      y: -0.07929611206054688,
    },
    {
      x: 9709.239130434782,
      y: -0.0643463134765625,
    },
    {
      x: 9713.315217391304,
      y: -0.049396514892578125,
    },
    {
      x: 9717.391304347826,
      y: -0.029460906982421875,
    },
    {
      x: 9721.467391304348,
      y: -0.0145111083984375,
    },
    {
      x: 9725.54347826087,
      y: 0.0004425048828125,
    },
    {
      x: 9729.619565217392,
      y: 0.015392303466796875,
    },
    {
      x: 9733.695652173914,
      y: 0.0353240966796875,
    },
    {
      x: 9737.771739130436,
      y: 0.050273895263671875,
    },
    {
      x: 9741.847826086956,
      y: 0.06522369384765625,
    },
    {
      x: 9745.923913043478,
      y: 0.0751953125,
    },
    {
      x: 9750,
      y: 0.08017730712890625,
    },
    {
      x: 9754.076086956522,
      y: 0.08017730712890625,
    },
    {
      x: 9758.152173913044,
      y: 0.0851593017578125,
    },
    {
      x: 9762.228260869566,
      y: 0.0851593017578125,
    },
    {
      x: 9786.684782608696,
      y: 0.0851593017578125,
    },
    {
      x: 9790.760869565218,
      y: 0.09014511108398438,
    },
    {
      x: 9794.83695652174,
      y: 0.09014511108398438,
    },
    {
      x: 9819.29347826087,
      y: 0.09014511108398438,
    },
    {
      x: 9823.369565217392,
      y: 0.09512710571289062,
    },
    {
      x: 9827.445652173914,
      y: 0.09512710571289062,
    },
    {
      x: 9908.967391304348,
      y: 0.09512710571289062,
    },
    {
      x: 9913.04347826087,
      y: 0.06024169921875,
    },
    {
      x: 9917.119565217392,
      y: 0.06024169921875,
    },
    {
      x: 9925.271739130436,
      y: 0.06024169921875,
    },
    {
      x: 9929.347826086956,
      y: 0.06522369384765625,
    },
    {
      x: 9933.423913043478,
      y: 0.06522369384765625,
    },
    {
      x: 9957.88043478261,
      y: 0.06522369384765625,
    },
    {
      x: 9961.95652173913,
      y: 0.07020950317382812,
    },
    {
      x: 9966.032608695652,
      y: 0.07020950317382812,
    },
    {
      x: 9990.489130434782,
      y: 0.07020950317382812,
    },
    {
      x: 9994.565217391304,
      y: 0.0751953125,
    },
    {
      x: 9998.641304347826,
      y: 0.0751953125,
    },
    {
      x: 10023.097826086956,
      y: 0.0751953125,
    },
    {
      x: 10027.173913043478,
      y: 0.08017730712890625,
    },
    {
      x: 10031.25,
      y: 0.08017730712890625,
    },
    {
      x: 10055.70652173913,
      y: 0.08017730712890625,
    },
    {
      x: 10059.782608695652,
      y: 0.0851593017578125,
    },
    {
      x: 10063.858695652174,
      y: 0.0851593017578125,
    },
    {
      x: 10088.315217391304,
      y: 0.0851593017578125,
    },
    {
      x: 10092.391304347826,
      y: 0.09014511108398438,
    },
    {
      x: 10096.467391304348,
      y: 0.09014511108398438,
    },
    {
      x: 10108.695652173914,
      y: 0.09014511108398438,
    },
    {
      x: 10112.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 10116.847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 10120.923913043478,
      y: 0.020374298095703125,
    },
    {
      x: 10125,
      y: -0.004543304443359375,
    },
    {
      x: 10129.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 10133.152173913044,
      y: -0.034442901611328125,
    },
    {
      x: 10137.228260869566,
      y: -0.049396514892578125,
    },
    {
      x: 10141.304347826088,
      y: -0.05936431884765625,
    },
    {
      x: 10145.38043478261,
      y: -0.074310302734375,
    },
    {
      x: 10149.45652173913,
      y: -0.08427810668945312,
    },
    {
      x: 10157.608695652174,
      y: -0.10421371459960938,
    },
    {
      x: 10161.684782608696,
      y: -0.11916351318359375,
    },
    {
      x: 10165.760869565218,
      y: -0.12913131713867188,
    },
    {
      x: 10169.83695652174,
      y: -0.13909912109375,
    },
    {
      x: 10173.913043478262,
      y: 0.09512710571289062,
    },
    {
      x: 10177.989130434782,
      y: 0.09512710571289062,
    },
    {
      x: 10324.728260869566,
      y: 0.09512710571289062,
    },
    {
      x: 10328.804347826088,
      y: 0.13001251220703125,
    },
    {
      x: 10332.88043478261,
      y: 0.22469711303710938,
    },
    {
      x: 10336.95652173913,
      y: 0.3393211364746094,
    },
    {
      x: 10341.032608695652,
      y: 0.45892333984375,
    },
    {
      x: 10345.108695652174,
      y: 0.5785293579101562,
    },
    {
      x: 10349.184782608696,
      y: 0.7031173706054688,
    },
    {
      x: 10353.260869565218,
      y: 0.822723388671875,
    },
    {
      x: 10357.33695652174,
      y: 0.9423255920410156,
    },
    {
      x: 10361.413043478262,
      y: 1.0569496154785156,
    },
    {
      x: 10365.489130434782,
      y: 1.1765518188476562,
    },
    {
      x: 10369.565217391304,
      y: 1.2313690185546875,
    },
    {
      x: 10373.641304347826,
      y: 1.1765518188476562,
    },
    {
      x: 10377.717391304348,
      y: 1.0718994140625,
    },
    {
      x: 10381.79347826087,
      y: 0.9473075866699219,
    },
    {
      x: 10385.869565217392,
      y: 0.8277053833007812,
    },
    {
      x: 10389.945652173914,
      y: 0.7031173706054688,
    },
    {
      x: 10398.097826086956,
      y: 0.45394134521484375,
    },
    {
      x: 10402.173913043478,
      y: 0.3343391418457031,
    },
    {
      x: 10406.25,
      y: 0.209747314453125,
    },
    {
      x: 10410.326086956522,
      y: 0.11506271362304688,
    },
    {
      x: 10414.402173913044,
      y: 0.0751953125,
    },
    {
      x: 10418.478260869566,
      y: 0.06522369384765625,
    },
    {
      x: 10422.554347826088,
      y: 0.06024169921875,
    },
    {
      x: 10426.63043478261,
      y: 0.06024169921875,
    },
    {
      x: 10430.70652173913,
      y: 0.06024169921875,
    },
    {
      x: 10434.782608695652,
      y: -0.08427810668945312,
    },
    {
      x: 10438.858695652174,
      y: -0.09424591064453125,
    },
    {
      x: 10442.934782608696,
      y: -0.10421371459960938,
    },
    {
      x: 10447.010869565218,
      y: -0.11916351318359375,
    },
    {
      x: 10451.08695652174,
      y: -0.12913131713867188,
    },
    {
      x: 10455.163043478262,
      y: -0.13909912109375,
    },
    {
      x: 10459.239130434782,
      y: -0.15404891967773438,
    },
    {
      x: 10463.315217391304,
      y: -0.1640167236328125,
    },
    {
      x: 10467.391304347826,
      y: -0.17398452758789062,
    },
    {
      x: 10471.467391304348,
      y: -0.18394851684570312,
    },
    {
      x: 10475.54347826087,
      y: -0.19391632080078125,
    },
    {
      x: 10479.619565217392,
      y: -0.20388412475585938,
    },
    {
      x: 10483.695652173914,
      y: -0.1988983154296875,
    },
    {
      x: 10487.771739130436,
      y: -0.18394851684570312,
    },
    {
      x: 10491.847826086956,
      y: -0.16899871826171875,
    },
    {
      x: 10495.923913043478,
      y: -0.14906692504882812,
    },
    {
      x: 10500,
      y: -0.13411712646484375,
    },
    {
      x: 10504.076086956522,
      y: -0.1141815185546875,
    },
    {
      x: 10508.152173913044,
      y: -0.09923171997070312,
    },
    {
      x: 10512.228260869566,
      y: -0.07929611206054688,
    },
    {
      x: 10516.304347826088,
      y: -0.0643463134765625,
    },
    {
      x: 10520.38043478261,
      y: -0.049396514892578125,
    },
    {
      x: 10524.45652173913,
      y: -0.029460906982421875,
    },
    {
      x: 10528.532608695652,
      y: -0.0145111083984375,
    },
    {
      x: 10532.608695652174,
      y: 0.0004425048828125,
    },
    {
      x: 10536.684782608696,
      y: 0.015392303466796875,
    },
    {
      x: 10540.760869565218,
      y: 0.0353240966796875,
    },
    {
      x: 10544.83695652174,
      y: 0.050273895263671875,
    },
    {
      x: 10548.913043478262,
      y: 0.06522369384765625,
    },
    {
      x: 10552.989130434782,
      y: 0.0751953125,
    },
    {
      x: 10557.065217391304,
      y: 0.08017730712890625,
    },
    {
      x: 10561.141304347826,
      y: 0.08017730712890625,
    },
    {
      x: 10565.217391304348,
      y: 0.0851593017578125,
    },
    {
      x: 10569.29347826087,
      y: 0.0851593017578125,
    },
    {
      x: 10593.75,
      y: 0.0851593017578125,
    },
    {
      x: 10597.826086956522,
      y: 0.09014511108398438,
    },
    {
      x: 10601.902173913044,
      y: 0.09014511108398438,
    },
    {
      x: 10626.358695652174,
      y: 0.09014511108398438,
    },
    {
      x: 10630.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 10634.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 10952.445652173914,
      y: 0.09512710571289062,
    },
    {
      x: 10956.521739130436,
      y: 0.11506271362304688,
    },
    {
      x: 10960.597826086956,
      y: 0.0751953125,
    },
    {
      x: 10964.673913043478,
      y: 0.06522369384765625,
    },
    {
      x: 10968.75,
      y: 0.06024169921875,
    },
    {
      x: 10972.826086956522,
      y: 0.06024169921875,
    },
    {
      x: 10993.20652173913,
      y: 0.06024169921875,
    },
    {
      x: 10997.282608695652,
      y: 0.06522369384765625,
    },
    {
      x: 11001.358695652174,
      y: 0.06522369384765625,
    },
    {
      x: 11025.815217391304,
      y: 0.06522369384765625,
    },
    {
      x: 11029.891304347826,
      y: 0.07020950317382812,
    },
    {
      x: 11033.967391304348,
      y: 0.07020950317382812,
    },
    {
      x: 11058.423913043478,
      y: 0.07020950317382812,
    },
    {
      x: 11062.5,
      y: 0.0751953125,
    },
    {
      x: 11066.576086956522,
      y: 0.0751953125,
    },
    {
      x: 11091.032608695652,
      y: 0.0751953125,
    },
    {
      x: 11095.108695652174,
      y: 0.08017730712890625,
    },
    {
      x: 11099.184782608696,
      y: 0.08017730712890625,
    },
    {
      x: 11123.641304347826,
      y: 0.08017730712890625,
    },
    {
      x: 11127.717391304348,
      y: 0.0851593017578125,
    },
    {
      x: 11131.79347826087,
      y: 0.0851593017578125,
    },
    {
      x: 11156.25,
      y: 0.0851593017578125,
    },
    {
      x: 11160.326086956522,
      y: 0.09014511108398438,
    },
    {
      x: 11164.402173913044,
      y: 0.09014511108398438,
    },
    {
      x: 11176.63043478261,
      y: 0.09014511108398438,
    },
    {
      x: 11180.70652173913,
      y: 0.08017730712890625,
    },
    {
      x: 11184.782608695652,
      y: 0.050273895263671875,
    },
    {
      x: 11188.858695652174,
      y: 0.020374298095703125,
    },
    {
      x: 11192.934782608696,
      y: -0.004543304443359375,
    },
    {
      x: 11197.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 11201.08695652174,
      y: -0.034442901611328125,
    },
    {
      x: 11205.163043478262,
      y: -0.049396514892578125,
    },
    {
      x: 11209.239130434782,
      y: -0.05936431884765625,
    },
    {
      x: 11213.315217391304,
      y: -0.074310302734375,
    },
    {
      x: 11217.391304347826,
      y: 0.09512710571289062,
    },
    {
      x: 11221.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 11392.663043478262,
      y: 0.09512710571289062,
    },
    {
      x: 11396.739130434782,
      y: 0.13001251220703125,
    },
    {
      x: 11400.815217391304,
      y: 0.22469711303710938,
    },
    {
      x: 11404.891304347826,
      y: 0.3393211364746094,
    },
    {
      x: 11408.967391304348,
      y: 0.45892333984375,
    },
    {
      x: 11413.04347826087,
      y: 0.5785293579101562,
    },
    {
      x: 11417.119565217392,
      y: 0.7031173706054688,
    },
    {
      x: 11421.195652173914,
      y: 0.822723388671875,
    },
    {
      x: 11425.271739130436,
      y: 0.9423255920410156,
    },
    {
      x: 11429.347826086956,
      y: 1.0569496154785156,
    },
    {
      x: 11433.423913043478,
      y: 1.1765518188476562,
    },
    {
      x: 11437.5,
      y: 1.2313690185546875,
    },
    {
      x: 11441.576086956522,
      y: 1.1765518188476562,
    },
    {
      x: 11445.652173913044,
      y: 1.0718994140625,
    },
    {
      x: 11449.728260869566,
      y: 0.9473075866699219,
    },
    {
      x: 11453.804347826088,
      y: 0.8277053833007812,
    },
    {
      x: 11457.88043478261,
      y: 0.7031173706054688,
    },
    {
      x: 11466.032608695652,
      y: 0.45394134521484375,
    },
    {
      x: 11470.108695652174,
      y: 0.3343391418457031,
    },
    {
      x: 11474.184782608696,
      y: 0.209747314453125,
    },
    {
      x: 11478.260869565218,
      y: 0.09512710571289062,
    },
    {
      x: 11482.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 11735.054347826088,
      y: 0.09512710571289062,
    },
    {
      x: 11739.13043478261,
      y: 0.8277053833007812,
    },
    {
      x: 11743.20652173913,
      y: 0.7031173706054688,
    },
    {
      x: 11751.358695652174,
      y: 0.45394134521484375,
    },
    {
      x: 11755.434782608696,
      y: 0.3343391418457031,
    },
    {
      x: 11759.510869565218,
      y: 0.209747314453125,
    },
    {
      x: 11763.58695652174,
      y: 0.11506271362304688,
    },
    {
      x: 11767.663043478262,
      y: 0.0751953125,
    },
    {
      x: 11771.739130434782,
      y: 0.06522369384765625,
    },
    {
      x: 11775.815217391304,
      y: 0.06024169921875,
    },
    {
      x: 11779.891304347826,
      y: 0.06024169921875,
    },
    {
      x: 11800.271739130436,
      y: 0.06024169921875,
    },
    {
      x: 11804.347826086956,
      y: 0.06522369384765625,
    },
    {
      x: 11808.423913043478,
      y: 0.06522369384765625,
    },
    {
      x: 11832.88043478261,
      y: 0.06522369384765625,
    },
    {
      x: 11836.95652173913,
      y: 0.07020950317382812,
    },
    {
      x: 11841.032608695652,
      y: 0.07020950317382812,
    },
    {
      x: 11865.489130434782,
      y: 0.07020950317382812,
    },
    {
      x: 11869.565217391304,
      y: 0.0751953125,
    },
    {
      x: 11873.641304347826,
      y: 0.0751953125,
    },
    {
      x: 11898.097826086956,
      y: 0.0751953125,
    },
    {
      x: 11902.173913043478,
      y: 0.08017730712890625,
    },
    {
      x: 11906.25,
      y: 0.08017730712890625,
    },
    {
      x: 11930.70652173913,
      y: 0.08017730712890625,
    },
    {
      x: 11934.782608695652,
      y: 0.0851593017578125,
    },
    {
      x: 11938.858695652174,
      y: 0.0851593017578125,
    },
    {
      x: 11963.315217391304,
      y: 0.0851593017578125,
    },
    {
      x: 11967.391304347826,
      y: 0.09014511108398438,
    },
    {
      x: 11971.467391304348,
      y: 0.09014511108398438,
    },
    {
      x: 11983.695652173914,
      y: 0.09014511108398438,
    },
    {
      x: 11987.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 11991.847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 11995.923913043478,
      y: 0.020374298095703125,
    },
    {
      x: 12000,
      y: -0.004543304443359375,
    },
    {
      x: 12004.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 12008.152173913044,
      y: -0.034442901611328125,
    },
    {
      x: 12012.228260869566,
      y: -0.049396514892578125,
    },
    {
      x: 12016.304347826088,
      y: -0.05936431884765625,
    },
    {
      x: 12020.38043478261,
      y: -0.074310302734375,
    },
    {
      x: 12024.45652173913,
      y: -0.08427810668945312,
    },
    {
      x: 12032.608695652174,
      y: -0.10421371459960938,
    },
    {
      x: 12036.684782608696,
      y: -0.11916351318359375,
    },
    {
      x: 12040.760869565218,
      y: -0.12913131713867188,
    },
    {
      x: 12044.83695652174,
      y: -0.13909912109375,
    },
    {
      x: 12048.913043478262,
      y: -0.15404891967773438,
    },
    {
      x: 12052.989130434782,
      y: -0.1640167236328125,
    },
    {
      x: 12057.065217391304,
      y: -0.17398452758789062,
    },
    {
      x: 12061.141304347826,
      y: -0.18394851684570312,
    },
    {
      x: 12065.217391304348,
      y: -0.1988983154296875,
    },
    {
      x: 12069.29347826087,
      y: -0.20886993408203125,
    },
    {
      x: 12073.369565217392,
      y: -0.1988983154296875,
    },
    {
      x: 12077.445652173914,
      y: -0.18394851684570312,
    },
    {
      x: 12081.521739130436,
      y: -0.16899871826171875,
    },
    {
      x: 12085.597826086956,
      y: -0.14906692504882812,
    },
    {
      x: 12089.673913043478,
      y: -0.13411712646484375,
    },
    {
      x: 12093.75,
      y: -0.1141815185546875,
    },
    {
      x: 12097.826086956522,
      y: -0.09923171997070312,
    },
    {
      x: 12101.902173913044,
      y: -0.07929611206054688,
    },
    {
      x: 12105.978260869566,
      y: -0.0643463134765625,
    },
    {
      x: 12110.054347826088,
      y: -0.049396514892578125,
    },
    {
      x: 12114.13043478261,
      y: -0.029460906982421875,
    },
    {
      x: 12118.20652173913,
      y: -0.0145111083984375,
    },
    {
      x: 12122.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 12126.358695652174,
      y: 0.015392303466796875,
    },
    {
      x: 12130.434782608696,
      y: 0.0353240966796875,
    },
    {
      x: 12134.510869565218,
      y: 0.050273895263671875,
    },
    {
      x: 12138.58695652174,
      y: 0.06522369384765625,
    },
    {
      x: 12142.663043478262,
      y: 0.0751953125,
    },
    {
      x: 12146.739130434782,
      y: 0.08017730712890625,
    },
    {
      x: 12150.815217391304,
      y: 0.08017730712890625,
    },
    {
      x: 12154.891304347826,
      y: 0.0851593017578125,
    },
    {
      x: 12158.967391304348,
      y: 0.0851593017578125,
    },
    {
      x: 12183.423913043478,
      y: 0.0851593017578125,
    },
    {
      x: 12187.5,
      y: 0.09014511108398438,
    },
    {
      x: 12191.576086956522,
      y: 0.09014511108398438,
    },
    {
      x: 12216.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 12220.108695652174,
      y: 0.09512710571289062,
    },
    {
      x: 12224.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 12460.597826086956,
      y: 0.09512710571289062,
    },
    {
      x: 12464.673913043478,
      y: 0.13001251220703125,
    },
    {
      x: 12468.75,
      y: 0.22469711303710938,
    },
    {
      x: 12472.826086956522,
      y: 0.3393211364746094,
    },
    {
      x: 12476.902173913044,
      y: 0.45892333984375,
    },
    {
      x: 12480.978260869566,
      y: 0.5785293579101562,
    },
    {
      x: 12485.054347826088,
      y: 0.7031173706054688,
    },
    {
      x: 12489.13043478261,
      y: 0.822723388671875,
    },
    {
      x: 12493.20652173913,
      y: 0.9423255920410156,
    },
    {
      x: 12497.282608695652,
      y: 1.0569496154785156,
    },
    {
      x: 12501.358695652174,
      y: 1.1765518188476562,
    },
    {
      x: 12505.434782608696,
      y: 1.2313690185546875,
    },
    {
      x: 12509.510869565218,
      y: 1.1765518188476562,
    },
    {
      x: 12513.58695652174,
      y: 1.0718994140625,
    },
    {
      x: 12517.663043478262,
      y: 0.9473075866699219,
    },
    {
      x: 12521.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 12525.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 12778.532608695652,
      y: 0.09512710571289062,
    },
    {
      x: 12782.608695652174,
      y: 0.09014511108398438,
    },
    {
      x: 12786.684782608696,
      y: 0.09014511108398438,
    },
    {
      x: 12790.760869565218,
      y: 0.09014511108398438,
    },
    {
      x: 12794.83695652174,
      y: 0.08017730712890625,
    },
    {
      x: 12798.913043478262,
      y: 0.050273895263671875,
    },
    {
      x: 12802.989130434784,
      y: 0.020374298095703125,
    },
    {
      x: 12807.065217391304,
      y: -0.004543304443359375,
    },
    {
      x: 12811.141304347826,
      y: -0.01949310302734375,
    },
    {
      x: 12815.217391304348,
      y: -0.034442901611328125,
    },
    {
      x: 12819.29347826087,
      y: -0.049396514892578125,
    },
    {
      x: 12823.369565217392,
      y: -0.05936431884765625,
    },
    {
      x: 12827.445652173914,
      y: -0.074310302734375,
    },
    {
      x: 12831.521739130436,
      y: -0.08427810668945312,
    },
    {
      x: 12839.673913043478,
      y: -0.10421371459960938,
    },
    {
      x: 12843.75,
      y: -0.11916351318359375,
    },
    {
      x: 12847.826086956522,
      y: -0.12913131713867188,
    },
    {
      x: 12851.902173913044,
      y: -0.13909912109375,
    },
    {
      x: 12855.978260869566,
      y: -0.15404891967773438,
    },
    {
      x: 12860.054347826088,
      y: -0.1640167236328125,
    },
    {
      x: 12864.13043478261,
      y: -0.17398452758789062,
    },
    {
      x: 12868.20652173913,
      y: -0.18394851684570312,
    },
    {
      x: 12872.282608695652,
      y: -0.1988983154296875,
    },
    {
      x: 12876.358695652174,
      y: -0.20886993408203125,
    },
    {
      x: 12880.434782608696,
      y: -0.1988983154296875,
    },
    {
      x: 12884.510869565218,
      y: -0.18394851684570312,
    },
    {
      x: 12888.58695652174,
      y: -0.16899871826171875,
    },
    {
      x: 12892.663043478262,
      y: -0.14906692504882812,
    },
    {
      x: 12896.739130434784,
      y: -0.13411712646484375,
    },
    {
      x: 12900.815217391304,
      y: -0.1141815185546875,
    },
    {
      x: 12904.891304347826,
      y: -0.09923171997070312,
    },
    {
      x: 12908.967391304348,
      y: -0.07929611206054688,
    },
    {
      x: 12913.04347826087,
      y: -0.0643463134765625,
    },
    {
      x: 12917.119565217392,
      y: -0.049396514892578125,
    },
    {
      x: 12921.195652173914,
      y: -0.029460906982421875,
    },
    {
      x: 12925.271739130436,
      y: -0.0145111083984375,
    },
    {
      x: 12929.347826086956,
      y: 0.0004425048828125,
    },
    {
      x: 12933.423913043478,
      y: 0.015392303466796875,
    },
    {
      x: 12937.5,
      y: 0.0353240966796875,
    },
    {
      x: 12941.576086956522,
      y: 0.050273895263671875,
    },
    {
      x: 12945.652173913044,
      y: 0.06522369384765625,
    },
    {
      x: 12949.728260869566,
      y: 0.0751953125,
    },
    {
      x: 12953.804347826088,
      y: 0.08017730712890625,
    },
    {
      x: 12957.88043478261,
      y: 0.08017730712890625,
    },
    {
      x: 12961.95652173913,
      y: 0.0851593017578125,
    },
    {
      x: 12966.032608695652,
      y: 0.0851593017578125,
    },
    {
      x: 12990.489130434784,
      y: 0.0851593017578125,
    },
    {
      x: 12994.565217391304,
      y: 0.09014511108398438,
    },
    {
      x: 12998.641304347826,
      y: 0.09014511108398438,
    },
    {
      x: 13023.097826086956,
      y: 0.09014511108398438,
    },
    {
      x: 13027.173913043478,
      y: 0.09512710571289062,
    },
    {
      x: 13031.25,
      y: 0.09512710571289062,
    },
    {
      x: 13039.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 13043.478260869566,
      y: 1.0569496154785156,
    },
    {
      x: 13047.554347826088,
      y: 1.1765518188476562,
    },
    {
      x: 13051.63043478261,
      y: 1.2313690185546875,
    },
    {
      x: 13055.70652173913,
      y: 1.1765518188476562,
    },
    {
      x: 13059.782608695652,
      y: 1.0718994140625,
    },
    {
      x: 13063.858695652174,
      y: 0.9473075866699219,
    },
    {
      x: 13067.934782608696,
      y: 0.8277053833007812,
    },
    {
      x: 13072.010869565218,
      y: 0.7031173706054688,
    },
    {
      x: 13080.163043478262,
      y: 0.45394134521484375,
    },
    {
      x: 13084.239130434784,
      y: 0.3343391418457031,
    },
    {
      x: 13088.315217391304,
      y: 0.209747314453125,
    },
    {
      x: 13092.391304347826,
      y: 0.11506271362304688,
    },
    {
      x: 13096.467391304348,
      y: 0.0751953125,
    },
    {
      x: 13100.54347826087,
      y: 0.06522369384765625,
    },
    {
      x: 13104.619565217392,
      y: 0.06024169921875,
    },
    {
      x: 13108.695652173914,
      y: 0.06024169921875,
    },
    {
      x: 13129.076086956522,
      y: 0.06024169921875,
    },
    {
      x: 13133.152173913044,
      y: 0.06522369384765625,
    },
    {
      x: 13137.228260869566,
      y: 0.06522369384765625,
    },
    {
      x: 13161.684782608696,
      y: 0.06522369384765625,
    },
    {
      x: 13165.760869565218,
      y: 0.07020950317382812,
    },
    {
      x: 13169.83695652174,
      y: 0.07020950317382812,
    },
    {
      x: 13194.29347826087,
      y: 0.07020950317382812,
    },
    {
      x: 13198.369565217392,
      y: 0.0751953125,
    },
    {
      x: 13202.445652173914,
      y: 0.0751953125,
    },
    {
      x: 13226.902173913044,
      y: 0.0751953125,
    },
    {
      x: 13230.978260869566,
      y: 0.08017730712890625,
    },
    {
      x: 13235.054347826088,
      y: 0.08017730712890625,
    },
    {
      x: 13259.510869565218,
      y: 0.08017730712890625,
    },
    {
      x: 13263.58695652174,
      y: 0.0851593017578125,
    },
    {
      x: 13267.663043478262,
      y: 0.0851593017578125,
    },
    {
      x: 13292.119565217392,
      y: 0.0851593017578125,
    },
    {
      x: 13296.195652173914,
      y: 0.09014511108398438,
    },
    {
      x: 13300.271739130436,
      y: 0.09014511108398438,
    },
    {
      x: 13304.347826086956,
      y: 0.09512710571289062,
    },
    {
      x: 13308.423913043478,
      y: 0.09512710571289062,
    },
    {
      x: 13528.532608695652,
      y: 0.09512710571289062,
    },
    {
      x: 13532.608695652174,
      y: 0.13001251220703125,
    },
    {
      x: 13536.684782608696,
      y: 0.22469711303710938,
    },
    {
      x: 13540.760869565218,
      y: 0.3393211364746094,
    },
    {
      x: 13544.83695652174,
      y: 0.45892333984375,
    },
    {
      x: 13548.913043478262,
      y: 0.5785293579101562,
    },
    {
      x: 13552.989130434784,
      y: 0.7031173706054688,
    },
    {
      x: 13557.065217391304,
      y: 0.822723388671875,
    },
    {
      x: 13561.141304347826,
      y: 0.9423255920410156,
    },
    {
      x: 13565.217391304348,
      y: 0.09014511108398438,
    },
    {
      x: 13569.29347826087,
      y: 0.09014511108398438,
    },
    {
      x: 13573.369565217392,
      y: 0.09512710571289062,
    },
    {
      x: 13577.445652173914,
      y: 0.09512710571289062,
    },
    {
      x: 13822.010869565218,
      y: 0.09512710571289062,
    },
    {
      x: 13826.08695652174,
      y: 0.08017730712890625,
    },
    {
      x: 13830.163043478262,
      y: 0.08017730712890625,
    },
    {
      x: 13838.315217391304,
      y: 0.08017730712890625,
    },
    {
      x: 13842.391304347826,
      y: 0.0851593017578125,
    },
    {
      x: 13846.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 13858.695652173914,
      y: 0.0851593017578125,
    },
    {
      x: 13862.771739130436,
      y: 0.0751953125,
    },
    {
      x: 13866.847826086956,
      y: 0.050273895263671875,
    },
    {
      x: 13870.923913043478,
      y: 0.015392303466796875,
    },
    {
      x: 13875,
      y: -0.004543304443359375,
    },
    {
      x: 13879.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 13883.152173913044,
      y: -0.034442901611328125,
    },
    {
      x: 13887.228260869566,
      y: -0.049396514892578125,
    },
    {
      x: 13891.304347826088,
      y: -0.05936431884765625,
    },
    {
      x: 13895.38043478261,
      y: -0.074310302734375,
    },
    {
      x: 13899.45652173913,
      y: -0.08427810668945312,
    },
    {
      x: 13907.608695652174,
      y: -0.10421371459960938,
    },
    {
      x: 13911.684782608696,
      y: -0.11916351318359375,
    },
    {
      x: 13915.760869565218,
      y: -0.12913131713867188,
    },
    {
      x: 13919.83695652174,
      y: -0.13909912109375,
    },
    {
      x: 13923.913043478262,
      y: -0.15404891967773438,
    },
    {
      x: 13927.989130434784,
      y: -0.1640167236328125,
    },
    {
      x: 13932.065217391304,
      y: -0.17398452758789062,
    },
    {
      x: 13936.141304347826,
      y: -0.18394851684570312,
    },
    {
      x: 13940.217391304348,
      y: -0.1988983154296875,
    },
    {
      x: 13944.29347826087,
      y: -0.20886993408203125,
    },
    {
      x: 13948.369565217392,
      y: -0.1988983154296875,
    },
    {
      x: 13952.445652173914,
      y: -0.18394851684570312,
    },
    {
      x: 13956.521739130436,
      y: -0.16899871826171875,
    },
    {
      x: 13960.597826086956,
      y: -0.14906692504882812,
    },
    {
      x: 13964.673913043478,
      y: -0.13411712646484375,
    },
    {
      x: 13968.75,
      y: -0.1141815185546875,
    },
    {
      x: 13972.826086956522,
      y: -0.09923171997070312,
    },
    {
      x: 13976.902173913044,
      y: -0.07929611206054688,
    },
    {
      x: 13980.978260869566,
      y: -0.0643463134765625,
    },
    {
      x: 13985.054347826088,
      y: -0.049396514892578125,
    },
    {
      x: 13989.13043478261,
      y: -0.029460906982421875,
    },
    {
      x: 13993.20652173913,
      y: -0.0145111083984375,
    },
    {
      x: 13997.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 14001.358695652174,
      y: 0.015392303466796875,
    },
    {
      x: 14005.434782608696,
      y: 0.0353240966796875,
    },
    {
      x: 14009.510869565218,
      y: 0.050273895263671875,
    },
    {
      x: 14013.58695652174,
      y: 0.06522369384765625,
    },
    {
      x: 14017.663043478262,
      y: 0.0751953125,
    },
    {
      x: 14021.739130434784,
      y: 0.08017730712890625,
    },
    {
      x: 14025.815217391304,
      y: 0.08017730712890625,
    },
    {
      x: 14029.891304347826,
      y: 0.0851593017578125,
    },
    {
      x: 14033.967391304348,
      y: 0.0851593017578125,
    },
    {
      x: 14058.423913043478,
      y: 0.0851593017578125,
    },
    {
      x: 14062.5,
      y: 0.09014511108398438,
    },
    {
      x: 14066.576086956522,
      y: 0.09014511108398438,
    },
    {
      x: 14082.88043478261,
      y: 0.09014511108398438,
    },
    {
      x: 14086.95652173913,
      y: 0.3393211364746094,
    },
    {
      x: 14091.032608695652,
      y: 0.45892333984375,
    },
    {
      x: 14095.108695652174,
      y: 0.5785293579101562,
    },
    {
      x: 14099.184782608696,
      y: 0.7031173706054688,
    },
    {
      x: 14103.260869565218,
      y: 0.822723388671875,
    },
    {
      x: 14107.33695652174,
      y: 0.9423255920410156,
    },
    {
      x: 14111.413043478262,
      y: 1.0569496154785156,
    },
    {
      x: 14115.489130434784,
      y: 1.1765518188476562,
    },
    {
      x: 14119.565217391304,
      y: 1.2313690185546875,
    },
    {
      x: 14123.641304347826,
      y: 1.1765518188476562,
    },
    {
      x: 14127.717391304348,
      y: 1.0669136047363281,
    },
    {
      x: 14131.79347826087,
      y: 0.9473075866699219,
    },
    {
      x: 14135.869565217392,
      y: 0.822723388671875,
    },
    {
      x: 14139.945652173914,
      y: 0.6981315612792969,
    },
    {
      x: 14144.021739130436,
      y: 0.57354736328125,
    },
    {
      x: 14148.097826086956,
      y: 0.4489555358886719,
    },
    {
      x: 14152.173913043478,
      y: 0.3293495178222656,
    },
    {
      x: 14156.25,
      y: 0.20476531982421875,
    },
    {
      x: 14160.326086956522,
      y: 0.10509490966796875,
    },
    {
      x: 14164.402173913044,
      y: 0.07020950317382812,
    },
    {
      x: 14168.478260869566,
      y: 0.06024169921875,
    },
    {
      x: 14172.554347826088,
      y: 0.05525970458984375,
    },
    {
      x: 14176.63043478261,
      y: 0.05525970458984375,
    },
    {
      x: 14197.010869565218,
      y: 0.05525970458984375,
    },
    {
      x: 14201.08695652174,
      y: 0.06024169921875,
    },
    {
      x: 14205.163043478262,
      y: 0.06024169921875,
    },
    {
      x: 14229.619565217392,
      y: 0.06024169921875,
    },
    {
      x: 14233.695652173914,
      y: 0.06522369384765625,
    },
    {
      x: 14237.771739130436,
      y: 0.06522369384765625,
    },
    {
      x: 14262.228260869566,
      y: 0.06522369384765625,
    },
    {
      x: 14266.304347826088,
      y: 0.07020950317382812,
    },
    {
      x: 14270.38043478261,
      y: 0.07020950317382812,
    },
    {
      x: 14294.83695652174,
      y: 0.07020950317382812,
    },
    {
      x: 14298.913043478262,
      y: 0.0751953125,
    },
    {
      x: 14302.989130434784,
      y: 0.0751953125,
    },
    {
      x: 14327.445652173914,
      y: 0.0751953125,
    },
    {
      x: 14331.521739130436,
      y: 0.08017730712890625,
    },
    {
      x: 14335.597826086956,
      y: 0.08017730712890625,
    },
    {
      x: 14343.75,
      y: 0.08017730712890625,
    },
    {
      x: 14347.826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 14351.902173913044,
      y: 0.09512710571289062,
    },
    {
      x: 14596.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 14600.54347826087,
      y: 0.13001251220703125,
    },
    {
      x: 14604.619565217392,
      y: 0.22469711303710938,
    },
    {
      x: 14608.695652173914,
      y: 0.09014511108398438,
    },
    {
      x: 14612.771739130436,
      y: 0.09014511108398438,
    },
    {
      x: 14637.228260869566,
      y: 0.09014511108398438,
    },
    {
      x: 14641.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 14645.38043478261,
      y: 0.09512710571289062,
    },
    {
      x: 14865.489130434784,
      y: 0.09512710571289062,
    },
    {
      x: 14869.565217391304,
      y: 0.0751953125,
    },
    {
      x: 14873.641304347826,
      y: 0.0751953125,
    },
    {
      x: 14877.717391304348,
      y: 0.08017730712890625,
    },
    {
      x: 14881.79347826087,
      y: 0.08017730712890625,
    },
    {
      x: 14906.25,
      y: 0.08017730712890625,
    },
    {
      x: 14910.326086956522,
      y: 0.0851593017578125,
    },
    {
      x: 14914.402173913044,
      y: 0.0851593017578125,
    },
    {
      x: 14926.63043478261,
      y: 0.0851593017578125,
    },
    {
      x: 14930.70652173913,
      y: 0.0751953125,
    },
    {
      x: 14934.782608695652,
      y: 0.050273895263671875,
    },
    {
      x: 14938.858695652174,
      y: 0.015392303466796875,
    },
    {
      x: 14942.934782608696,
      y: -0.004543304443359375,
    },
    {
      x: 14947.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 14951.08695652174,
      y: -0.034442901611328125,
    },
    {
      x: 14955.163043478262,
      y: -0.049396514892578125,
    },
    {
      x: 14959.239130434784,
      y: -0.05936431884765625,
    },
    {
      x: 14963.315217391304,
      y: -0.074310302734375,
    },
    {
      x: 14967.391304347826,
      y: -0.08427810668945312,
    },
    {
      x: 14975.54347826087,
      y: -0.10421371459960938,
    },
    {
      x: 14979.619565217392,
      y: -0.11916351318359375,
    },
    {
      x: 14983.695652173914,
      y: -0.12913131713867188,
    },
    {
      x: 14987.771739130436,
      y: -0.13909912109375,
    },
    {
      x: 14991.847826086956,
      y: -0.15404891967773438,
    },
    {
      x: 14995.923913043478,
      y: -0.1640167236328125,
    },
    {
      x: 15000,
      y: -0.17398452758789062,
    },
    {
      x: 15004.076086956522,
      y: -0.18394851684570312,
    },
    {
      x: 15008.152173913044,
      y: -0.19391632080078125,
    },
    {
      x: 15012.228260869566,
      y: -0.20388412475585938,
    },
    {
      x: 15016.304347826088,
      y: -0.1988983154296875,
    },
    {
      x: 15020.38043478261,
      y: -0.18394851684570312,
    },
    {
      x: 15024.45652173913,
      y: -0.16899871826171875,
    },
    {
      x: 15028.532608695652,
      y: -0.14906692504882812,
    },
    {
      x: 15032.608695652174,
      y: -0.13411712646484375,
    },
    {
      x: 15036.684782608696,
      y: -0.1141815185546875,
    },
    {
      x: 15040.760869565218,
      y: -0.09923171997070312,
    },
    {
      x: 15044.83695652174,
      y: -0.07929611206054688,
    },
    {
      x: 15048.913043478262,
      y: -0.0643463134765625,
    },
    {
      x: 15052.989130434784,
      y: -0.049396514892578125,
    },
    {
      x: 15057.065217391304,
      y: -0.029460906982421875,
    },
    {
      x: 15061.141304347826,
      y: -0.0145111083984375,
    },
    {
      x: 15065.217391304348,
      y: 0.0004425048828125,
    },
    {
      x: 15069.29347826087,
      y: 0.015392303466796875,
    },
    {
      x: 15073.369565217392,
      y: 0.0353240966796875,
    },
    {
      x: 15077.445652173914,
      y: 0.050273895263671875,
    },
    {
      x: 15081.521739130436,
      y: 0.06522369384765625,
    },
    {
      x: 15085.597826086956,
      y: 0.0751953125,
    },
    {
      x: 15089.673913043478,
      y: 0.08017730712890625,
    },
    {
      x: 15093.75,
      y: 0.08017730712890625,
    },
    {
      x: 15097.826086956522,
      y: 0.0851593017578125,
    },
    {
      x: 15101.902173913044,
      y: 0.0851593017578125,
    },
    {
      x: 15126.358695652174,
      y: 0.0851593017578125,
    },
    {
      x: 15130.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 15134.510869565218,
      y: 0.09512710571289062,
    },
    {
      x: 15142.663043478262,
      y: 0.09512710571289062,
    },
    {
      x: 15146.739130434784,
      y: 0.13001251220703125,
    },
    {
      x: 15150.815217391304,
      y: 0.22469711303710938,
    },
    {
      x: 15154.891304347826,
      y: 0.3393211364746094,
    },
    {
      x: 15158.967391304348,
      y: 0.45892333984375,
    },
    {
      x: 15163.04347826087,
      y: 0.5785293579101562,
    },
    {
      x: 15167.119565217392,
      y: 0.7031173706054688,
    },
    {
      x: 15171.195652173914,
      y: 0.822723388671875,
    },
    {
      x: 15175.271739130436,
      y: 0.9423255920410156,
    },
    {
      x: 15179.347826086956,
      y: 1.0569496154785156,
    },
    {
      x: 15183.423913043478,
      y: 1.1765518188476562,
    },
    {
      x: 15187.5,
      y: 1.2313690185546875,
    },
    {
      x: 15191.576086956522,
      y: 1.1765518188476562,
    },
    {
      x: 15195.652173913044,
      y: 1.0669136047363281,
    },
    {
      x: 15199.728260869566,
      y: 0.9473075866699219,
    },
    {
      x: 15203.804347826088,
      y: 0.822723388671875,
    },
    {
      x: 15207.88043478261,
      y: 0.6981315612792969,
    },
    {
      x: 15211.95652173913,
      y: 0.57354736328125,
    },
    {
      x: 15216.032608695652,
      y: 0.4489555358886719,
    },
    {
      x: 15220.108695652174,
      y: 0.3293495178222656,
    },
    {
      x: 15224.184782608696,
      y: 0.20476531982421875,
    },
    {
      x: 15228.260869565218,
      y: 0.10509490966796875,
    },
    {
      x: 15232.33695652174,
      y: 0.07020950317382812,
    },
    {
      x: 15236.413043478262,
      y: 0.06024169921875,
    },
    {
      x: 15240.489130434784,
      y: 0.05525970458984375,
    },
    {
      x: 15244.565217391304,
      y: 0.05525970458984375,
    },
    {
      x: 15264.945652173914,
      y: 0.05525970458984375,
    },
    {
      x: 15269.021739130436,
      y: 0.06024169921875,
    },
    {
      x: 15273.097826086956,
      y: 0.06024169921875,
    },
    {
      x: 15297.554347826088,
      y: 0.06024169921875,
    },
    {
      x: 15301.63043478261,
      y: 0.06522369384765625,
    },
    {
      x: 15305.70652173913,
      y: 0.06522369384765625,
    },
    {
      x: 15330.163043478262,
      y: 0.06522369384765625,
    },
    {
      x: 15334.239130434784,
      y: 0.07020950317382812,
    },
    {
      x: 15338.315217391304,
      y: 0.07020950317382812,
    },
    {
      x: 15362.771739130436,
      y: 0.07020950317382812,
    },
    {
      x: 15366.847826086958,
      y: 0.0751953125,
    },
    {
      x: 15370.923913043478,
      y: 0.0751953125,
    },
    {
      x: 15387.228260869566,
      y: 0.0751953125,
    },
    {
      x: 15391.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 15395.38043478261,
      y: 0.09512710571289062,
    },
    {
      x: 15648.097826086958,
      y: 0.09512710571289062,
    },
    {
      x: 15652.173913043478,
      y: 0.0751953125,
    },
    {
      x: 15656.25,
      y: 0.0751953125,
    },
    {
      x: 15680.70652173913,
      y: 0.0751953125,
    },
    {
      x: 15684.782608695652,
      y: 0.08017730712890625,
    },
    {
      x: 15688.858695652174,
      y: 0.08017730712890625,
    },
    {
      x: 15713.315217391304,
      y: 0.08017730712890625,
    },
    {
      x: 15717.391304347826,
      y: 0.0851593017578125,
    },
    {
      x: 15721.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 15733.695652173914,
      y: 0.0851593017578125,
    },
    {
      x: 15737.771739130436,
      y: 0.0751953125,
    },
    {
      x: 15741.847826086958,
      y: 0.050273895263671875,
    },
    {
      x: 15745.923913043478,
      y: 0.015392303466796875,
    },
    {
      x: 15750,
      y: -0.004543304443359375,
    },
    {
      x: 15754.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 15758.152173913044,
      y: -0.034442901611328125,
    },
    {
      x: 15762.228260869566,
      y: -0.049396514892578125,
    },
    {
      x: 15766.304347826088,
      y: -0.05936431884765625,
    },
    {
      x: 15770.38043478261,
      y: -0.074310302734375,
    },
    {
      x: 15774.45652173913,
      y: -0.08427810668945312,
    },
    {
      x: 15782.608695652174,
      y: -0.10421371459960938,
    },
    {
      x: 15786.684782608696,
      y: -0.11916351318359375,
    },
    {
      x: 15790.760869565218,
      y: -0.12913131713867188,
    },
    {
      x: 15794.83695652174,
      y: -0.13909912109375,
    },
    {
      x: 15798.913043478262,
      y: -0.15404891967773438,
    },
    {
      x: 15802.989130434784,
      y: -0.1640167236328125,
    },
    {
      x: 15807.065217391304,
      y: -0.17398452758789062,
    },
    {
      x: 15811.141304347826,
      y: -0.18394851684570312,
    },
    {
      x: 15815.217391304348,
      y: -0.1988983154296875,
    },
    {
      x: 15819.29347826087,
      y: -0.20886993408203125,
    },
    {
      x: 15823.369565217392,
      y: -0.1988983154296875,
    },
    {
      x: 15827.445652173914,
      y: -0.18394851684570312,
    },
    {
      x: 15831.521739130436,
      y: -0.16899871826171875,
    },
    {
      x: 15835.597826086958,
      y: -0.14906692504882812,
    },
    {
      x: 15839.673913043478,
      y: -0.13411712646484375,
    },
    {
      x: 15843.75,
      y: -0.1141815185546875,
    },
    {
      x: 15847.826086956522,
      y: -0.09923171997070312,
    },
    {
      x: 15851.902173913044,
      y: -0.07929611206054688,
    },
    {
      x: 15855.978260869566,
      y: -0.0643463134765625,
    },
    {
      x: 15860.054347826088,
      y: -0.049396514892578125,
    },
    {
      x: 15864.13043478261,
      y: -0.029460906982421875,
    },
    {
      x: 15868.20652173913,
      y: -0.0145111083984375,
    },
    {
      x: 15872.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 15876.358695652174,
      y: 0.015392303466796875,
    },
    {
      x: 15880.434782608696,
      y: 0.0353240966796875,
    },
    {
      x: 15884.510869565218,
      y: 0.050273895263671875,
    },
    {
      x: 15888.58695652174,
      y: 0.06522369384765625,
    },
    {
      x: 15892.663043478262,
      y: 0.0751953125,
    },
    {
      x: 15896.739130434784,
      y: 0.08017730712890625,
    },
    {
      x: 15900.815217391304,
      y: 0.08017730712890625,
    },
    {
      x: 15904.891304347826,
      y: 0.0851593017578125,
    },
    {
      x: 15908.967391304348,
      y: 0.0851593017578125,
    },
    {
      x: 15913.04347826087,
      y: 0.09512710571289062,
    },
    {
      x: 15917.119565217392,
      y: 0.09512710571289062,
    },
    {
      x: 15949.728260869566,
      y: 0.09512710571289062,
    },
    {
      x: 15953.804347826088,
      y: 0.13001251220703125,
    },
    {
      x: 15957.88043478261,
      y: 0.22469711303710938,
    },
    {
      x: 15961.95652173913,
      y: 0.3393211364746094,
    },
    {
      x: 15966.032608695652,
      y: 0.45892333984375,
    },
    {
      x: 15970.108695652174,
      y: 0.5785293579101562,
    },
    {
      x: 15974.184782608696,
      y: 0.7031173706054688,
    },
    {
      x: 15978.260869565218,
      y: 0.822723388671875,
    },
    {
      x: 15982.33695652174,
      y: 0.9423255920410156,
    },
    {
      x: 15986.413043478262,
      y: 1.0569496154785156,
    },
    {
      x: 15990.489130434784,
      y: 1.1765518188476562,
    },
    {
      x: 15994.565217391304,
      y: 1.2313690185546875,
    },
    {
      x: 15998.641304347826,
      y: 1.1765518188476562,
    },
    {
      x: 16002.717391304348,
      y: 1.0669136047363281,
    },
    {
      x: 16006.79347826087,
      y: 0.9473075866699219,
    },
    {
      x: 16010.869565217392,
      y: 0.822723388671875,
    },
    {
      x: 16014.945652173914,
      y: 0.6981315612792969,
    },
    {
      x: 16019.021739130436,
      y: 0.57354736328125,
    },
    {
      x: 16023.097826086958,
      y: 0.4489555358886719,
    },
    {
      x: 16027.173913043478,
      y: 0.3293495178222656,
    },
    {
      x: 16031.25,
      y: 0.20476531982421875,
    },
    {
      x: 16035.326086956522,
      y: 0.10509490966796875,
    },
    {
      x: 16039.402173913044,
      y: 0.07020950317382812,
    },
    {
      x: 16043.478260869566,
      y: 0.06024169921875,
    },
    {
      x: 16047.554347826088,
      y: 0.05525970458984375,
    },
    {
      x: 16051.63043478261,
      y: 0.05525970458984375,
    },
    {
      x: 16072.010869565218,
      y: 0.05525970458984375,
    },
    {
      x: 16076.08695652174,
      y: 0.06024169921875,
    },
    {
      x: 16080.163043478262,
      y: 0.06024169921875,
    },
    {
      x: 16104.619565217392,
      y: 0.06024169921875,
    },
    {
      x: 16108.695652173914,
      y: 0.06522369384765625,
    },
    {
      x: 16112.771739130436,
      y: 0.06522369384765625,
    },
    {
      x: 16137.228260869566,
      y: 0.06522369384765625,
    },
    {
      x: 16141.304347826088,
      y: 0.07020950317382812,
    },
    {
      x: 16145.38043478261,
      y: 0.07020950317382812,
    },
    {
      x: 16169.83695652174,
      y: 0.07020950317382812,
    },
    {
      x: 16173.913043478262,
      y: 0.0851593017578125,
    },
    {
      x: 16177.989130434784,
      y: 0.0851593017578125,
    },
    {
      x: 16194.29347826087,
      y: 0.0851593017578125,
    },
    {
      x: 16198.369565217392,
      y: 0.09014511108398438,
    },
    {
      x: 16202.445652173914,
      y: 0.09014511108398438,
    },
    {
      x: 16226.902173913044,
      y: 0.09014511108398438,
    },
    {
      x: 16230.978260869566,
      y: 0.09512710571289062,
    },
    {
      x: 16235.054347826088,
      y: 0.09512710571289062,
    },
    {
      x: 16952.445652173912,
      y: 0.09512710571289062,
    },
    {
      x: 16956.521739130436,
      y: 0.06522369384765625,
    },
    {
      x: 16960.597826086956,
      y: 0.0751953125,
    },
    {
      x: 16964.67391304348,
      y: 0.08017730712890625,
    },
    {
      x: 16968.75,
      y: 0.08017730712890625,
    },
    {
      x: 16972.826086956524,
      y: 0.0851593017578125,
    },
    {
      x: 16976.902173913044,
      y: 0.0851593017578125,
    },
    {
      x: 17001.358695652176,
      y: 0.0851593017578125,
    },
    {
      x: 17005.434782608696,
      y: 0.09014511108398438,
    },
    {
      x: 17009.510869565216,
      y: 0.09014511108398438,
    },
    {
      x: 17033.967391304348,
      y: 0.09014511108398438,
    },
    {
      x: 17038.04347826087,
      y: 0.09512710571289062,
    },
    {
      x: 17042.119565217392,
      y: 0.09512710571289062,
    },
    {
      x: 17213.315217391304,
      y: 0.09512710571289062,
    },
    {
      x: 17217.391304347828,
      y: 0.07020950317382812,
    },
    {
      x: 17221.467391304348,
      y: 0.07020950317382812,
    },
    {
      x: 17237.771739130436,
      y: 0.07020950317382812,
    },
    {
      x: 17241.847826086956,
      y: 0.0751953125,
    },
    {
      x: 17245.92391304348,
      y: 0.0751953125,
    },
    {
      x: 17270.380434782608,
      y: 0.0751953125,
    },
    {
      x: 17274.456521739132,
      y: 0.08017730712890625,
    },
    {
      x: 17278.532608695652,
      y: 0.08017730712890625,
    },
    {
      x: 17302.989130434784,
      y: 0.08017730712890625,
    },
    {
      x: 17307.065217391304,
      y: 0.0851593017578125,
    },
    {
      x: 17311.141304347828,
      y: 0.0851593017578125,
    },
    {
      x: 17323.369565217392,
      y: 0.0851593017578125,
    },
    {
      x: 17327.445652173912,
      y: 0.0751953125,
    },
    {
      x: 17331.521739130436,
      y: 0.050273895263671875,
    },
    {
      x: 17335.597826086956,
      y: 0.015392303466796875,
    },
    {
      x: 17339.67391304348,
      y: -0.004543304443359375,
    },
    {
      x: 17343.75,
      y: -0.01949310302734375,
    },
    {
      x: 17347.826086956524,
      y: -0.034442901611328125,
    },
    {
      x: 17351.902173913044,
      y: -0.049396514892578125,
    },
    {
      x: 17355.978260869564,
      y: -0.05936431884765625,
    },
    {
      x: 17360.054347826088,
      y: -0.074310302734375,
    },
    {
      x: 17364.130434782608,
      y: -0.08427810668945312,
    },
    {
      x: 17372.282608695652,
      y: -0.10421371459960938,
    },
    {
      x: 17376.358695652176,
      y: -0.11916351318359375,
    },
    {
      x: 17380.434782608696,
      y: -0.12913131713867188,
    },
    {
      x: 17384.510869565216,
      y: -0.13909912109375,
    },
    {
      x: 17388.58695652174,
      y: -0.15404891967773438,
    },
    {
      x: 17392.66304347826,
      y: -0.1640167236328125,
    },
    {
      x: 17396.739130434784,
      y: -0.17398452758789062,
    },
    {
      x: 17400.815217391304,
      y: -0.18394851684570312,
    },
    {
      x: 17404.891304347828,
      y: -0.1988983154296875,
    },
    {
      x: 17408.967391304348,
      y: -0.20886993408203125,
    },
    {
      x: 17413.04347826087,
      y: -0.1988983154296875,
    },
    {
      x: 17417.119565217392,
      y: -0.18394851684570312,
    },
    {
      x: 17421.195652173912,
      y: -0.16899871826171875,
    },
    {
      x: 17425.271739130436,
      y: -0.14906692504882812,
    },
    {
      x: 17429.347826086956,
      y: -0.13411712646484375,
    },
    {
      x: 17433.42391304348,
      y: -0.1141815185546875,
    },
    {
      x: 17437.5,
      y: -0.09923171997070312,
    },
    {
      x: 17441.576086956524,
      y: -0.07929611206054688,
    },
    {
      x: 17445.652173913044,
      y: -0.0643463134765625,
    },
    {
      x: 17449.728260869564,
      y: -0.049396514892578125,
    },
    {
      x: 17453.804347826088,
      y: -0.029460906982421875,
    },
    {
      x: 17457.880434782608,
      y: -0.0145111083984375,
    },
    {
      x: 17461.956521739132,
      y: 0.0004425048828125,
    },
    {
      x: 17466.032608695652,
      y: 0.015392303466796875,
    },
    {
      x: 17470.108695652176,
      y: 0.0353240966796875,
    },
    {
      x: 17474.184782608696,
      y: 0.050273895263671875,
    },
    {
      x: 17478.260869565216,
      y: 0.09512710571289062,
    },
    {
      x: 17482.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 17539.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 17543.478260869564,
      y: 0.13001251220703125,
    },
    {
      x: 17547.554347826088,
      y: 0.22469711303710938,
    },
    {
      x: 17551.630434782608,
      y: 0.3393211364746094,
    },
    {
      x: 17555.706521739132,
      y: 0.45892333984375,
    },
    {
      x: 17559.782608695652,
      y: 0.5785293579101562,
    },
    {
      x: 17563.858695652176,
      y: 0.7031173706054688,
    },
    {
      x: 17567.934782608696,
      y: 0.822723388671875,
    },
    {
      x: 17572.010869565216,
      y: 0.9423255920410156,
    },
    {
      x: 17576.08695652174,
      y: 1.0569496154785156,
    },
    {
      x: 17580.16304347826,
      y: 1.1765518188476562,
    },
    {
      x: 17584.239130434784,
      y: 1.2313690185546875,
    },
    {
      x: 17588.315217391304,
      y: 1.1765518188476562,
    },
    {
      x: 17592.391304347828,
      y: 1.0669136047363281,
    },
    {
      x: 17596.467391304348,
      y: 0.9473075866699219,
    },
    {
      x: 17600.54347826087,
      y: 0.822723388671875,
    },
    {
      x: 17604.619565217392,
      y: 0.6981315612792969,
    },
    {
      x: 17608.695652173912,
      y: 0.57354736328125,
    },
    {
      x: 17612.771739130436,
      y: 0.4489555358886719,
    },
    {
      x: 17616.847826086956,
      y: 0.3293495178222656,
    },
    {
      x: 17620.92391304348,
      y: 0.20476531982421875,
    },
    {
      x: 17625,
      y: 0.10509490966796875,
    },
    {
      x: 17629.076086956524,
      y: 0.07020950317382812,
    },
    {
      x: 17633.152173913044,
      y: 0.06024169921875,
    },
    {
      x: 17637.228260869564,
      y: 0.05525970458984375,
    },
    {
      x: 17641.304347826088,
      y: 0.05525970458984375,
    },
    {
      x: 17661.684782608696,
      y: 0.05525970458984375,
    },
    {
      x: 17665.760869565216,
      y: 0.06024169921875,
    },
    {
      x: 17669.83695652174,
      y: 0.06024169921875,
    },
    {
      x: 17694.29347826087,
      y: 0.06024169921875,
    },
    {
      x: 17698.369565217392,
      y: 0.06522369384765625,
    },
    {
      x: 17702.445652173912,
      y: 0.06522369384765625,
    },
    {
      x: 17726.902173913044,
      y: 0.06522369384765625,
    },
    {
      x: 17730.978260869564,
      y: 0.07020950317382812,
    },
    {
      x: 17735.054347826088,
      y: 0.07020950317382812,
    },
    {
      x: 17739.130434782608,
      y: -0.029460906982421875,
    },
    {
      x: 17743.206521739132,
      y: -0.0145111083984375,
    },
    {
      x: 17747.282608695652,
      y: 0.0004425048828125,
    },
    {
      x: 17751.358695652176,
      y: 0.015392303466796875,
    },
    {
      x: 17755.434782608696,
      y: 0.0353240966796875,
    },
    {
      x: 17759.510869565216,
      y: 0.050273895263671875,
    },
    {
      x: 17763.58695652174,
      y: 0.06522369384765625,
    },
    {
      x: 17767.66304347826,
      y: 0.0751953125,
    },
    {
      x: 17771.739130434784,
      y: 0.08017730712890625,
    },
    {
      x: 17775.815217391304,
      y: 0.08017730712890625,
    },
    {
      x: 17779.891304347828,
      y: 0.0851593017578125,
    },
    {
      x: 17783.967391304348,
      y: 0.0851593017578125,
    },
    {
      x: 17808.42391304348,
      y: 0.0851593017578125,
    },
    {
      x: 17812.5,
      y: 0.09014511108398438,
    },
    {
      x: 17816.576086956524,
      y: 0.09014511108398438,
    },
    {
      x: 17841.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 17845.108695652176,
      y: 0.09512710571289062,
    },
    {
      x: 17849.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 17995.92391304348,
      y: 0.09512710571289062,
    },
    {
      x: 18000,
      y: 0.06522369384765625,
    },
    {
      x: 18004.076086956524,
      y: 0.06522369384765625,
    },
    {
      x: 18012.228260869564,
      y: 0.06522369384765625,
    },
    {
      x: 18016.304347826088,
      y: 0.07020950317382812,
    },
    {
      x: 18020.380434782608,
      y: 0.07020950317382812,
    },
    {
      x: 18044.83695652174,
      y: 0.07020950317382812,
    },
    {
      x: 18048.91304347826,
      y: 0.0751953125,
    },
    {
      x: 18052.989130434784,
      y: 0.0751953125,
    },
    {
      x: 18077.445652173912,
      y: 0.0751953125,
    },
    {
      x: 18081.521739130436,
      y: 0.08017730712890625,
    },
    {
      x: 18085.597826086956,
      y: 0.08017730712890625,
    },
    {
      x: 18110.054347826088,
      y: 0.08017730712890625,
    },
    {
      x: 18114.130434782608,
      y: 0.0851593017578125,
    },
    {
      x: 18118.206521739132,
      y: 0.0851593017578125,
    },
    {
      x: 18130.434782608696,
      y: 0.0851593017578125,
    },
    {
      x: 18134.510869565216,
      y: 0.0751953125,
    },
    {
      x: 18138.58695652174,
      y: 0.050273895263671875,
    },
    {
      x: 18142.66304347826,
      y: 0.015392303466796875,
    },
    {
      x: 18146.739130434784,
      y: -0.004543304443359375,
    },
    {
      x: 18150.815217391304,
      y: -0.01949310302734375,
    },
    {
      x: 18154.891304347828,
      y: -0.034442901611328125,
    },
    {
      x: 18158.967391304348,
      y: -0.049396514892578125,
    },
    {
      x: 18163.04347826087,
      y: -0.05936431884765625,
    },
    {
      x: 18167.119565217392,
      y: -0.074310302734375,
    },
    {
      x: 18171.195652173912,
      y: -0.08427810668945312,
    },
    {
      x: 18179.347826086956,
      y: -0.10421371459960938,
    },
    {
      x: 18183.42391304348,
      y: -0.11916351318359375,
    },
    {
      x: 18187.5,
      y: -0.12913131713867188,
    },
    {
      x: 18191.576086956524,
      y: -0.13909912109375,
    },
    {
      x: 18195.652173913044,
      y: -0.15404891967773438,
    },
    {
      x: 18199.728260869564,
      y: -0.1640167236328125,
    },
    {
      x: 18203.804347826088,
      y: -0.17398452758789062,
    },
    {
      x: 18207.880434782608,
      y: -0.18394851684570312,
    },
    {
      x: 18211.956521739132,
      y: -0.1988983154296875,
    },
    {
      x: 18216.032608695652,
      y: -0.20886993408203125,
    },
    {
      x: 18220.108695652176,
      y: -0.1988983154296875,
    },
    {
      x: 18224.184782608696,
      y: -0.18394851684570312,
    },
    {
      x: 18228.260869565216,
      y: -0.16899871826171875,
    },
    {
      x: 18232.33695652174,
      y: -0.14906692504882812,
    },
    {
      x: 18236.41304347826,
      y: -0.13411712646484375,
    },
    {
      x: 18240.489130434784,
      y: -0.1141815185546875,
    },
    {
      x: 18244.565217391304,
      y: -0.09923171997070312,
    },
    {
      x: 18248.641304347828,
      y: -0.07929611206054688,
    },
    {
      x: 18252.717391304348,
      y: -0.0643463134765625,
    },
    {
      x: 18256.79347826087,
      y: -0.049396514892578125,
    },
    {
      x: 18260.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 18264.945652173912,
      y: 0.09512710571289062,
    },
    {
      x: 18346.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 18350.54347826087,
      y: 0.13001251220703125,
    },
    {
      x: 18354.619565217392,
      y: 0.22469711303710938,
    },
    {
      x: 18358.695652173912,
      y: 0.3393211364746094,
    },
    {
      x: 18362.771739130436,
      y: 0.45892333984375,
    },
    {
      x: 18366.847826086956,
      y: 0.5785293579101562,
    },
    {
      x: 18370.92391304348,
      y: 0.7031173706054688,
    },
    {
      x: 18375,
      y: 0.822723388671875,
    },
    {
      x: 18379.076086956524,
      y: 0.9423255920410156,
    },
    {
      x: 18383.152173913044,
      y: 1.0569496154785156,
    },
    {
      x: 18387.228260869564,
      y: 1.1765518188476562,
    },
    {
      x: 18391.304347826088,
      y: 1.2313690185546875,
    },
    {
      x: 18395.380434782608,
      y: 1.1765518188476562,
    },
    {
      x: 18399.456521739132,
      y: 1.0669136047363281,
    },
    {
      x: 18403.532608695652,
      y: 0.9473075866699219,
    },
    {
      x: 18407.608695652176,
      y: 0.822723388671875,
    },
    {
      x: 18411.684782608696,
      y: 0.6981315612792969,
    },
    {
      x: 18415.760869565216,
      y: 0.57354736328125,
    },
    {
      x: 18419.83695652174,
      y: 0.4489555358886719,
    },
    {
      x: 18423.91304347826,
      y: 0.3293495178222656,
    },
    {
      x: 18427.989130434784,
      y: 0.20476531982421875,
    },
    {
      x: 18432.065217391304,
      y: 0.10509490966796875,
    },
    {
      x: 18436.141304347828,
      y: 0.07020950317382812,
    },
    {
      x: 18440.217391304348,
      y: 0.06024169921875,
    },
    {
      x: 18444.29347826087,
      y: 0.05525970458984375,
    },
    {
      x: 18448.369565217392,
      y: 0.05525970458984375,
    },
    {
      x: 18468.75,
      y: 0.05525970458984375,
    },
    {
      x: 18472.826086956524,
      y: 0.06024169921875,
    },
    {
      x: 18476.902173913044,
      y: 0.06024169921875,
    },
    {
      x: 18501.358695652176,
      y: 0.06024169921875,
    },
    {
      x: 18505.434782608696,
      y: 0.06522369384765625,
    },
    {
      x: 18509.510869565216,
      y: 0.06522369384765625,
    },
    {
      x: 18517.66304347826,
      y: 0.06522369384765625,
    },
    {
      x: 18521.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 18525.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 18778.532608695652,
      y: 0.09512710571289062,
    },
    {
      x: 18782.608695652176,
      y: -0.13411712646484375,
    },
    {
      x: 18786.684782608696,
      y: -0.1141815185546875,
    },
    {
      x: 18790.760869565216,
      y: -0.09923171997070312,
    },
    {
      x: 18794.83695652174,
      y: -0.07929611206054688,
    },
    {
      x: 18798.91304347826,
      y: -0.0643463134765625,
    },
    {
      x: 18802.989130434784,
      y: -0.049396514892578125,
    },
    {
      x: 18807.065217391304,
      y: -0.029460906982421875,
    },
    {
      x: 18811.141304347828,
      y: -0.0145111083984375,
    },
    {
      x: 18815.217391304348,
      y: 0.0004425048828125,
    },
    {
      x: 18819.29347826087,
      y: 0.015392303466796875,
    },
    {
      x: 18823.369565217392,
      y: 0.0353240966796875,
    },
    {
      x: 18827.445652173912,
      y: 0.050273895263671875,
    },
    {
      x: 18831.521739130436,
      y: 0.06522369384765625,
    },
    {
      x: 18835.597826086956,
      y: 0.0751953125,
    },
    {
      x: 18839.67391304348,
      y: 0.08017730712890625,
    },
    {
      x: 18843.75,
      y: 0.08017730712890625,
    },
    {
      x: 18847.826086956524,
      y: 0.0851593017578125,
    },
    {
      x: 18851.902173913044,
      y: 0.0851593017578125,
    },
    {
      x: 18876.358695652176,
      y: 0.0851593017578125,
    },
    {
      x: 18880.434782608696,
      y: 0.09014511108398438,
    },
    {
      x: 18884.510869565216,
      y: 0.09014511108398438,
    },
    {
      x: 18908.967391304348,
      y: 0.09014511108398438,
    },
    {
      x: 18913.04347826087,
      y: 0.09512710571289062,
    },
    {
      x: 18917.119565217392,
      y: 0.09512710571289062,
    },
    {
      x: 19153.532608695652,
      y: 0.09512710571289062,
    },
    {
      x: 19157.608695652176,
      y: 0.13001251220703125,
    },
    {
      x: 19161.684782608696,
      y: 0.22469711303710938,
    },
    {
      x: 19165.760869565216,
      y: 0.3393211364746094,
    },
    {
      x: 19169.83695652174,
      y: 0.45892333984375,
    },
    {
      x: 19173.91304347826,
      y: 0.5785293579101562,
    },
    {
      x: 19177.989130434784,
      y: 0.7031173706054688,
    },
    {
      x: 19182.065217391304,
      y: 0.822723388671875,
    },
    {
      x: 19186.141304347828,
      y: 0.9423255920410156,
    },
    {
      x: 19190.217391304348,
      y: 1.0569496154785156,
    },
    {
      x: 19194.29347826087,
      y: 1.1765518188476562,
    },
    {
      x: 19198.369565217392,
      y: 1.2313690185546875,
    },
    {
      x: 19202.445652173912,
      y: 1.1765518188476562,
    },
    {
      x: 19206.521739130436,
      y: 1.0669136047363281,
    },
    {
      x: 19210.597826086956,
      y: 0.9473075866699219,
    },
    {
      x: 19214.67391304348,
      y: 0.822723388671875,
    },
    {
      x: 19218.75,
      y: 0.6981315612792969,
    },
    {
      x: 19222.826086956524,
      y: 0.57354736328125,
    },
    {
      x: 19226.902173913044,
      y: 0.4489555358886719,
    },
    {
      x: 19230.978260869564,
      y: 0.3293495178222656,
    },
    {
      x: 19235.054347826088,
      y: 0.20476531982421875,
    },
    {
      x: 19239.130434782608,
      y: 0.10509490966796875,
    },
    {
      x: 19243.206521739132,
      y: 0.07020950317382812,
    },
    {
      x: 19247.282608695652,
      y: 0.06024169921875,
    },
    {
      x: 19251.358695652176,
      y: 0.05525970458984375,
    },
    {
      x: 19255.434782608696,
      y: 0.05525970458984375,
    },
    {
      x: 19275.815217391304,
      y: 0.05525970458984375,
    },
    {
      x: 19279.891304347828,
      y: 0.06024169921875,
    },
    {
      x: 19283.967391304348,
      y: 0.06024169921875,
    },
    {
      x: 19308.42391304348,
      y: 0.06024169921875,
    },
    {
      x: 19312.5,
      y: 0.06522369384765625,
    },
    {
      x: 19316.576086956524,
      y: 0.06522369384765625,
    },
    {
      x: 19341.032608695652,
      y: 0.06522369384765625,
    },
    {
      x: 19345.108695652176,
      y: 0.07020950317382812,
    },
    {
      x: 19349.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 19373.641304347828,
      y: 0.07020950317382812,
    },
    {
      x: 19377.717391304348,
      y: 0.0751953125,
    },
    {
      x: 19381.79347826087,
      y: 0.0751953125,
    },
    {
      x: 19406.25,
      y: 0.0751953125,
    },
    {
      x: 19410.326086956524,
      y: 0.08017730712890625,
    },
    {
      x: 19414.402173913044,
      y: 0.08017730712890625,
    },
    {
      x: 19438.858695652176,
      y: 0.08017730712890625,
    },
    {
      x: 19442.934782608696,
      y: 0.0851593017578125,
    },
    {
      x: 19447.010869565216,
      y: 0.0851593017578125,
    },
    {
      x: 19459.239130434784,
      y: 0.0851593017578125,
    },
    {
      x: 19463.315217391304,
      y: 0.0751953125,
    },
    {
      x: 19467.391304347828,
      y: 0.050273895263671875,
    },
    {
      x: 19471.467391304348,
      y: 0.015392303466796875,
    },
    {
      x: 19475.54347826087,
      y: -0.004543304443359375,
    },
    {
      x: 19479.619565217392,
      y: -0.01949310302734375,
    },
    {
      x: 19483.695652173912,
      y: -0.034442901611328125,
    },
    {
      x: 19487.771739130436,
      y: -0.049396514892578125,
    },
    {
      x: 19491.847826086956,
      y: -0.05936431884765625,
    },
    {
      x: 19495.92391304348,
      y: -0.074310302734375,
    },
    {
      x: 19500,
      y: -0.08427810668945312,
    },
    {
      x: 19508.152173913044,
      y: -0.10421371459960938,
    },
    {
      x: 19512.228260869564,
      y: -0.11916351318359375,
    },
    {
      x: 19516.304347826088,
      y: -0.12913131713867188,
    },
    {
      x: 19520.380434782608,
      y: -0.13909912109375,
    },
    {
      x: 19524.456521739132,
      y: -0.15404891967773438,
    },
    {
      x: 19528.532608695652,
      y: -0.1640167236328125,
    },
    {
      x: 19532.608695652176,
      y: -0.17398452758789062,
    },
    {
      x: 19536.684782608696,
      y: -0.18394851684570312,
    },
    {
      x: 19540.76086956522,
      y: -0.19391632080078125,
    },
    {
      x: 19544.83695652174,
      y: -0.20388412475585938,
    },
    {
      x: 19548.91304347826,
      y: -0.1988983154296875,
    },
    {
      x: 19552.989130434784,
      y: -0.18394851684570312,
    },
    {
      x: 19557.065217391304,
      y: -0.16899871826171875,
    },
    {
      x: 19561.141304347828,
      y: -0.14906692504882812,
    },
    {
      x: 19565.217391304348,
      y: 0.09512710571289062,
    },
    {
      x: 19569.29347826087,
      y: 0.09512710571289062,
    },
    {
      x: 19960.597826086956,
      y: 0.09512710571289062,
    },
    {
      x: 19964.67391304348,
      y: 0.13001251220703125,
    },
    {
      x: 19968.75,
      y: 0.22469711303710938,
    },
    {
      x: 19972.826086956524,
      y: 0.3393211364746094,
    },
    {
      x: 19976.902173913044,
      y: 0.45892333984375,
    },
    {
      x: 19980.978260869564,
      y: 0.5785293579101562,
    },
    {
      x: 19985.054347826088,
      y: 0.7031173706054688,
    },
    {
      x: 19989.130434782608,
      y: 0.822723388671875,
    },
    {
      x: 19993.206521739132,
      y: 0.9423255920410156,
    },
    {
      x: 19997.282608695652,
      y: 1.0569496154785156,
    },
    {
      x: 20001.358695652176,
      y: 1.1765518188476562,
    },
    {
      x: 20005.434782608696,
      y: 1.2313690185546875,
    },
    {
      x: 20009.51086956522,
      y: 1.1765518188476562,
    },
    {
      x: 20013.58695652174,
      y: 1.0669136047363281,
    },
    {
      x: 20017.66304347826,
      y: 0.9473075866699219,
    },
    {
      x: 20021.739130434784,
      y: 0.822723388671875,
    },
    {
      x: 20025.815217391304,
      y: 0.6981315612792969,
    },
    {
      x: 20029.891304347828,
      y: 0.57354736328125,
    },
    {
      x: 20033.967391304348,
      y: 0.4489555358886719,
    },
    {
      x: 20038.04347826087,
      y: 0.3293495178222656,
    },
    {
      x: 20042.119565217392,
      y: 0.20476531982421875,
    },
    {
      x: 20046.195652173912,
      y: 0.10509490966796875,
    },
    {
      x: 20050.271739130436,
      y: 0.07020950317382812,
    },
    {
      x: 20054.347826086956,
      y: 0.06024169921875,
    },
    {
      x: 20058.42391304348,
      y: 0.05525970458984375,
    },
    {
      x: 20062.5,
      y: 0.05525970458984375,
    },
    {
      x: 20082.880434782608,
      y: 0.05525970458984375,
    },
    {
      x: 20086.956521739132,
      y: 0.06024169921875,
    },
    {
      x: 20091.032608695652,
      y: 0.06024169921875,
    },
    {
      x: 20115.489130434784,
      y: 0.06024169921875,
    },
    {
      x: 20119.565217391304,
      y: 0.06522369384765625,
    },
    {
      x: 20123.641304347828,
      y: 0.06522369384765625,
    },
    {
      x: 20148.097826086956,
      y: 0.06522369384765625,
    },
    {
      x: 20152.17391304348,
      y: 0.07020950317382812,
    },
    {
      x: 20156.25,
      y: 0.07020950317382812,
    },
    {
      x: 20180.706521739132,
      y: 0.07020950317382812,
    },
    {
      x: 20184.782608695652,
      y: 0.0751953125,
    },
    {
      x: 20188.858695652176,
      y: 0.0751953125,
    },
    {
      x: 20213.315217391304,
      y: 0.0751953125,
    },
    {
      x: 20217.391304347828,
      y: 0.08017730712890625,
    },
    {
      x: 20221.467391304348,
      y: 0.08017730712890625,
    },
    {
      x: 20245.92391304348,
      y: 0.08017730712890625,
    },
    {
      x: 20250,
      y: 0.0851593017578125,
    },
    {
      x: 20254.076086956524,
      y: 0.0851593017578125,
    },
    {
      x: 20266.304347826088,
      y: 0.0851593017578125,
    },
    {
      x: 20270.380434782608,
      y: 0.0751953125,
    },
    {
      x: 20274.456521739132,
      y: 0.050273895263671875,
    },
    {
      x: 20278.532608695652,
      y: 0.015392303466796875,
    },
    {
      x: 20282.608695652176,
      y: -0.004543304443359375,
    },
    {
      x: 20286.684782608696,
      y: -0.01949310302734375,
    },
    {
      x: 20290.76086956522,
      y: -0.034442901611328125,
    },
    {
      x: 20294.83695652174,
      y: -0.049396514892578125,
    },
    {
      x: 20298.91304347826,
      y: -0.05936431884765625,
    },
    {
      x: 20302.989130434784,
      y: -0.074310302734375,
    },
    {
      x: 20307.065217391304,
      y: -0.08427810668945312,
    },
    {
      x: 20315.217391304348,
      y: -0.10421371459960938,
    },
    {
      x: 20319.29347826087,
      y: -0.11916351318359375,
    },
    {
      x: 20323.369565217392,
      y: -0.12913131713867188,
    },
    {
      x: 20327.445652173912,
      y: -0.13909912109375,
    },
    {
      x: 20331.521739130436,
      y: -0.15404891967773438,
    },
    {
      x: 20335.597826086956,
      y: -0.1640167236328125,
    },
    {
      x: 20339.67391304348,
      y: -0.17398452758789062,
    },
    {
      x: 20343.75,
      y: -0.18394851684570312,
    },
    {
      x: 20347.826086956524,
      y: -0.1988983154296875,
    },
    {
      x: 20351.902173913044,
      y: -0.20886993408203125,
    },
    {
      x: 20355.978260869564,
      y: -0.1988983154296875,
    },
    {
      x: 20360.054347826088,
      y: -0.18394851684570312,
    },
    {
      x: 20364.130434782608,
      y: -0.16899871826171875,
    },
    {
      x: 20368.206521739132,
      y: -0.14906692504882812,
    },
    {
      x: 20372.282608695652,
      y: -0.13411712646484375,
    },
    {
      x: 20376.358695652176,
      y: -0.1141815185546875,
    },
    {
      x: 20380.434782608696,
      y: -0.09923171997070312,
    },
    {
      x: 20384.51086956522,
      y: -0.07929611206054688,
    },
    {
      x: 20388.58695652174,
      y: -0.0643463134765625,
    },
    {
      x: 20392.66304347826,
      y: -0.049396514892578125,
    },
    {
      x: 20396.739130434784,
      y: -0.029460906982421875,
    },
    {
      x: 20400.815217391304,
      y: -0.0145111083984375,
    },
    {
      x: 20404.891304347828,
      y: 0.0004425048828125,
    },
    {
      x: 20408.967391304348,
      y: 0.015392303466796875,
    },
    {
      x: 20413.04347826087,
      y: 0.0353240966796875,
    },
    {
      x: 20417.119565217392,
      y: 0.050273895263671875,
    },
    {
      x: 20421.195652173912,
      y: 0.06522369384765625,
    },
    {
      x: 20425.271739130436,
      y: 0.0751953125,
    },
    {
      x: 20429.347826086956,
      y: 0.08017730712890625,
    },
    {
      x: 20433.42391304348,
      y: 0.08017730712890625,
    },
    {
      x: 20437.5,
      y: 0.0851593017578125,
    },
    {
      x: 20441.576086956524,
      y: 0.0851593017578125,
    },
    {
      x: 20466.032608695652,
      y: 0.0851593017578125,
    },
    {
      x: 20470.108695652176,
      y: 0.09014511108398438,
    },
    {
      x: 20474.184782608696,
      y: 0.09014511108398438,
    },
    {
      x: 20498.641304347828,
      y: 0.09014511108398438,
    },
    {
      x: 20502.717391304348,
      y: 0.09512710571289062,
    },
    {
      x: 20506.79347826087,
      y: 0.09512710571289062,
    },
    {
      x: 20865.489130434784,
      y: 0.09512710571289062,
    },
    {
      x: 20869.565217391304,
      y: -0.12913131713867188,
    },
    {
      x: 20873.641304347828,
      y: -0.13909912109375,
    },
    {
      x: 20877.717391304348,
      y: -0.15404891967773438,
    },
    {
      x: 20881.79347826087,
      y: -0.1640167236328125,
    },
    {
      x: 20885.869565217392,
      y: -0.17398452758789062,
    },
    {
      x: 20889.945652173912,
      y: -0.18394851684570312,
    },
    {
      x: 20894.021739130436,
      y: -0.1988983154296875,
    },
    {
      x: 20898.097826086956,
      y: -0.20886993408203125,
    },
    {
      x: 20902.17391304348,
      y: -0.1988983154296875,
    },
    {
      x: 20906.25,
      y: -0.18394851684570312,
    },
    {
      x: 20910.326086956524,
      y: -0.16899871826171875,
    },
    {
      x: 20914.402173913044,
      y: -0.14906692504882812,
    },
    {
      x: 20918.478260869564,
      y: -0.13411712646484375,
    },
    {
      x: 20922.554347826088,
      y: -0.1141815185546875,
    },
    {
      x: 20926.630434782608,
      y: -0.09923171997070312,
    },
    {
      x: 20930.706521739132,
      y: -0.07929611206054688,
    },
    {
      x: 20934.782608695652,
      y: -0.0643463134765625,
    },
    {
      x: 20938.858695652176,
      y: -0.049396514892578125,
    },
    {
      x: 20942.934782608696,
      y: -0.029460906982421875,
    },
    {
      x: 20947.01086956522,
      y: -0.0145111083984375,
    },
    {
      x: 20951.08695652174,
      y: 0.0004425048828125,
    },
    {
      x: 20955.16304347826,
      y: 0.015392303466796875,
    },
    {
      x: 20959.239130434784,
      y: 0.0353240966796875,
    },
    {
      x: 20963.315217391304,
      y: 0.050273895263671875,
    },
    {
      x: 20967.391304347828,
      y: 0.06522369384765625,
    },
    {
      x: 20971.467391304348,
      y: 0.0751953125,
    },
    {
      x: 20975.54347826087,
      y: 0.08017730712890625,
    },
    {
      x: 20979.619565217392,
      y: 0.08017730712890625,
    },
    {
      x: 20983.695652173912,
      y: 0.0851593017578125,
    },
    {
      x: 20987.771739130436,
      y: 0.0851593017578125,
    },
    {
      x: 21012.228260869564,
      y: 0.0851593017578125,
    },
    {
      x: 21016.304347826088,
      y: 0.09014511108398438,
    },
    {
      x: 21020.380434782608,
      y: 0.09014511108398438,
    },
    {
      x: 21044.83695652174,
      y: 0.09014511108398438,
    },
    {
      x: 21048.91304347826,
      y: 0.09512710571289062,
    },
    {
      x: 21052.989130434784,
      y: 0.09512710571289062,
    },
    {
      x: 21126.358695652176,
      y: 0.09512710571289062,
    },
    {
      x: 21130.434782608696,
      y: 0.05525970458984375,
    },
    {
      x: 21134.51086956522,
      y: 0.05525970458984375,
    },
    {
      x: 21150.815217391304,
      y: 0.05525970458984375,
    },
    {
      x: 21154.891304347828,
      y: 0.06024169921875,
    },
    {
      x: 21158.967391304348,
      y: 0.06024169921875,
    },
    {
      x: 21183.42391304348,
      y: 0.06024169921875,
    },
    {
      x: 21187.5,
      y: 0.06522369384765625,
    },
    {
      x: 21191.576086956524,
      y: 0.06522369384765625,
    },
    {
      x: 21216.032608695652,
      y: 0.06522369384765625,
    },
    {
      x: 21220.108695652176,
      y: 0.07020950317382812,
    },
    {
      x: 21224.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 21248.641304347828,
      y: 0.07020950317382812,
    },
    {
      x: 21252.717391304348,
      y: 0.0751953125,
    },
    {
      x: 21256.79347826087,
      y: 0.0751953125,
    },
    {
      x: 21281.25,
      y: 0.0751953125,
    },
    {
      x: 21285.326086956524,
      y: 0.08017730712890625,
    },
    {
      x: 21289.402173913044,
      y: 0.08017730712890625,
    },
    {
      x: 21313.858695652176,
      y: 0.08017730712890625,
    },
    {
      x: 21317.934782608696,
      y: 0.0851593017578125,
    },
    {
      x: 21322.01086956522,
      y: 0.0851593017578125,
    },
    {
      x: 21334.239130434784,
      y: 0.0851593017578125,
    },
    {
      x: 21338.315217391304,
      y: 0.0751953125,
    },
    {
      x: 21342.391304347828,
      y: 0.050273895263671875,
    },
    {
      x: 21346.467391304348,
      y: 0.015392303466796875,
    },
    {
      x: 21350.54347826087,
      y: -0.004543304443359375,
    },
    {
      x: 21354.619565217392,
      y: -0.01949310302734375,
    },
    {
      x: 21358.695652173912,
      y: -0.034442901611328125,
    },
    {
      x: 21362.771739130436,
      y: -0.049396514892578125,
    },
    {
      x: 21366.847826086956,
      y: -0.05936431884765625,
    },
    {
      x: 21370.92391304348,
      y: -0.074310302734375,
    },
    {
      x: 21375,
      y: -0.08427810668945312,
    },
    {
      x: 21383.152173913044,
      y: -0.10421371459960938,
    },
    {
      x: 21387.228260869564,
      y: -0.11916351318359375,
    },
    {
      x: 21391.304347826088,
      y: 0.09512710571289062,
    },
    {
      x: 21395.380434782608,
      y: 0.09512710571289062,
    },
    {
      x: 21550.271739130436,
      y: 0.09512710571289062,
    },
    {
      x: 21554.347826086956,
      y: 0.13001251220703125,
    },
    {
      x: 21558.42391304348,
      y: 0.22469711303710938,
    },
    {
      x: 21562.5,
      y: 0.3393211364746094,
    },
    {
      x: 21566.576086956524,
      y: 0.45892333984375,
    },
    {
      x: 21570.652173913044,
      y: 0.5785293579101562,
    },
    {
      x: 21574.728260869564,
      y: 0.7031173706054688,
    },
    {
      x: 21578.804347826088,
      y: 0.822723388671875,
    },
    {
      x: 21582.880434782608,
      y: 0.9423255920410156,
    },
    {
      x: 21586.956521739132,
      y: 1.0569496154785156,
    },
    {
      x: 21591.032608695652,
      y: 1.1765518188476562,
    },
    {
      x: 21595.108695652176,
      y: 1.2313690185546875,
    },
    {
      x: 21599.184782608696,
      y: 1.1765518188476562,
    },
    {
      x: 21603.26086956522,
      y: 1.0669136047363281,
    },
    {
      x: 21607.33695652174,
      y: 0.9473075866699219,
    },
    {
      x: 21611.41304347826,
      y: 0.822723388671875,
    },
    {
      x: 21615.489130434784,
      y: 0.6981315612792969,
    },
    {
      x: 21619.565217391304,
      y: 0.57354736328125,
    },
    {
      x: 21623.641304347828,
      y: 0.4489555358886719,
    },
    {
      x: 21627.717391304348,
      y: 0.3293495178222656,
    },
    {
      x: 21631.79347826087,
      y: 0.20476531982421875,
    },
    {
      x: 21635.869565217392,
      y: 0.10509490966796875,
    },
    {
      x: 21639.945652173912,
      y: 0.07020950317382812,
    },
    {
      x: 21644.021739130436,
      y: 0.06024169921875,
    },
    {
      x: 21648.097826086956,
      y: 0.05525970458984375,
    },
    {
      x: 21652.17391304348,
      y: 0.09512710571289062,
    },
    {
      x: 21656.25,
      y: 0.09512710571289062,
    },
    {
      x: 21908.967391304348,
      y: 0.09512710571289062,
    },
    {
      x: 21913.04347826087,
      y: -0.05936431884765625,
    },
    {
      x: 21917.119565217392,
      y: -0.074310302734375,
    },
    {
      x: 21921.195652173912,
      y: -0.08427810668945312,
    },
    {
      x: 21929.347826086956,
      y: -0.10421371459960938,
    },
    {
      x: 21933.42391304348,
      y: -0.11916351318359375,
    },
    {
      x: 21937.5,
      y: -0.12913131713867188,
    },
    {
      x: 21941.576086956524,
      y: -0.13909912109375,
    },
    {
      x: 21945.652173913044,
      y: -0.15404891967773438,
    },
    {
      x: 21949.728260869564,
      y: -0.1640167236328125,
    },
    {
      x: 21953.804347826088,
      y: -0.17398452758789062,
    },
    {
      x: 21957.880434782608,
      y: -0.18394851684570312,
    },
    {
      x: 21961.956521739132,
      y: -0.1988983154296875,
    },
    {
      x: 21966.032608695652,
      y: -0.20886993408203125,
    },
    {
      x: 21970.108695652176,
      y: -0.1988983154296875,
    },
    {
      x: 21974.184782608696,
      y: -0.18394851684570312,
    },
    {
      x: 21978.26086956522,
      y: -0.16899871826171875,
    },
    {
      x: 21982.33695652174,
      y: -0.14906692504882812,
    },
    {
      x: 21986.41304347826,
      y: -0.12913131713867188,
    },
    {
      x: 21990.489130434784,
      y: -0.1141815185546875,
    },
    {
      x: 21994.565217391304,
      y: -0.09923171997070312,
    },
    {
      x: 21998.641304347828,
      y: -0.07929611206054688,
    },
    {
      x: 22002.717391304348,
      y: -0.0643463134765625,
    },
    {
      x: 22006.79347826087,
      y: -0.049396514892578125,
    },
    {
      x: 22010.869565217392,
      y: -0.029460906982421875,
    },
    {
      x: 22014.945652173912,
      y: -0.0145111083984375,
    },
    {
      x: 22019.021739130436,
      y: 0.0004425048828125,
    },
    {
      x: 22023.097826086956,
      y: 0.020374298095703125,
    },
    {
      x: 22027.17391304348,
      y: 0.0353240966796875,
    },
    {
      x: 22035.326086956524,
      y: 0.06522369384765625,
    },
    {
      x: 22039.402173913044,
      y: 0.0751953125,
    },
    {
      x: 22043.478260869564,
      y: 0.08017730712890625,
    },
    {
      x: 22047.554347826088,
      y: 0.08017730712890625,
    },
    {
      x: 22051.630434782608,
      y: 0.0851593017578125,
    },
    {
      x: 22055.706521739132,
      y: 0.0851593017578125,
    },
    {
      x: 22076.08695652174,
      y: 0.0851593017578125,
    },
    {
      x: 22080.16304347826,
      y: 0.09014511108398438,
    },
    {
      x: 22084.239130434784,
      y: 0.09014511108398438,
    },
    {
      x: 22108.695652173912,
      y: 0.09014511108398438,
    },
    {
      x: 22112.771739130436,
      y: 0.09512710571289062,
    },
    {
      x: 22116.847826086956,
      y: 0.09512710571289062,
    },
    {
      x: 22169.83695652174,
      y: 0.09512710571289062,
    },
    {
      x: 22173.91304347826,
      y: 0.3243675231933594,
    },
    {
      x: 22177.989130434784,
      y: 0.20476531982421875,
    },
    {
      x: 22182.065217391304,
      y: 0.10010910034179688,
    },
    {
      x: 22186.141304347828,
      y: 0.06522369384765625,
    },
    {
      x: 22190.217391304348,
      y: 0.05525970458984375,
    },
    {
      x: 22194.29347826087,
      y: 0.050273895263671875,
    },
    {
      x: 22198.369565217392,
      y: 0.050273895263671875,
    },
    {
      x: 22218.75,
      y: 0.050273895263671875,
    },
    {
      x: 22222.826086956524,
      y: 0.05525970458984375,
    },
    {
      x: 22226.902173913044,
      y: 0.05525970458984375,
    },
    {
      x: 22251.358695652176,
      y: 0.05525970458984375,
    },
    {
      x: 22255.434782608696,
      y: 0.06024169921875,
    },
    {
      x: 22259.51086956522,
      y: 0.06024169921875,
    },
    {
      x: 22283.967391304348,
      y: 0.06024169921875,
    },
    {
      x: 22288.04347826087,
      y: 0.06522369384765625,
    },
    {
      x: 22292.119565217392,
      y: 0.06522369384765625,
    },
    {
      x: 22316.576086956524,
      y: 0.06522369384765625,
    },
    {
      x: 22320.652173913044,
      y: 0.07020950317382812,
    },
    {
      x: 22324.728260869564,
      y: 0.07020950317382812,
    },
    {
      x: 22349.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 22353.26086956522,
      y: 0.0751953125,
    },
    {
      x: 22357.33695652174,
      y: 0.0751953125,
    },
    {
      x: 22381.79347826087,
      y: 0.0751953125,
    },
    {
      x: 22385.869565217392,
      y: 0.08017730712890625,
    },
    {
      x: 22389.945652173912,
      y: 0.08017730712890625,
    },
    {
      x: 22402.17391304348,
      y: 0.08017730712890625,
    },
    {
      x: 22406.25,
      y: 0.07020950317382812,
    },
    {
      x: 22410.326086956524,
      y: 0.045291900634765625,
    },
    {
      x: 22414.402173913044,
      y: 0.015392303466796875,
    },
    {
      x: 22418.478260869564,
      y: -0.004543304443359375,
    },
    {
      x: 22422.554347826088,
      y: -0.01949310302734375,
    },
    {
      x: 22426.630434782608,
      y: -0.034442901611328125,
    },
    {
      x: 22430.706521739132,
      y: -0.049396514892578125,
    },
    {
      x: 22434.782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 22438.858695652176,
      y: 0.09512710571289062,
    },
    {
      x: 22618.206521739132,
      y: 0.09512710571289062,
    },
    {
      x: 22622.282608695652,
      y: 0.13001251220703125,
    },
    {
      x: 22626.358695652176,
      y: 0.22469711303710938,
    },
    {
      x: 22630.434782608696,
      y: 0.3393211364746094,
    },
    {
      x: 22634.51086956522,
      y: 0.45892333984375,
    },
    {
      x: 22638.58695652174,
      y: 0.5785293579101562,
    },
    {
      x: 22642.66304347826,
      y: 0.7031173706054688,
    },
    {
      x: 22646.739130434784,
      y: 0.822723388671875,
    },
    {
      x: 22650.815217391304,
      y: 0.9423255920410156,
    },
    {
      x: 22654.891304347828,
      y: 1.0569496154785156,
    },
    {
      x: 22658.967391304348,
      y: 1.1765518188476562,
    },
    {
      x: 22663.04347826087,
      y: 1.2313690185546875,
    },
    {
      x: 22667.119565217392,
      y: 1.1765518188476562,
    },
    {
      x: 22671.195652173912,
      y: 1.0669136047363281,
    },
    {
      x: 22675.271739130436,
      y: 0.9473075866699219,
    },
    {
      x: 22679.347826086956,
      y: 0.822723388671875,
    },
    {
      x: 22683.42391304348,
      y: 0.6981315612792969,
    },
    {
      x: 22687.5,
      y: 0.57354736328125,
    },
    {
      x: 22691.576086956524,
      y: 0.4439735412597656,
    },
    {
      x: 22695.652173913044,
      y: 0.09512710571289062,
    },
    {
      x: 22699.728260869564,
      y: 0.09512710571289062,
    },
    {
      x: 22952.445652173912,
      y: 0.09512710571289062,
    },
    {
      x: 22956.521739130436,
      y: 0.045291900634765625,
    },
    {
      x: 22960.597826086956,
      y: 0.015392303466796875,
    },
    {
      x: 22964.67391304348,
      y: -0.004543304443359375,
    },
    {
      x: 22968.75,
      y: -0.01949310302734375,
    },
    {
      x: 22972.826086956524,
      y: -0.034442901611328125,
    },
    {
      x: 22976.902173913044,
      y: -0.049396514892578125,
    },
    {
      x: 22980.978260869564,
      y: -0.05936431884765625,
    },
    {
      x: 22985.054347826088,
      y: -0.074310302734375,
    },
    {
      x: 22989.130434782608,
      y: -0.08427810668945312,
    },
    {
      x: 22997.282608695652,
      y: -0.10421371459960938,
    },
    {
      x: 23001.358695652176,
      y: -0.11916351318359375,
    },
    {
      x: 23005.434782608696,
      y: -0.12913131713867188,
    },
    {
      x: 23009.51086956522,
      y: -0.13909912109375,
    },
    {
      x: 23013.58695652174,
      y: -0.15404891967773438,
    },
    {
      x: 23017.66304347826,
      y: -0.1640167236328125,
    },
    {
      x: 23021.739130434784,
      y: -0.17398452758789062,
    },
    {
      x: 23025.815217391304,
      y: -0.18394851684570312,
    },
    {
      x: 23029.891304347828,
      y: -0.19391632080078125,
    },
    {
      x: 23033.967391304348,
      y: -0.20388412475585938,
    },
    {
      x: 23038.04347826087,
      y: -0.1988983154296875,
    },
    {
      x: 23042.119565217392,
      y: -0.18394851684570312,
    },
    {
      x: 23046.195652173912,
      y: -0.16899871826171875,
    },
    {
      x: 23050.271739130436,
      y: -0.14906692504882812,
    },
    {
      x: 23054.347826086956,
      y: -0.12913131713867188,
    },
    {
      x: 23058.42391304348,
      y: -0.1141815185546875,
    },
    {
      x: 23062.5,
      y: -0.09923171997070312,
    },
    {
      x: 23066.576086956524,
      y: -0.07929611206054688,
    },
    {
      x: 23070.652173913044,
      y: -0.0643463134765625,
    },
    {
      x: 23074.728260869564,
      y: -0.049396514892578125,
    },
    {
      x: 23078.804347826088,
      y: -0.029460906982421875,
    },
    {
      x: 23082.880434782608,
      y: -0.0145111083984375,
    },
    {
      x: 23086.956521739132,
      y: 0.0004425048828125,
    },
    {
      x: 23091.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 23095.108695652176,
      y: 0.0353240966796875,
    },
    {
      x: 23103.26086956522,
      y: 0.06522369384765625,
    },
    {
      x: 23107.33695652174,
      y: 0.0751953125,
    },
    {
      x: 23111.41304347826,
      y: 0.08017730712890625,
    },
    {
      x: 23115.489130434784,
      y: 0.08017730712890625,
    },
    {
      x: 23119.565217391304,
      y: 0.0851593017578125,
    },
    {
      x: 23123.641304347828,
      y: 0.0851593017578125,
    },
    {
      x: 23144.021739130436,
      y: 0.0851593017578125,
    },
    {
      x: 23148.097826086956,
      y: 0.09014511108398438,
    },
    {
      x: 23152.17391304348,
      y: 0.09014511108398438,
    },
    {
      x: 23176.630434782608,
      y: 0.09014511108398438,
    },
    {
      x: 23180.706521739132,
      y: 0.09512710571289062,
    },
    {
      x: 23184.782608695652,
      y: 0.09512710571289062,
    },
    {
      x: 23213.315217391304,
      y: 0.09512710571289062,
    },
    {
      x: 23217.391304347828,
      y: 1.0669136047363281,
    },
    {
      x: 23221.467391304348,
      y: 0.9473075866699219,
    },
    {
      x: 23225.54347826087,
      y: 0.822723388671875,
    },
    {
      x: 23229.619565217392,
      y: 0.6981315612792969,
    },
    {
      x: 23233.695652173912,
      y: 0.57354736328125,
    },
    {
      x: 23237.771739130436,
      y: 0.4439735412597656,
    },
    {
      x: 23241.847826086956,
      y: 0.3243675231933594,
    },
    {
      x: 23245.92391304348,
      y: 0.20476531982421875,
    },
    {
      x: 23250,
      y: 0.10010910034179688,
    },
    {
      x: 23254.076086956524,
      y: 0.06522369384765625,
    },
    {
      x: 23258.152173913044,
      y: 0.05525970458984375,
    },
    {
      x: 23262.228260869564,
      y: 0.050273895263671875,
    },
    {
      x: 23266.304347826088,
      y: 0.050273895263671875,
    },
    {
      x: 23286.684782608696,
      y: 0.050273895263671875,
    },
    {
      x: 23290.76086956522,
      y: 0.05525970458984375,
    },
    {
      x: 23294.83695652174,
      y: 0.05525970458984375,
    },
    {
      x: 23319.29347826087,
      y: 0.05525970458984375,
    },
    {
      x: 23323.369565217392,
      y: 0.06024169921875,
    },
    {
      x: 23327.445652173912,
      y: 0.06024169921875,
    },
    {
      x: 23351.902173913044,
      y: 0.06024169921875,
    },
    {
      x: 23355.978260869564,
      y: 0.06522369384765625,
    },
    {
      x: 23360.054347826088,
      y: 0.06522369384765625,
    },
    {
      x: 23384.51086956522,
      y: 0.06522369384765625,
    },
    {
      x: 23388.58695652174,
      y: 0.07020950317382812,
    },
    {
      x: 23392.66304347826,
      y: 0.07020950317382812,
    },
    {
      x: 23417.119565217392,
      y: 0.07020950317382812,
    },
    {
      x: 23421.195652173912,
      y: 0.0751953125,
    },
    {
      x: 23425.271739130436,
      y: 0.0751953125,
    },
    {
      x: 23449.728260869564,
      y: 0.0751953125,
    },
    {
      x: 23453.804347826088,
      y: 0.08017730712890625,
    },
    {
      x: 23457.880434782608,
      y: 0.08017730712890625,
    },
    {
      x: 23470.108695652176,
      y: 0.08017730712890625,
    },
    {
      x: 23474.184782608696,
      y: 0.07020950317382812,
    },
    {
      x: 23478.26086956522,
      y: 0.09512710571289062,
    },
    {
      x: 23482.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 23686.141304347828,
      y: 0.09512710571289062,
    },
    {
      x: 23690.217391304348,
      y: 0.13001251220703125,
    },
    {
      x: 23694.29347826087,
      y: 0.22469711303710938,
    },
    {
      x: 23698.369565217392,
      y: 0.3393211364746094,
    },
    {
      x: 23702.445652173912,
      y: 0.45892333984375,
    },
    {
      x: 23706.521739130436,
      y: 0.5785293579101562,
    },
    {
      x: 23710.597826086956,
      y: 0.7031173706054688,
    },
    {
      x: 23714.67391304348,
      y: 0.822723388671875,
    },
    {
      x: 23718.75,
      y: 0.9423255920410156,
    },
    {
      x: 23722.826086956524,
      y: 1.0569496154785156,
    },
    {
      x: 23726.902173913044,
      y: 1.1765518188476562,
    },
    {
      x: 23730.978260869564,
      y: 1.2313690185546875,
    },
    {
      x: 23735.054347826088,
      y: 1.1765518188476562,
    },
    {
      x: 23739.130434782608,
      y: 0.09512710571289062,
    },
    {
      x: 23743.206521739132,
      y: 0.09512710571289062,
    },
    {
      x: 24000,
      y: 0.09512710571289062,
    },
  ],
  v5: [
    {
      x: 0,
      y: 0.7629203796386719,
    },
    {
      x: 4.261363636363637,
      y: 0.6482963562011719,
    },
    {
      x: 8.522727272727273,
      y: 0.5436439514160156,
    },
    {
      x: 12.78409090909091,
      y: 0.4340057373046875,
    },
    {
      x: 17.045454545454547,
      y: 0.3243675231933594,
    },
    {
      x: 21.306818181818183,
      y: 0.21473312377929688,
    },
    {
      x: 25.56818181818182,
      y: 0.13001251220703125,
    },
    {
      x: 29.829545454545457,
      y: 0.09512710571289062,
    },
    {
      x: 34.09090909090909,
      y: 0.0851593017578125,
    },
    {
      x: 38.352272727272734,
      y: 0.08017730712890625,
    },
    {
      x: 42.61363636363637,
      y: 0.08017730712890625,
    },
    {
      x: 68.18181818181819,
      y: 0.08017730712890625,
    },
    {
      x: 72.44318181818183,
      y: 0.0851593017578125,
    },
    {
      x: 76.70454545454547,
      y: 0.0851593017578125,
    },
    {
      x: 102.27272727272728,
      y: 0.0851593017578125,
    },
    {
      x: 106.53409090909092,
      y: 0.09014511108398438,
    },
    {
      x: 110.79545454545456,
      y: 0.09014511108398438,
    },
    {
      x: 136.36363636363637,
      y: 0.09014511108398438,
    },
    {
      x: 140.625,
      y: 0.09512710571289062,
    },
    {
      x: 144.88636363636365,
      y: 0.09512710571289062,
    },
    {
      x: 255.6818181818182,
      y: 0.09512710571289062,
    },
    {
      x: 259.9431818181818,
      y: 0.0851593017578125,
    },
    {
      x: 264.2045454545455,
      y: 0.05525970458984375,
    },
    {
      x: 268.4659090909091,
      y: 0.020374298095703125,
    },
    {
      x: 272.72727272727275,
      y: 0.09512710571289062,
    },
    {
      x: 276.9886363636364,
      y: 0.09512710571289062,
    },
    {
      x: 455.9659090909091,
      y: 0.09512710571289062,
    },
    {
      x: 460.22727272727275,
      y: 0.125030517578125,
    },
    {
      x: 464.4886363636364,
      y: 0.21473312377929688,
    },
    {
      x: 468.75000000000006,
      y: 0.31439971923828125,
    },
    {
      x: 473.0113636363637,
      y: 0.424041748046875,
    },
    {
      x: 477.2727272727273,
      y: 0.5336761474609375,
    },
    {
      x: 481.53409090909093,
      y: 0.643310546875,
    },
    {
      x: 485.79545454545456,
      y: 0.7529525756835938,
    },
    {
      x: 490.05681818181824,
      y: 0.85760498046875,
    },
    {
      x: 494.31818181818187,
      y: 0.9622573852539062,
    },
    {
      x: 498.5795454545455,
      y: 1.0669136047363281,
    },
    {
      x: 502.8409090909091,
      y: 1.1217308044433594,
    },
    {
      x: 507.10227272727275,
      y: 1.0669136047363281,
    },
    {
      x: 511.3636363636364,
      y: 0.9772109985351562,
    },
    {
      x: 515.625,
      y: 0.8725547790527344,
    },
    {
      x: 519.8863636363636,
      y: 0.7629203796386719,
    },
    {
      x: 524.1477272727274,
      y: 0.6482963562011719,
    },
    {
      x: 528.409090909091,
      y: 0.5436439514160156,
    },
    {
      x: 532.6704545454546,
      y: 0.4340057373046875,
    },
    {
      x: 536.9318181818182,
      y: 0.3243675231933594,
    },
    {
      x: 541.1931818181819,
      y: 0.21473312377929688,
    },
    {
      x: 545.4545454545455,
      y: 0.09512710571289062,
    },
    {
      x: 549.7159090909091,
      y: 0.09512710571289062,
    },
    {
      x: 813.9204545454546,
      y: 0.09512710571289062,
    },
    {
      x: 818.1818181818182,
      y: -0.004543304443359375,
    },
    {
      x: 822.4431818181819,
      y: -0.01949310302734375,
    },
    {
      x: 826.7045454545455,
      y: -0.029460906982421875,
    },
    {
      x: 830.9659090909091,
      y: -0.04441070556640625,
    },
    {
      x: 835.2272727272727,
      y: -0.054378509521484375,
    },
    {
      x: 839.4886363636364,
      y: -0.0643463134765625,
    },
    {
      x: 843.7500000000001,
      y: -0.074310302734375,
    },
    {
      x: 848.0113636363637,
      y: -0.08427810668945312,
    },
    {
      x: 856.534090909091,
      y: -0.10421371459960938,
    },
    {
      x: 860.7954545454546,
      y: -0.11916351318359375,
    },
    {
      x: 865.0568181818182,
      y: -0.12913131713867188,
    },
    {
      x: 873.5795454545455,
      y: -0.14906692504882812,
    },
    {
      x: 877.8409090909091,
      y: -0.15404891967773438,
    },
    {
      x: 882.1022727272727,
      y: -0.1640167236328125,
    },
    {
      x: 886.3636363636365,
      y: -0.17398452758789062,
    },
    {
      x: 890.6250000000001,
      y: -0.18394851684570312,
    },
    {
      x: 894.8863636363637,
      y: -0.17896652221679688,
    },
    {
      x: 899.1477272727274,
      y: -0.1640167236328125,
    },
    {
      x: 907.6704545454546,
      y: -0.13411712646484375,
    },
    {
      x: 911.9318181818182,
      y: -0.11916351318359375,
    },
    {
      x: 916.1931818181819,
      y: -0.10421371459960938,
    },
    {
      x: 920.4545454545455,
      y: -0.08427810668945312,
    },
    {
      x: 924.7159090909091,
      y: -0.06932830810546875,
    },
    {
      x: 941.7613636363637,
      y: -0.00952911376953125,
    },
    {
      x: 946.0227272727274,
      y: 0.00542449951171875,
    },
    {
      x: 950.284090909091,
      y: 0.020374298095703125,
    },
    {
      x: 958.8068181818182,
      y: 0.050273895263671875,
    },
    {
      x: 963.0681818181819,
      y: 0.06024169921875,
    },
    {
      x: 967.3295454545455,
      y: 0.07020950317382812,
    },
    {
      x: 971.5909090909091,
      y: 0.0751953125,
    },
    {
      x: 975.8522727272727,
      y: 0.08017730712890625,
    },
    {
      x: 980.1136363636365,
      y: 0.08017730712890625,
    },
    {
      x: 1005.6818181818182,
      y: 0.08017730712890625,
    },
    {
      x: 1009.9431818181819,
      y: 0.0851593017578125,
    },
    {
      x: 1014.2045454545455,
      y: 0.0851593017578125,
    },
    {
      x: 1039.7727272727273,
      y: 0.0851593017578125,
    },
    {
      x: 1044.034090909091,
      y: 0.09014511108398438,
    },
    {
      x: 1048.2954545454547,
      y: 0.09014511108398438,
    },
    {
      x: 1073.8636363636365,
      y: 0.09014511108398438,
    },
    {
      x: 1078.125,
      y: 0.09512710571289062,
    },
    {
      x: 1082.3863636363637,
      y: 0.09512710571289062,
    },
    {
      x: 1299.7159090909092,
      y: 0.09512710571289062,
    },
    {
      x: 1303.9772727272727,
      y: 0.125030517578125,
    },
    {
      x: 1308.2386363636365,
      y: 0.21473312377929688,
    },
    {
      x: 1312.5,
      y: 0.31439971923828125,
    },
    {
      x: 1316.7613636363637,
      y: 0.424041748046875,
    },
    {
      x: 1321.0227272727275,
      y: 0.5336761474609375,
    },
    {
      x: 1325.284090909091,
      y: 0.643310546875,
    },
    {
      x: 1329.5454545454547,
      y: 0.7529525756835938,
    },
    {
      x: 1333.8068181818182,
      y: 0.85760498046875,
    },
    {
      x: 1338.068181818182,
      y: 0.9622573852539062,
    },
    {
      x: 1342.3295454545455,
      y: 1.0669136047363281,
    },
    {
      x: 1346.5909090909092,
      y: 1.1217308044433594,
    },
    {
      x: 1350.8522727272727,
      y: 1.0669136047363281,
    },
    {
      x: 1355.1136363636365,
      y: 0.9772109985351562,
    },
    {
      x: 1359.375,
      y: 0.8725547790527344,
    },
    {
      x: 1363.6363636363637,
      y: 0.09014511108398438,
    },
    {
      x: 1367.8977272727275,
      y: 0.09014511108398438,
    },
    {
      x: 1372.159090909091,
      y: 0.09014511108398438,
    },
    {
      x: 1376.4204545454547,
      y: 0.09512710571289062,
    },
    {
      x: 1380.6818181818182,
      y: 0.09512710571289062,
    },
    {
      x: 1644.8863636363637,
      y: 0.09512710571289062,
    },
    {
      x: 1649.1477272727275,
      y: 0.0851593017578125,
    },
    {
      x: 1653.409090909091,
      y: 0.05525970458984375,
    },
    {
      x: 1657.6704545454547,
      y: 0.020374298095703125,
    },
    {
      x: 1661.9318181818182,
      y: -0.004543304443359375,
    },
    {
      x: 1666.193181818182,
      y: -0.01949310302734375,
    },
    {
      x: 1670.4545454545455,
      y: -0.029460906982421875,
    },
    {
      x: 1674.7159090909092,
      y: -0.04441070556640625,
    },
    {
      x: 1678.9772727272727,
      y: -0.054378509521484375,
    },
    {
      x: 1683.2386363636365,
      y: -0.0643463134765625,
    },
    {
      x: 1687.5000000000002,
      y: -0.074310302734375,
    },
    {
      x: 1691.7613636363637,
      y: -0.08427810668945312,
    },
    {
      x: 1700.284090909091,
      y: -0.10421371459960938,
    },
    {
      x: 1704.5454545454547,
      y: -0.11916351318359375,
    },
    {
      x: 1708.8068181818182,
      y: -0.12913131713867188,
    },
    {
      x: 1713.068181818182,
      y: -0.13411712646484375,
    },
    {
      x: 1717.3295454545455,
      y: -0.14408111572265625,
    },
    {
      x: 1721.5909090909092,
      y: -0.15404891967773438,
    },
    {
      x: 1730.1136363636365,
      y: -0.17398452758789062,
    },
    {
      x: 1734.3750000000002,
      y: -0.18394851684570312,
    },
    {
      x: 1738.6363636363637,
      y: -0.17896652221679688,
    },
    {
      x: 1742.8977272727275,
      y: -0.1640167236328125,
    },
    {
      x: 1751.4204545454547,
      y: -0.13411712646484375,
    },
    {
      x: 1755.6818181818182,
      y: -0.11916351318359375,
    },
    {
      x: 1759.943181818182,
      y: -0.10421371459960938,
    },
    {
      x: 1764.2045454545455,
      y: -0.08427810668945312,
    },
    {
      x: 1768.4659090909092,
      y: -0.06932830810546875,
    },
    {
      x: 1785.5113636363637,
      y: -0.00952911376953125,
    },
    {
      x: 1789.7727272727275,
      y: 0.00542449951171875,
    },
    {
      x: 1794.034090909091,
      y: 0.020374298095703125,
    },
    {
      x: 1802.5568181818182,
      y: 0.050273895263671875,
    },
    {
      x: 1806.818181818182,
      y: 0.06024169921875,
    },
    {
      x: 1811.0795454545455,
      y: 0.07020950317382812,
    },
    {
      x: 1815.3409090909092,
      y: 0.0751953125,
    },
    {
      x: 1819.602272727273,
      y: 0.0751953125,
    },
    {
      x: 1823.8636363636365,
      y: 0.08017730712890625,
    },
    {
      x: 1828.1250000000002,
      y: 0.08017730712890625,
    },
    {
      x: 1849.4318181818182,
      y: 0.08017730712890625,
    },
    {
      x: 1853.693181818182,
      y: 0.0851593017578125,
    },
    {
      x: 1857.9545454545455,
      y: 0.0851593017578125,
    },
    {
      x: 1883.5227272727275,
      y: 0.0851593017578125,
    },
    {
      x: 1887.784090909091,
      y: 0.09014511108398438,
    },
    {
      x: 1892.0454545454547,
      y: 0.09014511108398438,
    },
    {
      x: 1904.8295454545455,
      y: 0.09014511108398438,
    },
    {
      x: 1909.0909090909092,
      y: 0.9622573852539062,
    },
    {
      x: 1913.352272727273,
      y: 1.0669136047363281,
    },
    {
      x: 1917.6136363636365,
      y: 1.1217308044433594,
    },
    {
      x: 1921.8750000000002,
      y: 1.0669136047363281,
    },
    {
      x: 1926.1363636363637,
      y: 0.9772109985351562,
    },
    {
      x: 1930.3977272727275,
      y: 0.8725547790527344,
    },
    {
      x: 1934.659090909091,
      y: 0.7629203796386719,
    },
    {
      x: 1938.9204545454547,
      y: 0.6482963562011719,
    },
    {
      x: 1943.1818181818182,
      y: 0.5436439514160156,
    },
    {
      x: 1947.443181818182,
      y: 0.4340057373046875,
    },
    {
      x: 1951.7045454545455,
      y: 0.3243675231933594,
    },
    {
      x: 1955.9659090909092,
      y: 0.21473312377929688,
    },
    {
      x: 1960.227272727273,
      y: 0.13001251220703125,
    },
    {
      x: 1964.4886363636365,
      y: 0.09512710571289062,
    },
    {
      x: 1968.7500000000002,
      y: 0.0851593017578125,
    },
    {
      x: 1973.0113636363637,
      y: 0.08017730712890625,
    },
    {
      x: 1977.2727272727275,
      y: 0.08017730712890625,
    },
    {
      x: 2002.8409090909092,
      y: 0.08017730712890625,
    },
    {
      x: 2007.102272727273,
      y: 0.0851593017578125,
    },
    {
      x: 2011.3636363636365,
      y: 0.0851593017578125,
    },
    {
      x: 2036.9318181818182,
      y: 0.0851593017578125,
    },
    {
      x: 2041.193181818182,
      y: 0.09014511108398438,
    },
    {
      x: 2045.4545454545455,
      y: 0.09014511108398438,
    },
    {
      x: 2071.0227272727275,
      y: 0.09014511108398438,
    },
    {
      x: 2075.284090909091,
      y: 0.09512710571289062,
    },
    {
      x: 2079.5454545454545,
      y: 0.09512710571289062,
    },
    {
      x: 2416.193181818182,
      y: 0.09512710571289062,
    },
    {
      x: 2420.4545454545455,
      y: 0.125030517578125,
    },
    {
      x: 2424.7159090909095,
      y: 0.21473312377929688,
    },
    {
      x: 2428.977272727273,
      y: 0.31439971923828125,
    },
    {
      x: 2433.2386363636365,
      y: 0.424041748046875,
    },
    {
      x: 2437.5,
      y: 0.5336761474609375,
    },
    {
      x: 2441.761363636364,
      y: 0.643310546875,
    },
    {
      x: 2446.0227272727275,
      y: 0.7529525756835938,
    },
    {
      x: 2450.284090909091,
      y: 0.85760498046875,
    },
    {
      x: 2454.545454545455,
      y: 0.0851593017578125,
    },
    {
      x: 2458.8068181818185,
      y: 0.09014511108398438,
    },
    {
      x: 2463.068181818182,
      y: 0.09014511108398438,
    },
    {
      x: 2488.636363636364,
      y: 0.09014511108398438,
    },
    {
      x: 2492.8977272727275,
      y: 0.09512710571289062,
    },
    {
      x: 2497.159090909091,
      y: 0.09512710571289062,
    },
    {
      x: 2723.011363636364,
      y: 0.09512710571289062,
    },
    {
      x: 2727.2727272727275,
      y: 0.31439971923828125,
    },
    {
      x: 2731.534090909091,
      y: 0.424041748046875,
    },
    {
      x: 2735.795454545455,
      y: 0.5336761474609375,
    },
    {
      x: 2740.0568181818185,
      y: 0.643310546875,
    },
    {
      x: 2744.318181818182,
      y: 0.7529525756835938,
    },
    {
      x: 2748.5795454545455,
      y: 0.85760498046875,
    },
    {
      x: 2752.8409090909095,
      y: 0.9622573852539062,
    },
    {
      x: 2757.102272727273,
      y: 1.0669136047363281,
    },
    {
      x: 2761.3636363636365,
      y: 1.1217308044433594,
    },
    {
      x: 2765.625,
      y: 1.0669136047363281,
    },
    {
      x: 2769.886363636364,
      y: 0.9772109985351562,
    },
    {
      x: 2774.1477272727275,
      y: 0.8725547790527344,
    },
    {
      x: 2778.409090909091,
      y: 0.7629203796386719,
    },
    {
      x: 2782.670454545455,
      y: 0.6482963562011719,
    },
    {
      x: 2786.9318181818185,
      y: 0.5436439514160156,
    },
    {
      x: 2791.193181818182,
      y: 0.4340057373046875,
    },
    {
      x: 2795.4545454545455,
      y: 0.3243675231933594,
    },
    {
      x: 2799.7159090909095,
      y: 0.21473312377929688,
    },
    {
      x: 2803.977272727273,
      y: 0.13001251220703125,
    },
    {
      x: 2808.2386363636365,
      y: 0.09512710571289062,
    },
    {
      x: 2812.5,
      y: 0.0851593017578125,
    },
    {
      x: 2816.761363636364,
      y: 0.08017730712890625,
    },
    {
      x: 2821.0227272727275,
      y: 0.08017730712890625,
    },
    {
      x: 2846.5909090909095,
      y: 0.08017730712890625,
    },
    {
      x: 2850.852272727273,
      y: 0.0851593017578125,
    },
    {
      x: 2855.1136363636365,
      y: 0.0851593017578125,
    },
    {
      x: 2880.6818181818185,
      y: 0.0851593017578125,
    },
    {
      x: 2884.943181818182,
      y: 0.09014511108398438,
    },
    {
      x: 2889.2045454545455,
      y: 0.09014511108398438,
    },
    {
      x: 2914.7727272727275,
      y: 0.09014511108398438,
    },
    {
      x: 2919.034090909091,
      y: 0.09512710571289062,
    },
    {
      x: 2923.295454545455,
      y: 0.09512710571289062,
    },
    {
      x: 3034.0909090909095,
      y: 0.09512710571289062,
    },
    {
      x: 3038.352272727273,
      y: 0.0851593017578125,
    },
    {
      x: 3042.6136363636365,
      y: 0.05525970458984375,
    },
    {
      x: 3046.8750000000005,
      y: 0.020374298095703125,
    },
    {
      x: 3051.136363636364,
      y: -0.004543304443359375,
    },
    {
      x: 3055.3977272727275,
      y: -0.01949310302734375,
    },
    {
      x: 3059.659090909091,
      y: -0.029460906982421875,
    },
    {
      x: 3063.920454545455,
      y: -0.04441070556640625,
    },
    {
      x: 3068.1818181818185,
      y: -0.054378509521484375,
    },
    {
      x: 3072.443181818182,
      y: -0.0643463134765625,
    },
    {
      x: 3076.7045454545455,
      y: -0.074310302734375,
    },
    {
      x: 3080.9659090909095,
      y: -0.08427810668945312,
    },
    {
      x: 3089.4886363636365,
      y: -0.10421371459960938,
    },
    {
      x: 3093.7500000000005,
      y: -0.11916351318359375,
    },
    {
      x: 3098.011363636364,
      y: -0.12913131713867188,
    },
    {
      x: 3106.534090909091,
      y: -0.14906692504882812,
    },
    {
      x: 3110.795454545455,
      y: -0.15404891967773438,
    },
    {
      x: 3115.0568181818185,
      y: -0.1640167236328125,
    },
    {
      x: 3119.318181818182,
      y: -0.17398452758789062,
    },
    {
      x: 3123.5795454545455,
      y: -0.18394851684570312,
    },
    {
      x: 3127.8409090909095,
      y: -0.17896652221679688,
    },
    {
      x: 3132.102272727273,
      y: -0.1640167236328125,
    },
    {
      x: 3140.6250000000005,
      y: -0.13411712646484375,
    },
    {
      x: 3144.886363636364,
      y: -0.11916351318359375,
    },
    {
      x: 3149.1477272727275,
      y: -0.10421371459960938,
    },
    {
      x: 3153.409090909091,
      y: -0.08427810668945312,
    },
    {
      x: 3157.670454545455,
      y: -0.06932830810546875,
    },
    {
      x: 3174.7159090909095,
      y: -0.00952911376953125,
    },
    {
      x: 3178.977272727273,
      y: 0.00542449951171875,
    },
    {
      x: 3183.2386363636365,
      y: 0.020374298095703125,
    },
    {
      x: 3191.761363636364,
      y: 0.050273895263671875,
    },
    {
      x: 3196.0227272727275,
      y: 0.06024169921875,
    },
    {
      x: 3200.284090909091,
      y: 0.07020950317382812,
    },
    {
      x: 3204.545454545455,
      y: 0.0751953125,
    },
    {
      x: 3208.8068181818185,
      y: 0.08017730712890625,
    },
    {
      x: 3213.068181818182,
      y: 0.08017730712890625,
    },
    {
      x: 3238.636363636364,
      y: 0.08017730712890625,
    },
    {
      x: 3242.8977272727275,
      y: 0.0851593017578125,
    },
    {
      x: 3247.159090909091,
      y: 0.0851593017578125,
    },
    {
      x: 3268.4659090909095,
      y: 0.0851593017578125,
    },
    {
      x: 3272.727272727273,
      y: 0.09512710571289062,
    },
    {
      x: 3276.9886363636365,
      y: 0.09512710571289062,
    },
    {
      x: 3532.670454545455,
      y: 0.09512710571289062,
    },
    {
      x: 3536.9318181818185,
      y: 0.125030517578125,
    },
    {
      x: 3541.193181818182,
      y: 0.21473312377929688,
    },
    {
      x: 3545.454545454546,
      y: 0.0851593017578125,
    },
    {
      x: 3549.7159090909095,
      y: 0.0851593017578125,
    },
    {
      x: 3571.0227272727275,
      y: 0.0851593017578125,
    },
    {
      x: 3575.284090909091,
      y: 0.09014511108398438,
    },
    {
      x: 3579.545454545455,
      y: 0.09014511108398438,
    },
    {
      x: 3605.1136363636365,
      y: 0.09014511108398438,
    },
    {
      x: 3609.3750000000005,
      y: 0.09512710571289062,
    },
    {
      x: 3613.636363636364,
      y: 0.09512710571289062,
    },
    {
      x: 3877.8409090909095,
      y: 0.09512710571289062,
    },
    {
      x: 3882.102272727273,
      y: 0.0851593017578125,
    },
    {
      x: 3886.3636363636365,
      y: 0.05525970458984375,
    },
    {
      x: 3890.6250000000005,
      y: 0.020374298095703125,
    },
    {
      x: 3894.886363636364,
      y: -0.004543304443359375,
    },
    {
      x: 3899.1477272727275,
      y: -0.01949310302734375,
    },
    {
      x: 3903.409090909091,
      y: -0.029460906982421875,
    },
    {
      x: 3907.670454545455,
      y: -0.04441070556640625,
    },
    {
      x: 3911.9318181818185,
      y: -0.054378509521484375,
    },
    {
      x: 3916.193181818182,
      y: -0.0643463134765625,
    },
    {
      x: 3920.454545454546,
      y: -0.074310302734375,
    },
    {
      x: 3924.7159090909095,
      y: -0.08427810668945312,
    },
    {
      x: 3933.2386363636365,
      y: -0.10421371459960938,
    },
    {
      x: 3937.5000000000005,
      y: -0.11916351318359375,
    },
    {
      x: 3941.761363636364,
      y: -0.12913131713867188,
    },
    {
      x: 3950.284090909091,
      y: -0.14906692504882812,
    },
    {
      x: 3954.545454545455,
      y: -0.15404891967773438,
    },
    {
      x: 3958.8068181818185,
      y: -0.1640167236328125,
    },
    {
      x: 3963.068181818182,
      y: -0.17398452758789062,
    },
    {
      x: 3967.329545454546,
      y: -0.18394851684570312,
    },
    {
      x: 3971.5909090909095,
      y: -0.17896652221679688,
    },
    {
      x: 3975.852272727273,
      y: -0.1640167236328125,
    },
    {
      x: 3984.3750000000005,
      y: -0.13411712646484375,
    },
    {
      x: 3988.636363636364,
      y: -0.11916351318359375,
    },
    {
      x: 3992.8977272727275,
      y: -0.10421371459960938,
    },
    {
      x: 3997.159090909091,
      y: -0.08427810668945312,
    },
    {
      x: 4001.420454545455,
      y: -0.06932830810546875,
    },
    {
      x: 4018.4659090909095,
      y: -0.00952911376953125,
    },
    {
      x: 4022.727272727273,
      y: 0.00542449951171875,
    },
    {
      x: 4026.9886363636365,
      y: 0.020374298095703125,
    },
    {
      x: 4035.511363636364,
      y: 0.050273895263671875,
    },
    {
      x: 4039.7727272727275,
      y: 0.06024169921875,
    },
    {
      x: 4044.034090909091,
      y: 0.07020950317382812,
    },
    {
      x: 4048.295454545455,
      y: 0.0751953125,
    },
    {
      x: 4052.5568181818185,
      y: 0.08017730712890625,
    },
    {
      x: 4056.818181818182,
      y: 0.08017730712890625,
    },
    {
      x: 4082.386363636364,
      y: 0.08017730712890625,
    },
    {
      x: 4086.6477272727275,
      y: 0.0851593017578125,
    },
    {
      x: 4090.909090909091,
      y: 0.09512710571289062,
    },
    {
      x: 4095.170454545455,
      y: 0.09512710571289062,
    },
    {
      x: 4103.693181818182,
      y: 0.09512710571289062,
    },
    {
      x: 4107.954545454546,
      y: 0.125030517578125,
    },
    {
      x: 4112.215909090909,
      y: 0.21473312377929688,
    },
    {
      x: 4116.477272727273,
      y: 0.31439971923828125,
    },
    {
      x: 4120.738636363637,
      y: 0.424041748046875,
    },
    {
      x: 4125,
      y: 0.5336761474609375,
    },
    {
      x: 4129.261363636364,
      y: 0.643310546875,
    },
    {
      x: 4133.522727272728,
      y: 0.7529525756835938,
    },
    {
      x: 4137.784090909091,
      y: 0.85760498046875,
    },
    {
      x: 4142.045454545455,
      y: 0.9622573852539062,
    },
    {
      x: 4146.306818181819,
      y: 1.0669136047363281,
    },
    {
      x: 4150.568181818182,
      y: 1.1217308044433594,
    },
    {
      x: 4154.829545454546,
      y: 1.0669136047363281,
    },
    {
      x: 4159.090909090909,
      y: 0.9772109985351562,
    },
    {
      x: 4163.352272727273,
      y: 0.8725547790527344,
    },
    {
      x: 4167.613636363637,
      y: 0.7629203796386719,
    },
    {
      x: 4171.875,
      y: 0.6482963562011719,
    },
    {
      x: 4176.136363636364,
      y: 0.5436439514160156,
    },
    {
      x: 4180.397727272728,
      y: 0.4340057373046875,
    },
    {
      x: 4184.659090909091,
      y: 0.3243675231933594,
    },
    {
      x: 4188.920454545455,
      y: 0.21473312377929688,
    },
    {
      x: 4193.181818181819,
      y: 0.13001251220703125,
    },
    {
      x: 4197.443181818182,
      y: 0.09512710571289062,
    },
    {
      x: 4201.704545454546,
      y: 0.0851593017578125,
    },
    {
      x: 4205.965909090909,
      y: 0.08017730712890625,
    },
    {
      x: 4210.227272727273,
      y: 0.08017730712890625,
    },
    {
      x: 4235.795454545455,
      y: 0.08017730712890625,
    },
    {
      x: 4240.056818181819,
      y: 0.0851593017578125,
    },
    {
      x: 4244.318181818182,
      y: 0.0851593017578125,
    },
    {
      x: 4269.886363636364,
      y: 0.0851593017578125,
    },
    {
      x: 4274.147727272728,
      y: 0.09014511108398438,
    },
    {
      x: 4278.409090909091,
      y: 0.09014511108398438,
    },
    {
      x: 4303.977272727273,
      y: 0.09014511108398438,
    },
    {
      x: 4308.238636363637,
      y: 0.09512710571289062,
    },
    {
      x: 4312.5,
      y: 0.09512710571289062,
    },
    {
      x: 4401.988636363637,
      y: 0.09512710571289062,
    },
    {
      x: 4406.25,
      y: 0.125030517578125,
    },
    {
      x: 4410.511363636364,
      y: 0.21473312377929688,
    },
    {
      x: 4414.772727272728,
      y: 0.31439971923828125,
    },
    {
      x: 4419.034090909091,
      y: 0.424041748046875,
    },
    {
      x: 4423.295454545455,
      y: 0.5336761474609375,
    },
    {
      x: 4427.556818181819,
      y: 0.643310546875,
    },
    {
      x: 4431.818181818182,
      y: 0.7529525756835938,
    },
    {
      x: 4436.079545454546,
      y: 0.85760498046875,
    },
    {
      x: 4440.340909090909,
      y: 0.9622573852539062,
    },
    {
      x: 4444.602272727273,
      y: 1.0669136047363281,
    },
    {
      x: 4448.863636363637,
      y: 1.1217308044433594,
    },
    {
      x: 4453.125,
      y: 1.0669136047363281,
    },
    {
      x: 4457.386363636364,
      y: 0.9772109985351562,
    },
    {
      x: 4461.647727272728,
      y: 0.8725547790527344,
    },
    {
      x: 4465.909090909091,
      y: 0.7629203796386719,
    },
    {
      x: 4470.170454545455,
      y: 0.6482963562011719,
    },
    {
      x: 4474.431818181819,
      y: 0.5436439514160156,
    },
    {
      x: 4478.693181818182,
      y: 0.4340057373046875,
    },
    {
      x: 4482.954545454546,
      y: 0.3243675231933594,
    },
    {
      x: 4487.215909090909,
      y: 0.21473312377929688,
    },
    {
      x: 4491.477272727273,
      y: 0.13001251220703125,
    },
    {
      x: 4495.738636363637,
      y: 0.09512710571289062,
    },
    {
      x: 4500,
      y: 0.0851593017578125,
    },
    {
      x: 4504.261363636364,
      y: 0.08017730712890625,
    },
    {
      x: 4508.522727272728,
      y: 0.08017730712890625,
    },
    {
      x: 4534.090909090909,
      y: 0.08017730712890625,
    },
    {
      x: 4538.352272727273,
      y: 0.0851593017578125,
    },
    {
      x: 4542.613636363637,
      y: 0.0851593017578125,
    },
    {
      x: 4568.181818181819,
      y: 0.0851593017578125,
    },
    {
      x: 4572.443181818182,
      y: 0.09014511108398438,
    },
    {
      x: 4576.704545454546,
      y: 0.09014511108398438,
    },
    {
      x: 4602.272727272728,
      y: 0.09014511108398438,
    },
    {
      x: 4606.534090909091,
      y: 0.09512710571289062,
    },
    {
      x: 4610.795454545455,
      y: 0.09512710571289062,
    },
    {
      x: 4904.829545454546,
      y: 0.09512710571289062,
    },
    {
      x: 4909.09090909091,
      y: 0.08017730712890625,
    },
    {
      x: 4913.352272727273,
      y: 0.08017730712890625,
    },
    {
      x: 4926.136363636364,
      y: 0.08017730712890625,
    },
    {
      x: 4930.397727272728,
      y: 0.0851593017578125,
    },
    {
      x: 4934.659090909091,
      y: 0.0851593017578125,
    },
    {
      x: 4960.227272727273,
      y: 0.0851593017578125,
    },
    {
      x: 4964.488636363637,
      y: 0.09014511108398438,
    },
    {
      x: 4968.75,
      y: 0.09014511108398438,
    },
    {
      x: 4994.318181818182,
      y: 0.09014511108398438,
    },
    {
      x: 4998.579545454546,
      y: 0.09512710571289062,
    },
    {
      x: 5002.84090909091,
      y: 0.09512710571289062,
    },
    {
      x: 5267.045454545455,
      y: 0.09512710571289062,
    },
    {
      x: 5271.306818181819,
      y: 0.0851593017578125,
    },
    {
      x: 5275.568181818182,
      y: 0.05525970458984375,
    },
    {
      x: 5279.829545454546,
      y: 0.020374298095703125,
    },
    {
      x: 5284.09090909091,
      y: -0.004543304443359375,
    },
    {
      x: 5288.352272727273,
      y: -0.01949310302734375,
    },
    {
      x: 5292.613636363637,
      y: -0.029460906982421875,
    },
    {
      x: 5296.875,
      y: -0.04441070556640625,
    },
    {
      x: 5301.136363636364,
      y: -0.054378509521484375,
    },
    {
      x: 5305.397727272728,
      y: -0.0643463134765625,
    },
    {
      x: 5309.659090909091,
      y: -0.074310302734375,
    },
    {
      x: 5313.920454545455,
      y: -0.08427810668945312,
    },
    {
      x: 5322.443181818182,
      y: -0.10421371459960938,
    },
    {
      x: 5326.704545454546,
      y: -0.11916351318359375,
    },
    {
      x: 5330.96590909091,
      y: -0.12913131713867188,
    },
    {
      x: 5339.488636363637,
      y: -0.14906692504882812,
    },
    {
      x: 5343.75,
      y: -0.15404891967773438,
    },
    {
      x: 5348.011363636364,
      y: -0.1640167236328125,
    },
    {
      x: 5352.272727272728,
      y: -0.17398452758789062,
    },
    {
      x: 5356.534090909091,
      y: -0.18394851684570312,
    },
    {
      x: 5360.795454545455,
      y: -0.17896652221679688,
    },
    {
      x: 5365.056818181819,
      y: -0.1640167236328125,
    },
    {
      x: 5373.579545454546,
      y: -0.13411712646484375,
    },
    {
      x: 5377.84090909091,
      y: -0.11916351318359375,
    },
    {
      x: 5382.102272727273,
      y: -0.10421371459960938,
    },
    {
      x: 5386.363636363637,
      y: -0.08427810668945312,
    },
    {
      x: 5390.625,
      y: -0.06932830810546875,
    },
    {
      x: 5407.670454545455,
      y: -0.00952911376953125,
    },
    {
      x: 5411.931818181819,
      y: 0.00542449951171875,
    },
    {
      x: 5416.193181818182,
      y: 0.020374298095703125,
    },
    {
      x: 5424.71590909091,
      y: 0.050273895263671875,
    },
    {
      x: 5428.977272727273,
      y: 0.06024169921875,
    },
    {
      x: 5433.238636363637,
      y: 0.07020950317382812,
    },
    {
      x: 5437.5,
      y: 0.0751953125,
    },
    {
      x: 5441.761363636364,
      y: 0.08017730712890625,
    },
    {
      x: 5446.022727272728,
      y: 0.08017730712890625,
    },
    {
      x: 5450.284090909091,
      y: 0.08017730712890625,
    },
    {
      x: 5454.545454545455,
      y: 0.09512710571289062,
    },
    {
      x: 5458.806818181819,
      y: 0.09512710571289062,
    },
    {
      x: 5723.011363636364,
      y: 0.09512710571289062,
    },
    {
      x: 5727.272727272728,
      y: 0.06024169921875,
    },
    {
      x: 5731.534090909091,
      y: 0.07020950317382812,
    },
    {
      x: 5735.795454545455,
      y: 0.0751953125,
    },
    {
      x: 5740.056818181819,
      y: 0.0751953125,
    },
    {
      x: 5744.318181818182,
      y: 0.08017730712890625,
    },
    {
      x: 5748.579545454546,
      y: 0.08017730712890625,
    },
    {
      x: 5769.886363636364,
      y: 0.08017730712890625,
    },
    {
      x: 5774.147727272728,
      y: 0.0851593017578125,
    },
    {
      x: 5778.409090909091,
      y: 0.0851593017578125,
    },
    {
      x: 5803.977272727273,
      y: 0.0851593017578125,
    },
    {
      x: 5808.238636363637,
      y: 0.09014511108398438,
    },
    {
      x: 5812.5,
      y: 0.09014511108398438,
    },
    {
      x: 5838.068181818182,
      y: 0.09014511108398438,
    },
    {
      x: 5842.329545454546,
      y: 0.09512710571289062,
    },
    {
      x: 5846.59090909091,
      y: 0.09512710571289062,
    },
    {
      x: 6110.795454545455,
      y: 0.09512710571289062,
    },
    {
      x: 6115.056818181819,
      y: 0.0851593017578125,
    },
    {
      x: 6119.318181818182,
      y: 0.05525970458984375,
    },
    {
      x: 6123.579545454546,
      y: 0.020374298095703125,
    },
    {
      x: 6127.84090909091,
      y: -0.004543304443359375,
    },
    {
      x: 6132.102272727273,
      y: -0.01949310302734375,
    },
    {
      x: 6136.363636363637,
      y: -0.029460906982421875,
    },
    {
      x: 6140.625000000001,
      y: -0.04441070556640625,
    },
    {
      x: 6144.886363636364,
      y: -0.054378509521484375,
    },
    {
      x: 6149.147727272728,
      y: -0.0643463134765625,
    },
    {
      x: 6153.409090909091,
      y: -0.074310302734375,
    },
    {
      x: 6157.670454545455,
      y: -0.08427810668945312,
    },
    {
      x: 6166.193181818182,
      y: -0.10421371459960938,
    },
    {
      x: 6170.454545454546,
      y: -0.11916351318359375,
    },
    {
      x: 6174.71590909091,
      y: -0.12913131713867188,
    },
    {
      x: 6178.977272727273,
      y: -0.13411712646484375,
    },
    {
      x: 6183.238636363637,
      y: -0.14408111572265625,
    },
    {
      x: 6187.500000000001,
      y: -0.15404891967773438,
    },
    {
      x: 6196.022727272728,
      y: -0.17398452758789062,
    },
    {
      x: 6200.284090909091,
      y: -0.18394851684570312,
    },
    {
      x: 6204.545454545455,
      y: -0.17896652221679688,
    },
    {
      x: 6208.806818181819,
      y: -0.1640167236328125,
    },
    {
      x: 6217.329545454546,
      y: -0.13411712646484375,
    },
    {
      x: 6221.59090909091,
      y: -0.11916351318359375,
    },
    {
      x: 6225.852272727273,
      y: -0.10421371459960938,
    },
    {
      x: 6230.113636363637,
      y: -0.08427810668945312,
    },
    {
      x: 6234.375000000001,
      y: -0.06932830810546875,
    },
    {
      x: 6251.420454545455,
      y: -0.00952911376953125,
    },
    {
      x: 6255.681818181819,
      y: 0.00542449951171875,
    },
    {
      x: 6259.943181818182,
      y: 0.020374298095703125,
    },
    {
      x: 6268.46590909091,
      y: 0.050273895263671875,
    },
    {
      x: 6272.727272727273,
      y: 0.09512710571289062,
    },
    {
      x: 6276.988636363637,
      y: 0.09512710571289062,
    },
    {
      x: 6336.647727272728,
      y: 0.09512710571289062,
    },
    {
      x: 6340.909090909091,
      y: 0.125030517578125,
    },
    {
      x: 6345.170454545455,
      y: 0.21473312377929688,
    },
    {
      x: 6349.431818181819,
      y: 0.31439971923828125,
    },
    {
      x: 6353.693181818182,
      y: 0.424041748046875,
    },
    {
      x: 6357.954545454546,
      y: 0.5336761474609375,
    },
    {
      x: 6362.21590909091,
      y: 0.643310546875,
    },
    {
      x: 6366.477272727273,
      y: 0.7529525756835938,
    },
    {
      x: 6370.738636363637,
      y: 0.85760498046875,
    },
    {
      x: 6375.000000000001,
      y: 0.9622573852539062,
    },
    {
      x: 6379.261363636364,
      y: 1.0669136047363281,
    },
    {
      x: 6383.522727272728,
      y: 1.1217308044433594,
    },
    {
      x: 6387.784090909091,
      y: 1.0669136047363281,
    },
    {
      x: 6392.045454545455,
      y: 0.9772109985351562,
    },
    {
      x: 6396.306818181819,
      y: 0.8725547790527344,
    },
    {
      x: 6400.568181818182,
      y: 0.7629203796386719,
    },
    {
      x: 6404.829545454546,
      y: 0.6482963562011719,
    },
    {
      x: 6409.09090909091,
      y: 0.5436439514160156,
    },
    {
      x: 6413.352272727273,
      y: 0.4340057373046875,
    },
    {
      x: 6417.613636363637,
      y: 0.3243675231933594,
    },
    {
      x: 6421.875000000001,
      y: 0.21473312377929688,
    },
    {
      x: 6426.136363636364,
      y: 0.13001251220703125,
    },
    {
      x: 6430.397727272728,
      y: 0.09512710571289062,
    },
    {
      x: 6434.659090909091,
      y: 0.0851593017578125,
    },
    {
      x: 6438.920454545455,
      y: 0.08017730712890625,
    },
    {
      x: 6443.181818181819,
      y: 0.08017730712890625,
    },
    {
      x: 6468.750000000001,
      y: 0.08017730712890625,
    },
    {
      x: 6473.011363636364,
      y: 0.0851593017578125,
    },
    {
      x: 6477.272727272728,
      y: 0.0851593017578125,
    },
    {
      x: 6502.84090909091,
      y: 0.0851593017578125,
    },
    {
      x: 6507.102272727273,
      y: 0.09014511108398438,
    },
    {
      x: 6511.363636363637,
      y: 0.09014511108398438,
    },
    {
      x: 6536.931818181819,
      y: 0.09014511108398438,
    },
    {
      x: 6541.193181818182,
      y: 0.09512710571289062,
    },
    {
      x: 6545.454545454546,
      y: -0.024478912353515625,
    },
    {
      x: 6549.71590909091,
      y: -0.00952911376953125,
    },
    {
      x: 6553.977272727273,
      y: 0.00542449951171875,
    },
    {
      x: 6558.238636363637,
      y: 0.020374298095703125,
    },
    {
      x: 6566.761363636364,
      y: 0.050273895263671875,
    },
    {
      x: 6571.022727272728,
      y: 0.06024169921875,
    },
    {
      x: 6575.284090909091,
      y: 0.07020950317382812,
    },
    {
      x: 6579.545454545455,
      y: 0.0751953125,
    },
    {
      x: 6583.806818181819,
      y: 0.08017730712890625,
    },
    {
      x: 6588.068181818182,
      y: 0.08017730712890625,
    },
    {
      x: 6613.636363636364,
      y: 0.08017730712890625,
    },
    {
      x: 6617.897727272728,
      y: 0.0851593017578125,
    },
    {
      x: 6622.159090909091,
      y: 0.0851593017578125,
    },
    {
      x: 6647.727272727273,
      y: 0.0851593017578125,
    },
    {
      x: 6651.988636363637,
      y: 0.09014511108398438,
    },
    {
      x: 6656.250000000001,
      y: 0.09014511108398438,
    },
    {
      x: 6681.818181818182,
      y: 0.09014511108398438,
    },
    {
      x: 6686.079545454546,
      y: 0.09512710571289062,
    },
    {
      x: 6690.34090909091,
      y: 0.09512710571289062,
    },
    {
      x: 7086.647727272728,
      y: 0.09512710571289062,
    },
    {
      x: 7090.909090909092,
      y: 0.09014511108398438,
    },
    {
      x: 7095.170454545455,
      y: 0.09014511108398438,
    },
    {
      x: 7107.954545454546,
      y: 0.09014511108398438,
    },
    {
      x: 7112.21590909091,
      y: 0.09512710571289062,
    },
    {
      x: 7116.477272727273,
      y: 0.09512710571289062,
    },
    {
      x: 7227.272727272728,
      y: 0.09512710571289062,
    },
    {
      x: 7231.534090909092,
      y: 0.0851593017578125,
    },
    {
      x: 7235.795454545455,
      y: 0.05525970458984375,
    },
    {
      x: 7240.056818181819,
      y: 0.020374298095703125,
    },
    {
      x: 7244.318181818182,
      y: -0.004543304443359375,
    },
    {
      x: 7248.579545454546,
      y: -0.01949310302734375,
    },
    {
      x: 7252.84090909091,
      y: -0.029460906982421875,
    },
    {
      x: 7257.102272727273,
      y: -0.04441070556640625,
    },
    {
      x: 7261.363636363637,
      y: -0.054378509521484375,
    },
    {
      x: 7265.625000000001,
      y: -0.0643463134765625,
    },
    {
      x: 7269.886363636364,
      y: -0.074310302734375,
    },
    {
      x: 7274.147727272728,
      y: -0.08427810668945312,
    },
    {
      x: 7282.670454545455,
      y: -0.10421371459960938,
    },
    {
      x: 7286.931818181819,
      y: -0.11916351318359375,
    },
    {
      x: 7291.193181818182,
      y: -0.12913131713867188,
    },
    {
      x: 7299.71590909091,
      y: -0.14906692504882812,
    },
    {
      x: 7303.977272727273,
      y: -0.15404891967773438,
    },
    {
      x: 7308.238636363637,
      y: -0.1640167236328125,
    },
    {
      x: 7312.500000000001,
      y: -0.17398452758789062,
    },
    {
      x: 7316.761363636364,
      y: -0.18394851684570312,
    },
    {
      x: 7321.022727272728,
      y: -0.17896652221679688,
    },
    {
      x: 7325.284090909092,
      y: -0.1640167236328125,
    },
    {
      x: 7333.806818181819,
      y: -0.13411712646484375,
    },
    {
      x: 7338.068181818182,
      y: -0.11916351318359375,
    },
    {
      x: 7342.329545454546,
      y: -0.10421371459960938,
    },
    {
      x: 7346.59090909091,
      y: -0.08427810668945312,
    },
    {
      x: 7350.852272727273,
      y: -0.06932830810546875,
    },
    {
      x: 7359.375000000001,
      y: -0.0394287109375,
    },
    {
      x: 7363.636363636364,
      y: 0.09512710571289062,
    },
    {
      x: 7367.897727272728,
      y: 0.09512710571289062,
    },
    {
      x: 7453.125000000001,
      y: 0.09512710571289062,
    },
    {
      x: 7457.386363636364,
      y: 0.125030517578125,
    },
    {
      x: 7461.647727272728,
      y: 0.21473312377929688,
    },
    {
      x: 7465.909090909092,
      y: 0.31439971923828125,
    },
    {
      x: 7470.170454545455,
      y: 0.424041748046875,
    },
    {
      x: 7474.431818181819,
      y: 0.5336761474609375,
    },
    {
      x: 7478.693181818182,
      y: 0.643310546875,
    },
    {
      x: 7482.954545454546,
      y: 0.7529525756835938,
    },
    {
      x: 7487.21590909091,
      y: 0.85760498046875,
    },
    {
      x: 7491.477272727273,
      y: 0.9622573852539062,
    },
    {
      x: 7495.738636363637,
      y: 1.0669136047363281,
    },
    {
      x: 7500.000000000001,
      y: 1.1217308044433594,
    },
    {
      x: 7504.261363636364,
      y: 1.0669136047363281,
    },
    {
      x: 7508.522727272728,
      y: 0.9772109985351562,
    },
    {
      x: 7512.784090909092,
      y: 0.8725547790527344,
    },
    {
      x: 7517.045454545455,
      y: 0.7629203796386719,
    },
    {
      x: 7521.306818181819,
      y: 0.6482963562011719,
    },
    {
      x: 7525.568181818182,
      y: 0.5436439514160156,
    },
    {
      x: 7529.829545454546,
      y: 0.4340057373046875,
    },
    {
      x: 7534.09090909091,
      y: 0.3243675231933594,
    },
    {
      x: 7538.352272727273,
      y: 0.21473312377929688,
    },
    {
      x: 7542.613636363637,
      y: 0.13001251220703125,
    },
    {
      x: 7546.875000000001,
      y: 0.09512710571289062,
    },
    {
      x: 7551.136363636364,
      y: 0.0851593017578125,
    },
    {
      x: 7555.397727272728,
      y: 0.08017730712890625,
    },
    {
      x: 7559.659090909092,
      y: 0.08017730712890625,
    },
    {
      x: 7585.227272727273,
      y: 0.08017730712890625,
    },
    {
      x: 7589.488636363637,
      y: 0.0851593017578125,
    },
    {
      x: 7593.750000000001,
      y: 0.0851593017578125,
    },
    {
      x: 7619.318181818182,
      y: 0.0851593017578125,
    },
    {
      x: 7623.579545454546,
      y: 0.09014511108398438,
    },
    {
      x: 7627.84090909091,
      y: 0.09014511108398438,
    },
    {
      x: 7632.102272727273,
      y: 0.09014511108398438,
    },
    {
      x: 7636.363636363637,
      y: 0.09512710571289062,
    },
    {
      x: 7640.625000000001,
      y: 0.09512710571289062,
    },
    {
      x: 7904.829545454546,
      y: 0.09512710571289062,
    },
    {
      x: 7909.09090909091,
      y: -0.1141815185546875,
    },
    {
      x: 7913.352272727273,
      y: -0.09923171997070312,
    },
    {
      x: 7917.613636363637,
      y: -0.08427810668945312,
    },
    {
      x: 7921.875000000001,
      y: -0.06932830810546875,
    },
    {
      x: 7926.136363636364,
      y: -0.049396514892578125,
    },
    {
      x: 7930.397727272728,
      y: -0.034442901611328125,
    },
    {
      x: 7934.659090909092,
      y: -0.01949310302734375,
    },
    {
      x: 7947.443181818182,
      y: 0.025356292724609375,
    },
    {
      x: 7951.704545454546,
      y: 0.0353240966796875,
    },
    {
      x: 7955.96590909091,
      y: 0.050273895263671875,
    },
    {
      x: 7960.227272727273,
      y: 0.06522369384765625,
    },
    {
      x: 7964.488636363637,
      y: 0.0751953125,
    },
    {
      x: 7968.750000000001,
      y: 0.08017730712890625,
    },
    {
      x: 7973.011363636364,
      y: 0.08017730712890625,
    },
    {
      x: 7981.534090909092,
      y: 0.08017730712890625,
    },
    {
      x: 7985.795454545455,
      y: 0.0851593017578125,
    },
    {
      x: 7990.056818181819,
      y: 0.0851593017578125,
    },
    {
      x: 8015.625000000001,
      y: 0.0851593017578125,
    },
    {
      x: 8019.886363636364,
      y: 0.09014511108398438,
    },
    {
      x: 8024.147727272728,
      y: 0.09014511108398438,
    },
    {
      x: 8049.71590909091,
      y: 0.09014511108398438,
    },
    {
      x: 8053.977272727273,
      y: 0.09512710571289062,
    },
    {
      x: 8058.238636363637,
      y: 0.09512710571289062,
    },
    {
      x: 8177.556818181819,
      y: 0.09512710571289062,
    },
    {
      x: 8181.818181818182,
      y: 0.08017730712890625,
    },
    {
      x: 8186.079545454546,
      y: 0.08017730712890625,
    },
    {
      x: 8190.34090909091,
      y: 0.0851593017578125,
    },
    {
      x: 8194.602272727274,
      y: 0.0851593017578125,
    },
    {
      x: 8220.170454545456,
      y: 0.0851593017578125,
    },
    {
      x: 8224.431818181818,
      y: 0.09014511108398438,
    },
    {
      x: 8228.693181818182,
      y: 0.09014511108398438,
    },
    {
      x: 8254.261363636364,
      y: 0.09014511108398438,
    },
    {
      x: 8258.522727272728,
      y: 0.09512710571289062,
    },
    {
      x: 8262.784090909092,
      y: 0.09512710571289062,
    },
    {
      x: 8343.75,
      y: 0.09512710571289062,
    },
    {
      x: 8348.011363636364,
      y: 0.0851593017578125,
    },
    {
      x: 8352.272727272728,
      y: 0.05525970458984375,
    },
    {
      x: 8356.534090909092,
      y: 0.020374298095703125,
    },
    {
      x: 8360.795454545456,
      y: 0.0004425048828125,
    },
    {
      x: 8365.056818181818,
      y: -0.0145111083984375,
    },
    {
      x: 8369.318181818182,
      y: -0.024478912353515625,
    },
    {
      x: 8373.579545454546,
      y: -0.0394287109375,
    },
    {
      x: 8377.84090909091,
      y: -0.049396514892578125,
    },
    {
      x: 8382.102272727274,
      y: -0.05936431884765625,
    },
    {
      x: 8386.363636363638,
      y: -0.06932830810546875,
    },
    {
      x: 8390.625,
      y: -0.07929611206054688,
    },
    {
      x: 8399.147727272728,
      y: -0.09923171997070312,
    },
    {
      x: 8403.409090909092,
      y: -0.1141815185546875,
    },
    {
      x: 8407.670454545456,
      y: -0.1241455078125,
    },
    {
      x: 8411.931818181818,
      y: -0.13411712646484375,
    },
    {
      x: 8416.193181818182,
      y: -0.14408111572265625,
    },
    {
      x: 8420.454545454546,
      y: -0.14906692504882812,
    },
    {
      x: 8424.71590909091,
      y: -0.15903091430664062,
    },
    {
      x: 8428.977272727274,
      y: -0.16899871826171875,
    },
    {
      x: 8433.238636363638,
      y: -0.18394851684570312,
    },
    {
      x: 8437.5,
      y: -0.17398452758789062,
    },
    {
      x: 8441.761363636364,
      y: -0.15903091430664062,
    },
    {
      x: 8446.022727272728,
      y: -0.14408111572265625,
    },
    {
      x: 8450.284090909092,
      y: -0.12913131713867188,
    },
    {
      x: 8454.545454545456,
      y: 0.09512710571289062,
    },
    {
      x: 8458.806818181818,
      y: 0.09512710571289062,
    },
    {
      x: 8569.602272727274,
      y: 0.09512710571289062,
    },
    {
      x: 8573.863636363638,
      y: 0.125030517578125,
    },
    {
      x: 8578.125,
      y: 0.21473312377929688,
    },
    {
      x: 8582.386363636364,
      y: 0.31439971923828125,
    },
    {
      x: 8586.647727272728,
      y: 0.424041748046875,
    },
    {
      x: 8590.909090909092,
      y: 0.5336761474609375,
    },
    {
      x: 8595.170454545456,
      y: 0.643310546875,
    },
    {
      x: 8599.431818181818,
      y: 0.7529525756835938,
    },
    {
      x: 8603.693181818182,
      y: 0.85760498046875,
    },
    {
      x: 8607.954545454546,
      y: 0.9622573852539062,
    },
    {
      x: 8612.21590909091,
      y: 1.0669136047363281,
    },
    {
      x: 8616.477272727274,
      y: 1.1167488098144531,
    },
    {
      x: 8620.738636363638,
      y: 1.0669136047363281,
    },
    {
      x: 8625,
      y: 0.9772109985351562,
    },
    {
      x: 8629.261363636364,
      y: 0.8675727844238281,
    },
    {
      x: 8642.045454545456,
      y: 0.5386581420898438,
    },
    {
      x: 8646.306818181818,
      y: 0.42902374267578125,
    },
    {
      x: 8650.568181818182,
      y: 0.3193855285644531,
    },
    {
      x: 8654.829545454546,
      y: 0.209747314453125,
    },
    {
      x: 8659.09090909091,
      y: 0.125030517578125,
    },
    {
      x: 8663.352272727274,
      y: 0.09014511108398438,
    },
    {
      x: 8667.613636363638,
      y: 0.08017730712890625,
    },
    {
      x: 8671.875,
      y: 0.0751953125,
    },
    {
      x: 8676.136363636364,
      y: 0.0751953125,
    },
    {
      x: 8697.443181818182,
      y: 0.0751953125,
    },
    {
      x: 8701.704545454546,
      y: 0.08017730712890625,
    },
    {
      x: 8705.96590909091,
      y: 0.08017730712890625,
    },
    {
      x: 8723.011363636364,
      y: 0.08017730712890625,
    },
    {
      x: 8727.272727272728,
      y: 0.09512710571289062,
    },
    {
      x: 8731.534090909092,
      y: 0.09512710571289062,
    },
    {
      x: 9140.625,
      y: 0.09512710571289062,
    },
    {
      x: 9144.886363636364,
      y: 0.125030517578125,
    },
    {
      x: 9149.147727272728,
      y: 0.21473312377929688,
    },
    {
      x: 9153.409090909092,
      y: 0.31439971923828125,
    },
    {
      x: 9157.670454545456,
      y: 0.424041748046875,
    },
    {
      x: 9161.931818181818,
      y: 0.5336761474609375,
    },
    {
      x: 9166.193181818182,
      y: 0.643310546875,
    },
    {
      x: 9170.454545454546,
      y: 0.7529525756835938,
    },
    {
      x: 9174.71590909091,
      y: 0.85760498046875,
    },
    {
      x: 9178.977272727274,
      y: 0.9622573852539062,
    },
    {
      x: 9183.238636363638,
      y: 1.0669136047363281,
    },
    {
      x: 9187.5,
      y: 1.1167488098144531,
    },
    {
      x: 9191.761363636364,
      y: 1.0669136047363281,
    },
    {
      x: 9196.022727272728,
      y: 0.9772109985351562,
    },
    {
      x: 9200.284090909092,
      y: 0.8675727844238281,
    },
    {
      x: 9213.068181818182,
      y: 0.5386581420898438,
    },
    {
      x: 9217.329545454546,
      y: 0.42902374267578125,
    },
    {
      x: 9221.59090909091,
      y: 0.3193855285644531,
    },
    {
      x: 9225.852272727274,
      y: 0.209747314453125,
    },
    {
      x: 9230.113636363638,
      y: 0.125030517578125,
    },
    {
      x: 9234.375,
      y: 0.09014511108398438,
    },
    {
      x: 9238.636363636364,
      y: 0.08017730712890625,
    },
    {
      x: 9242.897727272728,
      y: 0.0751953125,
    },
    {
      x: 9247.159090909092,
      y: 0.0751953125,
    },
    {
      x: 9268.46590909091,
      y: 0.0751953125,
    },
    {
      x: 9272.727272727274,
      y: -0.16899871826171875,
    },
    {
      x: 9276.988636363638,
      y: -0.18394851684570312,
    },
    {
      x: 9281.25,
      y: -0.17398452758789062,
    },
    {
      x: 9285.511363636364,
      y: -0.15903091430664062,
    },
    {
      x: 9289.772727272728,
      y: -0.14408111572265625,
    },
    {
      x: 9302.556818181818,
      y: -0.09923171997070312,
    },
    {
      x: 9306.818181818182,
      y: -0.08427810668945312,
    },
    {
      x: 9311.079545454546,
      y: -0.06932830810546875,
    },
    {
      x: 9315.34090909091,
      y: -0.049396514892578125,
    },
    {
      x: 9319.602272727274,
      y: -0.034442901611328125,
    },
    {
      x: 9323.863636363638,
      y: -0.01949310302734375,
    },
    {
      x: 9336.647727272728,
      y: 0.025356292724609375,
    },
    {
      x: 9340.909090909092,
      y: 0.0353240966796875,
    },
    {
      x: 9345.170454545456,
      y: 0.050273895263671875,
    },
    {
      x: 9349.431818181818,
      y: 0.06522369384765625,
    },
    {
      x: 9353.693181818182,
      y: 0.0751953125,
    },
    {
      x: 9357.954545454546,
      y: 0.08017730712890625,
    },
    {
      x: 9362.21590909091,
      y: 0.08017730712890625,
    },
    {
      x: 9370.738636363638,
      y: 0.08017730712890625,
    },
    {
      x: 9375,
      y: 0.0851593017578125,
    },
    {
      x: 9379.261363636364,
      y: 0.0851593017578125,
    },
    {
      x: 9404.829545454546,
      y: 0.0851593017578125,
    },
    {
      x: 9409.09090909091,
      y: 0.09014511108398438,
    },
    {
      x: 9413.352272727274,
      y: 0.09014511108398438,
    },
    {
      x: 9438.920454545456,
      y: 0.09014511108398438,
    },
    {
      x: 9443.181818181818,
      y: 0.09512710571289062,
    },
    {
      x: 9447.443181818182,
      y: 0.09512710571289062,
    },
    {
      x: 9541.193181818182,
      y: 0.09512710571289062,
    },
    {
      x: 9545.454545454546,
      y: 0.08017730712890625,
    },
    {
      x: 9549.71590909091,
      y: 0.08017730712890625,
    },
    {
      x: 9575.284090909092,
      y: 0.08017730712890625,
    },
    {
      x: 9579.545454545456,
      y: 0.0851593017578125,
    },
    {
      x: 9583.806818181818,
      y: 0.0851593017578125,
    },
    {
      x: 9609.375,
      y: 0.0851593017578125,
    },
    {
      x: 9613.636363636364,
      y: 0.09014511108398438,
    },
    {
      x: 9617.897727272728,
      y: 0.09014511108398438,
    },
    {
      x: 9643.46590909091,
      y: 0.09014511108398438,
    },
    {
      x: 9647.727272727274,
      y: 0.09512710571289062,
    },
    {
      x: 9651.988636363638,
      y: 0.09512710571289062,
    },
    {
      x: 9732.954545454546,
      y: 0.09512710571289062,
    },
    {
      x: 9737.21590909091,
      y: 0.0851593017578125,
    },
    {
      x: 9741.477272727274,
      y: 0.05525970458984375,
    },
    {
      x: 9745.738636363638,
      y: 0.020374298095703125,
    },
    {
      x: 9750,
      y: 0.0004425048828125,
    },
    {
      x: 9754.261363636364,
      y: -0.0145111083984375,
    },
    {
      x: 9758.522727272728,
      y: -0.024478912353515625,
    },
    {
      x: 9762.784090909092,
      y: -0.0394287109375,
    },
    {
      x: 9767.045454545456,
      y: -0.049396514892578125,
    },
    {
      x: 9771.306818181818,
      y: -0.05936431884765625,
    },
    {
      x: 9775.568181818182,
      y: -0.06932830810546875,
    },
    {
      x: 9779.829545454546,
      y: -0.07929611206054688,
    },
    {
      x: 9788.352272727274,
      y: -0.09923171997070312,
    },
    {
      x: 9792.613636363638,
      y: -0.1141815185546875,
    },
    {
      x: 9796.875,
      y: -0.1241455078125,
    },
    {
      x: 9801.136363636364,
      y: -0.13411712646484375,
    },
    {
      x: 9805.397727272728,
      y: -0.14408111572265625,
    },
    {
      x: 9809.659090909092,
      y: -0.14906692504882812,
    },
    {
      x: 9813.920454545456,
      y: -0.15903091430664062,
    },
    {
      x: 9818.18181818182,
      y: 0.09512710571289062,
    },
    {
      x: 9822.443181818182,
      y: 0.09512710571289062,
    },
    {
      x: 10086.647727272728,
      y: 0.09512710571289062,
    },
    {
      x: 10090.909090909092,
      y: -0.1141815185546875,
    },
    {
      x: 10095.170454545456,
      y: -0.1241455078125,
    },
    {
      x: 10099.43181818182,
      y: -0.12913131713867188,
    },
    {
      x: 10103.693181818182,
      y: -0.14408111572265625,
    },
    {
      x: 10107.954545454546,
      y: -0.14906692504882812,
    },
    {
      x: 10112.21590909091,
      y: -0.15903091430664062,
    },
    {
      x: 10116.477272727274,
      y: -0.16899871826171875,
    },
    {
      x: 10120.738636363638,
      y: -0.18394851684570312,
    },
    {
      x: 10125,
      y: -0.17398452758789062,
    },
    {
      x: 10129.261363636364,
      y: -0.15903091430664062,
    },
    {
      x: 10133.522727272728,
      y: -0.14408111572265625,
    },
    {
      x: 10146.30681818182,
      y: -0.09923171997070312,
    },
    {
      x: 10150.568181818182,
      y: -0.08427810668945312,
    },
    {
      x: 10154.829545454546,
      y: -0.06932830810546875,
    },
    {
      x: 10159.09090909091,
      y: -0.049396514892578125,
    },
    {
      x: 10163.352272727274,
      y: -0.034442901611328125,
    },
    {
      x: 10167.613636363638,
      y: -0.01949310302734375,
    },
    {
      x: 10180.397727272728,
      y: 0.025356292724609375,
    },
    {
      x: 10184.659090909092,
      y: 0.0353240966796875,
    },
    {
      x: 10188.920454545456,
      y: 0.050273895263671875,
    },
    {
      x: 10193.18181818182,
      y: 0.06522369384765625,
    },
    {
      x: 10197.443181818182,
      y: 0.0751953125,
    },
    {
      x: 10201.704545454546,
      y: 0.08017730712890625,
    },
    {
      x: 10205.96590909091,
      y: 0.08017730712890625,
    },
    {
      x: 10214.488636363638,
      y: 0.08017730712890625,
    },
    {
      x: 10218.75,
      y: 0.0851593017578125,
    },
    {
      x: 10223.011363636364,
      y: 0.0851593017578125,
    },
    {
      x: 10248.579545454546,
      y: 0.0851593017578125,
    },
    {
      x: 10252.84090909091,
      y: 0.09014511108398438,
    },
    {
      x: 10257.102272727274,
      y: 0.09014511108398438,
    },
    {
      x: 10282.670454545456,
      y: 0.09014511108398438,
    },
    {
      x: 10286.93181818182,
      y: 0.09512710571289062,
    },
    {
      x: 10291.193181818182,
      y: 0.09512710571289062,
    },
    {
      x: 10529.829545454546,
      y: 0.09512710571289062,
    },
    {
      x: 10534.09090909091,
      y: 0.125030517578125,
    },
    {
      x: 10538.352272727274,
      y: 0.21473312377929688,
    },
    {
      x: 10542.613636363638,
      y: 0.31439971923828125,
    },
    {
      x: 10546.875,
      y: 0.424041748046875,
    },
    {
      x: 10551.136363636364,
      y: 0.5336761474609375,
    },
    {
      x: 10555.397727272728,
      y: 0.643310546875,
    },
    {
      x: 10559.659090909092,
      y: 0.7529525756835938,
    },
    {
      x: 10563.920454545456,
      y: 0.85760498046875,
    },
    {
      x: 10568.18181818182,
      y: 0.9622573852539062,
    },
    {
      x: 10572.443181818182,
      y: 1.0669136047363281,
    },
    {
      x: 10576.704545454546,
      y: 1.1167488098144531,
    },
    {
      x: 10580.96590909091,
      y: 1.0669136047363281,
    },
    {
      x: 10585.227272727274,
      y: 0.9772109985351562,
    },
    {
      x: 10589.488636363638,
      y: 0.8675727844238281,
    },
    {
      x: 10602.272727272728,
      y: 0.5386581420898438,
    },
    {
      x: 10606.534090909092,
      y: 0.42902374267578125,
    },
    {
      x: 10610.795454545456,
      y: 0.3193855285644531,
    },
    {
      x: 10615.05681818182,
      y: 0.209747314453125,
    },
    {
      x: 10619.318181818182,
      y: 0.125030517578125,
    },
    {
      x: 10623.579545454546,
      y: 0.09014511108398438,
    },
    {
      x: 10627.84090909091,
      y: 0.08017730712890625,
    },
    {
      x: 10632.102272727274,
      y: 0.0751953125,
    },
    {
      x: 10636.363636363638,
      y: 0.0751953125,
    },
    {
      x: 10657.670454545456,
      y: 0.0751953125,
    },
    {
      x: 10661.93181818182,
      y: 0.08017730712890625,
    },
    {
      x: 10666.193181818182,
      y: 0.08017730712890625,
    },
    {
      x: 10691.761363636364,
      y: 0.08017730712890625,
    },
    {
      x: 10696.022727272728,
      y: 0.0851593017578125,
    },
    {
      x: 10700.284090909092,
      y: 0.0851593017578125,
    },
    {
      x: 10725.852272727274,
      y: 0.0851593017578125,
    },
    {
      x: 10730.113636363638,
      y: 0.09014511108398438,
    },
    {
      x: 10734.375,
      y: 0.09014511108398438,
    },
    {
      x: 10759.943181818182,
      y: 0.09014511108398438,
    },
    {
      x: 10764.204545454546,
      y: 0.09512710571289062,
    },
    {
      x: 10768.46590909091,
      y: 0.09512710571289062,
    },
    {
      x: 10849.43181818182,
      y: 0.09512710571289062,
    },
    {
      x: 10853.693181818182,
      y: 0.0851593017578125,
    },
    {
      x: 10857.954545454546,
      y: 0.05525970458984375,
    },
    {
      x: 10862.21590909091,
      y: 0.020374298095703125,
    },
    {
      x: 10866.477272727274,
      y: 0.0004425048828125,
    },
    {
      x: 10870.738636363638,
      y: -0.0145111083984375,
    },
    {
      x: 10875,
      y: -0.024478912353515625,
    },
    {
      x: 10879.261363636364,
      y: -0.0394287109375,
    },
    {
      x: 10883.522727272728,
      y: -0.049396514892578125,
    },
    {
      x: 10887.784090909092,
      y: -0.05936431884765625,
    },
    {
      x: 10892.045454545456,
      y: -0.06932830810546875,
    },
    {
      x: 10896.30681818182,
      y: -0.07929611206054688,
    },
    {
      x: 10904.829545454546,
      y: -0.09923171997070312,
    },
    {
      x: 10909.09090909091,
      y: 0.09512710571289062,
    },
    {
      x: 10913.352272727274,
      y: 0.09512710571289062,
    },
    {
      x: 11177.55681818182,
      y: 0.09512710571289062,
    },
    {
      x: 11181.818181818182,
      y: -0.049396514892578125,
    },
    {
      x: 11186.079545454546,
      y: -0.05936431884765625,
    },
    {
      x: 11190.34090909091,
      y: -0.06932830810546875,
    },
    {
      x: 11194.602272727274,
      y: -0.07929611206054688,
    },
    {
      x: 11203.125,
      y: -0.09923171997070312,
    },
    {
      x: 11207.386363636364,
      y: -0.1141815185546875,
    },
    {
      x: 11211.647727272728,
      y: -0.1241455078125,
    },
    {
      x: 11215.909090909092,
      y: -0.13411712646484375,
    },
    {
      x: 11220.170454545456,
      y: -0.14408111572265625,
    },
    {
      x: 11224.43181818182,
      y: -0.14906692504882812,
    },
    {
      x: 11228.693181818182,
      y: -0.15903091430664062,
    },
    {
      x: 11232.954545454546,
      y: -0.16899871826171875,
    },
    {
      x: 11237.21590909091,
      y: -0.17896652221679688,
    },
    {
      x: 11241.477272727274,
      y: -0.17398452758789062,
    },
    {
      x: 11245.738636363638,
      y: -0.15903091430664062,
    },
    {
      x: 11250,
      y: -0.14408111572265625,
    },
    {
      x: 11262.784090909092,
      y: -0.09923171997070312,
    },
    {
      x: 11267.045454545456,
      y: -0.08427810668945312,
    },
    {
      x: 11271.30681818182,
      y: -0.06932830810546875,
    },
    {
      x: 11275.568181818182,
      y: -0.049396514892578125,
    },
    {
      x: 11279.829545454546,
      y: -0.034442901611328125,
    },
    {
      x: 11284.09090909091,
      y: -0.01949310302734375,
    },
    {
      x: 11296.875,
      y: 0.025356292724609375,
    },
    {
      x: 11301.136363636364,
      y: 0.0353240966796875,
    },
    {
      x: 11305.397727272728,
      y: 0.050273895263671875,
    },
    {
      x: 11309.659090909092,
      y: 0.06522369384765625,
    },
    {
      x: 11313.920454545456,
      y: 0.0751953125,
    },
    {
      x: 11318.18181818182,
      y: 0.08017730712890625,
    },
    {
      x: 11322.443181818182,
      y: 0.08017730712890625,
    },
    {
      x: 11330.96590909091,
      y: 0.08017730712890625,
    },
    {
      x: 11335.227272727274,
      y: 0.0851593017578125,
    },
    {
      x: 11339.488636363638,
      y: 0.0851593017578125,
    },
    {
      x: 11365.05681818182,
      y: 0.0851593017578125,
    },
    {
      x: 11369.318181818182,
      y: 0.09014511108398438,
    },
    {
      x: 11373.579545454546,
      y: 0.09014511108398438,
    },
    {
      x: 11399.147727272728,
      y: 0.09014511108398438,
    },
    {
      x: 11403.409090909092,
      y: 0.09512710571289062,
    },
    {
      x: 11407.670454545456,
      y: 0.09512710571289062,
    },
    {
      x: 11646.30681818182,
      y: 0.09512710571289062,
    },
    {
      x: 11650.568181818182,
      y: 0.125030517578125,
    },
    {
      x: 11654.829545454546,
      y: 0.21473312377929688,
    },
    {
      x: 11659.09090909091,
      y: 0.31439971923828125,
    },
    {
      x: 11663.352272727274,
      y: 0.424041748046875,
    },
    {
      x: 11667.613636363638,
      y: 0.5336761474609375,
    },
    {
      x: 11671.875,
      y: 0.643310546875,
    },
    {
      x: 11676.136363636364,
      y: 0.7529525756835938,
    },
    {
      x: 11680.397727272728,
      y: 0.85760498046875,
    },
    {
      x: 11684.659090909092,
      y: 0.9622573852539062,
    },
    {
      x: 11688.920454545456,
      y: 1.0669136047363281,
    },
    {
      x: 11693.18181818182,
      y: 1.1167488098144531,
    },
    {
      x: 11697.443181818182,
      y: 1.0669136047363281,
    },
    {
      x: 11701.704545454546,
      y: 0.9772109985351562,
    },
    {
      x: 11705.96590909091,
      y: 0.8675727844238281,
    },
    {
      x: 11718.75,
      y: 0.5386581420898438,
    },
    {
      x: 11723.011363636364,
      y: 0.42902374267578125,
    },
    {
      x: 11727.272727272728,
      y: 0.3193855285644531,
    },
    {
      x: 11731.534090909092,
      y: 0.209747314453125,
    },
    {
      x: 11735.795454545456,
      y: 0.125030517578125,
    },
    {
      x: 11740.05681818182,
      y: 0.09014511108398438,
    },
    {
      x: 11744.318181818182,
      y: 0.08017730712890625,
    },
    {
      x: 11748.579545454546,
      y: 0.0751953125,
    },
    {
      x: 11752.84090909091,
      y: 0.0751953125,
    },
    {
      x: 11774.147727272728,
      y: 0.0751953125,
    },
    {
      x: 11778.409090909092,
      y: 0.08017730712890625,
    },
    {
      x: 11782.670454545456,
      y: 0.08017730712890625,
    },
    {
      x: 11808.238636363638,
      y: 0.08017730712890625,
    },
    {
      x: 11812.5,
      y: 0.0851593017578125,
    },
    {
      x: 11816.761363636364,
      y: 0.0851593017578125,
    },
    {
      x: 11842.329545454546,
      y: 0.0851593017578125,
    },
    {
      x: 11846.59090909091,
      y: 0.09014511108398438,
    },
    {
      x: 11850.852272727274,
      y: 0.09014511108398438,
    },
    {
      x: 11876.420454545456,
      y: 0.09014511108398438,
    },
    {
      x: 11880.68181818182,
      y: 0.09512710571289062,
    },
    {
      x: 11884.943181818182,
      y: 0.09512710571289062,
    },
    {
      x: 11965.909090909092,
      y: 0.09512710571289062,
    },
    {
      x: 11970.170454545456,
      y: 0.0851593017578125,
    },
    {
      x: 11974.43181818182,
      y: 0.05525970458984375,
    },
    {
      x: 11978.693181818182,
      y: 0.020374298095703125,
    },
    {
      x: 11982.954545454546,
      y: 0.0004425048828125,
    },
    {
      x: 11987.21590909091,
      y: -0.0145111083984375,
    },
    {
      x: 11991.477272727274,
      y: -0.024478912353515625,
    },
    {
      x: 11995.738636363638,
      y: -0.0394287109375,
    },
    {
      x: 12000,
      y: 0.09512710571289062,
    },
    {
      x: 12004.261363636364,
      y: 0.09512710571289062,
    },
    {
      x: 12268.46590909091,
      y: 0.09512710571289062,
    },
    {
      x: 12272.727272727274,
      y: 0.05525970458984375,
    },
    {
      x: 12276.988636363638,
      y: 0.020374298095703125,
    },
    {
      x: 12281.250000000002,
      y: 0.0004425048828125,
    },
    {
      x: 12285.511363636364,
      y: -0.0145111083984375,
    },
    {
      x: 12289.772727272728,
      y: -0.024478912353515625,
    },
    {
      x: 12294.034090909092,
      y: -0.0394287109375,
    },
    {
      x: 12298.295454545456,
      y: -0.049396514892578125,
    },
    {
      x: 12302.55681818182,
      y: -0.05936431884765625,
    },
    {
      x: 12306.818181818182,
      y: -0.06932830810546875,
    },
    {
      x: 12311.079545454546,
      y: -0.07929611206054688,
    },
    {
      x: 12319.602272727274,
      y: -0.09923171997070312,
    },
    {
      x: 12323.863636363638,
      y: -0.1141815185546875,
    },
    {
      x: 12328.125000000002,
      y: -0.1241455078125,
    },
    {
      x: 12332.386363636364,
      y: -0.13411712646484375,
    },
    {
      x: 12336.647727272728,
      y: -0.14408111572265625,
    },
    {
      x: 12340.909090909092,
      y: -0.14906692504882812,
    },
    {
      x: 12345.170454545456,
      y: -0.15903091430664062,
    },
    {
      x: 12349.43181818182,
      y: -0.16899871826171875,
    },
    {
      x: 12353.693181818182,
      y: -0.18394851684570312,
    },
    {
      x: 12357.954545454546,
      y: -0.17398452758789062,
    },
    {
      x: 12362.21590909091,
      y: -0.15903091430664062,
    },
    {
      x: 12366.477272727274,
      y: -0.14408111572265625,
    },
    {
      x: 12379.261363636364,
      y: -0.09923171997070312,
    },
    {
      x: 12383.522727272728,
      y: -0.08427810668945312,
    },
    {
      x: 12387.784090909092,
      y: -0.06932830810546875,
    },
    {
      x: 12392.045454545456,
      y: -0.049396514892578125,
    },
    {
      x: 12396.30681818182,
      y: -0.034442901611328125,
    },
    {
      x: 12400.568181818182,
      y: -0.01949310302734375,
    },
    {
      x: 12413.352272727274,
      y: 0.025356292724609375,
    },
    {
      x: 12417.613636363638,
      y: 0.0353240966796875,
    },
    {
      x: 12421.875000000002,
      y: 0.050273895263671875,
    },
    {
      x: 12426.136363636364,
      y: 0.06522369384765625,
    },
    {
      x: 12430.397727272728,
      y: 0.0751953125,
    },
    {
      x: 12434.659090909092,
      y: 0.08017730712890625,
    },
    {
      x: 12438.920454545456,
      y: 0.08017730712890625,
    },
    {
      x: 12447.443181818182,
      y: 0.08017730712890625,
    },
    {
      x: 12451.704545454546,
      y: 0.0851593017578125,
    },
    {
      x: 12455.96590909091,
      y: 0.0851593017578125,
    },
    {
      x: 12481.534090909092,
      y: 0.0851593017578125,
    },
    {
      x: 12485.795454545456,
      y: 0.09014511108398438,
    },
    {
      x: 12490.05681818182,
      y: 0.09014511108398438,
    },
    {
      x: 12515.625000000002,
      y: 0.09014511108398438,
    },
    {
      x: 12519.886363636364,
      y: 0.09512710571289062,
    },
    {
      x: 12524.147727272728,
      y: 0.09512710571289062,
    },
    {
      x: 12541.193181818182,
      y: 0.09512710571289062,
    },
    {
      x: 12545.454545454546,
      y: 0.9772109985351562,
    },
    {
      x: 12549.71590909091,
      y: 0.8675727844238281,
    },
    {
      x: 12562.500000000002,
      y: 0.5386581420898438,
    },
    {
      x: 12566.761363636364,
      y: 0.42902374267578125,
    },
    {
      x: 12571.022727272728,
      y: 0.3193855285644531,
    },
    {
      x: 12575.284090909092,
      y: 0.209747314453125,
    },
    {
      x: 12579.545454545456,
      y: 0.125030517578125,
    },
    {
      x: 12583.80681818182,
      y: 0.09014511108398438,
    },
    {
      x: 12588.068181818182,
      y: 0.08017730712890625,
    },
    {
      x: 12592.329545454546,
      y: 0.0751953125,
    },
    {
      x: 12596.59090909091,
      y: 0.0751953125,
    },
    {
      x: 12617.897727272728,
      y: 0.0751953125,
    },
    {
      x: 12622.159090909092,
      y: 0.08017730712890625,
    },
    {
      x: 12626.420454545456,
      y: 0.08017730712890625,
    },
    {
      x: 12651.988636363638,
      y: 0.08017730712890625,
    },
    {
      x: 12656.250000000002,
      y: 0.0851593017578125,
    },
    {
      x: 12660.511363636364,
      y: 0.0851593017578125,
    },
    {
      x: 12686.079545454546,
      y: 0.0851593017578125,
    },
    {
      x: 12690.34090909091,
      y: 0.09014511108398438,
    },
    {
      x: 12694.602272727274,
      y: 0.09014511108398438,
    },
    {
      x: 12720.170454545456,
      y: 0.09014511108398438,
    },
    {
      x: 12724.43181818182,
      y: 0.09512710571289062,
    },
    {
      x: 12728.693181818182,
      y: 0.09512710571289062,
    },
    {
      x: 12809.659090909092,
      y: 0.09512710571289062,
    },
    {
      x: 12813.920454545456,
      y: 0.0851593017578125,
    },
    {
      x: 12818.18181818182,
      y: 0.09512710571289062,
    },
    {
      x: 12822.443181818182,
      y: 0.09512710571289062,
    },
    {
      x: 13035.511363636364,
      y: 0.09512710571289062,
    },
    {
      x: 13039.772727272728,
      y: 0.125030517578125,
    },
    {
      x: 13044.034090909092,
      y: 0.21473312377929688,
    },
    {
      x: 13048.295454545456,
      y: 0.31439971923828125,
    },
    {
      x: 13052.55681818182,
      y: 0.424041748046875,
    },
    {
      x: 13056.818181818182,
      y: 0.5336761474609375,
    },
    {
      x: 13061.079545454546,
      y: 0.643310546875,
    },
    {
      x: 13065.34090909091,
      y: 0.7529525756835938,
    },
    {
      x: 13069.602272727274,
      y: 0.85760498046875,
    },
    {
      x: 13073.863636363638,
      y: 0.9622573852539062,
    },
    {
      x: 13078.125000000002,
      y: 1.0669136047363281,
    },
    {
      x: 13082.386363636364,
      y: 1.1167488098144531,
    },
    {
      x: 13086.647727272728,
      y: 1.0669136047363281,
    },
    {
      x: 13090.909090909092,
      y: 0.7529525756835938,
    },
    {
      x: 13095.170454545456,
      y: 0.85760498046875,
    },
    {
      x: 13099.43181818182,
      y: 0.9622573852539062,
    },
    {
      x: 13103.693181818182,
      y: 1.0669136047363281,
    },
    {
      x: 13107.954545454546,
      y: 1.1167488098144531,
    },
    {
      x: 13112.21590909091,
      y: 1.0669136047363281,
    },
    {
      x: 13116.477272727274,
      y: 0.9772109985351562,
    },
    {
      x: 13120.738636363638,
      y: 0.8675727844238281,
    },
    {
      x: 13133.522727272728,
      y: 0.5386581420898438,
    },
    {
      x: 13137.784090909092,
      y: 0.42902374267578125,
    },
    {
      x: 13142.045454545456,
      y: 0.3193855285644531,
    },
    {
      x: 13146.30681818182,
      y: 0.209747314453125,
    },
    {
      x: 13150.568181818182,
      y: 0.125030517578125,
    },
    {
      x: 13154.829545454546,
      y: 0.09014511108398438,
    },
    {
      x: 13159.09090909091,
      y: 0.08017730712890625,
    },
    {
      x: 13163.352272727274,
      y: 0.0751953125,
    },
    {
      x: 13167.613636363638,
      y: 0.0751953125,
    },
    {
      x: 13188.920454545456,
      y: 0.0751953125,
    },
    {
      x: 13193.18181818182,
      y: 0.08017730712890625,
    },
    {
      x: 13197.443181818182,
      y: 0.08017730712890625,
    },
    {
      x: 13223.011363636364,
      y: 0.08017730712890625,
    },
    {
      x: 13227.272727272728,
      y: 0.0851593017578125,
    },
    {
      x: 13231.534090909092,
      y: 0.0851593017578125,
    },
    {
      x: 13257.102272727274,
      y: 0.0851593017578125,
    },
    {
      x: 13261.363636363638,
      y: 0.09014511108398438,
    },
    {
      x: 13265.625000000002,
      y: 0.09014511108398438,
    },
    {
      x: 13291.193181818182,
      y: 0.09014511108398438,
    },
    {
      x: 13295.454545454546,
      y: 0.09512710571289062,
    },
    {
      x: 13299.71590909091,
      y: 0.09512710571289062,
    },
    {
      x: 13653.409090909092,
      y: 0.09512710571289062,
    },
    {
      x: 13657.670454545456,
      y: 0.0851593017578125,
    },
    {
      x: 13661.93181818182,
      y: 0.05525970458984375,
    },
    {
      x: 13666.193181818182,
      y: 0.020374298095703125,
    },
    {
      x: 13670.454545454546,
      y: 0.0004425048828125,
    },
    {
      x: 13674.71590909091,
      y: -0.0145111083984375,
    },
    {
      x: 13678.977272727274,
      y: -0.024478912353515625,
    },
    {
      x: 13683.238636363638,
      y: -0.0394287109375,
    },
    {
      x: 13687.500000000002,
      y: -0.049396514892578125,
    },
    {
      x: 13691.761363636364,
      y: -0.05936431884765625,
    },
    {
      x: 13696.022727272728,
      y: -0.06932830810546875,
    },
    {
      x: 13700.284090909092,
      y: -0.07929611206054688,
    },
    {
      x: 13708.80681818182,
      y: -0.09923171997070312,
    },
    {
      x: 13713.068181818182,
      y: -0.1141815185546875,
    },
    {
      x: 13717.329545454546,
      y: -0.1241455078125,
    },
    {
      x: 13721.59090909091,
      y: -0.13411712646484375,
    },
    {
      x: 13725.852272727274,
      y: -0.14408111572265625,
    },
    {
      x: 13730.113636363638,
      y: -0.14906692504882812,
    },
    {
      x: 13734.375000000002,
      y: -0.15903091430664062,
    },
    {
      x: 13738.636363636364,
      y: -0.16899871826171875,
    },
    {
      x: 13742.897727272728,
      y: -0.18394851684570312,
    },
    {
      x: 13747.159090909092,
      y: -0.17398452758789062,
    },
    {
      x: 13751.420454545456,
      y: -0.15903091430664062,
    },
    {
      x: 13755.68181818182,
      y: -0.14408111572265625,
    },
    {
      x: 13768.46590909091,
      y: -0.09923171997070312,
    },
    {
      x: 13772.727272727274,
      y: -0.08427810668945312,
    },
    {
      x: 13776.988636363638,
      y: -0.06932830810546875,
    },
    {
      x: 13781.250000000002,
      y: -0.049396514892578125,
    },
    {
      x: 13785.511363636364,
      y: -0.034442901611328125,
    },
    {
      x: 13789.772727272728,
      y: -0.01949310302734375,
    },
    {
      x: 13802.55681818182,
      y: 0.025356292724609375,
    },
    {
      x: 13806.818181818182,
      y: 0.0353240966796875,
    },
    {
      x: 13811.079545454546,
      y: 0.050273895263671875,
    },
    {
      x: 13815.34090909091,
      y: 0.06522369384765625,
    },
    {
      x: 13819.602272727274,
      y: 0.0751953125,
    },
    {
      x: 13823.863636363638,
      y: 0.08017730712890625,
    },
    {
      x: 13828.125000000002,
      y: 0.08017730712890625,
    },
    {
      x: 13836.647727272728,
      y: 0.08017730712890625,
    },
    {
      x: 13840.909090909092,
      y: 0.0851593017578125,
    },
    {
      x: 13845.170454545456,
      y: 0.0851593017578125,
    },
    {
      x: 13870.738636363638,
      y: 0.0851593017578125,
    },
    {
      x: 13875.000000000002,
      y: 0.09014511108398438,
    },
    {
      x: 13879.261363636364,
      y: 0.09014511108398438,
    },
    {
      x: 13904.829545454546,
      y: 0.09014511108398438,
    },
    {
      x: 13909.09090909091,
      y: 0.09512710571289062,
    },
    {
      x: 13913.352272727274,
      y: 0.09512710571289062,
    },
    {
      x: 14151.988636363638,
      y: 0.09512710571289062,
    },
    {
      x: 14156.250000000002,
      y: 0.125030517578125,
    },
    {
      x: 14160.511363636364,
      y: 0.21473312377929688,
    },
    {
      x: 14164.772727272728,
      y: 0.31439971923828125,
    },
    {
      x: 14169.034090909092,
      y: 0.424041748046875,
    },
    {
      x: 14173.295454545456,
      y: 0.5336761474609375,
    },
    {
      x: 14177.55681818182,
      y: 0.643310546875,
    },
    {
      x: 14181.818181818184,
      y: 0.09014511108398438,
    },
    {
      x: 14186.079545454546,
      y: 0.09014511108398438,
    },
    {
      x: 14203.125000000002,
      y: 0.09014511108398438,
    },
    {
      x: 14207.386363636364,
      y: 0.09512710571289062,
    },
    {
      x: 14211.647727272728,
      y: 0.09512710571289062,
    },
    {
      x: 14450.284090909092,
      y: 0.09512710571289062,
    },
    {
      x: 14454.545454545456,
      y: 0.125030517578125,
    },
    {
      x: 14458.80681818182,
      y: 0.21473312377929688,
    },
    {
      x: 14463.068181818184,
      y: 0.31439971923828125,
    },
    {
      x: 14467.329545454546,
      y: 0.424041748046875,
    },
    {
      x: 14471.59090909091,
      y: 0.5336761474609375,
    },
    {
      x: 14475.852272727274,
      y: 0.643310546875,
    },
    {
      x: 14480.113636363638,
      y: 0.7529525756835938,
    },
    {
      x: 14484.375000000002,
      y: 0.85760498046875,
    },
    {
      x: 14488.636363636364,
      y: 0.9622573852539062,
    },
    {
      x: 14492.897727272728,
      y: 1.0669136047363281,
    },
    {
      x: 14497.159090909092,
      y: 1.1167488098144531,
    },
    {
      x: 14501.420454545456,
      y: 1.0669136047363281,
    },
    {
      x: 14505.68181818182,
      y: 0.9772109985351562,
    },
    {
      x: 14509.943181818184,
      y: 0.8675727844238281,
    },
    {
      x: 14522.727272727274,
      y: 0.5386581420898438,
    },
    {
      x: 14526.988636363638,
      y: 0.42902374267578125,
    },
    {
      x: 14531.250000000002,
      y: 0.3193855285644531,
    },
    {
      x: 14535.511363636364,
      y: 0.209747314453125,
    },
    {
      x: 14539.772727272728,
      y: 0.125030517578125,
    },
    {
      x: 14544.034090909092,
      y: 0.09014511108398438,
    },
    {
      x: 14548.295454545456,
      y: 0.08017730712890625,
    },
    {
      x: 14552.55681818182,
      y: 0.0751953125,
    },
    {
      x: 14556.818181818184,
      y: 0.0751953125,
    },
    {
      x: 14578.125000000002,
      y: 0.0751953125,
    },
    {
      x: 14582.386363636364,
      y: 0.08017730712890625,
    },
    {
      x: 14586.647727272728,
      y: 0.08017730712890625,
    },
    {
      x: 14612.21590909091,
      y: 0.08017730712890625,
    },
    {
      x: 14616.477272727274,
      y: 0.0851593017578125,
    },
    {
      x: 14620.738636363638,
      y: 0.0851593017578125,
    },
    {
      x: 14646.30681818182,
      y: 0.0851593017578125,
    },
    {
      x: 14650.568181818184,
      y: 0.09014511108398438,
    },
    {
      x: 14654.829545454546,
      y: 0.09014511108398438,
    },
    {
      x: 14680.397727272728,
      y: 0.09014511108398438,
    },
    {
      x: 14684.659090909092,
      y: 0.09512710571289062,
    },
    {
      x: 14688.920454545456,
      y: 0.09512710571289062,
    },
    {
      x: 15042.613636363638,
      y: 0.09512710571289062,
    },
    {
      x: 15046.875000000002,
      y: 0.0851593017578125,
    },
    {
      x: 15051.136363636364,
      y: 0.05525970458984375,
    },
    {
      x: 15055.397727272728,
      y: 0.020374298095703125,
    },
    {
      x: 15059.659090909092,
      y: 0.0004425048828125,
    },
    {
      x: 15063.920454545456,
      y: -0.0145111083984375,
    },
    {
      x: 15068.18181818182,
      y: -0.024478912353515625,
    },
    {
      x: 15072.443181818184,
      y: -0.0394287109375,
    },
    {
      x: 15076.704545454546,
      y: -0.049396514892578125,
    },
    {
      x: 15080.96590909091,
      y: -0.05936431884765625,
    },
    {
      x: 15085.227272727274,
      y: -0.06932830810546875,
    },
    {
      x: 15089.488636363638,
      y: -0.07929611206054688,
    },
    {
      x: 15098.011363636364,
      y: -0.09923171997070312,
    },
    {
      x: 15102.272727272728,
      y: -0.1141815185546875,
    },
    {
      x: 15106.534090909092,
      y: -0.1241455078125,
    },
    {
      x: 15110.795454545456,
      y: -0.12913131713867188,
    },
    {
      x: 15115.05681818182,
      y: -0.14408111572265625,
    },
    {
      x: 15119.318181818184,
      y: -0.14906692504882812,
    },
    {
      x: 15123.579545454546,
      y: -0.15903091430664062,
    },
    {
      x: 15127.84090909091,
      y: -0.16899871826171875,
    },
    {
      x: 15132.102272727274,
      y: -0.18394851684570312,
    },
    {
      x: 15136.363636363638,
      y: -0.17398452758789062,
    },
    {
      x: 15140.625000000002,
      y: -0.15903091430664062,
    },
    {
      x: 15144.886363636364,
      y: -0.14408111572265625,
    },
    {
      x: 15157.670454545456,
      y: -0.09923171997070312,
    },
    {
      x: 15161.93181818182,
      y: -0.08427810668945312,
    },
    {
      x: 15166.193181818184,
      y: -0.06932830810546875,
    },
    {
      x: 15170.454545454546,
      y: -0.049396514892578125,
    },
    {
      x: 15174.71590909091,
      y: -0.034442901611328125,
    },
    {
      x: 15178.977272727274,
      y: -0.01949310302734375,
    },
    {
      x: 15191.761363636364,
      y: 0.025356292724609375,
    },
    {
      x: 15196.022727272728,
      y: 0.0353240966796875,
    },
    {
      x: 15200.284090909092,
      y: 0.050273895263671875,
    },
    {
      x: 15204.545454545456,
      y: 0.06522369384765625,
    },
    {
      x: 15208.80681818182,
      y: 0.0751953125,
    },
    {
      x: 15213.068181818184,
      y: 0.08017730712890625,
    },
    {
      x: 15217.329545454546,
      y: 0.08017730712890625,
    },
    {
      x: 15225.852272727274,
      y: 0.08017730712890625,
    },
    {
      x: 15230.113636363638,
      y: 0.0851593017578125,
    },
    {
      x: 15234.375000000002,
      y: 0.0851593017578125,
    },
    {
      x: 15259.943181818184,
      y: 0.0851593017578125,
    },
    {
      x: 15264.204545454546,
      y: 0.09014511108398438,
    },
    {
      x: 15268.46590909091,
      y: 0.09014511108398438,
    },
    {
      x: 15272.727272727274,
      y: 0.0851593017578125,
    },
    {
      x: 15276.988636363638,
      y: 0.0851593017578125,
    },
    {
      x: 15285.511363636364,
      y: 0.0851593017578125,
    },
    {
      x: 15289.772727272728,
      y: 0.09014511108398438,
    },
    {
      x: 15294.034090909092,
      y: 0.09014511108398438,
    },
    {
      x: 15319.602272727274,
      y: 0.09014511108398438,
    },
    {
      x: 15323.863636363638,
      y: 0.09512710571289062,
    },
    {
      x: 15328.125000000002,
      y: 0.09512710571289062,
    },
    {
      x: 15613.636363636364,
      y: 0.09512710571289062,
    },
    {
      x: 15617.897727272728,
      y: 0.0851593017578125,
    },
    {
      x: 15622.159090909092,
      y: 0.05525970458984375,
    },
    {
      x: 15626.420454545456,
      y: 0.020374298095703125,
    },
    {
      x: 15630.68181818182,
      y: 0.0004425048828125,
    },
    {
      x: 15634.943181818184,
      y: -0.0145111083984375,
    },
    {
      x: 15639.204545454546,
      y: -0.024478912353515625,
    },
    {
      x: 15643.46590909091,
      y: -0.0394287109375,
    },
    {
      x: 15647.727272727274,
      y: -0.049396514892578125,
    },
    {
      x: 15651.988636363638,
      y: -0.05936431884765625,
    },
    {
      x: 15656.250000000002,
      y: -0.06932830810546875,
    },
    {
      x: 15660.511363636364,
      y: -0.07929611206054688,
    },
    {
      x: 15669.034090909092,
      y: -0.09923171997070312,
    },
    {
      x: 15673.295454545456,
      y: -0.1141815185546875,
    },
    {
      x: 15677.55681818182,
      y: -0.1241455078125,
    },
    {
      x: 15681.818181818184,
      y: -0.13411712646484375,
    },
    {
      x: 15686.079545454546,
      y: -0.14408111572265625,
    },
    {
      x: 15690.34090909091,
      y: -0.14906692504882812,
    },
    {
      x: 15694.602272727274,
      y: -0.15903091430664062,
    },
    {
      x: 15698.863636363638,
      y: -0.16899871826171875,
    },
    {
      x: 15703.125000000002,
      y: -0.17896652221679688,
    },
    {
      x: 15707.386363636364,
      y: -0.17398452758789062,
    },
    {
      x: 15711.647727272728,
      y: -0.15903091430664062,
    },
    {
      x: 15715.909090909092,
      y: -0.14408111572265625,
    },
    {
      x: 15728.693181818184,
      y: -0.09923171997070312,
    },
    {
      x: 15732.954545454546,
      y: -0.08427810668945312,
    },
    {
      x: 15737.21590909091,
      y: -0.06932830810546875,
    },
    {
      x: 15741.477272727274,
      y: -0.049396514892578125,
    },
    {
      x: 15745.738636363638,
      y: -0.034442901611328125,
    },
    {
      x: 15750.000000000002,
      y: -0.01949310302734375,
    },
    {
      x: 15762.784090909092,
      y: 0.025356292724609375,
    },
    {
      x: 15767.045454545456,
      y: 0.0353240966796875,
    },
    {
      x: 15771.30681818182,
      y: 0.050273895263671875,
    },
    {
      x: 15775.568181818184,
      y: 0.06522369384765625,
    },
    {
      x: 15779.829545454546,
      y: 0.0751953125,
    },
    {
      x: 15784.09090909091,
      y: 0.08017730712890625,
    },
    {
      x: 15788.352272727274,
      y: 0.08017730712890625,
    },
    {
      x: 15796.875000000002,
      y: 0.08017730712890625,
    },
    {
      x: 15801.136363636364,
      y: 0.0851593017578125,
    },
    {
      x: 15805.397727272728,
      y: 0.0851593017578125,
    },
    {
      x: 15813.920454545456,
      y: 0.0851593017578125,
    },
    {
      x: 15818.18181818182,
      y: 0.09512710571289062,
    },
    {
      x: 15822.443181818184,
      y: 0.09512710571289062,
    },
    {
      x: 15839.488636363638,
      y: 0.09512710571289062,
    },
    {
      x: 15843.750000000002,
      y: 0.125030517578125,
    },
    {
      x: 15848.011363636364,
      y: 0.21473312377929688,
    },
    {
      x: 15852.272727272728,
      y: 0.31439971923828125,
    },
    {
      x: 15856.534090909092,
      y: 0.424041748046875,
    },
    {
      x: 15860.795454545456,
      y: 0.5336761474609375,
    },
    {
      x: 15865.05681818182,
      y: 0.643310546875,
    },
    {
      x: 15869.318181818184,
      y: 0.7529525756835938,
    },
    {
      x: 15873.579545454546,
      y: 0.85760498046875,
    },
    {
      x: 15877.84090909091,
      y: 0.9622573852539062,
    },
    {
      x: 15882.102272727274,
      y: 1.0669136047363281,
    },
    {
      x: 15886.363636363638,
      y: 1.1167488098144531,
    },
    {
      x: 15890.625000000002,
      y: 1.0669136047363281,
    },
    {
      x: 15894.886363636364,
      y: 0.9772109985351562,
    },
    {
      x: 15899.147727272728,
      y: 0.8675727844238281,
    },
    {
      x: 15911.93181818182,
      y: 0.5386581420898438,
    },
    {
      x: 15916.193181818184,
      y: 0.42902374267578125,
    },
    {
      x: 15920.454545454546,
      y: 0.3193855285644531,
    },
    {
      x: 15924.71590909091,
      y: 0.209747314453125,
    },
    {
      x: 15928.977272727274,
      y: 0.125030517578125,
    },
    {
      x: 15933.238636363638,
      y: 0.09014511108398438,
    },
    {
      x: 15937.500000000002,
      y: 0.08017730712890625,
    },
    {
      x: 15941.761363636364,
      y: 0.0751953125,
    },
    {
      x: 15946.022727272728,
      y: 0.0751953125,
    },
    {
      x: 15967.329545454546,
      y: 0.0751953125,
    },
    {
      x: 15971.59090909091,
      y: 0.08017730712890625,
    },
    {
      x: 15975.852272727274,
      y: 0.08017730712890625,
    },
    {
      x: 16001.420454545456,
      y: 0.08017730712890625,
    },
    {
      x: 16005.68181818182,
      y: 0.0851593017578125,
    },
    {
      x: 16009.943181818184,
      y: 0.0851593017578125,
    },
    {
      x: 16035.511363636364,
      y: 0.0851593017578125,
    },
    {
      x: 16039.772727272728,
      y: 0.09014511108398438,
    },
    {
      x: 16044.034090909092,
      y: 0.09014511108398438,
    },
    {
      x: 16069.602272727274,
      y: 0.09014511108398438,
    },
    {
      x: 16073.863636363638,
      y: 0.09512710571289062,
    },
    {
      x: 16078.125000000002,
      y: 0.09512710571289062,
    },
    {
      x: 16359.375000000002,
      y: 0.09512710571289062,
    },
    {
      x: 16363.636363636364,
      y: 0.08017730712890625,
    },
    {
      x: 16367.897727272728,
      y: 0.08017730712890625,
    },
    {
      x: 16372.159090909092,
      y: 0.0851593017578125,
    },
    {
      x: 16376.420454545456,
      y: 0.0851593017578125,
    },
    {
      x: 16401.988636363636,
      y: 0.0851593017578125,
    },
    {
      x: 16406.25,
      y: 0.09014511108398438,
    },
    {
      x: 16410.511363636364,
      y: 0.09014511108398438,
    },
    {
      x: 16436.079545454548,
      y: 0.09014511108398438,
    },
    {
      x: 16440.34090909091,
      y: 0.09512710571289062,
    },
    {
      x: 16444.602272727276,
      y: 0.09512710571289062,
    },
    {
      x: 16632.102272727276,
      y: 0.09512710571289062,
    },
    {
      x: 16636.363636363636,
      y: 0.09014511108398438,
    },
    {
      x: 16640.625,
      y: 0.09014511108398438,
    },
    {
      x: 16644.886363636364,
      y: 0.09512710571289062,
    },
    {
      x: 16649.147727272728,
      y: 0.09512710571289062,
    },
    {
      x: 16730.113636363636,
      y: 0.09512710571289062,
    },
    {
      x: 16734.375,
      y: 0.0851593017578125,
    },
    {
      x: 16738.636363636364,
      y: 0.05525970458984375,
    },
    {
      x: 16742.897727272728,
      y: 0.020374298095703125,
    },
    {
      x: 16747.159090909092,
      y: 0.0004425048828125,
    },
    {
      x: 16751.420454545456,
      y: -0.0145111083984375,
    },
    {
      x: 16755.68181818182,
      y: -0.024478912353515625,
    },
    {
      x: 16759.943181818184,
      y: -0.0394287109375,
    },
    {
      x: 16764.204545454548,
      y: -0.049396514892578125,
    },
    {
      x: 16768.46590909091,
      y: -0.05936431884765625,
    },
    {
      x: 16772.727272727276,
      y: -0.06932830810546875,
    },
    {
      x: 16776.988636363636,
      y: -0.07929611206054688,
    },
    {
      x: 16785.511363636364,
      y: -0.09923171997070312,
    },
    {
      x: 16789.772727272728,
      y: -0.1141815185546875,
    },
    {
      x: 16794.034090909092,
      y: -0.1241455078125,
    },
    {
      x: 16798.295454545456,
      y: -0.13411712646484375,
    },
    {
      x: 16802.55681818182,
      y: -0.14408111572265625,
    },
    {
      x: 16806.818181818184,
      y: -0.14906692504882812,
    },
    {
      x: 16811.079545454548,
      y: -0.15903091430664062,
    },
    {
      x: 16815.34090909091,
      y: -0.16899871826171875,
    },
    {
      x: 16819.602272727276,
      y: -0.18394851684570312,
    },
    {
      x: 16823.863636363636,
      y: -0.17398452758789062,
    },
    {
      x: 16828.125,
      y: -0.15903091430664062,
    },
    {
      x: 16832.386363636364,
      y: -0.14408111572265625,
    },
    {
      x: 16845.170454545456,
      y: -0.09923171997070312,
    },
    {
      x: 16849.43181818182,
      y: -0.08427810668945312,
    },
    {
      x: 16853.693181818184,
      y: -0.06932830810546875,
    },
    {
      x: 16857.954545454548,
      y: -0.049396514892578125,
    },
    {
      x: 16862.21590909091,
      y: -0.034442901611328125,
    },
    {
      x: 16866.477272727276,
      y: -0.01949310302734375,
    },
    {
      x: 16879.261363636364,
      y: 0.025356292724609375,
    },
    {
      x: 16883.522727272728,
      y: 0.0353240966796875,
    },
    {
      x: 16887.784090909092,
      y: 0.050273895263671875,
    },
    {
      x: 16892.045454545456,
      y: 0.06522369384765625,
    },
    {
      x: 16896.30681818182,
      y: 0.0751953125,
    },
    {
      x: 16900.568181818184,
      y: 0.08017730712890625,
    },
    {
      x: 16904.829545454548,
      y: 0.08017730712890625,
    },
    {
      x: 16909.09090909091,
      y: 0.09512710571289062,
    },
    {
      x: 16913.352272727276,
      y: 0.09512710571289062,
    },
    {
      x: 16955.96590909091,
      y: 0.09512710571289062,
    },
    {
      x: 16960.227272727276,
      y: 0.125030517578125,
    },
    {
      x: 16964.488636363636,
      y: 0.21473312377929688,
    },
    {
      x: 16968.75,
      y: 0.31439971923828125,
    },
    {
      x: 16973.011363636364,
      y: 0.424041748046875,
    },
    {
      x: 16977.272727272728,
      y: 0.5336761474609375,
    },
    {
      x: 16981.534090909092,
      y: 0.643310546875,
    },
    {
      x: 16985.795454545456,
      y: 0.7529525756835938,
    },
    {
      x: 16990.05681818182,
      y: 0.85760498046875,
    },
    {
      x: 16994.318181818184,
      y: 0.9622573852539062,
    },
    {
      x: 16998.579545454548,
      y: 1.0669136047363281,
    },
    {
      x: 17002.84090909091,
      y: 1.1167488098144531,
    },
    {
      x: 17007.102272727276,
      y: 1.0669136047363281,
    },
    {
      x: 17011.363636363636,
      y: 0.9772109985351562,
    },
    {
      x: 17015.625,
      y: 0.8675727844238281,
    },
    {
      x: 17028.409090909092,
      y: 0.5386581420898438,
    },
    {
      x: 17032.670454545456,
      y: 0.42902374267578125,
    },
    {
      x: 17036.93181818182,
      y: 0.3193855285644531,
    },
    {
      x: 17041.193181818184,
      y: 0.209747314453125,
    },
    {
      x: 17045.454545454548,
      y: 0.125030517578125,
    },
    {
      x: 17049.71590909091,
      y: 0.09014511108398438,
    },
    {
      x: 17053.977272727276,
      y: 0.08017730712890625,
    },
    {
      x: 17058.238636363636,
      y: 0.0751953125,
    },
    {
      x: 17062.5,
      y: 0.0751953125,
    },
    {
      x: 17083.80681818182,
      y: 0.0751953125,
    },
    {
      x: 17088.068181818184,
      y: 0.08017730712890625,
    },
    {
      x: 17092.329545454548,
      y: 0.08017730712890625,
    },
    {
      x: 17117.897727272728,
      y: 0.08017730712890625,
    },
    {
      x: 17122.159090909092,
      y: 0.0851593017578125,
    },
    {
      x: 17126.420454545456,
      y: 0.0851593017578125,
    },
    {
      x: 17151.988636363636,
      y: 0.0851593017578125,
    },
    {
      x: 17156.25,
      y: 0.09014511108398438,
    },
    {
      x: 17160.511363636364,
      y: 0.09014511108398438,
    },
    {
      x: 17177.55681818182,
      y: 0.09014511108398438,
    },
    {
      x: 17181.818181818184,
      y: 0.09512710571289062,
    },
    {
      x: 17186.079545454548,
      y: 0.09512710571289062,
    },
    {
      x: 17450.284090909092,
      y: 0.09512710571289062,
    },
    {
      x: 17454.545454545456,
      y: -0.049396514892578125,
    },
    {
      x: 17458.80681818182,
      y: -0.034442901611328125,
    },
    {
      x: 17463.068181818184,
      y: -0.01949310302734375,
    },
    {
      x: 17475.852272727276,
      y: 0.025356292724609375,
    },
    {
      x: 17480.113636363636,
      y: 0.040309906005859375,
    },
    {
      x: 17484.375,
      y: 0.05525970458984375,
    },
    {
      x: 17488.636363636364,
      y: 0.06522369384765625,
    },
    {
      x: 17492.897727272728,
      y: 0.08017730712890625,
    },
    {
      x: 17497.159090909092,
      y: 0.08017730712890625,
    },
    {
      x: 17501.420454545456,
      y: 0.0851593017578125,
    },
    {
      x: 17505.68181818182,
      y: 0.0851593017578125,
    },
    {
      x: 17522.727272727276,
      y: 0.0851593017578125,
    },
    {
      x: 17526.988636363636,
      y: 0.09014511108398438,
    },
    {
      x: 17531.25,
      y: 0.09014511108398438,
    },
    {
      x: 17556.818181818184,
      y: 0.09014511108398438,
    },
    {
      x: 17561.079545454548,
      y: 0.09512710571289062,
    },
    {
      x: 17565.34090909091,
      y: 0.09512710571289062,
    },
    {
      x: 17723.011363636364,
      y: 0.09512710571289062,
    },
    {
      x: 17727.272727272728,
      y: 0.08017730712890625,
    },
    {
      x: 17731.534090909092,
      y: 0.08017730712890625,
    },
    {
      x: 17748.579545454548,
      y: 0.08017730712890625,
    },
    {
      x: 17752.84090909091,
      y: 0.0851593017578125,
    },
    {
      x: 17757.102272727276,
      y: 0.0851593017578125,
    },
    {
      x: 17782.670454545456,
      y: 0.0851593017578125,
    },
    {
      x: 17786.93181818182,
      y: 0.09014511108398438,
    },
    {
      x: 17791.193181818184,
      y: 0.09014511108398438,
    },
    {
      x: 17816.761363636364,
      y: 0.09014511108398438,
    },
    {
      x: 17821.022727272728,
      y: 0.09512710571289062,
    },
    {
      x: 17825.284090909092,
      y: 0.09512710571289062,
    },
    {
      x: 17872.159090909092,
      y: 0.09512710571289062,
    },
    {
      x: 17876.420454545456,
      y: 0.0851593017578125,
    },
    {
      x: 17880.68181818182,
      y: 0.06024169921875,
    },
    {
      x: 17884.943181818184,
      y: 0.025356292724609375,
    },
    {
      x: 17889.204545454548,
      y: 0.00542449951171875,
    },
    {
      x: 17893.46590909091,
      y: -0.00952911376953125,
    },
    {
      x: 17897.727272727276,
      y: -0.024478912353515625,
    },
    {
      x: 17901.988636363636,
      y: -0.034442901611328125,
    },
    {
      x: 17906.25,
      y: -0.04441070556640625,
    },
    {
      x: 17914.772727272728,
      y: -0.0643463134765625,
    },
    {
      x: 17919.034090909092,
      y: -0.074310302734375,
    },
    {
      x: 17923.295454545456,
      y: -0.089263916015625,
    },
    {
      x: 17927.55681818182,
      y: -0.09923171997070312,
    },
    {
      x: 17931.818181818184,
      y: -0.10919570922851562,
    },
    {
      x: 17936.079545454548,
      y: -0.11916351318359375,
    },
    {
      x: 17948.863636363636,
      y: -0.14906692504882812,
    },
    {
      x: 17953.125,
      y: -0.15903091430664062,
    },
    {
      x: 17957.386363636364,
      y: -0.16899871826171875,
    },
    {
      x: 17965.909090909092,
      y: -0.16899871826171875,
    },
    {
      x: 17970.170454545456,
      y: -0.15903091430664062,
    },
    {
      x: 17974.43181818182,
      y: -0.14408111572265625,
    },
    {
      x: 17978.693181818184,
      y: -0.1241455078125,
    },
    {
      x: 17982.954545454548,
      y: -0.10919570922851562,
    },
    {
      x: 17995.738636363636,
      y: -0.0643463134765625,
    },
    {
      x: 18000,
      y: 0.09512710571289062,
    },
    {
      x: 18004.261363636364,
      y: 0.09512710571289062,
    },
    {
      x: 18098.011363636364,
      y: 0.09512710571289062,
    },
    {
      x: 18102.272727272728,
      y: 0.125030517578125,
    },
    {
      x: 18106.534090909092,
      y: 0.21473312377929688,
    },
    {
      x: 18110.795454545456,
      y: 0.31439971923828125,
    },
    {
      x: 18115.05681818182,
      y: 0.424041748046875,
    },
    {
      x: 18119.318181818184,
      y: 0.5336761474609375,
    },
    {
      x: 18123.579545454548,
      y: 0.643310546875,
    },
    {
      x: 18127.84090909091,
      y: 0.7529525756835938,
    },
    {
      x: 18132.102272727276,
      y: 0.85760498046875,
    },
    {
      x: 18136.363636363636,
      y: 0.9622573852539062,
    },
    {
      x: 18140.625,
      y: 1.0669136047363281,
    },
    {
      x: 18144.886363636364,
      y: 1.1167488098144531,
    },
    {
      x: 18149.147727272728,
      y: 1.0669136047363281,
    },
    {
      x: 18153.409090909092,
      y: 0.9772109985351562,
    },
    {
      x: 18157.670454545456,
      y: 0.8675727844238281,
    },
    {
      x: 18161.93181818182,
      y: 0.7579345703125,
    },
    {
      x: 18166.193181818184,
      y: 0.643310546875,
    },
    {
      x: 18170.454545454548,
      y: 0.5336761474609375,
    },
    {
      x: 18174.71590909091,
      y: 0.424041748046875,
    },
    {
      x: 18178.977272727276,
      y: 0.31439971923828125,
    },
    {
      x: 18183.238636363636,
      y: 0.209747314453125,
    },
    {
      x: 18187.5,
      y: 0.12004470825195312,
    },
    {
      x: 18191.761363636364,
      y: 0.0851593017578125,
    },
    {
      x: 18196.022727272728,
      y: 0.0751953125,
    },
    {
      x: 18200.284090909092,
      y: 0.07020950317382812,
    },
    {
      x: 18204.545454545456,
      y: 0.07020950317382812,
    },
    {
      x: 18225.852272727276,
      y: 0.07020950317382812,
    },
    {
      x: 18230.113636363636,
      y: 0.0751953125,
    },
    {
      x: 18234.375,
      y: 0.0751953125,
    },
    {
      x: 18259.943181818184,
      y: 0.0751953125,
    },
    {
      x: 18264.204545454548,
      y: 0.08017730712890625,
    },
    {
      x: 18268.46590909091,
      y: 0.08017730712890625,
    },
    {
      x: 18272.727272727276,
      y: 0.09512710571289062,
    },
    {
      x: 18276.988636363636,
      y: 0.09512710571289062,
    },
    {
      x: 18541.193181818184,
      y: 0.09512710571289062,
    },
    {
      x: 18545.454545454548,
      y: -0.14408111572265625,
    },
    {
      x: 18549.71590909091,
      y: -0.1241455078125,
    },
    {
      x: 18553.977272727276,
      y: -0.10919570922851562,
    },
    {
      x: 18571.022727272728,
      y: -0.049396514892578125,
    },
    {
      x: 18575.284090909092,
      y: -0.034442901611328125,
    },
    {
      x: 18579.545454545456,
      y: -0.01949310302734375,
    },
    {
      x: 18592.329545454548,
      y: 0.025356292724609375,
    },
    {
      x: 18596.59090909091,
      y: 0.040309906005859375,
    },
    {
      x: 18600.852272727276,
      y: 0.05525970458984375,
    },
    {
      x: 18605.113636363636,
      y: 0.06522369384765625,
    },
    {
      x: 18609.375,
      y: 0.08017730712890625,
    },
    {
      x: 18613.636363636364,
      y: 0.08017730712890625,
    },
    {
      x: 18617.897727272728,
      y: 0.0851593017578125,
    },
    {
      x: 18622.159090909092,
      y: 0.0851593017578125,
    },
    {
      x: 18639.204545454548,
      y: 0.0851593017578125,
    },
    {
      x: 18643.46590909091,
      y: 0.09014511108398438,
    },
    {
      x: 18647.727272727276,
      y: 0.09014511108398438,
    },
    {
      x: 18673.295454545456,
      y: 0.09014511108398438,
    },
    {
      x: 18677.55681818182,
      y: 0.09512710571289062,
    },
    {
      x: 18681.818181818184,
      y: 0.09512710571289062,
    },
    {
      x: 18813.920454545456,
      y: 0.09512710571289062,
    },
    {
      x: 18818.18181818182,
      y: 0.0751953125,
    },
    {
      x: 18822.443181818184,
      y: 0.0751953125,
    },
    {
      x: 18830.96590909091,
      y: 0.0751953125,
    },
    {
      x: 18835.227272727276,
      y: 0.08017730712890625,
    },
    {
      x: 18839.488636363636,
      y: 0.08017730712890625,
    },
    {
      x: 18865.05681818182,
      y: 0.08017730712890625,
    },
    {
      x: 18869.318181818184,
      y: 0.0851593017578125,
    },
    {
      x: 18873.579545454548,
      y: 0.0851593017578125,
    },
    {
      x: 18899.147727272728,
      y: 0.0851593017578125,
    },
    {
      x: 18903.409090909092,
      y: 0.09014511108398438,
    },
    {
      x: 18907.670454545456,
      y: 0.09014511108398438,
    },
    {
      x: 18933.238636363636,
      y: 0.09014511108398438,
    },
    {
      x: 18937.5,
      y: 0.09512710571289062,
    },
    {
      x: 18941.761363636364,
      y: 0.09512710571289062,
    },
    {
      x: 18988.636363636364,
      y: 0.09512710571289062,
    },
    {
      x: 18992.897727272728,
      y: 0.0851593017578125,
    },
    {
      x: 18997.159090909092,
      y: 0.06024169921875,
    },
    {
      x: 19001.420454545456,
      y: 0.025356292724609375,
    },
    {
      x: 19005.68181818182,
      y: 0.00542449951171875,
    },
    {
      x: 19009.943181818184,
      y: -0.00952911376953125,
    },
    {
      x: 19014.204545454548,
      y: -0.024478912353515625,
    },
    {
      x: 19018.46590909091,
      y: -0.034442901611328125,
    },
    {
      x: 19022.727272727276,
      y: -0.04441070556640625,
    },
    {
      x: 19031.25,
      y: -0.0643463134765625,
    },
    {
      x: 19035.511363636364,
      y: -0.074310302734375,
    },
    {
      x: 19039.772727272728,
      y: -0.089263916015625,
    },
    {
      x: 19044.034090909092,
      y: -0.09923171997070312,
    },
    {
      x: 19048.295454545456,
      y: -0.10919570922851562,
    },
    {
      x: 19052.55681818182,
      y: -0.11916351318359375,
    },
    {
      x: 19065.34090909091,
      y: -0.14906692504882812,
    },
    {
      x: 19069.602272727276,
      y: -0.15404891967773438,
    },
    {
      x: 19073.863636363636,
      y: -0.16899871826171875,
    },
    {
      x: 19078.125,
      y: -0.17896652221679688,
    },
    {
      x: 19082.386363636364,
      y: -0.16899871826171875,
    },
    {
      x: 19086.647727272728,
      y: -0.15903091430664062,
    },
    {
      x: 19090.909090909092,
      y: 0.09512710571289062,
    },
    {
      x: 19095.170454545456,
      y: 0.09512710571289062,
    },
    {
      x: 19487.21590909091,
      y: 0.09512710571289062,
    },
    {
      x: 19491.477272727276,
      y: 0.125030517578125,
    },
    {
      x: 19495.738636363636,
      y: 0.21473312377929688,
    },
    {
      x: 19500,
      y: 0.31439971923828125,
    },
    {
      x: 19504.261363636364,
      y: 0.424041748046875,
    },
    {
      x: 19508.522727272728,
      y: 0.5336761474609375,
    },
    {
      x: 19512.784090909092,
      y: 0.643310546875,
    },
    {
      x: 19517.045454545456,
      y: 0.7529525756835938,
    },
    {
      x: 19521.30681818182,
      y: 0.85760498046875,
    },
    {
      x: 19525.568181818184,
      y: 0.9622573852539062,
    },
    {
      x: 19529.829545454548,
      y: 1.0669136047363281,
    },
    {
      x: 19534.09090909091,
      y: 1.1167488098144531,
    },
    {
      x: 19538.352272727276,
      y: 1.0669136047363281,
    },
    {
      x: 19542.613636363636,
      y: 0.9772109985351562,
    },
    {
      x: 19546.875,
      y: 0.8675727844238281,
    },
    {
      x: 19551.136363636364,
      y: 0.7579345703125,
    },
    {
      x: 19555.397727272728,
      y: 0.643310546875,
    },
    {
      x: 19559.659090909092,
      y: 0.5336761474609375,
    },
    {
      x: 19563.920454545456,
      y: 0.424041748046875,
    },
    {
      x: 19568.18181818182,
      y: 0.31439971923828125,
    },
    {
      x: 19572.443181818184,
      y: 0.209747314453125,
    },
    {
      x: 19576.704545454548,
      y: 0.12004470825195312,
    },
    {
      x: 19580.96590909091,
      y: 0.0851593017578125,
    },
    {
      x: 19585.227272727276,
      y: 0.0751953125,
    },
    {
      x: 19589.488636363636,
      y: 0.07020950317382812,
    },
    {
      x: 19593.75,
      y: 0.07020950317382812,
    },
    {
      x: 19615.05681818182,
      y: 0.07020950317382812,
    },
    {
      x: 19619.318181818184,
      y: 0.0751953125,
    },
    {
      x: 19623.579545454548,
      y: 0.0751953125,
    },
    {
      x: 19632.102272727276,
      y: 0.0751953125,
    },
    {
      x: 19636.36363636364,
      y: -0.14906692504882812,
    },
    {
      x: 19640.625,
      y: -0.15903091430664062,
    },
    {
      x: 19644.886363636364,
      y: -0.16899871826171875,
    },
    {
      x: 19653.409090909092,
      y: -0.16899871826171875,
    },
    {
      x: 19657.670454545456,
      y: -0.15903091430664062,
    },
    {
      x: 19661.93181818182,
      y: -0.14408111572265625,
    },
    {
      x: 19666.193181818184,
      y: -0.1241455078125,
    },
    {
      x: 19670.454545454548,
      y: -0.10919570922851562,
    },
    {
      x: 19687.5,
      y: -0.049396514892578125,
    },
    {
      x: 19691.761363636364,
      y: -0.034442901611328125,
    },
    {
      x: 19696.022727272728,
      y: -0.01949310302734375,
    },
    {
      x: 19708.80681818182,
      y: 0.025356292724609375,
    },
    {
      x: 19713.068181818184,
      y: 0.040309906005859375,
    },
    {
      x: 19717.329545454548,
      y: 0.05525970458984375,
    },
    {
      x: 19721.59090909091,
      y: 0.06522369384765625,
    },
    {
      x: 19725.852272727276,
      y: 0.08017730712890625,
    },
    {
      x: 19730.11363636364,
      y: 0.08017730712890625,
    },
    {
      x: 19734.375,
      y: 0.0851593017578125,
    },
    {
      x: 19738.636363636364,
      y: 0.0851593017578125,
    },
    {
      x: 19755.68181818182,
      y: 0.0851593017578125,
    },
    {
      x: 19759.943181818184,
      y: 0.09014511108398438,
    },
    {
      x: 19764.204545454548,
      y: 0.09014511108398438,
    },
    {
      x: 19789.772727272728,
      y: 0.09014511108398438,
    },
    {
      x: 19794.034090909092,
      y: 0.09512710571289062,
    },
    {
      x: 19798.295454545456,
      y: 0.09512710571289062,
    },
    {
      x: 19904.829545454548,
      y: 0.09512710571289062,
    },
    {
      x: 19909.09090909091,
      y: 0.07020950317382812,
    },
    {
      x: 19913.352272727276,
      y: 0.07020950317382812,
    },
    {
      x: 19917.61363636364,
      y: 0.0751953125,
    },
    {
      x: 19921.875,
      y: 0.0751953125,
    },
    {
      x: 19947.443181818184,
      y: 0.0751953125,
    },
    {
      x: 19951.704545454548,
      y: 0.08017730712890625,
    },
    {
      x: 19955.96590909091,
      y: 0.08017730712890625,
    },
    {
      x: 19981.534090909092,
      y: 0.08017730712890625,
    },
    {
      x: 19985.795454545456,
      y: 0.0851593017578125,
    },
    {
      x: 19990.05681818182,
      y: 0.0851593017578125,
    },
    {
      x: 20015.625,
      y: 0.0851593017578125,
    },
    {
      x: 20019.886363636364,
      y: 0.09014511108398438,
    },
    {
      x: 20024.147727272728,
      y: 0.09014511108398438,
    },
    {
      x: 20049.71590909091,
      y: 0.09014511108398438,
    },
    {
      x: 20053.977272727276,
      y: 0.09512710571289062,
    },
    {
      x: 20058.23863636364,
      y: 0.09512710571289062,
    },
    {
      x: 20105.11363636364,
      y: 0.09512710571289062,
    },
    {
      x: 20109.375,
      y: 0.0851593017578125,
    },
    {
      x: 20113.636363636364,
      y: 0.06024169921875,
    },
    {
      x: 20117.897727272728,
      y: 0.025356292724609375,
    },
    {
      x: 20122.159090909092,
      y: 0.00542449951171875,
    },
    {
      x: 20126.420454545456,
      y: -0.00952911376953125,
    },
    {
      x: 20130.68181818182,
      y: -0.024478912353515625,
    },
    {
      x: 20134.943181818184,
      y: -0.034442901611328125,
    },
    {
      x: 20139.204545454548,
      y: -0.04441070556640625,
    },
    {
      x: 20147.727272727276,
      y: -0.0643463134765625,
    },
    {
      x: 20151.98863636364,
      y: -0.074310302734375,
    },
    {
      x: 20156.25,
      y: -0.089263916015625,
    },
    {
      x: 20160.511363636364,
      y: -0.09923171997070312,
    },
    {
      x: 20164.772727272728,
      y: -0.10919570922851562,
    },
    {
      x: 20169.034090909092,
      y: -0.11916351318359375,
    },
    {
      x: 20177.55681818182,
      y: -0.13909912109375,
    },
    {
      x: 20181.818181818184,
      y: 0.09512710571289062,
    },
    {
      x: 20186.079545454548,
      y: 0.09512710571289062,
    },
    {
      x: 20330.96590909091,
      y: 0.09512710571289062,
    },
    {
      x: 20335.227272727276,
      y: 0.125030517578125,
    },
    {
      x: 20339.48863636364,
      y: 0.21473312377929688,
    },
    {
      x: 20343.75,
      y: 0.31439971923828125,
    },
    {
      x: 20348.011363636364,
      y: 0.424041748046875,
    },
    {
      x: 20352.272727272728,
      y: 0.5336761474609375,
    },
    {
      x: 20356.534090909092,
      y: 0.643310546875,
    },
    {
      x: 20360.795454545456,
      y: 0.7529525756835938,
    },
    {
      x: 20365.05681818182,
      y: 0.85760498046875,
    },
    {
      x: 20369.318181818184,
      y: 0.9622573852539062,
    },
    {
      x: 20373.579545454548,
      y: 1.0669136047363281,
    },
    {
      x: 20377.84090909091,
      y: 1.1167488098144531,
    },
    {
      x: 20382.102272727276,
      y: 1.0669136047363281,
    },
    {
      x: 20386.36363636364,
      y: 0.9772109985351562,
    },
    {
      x: 20390.625,
      y: 0.8675727844238281,
    },
    {
      x: 20394.886363636364,
      y: 0.7579345703125,
    },
    {
      x: 20399.147727272728,
      y: 0.643310546875,
    },
    {
      x: 20403.409090909092,
      y: 0.5336761474609375,
    },
    {
      x: 20407.670454545456,
      y: 0.424041748046875,
    },
    {
      x: 20411.93181818182,
      y: 0.31439971923828125,
    },
    {
      x: 20416.193181818184,
      y: 0.209747314453125,
    },
    {
      x: 20420.454545454548,
      y: 0.12004470825195312,
    },
    {
      x: 20424.71590909091,
      y: 0.0851593017578125,
    },
    {
      x: 20428.977272727276,
      y: 0.0751953125,
    },
    {
      x: 20433.23863636364,
      y: 0.07020950317382812,
    },
    {
      x: 20437.5,
      y: 0.07020950317382812,
    },
    {
      x: 20450.284090909092,
      y: 0.07020950317382812,
    },
    {
      x: 20454.545454545456,
      y: 0.09512710571289062,
    },
    {
      x: 20458.80681818182,
      y: 0.09512710571289062,
    },
    {
      x: 20723.011363636364,
      y: 0.09512710571289062,
    },
    {
      x: 20727.272727272728,
      y: 0.0751953125,
    },
    {
      x: 20731.534090909092,
      y: 0.07020950317382812,
    },
    {
      x: 20735.795454545456,
      y: 0.07020950317382812,
    },
    {
      x: 20757.102272727276,
      y: 0.07020950317382812,
    },
    {
      x: 20761.36363636364,
      y: 0.0751953125,
    },
    {
      x: 20765.625,
      y: 0.0751953125,
    },
    {
      x: 20791.193181818184,
      y: 0.0751953125,
    },
    {
      x: 20795.454545454548,
      y: 0.08017730712890625,
    },
    {
      x: 20799.71590909091,
      y: 0.08017730712890625,
    },
    {
      x: 20825.284090909092,
      y: 0.08017730712890625,
    },
    {
      x: 20829.545454545456,
      y: 0.0851593017578125,
    },
    {
      x: 20833.80681818182,
      y: 0.0851593017578125,
    },
    {
      x: 20859.375,
      y: 0.0851593017578125,
    },
    {
      x: 20863.636363636364,
      y: 0.09014511108398438,
    },
    {
      x: 20867.897727272728,
      y: 0.09014511108398438,
    },
    {
      x: 20893.46590909091,
      y: 0.09014511108398438,
    },
    {
      x: 20897.727272727276,
      y: 0.09512710571289062,
    },
    {
      x: 20901.98863636364,
      y: 0.09512710571289062,
    },
    {
      x: 20948.86363636364,
      y: 0.09512710571289062,
    },
    {
      x: 20953.125,
      y: 0.0851593017578125,
    },
    {
      x: 20957.386363636364,
      y: 0.06024169921875,
    },
    {
      x: 20961.647727272728,
      y: 0.025356292724609375,
    },
    {
      x: 20965.909090909092,
      y: 0.00542449951171875,
    },
    {
      x: 20970.170454545456,
      y: -0.00952911376953125,
    },
    {
      x: 20974.43181818182,
      y: -0.024478912353515625,
    },
    {
      x: 20978.693181818184,
      y: -0.034442901611328125,
    },
    {
      x: 20982.954545454548,
      y: -0.04441070556640625,
    },
    {
      x: 20991.477272727276,
      y: -0.0643463134765625,
    },
    {
      x: 20995.73863636364,
      y: -0.074310302734375,
    },
    {
      x: 21000,
      y: -0.089263916015625,
    },
    {
      x: 21004.261363636364,
      y: -0.09923171997070312,
    },
    {
      x: 21008.522727272728,
      y: -0.10919570922851562,
    },
    {
      x: 21012.784090909092,
      y: -0.11916351318359375,
    },
    {
      x: 21025.568181818184,
      y: -0.14906692504882812,
    },
    {
      x: 21029.829545454548,
      y: -0.15903091430664062,
    },
    {
      x: 21034.09090909091,
      y: -0.16899871826171875,
    },
    {
      x: 21042.61363636364,
      y: -0.16899871826171875,
    },
    {
      x: 21046.875,
      y: -0.15903091430664062,
    },
    {
      x: 21051.136363636364,
      y: -0.14408111572265625,
    },
    {
      x: 21055.397727272728,
      y: -0.1241455078125,
    },
    {
      x: 21059.659090909092,
      y: -0.10919570922851562,
    },
    {
      x: 21076.704545454548,
      y: -0.049396514892578125,
    },
    {
      x: 21080.96590909091,
      y: -0.034442901611328125,
    },
    {
      x: 21085.227272727276,
      y: -0.01949310302734375,
    },
    {
      x: 21098.011363636364,
      y: 0.025356292724609375,
    },
    {
      x: 21102.272727272728,
      y: 0.040309906005859375,
    },
    {
      x: 21106.534090909092,
      y: 0.05525970458984375,
    },
    {
      x: 21110.795454545456,
      y: 0.06522369384765625,
    },
    {
      x: 21115.05681818182,
      y: 0.08017730712890625,
    },
    {
      x: 21119.318181818184,
      y: 0.08017730712890625,
    },
    {
      x: 21123.579545454548,
      y: 0.0851593017578125,
    },
    {
      x: 21127.84090909091,
      y: 0.0851593017578125,
    },
    {
      x: 21144.886363636364,
      y: 0.0851593017578125,
    },
    {
      x: 21149.147727272728,
      y: 0.09014511108398438,
    },
    {
      x: 21153.409090909092,
      y: 0.09014511108398438,
    },
    {
      x: 21178.977272727276,
      y: 0.09014511108398438,
    },
    {
      x: 21183.23863636364,
      y: 0.09512710571289062,
    },
    {
      x: 21187.5,
      y: 0.09512710571289062,
    },
    {
      x: 21447.443181818184,
      y: 0.09512710571289062,
    },
    {
      x: 21451.704545454548,
      y: 0.125030517578125,
    },
    {
      x: 21455.96590909091,
      y: 0.21473312377929688,
    },
    {
      x: 21460.227272727276,
      y: 0.31439971923828125,
    },
    {
      x: 21464.48863636364,
      y: 0.424041748046875,
    },
    {
      x: 21468.75,
      y: 0.5336761474609375,
    },
    {
      x: 21473.011363636364,
      y: 0.643310546875,
    },
    {
      x: 21477.272727272728,
      y: 0.7529525756835938,
    },
    {
      x: 21481.534090909092,
      y: 0.85760498046875,
    },
    {
      x: 21485.795454545456,
      y: 0.9622573852539062,
    },
    {
      x: 21490.05681818182,
      y: 1.0669136047363281,
    },
    {
      x: 21494.318181818184,
      y: 1.1167488098144531,
    },
    {
      x: 21498.579545454548,
      y: 1.0669136047363281,
    },
    {
      x: 21502.84090909091,
      y: 0.9772109985351562,
    },
    {
      x: 21507.102272727276,
      y: 0.8675727844238281,
    },
    {
      x: 21511.36363636364,
      y: 0.7579345703125,
    },
    {
      x: 21515.625,
      y: 0.643310546875,
    },
    {
      x: 21519.886363636364,
      y: 0.5336761474609375,
    },
    {
      x: 21524.147727272728,
      y: 0.424041748046875,
    },
    {
      x: 21528.409090909092,
      y: 0.31439971923828125,
    },
    {
      x: 21532.670454545456,
      y: 0.209747314453125,
    },
    {
      x: 21536.93181818182,
      y: 0.12004470825195312,
    },
    {
      x: 21541.193181818184,
      y: 0.0851593017578125,
    },
    {
      x: 21545.454545454548,
      y: 0.09512710571289062,
    },
    {
      x: 21549.71590909091,
      y: 0.09512710571289062,
    },
    {
      x: 21813.920454545456,
      y: 0.09512710571289062,
    },
    {
      x: 21818.18181818182,
      y: -0.024478912353515625,
    },
    {
      x: 21822.443181818184,
      y: -0.034442901611328125,
    },
    {
      x: 21826.704545454548,
      y: -0.04441070556640625,
    },
    {
      x: 21835.227272727276,
      y: -0.0643463134765625,
    },
    {
      x: 21839.48863636364,
      y: -0.074310302734375,
    },
    {
      x: 21843.75,
      y: -0.089263916015625,
    },
    {
      x: 21848.011363636364,
      y: -0.09923171997070312,
    },
    {
      x: 21852.272727272728,
      y: -0.10919570922851562,
    },
    {
      x: 21856.534090909092,
      y: -0.11916351318359375,
    },
    {
      x: 21869.318181818184,
      y: -0.14906692504882812,
    },
    {
      x: 21873.579545454548,
      y: -0.15903091430664062,
    },
    {
      x: 21877.84090909091,
      y: -0.16899871826171875,
    },
    {
      x: 21886.36363636364,
      y: -0.16899871826171875,
    },
    {
      x: 21890.625,
      y: -0.15903091430664062,
    },
    {
      x: 21894.886363636364,
      y: -0.14408111572265625,
    },
    {
      x: 21899.147727272728,
      y: -0.1241455078125,
    },
    {
      x: 21903.409090909092,
      y: -0.10919570922851562,
    },
    {
      x: 21920.454545454548,
      y: -0.049396514892578125,
    },
    {
      x: 21924.71590909091,
      y: -0.034442901611328125,
    },
    {
      x: 21928.977272727276,
      y: -0.01949310302734375,
    },
    {
      x: 21941.761363636364,
      y: 0.025356292724609375,
    },
    {
      x: 21946.022727272728,
      y: 0.040309906005859375,
    },
    {
      x: 21950.284090909092,
      y: 0.05525970458984375,
    },
    {
      x: 21954.545454545456,
      y: 0.06522369384765625,
    },
    {
      x: 21958.80681818182,
      y: 0.08017730712890625,
    },
    {
      x: 21963.068181818184,
      y: 0.08017730712890625,
    },
    {
      x: 21967.329545454548,
      y: 0.0851593017578125,
    },
    {
      x: 21971.59090909091,
      y: 0.0851593017578125,
    },
    {
      x: 21988.636363636364,
      y: 0.0851593017578125,
    },
    {
      x: 21992.897727272728,
      y: 0.09014511108398438,
    },
    {
      x: 21997.159090909092,
      y: 0.09014511108398438,
    },
    {
      x: 22022.727272727276,
      y: 0.09014511108398438,
    },
    {
      x: 22026.98863636364,
      y: 0.09512710571289062,
    },
    {
      x: 22031.25,
      y: 0.09512710571289062,
    },
    {
      x: 22291.193181818184,
      y: 0.09512710571289062,
    },
    {
      x: 22295.454545454548,
      y: 0.125030517578125,
    },
    {
      x: 22299.71590909091,
      y: 0.21473312377929688,
    },
    {
      x: 22303.977272727276,
      y: 0.31439971923828125,
    },
    {
      x: 22308.23863636364,
      y: 0.424041748046875,
    },
    {
      x: 22312.5,
      y: 0.5336761474609375,
    },
    {
      x: 22316.761363636364,
      y: 0.643310546875,
    },
    {
      x: 22321.022727272728,
      y: 0.7529525756835938,
    },
    {
      x: 22325.284090909092,
      y: 0.85760498046875,
    },
    {
      x: 22329.545454545456,
      y: 0.9622573852539062,
    },
    {
      x: 22333.80681818182,
      y: 1.0669136047363281,
    },
    {
      x: 22338.068181818184,
      y: 1.1167488098144531,
    },
    {
      x: 22342.329545454548,
      y: 1.0669136047363281,
    },
    {
      x: 22346.59090909091,
      y: 0.9772109985351562,
    },
    {
      x: 22350.852272727276,
      y: 0.8675727844238281,
    },
    {
      x: 22355.11363636364,
      y: 0.7579345703125,
    },
    {
      x: 22359.375,
      y: 0.643310546875,
    },
    {
      x: 22363.636363636364,
      y: 0.09512710571289062,
    },
    {
      x: 22367.897727272728,
      y: 0.09512710571289062,
    },
    {
      x: 22632.102272727276,
      y: 0.09512710571289062,
    },
    {
      x: 22636.36363636364,
      y: 0.5336761474609375,
    },
    {
      x: 22640.625,
      y: 0.424041748046875,
    },
    {
      x: 22644.886363636364,
      y: 0.31439971923828125,
    },
    {
      x: 22649.147727272728,
      y: 0.209747314453125,
    },
    {
      x: 22653.409090909092,
      y: 0.12004470825195312,
    },
    {
      x: 22657.670454545456,
      y: 0.0851593017578125,
    },
    {
      x: 22661.93181818182,
      y: 0.0751953125,
    },
    {
      x: 22666.193181818184,
      y: 0.07020950317382812,
    },
    {
      x: 22670.454545454548,
      y: 0.07020950317382812,
    },
    {
      x: 22691.761363636364,
      y: 0.07020950317382812,
    },
    {
      x: 22696.022727272728,
      y: 0.0751953125,
    },
    {
      x: 22700.284090909092,
      y: 0.0751953125,
    },
    {
      x: 22725.852272727276,
      y: 0.0751953125,
    },
    {
      x: 22730.11363636364,
      y: 0.08017730712890625,
    },
    {
      x: 22734.375,
      y: 0.08017730712890625,
    },
    {
      x: 22759.943181818184,
      y: 0.08017730712890625,
    },
    {
      x: 22764.204545454548,
      y: 0.0851593017578125,
    },
    {
      x: 22768.46590909091,
      y: 0.0851593017578125,
    },
    {
      x: 22794.034090909092,
      y: 0.0851593017578125,
    },
    {
      x: 22798.295454545456,
      y: 0.09014511108398438,
    },
    {
      x: 22802.55681818182,
      y: 0.09014511108398438,
    },
    {
      x: 22828.125,
      y: 0.09014511108398438,
    },
    {
      x: 22832.386363636364,
      y: 0.09512710571289062,
    },
    {
      x: 22836.647727272728,
      y: 0.09512710571289062,
    },
    {
      x: 22883.522727272728,
      y: 0.09512710571289062,
    },
    {
      x: 22887.784090909092,
      y: 0.0851593017578125,
    },
    {
      x: 22892.045454545456,
      y: 0.06024169921875,
    },
    {
      x: 22896.30681818182,
      y: 0.025356292724609375,
    },
    {
      x: 22900.568181818184,
      y: 0.00542449951171875,
    },
    {
      x: 22904.829545454548,
      y: -0.00952911376953125,
    },
    {
      x: 22909.09090909091,
      y: 1.1167488098144531,
    },
    {
      x: 22913.352272727276,
      y: 1.0669136047363281,
    },
    {
      x: 22917.61363636364,
      y: 0.9772109985351562,
    },
    {
      x: 22921.875,
      y: 0.8675727844238281,
    },
    {
      x: 22926.136363636364,
      y: 0.7579345703125,
    },
    {
      x: 22930.397727272728,
      y: 0.643310546875,
    },
    {
      x: 22934.659090909092,
      y: 0.5336761474609375,
    },
    {
      x: 22938.920454545456,
      y: 0.424041748046875,
    },
    {
      x: 22943.18181818182,
      y: 0.31439971923828125,
    },
    {
      x: 22947.443181818184,
      y: 0.209747314453125,
    },
    {
      x: 22951.704545454548,
      y: 0.12004470825195312,
    },
    {
      x: 22955.96590909091,
      y: 0.0851593017578125,
    },
    {
      x: 22960.227272727276,
      y: 0.0751953125,
    },
    {
      x: 22964.48863636364,
      y: 0.07020950317382812,
    },
    {
      x: 22968.75,
      y: 0.07020950317382812,
    },
    {
      x: 22990.05681818182,
      y: 0.07020950317382812,
    },
    {
      x: 22994.318181818184,
      y: 0.0751953125,
    },
    {
      x: 22998.579545454548,
      y: 0.0751953125,
    },
    {
      x: 23024.147727272728,
      y: 0.0751953125,
    },
    {
      x: 23028.409090909092,
      y: 0.08017730712890625,
    },
    {
      x: 23032.670454545456,
      y: 0.08017730712890625,
    },
    {
      x: 23058.23863636364,
      y: 0.08017730712890625,
    },
    {
      x: 23062.5,
      y: 0.0851593017578125,
    },
    {
      x: 23066.761363636364,
      y: 0.0851593017578125,
    },
    {
      x: 23092.329545454548,
      y: 0.0851593017578125,
    },
    {
      x: 23096.59090909091,
      y: 0.09014511108398438,
    },
    {
      x: 23100.852272727276,
      y: 0.09014511108398438,
    },
    {
      x: 23126.420454545456,
      y: 0.09014511108398438,
    },
    {
      x: 23130.68181818182,
      y: 0.09512710571289062,
    },
    {
      x: 23134.943181818184,
      y: 0.09512710571289062,
    },
    {
      x: 23181.818181818184,
      y: 0.09512710571289062,
    },
    {
      x: 23186.079545454548,
      y: 0.0851593017578125,
    },
    {
      x: 23190.34090909091,
      y: 0.06024169921875,
    },
    {
      x: 23194.602272727276,
      y: 0.025356292724609375,
    },
    {
      x: 23198.86363636364,
      y: 0.00542449951171875,
    },
    {
      x: 23203.125,
      y: -0.00952911376953125,
    },
    {
      x: 23207.386363636364,
      y: -0.024478912353515625,
    },
    {
      x: 23211.647727272728,
      y: -0.034442901611328125,
    },
    {
      x: 23215.909090909092,
      y: -0.04441070556640625,
    },
    {
      x: 23224.43181818182,
      y: -0.0643463134765625,
    },
    {
      x: 23228.693181818184,
      y: -0.074310302734375,
    },
    {
      x: 23232.954545454548,
      y: -0.089263916015625,
    },
    {
      x: 23237.21590909091,
      y: -0.09923171997070312,
    },
    {
      x: 23241.477272727276,
      y: -0.10919570922851562,
    },
    {
      x: 23245.73863636364,
      y: -0.11916351318359375,
    },
    {
      x: 23258.522727272728,
      y: -0.14906692504882812,
    },
    {
      x: 23262.784090909092,
      y: -0.15404891967773438,
    },
    {
      x: 23267.045454545456,
      y: -0.16899871826171875,
    },
    {
      x: 23271.30681818182,
      y: -0.17896652221679688,
    },
    {
      x: 23275.568181818184,
      y: -0.16899871826171875,
    },
    {
      x: 23279.829545454548,
      y: -0.15903091430664062,
    },
    {
      x: 23284.09090909091,
      y: -0.14408111572265625,
    },
    {
      x: 23288.352272727276,
      y: -0.1241455078125,
    },
    {
      x: 23292.61363636364,
      y: -0.10919570922851562,
    },
    {
      x: 23309.659090909092,
      y: -0.049396514892578125,
    },
    {
      x: 23313.920454545456,
      y: -0.034442901611328125,
    },
    {
      x: 23318.18181818182,
      y: -0.01949310302734375,
    },
    {
      x: 23330.96590909091,
      y: 0.025356292724609375,
    },
    {
      x: 23335.227272727276,
      y: 0.040309906005859375,
    },
    {
      x: 23339.48863636364,
      y: 0.05525970458984375,
    },
    {
      x: 23343.75,
      y: 0.06522369384765625,
    },
    {
      x: 23348.011363636364,
      y: 0.08017730712890625,
    },
    {
      x: 23352.272727272728,
      y: 0.08017730712890625,
    },
    {
      x: 23356.534090909092,
      y: 0.0851593017578125,
    },
    {
      x: 23360.795454545456,
      y: 0.0851593017578125,
    },
    {
      x: 23377.84090909091,
      y: 0.0851593017578125,
    },
    {
      x: 23382.102272727276,
      y: 0.09014511108398438,
    },
    {
      x: 23386.36363636364,
      y: 0.09014511108398438,
    },
    {
      x: 23411.93181818182,
      y: 0.09014511108398438,
    },
    {
      x: 23416.193181818184,
      y: 0.09512710571289062,
    },
    {
      x: 23420.454545454548,
      y: 0.09512710571289062,
    },
    {
      x: 23680.397727272728,
      y: 0.09512710571289062,
    },
    {
      x: 23684.659090909092,
      y: 0.125030517578125,
    },
    {
      x: 23688.920454545456,
      y: 0.21473312377929688,
    },
    {
      x: 23693.18181818182,
      y: 0.31439971923828125,
    },
    {
      x: 23697.443181818184,
      y: 0.424041748046875,
    },
    {
      x: 23701.704545454548,
      y: 0.5336761474609375,
    },
    {
      x: 23705.96590909091,
      y: 0.643310546875,
    },
    {
      x: 23710.227272727276,
      y: 0.7529525756835938,
    },
    {
      x: 23714.48863636364,
      y: 0.85760498046875,
    },
    {
      x: 23718.75,
      y: 0.9622573852539062,
    },
    {
      x: 23723.011363636364,
      y: 1.0669136047363281,
    },
    {
      x: 23727.272727272728,
      y: 0.09512710571289062,
    },
    {
      x: 23731.534090909092,
      y: 0.09512710571289062,
    },
    {
      x: 24000,
      y: 0.09512710571289062,
    },
  ],
  v6: [
    {
      x: 0,
      y: 0.10010910034179688,
    },
    {
      x: 77.44565217391305,
      y: 0.10010910034179688,
    },
    {
      x: 81.52173913043478,
      y: 0.125030517578125,
    },
    {
      x: 85.59782608695653,
      y: 0.18483352661132812,
    },
    {
      x: 89.67391304347827,
      y: 0.25460052490234375,
    },
    {
      x: 93.75,
      y: 0.3293495178222656,
    },
    {
      x: 97.82608695652175,
      y: 0.4041023254394531,
    },
    {
      x: 101.90217391304348,
      y: 0.4788551330566406,
    },
    {
      x: 105.97826086956522,
      y: 0.5486259460449219,
    },
    {
      x: 110.05434782608695,
      y: 0.6233787536621094,
    },
    {
      x: 114.1304347826087,
      y: 0.6981315612792969,
    },
    {
      x: 118.20652173913044,
      y: 0.7679023742675781,
    },
    {
      x: 122.28260869565217,
      y: 0.8027839660644531,
    },
    {
      x: 126.35869565217392,
      y: 0.7629203796386719,
    },
    {
      x: 130.43478260869566,
      y: 0.6981315612792969,
    },
    {
      x: 134.5108695652174,
      y: 0.6233787536621094,
    },
    {
      x: 138.58695652173913,
      y: 0.5486259460449219,
    },
    {
      x: 142.66304347826087,
      y: 0.4688911437988281,
    },
    {
      x: 146.73913043478262,
      y: 0.3941383361816406,
    },
    {
      x: 150.81521739130434,
      y: 0.31439971923828125,
    },
    {
      x: 154.8913043478261,
      y: 0.23964691162109375,
    },
    {
      x: 158.96739130434784,
      y: 0.16489410400390625,
    },
    {
      x: 163.04347826086956,
      y: 0.10010910034179688,
    },
    {
      x: 167.1195652173913,
      y: 0.08017730712890625,
    },
    {
      x: 171.19565217391306,
      y: 0.07020950317382812,
    },
    {
      x: 175.27173913043478,
      y: 0.06522369384765625,
    },
    {
      x: 179.34782608695653,
      y: 0.06522369384765625,
    },
    {
      x: 183.42391304347828,
      y: 0.07020950317382812,
    },
    {
      x: 187.5,
      y: 0.07020950317382812,
    },
    {
      x: 211.95652173913044,
      y: 0.07020950317382812,
    },
    {
      x: 216.0326086956522,
      y: 0.0751953125,
    },
    {
      x: 220.1086956521739,
      y: 0.0751953125,
    },
    {
      x: 244.56521739130434,
      y: 0.0751953125,
    },
    {
      x: 248.6413043478261,
      y: 0.08017730712890625,
    },
    {
      x: 252.71739130434784,
      y: 0.08017730712890625,
    },
    {
      x: 256.79347826086956,
      y: 0.08017730712890625,
    },
    {
      x: 260.8695652173913,
      y: -0.00952911376953125,
    },
    {
      x: 264.94565217391306,
      y: 0.00542449951171875,
    },
    {
      x: 269.0217391304348,
      y: 0.015392303466796875,
    },
    {
      x: 273.0978260869565,
      y: 0.025356292724609375,
    },
    {
      x: 277.17391304347825,
      y: 0.0353240966796875,
    },
    {
      x: 285.32608695652175,
      y: 0.05525970458984375,
    },
    {
      x: 289.4021739130435,
      y: 0.06522369384765625,
    },
    {
      x: 293.47826086956525,
      y: 0.07020950317382812,
    },
    {
      x: 297.55434782608694,
      y: 0.08017730712890625,
    },
    {
      x: 301.6304347826087,
      y: 0.09014511108398438,
    },
    {
      x: 305.70652173913044,
      y: 0.09512710571289062,
    },
    {
      x: 309.7826086956522,
      y: 0.10010910034179688,
    },
    {
      x: 313.85869565217394,
      y: 0.10010910034179688,
    },
    {
      x: 778.5326086956522,
      y: 0.10010910034179688,
    },
    {
      x: 782.608695652174,
      y: 0.0751953125,
    },
    {
      x: 786.6847826086956,
      y: 0.0751953125,
    },
    {
      x: 798.9130434782609,
      y: 0.0751953125,
    },
    {
      x: 802.9891304347826,
      y: 0.08017730712890625,
    },
    {
      x: 807.0652173913044,
      y: 0.08017730712890625,
    },
    {
      x: 831.5217391304348,
      y: 0.08017730712890625,
    },
    {
      x: 835.5978260869565,
      y: 0.0851593017578125,
    },
    {
      x: 839.6739130434783,
      y: 0.0851593017578125,
    },
    {
      x: 864.1304347826087,
      y: 0.0851593017578125,
    },
    {
      x: 868.2065217391305,
      y: 0.09014511108398438,
    },
    {
      x: 872.2826086956522,
      y: 0.09014511108398438,
    },
    {
      x: 896.7391304347826,
      y: 0.09014511108398438,
    },
    {
      x: 900.8152173913044,
      y: 0.09512710571289062,
    },
    {
      x: 904.8913043478261,
      y: 0.09512710571289062,
    },
    {
      x: 929.3478260869565,
      y: 0.09512710571289062,
    },
    {
      x: 933.4239130434783,
      y: 0.09014511108398438,
    },
    {
      x: 937.5,
      y: 0.0751953125,
    },
    {
      x: 941.5760869565217,
      y: 0.06024169921875,
    },
    {
      x: 945.6521739130435,
      y: 0.050273895263671875,
    },
    {
      x: 949.7282608695652,
      y: 0.040309906005859375,
    },
    {
      x: 953.804347826087,
      y: 0.0353240966796875,
    },
    {
      x: 957.8804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 961.9565217391305,
      y: 0.020374298095703125,
    },
    {
      x: 966.0326086956522,
      y: 0.010406494140625,
    },
    {
      x: 970.108695652174,
      y: 0.00542449951171875,
    },
    {
      x: 974.1847826086956,
      y: -0.004543304443359375,
    },
    {
      x: 978.2608695652174,
      y: -0.00952911376953125,
    },
    {
      x: 982.3369565217391,
      y: -0.0145111083984375,
    },
    {
      x: 986.4130434782609,
      y: -0.024478912353515625,
    },
    {
      x: 990.4891304347826,
      y: -0.029460906982421875,
    },
    {
      x: 994.5652173913044,
      y: -0.0394287109375,
    },
    {
      x: 998.6413043478261,
      y: -0.04441070556640625,
    },
    {
      x: 1002.7173913043479,
      y: -0.049396514892578125,
    },
    {
      x: 1006.7934782608696,
      y: -0.05936431884765625,
    },
    {
      x: 1010.8695652173914,
      y: -0.0643463134765625,
    },
    {
      x: 1014.9456521739131,
      y: -0.074310302734375,
    },
    {
      x: 1019.0217391304349,
      y: -0.06932830810546875,
    },
    {
      x: 1023.0978260869565,
      y: -0.05936431884765625,
    },
    {
      x: 1027.1739130434783,
      y: -0.049396514892578125,
    },
    {
      x: 1035.3260869565217,
      y: -0.029460906982421875,
    },
    {
      x: 1039.4021739130435,
      y: -0.0145111083984375,
    },
    {
      x: 1043.4782608695652,
      y: 0.10010910034179688,
    },
    {
      x: 1047.554347826087,
      y: 0.10010910034179688,
    },
    {
      x: 1406.25,
      y: 0.10010910034179688,
    },
    {
      x: 1410.3260869565217,
      y: 0.125030517578125,
    },
    {
      x: 1414.4021739130435,
      y: 0.18483352661132812,
    },
    {
      x: 1418.4782608695652,
      y: 0.25460052490234375,
    },
    {
      x: 1422.554347826087,
      y: 0.3243675231933594,
    },
    {
      x: 1426.6304347826087,
      y: 0.3991203308105469,
    },
    {
      x: 1438.858695652174,
      y: 0.6233787536621094,
    },
    {
      x: 1442.9347826086957,
      y: 0.6931495666503906,
    },
    {
      x: 1447.0108695652175,
      y: 0.7629203796386719,
    },
    {
      x: 1451.0869565217392,
      y: 0.7978019714355469,
    },
    {
      x: 1455.163043478261,
      y: 0.7629203796386719,
    },
    {
      x: 1459.2391304347827,
      y: 0.6981315612792969,
    },
    {
      x: 1463.3152173913045,
      y: 0.6233787536621094,
    },
    {
      x: 1467.3913043478262,
      y: 0.5436439514160156,
    },
    {
      x: 1471.4673913043478,
      y: 0.4688911437988281,
    },
    {
      x: 1475.5434782608695,
      y: 0.38915252685546875,
    },
    {
      x: 1479.6195652173913,
      y: 0.31439971923828125,
    },
    {
      x: 1487.7717391304348,
      y: 0.16489410400390625,
    },
    {
      x: 1491.8478260869565,
      y: 0.10010910034179688,
    },
    {
      x: 1495.9239130434783,
      y: 0.08017730712890625,
    },
    {
      x: 1500,
      y: 0.07020950317382812,
    },
    {
      x: 1504.0760869565217,
      y: 0.06522369384765625,
    },
    {
      x: 1508.1521739130435,
      y: 0.06522369384765625,
    },
    {
      x: 1516.304347826087,
      y: 0.06522369384765625,
    },
    {
      x: 1520.3804347826087,
      y: 0.07020950317382812,
    },
    {
      x: 1524.4565217391305,
      y: 0.07020950317382812,
    },
    {
      x: 1548.913043478261,
      y: 0.07020950317382812,
    },
    {
      x: 1552.9891304347827,
      y: 0.0751953125,
    },
    {
      x: 1557.0652173913045,
      y: 0.0751953125,
    },
    {
      x: 1561.1413043478262,
      y: 0.0751953125,
    },
    {
      x: 1565.217391304348,
      y: -0.06932830810546875,
    },
    {
      x: 1569.2934782608695,
      y: -0.0643463134765625,
    },
    {
      x: 1573.3695652173913,
      y: -0.054378509521484375,
    },
    {
      x: 1577.445652173913,
      y: -0.04441070556640625,
    },
    {
      x: 1581.5217391304348,
      y: -0.029460906982421875,
    },
    {
      x: 1585.5978260869565,
      y: -0.01949310302734375,
    },
    {
      x: 1589.6739130434783,
      y: -0.00952911376953125,
    },
    {
      x: 1593.75,
      y: 0.0004425048828125,
    },
    {
      x: 1597.8260869565217,
      y: 0.010406494140625,
    },
    {
      x: 1601.9021739130435,
      y: 0.020374298095703125,
    },
    {
      x: 1610.054347826087,
      y: 0.040309906005859375,
    },
    {
      x: 1614.1304347826087,
      y: 0.050273895263671875,
    },
    {
      x: 1618.2065217391305,
      y: 0.06024169921875,
    },
    {
      x: 1630.4347826086957,
      y: 0.09014511108398438,
    },
    {
      x: 1634.5108695652175,
      y: 0.09512710571289062,
    },
    {
      x: 1638.5869565217392,
      y: 0.10010910034179688,
    },
    {
      x: 1642.663043478261,
      y: 0.10010910034179688,
    },
    {
      x: 1822.0108695652175,
      y: 0.10010910034179688,
    },
    {
      x: 1826.0869565217392,
      y: 0.0751953125,
    },
    {
      x: 1830.163043478261,
      y: 0.0751953125,
    },
    {
      x: 1834.2391304347827,
      y: 0.0751953125,
    },
    {
      x: 1838.3152173913045,
      y: 0.08017730712890625,
    },
    {
      x: 1842.3913043478262,
      y: 0.08017730712890625,
    },
    {
      x: 1866.8478260869565,
      y: 0.08017730712890625,
    },
    {
      x: 1870.9239130434783,
      y: 0.0851593017578125,
    },
    {
      x: 1875,
      y: 0.0851593017578125,
    },
    {
      x: 1899.4565217391305,
      y: 0.0851593017578125,
    },
    {
      x: 1903.5326086956522,
      y: 0.09014511108398438,
    },
    {
      x: 1907.608695652174,
      y: 0.09014511108398438,
    },
    {
      x: 1932.0652173913045,
      y: 0.09014511108398438,
    },
    {
      x: 1936.1413043478262,
      y: 0.09512710571289062,
    },
    {
      x: 1940.217391304348,
      y: 0.09512710571289062,
    },
    {
      x: 1997.2826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 2001.358695652174,
      y: 0.09014511108398438,
    },
    {
      x: 2005.4347826086957,
      y: 0.0751953125,
    },
    {
      x: 2009.5108695652175,
      y: 0.05525970458984375,
    },
    {
      x: 2013.5869565217392,
      y: 0.045291900634765625,
    },
    {
      x: 2017.663043478261,
      y: 0.040309906005859375,
    },
    {
      x: 2021.7391304347827,
      y: 0.03034210205078125,
    },
    {
      x: 2025.8152173913045,
      y: 0.025356292724609375,
    },
    {
      x: 2029.8913043478262,
      y: 0.015392303466796875,
    },
    {
      x: 2033.967391304348,
      y: 0.010406494140625,
    },
    {
      x: 2038.0434782608697,
      y: 0.0004425048828125,
    },
    {
      x: 2042.1195652173913,
      y: -0.004543304443359375,
    },
    {
      x: 2046.195652173913,
      y: -0.0145111083984375,
    },
    {
      x: 2050.271739130435,
      y: -0.01949310302734375,
    },
    {
      x: 2054.3478260869565,
      y: -0.029460906982421875,
    },
    {
      x: 2058.4239130434785,
      y: -0.034442901611328125,
    },
    {
      x: 2062.5,
      y: -0.0394287109375,
    },
    {
      x: 2066.576086956522,
      y: -0.049396514892578125,
    },
    {
      x: 2070.6521739130435,
      y: -0.054378509521484375,
    },
    {
      x: 2074.7282608695655,
      y: -0.05936431884765625,
    },
    {
      x: 2078.804347826087,
      y: -0.06932830810546875,
    },
    {
      x: 2082.880434782609,
      y: -0.074310302734375,
    },
    {
      x: 2086.9565217391305,
      y: 0.10010910034179688,
    },
    {
      x: 2091.032608695652,
      y: 0.10010910034179688,
    },
    {
      x: 2213.3152173913045,
      y: 0.10010910034179688,
    },
    {
      x: 2217.391304347826,
      y: 0.125030517578125,
    },
    {
      x: 2221.467391304348,
      y: 0.18483352661132812,
    },
    {
      x: 2225.5434782608695,
      y: 0.25460052490234375,
    },
    {
      x: 2229.6195652173915,
      y: 0.3243675231933594,
    },
    {
      x: 2233.695652173913,
      y: 0.3991203308105469,
    },
    {
      x: 2245.9239130434785,
      y: 0.6233787536621094,
    },
    {
      x: 2250,
      y: 0.6931495666503906,
    },
    {
      x: 2254.076086956522,
      y: 0.7629203796386719,
    },
    {
      x: 2258.1521739130435,
      y: 0.7978019714355469,
    },
    {
      x: 2262.2282608695655,
      y: 0.7629203796386719,
    },
    {
      x: 2266.304347826087,
      y: 0.6981315612792969,
    },
    {
      x: 2270.380434782609,
      y: 0.6233787536621094,
    },
    {
      x: 2274.4565217391305,
      y: 0.5486259460449219,
    },
    {
      x: 2278.532608695652,
      y: 0.4688911437988281,
    },
    {
      x: 2282.608695652174,
      y: 0.3941383361816406,
    },
    {
      x: 2286.6847826086955,
      y: 0.3193855285644531,
    },
    {
      x: 2290.7608695652175,
      y: 0.24462890625,
    },
    {
      x: 2294.836956521739,
      y: 0.1698760986328125,
    },
    {
      x: 2298.913043478261,
      y: 0.10509490966796875,
    },
    {
      x: 2302.9891304347825,
      y: 0.0851593017578125,
    },
    {
      x: 2307.0652173913045,
      y: 0.0751953125,
    },
    {
      x: 2311.141304347826,
      y: 0.07020950317382812,
    },
    {
      x: 2315.217391304348,
      y: 0.07020950317382812,
    },
    {
      x: 2323.3695652173915,
      y: 0.07020950317382812,
    },
    {
      x: 2327.445652173913,
      y: 0.0751953125,
    },
    {
      x: 2331.521739130435,
      y: 0.0751953125,
    },
    {
      x: 2343.75,
      y: 0.0751953125,
    },
    {
      x: 2347.826086956522,
      y: 0.10010910034179688,
    },
    {
      x: 2351.9021739130435,
      y: 0.10010910034179688,
    },
    {
      x: 2604.6195652173915,
      y: 0.10010910034179688,
    },
    {
      x: 2608.695652173913,
      y: 0.07020950317382812,
    },
    {
      x: 2612.771739130435,
      y: 0.0751953125,
    },
    {
      x: 2616.8478260869565,
      y: 0.0751953125,
    },
    {
      x: 2641.304347826087,
      y: 0.0751953125,
    },
    {
      x: 2645.380434782609,
      y: 0.08017730712890625,
    },
    {
      x: 2649.4565217391305,
      y: 0.08017730712890625,
    },
    {
      x: 2673.913043478261,
      y: 0.08017730712890625,
    },
    {
      x: 2677.9891304347825,
      y: 0.0851593017578125,
    },
    {
      x: 2682.0652173913045,
      y: 0.0851593017578125,
    },
    {
      x: 2706.521739130435,
      y: 0.0851593017578125,
    },
    {
      x: 2710.5978260869565,
      y: 0.09014511108398438,
    },
    {
      x: 2714.6739130434785,
      y: 0.09014511108398438,
    },
    {
      x: 2739.130434782609,
      y: 0.09014511108398438,
    },
    {
      x: 2743.2065217391305,
      y: 0.09512710571289062,
    },
    {
      x: 2747.2826086956525,
      y: 0.09512710571289062,
    },
    {
      x: 2804.3478260869565,
      y: 0.09512710571289062,
    },
    {
      x: 2808.4239130434785,
      y: 0.09014511108398438,
    },
    {
      x: 2812.5,
      y: 0.0751953125,
    },
    {
      x: 2816.576086956522,
      y: 0.05525970458984375,
    },
    {
      x: 2820.6521739130435,
      y: 0.045291900634765625,
    },
    {
      x: 2824.7282608695655,
      y: 0.040309906005859375,
    },
    {
      x: 2828.804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 2832.880434782609,
      y: 0.025356292724609375,
    },
    {
      x: 2836.9565217391305,
      y: 0.015392303466796875,
    },
    {
      x: 2841.0326086956525,
      y: 0.010406494140625,
    },
    {
      x: 2845.108695652174,
      y: 0.0004425048828125,
    },
    {
      x: 2849.1847826086955,
      y: -0.004543304443359375,
    },
    {
      x: 2853.2608695652175,
      y: -0.0145111083984375,
    },
    {
      x: 2857.336956521739,
      y: -0.01949310302734375,
    },
    {
      x: 2861.413043478261,
      y: -0.029460906982421875,
    },
    {
      x: 2865.4891304347825,
      y: -0.034442901611328125,
    },
    {
      x: 2869.5652173913045,
      y: -0.0394287109375,
    },
    {
      x: 2873.641304347826,
      y: -0.049396514892578125,
    },
    {
      x: 2877.717391304348,
      y: -0.054378509521484375,
    },
    {
      x: 2881.7934782608695,
      y: -0.05936431884765625,
    },
    {
      x: 2885.8695652173915,
      y: -0.06932830810546875,
    },
    {
      x: 2889.945652173913,
      y: -0.074310302734375,
    },
    {
      x: 2894.021739130435,
      y: -0.06932830810546875,
    },
    {
      x: 2898.0978260869565,
      y: -0.0643463134765625,
    },
    {
      x: 2902.1739130434785,
      y: -0.054378509521484375,
    },
    {
      x: 2906.25,
      y: -0.04441070556640625,
    },
    {
      x: 2910.326086956522,
      y: -0.029460906982421875,
    },
    {
      x: 2914.4021739130435,
      y: -0.01949310302734375,
    },
    {
      x: 2918.4782608695655,
      y: -0.00952911376953125,
    },
    {
      x: 2922.554347826087,
      y: 0.0004425048828125,
    },
    {
      x: 2926.630434782609,
      y: 0.010406494140625,
    },
    {
      x: 2930.7065217391305,
      y: 0.020374298095703125,
    },
    {
      x: 2938.858695652174,
      y: 0.040309906005859375,
    },
    {
      x: 2942.9347826086955,
      y: 0.050273895263671875,
    },
    {
      x: 2947.0108695652175,
      y: 0.06024169921875,
    },
    {
      x: 2959.2391304347825,
      y: 0.09014511108398438,
    },
    {
      x: 2963.3152173913045,
      y: 0.09512710571289062,
    },
    {
      x: 2967.391304347826,
      y: 0.10010910034179688,
    },
    {
      x: 2971.467391304348,
      y: 0.10010910034179688,
    },
    {
      x: 3281.25,
      y: 0.10010910034179688,
    },
    {
      x: 3285.326086956522,
      y: 0.125030517578125,
    },
    {
      x: 3289.4021739130435,
      y: 0.18483352661132812,
    },
    {
      x: 3293.4782608695655,
      y: 0.25460052490234375,
    },
    {
      x: 3297.554347826087,
      y: 0.3243675231933594,
    },
    {
      x: 3301.630434782609,
      y: 0.3991203308105469,
    },
    {
      x: 3313.858695652174,
      y: 0.6233787536621094,
    },
    {
      x: 3317.934782608696,
      y: 0.6931495666503906,
    },
    {
      x: 3322.0108695652175,
      y: 0.7629203796386719,
    },
    {
      x: 3326.086956521739,
      y: 0.7978019714355469,
    },
    {
      x: 3330.163043478261,
      y: 0.7629203796386719,
    },
    {
      x: 3334.2391304347825,
      y: 0.6981315612792969,
    },
    {
      x: 3338.3152173913045,
      y: 0.6233787536621094,
    },
    {
      x: 3342.391304347826,
      y: 0.5486259460449219,
    },
    {
      x: 3346.467391304348,
      y: 0.4688911437988281,
    },
    {
      x: 3350.5434782608695,
      y: 0.3941383361816406,
    },
    {
      x: 3354.6195652173915,
      y: 0.3193855285644531,
    },
    {
      x: 3358.695652173913,
      y: 0.24462890625,
    },
    {
      x: 3362.771739130435,
      y: 0.1698760986328125,
    },
    {
      x: 3366.8478260869565,
      y: 0.10509490966796875,
    },
    {
      x: 3370.9239130434785,
      y: 0.0851593017578125,
    },
    {
      x: 3375,
      y: 0.0751953125,
    },
    {
      x: 3379.076086956522,
      y: 0.07020950317382812,
    },
    {
      x: 3383.1521739130435,
      y: 0.07020950317382812,
    },
    {
      x: 3387.2282608695655,
      y: 0.07020950317382812,
    },
    {
      x: 3391.304347826087,
      y: 0.10010910034179688,
    },
    {
      x: 3395.380434782609,
      y: 0.10010910034179688,
    },
    {
      x: 3648.0978260869565,
      y: 0.10010910034179688,
    },
    {
      x: 3652.1739130434785,
      y: 0.0004425048828125,
    },
    {
      x: 3656.25,
      y: -0.004543304443359375,
    },
    {
      x: 3660.326086956522,
      y: -0.0145111083984375,
    },
    {
      x: 3664.4021739130435,
      y: -0.01949310302734375,
    },
    {
      x: 3668.4782608695655,
      y: -0.029460906982421875,
    },
    {
      x: 3672.554347826087,
      y: -0.034442901611328125,
    },
    {
      x: 3676.630434782609,
      y: -0.0394287109375,
    },
    {
      x: 3680.7065217391305,
      y: -0.049396514892578125,
    },
    {
      x: 3684.7826086956525,
      y: -0.054378509521484375,
    },
    {
      x: 3688.858695652174,
      y: -0.05936431884765625,
    },
    {
      x: 3692.934782608696,
      y: -0.06932830810546875,
    },
    {
      x: 3697.0108695652175,
      y: -0.074310302734375,
    },
    {
      x: 3701.086956521739,
      y: -0.06932830810546875,
    },
    {
      x: 3705.163043478261,
      y: -0.0643463134765625,
    },
    {
      x: 3709.2391304347825,
      y: -0.054378509521484375,
    },
    {
      x: 3713.3152173913045,
      y: -0.04441070556640625,
    },
    {
      x: 3717.391304347826,
      y: -0.029460906982421875,
    },
    {
      x: 3721.467391304348,
      y: -0.01949310302734375,
    },
    {
      x: 3725.5434782608695,
      y: -0.00952911376953125,
    },
    {
      x: 3729.6195652173915,
      y: 0.0004425048828125,
    },
    {
      x: 3733.695652173913,
      y: 0.010406494140625,
    },
    {
      x: 3737.771739130435,
      y: 0.020374298095703125,
    },
    {
      x: 3745.9239130434785,
      y: 0.040309906005859375,
    },
    {
      x: 3750,
      y: 0.050273895263671875,
    },
    {
      x: 3754.076086956522,
      y: 0.06024169921875,
    },
    {
      x: 3766.304347826087,
      y: 0.09014511108398438,
    },
    {
      x: 3770.380434782609,
      y: 0.09512710571289062,
    },
    {
      x: 3774.4565217391305,
      y: 0.10010910034179688,
    },
    {
      x: 3778.5326086956525,
      y: 0.10010910034179688,
    },
    {
      x: 3908.967391304348,
      y: 0.10010910034179688,
    },
    {
      x: 3913.0434782608695,
      y: 0.10509490966796875,
    },
    {
      x: 3917.1195652173915,
      y: 0.0851593017578125,
    },
    {
      x: 3921.195652173913,
      y: 0.0751953125,
    },
    {
      x: 3925.271739130435,
      y: 0.07020950317382812,
    },
    {
      x: 3929.3478260869565,
      y: 0.07020950317382812,
    },
    {
      x: 3937.5,
      y: 0.07020950317382812,
    },
    {
      x: 3941.576086956522,
      y: 0.0751953125,
    },
    {
      x: 3945.6521739130435,
      y: 0.0751953125,
    },
    {
      x: 3970.108695652174,
      y: 0.0751953125,
    },
    {
      x: 3974.184782608696,
      y: 0.08017730712890625,
    },
    {
      x: 3978.2608695652175,
      y: 0.08017730712890625,
    },
    {
      x: 4002.717391304348,
      y: 0.08017730712890625,
    },
    {
      x: 4006.7934782608695,
      y: 0.0851593017578125,
    },
    {
      x: 4010.8695652173915,
      y: 0.0851593017578125,
    },
    {
      x: 4035.326086956522,
      y: 0.0851593017578125,
    },
    {
      x: 4039.4021739130435,
      y: 0.09014511108398438,
    },
    {
      x: 4043.4782608695655,
      y: 0.09014511108398438,
    },
    {
      x: 4067.934782608696,
      y: 0.09014511108398438,
    },
    {
      x: 4072.0108695652175,
      y: 0.09512710571289062,
    },
    {
      x: 4076.0869565217395,
      y: 0.09512710571289062,
    },
    {
      x: 4133.152173913044,
      y: 0.09512710571289062,
    },
    {
      x: 4137.228260869565,
      y: 0.09014511108398438,
    },
    {
      x: 4141.304347826087,
      y: 0.0751953125,
    },
    {
      x: 4145.380434782609,
      y: 0.05525970458984375,
    },
    {
      x: 4149.456521739131,
      y: 0.045291900634765625,
    },
    {
      x: 4153.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 4157.608695652174,
      y: 0.03034210205078125,
    },
    {
      x: 4161.684782608696,
      y: 0.025356292724609375,
    },
    {
      x: 4165.760869565218,
      y: 0.015392303466796875,
    },
    {
      x: 4169.836956521739,
      y: 0.010406494140625,
    },
    {
      x: 4173.913043478261,
      y: 0.10010910034179688,
    },
    {
      x: 4177.989130434783,
      y: 0.10010910034179688,
    },
    {
      x: 4349.184782608696,
      y: 0.10010910034179688,
    },
    {
      x: 4353.260869565218,
      y: 0.125030517578125,
    },
    {
      x: 4357.336956521739,
      y: 0.18483352661132812,
    },
    {
      x: 4361.413043478261,
      y: 0.25460052490234375,
    },
    {
      x: 4365.489130434783,
      y: 0.3243675231933594,
    },
    {
      x: 4369.565217391304,
      y: 0.3991203308105469,
    },
    {
      x: 4381.79347826087,
      y: 0.6233787536621094,
    },
    {
      x: 4385.869565217391,
      y: 0.6931495666503906,
    },
    {
      x: 4389.945652173913,
      y: 0.7629203796386719,
    },
    {
      x: 4394.021739130435,
      y: 0.7978019714355469,
    },
    {
      x: 4398.097826086957,
      y: 0.7629203796386719,
    },
    {
      x: 4402.173913043478,
      y: 0.6981315612792969,
    },
    {
      x: 4406.25,
      y: 0.6233787536621094,
    },
    {
      x: 4410.326086956522,
      y: 0.5486259460449219,
    },
    {
      x: 4414.402173913044,
      y: 0.4688911437988281,
    },
    {
      x: 4418.478260869565,
      y: 0.3941383361816406,
    },
    {
      x: 4422.554347826087,
      y: 0.3193855285644531,
    },
    {
      x: 4426.630434782609,
      y: 0.24462890625,
    },
    {
      x: 4430.706521739131,
      y: 0.1698760986328125,
    },
    {
      x: 4434.782608695652,
      y: 0.10010910034179688,
    },
    {
      x: 4438.858695652174,
      y: 0.10010910034179688,
    },
    {
      x: 4691.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 4695.652173913044,
      y: 0.045291900634765625,
    },
    {
      x: 4699.728260869565,
      y: 0.040309906005859375,
    },
    {
      x: 4703.804347826087,
      y: 0.03034210205078125,
    },
    {
      x: 4707.880434782609,
      y: 0.025356292724609375,
    },
    {
      x: 4711.956521739131,
      y: 0.015392303466796875,
    },
    {
      x: 4716.032608695652,
      y: 0.010406494140625,
    },
    {
      x: 4720.108695652174,
      y: 0.0004425048828125,
    },
    {
      x: 4724.184782608696,
      y: -0.004543304443359375,
    },
    {
      x: 4728.260869565218,
      y: -0.0145111083984375,
    },
    {
      x: 4732.336956521739,
      y: -0.01949310302734375,
    },
    {
      x: 4736.413043478261,
      y: -0.029460906982421875,
    },
    {
      x: 4740.489130434783,
      y: -0.034442901611328125,
    },
    {
      x: 4744.565217391304,
      y: -0.0394287109375,
    },
    {
      x: 4748.641304347826,
      y: -0.049396514892578125,
    },
    {
      x: 4752.717391304348,
      y: -0.054378509521484375,
    },
    {
      x: 4756.79347826087,
      y: -0.05936431884765625,
    },
    {
      x: 4760.869565217391,
      y: -0.06932830810546875,
    },
    {
      x: 4764.945652173913,
      y: -0.074310302734375,
    },
    {
      x: 4769.021739130435,
      y: -0.06932830810546875,
    },
    {
      x: 4773.097826086957,
      y: -0.0643463134765625,
    },
    {
      x: 4777.173913043478,
      y: -0.054378509521484375,
    },
    {
      x: 4781.25,
      y: -0.04441070556640625,
    },
    {
      x: 4785.326086956522,
      y: -0.029460906982421875,
    },
    {
      x: 4789.402173913044,
      y: -0.01949310302734375,
    },
    {
      x: 4793.478260869565,
      y: -0.00952911376953125,
    },
    {
      x: 4797.554347826087,
      y: 0.0004425048828125,
    },
    {
      x: 4801.630434782609,
      y: 0.010406494140625,
    },
    {
      x: 4805.706521739131,
      y: 0.020374298095703125,
    },
    {
      x: 4813.858695652174,
      y: 0.040309906005859375,
    },
    {
      x: 4817.934782608696,
      y: 0.050273895263671875,
    },
    {
      x: 4822.010869565218,
      y: 0.06024169921875,
    },
    {
      x: 4834.239130434783,
      y: 0.09014511108398438,
    },
    {
      x: 4838.315217391304,
      y: 0.09512710571289062,
    },
    {
      x: 4842.391304347826,
      y: 0.10010910034179688,
    },
    {
      x: 4846.467391304348,
      y: 0.10010910034179688,
    },
    {
      x: 4952.445652173913,
      y: 0.10010910034179688,
    },
    {
      x: 4956.521739130435,
      y: 0.5486259460449219,
    },
    {
      x: 4960.597826086957,
      y: 0.4688911437988281,
    },
    {
      x: 4964.673913043478,
      y: 0.3941383361816406,
    },
    {
      x: 4968.75,
      y: 0.3193855285644531,
    },
    {
      x: 4972.826086956522,
      y: 0.24462890625,
    },
    {
      x: 4976.902173913044,
      y: 0.1698760986328125,
    },
    {
      x: 4980.978260869565,
      y: 0.10509490966796875,
    },
    {
      x: 4985.054347826087,
      y: 0.0851593017578125,
    },
    {
      x: 4989.130434782609,
      y: 0.0751953125,
    },
    {
      x: 4993.206521739131,
      y: 0.07020950317382812,
    },
    {
      x: 4997.282608695652,
      y: 0.07020950317382812,
    },
    {
      x: 5005.434782608696,
      y: 0.07020950317382812,
    },
    {
      x: 5009.510869565218,
      y: 0.0751953125,
    },
    {
      x: 5013.586956521739,
      y: 0.0751953125,
    },
    {
      x: 5038.04347826087,
      y: 0.0751953125,
    },
    {
      x: 5042.119565217391,
      y: 0.08017730712890625,
    },
    {
      x: 5046.195652173913,
      y: 0.08017730712890625,
    },
    {
      x: 5070.652173913044,
      y: 0.08017730712890625,
    },
    {
      x: 5074.728260869565,
      y: 0.0851593017578125,
    },
    {
      x: 5078.804347826087,
      y: 0.0851593017578125,
    },
    {
      x: 5103.260869565218,
      y: 0.0851593017578125,
    },
    {
      x: 5107.336956521739,
      y: 0.09014511108398438,
    },
    {
      x: 5111.413043478261,
      y: 0.09014511108398438,
    },
    {
      x: 5135.869565217391,
      y: 0.09014511108398438,
    },
    {
      x: 5139.945652173913,
      y: 0.09512710571289062,
    },
    {
      x: 5144.021739130435,
      y: 0.09512710571289062,
    },
    {
      x: 5201.086956521739,
      y: 0.09512710571289062,
    },
    {
      x: 5205.163043478261,
      y: 0.09014511108398438,
    },
    {
      x: 5209.239130434783,
      y: 0.0751953125,
    },
    {
      x: 5213.315217391305,
      y: 0.05525970458984375,
    },
    {
      x: 5217.391304347826,
      y: 0.10010910034179688,
    },
    {
      x: 5221.467391304348,
      y: 0.10010910034179688,
    },
    {
      x: 5677.989130434783,
      y: 0.10010910034179688,
    },
    {
      x: 5682.065217391305,
      y: 0.125030517578125,
    },
    {
      x: 5686.141304347826,
      y: 0.18483352661132812,
    },
    {
      x: 5690.217391304348,
      y: 0.25460052490234375,
    },
    {
      x: 5694.29347826087,
      y: 0.3243675231933594,
    },
    {
      x: 5698.369565217391,
      y: 0.3991203308105469,
    },
    {
      x: 5710.597826086957,
      y: 0.6233787536621094,
    },
    {
      x: 5714.673913043478,
      y: 0.6931495666503906,
    },
    {
      x: 5718.75,
      y: 0.7629203796386719,
    },
    {
      x: 5722.826086956522,
      y: 0.7978019714355469,
    },
    {
      x: 5726.902173913044,
      y: 0.7629203796386719,
    },
    {
      x: 5730.978260869565,
      y: 0.6981315612792969,
    },
    {
      x: 5735.054347826087,
      y: 0.6233787536621094,
    },
    {
      x: 5739.130434782609,
      y: 0.6931495666503906,
    },
    {
      x: 5743.206521739131,
      y: 0.7629203796386719,
    },
    {
      x: 5747.282608695652,
      y: 0.7978019714355469,
    },
    {
      x: 5751.358695652174,
      y: 0.7629203796386719,
    },
    {
      x: 5755.434782608696,
      y: 0.6981315612792969,
    },
    {
      x: 5759.510869565218,
      y: 0.6233787536621094,
    },
    {
      x: 5763.586956521739,
      y: 0.5486259460449219,
    },
    {
      x: 5767.663043478261,
      y: 0.4688911437988281,
    },
    {
      x: 5771.739130434783,
      y: 0.3941383361816406,
    },
    {
      x: 5775.815217391305,
      y: 0.3193855285644531,
    },
    {
      x: 5779.891304347826,
      y: 0.24462890625,
    },
    {
      x: 5783.967391304348,
      y: 0.1698760986328125,
    },
    {
      x: 5788.04347826087,
      y: 0.10509490966796875,
    },
    {
      x: 5792.119565217391,
      y: 0.0851593017578125,
    },
    {
      x: 5796.195652173913,
      y: 0.0751953125,
    },
    {
      x: 5800.271739130435,
      y: 0.07020950317382812,
    },
    {
      x: 5804.347826086957,
      y: 0.07020950317382812,
    },
    {
      x: 5812.5,
      y: 0.07020950317382812,
    },
    {
      x: 5816.576086956522,
      y: 0.0751953125,
    },
    {
      x: 5820.652173913044,
      y: 0.0751953125,
    },
    {
      x: 5845.108695652174,
      y: 0.0751953125,
    },
    {
      x: 5849.184782608696,
      y: 0.08017730712890625,
    },
    {
      x: 5853.260869565218,
      y: 0.08017730712890625,
    },
    {
      x: 5877.717391304348,
      y: 0.08017730712890625,
    },
    {
      x: 5881.79347826087,
      y: 0.0851593017578125,
    },
    {
      x: 5885.869565217391,
      y: 0.0851593017578125,
    },
    {
      x: 5910.326086956522,
      y: 0.0851593017578125,
    },
    {
      x: 5914.402173913044,
      y: 0.09014511108398438,
    },
    {
      x: 5918.478260869565,
      y: 0.09014511108398438,
    },
    {
      x: 5942.934782608696,
      y: 0.09014511108398438,
    },
    {
      x: 5947.010869565218,
      y: 0.09512710571289062,
    },
    {
      x: 5951.086956521739,
      y: 0.09512710571289062,
    },
    {
      x: 6008.152173913044,
      y: 0.09512710571289062,
    },
    {
      x: 6012.228260869565,
      y: 0.09014511108398438,
    },
    {
      x: 6016.304347826087,
      y: 0.0751953125,
    },
    {
      x: 6020.380434782609,
      y: 0.05525970458984375,
    },
    {
      x: 6024.456521739131,
      y: 0.045291900634765625,
    },
    {
      x: 6028.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 6032.608695652174,
      y: 0.03034210205078125,
    },
    {
      x: 6036.684782608696,
      y: 0.025356292724609375,
    },
    {
      x: 6040.760869565218,
      y: 0.015392303466796875,
    },
    {
      x: 6044.836956521739,
      y: 0.010406494140625,
    },
    {
      x: 6048.913043478261,
      y: 0.0004425048828125,
    },
    {
      x: 6052.989130434783,
      y: -0.004543304443359375,
    },
    {
      x: 6057.065217391305,
      y: -0.0145111083984375,
    },
    {
      x: 6061.141304347826,
      y: -0.01949310302734375,
    },
    {
      x: 6065.217391304348,
      y: -0.029460906982421875,
    },
    {
      x: 6069.29347826087,
      y: -0.034442901611328125,
    },
    {
      x: 6073.369565217391,
      y: -0.0394287109375,
    },
    {
      x: 6077.445652173913,
      y: -0.049396514892578125,
    },
    {
      x: 6081.521739130435,
      y: -0.054378509521484375,
    },
    {
      x: 6085.597826086957,
      y: -0.05936431884765625,
    },
    {
      x: 6089.673913043478,
      y: -0.06932830810546875,
    },
    {
      x: 6093.75,
      y: -0.074310302734375,
    },
    {
      x: 6097.826086956522,
      y: -0.06932830810546875,
    },
    {
      x: 6101.902173913044,
      y: -0.0643463134765625,
    },
    {
      x: 6105.978260869565,
      y: -0.054378509521484375,
    },
    {
      x: 6110.054347826087,
      y: -0.04441070556640625,
    },
    {
      x: 6114.130434782609,
      y: -0.029460906982421875,
    },
    {
      x: 6118.206521739131,
      y: -0.01949310302734375,
    },
    {
      x: 6122.282608695652,
      y: -0.00952911376953125,
    },
    {
      x: 6126.358695652174,
      y: 0.0004425048828125,
    },
    {
      x: 6130.434782608696,
      y: 0.010406494140625,
    },
    {
      x: 6134.510869565218,
      y: 0.020374298095703125,
    },
    {
      x: 6142.663043478261,
      y: 0.040309906005859375,
    },
    {
      x: 6146.739130434783,
      y: 0.050273895263671875,
    },
    {
      x: 6150.815217391305,
      y: 0.06024169921875,
    },
    {
      x: 6163.04347826087,
      y: 0.09014511108398438,
    },
    {
      x: 6167.119565217391,
      y: 0.09512710571289062,
    },
    {
      x: 6171.195652173913,
      y: 0.10010910034179688,
    },
    {
      x: 6175.271739130435,
      y: 0.10010910034179688,
    },
    {
      x: 6485.054347826087,
      y: 0.10010910034179688,
    },
    {
      x: 6489.130434782609,
      y: 0.125030517578125,
    },
    {
      x: 6493.206521739131,
      y: 0.18483352661132812,
    },
    {
      x: 6497.282608695652,
      y: 0.25460052490234375,
    },
    {
      x: 6501.358695652174,
      y: 0.3243675231933594,
    },
    {
      x: 6505.434782608696,
      y: 0.3991203308105469,
    },
    {
      x: 6517.663043478261,
      y: 0.6233787536621094,
    },
    {
      x: 6521.739130434783,
      y: 0.10010910034179688,
    },
    {
      x: 6525.815217391305,
      y: 0.10010910034179688,
    },
    {
      x: 6778.532608695652,
      y: 0.10010910034179688,
    },
    {
      x: 6782.608695652174,
      y: 0.09512710571289062,
    },
    {
      x: 6786.684782608696,
      y: 0.09512710571289062,
    },
    {
      x: 6815.217391304348,
      y: 0.09512710571289062,
    },
    {
      x: 6819.29347826087,
      y: 0.09014511108398438,
    },
    {
      x: 6823.369565217392,
      y: 0.0751953125,
    },
    {
      x: 6827.445652173913,
      y: 0.05525970458984375,
    },
    {
      x: 6831.521739130435,
      y: 0.045291900634765625,
    },
    {
      x: 6835.597826086957,
      y: 0.040309906005859375,
    },
    {
      x: 6839.673913043478,
      y: 0.03034210205078125,
    },
    {
      x: 6843.75,
      y: 0.025356292724609375,
    },
    {
      x: 6847.826086956522,
      y: 0.015392303466796875,
    },
    {
      x: 6851.902173913044,
      y: 0.010406494140625,
    },
    {
      x: 6855.978260869565,
      y: 0.0004425048828125,
    },
    {
      x: 6860.054347826087,
      y: -0.004543304443359375,
    },
    {
      x: 6864.130434782609,
      y: -0.0145111083984375,
    },
    {
      x: 6868.206521739131,
      y: -0.01949310302734375,
    },
    {
      x: 6872.282608695652,
      y: -0.029460906982421875,
    },
    {
      x: 6876.358695652174,
      y: -0.034442901611328125,
    },
    {
      x: 6880.434782608696,
      y: -0.0394287109375,
    },
    {
      x: 6884.510869565218,
      y: -0.049396514892578125,
    },
    {
      x: 6888.586956521739,
      y: -0.054378509521484375,
    },
    {
      x: 6892.663043478261,
      y: -0.05936431884765625,
    },
    {
      x: 6896.739130434783,
      y: -0.06932830810546875,
    },
    {
      x: 6900.815217391305,
      y: -0.074310302734375,
    },
    {
      x: 6904.891304347826,
      y: -0.06932830810546875,
    },
    {
      x: 6908.967391304348,
      y: -0.0643463134765625,
    },
    {
      x: 6913.04347826087,
      y: -0.054378509521484375,
    },
    {
      x: 6917.119565217392,
      y: -0.04441070556640625,
    },
    {
      x: 6921.195652173913,
      y: -0.029460906982421875,
    },
    {
      x: 6925.271739130435,
      y: -0.01949310302734375,
    },
    {
      x: 6929.347826086957,
      y: -0.00952911376953125,
    },
    {
      x: 6933.423913043478,
      y: 0.0004425048828125,
    },
    {
      x: 6937.5,
      y: 0.010406494140625,
    },
    {
      x: 6941.576086956522,
      y: 0.020374298095703125,
    },
    {
      x: 6949.728260869565,
      y: 0.040309906005859375,
    },
    {
      x: 6953.804347826087,
      y: 0.050273895263671875,
    },
    {
      x: 6957.880434782609,
      y: 0.06024169921875,
    },
    {
      x: 6970.108695652174,
      y: 0.09014511108398438,
    },
    {
      x: 6974.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 6978.260869565218,
      y: 0.10010910034179688,
    },
    {
      x: 6982.336956521739,
      y: 0.10010910034179688,
    },
    {
      x: 7039.402173913044,
      y: 0.10010910034179688,
    },
    {
      x: 7043.478260869565,
      y: 0.25460052490234375,
    },
    {
      x: 7047.554347826087,
      y: 0.3243675231933594,
    },
    {
      x: 7051.630434782609,
      y: 0.3991203308105469,
    },
    {
      x: 7063.858695652174,
      y: 0.6233787536621094,
    },
    {
      x: 7067.934782608696,
      y: 0.6931495666503906,
    },
    {
      x: 7072.010869565218,
      y: 0.7629203796386719,
    },
    {
      x: 7076.086956521739,
      y: 0.7978019714355469,
    },
    {
      x: 7080.163043478261,
      y: 0.7629203796386719,
    },
    {
      x: 7084.239130434783,
      y: 0.6981315612792969,
    },
    {
      x: 7088.315217391305,
      y: 0.6233787536621094,
    },
    {
      x: 7092.391304347826,
      y: 0.5486259460449219,
    },
    {
      x: 7096.467391304348,
      y: 0.4688911437988281,
    },
    {
      x: 7100.54347826087,
      y: 0.3941383361816406,
    },
    {
      x: 7104.619565217392,
      y: 0.3193855285644531,
    },
    {
      x: 7108.695652173913,
      y: 0.24462890625,
    },
    {
      x: 7112.771739130435,
      y: 0.1698760986328125,
    },
    {
      x: 7116.847826086957,
      y: 0.10509490966796875,
    },
    {
      x: 7120.923913043478,
      y: 0.0851593017578125,
    },
    {
      x: 7125,
      y: 0.0751953125,
    },
    {
      x: 7129.076086956522,
      y: 0.07020950317382812,
    },
    {
      x: 7133.152173913044,
      y: 0.07020950317382812,
    },
    {
      x: 7141.304347826087,
      y: 0.07020950317382812,
    },
    {
      x: 7145.380434782609,
      y: 0.0751953125,
    },
    {
      x: 7149.456521739131,
      y: 0.0751953125,
    },
    {
      x: 7173.913043478261,
      y: 0.0751953125,
    },
    {
      x: 7177.989130434783,
      y: 0.08017730712890625,
    },
    {
      x: 7182.065217391305,
      y: 0.08017730712890625,
    },
    {
      x: 7206.521739130435,
      y: 0.08017730712890625,
    },
    {
      x: 7210.597826086957,
      y: 0.0851593017578125,
    },
    {
      x: 7214.673913043478,
      y: 0.0851593017578125,
    },
    {
      x: 7239.130434782609,
      y: 0.0851593017578125,
    },
    {
      x: 7243.206521739131,
      y: 0.09014511108398438,
    },
    {
      x: 7247.282608695652,
      y: 0.09014511108398438,
    },
    {
      x: 7271.739130434783,
      y: 0.09014511108398438,
    },
    {
      x: 7275.815217391305,
      y: 0.09512710571289062,
    },
    {
      x: 7279.891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 7300.271739130435,
      y: 0.09512710571289062,
    },
    {
      x: 7304.347826086957,
      y: 0.10010910034179688,
    },
    {
      x: 7308.423913043478,
      y: 0.10010910034179688,
    },
    {
      x: 7316.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 7320.652173913044,
      y: 0.125030517578125,
    },
    {
      x: 7324.728260869565,
      y: 0.18483352661132812,
    },
    {
      x: 7328.804347826087,
      y: 0.25460052490234375,
    },
    {
      x: 7332.880434782609,
      y: 0.3243675231933594,
    },
    {
      x: 7336.956521739131,
      y: 0.3991203308105469,
    },
    {
      x: 7349.184782608696,
      y: 0.6233787536621094,
    },
    {
      x: 7353.260869565218,
      y: 0.6931495666503906,
    },
    {
      x: 7357.336956521739,
      y: 0.7629203796386719,
    },
    {
      x: 7361.413043478261,
      y: 0.7978019714355469,
    },
    {
      x: 7365.489130434783,
      y: 0.7629203796386719,
    },
    {
      x: 7369.565217391305,
      y: 0.6981315612792969,
    },
    {
      x: 7373.641304347826,
      y: 0.6233787536621094,
    },
    {
      x: 7377.717391304348,
      y: 0.5486259460449219,
    },
    {
      x: 7381.79347826087,
      y: 0.4688911437988281,
    },
    {
      x: 7385.869565217392,
      y: 0.3941383361816406,
    },
    {
      x: 7389.945652173913,
      y: 0.3193855285644531,
    },
    {
      x: 7394.021739130435,
      y: 0.24462890625,
    },
    {
      x: 7398.097826086957,
      y: 0.1698760986328125,
    },
    {
      x: 7402.173913043478,
      y: 0.10509490966796875,
    },
    {
      x: 7406.25,
      y: 0.0851593017578125,
    },
    {
      x: 7410.326086956522,
      y: 0.0751953125,
    },
    {
      x: 7414.402173913044,
      y: 0.07020950317382812,
    },
    {
      x: 7418.478260869565,
      y: 0.07020950317382812,
    },
    {
      x: 7426.630434782609,
      y: 0.07020950317382812,
    },
    {
      x: 7430.706521739131,
      y: 0.0751953125,
    },
    {
      x: 7434.782608695652,
      y: 0.0751953125,
    },
    {
      x: 7459.239130434783,
      y: 0.0751953125,
    },
    {
      x: 7463.315217391305,
      y: 0.08017730712890625,
    },
    {
      x: 7467.391304347826,
      y: 0.08017730712890625,
    },
    {
      x: 7491.847826086957,
      y: 0.08017730712890625,
    },
    {
      x: 7495.923913043478,
      y: 0.0851593017578125,
    },
    {
      x: 7500,
      y: 0.0851593017578125,
    },
    {
      x: 7524.456521739131,
      y: 0.0851593017578125,
    },
    {
      x: 7528.532608695652,
      y: 0.09014511108398438,
    },
    {
      x: 7532.608695652174,
      y: 0.09014511108398438,
    },
    {
      x: 7557.065217391305,
      y: 0.09014511108398438,
    },
    {
      x: 7561.141304347826,
      y: 0.09512710571289062,
    },
    {
      x: 7565.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 7569.29347826087,
      y: 0.10010910034179688,
    },
    {
      x: 7813.858695652174,
      y: 0.10010910034179688,
    },
    {
      x: 7817.934782608696,
      y: 0.125030517578125,
    },
    {
      x: 7822.010869565218,
      y: 0.18483352661132812,
    },
    {
      x: 7826.086956521739,
      y: 0.10010910034179688,
    },
    {
      x: 7830.163043478261,
      y: 0.10010910034179688,
    },
    {
      x: 8082.880434782609,
      y: 0.10010910034179688,
    },
    {
      x: 8086.956521739131,
      y: 0.09512710571289062,
    },
    {
      x: 8091.032608695652,
      y: 0.09512710571289062,
    },
    {
      x: 8144.021739130435,
      y: 0.09512710571289062,
    },
    {
      x: 8148.097826086957,
      y: 0.09014511108398438,
    },
    {
      x: 8152.173913043479,
      y: 0.0751953125,
    },
    {
      x: 8156.25,
      y: 0.05525970458984375,
    },
    {
      x: 8160.326086956522,
      y: 0.045291900634765625,
    },
    {
      x: 8164.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 8168.478260869565,
      y: 0.03034210205078125,
    },
    {
      x: 8172.554347826087,
      y: 0.025356292724609375,
    },
    {
      x: 8176.630434782609,
      y: 0.015392303466796875,
    },
    {
      x: 8180.706521739131,
      y: 0.010406494140625,
    },
    {
      x: 8184.782608695652,
      y: 0.0004425048828125,
    },
    {
      x: 8188.858695652174,
      y: -0.004543304443359375,
    },
    {
      x: 8192.934782608696,
      y: -0.0145111083984375,
    },
    {
      x: 8197.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 8201.08695652174,
      y: -0.029460906982421875,
    },
    {
      x: 8205.163043478262,
      y: -0.034442901611328125,
    },
    {
      x: 8209.239130434782,
      y: -0.0394287109375,
    },
    {
      x: 8213.315217391304,
      y: -0.049396514892578125,
    },
    {
      x: 8217.391304347826,
      y: -0.054378509521484375,
    },
    {
      x: 8221.467391304348,
      y: -0.05936431884765625,
    },
    {
      x: 8225.54347826087,
      y: -0.06932830810546875,
    },
    {
      x: 8229.619565217392,
      y: -0.074310302734375,
    },
    {
      x: 8233.695652173914,
      y: -0.06932830810546875,
    },
    {
      x: 8237.771739130436,
      y: -0.0643463134765625,
    },
    {
      x: 8241.847826086956,
      y: -0.054378509521484375,
    },
    {
      x: 8245.923913043478,
      y: -0.04441070556640625,
    },
    {
      x: 8250,
      y: -0.029460906982421875,
    },
    {
      x: 8254.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 8258.152173913044,
      y: -0.00952911376953125,
    },
    {
      x: 8262.228260869566,
      y: 0.0004425048828125,
    },
    {
      x: 8266.304347826088,
      y: 0.010406494140625,
    },
    {
      x: 8270.380434782608,
      y: 0.020374298095703125,
    },
    {
      x: 8278.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 8282.608695652174,
      y: 0.050273895263671875,
    },
    {
      x: 8286.684782608696,
      y: 0.06024169921875,
    },
    {
      x: 8298.913043478262,
      y: 0.09014511108398438,
    },
    {
      x: 8302.989130434782,
      y: 0.09512710571289062,
    },
    {
      x: 8307.065217391304,
      y: 0.10010910034179688,
    },
    {
      x: 8311.141304347826,
      y: 0.10010910034179688,
    },
    {
      x: 8906.25,
      y: 0.10010910034179688,
    },
    {
      x: 8910.326086956522,
      y: 0.125030517578125,
    },
    {
      x: 8914.402173913044,
      y: 0.18483352661132812,
    },
    {
      x: 8918.478260869566,
      y: 0.25460052490234375,
    },
    {
      x: 8922.554347826088,
      y: 0.3243675231933594,
    },
    {
      x: 8926.630434782608,
      y: 0.3991203308105469,
    },
    {
      x: 8938.858695652174,
      y: 0.6233787536621094,
    },
    {
      x: 8942.934782608696,
      y: 0.6931495666503906,
    },
    {
      x: 8947.010869565218,
      y: 0.7629203796386719,
    },
    {
      x: 8951.08695652174,
      y: 0.7978019714355469,
    },
    {
      x: 8955.163043478262,
      y: 0.7629203796386719,
    },
    {
      x: 8959.239130434782,
      y: 0.6981315612792969,
    },
    {
      x: 8963.315217391304,
      y: 0.6233787536621094,
    },
    {
      x: 8967.391304347826,
      y: 0.5486259460449219,
    },
    {
      x: 8971.467391304348,
      y: 0.4688911437988281,
    },
    {
      x: 8975.54347826087,
      y: 0.3941383361816406,
    },
    {
      x: 8979.619565217392,
      y: 0.3193855285644531,
    },
    {
      x: 8983.695652173914,
      y: 0.24462890625,
    },
    {
      x: 8987.771739130436,
      y: 0.1698760986328125,
    },
    {
      x: 8991.847826086956,
      y: 0.10509490966796875,
    },
    {
      x: 8995.923913043478,
      y: 0.0851593017578125,
    },
    {
      x: 9000,
      y: 0.0751953125,
    },
    {
      x: 9004.076086956522,
      y: 0.07020950317382812,
    },
    {
      x: 9008.152173913044,
      y: 0.07020950317382812,
    },
    {
      x: 9016.304347826088,
      y: 0.07020950317382812,
    },
    {
      x: 9020.380434782608,
      y: 0.0751953125,
    },
    {
      x: 9024.45652173913,
      y: 0.0751953125,
    },
    {
      x: 9048.913043478262,
      y: 0.0751953125,
    },
    {
      x: 9052.989130434782,
      y: 0.08017730712890625,
    },
    {
      x: 9057.065217391304,
      y: 0.08017730712890625,
    },
    {
      x: 9081.521739130436,
      y: 0.08017730712890625,
    },
    {
      x: 9085.597826086956,
      y: 0.0851593017578125,
    },
    {
      x: 9089.673913043478,
      y: 0.0851593017578125,
    },
    {
      x: 9114.130434782608,
      y: 0.0851593017578125,
    },
    {
      x: 9118.20652173913,
      y: 0.09014511108398438,
    },
    {
      x: 9122.282608695652,
      y: 0.09014511108398438,
    },
    {
      x: 9146.739130434782,
      y: 0.09014511108398438,
    },
    {
      x: 9150.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 9154.891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 9211.95652173913,
      y: 0.09512710571289062,
    },
    {
      x: 9216.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 9220.108695652174,
      y: 0.0751953125,
    },
    {
      x: 9224.184782608696,
      y: 0.05525970458984375,
    },
    {
      x: 9228.260869565218,
      y: 0.045291900634765625,
    },
    {
      x: 9232.33695652174,
      y: 0.040309906005859375,
    },
    {
      x: 9236.413043478262,
      y: 0.03034210205078125,
    },
    {
      x: 9240.489130434782,
      y: 0.025356292724609375,
    },
    {
      x: 9244.565217391304,
      y: 0.015392303466796875,
    },
    {
      x: 9248.641304347826,
      y: 0.010406494140625,
    },
    {
      x: 9252.717391304348,
      y: 0.0004425048828125,
    },
    {
      x: 9256.79347826087,
      y: -0.004543304443359375,
    },
    {
      x: 9260.869565217392,
      y: -0.0145111083984375,
    },
    {
      x: 9264.945652173914,
      y: -0.01949310302734375,
    },
    {
      x: 9269.021739130436,
      y: -0.029460906982421875,
    },
    {
      x: 9273.097826086956,
      y: -0.034442901611328125,
    },
    {
      x: 9277.173913043478,
      y: -0.0394287109375,
    },
    {
      x: 9281.25,
      y: -0.049396514892578125,
    },
    {
      x: 9285.326086956522,
      y: -0.054378509521484375,
    },
    {
      x: 9289.402173913044,
      y: -0.05936431884765625,
    },
    {
      x: 9293.478260869566,
      y: -0.06932830810546875,
    },
    {
      x: 9297.554347826088,
      y: -0.074310302734375,
    },
    {
      x: 9301.630434782608,
      y: -0.06932830810546875,
    },
    {
      x: 9305.70652173913,
      y: -0.0643463134765625,
    },
    {
      x: 9309.782608695652,
      y: -0.054378509521484375,
    },
    {
      x: 9313.858695652174,
      y: -0.04441070556640625,
    },
    {
      x: 9317.934782608696,
      y: -0.029460906982421875,
    },
    {
      x: 9322.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 9326.08695652174,
      y: -0.00952911376953125,
    },
    {
      x: 9330.163043478262,
      y: 0.0004425048828125,
    },
    {
      x: 9334.239130434782,
      y: 0.010406494140625,
    },
    {
      x: 9338.315217391304,
      y: 0.020374298095703125,
    },
    {
      x: 9346.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 9350.54347826087,
      y: 0.050273895263671875,
    },
    {
      x: 9354.619565217392,
      y: 0.06024169921875,
    },
    {
      x: 9366.847826086956,
      y: 0.09014511108398438,
    },
    {
      x: 9370.923913043478,
      y: 0.09512710571289062,
    },
    {
      x: 9375,
      y: 0.10010910034179688,
    },
    {
      x: 9379.076086956522,
      y: 0.10010910034179688,
    },
    {
      x: 9648.097826086956,
      y: 0.10010910034179688,
    },
    {
      x: 9652.173913043478,
      y: 0.09014511108398438,
    },
    {
      x: 9656.25,
      y: 0.09512710571289062,
    },
    {
      x: 9660.326086956522,
      y: 0.10010910034179688,
    },
    {
      x: 9664.402173913044,
      y: 0.10010910034179688,
    },
    {
      x: 9908.967391304348,
      y: 0.10010910034179688,
    },
    {
      x: 9913.04347826087,
      y: 0.0851593017578125,
    },
    {
      x: 9917.119565217392,
      y: 0.0851593017578125,
    },
    {
      x: 9921.195652173914,
      y: 0.0851593017578125,
    },
    {
      x: 9925.271739130436,
      y: 0.09014511108398438,
    },
    {
      x: 9929.347826086956,
      y: 0.09014511108398438,
    },
    {
      x: 9953.804347826088,
      y: 0.09014511108398438,
    },
    {
      x: 9957.88043478261,
      y: 0.09512710571289062,
    },
    {
      x: 9961.95652173913,
      y: 0.09512710571289062,
    },
    {
      x: 10019.021739130436,
      y: 0.09512710571289062,
    },
    {
      x: 10023.097826086956,
      y: 0.09014511108398438,
    },
    {
      x: 10027.173913043478,
      y: 0.0751953125,
    },
    {
      x: 10031.25,
      y: 0.05525970458984375,
    },
    {
      x: 10035.326086956522,
      y: 0.045291900634765625,
    },
    {
      x: 10039.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 10043.478260869566,
      y: 0.03034210205078125,
    },
    {
      x: 10047.554347826088,
      y: 0.025356292724609375,
    },
    {
      x: 10051.63043478261,
      y: 0.015392303466796875,
    },
    {
      x: 10055.70652173913,
      y: 0.010406494140625,
    },
    {
      x: 10059.782608695652,
      y: 0.0004425048828125,
    },
    {
      x: 10063.858695652174,
      y: -0.004543304443359375,
    },
    {
      x: 10067.934782608696,
      y: -0.0145111083984375,
    },
    {
      x: 10072.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 10076.08695652174,
      y: -0.029460906982421875,
    },
    {
      x: 10080.163043478262,
      y: -0.034442901611328125,
    },
    {
      x: 10084.239130434782,
      y: -0.0394287109375,
    },
    {
      x: 10088.315217391304,
      y: -0.049396514892578125,
    },
    {
      x: 10092.391304347826,
      y: -0.054378509521484375,
    },
    {
      x: 10096.467391304348,
      y: -0.05936431884765625,
    },
    {
      x: 10100.54347826087,
      y: -0.06932830810546875,
    },
    {
      x: 10104.619565217392,
      y: -0.074310302734375,
    },
    {
      x: 10108.695652173914,
      y: -0.06932830810546875,
    },
    {
      x: 10112.771739130436,
      y: -0.0643463134765625,
    },
    {
      x: 10116.847826086956,
      y: -0.054378509521484375,
    },
    {
      x: 10120.923913043478,
      y: -0.04441070556640625,
    },
    {
      x: 10125,
      y: -0.029460906982421875,
    },
    {
      x: 10129.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 10133.152173913044,
      y: -0.00952911376953125,
    },
    {
      x: 10137.228260869566,
      y: 0.0004425048828125,
    },
    {
      x: 10141.304347826088,
      y: 0.010406494140625,
    },
    {
      x: 10145.38043478261,
      y: 0.020374298095703125,
    },
    {
      x: 10153.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 10157.608695652174,
      y: 0.050273895263671875,
    },
    {
      x: 10161.684782608696,
      y: 0.06024169921875,
    },
    {
      x: 10169.83695652174,
      y: 0.08017730712890625,
    },
    {
      x: 10173.913043478262,
      y: 0.10010910034179688,
    },
    {
      x: 10177.989130434782,
      y: 0.10010910034179688,
    },
    {
      x: 10235.054347826088,
      y: 0.10010910034179688,
    },
    {
      x: 10239.13043478261,
      y: 0.125030517578125,
    },
    {
      x: 10243.20652173913,
      y: 0.18483352661132812,
    },
    {
      x: 10247.282608695652,
      y: 0.25460052490234375,
    },
    {
      x: 10251.358695652174,
      y: 0.3243675231933594,
    },
    {
      x: 10255.434782608696,
      y: 0.3991203308105469,
    },
    {
      x: 10267.663043478262,
      y: 0.6233787536621094,
    },
    {
      x: 10271.739130434782,
      y: 0.6931495666503906,
    },
    {
      x: 10275.815217391304,
      y: 0.7629203796386719,
    },
    {
      x: 10279.891304347826,
      y: 0.7978019714355469,
    },
    {
      x: 10283.967391304348,
      y: 0.7629203796386719,
    },
    {
      x: 10288.04347826087,
      y: 0.6981315612792969,
    },
    {
      x: 10292.119565217392,
      y: 0.6233787536621094,
    },
    {
      x: 10296.195652173914,
      y: 0.5486259460449219,
    },
    {
      x: 10300.271739130436,
      y: 0.4688911437988281,
    },
    {
      x: 10304.347826086956,
      y: 0.3941383361816406,
    },
    {
      x: 10308.423913043478,
      y: 0.3193855285644531,
    },
    {
      x: 10312.5,
      y: 0.24462890625,
    },
    {
      x: 10316.576086956522,
      y: 0.1698760986328125,
    },
    {
      x: 10320.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 10324.728260869566,
      y: 0.0851593017578125,
    },
    {
      x: 10328.804347826088,
      y: 0.0751953125,
    },
    {
      x: 10332.88043478261,
      y: 0.07020950317382812,
    },
    {
      x: 10336.95652173913,
      y: 0.07020950317382812,
    },
    {
      x: 10345.108695652174,
      y: 0.07020950317382812,
    },
    {
      x: 10349.184782608696,
      y: 0.0751953125,
    },
    {
      x: 10353.260869565218,
      y: 0.0751953125,
    },
    {
      x: 10377.717391304348,
      y: 0.0751953125,
    },
    {
      x: 10381.79347826087,
      y: 0.08017730712890625,
    },
    {
      x: 10385.869565217392,
      y: 0.08017730712890625,
    },
    {
      x: 10410.326086956522,
      y: 0.08017730712890625,
    },
    {
      x: 10414.402173913044,
      y: 0.0851593017578125,
    },
    {
      x: 10418.478260869566,
      y: 0.0851593017578125,
    },
    {
      x: 10430.70652173913,
      y: 0.0851593017578125,
    },
    {
      x: 10434.782608695652,
      y: 0.10010910034179688,
    },
    {
      x: 10438.858695652174,
      y: 0.10010910034179688,
    },
    {
      x: 10691.576086956522,
      y: 0.10010910034179688,
    },
    {
      x: 10695.652173913044,
      y: 0.03034210205078125,
    },
    {
      x: 10699.728260869566,
      y: 0.040309906005859375,
    },
    {
      x: 10703.804347826088,
      y: 0.050273895263671875,
    },
    {
      x: 10707.88043478261,
      y: 0.06024169921875,
    },
    {
      x: 10720.108695652174,
      y: 0.09014511108398438,
    },
    {
      x: 10724.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 10728.260869565218,
      y: 0.10010910034179688,
    },
    {
      x: 10732.33695652174,
      y: 0.10010910034179688,
    },
    {
      x: 10952.445652173914,
      y: 0.10010910034179688,
    },
    {
      x: 10956.521739130436,
      y: 0.08017730712890625,
    },
    {
      x: 10960.597826086956,
      y: 0.0851593017578125,
    },
    {
      x: 10964.673913043478,
      y: 0.0851593017578125,
    },
    {
      x: 10989.13043478261,
      y: 0.0851593017578125,
    },
    {
      x: 10993.20652173913,
      y: 0.09014511108398438,
    },
    {
      x: 10997.282608695652,
      y: 0.09014511108398438,
    },
    {
      x: 11021.739130434782,
      y: 0.09014511108398438,
    },
    {
      x: 11025.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 11029.891304347826,
      y: 0.09512710571289062,
    },
    {
      x: 11086.95652173913,
      y: 0.09512710571289062,
    },
    {
      x: 11091.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 11095.108695652174,
      y: 0.0751953125,
    },
    {
      x: 11099.184782608696,
      y: 0.05525970458984375,
    },
    {
      x: 11103.260869565218,
      y: 0.045291900634765625,
    },
    {
      x: 11107.33695652174,
      y: 0.040309906005859375,
    },
    {
      x: 11111.413043478262,
      y: 0.03034210205078125,
    },
    {
      x: 11115.489130434782,
      y: 0.025356292724609375,
    },
    {
      x: 11119.565217391304,
      y: 0.015392303466796875,
    },
    {
      x: 11123.641304347826,
      y: 0.010406494140625,
    },
    {
      x: 11127.717391304348,
      y: 0.0004425048828125,
    },
    {
      x: 11131.79347826087,
      y: -0.004543304443359375,
    },
    {
      x: 11135.869565217392,
      y: -0.0145111083984375,
    },
    {
      x: 11139.945652173914,
      y: -0.01949310302734375,
    },
    {
      x: 11144.021739130436,
      y: -0.029460906982421875,
    },
    {
      x: 11148.097826086956,
      y: -0.034442901611328125,
    },
    {
      x: 11152.173913043478,
      y: -0.0394287109375,
    },
    {
      x: 11156.25,
      y: -0.049396514892578125,
    },
    {
      x: 11160.326086956522,
      y: -0.054378509521484375,
    },
    {
      x: 11164.402173913044,
      y: -0.05936431884765625,
    },
    {
      x: 11168.478260869566,
      y: -0.06932830810546875,
    },
    {
      x: 11172.554347826088,
      y: -0.074310302734375,
    },
    {
      x: 11176.63043478261,
      y: -0.06932830810546875,
    },
    {
      x: 11180.70652173913,
      y: -0.0643463134765625,
    },
    {
      x: 11184.782608695652,
      y: -0.054378509521484375,
    },
    {
      x: 11188.858695652174,
      y: -0.04441070556640625,
    },
    {
      x: 11192.934782608696,
      y: -0.029460906982421875,
    },
    {
      x: 11197.010869565218,
      y: -0.01949310302734375,
    },
    {
      x: 11201.08695652174,
      y: -0.00952911376953125,
    },
    {
      x: 11205.163043478262,
      y: 0.0004425048828125,
    },
    {
      x: 11209.239130434782,
      y: 0.010406494140625,
    },
    {
      x: 11213.315217391304,
      y: 0.020374298095703125,
    },
    {
      x: 11217.391304347826,
      y: 0.10010910034179688,
    },
    {
      x: 11221.467391304348,
      y: 0.10010910034179688,
    },
    {
      x: 11302.989130434782,
      y: 0.10010910034179688,
    },
    {
      x: 11307.065217391304,
      y: 0.125030517578125,
    },
    {
      x: 11311.141304347826,
      y: 0.18483352661132812,
    },
    {
      x: 11315.217391304348,
      y: 0.25460052490234375,
    },
    {
      x: 11319.29347826087,
      y: 0.3243675231933594,
    },
    {
      x: 11323.369565217392,
      y: 0.3991203308105469,
    },
    {
      x: 11335.597826086956,
      y: 0.6233787536621094,
    },
    {
      x: 11339.673913043478,
      y: 0.6931495666503906,
    },
    {
      x: 11343.75,
      y: 0.7629203796386719,
    },
    {
      x: 11347.826086956522,
      y: 0.7978019714355469,
    },
    {
      x: 11351.902173913044,
      y: 0.7629203796386719,
    },
    {
      x: 11355.978260869566,
      y: 0.6981315612792969,
    },
    {
      x: 11360.054347826088,
      y: 0.6233787536621094,
    },
    {
      x: 11364.13043478261,
      y: 0.5486259460449219,
    },
    {
      x: 11368.20652173913,
      y: 0.4688911437988281,
    },
    {
      x: 11372.282608695652,
      y: 0.3941383361816406,
    },
    {
      x: 11376.358695652174,
      y: 0.3193855285644531,
    },
    {
      x: 11380.434782608696,
      y: 0.24462890625,
    },
    {
      x: 11384.510869565218,
      y: 0.1698760986328125,
    },
    {
      x: 11388.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 11392.663043478262,
      y: 0.0851593017578125,
    },
    {
      x: 11396.739130434782,
      y: 0.0751953125,
    },
    {
      x: 11400.815217391304,
      y: 0.07020950317382812,
    },
    {
      x: 11404.891304347826,
      y: 0.07020950317382812,
    },
    {
      x: 11413.04347826087,
      y: 0.07020950317382812,
    },
    {
      x: 11417.119565217392,
      y: 0.0751953125,
    },
    {
      x: 11421.195652173914,
      y: 0.0751953125,
    },
    {
      x: 11445.652173913044,
      y: 0.0751953125,
    },
    {
      x: 11449.728260869566,
      y: 0.08017730712890625,
    },
    {
      x: 11453.804347826088,
      y: 0.08017730712890625,
    },
    {
      x: 11474.184782608696,
      y: 0.08017730712890625,
    },
    {
      x: 11478.260869565218,
      y: 0.10010910034179688,
    },
    {
      x: 11482.33695652174,
      y: 0.10010910034179688,
    },
    {
      x: 11735.054347826088,
      y: 0.10010910034179688,
    },
    {
      x: 11739.13043478261,
      y: -0.029460906982421875,
    },
    {
      x: 11743.20652173913,
      y: -0.01949310302734375,
    },
    {
      x: 11747.282608695652,
      y: -0.00952911376953125,
    },
    {
      x: 11751.358695652174,
      y: 0.0004425048828125,
    },
    {
      x: 11755.434782608696,
      y: 0.010406494140625,
    },
    {
      x: 11759.510869565218,
      y: 0.020374298095703125,
    },
    {
      x: 11767.663043478262,
      y: 0.040309906005859375,
    },
    {
      x: 11771.739130434782,
      y: 0.050273895263671875,
    },
    {
      x: 11775.815217391304,
      y: 0.06024169921875,
    },
    {
      x: 11788.04347826087,
      y: 0.09014511108398438,
    },
    {
      x: 11792.119565217392,
      y: 0.09512710571289062,
    },
    {
      x: 11796.195652173914,
      y: 0.10010910034179688,
    },
    {
      x: 11800.271739130436,
      y: 0.10010910034179688,
    },
    {
      x: 12110.054347826088,
      y: 0.10010910034179688,
    },
    {
      x: 12114.13043478261,
      y: 0.125030517578125,
    },
    {
      x: 12118.20652173913,
      y: 0.18483352661132812,
    },
    {
      x: 12122.282608695652,
      y: 0.25460052490234375,
    },
    {
      x: 12126.358695652174,
      y: 0.3243675231933594,
    },
    {
      x: 12130.434782608696,
      y: 0.3991203308105469,
    },
    {
      x: 12142.663043478262,
      y: 0.6233787536621094,
    },
    {
      x: 12146.739130434782,
      y: 0.6931495666503906,
    },
    {
      x: 12150.815217391304,
      y: 0.7629203796386719,
    },
    {
      x: 12154.891304347826,
      y: 0.7978019714355469,
    },
    {
      x: 12158.967391304348,
      y: 0.7629203796386719,
    },
    {
      x: 12163.04347826087,
      y: 0.6981315612792969,
    },
    {
      x: 12167.119565217392,
      y: 0.6233787536621094,
    },
    {
      x: 12171.195652173914,
      y: 0.5486259460449219,
    },
    {
      x: 12175.271739130436,
      y: 0.4688911437988281,
    },
    {
      x: 12179.347826086956,
      y: 0.3941383361816406,
    },
    {
      x: 12183.423913043478,
      y: 0.3193855285644531,
    },
    {
      x: 12187.5,
      y: 0.24462890625,
    },
    {
      x: 12191.576086956522,
      y: 0.1698760986328125,
    },
    {
      x: 12195.652173913044,
      y: 0.10509490966796875,
    },
    {
      x: 12199.728260869566,
      y: 0.0851593017578125,
    },
    {
      x: 12203.804347826088,
      y: 0.0751953125,
    },
    {
      x: 12207.88043478261,
      y: 0.07020950317382812,
    },
    {
      x: 12211.95652173913,
      y: 0.07020950317382812,
    },
    {
      x: 12220.108695652174,
      y: 0.07020950317382812,
    },
    {
      x: 12224.184782608696,
      y: 0.0751953125,
    },
    {
      x: 12228.260869565218,
      y: 0.0751953125,
    },
    {
      x: 12252.717391304348,
      y: 0.0751953125,
    },
    {
      x: 12256.79347826087,
      y: 0.08017730712890625,
    },
    {
      x: 12260.869565217392,
      y: 0.08017730712890625,
    },
    {
      x: 12285.326086956522,
      y: 0.08017730712890625,
    },
    {
      x: 12289.402173913044,
      y: 0.0851593017578125,
    },
    {
      x: 12293.478260869566,
      y: 0.0851593017578125,
    },
    {
      x: 12317.934782608696,
      y: 0.0851593017578125,
    },
    {
      x: 12322.010869565218,
      y: 0.09014511108398438,
    },
    {
      x: 12326.08695652174,
      y: 0.09014511108398438,
    },
    {
      x: 12350.54347826087,
      y: 0.09014511108398438,
    },
    {
      x: 12354.619565217392,
      y: 0.09512710571289062,
    },
    {
      x: 12358.695652173914,
      y: 0.09512710571289062,
    },
    {
      x: 12415.760869565218,
      y: 0.09512710571289062,
    },
    {
      x: 12419.83695652174,
      y: 0.09014511108398438,
    },
    {
      x: 12423.913043478262,
      y: 0.0751953125,
    },
    {
      x: 12427.989130434782,
      y: 0.05525970458984375,
    },
    {
      x: 12432.065217391304,
      y: 0.045291900634765625,
    },
    {
      x: 12436.141304347826,
      y: 0.040309906005859375,
    },
    {
      x: 12440.217391304348,
      y: 0.03034210205078125,
    },
    {
      x: 12444.29347826087,
      y: 0.025356292724609375,
    },
    {
      x: 12448.369565217392,
      y: 0.015392303466796875,
    },
    {
      x: 12452.445652173914,
      y: 0.010406494140625,
    },
    {
      x: 12456.521739130436,
      y: 0.0004425048828125,
    },
    {
      x: 12460.597826086956,
      y: -0.004543304443359375,
    },
    {
      x: 12464.673913043478,
      y: -0.0145111083984375,
    },
    {
      x: 12468.75,
      y: -0.01949310302734375,
    },
    {
      x: 12472.826086956522,
      y: -0.029460906982421875,
    },
    {
      x: 12476.902173913044,
      y: -0.034442901611328125,
    },
    {
      x: 12480.978260869566,
      y: -0.0394287109375,
    },
    {
      x: 12485.054347826088,
      y: -0.049396514892578125,
    },
    {
      x: 12489.13043478261,
      y: -0.054378509521484375,
    },
    {
      x: 12493.20652173913,
      y: -0.05936431884765625,
    },
    {
      x: 12497.282608695652,
      y: -0.06932830810546875,
    },
    {
      x: 12501.358695652174,
      y: -0.074310302734375,
    },
    {
      x: 12505.434782608696,
      y: -0.06932830810546875,
    },
    {
      x: 12509.510869565218,
      y: -0.0643463134765625,
    },
    {
      x: 12513.58695652174,
      y: -0.054378509521484375,
    },
    {
      x: 12517.663043478262,
      y: -0.04441070556640625,
    },
    {
      x: 12521.739130434784,
      y: -0.06932830810546875,
    },
    {
      x: 12525.815217391304,
      y: -0.074310302734375,
    },
    {
      x: 12529.891304347826,
      y: -0.06932830810546875,
    },
    {
      x: 12533.967391304348,
      y: -0.0643463134765625,
    },
    {
      x: 12538.04347826087,
      y: -0.054378509521484375,
    },
    {
      x: 12542.119565217392,
      y: -0.04441070556640625,
    },
    {
      x: 12546.195652173914,
      y: -0.029460906982421875,
    },
    {
      x: 12550.271739130436,
      y: -0.01949310302734375,
    },
    {
      x: 12554.347826086956,
      y: -0.00952911376953125,
    },
    {
      x: 12558.423913043478,
      y: 0.0004425048828125,
    },
    {
      x: 12562.5,
      y: 0.010406494140625,
    },
    {
      x: 12566.576086956522,
      y: 0.020374298095703125,
    },
    {
      x: 12574.728260869566,
      y: 0.040309906005859375,
    },
    {
      x: 12578.804347826088,
      y: 0.050273895263671875,
    },
    {
      x: 12582.88043478261,
      y: 0.06024169921875,
    },
    {
      x: 12595.108695652174,
      y: 0.09014511108398438,
    },
    {
      x: 12599.184782608696,
      y: 0.09512710571289062,
    },
    {
      x: 12603.260869565218,
      y: 0.10010910034179688,
    },
    {
      x: 12607.33695652174,
      y: 0.10010910034179688,
    },
    {
      x: 13177.989130434784,
      y: 0.10010910034179688,
    },
    {
      x: 13182.065217391304,
      y: 0.125030517578125,
    },
    {
      x: 13186.141304347826,
      y: 0.18483352661132812,
    },
    {
      x: 13190.217391304348,
      y: 0.25460052490234375,
    },
    {
      x: 13194.29347826087,
      y: 0.3243675231933594,
    },
    {
      x: 13198.369565217392,
      y: 0.3991203308105469,
    },
    {
      x: 13210.597826086956,
      y: 0.6233787536621094,
    },
    {
      x: 13214.673913043478,
      y: 0.6931495666503906,
    },
    {
      x: 13218.75,
      y: 0.7629203796386719,
    },
    {
      x: 13222.826086956522,
      y: 0.7978019714355469,
    },
    {
      x: 13226.902173913044,
      y: 0.7629203796386719,
    },
    {
      x: 13230.978260869566,
      y: 0.6981315612792969,
    },
    {
      x: 13235.054347826088,
      y: 0.6233787536621094,
    },
    {
      x: 13239.13043478261,
      y: 0.5486259460449219,
    },
    {
      x: 13243.20652173913,
      y: 0.4688911437988281,
    },
    {
      x: 13247.282608695652,
      y: 0.3941383361816406,
    },
    {
      x: 13251.358695652174,
      y: 0.3193855285644531,
    },
    {
      x: 13255.434782608696,
      y: 0.24462890625,
    },
    {
      x: 13259.510869565218,
      y: 0.1698760986328125,
    },
    {
      x: 13263.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 13267.663043478262,
      y: 0.0851593017578125,
    },
    {
      x: 13271.739130434784,
      y: 0.0751953125,
    },
    {
      x: 13275.815217391304,
      y: 0.07020950317382812,
    },
    {
      x: 13279.891304347826,
      y: 0.07020950317382812,
    },
    {
      x: 13288.04347826087,
      y: 0.07020950317382812,
    },
    {
      x: 13292.119565217392,
      y: 0.0751953125,
    },
    {
      x: 13296.195652173914,
      y: 0.0751953125,
    },
    {
      x: 13320.652173913044,
      y: 0.0751953125,
    },
    {
      x: 13324.728260869566,
      y: 0.08017730712890625,
    },
    {
      x: 13328.804347826088,
      y: 0.08017730712890625,
    },
    {
      x: 13353.260869565218,
      y: 0.08017730712890625,
    },
    {
      x: 13357.33695652174,
      y: 0.0851593017578125,
    },
    {
      x: 13361.413043478262,
      y: 0.0851593017578125,
    },
    {
      x: 13385.869565217392,
      y: 0.0851593017578125,
    },
    {
      x: 13389.945652173914,
      y: 0.09014511108398438,
    },
    {
      x: 13394.021739130436,
      y: 0.09014511108398438,
    },
    {
      x: 13418.478260869566,
      y: 0.09014511108398438,
    },
    {
      x: 13422.554347826088,
      y: 0.09512710571289062,
    },
    {
      x: 13426.63043478261,
      y: 0.09512710571289062,
    },
    {
      x: 13483.695652173914,
      y: 0.09512710571289062,
    },
    {
      x: 13487.771739130436,
      y: 0.09014511108398438,
    },
    {
      x: 13491.847826086956,
      y: 0.0751953125,
    },
    {
      x: 13495.923913043478,
      y: 0.05525970458984375,
    },
    {
      x: 13500,
      y: 0.045291900634765625,
    },
    {
      x: 13504.076086956522,
      y: 0.040309906005859375,
    },
    {
      x: 13508.152173913044,
      y: 0.03034210205078125,
    },
    {
      x: 13512.228260869566,
      y: 0.025356292724609375,
    },
    {
      x: 13516.304347826088,
      y: 0.015392303466796875,
    },
    {
      x: 13520.38043478261,
      y: 0.010406494140625,
    },
    {
      x: 13524.45652173913,
      y: 0.0004425048828125,
    },
    {
      x: 13528.532608695652,
      y: -0.004543304443359375,
    },
    {
      x: 13532.608695652174,
      y: -0.0145111083984375,
    },
    {
      x: 13536.684782608696,
      y: -0.01949310302734375,
    },
    {
      x: 13540.760869565218,
      y: -0.029460906982421875,
    },
    {
      x: 13544.83695652174,
      y: -0.034442901611328125,
    },
    {
      x: 13548.913043478262,
      y: -0.0394287109375,
    },
    {
      x: 13552.989130434784,
      y: -0.049396514892578125,
    },
    {
      x: 13557.065217391304,
      y: -0.054378509521484375,
    },
    {
      x: 13561.141304347826,
      y: -0.05936431884765625,
    },
    {
      x: 13565.217391304348,
      y: 0.10010910034179688,
    },
    {
      x: 13569.29347826087,
      y: 0.10010910034179688,
    },
    {
      x: 13822.010869565218,
      y: 0.10010910034179688,
    },
    {
      x: 13826.08695652174,
      y: -0.029460906982421875,
    },
    {
      x: 13830.163043478262,
      y: -0.034442901611328125,
    },
    {
      x: 13834.239130434784,
      y: -0.0394287109375,
    },
    {
      x: 13838.315217391304,
      y: -0.049396514892578125,
    },
    {
      x: 13842.391304347826,
      y: -0.054378509521484375,
    },
    {
      x: 13846.467391304348,
      y: -0.05936431884765625,
    },
    {
      x: 13850.54347826087,
      y: -0.06932830810546875,
    },
    {
      x: 13854.619565217392,
      y: -0.074310302734375,
    },
    {
      x: 13858.695652173914,
      y: -0.06932830810546875,
    },
    {
      x: 13862.771739130436,
      y: -0.0643463134765625,
    },
    {
      x: 13866.847826086956,
      y: -0.054378509521484375,
    },
    {
      x: 13870.923913043478,
      y: -0.04441070556640625,
    },
    {
      x: 13875,
      y: -0.029460906982421875,
    },
    {
      x: 13879.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 13883.152173913044,
      y: -0.00952911376953125,
    },
    {
      x: 13887.228260869566,
      y: 0.0004425048828125,
    },
    {
      x: 13891.304347826088,
      y: 0.010406494140625,
    },
    {
      x: 13895.38043478261,
      y: 0.020374298095703125,
    },
    {
      x: 13903.532608695652,
      y: 0.040309906005859375,
    },
    {
      x: 13907.608695652174,
      y: 0.050273895263671875,
    },
    {
      x: 13911.684782608696,
      y: 0.06024169921875,
    },
    {
      x: 13923.913043478262,
      y: 0.09014511108398438,
    },
    {
      x: 13927.989130434784,
      y: 0.09512710571289062,
    },
    {
      x: 13932.065217391304,
      y: 0.10010910034179688,
    },
    {
      x: 13936.141304347826,
      y: 0.10010910034179688,
    },
    {
      x: 14082.88043478261,
      y: 0.10010910034179688,
    },
    {
      x: 14086.95652173913,
      y: 0.07020950317382812,
    },
    {
      x: 14091.032608695652,
      y: 0.07020950317382812,
    },
    {
      x: 14095.108695652174,
      y: 0.07020950317382812,
    },
    {
      x: 14099.184782608696,
      y: 0.0751953125,
    },
    {
      x: 14103.260869565218,
      y: 0.0751953125,
    },
    {
      x: 14127.717391304348,
      y: 0.0751953125,
    },
    {
      x: 14131.79347826087,
      y: 0.08017730712890625,
    },
    {
      x: 14135.869565217392,
      y: 0.08017730712890625,
    },
    {
      x: 14160.326086956522,
      y: 0.08017730712890625,
    },
    {
      x: 14164.402173913044,
      y: 0.0851593017578125,
    },
    {
      x: 14168.478260869566,
      y: 0.0851593017578125,
    },
    {
      x: 14192.934782608696,
      y: 0.0851593017578125,
    },
    {
      x: 14197.010869565218,
      y: 0.09014511108398438,
    },
    {
      x: 14201.08695652174,
      y: 0.09014511108398438,
    },
    {
      x: 14225.54347826087,
      y: 0.09014511108398438,
    },
    {
      x: 14229.619565217392,
      y: 0.09512710571289062,
    },
    {
      x: 14233.695652173914,
      y: 0.09512710571289062,
    },
    {
      x: 14290.760869565218,
      y: 0.09512710571289062,
    },
    {
      x: 14294.83695652174,
      y: 0.09014511108398438,
    },
    {
      x: 14298.913043478262,
      y: 0.0751953125,
    },
    {
      x: 14302.989130434784,
      y: 0.05525970458984375,
    },
    {
      x: 14307.065217391304,
      y: 0.045291900634765625,
    },
    {
      x: 14311.141304347826,
      y: 0.040309906005859375,
    },
    {
      x: 14315.217391304348,
      y: 0.03034210205078125,
    },
    {
      x: 14319.29347826087,
      y: 0.025356292724609375,
    },
    {
      x: 14323.369565217392,
      y: 0.015392303466796875,
    },
    {
      x: 14327.445652173914,
      y: 0.010406494140625,
    },
    {
      x: 14331.521739130436,
      y: 0.0004425048828125,
    },
    {
      x: 14335.597826086956,
      y: -0.004543304443359375,
    },
    {
      x: 14339.673913043478,
      y: -0.0145111083984375,
    },
    {
      x: 14343.75,
      y: -0.01949310302734375,
    },
    {
      x: 14347.826086956522,
      y: 0.10010910034179688,
    },
    {
      x: 14351.902173913044,
      y: 0.10010910034179688,
    },
    {
      x: 14506.79347826087,
      y: 0.10010910034179688,
    },
    {
      x: 14510.869565217392,
      y: 0.125030517578125,
    },
    {
      x: 14514.945652173914,
      y: 0.18483352661132812,
    },
    {
      x: 14519.021739130436,
      y: 0.25460052490234375,
    },
    {
      x: 14523.097826086956,
      y: 0.3243675231933594,
    },
    {
      x: 14527.173913043478,
      y: 0.3991203308105469,
    },
    {
      x: 14539.402173913044,
      y: 0.6233787536621094,
    },
    {
      x: 14543.478260869566,
      y: 0.6931495666503906,
    },
    {
      x: 14547.554347826088,
      y: 0.7629203796386719,
    },
    {
      x: 14551.63043478261,
      y: 0.7978019714355469,
    },
    {
      x: 14555.70652173913,
      y: 0.7629203796386719,
    },
    {
      x: 14559.782608695652,
      y: 0.6981315612792969,
    },
    {
      x: 14563.858695652174,
      y: 0.6233787536621094,
    },
    {
      x: 14567.934782608696,
      y: 0.5486259460449219,
    },
    {
      x: 14572.010869565218,
      y: 0.4688911437988281,
    },
    {
      x: 14576.08695652174,
      y: 0.3941383361816406,
    },
    {
      x: 14580.163043478262,
      y: 0.3193855285644531,
    },
    {
      x: 14584.239130434784,
      y: 0.24462890625,
    },
    {
      x: 14588.315217391304,
      y: 0.1698760986328125,
    },
    {
      x: 14592.391304347826,
      y: 0.10509490966796875,
    },
    {
      x: 14596.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 14600.54347826087,
      y: 0.0751953125,
    },
    {
      x: 14604.619565217392,
      y: 0.07020950317382812,
    },
    {
      x: 14608.695652173914,
      y: 0.015392303466796875,
    },
    {
      x: 14612.771739130436,
      y: 0.010406494140625,
    },
    {
      x: 14616.847826086956,
      y: 0.0004425048828125,
    },
    {
      x: 14620.923913043478,
      y: -0.004543304443359375,
    },
    {
      x: 14625,
      y: -0.0145111083984375,
    },
    {
      x: 14629.076086956522,
      y: -0.01949310302734375,
    },
    {
      x: 14633.152173913044,
      y: -0.029460906982421875,
    },
    {
      x: 14637.228260869566,
      y: -0.034442901611328125,
    },
    {
      x: 14641.304347826088,
      y: -0.0394287109375,
    },
    {
      x: 14645.38043478261,
      y: -0.049396514892578125,
    },
    {
      x: 14649.45652173913,
      y: -0.054378509521484375,
    },
    {
      x: 14653.532608695652,
      y: -0.05936431884765625,
    },
    {
      x: 14657.608695652174,
      y: -0.06932830810546875,
    },
    {
      x: 14661.684782608696,
      y: -0.074310302734375,
    },
    {
      x: 14665.760869565218,
      y: -0.06932830810546875,
    },
    {
      x: 14669.83695652174,
      y: -0.0643463134765625,
    },
    {
      x: 14673.913043478262,
      y: -0.054378509521484375,
    },
    {
      x: 14677.989130434784,
      y: -0.04441070556640625,
    },
    {
      x: 14682.065217391304,
      y: -0.029460906982421875,
    },
    {
      x: 14686.141304347826,
      y: -0.01949310302734375,
    },
    {
      x: 14690.217391304348,
      y: -0.00952911376953125,
    },
    {
      x: 14694.29347826087,
      y: 0.0004425048828125,
    },
    {
      x: 14698.369565217392,
      y: 0.010406494140625,
    },
    {
      x: 14702.445652173914,
      y: 0.020374298095703125,
    },
    {
      x: 14710.597826086956,
      y: 0.040309906005859375,
    },
    {
      x: 14714.673913043478,
      y: 0.050273895263671875,
    },
    {
      x: 14718.75,
      y: 0.06024169921875,
    },
    {
      x: 14730.978260869566,
      y: 0.09014511108398438,
    },
    {
      x: 14735.054347826088,
      y: 0.09512710571289062,
    },
    {
      x: 14739.13043478261,
      y: 0.10010910034179688,
    },
    {
      x: 14743.20652173913,
      y: 0.10010910034179688,
    },
    {
      x: 14865.489130434784,
      y: 0.10010910034179688,
    },
    {
      x: 14869.565217391304,
      y: 0.24462890625,
    },
    {
      x: 14873.641304347826,
      y: 0.1698760986328125,
    },
    {
      x: 14877.717391304348,
      y: 0.10509490966796875,
    },
    {
      x: 14881.79347826087,
      y: 0.0851593017578125,
    },
    {
      x: 14885.869565217392,
      y: 0.0751953125,
    },
    {
      x: 14889.945652173914,
      y: 0.07020950317382812,
    },
    {
      x: 14894.021739130436,
      y: 0.07020950317382812,
    },
    {
      x: 14902.173913043478,
      y: 0.07020950317382812,
    },
    {
      x: 14906.25,
      y: 0.0751953125,
    },
    {
      x: 14910.326086956522,
      y: 0.0751953125,
    },
    {
      x: 14934.782608695652,
      y: 0.0751953125,
    },
    {
      x: 14938.858695652174,
      y: 0.08017730712890625,
    },
    {
      x: 14942.934782608696,
      y: 0.08017730712890625,
    },
    {
      x: 14967.391304347826,
      y: 0.08017730712890625,
    },
    {
      x: 14971.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 14975.54347826087,
      y: 0.0851593017578125,
    },
    {
      x: 15000,
      y: 0.0851593017578125,
    },
    {
      x: 15004.076086956522,
      y: 0.09014511108398438,
    },
    {
      x: 15008.152173913044,
      y: 0.09014511108398438,
    },
    {
      x: 15032.608695652174,
      y: 0.09014511108398438,
    },
    {
      x: 15036.684782608696,
      y: 0.09512710571289062,
    },
    {
      x: 15040.760869565218,
      y: 0.09512710571289062,
    },
    {
      x: 15097.826086956522,
      y: 0.09512710571289062,
    },
    {
      x: 15101.902173913044,
      y: 0.09014511108398438,
    },
    {
      x: 15105.978260869566,
      y: 0.0751953125,
    },
    {
      x: 15110.054347826088,
      y: 0.05525970458984375,
    },
    {
      x: 15114.13043478261,
      y: 0.045291900634765625,
    },
    {
      x: 15118.20652173913,
      y: 0.040309906005859375,
    },
    {
      x: 15122.282608695652,
      y: 0.03034210205078125,
    },
    {
      x: 15126.358695652174,
      y: 0.025356292724609375,
    },
    {
      x: 15130.434782608696,
      y: 0.10010910034179688,
    },
    {
      x: 15134.510869565218,
      y: 0.10010910034179688,
    },
    {
      x: 15574.728260869566,
      y: 0.10010910034179688,
    },
    {
      x: 15578.804347826088,
      y: 0.125030517578125,
    },
    {
      x: 15582.88043478261,
      y: 0.18483352661132812,
    },
    {
      x: 15586.95652173913,
      y: 0.25460052490234375,
    },
    {
      x: 15591.032608695652,
      y: 0.3243675231933594,
    },
    {
      x: 15595.108695652174,
      y: 0.3991203308105469,
    },
    {
      x: 15607.33695652174,
      y: 0.6233787536621094,
    },
    {
      x: 15611.413043478262,
      y: 0.6931495666503906,
    },
    {
      x: 15615.489130434784,
      y: 0.7629203796386719,
    },
    {
      x: 15619.565217391304,
      y: 0.7978019714355469,
    },
    {
      x: 15623.641304347826,
      y: 0.7629203796386719,
    },
    {
      x: 15627.717391304348,
      y: 0.6981315612792969,
    },
    {
      x: 15631.79347826087,
      y: 0.6233787536621094,
    },
    {
      x: 15635.869565217392,
      y: 0.5486259460449219,
    },
    {
      x: 15639.945652173914,
      y: 0.4688911437988281,
    },
    {
      x: 15644.021739130436,
      y: 0.3941383361816406,
    },
    {
      x: 15648.097826086958,
      y: 0.3193855285644531,
    },
    {
      x: 15652.173913043478,
      y: 0.10010910034179688,
    },
    {
      x: 15656.25,
      y: 0.10010910034179688,
    },
    {
      x: 15908.967391304348,
      y: 0.10010910034179688,
    },
    {
      x: 15913.04347826087,
      y: 0.0751953125,
    },
    {
      x: 15917.119565217392,
      y: 0.05525970458984375,
    },
    {
      x: 15921.195652173914,
      y: 0.045291900634765625,
    },
    {
      x: 15925.271739130436,
      y: 0.040309906005859375,
    },
    {
      x: 15929.347826086958,
      y: 0.03034210205078125,
    },
    {
      x: 15933.423913043478,
      y: 0.025356292724609375,
    },
    {
      x: 15937.5,
      y: 0.015392303466796875,
    },
    {
      x: 15941.576086956522,
      y: 0.010406494140625,
    },
    {
      x: 15945.652173913044,
      y: 0.0004425048828125,
    },
    {
      x: 15949.728260869566,
      y: -0.004543304443359375,
    },
    {
      x: 15953.804347826088,
      y: -0.0145111083984375,
    },
    {
      x: 15957.88043478261,
      y: -0.01949310302734375,
    },
    {
      x: 15961.95652173913,
      y: -0.029460906982421875,
    },
    {
      x: 15966.032608695652,
      y: -0.034442901611328125,
    },
    {
      x: 15970.108695652174,
      y: -0.0394287109375,
    },
    {
      x: 15974.184782608696,
      y: -0.049396514892578125,
    },
    {
      x: 15978.260869565218,
      y: -0.054378509521484375,
    },
    {
      x: 15982.33695652174,
      y: -0.05936431884765625,
    },
    {
      x: 15986.413043478262,
      y: -0.06932830810546875,
    },
    {
      x: 15990.489130434784,
      y: -0.074310302734375,
    },
    {
      x: 15994.565217391304,
      y: -0.06932830810546875,
    },
    {
      x: 15998.641304347826,
      y: -0.0643463134765625,
    },
    {
      x: 16002.717391304348,
      y: -0.054378509521484375,
    },
    {
      x: 16006.79347826087,
      y: -0.04441070556640625,
    },
    {
      x: 16010.869565217392,
      y: -0.029460906982421875,
    },
    {
      x: 16014.945652173914,
      y: -0.01949310302734375,
    },
    {
      x: 16019.021739130436,
      y: -0.00952911376953125,
    },
    {
      x: 16023.097826086958,
      y: 0.0004425048828125,
    },
    {
      x: 16027.173913043478,
      y: 0.010406494140625,
    },
    {
      x: 16031.25,
      y: 0.020374298095703125,
    },
    {
      x: 16039.402173913044,
      y: 0.040309906005859375,
    },
    {
      x: 16043.478260869566,
      y: 0.050273895263671875,
    },
    {
      x: 16047.554347826088,
      y: 0.06024169921875,
    },
    {
      x: 16059.782608695652,
      y: 0.09014511108398438,
    },
    {
      x: 16063.858695652174,
      y: 0.09512710571289062,
    },
    {
      x: 16067.934782608696,
      y: 0.10010910034179688,
    },
    {
      x: 16072.010869565218,
      y: 0.10010910034179688,
    },
    {
      x: 16169.83695652174,
      y: 0.10010910034179688,
    },
    {
      x: 16173.913043478262,
      y: 0.6981315612792969,
    },
    {
      x: 16177.989130434784,
      y: 0.6233787536621094,
    },
    {
      x: 16182.065217391304,
      y: 0.5486259460449219,
    },
    {
      x: 16186.141304347826,
      y: 0.4688911437988281,
    },
    {
      x: 16190.217391304348,
      y: 0.3941383361816406,
    },
    {
      x: 16194.29347826087,
      y: 0.3193855285644531,
    },
    {
      x: 16198.369565217392,
      y: 0.24462890625,
    },
    {
      x: 16202.445652173914,
      y: 0.1698760986328125,
    },
    {
      x: 16206.521739130436,
      y: 0.10509490966796875,
    },
    {
      x: 16210.597826086958,
      y: 0.0851593017578125,
    },
    {
      x: 16214.673913043478,
      y: 0.0751953125,
    },
    {
      x: 16218.75,
      y: 0.07020950317382812,
    },
    {
      x: 16222.826086956522,
      y: 0.07020950317382812,
    },
    {
      x: 16230.978260869566,
      y: 0.07020950317382812,
    },
    {
      x: 16235.054347826088,
      y: 0.0751953125,
    },
    {
      x: 16239.13043478261,
      y: 0.0751953125,
    },
    {
      x: 16263.58695652174,
      y: 0.0751953125,
    },
    {
      x: 16267.663043478262,
      y: 0.08017730712890625,
    },
    {
      x: 16271.739130434784,
      y: 0.08017730712890625,
    },
    {
      x: 16296.195652173914,
      y: 0.08017730712890625,
    },
    {
      x: 16300.271739130436,
      y: 0.0851593017578125,
    },
    {
      x: 16304.347826086958,
      y: 0.0851593017578125,
    },
    {
      x: 16328.804347826088,
      y: 0.0851593017578125,
    },
    {
      x: 16332.88043478261,
      y: 0.09014511108398438,
    },
    {
      x: 16336.95652173913,
      y: 0.09014511108398438,
    },
    {
      x: 16361.413043478262,
      y: 0.09014511108398438,
    },
    {
      x: 16365.489130434784,
      y: 0.09512710571289062,
    },
    {
      x: 16369.565217391304,
      y: 0.09512710571289062,
    },
    {
      x: 16426.630434782608,
      y: 0.09512710571289062,
    },
    {
      x: 16430.706521739132,
      y: 0.09014511108398438,
    },
    {
      x: 16434.782608695652,
      y: 0.10010910034179688,
    },
    {
      x: 16438.858695652176,
      y: 0.10010910034179688,
    },
    {
      x: 16642.66304347826,
      y: 0.10010910034179688,
    },
    {
      x: 16646.739130434784,
      y: 0.125030517578125,
    },
    {
      x: 16650.815217391304,
      y: 0.18483352661132812,
    },
    {
      x: 16654.891304347828,
      y: 0.25460052490234375,
    },
    {
      x: 16658.967391304348,
      y: 0.3243675231933594,
    },
    {
      x: 16663.04347826087,
      y: 0.3991203308105469,
    },
    {
      x: 16675.271739130436,
      y: 0.6233787536621094,
    },
    {
      x: 16679.347826086956,
      y: 0.6931495666503906,
    },
    {
      x: 16683.42391304348,
      y: 0.7629203796386719,
    },
    {
      x: 16687.5,
      y: 0.7978019714355469,
    },
    {
      x: 16691.576086956524,
      y: 0.7629203796386719,
    },
    {
      x: 16695.652173913044,
      y: 0.09512710571289062,
    },
    {
      x: 16699.728260869564,
      y: 0.09512710571289062,
    },
    {
      x: 16711.956521739132,
      y: 0.09512710571289062,
    },
    {
      x: 16716.032608695652,
      y: 0.09014511108398438,
    },
    {
      x: 16720.108695652176,
      y: 0.0751953125,
    },
    {
      x: 16724.184782608696,
      y: 0.05525970458984375,
    },
    {
      x: 16728.260869565216,
      y: 0.045291900634765625,
    },
    {
      x: 16732.33695652174,
      y: 0.040309906005859375,
    },
    {
      x: 16736.41304347826,
      y: 0.03034210205078125,
    },
    {
      x: 16740.489130434784,
      y: 0.025356292724609375,
    },
    {
      x: 16744.565217391304,
      y: 0.015392303466796875,
    },
    {
      x: 16748.641304347828,
      y: 0.010406494140625,
    },
    {
      x: 16752.717391304348,
      y: 0.0004425048828125,
    },
    {
      x: 16756.79347826087,
      y: -0.004543304443359375,
    },
    {
      x: 16760.869565217392,
      y: -0.0145111083984375,
    },
    {
      x: 16764.945652173912,
      y: -0.01949310302734375,
    },
    {
      x: 16769.021739130436,
      y: -0.029460906982421875,
    },
    {
      x: 16773.097826086956,
      y: -0.034442901611328125,
    },
    {
      x: 16777.17391304348,
      y: -0.0394287109375,
    },
    {
      x: 16781.25,
      y: -0.049396514892578125,
    },
    {
      x: 16785.326086956524,
      y: -0.054378509521484375,
    },
    {
      x: 16789.402173913044,
      y: -0.05936431884765625,
    },
    {
      x: 16793.478260869564,
      y: -0.06932830810546875,
    },
    {
      x: 16797.554347826088,
      y: -0.074310302734375,
    },
    {
      x: 16801.630434782608,
      y: -0.06932830810546875,
    },
    {
      x: 16805.706521739132,
      y: -0.0643463134765625,
    },
    {
      x: 16809.782608695652,
      y: -0.054378509521484375,
    },
    {
      x: 16813.858695652176,
      y: -0.04441070556640625,
    },
    {
      x: 16817.934782608696,
      y: -0.029460906982421875,
    },
    {
      x: 16822.010869565216,
      y: -0.01949310302734375,
    },
    {
      x: 16826.08695652174,
      y: -0.00952911376953125,
    },
    {
      x: 16830.16304347826,
      y: 0.0004425048828125,
    },
    {
      x: 16834.239130434784,
      y: 0.010406494140625,
    },
    {
      x: 16838.315217391304,
      y: 0.020374298095703125,
    },
    {
      x: 16846.467391304348,
      y: 0.040309906005859375,
    },
    {
      x: 16850.54347826087,
      y: 0.050273895263671875,
    },
    {
      x: 16854.619565217392,
      y: 0.06024169921875,
    },
    {
      x: 16866.847826086956,
      y: 0.09014511108398438,
    },
    {
      x: 16870.92391304348,
      y: 0.09512710571289062,
    },
    {
      x: 16875,
      y: 0.10010910034179688,
    },
    {
      x: 16879.076086956524,
      y: 0.10010910034179688,
    },
    {
      x: 16952.445652173912,
      y: 0.10010910034179688,
    },
    {
      x: 16956.521739130436,
      y: 0.5486259460449219,
    },
    {
      x: 16960.597826086956,
      y: 0.6233787536621094,
    },
    {
      x: 16964.67391304348,
      y: 0.6931495666503906,
    },
    {
      x: 16968.75,
      y: 0.7629203796386719,
    },
    {
      x: 16972.826086956524,
      y: 0.7978019714355469,
    },
    {
      x: 16976.902173913044,
      y: 0.7629203796386719,
    },
    {
      x: 16980.978260869564,
      y: 0.6981315612792969,
    },
    {
      x: 16985.054347826088,
      y: 0.6233787536621094,
    },
    {
      x: 16989.130434782608,
      y: 0.5486259460449219,
    },
    {
      x: 16993.206521739132,
      y: 0.4688911437988281,
    },
    {
      x: 16997.282608695652,
      y: 0.3941383361816406,
    },
    {
      x: 17001.358695652176,
      y: 0.3193855285644531,
    },
    {
      x: 17005.434782608696,
      y: 0.24462890625,
    },
    {
      x: 17009.510869565216,
      y: 0.1698760986328125,
    },
    {
      x: 17013.58695652174,
      y: 0.10509490966796875,
    },
    {
      x: 17017.66304347826,
      y: 0.0851593017578125,
    },
    {
      x: 17021.739130434784,
      y: 0.0751953125,
    },
    {
      x: 17025.815217391304,
      y: 0.07020950317382812,
    },
    {
      x: 17029.891304347828,
      y: 0.07020950317382812,
    },
    {
      x: 17038.04347826087,
      y: 0.07020950317382812,
    },
    {
      x: 17042.119565217392,
      y: 0.0751953125,
    },
    {
      x: 17046.195652173912,
      y: 0.0751953125,
    },
    {
      x: 17070.652173913044,
      y: 0.0751953125,
    },
    {
      x: 17074.728260869564,
      y: 0.08017730712890625,
    },
    {
      x: 17078.804347826088,
      y: 0.08017730712890625,
    },
    {
      x: 17103.260869565216,
      y: 0.08017730712890625,
    },
    {
      x: 17107.33695652174,
      y: 0.0851593017578125,
    },
    {
      x: 17111.41304347826,
      y: 0.0851593017578125,
    },
    {
      x: 17135.869565217392,
      y: 0.0851593017578125,
    },
    {
      x: 17139.945652173912,
      y: 0.09014511108398438,
    },
    {
      x: 17144.021739130436,
      y: 0.09014511108398438,
    },
    {
      x: 17168.478260869564,
      y: 0.09014511108398438,
    },
    {
      x: 17172.554347826088,
      y: 0.09512710571289062,
    },
    {
      x: 17176.630434782608,
      y: 0.09512710571289062,
    },
    {
      x: 17213.315217391304,
      y: 0.09512710571289062,
    },
    {
      x: 17217.391304347828,
      y: 0.10010910034179688,
    },
    {
      x: 17221.467391304348,
      y: 0.10010910034179688,
    },
    {
      x: 17710.597826086956,
      y: 0.10010910034179688,
    },
    {
      x: 17714.67391304348,
      y: 0.125030517578125,
    },
    {
      x: 17718.75,
      y: 0.18483352661132812,
    },
    {
      x: 17722.826086956524,
      y: 0.25460052490234375,
    },
    {
      x: 17726.902173913044,
      y: 0.3243675231933594,
    },
    {
      x: 17730.978260869564,
      y: 0.3991203308105469,
    },
    {
      x: 17735.054347826088,
      y: 0.4738731384277344,
    },
    {
      x: 17739.130434782608,
      y: 0.10010910034179688,
    },
    {
      x: 17743.206521739132,
      y: 0.10010910034179688,
    },
    {
      x: 17995.92391304348,
      y: 0.10010910034179688,
    },
    {
      x: 18000,
      y: 0.12004470825195312,
    },
    {
      x: 18004.076086956524,
      y: 0.17984771728515625,
    },
    {
      x: 18008.152173913044,
      y: 0.24961471557617188,
    },
    {
      x: 18012.228260869564,
      y: 0.3243675231933594,
    },
    {
      x: 18020.380434782608,
      y: 0.4738731384277344,
    },
    {
      x: 18024.456521739132,
      y: 0.5436439514160156,
    },
    {
      x: 18028.532608695652,
      y: 0.6183967590332031,
    },
    {
      x: 18032.608695652176,
      y: 0.6931495666503906,
    },
    {
      x: 18036.684782608696,
      y: 0.7629203796386719,
    },
    {
      x: 18040.760869565216,
      y: 0.7978019714355469,
    },
    {
      x: 18044.83695652174,
      y: 0.7579345703125,
    },
    {
      x: 18048.91304347826,
      y: 0.6931495666503906,
    },
    {
      x: 18052.989130434784,
      y: 0.6183967590332031,
    },
    {
      x: 18061.141304347828,
      y: 0.4688911437988281,
    },
    {
      x: 18065.217391304348,
      y: 0.38915252685546875,
    },
    {
      x: 18069.29347826087,
      y: 0.31439971923828125,
    },
    {
      x: 18077.445652173912,
      y: 0.16489410400390625,
    },
    {
      x: 18081.521739130436,
      y: 0.10010910034179688,
    },
    {
      x: 18085.597826086956,
      y: 0.08017730712890625,
    },
    {
      x: 18089.67391304348,
      y: 0.07020950317382812,
    },
    {
      x: 18093.75,
      y: 0.06522369384765625,
    },
    {
      x: 18097.826086956524,
      y: 0.07020950317382812,
    },
    {
      x: 18101.902173913044,
      y: 0.07020950317382812,
    },
    {
      x: 18126.358695652176,
      y: 0.07020950317382812,
    },
    {
      x: 18130.434782608696,
      y: 0.0751953125,
    },
    {
      x: 18134.510869565216,
      y: 0.0751953125,
    },
    {
      x: 18158.967391304348,
      y: 0.0751953125,
    },
    {
      x: 18163.04347826087,
      y: 0.08017730712890625,
    },
    {
      x: 18167.119565217392,
      y: 0.08017730712890625,
    },
    {
      x: 18191.576086956524,
      y: 0.08017730712890625,
    },
    {
      x: 18195.652173913044,
      y: 0.0851593017578125,
    },
    {
      x: 18199.728260869564,
      y: 0.0851593017578125,
    },
    {
      x: 18224.184782608696,
      y: 0.0851593017578125,
    },
    {
      x: 18228.260869565216,
      y: 0.09014511108398438,
    },
    {
      x: 18232.33695652174,
      y: 0.09014511108398438,
    },
    {
      x: 18256.79347826087,
      y: 0.09014511108398438,
    },
    {
      x: 18260.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 18264.945652173912,
      y: 0.09512710571289062,
    },
    {
      x: 18301.630434782608,
      y: 0.09512710571289062,
    },
    {
      x: 18305.706521739132,
      y: 0.09014511108398438,
    },
    {
      x: 18309.782608695652,
      y: 0.0751953125,
    },
    {
      x: 18313.858695652176,
      y: 0.05525970458984375,
    },
    {
      x: 18317.934782608696,
      y: 0.045291900634765625,
    },
    {
      x: 18322.010869565216,
      y: 0.040309906005859375,
    },
    {
      x: 18326.08695652174,
      y: 0.03034210205078125,
    },
    {
      x: 18330.16304347826,
      y: 0.025356292724609375,
    },
    {
      x: 18334.239130434784,
      y: 0.015392303466796875,
    },
    {
      x: 18338.315217391304,
      y: 0.010406494140625,
    },
    {
      x: 18342.391304347828,
      y: 0.0004425048828125,
    },
    {
      x: 18346.467391304348,
      y: -0.004543304443359375,
    },
    {
      x: 18350.54347826087,
      y: -0.0145111083984375,
    },
    {
      x: 18354.619565217392,
      y: -0.01949310302734375,
    },
    {
      x: 18358.695652173912,
      y: -0.029460906982421875,
    },
    {
      x: 18362.771739130436,
      y: -0.034442901611328125,
    },
    {
      x: 18366.847826086956,
      y: -0.0394287109375,
    },
    {
      x: 18370.92391304348,
      y: -0.049396514892578125,
    },
    {
      x: 18375,
      y: -0.054378509521484375,
    },
    {
      x: 18379.076086956524,
      y: -0.05936431884765625,
    },
    {
      x: 18383.152173913044,
      y: -0.06932830810546875,
    },
    {
      x: 18387.228260869564,
      y: -0.074310302734375,
    },
    {
      x: 18391.304347826088,
      y: -0.06932830810546875,
    },
    {
      x: 18395.380434782608,
      y: -0.0643463134765625,
    },
    {
      x: 18399.456521739132,
      y: -0.054378509521484375,
    },
    {
      x: 18403.532608695652,
      y: -0.04441070556640625,
    },
    {
      x: 18407.608695652176,
      y: -0.029460906982421875,
    },
    {
      x: 18411.684782608696,
      y: -0.01949310302734375,
    },
    {
      x: 18415.760869565216,
      y: -0.00952911376953125,
    },
    {
      x: 18419.83695652174,
      y: 0.0004425048828125,
    },
    {
      x: 18423.91304347826,
      y: 0.010406494140625,
    },
    {
      x: 18427.989130434784,
      y: 0.020374298095703125,
    },
    {
      x: 18436.141304347828,
      y: 0.040309906005859375,
    },
    {
      x: 18440.217391304348,
      y: 0.050273895263671875,
    },
    {
      x: 18444.29347826087,
      y: 0.06024169921875,
    },
    {
      x: 18456.521739130436,
      y: 0.09014511108398438,
    },
    {
      x: 18460.597826086956,
      y: 0.09512710571289062,
    },
    {
      x: 18464.67391304348,
      y: 0.10010910034179688,
    },
    {
      x: 18468.75,
      y: 0.10010910034179688,
    },
    {
      x: 18517.66304347826,
      y: 0.10010910034179688,
    },
    {
      x: 18521.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 18525.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 19108.695652173912,
      y: 0.09512710571289062,
    },
    {
      x: 19112.771739130436,
      y: 0.09014511108398438,
    },
    {
      x: 19116.847826086956,
      y: 0.07020950317382812,
    },
    {
      x: 19120.92391304348,
      y: 0.050273895263671875,
    },
    {
      x: 19125,
      y: 0.040309906005859375,
    },
    {
      x: 19129.076086956524,
      y: 0.0353240966796875,
    },
    {
      x: 19133.152173913044,
      y: 0.025356292724609375,
    },
    {
      x: 19137.228260869564,
      y: 0.020374298095703125,
    },
    {
      x: 19141.304347826088,
      y: 0.010406494140625,
    },
    {
      x: 19145.380434782608,
      y: 0.00542449951171875,
    },
    {
      x: 19149.456521739132,
      y: -0.004543304443359375,
    },
    {
      x: 19153.532608695652,
      y: -0.00952911376953125,
    },
    {
      x: 19157.608695652176,
      y: -0.01949310302734375,
    },
    {
      x: 19161.684782608696,
      y: -0.024478912353515625,
    },
    {
      x: 19165.760869565216,
      y: -0.034442901611328125,
    },
    {
      x: 19169.83695652174,
      y: -0.0394287109375,
    },
    {
      x: 19173.91304347826,
      y: -0.04441070556640625,
    },
    {
      x: 19177.989130434784,
      y: -0.054378509521484375,
    },
    {
      x: 19182.065217391304,
      y: -0.05936431884765625,
    },
    {
      x: 19186.141304347828,
      y: -0.0643463134765625,
    },
    {
      x: 19190.217391304348,
      y: -0.074310302734375,
    },
    {
      x: 19194.29347826087,
      y: -0.07929611206054688,
    },
    {
      x: 19198.369565217392,
      y: -0.074310302734375,
    },
    {
      x: 19202.445652173912,
      y: -0.06932830810546875,
    },
    {
      x: 19206.521739130436,
      y: -0.05936431884765625,
    },
    {
      x: 19210.597826086956,
      y: -0.04441070556640625,
    },
    {
      x: 19214.67391304348,
      y: -0.034442901611328125,
    },
    {
      x: 19218.75,
      y: -0.024478912353515625,
    },
    {
      x: 19222.826086956524,
      y: -0.0145111083984375,
    },
    {
      x: 19230.978260869564,
      y: 0.00542449951171875,
    },
    {
      x: 19235.054347826088,
      y: 0.020374298095703125,
    },
    {
      x: 19239.130434782608,
      y: 0.025356292724609375,
    },
    {
      x: 19243.206521739132,
      y: 0.0353240966796875,
    },
    {
      x: 19251.358695652176,
      y: 0.05525970458984375,
    },
    {
      x: 19255.434782608696,
      y: 0.06522369384765625,
    },
    {
      x: 19259.510869565216,
      y: 0.0751953125,
    },
    {
      x: 19263.58695652174,
      y: 0.0851593017578125,
    },
    {
      x: 19267.66304347826,
      y: 0.09014511108398438,
    },
    {
      x: 19271.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 19275.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 19324.728260869564,
      y: 0.09512710571289062,
    },
    {
      x: 19328.804347826088,
      y: 0.12004470825195312,
    },
    {
      x: 19332.880434782608,
      y: 0.17984771728515625,
    },
    {
      x: 19336.956521739132,
      y: 0.24961471557617188,
    },
    {
      x: 19341.032608695652,
      y: 0.3243675231933594,
    },
    {
      x: 19349.184782608696,
      y: 0.4738731384277344,
    },
    {
      x: 19353.260869565216,
      y: 0.5436439514160156,
    },
    {
      x: 19357.33695652174,
      y: 0.6183967590332031,
    },
    {
      x: 19361.41304347826,
      y: 0.6931495666503906,
    },
    {
      x: 19365.489130434784,
      y: 0.7629203796386719,
    },
    {
      x: 19369.565217391304,
      y: 0.7978019714355469,
    },
    {
      x: 19373.641304347828,
      y: 0.7579345703125,
    },
    {
      x: 19377.717391304348,
      y: 0.6981315612792969,
    },
    {
      x: 19381.79347826087,
      y: 0.6233787536621094,
    },
    {
      x: 19385.869565217392,
      y: 0.5436439514160156,
    },
    {
      x: 19389.945652173912,
      y: 0.4688911437988281,
    },
    {
      x: 19398.097826086956,
      y: 0.3193855285644531,
    },
    {
      x: 19402.17391304348,
      y: 0.24462890625,
    },
    {
      x: 19406.25,
      y: 0.1698760986328125,
    },
    {
      x: 19410.326086956524,
      y: 0.10509490966796875,
    },
    {
      x: 19414.402173913044,
      y: 0.0851593017578125,
    },
    {
      x: 19418.478260869564,
      y: 0.0751953125,
    },
    {
      x: 19422.554347826088,
      y: 0.07020950317382812,
    },
    {
      x: 19426.630434782608,
      y: 0.0751953125,
    },
    {
      x: 19430.706521739132,
      y: 0.0751953125,
    },
    {
      x: 19455.16304347826,
      y: 0.0751953125,
    },
    {
      x: 19459.239130434784,
      y: 0.08017730712890625,
    },
    {
      x: 19463.315217391304,
      y: 0.08017730712890625,
    },
    {
      x: 19487.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 19491.847826086956,
      y: 0.0851593017578125,
    },
    {
      x: 19495.92391304348,
      y: 0.0851593017578125,
    },
    {
      x: 19520.380434782608,
      y: 0.0851593017578125,
    },
    {
      x: 19524.456521739132,
      y: 0.09014511108398438,
    },
    {
      x: 19528.532608695652,
      y: 0.09014511108398438,
    },
    {
      x: 19552.989130434784,
      y: 0.09014511108398438,
    },
    {
      x: 19557.065217391304,
      y: 0.09512710571289062,
    },
    {
      x: 19561.141304347828,
      y: 0.09512710571289062,
    },
    {
      x: 20131.79347826087,
      y: 0.09512710571289062,
    },
    {
      x: 20135.869565217392,
      y: 0.12004470825195312,
    },
    {
      x: 20139.945652173912,
      y: 0.17984771728515625,
    },
    {
      x: 20144.021739130436,
      y: 0.24961471557617188,
    },
    {
      x: 20148.097826086956,
      y: 0.3243675231933594,
    },
    {
      x: 20156.25,
      y: 0.4738731384277344,
    },
    {
      x: 20160.326086956524,
      y: 0.5436439514160156,
    },
    {
      x: 20164.402173913044,
      y: 0.6183967590332031,
    },
    {
      x: 20168.478260869564,
      y: 0.6931495666503906,
    },
    {
      x: 20172.554347826088,
      y: 0.7629203796386719,
    },
    {
      x: 20176.630434782608,
      y: 0.7978019714355469,
    },
    {
      x: 20180.706521739132,
      y: 0.7579345703125,
    },
    {
      x: 20184.782608695652,
      y: 0.6981315612792969,
    },
    {
      x: 20188.858695652176,
      y: 0.6233787536621094,
    },
    {
      x: 20192.934782608696,
      y: 0.5436439514160156,
    },
    {
      x: 20197.01086956522,
      y: 0.4688911437988281,
    },
    {
      x: 20205.16304347826,
      y: 0.3193855285644531,
    },
    {
      x: 20209.239130434784,
      y: 0.24462890625,
    },
    {
      x: 20213.315217391304,
      y: 0.1698760986328125,
    },
    {
      x: 20217.391304347828,
      y: 0.10509490966796875,
    },
    {
      x: 20221.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 20225.54347826087,
      y: 0.0751953125,
    },
    {
      x: 20229.619565217392,
      y: 0.07020950317382812,
    },
    {
      x: 20233.695652173912,
      y: 0.0751953125,
    },
    {
      x: 20237.771739130436,
      y: 0.0751953125,
    },
    {
      x: 20262.228260869564,
      y: 0.0751953125,
    },
    {
      x: 20266.304347826088,
      y: 0.08017730712890625,
    },
    {
      x: 20270.380434782608,
      y: 0.08017730712890625,
    },
    {
      x: 20294.83695652174,
      y: 0.08017730712890625,
    },
    {
      x: 20298.91304347826,
      y: 0.0851593017578125,
    },
    {
      x: 20302.989130434784,
      y: 0.0851593017578125,
    },
    {
      x: 20327.445652173912,
      y: 0.0851593017578125,
    },
    {
      x: 20331.521739130436,
      y: 0.09014511108398438,
    },
    {
      x: 20335.597826086956,
      y: 0.09014511108398438,
    },
    {
      x: 20360.054347826088,
      y: 0.09014511108398438,
    },
    {
      x: 20364.130434782608,
      y: 0.09512710571289062,
    },
    {
      x: 20368.206521739132,
      y: 0.09512710571289062,
    },
    {
      x: 20437.5,
      y: 0.09512710571289062,
    },
    {
      x: 20441.576086956524,
      y: 0.09014511108398438,
    },
    {
      x: 20445.652173913044,
      y: 0.07020950317382812,
    },
    {
      x: 20449.728260869564,
      y: 0.050273895263671875,
    },
    {
      x: 20453.804347826088,
      y: 0.040309906005859375,
    },
    {
      x: 20457.880434782608,
      y: 0.0353240966796875,
    },
    {
      x: 20461.956521739132,
      y: 0.025356292724609375,
    },
    {
      x: 20466.032608695652,
      y: 0.020374298095703125,
    },
    {
      x: 20470.108695652176,
      y: 0.010406494140625,
    },
    {
      x: 20474.184782608696,
      y: 0.00542449951171875,
    },
    {
      x: 20478.26086956522,
      y: -0.004543304443359375,
    },
    {
      x: 20482.33695652174,
      y: -0.00952911376953125,
    },
    {
      x: 20486.41304347826,
      y: -0.01949310302734375,
    },
    {
      x: 20490.489130434784,
      y: -0.024478912353515625,
    },
    {
      x: 20494.565217391304,
      y: -0.034442901611328125,
    },
    {
      x: 20498.641304347828,
      y: -0.0394287109375,
    },
    {
      x: 20502.717391304348,
      y: -0.04441070556640625,
    },
    {
      x: 20506.79347826087,
      y: -0.054378509521484375,
    },
    {
      x: 20510.869565217392,
      y: -0.05936431884765625,
    },
    {
      x: 20514.945652173912,
      y: -0.0643463134765625,
    },
    {
      x: 20519.021739130436,
      y: -0.074310302734375,
    },
    {
      x: 20523.097826086956,
      y: -0.07929611206054688,
    },
    {
      x: 20527.17391304348,
      y: -0.074310302734375,
    },
    {
      x: 20531.25,
      y: -0.06932830810546875,
    },
    {
      x: 20535.326086956524,
      y: -0.05936431884765625,
    },
    {
      x: 20539.402173913044,
      y: -0.04441070556640625,
    },
    {
      x: 20543.478260869564,
      y: -0.034442901611328125,
    },
    {
      x: 20547.554347826088,
      y: -0.024478912353515625,
    },
    {
      x: 20551.630434782608,
      y: -0.0145111083984375,
    },
    {
      x: 20559.782608695652,
      y: 0.00542449951171875,
    },
    {
      x: 20563.858695652176,
      y: 0.020374298095703125,
    },
    {
      x: 20567.934782608696,
      y: 0.025356292724609375,
    },
    {
      x: 20572.01086956522,
      y: 0.0353240966796875,
    },
    {
      x: 20580.16304347826,
      y: 0.05525970458984375,
    },
    {
      x: 20584.239130434784,
      y: 0.06522369384765625,
    },
    {
      x: 20588.315217391304,
      y: 0.0751953125,
    },
    {
      x: 20592.391304347828,
      y: 0.0851593017578125,
    },
    {
      x: 20596.467391304348,
      y: 0.09014511108398438,
    },
    {
      x: 20600.54347826087,
      y: 0.09512710571289062,
    },
    {
      x: 20604.619565217392,
      y: 0.09512710571289062,
    },
    {
      x: 20865.489130434784,
      y: 0.09512710571289062,
    },
    {
      x: 20869.565217391304,
      y: 0.0851593017578125,
    },
    {
      x: 20873.641304347828,
      y: 0.0851593017578125,
    },
    {
      x: 20877.717391304348,
      y: 0.09014511108398438,
    },
    {
      x: 20881.79347826087,
      y: 0.09014511108398438,
    },
    {
      x: 20906.25,
      y: 0.09014511108398438,
    },
    {
      x: 20910.326086956524,
      y: 0.09512710571289062,
    },
    {
      x: 20914.402173913044,
      y: 0.09512710571289062,
    },
    {
      x: 20983.695652173912,
      y: 0.09512710571289062,
    },
    {
      x: 20987.771739130436,
      y: 0.09014511108398438,
    },
    {
      x: 20991.847826086956,
      y: 0.07020950317382812,
    },
    {
      x: 20995.92391304348,
      y: 0.050273895263671875,
    },
    {
      x: 21000,
      y: 0.040309906005859375,
    },
    {
      x: 21004.076086956524,
      y: 0.0353240966796875,
    },
    {
      x: 21008.152173913044,
      y: 0.025356292724609375,
    },
    {
      x: 21012.228260869564,
      y: 0.020374298095703125,
    },
    {
      x: 21016.304347826088,
      y: 0.010406494140625,
    },
    {
      x: 21020.380434782608,
      y: 0.00542449951171875,
    },
    {
      x: 21024.456521739132,
      y: -0.004543304443359375,
    },
    {
      x: 21028.532608695652,
      y: -0.00952911376953125,
    },
    {
      x: 21032.608695652176,
      y: -0.01949310302734375,
    },
    {
      x: 21036.684782608696,
      y: -0.024478912353515625,
    },
    {
      x: 21040.76086956522,
      y: -0.034442901611328125,
    },
    {
      x: 21044.83695652174,
      y: -0.0394287109375,
    },
    {
      x: 21048.91304347826,
      y: -0.04441070556640625,
    },
    {
      x: 21052.989130434784,
      y: -0.054378509521484375,
    },
    {
      x: 21057.065217391304,
      y: -0.05936431884765625,
    },
    {
      x: 21061.141304347828,
      y: -0.0643463134765625,
    },
    {
      x: 21065.217391304348,
      y: -0.074310302734375,
    },
    {
      x: 21069.29347826087,
      y: -0.07929611206054688,
    },
    {
      x: 21073.369565217392,
      y: -0.074310302734375,
    },
    {
      x: 21077.445652173912,
      y: -0.06932830810546875,
    },
    {
      x: 21081.521739130436,
      y: -0.05936431884765625,
    },
    {
      x: 21085.597826086956,
      y: -0.04441070556640625,
    },
    {
      x: 21089.67391304348,
      y: -0.034442901611328125,
    },
    {
      x: 21093.75,
      y: -0.024478912353515625,
    },
    {
      x: 21097.826086956524,
      y: -0.0145111083984375,
    },
    {
      x: 21105.978260869564,
      y: 0.00542449951171875,
    },
    {
      x: 21110.054347826088,
      y: 0.020374298095703125,
    },
    {
      x: 21114.130434782608,
      y: 0.025356292724609375,
    },
    {
      x: 21118.206521739132,
      y: 0.0353240966796875,
    },
    {
      x: 21126.358695652176,
      y: 0.05525970458984375,
    },
    {
      x: 21130.434782608696,
      y: 0.06522369384765625,
    },
    {
      x: 21134.51086956522,
      y: 0.0751953125,
    },
    {
      x: 21138.58695652174,
      y: 0.0851593017578125,
    },
    {
      x: 21142.66304347826,
      y: 0.09014511108398438,
    },
    {
      x: 21146.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 21150.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 21460.597826086956,
      y: 0.09512710571289062,
    },
    {
      x: 21464.67391304348,
      y: 0.12004470825195312,
    },
    {
      x: 21468.75,
      y: 0.17984771728515625,
    },
    {
      x: 21472.826086956524,
      y: 0.24961471557617188,
    },
    {
      x: 21476.902173913044,
      y: 0.3243675231933594,
    },
    {
      x: 21485.054347826088,
      y: 0.4738731384277344,
    },
    {
      x: 21489.130434782608,
      y: 0.5436439514160156,
    },
    {
      x: 21493.206521739132,
      y: 0.6183967590332031,
    },
    {
      x: 21497.282608695652,
      y: 0.6931495666503906,
    },
    {
      x: 21501.358695652176,
      y: 0.7629203796386719,
    },
    {
      x: 21505.434782608696,
      y: 0.7978019714355469,
    },
    {
      x: 21509.51086956522,
      y: 0.7579345703125,
    },
    {
      x: 21513.58695652174,
      y: 0.6981315612792969,
    },
    {
      x: 21517.66304347826,
      y: 0.6233787536621094,
    },
    {
      x: 21521.739130434784,
      y: 0.5436439514160156,
    },
    {
      x: 21525.815217391304,
      y: 0.4688911437988281,
    },
    {
      x: 21533.967391304348,
      y: 0.3193855285644531,
    },
    {
      x: 21538.04347826087,
      y: 0.24462890625,
    },
    {
      x: 21542.119565217392,
      y: 0.1698760986328125,
    },
    {
      x: 21546.195652173912,
      y: 0.10509490966796875,
    },
    {
      x: 21550.271739130436,
      y: 0.0851593017578125,
    },
    {
      x: 21554.347826086956,
      y: 0.0751953125,
    },
    {
      x: 21558.42391304348,
      y: 0.07020950317382812,
    },
    {
      x: 21562.5,
      y: 0.0751953125,
    },
    {
      x: 21566.576086956524,
      y: 0.0751953125,
    },
    {
      x: 21591.032608695652,
      y: 0.0751953125,
    },
    {
      x: 21595.108695652176,
      y: 0.08017730712890625,
    },
    {
      x: 21599.184782608696,
      y: 0.08017730712890625,
    },
    {
      x: 21623.641304347828,
      y: 0.08017730712890625,
    },
    {
      x: 21627.717391304348,
      y: 0.0851593017578125,
    },
    {
      x: 21631.79347826087,
      y: 0.0851593017578125,
    },
    {
      x: 21648.097826086956,
      y: 0.0851593017578125,
    },
    {
      x: 21652.17391304348,
      y: 0.09512710571289062,
    },
    {
      x: 21656.25,
      y: 0.09512710571289062,
    },
    {
      x: 22006.79347826087,
      y: 0.09512710571289062,
    },
    {
      x: 22010.869565217392,
      y: 0.12004470825195312,
    },
    {
      x: 22014.945652173912,
      y: 0.17984771728515625,
    },
    {
      x: 22019.021739130436,
      y: 0.24961471557617188,
    },
    {
      x: 22023.097826086956,
      y: 0.3243675231933594,
    },
    {
      x: 22031.25,
      y: 0.4738731384277344,
    },
    {
      x: 22035.326086956524,
      y: 0.5436439514160156,
    },
    {
      x: 22039.402173913044,
      y: 0.6183967590332031,
    },
    {
      x: 22043.478260869564,
      y: 0.6931495666503906,
    },
    {
      x: 22047.554347826088,
      y: 0.7629203796386719,
    },
    {
      x: 22051.630434782608,
      y: 0.7978019714355469,
    },
    {
      x: 22055.706521739132,
      y: 0.7579345703125,
    },
    {
      x: 22059.782608695652,
      y: 0.6981315612792969,
    },
    {
      x: 22063.858695652176,
      y: 0.6233787536621094,
    },
    {
      x: 22067.934782608696,
      y: 0.5436439514160156,
    },
    {
      x: 22072.01086956522,
      y: 0.4688911437988281,
    },
    {
      x: 22080.16304347826,
      y: 0.3193855285644531,
    },
    {
      x: 22084.239130434784,
      y: 0.24462890625,
    },
    {
      x: 22088.315217391304,
      y: 0.1698760986328125,
    },
    {
      x: 22092.391304347828,
      y: 0.10509490966796875,
    },
    {
      x: 22096.467391304348,
      y: 0.0851593017578125,
    },
    {
      x: 22100.54347826087,
      y: 0.0751953125,
    },
    {
      x: 22104.619565217392,
      y: 0.07020950317382812,
    },
    {
      x: 22108.695652173912,
      y: 0.0751953125,
    },
    {
      x: 22112.771739130436,
      y: 0.0751953125,
    },
    {
      x: 22137.228260869564,
      y: 0.0751953125,
    },
    {
      x: 22141.304347826088,
      y: 0.08017730712890625,
    },
    {
      x: 22145.380434782608,
      y: 0.08017730712890625,
    },
    {
      x: 22169.83695652174,
      y: 0.08017730712890625,
    },
    {
      x: 22173.91304347826,
      y: 0.09512710571289062,
    },
    {
      x: 22177.989130434784,
      y: 0.09512710571289062,
    },
    {
      x: 22430.706521739132,
      y: 0.09512710571289062,
    },
    {
      x: 22434.782608695652,
      y: 0.00542449951171875,
    },
    {
      x: 22438.858695652176,
      y: 0.020374298095703125,
    },
    {
      x: 22442.934782608696,
      y: 0.025356292724609375,
    },
    {
      x: 22447.01086956522,
      y: 0.0353240966796875,
    },
    {
      x: 22455.16304347826,
      y: 0.05525970458984375,
    },
    {
      x: 22459.239130434784,
      y: 0.06522369384765625,
    },
    {
      x: 22463.315217391304,
      y: 0.0751953125,
    },
    {
      x: 22467.391304347828,
      y: 0.0851593017578125,
    },
    {
      x: 22471.467391304348,
      y: 0.09014511108398438,
    },
    {
      x: 22475.54347826087,
      y: 0.09512710571289062,
    },
    {
      x: 22479.619565217392,
      y: 0.09512710571289062,
    },
    {
      x: 22691.576086956524,
      y: 0.09512710571289062,
    },
    {
      x: 22695.652173913044,
      y: 0.0851593017578125,
    },
    {
      x: 22699.728260869564,
      y: 0.0851593017578125,
    },
    {
      x: 22724.184782608696,
      y: 0.0851593017578125,
    },
    {
      x: 22728.26086956522,
      y: 0.09014511108398438,
    },
    {
      x: 22732.33695652174,
      y: 0.09014511108398438,
    },
    {
      x: 22756.79347826087,
      y: 0.09014511108398438,
    },
    {
      x: 22760.869565217392,
      y: 0.09512710571289062,
    },
    {
      x: 22764.945652173912,
      y: 0.09512710571289062,
    },
    {
      x: 22834.239130434784,
      y: 0.09512710571289062,
    },
    {
      x: 22838.315217391304,
      y: 0.09014511108398438,
    },
    {
      x: 22842.391304347828,
      y: 0.07020950317382812,
    },
    {
      x: 22846.467391304348,
      y: 0.050273895263671875,
    },
    {
      x: 22850.54347826087,
      y: 0.040309906005859375,
    },
    {
      x: 22854.619565217392,
      y: 0.0353240966796875,
    },
    {
      x: 22858.695652173912,
      y: 0.025356292724609375,
    },
    {
      x: 22862.771739130436,
      y: 0.020374298095703125,
    },
    {
      x: 22866.847826086956,
      y: 0.010406494140625,
    },
    {
      x: 22870.92391304348,
      y: 0.00542449951171875,
    },
    {
      x: 22875,
      y: -0.004543304443359375,
    },
    {
      x: 22879.076086956524,
      y: -0.00952911376953125,
    },
    {
      x: 22883.152173913044,
      y: -0.01949310302734375,
    },
    {
      x: 22887.228260869564,
      y: -0.024478912353515625,
    },
    {
      x: 22891.304347826088,
      y: -0.034442901611328125,
    },
    {
      x: 22895.380434782608,
      y: -0.0394287109375,
    },
    {
      x: 22899.456521739132,
      y: -0.04441070556640625,
    },
    {
      x: 22903.532608695652,
      y: -0.054378509521484375,
    },
    {
      x: 22907.608695652176,
      y: -0.05936431884765625,
    },
    {
      x: 22911.684782608696,
      y: -0.0643463134765625,
    },
    {
      x: 22915.76086956522,
      y: -0.074310302734375,
    },
    {
      x: 22919.83695652174,
      y: -0.07929611206054688,
    },
    {
      x: 22923.91304347826,
      y: -0.074310302734375,
    },
    {
      x: 22927.989130434784,
      y: -0.06932830810546875,
    },
    {
      x: 22932.065217391304,
      y: -0.05936431884765625,
    },
    {
      x: 22936.141304347828,
      y: -0.04441070556640625,
    },
    {
      x: 22940.217391304348,
      y: -0.034442901611328125,
    },
    {
      x: 22944.29347826087,
      y: -0.024478912353515625,
    },
    {
      x: 22948.369565217392,
      y: -0.0145111083984375,
    },
    {
      x: 22952.445652173912,
      y: -0.004543304443359375,
    },
    {
      x: 22956.521739130436,
      y: -0.05936431884765625,
    },
    {
      x: 22960.597826086956,
      y: -0.04441070556640625,
    },
    {
      x: 22964.67391304348,
      y: -0.034442901611328125,
    },
    {
      x: 22968.75,
      y: -0.024478912353515625,
    },
    {
      x: 22972.826086956524,
      y: -0.0145111083984375,
    },
    {
      x: 22980.978260869564,
      y: 0.00542449951171875,
    },
    {
      x: 22985.054347826088,
      y: 0.020374298095703125,
    },
    {
      x: 22989.130434782608,
      y: 0.025356292724609375,
    },
    {
      x: 22993.206521739132,
      y: 0.0353240966796875,
    },
    {
      x: 23001.358695652176,
      y: 0.05525970458984375,
    },
    {
      x: 23005.434782608696,
      y: 0.06522369384765625,
    },
    {
      x: 23009.51086956522,
      y: 0.0751953125,
    },
    {
      x: 23013.58695652174,
      y: 0.0851593017578125,
    },
    {
      x: 23017.66304347826,
      y: 0.09014511108398438,
    },
    {
      x: 23021.739130434784,
      y: 0.09512710571289062,
    },
    {
      x: 23025.815217391304,
      y: 0.09512710571289062,
    },
    {
      x: 23213.315217391304,
      y: 0.09512710571289062,
    },
    {
      x: 23217.391304347828,
      y: 0.08017730712890625,
    },
    {
      x: 23221.467391304348,
      y: 0.08017730712890625,
    },
    {
      x: 23237.771739130436,
      y: 0.08017730712890625,
    },
    {
      x: 23241.847826086956,
      y: 0.0851593017578125,
    },
    {
      x: 23245.92391304348,
      y: 0.0851593017578125,
    },
    {
      x: 23270.380434782608,
      y: 0.0851593017578125,
    },
    {
      x: 23274.456521739132,
      y: 0.09014511108398438,
    },
    {
      x: 23278.532608695652,
      y: 0.09014511108398438,
    },
    {
      x: 23302.989130434784,
      y: 0.09014511108398438,
    },
    {
      x: 23307.065217391304,
      y: 0.09512710571289062,
    },
    {
      x: 23311.141304347828,
      y: 0.09512710571289062,
    },
    {
      x: 23380.434782608696,
      y: 0.09512710571289062,
    },
    {
      x: 23384.51086956522,
      y: 0.09014511108398438,
    },
    {
      x: 23388.58695652174,
      y: 0.07020950317382812,
    },
    {
      x: 23392.66304347826,
      y: 0.050273895263671875,
    },
    {
      x: 23396.739130434784,
      y: 0.040309906005859375,
    },
    {
      x: 23400.815217391304,
      y: 0.0353240966796875,
    },
    {
      x: 23404.891304347828,
      y: 0.025356292724609375,
    },
    {
      x: 23408.967391304348,
      y: 0.020374298095703125,
    },
    {
      x: 23413.04347826087,
      y: 0.010406494140625,
    },
    {
      x: 23417.119565217392,
      y: 0.00542449951171875,
    },
    {
      x: 23421.195652173912,
      y: -0.004543304443359375,
    },
    {
      x: 23425.271739130436,
      y: -0.00952911376953125,
    },
    {
      x: 23429.347826086956,
      y: -0.01949310302734375,
    },
    {
      x: 23433.42391304348,
      y: -0.024478912353515625,
    },
    {
      x: 23437.5,
      y: -0.034442901611328125,
    },
    {
      x: 23441.576086956524,
      y: -0.0394287109375,
    },
    {
      x: 23445.652173913044,
      y: -0.04441070556640625,
    },
    {
      x: 23449.728260869564,
      y: -0.054378509521484375,
    },
    {
      x: 23453.804347826088,
      y: -0.05936431884765625,
    },
    {
      x: 23457.880434782608,
      y: -0.0643463134765625,
    },
    {
      x: 23461.956521739132,
      y: -0.074310302734375,
    },
    {
      x: 23466.032608695652,
      y: -0.07929611206054688,
    },
    {
      x: 23470.108695652176,
      y: -0.074310302734375,
    },
    {
      x: 23474.184782608696,
      y: -0.06932830810546875,
    },
    {
      x: 23478.26086956522,
      y: 0.09512710571289062,
    },
    {
      x: 23482.33695652174,
      y: 0.09512710571289062,
    },
    {
      x: 23596.467391304348,
      y: 0.09512710571289062,
    },
    {
      x: 23600.54347826087,
      y: 0.12004470825195312,
    },
    {
      x: 23604.619565217392,
      y: 0.17984771728515625,
    },
    {
      x: 23608.695652173912,
      y: 0.24961471557617188,
    },
    {
      x: 23612.771739130436,
      y: 0.3243675231933594,
    },
    {
      x: 23620.92391304348,
      y: 0.4738731384277344,
    },
    {
      x: 23625,
      y: 0.5436439514160156,
    },
    {
      x: 23629.076086956524,
      y: 0.6183967590332031,
    },
    {
      x: 23633.152173913044,
      y: 0.6931495666503906,
    },
    {
      x: 23637.228260869564,
      y: 0.7629203796386719,
    },
    {
      x: 23641.304347826088,
      y: 0.7978019714355469,
    },
    {
      x: 23645.380434782608,
      y: 0.7579345703125,
    },
    {
      x: 23649.456521739132,
      y: 0.6981315612792969,
    },
    {
      x: 23653.532608695652,
      y: 0.6233787536621094,
    },
    {
      x: 23657.608695652176,
      y: 0.5436439514160156,
    },
    {
      x: 23661.684782608696,
      y: 0.4688911437988281,
    },
    {
      x: 23669.83695652174,
      y: 0.3193855285644531,
    },
    {
      x: 23673.91304347826,
      y: 0.24462890625,
    },
    {
      x: 23677.989130434784,
      y: 0.1698760986328125,
    },
    {
      x: 23682.065217391304,
      y: 0.10509490966796875,
    },
    {
      x: 23686.141304347828,
      y: 0.0851593017578125,
    },
    {
      x: 23690.217391304348,
      y: 0.0751953125,
    },
    {
      x: 23694.29347826087,
      y: 0.07020950317382812,
    },
    {
      x: 23698.369565217392,
      y: 0.0751953125,
    },
    {
      x: 23702.445652173912,
      y: 0.0751953125,
    },
    {
      x: 23726.902173913044,
      y: 0.0751953125,
    },
    {
      x: 23730.978260869564,
      y: 0.08017730712890625,
    },
    {
      x: 23735.054347826088,
      y: 0.08017730712890625,
    },
    {
      x: 23739.130434782608,
      y: 0.09512710571289062,
    },
    {
      x: 23743.206521739132,
      y: 0.09512710571289062,
    },
    {
      x: 24000,
      y: 0.09512710571289062,
    },
  ],
};
