import React from 'react';

import { Container } from './styles';

export default function Overlay({
  isVisible, onClick,
}) {
  return (
    <Container isVisible={isVisible} onClick={onClick} />
  );
}
