import { validityEmail } from './validityEmail';

export function userHospitalsIsValid(userHospitals, hasOnlyOneHospital) {
  if (!userHospitals) return { isHospitalsValid: false, missingField: '' };
  if (userHospitals.length === 0) return { isHospitalsValid: false, missingField: '' };

  const result = userHospitals.find((hospital) => {
    const {
      label,
      value,
      pepId,
    } = hospital;

    if (hasOnlyOneHospital) {
      const isValid = !!(value && pepId);
      return isValid === false;
    }
    const isValid = !!(label && value && pepId);
    return isValid === false;
  });

  return {
    isHospitalsValid: !result,
    missingField: result?.value,
  };
}

export function formValidation(payload, hasOnlyOneHospital = false) {
  if (!payload) return false;

  const {
    fullName,
    username,
    userPosition,
    phoneNumber,
    email,
    userHospitals,
    role,
    proxyEmailAdmin,
  } = payload;

  const { isHospitalsValid } = userHospitalsIsValid(userHospitals, hasOnlyOneHospital);
  const checkPhoneLength = () => {
    const checkMask = phoneNumber.indexOf('-');
    if (checkMask === -1) return 11;
    return 13;
  };

  const phoneNumberIsValid = phoneNumber?.length === 0 || phoneNumber?.length >= checkPhoneLength();

  const missingFields = [];

  if (!fullName) {
    missingFields.push('fullName');
  }
  if (!username) {
    missingFields.push('userName');
  }
  if (!userPosition) {
    missingFields.push('userPosition');
  }
  if ((!email || !validityEmail(email)) && !proxyEmailAdmin) {
    missingFields.push('email');
  }
  if (!isHospitalsValid) {
    missingFields.push('userHospitals');
  }
  if (!role) {
    missingFields.push('role');
  }
  if (!phoneNumberIsValid) {
    missingFields.push('phoneNumber');
  }

  const isValid = missingFields.length === 0;

  return { isValid, missingFields };
}
