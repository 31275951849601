import { gql } from '@apollo/client';
import client from 'services/apollo';

const ON_ERROR = gql`
mutation OnFrontendError($payload: FrontendError) {
  onFrontendError(payload: $payload)
}
`;

export function logError({
  applicationError,
  communicationError,
}) {
  const apolloClient = client();
  const payload = {
    timeStamp: new Date().toISOString(),
    applicationError,
    communicationError,
  };

  if (!process.env.REACT_APP_IS_DEMO) {
    apolloClient.mutate({
      mutation: ON_ERROR,
      variables: { payload },
    });
  }
}
