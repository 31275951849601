import {
  combineReducers, createStore, applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import translateReducer from 'components/layout/SelectLanguage/reducer';
import exitModalReducer from 'components/layout/ExitModal/reducer';
import loginReducer from 'components/forms/Login/reducer';
import userManagementReducer from 'pages/UserManagement/redux/reducer';
import chartMonitorReducer from 'pages/History/redux/reducers';
import themeReducer from './themes/reducer';

const reducer = combineReducers({
  theme: themeReducer,
  translate: translateReducer,
  login: loginReducer,
  showExitModal: exitModalReducer,
  chartMonitor: chartMonitorReducer,
  userManagement: userManagementReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
);

export default store;
