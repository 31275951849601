import styled, { css } from 'styled-components';
import { fontSize } from 'config/constants';

const fullScreen = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(0,0,0,0.3);
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto;
  ${({ isFullScreen }) => (isFullScreen && fullScreen)}

  svg {
    fill: ${({ theme }) => theme.colors.primary.w200};
    width: ${({ imgWidth }) => (imgWidth || 'auto')};
    height: ${({ imgHeight }) => (imgHeight || 'auto')};
  }
`;

export const Title = styled.h3`
  font-size: ${fontSize.sm};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.w000};
  text-transform: uppercase;
`;
