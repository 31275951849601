export const getAllHospitalsGroup = {
  GetAllHospitalsGroup: () => ({
    getAllHospitalsGroup: [
      {
        id: '1',
        groupName: 'Rede Carenet',
      },
      {
        id: '2',
        groupName: 'Rede São Camilo',
      },
      {
        id: '3',
        groupName: 'Rede Dasa',
      },
      {
        id: '849',
        groupName: 'Rede Unimed',
      },
      {
        id: '871',
        groupName: 'Rede São Carmenio',
      },
      {
        id: '872',
        groupName: 'Rede Hope Clinic',
      },
      {
        id: '516',
        groupName: 'Rede Monte Negro',
      },
      {
        id: '539',
        groupName: 'Rede Alan Turin',
      },
    ],
  }),
  UpsertHospitalGroup: ({ onCompleted }) => [() => onCompleted(), { loading: false }],
  GetAllHospitals: () => ({
    getAllHospitals: [
      {
        hospitalGroup: 'Rede Carenet',
        id: '1400',
        hospitalName: 'Hospital Carenet 4.0 [HG-2]',
        city: 'São Paulo',
        district: 'Moema',
        updatedAt: new Date().toISOString(),
        region: 'SP',
      },
      {
        hospitalGroup: 'Rede Carenet',
        id: '1500',
        hospitalName: 'Hospital Carenet 4.1 [HG-2]',
        city: 'Fortaleza',
        district: 'Aldeota',
        updatedAt: new Date().toISOString(),
        region: 'CE',
      },
    ],
  }),
};
