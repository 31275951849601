import React from 'react';

import {
  Container, Input, Span,
} from './styles';

export default function Checkbox({
  text, onChange, onClick, margin, value, id, className, readOnly, checked, isControlled, cy,
}) {
  return (
    <Container onClick={onClick} margin={margin}>
      <Input
        onChange={onChange}
        value={value}
        id={id}
        className={className}
        disabled={readOnly}
        checked={isControlled && checked}
        data-cy={cy}
      />
      {text && <Span>{text}</Span>}
    </Container>
  );
}

Checkbox.defaultProps = {
  checked: false,
  readOnly: false,
  text: '',
  margin: '0',
  value: '',
  onChange: () => { /** */ },
  onClick: () => { /** */ },
};
