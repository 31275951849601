import { generateAir } from './dataGenerator';

export const air = {
  GetAirDashboard: () => ({
    getAirDashboard: {
      ventMode: 'VS_PRVC',
      flowTrigger: 'ASSISTED',
      flowControl: 'VOLUME',
      automode: null,
      trigger: null,
      dashboard: [
        {
          name: 'cstat',
          value: [
            {
              value: '100',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '78',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '57',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '50',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '60',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '82',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '103',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '110',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '78',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '57',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '50',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '60',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '82',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '103',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '110',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '78',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '57',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '50',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '60',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '82',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '103',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '110',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '78',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '57',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '50',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '60',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '109',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'vte',
          value: [
            {
              value: '19',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '17',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '17',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '17',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '17',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '16',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'pplat',
          value: [
            {
              value: '141',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '123',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '106',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '109',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '127',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '144',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '150',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '141',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '123',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '106',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '109',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '127',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '144',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '150',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '141',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '123',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '106',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '109',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '127',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '144',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '150',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '141',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '123',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '106',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '100',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '109',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '150',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'ppeak',
          value: [
            {
              value: '18',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'pins',
          value: [
            {
              value: '13',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '14',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '14',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '14',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 're',
          value: [
            {
              value: '13',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'ri',
          value: [
            {
              value: '13',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '8',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '2',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'peep',
          value: [
            {
              value: '9',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '9',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '4',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'cdyn',
          value: [
            {
              value: '13',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '14',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '14',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '14',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '13',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '6',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '5',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '7',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
        {
          name: 'vti',
          value: [
            {
              value: '18',
              timeStamp: '2022-10-11T02:05:56.922Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:06:56.927Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:07:56.937Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:08:56.941Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:09:56.946Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:10:56.951Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:11:56.958Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:12:56.963Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:13:56.973Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:14:56.977Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:15:56.985Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:16:56.990Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:17:56.997Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:18:57.003Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:19:57.009Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:20:57.016Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:21:57.023Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:22:57.029Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:23:57.041Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:24:57.040Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:25:57.048Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:26:57.054Z',
              __typename: 'AirParam',
            },
            {
              value: '19',
              timeStamp: '2022-10-11T02:27:57.061Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:28:57.068Z',
              __typename: 'AirParam',
            },
            {
              value: '18',
              timeStamp: '2022-10-11T02:29:57.072Z',
              __typename: 'AirParam',
            },
            {
              value: '15',
              timeStamp: '2022-10-11T02:30:57.078Z',
              __typename: 'AirParam',
            },
            {
              value: '11',
              timeStamp: '2022-10-11T02:31:57.085Z',
              __typename: 'AirParam',
            },
            {
              value: '10',
              timeStamp: '2022-10-11T02:32:57.092Z',
              __typename: 'AirParam',
            },
            {
              value: '12',
              timeStamp: '2022-10-11T02:33:57.098Z',
              __typename: 'AirParam',
            },
            {
              value: '20',
              timeStamp: '2022-10-11T02:34:35.280Z',
              __typename: 'AirParam',
            },
          ],
          __typename: 'AirDashboardItem',
        },
      ],
      __typename: 'AirResponseToSelect',
    },
  }),
  SaveBedPreferences: (onCompleted) => [() => onCompleted(), { loading: false }],
  Air: () => {
    let callback = () => { /* */ };

    const interval = setInterval(() => {
      callback({
        air: {
          ventMode: 'VS_PRVC',
          flowTrigger: 'ASSISTED',
          flowControl: 'VOLUME',
          automode: null,
          trigger: null,
          timeStamp: new Date().toISOString(),
          info: generateAir(30),
        },
      });
    }, 1000);

    return {
      onData: (func) => {
        callback = func;
      },
      unsubscribe: () => {
        clearInterval(interval);
      },
    };
  },
};
