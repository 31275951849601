export const bedStatus = {
  active: 'ACTIVE',
  free: 'FREE',
  inactive: 'DISABLED',
  onHolding: 'ON_HOLD',
  activeUnplugged: 'ACTIVE_UNPLUGGED',
  onHoldingUnplugged: 'ON_HOLD_UNPLUGGED',
  freeUnplugged: 'FREE_UNPLUGGED',
  waitingAdmission: 'WAITING_ADMISSION',
};
