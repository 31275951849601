import React from 'react';

import { ReactComponent as LoadingHeart } from '__assets/icons/loadingHeart.svg';
import { ReactComponent as LoadingCircle } from '__assets/icons/loadingCircle.svg';

import { Container, Title } from './styles';

export const types = {
  circle: 'loadingCircle',
  heart: 'loadingHeart',
};

export default function Loading({
  type,
  title,
  imgWidth,
  imgHeight,
  fullScreen,
}) {
  return (
    <Container isFullScreen={fullScreen} data-cy="loading" imgWidth={imgWidth} imgHeight={imgHeight}>
      {type === types.circle && <LoadingCircle />}
      {type === types.heart && <LoadingHeart />}
      <Title>{title}</Title>
    </Container>
  );
}

Loading.defaultProps = {
  title: '',
  type: types.circle,
};
