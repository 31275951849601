import { useMutation } from '@apollo/client';
import mockedData from './demo';

function getOperationDefinition({ definitions }) {
  return definitions.find((el) => el.kind === 'OperationDefinition').name.value;
}

function useMutationMock(payload, options = { onCompleted: () => {}, isLoading: () => {} }) {
  return mockedData[getOperationDefinition(payload)](options);
}

const useMockableMutation = process.env.REACT_APP_IS_DEMO ? useMutationMock : useMutation;

export default useMockableMutation;
