import styled, { css } from 'styled-components';
import { breakpoints, fontSize } from 'config/constants';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  overflow-x: auto;
  
  @media (min-width: ${breakpoints.md}) {
    gap: 40px;
  }
`;

export const List = styled.ul`
  background-color: ${({ theme }) => theme.colors.secondary.w000};
  height: 250px;
  min-width: 260px;
  overflow: auto;
  scrollbar-color: ${({ theme }) => theme.colors.primary.w100} ${({ theme }) => theme.colors.secondary.w500};
  scrollbar-highlight-color: ${({ theme }) => theme.colors.primary.w100};
  scrollbar-width: thin;
  width: 100%;

  &::-webkit-scrollbar {
    padding: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    background: ${({ theme }) => theme.colors.secondary.w500};
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary.w100};
  }
`;

const listFeedback = css`
  border-color: ${({ theme }) => theme.colors.feedback.alert};
  border-style: solid;
  border-width: 2px;
`;

export const ListWithFeedback = styled(List)`
  ${({ feedback }) => feedback && listFeedback}
`;

export const ListTitle = styled.span`
  color: ${({ theme }) => theme.colors.secondary.w000};
  display: block;
  margin-bottom: 8px;
`;

const selectedItem = css`
  background: ${({ theme }) => theme.colors.primary.w100};
  color: ${({ theme }) => theme.colors.secondary.w000};
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.secondary.w100};
  cursor: default;
  list-style: none;
  padding: 10px;
  display: block;
  ${(props) => props?.selected && selectedItem};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 200px;
  min-width: 170px;
  width: 100%;
`;

export const Feedback = styled.p`
  color: ${({ theme }) => theme.colors.feedback.alert};
  font-size: ${fontSize.xs};
  font-weight: 200;
  margin: 4px 10px 0 auto;
  text-align: right;
`;

export const ListContainer = styled.div`
  width: 100%
`;
