/* eslint-disable no-plusplus */
import * as ecgWaves from './ecg_waves';
import { getECG } from './dataGenerator';

export const WAVES = {
  ecgInterval: undefined,
  Waves: () => {
    let callback = () => { };
    function propagateData() {
      callback({
        waves: {
          waveI: getECG(ecgWaves.I),
          waveIi: getECG(ecgWaves.Ii),
          waveIii: getECG(ecgWaves.Iii),
          waveAvf: getECG(ecgWaves.Avf),
          waveAvl: getECG(ecgWaves.Avl),
          waveAvr: getECG(ecgWaves.Avr),
          waveV1: getECG(ecgWaves.V1),
          waveV2: getECG(ecgWaves.V2),
          waveV3: getECG(ecgWaves.V3),
          waveV4: getECG(ecgWaves.V4),
          waveV5: getECG(ecgWaves.V5),
          waveV6: getECG(ecgWaves.V6),
        },
      });
    }

    if (WAVES.ecgInterval) {
      clearInterval(WAVES.ecgInterval);
    }

    WAVES.ecgInterval = setInterval(propagateData, 6100);

    return {
      onData: (func) => {
        callback = func;
      },
      unsubscribe: () => {
        clearInterval(WAVES.ecgInterval);
      },
    };
  },
  GetEcgLastHourStreak: (data) => ({ getEcgLastHourStreak: ecgWaves.waves24[data?.derivation] }),
};
