import { actions } from 'config/constants';
import getUserActions from 'utils/getUserActions';

export default function shouldSessionExpire() {
  const userActions = getUserActions();
  const noUserLoggedIn = userActions.length === 0;

  if (noUserLoggedIn) return false;

  return !userActions.find((action) => action === actions.UNLIMITED_SESSION_TIMEOUT);
}
