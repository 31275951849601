import {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useQuery } from '@apollo/client';

import mockedData from './demo';
import getOperationDefinition from './utils/getOperationDefinition';

function useQueryMock(query, options) {
  const [data, setData] = useState(mockedData[getOperationDefinition(query)](options?.variables));
  const subscriptions = useRef([]);

  useEffect(
    () => {
      subscriptions.current.forEach((s) => {
        s.unsubscribe();
      });
      subscriptions.current.length = 0;
      return setData(
        mockedData[getOperationDefinition(query)](options?.variables),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mockedData[getOperationDefinition(query)](options?.variables).pageHome?.icus[0].id],
  );

  const subscribeToMore = useMemo(() => ((
    { document, variables, updateQuery },
  ) => {
    const name = getOperationDefinition(document);

    const subscription = mockedData[name](variables);
    subscription.onData((subscriptionData) => {
      const { id } = Object.values(subscriptionData)[0];
      if (id) {
        const [hospital, icu, bed, vitalSign] = id.split('#');

        setData((data) => {
          const newData = data;

          let prev;
          const currentIcuIndex = newData.pageHome.icus.findIndex((i) => i.id === [hospital, icu].join('#'));
          const currentIcu = newData.pageHome.icus[currentIcuIndex > 0 ? currentIcuIndex : 0];
          let currentBedIndex;

          if (vitalSign) {
            currentBedIndex = currentIcu.beds.findIndex((b) => b.id === [hospital, icu, bed].join('#'));
            prev = currentIcu.beds[currentBedIndex];
          } else {
            prev = currentIcu;
          }

          const res = updateQuery(
            prev,
            { subscriptionData: { data: subscriptionData } },
          );

          if (vitalSign) {
            currentIcu.beds[currentBedIndex] = res;
          } else {
            newData.pageHome.icus[currentIcuIndex] = res;
          }

          return { ...newData };
        });
      }
    });
    subscriptions.current.push(subscription);
    return subscription.unsubscribe;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return {
    client: {},
    observable: {},
    data,
    previousData: {},
    error: undefined,
    loading: false,
    networkStatus: 7,
    called: true,
    subscribeToMore,
  };
}

const useMockableQuery = process.env.REACT_APP_IS_DEMO ? useQueryMock : useQuery;

export default useMockableQuery;
