import styled, { css } from 'styled-components';
import { breakpoints } from 'config/constants';

const baseGrid = css`
  display: grid;
  gap: 40px 0;
  width: 100%;
  
  grid-template-columns: 1fr;
  
  @media (min-width: ${breakpoints.sm}) {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
   }
`;

export const Container = styled.div`
 ${baseGrid}
`;

export const List = styled.div`
  ${baseGrid}
  grid-column: span 2;
`;

export const UniquepepIdContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  grid-row: 2;
  
  @media (min-width: ${breakpoints.sm}) {
    display: block;
    grid-column: 1;
    grid-row: 1;
  }
`;
