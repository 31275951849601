import token from 'entities/token';

export default function getUserActions() {
  const actionsJson = token.getIdToken()?.payload.actions;

  if (!actionsJson) return [];
  const parsedActions = JSON.parse(`${actionsJson}`);

  return parsedActions[Object.keys(parsedActions)[0]];
}
