import { getIcu } from './dataGenerator';

const VITAL_SIGN_INTERVAL = 3000;

const alarmPreset = {
  systolicArterialPressure: [89, 110, 126, 146],
  diastolicArterialPressure: [56, 80, 86, 101],
  meanArterialPressure: [69, 90, 99, 116],
  heartRate: [39, 50, 91, 121],
  oxygenSaturation: [88, 96],
  respiratoryRate: [9, 12, 20, 31],
  temperature: [34.9, 36.1, 37.9, 40.9],
  carbonDioxide: [28, 30, 46, 48],
};

function getAlarmStatus(value, vitalSign) {
  const [lower, low, high, higher] = alarmPreset[vitalSign];
  if (value <= lower || value >= higher) {
    return 'danger';
  }
  if (value < low || value > high) {
    return 'warning';
  }
  return 'normal';
}

const randomValues = new Map();
function nextValue(id, floor, ceiling, step) {
  const lastValue = randomValues.get(id) || Math.random() * (ceiling - floor) + floor;
  let newValue = lastValue + (Math.random() * (2 * step) - step);

  if (newValue < floor) newValue = floor;
  else if (newValue > ceiling) newValue = ceiling;

  randomValues.set(id, newValue);
  return newValue;
}

function subscriptionFactory(ids, callback) {
  let observationFunction = () => { /* */ };

  const intervals = ids.map((id) => setInterval(() => {
    callback(id, observationFunction);
  }, VITAL_SIGN_INTERVAL));

  const subscription = {
    onData: (dataCallback) => {
      observationFunction = dataCallback;
    },
    unsubscribe: () => {
      intervals.forEach((interval) => {
        clearInterval(interval);
      });
    },
  };

  return subscription;
}

export const pageHome = {
  PageHome: () => {
    const hospitalIcuSelected = sessionStorage.getItem('selectedIcu') || 'rede_carenet/hospital_elizabeth_blackwell#uti_geral';
    const icus = getIcu(hospitalIcuSelected);
    const hospitalSelected = hospitalIcuSelected.split('#')[0];
    return {
      pageHome: {
        features: {
          air: false,
          alarms: false,
          infusion: false,
          monitor: true,
          pep: false,
          __typename: 'Features',
        },
        icus,
        hospitals: [
          {
            isSelected: (hospitalSelected === 'rede_carenet/hospital_elizabeth_blackwell'),
            label: 'Hospital Elizabeth Blackwell',
            value: 'rede_carenet/hospital_elizabeth_blackwell',
            icus: [
              {
                value: 'rede_carenet/hospital_elizabeth_blackwell#uti_geral',
                isSelected: (hospitalIcuSelected === 'rede_carenet/hospital_elizabeth_blackwell#uti_geral'),
                label: 'UTI Geral',
                __typename: 'ICUSelect',
              },
              {
                value: 'rede_carenet/hospital_elizabeth_blackwell#uti_pediatrica',
                isSelected: (hospitalIcuSelected === 'rede_carenet/hospital_elizabeth_blackwell#uti_pediatrica'),
                label: 'UTI Pediátrica',
                __typename: 'ICUSelect',
              },
            ],
            __typename: 'Hospital',
          },
          {
            isSelected: (hospitalSelected === 'rede_carenet/hospital_sao_marcio'),
            label: 'Hospital São Márcio',
            value: 'rede_carenet/hospital_sao_marcio',
            icus: [
              {
                value: 'rede_carenet/hospital_sao_marcio#uti_geral',
                isSelected: (hospitalIcuSelected === 'rede_carenet/hospital_sao_marcio#uti_geral'),
                label: 'UTI Geral',
                __typename: 'ICUSelect',
              },
              {
                value: 'rede_carenet/hospital_sao_marcio#uti_adulto',
                isSelected: (hospitalIcuSelected === 'rede_carenet/hospital_sao_marcio#uti_adulto'),
                label: 'UTI Adulto',
                __typename: 'ICUSelect',
              },
            ],
            __typename: 'Hospital',
          },
        ],
        __typename: 'LandingPage',
      },
    };
  },
  Notices: () => ([
    { EXPIRING_PRESET: 'Preset Hospital' },
  ]),
  Bed: () => ({
    onData: (callback) => {
      const hospitalIcuSelected = sessionStorage.getItem('selectedIcu') || 'rede_carenet/hospital_elizabeth_blackwell#uti_geral';
      const icus = getIcu(hospitalIcuSelected);
      callback({
        bed: icus[0].beds,
      });
    },
    unsubscribe: () => { /* Do nothing */ },
  }),
  RespiratoryRate: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = Math.floor(nextValue(id, 7, 34, 0.1));

    onData({
      respiratoryRate: {
        id,
        alarmStatus: getAlarmStatus(value, 'respiratoryRate'),
        timeStamp: new Date().toISOString(),
        value: `${value}`,
        type: 'respiratoryRate',
        __typename: 'VitalSign',
      },
    });
  }),
  CarbonDioxide: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = id.split('#')[1] === 'uti_pediatrica' ? null : Math.floor(nextValue(id, 25, 42, 1.0));

    onData({
      carbonDioxide: {
        id,
        alarmStatus: id.split('#')[1] === 'uti_pediatrica' ? '' : getAlarmStatus(value, 'carbonDioxide'),
        timeStamp: new Date().toISOString(),
        value,
        type: 'carbonDioxide',
        __typename: 'VitalSign',
      },
    });
  }),
  ArterialPressure: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const syst = Math.floor(nextValue(`${id}Systolic`, 70, 200, 0.7));
    const diast = Math.floor(nextValue(`${id}Diastolic`, 30, 120, 0.7));

    const sAlarm = getAlarmStatus(syst, 'systolicArterialPressure');
    const dAlarm = getAlarmStatus(diast, 'diastolicArterialPressure');

    function getHigherAlarmStatus(statuses) {
      return (
        statuses.find((s) => s === 'danger')
        || statuses.find((s) => s === 'warning')
        || statuses.find((s) => s === 'normal')
      );
    }

    onData({
      arterialPressure: {
        id,
        alarmStatus: getHigherAlarmStatus([sAlarm, dAlarm]),
        timeStamp: new Date().toISOString(),
        value: `${syst}/${diast}`,
        type: 'arterialPressure',
        __typename: 'VitalSign',
      },
    });
  }),
  MeanArterialPressure: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const reducedId = id.split('#');
    reducedId.length -= 1;
    const syst = randomValues.get(`${reducedId.join('#')}#arterialPressureSystolic`) || 120;
    const diast = randomValues.get(`${reducedId.join('#')}#arterialPressureDiastolic`) || 80;

    const value = id.split('#')[1] === 'uti_pediatrica' ? null : Math.floor((syst + (diast * 2)) / 3);

    onData({
      meanArterialPressure: {
        id,
        alarmStatus: id.split('#')[1] === 'uti_pediatrica' ? 'normal' : getAlarmStatus(value, 'meanArterialPressure'),
        timeStamp: new Date().toISOString(),
        value,
        type: 'meanArterialPressure',
        __typename: 'VitalSign',
      },
    });
  }),
  OxygenSaturation: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = Math.floor(nextValue(id, 80, 100, 0.5));

    onData({
      oxygenSaturation: {
        id,
        alarmStatus: getAlarmStatus(value, 'oxygenSaturation'),
        timeStamp: new Date().toISOString(),
        value: `${value}`,
        type: 'oxygenSaturation',
        __typename: 'VitalSign',
      },
    });
  }),
  Temperature: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = nextValue(id, 33, 41, 0.01).toFixed(2);

    onData({
      temperature: {
        id,
        alarmStatus: getAlarmStatus(value, 'temperature'),
        timeStamp: new Date().toISOString(),
        value: `${value}`,
        type: 'temperature',
        __typename: 'VitalSign',
      },
    });
  }),
  HeartRate: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = Math.floor(nextValue(id, 35, 130, 1.2));

    onData({
      heartRate: {
        id,
        alarmStatus: getAlarmStatus(value, 'heartRate'),
        timeStamp: new Date().toISOString(),
        value: `${value}`,
        type: 'heartRate',
        __typename: 'VitalSign',
      },
    });
  }),
  SystolicArterialPressure: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = Math.floor(nextValue(id, 35, 130, 1.2));

    onData({
      systolicArterialPressure: {
        id,
        alarmStatus: getAlarmStatus(value, 'systolicArterialPressure'),
        timeStamp: new Date().toISOString(),
        value: `${value}`,
        type: 'systolicArterialPressure',
        __typename: 'VitalSign',
      },
    });
  }),
  DiastolicArterialPressure: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    const value = Math.floor(nextValue(id, 35, 130, 1.2));

    onData({
      diastolicArterialPressure: {
        id,
        alarmStatus: getAlarmStatus(value, 'diastolicArterialPressure'),
        timeStamp: new Date().toISOString(),
        value: `${value}`,
        type: 'diastolicArterialPressure',
        __typename: 'VitalSign',
      },
    });
  }),

  SetSelectedICU: () => {
    function setSelectedICU({ variables: { id } }) {
      window.sessionStorage.setItem('selectedIcu', id);
    }
    return [setSelectedICU, { loading: false }];
  },
};
