import { loginStatus } from 'settings';
import { actionTypes } from './constants';

const INITIAL_STATE = {
  loginStatus: loginStatus.idle,
  errorMessage: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.isAuthenticating:
      return { loginStatus: loginStatus.running };
    case actionTypes.authenticated:
      return {
        loginStatus: loginStatus.success,
      };
    case actionTypes.error:
      return {
        ...state,
        loginStatus: loginStatus.error,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
