import { generateHistoryBed } from './dataGenerator';

export const historyMonitor = {
  GetHistoryByBed: (data) => {
    const resolution = data.variables?.resolution ?? data.resolution;
    switch (resolution) {
      case 'EVERY_30_MINUTES':
        return ({ getHistoryByBed: generateHistoryBed(181, 30) });
      case 'EVERY_15_MINUTES':
        return ({ getHistoryByBed: generateHistoryBed(361, 15) });
      case 'EVERY_1_MINUTE':
        return ({ getHistoryByBed: generateHistoryBed(722, 1) });
      default:
        throw new Error();
    }
  },
};
