export const patientRecord = {
  GetPepSent: () => ({
    getPepSent: {
      entries: [
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-19T01:00:42.417Z',
          heartRate: '106.00',
          respiratoryRate: '17.00',
          systolicArterialPressure: '103.00',
          diastolicArterialPressure: '140.00',
          meanArterialPressure: '81.00',
          oxygenSaturation: '94.00',
          temperature: '36.51',
          carbonDioxide: '35',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T18:28:26.347Z',
          heartRate: '107.00',
          respiratoryRate: '18.00',
          systolicArterialPressure: '106.00',
          diastolicArterialPressure: '141.00',
          meanArterialPressure: '83.00',
          oxygenSaturation: '94.00',
          carbonDioxide: '35',
          temperature: '36.53',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:15:25.634Z',
          heartRate: '126.00',
          respiratoryRate: '24.00',
          systolicArterialPressure: '169.00',
          diastolicArterialPressure: '149.00',
          meanArterialPressure: '114.00',
          oxygenSaturation: '82.00',
          carbonDioxide: '35',
          temperature: '36.84',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:14:25.629Z',
          heartRate: '129.00',
          respiratoryRate: '25.00',
          systolicArterialPressure: '178.00',
          diastolicArterialPressure: '150.00',
          meanArterialPressure: '119.00',
          oxygenSaturation: '80.00',
          carbonDioxide: '35',
          temperature: '36.89',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:13:25.625Z',
          heartRate: '130.00',
          respiratoryRate: '25.00',
          systolicArterialPressure: '180.00',
          diastolicArterialPressure: '150.00',
          meanArterialPressure: '120.00',
          oxygenSaturation: '80.00',
          carbonDioxide: '35',
          temperature: '36.90',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:12:25.620Z',
          heartRate: '129.00',
          respiratoryRate: '25.00',
          systolicArterialPressure: '175.00',
          diastolicArterialPressure: '149.00',
          meanArterialPressure: '118.00',
          oxygenSaturation: '81.00',
          temperature: '36.88',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:09:25.605Z',
          heartRate: '115.00',
          respiratoryRate: '20.00',
          systolicArterialPressure: '133.00',
          diastolicArterialPressure: '144.00',
          meanArterialPressure: '97.00',
          oxygenSaturation: '89.00',
          carbonDioxide: '35',
          temperature: '36.67',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:08:25.601Z',
          heartRate: '111.00',
          respiratoryRate: '19.00',
          systolicArterialPressure: '118.00',
          diastolicArterialPressure: '142.00',
          meanArterialPressure: '89.00',
          oxygenSaturation: '92.00',
          carbonDioxide: '35',
          temperature: '36.59',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:07:25.596Z',
          heartRate: '107.00',
          respiratoryRate: '18.00',
          systolicArterialPressure: '106.00',
          diastolicArterialPressure: '141.00',
          meanArterialPressure: '83.00',
          oxygenSaturation: '94.00',
          carbonDioxide: '35',
          temperature: '36.53',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:06:25.591Z',
          heartRate: '105.00',
          respiratoryRate: '17.00',
          systolicArterialPressure: '100.00',
          diastolicArterialPressure: '140.00',
          meanArterialPressure: '80.00',
          oxygenSaturation: '95.00',
          carbonDioxide: '35',
          temperature: '36.50',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
        {
          type: 'MONITOR',
          vitalSigns: null,
          referenceTimestamp: '2022-11-18T14:05:15.123Z',
          heartRate: '106.00',
          respiratoryRate: '17.00',
          systolicArterialPressure: '101.00',
          diastolicArterialPressure: '141.00',
          meanArterialPressure: '80.00',
          oxygenSaturation: '94.00',
          carbonDioxide: '35',
          temperature: '36.50',
          dispatchTimestamp: null,
          messageId: null,
          infusionParameter: null,
          medication: null,
          volumeInfused: null,
          airParameter: null,
          ventMode: null,
          flowTrigger: null,
          flowControl: null,
          automode: null,
          trigger: null,
          info: null,
          __typename: 'PatientHistory',
        },
      ],
      pageSize: 10,
      entriesCount: 26,
      pageNumber: 0,
      pagesCount: 3,
      pepStatus: null,
      __typename: 'PagedResultToSent',
    },
    getSearch: () => [() => true, {
      data:
      {
        getPepSent: {
          entries: [
            {
              type: 'MONITOR',
              vitalSigns: null,
              referenceTimestamp: '2022-11-18T14:06:25.591Z',
              heartRate: '105.00',
              respiratoryRate: '17.00',
              systolicArterialPressure: '100.00',
              diastolicArterialPressure: '140.00',
              meanArterialPressure: '80.00',
              oxygenSaturation: '95.00',
              carbonDioxide: '35',
              temperature: '36.50',
              dispatchTimestamp: null,
              messageId: null,
              infusionParameter: null,
              medication: null,
              volumeInfused: null,
              airParameter: null,
              ventMode: null,
              flowTrigger: null,
              flowControl: null,
              automode: null,
              trigger: null,
              info: null,
              __typename: 'PatientHistory',
            },
            {
              type: 'MONITOR',
              vitalSigns: null,
              referenceTimestamp: '2022-11-18T14:05:15.123Z',
              heartRate: '106.00',
              respiratoryRate: '17.00',
              systolicArterialPressure: '101.00',
              diastolicArterialPressure: '141.00',
              meanArterialPressure: '80.00',
              oxygenSaturation: '94.00',
              carbonDioxide: '35',
              temperature: '36.50',
              dispatchTimestamp: null,
              messageId: null,
              infusionParameter: null,
              medication: null,
              volumeInfused: null,
              airParameter: null,
              ventMode: null,
              flowTrigger: null,
              flowControl: null,
              automode: null,
              trigger: null,
              info: null,
              __typename: 'PatientHistory',
            },
          ],
        },
      },
    }, { loading: false }],
  }),
};
