import { gql } from '@apollo/client';

export const REGISTER_USER_MUTATION = gql`
  mutation upsertUser($user: UserInput, $isUpdate: Boolean) {
    upsertUser(user: $user, isUpdate: $isUpdate) {
      fullName
      username
      userPosition
      role {
        value
      }
      pepId
    }
  }
`;

export const DOES_ID_EXIST = gql`
    query doesIdExist($id: String) {
      doesIdExist(id: $id)
    }
`;
