import React, { memo, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Checkbox from 'components/core/Checkbox';
import Input from 'components/core/Input';
import Select, { models as selectModels } from 'components/core/Select';

import { Container } from './styles';

function EmailSelector({
  callback, options, feedbackMessage, value, proxyEmailAdmin, readonly,
}) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState({ value: '', proxyEmailAdmin: null });

  useEffect(() => {
    if (proxyEmailAdmin) {
      setIsChecked(true);
      setInputValue((prev) => ({ ...prev, proxyEmailAdmin }));
    }
  }, [proxyEmailAdmin]);

  useEffect(() => {
    if (value) {
      setInputValue((prev) => ({ ...prev, value }));
    }
  }, [value]);

  useEffect(() => {
    callback(inputValue);
  }, [isChecked, callback, inputValue]);

  useEffect(() => {
    if (!isChecked) {
      setInputValue({ proxyEmailAdmin: null, value: '' });
    }
  }, [isChecked]);

  return (
    <Container>
      {
        isChecked ? (
          <Select
            label={t('email')}
            placeholder={t('text.typeAnEmail')}
            textAlign="left"
            model={selectModels.light}
            options={options}
            onChange={(eventValue) => {
              setInputValue({ proxyEmailAdmin: eventValue.username, value: eventValue.value });
            }}
            feedbackMessage={feedbackMessage}
            required
            value={options.find((option) => option?.username === proxyEmailAdmin)}
          />
        ) : (
          <Input
            value={inputValue.value}
            onChange={
              (event) => setInputValue({ proxyEmailAdmin: null, value: event.target.value })
            }
            type="email"
            label={t('email')}
            placeholder={t('text.typeAnEmail')}
            textAlign="left"
            feedbackMessage={feedbackMessage}
            required
            readonly={readonly}
            cy="emailProfessional"
          />
        )
      }
      <Checkbox
        readOnly={readonly}
        checked={isChecked}
        isControlled
        text={t('text.doNotHaveEmail')}
        margin="26px 0 0 0"
        onChange={(event) => setIsChecked(event.target.checked)}
        cy="noEmail"
      />
    </Container>
  );
}

EmailSelector.defaultProps = {
  callback: () => { /* */ },
  feedbackMessage: '',
  options: [],
};

export default memo(EmailSelector);
