/* eslint-disable max-len */
import { toast } from 'components/core/Toast';
import { i18n } from 'services/translate';
import { logError } from 'utils/logError';
import checkEnvironment from './checkEnvironment';

export function handleCommunicationErrors(error) {
  if (!error) return;

  logError({ communicationError: error });
  const errorCode = error?.graphQLErrors[0]?.extensions?.code;

  switch (errorCode) {
    case 'FORBIDDEN':
      toast.info(i18n.t('feedback.forbidden'), { toastId: 'forbidden' });
      break;
    case 'BAD_USER_INPUT':
      toast.error(i18n.t('feedback.badUserInput'), { toastId: 'badUserInput' });
      break;
    case 'INTERNAL_SERVER_ERROR':
      toast.error(i18n.t('feedback.internalServerError'), { toastId: 'internalServerError' });
      break;
    default:
      toast.error(i18n.t('feedback.serverErrorTryAgain'), { toastId: 'communicationError' });
      error.forward();
      break;
  }

  if (checkEnvironment().isProd) return;

  error.graphQLErrors?.forEach(({ message }) => {
    toast.error(`Dev: ${message}`, { autoClose: false, toastId: message });
  });
}
