/* eslint-disable no-plusplus */
function generateTimestamp() {
  const now = new Date();
  const twelveHoursAgo = new Date(now.getTime() - 12 * 60 * 60 * 1000);
  return twelveHoursAgo.toISOString();
}

function generateVitalSigns(min, max, length, bed) {
  const vitalSigns = [];
  const activeToggle = !bed ? true : bed % 2 === 1;

  for (let i = 0; i < length; i++) {
    const value = Math.floor(Math.random() * (max - min + 1)) + min;
    vitalSigns.push({
      timeStamp: generateTimestamp(),
      alarmStatus: null,
      isTemperatureEnabled: activeToggle,
      isCarbonDioxideEnabled: activeToggle,
      value: `${value}`,
    });
  }
  return vitalSigns;
}

export const monitor = {
  GetMonitorDashboard: (ref) => {
    if (!ref || !ref.bed) return undefined;
    const { bed } = ref;
    const bedNum = Number(bed.split('#')[2]);
    const activeToggle = bedNum % 2 === 1;
    return (
      {
        getMonitorDashboard: {
          isTemperatureEnabled: activeToggle,
          isCarbonDioxideEnabled: activeToggle,
          vitalSigns: [
            {
              name: 'temperature',
              value: generateVitalSigns(36, 38, 30, bedNum),
            },
            {
              name: 'heartRate',
              value: generateVitalSigns(70, 78, 30),
            },
            {
              name: 'respiratoryRate',
              value: generateVitalSigns(12, 20, 30),
            },
            {
              name: 'oxygenSaturation',
              value: generateVitalSigns(95, 100, 30),
            },
            {
              name: 'systolicArterialPressure',
              value: generateVitalSigns(90, 120, 30),
            },
            {
              name: 'diastolicArterialPressure',
              value: generateVitalSigns(60, 80, 30),
            },
            {
              name: 'meanArterialPressure',
              value: generateVitalSigns(70, 100, 30),
            },
            {
              name: 'carbonDioxide',
              value: generateVitalSigns(30, 45, 30, bedNum),
            },
          ],
        },
      }
    );
  },
  SaveBedPreferences: (onCompleted) => [() => onCompleted(), { loading: false }],
};
