import { useEffect } from 'react';
import clearHospitals from '../helpers/clearHospitals';

export default function useHandleOneHospital(hospitals, callback) {
  const hasOnlyOneHospital = hospitals.length === 1;

  useEffect(() => {
    if (hasOnlyOneHospital) {
      const cleanHospitals = clearHospitals(hospitals);
      callback(cleanHospitals);
    }
  }, [callback, hasOnlyOneHospital, hospitals]);

  return { hasOnlyOneHospital };
}
