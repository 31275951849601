import { breakpoints } from 'config/constants';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 12;
  display: grid;
  grid-column: span 2 !important;

  label {
    width: fit-content;
  }
  
  @media (min-width: ${breakpoints.sm}) {
    gap: 60px;
    grid-template-columns: 1fr 1fr;
  }
`;
