import React from 'react';

import { Container } from './styles';

export default function SectionTitle({ text }) {
  if (!text) return null;

  return (
    <Container>
      <span>
        {text}
      </span>
    </Container>
  );
}
