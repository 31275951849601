let counter = 29;
let _volumeInfused = 1;
let lastCallTime = null;
const TIME_TO_AWAIT = 3000; // 60000
const today = new Date().toISOString().split('T')[0];

function checkTime() {
  if (lastCallTime === null || (new Date() - lastCallTime) > TIME_TO_AWAIT) {
    lastCallTime = new Date();
    return true;
  }
  return false;
}

function build() {
  if (checkTime()) {
    counter += 1;
    _volumeInfused += 1;
  }
  return {
    volumeInfused: {
      id: counter,
      __typename: 'VolumeInfused',
      timeStamp: `${today}T10:${counter}:00.000Z`,
      volumeInfused: _volumeInfused,
    },
  };
}

const data = {
  infusionPumps: {
    maxValueToChartHeight: 100,
    pumps: [
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#1-1',
        pumpId: '1-1',
        infusionRate: '390.00',
        volumeToBeInfused: '174.75',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:00:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:01:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:02:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:03:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:04:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:05:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:06:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:07:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:08:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:09:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:10:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:11:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:12:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:13:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:14:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:15:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:16:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:17:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:18:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 50,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:19:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:20:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:21:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:22:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:23:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:24:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:25:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:26:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:27:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:28:00.000Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:29:00.000Z`,
            timeTrail: {},
            requestId: null,
            volumeInfused: 75,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-2',
        medication: 'DIETA ENTERAL 1200mL',
        infusionRate: '35',
        volumeToBeInfused: '1200.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-3',
        medication: 'DEXTROCETAMINA 500 MG/100 ML',
        infusionRate: '35',
        volumeToBeInfused: '300.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 14,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 15,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 16,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 17,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 18,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 19,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 21,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 22,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 23,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 10,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 12,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 14,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 16,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 23,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 28,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 23,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 20,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 26,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-4',
        medication: 'NITROGLICERINA 25 MG/250 ML',
        infusionRate: '35',
        volumeToBeInfused: '300.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 85,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 85,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 100,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 100,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 90,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 280,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 80,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-5',
        medication: 'AMIODARONA 300 MG/106 ML',
        infusionRate: '35',
        volumeToBeInfused: '300.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-6',
        medication: 'CISATRACURIO 200 MG/100 ML',
        infusionRate: '35',
        volumeToBeInfused: '300.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-7',
        medication: 'ROCURONIO 500 MG/50 ML',
        infusionRate: '35',
        volumeToBeInfused: '300.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
      {
        id: 'rede_carenet/hospital_sao_marcio#uti_demo_1#102#654',
        pumpId: '1-8',
        medication: 'REMIFENTANIL 2 MG/100 ML',
        infusionRate: '35',
        volumeToBeInfused: '300.00',
        volumeInfused: [
          {
            id: 1,
            timeStamp: `${today}T00:26:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 2,
            timeStamp: `${today}T00:27:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 3,
            timeStamp: `${today}T00:28:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 4,
            timeStamp: `${today}T00:29:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 5,
            timeStamp: `${today}T00:30:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 6,
            timeStamp: `${today}T00:31:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 7,
            timeStamp: `${today}T00:32:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 8,
            timeStamp: `${today}T00:33:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 9,
            timeStamp: `${today}T00:34:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 10,
            timeStamp: `${today}T00:35:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 11,
            timeStamp: `${today}T00:36:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 12,
            timeStamp: `${today}T00:37:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 13,
            timeStamp: `${today}T00:38:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 14,
            timeStamp: `${today}T00:39:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 15,
            timeStamp: `${today}T00:40:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 1,
            __typename: 'VolumeInfused',
          },
          {
            id: 16,
            timeStamp: `${today}T00:41:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 17,
            timeStamp: `${today}T00:42:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 18,
            timeStamp: `${today}T00:43:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 19,
            timeStamp: `${today}T00:44:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 20,
            timeStamp: `${today}T00:45:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 21,
            timeStamp: `${today}T00:46:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 22,
            timeStamp: `${today}T00:47:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 23,
            timeStamp: `${today}T00:48:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 24,
            timeStamp: `${today}T00:49:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 25,
            timeStamp: `${today}T00:50:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 26,
            timeStamp: `${today}T00:51:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 27,
            timeStamp: `${today}T00:52:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 28,
            timeStamp: `${today}T00:53:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 29,
            timeStamp: `${today}T00:54:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
          {
            id: 30,
            timeStamp: `${today}T00:55:56.906Z`,
            timeTrail: null,
            requestId: null,
            volumeInfused: 2,
            __typename: 'VolumeInfused',
          },
        ],
        __typename: 'InfusionPump',
      },
    ],
  },
};

function subscriptionFactory({ bed }, callback) {
  let observationFunction = () => { /* */ };

  const intervals = setInterval(() => {
    callback(bed, observationFunction);
  }, TIME_TO_AWAIT);

  const subscription = {
    onData: (callback) => {
      observationFunction = callback;
    },
    unsubscribe: () => {
      intervals.forEach((interval) => {
        clearInterval(interval);
      });
    },
  };

  return subscription;
}

export const infusion = {
  VolumeInfused: ({ ids }) => subscriptionFactory(ids, (_, onData) => {
    const x = build();
    onData({ ...x });
  }),
  InfusionPumps: (param) => {
    const onData = subscriptionFactory(param, () => {
      // onData({ ...data });
    });
    return {
      ...data,
      ...onData,
    };
  },
  GetMedications: () => ({
    getMedications: [
      {
        id: 1,
        medicationName: 'Nimesulida 100mg',
        hospital: 1,
      },
      {
        id: 2,
        medicationName: 'Hemifumarato de Bisoprolol 5mg',
        hospital: 1,
      },
      {
        id: 3,
        medicationName: 'Benicar Anlo 40mg',
        hospital: 1,
      },
      {
        id: 4,
        medicationName: 'Paracetamol 750mg',
        hospital: 1,
      },
      {
        id: 5,
        medicationName: 'Hemitartarato de Zolpidem 10mg',
        hospital: 1,
      },
      {
        id: 6,
        medicationName: 'Dipirona Prati 500mg',
        hospital: 1,
      },
    ],
  }),
};
