import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter, Outlet, Route, Routes as AppRoutes, Navigate,
} from 'react-router-dom';
import PrivateRoute from 'components/layout/PrivateRoute';
import UserLogs from 'pages/UserLogs';
import { paths } from './constants';

const User = lazy(() => import('pages/User'));
const Bed = lazy(() => import('pages/Bed'));
const Home = lazy(() => import('pages/Home'));
const Login = lazy(() => import('pages/Login'));
const RecoverPassword = lazy(() => import('components/forms/RecoverPassword'));
const ChangePasswordWithCode = lazy(() => import('components/forms/ChangePasswordWithCode'));
const LoginForm = lazy(() => import('components/forms/Login'));
const Settings = lazy(() => import('pages/Settings'));
const UserManagement = lazy(() => import('pages/UserManagement'));
const Infusion = lazy(() => import('pages/Infusion'));
const Air = lazy(() => import('pages/Air'));
const Monitor = lazy(() => import('pages/Monitor'));
const History = lazy(() => import('pages/History'));
const SendERP = lazy(() => import('pages/SendERP'));
const FrequencyERP = lazy(() => import('pages/FrequencyERP'));
const AlarmsConfig = lazy(() => import('pages/AlarmsConfig'));
const PresetConfig = lazy(() => import('pages/PresetConfig'));
const PresetEdit = lazy(() => import('pages/PresetEdit'));
const AlarmsBed = lazy(() => import('pages/AlarmsBed'));
const PatientRecord = lazy(() => import('pages/PatientRecord'));
const Deployment = lazy(() => import('pages/Deployment'));
const DeploymentTabs = lazy(() => import('pages/Deployment/DeploymentTabs'));
const DownloadApp = lazy(() => import('pages/DownloadApp'));
const DashboardECG = lazy(() => import('pages/Monitor/ECG'));
const Label = lazy(() => import('pages/Label'));

function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={(<div />)}>
        <AppRoutes>
          <Route path={paths.root} element={<PrivateRoute authorization element={Home} />} />

          <Route path={paths.login} element={<Login />}>
            <Route index element={<LoginForm />} />
            <Route path={paths.recoverPassword} element={<RecoverPassword />} />
            <Route path={paths.newPassword} element={<ChangePasswordWithCode />} />
          </Route>

          <Route path={paths.downloadMozard} element={<DownloadApp />} />

          <Route
            path={paths.downloadMozart}
            element={<PrivateRoute authorization element={DownloadApp} />}
          />

          <Route
            path={paths.label}
            element={<PrivateRoute authorization element={Label} />}
          />

          <Route
            path={paths.settings}
            element={<PrivateRoute authorization element={Settings} />}
          >
            <Route path={paths.userManagement} element={<UserManagement />} />
            <Route path={paths.userLogs} element={<UserLogs />} />
            <Route path={paths.user} element={<User />} />
            <Route path={paths.frequencyERP} element={<FrequencyERP />} />
            <Route path={paths.alarmsConfig} element={<AlarmsConfig />} />
            <Route path={paths.createPreset} element={<PresetConfig />} />
            <Route path={`${paths.editPreset}:id/:status`} element={<PresetEdit />} />
            <Route
              path={paths.deployment}
              element={<PrivateRoute authorization element={Deployment} />}
            />
            <Route
              path={`${paths.deployment}/new`}
              element={<PrivateRoute authorization element={DeploymentTabs} />}
            />
            <Route
              path={`${paths.deployment}/edit/:hospitalId/:id`}
              element={<PrivateRoute authorization element={DeploymentTabs} />}
            />
          </Route>

          <Route path={`${paths.patientRecord}:pid/:id/*`} element={<PrivateRoute authorization element={PatientRecord} />} />

          <Route path={`${paths.bed}:pid/:id/*`} element={<PrivateRoute authorization element={Bed} />}>
            <Route path="*" element={<Navigate replace to="monitor/dashboard" />} />
            <Route path="monitor/*" element={(<Suspense fallback={(<div />)}><Outlet /></Suspense>)}>
              <Route path="dashboard" element={<Monitor />} />
              <Route path="history" element={<History />} />
              <Route path="send-erp" element={<SendERP />} />
              <Route path="alarms" element={<AlarmsBed />} />
              <Route path="ecg" element={<DashboardECG />} />
            </Route>
            <Route path="air/*" element={<Suspense fallback={(<div />)}><Outlet /></Suspense>}>
              <Route path="dashboard" element={<Air />} />
            </Route>
            <Route path="infusion/*" element={<Suspense fallback={(<div />)}><Outlet /></Suspense>}>
              <Route path="dashboard" element={<Infusion />} />
            </Route>
          </Route>

        </AppRoutes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
