export function createTableColumns() {
  const columns = [];

  function getColumns() {
    return columns;
  }

  function setColumns({ header, accessor, ...rest }) {
    columns.push({ Header: header, accessor, ...rest });
  }

  function addActions({ header, componentCreator }) {
    columns.push({
      Header: header,
      id: header,
      accessor: (originalRow) => componentCreator(originalRow),
    });
  }

  return {
    getColumns,
    setColumns,
    addActions,
  };
}
