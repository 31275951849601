import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  button {
    max-width: 150px;
  }
`;

export const Footer = styled.div`
  backdrop-filter: blur(8px);
  background: ${({ theme }) => theme.colors.secondary.w200};
  bottom: 0;
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 40%);
  left: 0;
  position: fixed;
  right: 0;

  @supports (backdrop-filter: blur(8px)) {
    background: ${({ theme }) => theme.colors.secondary.w200blur};
  }
`;
