import { breakpoints, fontSize } from 'config/constants';
import styled from 'styled-components';

export const Container = styled.div`
  left: 0;
  position: absolute;
  right: 0;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const List = styled.ul`
  color: ${({ theme }) => theme.colors.text.w000};
  display: block;
  min-width: 320px;
  padding: 10px;
  width: 100%;
`;

export const ListItem = styled.li`
  background-color: ${({ theme }) => theme.colors.secondary.w200};
  font-size: ${fontSize.xs};
  list-style: none;
  padding: 10px;
  width: 100%;

  &:nth-child(1) {
    background-color: ${({ theme }) => theme.colors.secondary.w300};
  }
`;
