import React, { useEffect } from 'react';

import Checkbox from 'components/core/Checkbox';

import { useTable, useRowSelect, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import {
  Arrow,
  Container, HeaderText, Table as StyledTable, Td, Th, Tr,
  Tbody,
  Thead,
} from './styles';

const headerCheckbox = ({ getToggleAllRowsSelectedProps }) => (
  <Checkbox
    checked={getToggleAllRowsSelectedProps().checked}
    onChange={getToggleAllRowsSelectedProps().onChange}
    isControlled
  />
);
const cellCheckbox = ({ row }) => (
  <Checkbox
    checked={row.getToggleRowSelectedProps().checked}
    onChange={row.getToggleRowSelectedProps().onChange}
    isControlled
  />
);

export { createTableColumns } from './helpers/createTableColumns';

export default function Table({
  data, columns, onSelectItems, isSelectable, rowWidth, scrollBar, cy,
}) {
  const { t } = useTranslation();
  const tableInstance = useTable(
    { columns, data },
    useSortBy,
    useRowSelect,
    isSelectable && ((hooks) => {
      hooks.visibleColumns.push((previousColumns) => [
        {
          id: 'selection',
          Header: headerCheckbox,
          Cell: cellCheckbox,
          width: '2%',
        },
        ...previousColumns,
      ]);
    }),
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const selectedRowsKey = selectedFlatRows.reduce((acc, d) => acc + d.original.username, '');
  useEffect(() => {
    const payload = selectedFlatRows.map(
      (d) => d.original.username,
    );
    onSelectItems(payload);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsKey]);

  return (
    <Container>
      <StyledTable role={getTableProps()?.role} data-cy={`table-${cy}`}>
        <Thead scrollBar={scrollBar}>
          {
            headerGroups.map((headerGroup) => (
              <Tr
                key={headerGroup.getHeaderGroupProps().key}
                role={headerGroup.getHeaderGroupProps().role}
                scrollBar={scrollBar}
              >
                {
                  headerGroup.headers.map((column, index) => (
                    <Th
                      colSpan={column.getHeaderProps(column.getSortByToggleProps()).colSpan}
                      key={column.getHeaderProps(column.getSortByToggleProps()).key}
                      onClick={column.getHeaderProps(column.getSortByToggleProps()).onClick}
                      role={column.getHeaderProps(column.getSortByToggleProps()).role}
                      width={column.width || rowWidth}
                      data-cy={`th-${index}-${cy}`}
                    >

                      <HeaderText>
                        {
                          column.render('Header')
                        }
                        <Arrow
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                        />
                      </HeaderText>
                    </Th>
                  ))
                }
              </Tr>
            ))
          }
        </Thead>
        <Tbody role={getTableBodyProps().role} className={scrollBar ? 'scrollbar' : ''} data-cy={`tbody-${cy}`}>
          {
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr
                  key={row.getRowProps().key}
                  role={row.getRowProps().role}
                  scrollBar={scrollBar}
                  data-cy={`tr-${index}-row-${cy}`}
                >
                  {
                    row.cells.map((cell, indexNum) => (
                      <Td
                        key={cell.getCellProps().key}
                        role={cell.getCellProps().role}
                        width={cell.column.width || rowWidth}
                        data-cy={`td-${indexNum}-${cy}`}
                      >
                        {
                          cell.column.id === 'role' ? t(`useRoles.${cell.value}`) : cell.render('Cell')
                        }
                      </Td>
                    ))
                  }
                </Tr>
              );
            })
          }
        </Tbody>
      </StyledTable>
    </Container>
  );
}

Table.defaultProps = {
  data: [],
  onSelectItems: () => { /* void */ },
  isSelectable: true,
};
