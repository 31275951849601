export const vitalSigns = {
  arterialPressure: {
    i18nKey: 'ssvv.arterialPressure',
    initials: 'abbreviation.systolicArterialPressure',
    type: 'arterialPressure',
  },
  diastolicArterialPressure: {
    i18nKey: 'ssvv.diastolicArterialPressure',
    initials: 'abbreviation.diastolicArterialPressure',
    type: 'diastolicArterialPressure',
  },
  heartRate: {
    i18nKey: 'ssvv.heartRate',
    initials: 'abbreviation.heartRate',
    type: 'heartRate',
  },
  meanArterialPressure: {
    i18nKey: 'ssvv.meanArterialPressure',
    initials: 'abbreviation.meanArterialPressure',
    type: 'meanArterialPressure',
  },
  oxygenSaturation: {
    i18nKey: 'ssvv.oxygenSaturation',
    initials: 'abbreviation.oxygenSaturation',
    type: 'oxygenSaturation',
  },
  respiratoryRate: {
    i18nKey: 'ssvv.respiratoryRate',
    initials: 'abbreviation.respiratoryRate',
    type: 'respiratoryRate',
  },
  systolicArterialPressure: {
    i18nKey: 'ssvv.systolicArterialPressure',
    initials: 'abbreviation.systolicArterialPressure',
    type: 'systolicArterialPressure',
  },
  temperature: {
    i18nKey: 'ssvv.temperature',
    initials: 'abbreviation.temperature',
    type: 'temperature',
  },
  carbonDioxide: {
    i18nKey: 'ssvv.carbonDioxide',
    initials: 'abbreviation.carbonDioxide',
    type: 'carbonDioxide',
  },
  isInvasive: {
    i18nKey: 'ssvv.isInvasive',
    initials: 'abbreviation.isInvasive',
    type: 'isInvasive',
  },
};
