import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import ExpiredSession from 'components/layout/ExpiredSession';
import useUser from 'services/cognito/user';
import LoadingPageCentralized from '../LoadingPageCentralized';

export default function PrivateRoute({
  authorization,
  element: Component,
}) {
  const navigate = useNavigate();
  const { user } = useUser();
  useEffect(() => {
    if (!user) navigate('/login');
  }, [navigate, user]);

  if (!authorization) return <div>Não autorizado</div>; // TBD

  if (!user && !process.env.REACT_APP_IS_DEMO) return <LoadingPageCentralized />;

  return (
    <>
      <ExpiredSession />
      <Component />
    </>
  );
}
