/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import Loading, { types } from 'components/core/Loading';
import Icon from 'components/core/Icon';

import { models, states } from './constants';

import { Container, FileContainer } from './styles';

export default function Button({
  text,
  onClick,
  minWidth,
  width,
  model,
  type,
  id,
  margin,
  state,
  form,
  iconName,
  cy,
  fileMode,
  file,
  accept,
}) {
  return (
    <>
      { fileMode ? (
        <FileContainer
          minWidth={minWidth}
          width={width}
          model={models.modelB}
          state={state}
          iconName={iconName}
          htmlFor={id}
        >
          {
            state === states.loading && <Loading title="" type={types.circle} />
          }
          {
            state !== states.loading && (
              text
            )
          }
          {
              iconName && state !== states.loading && (
                <Icon name={iconName} width="20px" margin={text ? '0 0 0 12px' : '0'} />
              )
            }
          <input id={id} accept={accept} type="file" onChange={(event) => file(event)} />
        </FileContainer>
      ) : (
        <Container
          form={form}
          data-testid={`button-${text}`}
          data-cy={cy}
          id={id}
          onClick={onClick}
          minWidth={minWidth}
          width={width}
          model={model}
          type={type}
          margin={margin}
          state={state}
          iconName={iconName}
          disabled={state === states.disabled}
        >
          {
          state === states.loading && <Loading title="" type={types.circle} />
        }
          {
          state !== states.loading && (
            text
          )
        }
          {
          iconName && state !== states.loading && (
            <Icon name={iconName} width="20px" margin={text ? '0 0 0 12px' : '0'} />
          )
        }
        </Container>
      )}
    </>
  );
}

Button.defaultProps = {
  id: '',
  form: null,
  margin: 'initial',
  model: models.modelA,
  onClick: null,
  state: states.default,
  text: '',
  type: 'button',
  width: '100%',
};

export { models, states };
