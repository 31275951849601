import { gql } from '@apollo/client';

export const USER_LOGS_QUERY = gql`
  query GetLogs($username: String) {
    getLogs(username: $username) {    
      username
      timestamp
      action
      parameters
      children {
        username
        timestamp
        action
        parameters
      }
    }
  }
`;

export const USER_FORM_DATA_QUERY = gql`
  query User($username: ID) {
    user(username: $username) {
      username
      fullName
      userPosition
      phoneNumber
      email
      proxyEmailAdmin
      userHospitals {
        label
        value
        pepId
        address
      }
      role {
        label
        value
        canHaveMultipleHospitals
      }
      pepId
      isDisabled
    }
  }
`;
