import styled from 'styled-components';

import { breakpoints, fontSize } from 'config/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;

  @media (min-width: ${breakpoints.xs}) {
    width: 360px;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${fontSize.lg};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${fontSize.xs};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;
