import styled from 'styled-components';

export const Title = styled.span`
  display: block;
  margin-top: 5px;
`;

export const List = styled.ul`
  list-style-type: '- ';
  list-style: '- ';
  padding: 12px 10px;
`;
