import styled, { css } from 'styled-components';

import { breakpoints, zIndex as levels } from 'config/constants';

const blurredBackground = css`
  & ~ * {
    filter: blur(8px);
    overflow: hidden;
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.secondary.w350};
  border-radius: 15px;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.4);
  height: auto;
  left: 50%;
  padding: 30px;
  position: fixed;
  top: ${({ show }) => (show ? '15%' : '-200%')};
  transform: translate(-50%, -15%);
  transition: top ease-in-out 200ms;
  width: 94%;
  z-index: ${({ zIndex }) => (zIndex || levels.lv4)};
  
  ${({ blurBackground, show }) => blurBackground && show && blurredBackground}
  
  @media (min-width: ${breakpoints.xs}) {
    width: auto;
  }
`;

export const Close = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 10px;
`;
