import { languages } from 'config/constants';

export const options = [
  {
    id: 1,
    language: languages.pt_BR,
    flagName: 'pt',
    languageName: 'Português',
  },
  {
    id: 2,
    language: languages.es_ES,
    flagName: 'es',
    languageName: 'Español',
  },
];

export const languageActions = {
  CHANGE_LANGUAGE: 'LANGUAGE/CHANGE_LANGUAGE',
};
