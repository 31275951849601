import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query Users {
    users {
      username
      fullName
      userPosition
      role {
        label
      }
      pepId
      isDisabled
    }
  }
`;

export const SET_USER_ENABLED_MUTATION = gql`
  mutation SetUserEnabled($username: String!, $enabled: Boolean!) {
    setUserEnabled(username: $username, enabled: $enabled) {
      username
      isDisabled
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($username: String!) {
    deleteUser(username: $username) {
      success
    }
  }
`;
