import React from 'react';

import Loading, { types } from 'components/core/Loading';
import { Container } from './styles';

export default function LoadingPageCentralized({ isLoading, fixed }) {
  if (!isLoading) return null;

  return (
    <Container fixed={fixed}>
      <Loading title="" type={types.heart} />
    </Container>
  );
}

LoadingPageCentralized.defaultProps = {
  isLoading: true,
};
