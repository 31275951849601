/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { toast } from 'components/core/Toast';
import LoadingPageCentralized from 'components/layout/LoadingPageCentralized';
import Section from 'components/core/Section';
import Table, { createTableColumns } from 'components/layout/Table';
import { LoadMore } from 'components/layout/Table/styles';
import toCamelCase from 'utils/toCamelCase';
import LogList from 'components/layout/LogList';

import AddUser from 'components/forms/AddUser';
import Loading from 'components/core/Loading';
import Button, { models } from 'components/core/Button';
import { paths } from 'config/routes/constants';
import useMockableQuery from 'services/apollo/hooks/useMockableQuery';
import { ButtonContainer, Container, Footer } from './styles';
import { USER_FORM_DATA_QUERY, USER_LOGS_QUERY } from './queries';
import CompositeLog from './CompositeLog';

export default function UserLogs() {
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);
  const countToShow = 11;
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data, loading, error } = useMockableQuery(USER_LOGS_QUERY, { variables: { username }, fetchPolicy: 'cache-and-network' });
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useMockableQuery(USER_FORM_DATA_QUERY, {
    variables: { username },
  });

  if (loading) return <LoadingPageCentralized />;
  if (error || userError) {
    toast.error(t('feedback.errorUnknown'), { toastId: 'userLogsError' });
    return '';
  }
  function formatLog(log) {
    const parameters = log.parameters || [];
    const treatedParams = parameters.map((p) => (p?.startsWith('t_') ? t(p.substr(2)) : (p || '')));
    if (log.action === 'PEP_UPDATED_VITAL_SIGN') {
      treatedParams[0] = dayjs(treatedParams[0]).format('DD/MM/YYYY [ás] HH:mm:ss');
      treatedParams[1] = t(`logActions.${treatedParams[1]}`);
    }
    if (log.action === 'PEP_SENT_DATA_TO_PEP') {
      treatedParams[0] = dayjs(treatedParams[0]).format('DD/MM/YYYY [ás] HH:mm:ss');
      treatedParams[1] = dayjs(treatedParams[1]).format('DD/MM/YYYY [ás] HH:mm:ss');
    }

    return t(`logActions.${toCamelCase(log.action)}`, { ...treatedParams });
  }

  function withChildren(log) {
    return (
      <CompositeLog log={log} formatLog={formatLog} />
    );
  }

  function getLogData() {
    const allData = data.getLogs.map((log, index) => ({
      id: index + 1,
      timestamp: dayjs(log.timestamp).format('DD/MM/YYYY[ às ]HH[h]mm'),
      username: log.username,
      action: log.children.length ? withChildren(log) : formatLog(log),
    }));

    if (allData?.length < countToShow || !loadMoreVisible) return allData;

    const res = allData.map((item) => (item.id < countToShow ? item : null));
    return res.filter((item) => item !== null);
  }

  const columns = createTableColumns();
  columns.setColumns({
    header: t('dateTime'),
    accessor: 'timestamp',
    width: '20%',
  });
  columns.setColumns({
    header: t('usersTable.userName'),
    accessor: 'username',
    width: '20%',
  });
  columns.setColumns({
    header: t('action'),
    accessor: 'action',
    width: '60%',
  });
  return (
    data && (
    <Container>
      <Section title={t('logs.logUser')} isFolded>
        {userLoading && <Loading />}
        {userData && <AddUser readonly user={userData.user} />}
      </Section>
      <Section title={t('logs.logHistory')}>
        <Table
          data={getLogData()}
          columns={columns.getColumns()}
          isSelectable={false}
          scrollBar
          cy="user-log"
        />
        <LoadMore
          data-cy="loadMore"
          onClick={() => setLoadMoreVisible(false)}
          isVisible={loadMoreVisible && data.getLogs.length >= countToShow}
        >
          {t('usersTable.loadMore')}
        </LoadMore>
        <LogList logs={getLogData()} />
      </Section>
      <Footer>
        <ButtonContainer>
          <Button
            type="button"
            text={t('button.back')}
            model={models.modelBSecondary}
            onClick={() => navigate(paths.userManagement)}
            cy="log-back-button"
          />
        </ButtonContainer>
      </Footer>
    </Container>
    )
  );
}
