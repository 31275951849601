import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckBoxList from 'components/layout/CheckBoxList';
import PickList from 'components/layout/PickList';

import { isMobile } from 'utils/isMobile';
import clearHospitals from 'components/forms/AddUser/helpers/clearHospitals';

function LinkHospitalsToUser({
  hospitals, callback, feedbackMessage, canHaveMultipleHospitals, defaultSelectedItems,
}) {
  const { t } = useTranslation();
  function getPepId(hospital) {
    const item = defaultSelectedItems?.find((i) => i.value === hospital.value);
    return item?.pepId || null;
  }

  const hospitalsWithPepId = hospitals.map((hospital) => (
    {
      ...hospital,
      pepId: getPepId(hospital),
      label: `${hospital.label} (${hospital.hospitalGroup})`,
    }
  ));

  const cleanHospitals = clearHospitals(hospitalsWithPepId);
  const labels = {
    leftListTitle: t('pickList.leftListTitle'),
    rightListTitle: t('pickList.rightListTitle'),
    addAll: t('pickList.addAll'),
    addOne: t('pickList.addOne'),
    removeAll: t('pickList.removeAll'),
    removeOne: t('pickList.removeOne'),
  };

  if (isMobile()) {
    return (
      <CheckBoxList
        defaultSelectedItems={defaultSelectedItems}
        items={cleanHospitals}
        id="hospitals_checkbox_list"
        callback={callback}
        canSelectMultipleItems={canHaveMultipleHospitals}
      />
    );
  }

  return (
    <PickList
      defaultSelectedItems={defaultSelectedItems}
      canSelectMultipleItems={canHaveMultipleHospitals}
      labels={labels}
      items={cleanHospitals}
      callback={callback}
      feedbackMessage={feedbackMessage}
    />
  );
}

export default memo(LinkHospitalsToUser);
