import React, { memo, useEffect, useState } from 'react';
import Button, { models, states } from 'components/core/Button';
import toSlug from 'utils/toSlug';
import {
  ButtonsContainer, Container, List, ListItem, Feedback, ListTitle, ListWithFeedback, ListContainer,
} from './styles';

function PickList({
  items, callback, labels, feedbackMessage, canSelectMultipleItems, defaultSelectedItems,
}) {
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [leftSelectedItem, setLeftSelectedItem] = useState();
  const [rightSelectedItem, setRightSelectedItem] = useState();

  useEffect(() => {
    const defaultSelectedItemsValues = defaultSelectedItems.map((item) => item.value);
    const selectedItems = items.filter((item) => defaultSelectedItemsValues?.includes(item.value));
    const remainingItems = items.filter(
      (item) => !defaultSelectedItemsValues?.includes(item.value),
    );
    setRightList(selectedItems);
    setLeftList(remainingItems);
  }, [defaultSelectedItems, items]);

  useEffect(() => {
    callback(rightList);
  }, [rightList, callback]);

  function addAllItems() {
    setRightList(rightList.concat(leftList));
    setLeftList([]);
    setLeftSelectedItem(null);
  }

  function removeAllItems() {
    setLeftList(rightList.concat(leftList));
    setRightList([]);
    setRightSelectedItem(null);
  }

  function addSelectedItem() {
    if (!leftSelectedItem) return;

    const list = [...rightList];
    list.push(leftSelectedItem);

    setLeftList(leftList.filter((item) => item !== leftSelectedItem));
    setRightList(list);
    setLeftSelectedItem(null);
  }

  function removeOneItem() {
    if (!rightSelectedItem) return;
    const list = [...leftList];
    list.push(rightSelectedItem);

    setRightList(rightList.filter((item) => item !== rightSelectedItem));
    setLeftList(list);
    setRightSelectedItem(null);
  }

  return (
    <Container>
      <ListContainer>
        <ListTitle>{labels.leftListTitle}</ListTitle>
        <List>
          {
            leftList.map((option) => (
              <ListItem
                isActive={option.isActive || true}
                selected={option === leftSelectedItem}
                onClick={() => setLeftSelectedItem(option)}
                key={`${option.value}-${option.label}`}
                data-cy={`left-list-item-${toSlug(option.label)}`}
              >
                {option.label}
              </ListItem>
            ))
          }
        </List>
      </ListContainer>
      <ButtonsContainer>
        <Button
          text={labels.addAll}
          onClick={addAllItems}
          model={models.modelB}
          state={canSelectMultipleItems ? states.default : states.disabled}
          cy={`actions-picklist-${toSlug(labels.addAll)}`}
        />
        <Button
          text={labels.addOne}
          onClick={addSelectedItem}
          model={models.modelB}
          state={
            rightList.length >= 1 && !canSelectMultipleItems ? states.disabled : states.default
          }
          cy={`actions-picklist-${toSlug(labels.addOne)}`}
        />
        <Button
          text={labels.removeOne}
          onClick={removeOneItem}
          model={models.modelB}
          cy={`actions-picklist-${toSlug(labels.removeOne)}`}
        />
        <Button
          text={labels.removeAll}
          onClick={removeAllItems}
          model={models.modelB}
          state={canSelectMultipleItems ? states.default : states.disabled}
          cy={`actions-picklist-${toSlug(labels.removeAll)}`}
        />
      </ButtonsContainer>
      <ListContainer>
        <ListTitle>{labels.rightListTitle}</ListTitle>
        <ListWithFeedback feedback={feedbackMessage}>
          {
            rightList.map((option) => (
              <ListItem
                isActive
                selected={option === rightSelectedItem}
                onClick={() => setRightSelectedItem(option)}
                key={option.value}
                data-cy={`right-list-item-${toSlug(option.label)}`}
              >
                {option.label}
              </ListItem>
            ))
          }
        </ListWithFeedback>
        <Feedback>{feedbackMessage}</Feedback>
      </ListContainer>
    </Container>
  );
}

PickList.defaultProps = {
  items: [],
  defaultSelectedItems: [],
  callback: () => { /* */ },
  labels: {
    leftListTitle: '',
    rightListTitle: '',
    addAll: '',
    addOne: '',
    removeAll: '',
    removeOne: '',
  },
  canSelectMultipleItems: true,
  feedbackMessage: '',
};

export default memo(PickList);
