import { useEffect } from 'react';
import { submittingStates } from '../constants';

export default function useGetSubmittingState(callback, { loading, error, data }) {
  useEffect(() => {
    if (loading) {
      callback(submittingStates.submitting);
    }
    if (error) {
      callback(submittingStates.error);
    }
    if (data) {
      callback(submittingStates.success);
    }
  }, [callback, data, error, loading]);
}
