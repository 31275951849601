import { ApolloLink } from '@apollo/client';
import token from 'entities/token';

export const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${token.getIdToken().jwtToken}`,
    },
  });
  return forward(operation);
});
