import { breakpoints, fontSize } from 'config/constants';

import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.secondary.w000};
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${fontSize.lg};
  font-weight: 200;
  margin: 30px 0;
  position: relative;

  
  span {  
    background-color: ${({ theme }) => theme.colors.secondary.w200};
    display: flex;
    margin: 0 auto;
    padding: 0 10px;
    transform: translateY(12px);
    width: fit-content;

    @media (min-width: ${breakpoints.xs}) {
      left: 20px;
      position: absolute;
      transform: initial;
      top: -26px;
    }
  }

  @media (min-width: ${breakpoints.xs}) {
    margin: 80px 0 60px;
  }
`;
