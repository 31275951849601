import { fontSize } from 'config/constants';
import styled from 'styled-components';

export const Container = styled.label`
  align-items: center;
  display: flex;
  margin: ${({ margin }) => margin};
  min-width: 24px;
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
  height: 24px;
  position: relative;
  width: 1px;

  &:before {
    background: transparent;
    border: 2px solid;
    border-radius: 5px;
    border-color: ${({ theme }) => (theme.colors.primary.w100)};
    content: '';
    cursor: pointer;
    display: flex;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;    
  }

  &:checked {
    &:before {
      background: ${({ theme }) => (theme.colors.primary.w100)};
      border-color: #ffffff;
      color: ${({ theme }) => theme.colors.text.w000};
      content: '✓';
      display: flex;
      font-size: ${fontSize.xs};
      justify-content: center;
    }
  }
  `;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.text.w000};
  font-size: ${fontSize.sm};
  font-weight: 200;
  margin-left: 30px;
  user-select: none;
`;
