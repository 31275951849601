/* eslint-disable no-loss-of-precision */
export const respiratoryWave = [
  {
    y: -31.35088348388672,
    x: 0,
  },
  {
    y: -31.186426162719727,
    x: 7.8125,
  },
  {
    y: -31.026954650878906,
    x: 15.625,
  },
  {
    y: -30.87744903564453,
    x: 23.4375,
  },
  {
    y: -30.57843780517578,
    x: 39.0625,
  },
  {
    y: -30.433914184570312,
    x: 46.875,
  },
  {
    y: -30.294376373291016,
    x: 54.6875,
  },
  {
    y: -30.15483856201172,
    x: 62.5,
  },
  {
    y: -30.01529884338379,
    x: 70.3125,
  },
  {
    y: -29.87575912475586,
    x: 78.125,
  },
  {
    y: -29.74618911743164,
    x: 85.9375,
  },
  {
    y: -29.621601104736328,
    x: 93.75,
  },
  {
    y: -29.506980895996094,
    x: 101.5625,
  },
  {
    y: -29.397342681884766,
    x: 109.375,
  },
  {
    y: -29.287704467773438,
    x: 117.1875,
  },
  {
    y: -29.18305206298828,
    x: 125,
  },
  {
    y: -29.093347549438477,
    x: 132.8125,
  },
  {
    y: -29.008628845214844,
    x: 140.625,
  },
  {
    y: -28.933876037597656,
    x: 148.4375,
  },
  {
    y: -28.864105224609375,
    x: 156.25,
  },
  {
    y: -28.809288024902344,
    x: 164.0625,
  },
  {
    y: -28.764434814453125,
    x: 171.875,
  },
  {
    y: -28.729551315307617,
    x: 179.6875,
  },
  {
    y: -28.699649810791016,
    x: 187.5,
  },
  {
    y: -28.674732208251953,
    x: 195.3125,
  },
  {
    y: -28.65479850769043,
    x: 203.125,
  },
  {
    y: -28.644832611083984,
    x: 210.9375,
  },
  {
    y: -28.644832611083984,
    x: 218.75,
  },
  {
    y: -28.64981460571289,
    x: 226.5625,
  },
  {
    y: -28.65978240966797,
    x: 234.375,
  },
  {
    y: -28.68470001220703,
    x: 242.1875,
  },
  {
    y: -28.71958351135254,
    x: 250,
  },
  {
    y: -28.75945281982422,
    x: 257.8125,
  },
  {
    y: -28.809288024902344,
    x: 265.625,
  },
  {
    y: -28.85912322998047,
    x: 273.4375,
  },
  {
    y: -28.91892433166504,
    x: 281.25,
  },
  {
    y: -28.98371124267578,
    x: 289.0625,
  },
  {
    y: -29.05846405029297,
    x: 296.875,
  },
  {
    y: -29.138198852539062,
    x: 304.6875,
  },
  {
    y: -29.227903366088867,
    x: 312.5,
  },
  {
    y: -29.332557678222656,
    x: 320.3125,
  },
  {
    y: -29.442195892333984,
    x: 328.125,
  },
  {
    y: -29.561798095703125,
    x: 335.9375,
  },
  {
    y: -29.691370010375977,
    x: 343.75,
  },
  {
    y: -29.820941925048828,
    x: 351.5625,
  },
  {
    y: -29.95549774169922,
    x: 359.375,
  },
  {
    y: -30.100019454956055,
    x: 367.1875,
  },
  {
    y: -30.25450897216797,
    x: 375,
  },
  {
    y: -30.41398048400879,
    x: 382.8125,
  },
  {
    y: -30.57843780517578,
    x: 390.625,
  },
  {
    y: -30.747875213623047,
    x: 398.4375,
  },
  {
    y: -30.917316436767578,
    x: 406.25,
  },
  {
    y: -31.086755752563477,
    x: 414.0625,
  },
  {
    y: -31.261178970336914,
    x: 421.875,
  },
  {
    y: -31.43560218811035,
    x: 429.6875,
  },
  {
    y: -31.615009307861328,
    x: 437.5,
  },
  {
    y: -31.799400329589844,
    x: 445.3125,
  },
  {
    y: -31.988773345947266,
    x: 453.125,
  },
  {
    y: -32.55689239501953,
    x: 476.5625,
  },
  {
    y: -32.751251220703125,
    x: 484.375,
  },
  {
    y: -32.950592041015625,
    x: 492.1875,
  },
  {
    y: -33.149932861328125,
    x: 500,
  },
  {
    y: -33.34429168701172,
    x: 507.8125,
  },
  {
    y: -33.53366470336914,
    x: 515.625,
  },
  {
    y: -33.72303771972656,
    x: 523.4375,
  },
  {
    y: -33.917396545410156,
    x: 531.25,
  },
  {
    y: -34.10676956176758,
    x: 539.0625,
  },
  {
    y: -34.291160583496094,
    x: 546.875,
  },
  {
    y: -34.47056579589844,
    x: 554.6875,
  },
  {
    y: -34.644989013671875,
    x: 562.5,
  },
  {
    y: -34.814430236816406,
    x: 570.3125,
  },
  {
    y: -34.98386764526367,
    x: 578.125,
  },
  {
    y: -35.1533088684082,
    x: 585.9375,
  },
  {
    y: -35.32274627685547,
    x: 593.75,
  },
  {
    y: -35.48221969604492,
    x: 601.5625,
  },
  {
    y: -35.63671112060547,
    x: 609.375,
  },
  {
    y: -35.78123092651367,
    x: 617.1875,
  },
  {
    y: -35.91578674316406,
    x: 625,
  },
  {
    y: -36.03539276123047,
    x: 632.8125,
  },
  {
    y: -36.1500129699707,
    x: 640.625,
  },
  {
    y: -36.25965118408203,
    x: 648.4375,
  },
  {
    y: -36.35932159423828,
    x: 656.25,
  },
  {
    y: -36.44403839111328,
    x: 664.0625,
  },
  {
    y: -36.51879119873047,
    x: 671.875,
  },
  {
    y: -36.58856201171875,
    x: 679.6875,
  },
  {
    y: -36.64836502075195,
    x: 687.5,
  },
  {
    y: -36.693214416503906,
    x: 695.3125,
  },
  {
    y: -36.72809982299805,
    x: 703.125,
  },
  {
    y: -36.75301742553711,
    x: 710.9375,
  },
  {
    y: -36.772953033447266,
    x: 718.75,
  },
  {
    y: -36.78790283203125,
    x: 726.5625,
  },
  {
    y: -36.79787063598633,
    x: 734.375,
  },
  {
    y: -36.79787063598633,
    x: 742.1875,
  },
  {
    y: -36.78790283203125,
    x: 750,
  },
  {
    y: -36.772953033447266,
    x: 757.8125,
  },
  {
    y: -36.75301742553711,
    x: 765.625,
  },
  {
    y: -36.72809982299805,
    x: 773.4375,
  },
  {
    y: -36.69820022583008,
    x: 781.25,
  },
  {
    y: -36.668296813964844,
    x: 789.0625,
  },
  {
    y: -36.638397216796875,
    x: 796.875,
  },
  {
    y: -36.608497619628906,
    x: 804.6875,
  },
  {
    y: -36.57859420776367,
    x: 812.5,
  },
  {
    y: -36.5486946105957,
    x: 820.3125,
  },
  {
    y: -36.51879119873047,
    x: 828.125,
  },
  {
    y: -36.4888916015625,
    x: 835.9375,
  },
  {
    y: -36.45899200439453,
    x: 843.75,
  },
  {
    y: -36.4290885925293,
    x: 851.5625,
  },
  {
    y: -36.404170989990234,
    x: 859.375,
  },
  {
    y: -36.384239196777344,
    x: 867.1875,
  },
  {
    y: -36.35932159423828,
    x: 875,
  },
  {
    y: -36.339385986328125,
    x: 882.8125,
  },
  {
    y: -36.31446838378906,
    x: 890.625,
  },
  {
    y: -36.294532775878906,
    x: 898.4375,
  },
  {
    y: -36.274600982666016,
    x: 906.25,
  },
  {
    y: -36.25466537475586,
    x: 914.0625,
  },
  {
    y: -36.23473358154297,
    x: 921.875,
  },
  {
    y: -36.21479797363281,
    x: 929.6875,
  },
  {
    y: -36.194862365722656,
    x: 937.5,
  },
  {
    y: -36.174930572509766,
    x: 945.3125,
  },
  {
    y: -36.15499496459961,
    x: 953.125,
  },
  {
    y: -36.13506317138672,
    x: 960.9375,
  },
  {
    y: -36.11512756347656,
    x: 968.75,
  },
  {
    y: -36.095191955566406,
    x: 976.5625,
  },
  {
    y: -36.075260162353516,
    x: 984.375,
  },
  {
    y: -36.06031036376953,
    x: 992.1875,
  },
  {
    y: -36.04535675048828,
    x: 1000,
  },
  {
    y: -36.0304069519043,
    x: 1007.8125,
  },
  {
    y: -36.02043914794922,
    x: 1015.625,
  },
  {
    y: -36.005489349365234,
    x: 1023.4375,
  },
  {
    y: -35.995521545410156,
    x: 1031.25,
  },
  {
    y: -35.98057174682617,
    x: 1039.0625,
  },
  {
    y: -35.970603942871094,
    x: 1046.875,
  },
  {
    y: -35.96063995361328,
    x: 1054.6875,
  },
  {
    y: -35.9506721496582,
    x: 1062.5,
  },
  {
    y: -35.92076873779297,
    x: 1085.9375,
  },
  {
    y: -35.910804748535156,
    x: 1093.75,
  },
  {
    y: -35.90083694458008,
    x: 1101.5625,
  },
  {
    y: -35.870933532714844,
    x: 1125,
  },
  {
    y: -35.86096954345703,
    x: 1132.8125,
  },
  {
    y: -35.85100173950195,
    x: 1140.625,
  },
  {
    y: -35.82109832763672,
    x: 1164.0625,
  },
  {
    y: -35.811134338378906,
    x: 1171.875,
  },
  {
    y: -35.80116653442383,
    x: 1179.6875,
  },
  {
    y: -35.771263122558594,
    x: 1203.125,
  },
  {
    y: -35.76628112792969,
    x: 1210.9375,
  },
  {
    y: -35.76129913330078,
    x: 1218.75,
  },
  {
    y: -35.75631332397461,
    x: 1226.5625,
  },
  {
    y: -35.7513313293457,
    x: 1234.375,
  },
  {
    y: -35.74634552001953,
    x: 1242.1875,
  },
  {
    y: -35.741363525390625,
    x: 1250,
  },
  {
    y: -35.73638153076172,
    x: 1257.8125,
  },
  {
    y: -35.73139572143555,
    x: 1265.625,
  },
  {
    y: -35.72641372680664,
    x: 1273.4375,
  },
  {
    y: -35.72142791748047,
    x: 1281.25,
  },
  {
    y: -35.71644592285156,
    x: 1289.0625,
  },
  {
    y: -35.711463928222656,
    x: 1296.875,
  },
  {
    y: -35.706478118896484,
    x: 1304.6875,
  },
  {
    y: -35.70149612426758,
    x: 1312.5,
  },
  {
    y: -35.70149612426758,
    x: 1320.3125,
  },
  {
    y: -35.696510314941406,
    x: 1328.125,
  },
  {
    y: -35.696510314941406,
    x: 1335.9375,
  },
  {
    y: -35.6915283203125,
    x: 1343.75,
  },
  {
    y: -35.6915283203125,
    x: 1351.5625,
  },
  {
    y: -35.686546325683594,
    x: 1359.375,
  },
  {
    y: -35.686546325683594,
    x: 1367.1875,
  },
  {
    y: -35.68156051635742,
    x: 1375,
  },
  {
    y: -35.68156051635742,
    x: 1382.8125,
  },
  {
    y: -35.676578521728516,
    x: 1390.625,
  },
  {
    y: -35.676578521728516,
    x: 1398.4375,
  },
  {
    y: -35.671592712402344,
    x: 1406.25,
  },
  {
    y: -35.671592712402344,
    x: 1414.0625,
  },
  {
    y: -35.66661071777344,
    x: 1421.875,
  },
  {
    y: -35.66661071777344,
    x: 1429.6875,
  },
  {
    y: -35.66162872314453,
    x: 1437.5,
  },
  {
    y: -35.66162872314453,
    x: 1445.3125,
  },
  {
    y: -35.65664291381836,
    x: 1453.125,
  },
  {
    y: -35.65664291381836,
    x: 1460.9375,
  },
  {
    y: -35.65166091918945,
    x: 1468.75,
  },
  {
    y: -35.65166091918945,
    x: 1476.5625,
  },
  {
    y: -35.64667510986328,
    x: 1484.375,
  },
  {
    y: -35.64667510986328,
    x: 1492.1875,
  },
  {
    y: -35.641693115234375,
    x: 1500,
  },
  {
    y: -35.641693115234375,
    x: 1507.8125,
  },
  {
    y: -35.63671112060547,
    x: 1515.625,
  },
  {
    y: -35.63671112060547,
    x: 1523.4375,
  },
  {
    y: -35.6317253112793,
    x: 1531.25,
  },
  {
    y: -35.6317253112793,
    x: 1539.0625,
  },
  {
    y: -35.62674331665039,
    x: 1546.875,
  },
  {
    y: -35.62674331665039,
    x: 1554.6875,
  },
  {
    y: -35.62674331665039,
    x: 1632.8125,
  },
  {
    y: -35.62175750732422,
    x: 1640.625,
  },
  {
    y: -35.611793518066406,
    x: 1648.4375,
  },
  {
    y: -35.59185791015625,
    x: 1656.25,
  },
  {
    y: -35.571922302246094,
    x: 1664.0625,
  },
  {
    y: -35.54700469970703,
    x: 1671.875,
  },
  {
    y: -35.51710510253906,
    x: 1679.6875,
  },
  {
    y: -35.487205505371094,
    x: 1687.5,
  },
  {
    y: -35.44733428955078,
    x: 1695.3125,
  },
  {
    y: -35.397499084472656,
    x: 1703.125,
  },
  {
    y: -35.342681884765625,
    x: 1710.9375,
  },
  {
    y: -35.28287887573242,
    x: 1718.75,
  },
  {
    y: -35.208126068115234,
    x: 1726.5625,
  },
  {
    y: -35.12340545654297,
    x: 1734.375,
  },
  {
    y: -35.0337028503418,
    x: 1742.1875,
  },
  {
    y: -34.93403244018555,
    x: 1750,
  },
  {
    y: -34.8343620300293,
    x: 1757.8125,
  },
  {
    y: -34.72472381591797,
    x: 1765.625,
  },
  {
    y: -34.610103607177734,
    x: 1773.4375,
  },
  {
    y: -34.480533599853516,
    x: 1781.25,
  },
  {
    y: -34.35095977783203,
    x: 1789.0625,
  },
  {
    y: -34.211421966552734,
    x: 1796.875,
  },
  {
    y: -34.07188415527344,
    x: 1804.6875,
  },
  {
    y: -33.92736053466797,
    x: 1812.5,
  },
  {
    y: -33.777854919433594,
    x: 1820.3125,
  },
  {
    y: -33.32933807373047,
    x: 1843.75,
  },
  {
    y: -33.17485046386719,
    x: 1851.5625,
  },
  {
    y: -33.015377044677734,
    x: 1859.375,
  },
  {
    y: -32.85590362548828,
    x: 1867.1875,
  },
  {
    y: -32.696434020996094,
    x: 1875,
  },
  {
    y: -32.53197479248047,
    x: 1882.8125,
  },
  {
    y: -32.3625373840332,
    x: 1890.625,
  },
  {
    y: -32.19309616088867,
    x: 1898.4375,
  },
  {
    y: -32.018672943115234,
    x: 1906.25,
  },
  {
    y: -31.84923553466797,
    x: 1914.0625,
  },
  {
    y: -31.689762115478516,
    x: 1921.875,
  },
  {
    y: -31.520320892333984,
    x: 1929.6875,
  },
  {
    y: -31.35088348388672,
    x: 1937.5,
  },
  {
    y: -31.186426162719727,
    x: 1945.3125,
  },
  {
    y: -31.026954650878906,
    x: 1953.125,
  },
  {
    y: -30.87744903564453,
    x: 1960.9375,
  },
  {
    y: -30.57843780517578,
    x: 1976.5625,
  },
  {
    y: -30.433914184570312,
    x: 1984.375,
  },
  {
    y: -30.294376373291016,
    x: 1992.1875,
  },
  {
    y: -30.15483856201172,
    x: 2000,
  },
  {
    y: -30.01529884338379,
    x: 2007.8125,
  },
  {
    y: -29.87575912475586,
    x: 2015.625,
  },
  {
    y: -29.74618911743164,
    x: 2023.4375,
  },
  {
    y: -29.621601104736328,
    x: 2031.25,
  },
  {
    y: -29.506980895996094,
    x: 2039.0625,
  },
  {
    y: -29.397342681884766,
    x: 2046.875,
  },
  {
    y: -29.287704467773438,
    x: 2054.6875,
  },
  {
    y: -29.18305206298828,
    x: 2062.5,
  },
  {
    y: -29.093347549438477,
    x: 2070.3125,
  },
  {
    y: -29.008628845214844,
    x: 2078.125,
  },
  {
    y: -28.933876037597656,
    x: 2085.9375,
  },
  {
    y: -28.864105224609375,
    x: 2093.75,
  },
  {
    y: -28.809288024902344,
    x: 2101.5625,
  },
  {
    y: -28.764434814453125,
    x: 2109.375,
  },
  {
    y: -28.729551315307617,
    x: 2117.1875,
  },
  {
    y: -28.699649810791016,
    x: 2125,
  },
  {
    y: -28.674732208251953,
    x: 2132.8125,
  },
  {
    y: -28.65479850769043,
    x: 2140.625,
  },
  {
    y: -28.644832611083984,
    x: 2148.4375,
  },
  {
    y: -28.644832611083984,
    x: 2156.25,
  },
  {
    y: -28.64981460571289,
    x: 2164.0625,
  },
  {
    y: -28.65978240966797,
    x: 2171.875,
  },
  {
    y: -28.68470001220703,
    x: 2179.6875,
  },
  {
    y: -28.71958351135254,
    x: 2187.5,
  },
  {
    y: -28.75945281982422,
    x: 2195.3125,
  },
  {
    y: -28.809288024902344,
    x: 2203.125,
  },
  {
    y: -28.85912322998047,
    x: 2210.9375,
  },
  {
    y: -28.91892433166504,
    x: 2218.75,
  },
  {
    y: -28.98371124267578,
    x: 2226.5625,
  },
  {
    y: -29.05846405029297,
    x: 2234.375,
  },
  {
    y: -29.138198852539062,
    x: 2242.1875,
  },
  {
    y: -29.227903366088867,
    x: 2250,
  },
  {
    y: -29.332557678222656,
    x: 2257.8125,
  },
  {
    y: -29.442195892333984,
    x: 2265.625,
  },
  {
    y: -29.561798095703125,
    x: 2273.4375,
  },
  {
    y: -29.691370010375977,
    x: 2281.25,
  },
  {
    y: -29.820941925048828,
    x: 2289.0625,
  },
  {
    y: -29.95549774169922,
    x: 2296.875,
  },
  {
    y: -30.100019454956055,
    x: 2304.6875,
  },
  {
    y: -30.25450897216797,
    x: 2312.5,
  },
  {
    y: -30.41398048400879,
    x: 2320.3125,
  },
  {
    y: -30.57843780517578,
    x: 2328.125,
  },
  {
    y: -30.747875213623047,
    x: 2335.9375,
  },
  {
    y: -30.917316436767578,
    x: 2343.75,
  },
  {
    y: -31.086755752563477,
    x: 2351.5625,
  },
  {
    y: -31.261178970336914,
    x: 2359.375,
  },
  {
    y: -31.43560218811035,
    x: 2367.1875,
  },
  {
    y: -31.615009307861328,
    x: 2375,
  },
  {
    y: -31.799400329589844,
    x: 2382.8125,
  },
  {
    y: -31.988773345947266,
    x: 2390.625,
  },
  {
    y: -32.55689239501953,
    x: 2414.0625,
  },
  {
    y: -32.751251220703125,
    x: 2421.875,
  },
  {
    y: -32.950592041015625,
    x: 2429.6875,
  },
  {
    y: -33.149932861328125,
    x: 2437.5,
  },
  {
    y: -33.34429168701172,
    x: 2445.3125,
  },
  {
    y: -33.53366470336914,
    x: 2453.125,
  },
  {
    y: -33.72303771972656,
    x: 2460.9375,
  },
  {
    y: -33.917396545410156,
    x: 2468.75,
  },
  {
    y: -34.10676956176758,
    x: 2476.5625,
  },
  {
    y: -34.291160583496094,
    x: 2484.375,
  },
  {
    y: -34.47056579589844,
    x: 2492.1875,
  },
  {
    y: -34.644989013671875,
    x: 2500,
  },
  {
    y: -34.814430236816406,
    x: 2507.8125,
  },
  {
    y: -34.98386764526367,
    x: 2515.625,
  },
  {
    y: -35.1533088684082,
    x: 2523.4375,
  },
  {
    y: -35.32274627685547,
    x: 2531.25,
  },
  {
    y: -35.48221969604492,
    x: 2539.0625,
  },
  {
    y: -35.63671112060547,
    x: 2546.875,
  },
  {
    y: -35.78123092651367,
    x: 2554.6875,
  },
  {
    y: -35.91578674316406,
    x: 2562.5,
  },
  {
    y: -36.03539276123047,
    x: 2570.3125,
  },
  {
    y: -36.1500129699707,
    x: 2578.125,
  },
  {
    y: -36.25965118408203,
    x: 2585.9375,
  },
  {
    y: -36.35932159423828,
    x: 2593.75,
  },
  {
    y: -36.44403839111328,
    x: 2601.5625,
  },
  {
    y: -36.51879119873047,
    x: 2609.375,
  },
  {
    y: -36.58856201171875,
    x: 2617.1875,
  },
  {
    y: -36.64836502075195,
    x: 2625,
  },
  {
    y: -36.693214416503906,
    x: 2632.8125,
  },
  {
    y: -36.72809982299805,
    x: 2640.625,
  },
  {
    y: -36.75301742553711,
    x: 2648.4375,
  },
  {
    y: -36.772953033447266,
    x: 2656.25,
  },
  {
    y: -36.78790283203125,
    x: 2664.0625,
  },
  {
    y: -36.79787063598633,
    x: 2671.875,
  },
  {
    y: -36.79787063598633,
    x: 2679.6875,
  },
  {
    y: -36.78790283203125,
    x: 2687.5,
  },
  {
    y: -36.772953033447266,
    x: 2695.3125,
  },
  {
    y: -36.75301742553711,
    x: 2703.125,
  },
  {
    y: -36.72809982299805,
    x: 2710.9375,
  },
  {
    y: -36.69820022583008,
    x: 2718.75,
  },
  {
    y: -36.668296813964844,
    x: 2726.5625,
  },
  {
    y: -36.638397216796875,
    x: 2734.375,
  },
  {
    y: -36.608497619628906,
    x: 2742.1875,
  },
  {
    y: -36.57859420776367,
    x: 2750,
  },
  {
    y: -36.5486946105957,
    x: 2757.8125,
  },
  {
    y: -36.51879119873047,
    x: 2765.625,
  },
  {
    y: -36.4888916015625,
    x: 2773.4375,
  },
  {
    y: -36.45899200439453,
    x: 2781.25,
  },
  {
    y: -36.4290885925293,
    x: 2789.0625,
  },
  {
    y: -36.404170989990234,
    x: 2796.875,
  },
  {
    y: -36.384239196777344,
    x: 2804.6875,
  },
  {
    y: -36.35932159423828,
    x: 2812.5,
  },
  {
    y: -36.339385986328125,
    x: 2820.3125,
  },
  {
    y: -36.31446838378906,
    x: 2828.125,
  },
  {
    y: -36.294532775878906,
    x: 2835.9375,
  },
  {
    y: -36.274600982666016,
    x: 2843.75,
  },
  {
    y: -36.25466537475586,
    x: 2851.5625,
  },
  {
    y: -36.23473358154297,
    x: 2859.375,
  },
  {
    y: -36.21479797363281,
    x: 2867.1875,
  },
  {
    y: -36.194862365722656,
    x: 2875,
  },
  {
    y: -36.174930572509766,
    x: 2882.8125,
  },
  {
    y: -36.15499496459961,
    x: 2890.625,
  },
  {
    y: -36.13506317138672,
    x: 2898.4375,
  },
  {
    y: -36.11512756347656,
    x: 2906.25,
  },
  {
    y: -36.095191955566406,
    x: 2914.0625,
  },
  {
    y: -36.075260162353516,
    x: 2921.875,
  },
  {
    y: -36.06031036376953,
    x: 2929.6875,
  },
  {
    y: -36.04535675048828,
    x: 2937.5,
  },
  {
    y: -36.0304069519043,
    x: 2945.3125,
  },
  {
    y: -36.02043914794922,
    x: 2953.125,
  },
  {
    y: -36.005489349365234,
    x: 2960.9375,
  },
  {
    y: -35.995521545410156,
    x: 2968.75,
  },
  {
    y: -35.98057174682617,
    x: 2976.5625,
  },
  {
    y: -35.970603942871094,
    x: 2984.375,
  },
  {
    y: -35.96063995361328,
    x: 2992.1875,
  },
  {
    y: -35.9506721496582,
    x: 3000,
  },
  {
    y: -35.92076873779297,
    x: 3023.4375,
  },
  {
    y: -35.910804748535156,
    x: 3031.25,
  },
  {
    y: -35.90083694458008,
    x: 3039.0625,
  },
  {
    y: -35.870933532714844,
    x: 3062.5,
  },
  {
    y: -35.86096954345703,
    x: 3070.3125,
  },
  {
    y: -35.85100173950195,
    x: 3078.125,
  },
  {
    y: -35.82109832763672,
    x: 3101.5625,
  },
  {
    y: -35.811134338378906,
    x: 3109.375,
  },
  {
    y: -35.80116653442383,
    x: 3117.1875,
  },
  {
    y: -35.771263122558594,
    x: 3140.625,
  },
  {
    y: -35.76628112792969,
    x: 3148.4375,
  },
  {
    y: -35.76129913330078,
    x: 3156.25,
  },
  {
    y: -35.75631332397461,
    x: 3164.0625,
  },
  {
    y: -35.7513313293457,
    x: 3171.875,
  },
  {
    y: -35.74634552001953,
    x: 3179.6875,
  },
  {
    y: -35.741363525390625,
    x: 3187.5,
  },
  {
    y: -35.73638153076172,
    x: 3195.3125,
  },
  {
    y: -35.73139572143555,
    x: 3203.125,
  },
  {
    y: -35.72641372680664,
    x: 3210.9375,
  },
  {
    y: -35.72142791748047,
    x: 3218.75,
  },
  {
    y: -35.71644592285156,
    x: 3226.5625,
  },
  {
    y: -35.711463928222656,
    x: 3234.375,
  },
  {
    y: -35.706478118896484,
    x: 3242.1875,
  },
  {
    y: -35.70149612426758,
    x: 3250,
  },
  {
    y: -35.70149612426758,
    x: 3257.8125,
  },
  {
    y: -35.696510314941406,
    x: 3265.625,
  },
  {
    y: -35.696510314941406,
    x: 3273.4375,
  },
  {
    y: -35.6915283203125,
    x: 3281.25,
  },
  {
    y: -35.6915283203125,
    x: 3289.0625,
  },
  {
    y: -35.686546325683594,
    x: 3296.875,
  },
  {
    y: -35.686546325683594,
    x: 3304.6875,
  },
  {
    y: -35.68156051635742,
    x: 3312.5,
  },
  {
    y: -35.68156051635742,
    x: 3320.3125,
  },
  {
    y: -35.676578521728516,
    x: 3328.125,
  },
  {
    y: -35.676578521728516,
    x: 3335.9375,
  },
  {
    y: -35.671592712402344,
    x: 3343.75,
  },
  {
    y: -35.671592712402344,
    x: 3351.5625,
  },
  {
    y: -35.66661071777344,
    x: 3359.375,
  },
  {
    y: -35.66661071777344,
    x: 3367.1875,
  },
  {
    y: -35.66162872314453,
    x: 3375,
  },
  {
    y: -35.66162872314453,
    x: 3382.8125,
  },
  {
    y: -35.65664291381836,
    x: 3390.625,
  },
  {
    y: -35.65664291381836,
    x: 3398.4375,
  },
  {
    y: -35.65166091918945,
    x: 3406.25,
  },
  {
    y: -35.65166091918945,
    x: 3414.0625,
  },
  {
    y: -35.64667510986328,
    x: 3421.875,
  },
  {
    y: -35.64667510986328,
    x: 3429.6875,
  },
  {
    y: -35.641693115234375,
    x: 3437.5,
  },
  {
    y: -35.641693115234375,
    x: 3445.3125,
  },
  {
    y: -35.63671112060547,
    x: 3453.125,
  },
  {
    y: -35.63671112060547,
    x: 3460.9375,
  },
  {
    y: -35.6317253112793,
    x: 3468.75,
  },
  {
    y: -35.6317253112793,
    x: 3476.5625,
  },
  {
    y: -35.62674331665039,
    x: 3484.375,
  },
  {
    y: -35.62674331665039,
    x: 3492.1875,
  },
  {
    y: -35.62674331665039,
    x: 3570.3125,
  },
  {
    y: -35.62175750732422,
    x: 3578.125,
  },
  {
    y: -35.611793518066406,
    x: 3585.9375,
  },
  {
    y: -35.59185791015625,
    x: 3593.75,
  },
  {
    y: -35.571922302246094,
    x: 3601.5625,
  },
  {
    y: -35.54700469970703,
    x: 3609.375,
  },
  {
    y: -35.51710510253906,
    x: 3617.1875,
  },
  {
    y: -35.487205505371094,
    x: 3625,
  },
  {
    y: -35.44733428955078,
    x: 3632.8125,
  },
  {
    y: -35.397499084472656,
    x: 3640.625,
  },
  {
    y: -35.342681884765625,
    x: 3648.4375,
  },
  {
    y: -35.28287887573242,
    x: 3656.25,
  },
  {
    y: -35.208126068115234,
    x: 3664.0625,
  },
  {
    y: -35.12340545654297,
    x: 3671.875,
  },
  {
    y: -35.0337028503418,
    x: 3679.6875,
  },
  {
    y: -34.93403244018555,
    x: 3687.5,
  },
  {
    y: -34.8343620300293,
    x: 3695.3125,
  },
  {
    y: -34.72472381591797,
    x: 3703.125,
  },
  {
    y: -34.610103607177734,
    x: 3710.9375,
  },
  {
    y: -34.480533599853516,
    x: 3718.75,
  },
  {
    y: -34.35095977783203,
    x: 3726.5625,
  },
  {
    y: -34.211421966552734,
    x: 3734.375,
  },
  {
    y: -34.07188415527344,
    x: 3742.1875,
  },
  {
    y: -33.92736053466797,
    x: 3750,
  },
  {
    y: -33.777854919433594,
    x: 3757.8125,
  },
  {
    y: -33.32933807373047,
    x: 3781.25,
  },
  {
    y: -33.17485046386719,
    x: 3789.0625,
  },
  {
    y: -33.015377044677734,
    x: 3796.875,
  },
  {
    y: -32.85590362548828,
    x: 3804.6875,
  },
  {
    y: -32.696434020996094,
    x: 3812.5,
  },
  {
    y: -32.53197479248047,
    x: 3820.3125,
  },
  {
    y: -32.3625373840332,
    x: 3828.125,
  },
  {
    y: -32.19309616088867,
    x: 3835.9375,
  },
  {
    y: -32.018672943115234,
    x: 3843.75,
  },
  {
    y: -31.84923553466797,
    x: 3851.5625,
  },
  {
    y: -31.689762115478516,
    x: 3859.375,
  },
  {
    y: -31.520320892333984,
    x: 3867.1875,
  },
  {
    y: -31.35088348388672,
    x: 3875,
  },
  {
    y: -31.186426162719727,
    x: 3882.8125,
  },
  {
    y: -31.026954650878906,
    x: 3890.625,
  },
  {
    y: -30.87744903564453,
    x: 3898.4375,
  },
  {
    y: -30.57843780517578,
    x: 3914.0625,
  },
  {
    y: -30.433914184570312,
    x: 3921.875,
  },
  {
    y: -30.294376373291016,
    x: 3929.6875,
  },
  {
    y: -30.15483856201172,
    x: 3937.5,
  },
  {
    y: -30.01529884338379,
    x: 3945.3125,
  },
  {
    y: -29.87575912475586,
    x: 3953.125,
  },
  {
    y: -29.74618911743164,
    x: 3960.9375,
  },
  {
    y: -29.621601104736328,
    x: 3968.75,
  },
  {
    y: -29.506980895996094,
    x: 3976.5625,
  },
  {
    y: -29.397342681884766,
    x: 3984.375,
  },
  {
    y: -29.287704467773438,
    x: 3992.1875,
  },
  {
    y: -29.18305206298828,
    x: 4000,
  },
  {
    y: -29.093347549438477,
    x: 4007.8125,
  },
  {
    y: -29.008628845214844,
    x: 4015.625,
  },
  {
    y: -28.933876037597656,
    x: 4023.4375,
  },
  {
    y: -28.864105224609375,
    x: 4031.25,
  },
  {
    y: -28.809288024902344,
    x: 4039.0625,
  },
  {
    y: -28.764434814453125,
    x: 4046.875,
  },
  {
    y: -28.729551315307617,
    x: 4054.6875,
  },
  {
    y: -28.699649810791016,
    x: 4062.5,
  },
  {
    y: -28.674732208251953,
    x: 4070.3125,
  },
  {
    y: -28.65479850769043,
    x: 4078.125,
  },
  {
    y: -28.644832611083984,
    x: 4085.9375,
  },
  {
    y: -28.644832611083984,
    x: 4093.75,
  },
  {
    y: -28.64981460571289,
    x: 4101.5625,
  },
  {
    y: -28.65978240966797,
    x: 4109.375,
  },
  {
    y: -28.68470001220703,
    x: 4117.1875,
  },
  {
    y: -28.71958351135254,
    x: 4125,
  },
  {
    y: -28.75945281982422,
    x: 4132.8125,
  },
  {
    y: -28.809288024902344,
    x: 4140.625,
  },
  {
    y: -28.85912322998047,
    x: 4148.4375,
  },
  {
    y: -28.91892433166504,
    x: 4156.25,
  },
  {
    y: -28.98371124267578,
    x: 4164.0625,
  },
  {
    y: -29.05846405029297,
    x: 4171.875,
  },
  {
    y: -29.138198852539062,
    x: 4179.6875,
  },
  {
    y: -29.227903366088867,
    x: 4187.5,
  },
  {
    y: -29.332557678222656,
    x: 4195.3125,
  },
  {
    y: -29.442195892333984,
    x: 4203.125,
  },
  {
    y: -29.561798095703125,
    x: 4210.9375,
  },
  {
    y: -29.691370010375977,
    x: 4218.75,
  },
  {
    y: -29.820941925048828,
    x: 4226.5625,
  },
  {
    y: -29.95549774169922,
    x: 4234.375,
  },
  {
    y: -30.100019454956055,
    x: 4242.1875,
  },
  {
    y: -30.25450897216797,
    x: 4250,
  },
  {
    y: -30.41398048400879,
    x: 4257.8125,
  },
  {
    y: -30.57843780517578,
    x: 4265.625,
  },
  {
    y: -30.747875213623047,
    x: 4273.4375,
  },
  {
    y: -30.917316436767578,
    x: 4281.25,
  },
  {
    y: -31.086755752563477,
    x: 4289.0625,
  },
  {
    y: -31.261178970336914,
    x: 4296.875,
  },
  {
    y: -31.43560218811035,
    x: 4304.6875,
  },
  {
    y: -31.615009307861328,
    x: 4312.5,
  },
  {
    y: -31.799400329589844,
    x: 4320.3125,
  },
  {
    y: -31.988773345947266,
    x: 4328.125,
  },
  {
    y: -32.55689239501953,
    x: 4351.5625,
  },
  {
    y: -32.751251220703125,
    x: 4359.375,
  },
  {
    y: -32.950592041015625,
    x: 4367.1875,
  },
  {
    y: -33.149932861328125,
    x: 4375,
  },
  {
    y: -33.34429168701172,
    x: 4382.8125,
  },
  {
    y: -33.53366470336914,
    x: 4390.625,
  },
  {
    y: -33.72303771972656,
    x: 4398.4375,
  },
  {
    y: -33.917396545410156,
    x: 4406.25,
  },
  {
    y: -34.10676956176758,
    x: 4414.0625,
  },
  {
    y: -34.291160583496094,
    x: 4421.875,
  },
  {
    y: -34.47056579589844,
    x: 4429.6875,
  },
  {
    y: -34.644989013671875,
    x: 4437.5,
  },
  {
    y: -34.814430236816406,
    x: 4445.3125,
  },
  {
    y: -34.98386764526367,
    x: 4453.125,
  },
  {
    y: -35.1533088684082,
    x: 4460.9375,
  },
  {
    y: -35.32274627685547,
    x: 4468.75,
  },
  {
    y: -35.48221969604492,
    x: 4476.5625,
  },
  {
    y: -35.63671112060547,
    x: 4484.375,
  },
  {
    y: -35.78123092651367,
    x: 4492.1875,
  },
  {
    y: -35.91578674316406,
    x: 4500,
  },
  {
    y: -36.03539276123047,
    x: 4507.8125,
  },
  {
    y: -36.1500129699707,
    x: 4515.625,
  },
  {
    y: -36.25965118408203,
    x: 4523.4375,
  },
  {
    y: -36.35932159423828,
    x: 4531.25,
  },
  {
    y: -36.44403839111328,
    x: 4539.0625,
  },
  {
    y: -36.51879119873047,
    x: 4546.875,
  },
  {
    y: -36.58856201171875,
    x: 4554.6875,
  },
  {
    y: -36.64836502075195,
    x: 4562.5,
  },
  {
    y: -36.693214416503906,
    x: 4570.3125,
  },
  {
    y: -36.72809982299805,
    x: 4578.125,
  },
  {
    y: -36.75301742553711,
    x: 4585.9375,
  },
  {
    y: -36.772953033447266,
    x: 4593.75,
  },
  {
    y: -36.78790283203125,
    x: 4601.5625,
  },
  {
    y: -36.79787063598633,
    x: 4609.375,
  },
  {
    y: -36.79787063598633,
    x: 4617.1875,
  },
  {
    y: -36.78790283203125,
    x: 4625,
  },
  {
    y: -36.772953033447266,
    x: 4632.8125,
  },
  {
    y: -36.75301742553711,
    x: 4640.625,
  },
  {
    y: -36.72809982299805,
    x: 4648.4375,
  },
  {
    y: -36.69820022583008,
    x: 4656.25,
  },
  {
    y: -36.668296813964844,
    x: 4664.0625,
  },
  {
    y: -36.638397216796875,
    x: 4671.875,
  },
  {
    y: -36.608497619628906,
    x: 4679.6875,
  },
  {
    y: -36.57859420776367,
    x: 4687.5,
  },
  {
    y: -36.5486946105957,
    x: 4695.3125,
  },
  {
    y: -36.51879119873047,
    x: 4703.125,
  },
  {
    y: -36.4888916015625,
    x: 4710.9375,
  },
  {
    y: -36.45899200439453,
    x: 4718.75,
  },
  {
    y: -36.4290885925293,
    x: 4726.5625,
  },
  {
    y: -36.404170989990234,
    x: 4734.375,
  },
  {
    y: -36.384239196777344,
    x: 4742.1875,
  },
  {
    y: -36.35932159423828,
    x: 4750,
  },
  {
    y: -36.339385986328125,
    x: 4757.8125,
  },
  {
    y: -36.31446838378906,
    x: 4765.625,
  },
  {
    y: -36.294532775878906,
    x: 4773.4375,
  },
  {
    y: -36.274600982666016,
    x: 4781.25,
  },
  {
    y: -36.25466537475586,
    x: 4789.0625,
  },
  {
    y: -36.23473358154297,
    x: 4796.875,
  },
  {
    y: -36.21479797363281,
    x: 4804.6875,
  },
  {
    y: -36.194862365722656,
    x: 4812.5,
  },
  {
    y: -36.174930572509766,
    x: 4820.3125,
  },
  {
    y: -36.15499496459961,
    x: 4828.125,
  },
  {
    y: -36.13506317138672,
    x: 4835.9375,
  },
  {
    y: -36.11512756347656,
    x: 4843.75,
  },
  {
    y: -36.095191955566406,
    x: 4851.5625,
  },
  {
    y: -36.075260162353516,
    x: 4859.375,
  },
  {
    y: -36.06031036376953,
    x: 4867.1875,
  },
  {
    y: -36.04535675048828,
    x: 4875,
  },
  {
    y: -36.0304069519043,
    x: 4882.8125,
  },
  {
    y: -36.02043914794922,
    x: 4890.625,
  },
  {
    y: -36.005489349365234,
    x: 4898.4375,
  },
  {
    y: -35.995521545410156,
    x: 4906.25,
  },
  {
    y: -35.98057174682617,
    x: 4914.0625,
  },
  {
    y: -35.970603942871094,
    x: 4921.875,
  },
  {
    y: -35.96063995361328,
    x: 4929.6875,
  },
  {
    y: -35.9506721496582,
    x: 4937.5,
  },
  {
    y: -35.92076873779297,
    x: 4960.9375,
  },
  {
    y: -35.910804748535156,
    x: 4968.75,
  },
  {
    y: -35.90083694458008,
    x: 4976.5625,
  },
  {
    y: -35.870933532714844,
    x: 5000,
  },
  {
    y: -35.86096954345703,
    x: 5007.8125,
  },
  {
    y: -35.85100173950195,
    x: 5015.625,
  },
  {
    y: -35.82109832763672,
    x: 5039.0625,
  },
  {
    y: -35.811134338378906,
    x: 5046.875,
  },
  {
    y: -35.80116653442383,
    x: 5054.6875,
  },
  {
    y: -35.771263122558594,
    x: 5078.125,
  },
  {
    y: -35.76628112792969,
    x: 5085.9375,
  },
  {
    y: -35.76129913330078,
    x: 5093.75,
  },
  {
    y: -35.75631332397461,
    x: 5101.5625,
  },
  {
    y: -35.7513313293457,
    x: 5109.375,
  },
  {
    y: -35.74634552001953,
    x: 5117.1875,
  },
  {
    y: -35.741363525390625,
    x: 5125,
  },
  {
    y: -35.73638153076172,
    x: 5132.8125,
  },
  {
    y: -35.73139572143555,
    x: 5140.625,
  },
  {
    y: -35.72641372680664,
    x: 5148.4375,
  },
  {
    y: -35.72142791748047,
    x: 5156.25,
  },
  {
    y: -35.71644592285156,
    x: 5164.0625,
  },
  {
    y: -35.711463928222656,
    x: 5171.875,
  },
  {
    y: -35.706478118896484,
    x: 5179.6875,
  },
  {
    y: -35.70149612426758,
    x: 5187.5,
  },
  {
    y: -35.70149612426758,
    x: 5195.3125,
  },
  {
    y: -35.696510314941406,
    x: 5203.125,
  },
  {
    y: -35.696510314941406,
    x: 5210.9375,
  },
  {
    y: -35.6915283203125,
    x: 5218.75,
  },
  {
    y: -35.6915283203125,
    x: 5226.5625,
  },
  {
    y: -35.686546325683594,
    x: 5234.375,
  },
  {
    y: -35.686546325683594,
    x: 5242.1875,
  },
  {
    y: -35.68156051635742,
    x: 5250,
  },
  {
    y: -35.68156051635742,
    x: 5257.8125,
  },
  {
    y: -35.676578521728516,
    x: 5265.625,
  },
  {
    y: -35.676578521728516,
    x: 5273.4375,
  },
  {
    y: -35.671592712402344,
    x: 5281.25,
  },
  {
    y: -35.671592712402344,
    x: 5289.0625,
  },
  {
    y: -35.66661071777344,
    x: 5296.875,
  },
  {
    y: -35.66661071777344,
    x: 5304.6875,
  },
  {
    y: -35.66162872314453,
    x: 5312.5,
  },
  {
    y: -35.66162872314453,
    x: 5320.3125,
  },
  {
    y: -35.65664291381836,
    x: 5328.125,
  },
  {
    y: -35.65664291381836,
    x: 5335.9375,
  },
  {
    y: -35.65166091918945,
    x: 5343.75,
  },
  {
    y: -35.65166091918945,
    x: 5351.5625,
  },
  {
    y: -35.64667510986328,
    x: 5359.375,
  },
  {
    y: -35.64667510986328,
    x: 5367.1875,
  },
  {
    y: -35.641693115234375,
    x: 5375,
  },
  {
    y: -35.641693115234375,
    x: 5382.8125,
  },
  {
    y: -35.63671112060547,
    x: 5390.625,
  },
  {
    y: -35.63671112060547,
    x: 5398.4375,
  },
  {
    y: -35.6317253112793,
    x: 5406.25,
  },
  {
    y: -35.6317253112793,
    x: 5414.0625,
  },
  {
    y: -35.62674331665039,
    x: 5421.875,
  },
  {
    y: -35.62674331665039,
    x: 5429.6875,
  },
  {
    y: -35.62674331665039,
    x: 5507.8125,
  },
  {
    y: -35.62175750732422,
    x: 5515.625,
  },
  {
    y: -35.611793518066406,
    x: 5523.4375,
  },
  {
    y: -35.59185791015625,
    x: 5531.25,
  },
  {
    y: -35.571922302246094,
    x: 5539.0625,
  },
  {
    y: -35.54700469970703,
    x: 5546.875,
  },
  {
    y: -35.51710510253906,
    x: 5554.6875,
  },
  {
    y: -35.487205505371094,
    x: 5562.5,
  },
  {
    y: -35.44733428955078,
    x: 5570.3125,
  },
  {
    y: -35.397499084472656,
    x: 5578.125,
  },
  {
    y: -35.342681884765625,
    x: 5585.9375,
  },
  {
    y: -35.28287887573242,
    x: 5593.75,
  },
  {
    y: -35.208126068115234,
    x: 5601.5625,
  },
  {
    y: -35.12340545654297,
    x: 5609.375,
  },
  {
    y: -35.0337028503418,
    x: 5617.1875,
  },
  {
    y: -34.93403244018555,
    x: 5625,
  },
  {
    y: -34.8343620300293,
    x: 5632.8125,
  },
  {
    y: -34.72472381591797,
    x: 5640.625,
  },
  {
    y: -34.610103607177734,
    x: 5648.4375,
  },
  {
    y: -34.480533599853516,
    x: 5656.25,
  },
  {
    y: -34.35095977783203,
    x: 5664.0625,
  },
  {
    y: -34.211421966552734,
    x: 5671.875,
  },
  {
    y: -34.07188415527344,
    x: 5679.6875,
  },
  {
    y: -33.92736053466797,
    x: 5687.5,
  },
  {
    y: -33.777854919433594,
    x: 5695.3125,
  },
  {
    y: -33.32933807373047,
    x: 5718.75,
  },
  {
    y: -33.17485046386719,
    x: 5726.5625,
  },
  {
    y: -33.015377044677734,
    x: 5734.375,
  },
  {
    y: -32.85590362548828,
    x: 5742.1875,
  },
  {
    y: -32.696434020996094,
    x: 5750,
  },
  {
    y: -32.53197479248047,
    x: 5757.8125,
  },
  {
    y: -32.3625373840332,
    x: 5765.625,
  },
  {
    y: -32.19309616088867,
    x: 5773.4375,
  },
  {
    y: -32.018672943115234,
    x: 5781.25,
  },
  {
    y: -31.84923553466797,
    x: 5789.0625,
  },
  {
    y: -31.689762115478516,
    x: 5796.875,
  },
  {
    y: -31.520320892333984,
    x: 5804.6875,
  },
  {
    y: -31.35088348388672,
    x: 5812.5,
  },
  {
    y: -31.186426162719727,
    x: 5820.3125,
  },
  {
    y: -31.026954650878906,
    x: 5828.125,
  },
  {
    y: -30.87744903564453,
    x: 5835.9375,
  },
  {
    y: -30.57843780517578,
    x: 5851.5625,
  },
  {
    y: -30.433914184570312,
    x: 5859.375,
  },
  {
    y: -30.294376373291016,
    x: 5867.1875,
  },
  {
    y: -30.15483856201172,
    x: 5875,
  },
  {
    y: -30.01529884338379,
    x: 5882.8125,
  },
  {
    y: -29.87575912475586,
    x: 5890.625,
  },
  {
    y: -29.74618911743164,
    x: 5898.4375,
  },
  {
    y: -29.621601104736328,
    x: 5906.25,
  },
  {
    y: -29.506980895996094,
    x: 5914.0625,
  },
  {
    y: -29.397342681884766,
    x: 5921.875,
  },
  {
    y: -29.287704467773438,
    x: 5929.6875,
  },
  {
    y: -29.18305206298828,
    x: 5937.5,
  },
  {
    y: -29.093347549438477,
    x: 5945.3125,
  },
  {
    y: -29.008628845214844,
    x: 5953.125,
  },
  {
    y: -28.933876037597656,
    x: 5960.9375,
  },
  {
    y: -28.864105224609375,
    x: 5968.75,
  },
  {
    y: -28.809288024902344,
    x: 5976.5625,
  },
  {
    y: -28.764434814453125,
    x: 5984.375,
  },
  {
    y: -28.729551315307617,
    x: 5992.1875,
  },
  {
    y: -28.729551315307617,
    x: 6000,
  },
];
