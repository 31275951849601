import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../SectionTitle';
import { Container, Foldable, FoldButton } from './styles';

export default function Section({
  title, children, isFolded: isFoldedProps,
}) {
  const { t } = useTranslation();

  const foldedLabel = t('section.seeMore');
  const unfoldedLabel = t('section.seeLess');

  const [isFolded, setIsFolded] = useState(isFoldedProps);

  return (
    <Container>
      <FoldButton type="button" onClick={() => setIsFolded((f) => !f)}>{isFolded ? foldedLabel : unfoldedLabel}</FoldButton>
      <SectionTitle text={title} />
      <Foldable folded={isFolded}>
        {children}
      </Foldable>
    </Container>
  );
}

Section.defaultProps = {
  title: '',
  isFolded: false,
};
