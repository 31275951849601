export const tableAlarms = {
  GetPresetDefault: () => (
    {
      getPresetDefault: {
        id: 1,
        createdBy: 'Usuário Carenet',
        createdAt: '2023-01-25T16:00:31.005Z',
        deletedAt: null,
        items: [
          {
            vitalSign: 'systolicArterialPressure',
            presetVeryLower: 89,
            presetLower: 110,
            presetHigher: 126,
            presetVeryHigher: 146,
            presetMin: 0,
            presetMax: 300,
            __typename: 'PresetsItems',
          },
          {
            vitalSign: 'diastolicArterialPressure',
            presetVeryLower: 59,
            presetLower: 80,
            presetHigher: 86,
            presetVeryHigher: 101,
            presetMin: 0,
            presetMax: 150,
            __typename: 'PresetsItems',
          },
          {
            vitalSign: 'meanArterialPressure',
            presetVeryLower: 69,
            presetLower: 90,
            presetHigher: 99,
            presetVeryHigher: 116,
            presetMin: 0,
            presetMax: 250,
            __typename: 'PresetsItems',
          },
          {
            vitalSign: 'heartRate',
            presetVeryLower: 39,
            presetLower: 50,
            presetHigher: 91,
            presetVeryHigher: 121,
            presetMin: 0,
            presetMax: 250,
            __typename: 'PresetsItems',
          },
          {
            vitalSign: 'oxygenSaturation',
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMin: 87,
            presetMax: 100,
            __typename: 'PresetsItems',
          },
          {
            vitalSign: 'respiratoryRate',
            presetVeryLower: 9,
            presetLower: 12,
            presetHigher: 20,
            presetVeryHigher: 31,
            presetMin: 0,
            presetMax: 90,
            __typename: 'PresetsItems',
          },
          {
            vitalSign: 'temperature',
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMin: 30,
            presetMax: 42,
            __typename: 'PresetsItems',
          },
        ],
        __typename: 'Presets',
      },
    }
  ),
  GetPreset: () => ({
    getPreset: {
      id: 2090,
      presetName: 'Preset Customizado #1',
      specialty: 'Pediátrica mista',
      createdBy: 'Daniel Gomes Pereira',
      createdAt: '2023-02-10T17:19:56.732Z',
      deletedAt: null,
      items: [
        {
          id: 14312,
          preset: 2090,
          vitalSign: 'systolicArterialPressure',
          presetMin: 0,
          presetVeryLower: 89,
          presetLower: 110,
          presetHigher: 126,
          presetVeryHigher: 146,
          presetMax: 300,
          __typename: 'PresetsItems',
        },
        {
          id: 14313,
          preset: 2090,
          vitalSign: 'diastolicArterialPressure',
          presetMin: 0,
          presetVeryLower: 59,
          presetLower: 80,
          presetHigher: 86,
          presetVeryHigher: 101,
          presetMax: 150,
          __typename: 'PresetsItems',
        },
        {
          id: 14314,
          preset: 2090,
          vitalSign: 'meanArterialPressure',
          presetMin: 0,
          presetVeryLower: 69,
          presetLower: 90,
          presetHigher: 99,
          presetVeryHigher: 116,
          presetMax: 250,
          __typename: 'PresetsItems',
        },
        {
          id: 14315,
          preset: 2090,
          vitalSign: 'heartRate',
          presetMin: 0,
          presetVeryLower: 39,
          presetLower: 50,
          presetHigher: 91,
          presetVeryHigher: 121,
          presetMax: 250,
          __typename: 'PresetsItems',
        },
        {
          id: 14316,
          preset: 2090,
          vitalSign: 'oxygenSaturation',
          presetMin: 87,
          presetVeryLower: 88,
          presetLower: 96,
          presetHigher: null,
          presetVeryHigher: null,
          presetMax: 100,
          __typename: 'PresetsItems',
        },
        {
          id: 14317,
          preset: 2090,
          vitalSign: 'respiratoryRate',
          presetMin: 0,
          presetVeryLower: 9,
          presetLower: 12,
          presetHigher: 20,
          presetVeryHigher: 31,
          presetMax: 90,
          __typename: 'PresetsItems',
        },
        {
          id: 14318,
          preset: 2090,
          vitalSign: 'temperature',
          presetMin: 30,
          presetVeryLower: 34.9,
          presetLower: 36.1,
          presetHigher: 37.9,
          presetVeryHigher: 40.9,
          presetMax: 42,
          __typename: 'PresetsItems',
        },
      ],
      __typename: 'Presets',
    },
  }
  ),
  GetPresets: () => ({
    getPresets: [
      {
        id: 1,
        presetName: 'Orchestra Default',
        specialty: 'Geral',
        createdBy: 'Usuário Carenet',
        createdAt: '2023-01-25T16:00:31.005Z',
        deletedAt: null,
        items: [
          {
            id: 1,
            preset: 1,
            vitalSign: 'systolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 89,
            presetLower: 110,
            presetHigher: 126,
            presetVeryHigher: 146,
            presetMax: 300,
          },
          {
            id: 2,
            preset: 1,
            vitalSign: 'diastolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 59,
            presetLower: 80,
            presetHigher: 86,
            presetVeryHigher: 101,
            presetMax: 150,
          },
          {
            id: 3,
            preset: 1,
            vitalSign: 'meanArterialPressure',
            presetMin: 0,
            presetVeryLower: 69,
            presetLower: 90,
            presetHigher: 99,
            presetVeryHigher: 116,
            presetMax: 250,
          },
          {
            id: 4,
            preset: 1,
            vitalSign: 'heartRate',
            presetMin: 0,
            presetVeryLower: 39,
            presetLower: 50,
            presetHigher: 91,
            presetVeryHigher: 121,
            presetMax: 250,
          },
          {
            id: 5,
            preset: 1,
            vitalSign: 'oxygenSaturation',
            presetMin: 87,
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMax: 100,
          },
          {
            id: 6,
            preset: 1,
            vitalSign: 'respiratoryRate',
            presetMin: 0,
            presetVeryLower: 9,
            presetLower: 12,
            presetHigher: 20,
            presetVeryHigher: 31,
            presetMax: 90,
          },
          {
            id: 7,
            preset: 1,
            vitalSign: 'temperature',
            presetMin: 30,
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMax: 42,
          },
        ],
        icus: [],
        updatedAt: null,
        unpublished: null,
      },
      {
        id: 2090,
        presetName: 'UTI Adulto',
        specialty: 'Adulto',
        createdBy: 'Daniel Gomes Pereira',
        createdAt: '2023-02-10T17:19:56.732Z',
        deletedAt: null,
        items: [
          {
            id: 14312,
            preset: 2090,
            vitalSign: 'systolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 89,
            presetLower: 110,
            presetHigher: 126,
            presetVeryHigher: 146,
            presetMax: 300,
          },
          {
            id: 14313,
            preset: 2090,
            vitalSign: 'diastolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 59,
            presetLower: 80,
            presetHigher: 86,
            presetVeryHigher: 101,
            presetMax: 150,
          },
          {
            id: 14314,
            preset: 2090,
            vitalSign: 'meanArterialPressure',
            presetMin: 0,
            presetVeryLower: 69,
            presetLower: 90,
            presetHigher: 99,
            presetVeryHigher: 116,
            presetMax: 250,
          },
          {
            id: 14315,
            preset: 2090,
            vitalSign: 'heartRate',
            presetMin: 0,
            presetVeryLower: 39,
            presetLower: 50,
            presetHigher: 91,
            presetVeryHigher: 121,
            presetMax: 250,
          },
          {
            id: 14316,
            preset: 2090,
            vitalSign: 'oxygenSaturation',
            presetMin: 87,
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMax: 100,
          },
          {
            id: 14317,
            preset: 2090,
            vitalSign: 'respiratoryRate',
            presetMin: 0,
            presetVeryLower: 9,
            presetLower: 12,
            presetHigher: 20,
            presetVeryHigher: 31,
            presetMax: 90,
          },
          {
            id: 14318,
            preset: 2090,
            vitalSign: 'temperature',
            presetMin: 30,
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMax: 42,
          },
        ],
        icus: [
          {
            hospitalId: 1400,
            hospitalName: 'Hospital São Márcio',
            icuId: 4069,
            icuName: 'UTI Hospital São Márcio [H2-I1]',
          },
        ],
        updatedAt: null,
        unpublished: null,
      },
      {
        id: 2091,
        presetName: 'UTI Cirúrgica',
        specialty: 'Cirúrgica',
        createdBy: 'Rafael Medeiros',
        createdAt: '2023-02-10T17:19:56.732Z',
        deletedAt: null,
        items: [
          {
            id: 14312,
            preset: 2090,
            vitalSign: 'systolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 89,
            presetLower: 110,
            presetHigher: 126,
            presetVeryHigher: 146,
            presetMax: 300,
          },
          {
            id: 14313,
            preset: 2090,
            vitalSign: 'diastolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 59,
            presetLower: 80,
            presetHigher: 86,
            presetVeryHigher: 101,
            presetMax: 150,
          },
          {
            id: 14314,
            preset: 2090,
            vitalSign: 'meanArterialPressure',
            presetMin: 0,
            presetVeryLower: 69,
            presetLower: 90,
            presetHigher: 99,
            presetVeryHigher: 116,
            presetMax: 250,
          },
          {
            id: 14315,
            preset: 2090,
            vitalSign: 'heartRate',
            presetMin: 0,
            presetVeryLower: 39,
            presetLower: 50,
            presetHigher: 91,
            presetVeryHigher: 121,
            presetMax: 250,
          },
          {
            id: 14316,
            preset: 2090,
            vitalSign: 'oxygenSaturation',
            presetMin: 87,
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMax: 100,
          },
          {
            id: 14317,
            preset: 2090,
            vitalSign: 'respiratoryRate',
            presetMin: 0,
            presetVeryLower: 9,
            presetLower: 12,
            presetHigher: 20,
            presetVeryHigher: 31,
            presetMax: 90,
          },
          {
            id: 14318,
            preset: 2090,
            vitalSign: 'temperature',
            presetMin: 30,
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMax: 42,
          },
        ],
        icus: [],
        updatedAt: null,
        unpublished: null,
      },
      {
        id: 2092,
        presetName: 'UTI Neonatal',
        specialty: 'Neonatal',
        createdBy: 'Ítalo Russan',
        createdAt: '2023-02-10T17:19:56.732Z',
        deletedAt: null,
        items: [
          {
            id: 14312,
            preset: 2090,
            vitalSign: 'systolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 89,
            presetLower: 110,
            presetHigher: 126,
            presetVeryHigher: 146,
            presetMax: 300,
          },
          {
            id: 14313,
            preset: 2090,
            vitalSign: 'diastolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 59,
            presetLower: 80,
            presetHigher: 86,
            presetVeryHigher: 101,
            presetMax: 150,
          },
          {
            id: 14314,
            preset: 2090,
            vitalSign: 'meanArterialPressure',
            presetMin: 0,
            presetVeryLower: 69,
            presetLower: 90,
            presetHigher: 99,
            presetVeryHigher: 116,
            presetMax: 250,
          },
          {
            id: 14315,
            preset: 2090,
            vitalSign: 'heartRate',
            presetMin: 0,
            presetVeryLower: 39,
            presetLower: 50,
            presetHigher: 91,
            presetVeryHigher: 121,
            presetMax: 250,
          },
          {
            id: 14316,
            preset: 2090,
            vitalSign: 'oxygenSaturation',
            presetMin: 87,
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMax: 100,
          },
          {
            id: 14317,
            preset: 2090,
            vitalSign: 'respiratoryRate',
            presetMin: 0,
            presetVeryLower: 9,
            presetLower: 12,
            presetHigher: 20,
            presetVeryHigher: 31,
            presetMax: 90,
          },
          {
            id: 14318,
            preset: 2090,
            vitalSign: 'temperature',
            presetMin: 30,
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMax: 42,
          },
        ],
        icus: [],
        updatedAt: null,
        unpublished: null,
      },
    ],
  }),
  GetHospitalsIcus: () => ({
    getHospitalsIcus: [
      {
        hospitalId: 1399,
        hospitalName: 'Hospital Elizabeth Blackwell',
        icuId: 4067,
        icuName: 'UTI Elizabeth Blackwell [H1-I1]',
      },
      {
        hospitalId: 1399,
        hospitalName: 'Hospital Elizabeth Blackwell',
        icuId: 4068,
        icuName: 'UTI Elizabeth Blackwell [H1-I2]',
      },
      {
        hospitalId: 1400,
        hospitalName: 'Hospital São Márcio',
        icuId: 4069,
        icuName: 'UTI Hospital São Márcio [H2-I1]',
      },
      {
        hospitalId: 1400,
        hospitalName: 'Hospital São Márcio',
        icuId: 4070,
        icuName: 'UTI Hospital São Márcio [H2-I2]',
      },
    ],
  }),
  LinkPreset: () => {
    function linkPreset() {}
    return [linkPreset, { loading: false }];
  },
  DuplicatePreset: () => {
    function duplicatePreset() {}
    return [duplicatePreset, { loading: false }];
  },
  RemovePreset: () => {
    function removePreset({ onCompleted }) {
      onCompleted();
    }
    return [removePreset, { loading: false }];
  },
  PublishPreset: () => {
    function publishPreset() {}
    return [publishPreset, { loading: false }];
  },
  UnpublishPreset: () => {
    function unPublushPreset() {}
    return [unPublushPreset, { loading: false }];
  },
  UpdatePreset: () => {
    function updatePreset({ onCompleted }) {
      onCompleted();
    }
    return [updatePreset, { loading: false }];
  },
  CreatePreset: () => {
    function createPreset({ onCompleted }) {
      onCompleted();
    }
    return [createPreset, { loading: false }];
  },
};
