import { useLazyQuery } from '@apollo/client';
import mockedData from './demo';

function getOperationDefinition({ definitions }) {
  return definitions.find((el) => el.kind === 'OperationDefinition').name.value;
}

function useLazyQueryMock(query, options) {
  return [
    ({ variables, onCompleted }) => {
      onCompleted(mockedData[getOperationDefinition(query)](variables, options));
    },
    { data: mockedData[getOperationDefinition(query)](options?.variables, options) },
  ];
}

const useMockableLazyQuery = process.env.REACT_APP_IS_DEMO ? useLazyQueryMock : useLazyQuery;

export default useMockableLazyQuery;
