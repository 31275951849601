import styled, { css } from 'styled-components';
import { breakpoints, fontSize } from 'config/constants';
import { models, states } from './constants';

const getColor = (theme, state) => {
  switch (state) {
    case states.disabled:
      return theme.colors.disabled;
    case states.danger:
      return theme.colors.feedback.danger;
    default:
      return theme.colors.primary.w100;
  }
};

const getPointerEvents = (state) => {
  switch (state) {
    case states.default:
      return 'initial';
    case states.danger:
      return 'initial';
    default:
      return 'none';
  }
};

const getIconLoadingSize = (iconName) => {
  if (!iconName) {
    return css`
    img {
      width: 28px !important;
    }
    `;
  }

  return null;
};

const roundedButton = css`
  background: ${({ theme, state }) => getColor(theme, state)};
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.colors.text.w000};
  cursor: pointer;
  display: block;
  font-size: ${fontSize.md};
  font-weight: bold;
  height: 90px;
  margin: ${({ margin }) => margin};
  outline: 0;
  pointer-events: ${({ state }) => getPointerEvents(state)};
  transition: all 200ms ease;
  width: 90px;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
  }
  &:focus {
    outline: 0;
  }

  
  @media (min-width: ${breakpoints.sm}) {
    height: 130px;
    width: 130px;
  }
`;

const roundedButtonSecondary = css`
  background: ${({ theme, state }) => ((state === states.disabled) ? theme.colors.disabled : 'transparent')};
  border-radius: 50%;
  border:${({ theme }) => `2px solid ${theme.colors.primary.w100}`};
  color: ${({ theme, state }) => (state === states.default ? theme.colors.primary.w100 : theme.colors.text.w000)};
  cursor: pointer;
  display: block;
  font-size: ${fontSize.md};
  font-weight: bold;
  height: 130px;
  margin: ${({ margin }) => margin};
  pointer-events: ${({ state }) => getPointerEvents(state)};
  transition: all 200ms ease;
  width: 130px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
    color: ${({ theme }) => theme.colors.text.w000};
  }
`;

const flatButton = css`
  align-items: center;
  background: ${({ theme, state }) => getColor(theme, state)};
  border-radius: 50px;
  border: none;
  color: ${({ theme }) => theme.colors.text.w000};
  cursor: pointer;
  display: flex;
  font-size: ${fontSize.sm};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 38px;
  justify-content: center;
  margin: ${({ margin }) => margin};
  min-width: ${({ minWidth }) => minWidth || '120px'};
  padding: 10px 20px;
  pointer-events: ${({ state }) => getPointerEvents(state)};
  text-align: center;
  transition: all 200ms ease;
  width: ${({ width }) => width};

  ${({ iconName }) => getIconLoadingSize(iconName)};

  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
  }
`;

const flatButtonSecondary = css`
  align-items: center;
  background: ${({ theme, state }) => ((state === states.disabled) ? theme.colors.disabled : 'transparent')};
  border-radius: 50px;
  border:${({ theme }) => `2px solid ${theme.colors.primary.w100}`};
  color: ${({ theme, state }) => (state === states.default ? theme.colors.primary.w100 : theme.colors.text.w000)};
  cursor: pointer;
  display: flex;
  font-size: ${fontSize.sm};
  height: 38px;
  justify-content: center;
  margin: ${({ margin }) => margin};
  min-width: ${({ minWidth }) => minWidth || '120px'};
  padding: 10px 20px;
  pointer-events: ${({ state }) => getPointerEvents(state)};
  text-align: center;
  transition: all 200ms ease;
  width: ${({ width }) => width};

  ${({ iconName }) => getIconLoadingSize(iconName)};

  &:hover {
    background: ${({ theme }) => theme.colors.primary.w200};
    color: ${({ theme }) => theme.colors.text.w000};
  }
`;

export const Container = styled.button`
  ${(props) => (props.model === models.modelA && roundedButton)}
  ${(props) => (props.model === models.modelASecondary && roundedButtonSecondary)}
  ${(props) => (props.model === models.modelB && flatButton)};
  ${(props) => (props.model === models.modelBSecondary && flatButtonSecondary)};
`;

export const FileContainer = styled.label`
${(props) => (props.model === models.modelA && roundedButton)}
  ${(props) => (props.model === models.modelASecondary && roundedButtonSecondary)}
  ${(props) => (props.model === models.modelB && flatButton)};
  ${(props) => (props.model === models.modelBSecondary && flatButtonSecondary)};

  & > input {
    display: none;
  }
  & > label {
    cursor: pointer;
  }
`;
