import { chartConfig } from './constants';

const initial = {
  config: {
    startIndex: 0,
    endIndex: null,
  },
};

export default function reducer(state = initial, action) {
  if (action.type === chartConfig.SET_BRUSH_MONITOR) {
    return action.payload;
  }
  return state;
}
