import { CognitoUserPool } from 'amazon-cognito-identity-js';
import MockableCognitoUserPool from './demo/cognitoUserPool';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

export const UserPool = process.env.REACT_APP_IS_DEMO
  ? new MockableCognitoUserPool(poolData) : new CognitoUserPool(poolData);
