import { modes, userManagement } from './constants';

const INITIAL_STATE = {
  error: {
    fromModal: null,
    errors: [],
  },
  selectedUsers: [],
  isCreateUsersModalOpen: false,
  isEditUsersModalOpen: false,
  isSuccessModalOpen: false,
  lastModalOpened: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userManagement.SET_ERRORS:
      return {
        ...state,
        error: {
          fromModal: action.payload?.fromModal,
          errors: action.payload?.errors,
        },
      };
    case userManagement.TOGGLE_CREATE_USERS_MODAL:
      return {
        ...state,
        isCreateUsersModalOpen: action.payload,
        lastModalOpened: modes.create,
      };
    case userManagement.TOGGLE_EDIT_USERS_MODAL:
      return {
        ...state,
        isEditUsersModalOpen: action.payload,
        lastModalOpened: modes.edit,
      };
    case userManagement.TOGGLE_SUCCESS_MODAL:
      return { ...state, isSuccessModalOpen: action.payload };
    case userManagement.SET_SELECTED_USERS:
      return { ...state, selectedUsers: action.payload };
    default:
      return state;
  }
}
