import { options, languageActions } from './constants';

const INITIAL_STATE = options[0];

export default function reducer(state = INITIAL_STATE, action) {
  if (action.type === languageActions.CHANGE_LANGUAGE) {
    return action.payload;
  }
  return state;
}
