import React from 'react';

import {
  Container, Input, Span,
} from './styles';

export default function Radio({
  text, onChange, onClick, margin, value, id, className, name,
}) {
  return (
    <Container onClick={onClick} onChange={onChange} margin={margin}>
      <Input value={value} id={id} className={className} name={name} />
      <Span>{text}</Span>
    </Container>
  );
}

Radio.defaultProps = {
  text: '',
  margin: '0',
  value: '',
  onChange: () => { /** */ },
  onClick: () => { /** */ },
};
