import React, { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Input, { states } from 'components/core/Input';
import Checkbox from 'components/core/Checkbox';
import toSlug from 'utils/toSlug';

import { addSameCodeForAllHospitals } from './helpers/addSameCodeForAllHospitals';
import { shouldShowErrorMessage } from './helpers/shouldShowErrorMessage';

import { Container, UniquepepIdContainer, List } from './styles';
import { setInputState } from './helpers/setInputState';

function LinkHospitalToPep({
  hospitals, callback, isValid, readonly,
}) {
  const { t } = useTranslation();
  const [hasUniquepepId, setHasUniquepepId] = useState(false);

  if (!hospitals) return null;

  function handleInputChange(event) {
    const code = event.target.value;
    const inputName = event.target.name;

    if (hasUniquepepId) {
      callback(addSameCodeForAllHospitals(hospitals, code));
      return;
    }

    const updatedHospitals = hospitals.map((hospital) => {
      if (hospital.value === inputName) {
        return {
          ...hospital,
          pepId: code,
        };
      }
      return hospital;
    });

    callback(updatedHospitals);
  }

  return (
    <Container>
      <UniquepepIdContainer isVisible={hasUniquepepId}>
        <Input
          label={t('text.typeCodeErp')}
          state={setInputState(hasUniquepepId, isValid)}
          required
          autoComplete="off"
          onChange={handleInputChange}
          value={hospitals[0]?.pepId || ''}
          feedbackMessage={
            shouldShowErrorMessage(isValid, hospitals[0]?.pepId)
            && hasUniquepepId
            && t('feedback.requiredField')
          }
          readonly={readonly}
          cy="input-code-pep"
        />
      </UniquepepIdContainer>
      <Checkbox
        readOnly={readonly}
        text={t('text.uniquepepIdForAllHospitals')}
        onChange={(event) => setHasUniquepepId(event.target.checked)}
        margin="26px 0 0 0"
        cy="checkbox-unique-id"
      />
      {
        !hasUniquepepId && (
          <List>
            {
              hospitals.map((hospital) => (
                <Input
                  key={hospital.value}
                  name={hospital.value}
                  label={hospital.label}
                  placeholder={t('text.typeCodeErp')}
                  required
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={hospital?.pepId || ''}
                  state={
                    shouldShowErrorMessage(isValid, hospital?.pepId)
                      ? states.error
                      : states.default
                  }
                  cy={`input-pep-${toSlug(hospital.label)}`}
                  feedbackMessage={shouldShowErrorMessage(isValid, hospital?.pepId) && t('feedback.requiredField')}
                  readonly={readonly}
                />
              ))
            }
          </List>
        )
      }
    </Container>
  );
}

LinkHospitalToPep.defaultProps = {
  callback: () => { /* */ },
  isValid: true,
};

export default memo(LinkHospitalToPep);
