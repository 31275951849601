import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import store from './store';
import 'config/font-face.css';

const { theme } = store.getState();

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  input[type=checkbox] {
    -moz-appearance:initial
  }
  input[type=radio] {
    -moz-appearance:initial
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px ${theme.colors.input.w100} inset;
  }

  :root {
    --toastify-icon-color-info: ${theme.colors.primary.w100};
    --toastify-icon-color-success: ${theme.colors.primary.w100};
    --toastify-icon-color-warning: ${theme.colors.feedback.alert};
    --toastify-color-progress-light: ${theme.colors.primary.gradient};
    --toastify-color-progress-dark: ${theme.colors.primary.gradient};
    --toastify-color-progress-info: ${theme.colors.primary.gradient};
    --toastify-color-progress-success: ${theme.colors.primary.gradient};
    --toastify-color-progress-warning: ${theme.colors.feedback.alert};
  }

  body {
    font-family: 'Barlow Semi Condensed', sans-serif;
    background-color: ${theme.colors.secondary.w200};
  }
  
  a {
    color: unset;
    margin: 0;
    paddi'n'g: 0;
    text-decoration: none;
  }

  a, p, button, input {
    font-family: 'Barlow Semi Condensed';
  }

  p, span, h1, h2, li, b {
    ::selection {
      background-color: ${theme.colors.primary.w200};
      color: ${theme.colors.text.w000};
    }
  }
`;
