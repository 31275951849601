import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast as toastify } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify {
    &__toast-container {
      padding: 10px;
    }

    &__toast {
      background: ${({ theme }) => theme.colors.secondary.w400};
      color: ${({ theme }) => theme.colors.text.w000};
      font-family: unset;
    }

    &__toast-body {}
    &__close-button {
      color: ${({ theme }) => theme.colors.text.w000};
      opacity: 0.9;
    }
    &__progress-bar {}
  }
`;

export const toast = toastify;
