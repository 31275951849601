import { breakpoints, fontSize } from 'config/constants';
import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 150px;
`;

export const Footer = styled.div`
  backdrop-filter: blur(8px);
  background: ${({ theme }) => theme.colors.secondary.w200};
  bottom: 0;
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 40%);
  left: 0;
  position: fixed;
  z-index: 20;
  right: 0;

  @supports (backdrop-filter: blur(8px)) {
    background: ${({ theme }) => theme.colors.secondary.w200blur};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  grid-column: main;
  justify-content: space-between;

  button {
    max-width: 150px;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  gap: 60px 0;
  grid-template-columns: 1fr;

  & > div {
    grid-column: 1;
    
    @media (min-width: ${breakpoints.sm}) {
      grid-column: unset;
    }
  }
  
  @media (min-width: ${breakpoints.sm}) {
    gap: 60px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const HospitalInfo = styled.div`
  color: ${({ theme }) => theme.colors.text.w000};
  display: flex;
  flex-direction: column;
  font-size: ${fontSize.sm};
  font-weight: 200;
  margin: 0 10px;
  
  span {
    margin-bottom: 4px;
  }

  @media (min-width: ${breakpoints.sm}) {
    margin: 0 40px;
  }
`;
